import { Accordion, ExpandedIndex, Stack, Tag } from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ChatIcon, CrossIcon, DashboardIcon, MessageIcon, WalletIcon } from '../../../assets/icons';
import AppFeatureComponent from '../../../components/AppFeatureComponent';
import {
  AppFeatures,
  DashboardRoutes,
  HealRoutes,
  LearnRoutes,
  MessageCenterRoutes,
  SubMenuEnum,
  WalletRoutes,
} from '../../../constants';
import { useIsMarketingPartner } from '../../../hooks/useIsMarketingPartner';
import useNotification from '../../../hooks/useNotification';
import { AppState } from '../../../store/root-reducers';
import { HealthRecords } from '../../heal/provider/Clinic/WaitingRoom/components/HealthRecords/HealthRecords';
import { MenuItem } from './components/MenuItem';
import { SideMenu } from './components/SideMenu';
import useTranslationComponent from '../../../hooks/useTranslationComponent';
import { PatientLoginPurposeEnum } from '../../../store/patient/patientReducers';

export const PatientSideMenu = () => {
  const { t } = useTranslationComponent();
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const [openIndex, setOpenIndex] = useState<ExpandedIndex | undefined>(0);
  const { mail } = useNotification({ currentUser });

  const { patient } = useSelector((state: AppState) => state.patientState);
  const [showPhrModal, setShowPhrModal] = useState(false);
  const isMarketingPartner = useIsMarketingPartner();

  return (
    <>
      <SideMenu containerId="patient-side-bar">
        <MenuItem
          id="side-bar-dashboard"
          ariaLabel="dashboard"
          routes={[DashboardRoutes.Dashboard]}
          Icon={DashboardIcon}
        >
          {t('Dashboard')}
        </MenuItem>
        <MenuItem
          id="side-bar-wallet"
          ariaLabel={t(`${isMarketingPartner ? '' : 'Health'} Wallet`)}
          Icon={WalletIcon}
          routes={[
            WalletRoutes.Wallet,
            WalletRoutes.WalletBalance,
            WalletRoutes.WalletTransactions,
            WalletRoutes.WalletPayees,
          ]}
        >
          {t(`${isMarketingPartner ? '' : 'Health'} Wallet`)}
        </MenuItem>
        {patient?.loginPurpose !== PatientLoginPurposeEnum.SEND_FUNDS && (
          <AppFeatureComponent featureName={AppFeatures.PatientMessagingEnabled}>
            <MenuItem
              id="side-bar-messages"
              ariaLabel="messages"
              Icon={MessageIcon}
              routes={[
                MessageCenterRoutes.InboxMail,
                MessageCenterRoutes.Mail,
                MessageCenterRoutes.SentMail,
                MessageCenterRoutes.ComposeMail,
                MessageCenterRoutes.DeletedMail,
                MessageCenterRoutes.AddressBook,
              ]}
            >
              {t('Messages')}
              {!!mail.count && (
                <Tag ml={4} size="sm" colorScheme="blue">
                  {mail.count}
                </Tag>
              )}
            </MenuItem>
          </AppFeatureComponent>
        )}

        <Accordion index={openIndex} onChange={setOpenIndex} allowToggle>
          <Stack spacing={4}>
            <MenuItem
              id="side-bar-heal"
              ariaLabel="heal"
              routes={[
                `${DashboardRoutes.Submenu}?id=${SubMenuEnum.Heal}`,
                HealRoutes.BookConsult,
                HealRoutes.ClinicQueue,
                HealRoutes.ShoppingCart,
                HealRoutes.CareTeam,
              ]}
              Icon={CrossIcon}
            >
              {t('Heal')}
            </MenuItem>

            {!isMarketingPartner &&
              patient?.loginPurpose !== PatientLoginPurposeEnum.SEND_FUNDS && (
                <MenuItem
                  id="side-bar-learn"
                  ariaLabel="learn"
                  routes={[
                    `${DashboardRoutes.Submenu}?id=${SubMenuEnum.Learning}`,
                    LearnRoutes.Community,
                    LearnRoutes.CommunitySearchResults,
                    LearnRoutes.CommunityDiscussion,
                    LearnRoutes.CommunityDiscussionCreate,
                    LearnRoutes.CommunityDiscussionItem,
                  ]}
                  Icon={ChatIcon}
                >
                  {t('Learn')}
                </MenuItem>
              )}
          </Stack>
        </Accordion>
      </SideMenu>

      <HealthRecords
        patientID={showPhrModal ? patient?.patientID : ''}
        onClose={() => setShowPhrModal(false)}
      />
    </>
  );
};
