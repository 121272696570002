import { push } from 'connected-react-router';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { ConsultRoutes, HealRoutes, TimeFormatEnum } from '../../constants';
import {
  ActionMessageEnum,
  ActionMessagePayloadTypeEnum,
  ConsultStatusEnum,
  ConsultStoreItemTypeEnum,
  ConsultTypeEnum,
  IActionMessage,
  IConsult,
  RolesEnum,
} from '../../generated';
import useInterval from '../../hooks/useInterval';
import usePrevious from '../../hooks/usePrevious';
import useTranslationComponent from '../../hooks/useTranslationComponent';
import { useMixTrack } from '../../lib/mixpanel/mixpanelUtils';
import { sendActionMessageActions } from '../../store/actionMessage/sendActionMessageAction';
import { getActiveConsultByConsultID } from '../../store/activeConsult/activeConsultCreators';
import { ICurrentUser } from '../../store/currentUser/currentUserReducers';
import { getActiveConsult } from '../../store/root-creator';
import { AppState } from '../../store/root-reducers';
import { createRoute } from '../../utils/createRoute';
import { isInRole } from '../../utils/isInRole';
import ActiveConsultInfoBanner from './ActiveConsultInfoBanner';

const ONE_MINUTE = 1000 * 60;
const FIFTEEN_SECONDS = 1000 * 15;
const THIRTY_SECONDS = 1000 * 30;

interface IProps {
  currentUser: ICurrentUser;
}

const PatientActiveConsultInfoBanner = ({ currentUser }: IProps) => {
  const { t } = useTranslationComponent();
  const dispatch = useDispatch();
  const location = useLocation();
  const { consult } = useSelector((state: AppState) => state.activeConsultState);
  const previousActiveConsult = usePrevious(consult) as IConsult | null | undefined;

  const { mixTrack } = useMixTrack();

  const isInConsult = !!matchPath(location.pathname, {
    path: [ConsultRoutes.Consult],
    exact: true,
  });

  const isInWaitingRoom = !!matchPath(location.pathname, {
    path: [HealRoutes.ClinicQueue],
    exact: true,
  });

  const isInMobileView = currentUser?.isInMobileView;
  const isMobileSystem = currentUser?.mobileSystem;

  // If not in the consult
  // get Active Consult
  useEffect(() => {
    if (!isInConsult) {
      dispatch(getActiveConsult(currentUser.userDetailID));
    }
  }, [dispatch, currentUser, isInConsult]);

  // If patient is sitting on the page, and consult started,
  // pull the user into the consultation
  useEffect(() => {
    if (
      previousActiveConsult &&
      previousActiveConsult.consultStatusEnum !== consult?.consultStatusEnum
    ) {
      if (
        consult?.consultStatusEnum === ConsultStatusEnum.InProgress &&
        consult?.consultTypeEnum !== ConsultTypeEnum.InPerson
      ) {
        if (isInMobileView && isMobileSystem === 'ios') {
          // @ts-ignore
          webkit.messageHandlers.startPatientConsultCallbackHandler.postMessage(consult.consultID);
        } else {
          dispatch(push(createRoute(ConsultRoutes.Consult, [['consultID', consult.consultID]])));
        }
      }
    }
  }, [consult, isInMobileView, isMobileSystem, dispatch, previousActiveConsult]);

  // Check for Active Consult
  useInterval(
    () => {
      if (currentUser && !isInConsult) {
        if (consult?.consultStatusEnum === ConsultStatusEnum.ConsultReady) {
          dispatch(getActiveConsultByConsultID(consult.consultID));
        } else {
          dispatch(getActiveConsult(currentUser.userDetailID));
        }
      }
    },
    currentUser && isInRole(currentUser, RolesEnum.Patient)
      ? consult?.consultStatusEnum === ConsultStatusEnum.ConsultReady
        ? FIFTEEN_SECONDS
        : ONE_MINUTE
      : null,
  );

  // Ping Provider if we have an appointment or the Consult is ready
  const shouldPingProviderAboutBeingOnline =
    consult?.consultStatusEnum === ConsultStatusEnum.Scheduled ||
    consult?.consultStatusEnum === ConsultStatusEnum.PatientReady;
  useInterval(
    () => {
      if (consult) {
        dispatch(
          sendActionMessageActions({
            userDetailID: currentUser.userDetailID,
            toUserDetailID: consult.consultStoreItems?.filter(
              (x) => x.consultStoreItemTypeEnum === ConsultStoreItemTypeEnum.Primary,
            )[0].notificationID,
            actionMessageEnum: ActionMessageEnum.Consult,
            actionMessagePayloadTypeEnum: ActionMessagePayloadTypeEnum.PatientReady,
            message: consult.consultID,
          } as IActionMessage),
        );
      }
    },
    shouldPingProviderAboutBeingOnline ? THIRTY_SECONDS : null,
  );

  if (consult) {
    let statusText = '';
    let connectionButtonText = '';
    let routeTo = '';

    const onTrack = () => {
      mixTrack({
        eventName: 'video_consult:patient_go_to_video_consult_clicked',
      });
    };
    let onClick = () => {};
    switch (consult.consultStatusEnum) {
      case ConsultStatusEnum.Scheduled:
        if (!isInWaitingRoom) {
          statusText = t(`Appointment`, {
            patientName: consult.patient?.fullName,
            time: moment(consult.startDate).format(TimeFormatEnum.SHORT_DATE_TIME),
          });
          connectionButtonText = t('Go To Waiting Room');
          routeTo = createRoute(HealRoutes.ClinicQueue, [['consultID', consult.consultID]]);
          onClick = () =>
            dispatch(push(createRoute(HealRoutes.ClinicQueue, [['consultID', consult.consultID]])));
        }
        break;
      case ConsultStatusEnum.PatientReady:
        if (!isInWaitingRoom) {
          statusText = t(`Currently in line to see a provider`);
          connectionButtonText = t('Go To Waiting Room');
          routeTo = createRoute(HealRoutes.ClinicQueue, [['consultID', consult.consultID]]);
          onClick = () =>
            dispatch(push(createRoute(HealRoutes.ClinicQueue, [['consultID', consult.consultID]])));
        }
        break;
      case ConsultStatusEnum.ConsultReady:
        if (!isInWaitingRoom) {
          statusText = t(`Provider will be with you shortly`, { name: consult.providerName });
          connectionButtonText = t('Go To Waiting Room');
          routeTo = createRoute(HealRoutes.ClinicQueue, [['consultID', consult.consultID]]);
          onClick = () =>
            dispatch(push(createRoute(HealRoutes.ClinicQueue, [['consultID', consult.consultID]])));
        }
        break;
      case ConsultStatusEnum.InProgress:
        if (consult.consultTypeEnum === ConsultTypeEnum.Video) {
          statusText = t(`Return to Active Consult`);
          connectionButtonText = t('Join');
          routeTo = createRoute(ConsultRoutes.Consult, [['consultID', consult.consultID]]);
          onClick = () => {
            onTrack();

            if (currentUser?.isInMobileView && currentUser.mobileSystem === 'ios') {
              // @ts-ignore
              webkit.messageHandlers.startPatientConsultCallbackHandler.postMessage(
                consult.consultID,
              );
            } else {
              dispatch(
                push(createRoute(ConsultRoutes.Consult, [['consultID', consult.consultID]])),
              );
            }
          };
        }
        break;
      case ConsultStatusEnum.PatientCompleted:
        if (previousActiveConsult?.consultStatusEnum !== consult.consultStatusEnum) {
          // In case the consult was ended while not in the consult
          // Reload activeConsult, patient complete won't come back
          setTimeout(() => dispatch(getActiveConsult(currentUser.userDetailID)), 2000);
          statusText = t(`Your consultation has ended`);
        }
        break;
    }

    return (
      <ActiveConsultInfoBanner
        statusText={statusText}
        connectionButtonText={connectionButtonText}
        onClick={onClick}
        routeTo={routeTo}
        onTrack={onTrack}
      />
    );
  }

  return null;
};

export default PatientActiveConsultInfoBanner;
