import styled from 'styled-components';

interface ITableRowProps {
  readonly inverse?: boolean;
}

export const Row = styled.tr<ITableRowProps>`
  background-color: ${(props) => (props.inverse ? '#f3f3f3' : 'white')};
  border-top: 5px solid ${(props) => (props.inverse ? 'white' : '#f3f3f3')};
  border-bottom: 5px solid ${(props) => (props.inverse ? 'white' : '#f3f3f3')};

  &:hover {
    background-color: #f5f6fb;
  }
`;
