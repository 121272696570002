import { Box, ModalBody, ModalFooter, Stack, VStack, Text, StackProps } from '@chakra-ui/react';
import React, { FC } from 'react';

import { PrimaryButton } from '../../routes/heal/patient/BookAppointment/components/common/Buttons';
import { BaseModal, HeaderSection } from '.';
import useTranslationComponent from '../../hooks/useTranslationComponent';

type NotificationModalProps = {
  closeButtonText?: string;
  onClose?: () => void;
  modalTitle?: string;
  isModalOpen: boolean;
  onDismissModal?: () => void;
  messages?: string[];
  messagesContainerProps?: StackProps;
  children?: React.ReactNode;
};

const NotificationModal: FC<NotificationModalProps> = (props) => {
  const { t } = useTranslationComponent();
  const hasOnlyOneMessage = props.messages?.length === 1;

  return (
    <BaseModal isOpen={props.isModalOpen} onDismiss={props.onDismissModal}>
      <ModalBody flex="1" px="0" overflowY="auto">
        <VStack height="100%" spacing="0" alignItems="stretch" overflowY="auto">
          <HeaderSection heading={props.modalTitle} noPrevious containerProps={{ mb: '3' }} />
          <Box overflowY="auto" flex="1">
            {props.messages ? (
              <VStack height="100%" {...props.messagesContainerProps}>
                {props.messages.map((message, index) => (
                  <Text
                    fontSize="14px"
                    key={index.toString()}
                    borderBottom={hasOnlyOneMessage ? undefined : '1px solid rgb(211, 211, 211)'}
                    pb="2"
                  >
                    {message}
                  </Text>
                ))}
              </VStack>
            ) : (
              props.children
            )}
          </Box>
        </VStack>
      </ModalBody>
      {!!props.onClose && (
        <ModalFooter>
          <Stack
            justifyContent={{ base: 'center', sm: 'flex-end' }}
            spacing="3"
            direction={{ base: 'column', sm: 'row' }}
            width="100%"
          >
            <PrimaryButton onClick={props.onClose}>
              {t(props.closeButtonText || 'Close')}
            </PrimaryButton>
          </Stack>
        </ModalFooter>
      )}
    </BaseModal>
  );
};

export { NotificationModal };
