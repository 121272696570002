import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { RolesEnum } from '../../generated';
import {
  clearErrorAction,
  CurrentUserActionTypes,
  delegateActions,
  logoutActions,
  refreshTokenActions,
  signInActions,
} from './currentUserActions';

const data = createReducer<ICurrentUser | null, CurrentUserActionTypes>(null)
  .handleAction(
    [signInActions.success, delegateActions.success, refreshTokenActions.success],
    (_state, action) => action.payload,
  )
  .handleAction([logoutActions], () => null);

const error = createReducer<string, CurrentUserActionTypes>('')
  .handleAction(
    [signInActions.failure, delegateActions.failure, refreshTokenActions.failure],
    (_state, action) => action.payload,
  )
  .handleAction([signInActions.success], () => '')
  .handleAction([clearErrorAction], () => '');

const isLoading = createReducer<boolean, CurrentUserActionTypes>(false)
  .handleAction([signInActions.request, delegateActions.request], () => true)
  .handleAction(
    [
      signInActions.failure,
      signInActions.success,
      refreshTokenActions.failure,
      refreshTokenActions.success,
      delegateActions.success,
      delegateActions.failure,
    ],
    () => false,
  );

export interface ICurrentUserState {
  error: string;
  isLoading: boolean;
  data: ICurrentUser | null;
}

export interface ICurrentUser {
  nameID: string;
  uniqueName: string[];
  tenantID: string;
  cultureInfo: string;
  userDetailID: string;
  firstName: string;
  lastName: string;
  imageDetailID: string;
  uniqueLifeTimeID: string;
  emailAddress: string;
  title: string;
  fullName: string;
  roles: RolesEnum[];
  exp: number;
  delegateJWT?: string;
  isInMobileView?: boolean;
  mobileSystem?: 'android' | 'ios'; // Some screens behave different on ios than on android only works with isInMobileView
}

export default combineReducers<ICurrentUserState>({
  data,
  error,
  isLoading,
});
