import { Box, Button, HStack } from '@chakra-ui/react';
import { Formik } from 'formik';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { date, object, string } from 'yup';

import { GLOBAL_MAX_TEXT_LENGTH } from '../../../constants';
import {
  AuthClient,
  FileDetail,
  IPHRHealthJournal,
  PHRDocumentCategoryEnum,
  PHRDocumentsClient,
  PHRDocumentUploadDetails,
  PHRHealthJournal,
} from '../../../generated';
import { createForPhrSection, updateForPhrSection } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../store/types';

import {
  Form,
  FormInputControl,
  FormMobisPicker,
  FormTextArea,
  FormTimePicker,
} from '../../../components/forms';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import ServerValidationBox from '../../../components/ServerValidationBox';
import PhrItemForm from '../components/PhrItemForm';
import { IFileUpload } from '../../../components/FileUploader/useFileUploader/useFileUploader';
import { httpRequest } from '../../../utils';
import { JournalImages } from '../../monitor/HealthJournal/components/JournalList/JournalImages';
import { FileUploader } from '../../../components/FileUploader/FileUploader';

interface IProps {
  selectedHealthJournal: IPHRHealthJournal | null;
  patientID: string;
  toggleView: () => void;
  innerBtnRef: React.RefObject<HTMLButtonElement>;
}

interface IForm {
  title: string;
  notes: string;
  journalDate?: Date;
}

const HealthJournalForm: React.FC<IProps> = ({
  selectedHealthJournal,
  patientID,
  toggleView,
  innerBtnRef,
}) => {
  const dispatch = useDispatch();
  const { error, isPhrLoading } = useSelector((state: AppState) => state.phrPatientState);
  const [files, setFiles] = useState<IFileUpload[]>([]);
  const [selectedJournal, setSelectedJournal] = useState<IPHRHealthJournal | null>(
    selectedHealthJournal,
  );

  const initialValues: IForm = {
    title: selectedJournal ? selectedJournal.title! : '',
    notes: selectedJournal ? selectedJournal.notes! : '',
    journalDate: selectedJournal ? selectedJournal.journalDate! : new Date(),
  };

  const filteredSelectedJournalDocumentIds = (selectedJournal?.phrDocuments ?? [])?.map(
    (x) => x.phrDocumentID,
  );

  const filteredFileIds = files.filter((file) => !!file.id).map((file) => file.id!);

  const allDocumentIDs = [...filteredSelectedJournalDocumentIds, ...filteredFileIds];

  const onDeleteImage = useCallback(
    async (phrDocumentID: string) => {
      try {
        await httpRequest(() =>
          new PHRDocumentsClient(new AuthClient()).pHRDocumentsDelete(patientID, phrDocumentID),
        );
      } catch (err) {}
      setSelectedJournal((prev) => {
        const updated = new PHRHealthJournal();
        updated.init(prev);
        updated.phrDocuments = (updated.phrDocuments ?? []).filter(
          (y) => y.phrDocumentID !== phrDocumentID,
        );
        return updated;
      });
    },
    [patientID],
  );

  const deleteFile = useCallback(
    async (phrDocumentID: string) => {
      setFiles((prevFiles) => prevFiles.filter((x) => x.id !== phrDocumentID));
      try {
        const client = new PHRDocumentsClient(new AuthClient());
        await httpRequest(() => client.pHRDocumentsDelete(patientID!, phrDocumentID));
      } catch (err) {
        // eat error
      }
    },
    [patientID],
  );

  const submit = async (values: IForm) => {
    const completedFiles = files.filter((x) => !!x.id);
    const phrDocumentIDs: string[] = completedFiles.map((x) => x.id || '');

    const healthJournal = new PHRHealthJournal();
    healthJournal.init(selectedJournal);
    healthJournal.patientID = patientID;
    healthJournal.title = values.title;
    healthJournal.notes = values.notes;
    healthJournal.journalDate = new Date(values.journalDate!);
    healthJournal.phrDocumentIDs = phrDocumentIDs;

    if (selectedJournal) {
      dispatch(
        updateForPhrSection(
          ReduxPHRCategoryEnum.PHRHealthJournals,
          healthJournal,
          healthJournal.patientID,
          false,
        ),
      );
    } else {
      dispatch(
        createForPhrSection(
          ReduxPHRCategoryEnum.PHRHealthJournals,
          healthJournal,
          healthJournal.patientID,
          false,
        ),
      );
    }
    toggleView();
  };

  const getRequestBody = useCallback(
    (fileDetail: FileDetail) => {
      const phrDocument = new PHRDocumentUploadDetails();
      phrDocument.fileDetail = fileDetail;
      phrDocument.phrDocumentCategoryEnum = PHRDocumentCategoryEnum.Other;
      phrDocument.patientID = patientID;
      phrDocument.phrHealthJournalID = selectedJournal?.phrHealthJournalID;
      return phrDocument;
    },
    [patientID, selectedJournal],
  );

  return (
    <PhrItemForm>
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          title: string()
            .required('Subject is required')
            .max(100, 'Subject must be at most 100 characters'),
          notes: string().max(GLOBAL_MAX_TEXT_LENGTH, 'Notes must be at most 10000 characters'),
          journalDate: date().required('Date and time is required').typeError('Invalid date'),
        })}
        onSubmit={(values) => submit(values)}
      >
        {({ status }) => (
          <Form error={error}>
            <>
              <ServerValidationBox message={status} />
              {isPhrLoading ? (
                <CenteredLoadingIndicator />
              ) : (
                <>
                  <HStack
                    marginBottom="2rem"
                    spacing={{ base: 0, md: 4 }}
                    flexDir={{ base: 'column', md: 'row' }}
                    alignItems="flex-start"
                  >
                    <Box flex="1">
                      <FormMobisPicker
                        name="journalDate"
                        label="Journal Date"
                        placeholder="DD / MM / YY"
                        max={moment().toDate()}
                        min={moment().add(-150, 'year').toDate()}
                        borderRadius="lg"
                        formControlProps={{
                          marginBottom: 0,
                          formLabelProps: {
                            textTransform: 'uppercase',
                            color: '#01143173',
                            fontSize: '12px',
                            fontWeight: 'light',
                          },
                        }}
                      />
                    </Box>
                    <Box flex="1">
                      <FormTimePicker
                        name="journalDate"
                        label="Time"
                        formControlProps={{
                          mb: '0',
                        }}
                      />
                    </Box>
                  </HStack>

                  <HStack
                    marginBottom="2rem"
                    spacing={{ base: 0, md: 4 }}
                    flexDir={{ base: 'column', md: 'row' }}
                  >
                    <FormInputControl
                      type="text"
                      name="title"
                      label="Subject"
                      placeholder="Enter Journal Subject"
                      height="48px"
                      borderRadius="lg"
                      formControlProps={{
                        marginBottom: { base: '2rem', md: 0 },
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </HStack>

                  <FormTextArea
                    name="notes"
                    label="Reason / Notes"
                    borderRadius="lg"
                    height="120px"
                    resize="none"
                    placeholder="Type a short note here"
                    formControlProps={{
                      marginTop: '1rem',
                      formLabelProps: {
                        textTransform: 'uppercase',
                        color: '#01143173',
                        fontSize: '12px',
                        fontWeight: 'light',
                      },
                    }}
                  />

                  <Button display="none" ref={innerBtnRef} type="submit" />
                </>
              )}
              {(selectedJournal?.phrDocuments ?? []).length > 0 ? (
                <JournalImages
                  onDeleteImage={onDeleteImage}
                  phrDocumentIDs={allDocumentIDs}
                  patientID={selectedJournal!.patientID}
                />
              ) : null}
              <HStack justifyContent="center" mt="3">
                <FileUploader
                  uploadUrl="/api/v1/PHRs/PHRDocuments/uploadPHRDocument"
                  files={files}
                  setFiles={setFiles}
                  getRequestBody={getRequestBody}
                  deleteFile={deleteFile}
                  dropzoneOptions={{
                    multiple: true,
                  }}
                />
              </HStack>
            </>
          </Form>
        )}
      </Formik>
    </PhrItemForm>
  );
};

export default HealthJournalForm;
