import { CountryDefaultStoreDetails, StoreTypeEnum } from '../../../../generated';

type GetCountryDefaultStoreDetails = {
  countryID: string;
  storeTypeEnum: StoreTypeEnum;
};

const getCountryDefaultStoreDetails = (props: GetCountryDefaultStoreDetails) => {
  const countryDefaultStoreDetails = new CountryDefaultStoreDetails();
  countryDefaultStoreDetails.countryID = props.countryID;
  countryDefaultStoreDetails.storeTypeEnum = props.storeTypeEnum;

  return countryDefaultStoreDetails;
};

export { getCountryDefaultStoreDetails };
