import { AddressBooksClient, AuthClient } from '../../../../generated';
import { httpRequest } from '../../../../utils';

const addressBookGet = async (storeID: string) => {
  const addressBooksClient = new AddressBooksClient(new AuthClient());

  const addressBook = await httpRequest(() => addressBooksClient.byAssociatedID(storeID));

  return addressBook;
};

export { addressBookGet };
