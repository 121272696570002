/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { TimeFormatEnum } from '../../../../../constants';
import {
  BloodGlucoseTypeEnum,
  HeightEnum,
  PHRTrackerBloodGlucoseDTO,
  PHRTrackerBloodOxygenDTO,
  PHRTrackerBloodPressureDTO,
  PHRTrackerHeadCircumferenceDTO,
  PHRTrackerLengthDTO,
  PHRTrackerPulseDTO,
  PHRTrackerRespiratoryDTO,
  PHRTrackerTemperatureDTO,
  PHRTrackerWeightHeightDTO,
  TemperatureTypeEnum,
  WeightEnum,
} from '../../../../../generated';
import { ReduxPHRCategoryEnum } from '../../../../../store/types';
import {
  calculateBmi,
  convertBloodGlucose,
  convertHeight,
  convertHeight2,
  convertTemperature,
  convertWeight,
} from '../../../../../utils/conversionHelpers';
import { IUnitSettings } from '../../hooks/usePatientVitals';

export type VitalsTracker =
  | PHRTrackerBloodPressureDTO
  | PHRTrackerPulseDTO
  | PHRTrackerWeightHeightDTO
  | PHRTrackerTemperatureDTO
  | PHRTrackerRespiratoryDTO
  | PHRTrackerBloodOxygenDTO
  | PHRTrackerBloodGlucoseDTO
  | PHRTrackerHeadCircumferenceDTO
  | PHRTrackerLengthDTO;

export interface IRecord {
  value: string;
  unit: string;
}
class Record {
  label: string;

  records: IRecord[];

  constructor(label: string, records: IRecord[]) {
    this.label = label ?? '';
    this.records = records ?? [];
  }
}

export const usePatientVitalsCard = (
  trackerID: ReduxPHRCategoryEnum,
  tracker: VitalsTracker,
  unitSettings?: IUnitSettings,
) => {
  const [date, setDate] = useState<string>('-');
  const [isLoading, setLoading] = useState<boolean>(true);
  const refRecordSet = useRef<Record[]>([]);

  useEffect(() => {
    setLoading(true);
    refRecordSet.current = [];
    switch (trackerID) {
      case ReduxPHRCategoryEnum.PHRBloodPressures: {
        const t: PHRTrackerBloodPressureDTO = tracker;
        refRecordSet.current.push(
          new Record('Blood Pressure', [
            { value: t ? `${t.systolic} / ${t.diastolic}` : '-', unit: 'mmHg' },
          ]),
        );
        break;
      }
      case ReduxPHRCategoryEnum.PHRPulses: {
        const t: PHRTrackerPulseDTO = tracker;
        refRecordSet.current.push(
          new Record('Heart Rate', [{ value: t ? `${t.pulse}` : '-', unit: 'bpm' }]),
        );
        break;
      }
      case ReduxPHRCategoryEnum.PHRWeightHeights: {
        const t: PHRTrackerWeightHeightDTO = tracker;
        const weight = !t?.weight
          ? '-'
          : unitSettings?.weight === WeightEnum.Lbs
          ? `${convertWeight(true, t.weight, unitSettings?.weight!, WeightEnum.Kg)}`
          : `${t.weight}`;
        refRecordSet.current.push(
          new Record('Weight', [{ value: weight, unit: unitSettings?.weight! }]),
        );
        if (unitSettings?.height === HeightEnum.In) {
          const height = !t?.height
            ? { feet: null, inches: null }
            : convertHeight2(true, { centimeters: t.height }, HeightEnum.In, HeightEnum.Cm);
          refRecordSet.current.push(
            new Record('Height', [
              { value: `${height.feet ?? '-'}`, unit: 'ft' },
              { value: `${height.inches ?? '-'}`, unit: 'in' },
            ]),
          );
        } else {
          const height = !t?.height ? '-' : `${t.height}`;
          refRecordSet.current.push(
            new Record('Height', [{ value: height, unit: unitSettings?.height.toLowerCase()! }]),
          );
        }
        refRecordSet.current.push(
          new Record('BMI', [
            {
              value: t?.weight && t?.height ? `${calculateBmi(t.weight, t.height)}` : '-',
              unit: '',
            },
          ]),
        );
        break;
      }
      case ReduxPHRCategoryEnum.PHRTemperatures: {
        const t: PHRTrackerTemperatureDTO = tracker;
        const temperature = !t
          ? '-'
          : unitSettings?.temperature === TemperatureTypeEnum.Fahrenheit
          ? `${convertTemperature(
              true,
              t.temperature,
              unitSettings?.temperature!,
              TemperatureTypeEnum.Celsius,
            )}`
          : `${t.temperature}`;
        refRecordSet.current.push(
          new Record('Temperature', [{ value: temperature, unit: unitSettings?.temperature! }]),
        );
        break;
      }
      case ReduxPHRCategoryEnum.PHRRespiratories: {
        const t: PHRTrackerRespiratoryDTO = tracker;
        refRecordSet.current.push(
          new Record('Respiration', [
            { value: t ? `${t.respiratory}` : '-', unit: 'breaths / min' },
          ]),
        );
        break;
      }
      case ReduxPHRCategoryEnum.PHRBloodOxygens: {
        const t: PHRTrackerBloodOxygenDTO = tracker;
        refRecordSet.current.push(
          new Record('Blood Oxygen (SPO2)', [{ value: t ? `${t.bloodOxygen}` : '-', unit: '%' }]),
        );
        break;
      }
      case ReduxPHRCategoryEnum.PHRBloodGlucoses: {
        const t: PHRTrackerBloodGlucoseDTO = tracker;
        const bloodGlucose = !t
          ? '-'
          : unitSettings?.bloodGlucose === BloodGlucoseTypeEnum.MgdL
          ? `${convertBloodGlucose(
              true,
              t.bloodGlucose,
              unitSettings?.bloodGlucose!,
              BloodGlucoseTypeEnum.MmolL,
            )}`
          : `${t.bloodGlucose}`;
        refRecordSet.current.push(
          new Record('Blood Glucose', [{ value: bloodGlucose, unit: unitSettings?.bloodGlucose! }]),
        );
        break;
      }
      case ReduxPHRCategoryEnum.PHRHeadCircumferences: {
        const t: PHRTrackerHeadCircumferenceDTO = tracker;
        const headCircumference = !t
          ? '-'
          : unitSettings?.height === HeightEnum.In
          ? `${convertHeight(true, t.headCircumference, unitSettings?.height!, HeightEnum.Cm)}`
          : `${t.headCircumference}`;
        refRecordSet.current.push(
          new Record('Head Circumference', [
            { value: headCircumference, unit: unitSettings?.height.toLowerCase()! },
          ]),
        );
        break;
      }
      case ReduxPHRCategoryEnum.PHRLengths: {
        const t: PHRTrackerLengthDTO = tracker;
        const length = !t
          ? '-'
          : unitSettings?.height === HeightEnum.In
          ? `${convertHeight(true, t.length, unitSettings?.height!, HeightEnum.Cm)}`
          : `${t.length}`;
        refRecordSet.current.push(
          new Record('Length', [{ value: length, unit: unitSettings?.height.toLowerCase()! }]),
        );
        break;
      }
    }
    if (tracker) {
      setDate(`${moment(tracker.upsertDate).format(TimeFormatEnum.LONG_DATE_TIME)}`);
    }
    setLoading(false);
  }, [tracker, trackerID, unitSettings]);

  return { isLoading, recordSet: refRecordSet.current, date };
};
