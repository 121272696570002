import React from 'react';
import { Grid, Flex, Text } from '@chakra-ui/react';

import { VerifiedShieldIcon } from '../../../assets/icons';
import { ButtonSizeEnum } from '../../../constants';

import Modal from '../../../components/Modals/Modal';
import { PrimaryButton, TertiaryButton } from '../../../components/Buttons';
import { OutpostTheme } from '../../../themes/outpost';

const PhrVerifyModal = ({ isOpen, onDismiss, onVerify }) => {
  return (
    <Modal
      isOpen={isOpen}
      title="VERIFY RECORD"
      onDismiss={onDismiss}
      size="md"
      modalHeaderProps={{ fontSize: '14px', fontWeight: 'semibold' }}
    >
      <Flex justify="center" align="center" flexDir="column" height="100%">
        <Grid
          marginBottom="2rem"
          width="75px"
          height="75px"
          borderRadius="full"
          bgColor="#0202020D"
          placeItems="center"
        >
          <VerifiedShieldIcon fontSize="2.5rem" stroke="#020202" />
        </Grid>
        <Text
          maxW="425px"
          textAlign="center"
          fontSize={OutpostTheme.FontSizes.body}
          fontWeight="light"
          color="#020202"
        >
          Verifying This Record Means That You Have Confirmed This Entry Is Correct.
        </Text>
        <Text
          maxW="425px"
          mt="1rem"
          textAlign="center"
          fontSize={OutpostTheme.FontSizes.body}
          fontWeight="light"
          color="#020202"
        >
          {' '}
          This Will Lock In The Record. Would You Like To Continue?
        </Text>
        <Flex marginTop="2.5rem">
          <TertiaryButton
            size={ButtonSizeEnum.medium}
            fontWeight="normal"
            color="#020202"
            marginRight="1rem"
            onClick={onDismiss}
          >
            Go Back
          </TertiaryButton>
          <PrimaryButton
            size={ButtonSizeEnum.medium}
            bgColor="#020202"
            borderColor="#020202"
            _hover={{
              bgColor: '#0202020D',
            }}
            borderRadius="full"
            onClick={onVerify}
          >
            Yes, Verify
          </PrimaryButton>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default PhrVerifyModal;
