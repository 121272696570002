import { PatientOrderItem } from '../../../../generated';
import { getTime } from '../../../../utils';

const getSortedPatientOrderItems = () => {
  const sortByName = (patientOrderItems: PatientOrderItem[]) => {
    const filteredItems = patientOrderItems.filter(
      (item) => !!item.orderItem?.item?.name || item.testDescription,
    );
    return filteredItems.sort((a, b) =>
      (a.orderItem?.item?.name || a.testDescription!).localeCompare(
        b.orderItem?.item?.name || b.testDescription!,
      ),
    );
  };

  const sortByDateDesc = (phrMedication: PatientOrderItem[]) => {
    return phrMedication.sort((a, b) => getTime(a.createdDate) - getTime(b.createdDate));
  };

  const sortByDateAsc = (phrMedication: PatientOrderItem[]) => {
    return phrMedication.sort((a, b) => getTime(b.createdDate) - getTime(a.createdDate));
  };
  return { sortByName, sortByDateDesc, sortByDateAsc };
};
export { getSortedPatientOrderItems as getSortedPhrMedications };
