import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { BASE_URL } from '../../baseUrl';
import { COOKIE_NAME } from '../../constants';
import {
  ActionMessage,
  ActionMessageEnum,
  ActionMessagePayloadTypeEnum,
  ConsultStatusEnum,
  IActionMessage,
  DiscussionItem,
  RolesEnum,
} from '../../generated';
import { QueryKeys } from '../../services/keys';
import { getCookie } from '../../utils/cookieHelper';
import { isInRole } from '../../utils/isInRole';
import {
  ActionMessageActionTypes,
  sendActionMessageActions,
} from '../actionMessage/sendActionMessageAction';
import {
  moveConsultToPhone,
  updatedConsultStoreItemAction,
} from '../activeConsult/activeConsultActions';
import { updateActiveConsultStatus } from '../activeConsult/activeConsultCreators';
import { AppState } from '../root-reducers';
import { getShoppingCartItemCount } from '../shoppingCartItemCount/getShoppingCartItemCountAction';
import { newSystemMessageAction } from '../systemMessages/getSystemMessagesAction';
import { getUnacknowledgedRewardCount } from '../unacknowledgedRewardCount/getUnacknowledgedRewardCountAction';
import { getUnreadMailMessageCount } from '../unreadMailMessageCount/getUnreadMailMessageCountAction';
import { updateUssdTransaction } from '../ussdTransaction/ussdTransactionActions';
import { patientReadyAction } from '../waitingRoom/waitingRoomActions';
import { replyDiscussionItems } from '../discussionItems/replyDiscussionItemsAction';

const options = {
  transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling,
  logMessageContent: true,
  logger: LogLevel.Error,
  accessTokenFactory: () => lastCookie,
};

let connection;

let lastCookie;

interface IProps {
  dispatch: any;
  getState: () => AppState;
}

const websocketMiddleware =
  ({ dispatch, getState }: IProps) =>
  (next) =>
  async (action) => {
    const currentUser = getState().currentUserState.data;
    if (!connection && currentUser && !isInRole(currentUser, RolesEnum.NotInitializedPatient)) {
      lastCookie = currentUser.delegateJWT ? currentUser.delegateJWT : getCookie(COOKIE_NAME);
      connection = new HubConnectionBuilder()
        .withUrl(`${BASE_URL}/api/v1/actionmessage`, options)
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: (retryContext) => {
            console.log('retry');
            if (retryContext.elapsedMilliseconds < 60000) {
              // If we've been reconnecting for less than 60 seconds so far,
              // wait between 0 and 10 seconds before the next reconnect attempt.
              return Math.random() * 10000;
            } else {
              // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
              return null;
              // Could dispatch an action here
            }
          },
        })
        .build();

      connection.on('ReceiveActionMessage', (actionMessage: ActionMessage) => {
        try {
          switch (actionMessage.actionMessageEnum) {
            case ActionMessageEnum.Mail:
              dispatch(getUnreadMailMessageCount());
              break;
            case ActionMessageEnum.ShoppingCart:
              if (isInRole(currentUser, RolesEnum.Patient)) {
                const message = JSON.parse(actionMessage.message!);
                dispatch(getShoppingCartItemCount(message.totalItemCount));
              }
              break;
            case ActionMessageEnum.USSDInvoicePayment:
              dispatch(updateUssdTransaction(JSON.parse(actionMessage.message!)));
              break;
            case ActionMessageEnum.MarketingCampaignUserDetailReward:
              {
                // TODO: multiple campaigns support
                const marketingCampaignID =
                  getState().activeMarketingCampaignsState.activeMarketingCampaigns?.[0]
                    ?.marketingCampaignID;
                if (marketingCampaignID) {
                  dispatch(getUnacknowledgedRewardCount(marketingCampaignID));
                }
              }
              break;
            case ActionMessageEnum.Consult:
              switch (actionMessage.actionMessagePayloadTypeEnum) {
                case ActionMessagePayloadTypeEnum.PatientReady:
                  if (actionMessage.message) {
                    dispatch(patientReadyAction(actionMessage.message));
                  }
                  break;
                case ActionMessagePayloadTypeEnum.ProviderReady:
                  if (actionMessage.message) {
                    dispatch(
                      sendActionMessageActions({
                        userDetailID: currentUser?.userDetailID,
                        toUserDetailID: actionMessage.userDetailID,
                        actionMessageEnum: ActionMessageEnum.Consult,
                        actionMessagePayloadTypeEnum: ActionMessagePayloadTypeEnum.PatientReady,
                        message: actionMessage.message,
                      } as IActionMessage),
                    );
                  }
                  break;
                case ActionMessagePayloadTypeEnum.ConsultStoreItem: {
                  const message = JSON.parse(actionMessage.message!);
                  dispatch(updatedConsultStoreItemAction(message));
                  break;
                }
                case ActionMessagePayloadTypeEnum.MoveToPhone: {
                  const consultID = actionMessage.message!;
                  dispatch(moveConsultToPhone(consultID));
                  break;
                }
                default:
                  if (isInRole(currentUser, RolesEnum.Patient)) {
                    const message = JSON.parse(actionMessage.message!);
                    dispatch(updateActiveConsultStatus(message));
                  } else if (isInRole(currentUser, RolesEnum.Provider)) {
                    const message = JSON.parse(actionMessage.message!);
                    dispatch(updateActiveConsultStatus(message));
                    if (
                      message.consultStatusEnum === ConsultStatusEnum.PatientReady ||
                      message.consultStatusEnum === ConsultStatusEnum.Cancelled ||
                      message.consultStatusEnum === ConsultStatusEnum.NoShow
                    ) {
                      // Not the cleanest solution, but okay for now
                      // @ts-ignore
                      window.queryClient.refetchQueries([QueryKeys.ConsultsWaitingRoomPatients]);
                    }
                  }
                  break;
              }
              break;
            case ActionMessageEnum.SystemMessage:
              {
                const message = JSON.parse(actionMessage.message!);
                dispatch(newSystemMessageAction(message));
              }
              break;
            case ActionMessageEnum.DiscussionItemDetail:
              {
                const message: DiscussionItem = JSON.parse(actionMessage.message!);
                dispatch(
                  replyDiscussionItems({
                    ...message,
                    isProvider: isInRole(currentUser, RolesEnum.Provider),
                  } as DiscussionItem & {
                    isProvider?: boolean | undefined;
                  }),
                );
              }
              break;
            default:
              break;
          }
        } catch (err) {}
      });

      connection
        .start()
        .then(() => console.log('connected'))
        .catch((error) => console.error('error', error));
    }

    // New Token is being used
    if ((connection && lastCookie !== getCookie(COOKIE_NAME)) || currentUser?.delegateJWT) {
      if (connection.state === 'Disconnected') {
        lastCookie = currentUser?.delegateJWT ?? getCookie(COOKIE_NAME);
        if (lastCookie) {
          connection
            .start()
            .then(() => console.log('connected'))
            .catch((error) => console.error('error', error));
        }
      } else if (connection.state === 'Connected') {
        connection.stop();
      }
    }

    if (action.type.startsWith('SIGNAL')) {
      const { type, payload } = action as ActionMessageActionTypes;
      switch (type) {
        case 'SIGNAL_DISCONNECT':
          if (connection) {
            console.log('disconnected');
            connection.stop();
          }
          break;
        case 'SIGNAL_SEND_ACTION_MESSAGE':
          connection
            .invoke('SendActionMessage', payload)
            .catch((err) => console.error(err.toString()));
          break;
      }
    }

    next(action);
  };

export default websocketMiddleware;
