import { Box, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { Avatar } from '../../../../components/common/Avatar';
import { Tag } from '../../../../components/TagComponent';
import { ConsultProviderSearchProviderResult } from '../../../../generated';
import { OutpostTheme } from '../../../../themes/outpost';
import { getUserThumbnail } from '../../../../utils/getUserThumbnail';

type ProviderCardProps = {
  provider: ConsultProviderSearchProviderResult;
  children?: React.ReactNode;
};

const ProviderCard: FC<ProviderCardProps> = (props) => {
  return (
    <HStack spacing="3" alignItems="flex-start">
      <Avatar
        size={OutpostTheme.Avatar.Size.Small}
        src={getUserThumbnail(props?.provider?.imageDetailID)}
      />
      <Box flex="1">
        <HStack mb="2">
          <Text fontSize={OutpostTheme.FontSizes.body} fontWeight="medium">
            {props?.provider?.fullName}
          </Text>
          {props?.provider?.providerTypeTag && (
            <Tag
              text={props?.provider?.providerTypeTag?.name}
              textProps={{
                fontSize: '12px',
              }}
              containerProps={{
                py: '0.5',
                px: '1',
              }}
            />
          )}
        </HStack>
        {props.children}
      </Box>
    </HStack>
  );
};

export { ProviderCard };
