import React, { useState } from 'react';

import { Box, Flex, IconButton, Link, Text, Wrap, WrapItem } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import moment from 'moment';

import { useSelector } from 'react-redux';

import { InputControl, MobisPicker } from '../../../../../../components/forms';
import { CountrySelect } from '../../../../../../components/forms/Selects/';
import {
  AuthClient,
  ConsultTagTypeEnum,
  PHRDocument,
  PHRDocumentsClient,
} from '../../../../../../generated';

import { AppState } from '../../../../../../store/root-reducers';

import { httpRequest, prepareForDownload } from '../../../../../../utils';

import useProviderConsultContext from '../../hooks/useProviderConsultContext';

import ImageViewer from 'react-simple-image-viewer';
import { EyeIcon } from '../../../../../../assets/icons';
import { OutpostTheme } from '../../../../../../themes/outpost';
import { ViewPhrDocument } from '../../../../../phr/components/ViewPhrDocument';
import { ComplaintTag, ComplaintTags, HeaderNote, SmallStyledTextArea } from '../Styled';

const Complaint = (): JSX.Element => {
  const {
    setComplaint,
    complaint,
    patientCountry,
    setPatientCountry,
    consultDate,
    setConsultDate,
    phrConsult,
    validationMessages,
    countries,
    isLoadingCountries,
    phrDocuments,
  } = useProviderConsultContext();

  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  const download = async (patientID: string, phrDocumentID: string) => {
    try {
      const phrDocumentsClient = new PHRDocumentsClient(new AuthClient());
      const x = await httpRequest(() => phrDocumentsClient.download(patientID, phrDocumentID));
      const newBlob = new Blob([x.data]);
      prepareForDownload(x.fileName!, newBlob);
    } catch (err) {}
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isImageOpen, setIsImageOpen] = useState<boolean>(false);
  const [downloadDoc, setDownloadDoc] = useState<PHRDocument>();

  const handleView = (document: PHRDocument) => {
    if (document.fileDetail?.contentType === 'application/pdf') {
      setDownloadDoc(document);
      setIsOpen(!isOpen);
    } else {
      setDownloadDoc(document);
      setIsImageOpen(!isImageOpen);
    }
  };

  return (
    <>
      <div>
        <Box id="subjective">
          <InputControl
            label="Patient Name"
            name="patientName"
            type="text"
            value={phrConsult?.patient?.fullName}
            onChange={() => {}}
            isDisabled={true}
          />
        </Box>
        <div>
          <InputControl
            label="Provider Name"
            name="providerame"
            type="text"
            value={phrConsult?.provider?.fullName}
            onChange={() => {}}
            isDisabled={true}
          />
        </div>
        {phrConsult?.consult?.consultStatusEnum && (
          <div>
            <InputControl
              label="Consult Result"
              name="consultResult"
              type="text"
              value={phrConsult?.consult?.consultStatusEnum}
              onChange={() => {}}
              isDisabled={true}
            />
          </div>
        )}
      </div>
      {consultDate && setConsultDate && (
        // Weird Chakra bug, wrap grows a couple pixels over the container.
        <Flex overflow="hidden">
          <Wrap spacing={4}>
            <WrapItem>
              <CountrySelect
                name="countryID"
                value={patientCountry}
                options={countries}
                label="Patient Location"
                valueKey="countryID"
                labelKey="name"
                onChange={setPatientCountry}
                isClearable={false}
                isLoading={isLoadingCountries}
                isDisabled={true}
              />
            </WrapItem>
            <WrapItem maxW="150px">
              {/* <DateTimePicker
                name="consultDate"
                label="Consult Date"
                minDate={moment().subtract(1, 'year').toDate()}
                maxDate={moment().toDate()}
                selected={consultDate}
                onChange={(date) => setConsultDate(date === null ? undefined : (date as Date))}
                disabled={!!phrConsult?.consultID}
              /> */}
              <MobisPicker
                containerProps={{ width: '100%' }}
                label="Consult Date"
                min={moment().subtract(1, 'year').toDate()}
                max={moment().toDate()}
                value={consultDate}
                controls={['calendar', 'time']}
                name="consultDate"
                onChange={(date) => setConsultDate(date === null ? undefined : (date as Date))}
                disabled={!!phrConsult?.consultID}
              />
            </WrapItem>
            <WrapItem maxW="115px">
              {/* <MobisPicker
                name="consultDate"
                label="Consult Time"
                value={consultDate}
                controls={['time']}
                onChange={(date) => setConsultDate(date as Date)}
                disabled={true}
              /> */}
            </WrapItem>
          </Wrap>
        </Flex>
      )}

      <Box my={4}>
        <Text fontSize={OutpostTheme.FontSizes.body} fontWeight="500">
          Attachments
        </Text>
        {phrDocuments.length === 0 && <Text fontSize={OutpostTheme.FontSizes.body}>none</Text>}
        {(phrDocuments ?? []).map((x) => (
          <React.Fragment key={x.phrDocumentID}>
            <IconButton
              variant="unstyled"
              ml={2}
              mr={2}
              aria-label="view order"
              icon={<EyeIcon fontSize="20px" />}
              onClick={() => {
                handleView(x);
              }}
            />

            <Link key={x.phrDocumentID} onClick={() => download(x.patientID, x.phrDocumentID)}>
              {x.fileDetail?.name} <FontAwesomeIcon icon={['far', 'cloud-download']} />
            </Link>
          </React.Fragment>
        ))}
      </Box>

      <HeaderNote as="div" className="d-flex">
        <div>
          {(phrConsult?.phrConsultTags ?? []).length > 0 && (
            <ComplaintTags>
              {(phrConsult?.phrConsultTags ?? [])
                .filter((x) => x.consultTagTypeEnum === ConsultTagTypeEnum.Complaint)
                .map((x, index) => (
                  <ComplaintTag key={index}>{x.tag?.name}</ComplaintTag>
                ))}
            </ComplaintTags>
          )}
        </div>
      </HeaderNote>

      <SmallStyledTextArea
        mb={4}
        name="complaint"
        onChange={(e) => setComplaint!(e.target.value)}
        value={complaint}
        label="Complaint"
        formControlProps={{ error: validationMessages?.complaint }}
        isDisabled={currentUser?.userDetailID === phrConsult?.createdBy ? false : true}
      />

      {isOpen && downloadDoc && downloadDoc.fileDetail?.contentType === 'application/pdf' && (
        <ViewPhrDocument
          patientID={downloadDoc.patientID ?? ''}
          documentID={downloadDoc.phrDocumentID ?? ''}
          onClose={() => setIsOpen(!isOpen)}
          fileName={downloadDoc.fileDetail?.name ?? ''}
        />
      )}

      {isImageOpen && (
        <ImageViewer
          src={[
            `/api/v1/PHRs/PHRDocuments/${downloadDoc?.patientID}/DocumentAsSrc/${downloadDoc?.phrDocumentID}`,
          ]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          onClose={() => {
            setIsImageOpen(false);
            setDownloadDoc(undefined);
          }}
        />
      )}
    </>
  );
};

export { Complaint };
