import { Flex, ModalBody, ModalFooter } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { IFormQuestion, IPHRFormQuestion, PHRFormQuestion } from '../../../../../generated';
import { ButtonRow } from '../../../../Buttons';
import { RadioButtonControl, RadioGroupControl } from '../../../../forms';
import useFormBuilderContext from '../../../hooks/useFormBuilderContext/useFormBuilderContext';
import { NextButton } from '../../NavigationButtons/NextButton';
import { PrevButton } from '../../NavigationButtons/PrevButton';
import { QuestionHeader } from '../../QuestionHeader/QuestionHeader';

interface IProps {
  formQuestion: IFormQuestion;
  previousAnswer?: IPHRFormQuestion;
}

export const YesNoQuestion = ({ formQuestion, previousAnswer }: IProps) => {
  const [value, setValue] = useState<string>();
  const { onNext, goToPrevious, canGoBack, formQuestions, state, lastStep } =
    useFormBuilderContext();

  useEffect(() => {
    if (previousAnswer?.value) {
      setValue(previousAnswer?.value);
    }
  }, [previousAnswer, setValue]);

  return (
    <>
      <ModalBody>
        <QuestionHeader
          title={formQuestion.name}
          description={formQuestion.description}
          currentStep={state.currentIndex + 1}
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
          totalQuestionCount={formQuestions?.length!}
        />
        <RadioGroupControl name="YesNo" onChange={(val: string) => setValue(val)} value={value}>
          <Flex w="100%" justifyContent="space-evenly">
            <RadioButtonControl value="Yes" size="lg">
              Yes
            </RadioButtonControl>
            <RadioButtonControl value="No" size="lg">
              No
            </RadioButtonControl>
          </Flex>
        </RadioGroupControl>
      </ModalBody>
      <ModalFooter>
        <ButtonRow>
          {canGoBack && <PrevButton onClick={goToPrevious} />}
          <NextButton
            lastStep={lastStep}
            onClick={() => {
              const newFormQuestion = new PHRFormQuestion();
              if (previousAnswer) {
                newFormQuestion.init(previousAnswer);
              } else {
                newFormQuestion.formQuestionID = formQuestion.formQuestionID;
              }
              newFormQuestion.value = `${value}`;
              onNext(newFormQuestion);
            }}
            disabled={formQuestion.required && !value}
          />
        </ButtonRow>
      </ModalFooter>
    </>
  );
};
