import { useEffect, useState } from 'react';
import { EMPTY_GUID } from '../../../constants';
import { ProvinceState } from '../../../generated';
import { useProvinceStatesByCountryID } from '../../../services/provinceStates/queries/useProvinceStatesByCountryID';
import { IProvinceOption } from './AddressPicker';

interface IProps {
  countryID?: string;
  initialProvinceState: IProvinceOption | null;
}

const useProvinceStates = ({ countryID, initialProvinceState }: IProps) => {
  const [provinceState, setProvinceState] = useState<IProvinceOption | null>(initialProvinceState);
  const [provinceStateOptions, setProvinceStateOptions] = useState<ProvinceState[]>([]);
  const { isLoadingProvinceStates, provinceStatesResult, provinceStatesError } =
    useProvinceStatesByCountryID({ countryID });

  useEffect(() => {
    let options = [...(provinceStatesResult ?? [])];
    if (provinceState?.provinceStateID === EMPTY_GUID) {
      // @ts-ignore
      options = [...options, { ...provinceState, __isNew__: true }];
    }
    setProvinceStateOptions(options);
  }, [provinceStatesResult, provinceState]);

  return {
    provinceStateOptions,
    provinceState,
    setProvinceState,
    isLoadingProvinceStates,
    provinceStatesError,
  };
};

export default useProvinceStates;
