import { useMutation } from 'react-query';
import { AuthClient, PatientOrder, PatientOrdersClient } from '../../../generated';
import { httpRequest } from '../../../utils';

export const usePatientOrdersPut = () => {
  const patientOrdersPut = useMutation<string, string, PatientOrder, string>(
    async (patientOrder: PatientOrder) =>
      httpRequest(() => new PatientOrdersClient(new AuthClient()).patientOrdersPut(patientOrder)),
  );

  return patientOrdersPut;
};
