import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IPatient, RolesEnum } from '../../../../generated';
import { getAllForPhrSection, getPHRPatient } from '../../../../store/patient/phr/phrCreators';
import { getPatient } from '../../../../store/root-creator';
import { AppState } from '../../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import { isInRole } from '../../../../utils/isInRole';

export const usePhrPatient = (inputPatient?: IPatient) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const loadedPatient = useSelector((state: AppState) => state.patientState.patient);
  const { phrPatient, isLoading } = useSelector((state: AppState) => state.phrPatientState);

  useEffect(() => {
    if (!inputPatient && currentUser && isInRole(currentUser, RolesEnum.Patient)) {
      dispatch(getPatient(currentUser.userDetailID));
    }
  }, [inputPatient, dispatch, currentUser]);

  useEffect(() => {
    const patient = inputPatient || loadedPatient;
    if (patient && (!phrPatient || phrPatient.patient.patientID !== patient.patientID)) {
      dispatch(getPHRPatient(patient.patientID));
    }
  }, [dispatch, inputPatient, loadedPatient, phrPatient]);

  useEffect(() => {
    if (phrPatient?.phr && phrPatient.phr.phrTrackers == null) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRTrackers, phrPatient.phr.patientID));
    }
  }, [dispatch, phrPatient]);

  return {
    isLoading,
    phrPatient,
  };
};
