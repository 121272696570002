import { ModalBody, ModalBodyProps, ModalFooter, ModalFooterProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { ButtonRow } from '../Buttons';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import { SecondaryButton } from '../Buttons/SecondaryButton';
import { BaseModal, BaseModalProps } from './BaseModal';

export type ModalProps = BaseModalProps & {
  modalBodyProps?: ModalBodyProps;
  modalFooterProps?: ModalFooterProps;
  footer?: ReactNode;
  onSubmit?: () => void;
  submitText?: string;
  onCancel?: () => void;
  cancelText?: string;
  isLoading?: boolean;
};

const Modal = (props: ModalProps) => {
  const {
    children,
    footer,
    onCancel,
    cancelText = 'Cancel',
    onSubmit,
    submitText = 'Submit',
    modalBodyProps,
    modalFooterProps,
    isLoading,
    ...rest
  } = props;

  const hasFooter = footer || onCancel || onSubmit;

  return (
    <BaseModal {...rest}>
      <ModalBody
        h={hasFooter ? 'calc(100% - 200px)' : undefined}
        overflowY="auto"
        {...modalBodyProps}
      >
        {children}
      </ModalBody>
      {hasFooter && (
        <ModalFooter {...modalFooterProps}>
          {footer}
          {(onCancel || onSubmit) && (
            <ButtonRow>
              {onCancel && (
                <SecondaryButton isDisabled={isLoading} onClick={onCancel}>
                  {cancelText}
                </SecondaryButton>
              )}
              {onSubmit && (
                <PrimaryButton isLoading={isLoading} onClick={onSubmit}>
                  {submitText}
                </PrimaryButton>
              )}
            </ButtonRow>
          )}
        </ModalFooter>
      )}
    </BaseModal>
  );
};

export default Modal;
