import { Box, FormHelperText, Text, VStack } from '@chakra-ui/react';
import { Formik } from 'formik';
import moment from 'moment';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { object, string } from 'yup';
import { PrimaryButton, OutlineButton } from '../../../../../components/Buttons';
import {
  Form,
  FormInputControl,
  FormMobisPicker,
  FormPhoneInputControl,
  FormSelectComponent,
} from '../../../../../components/forms';
import HelmetWrapper from '../../../../../components/HelmetWrapper';
import { CenteredLoadingIndicator } from '../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../components/ServerValidationBox';
import { API_KEY, getReactSelectStyle } from '../../../../../constants';
import {
  AuthClient,
  ICountry,
  MarketingCampaignsClient,
  SecurityClient,
  SignUpEmailDetails,
  TimeZone,
  UserExistsDetails,
} from '../../../../../generated';
import useTranslationComponent from '../../../../../hooks/useTranslationComponent';
import { mixTrack } from '../../../../../lib/mixpanel';
import { useCountriesAll } from '../../../../../services/countries/useCountriesAll';
import { useTimeZonesAll } from '../../../../../services/timeZones/queries/useTimeZonesAll';
import { httpRequest } from '../../../../../utils';
import { selectNearestTimeZone } from '../../../../../utils/selectNearestTimeZone';
import { SignupFlowEnum } from '../enums';
import { ISignupDetailsForm } from '../SignupModal';

type SignupProps = {
  controller: {
    setCurrentStage: Dispatch<SetStateAction<SignupFlowEnum>>;
    handleNext: (values: any, resultToken?: string) => void;
  };
  signupData: Omit<ISignupDetailsForm, 'referralCode'>;
  referralCode: string;
};

const Signup: FC<SignupProps> = (props) => {
  const { t } = useTranslationComponent(['signin', 'validation']);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [referralError, setReferralError] = useState<string>('');
  const [referralCodeValid, setReferralValid] = useState<string>('');
  const [initialValues, setInitialValues] = useState<ISignupDetailsForm>({
    email: props.signupData.email ?? '',
    firstName: props.signupData.firstName ?? '',
    lastName: props.signupData.lastName ?? '',
    smsNumber: props.signupData.smsNumber ?? '',
    referralCode: props.referralCode ?? '',
    countryID: props.signupData.countryID ?? '',
    birthDate: props.signupData.birthDate ?? '',
  });

  const [selectedCountry, setSelectedCountry] = useState<ICountry>();
  const { isLoadingCountries, countries = [] } = useCountriesAll();
  const { timeZones = [] } = useTimeZonesAll();

  const authClient = new AuthClient();
  const securityClient = new SecurityClient(authClient);
  const marketingCampaignsClient = new MarketingCampaignsClient(authClient);

  const signup = async (values: ISignupDetailsForm) => {
    try {
      mixTrack({
        eventName: 'login:patient_sign_up_clicked',
      });

      setIsLoading(true);

      const trimmedEmail = values.email.trim();
      const userExistsDetails = new UserExistsDetails();
      userExistsDetails.email = trimmedEmail;
      userExistsDetails.apiKey = API_KEY;
      const userExistsResult = await httpRequest(() =>
        securityClient.userExists(userExistsDetails),
      );

      setReferralValid('');
      setReferralError('');
      if (values.referralCode?.trim()) {
        const validateReferralCode = await httpRequest(() =>
          marketingCampaignsClient.validateReferralCode(values.referralCode.trim()),
        );
        if (!validateReferralCode.isValid) {
          setReferralValid('');
          setReferralError(t(validateReferralCode.message || 'Invalid or expired referral code'));
          setIsLoading(false);
          return;
        } else {
          setReferralError('');
        }
      }

      if (userExistsResult.isValid) {
        mixTrack({
          eventName: 'sign_up:patient_security_code_sent_failed',
          label: t('This email address is already in use'),
        });
        setError(t('This email address is already in use'));
      } else {
        const signUpEmailDetails = new SignUpEmailDetails();
        signUpEmailDetails.email = trimmedEmail;
        signUpEmailDetails.smsNumber = values.smsNumber ? values.smsNumber : undefined;
        const result = await httpRequest(() =>
          securityClient.sendEmailCode(signUpEmailDetails, API_KEY),
        );

        mixTrack({
          eventName: 'sign_up:patient_security_code_sent',
        });

        props.controller.handleNext(values, result.token);
      }
      setIsLoading(false);
    } catch (err) {
      mixTrack({
        eventName: 'sign_up:patient_security_code_sent_failed',
      });
      setError(err as string);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const initialize = async (countries: ICountry[], timeZones: TimeZone[]) => {
      const timeZone = await selectNearestTimeZone(timeZones);
      let country = countries.find((x) => x.name === timeZone?.country);

      if (!country || !country.countryID) {
        if (countries.length === 1) {
          country = countries[0];
        } else {
          country = countries.find((x) => x.cultureName === navigator.language);
        }
      }
      setSelectedCountry(country);
      setInitialValues((prev) => ({ ...prev, countryID: country?.countryID ?? '' }));
    };

    if (countries.length > 0 && timeZones.length > 0 && !initialValues) {
      initialize(countries, timeZones);
    }
  }, [countries, timeZones, initialValues]);

  return (
    <>
      <HelmetWrapper title={t('Sign Up')} />
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          email: string()
            .trim()
            .required(t('Email address is required', { ns: 'validation' }))
            .email(t('Must be a valid email address', { ns: 'validation' }))
            .max(256, t('Email address must be at most 256 characters', { ns: 'validation' })),
          firstName: string().required(t('First name is required', { ns: 'validation' })),
          lastName: string().required(t('Last name is required', { ns: 'validation' })),
          smsNumber: string().test(
            'required',
            t('Phone Number is required.', { ns: 'validation' }),
            function (value) {
              if ((value?.match(/\d/g)?.join('').length ?? 0) < 5) {
                return this.createError({
                  message: t('Phone Number must be at least 5 digits.', { ns: 'validation' }),
                  path: 'smsNumber',
                });
              }
              return true;
            },
          ),
          countryID: string().required(t('Country is required', { ns: 'validation' })),
          birthDate: string().required(t('Birth date is required', { ns: 'validation' })),
        })}
        onSubmit={(values) => signup(values)}
      >
        {() => (
          <Form error={error} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            {timeZones.length === 0 || countries.length === 0 || !initialValues || isLoading ? (
              <CenteredLoadingIndicator />
            ) : (
              <Box display="flex" flexDirection="column" flexGrow={1} mt={2}>
                <ServerValidationBox message={error} />

                <VStack gap={2} alignItems="stretch">
                  <FormInputControl
                    size="lg"
                    tabIndex={1}
                    autoComplete="on"
                    name="firstName"
                    type="text"
                    label={t('first name')}
                    placeholder={t('first name')}
                    id="firstNameId"
                    formControlProps={{
                      mb: '0',
                    }}
                  />
                  <FormInputControl
                    size="lg"
                    tabIndex={1}
                    autoComplete="on"
                    name="lastName"
                    type="text"
                    label={t('last name')}
                    placeholder={t('last name')}
                    id="lastNameId"
                    formControlProps={{
                      mb: '0',
                    }}
                  />
                  <FormInputControl
                    size="lg"
                    tabIndex={1}
                    autoComplete="on"
                    name="email"
                    type="email"
                    label={t('email address')}
                    placeholder={t('your email')}
                    id="emailId"
                    formControlProps={{
                      mb: '0',
                    }}
                  />
                  <FormSelectComponent
                    name="countryID"
                    label={t('select country')}
                    options={countries}
                    valueKey="countryID"
                    labelKey="name"
                    isLoading={isLoadingCountries}
                    styles={{
                      ...getReactSelectStyle(),
                      control: (styles, {}) => ({
                        ...styles,
                        minHeight: '48px',
                        backgroundColor: 'rgba(243, 243, 243, 0.50) !important',
                        borderColor: 'transparent',
                        borderRadius: '8px',
                        _focus: { boxShadow: 'none', borderColor: 'rgb(0, 3, 16)' },
                        _invalid: { boxShadow: 'none', borderColor: 'rgb(201, 0, 24)' },
                      }),
                    }}
                    formHelperText={
                      <FormHelperText fontSize="12px">
                        {t('This determines where your health records are stored')}.
                      </FormHelperText>
                    }
                  />
                  <FormMobisPicker
                    name="birthDate"
                    label={t('date of birth')}
                    max={moment().toDate()}
                    min={moment().add(-125, 'year').toDate()}
                    borderRadius="18px"
                    containerProps={{
                      width: '100%',
                      minHeight: '48px',
                      backgroundColor: 'rgba(243, 243, 243, 0.50) !important',
                      borderColor: 'transparent',
                      borderRadius: '8px',
                      _focus: { boxShadow: 'none', borderColor: 'rgb(0, 3, 16)' },
                      _invalid: { boxShadow: 'none', borderColor: 'rgb(201, 0, 24)' },
                    }}
                    formHelperText={
                      <FormHelperText fontSize="12px">
                        {t('This is needed to verify your identity')}.
                      </FormHelperText>
                    }
                  />
                  <FormPhoneInputControl
                    name="smsNumber"
                    label={t('phone number')}
                    placeholder={t('your phone')}
                    formControlProps={{
                      mb: '0',
                    }}
                    inputStyle={{
                      minHeight: '47px',
                      borderColor: 'rgb(0, 3, 16)',
                    }}
                    country={selectedCountry?.code ?? undefined}
                  />
                  <FormInputControl
                    size="lg"
                    tabIndex={1}
                    autoComplete="on"
                    name="referralCode"
                    type="text"
                    label={t('referral code (optional)')}
                    placeholder={t('enter referral code')}
                    id="referralCodeId"
                    formControlProps={{
                      mb: '0',
                    }}
                  />
                  {referralError && (
                    <Text fontSize="14px" color="red.500" w="100%">
                      {referralError}
                    </Text>
                  )}
                  {referralCodeValid && (
                    <Text fontSize="14px" color="green.500" w="100%">
                      {referralCodeValid}
                    </Text>
                  )}
                </VStack>

                <VStack mt="auto" pt={12} pb={1} gap={2}>
                  <PrimaryButton w="100%" tabIndex={3} size="lg" type="submit">
                    {t('next')}
                  </PrimaryButton>
                  <OutlineButton
                    w="100%"
                    tabIndex={3}
                    size="lg"
                    onClick={() => props.controller.setCurrentStage(SignupFlowEnum.Login)}
                  >
                    {t('sign in here')}
                  </OutlineButton>
                </VStack>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export { Signup };
