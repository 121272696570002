import { Box, BoxProps } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { CenteredLoadingIndicator } from '../LoadingIndicator';

interface Props {
  onBottomHit: () => void;
  isLoading: boolean;
  isInitLoading: boolean;
  hasMoreData: boolean;
  loadOnMount: boolean;
  isFetchingNextPage: boolean;
  height?: string;
  width?: any;
  children: any;
  hideLoading?: boolean;
  containerProps?: BoxProps;
}

const THRESHOLD = 50;

function isBottom(ref: React.RefObject<HTMLDivElement>, height: string | undefined) {
  if (!ref.current) {
    return false;
  }
  if (height) {
    const node = ref?.current;

    const heightDifference = node.scrollHeight - node.scrollTop - node.clientHeight;

    const bottom = heightDifference <= THRESHOLD;

    if (bottom) {
      return true;
    }
    return false;
  } else {
    return ref.current.getBoundingClientRect().bottom <= window.innerHeight;
  }
}

const InfiniteScroll = ({
  onBottomHit,
  isLoading,
  hasMoreData,
  loadOnMount,
  isInitLoading,
  isFetchingNextPage,
  height,
  children,
  hideLoading,
  width,
  containerProps,
}: Props) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (loadOnMount && initialLoad) {
      onBottomHit();
      setInitialLoad(false);
    }
  }, [onBottomHit, loadOnMount, initialLoad]);

  const onScroll = () => {
    if (!isLoading && hasMoreData && isBottom(contentRef, height) && height) {
      onBottomHit();
    }
  };

  // Disable because We don't want to execute onScroll when the entire page scrolls
  // useEffect(() => {
  //   const onScroll = () => {
  //     if (!isLoading && hasMoreData && isBottom(contentRef, height)) {
  //       onBottomHit();
  //     }
  //   };
  //   document.addEventListener('scroll', onScroll, false);
  //   return () => document.removeEventListener('scroll', onScroll, false);
  // }, [onBottomHit, isLoading, hasMoreData, height]);

  return (
    <Box
      ref={contentRef}
      height={height}
      width={width}
      overflowY={height ? 'auto' : 'initial'}
      onScroll={onScroll}
      px="2"
      {...containerProps}
    >
      {children}
      {(isFetchingNextPage || isInitLoading) && !hideLoading && <CenteredLoadingIndicator />}
    </Box>
  );
};

export default InfiniteScroll;
