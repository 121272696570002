import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PathParamKeysEnum } from '../../constants';
import { clearBreadcrumb } from '../../store/pageHeader/pageHeaderActions';
import { AppState } from '../../store/root-reducers';

export const ConnectedBreadcrumb = (key: PathParamKeysEnum) => {
  const dispatch = useDispatch();
  const breadcrumbs = useSelector((state: AppState) => state.pageHeaderState.breadcrumbs);
  const breadcrumbData = breadcrumbs.find((x) => x[0] === key);

  useEffect(() => {
    return () => {
      dispatch(clearBreadcrumb(key));
    };
  }, [dispatch, key]);

  if (breadcrumbData) {
    return <span>{breadcrumbData[1]}</span>;
  } else {
    return null;
  }
};
