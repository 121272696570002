import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SystemSettingsEnum } from '../../../constants';
import { AppState } from '../../../store/root-reducers';
import { getSystemSettingByName } from '../../../store/systemSettings/systemSettingsCreators';

const useLoadMimeTypes = () => {
  const dispatch = useDispatch();
  const systemSettings = useSelector((state: AppState) => state.systemSettingsState.systemSettings);
  const [hasSettings, setHasSettings] = useState<boolean>(false);
  const [allowedTypes, setAllowedTypes] = useState<string>('');

  useEffect(() => {
    if (systemSettings.findIndex((x) => x.name === SystemSettingsEnum.AllowedMimeTypes) === -1) {
      dispatch(getSystemSettingByName(SystemSettingsEnum.AllowedMimeTypes));
    } else {
      const allowedMimeTypes = systemSettings.find(
        (x) => x.name === SystemSettingsEnum.AllowedMimeTypes,
      );
      setAllowedTypes(allowedMimeTypes?.value.split('|').join(', ') ?? '');
      setHasSettings(true);
    }
  }, [dispatch, systemSettings]);

  return {
    allowedTypes,
    isLoading: !hasSettings,
  };
};

export default useLoadMimeTypes;
