import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  HStack,
  IconButton,
  Image,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { FC } from 'react';
import LanguageToggle from '../../../../components/LanguageToggle';
import useTranslationComponent from '../../../../hooks/useTranslationComponent';

type LinkProps = ChakraLinkProps;

type SidebarProps = {
  isOpen: boolean;
  onClose: () => void;
  t: any;
};

const Link = (props: LinkProps) => (
  <Box py={{ base: '4', lg: '0' }}>
    <ChakraLink
      fontSize="14px"
      color="rgb(0, 3, 16)"
      textDecoration="none"
      fontWeight="bold"
      borderBottom="2px solid transparent"
      px={{ base: '1', xl: '0.5rem' }}
      py="0.5rem"
      whiteSpace="nowrap"
      _hover={{
        textDecoration: 'none',
      }}
      // _active={{
      //   borderColor: '#38cabe',
      // }}
      // _focus={{
      //   borderBottom: '1px dotted #38cabe',
      // }}
      {...props}
    />
  </Box>
);

const LinkButton = (props: LinkProps) => (
  <Box py={{ base: '4', lg: '0' }}>
    <ChakraLink
      borderColor="rgb(0, 3, 16)"
      borderRadius="40px"
      fontSize="14px"
      color="rgb(0, 3, 16)"
      textDecoration="none"
      transition="all 0.25s ease-in"
      fontWeight="normal"
      _hover={{
        textDecoration: 'none',
      }}
      // _active={{
      //   borderColor: '#38cabe',
      // }}
      // _focus={{
      //   borderColor: '#38cabe',
      // }}
      {...props}
    />
  </Box>
);

const Sidebar: FC<SidebarProps> = (props) => (
  <Drawer placement="right" onClose={props.onClose} isOpen={props.isOpen}>
    <DrawerOverlay />
    <DrawerContent maxWidth="250px">
      <DrawerBody>
        <Box>
          <IconButton
            aria-label="Close sidebar"
            bgColor="transparent"
            _hover={{ bgColor: 'rgb(219, 217, 217)' }}
            onClick={props.onClose}
            p="2"
            borderRadius="full"
          >
            <CloseIcon color="rgb(0, 3, 16)" fontSize="16px" />
          </IconButton>
        </Box>
        <VStack>
          <Link href="https://www.outpost.health/patients">{props.t('Patients')}</Link>
          <Link href="https://www.outpost.health/provider">{props.t('Providers')}</Link>
          <Link href="https://www.outpost.health/neocarecard">{props.t('Neo Carecard')}</Link>
          <Link href="https://www.outpost.health/covid19">{props.t('Covid Testing')}</Link>
          <Link href="https://app.outpost.health/network">{props.t('Provider Search')}</Link>
          <Link href="https://www.outpost.health/homecare">{props.t('Homecare')}</Link>
          <LinkButton borderWidth="2px" mx="1" px="4" py="3" href="https://app.outpost.health/">
            {props.t('Get')}
            <Text as="strong" ml="1" fontWeight="bold">
              {props.t('Started')}
            </Text>
          </LinkButton>
        </VStack>
      </DrawerBody>
    </DrawerContent>
  </Drawer>
);

const PublicSearchHeader: FC = () => {
  const { t } = useTranslationComponent();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Box bgColor="white" position="sticky" top="0" zIndex={99}>
      <HStack
        maxW="75rem"
        mx="auto"
        py={{ base: '4', lg: '1rem' }}
        px={{ base: '4', lg: '1.5rem' }}
        justifyContent="space-between"
      >
        <Box mr="3" maxWidth="10.625rem">
          <ChakraLink href="https://www.outpost.health">
            <Image src="https://uploads-ssl.webflow.com/5ddece91214fad1536887f35/6183fb13a015382127d0b7aa_OutpostLogo.svg" />
          </ChakraLink>
        </Box>
        {!isMobile && (
          <HStack spacing="0.83rem">
            <Link href="https://www.outpost.health/patients">{t('Patients')}</Link>
            <Link href="https://www.outpost.health/provider">{t('Providers')}</Link>
            <Link href="https://www.outpost.health/neocarecard">{t('Neo Carecard')}</Link>
            <Link href="https://www.outpost.health/covid19">{t('Covid Testing')}</Link>
            <Link href="https://app.outpost.health/network">{t('Provider Search')}</Link>
            <Link href="https://www.outpost.health/homecare">{t('Homecare')}</Link>
            {/* <LinkButton
              borderWidth="2px"
              mx="1"
              px="4"
              py="3"
              href="https://app.outpost.health/"
              dangerouslySetInnerHTML={{ __html: t('Get Started') }}
            ></LinkButton> */}
            <LinkButton borderWidth="2px" mx="1" px="4" py="3" href="https://app.outpost.health/">
              {t('Get')}
              <Text as="strong" ml="1" fontWeight="bold">
                {t('Started')}
              </Text>
            </LinkButton>

            <LanguageToggle />
          </HStack>
        )}
        {isMobile && (
          <HStack>
            <LanguageToggle />
            <Box>
              <IconButton
                aria-label="Open sidebar"
                bgColor="transparent"
                _hover={{ bgColor: 'rgb(219, 217, 217)' }}
                onClick={onOpen}
                p="2"
                borderRadius="full"
              >
                <HamburgerIcon color="rgb(0, 3, 16)" fontSize="24px" />
              </IconButton>
            </Box>
          </HStack>
        )}
      </HStack>
      {isMobile && <Sidebar t={t} isOpen={isOpen} onClose={onClose} />}
    </Box>
  );
};

export { PublicSearchHeader };
