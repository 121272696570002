import { Box, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { Tag } from '../../../../../../../generated';
import { usePartnerSettings } from '../../../../../../../services/partners/usePartnerSettings';
import { OutpostTheme } from '../../../../../../../themes/outpost';

import { SelectedSymptomCard } from './SelectedSymptomCard';

type SelectedSymptomsContainerProps = {
  selectedSymptoms: Tag[];
  onRemoveSymptom?: (tagID: string) => void;
};

const SelectedSymptomsContainer: FC<SelectedSymptomsContainerProps> = (props) => {
  const hasSymptoms = props.selectedSymptoms.length !== 0;

  const { partnerSymptomsText } = usePartnerSettings();

  return (
    <Box>
      <Text textTransform="uppercase" mb="3" fontSize="12px" color="rgb(0, 3, 16)">
        {partnerSymptomsText ?? 'Symptoms'} (
        <Text fontWeight="semibold" as="span">
          5{' '}
        </Text>
        Maximum)
      </Text>
      {hasSymptoms ? (
        <Flex flexWrap="wrap">
          {props.selectedSymptoms.map((symptom) => (
            <Box p="1" key={symptom.tagID}>
              <SelectedSymptomCard
                symptom={symptom}
                onRemove={() => props.onRemoveSymptom?.(symptom.tagID)}
              />
            </Box>
          ))}
        </Flex>
      ) : (
        <Box>
          <Text color="rgb(0, 3, 16)" fontSize={OutpostTheme.FontSizes.body}>
            No {partnerSymptomsText ?? 'symptoms'} selected yet
          </Text>
        </Box>
      )}
    </Box>
  );
};

export { SelectedSymptomsContainer };
