import { Box, Divider, Stack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { Datepicker } from '@mobiscroll/react';
import { FC, useCallback, useEffect } from 'react';

import { InfiniteScrollNext } from '../../../../components/InfiniteScroll/InfiniteScrollNext';
import { CenteredLoadingIndicator } from '../../../../components/LoadingIndicator';
import { ConfirmationModal, HeaderSection } from '../../../../components/Modals';
import ServerValidationBox from '../../../../components/ServerValidationBox';
import {
  ConsultProviderSearchProviderAppointmentsResult,
  ConsultProviderSearchProviderResult,
} from '../../../../generated';
import { getStartOfDay } from '../../../../utils/momentUtils';

import { useAppointments } from '../../hooks/useAppointments';
import { UsePublicBookingFlowReturnType } from '../../hooks/usePublicBookingFlow';
import SignupModal from '../SignupFlow/SignupModal';

import { ProviderAppointmentCard } from './ProviderAppointmentCard';
import { ProviderCard } from './ProviderCard';

type AppointmentsProps = {
  controller: UsePublicBookingFlowReturnType;
};

const hasAppointments = (appointments?: ConsultProviderSearchProviderAppointmentsResult[]) => {
  if (!appointments) return false;
  return appointments.length > 0;
};

const today = getStartOfDay(new Date());

const Appointments: FC<AppointmentsProps> = (props) => {
  const controller = useAppointments(props.controller);
  const providers = controller.category as ConsultProviderSearchProviderResult[];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmationOpen,
    onOpen: onConfirmationOpen,
    onClose: onConfirmationClose,
  } = useDisclosure();

  const signInAction = useCallback(() => {
    onOpen();
  }, [onOpen]);

  const closeSignInAction = useCallback(() => {
    onClose();
    onConfirmationClose();
  }, [onClose, onConfirmationClose]);

  const onConfirmCancelContinue = useCallback(() => {
    closeSignInAction();
  }, [closeSignInAction]);

  useEffect(() => {
    if (!!controller.selectedProviderAppointmentData && !controller.isLoadingSearchResults) {
      signInAction();
    }
  }, [controller.isLoadingSearchResults, controller.selectedProviderAppointmentData, signInAction]);

  return (
    <VStack height="100%" alignItems="stretch" spacing="0">
      <HeaderSection
        heading="Select an appointment"
        containerProps={{
          mb: '3',
        }}
        onGoBack={props.controller.handleGoToPreviousBookingData}
      />
      <Box flex="1" overflowY="auto">
        <VStack
          maxW={{ md: '65%' }}
          width="100%"
          justifyContent="center"
          mx="auto"
          alignItems="stretch"
          height="100%"
          overflowY="auto"
        >
          <Box>
            <Datepicker
              controls={['calendar']}
              display="inline"
              touchUi={true}
              calendarSize={2}
              calendarType="week"
              value={controller.appointmentStartDate}
              onChange={(event) => controller.handleSetAppointmentRangeDate(event.value)}
              min={today}
            />
          </Box>
          <Box>
            <Divider borderColor="rgb(211, 211, 211)" my="2" />
          </Box>
          <Box flex="1" overflowY="auto">
            <Stack height="100%" overflowY="auto">
              {controller.isLoadingSearchResults ? (
                <Box flex="1" overflowY="auto" px="2" width="100%">
                  <CenteredLoadingIndicator />
                </Box>
              ) : controller.categoryError ? (
                <ServerValidationBox message={controller.categoryError} />
              ) : controller.categoryExist ? (
                <Box flex="1" overflowY="auto">
                  <InfiniteScrollNext
                    handleFetchMore={controller.fetchNextPage}
                    hasMore={controller.hasNextPage}
                    isLoading={controller.isFetchingNextPage}
                  >
                    <>
                      {providers?.map((provider) =>
                        hasAppointments(
                          provider.consultProviderSearchProviderAppointmentsResults,
                        ) ? (
                          <Box mb="4" key={provider.providerID}>
                            <ProviderCard provider={provider}>
                              <ProviderAppointmentCard
                                appointments={
                                  provider.consultProviderSearchProviderAppointmentsResults
                                }
                                onSelectAppointment={controller.handleNextStep(provider)}
                              />
                            </ProviderCard>
                          </Box>
                        ) : null,
                      )}
                    </>
                  </InfiniteScrollNext>
                </Box>
              ) : (
                <Box flex="1" overflowY="auto" px="2">
                  <Text textAlign="center" color="rgb(89, 101, 120)">
                    No Provider Appointments found
                  </Text>
                </Box>
              )}
            </Stack>
          </Box>
        </VStack>
      </Box>

      <SignupModal
        onLogin={() => {
          closeSignInAction();
          controller.onConfirmContinueBooking();
        }}
        isOpen={isOpen}
        onConfirmationOpen={onConfirmationOpen}
        onClose={closeSignInAction}
      />
      <ConfirmationModal
        isModalOpen={isConfirmationOpen}
        description="are you sure you want to exit? you will not be a able to join in the conversation if you exit"
        onConfirm={onConfirmationClose}
        onCancel={onConfirmCancelContinue}
        confirmButtonText="continue sign up"
        cancelButtonText="exit"
      />
      {/* <ConfirmationModal
        isModalOpen={
          !!controller.selectedProviderAppointmentData && !controller.isLoadingSearchResults
        }
        description="We will need to collect a bit more information about you to proceed with this appointment.  Once you sign in / sign up, the booking of this appointment will continue."
        onConfirm={controller.onConfirmContinueBooking}
        onCancel={controller.onCancelContinueBooking}
        confirmButtonText="Continue"
        cancelButtonText="Cancel"
      /> */}
    </VStack>
  );
};

export { Appointments };
