import {
  Box,
  BoxProps,
  Button,
  HStack,
  InputElementProps,
  InputGroupProps,
  InputProps,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { FastField, useFormikContext } from 'formik';
import moment from 'moment';
import React, { ReactNode, useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { createGlobalStyle } from 'styled-components';

import { CalendarIcon2, CalendarIcon4, TimeIcon } from '../../../assets/icons';

import { Datepicker, DisplayType, setOptions } from '@mobiscroll/react';

import {
  MbscDatepickerOptions,
  TDatepickerControl,
} from '@mobiscroll/react/dist/src/core/components/datepicker/datepicker';

import { useSelector } from 'react-redux';
import { AppState } from '../../../store/root-reducers';
import { BaseProps } from '../baseProps';
import { FormControl } from '../FormControl/FormControl';
import { SelectComponentUnStyled } from '../Selects';
import useTranslationComponent from '../../../hooks/useTranslationComponent';

export type DateTimePickerProps = BaseProps &
  ReactDatePickerProps & {
    customInputProps?: CustomInputProps;
    formHelperText?: ReactNode;
    isSearchable?: boolean;
    isTimePicker?: boolean;
  };

const GlobalDataPickerStyle = createGlobalStyle`
  .react-datepicker__close-icon {
    padding-right: 0;
  }

  .react-datepicker__day, .react-datepicker__day-name {
    margin: 0.15rem;
  }

  @media screen and (max-width: 480px) {
    .react-datepicker__month-container {
      min-height: 50vh !important;
    }
    .react-datepicker__time-list  {
      height: 20vh !important;
    }
  }
`;

const GlobalAppDataPickerStyle = createGlobalStyle`
  @media screen and (max-width: 480px) {
    .react-datepicker__month-container {
      min-height: 60vh !important;
    }
    .react-datepicker__time-list  {
      height: 20vh !important;
    }
  }
`;

type SelectComponentValueType = {
  label: string;
  value: string;
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getYearRange = (minYear: number, currentYear: number, maxYear: number) => {
  if (minYear === 0) {
    minYear = currentYear - 100;
  }
  if (maxYear === 0) {
    maxYear = currentYear + 1;
  }

  const startYearDistance = currentYear - minYear + 1; // +1 to make the range inclusive;
  const endYearDistance = maxYear - currentYear + 1; // +1 to make the range inclusive

  // We need to skip the duplicate current year
  const endYear = currentYear + 1;

  const yearsBeforeCurrent = [...Array(startYearDistance).keys()].map((i) => currentYear - i);
  const yearsAfterCurrent = [...Array(endYearDistance).keys()].map((i) => endYear + i);

  return [...yearsAfterCurrent.reverse(), ...yearsBeforeCurrent].filter(
    (year) => year >= minYear && year <= maxYear,
  );
};

const getDropDownOptions = (
  items: Array<string | number>,
  disableFrom?: number,
  upwards?: boolean,
) => {
  return items.map((item, index) => ({
    label: item,
    value: item,
    isDisabled: disableFrom ? (upwards ? index > disableFrom : index < disableFrom) : false,
  }));
};

// function range(size: number, startAt: number = 0) {
//   return [...Array(size).keys()].map((i) => i + startAt);
// }

const getYear = (date: Date) => {
  return date.getFullYear();
};

const getMonth = (date: Date) => {
  return date.getMonth();
};

const currentDate = new Date();

const currentYear = getYear(currentDate);

export const DateTimePicker = (props: DateTimePickerProps) => {
  const {
    label,
    formControlProps,
    customInputProps,
    autoComplete = 'off',
    name,
    timeFormat = 'p',
    dateFormat = 'P',
    yearDropdownItemNumber = 100,
    selected,
    formHelperText,
    isSearchable = false,
    isTimePicker,
    minDate,
    maxDate,
    ...rest
  } = props;

  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const isInApp = currentUser?.isInMobileView;
  const isMobile = useBreakpointValue({ base: true, md: false });

  const minYear = minDate ? getYear(minDate) : 0;
  const maxYear = maxDate ? getYear(maxDate) : 0;

  // const years = range(100, currentYear - 10).filter((x) => x >= minYear);
  const years = getYearRange(minYear, currentYear, maxYear);

  const maximumAllowedMonth = maxDate ? getMonth(maxDate) : 11; // 11 being the index of the last month;

  return (
    <FormControl name={name} label={label} {...formControlProps}>
      <HStack
        borderRadius="0.5rem"
        border={!isTimePicker || isSearchable ? '1px solid rgb(0, 3, 16)' : undefined}
        pr={!isTimePicker || isSearchable ? 2 : 0}
      >
        <GlobalDataPickerStyle />
        {isInApp && <GlobalAppDataPickerStyle />}
        <DatePicker
          calendarClassName={
            isMobile && !isTimePicker ? 'mobile-react-datepicker outpost-calendar' : ''
          }
          name={name}
          // onFocus={(e) => (e.target.readOnly = true)}
          autoComplete={autoComplete}
          minDate={minDate}
          maxDate={maxDate}
          showYearDropdown={true}
          showMonthDropdown={true}
          timeFormat={timeFormat}
          dateFormat={dateFormat}
          yearDropdownItemNumber={yearDropdownItemNumber}
          selected={moment.isMoment(selected) ? selected.toDate() : selected}
          className="datepicker-input"
          popperModifiers={{
            preventOverflow: {
              enabled: false,
            },
          }}
          customInput={!isTimePicker ? !isSearchable && <CustomDateInput /> : <CustomTimeInput />}
          {...rest}
          renderCustomHeader={({ date, changeYear, changeMonth }) => (
            <HStack justifyContent="center" px="2">
              <Box flex="1">
                <SelectComponentUnStyled
                  name="Year"
                  options={getDropDownOptions(years)}
                  onChange={(selected: SelectComponentValueType) =>
                    changeYear(parseInt(selected.value))
                  }
                  value={getDropDownOptions([getYear(date)])}
                  isClearable={false}
                  formControlProps={{
                    mb: '0',
                  }}
                />
              </Box>
              <Box flex="1">
                <SelectComponentUnStyled
                  name="Month"
                  options={getDropDownOptions(
                    months,
                    getYear(date) >= maxYear ? maximumAllowedMonth : undefined,
                    true,
                  )}
                  onChange={(selected: SelectComponentValueType) =>
                    changeMonth(months.indexOf(selected.value))
                  }
                  value={getDropDownOptions([months[getMonth(date)]])}
                  isClearable={false}
                  formControlProps={{
                    mb: '0',
                  }}
                />
              </Box>
            </HStack>
          )}
        />
        {(!isTimePicker || isSearchable) && <CalendarIcon2 fontSize="20px" color="#78819D" />}
      </HStack>
      {formHelperText}
    </FormControl>
  );
};

export type FormDateTimePickerProps = Omit<DateTimePickerProps, 'onChange'> & {
  extraSetFunction?: (val: Date | [Date, Date] | null) => void;
};

export const FormDateTimePicker = (props: FormDateTimePickerProps) => {
  const { name, formControlProps, extraSetFunction, ...rest } = props;
  const { setFieldValue } = useFormikContext();

  return (
    <FastField name={name}>
      {({ field, meta }) => (
        <DateTimePicker
          {...rest}
          {...field}
          formControlProps={{ ...meta, ...formControlProps }}
          selected={field.value}
          onChange={(val) => {
            if (moment(val as Date, 'MM.DD.YYYY HH:mm:ss').isValid()) {
              setFieldValue(field.name, val);
              if (extraSetFunction) {
                extraSetFunction(val);
              }
            }
          }}
        />
      )}
    </FastField>
  );
};

export type CustomInputProps = InputProps & {
  inputElementProps?: InputElementProps;
  inputGroupProps?: InputGroupProps;
  isTimePicker?: boolean;
};

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

export type MobisPickerProps = MbscDatepickerOptions &
  BaseProps & {
    onChange?: (val: Date | Date[]) => void;
    customControls?: TDatepickerControl[];
    formHelperText?: ReactNode;
    xsControls?: TDatepickerControl[];
    smallControls?: TDatepickerControl[];
    customDisplay?: DisplayType;
    xsDisplay?: DisplayType;
    smallDisplay?: DisplayType;
    customTouchUi?: boolean;
    xsTouchUi?: boolean;
    smallTouchUi?: boolean;
    containerProps?: BoxProps;
    label?: string;
    formError?: string;
    name: string;
    showOnlyText?: boolean;
    placeholder?: string;
    value?: Date | any[] | moment.Moment | undefined | string;
    extraSetFunction?: (val: Date | [Date, Date] | null) => void;
    borderRadius?: any;
    title?: string;
  };

const GlobalMobisStyle = createGlobalStyle`
  .mbsc-ios.mbsc-form-control-wrapper:before, .mbsc-ios.mbsc-form-control-wrapper:after {
    content: "";
    position: absolute;
    border: none;
    border-radius: 0px important;
  }
  .mbsc-textfield-wrapper {
    flex: 1 1 auto;
    z-index: 2;
  }
`;

export const MobisPicker = (props: MobisPickerProps) => {
  const { t } = useTranslationComponent();

  const {
    onChange,
    customControls,
    xsControls,
    smallControls,
    customDisplay,
    xsDisplay,
    smallDisplay,
    display = 'anchored',
    controls = ['calendar'],
    touchUi = true,
    customTouchUi,
    xsTouchUi,
    smallTouchUi,
    containerProps,
    label,
    formError,
    name,
    placeholder,
    extraSetFunction,
    value,
    formControlProps,
    showOnlyText,
    formHelperText,
    borderRadius,
    ...rest
  } = props;

  const [defaultValue, setDefaultValue] = useState(value);

  return (
    <>
      <Box>
        <GlobalMobisStyle />
        <FormControl mb={2} name={name} label={label} title={rest?.title} {...formControlProps}>
          <HStack
            borderRadius={borderRadius ? borderRadius : '0.5rem'}
            border={showOnlyText ? 'none' : '1px solid #000'}
            p={showOnlyText ? '0px' : '2px'}
            py={2}
            pr={2}
            justify="space-between"
            w="auto"
            position="relative"
            {...containerProps}
          >
            <Datepicker
              onChange={(ev) => {
                setDefaultValue(ev.value);
                if (onChange) onChange(ev.value);
                if (extraSetFunction) {
                  extraSetFunction(ev.value);
                }
              }}
              defaultValue={defaultValue}
              controls={controls}
              display={display}
              touchUi={touchUi}
              // inputComponent="input"
              inputProps={{
                placeholder: t(placeholder ? placeholder : 'Please Select...'),
              }}
              responsive={{
                xsmall: {
                  controls: xsControls ? xsControls : controls,
                  display: xsDisplay ? xsDisplay : display,
                  touchUi: xsTouchUi ? xsTouchUi : touchUi,
                },
                small: {
                  controls: smallControls ? smallControls : controls,
                  display: smallDisplay ? smallDisplay : display,
                  touchUi: smallTouchUi ? smallTouchUi : touchUi,
                },
                custom: {
                  // Custom breakpoint
                  breakpoint: 800,
                  controls: customControls ? customControls : controls,
                  display: customDisplay ? customDisplay : display,
                  touchUi: customTouchUi ? customTouchUi : touchUi,
                },
              }}
              {...rest}
            />
            {!showOnlyText && (
              <VStack position="absolute" right="2" top="50%" transform="translateY(-50%)">
                <CalendarIcon4 />
              </VStack>
            )}
          </HStack>
          {formHelperText}
        </FormControl>
      </Box>
    </>
  );
};

export const FormMobisPicker = (props: MobisPickerProps) => {
  const { name, formControlProps, ...rest } = props;
  const { setFieldValue } = useFormikContext();

  return (
    <FastField name={name} title={rest?.title}>
      {({ field, meta }) => (
        <MobisPicker
          {...rest}
          name={name}
          // {...field}
          formControlProps={{ ...meta, ...formControlProps }}
          value={field.value}
          onChange={(val) => {
            if (moment(val as Date, 'MM.DD.YYYY HH:mm:ss').isValid()) {
              setFieldValue(field.name, val);
            }
          }}
        />
      )}
    </FastField>
  );
};

// @ts-ignore
const CustomDateInput = (props) => {
  const { value, onClick } = props;
  return (
    <Button
      justifyContent="flex-start"
      w="100%"
      border="none"
      background="#FFF"
      onClick={onClick}
      variant="ghost"
      // @ts-ignore
      _focus={{
        background: '#FFF !important',
      }}
    >
      {value}
    </Button>
  );
};

// @ts-ignore
const CustomTimeInput = React.forwardRef(({ value, onClick }, ref) => (
  <Button
    w="100%"
    border="1px solid #000"
    background="#FFF"
    onClick={onClick}
    maxW="100px"
    // @ts-ignore
    ref={ref}
    rightIcon={<TimeIcon />}
  >
    {value}
  </Button>
));
