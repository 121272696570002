import reduceReducers from 'reduce-reducers';
import { AnyAction, combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  IPatient,
  IPHRPatient,
  IPHRSummaryDTO,
  PHRTrackerAnxietyDTO,
  PHRTrackerBloodGlucoseDTO,
  PHRTrackerBloodOxygenDTO,
  PHRTrackerBloodPressureDTO,
  PHRTrackerChinkunguniaDTO,
  PHRTrackerCholesterolDTO,
  PHRTrackerCovidDTO,
  PHRTrackerDengueDTO,
  PHRTrackerFetalDopplerDTO,
  PHRTrackerHCVDTO,
  PHRTrackerHeadCircumferenceDTO,
  PHRTrackerHemoglobinDTO,
  PHRTrackerHIVDTO,
  PHRTrackerLengthDTO,
  PHRTrackerMalariaDTO,
  PHRTrackerMoodDTO,
  PHRTrackerPulseDTO,
  PHRTrackerRapidPregnancyDTO,
  PHRTrackerRespiratoryDTO,
  PHRTrackerSleepDTO,
  PHRTrackerStepCounterDTO,
  PHRTrackerStressDTO,
  PHRTrackerSyphilisDTO,
  PHRTrackerTemperatureDTO,
  PHRTrackerTroponinDTO,
  PHRTrackerTyphoidDTO,
  PHRTrackerUricAcidDTO,
  PHRTrackerWaistCircumferenceDTO,
  PHRTrackerWeightHeightDTO,
} from '../../../generated';
import { updateOrAdd } from '../../../utils';
import { logoutActions } from '../../currentUser/currentUserActions';
import { ReduxPHRCategoryEnum } from '../../types';
import {
  clearPHRPatientErrorAction,
  clearPHRPatientTokenAction,
  getPHRPatientActions,
  getPHRPatientTokenActions,
  getPHRSummaryActions,
  phrActions,
  PhrActionTypes,
} from './phrActions';
import { isTrackerType } from './phrCreators';

const phrPatient = createReducer<IPHRPatientCombined | null, PhrActionTypes>(null)
  .handleAction(
    Object.keys(phrActions).reduce((acc: any, key) => {
      if (key.indexOf('getAll') !== -1) {
        acc.push(phrActions[key].success);
      }
      return acc;
    }, []),
    (state: IPHRPatientCombined, action) => {
      const { key, body } = action.payload;
      const phrKey = key.substring(0, 3).toLowerCase() + key.substring(3, key.length);
      if (state == null) {
        return state;
      } else {
        const newState = { ...state };
        newState.phr[phrKey] = body;
        return newState;
      }
    },
  )
  .handleAction(
    Object.keys(phrActions).reduce((acc: any, key) => {
      if (key.indexOf('getOne') !== -1) {
        acc.push(phrActions[key].success);
      }
      return acc;
    }, []),
    (state: IPHRPatientCombined, action) => {
      const { key, body } = action.payload;
      const phrKey = key.substring(0, 3).toLowerCase() + key.substring(3, key.length);
      let primaryKey = `${phrKey.substring(0, phrKey.length - 1)}ID`;
      if (phrKey.endsWith('ies')) {
        primaryKey = `${phrKey.substring(0, phrKey.length - 3)}yID`;
      }

      if (phrKey.toLowerCase() === ReduxPHRCategoryEnum.PHRAllergyDetails.toLowerCase()) {
        const phrAllergies = JSON.parse(JSON.stringify(state.phr.phrAllergies));
        phrAllergies[0].phrAllergyDetails = updateOrAdd(
          phrAllergies[0].phrAllergyDetails,
          primaryKey,
          body[primaryKey],
          body,
        );
        const newState = { ...state };
        newState.phr.phrAllergies = phrAllergies;
        return newState;
      }

      if (isTrackerType(phrKey)) {
        primaryKey = 'phrTrackerDetailID';
      }

      if (state == null || !state.phr[phrKey]) {
        return state;
      } else {
        const newState = { ...state };
        newState.phr[phrKey] = updateOrAdd(state.phr[phrKey], primaryKey, body[primaryKey], body);
        return newState;
      }
    },
  )
  .handleAction(
    Object.keys(phrActions).reduce((acc: any, key) => {
      if (key.indexOf('delete') !== -1) {
        acc.push(phrActions[key].success);
      }
      return acc;
    }, []),
    (state: IPHRPatientCombined, action) => {
      const { key, body } = action.payload;
      const phrKey = key.substring(0, 3).toLowerCase() + key.substring(3, key.length);
      let primaryKey = `${phrKey.substring(0, phrKey.length - 1)}ID`;
      if (phrKey.endsWith('ies')) {
        primaryKey = `${phrKey.substring(0, phrKey.length - 3)}yID`;
      }

      if (isTrackerType(phrKey)) {
        primaryKey = 'phrTrackerDetailID';
      }
      if (state == null) {
        return state;
      } else {
        const newState = { ...state };
        newState.phr[phrKey] = state.phr[phrKey]?.filter((x) => x[primaryKey] !== body);
        return newState;
      }
    },
  )
  .handleAction([getPHRPatientActions.request], () => null)
  .handleAction([getPHRPatientActions.success], (_state, action) => action.payload)
  .handleAction([logoutActions], () => initialState.phrPatient);

const error = createReducer<string, PhrActionTypes>('')
  .handleAction(
    Object.keys(phrActions).map((key) => phrActions[key].failure),
    (_state, action) => action.payload,
  )
  .handleAction([getPHRPatientActions.failure], (_state, action) => action.payload || '')
  .handleAction([clearPHRPatientErrorAction], () => '');

const isPhrLoading = createReducer<boolean, PhrActionTypes>(false)
  .handleAction([...Object.keys(phrActions).map((key) => phrActions[key].request)], () => true)
  .handleAction(
    [
      ...Object.keys(phrActions).map((key) => phrActions[key].success),
      ...Object.keys(phrActions).map((key) => phrActions[key].failure),
    ],
    () => false,
  );

const isLoading = createReducer<boolean, AnyAction>(false)
  .handleAction([getPHRPatientActions.request, getPHRPatientTokenActions.request], () => true)
  .handleAction(
    [
      getPHRPatientActions.failure,
      getPHRPatientActions.success,
      getPHRPatientTokenActions.success,
      getPHRPatientTokenActions.failure,
    ],
    () => false,
  );

const isPhrSummaryLoading = createReducer<boolean, AnyAction>(false)
  .handleAction([getPHRSummaryActions.request], () => true)
  .handleAction([getPHRSummaryActions.success, getPHRSummaryActions.failure], () => false);

const token = createReducer<string, AnyAction>('')
  .handleAction([getPHRPatientTokenActions.success], (_state, action) => action.payload)
  .handleAction([getPHRPatientTokenActions.failure], () => '')
  .handleAction([clearPHRPatientTokenAction], () => '');

const phrSummary = createReducer<IPHRSummaryDTO[] | null, AnyAction>(null)
  .handleAction([getPHRSummaryActions.request], () => null)
  .handleAction([getPHRSummaryActions.success], (_state, action) => action.payload);

export interface IPHRPatientState {
  error: string;
  isLoading: boolean;
  isPhrLoading: boolean;
  isPhrSummaryLoading: boolean;
  phrPatient: IPHRPatientCombined | null;
  phrSummary: IPHRSummaryDTO[] | null;
  token: string;
}

export interface IPHRPatientCombined {
  patient: IPatient;
  phr: IPHRPatientEnhanced;
}

export interface IPHRPatientEnhanced extends IPHRPatient {
  phrBloodPressures?: PHRTrackerBloodPressureDTO[];
  phrBloodGlucoses?: PHRTrackerBloodGlucoseDTO[];
  phrBloodOxygens?: PHRTrackerBloodOxygenDTO[];
  phrPulses?: PHRTrackerPulseDTO[];
  phrRespiratories?: PHRTrackerRespiratoryDTO[];
  phrTemperatures?: PHRTrackerTemperatureDTO[];
  phrStepCounters?: PHRTrackerStepCounterDTO[];
  phrHCVs?: PHRTrackerHCVDTO[];
  phrUricAcids?: PHRTrackerUricAcidDTO[];
  phrHemoglobins?: PHRTrackerHemoglobinDTO[];
  phrCholesterols?: PHRTrackerCholesterolDTO[];
  phrFetalDopplers?: PHRTrackerFetalDopplerDTO[];
  phrTyphoids?: PHRTrackerTyphoidDTO[];
  phrDengues?: PHRTrackerDengueDTO[];
  phrMalarias?: PHRTrackerMalariaDTO[];
  phrChinkungunias?: PHRTrackerChinkunguniaDTO[];
  phrTroponins?: PHRTrackerTroponinDTO[];
  phrSyphilises?: PHRTrackerSyphilisDTO[];
  phrHIVs?: PHRTrackerHIVDTO[];
  phrRapidPregnancies?: PHRTrackerRapidPregnancyDTO[];
  phrHeadCircumferences?: PHRTrackerHeadCircumferenceDTO[];
  phrWaistCircumferences?: PHRTrackerWaistCircumferenceDTO[];
  phrWeightHeights?: PHRTrackerWeightHeightDTO[];
  phrLengths?: PHRTrackerLengthDTO[];
  phrMoods?: PHRTrackerMoodDTO[];
  phrAnxieties?: PHRTrackerAnxietyDTO[];
  phrStresses?: PHRTrackerStressDTO[];
  phrSleeps?: PHRTrackerSleepDTO[];
  phrCovids?: PHRTrackerCovidDTO[];
}

const phrPatientReducers = combineReducers<IPHRPatientState>({
  phrPatient,
  phrSummary,
  error,
  isLoading,
  isPhrLoading,
  isPhrSummaryLoading,
  token,
});

const initialState: IPHRPatientState = {
  phrPatient: null,
  phrSummary: null,
  error: '',
  isLoading: false,
  isPhrLoading: false,
  isPhrSummaryLoading: false,
  token: '',
};

const reduce = reduceReducers(initialState, phrPatientReducers);

export default function phrPatientReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IPHRPatientState = initialState,
  action,
): IPHRPatientState {
  // @ts-ignore
  return reduce(state, action);
}
