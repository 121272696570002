import React from 'react';
import useProviderConsultContext from '../../hooks/useProviderConsultContext';

import { LargeStyledTextArea } from '../Styled';

const Objective = (): JSX.Element => {
  const {
    setObjective,
    objective,
    validationMessages,
    quickMenuDescription,
    debouncedUpdateSOAPNotes,
  } = useProviderConsultContext();

  return (
    <LargeStyledTextArea
      name="objective"
      onChange={(e) => {
        setObjective(e.target.value);
        debouncedUpdateSOAPNotes();
      }}
      value={quickMenuDescription ? quickMenuDescription : objective || ''}
      label="Objective"
      formControlProps={{ error: validationMessages?.objective }}
    />
  );
};

export { Objective };
