import { useCallback, useEffect, useRef, useState } from 'react';
import { useMixTrack } from '../../lib/mixpanel/mixpanelUtils';

type UseGeolocation = {
  watchPosition?: boolean;
};

const useGeolocation = (props: UseGeolocation = {}) => {
  const { watchPosition } = props;

  const [position, setPosition] = useState<GeolocationPosition>();
  const [positionError, setPositionError] = useState<GeolocationPositionError>();

  const watchPositionIdRef = useRef<number>();

  const { mixTrack } = useMixTrack();

  const getCurrentPosition = useCallback(() => {
    setPositionError(undefined);
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          mixTrack({
            eventName: 'location:location_access_successful',
          });

          setPosition(position);
        },
        (error) => {
          mixTrack({
            eventName: 'location:location_access_failed',
            label: JSON.stringify(error),
          });
          setPositionError(error);
        },
      );
    }
  }, [mixTrack]);

  const clearPosition = useCallback(() => {
    setPosition(undefined);
  }, []);

  const clearPositionError = useCallback(() => {
    setPositionError(undefined);
  }, []);

  const startWatchPosition = useCallback(() => {
    setPositionError(undefined);
    if ('geolocation' in navigator) {
      watchPositionIdRef.current = navigator.geolocation.watchPosition(
        (position) => {
          setPosition(position);
        },
        (error) => {
          setPositionError(error);
        },
      );
    }
  }, []);

  const stopWatchPosition = useCallback(() => {
    if ('geolocation' in navigator && watchPositionIdRef.current) {
      navigator.geolocation.clearWatch(watchPositionIdRef.current);
    }

    watchPositionIdRef.current = undefined;
  }, []);

  useEffect(() => {
    if (watchPosition) {
      startWatchPosition();
    }

    return () => {
      if (watchPosition) {
        stopWatchPosition();
      }
    };
  }, [startWatchPosition, watchPosition, stopWatchPosition]);

  return {
    position,
    positionError,
    watchPositionId: watchPositionIdRef.current,
    getCurrentPosition,
    clearPosition,
    startWatchPosition,
    stopWatchPosition,
    clearPositionError,
  };
};

export { useGeolocation };
