import { Box, Center, HStack, ModalBody, ModalFooter, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';

import { StarFilledIcon } from '../../../../../assets/icons';
import { ButtonRow, PurpleButton } from '../../../../../components/Buttons';
import { CenteredLoadingIndicator } from '../../../../../components/LoadingIndicator';
import { BaseModal } from '../../../../../components/Modals/BaseModal';
import ServerValidationBox from '../../../../../components/ServerValidationBox';
import { Tag } from '../../../../../components/TagComponent';
import { AddressBook } from '../../../../../generated';

import { useSelectLabOptions } from '../../hooks/useSelectLabOptions';

import { PharmacyCard as LabCard } from '../../../consultMedications/components/SelectPharmacyOptions/PharmacyCard';
import { SelectPharmacyOptionsCard as SelectLabOptionsCard } from '../../../consultMedications/components/SelectPharmacyOptions/SelectPharmacyOptionsCard';
import useTranslationComponent from '../../../../../hooks/useTranslationComponent';

type SelectLabOptionsModalProps = {
  isModalOpen: boolean;
  onCloseModal: () => void;
  patientID: string;
  onOpenAddressBook: () => void;
  selectedAddress?: AddressBook;
  isLoading?: boolean;
  onChangeAddress: (value: AddressBook) => void;
  preSelectedAddressBook?: AddressBook;
};

const SelectLabOptionsModal: FC<SelectLabOptionsModalProps> = (props) => {
  const { t } = useTranslationComponent();

  const controller = useSelectLabOptions({
    onCloseModal: props.onCloseModal,
    patientID: props.patientID,
    selectedAddress: props.selectedAddress,
    onChangeAddress: props.onChangeAddress,
    isLoading: props.isLoading,
    preSelectedAddressBook: props.preSelectedAddressBook,
  });

  return (
    <BaseModal
      isOpen={props.isModalOpen}
      size="xl"
      onDismiss={controller.isLoadingPreferredLab ? undefined : props.onCloseModal}
      title={
        <Box mb="3" pr="4" width="100%">
          <Text textTransform="uppercase" color="#3E1191" mb="2">
            {t(
              props.preSelectedAddressBook
                ? "Patient's Diagnostics Facility"
                : "Select Patient's Preferred Diagnostics Facility",
            )}
          </Text>
          <Text fontSize="14px" color="#3E1191">
            {t('This is where the orders will be sent to')}
          </Text>
        </Box>
      }
    >
      {controller.preferredLabError ? (
        <ServerValidationBox message={controller.preferredLabError} />
      ) : controller.isLoadingPreferredLab ? (
        <CenteredLoadingIndicator />
      ) : (
        <VStack height="100%" alignItems="stretch" overflowY="auto">
          <ModalBody flex="1" overflowY="auto">
            <VStack overflowY="auto" height="100%" width="100%" spacing="4">
              <SelectLabOptionsCard containerProps={{ _hover: undefined, minH: '100px' }}>
                <VStack
                  alignItems="stretch"
                  height="100%"
                  width="100%"
                  justifyContent={controller.hasSelectedLab ? 'flex-start' : 'center'}
                >
                  {controller.hasSelectedLab && (
                    <HStack justifyContent="flex-end">
                      <Tag
                        text={t(
                          `${
                            controller.isShowingPreferredLab ? 'Preferred' : 'Selected'
                          } Diagnostics Facility`,
                        )}
                        iconComponent={
                          controller.isShowingPreferredLab && <StarFilledIcon fontSize="12px" />
                        }
                        textProps={{ fontSize: '12px' }}
                        containerProps={{
                          bgColor: controller.isShowingPreferredLab
                            ? 'rgb(255, 194, 0)'
                            : '#b1e8e3',
                        }}
                      />
                    </HStack>
                  )}
                  <Center height="100%" justifyContent="flex-start">
                    {props.selectedAddress && props.selectedAddress.name ? (
                      <LabCard
                        imageID={props.selectedAddress?.imageDetailID}
                        name={props.selectedAddress?.name}
                      />
                    ) : (
                      <Text fontSize="14px">{t('No Diagnostics Facility Selected')}</Text>
                    )}
                  </Center>
                </VStack>
              </SelectLabOptionsCard>

              {!props.preSelectedAddressBook && (
                <SelectLabOptionsCard
                  containerProps={{
                    role: 'button',
                    onClick: props.onOpenAddressBook,
                  }}
                  showRightArrow
                >
                  <Center height="100%" justifyContent="flex-start">
                    <Text fontSize="14px">
                      {t(
                        `Select a ${
                          controller.hasSelectedLab ? 'Different ' : ''
                        }Diagnostics Facility`,
                      )}
                    </Text>
                  </Center>
                </SelectLabOptionsCard>
              )}

              {!controller.hasSelectedLab && (
                <SelectLabOptionsCard
                  containerProps={{
                    role: 'button',
                    onClick: controller.toggleSelectMedicationFirst,
                  }}
                  isSelected={controller.selectMedicationFirst}
                  showCheckbox
                  checkboxProps={{
                    isChecked: controller.selectMedicationFirst,
                  }}
                >
                  <Center height="100%" justifyContent="flex-start">
                    <Box>
                      <Text fontSize="14px">{t('Select Orders First')}</Text>
                      <Text fontSize="14px">
                        ({t('You may choose a Facility to send to later')})
                      </Text>
                    </Box>
                  </Center>
                </SelectLabOptionsCard>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter pt="0">
            <ButtonRow>
              <PurpleButton
                isDisabled={controller.isContinueDisabled}
                onClick={controller.onContinue}
              >
                {t('Continue')}
              </PurpleButton>
            </ButtonRow>
          </ModalFooter>
        </VStack>
      )}
    </BaseModal>
  );
};

export { SelectLabOptionsModal };
