import { Box, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { Avatar } from '../../../../../../../components/common/Avatar';
import { ThumbnailTypeEnum } from '../../../../../../../constants';
import useTranslationComponent from '../../../../../../../hooks/useTranslationComponent';
import { usePartnerSettings } from '../../../../../../../services/partners/usePartnerSettings';
import { OutpostTheme } from '../../../../../../../themes/outpost';
import { getUserThumbnail } from '../../../../../../../utils/getUserThumbnail';

import { RatingCard } from './RatingCard';

type ProviderClinicCardProps = {
  providerName?: string;
  providerImageDetailId?: string;
  providerRating?: string;
  clinicName?: string;
  clinicImageDetailId?: string;
  providerSpecialtyTag?: string;
  providerType?: string;
  fullProviderDetails?: boolean;
};

const ProviderClinicCard: FC<ProviderClinicCardProps> = (props) => {
  const { t } = useTranslationComponent(['heal']);

  const { partnerBookingAppointmentsClinic, partnerBookingAppointmentsProvider } =
    usePartnerSettings();

  return (
    <Box
      bgColor="white"
      borderRadius="xl"
      border="1px solid transparent"
      transition="border-color 0.3s ease"
      p={{ base: 4, md: 6 }}
      // _hover={{ borderColor: 'rgb(0, 3, 16)' }}
      width="100%"
    >
      {props.providerName && (
        <>
          <HStack spacing="3">
            <Box position="relative">
              <Avatar
                size={OutpostTheme.Avatar.Size.Large}
                src={getUserThumbnail(props.providerImageDetailId)}
              />
              {props.providerRating && <RatingCard rating={props.providerRating} />}
            </Box>
            <Box flex={1}>
              {!props.fullProviderDetails && (
                <Text fontSize="12px" fontWeight="medium" color="rgb(65, 79, 101)" mb="1">
                  {t(partnerBookingAppointmentsProvider ?? 'PROVIDER')}
                </Text>
              )}
              <Text
                fontSize={OutpostTheme.FontSizes.body}
                fontWeight="semibold"
                color="rgb(0, 3, 16)"
              >
                {props.providerName}
              </Text>
              {props.fullProviderDetails && (
                <Box>
                  <Text fontSize={OutpostTheme.FontSizes.body} color="rgb(0, 3, 16)" mb="1">
                    {t(props.providerType)}
                  </Text>
                  {props.providerSpecialtyTag && (
                    <Box
                      bgColor="rgb(235, 235, 235)"
                      px="2"
                      py="0.5"
                      borderRadius="sm"
                      maxW="max-content"
                    >
                      <Text color="rgb(0, 3, 16)" fontSize={OutpostTheme.FontSizes.body}>
                        {t(props.providerSpecialtyTag)}
                      </Text>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </HStack>
          <Box borderBottom="1px solid rgb(211, 211, 211)" my="3" />
        </>
      )}
      <HStack spacing="3">
        <Box position="relative">
          <Avatar
            size={OutpostTheme.Avatar.Size.Large}
            src={getUserThumbnail(props.clinicImageDetailId, ThumbnailTypeEnum.Clinic)}
          />
        </Box>
        <Box flex={1}>
          <Text fontSize="12px" fontWeight="medium" color="rgb(65, 79, 101)" mb="1">
            {t(partnerBookingAppointmentsClinic ?? 'CLINIC')}
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body} fontWeight="medium" color="rgb(0, 3, 16)">
            {props.clinicName}
          </Text>
        </Box>
      </HStack>
    </Box>
  );
};

export { ProviderClinicCard };
