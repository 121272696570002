import { PatientOrder } from '../../../../../../../../generated';
import { usePatientOrdersPut } from '../../../../../../../../services/patientOrders/mutations/usePatientOrdersPut';

import useCreateOrderContext from '../../../hooks/useCreateOrderContext';

export const useQuestionair = () => {
  const { controller, patientOrder, goToNextStep } = useCreateOrderContext();
  const patientOrdersPut = usePatientOrdersPut();

  const onSubmitForm = async (patientOrder: PatientOrder) => {
    try {
      controller.handleUpdateIsLoading(true);
      // await patientOrdersPut.mutateAsync(patientOrder);

      // const refreshedPatientOrder = await httpRequest(() =>
      //   new PatientOrdersClient(new AuthClient()).patientOrdersGet(patientOrder.patientOrderID),
      // );

      // setPatientOrder(refreshedPatientOrder);

      const partialPropertiesToUpdate: Partial<PatientOrder> = {
        reasonForExam: patientOrder.reasonForExam,
        patientOrderQuestion: patientOrder.patientOrderQuestion,
        patientOrderQuestionID: patientOrder.patientOrderQuestionID,
      };

      goToNextStep();

      await controller.handleUpdatePatientPartialProperty(partialPropertiesToUpdate);
    } catch (err) {
      // eat error
    } finally {
      controller.handleUpdateIsLoading(false);
    }
  };

  return {
    selectedOrderType: controller.selectedOrder,
    patientOrder,
    onSubmitForm,
    goToNextStep,
    isLoading: controller.isLoading,
    error: patientOrdersPut.error,
  };
};
