import { Textarea, TextareaProps } from '@chakra-ui/react';
import { FastField } from 'formik';
import React from 'react';
import { GLOBAL_MAX_TEXT_LENGTH } from '../../../constants';
import { BaseProps } from '../baseProps';
import { FormControl } from '../FormControl/FormControl';

export type TextareaControlProps = TextareaProps & BaseProps;

export const TextArea = (props: TextareaControlProps) => {
  const {
    name,
    label,
    maxLength = GLOBAL_MAX_TEXT_LENGTH,
    formControlProps,
    rows = 3,
    ...rest
  } = props;
  const { error, touched = !!error, ...formControlPropsRest } = formControlProps || {};

  return (
    <FormControl label={label} error={error} touched={touched} {...formControlPropsRest}>
      <Textarea
        id={name}
        maxLength={maxLength}
        minHeight="45px"
        backgroundColor="rgb(247, 249, 252)"
        // borderColor="rgba(12, 52, 104, 0.57)"
        borderColor="rgb(0, 3, 16)"
        borderRadius="8px"
        _focus={{ boxShadow: 'none', borderColor: 'rgb(0, 3, 16)' }}
        _invalid={{ boxShadow: 'none', borderColor: 'rgb(201, 0, 24)' }}
        rows={rows}
        {...rest}
      />
    </FormControl>
  );
};

export type FormTextareaControlProps = TextareaControlProps;

export const FormTextArea = (props: FormTextareaControlProps) => {
  const { name, formControlProps, ...rest } = props;

  return (
    <FastField name={name}>
      {({ field, meta }) => (
        <TextArea
          css={cssScrollbar}
          {...rest}
          formControlProps={{ ...meta, ...formControlProps }}
          {...field}
        />
      )}
    </FastField>
  );
};

const cssScrollbar = {
  '::-webkit-scrollbar': { width: '8px' },
  '::-webkit-scrollbar-track': {
    borderRadius: '0.5rem',
    marginTop: '8px',
    marginBottom: '8px',
  },
  '::-webkit-scrollbar-thumb': {
    background: '#ccc',
    borderRadius: '0.5rem',
  },
  '::-webkit-scrollbar-corner': {
    borderRadius: '0.5rem',
  },
};
