import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { BorderRadius, SpacingRelated } from '../../../constants';
import { formatBytes } from '../../../utils/bytesToSize';
import { Paragraph } from '../../common/Paragraph';
import { ProgressBar } from '../../ProgressBar/ProgressBar';
import { FileUploadStatusEnum, IFileUpload } from '../useFileUploader/useFileUploader';

interface IProps {
  file: IFileUpload;
  onDelete?: (name: string) => void;
}

export const FileListItem = ({ file, onDelete }: IProps) => (
  <FileCard>
    <FileIcon icon={['fal', 'file-alt']} size="2x" />
    <FileInfo>
      <FileInfoText>{file.name}</FileInfoText>
      {file.uploadStatus === FileUploadStatusEnum.Uploading && (
        <ProgressBar percentage={file.progress} />
      )}
      {file.uploadStatus === FileUploadStatusEnum.Failed && <ErrorText>{file.error}</ErrorText>}
      <BottomRow>
        <FileInfoText secondary>Size: {formatBytes(file.size, 1)}</FileInfoText>
        <FileInfoText secondary>
          Format: {(file.type || '').indexOf('/') > -1 ? file.type.split('/')[1].toUpperCase() : ''}
        </FileInfoText>
      </BottomRow>
    </FileInfo>
    <StatusIconWrapper>
      {file.uploadStatus === FileUploadStatusEnum.Uploading && (
        <ProgressPercentage small>{file.progress}%</ProgressPercentage>
      )}
      {onDelete &&
        (file.uploadStatus === FileUploadStatusEnum.Completed ||
          file.uploadStatus === FileUploadStatusEnum.Failed) && (
          <DeleteIcon onClick={() => onDelete(file.name)} icon={['far', 'trash-alt']} size="lg" />
        )}
    </StatusIconWrapper>
  </FileCard>
);

const ProgressPercentage = styled(Paragraph)`
  margin: 0;
  font-size: 12px;
`;

const StatusIconWrapper = styled.div`
  width: 44px;
  margin-left: 15px;
`;

const DeleteIcon = styled(FontAwesomeIcon)`
  &:hover {
    cursor: pointer;
  }

  color: ${(props) => props.theme.Colors.Danger};
`;

const ErrorText = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.Colors.Danger};
  font-weight: 500;
`;

interface ITextProps {
  readonly secondary?: boolean;
}

const FileInfoText = styled(Paragraph)<ITextProps>`
  min-width: 80px;
  ${(props) => !props.secondary && 'font-weight: 700'};
  font-size: 12px;
  margin: 0;
  color: ${(props) =>
    props.secondary ? props.theme.FontColors.Secondary : props.theme.FontColors.Primary};
`;

const FileIcon = styled(FontAwesomeIcon)`
  font-size: 34px;
  color: #b3b6bd;
  margin-right: ${SpacingRelated.HalfRegular};
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FileCard = styled.div`
  background-color: white;
  border-radius: ${BorderRadius.Regular};
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: ${SpacingRelated.HalfRegular};
  width: 100%;
`;

const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
