import { Box, Text, Wrap, WrapItem } from '@chakra-ui/react';
import moment from 'moment';
import { FC } from 'react';

import {
  ConsultProviderSearchProviderAppointmentsResult,
  ProviderAppointment,
} from '../../../../generated';

type StoreAppointmentsType = {
  storeName?: string;
  storeID?: string;
  storeItems: {
    storeItemName?: string;
    storeItemID?: string;
    appointments?: ProviderAppointment[];
  }[];
};

type GroupedStoreAppointmentsType = Record<string, StoreAppointmentsType>;

type ProviderAppointmentProps = {
  appointments?: ConsultProviderSearchProviderAppointmentsResult[];
  onSelectAppointment: (appointment: ProviderAppointment, storeItemID?: string) => void;
};

const getGroupedAppointments = (
  storeAppointments?: ConsultProviderSearchProviderAppointmentsResult[],
) => {
  if (!storeAppointments) return undefined;

  const groupedAppointments: GroupedStoreAppointmentsType = {};

  storeAppointments.forEach((storeAppointment) => {
    if (storeAppointment.storeID) {
      const storeAppointmentData = {
        storeItemName: storeAppointment.storeItemName,
        storeItemID: storeAppointment.storeItemID,
        appointments: storeAppointment.appointments,
      };

      if (groupedAppointments[storeAppointment.storeID]) {
        groupedAppointments[storeAppointment.storeID].storeItems.push(storeAppointmentData);
      } else {
        groupedAppointments[storeAppointment.storeID] = {
          storeName: storeAppointment.storeName,
          storeID: storeAppointment.storeID,
          storeItems: [storeAppointmentData],
        };
      }
    }
  });

  return Object.values(groupedAppointments);
};

const ProviderAppointmentCard: FC<ProviderAppointmentProps> = (props) => {
  const groupedAppointments = getGroupedAppointments(props.appointments);

  return (
    <Box>
      {groupedAppointments?.map((groupedAppointment) => (
        <Box mb="5" key={groupedAppointment.storeID}>
          <Text fontSize="14px" fontWeight="bold" pb="2" position="sticky" top="0" bgColor="white">
            {groupedAppointment.storeName}
          </Text>
          {groupedAppointment.storeItems.map((storeAppointment) => (
            <Box mb="4" key={storeAppointment.storeItemID}>
              <Text
                fontSize="14px"
                px="2"
                pb="1"
                fontWeight="bold"
                position="sticky"
                top="6"
                bgColor="white"
              >
                {storeAppointment.storeItemName}
              </Text>
              <Wrap justifyContent="center" spacing="3" p="2">
                {storeAppointment.appointments?.map((appointment) => (
                  <WrapItem key={appointment.appointmentID}>
                    <Box
                      role="button"
                      border={`1px solid rgb(211, 211, 211)`}
                      color={'rgb(0, 3, 16)'}
                      bgColor={'white'}
                      px="3"
                      py="1"
                      borderRadius="100px"
                      width="105px"
                      onClick={() =>
                        props.onSelectAppointment(appointment, storeAppointment.storeItemID)
                      }
                    >
                      <Text fontSize="14px" textAlign="center">
                        {moment(appointment.startDate).format('hh:mm A')}
                      </Text>
                    </Box>
                  </WrapItem>
                ))}
              </Wrap>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export { ProviderAppointmentCard };
