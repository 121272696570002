import { getRoundDecimalUpTo } from './getRoundDecimalUpTo';

const decimalPrecision = 1;

const getFormattedDistance = (distanceInMeters: number) => {
  const distanceInKm = distanceInMeters / 1000;
  const distanceInMiles = distanceInMeters / 1609.344;

  return {
    km: getRoundDecimalUpTo(distanceInKm, decimalPrecision),
    miles: getRoundDecimalUpTo(distanceInMiles, decimalPrecision),
  };
};

export { getFormattedDistance };
