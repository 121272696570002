import { Box, Text } from '@chakra-ui/react';
import { FC } from 'react';
import useTranslationComponent from '../../../../hooks/useTranslationComponent';
import { ContinueButton } from '../../../heal/patient/BookAppointment/components/common/Buttons';

type ProviderDetailedInfoProps = {
  bio?: string;
  education?: string;
  yearsOfExperience?: string | number;
  bioMaxHeight?: string;
  handleNext?: () => void;
};

const ProviderDetailedInfo: FC<ProviderDetailedInfoProps> = (props) => {
  const { t } = useTranslationComponent(['network']);

  return (
    <Box py={{ base: 4, md: 6 }}>
      {props.bio && (
        <Box mb="6">
          <Text fontWeight="600" mb="3" fontSize="14px" px="2">
            {t('BIO')}
          </Text>
          <Box maxH={props.bioMaxHeight ?? 'auto'} overflowY="auto" px="2">
            <Text fontSize="14px" dangerouslySetInnerHTML={{ __html: props.bio }} />
          </Box>
        </Box>
      )}
      {props.education && (
        <Box mb="6">
          <Text fontWeight="600" mb="3" fontSize="14px" px="2">
            {t('EDUCATION')}
          </Text>
          <Box px="2">
            <Text fontSize="14px" dangerouslySetInnerHTML={{ __html: props.education }} />
          </Box>
        </Box>
      )}
      {props.yearsOfExperience && (
        <Box>
          <Text fontWeight="600" mb="3" fontSize="14px" px="2">
            {t('EXPERIENCE')}
          </Text>
          <Box px="2">
            <Text fontSize="14px">
              {props.yearsOfExperience} {t('Years')}
            </Text>
          </Box>
        </Box>
      )}
      {props.handleNext && (
        <Box alignSelf={{ sm: 'center' }} px="2" mt={{ base: '6', md: '10' }} flexGrow={1}>
          <ContinueButton
            maxWidth={{ base: '584px' }}
            width="100%"
            onClick={props.handleNext}
            height="60px"
            fontWeight="600"
            fontSize={{ base: '14px', md: '16px' }}
          >
            {t('book now')}
          </ContinueButton>
        </Box>
      )}
    </Box>
  );
};

export { ProviderDetailedInfo };
