import { ButtonProps } from '@chakra-ui/react';
import { ActionButton } from '../../routes/heal/patient/BookAppointment/components/common/Buttons';

export type OutlineButtonProps = ButtonProps & { buttonColor?: string; buttonHoverColor?: string };

export const OutlineButton = (props: OutlineButtonProps) => {
  const { children, minWidth = '120px', buttonHoverColor, buttonColor, ...rest } = props;
  return (
    <ActionButton
      buttonVariant="outlined"
      minWidth={minWidth}
      buttonHoverColor={buttonHoverColor ?? 'white'}
      buttonColor={buttonColor ?? 'purple.600'}
      {...rest}
    >
      {props.children}
    </ActionButton>
  );
};
