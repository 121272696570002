import React from 'react';
import { ErrorLog } from '../generated';
import Status500 from './Status500';
import Stacktrace from 'stacktrace-js';

class GlobalErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorLog: null,
    };

    const self = this;
    const errorHandler = async (msg, url, line, col, error) => {
      try {
        Stacktrace.fromError(error)
          .then((err) => {
            let environment = {
              userAgent: navigator.userAgent,
              platform: navigator.platform,
              connection: navigator.connection,
              cookieEnabled: navigator.cookieEnabled,
              language: navigator.language,
            };
            let errorLog = new ErrorLog();
            // @ts-ignore
            errorLog.machineName = navigator.userAgent;
            errorLog.environment = JSON.stringify(environment);
            errorLog.uri = window.location.href;

            if (error) {
              errorLog.exceptionType = error.message.substring(0, 250);
              errorLog.message = msg ? msg.toString() : '';
              errorLog.fullMessage = err.toString();

              self.setState({
                errorLog,
                hasError: true,
              });
            } else if (msg instanceof PromiseRejectionEvent) {
              errorLog.exceptionType = 'Unrecoverable Error.';
              errorLog.message = msg.reason.message;
              errorLog.fullMessage = msg.reason.stack;

              self.setState({
                errorLog,
                hasError: true,
              });
            }

            return true;
          })
          .catch((err) => {
            // Eat err
          });
      } catch (err) {
        // Eat err
      }
    };

    window.onunhandledrejection = errorHandler;
    window.onerror = errorHandler;
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, info) {
    const handleError = async (error, info) => {
      try {
        Stacktrace.fromError(error)
          .then((err) => {
            // Error handling
            if (error) {
              let environment = {
                userAgent: navigator.userAgent,
                platform: navigator.platform,
                connection: navigator.connection,
                cookieEnabled: navigator.cookieEnabled,
                language: navigator.language,
              };
              let errorLog = new ErrorLog();
              // @ts-ignore
              errorLog.machineName = navigator.userAgent;
              errorLog.environment = JSON.stringify(environment);
              errorLog.uri = window.location.href;
              errorLog.exceptionType = error.message.substring(0, 250);
              errorLog.message = info.componentStack;
              errorLog.fullMessage = err.toString();

              this.setState({
                errorLog,
              });
            }
          })
          .catch((err) => {
            // Eat err
          });
      } catch (err) {
        // Eat err
      }
    };

    handleError(error, info);
  }

  render() {
    if (this.state.hasError) {
      return <Status500 errorLog={this.state.errorLog} />;
    }

    return this.props.children;
  }
}

export default GlobalErrorBoundary;
