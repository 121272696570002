import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { ButtonRow } from '../../../components/Buttons/ButtonRow';
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton';
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import { ConsultTabTypeEnum } from '../../../constants';
import { ConsultStatusEnum, IPHRConsult } from '../../../generated';
import { AppState } from '../../../store/root-reducers';
import { scrollToTop } from '../../../utils/scrollToTop';
import { successToastr } from '../../../utils/toastr';
import ProviderConsult from '../../heal/Consult/Provider';
import ConsultActionArea from '../../heal/Consult/Provider/components/ConsultActionArea/ConsultActionArea';
import useProviderConsultContext from '../../heal/Consult/Provider/hooks/useProviderConsultContext';

interface IProps {
  currentConsult: IPHRConsult;
  toggleView: () => void;
  quickMenuDescription?: string;
}

const ConsultsFormView = ({ currentConsult, toggleView, quickMenuDescription }: IProps) => {
  const provider = useSelector((state: AppState) => state.providerState.provider!);

  const {
    completeConsult,
    isUpdatingPhrConsult,
    setCurrentTab,
    reOpenConsult,
    reOpenConsultLoading,
  } = useProviderConsultContext();
  const isOnMobile = useBreakpointValue({ base: true, md: false });

  const onComplete = async (isDraft: boolean) => {
    if (await completeConsult(isDraft)) {
      successToastr({ description: "Updated patient's consult notes" });
      scrollToTop();
      toggleView();
    } else {
      setCurrentTab(ConsultTabTypeEnum.ConsultNotes);
    }
  };

  return (
    <Flex flexDirection="column" overflowY="auto" flexGrow={1}>
      {isUpdatingPhrConsult ? (
        <CenteredLoadingIndicator />
      ) : (
        <>
          <ConsultActionArea consult={currentConsult.consult} />
          {currentConsult?.consultID &&
          currentConsult.providerID === provider.providerID &&
          currentConsult.consult?.consultStatusEnum !== ConsultStatusEnum.Completed ? (
            <Box position={isOnMobile ? 'static' : 'absolute'} bottom="20px" right="50px">
              <ButtonRow>
                <SecondaryButton onClick={() => onComplete(true)}>Save As Draft</SecondaryButton>
                <SecondaryButton isLoading={reOpenConsultLoading} onClick={reOpenConsult}>
                  Re-open
                </SecondaryButton>
                <PrimaryButton onClick={() => onComplete(false)}>Complete Consult</PrimaryButton>
              </ButtonRow>
            </Box>
          ) : isOnMobile ? (
            <ButtonRow>
              {!quickMenuDescription && (
                <SecondaryButton onClick={toggleView}>Close</SecondaryButton>
              )}
              <PrimaryButton onClick={() => onComplete(true)}>
                {currentConsult?.attestationUserDetailID &&
                currentConsult?.attestationUserDetailID === provider.userDetailID
                  ? 'Complete'
                  : 'Update Consult'}
              </PrimaryButton>
            </ButtonRow>
          ) : (
            <Flex
              backgroundColor="white"
              width="100%"
              // position="absolute"
              bottom="0"
              right="0"
              pb={2}
              pr={10}
              justifyContent="flex-end"
            >
              {!quickMenuDescription && (
                <SecondaryButton mr={4} onClick={toggleView}>
                  Close
                </SecondaryButton>
              )}
              <PrimaryButton onClick={() => onComplete(true)}>
                {currentConsult?.attestationUserDetailID &&
                currentConsult?.attestationUserDetailID === provider.userDetailID
                  ? 'Complete'
                  : 'Update Consult'}
              </PrimaryButton>
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
};

export default function ConsultsForm({ currentConsult, toggleView, quickMenuDescription }: IProps) {
  return (
    <>
      {!currentConsult ? (
        <CenteredLoadingIndicator />
      ) : (
        <ProviderConsult
          consultID={currentConsult?.consultID}
          isInConsult={false}
          patientID={currentConsult.patientID}
          phrConsultID={currentConsult.phrConsultID}
          quickMenuDescription={quickMenuDescription}
        >
          <ConsultsFormView
            currentConsult={currentConsult}
            toggleView={toggleView}
            quickMenuDescription={quickMenuDescription}
          />
        </ProviderConsult>
      )}
    </>
  );
}
