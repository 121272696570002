import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IPatient, PHRTracker, TrackerTypeEnum } from '../../../../generated';
import { IPHRPatientEnhanced } from '../../../../store/patient/phr/phrReducers';
import { phrStepCounterSelector } from '../../../../store/patient/phr/phrSelectors';
import { getAllForPhrSection } from '../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import { usePhrPatient } from './usePhrPatient';

export default function usePhysicalTrackers(patient?: IPatient, trackers?: PHRTracker[]) {
  const { phrPatient, isLoading: isLoadingPhr } = usePhrPatient(patient);
  const [physicalTrackers, setPhysicalTrackers] = useState<PHRTracker[] | null>(
    trackers
      ?.filter((x) => x.name !== TrackerTypeEnum.Exercise)
      .sort((a, b) => a.name.localeCompare(b.name)) || [],
  );
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setPhysicalTrackers(trackers?.sort((a, b) => a.name.localeCompare(b.name)) || []);
  }, [trackers]);

  useEffect(() => {
    if (physicalTrackers && physicalTrackers.length) {
      setLoading(false);
    }
  }, [physicalTrackers]);

  return { isLoading: isLoading || isLoadingPhr, physicalTrackers, phrPatient };
}

interface IProps {
  patient: IPHRPatientEnhanced | null | undefined;
}

export const usePhysicalTrackerValue = ({ patient }: IProps) => {
  const dispatch = useDispatch();
  const phrStepCounters = patient ? phrStepCounterSelector(patient.phrStepCounters) : [];

  useEffect(() => {
    if (patient) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRStepCounters, patient.patientID));
    }
  }, [dispatch, patient]);

  return {
    phrStepCounters,
  };
};
