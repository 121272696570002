import { Box, Text, VStack } from '@chakra-ui/react';
import { FC, memo } from 'react';
import useTranslationComponent from '../../hooks/useTranslationComponent';

import { NotificationModal } from '../Modals/NotificationModal';

type LocationNotificationModalProps = {
  positionError?: GeolocationPositionError;
  isNotificationModalOpen: boolean;
  onDismissNotificationModalError?: () => void;
  onDismissNotificationModal?: () => void;
  description?: string;
  position?: GeolocationPosition;
};

const LocationNotificationModal: FC<LocationNotificationModalProps> = (props) => {
  const { t } = useTranslationComponent();
  if (props.position) return null;

  return (
    <NotificationModal
      isModalOpen={!!props.positionError || props.isNotificationModalOpen}
      onClose={
        props.positionError
          ? props.onDismissNotificationModalError
          : props.onDismissNotificationModal
      }
      modalTitle={t(props.positionError ? 'Location Access Error' : 'Location Access Request')}
      closeButtonText={t('Ok')}
    >
      <VStack justifyContent="center" height="100%">
        <Box textAlign="center">
          {props.positionError ? (
            <Text color="rgb(255, 43, 79)">{props.positionError.message}</Text>
          ) : (
            <Text>
              {t(
                props.description ??
                  `We need access to your location to better personalize your experience and find providers near you`,
              )}
              .
            </Text>
          )}
        </Box>
      </VStack>
    </NotificationModal>
  );
};

const MemoizedLocationNotificationModal = memo(LocationNotificationModal);

export { LocationNotificationModal, MemoizedLocationNotificationModal };
