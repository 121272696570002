import { Box, Grid, GridItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { PlusIconBig } from '../../../../../assets/icons';
import FadeInComponent from '../../../../../components/animations/FadeInComponent';
import { FloatingActionButton } from '../../../../../components/Buttons';
import { CenteredLoadingIndicator } from '../../../../../components/LoadingIndicator';
import { IUserDetailSetting, PHRVitalsDTO } from '../../../../../generated';
import { IPHRPatientCombined } from '../../../../../store/patient/phr/phrReducers';
import { AppState } from '../../../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../../../store/types';
import { IUnitSettings } from '../../hooks/usePatientVitals';
import { PatientVitalsCard } from '../PatientVitalsCard/PatientVitalsCard';
import { VitalsTracker } from '../PatientVitalsCard/usePatientVitalsCard';
import { addButton, renderVitalsHeader } from '../PatientVitalsTitle/PatientVitalsTitle';
import { ListViewStateEnum } from './usePatientVitalsList';

interface IProps {
  isLoading: boolean;
  phrPatient: IPHRPatientCombined;
  onBack?: () => void;
  setShowForm: (showForm: boolean) => void;
  renderTracker: () => JSX.Element | undefined;
  viewState: ListViewStateEnum;
  setTrackerID: (id: ReduxPHRCategoryEnum | null) => void;
  tracker: VitalsTracker;
  showTracker: (id: ReduxPHRCategoryEnum | null) => void;
  refreshList: () => void;
  vitals?: PHRVitalsDTO;
  userDetailSettings?: IUserDetailSetting[] | null;
  unitSettings: IUnitSettings;
}

export const PatientVitalsList = ({
  isLoading,
  onBack,
  setShowForm,
  renderTracker,
  viewState,
  setTrackerID,
  showTracker,
  vitals,
  unitSettings,
}: IProps) => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  return (
    <>
      {viewState === ListViewStateEnum.Tracker ? (
        <FadeInComponent>{renderTracker()}</FadeInComponent>
      ) : (
        <Box bgColor="#f4f4f4" height="100%" overflowY="hidden">
          {renderVitalsHeader(
            () =>
              addButton(() => {
                setShowForm(true);
                setTrackerID(null);
              }),
            // isMobile ? onBack : undefined,
            onBack,
            'Vital Signs',
          )}
          <Box
            height={{ base: 'calc(100vh - 4rem)', md: '580px' }}
            paddingX={{ base: '1rem', md: '70px' }}
            overflowY="auto"
          >
            {isLoading ? (
              <CenteredLoadingIndicator />
            ) : (
              <FadeInComponent>
                {vitals && (
                  <Grid
                    templateColumns={{ base: '1fr', md: '1fr 1fr' }}
                    gridTemplateRows={{ base: 'auto', md: 'repeat(10, 75px)' }}
                    gridGap={4}
                  >
                    <GridItem
                      gridColumn={{ base: 'auto', md: '1/2' }}
                      gridRow={{ base: 'auto', md: '1/4' }}
                    >
                      <PatientVitalsCard
                        trackerID={ReduxPHRCategoryEnum.PHRBloodPressures}
                        tracker={vitals.bloodPressure!}
                        trackerDisplayName="Blood Pressure"
                        showTracker={showTracker}
                        currentUser={currentUser}
                        color="#FD7024"
                      />
                    </GridItem>

                    <GridItem
                      gridColumn={{ base: 'auto', md: '2' }}
                      gridRow={{ base: 'auto', md: '2/5' }}
                      mt={{ base: '0', md: '-2rem' }}
                    >
                      <PatientVitalsCard
                        trackerID={ReduxPHRCategoryEnum.PHRPulses}
                        tracker={vitals.pulse!}
                        trackerDisplayName="Pulse Rate"
                        showTracker={showTracker}
                        currentUser={currentUser}
                        color="#7BBFB7"
                      />
                    </GridItem>

                    <GridItem
                      gridColumn={{ base: 'auto', md: '1/2' }}
                      gridRow={{ base: 'auto', md: '4/7' }}
                      mt={{ base: '0', md: '-2rem' }}
                    >
                      <PatientVitalsCard
                        trackerID={ReduxPHRCategoryEnum.PHRBloodOxygens}
                        tracker={vitals.bloodOxygen!}
                        trackerDisplayName="Oxygen Level"
                        showTracker={showTracker}
                        currentUser={currentUser}
                        color="#FFC235"
                      />
                    </GridItem>
                    <GridItem
                      gridColumn={{ base: 'auto', md: '2' }}
                      gridRow={{ base: 'auto', md: '5/8' }}
                      mt={{ base: '0', md: '-4rem' }}
                    >
                      <PatientVitalsCard
                        trackerID={ReduxPHRCategoryEnum.PHRRespiratories}
                        tracker={vitals.respiratory!}
                        trackerDisplayName="Respiratory Rate"
                        showTracker={showTracker}
                        currentUser={currentUser}
                        color="#F0E9D4"
                      />
                    </GridItem>

                    <GridItem
                      gridColumn={{ base: 'auto', md: '1/2' }}
                      gridRow={{ base: 'auto', md: '7/10' }}
                      mt={{ base: '0', md: '-4rem' }}
                    >
                      <PatientVitalsCard
                        trackerID={ReduxPHRCategoryEnum.PHRTemperatures}
                        tracker={vitals.temperature!}
                        trackerDisplayName="Temperature"
                        showTracker={showTracker}
                        unitSettings={unitSettings}
                        currentUser={currentUser}
                        color="#3FA884"
                      />
                    </GridItem>

                    <GridItem
                      gridColumn={{ base: 'auto', md: '2' }}
                      gridRow={{ base: 'auto', md: '8 / span 3' }}
                      mt={{ base: '0', md: '-6rem' }}
                    >
                      <PatientVitalsCard
                        trackerID={ReduxPHRCategoryEnum.PHRBloodGlucoses}
                        tracker={vitals.bloodGlucose!}
                        trackerDisplayName="Blood Glucose"
                        showTracker={showTracker}
                        unitSettings={unitSettings}
                        currentUser={currentUser}
                        color="#FF84A1"
                      />
                    </GridItem>
                  </Grid>
                )}
              </FadeInComponent>
            )}
          </Box>

          <FloatingActionButton
            zIndex={1}
            bottom={10}
            right={10}
            onClick={() => setShowForm(true)}
            display={{ base: 'block', md: 'none' }}
            bgColor="#020202"
          >
            <PlusIconBig color="#fff" fontSize="20px" />
          </FloatingActionButton>
        </Box>
      )}
    </>
  );
};
