import { Box, BoxProps, IconButton } from '@chakra-ui/react';
import React from 'react';

interface IProps {
  count: number | null;
  handleClick: () => void;
  Icon: React.ElementType;
  containerProps?: BoxProps;
  ariaLabel?: string;
  id?: string;
}

export const NotificationIcon = ({
  count,
  handleClick,
  Icon,
  ariaLabel,
  id,
  containerProps,
}: IProps) => {
  return (
    <Box position="relative" id={id} {...containerProps}>
      <IconButton
        variant="unstyled"
        aria-label={ariaLabel ?? 'notifications'}
        icon={<Icon h="24px" w="24px" />}
        onClick={handleClick}
      />
      {!!count && (
        <Box
          position="absolute"
          height="17px"
          width="17px"
          color="white"
          backgroundColor="#EF8062"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="full"
          fontSize="10px"
          bottom="0"
          right="0"
        >
          {count}
        </Box>
      )}
    </Box>
  );
};
