import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { PartnerSearchDetails } from '../../generated';
import { getCurrentPartner } from '../../store/root-creator';
import getUrl from '../../utils/getUrl';

const useGetCurrentPartner = () => {
  const dispatch = useDispatch();
  const url = getUrl();

  useEffect(() => {
    const partnerSearchDetails = new PartnerSearchDetails();
    partnerSearchDetails.host = url;
    dispatch(getCurrentPartner(partnerSearchDetails));
  }, [dispatch, url]);
};

export { useGetCurrentPartner };
