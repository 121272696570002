import { useState } from 'react';

export enum AddressBookStateEnum {
  List = 'List',
  Create = 'Create',
  Edit = 'Edit',
}

export const useNavigation = () => {
  const [state, setState] = useState<AddressBookStateEnum>(AddressBookStateEnum.List);
  const goto = (state: AddressBookStateEnum) => setState(state);
  return { state, goto };
};
