import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useField, useFormikContext } from 'formik';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';

import { Time2Icon } from '../../../assets/icons';
import usePrevious from '../../../hooks/usePrevious';

import { FormControl } from '../FormControl/FormControl';
import { DateTimePickerProps, FormDateTimePickerProps } from './DateTimePicker';

export type TimePickerProps = DateTimePickerProps;

const CustomInput = ({ value, onClick, inputElementProps, ...rest }) => (
  <InputGroup>
    <Input value={value} onClick={onClick} {...rest} />
    <InputRightElement color="#645D6B" {...inputElementProps}>
      <Time2Icon fontSize="20px" color="#78819D" />
    </InputRightElement>
  </InputGroup>
);

export const TimePicker = (props: TimePickerProps) => {
  const {
    label,
    formControlProps,
    showTimeSelect = true,
    showTimeSelectOnly = true,
    timeIntervals = 15,
    timeFormat = 'p',
    dateFormat = 'p',
    autoComplete = 'off',
    selected,
    customInputProps,
    ...rest
  } = props;
  return (
    <FormControl
      label={label}
      {...formControlProps}
      formLabelProps={{
        fontWeight: 'light',
      }}
    >
      <DatePicker
        calendarClassName="outpost-calendar"
        // onFocus={(e) => (e.target.readOnly = true)}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeIntervals={timeIntervals}
        timeFormat={timeFormat}
        dateFormat={dateFormat}
        selected={moment.isMoment(selected) ? selected.toDate() : selected}
        autoComplete={autoComplete}
        popperModifiers={{
          preventOverflow: {
            enabled: true,
          },
        }}
        // @ts-ignore
        customInput={<CustomInput {...customInputProps} />}
        {...rest}
      />
    </FormControl>
  );
};

export type FormTimePickerProps = FormDateTimePickerProps;

export const FormTimePicker = (props: FormDateTimePickerProps) => {
  const { name, formControlProps, ...rest } = props;
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const previousDate = usePrevious(field.value);

  return (
    <TimePicker
      {...rest}
      {...field}
      formControlProps={{ ...meta, ...formControlProps }}
      selected={field.value}
      onChange={(val) => {
        if (moment(val as Date).isValid()) {
          const newDate = moment(val as Date);
          if (previousDate) {
            const prev = moment(previousDate);
            newDate.set('d', prev.get('d'));
            newDate.set('M', prev.get('M'));
            newDate.set('y', prev.get('y'));
          }
          setFieldValue(field.name, newDate.toDate());
        }
      }}
    />
  );
};
