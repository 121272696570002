import { useState } from 'react';
import { Tag, TagTypeEnum } from '../../../../../../../generated';
import { useTagsAllByTagType } from '../../../../../../../services/tags/queries/useTagsAllByTagType';

import useBookConsultContext from '../../../hooks/useBookConsultContext/useBookConsultContext';

import { BookConsultStepEnum, IBookConsultState } from '../../BookConsultProvider';

export type ServiceType = {
  id: number | string;
  name: string;
};

export const serviceIsSelected = (selectedServices: Tag[], service: Tag) =>
  selectedServices.find((selectedService) => selectedService.tagID === service.tagID);

export const useServiceTags = (tagTypeEnum: TagTypeEnum) => {
  const [selectedServices, setSelectedServices] = useState<Tag[]>([]);

  const { isLoadingTags, tags, tagsError } = useTagsAllByTagType(tagTypeEnum);

  const onSelectService = (service: Tag) => {
    let filteredServices: Tag[] = [];

    if (serviceIsSelected(selectedServices, service)) {
      filteredServices = selectedServices.filter(
        (selectedService) => selectedService.tagID !== service.tagID,
      );
    } else {
      filteredServices = [...selectedServices, service];
    }

    setSelectedServices(filteredServices);
  };

  return {
    selectedServices,
    onSelectService,
    isLoadingTags,
    tags,
    tagsError,
  };
};

const useHomeCareServices = () => {
  const { state, updateState, handlers } = useBookConsultContext();

  const { selectedServices, onSelectService, isLoadingTags, tags, tagsError } = useServiceTags(
    TagTypeEnum.HomeCareService,
  );

  const isServiceSelected = (service: Tag) => !!serviceIsSelected(selectedServices, service);

  const cannotContinue = selectedServices.length === 0;

  const handleNextStep = () => {
    if (cannotContinue) return;

    const newState: IBookConsultState = {
      ...state,
      homeCareServiceTags: selectedServices,
      currentStep: BookConsultStepEnum.CategoryOfProvider,
    };

    updateState(newState);
  };

  return {
    handleNextStep,
    patient: state.patient,
    selectedServices,
    onSelectService,
    isServiceSelected,
    cannotContinue,
    isLoadingTags,
    tags,
    tagsError,
    handlers,
  };
};

export { useHomeCareServices };
