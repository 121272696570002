import { Text } from '@chakra-ui/react';
import { Formik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { date, number, object } from 'yup';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';
import { EmojiPicker } from '../../../../../components/emojiPicker/EmojiPicker';
import useEmojiPicker from '../../../../../components/emojiPicker/useEmojiPicker';
import { Form, FormMobisPicker } from '../../../../../components/forms';
import { FormikButtonGroup } from '../../../../../components/forms/ButtonGroup';
import { CenteredLoadingIndicator } from '../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../components/ServerValidationBox';
import { ButtonSizeEnum } from '../../../../../constants';
import { PHRTrackerStressDTO, TagTypeEnum } from '../../../../../generated';
import { useTagsAllByTagType } from '../../../../../services/tags/queries/useTagsAllByTagType';
import { createForPhrSection, updateForPhrSection } from '../../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../../store/types';
import { ButtonRow } from '../../../components/StyledPhrItems';

const DEFAULT_LEVEL = 5;

interface IProps {
  selectedData: PHRTrackerStressDTO | null;
  patientID: string;
  onClose: () => void;
}

interface IForm {
  trackerDate: Date;
  stressValue?: number;
  tagIDs?: string[];
}

const StressForm = ({ selectedData, patientID, onClose }: IProps) => {
  const dispatch = useDispatch();
  const { tagMap, tagsError } = useTagsAllByTagType(TagTypeEnum.StressTrackerValues);

  const { isLoading, emojis, getTagList } = useEmojiPicker({
    tagTypeEnum: TagTypeEnum.StressTracker,
  });
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const onSubmit = async (values: IForm) => {
    setSubmitting(true);
    if (selectedData) {
      selectedData.trackerDate = moment(values.trackerDate).toDate();
      selectedData.stressValue = values.stressValue;
      selectedData.tags = values.tagIDs?.map((x) => tagMap[x]);
      await dispatch(
        updateForPhrSection(ReduxPHRCategoryEnum.PHRStresses, selectedData, patientID, true),
      );
    } else {
      const stress = new PHRTrackerStressDTO();
      stress.trackerDate = values.trackerDate;
      stress.stressValue = values.stressValue;
      stress.tags = values.tagIDs?.map((x) => tagMap[x]);
      await dispatch(
        createForPhrSection(ReduxPHRCategoryEnum.PHRStresses, stress, patientID, true),
      );
    }
    setSubmitting(false);
    onClose();
  };

  const initialValues: IForm = {
    trackerDate: selectedData ? moment(selectedData.trackerDate!).toDate() : moment().toDate(),
    stressValue: selectedData ? selectedData.stressValue : DEFAULT_LEVEL,
    tagIDs: selectedData ? selectedData.tags?.map((x) => x.tagID) : [],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={object({
        trackerDate: date().required('Date is required').typeError('Invalid date'),
        stressValue: number().required('Stress Value is required'),
      })}
      onSubmit={onSubmit}
    >
      {({ status, values, setFieldValue }) => (
        <Form error={status}>
          {isSubmitting ? (
            <CenteredLoadingIndicator />
          ) : (
            <>
              <ServerValidationBox message={status || tagsError} />
              <Text
                textAlign="center"
                variant="heading"
                fontSize="20px"
                mb={10}
                mt={4}
                fontWeight="bold"
              >
                How stressed are you feeling?
              </Text>
              <div className="row" style={{ display: 'none' }}>
                <div className="col-12 col-sm-6">
                  <FormMobisPicker
                    name="trackerDate"
                    label="Tracked at"
                    controls={['calendar', 'time']}
                    max={moment().toDate()}
                    min={moment().add(-150, 'year').toDate()}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12">
                  <EmojiPicker
                    isLoading={isLoading}
                    emojis={emojis}
                    value={values.stressValue || DEFAULT_LEVEL}
                    onChange={(val) => setFieldValue('stressValue', val)}
                  />
                </div>
              </div>

              <TagsWrapper>
                {getTagList(values.stressValue)?.length > 0 && (
                  <Text
                    textAlign="center"
                    variant="heading"
                    fontSize="14px"
                    mb={8}
                    mt={10}
                    fontWeight="bold"
                  >
                    I also feel
                  </Text>
                )}
                <Tags>
                  {getTagList(values.stressValue)?.map((x) => (
                    <ButtonGroup
                      key={x.tagXrefID}
                      hideError={true}
                      label={x.toTag?.description}
                      value={x.toTag?.tagID}
                      name="tagIDs"
                      type="checkbox"
                    />
                  ))}
                  {/* {tags &&
                    tags
                      .filter((x) =>
                        getStressTagNamesByLevel(values.stressValue).find((y) => y === x.name),
                      )
                      .map((x, index) => {
                        return (
                          <ButtonGroup
                            key={x.tagID}
                            hideError={true}
                            label={x.name}
                            value={x.tagID}
                            name="tagIDs"
                            type="checkbox"
                          />
                        );
                      })} */}
                </Tags>
              </TagsWrapper>
              <StyledButtonRow>
                <PrimaryButton size={ButtonSizeEnum.medium} type="submit">
                  I'm done
                </PrimaryButton>
              </StyledButtonRow>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default StressForm;

const Tags = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 12px;
  margin-bottom: 24px;
`;

const ButtonGroup = styled(FormikButtonGroup)`
  width: max-content;
  margin: 4px 12px 4px 0;
  div {
    font-size: 0.85rem;
    font-weight: bold;
    min-height: 26px;
  }
`;

const TagsWrapper = styled.div`
  margin-top: 15px;
  margin-left: 2px;
`;

const StyledButtonRow = styled(ButtonRow)`
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 0;
`;
