import { Grid } from '@chakra-ui/react';
import React from 'react';
import { PHRNote } from '../../../generated';
import { NotesCard } from './NotesCard';

interface IProps {
  phrNotes: PHRNote[];
  ActionButton: React.ReactNode;
}

const NotesList = ({ phrNotes, ActionButton }: IProps) => {
  return (
    <>
      {phrNotes.length === 0 ? (
        ActionButton
      ) : (
        <Grid
          templateColumns={{ base: '1fr', sm: 'repeat(2, 300px)' }}
          templateRows="auto"
          gap={4}
          overflow="auto"
          height="fit-content"
          maxHeight="300px"
        >
          {phrNotes.map((x) => (
            <NotesCard referralNote={x} />
          ))}
        </Grid>
      )}
    </>
  );
};

export default NotesList;
