import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { OutpostTheme } from '../../themes/outpost';

const OptionWrapper = styled.div`
  background-color: #f4f6fa;
  border-radius: 10px;
  display: flex;
  margin: 2px;
  min-width: 0;
  box-sizing: border-box;
`;

const OptionText = styled.div`
  border-radius: 2px;
  color: ${OutpostTheme.Colors.DarkBlue};
  font-size: 85%;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
`;

const OptionClear = styled.div`
  align-items: center;
  border-radius: 2px;
  display: flex;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;

  &:hover {
    background-color: #c77777;
    color: #fff;
    cursor: pointer;
  }
`;

interface IProps {
  text: string;
  onClear: (event?: any) => void;
}

const InlineOption = ({ text, onClear }: IProps) => {
  return (
    <OptionWrapper>
      <OptionText>{text}</OptionText>
      <OptionClear onClick={onClear}>
        <FontAwesomeIcon icon={['far', 'times']} />
      </OptionClear>
    </OptionWrapper>
  );
};

export default InlineOption;
