import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { Consult, ConsultActionMessage, ConsultStoreItem, CancelConsultDTO } from '../../generated';

export const getActiveConsultActions = createAsyncAction(
  ['GET_ACTIVE_CONSULT_REQUEST', () => {}],
  ['GET_ACTIVE_CONSULT_SUCCESS', (res: Consult | null) => res],
  ['GET_ACTIVE_CONSULT_ERROR', (err: string) => err],
)();

export const getActiveConsultByConsultIDActions = createAsyncAction(
  ['GET_ACTIVE_CONSULT_BY_CONSULT_ID_REQUEST', () => {}],
  ['GET_ACTIVE_CONSULT_BY_CONSULT_ID_SUCCESS', (res: Consult) => res],
  ['GET_ACTIVE_CONSULT_BY_CONSULT_ID_ERROR', (err: string) => err],
)();

export const refreshActiveConsultByConsultIDActions = createAsyncAction(
  ['REFRESH_ACTIVE_CONSULT_BY_CONSULT_ID_REQUEST', () => {}],
  ['REFRESH_ACTIVE_CONSULT_BY_CONSULT_ID_SUCCESS', (res: Consult) => res],
  ['REFRESH_ACTIVE_CONSULT_BY_CONSULT_ID_ERROR', (err: string) => err],
)();

export const reInitializeActiveConsultByConsultIDActions = createAsyncAction(
  ['RE_INITIALIZE_ACTIVE_CONSULT_BY_CONSULT_ID_REQUEST', () => {}],
  ['RE_INITIALIZE_ACTIVE_CONSULT_BY_CONSULT_ID_SUCCESS', (res: Consult) => res],
  ['RE_INITIALIZE_ACTIVE_CONSULT_BY_CONSULT_ID_ERROR', (err: string) => err],
)();

export const boughtConsultAction = createAction('BOUGHT_CONSULT_SUCCESS', (res: Consult) => res)();

export const setActiveConsult = createAction('SET_ACTIVE_CONSULT', (res: Consult | null) => res)();

export const clearActiveConsult = createAction('CLEAR_ACTIVE_CONSULT', () => {})();

export const canceledConsultAction = createAction(
  'CANCELED_CONSULT_SUCCESS',
  (res: CancelConsultDTO) => res,
)();

export const updateActiveConsultStatusAction = createAction(
  'UPDATE_CONSULT_STATUS',
  (updateConsultStatus: ConsultActionMessage) => updateConsultStatus,
)();

export const updatedConsultStoreItemAction = createAction(
  'UPDATE_CONSULT_STORE_ITEM',
  (updatedConsultStoreItem: ConsultStoreItem) => updatedConsultStoreItem,
)();

export const moveConsultToPhone = createAction(
  'MOVE_CONSULT_TO_PHONE',
  (consultID: string) => consultID,
)();

export type ActiveConsultActionTypes = ActionType<
  | typeof boughtConsultAction
  | typeof canceledConsultAction
  | typeof getActiveConsultActions
  | typeof updateActiveConsultStatusAction
  | typeof getActiveConsultByConsultIDActions
  | typeof refreshActiveConsultByConsultIDActions
  | typeof reInitializeActiveConsultByConsultIDActions
  | typeof updatedConsultStoreItemAction
  | typeof setActiveConsult
  | typeof clearActiveConsult
  | typeof moveConsultToPhone
>;
