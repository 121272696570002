import { AuthClient, MarketingCampaignsClient } from '../../generated';
import { httpRequest } from '../../utils';
import { OHThunkResult } from '../root-reducers';
import { getActiveMarketingCampaignsActions } from './activeMarketingCampaignsActions';

export function getActiveMarketingCampaigns(countryID: string): OHThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    return new Promise<void>(async () => {
      dispatch(getActiveMarketingCampaignsActions.request());
      const marketingCampaignsClient = new MarketingCampaignsClient(new AuthClient());
      try {
        const result = await httpRequest(() => marketingCampaignsClient.active(countryID));
        dispatch(getActiveMarketingCampaignsActions.success(result));
      } catch (err) {
        dispatch(getActiveMarketingCampaignsActions.failure(err as any));
      }
    });
  };
}
