import {
  Box,
  Button,
  chakra,
  Flex,
  HStack,
  IconButton,
  SkeletonText,
  SlideFade,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';

import React, { useEffect, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Device } from '../../../constants';
import {
  IPatient,
  IPHRSummaryDTO,
  PatientAccessEnum,
  PHRSummaryStatusEnum,
  RolesEnum,
} from '../../../generated';
import { AppState } from '../../../store/root-reducers';
import { isInRole } from '../../../utils/isInRole';
import { IDependantsOption } from '../PhrComponent';

import PhrPatientSummary from './PhrPatientSummary';

import { XCloseIcon, TriangleArrowDownIcon } from '../../../assets/icons';
import useTranslationComponent from '../../../hooks/useTranslationComponent';

interface IPhrNavProps {
  readonly category?: IPHRSummaryDTO | null;
}

const PhrNav = styled.div<IPhrNavProps>`
  @media ${Device.laptop} {
    display: flex;
    flex-direction: column;
  }
  display: none;
  text-align: center;
  max-width: 275px;
  flex: 1 0 0;
  margin-right: 15px;
  overflow-y: auto;
`;

const ChakraSlideFade = chakra(SlideFade);

interface IProps {
  patient: IPatient;
  phrSummary: IPHRSummaryDTO[];
  currentCategory: IPHRSummaryDTO | null;
  setCurrentCategory: (x: IPHRSummaryDTO) => void;
  currentPHRDependant?: IDependantsOption;
  phrOptionsList?: IDependantsOption[];
  setCurrentPHRDependant?: (dependant: IDependantsOption) => void;
  onDismiss?: () => void;
}

const PhrNavComponent = ({
  patient,
  phrSummary,
  currentCategory,
  setCurrentCategory,
  currentPHRDependant,
  phrOptionsList,
  setCurrentPHRDependant,
  onDismiss,
}: IProps) => {
  const { t } = useTranslationComponent(['clinic']);
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const isCurrentCategory = (phrCategory: PatientAccessEnum): boolean =>
    currentCategory != null && phrCategory === currentCategory.phrCategory;

  const [showTopBtn, setShowTopBtn] = useState(false);
  const [showBottomBtn, setShowBottomBtn] = useState(false);
  const scrollRegionRef = React.useRef<HTMLDivElement>(null);
  const topTargetRef = React.useRef<HTMLDivElement>(null);
  const bottomTargetRef = React.useRef<HTMLDivElement>(null);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const mobileHeightOffset = !currentUser?.mobileSystem ? '14.5rem' : '10.5rem';

  useEffect(() => {
    let topTarget;
    let bottomTarget;

    const options = {
      root: scrollRegionRef.current,
      threshold: 1.0,
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.target.id === 'topTargetRef') {
          if (entry.isIntersecting) {
            setShowTopBtn(false);
          } else {
            if (!showTopBtn) {
              setShowTopBtn(true);
            }
          }
        }

        if (entry.target.id === 'bottomTargetRef') {
          if (entry.isIntersecting) {
            setShowBottomBtn(false);
          } else {
            if (!showBottomBtn) {
              setShowBottomBtn(true);
            }
          }
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    if (topTargetRef.current && bottomTargetRef.current) {
      topTarget = topTargetRef.current;
      bottomTarget = bottomTargetRef.current;
      observer.observe(topTarget);
      observer.observe(bottomTarget);
    }

    return () => {
      if (topTarget && bottomTarget) {
        observer.unobserve(topTarget);
        observer.unobserve(bottomTarget);
      }
    };
  }, [showBottomBtn, showTopBtn]);

  return (
    <Box bgColor="#F4F4F4" w={isMobile && !currentCategory ? '100%' : undefined} h="100%">
      <ChakraSlideFade
        in={isMobile ? !currentCategory : true}
        offsetX={isMobile ? '-100%' : undefined}
        offsetY={0}
        h="100%"
      >
        <Box
          display={{ base: currentCategory ? 'none' : 'block', md: 'block' }}
          width={{ base: '100%', md: '260px' }}
          h="100%"
          padding={{ base: '1rem 0.5rem', md: '2rem 1.5rem' }}
          bgColor="#ffffff"
        >
          {isMobile && onDismiss && (
            <HStack justifyContent="flex-end">
              <IconButton
                aria-label="close"
                p={0}
                variant="ghost"
                borderRadius="full"
                onClick={onDismiss}
              >
                <XCloseIcon width="24px" height="24px" />
              </IconButton>
            </HStack>
          )}
          <PhrPatientSummary
            patient={patient!}
            currentPHRDependant={currentPHRDependant}
            phrOptionsList={phrOptionsList}
            setCurrentPHRDependant={setCurrentPHRDependant}
          />
          <Box borderY="1px solid #78819D3D">
            {showTopBtn ? (
              <Flex bgColor="#FFFFFF" justify="center">
                <IconButton
                  aria-label="top-scroll-btn"
                  size="xs"
                  bgColor="#FFFFFF"
                  visibility={showTopBtn ? 'visible' : 'hidden'}
                  onClick={() => {
                    scrollRegionRef.current?.scroll({ top: 0, behavior: 'smooth' });
                  }}
                >
                  <TriangleArrowDownIcon transform="rotate(180deg)" />
                </IconButton>
              </Flex>
            ) : (
              <Box height="0.5rem" />
            )}

            <Stack
              spacing={1.5}
              height={{ base: `calc(${use100vh()}px - ${mobileHeightOffset})`, md: '450px' }}
              overflowY="auto"
              ref={scrollRegionRef}
              css={{
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              <Box id="topTargetRef" ref={topTargetRef} />
              {isInRole(currentUser, RolesEnum.Provider) && (
                <NavButton
                  summary={{
                    phrCategory: PatientAccessEnum.PHRSummary,
                    phrCategoryName: t('Patient Information'),
                    summaryStatusEnum: PHRSummaryStatusEnum.Normal,
                  }}
                  setCurrentCategory={setCurrentCategory}
                  isCurrentCategory={isCurrentCategory}
                />
              )}
              {phrSummary
                ?.sort((a, b) => (a.displaySortOrder || 99) - (b.displaySortOrder || 99))
                .map((x) => {
                  switch (x.phrCategory) {
                    case PatientAccessEnum.PHRConsult:
                    case PatientAccessEnum.PHRDocument:
                    case PatientAccessEnum.PHRHealthCondition:
                    case PatientAccessEnum.PHRMedication:
                    case PatientAccessEnum.PHRSurgeryHistory:
                    case PatientAccessEnum.PHRFamilyHistory:
                    case PatientAccessEnum.PHRAllergy:
                    case PatientAccessEnum.PHRCareTeam:
                    case PatientAccessEnum.PHRInsurance:
                    case PatientAccessEnum.PHRVaccination:
                    case PatientAccessEnum.PHRCovid:
                    case PatientAccessEnum.PHRHealthJournal:
                      return (
                        <NavButton
                          summary={x}
                          setCurrentCategory={setCurrentCategory}
                          isCurrentCategory={isCurrentCategory}
                        />
                      );

                    // Temporarily filtering out Health Trackers
                    case PatientAccessEnum.PHRTracker:
                      return (
                        <>
                          <NavButton
                            summary={x}
                            setCurrentCategory={setCurrentCategory}
                            isCurrentCategory={isCurrentCategory}
                          />
                          <NavButton
                            summary={{
                              ...x,
                              phrCategoryName: t('Vital Signs'),
                              phrCategory: 'VitalSigns' as PatientAccessEnum,
                            }}
                            setCurrentCategory={setCurrentCategory}
                            isCurrentCategory={isCurrentCategory}
                          />
                        </>
                      );
                    default:
                      return null;
                  }
                })}
              <Box id="bottomTargetRef" ref={bottomTargetRef} />
            </Stack>
          </Box>

          {showBottomBtn ? (
            <Flex bgColor="#FFFFFF" justify="center">
              <IconButton
                aria-label="bottom-scroll-btn"
                size="xs"
                bgColor="#FFFFFF"
                visibility={showBottomBtn ? 'visible' : 'hidden'}
                onClick={() => {
                  scrollRegionRef.current?.scroll({
                    top: scrollRegionRef.current.scrollHeight,
                    behavior: 'smooth',
                  });
                }}
              >
                <TriangleArrowDownIcon />
              </IconButton>
            </Flex>
          ) : (
            <Box height="0.5rem" />
          )}
        </Box>
      </ChakraSlideFade>
    </Box>
  );
};

export default PhrNavComponent;

interface INavButtonProps {
  summary: IPHRSummaryDTO;
  setCurrentCategory: (x: IPHRSummaryDTO) => void;
  isCurrentCategory: (phrCategory: PatientAccessEnum) => boolean;
}

const NavButton = ({ summary, setCurrentCategory, isCurrentCategory }: INavButtonProps) => {
  return (
    <Button
      id={summary.phrCategory}
      variant="unstyled"
      paddingLeft="1rem"
      fontSize="sm"
      textAlign="left"
      onClick={() => setCurrentCategory(summary)}
      borderRadius="0.75rem"
      borderColor={isCurrentCategory(summary.phrCategory!) ? '#E2F3F1' : 'transparent'}
      backgroundColor={isCurrentCategory(summary.phrCategory!) ? '#E2F3F1' : 'white'}
      color={isCurrentCategory(summary.phrCategory!) ? '#020202' : '#011431'}
      _hover={{
        backgroundColor: '#E2F3F1',
      }}
      display="flex"
      justifyContent="flex-start"
      height={{ base: '44px', xl: '50px' }}
      flexShrink={0}
    >
      <Box
        w="10px"
        h="10px"
        mr="1rem"
        borderRadius="full"
        border="2px solid #4C919A"
        backgroundColor={summary.count ? '#4C919A' : undefined}
      />
      {summary.phrCategoryName}
    </Button>
  );
};

export const PhrNavSkeleton = () => {
  return (
    <PhrNav>
      <Stack spacing={2} borderRadius="0.25rem">
        {[...Array(8)].map((x, index) => (
          <Button
            key={index}
            variant="unstyled"
            paddingLeft="1rem !important"
            fontSize="16px"
            textAlign="left"
            borderRadius="0"
            borderLeft="4px solid white"
            backgroundColor="white"
            display="flex"
            justifyContent="space-between"
            height={{ base: '44px', xl: '40px' }}
          >
            <SkeletonText width="85px" noOfLines={1} />
          </Button>
        ))}
      </Stack>
    </PhrNav>
  );
};
