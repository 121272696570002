import { useState } from 'react';

import { AddressBookFolderEnum } from '../../../generated';

import { isFolderSelected } from '../utils/isFolderSelected';

type UseAddressBookFolders = {
  defaultSelectedFolders?: AddressBookFolderEnum[];
  visibleFolders?: AddressBookFolderEnum[];
};
const useAddressBookFolders = (props: UseAddressBookFolders) => {
  const { defaultSelectedFolders, visibleFolders } = props;

  const hasVisibleFolders = visibleFolders && visibleFolders.length !== 0;

  const [selectedAddressBookFolders, setSelectedFolders] = useState<AddressBookFolderEnum[]>(
    defaultSelectedFolders || [],
  );

  const onToggleAddressBookFolder = (folder: AddressBookFolderEnum) => {
    let filteredFolders: AddressBookFolderEnum[] = [];

    if (isFolderSelected(selectedAddressBookFolders, folder)) {
      if (selectedAddressBookFolders.length === 1 && hasVisibleFolders) return;

      filteredFolders = selectedAddressBookFolders.filter(
        (selectedFolder) => selectedFolder !== folder,
      );
    } else {
      filteredFolders = [...selectedAddressBookFolders, folder];
    }

    setSelectedFolders(filteredFolders);
  };

  return {
    selectedAddressBookFolders,
    onToggleAddressBookFolder,
  };
};

export { useAddressBookFolders };
