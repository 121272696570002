import React, { FC } from 'react';

import {
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Divider,
  Box,
} from '@chakra-ui/react';

type ConsultNotesAccordionItemProps = {
  title: string;
  children?: React.ReactNode;
};

const ConsultNotesAccordionItem: FC<ConsultNotesAccordionItemProps> = (props): JSX.Element => (
  <AccordionItem pr="3">
    <AccordionButton borderBottom="gray.100" py="3" pl="3" pr="3" rounded="sm">
      <Box flex="1" textAlign="left">
        {props.title}
      </Box>
      <AccordionIcon />
    </AccordionButton>
    <AccordionPanel>{props.children}</AccordionPanel>
    <Divider borderColor="gray.100" />
  </AccordionItem>
);

export { ConsultNotesAccordionItem };
