import React, { useEffect } from 'react';

import { PHRHealthCondition } from '../../../generated';
import { scrollToTop } from '../../../utils/scrollToTop';

import PhrItem, {
  SecondaryInfoComponent,
  PHRItemList,
  EmptyPHRItemListCard,
} from '../components/PhrItem';
import { getTime } from '../../../utils';

interface IProps {
  phrHealthConditions: PHRHealthCondition[];
  onView: (phrHealthCondition: PHRHealthCondition) => void;
}

const HealthConditionList = ({ phrHealthConditions = [], onView }: IProps) => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      {phrHealthConditions.length === 0 ? (
        <EmptyPHRItemListCard>No Health Conditions added</EmptyPHRItemListCard>
      ) : (
        <PHRItemList>
          {phrHealthConditions
            .sort((a, b) => getTime(b.diagnosedStartDate) - getTime(a.diagnosedStartDate))
            .map((item, index) => (
              <PhrItem
                key={index}
                name={item.name}
                isVerified={item.verified}
                onView={() => onView(item)}
                SecondaryInfo={
                  <SecondaryInfoComponent
                    startDate={item.diagnosedStartDate}
                    endDate={item.diagnosedEndDate}
                  />
                }
              />
            ))}
        </PHRItemList>
      )}
    </>
  );
};

export default HealthConditionList;
