import { Center, ModalBody, ModalFooter } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import { object } from 'yup';
import { ButtonSizeEnum } from '../../../../constants';
import { AuthClient, PHRForm, PHRFormsClient } from '../../../../generated';
import { IntakeForm } from '../../../../routes/heal/Consult/Provider/ConsultNotesComponent';
import { useFormQuestions } from '../../../../services/formQuestions/useFormQuestions';
import { SecondaryButton } from '../../../Buttons';
import { ButtonRow } from '../../../Buttons/ButtonRow';
import { PrimaryButton } from '../../../Buttons/PrimaryButton';
import { Form } from '../../../forms';
import { CenteredLoadingIndicator } from '../../../LoadingIndicator';
import { Result, TargetWindowEnum } from './Result';

interface IProps {
  intakeForm: IntakeForm;
  phrForm: PHRForm;
  loadPhrForms: () => void;
  onClose: () => void;
}

export const ResultView = ({ intakeForm, phrForm, loadPhrForms, onClose }: IProps) => {
  const { isLoadingQuestions, questionsError, formQuestions } = useFormQuestions({
    formID: phrForm.formID,
  });
  const printScreenRef = useRef<any>(null);
  const handlePrint = useReactToPrint({
    content: () => printScreenRef.current,
    documentTitle: 'Outpost Health',
  });

  const onSubmit = async () => {
    await new PHRFormsClient(new AuthClient()).verify(
      intakeForm.patient?.patientID ?? '',
      phrForm.phrFormID,
    );
    loadPhrForms();
    onClose();
  };

  return (
    <Formik
      initialValues={{ showAllOptions: true }}
      validationSchema={object({})}
      onSubmit={onSubmit}
    >
      {isLoadingQuestions ? (
        <Center height="100%">
          <CenteredLoadingIndicator />
        </Center>
      ) : (
        ({ values }) => (
          <Form error={questionsError || ''}>
            <ModalBody>
              <Result
                intakeForm={intakeForm}
                phrForm={phrForm}
                formQuestions={formQuestions!}
                showAllOptions={values.showAllOptions}
              />
            </ModalBody>
            <ModalFooter>
              <ButtonRow>
                <SecondaryButton onClick={onClose}>
                  <StyledFontAwesomeIcon icon={['far', 'chevron-left']} size="lg" />
                  Back
                </SecondaryButton>
                <SecondaryButton onClick={handlePrint}>
                  <StyledFontAwesomeIcon icon={['far', 'print']} size="lg" />
                  Print
                </SecondaryButton>
                {!phrForm.verified && (
                  <VerifyButton type="submit" size={ButtonSizeEnum.medium}>
                    <StyledFontAwesomeIcon icon={['fas', 'shield-alt']} size="lg" />
                    {'I Have verified this form'}
                  </VerifyButton>
                )}
              </ButtonRow>
              <ResultPrint ref={printScreenRef}>
                <Result
                  intakeForm={intakeForm}
                  phrForm={phrForm}
                  formQuestions={formQuestions!}
                  showAllOptions={values.showAllOptions}
                  window={TargetWindowEnum.Print}
                />
              </ResultPrint>
            </ModalFooter>
          </Form>
        )
      )}
    </Formik>
  );
};

const ResultPrint = styled.div`
  width: 100%;
  height: 100%;
  font-size: 0.8rem;
  @media screen {
    display: none;
  }
  @media print {
    display: block;
    background-color: white;
    @page {
      margin: 2cm 1.5cm;
      size: a4 !important;
    }
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

const VerifyButton = styled(PrimaryButton)`
  background-color: ${(props) => props.theme.Colors.Green};
  border: 0;
`;
