import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { ChevronRightIcon, MentalHealthLogo, PhysicalHealthLogo } from '../../../../assets/icons';
import { TrackerCategoryEnum } from '../../../../generated';
import { IPHRPatientCombined } from '../../../../store/patient/phr/phrReducers';
import MentalWellbeing from '../../mentalWellbeing/MentalWellbeing';
import PhysicalTrackersDashboard from '../../physicalTrackers/PhysicalTrackersDashboard';
import PatientVitals from '../../vitals';

interface IProps {
  phrPatient: IPHRPatientCombined;
  onBack?: () => void;
  renderDefaultHeader: (
    onCreate?: any,
    onBack?: (() => void) | undefined,
    title?: string | undefined,
    showDismiss?: boolean,
  ) => JSX.Element;
}

const PhrTrackersDashboard = (props: IProps) => {
  const { renderDefaultHeader, phrPatient } = props;
  const [activeView, setActiveView] = useState<TrackerCategoryEnum | undefined>(undefined);
  const handleBack = () => setActiveView(undefined);
  return (
    <>
      {activeView === undefined && (
        <Box w="100%" flex={1} bg="#f4f4f4" py={4}>
          {renderDefaultHeader(undefined, undefined, 'HEALTH TRACKERS')}
          <Flex justify={'center'}>
            <Flex flexWrap={'wrap'} w={{ base: '100%', md: '80%' }} align="center">
              <MenuItem
                onClick={() => setActiveView(TrackerCategoryEnum.MentalAndWellBeing)}
                Logo={MentalHealthLogo}
                title="Mental Health"
              />
              <MenuItem
                onClick={() => setActiveView(TrackerCategoryEnum.Physical)}
                Logo={PhysicalHealthLogo}
                title="Physical Health"
              />
            </Flex>
          </Flex>
        </Box>
      )}

      {activeView === TrackerCategoryEnum.Vitals && (
        <PatientVitals patient={phrPatient.patient!} onBack={handleBack} />
      )}
      {activeView === TrackerCategoryEnum.MentalAndWellBeing && (
        <MentalWellbeing
          renderDefaultHeader={renderDefaultHeader}
          phrPatient={phrPatient}
          onBack={handleBack}
        />
      )}
      {activeView === TrackerCategoryEnum.Physical && (
        <PhysicalTrackersDashboard
          renderDefaultHeader={renderDefaultHeader}
          phrPatient={phrPatient}
          onBack={handleBack}
        />
      )}
    </>
  );
};

const MenuItem = ({ Logo, title, onClick }) => {
  return (
    <Box
      borderRadius="18px"
      border="1px solid #0114315b"
      p={4}
      py={8}
      w={{ base: '100%', md: '45%' }}
      m={2}
      bg="#FFFFFF"
      cursor="pointer"
      onClick={onClick}
    >
      <Flex align="center" justify={'space-between'}>
        <HStack>
          <Logo fontSize={'32px'} />
          <Text variant="body" fontWeight="bold">
            {title}
          </Text>
        </HStack>
        <ChevronRightIcon />
      </Flex>
    </Box>
  );
};

export default PhrTrackersDashboard;
