import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SecondaryButton } from '../../../components/Buttons';
import { Country, NoteTypeEnum, RolesEnum } from '../../../generated';
import { clearPHRPatientErrorAction } from '../../../store/patient/phr/phrActions';
import { IPHRPatientCombined } from '../../../store/patient/phr/phrReducers';
import { phrNotesSelector } from '../../../store/patient/phr/phrSelectors';
import { getAllForPhrSection } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { isInRole } from '../../../utils/isInRole';
import { scrollToTop } from '../../../utils/scrollToTop';
import PhrCardSkeletonComponent from '../components/PhrCardSkeleton';
import { EmptyNotesCard } from './EmptyNotesCard';
import NotesForm from './NotesForm';
import NotesList from './NotesList';

interface IProps {
  phrPatient: IPHRPatientCombined;
  renderHeader: (addButton?: any) => any;
  inverse?: boolean;
  isInConsult?: boolean;
  phrConsultID?: string;
  selectedPatientCountry?: Country;
  setSelectedPatientCountry?: (country: Country) => void;
}

const PHRNotesComponent = ({
  phrPatient,
  renderHeader,
  isInConsult,
  phrConsultID,
  selectedPatientCountry,
  setSelectedPatientCountry,
}: IProps) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const phrNotes = phrNotesSelector(phrPatient?.phr?.phrNotes);
  const validPhrNotes = phrNotes.filter((x) => x.noteTypeEnum !== NoteTypeEnum.Referral);
  const [showForm, setShowForm] = useState<boolean>(false);
  const { isPhrLoading } = useSelector((state: AppState) => state.phrPatientState);

  const onCreate = () => {
    setShowForm(true);
  };

  useEffect(() => {
    if (phrPatient) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRNotes, phrPatient.patient.patientID));
    }
    // Re-rendering issue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearPHRPatientErrorAction());
    scrollToTop();
  }, [dispatch, showForm]);

  return (
    <>
      {!showForm && isInRole(currentUser, RolesEnum.Provider)
        ? renderHeader(() => <SecondaryButton onClick={onCreate}>Add Note</SecondaryButton>)
        : renderHeader()}
      {!showForm ? (
        isPhrLoading ? (
          <>
            {[...Array(3)].map((x, index) => (
              <PhrCardSkeletonComponent key={index} />
            ))}
          </>
        ) : (
          <NotesList
            phrNotes={
              isInConsult
                ? validPhrNotes
                : validPhrNotes.filter((x) => x.phrConsultID === phrConsultID)
            }
            ActionButton={
              <EmptyNotesCard
                toggleModal={onCreate}
                primaryText="No Notes Added Yet"
                secondaryText="Looks like you haven't added any notes yet"
              />
            }
          />
        )
      ) : (
        <NotesForm
          phrPatient={phrPatient}
          toggleView={() => setShowForm(false)}
          phrConsultID={phrConsultID}
          isInConsult={isInConsult}
          selectedPatientCountry={selectedPatientCountry}
          setSelectedPatientCountry={setSelectedPatientCountry}
        />
      )}
    </>
  );
};

export default PHRNotesComponent;
