import { AuthClient, CountryDefaultStoreDetails, StoresClient } from '../../../../generated';
import { httpRequest } from '../../../../utils';

const countryDefaultStorePost = async (countryDefaultStoreDetails: CountryDefaultStoreDetails) => {
  const storesClient = new StoresClient(new AuthClient());

  const defaultStore = await httpRequest(() =>
    storesClient.countryDefault(countryDefaultStoreDetails),
  );

  return defaultStore;
};

export { countryDefaultStorePost };
