import { ArrowBackIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Flex,
  IconButton,
  Image,
  Spacer,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';

import { ReactNode, useEffect, useState } from 'react';
import { use100vh } from 'react-div-100vh';
import { useDispatch, useSelector } from 'react-redux';
import { SupportIcon } from '../../../../assets/icons';
import { SupportCenterRoutes } from '../../../../constants';
import { AppState } from '../../../../store/root-reducers';
import { openSideMenu } from '../../../../store/sideMenu/sideMenuActions';
import useToggleSideMenu from '../hooks/useToggleSideMenu';
import { LogoutButton } from './LogoutButton';
import { MenuItem } from './MenuItem';
import { TopBar } from './TopBar';
import useTranslationComponent, { languages } from '../../../../hooks/useTranslationComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
  children: ReactNode;
  containerId?: string;
}

interface IMenuWrapperProps {
  isOpen: boolean;
  children: ReactNode;
  id?: string;
}

const MenuWrapper = ({ isOpen, children, id }: IMenuWrapperProps) => {
  const isBigScreen = useBreakpointValue({ base: false, lg: true });
  const dispatch = useDispatch();

  useEffect(() => {
    if (isBigScreen) {
      dispatch(openSideMenu());
    }
  }, [dispatch, isBigScreen]);

  return (
    <>
      <Box
        w={isOpen ? '245px' : isBigScreen ? '80px' : '0px'}
        overflow="hidden"
        transition=".2s ease"
        position="fixed"
        style={{ zIndex: 1010 }}
        id={id}
      >
        {children}
      </Box>
    </>
  );
};

export const SideMenu = ({ children, containerId }: IProps) => {
  const { i18n, t } = useTranslationComponent();
  const alwaysShowSidemenu = useBreakpointValue({ base: false, lg: true });
  const isFloatingSidemenu = useBreakpointValue({ base: false, '2xl': true });
  const isOpen = useSelector((state: AppState) => state.sideMenuState.isOpen);
  const { toggleSideMenu } = useToggleSideMenu();
  const height = use100vh();
  const dispatch = useDispatch();
  const [showLanguageOptions, setShowLanguageOptions] = useState(false);

  const handleShowHideLanguageOptions = () => setShowLanguageOptions((prev) => !prev);

  const changeLanguage = (e: any) => {
    i18n.changeLanguage(e.value);
    toggleSideMenu();
    handleShowHideLanguageOptions();
  };

  useEffect(() => {
    if (alwaysShowSidemenu) {
      dispatch(openSideMenu());
    }
  }, [alwaysShowSidemenu, dispatch]);

  return (
    <>
      <TopBar />
      <MenuWrapper isOpen={isOpen} id={containerId}>
        <Box
          padding="1.25rem"
          zIndex="1010"
          w="245px"
          my={{ base: 0, '2xl': '1rem' }}
          height={{ base: height || '100vh', '2xl': 'calc(100vh - 2rem)' }}
          maxHeight={{ base: 'unset', '2xl': '800px' }}
          backgroundColor="#FFFFFF"
        >
          <IconButton
            h="32px"
            w="32px"
            minW="32px"
            maxW="32px"
            display={alwaysShowSidemenu ? 'none' : 'block'}
            position="absolute"
            right={2}
            top={2}
            aria-label="close menu"
            borderRadius="full"
            onClick={toggleSideMenu}
            icon={<CloseIcon w={3} h={3} />}
          />
          <Center>
            <Image
              src={process.env.REACT_APP_COMPANY_LOGO_WHITE}
              alt="Outpost Logo"
              height="40px"
            />
          </Center>
          <Box
            sx={{
              '> div > div': {
                display: 'flex',
                flexDirection: 'column',
              },
            }}
          >
            <Flex
              flexDirection="column"
              maxH={isFloatingSidemenu ? '728px' : `calc(${height + 'px' || '100vh'} - 72px)`}
              height={`calc(${height + 'px' || '100vh'} - 72px ${
                isFloatingSidemenu ? ' - 2rem' : ''
              })`}
              sx={{
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
                '::-webkit-scrollbar': {
                  width: 0,
                  height: 0,
                },
              }}
            >
              <Stack mt={4} spacing={4} flexGrow={1}>
                {showLanguageOptions && (
                  <IconButton
                    justifyContent="flex-start"
                    aria-label="back"
                    backgroundColor="white"
                    icon={<ArrowBackIcon fontSize="24px" />}
                    onClick={handleShowHideLanguageOptions}
                  />
                )}
                {!showLanguageOptions && children}
                {!showLanguageOptions && (
                  <MenuItem
                    id="side-bar-help-center"
                    ariaLabel="help center"
                    routes={[SupportCenterRoutes.Support]}
                    Icon={SupportIcon}
                  >
                    {isOpen && t('Help Center')}
                  </MenuItem>
                )}
                {!alwaysShowSidemenu && (
                  <Stack>
                    {showLanguageOptions &&
                      languages.map((lng) => (
                        <MenuItem
                          id="side-bar-change-language"
                          ariaLabel={t('change language')}
                          Icon={() => (
                            <FontAwesomeIcon
                              fontSize="20px"
                              style={{ marginRight: '16px' }}
                              icon={['fal', 'globe']}
                            />
                          )}
                          onClick={() => changeLanguage(lng)}
                        >
                          {lng.label}
                        </MenuItem>
                      ))}
                    {!showLanguageOptions && (
                      <MenuItem
                        id="side-bar-change-language"
                        ariaLabel={t('change language')}
                        onClick={handleShowHideLanguageOptions}
                        Icon={() => (
                          <FontAwesomeIcon
                            fontSize="20px"
                            style={{ marginRight: '16px' }}
                            icon={['fal', 'globe']}
                          />
                        )}
                      >
                        {t('Change Language')}
                      </MenuItem>
                    )}
                  </Stack>
                )}
                <Spacer />
              </Stack>
              <LogoutButton>{t('Logout')}</LogoutButton>
            </Flex>
          </Box>
        </Box>
      </MenuWrapper>
    </>
  );
};
