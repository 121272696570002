import { Box, Center, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { PrescriptionIcon } from '../../../../../assets/icons';
import { Tag } from '../../../../../components/TagComponent';
import {
  AddressBook,
  InvoiceStoreItem,
  PatientPrescription,
  PHRMedication,
} from '../../../../../generated';
import { AppState } from '../../../../../store/root-reducers';
import { OutpostTheme } from '../../../../../themes/outpost';
import { formatCurrency } from '../../../../../utils';

import { SwitchControl } from '../../../../../components/forms/Switch';
import { PrescriptionsTypeEnum } from '../../enums';
import { arrayHasData, getPrescriptionData, getSum } from '../../utils';
import useTranslationComponent from '../../../../../hooks/useTranslationComponent';

interface IProps {
  selectMedicationToEdit: (medication: PHRMedication) => void;
  selectedAddress?: AddressBook;
  expressPharmacyAddress?: AddressBook;
  showCategorizedSummary?: boolean;
  prescriptionToExpressPharmacy?: PatientPrescription;
  prescriptionToSelectedPharmacy?: PatientPrescription;
  prescriptionToPatient?: PatientPrescription;
  sendToPatient?: boolean;
  onRemoveMedication: (
    phrMedication: PHRMedication,
    prescriptionType: PrescriptionsTypeEnum,
  ) => void;
  handleSendPhrMedicationToPatient: (
    prescriptionType: PrescriptionsTypeEnum,
    phrMedication: PHRMedication,
  ) => Promise<void>;
  handleUnSendPhrMedicationToPatient: (
    prescriptionType: PrescriptionsTypeEnum,
    phrMedication: PHRMedication,
  ) => Promise<void>;
  handleUpdatePrescriptionToEditTypeEnum: (prescriptionType?: PrescriptionsTypeEnum) => void;
}

const showSwitchOnPatientPrescription = (
  prescriptionType: PrescriptionsTypeEnum,
  totalAmount?: number,
  alwaysShow?: boolean,
) => {
  if (alwaysShow) return true;
  if (prescriptionType !== PrescriptionsTypeEnum.PatientOnly) return true;
  if (totalAmount && totalAmount > 0) return true;

  return false;
};

export function AddedMedications({
  selectMedicationToEdit,
  selectedAddress,
  expressPharmacyAddress,
  showCategorizedSummary,
  prescriptionToExpressPharmacy,
  prescriptionToSelectedPharmacy,
  prescriptionToPatient,
  onRemoveMedication,
  handleSendPhrMedicationToPatient,
  handleUnSendPhrMedicationToPatient,
  handleUpdatePrescriptionToEditTypeEnum,
}: IProps) {
  const { t } = useTranslationComponent(['phr']);
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  const expressPharmacyPrescriptionData = getPrescriptionData(prescriptionToExpressPharmacy);
  const selectedPharmacyPrescriptionData = getPrescriptionData(prescriptionToSelectedPharmacy);
  const patientPrescriptionData = getPrescriptionData(prescriptionToPatient);

  const medicationsAdded =
    arrayHasData(expressPharmacyPrescriptionData.sortedMedicationsByName) ||
    arrayHasData(selectedPharmacyPrescriptionData.sortedMedicationsByName) ||
    arrayHasData(patientPrescriptionData.sortedMedicationsByName);

  const prescriptionsTotalAmount = getSum([
    selectedPharmacyPrescriptionData.totalAmount,
    expressPharmacyPrescriptionData.totalAmount,
  ]);

  const hasPrescriptionsTotalAmount = !!prescriptionsTotalAmount && prescriptionsTotalAmount > 0;

  const currencyEnum =
    selectedPharmacyPrescriptionData.currencyEnum || expressPharmacyPrescriptionData.currencyEnum;

  const taxLabel =
    selectedPharmacyPrescriptionData.taxLabel || expressPharmacyPrescriptionData.taxLabel;

  const hasAdditionalItems =
    arrayHasData(selectedPharmacyPrescriptionData.additionalItems) ||
    arrayHasData(expressPharmacyPrescriptionData.additionalItems) ||
    arrayHasData(patientPrescriptionData.additionalItems);

  if (!medicationsAdded) {
    return (
      <Flex width="100%" justifyContent="center" height="100%">
        <Center
          borderRadius="0.25rem"
          pt={2}
          px={8}
          pb={10}
          flexDirection="column"
          backgroundColor="#D2D7E826"
        >
          <PrescriptionIcon fontSize="48px" />
          <Text mb="5" mt="2" textAlign="center" fontWeight="500">
            {t('No Prescriptions Added Yet')}
          </Text>
          <Text textAlign="center" fontSize="12px">
            {t(
              'If a medication is not in stock at the Pharmacy, you can still select it and that prescription will be sent directly to the patient only.',
            )}
          </Text>
        </Center>
      </Flex>
    );
  }

  return (
    <>
      {showCategorizedSummary ? (
        <Stack width="100%" spacing="5">
          {selectedAddress &&
            arrayHasData(selectedPharmacyPrescriptionData.sortedMedicationsByName) && (
              <Box>
                <Box>
                  <Tag
                    text={t(`Medications to be sent directly to the selected address`, {
                      address: selectedAddress?.name,
                    })}
                    containerProps={{ bgColor: '#e0f6f4' }}
                  />
                </Box>
                {selectedPharmacyPrescriptionData.sortedMedicationsByName.map((medication) =>
                  renderMedication(medication, PrescriptionsTypeEnum.SelectedPharmacy),
                )}
                <Box mt="4">
                  {renderAdditionalItems(selectedPharmacyPrescriptionData.additionalItems)}
                </Box>
              </Box>
            )}
          {expressPharmacyAddress &&
            arrayHasData(expressPharmacyPrescriptionData.sortedMedicationsByName) && (
              <Box>
                <Box>
                  <Tag
                    text={t(`Medications to be sent directly to pharmacy`, {
                      pharmacy: expressPharmacyAddress?.name,
                    })}
                    containerProps={{ bgColor: 'rgb(255, 194, 0)' }}
                  />
                </Box>
                {expressPharmacyPrescriptionData.sortedMedicationsByName.map((medication) =>
                  renderMedication(medication, PrescriptionsTypeEnum.ExpressPharmacy),
                )}
                <Box mt="4">
                  {renderAdditionalItems(expressPharmacyPrescriptionData.additionalItems)}
                </Box>
              </Box>
            )}
          {arrayHasData(patientPrescriptionData.sortedMedicationsByName) && (
            <Box>
              <Box>
                <Tag
                  text={t(`Medications to be sent directly to patient`, {
                    patient: prescriptionToPatient?.customerName,
                  })}
                  containerProps={{ bgColor: 'rgb(211, 211, 211)' }}
                />
              </Box>
              {patientPrescriptionData.sortedMedicationsByName.map((medication) =>
                renderMedication(medication, PrescriptionsTypeEnum.PatientOnly),
              )}
              <Box mt="4">{renderAdditionalItems(patientPrescriptionData.additionalItems)}</Box>
            </Box>
          )}
        </Stack>
      ) : (
        <Stack width="100%">
          {selectedPharmacyPrescriptionData.sortedMedicationsByName.map((medication) =>
            renderMedication(medication, PrescriptionsTypeEnum.SelectedPharmacy),
          )}
          {expressPharmacyPrescriptionData.sortedMedicationsByName.map((medication) =>
            renderMedication(medication, PrescriptionsTypeEnum.ExpressPharmacy),
          )}
          {patientPrescriptionData.sortedMedicationsByName.map((medication) =>
            renderMedication(medication, PrescriptionsTypeEnum.PatientOnly),
          )}
          {hasAdditionalItems && (
            <Box mt="4">
              {renderAdditionalItems(selectedPharmacyPrescriptionData.additionalItems)}
              {renderAdditionalItems(expressPharmacyPrescriptionData.additionalItems)}
              {renderAdditionalItems(patientPrescriptionData.additionalItems)}
            </Box>
          )}
        </Stack>
      )}
      {hasPrescriptionsTotalAmount && (
        <Flex
          py={5}
          px={4}
          alignItems="center"
          justifyContent="space-between"
          backgroundColor="#F7F9FF"
          borderRadius="0.25rem"
          borderColor="#4163CE14"
          mt="3"
        >
          <Box>
            <Text fontWeight="400" fontSize="12px">
              {t('Total')}
            </Text>
            <Text fontSize="12px" color="gray.600">
              {t('Includes')} {taxLabel}
            </Text>
          </Box>
          <Text fontWeight="400" fontSize={OutpostTheme.FontSizes.body}>
            {currentUser && currencyEnum
              ? formatCurrency(prescriptionsTotalAmount, currencyEnum, currentUser.cultureInfo)
              : '-'}
          </Text>
        </Flex>
      )}
    </>
  );

  function renderMedication(
    medication: PHRMedication,
    prescriptionType: PrescriptionsTypeEnum,
  ): JSX.Element {
    return (
      <Flex
        key={medication.phrMedicationID}
        direction="column"
        bg="#FFFFFF"
        borderBottom="1px solid rgb(211, 211, 211)"
        width="100%"
        justifyContent="space-between"
        p="4"
      >
        <Box>
          <Text mb={3} fontSize={OutpostTheme.FontSizes.body} fontWeight="semibold">
            {medication.name}
          </Text>
          {!medication.medicationID ? (
            <Box>
              <Text color="#011431A6" fontSize="12px">
                {medication.notes}
              </Text>
              <HStack spacing={4} mt="3" justifyContent="flex-end">
                <Text
                  onClick={() => {
                    selectMedicationToEdit(medication);
                    handleUpdatePrescriptionToEditTypeEnum(prescriptionType);
                  }}
                  fontSize="12px"
                  color="#3E1191"
                  cursor="pointer"
                >
                  {t('Edit')}
                </Text>
                <Text
                  onClick={() => onRemoveMedication(medication, prescriptionType)}
                  fontSize="12px"
                  color="red.500"
                  cursor="pointer"
                >
                  {t('Delete')}
                </Text>
              </HStack>
              {showSwitchOnPatientPrescription(
                prescriptionType,
                medication.totalAmount,
                !!selectedAddress?.addressBookID,
              ) && (
                <HStack mt="3">
                  <Text fontSize="14px" color="#011431A6">
                    {t('Send Directly to Patient')}
                  </Text>
                  <Box ml="3">
                    <SwitchControl
                      colorScheme="teal"
                      name="sendToPatient"
                      isChecked={prescriptionType === PrescriptionsTypeEnum.PatientOnly}
                      onChange={() =>
                        prescriptionType === PrescriptionsTypeEnum.PatientOnly
                          ? handleUnSendPhrMedicationToPatient(prescriptionType, medication)
                          : handleSendPhrMedicationToPatient(prescriptionType, medication)
                      }
                    />
                  </Box>
                </HStack>
              )}
            </Box>
          ) : (
            <Box>
              {(medication.dosage || medication.frequency) && (
                <Box>
                  <Text color="#011431A6" fontSize="12px">
                    {medication.instructions}
                  </Text>
                  <Text color="#011431A6" fontSize="12px" mb="3">
                    {medication.dispensingInstructions}
                  </Text>
                </Box>
              )}
              <HStack justifyContent="space-between">
                {prescriptionType !== PrescriptionsTypeEnum.PatientOnly &&
                  !!selectedAddress?.addressBookID && (
                    <Box>
                      {medication.totalAmount && currentUser && (
                        <Text color="#011431A6" fontSize="12px">
                          Price:{' '}
                          {formatCurrency(
                            medication.totalAmount,
                            currencyEnum,
                            currentUser.cultureInfo,
                          ) || '-'}
                        </Text>
                      )}
                    </Box>
                  )}

                <HStack spacing={4} ml="auto">
                  <Text
                    onClick={() => {
                      selectMedicationToEdit(medication);
                      handleUpdatePrescriptionToEditTypeEnum(prescriptionType);
                    }}
                    fontSize="12px"
                    color="#3E1191"
                    cursor="pointer"
                  >
                    {t('Edit')}
                  </Text>
                  <Text
                    onClick={() => onRemoveMedication(medication, prescriptionType)}
                    fontSize="12px"
                    color="red.500"
                    cursor="pointer"
                  >
                    {t('Delete')}
                  </Text>
                </HStack>
              </HStack>
              {showSwitchOnPatientPrescription(
                prescriptionType,
                medication.totalAmount,
                !!selectedAddress?.addressBookID,
              ) && (
                <HStack mt="3">
                  <Text fontSize="14px" color="#011431A6">
                    {t('Send Directly to Patient')}
                  </Text>
                  <Box ml="3">
                    <SwitchControl
                      colorScheme="teal"
                      name="sendToPatient"
                      isChecked={prescriptionType === PrescriptionsTypeEnum.PatientOnly}
                      onChange={() =>
                        prescriptionType === PrescriptionsTypeEnum.PatientOnly
                          ? handleUnSendPhrMedicationToPatient(prescriptionType, medication)
                          : handleSendPhrMedicationToPatient(prescriptionType, medication)
                      }
                    />
                  </Box>
                </HStack>
              )}
            </Box>
          )}
        </Box>
      </Flex>
    );
  }

  function renderAdditionalItems(additionalItems: InvoiceStoreItem[]): JSX.Element {
    return (
      <Stack>
        {additionalItems
          .filter((additionalItem) => additionalItem.storeItem)
          .map((item) => (
            <Text fontSize={OutpostTheme.FontSizes.body} fontWeight="500">
              + {item.storeItem?.item?.name}:{' '}
              {item.storeItem
                ? formatCurrency(
                    item.storeItem.retailAmount,
                    item.storeItem.currencyEnum,
                    currentUser!.cultureInfo,
                  )
                : '-'}
            </Text>
          ))}
      </Stack>
    );
  }
}
