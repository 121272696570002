import { useQuery } from 'react-query';
import { AuthClient, ProviderOnboardingClient, ProviderOnboardingDTO } from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

export const useProviderOnboardingByUserDetailID = (
  userDetailID: string,
  hasSubmitted: boolean,
) => {
  const {
    isLoading: isLoadingProviderOnboardingByUserDetailID,
    data: providerOnboardingDetails,
    error: providerOnboardingDetailsError,
  } = useQuery<ProviderOnboardingDTO, string>(
    [QueryKeys.ProviderOnboardingByUserDetailID, hasSubmitted],
    async () =>
      httpRequest(() =>
        new ProviderOnboardingClient(new AuthClient()).byUserDetailID(userDetailID),
      ),
  );

  return {
    isLoadingProviderOnboardingByUserDetailID,
    providerOnboardingDetails,
    providerOnboardingDetailsError,
  };
};
