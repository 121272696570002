import { Box, Grid, GridItem } from '@chakra-ui/react';
import { useState } from 'react';
import FadeInComponent from '../../../components/animations/FadeInComponent';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import { IPatient, PHRTracker, TrackerTypeEnum } from '../../../generated';
import StepCounterComponent from '../trackers/stepCounter/StepCounter';
import { renderHeader } from './components/Header';
import PhysicalTrackerItem from './components/PhysicalTrackerItem';
import usePhysicalTrackers, { usePhysicalTrackerValue } from './hooks/usePhysicalTrackers';

interface IProps {
  patient?: IPatient;
  trackers: PHRTracker[];
  onBack?: () => void;
  renderDefaultHeader?: any;
}
const PhysicalTrackers = ({ patient, trackers, onBack, renderDefaultHeader }: IProps) => {
  const { isLoading, physicalTrackers, phrPatient } = usePhysicalTrackers(patient, trackers);
  const [currentTrackerType, setCurrentTrackerType] = useState<TrackerTypeEnum | null>(null);

  const { phrStepCounters } = usePhysicalTrackerValue({
    patient: phrPatient?.phr,
  });

  const rendeStepCounterHeader = renderHeader(() => setCurrentTrackerType(null), 'Step Counter');

  const renderTracker = () => {
    switch (currentTrackerType) {
      case TrackerTypeEnum.StepCounter:
        return (
          <StepCounterComponent
            onClose={() => setCurrentTrackerType(null)}
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            patient={phrPatient?.phr!}
            renderHeader={rendeStepCounterHeader}
          />
        );
    }
  };

  const getTrackerDisplayValue = (currentTrackerType) => {
    switch (currentTrackerType) {
      case TrackerTypeEnum.StepCounter:
        const step = phrStepCounters.sort()[0];
        return {
          value: `${step?.stepCount || '0'} steps`,
          date: step?.upsertDate,
        };
    }
  };

  const renderTrackerList = () => {
    const colors = ['#fd7024', '#7BBFB7', '#FFC235', '#F0E9D4', '#3FA884', '#FF84A1'];

    return (
      <>
        <Box bgColor="#f4f4f4" height="100%" overflowY="hidden" pt={4}>
          {onBack
            ? renderDefaultHeader(undefined, onBack, 'Physical Health', true)
            : renderDefaultHeader(undefined, undefined, 'Physical Health', true)}

          <Box
            height={{ base: 'calc(100vh - 4rem)', md: '580px' }}
            paddingX={{ base: '1rem', md: '70px' }}
            overflowY="auto"
            w="100%"
          >
            <FadeInComponent>
              {physicalTrackers && (
                <Grid
                  templateColumns={{ base: '1fr', md: '1fr 1fr' }}
                  gridTemplateRows={{ base: 'auto', md: 'repeat(10, 75px)' }}
                  gridGap={4}
                >
                  <GridItem
                    gridColumn={{ base: 'auto', md: '1/2' }}
                    gridRow={{ base: 'auto', md: '1/4' }}
                  >
                    <PhysicalTrackerItem
                      type={TrackerTypeEnum.StepCounter}
                      getTrackerDisplayValue={getTrackerDisplayValue}
                      color={colors[0]}
                      onClick={() => setCurrentTrackerType(TrackerTypeEnum.StepCounter)}
                      physicalTrackers={physicalTrackers}
                    />
                  </GridItem>
                </Grid>
              )}
            </FadeInComponent>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <Box w="100%" h="100%" p={4}>
          <CenteredLoadingIndicator />
        </Box>
      ) : (
        <>
          {currentTrackerType ? (
            <Box bg="#f4f4f4" h="100%" w="100%" p={4}>
              {renderTracker()}
            </Box>
          ) : (
            renderTrackerList()
          )}
        </>
      )}
    </>
  );
};

export default PhysicalTrackers;
