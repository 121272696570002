import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PHRTrackerBloodPressureDTO } from '../../../../generated';
import { IPHRPatientEnhanced } from '../../../../store/patient/phr/phrReducers';
import { phrBloodPressuresSelector } from '../../../../store/patient/phr/phrSelectors';
import { getAllForPhrSection } from '../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import PhrItem, { SecondaryInfoComponent } from '../../components/PhrItem';
import { VitalsTracker } from '../../vitals/components/PatientVitalsCard/usePatientVitalsCard';
import TrackerView from '../TrackerView';
import BloodPressureChart from './BloodPressureChart';
import BloodPressureForm from './BloodPressureForm';

interface IProps {
  patient: IPHRPatientEnhanced;
  showSingleTrackerForm?: (id: ReduxPHRCategoryEnum, tracker: VitalsTracker) => void;
  renderHeader: (addButton?: any, onBack?: () => void, title?: string) => any;
  defaultBack?: any;
  defaultTitle?: string;
}

const BloodPressureComponent: React.FC<IProps> = ({
  patient,
  renderHeader,
  showSingleTrackerForm,
  defaultBack,
  defaultTitle,
}) => {
  const dispatch = useDispatch();
  const phrBloodPressures = phrBloodPressuresSelector(patient.phrBloodPressures);

  useEffect(() => {
    if (patient) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRBloodPressures, patient.patientID));
    }
  }, [dispatch, patient]);

  const thinView = (x: PHRTrackerBloodPressureDTO, onView?: (data: any) => void) => (
    <>
      <PhrItem
        name={`${x.systolic} / ${x.diastolic} mmHg`}
        isVerified={x.verified}
        onView={() => {
          if (onView) {
            onView(x);
          }
        }}
        SecondaryInfo={
          <SecondaryInfoComponent
            startDate={moment(x.trackerDate).toDate()}
            startDateTitle="Created on"
            showTime
          />
        }
      />
    </>
  );

  return (
    <TrackerView
      renderHeader={renderHeader}
      phrCategoryEnum={ReduxPHRCategoryEnum.PHRBloodPressures}
      patient={patient}
      trackerData={phrBloodPressures}
      thinView={thinView}
      Chart={BloodPressureChart}
      Form={BloodPressureForm}
      createText="Blood Pressure"
      showSingleTrackerForm={showSingleTrackerForm}
      defaultBack={defaultBack}
      defaultTitle={defaultTitle}
    />
  );
};

export default BloodPressureComponent;
