import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PHRTrackerSleepDTO } from '../../../../../generated';
import { IPHRPatientEnhanced } from '../../../../../store/patient/phr/phrReducers';
import { phrSleepsSelector } from '../../../../../store/patient/phr/phrSelectors';
import { getAllForPhrSection } from '../../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../../store/types';
import PhrItem, { SecondaryInfoComponent } from '../../../components/PhrItem';
import { getSleepStatusByValue } from '../hooks/useTrackers';
import MoodTrackerView from '../MoodTrackerView';
import SleepChart from './SleepChart';
import SleepForm from './SleepForm';

interface IProps {
  patient: IPHRPatientEnhanced;
  renderHeader: (addButton?: any, optionalBack?: any) => any;
  onClose: () => void;
}

const SleepComponent = ({ patient, renderHeader, onClose }: IProps) => {
  const dispatch = useDispatch();
  const sleepRecords = phrSleepsSelector(patient.phrSleeps);

  useEffect(() => {
    if (patient) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRSleeps, patient.patientID));
    }
  }, [dispatch, patient]);

  const thinView = (sleep: PHRTrackerSleepDTO, onView?: (data: any) => void) => (
    <>
      <PhrItem
        name={getSleepStatusByValue(sleep.sleepValue)}
        isVerified={sleep.verified}
        onView={() => {
          if (onView) {
            onView(sleep);
          }
        }}
        SecondaryInfo={
          <SecondaryInfoComponent
            startDate={sleep.trackerDate}
            startDateTitle="Tracked on:"
            showTime
          />
        }
      />
    </>
  );

  return (
    <MoodTrackerView
      renderHeader={renderHeader}
      phrCategoryEnum={ReduxPHRCategoryEnum.PHRSleeps}
      patient={patient}
      trackerData={sleepRecords}
      thinView={thinView}
      Chart={SleepChart}
      Form={SleepForm}
      createText="Sleep"
      onClose={onClose}
    />
  );
};

export default SleepComponent;
