import { AppFeaturesClient, AuthClient } from '../../generated';
import { httpRequest } from '../../utils';
import { OHThunkResult } from '../root-reducers';
import { getAppFeaturesActions } from './appFeaturesActions';

export function getAppFeatures(): OHThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    return new Promise<void>(async () => {
      dispatch(getAppFeaturesActions.request());
      const appFeaturesClient = new AppFeaturesClient(new AuthClient());
      try {
        const result = await httpRequest(() => appFeaturesClient.all());
        dispatch(getAppFeaturesActions.success(result));
      } catch (err) {
        dispatch(getAppFeaturesActions.failure(err as any));
      }
    });
  };
}
