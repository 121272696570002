import React from 'react';

type ToggleSwitchContextType = {
  activeId: string;
  handleClick: (id: string) => void;
  active: boolean | string;
  onClick?: () => void;
};

export const toggleSwitchContext = React.createContext<ToggleSwitchContextType>({
  activeId: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleClick: (id) => {},
  active: '',
});
