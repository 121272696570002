import * as Yup from 'yup';

const checkPassword = (() => {
  return Yup.string()
    .trim()
    .required('A password is required')
    .min(8, 'Password must be at least 8 characters long')
    .max(128, 'Password must be at most 128 characters')
    .matches(/(?=.*[^a-zA-Z0-9])/, 'At least one non-alphanumeric character is required')
    .matches(/(?=.*[A-Z])/, 'At least one uppercase letter is required')
    .matches(/(?=.*[a-z])/, 'At least one lowercase letter is required')
    .matches(/(?=.*\d)/, 'At least one digit is required');
})();

const checkConfirmPassword = (() => {
  return Yup.string()
    .trim()
    .required('Confirmation password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match');
})();

export { checkPassword, checkConfirmPassword };
