import { useQuery } from 'react-query';

import { AuthClient, ConsultTypeEnum, SearchClient } from '../../../generated';
import { httpRequest } from '../../../utils';

import { QueryKeys } from '../../keys';

type UseAvailableConsultTypesProps = {
  storeID?: string;
  disabled?: boolean;
};

const useAvailableConsultTypes = ({ storeID, disabled }: UseAvailableConsultTypesProps) => {
  const {
    isLoading: isLoadingConsultTypes,
    data: consultTypes,
    error: consultTypesError,
  } = useQuery<ConsultTypeEnum[], string>(
    [QueryKeys.AvailableConsultTypes, storeID, disabled],
    async () =>
      httpRequest(() =>
        new SearchClient(new AuthClient()).availableConsultTypesByStoreID(storeID!),
      ),
    { enabled: !!storeID && !disabled },
  );

  return {
    isLoadingConsultTypes,
    consultTypes,
    consultTypesError,
  };
};

export { useAvailableConsultTypes };
