import { useEffect, useMemo, useState } from 'react';

export enum TextQuestionType {
  Short = 'ShortText',
  Long = 'LongText',
}

const SHORT_LIMIT = 100;
const LONG_LIMIT = 500;

interface IProps {
  type: TextQuestionType;
}

export const useTextQuestion = ({ type }: IProps) => {
  const limit = useMemo(() => (type === TextQuestionType.Long ? LONG_LIMIT : SHORT_LIMIT), [type]);
  const [answer, setAnswer] = useState<string>('');
  const [availableCharacters, setAvailableCharacters] = useState<number>(limit);

  useEffect(() => {
    setAvailableCharacters(answer ? limit - answer.length : limit);
  }, [answer, limit]);

  return { answer, setAnswer, availableCharacters, limit };
};
