import React from 'react';
import { Grid, Flex, Text } from '@chakra-ui/react';

import { BinIcon } from '../../../assets/icons';
import { ButtonSizeEnum } from '../../../constants';

import Modal from '../../../components/Modals/Modal';
import { DeleteButton, SecondaryButton } from '../../../components/Buttons';
import { OutpostTheme } from '../../../themes/outpost';

const PhrItemDeleteModal = ({ isOpen, onDismiss, onDelete }) => {
  return (
    <Modal
      isOpen={isOpen}
      title="DELETE RECORD"
      onDismiss={onDismiss}
      size="md"
      modalHeaderProps={{ fontSize: '14px', fontWeight: 'semibold' }}
    >
      <Flex justify="center" align="center" flexDir="column" height="100%">
        <Grid
          marginBottom="2rem"
          width="75px"
          height="75px"
          borderRadius="full"
          bgColor="#0202020D"
          placeItems="center"
        >
          <BinIcon fontSize="32px" stroke="#020202" />
        </Grid>
        <Text
          maxW="300px"
          textAlign="center"
          fontSize={OutpostTheme.FontSizes.body}
          fontWeight="light"
          color="#011431"
        >
          Do You Want To Delete This Health Record?
        </Text>
        <Text
          maxW="300px"
          textAlign="center"
          fontSize={OutpostTheme.FontSizes.body}
          fontWeight="light"
          color="#011431"
        >
          It Will Be Deleted Permanently
        </Text>
        <Flex marginTop="2.5rem">
          <DeleteButton
            size={ButtonSizeEnum.medium}
            fontWeight="normal"
            marginRight="1rem"
            onClick={onDelete}
          >
            Delete
          </DeleteButton>
          <SecondaryButton size={ButtonSizeEnum.medium} borderRadius="full" onClick={onDismiss}>
            Cancel
          </SecondaryButton>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default PhrItemDeleteModal;
