// import { useInterval } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import {
  AuthClient,
  ConsultsClient,
  ConsultStatusEnum,
  ConsultTagTypeEnum,
  Country,
  IPHRConsult,
  PHRConsultsClient,
  PHRConsultTag,
  PHRConsultTagsClient,
  PHRConsultTypeEnum,
  PHRForm,
  PHRFormsClient,
  Provider,
  ProviderSettingEnum,
  RolesEnum,
} from '../../../../../../generated';
import { AppState } from '../../../../../../store/root-reducers';
import { httpRequest } from '../../../../../../utils';
import { isInRole } from '../../../../../../utils/isInRole';
import { providerHasSetting } from '../../../../../../utils/providerHasSetting';

export interface ValidationMessages {
  subjective?: string;
  objective?: string;
  diagnosis?: string;
  plan?: string;
  preceptor?: string;
  attestation?: string;
  complaint?: string;
  nurseNotes?: string;
}

interface IProps {
  isInConsult: boolean;
  countries: Country[];
  patientID: string;
  consultID?: string;
  phrConsultID?: string;
  showEndCallModal: boolean;
  setOpenIndexes: (val: number[]) => void;
}

enum SectionAreasIndexEnum {
  Subjective = 1,
  Objective = 2,
  Diagnosis = 3,
  CarePlan = 4,
  Resident = 5,
  Preceptor = 6,
  NurseNotes = 1, //They can never be mounted at the same time,
}

const FORM_DEBOUNCE_TIMEOUT = 3000; // milliseconds

export const renderComplaints = (consultTags?: PHRConsultTag[], complaint?: string) => {
  let complaints;
  if (consultTags) {
    const complainTags = consultTags
      .filter((x) => x.consultTagTypeEnum === ConsultTagTypeEnum.Complaint)
      .map((x) => x.tag?.description.trim());
    complaints = [...complainTags];
  }
  if (complaint) {
    complaints = [...complaints, complaint];
  }
  return !complaints ? '-' : complaints.join(', ');
};

export default function usePhrConsult({
  countries,
  patientID,
  consultID,
  phrConsultID,
  setOpenIndexes,
}: IProps) {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data!);
  const provider = useSelector((state: AppState) => state.providerState.provider!);
  const [phrConsult, setPhrConsult] = useState<IPHRConsult>();
  const [phrConsultIsLoading, setPhrConsultIsLoading] = useState<boolean>(false);
  const [loadingPhrConsultError, setLoadingPhrConsultError] = useState<string>('');
  const [isUpdatingPhrConsult, setUpdatingPhrConsult] = useState<boolean>(false);
  const [complaint, setComplaint] = useState<string>('');
  const [subjective, setSubjective] = useState<string>('');
  const [objective, setObjective] = useState<string>('');
  const [diagnosis, setDiagnosis] = useState<string>('');
  const [plan, setPlan] = useState<string>('');
  const [phrConsultTags, setPhrConsultTags] = useState<PHRConsultTag[]>([]);
  const [attestation, setAttestation] = useState<string>('');
  const [attestationProvider, setAttestationProvider] = useState<Provider | null>(null);
  const [validationMessages, setValidationMessages] = useState<ValidationMessages | null>(null);
  const [updatingPhrError, setUpdatingPhrError] = useState<string>('');
  const [patientCountry, setPatientCountry] = useState<Country>();
  const [consultDate, setConsultDate] = useState<Date>();
  const [isAttested, setAttested] = useState<boolean>(false);
  const [phrForms, setPhrForms] = useState<PHRForm[]>([]);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(true);
  const [nurseNotes, setNurseNotes] = useState<string>('');

  const isNurse = isInRole(currentUser, RolesEnum.Nurse);

  useEffect(() => {
    if (attestationProvider) {
      setPhrConsult((prev) => {
        if (prev) {
          prev.attestationUserDetailID = attestationProvider.userDetailID;
        }
        return prev;
      });
    }
  }, [attestationProvider]);

  const validate = (isResident: boolean) => {
    const openIndexes: number[] = [];
    const hasDiagnosisTags = () =>
      phrConsultTags.find((x) => x.consultTagTypeEnum === ConsultTagTypeEnum.Diagnosis);
    let hasErrors = false;
    const errors = {
      subjective: '',
      objective: '',
      diagnosis: '',
      plan: '',
      preceptor: '',
      nurseNotes: '',
    };

    if (isNurse) {
      if (!nurseNotes || nurseNotes.length === 0) {
        openIndexes.push(SectionAreasIndexEnum.NurseNotes);
        errors.nurseNotes = 'Nurse notes is required';
        hasErrors = true;
      }
    } else {
      if (!subjective || subjective.length === 0) {
        openIndexes.push(SectionAreasIndexEnum.Subjective);
        errors.subjective = 'Subjective is required';
        hasErrors = true;
      }

      if (!objective || objective.length === 0) {
        openIndexes.push(SectionAreasIndexEnum.Objective);
        errors.objective = 'Objective is required';
        hasErrors = true;
      }

      if ((!diagnosis || diagnosis.length === 0) && !hasDiagnosisTags()) {
        openIndexes.push(SectionAreasIndexEnum.Diagnosis);
        errors.diagnosis = 'Diagnosis is required';
        hasErrors = true;
      }

      if (!plan || plan.length === 0) {
        openIndexes.push(SectionAreasIndexEnum.CarePlan);
        errors.plan = 'Plan is required';
        hasErrors = true;
      }

      if (isResident && (!phrConsult?.attestationUserDetailID || !attestationProvider)) {
        openIndexes.push(SectionAreasIndexEnum.Resident, SectionAreasIndexEnum.Preceptor);
        errors.preceptor = 'A preceptor is required';
        hasErrors = true;
      }
    }

    return { hasErrors, errors, openIndexes };
  };

  const completeConsult = async (isDraft: boolean) => {
    setShouldBlockNavigation(false);
    setValidationMessages(null);
    const isResident = providerHasSetting(
      currentUser,
      provider,
      ProviderSettingEnum.ResidentProvider,
    );
    const isPreceptor = providerHasSetting(
      currentUser,
      provider,
      ProviderSettingEnum.PreceptorProvider,
    );

    const { hasErrors, errors, openIndexes } = validate(isResident);

    if (!isDraft && hasErrors) {
      setValidationMessages(errors);
      setOpenIndexes(openIndexes);
      return false;
    } else {
      try {
        setUpdatingPhrConsult(true);
        if (!isDraft && consultID) {
          const consultsClient = new ConsultsClient(new AuthClient());
          const consultToUpdate = await httpRequest(() => consultsClient.consultsGet(consultID));
          consultToUpdate.consultStatusEnum = ConsultStatusEnum.Completed;
          await httpRequest(() => consultsClient.consultsPut(consultToUpdate));
        }

        const phrConsultsClient = new PHRConsultsClient(new AuthClient());
        let phrConsult;

        if (consultID) {
          phrConsult = await httpRequest(() => phrConsultsClient.byConsultID(patientID, consultID));
        } else if (phrConsultID) {
          phrConsult = await httpRequest(() =>
            phrConsultsClient.pHRConsultsGet(patientID, phrConsultID),
          );
        }

        phrConsult.complaint = complaint;
        phrConsult.subjective = subjective;
        phrConsult.diagnosis = diagnosis;
        phrConsult.objective = objective;
        phrConsult.plan = plan;
        phrConsult.providerID = provider?.providerID;
        phrConsult.consultDate = consultDate;
        phrConsult.nurseNote = nurseNotes;
        phrConsult.phrConsultTypeEnum = isNurse
          ? PHRConsultTypeEnum.NurseNote
          : PHRConsultTypeEnum.SOAPNote;

        if (isResident && attestationProvider) {
          phrConsult.attestationUserDetailID = attestationProvider.userDetailID;
          phrConsult.isAttested = false;
        }

        if (isPreceptor) {
          phrConsult.attestation = attestation;
          phrConsult.isAttested = isAttested;
          phrConsult.attestationDate = new Date();
        }

        const updatedPhrConsult = await httpRequest(() =>
          phrConsultsClient.pHRConsultsPut(phrConsult),
        );
        let tagsToSave = (phrConsult.phrConsultTags ?? []).filter(
          (x) => x.consultTagTypeEnum === ConsultTagTypeEnum.Complaint,
        );
        tagsToSave = [...tagsToSave, ...phrConsultTags];

        const phrConsultTagsClient = new PHRConsultTagsClient(new AuthClient());
        // @ts-ignore
        await httpRequest(() =>
          phrConsultTagsClient.many(
            patientID,
            updatedPhrConsult,
            // @ts-ignore
            tagsToSave.map((x) => ({
              consultTagTypeEnum: x.consultTagTypeEnum,
              phrConsultID: x.phrConsultID,
              tagID: x.tagID,
            })),
          ),
        );

        setUpdatingPhrConsult(false);
        return true;
      } catch (err) {
        setUpdatingPhrError(err as any);
        setUpdatingPhrConsult(false);
        return false;
      }
    }
  };

  // useInterval(
  //   () => {
  //     updateSOAPNotes(true);
  //   },
  //   isInConsult && !showEndCallModal ? 30000 : null,
  // );

  const updateSOAPNotes = async (isSilentUpdate = false) => {
    try {
      if (!isSilentUpdate) {
        setUpdatingPhrConsult(true);
      }
      const phrConsultsClient = new PHRConsultsClient(new AuthClient());
      let phrConsult;

      if (consultID) {
        phrConsult = await httpRequest(() => phrConsultsClient.byConsultID(patientID, consultID));
      } else if (phrConsultID) {
        phrConsult = await httpRequest(() =>
          phrConsultsClient.pHRConsultsGet(patientID, phrConsultID),
        );
      }
      phrConsult.subjective = subjective;
      phrConsult.diagnosis = diagnosis;
      phrConsult.objective = objective;
      phrConsult.plan = plan;
      phrConsult.nurseNote = nurseNotes;
      phrConsult.phrConsultTypeEnum = isNurse
        ? PHRConsultTypeEnum.NurseNote
        : PHRConsultTypeEnum.SOAPNote;

      const updatedPhrConsult = await httpRequest(() =>
        phrConsultsClient.pHRConsultsPut(phrConsult),
      );

      let tagsToSave = (phrConsult.phrConsultTags ?? []).filter(
        (x) => x.consultTagTypeEnum === ConsultTagTypeEnum.Complaint,
      );
      tagsToSave = [...tagsToSave, ...phrConsultTags];

      const phrConsultTagsClient = new PHRConsultTagsClient(new AuthClient());
      await httpRequest(() =>
        phrConsultTagsClient.many(
          patientID,
          updatedPhrConsult,
          // @ts-ignore
          tagsToSave.map((x) => ({
            consultTagTypeEnum: x.consultTagTypeEnum,
            phrConsultID: x.phrConsultID,
            tagID: x.tagID,
          })),
        ),
      );

      if (consultID) {
        phrConsult = await httpRequest(() => phrConsultsClient.byConsultID(patientID, consultID));
      } else if (phrConsultID) {
        phrConsult = await httpRequest(() =>
          phrConsultsClient.pHRConsultsGet(patientID, phrConsultID),
        );
      }

      setPhrConsult(phrConsult);
    } catch (err) {
      if (!isSilentUpdate) {
        setUpdatingPhrError(err as any);
      }
    }
    if (!isSilentUpdate) {
      setUpdatingPhrConsult(false);
    }
  };

  const loadPhrForms = useCallback(
    async (phrConsultID: string) => {
      const phrForms = await httpRequest(() =>
        new PHRFormsClient(new AuthClient()).pHRConsultID(patientID, phrConsultID),
      );
      setPhrForms(phrForms);
    },
    [patientID],
  );

  const loadPhrConsult = useCallback(async () => {
    const phrConsultsClient = new PHRConsultsClient(new AuthClient());
    setPhrConsultIsLoading(true);
    setLoadingPhrConsultError('');
    try {
      let phrConsult: IPHRConsult | undefined;

      if (consultID) {
        phrConsult = await httpRequest(() => phrConsultsClient.byConsultID(patientID, consultID));
      } else if (phrConsultID) {
        phrConsult = await httpRequest(() =>
          phrConsultsClient.pHRConsultsGet(patientID, phrConsultID),
        );
      }

      if (phrConsult?.phrConsultID) {
        loadPhrForms(phrConsult!.phrConsultID);
      }

      if (phrConsult) {
        setPhrConsult(phrConsult);
        setComplaint(phrConsult?.complaint ?? '');
        setAttested(phrConsult.isAttested);
        setAttestation(phrConsult.attestation || '');
        setConsultDate(phrConsult.consultDate);
        if (phrConsult.attestationProvider) {
          setAttestationProvider(phrConsult.attestationProvider);
        }
        setSubjective(phrConsult.subjective || '');
        setObjective(phrConsult.objective || '');
        setDiagnosis(phrConsult.diagnosis || '');
        setPlan(phrConsult.plan || '');
        setNurseNotes(phrConsult.nurseNote || '');
      }
    } catch (err) {
      setLoadingPhrConsultError(err as any);
    }
    setPhrConsultIsLoading(false);
  }, [
    patientID,
    consultID,
    setSubjective,
    setObjective,
    setDiagnosis,
    setPlan,
    setAttestationProvider,
    phrConsultID,
    loadPhrForms,
    setNurseNotes,
  ]);

  useEffect(() => {
    loadPhrConsult();
  }, [loadPhrConsult]);

  useEffect(() => {
    if (!patientCountry && countries.length > 0 && phrConsult) {
      if (phrConsult.patientCountryID) {
        setPatientCountry(countries.find((x) => x.countryID === phrConsult.patientCountryID));
      } else {
        setPatientCountry(countries.find((x) => x.countryID === phrConsult.countryID));
      }
    }
  }, [countries, phrConsult, patientCountry]);

  const debouncedUpdateSOAPNotes = useDebouncedCallback((isSilentUpdate = true) => {
    updateSOAPNotes(isSilentUpdate);
  }, FORM_DEBOUNCE_TIMEOUT);

  return {
    phrConsult,
    phrConsultIsLoading,
    setPhrConsultTags,
    phrConsultTags,
    setValidationMessages,
    validationMessages,
    subjective,
    setSubjective,
    objective,
    setObjective,
    diagnosis,
    setDiagnosis,
    plan,
    setPlan,
    attestation,
    setAttestation,
    attestationProvider,
    setAttestationProvider,
    isUpdatingPhrConsult,
    updateSOAPNotes,
    completeConsult,
    updatingPhrError,
    patientCountry,
    setPatientCountry,
    consultDate,
    setConsultDate,
    isAttested,
    setAttested,
    complaint,
    setComplaint,
    loadingPhrConsultError,
    phrForms,
    loadPhrForms,
    shouldBlockNavigation,
    debouncedUpdateSOAPNotes,
    nurseNotes,
    setNurseNotes,
  };
}
