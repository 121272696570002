import { useEffect, useState } from 'react';

const useUnreadMessages = () => {
  const [increaseUnreadCounter, setIncreaseUnreadCounter] = useState<boolean>(false);
  const [messageCount, setMessageCount] = useState<number>(0);
  const [readMessageCount, setReadMessageCount] = useState<number>(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState<number>(0);

  useEffect(() => {
    if (increaseUnreadCounter) {
      setUnreadMessageCount(messageCount - readMessageCount);
    } else {
      setReadMessageCount(messageCount);
      setUnreadMessageCount(0);
    }
  }, [messageCount, increaseUnreadCounter, readMessageCount]);

  return {
    setMessageCount,
    setIncreaseUnreadCounter,
    unreadMessageCount,
  };
};

export default useUnreadMessages;
