import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { HomeIcon } from '../../../../../../../assets/icons';
import { AddressBookDeliveryModeEnum } from '../../../../../../../components/AddressBook/AddressBook';
import { AddressBookView } from '../../../../../../../components/AddressBook/components/AddressBookView/AddressBookView';
import { AddressBooksProvider } from '../../../../../../../components/AddressBook/context/AddressBooksProvider';
import {
  AddressBook,
  AddressBookFolderEnum,
  ICountry,
  IPatient,
} from '../../../../../../../generated';
import { SelectedLab } from '../SelectedLab/SelectedLab';

interface IProps {
  country: ICountry;
  patient: IPatient;
  selectedAddress: AddressBook | undefined;
  setSelectedAddress: (addressBook: AddressBook | undefined) => void;
}

export const ChooseDiagnostics = ({
  country,
  patient,
  selectedAddress,
  setSelectedAddress,
}: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <AddressBooksProvider
      deliveryMode={AddressBookDeliveryModeEnum.Single}
      defaultCountry={country}
      patient={patient}
      callback={(addressBooks: AddressBook[]) =>
        addressBooks.length ? setSelectedAddress(addressBooks[0]) : setSelectedAddress(undefined)
      }
      validationMessage={'The Contact needs to be a Diagnostic Center'}
      onValidate={(addressBook: AddressBook) =>
        addressBook.addressBookFolderEnum === AddressBookFolderEnum.Lab
      }
      defaultFolder={AddressBookFolderEnum.Lab}
    >
      {!selectedAddress ? (
        <>
          <Flex
            justify="space-between"
            align="center"
            border="1px solid #E4E9F2"
            borderRadius="5px"
            height={{ base: '48px', md: '119px' }}
            paddingX="15px"
            paddingY={{ base: '20px' }}
            cursor="pointer"
            onClick={onOpen}
            _hover={{ backgroundColor: 'purple.100', transition: '.2s ease-in-out' }}
          >
            <HStack align="center">
              <HomeIcon color="#3E1191" boxSize={5} />
              <Text fontSize="12px" color="#3E1191">
                Diagnostic Facility
              </Text>
            </HStack>
            <ChevronRightIcon boxSize={5} color="#3E1191" />
          </Flex>
        </>
      ) : (
        <Box borderRadius="0.25rem" borderWidth="1px" borderColor="#4163CE14" py={4} px={2}>
          <SelectedLab
            selectedAddress={selectedAddress}
            ActionButton={
              <Text
                cursor="pointer"
                fontSize="12px"
                mr={2}
                ml={[2, 0]}
                color="purple.400"
                onClick={() => setSelectedAddress(undefined)}
              >
                Change
              </Text>
            }
          />
        </Box>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <AddressBookView onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </AddressBooksProvider>
  );
};
