import { useQuery } from 'react-query';
import { AuthClient, PHRDocumentsClient } from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

interface IProps {
  patientID?: string;
  documentID?: string;
}

export const usePhrDocumentsDownload = ({ patientID, documentID }: IProps) => {
  const {
    isLoading: isDownloadingFile,
    data: file,
    error: downloadError,
  } = useQuery<Blob, string>(
    [QueryKeys.PHRDocumentsDownload, patientID, documentID],
    async () => {
      const result = await httpRequest(() =>
        new PHRDocumentsClient(new AuthClient()).download(patientID!, documentID!),
      );
      return new Blob([result.data]);
    },
    { enabled: !!patientID, staleTime: Infinity },
  );

  return {
    isDownloadingFile,
    file,
    downloadError,
  };
};
