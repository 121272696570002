import { useEffect, useState } from 'react';
import {
  AddressBook,
  AddressBooksClient,
  AuthClient,
  PatientOrder,
} from '../../../../../../../../generated';
import { httpRequest } from '../../../../../../../../utils';

interface IProps {
  patientOrder?: PatientOrder;
}

export const useSelectedAddress = ({ patientOrder }: IProps) => {
  const [selectedAddress, setSelectedAddress] = useState<AddressBook>();
  const [isAddressBookInit, setIsAddressBookInit] = useState<boolean>(false);

  useEffect(() => {
    const loadAddressBook = async (storeID?: string) => {
      if (storeID) {
        try {
          const result = await httpRequest(() =>
            new AddressBooksClient(new AuthClient()).byAssociatedID(storeID),
          );
          setSelectedAddress(result);
        } catch (err) {
          // eat error
        }
        setIsAddressBookInit(true);
      }
    };
    if (!isAddressBookInit && patientOrder?.storeID) {
      loadAddressBook(patientOrder.storeID);
    } else {
      setIsAddressBookInit(true);
    }
  }, [isAddressBookInit, patientOrder]);

  return { selectedAddress, setSelectedAddress, isAddressBookInit };
};
