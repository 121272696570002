import { AuthClient, PatientOrder, PatientOrdersClient } from '../../../../generated';
import { httpRequest } from '../../../../utils';

const patientOrderPut = async (patientOrder: PatientOrder) => {
  const patientOrdersClient = new PatientOrdersClient(new AuthClient());

  const orderID = await httpRequest(() => patientOrdersClient.patientOrdersPut(patientOrder));

  return orderID;
};

export { patientOrderPut };
