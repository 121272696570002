import { Center, Flex, Grid, GridItem, Stack, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { PrimaryButton } from '../../../../../../../components/Buttons';
import { CenteredLoadingIndicator } from '../../../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../../../components/ServerValidationBox';
import { AppState } from '../../../../../../../store/root-reducers';
import { OutpostTheme } from '../../../../../../../themes/outpost';
import { formatCurrency } from '../../../../../../../utils';
import { useCreateOrderContext } from '../../contexts/CreateOrderContext';
import { ItemCard } from '../ItemCard/ItemCard';
import { SelectedLab } from '../SelectedLab/SelectedLab';

export const ConfirmOrder = () => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  const {
    selectedAddress,
    isAddressBookInit,
    patientOrder,
    confirmPatientOrder,
    patientOrderFinalizeLoading: isLoading,
    patientOrderFinalizeError: error,
  } = useCreateOrderContext();

  return (
    <Center>
      <Grid
        w="100%"
        maxW="600px"
        templateColumns={{ base: '1fr', md: '225px 1fr' }}
        gridGap={{ base: 4, md: 10 }}
      >
        <GridItem>
          <Text mt={{ base: 6, md: 0 }} fontWeight="500">
            Lab
          </Text>
        </GridItem>
        {selectedAddress ? (
          <GridItem>
            {!isAddressBookInit ? (
              <CenteredLoadingIndicator />
            ) : (
              <SelectedLab selectedAddress={selectedAddress!} />
            )}
          </GridItem>
        ) : (
          <GridItem>
            <Text mt={4} fontSize={OutpostTheme.FontSizes.body} textAlign="center">
              No Lab selected
            </Text>
          </GridItem>
        )}

        <GridItem>
          <Text fontWeight="500">Tests</Text>
        </GridItem>
        <GridItem>
          <Stack>
            {(patientOrder?.patientOrderItems ?? []).map((x) => (
              <ItemCard
                key={x.orderItemID}
                patientOrderItem={x}
                Cost={
                  x.totalAmount ? (
                    <Flex mb={4} mt={8} justifyContent="space-between">
                      <Text fontSize="12px" fontWeight="400">
                        Cost
                      </Text>
                      {patientOrder?.invoice ? (
                        <Text fontWeight="400" fontSize={OutpostTheme.FontSizes.body}>
                          {formatCurrency(
                            x.totalAmount,
                            patientOrder.invoice.currencyEnum,
                            currentUser?.cultureInfo,
                          )}
                        </Text>
                      ) : (
                        <></>
                      )}
                    </Flex>
                  ) : (
                    <></>
                  )
                }
              />
            ))}
            {patientOrder?.invoice && patientOrder?.invoice?.totalAmount > 0 && (
              <Flex
                py={5}
                px={4}
                alignItems="center"
                justifyContent="space-between"
                backgroundColor="#F7F9FF"
                borderRadius="0.25rem"
                borderColor="#4163CE14"
              >
                <Text fontWeight="400" fontSize="12px">
                  Total
                </Text>
                <Text fontWeight="400" fontSize={OutpostTheme.FontSizes.body}>
                  {patientOrder?.invoice &&
                    formatCurrency(
                      patientOrder?.invoice?.totalAmount,
                      patientOrder.invoice.currencyEnum,
                      currentUser?.cultureInfo,
                    )}
                </Text>
              </Flex>
            )}
            <ServerValidationBox message={error} />
          </Stack>
          <Flex mt={4} justifyContent="flex-end">
            <PrimaryButton isLoading={isLoading} onClick={confirmPatientOrder}>
              Send Order
            </PrimaryButton>
          </Flex>
        </GridItem>
      </Grid>
    </Center>
  );
};
