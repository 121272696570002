import { useTimeout } from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CampaignSettingsEnum } from '../../../../store/currentCampaign/currentCampaignReducers';
import { PartnerSettingsEnum } from '../../../../store/currentPartner/currentPartnerReducers';
import { AppState } from '../../../../store/root-reducers';
import getUrlParam from '../../../../utils/getUrlParam';
import outpostLogo from '../../../../assets/images/logo.svg';

const useUnAuthenticatedLayout = () => {
  const sysParam = getUrlParam('sys') as 'android' | 'ios';
  const webviewID = getUrlParam('webViewID');
  const [canShowLoginMenu, setCanShowLoginMenu] = useState(false);

  // We want to delay showing the header and footer to avoid flicker when the partner is loading
  useTimeout(() => setCanShowLoginMenu(true), 300);

  const currentPartner = useSelector((state: AppState) => state.currentPartnerState.data);
  const currentPartnerLoading = useSelector(
    (state: AppState) => state.currentPartnerState.isLoading,
  );

  const partnerImage = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.WELCOME_IMAGE,
  )?.value;

  const partnerLogo = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.APP_LOGO,
  )?.value;

  const currentCampaign = useSelector((state: AppState) => state.currentCampaignState.data);

  const currentCampaignLoading = useSelector(
    (state: AppState) => state.currentCampaignState.isLoading,
  );

  const campaignImage = currentCampaign?.campaignSettings?.find(
    ({ campaignSettingName }) => campaignSettingName === CampaignSettingsEnum.AUTHENTICATION_IMAGE,
  )?.value;

  const campaignLogo = currentCampaign?.campaignSettings?.find(
    ({ campaignSettingName }) => campaignSettingName === CampaignSettingsEnum.AUTHENTICATION_LOGO,
  )?.value;

  const campaignTitle = currentCampaign?.campaignSettings?.find(
    ({ campaignSettingName }) => campaignSettingName === CampaignSettingsEnum.AUTHENTICATION_TITLE,
  )?.value;

  const campaignSubTitle = currentCampaign?.campaignSettings?.find(
    ({ campaignSettingName }) =>
      campaignSettingName === CampaignSettingsEnum.AUTHENTICATION_SUBTITLE,
  )?.value;

  const suppressLoginMenu =
    !!currentPartner?.partnerSettings?.find(
      ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.SUPPRESS_LOGIN_MENU,
    )?.value || false;

  const hideHeaderAndFooter = !!(sysParam || webviewID || suppressLoginMenu);

  const showHeaderAndFooter = canShowLoginMenu && !currentPartnerLoading && !hideHeaderAndFooter;

  return {
    showHeaderAndFooter,
    campaignSubTitle,
    campaignTitle,
    campaignLogo,
    campaignImage,
    currentCampaignLoading,
    partnerLogo,
    partnerImage,
    currentPartnerLoading,
    outpostLogo,
  };
};

export { useUnAuthenticatedLayout };
