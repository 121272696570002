import { BloodGlucoseTypeEnum, UserDetailSettingEnum } from '../generated';

export const parseUserDetailSettings = (
  userDetailSettingEnum: UserDetailSettingEnum,
  value: string,
) => {
  if (userDetailSettingEnum === UserDetailSettingEnum.BloodGlucose) {
    if (value === 'MmolL' || value === BloodGlucoseTypeEnum.MmolL) {
      return BloodGlucoseTypeEnum.MmolL;
    } else {
      return BloodGlucoseTypeEnum.MgdL;
    }
  }

  return value;
};

export const serializeUserDetailSettings = (
  userDetailSettingEnum: UserDetailSettingEnum,
  value: string,
) => {
  if (userDetailSettingEnum === UserDetailSettingEnum.BloodGlucose) {
    if (value === BloodGlucoseTypeEnum.MmolL) {
      return 'MmolL';
    } else {
      return 'MgdL';
    }
  }

  return value;
};
