import { chakra, Link } from '@chakra-ui/react';
import { i18n, TFunction } from 'i18next';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SupportCenterRoutes } from '../constants';

const RouteLink = chakra(Link, {
  baseStyle: {
    _hover: { textDecoration: 'none' },
    textDecoration: 'none',
  },
});

const translationComponents = {
  "Country can't be changed": {
    anchor: <RouteLink href={SupportCenterRoutes.Support} />,
  },
  "This info can't be changed": {
    anchor: <RouteLink href={SupportCenterRoutes.Support} />,
  },
  'Complete contact information': {
    anchor: <RouteLink href={process.env.REACT_APP_PRIVACY_URL} />,
  },
  'Changing contact information': {
    anchor: <RouteLink href={SupportCenterRoutes.Support} />,
  },
};

export const languages = [
  { label: 'EN', value: 'en' },
  { label: 'FR', value: 'fr' },
];

const useTranslationComponent = (namespace?: any) => {
  // @ts-ignore
  const { t, i18n }: { t: any; i18n: i18n } =
    // @ts-ignore
    useTranslation(namespace);
  // @ts-ignore
  const tc: TFunction = (key, ...options) => (
    // @ts-ignore
    <Trans defaults={t(key, ...options)} components={translationComponents[key]} />
  );
  return { tc, t, i18n };
};

export default useTranslationComponent;
