import { Stack, StackProps } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { FormControl } from '../FormControl/FormControl';
import { useFastField } from '../useFastField';
import { BaseProps } from '../baseProps';

export type CheckboxContainerProps = BaseProps & {
  stackProps?: StackProps;
  children: ReactNode;
};

export const CheckboxContainer = (props: CheckboxContainerProps) => {
  const { name, label, children, stackProps, formControlProps } = props;

  return (
    <FormControl name={name} label={label} {...formControlProps}>
      <Stack pl={6} mt={1} spacing={1} {...stackProps}>
        {children}
      </Stack>
    </FormControl>
  );
};

export type FormCheckboxContainerProps = CheckboxContainerProps;

export const FormCheckboxContainer = (props: FormCheckboxContainerProps) => {
  const { name, formControlProps, ...rest } = props;
  const [, meta] = useFastField(name);

  return (
    <CheckboxContainer name={name} formControlProps={{ ...meta, ...formControlProps }} {...rest} />
  );
};
