import React from 'react';
import moment from 'moment';
import { Box, Grid, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { CalendarIcon2 } from '../../../../assets/icons';
import { PHRMedication } from '../../../../generated';
import { TimeFormatEnum } from '../../../../constants';
import { verifyForPhrSection } from '../../../../store/patient/phr/phrCreators';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import { successToastr } from '../../../../utils/toastr';

import PhrItemView from '../../components/PhrItemView';
import { OutpostTheme } from '../../../../themes/outpost';

interface IMedicationViewProps {
  medication: PHRMedication;
}

const MedicationView = ({ medication }: IMedicationViewProps) => {
  const dispatch = useDispatch();

  const onVerify = async (id: string, patientID: string) => {
    dispatch(verifyForPhrSection(ReduxPHRCategoryEnum.PHRMedications, id, patientID));
    successToastr({ description: 'Successfully verified medication' });
  };

  return (
    <PhrItemView
      phrItem={medication}
      onVerify={() => onVerify(medication.phrMedicationID, medication.patientID)}
    >
      <Grid
        templateColumns="repeat(2, 1fr)"
        templateRows="repeat(4, 1fr)"
        gridColumnGap="1rem"
        gridRowGap="1.5rem"
      >
        <Box>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Instructions
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
            {medication.instructions}
          </Text>
        </Box>
        <Box>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Start Date
          </Text>
          <Text
            fontSize={OutpostTheme.FontSizes.body}
            marginTop="0.5rem"
            display="flex"
            alignItems="center"
          >
            <CalendarIcon2 fontSize="18px" color="#78819D" />
            &nbsp;
            {moment(medication.startDate).format(TimeFormatEnum.SHORT_LONG_DATE)}
          </Text>
        </Box>

        <Box>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            End Date
          </Text>
          <Text
            fontSize={OutpostTheme.FontSizes.body}
            marginTop="0.5rem"
            display="flex"
            alignItems="center"
          >
            {medication.endDate ? (
              <>
                <CalendarIcon2 fontSize="18px" color="#78819D" />
                &nbsp;
                {moment(medication.endDate).format(TimeFormatEnum.SHORT_LONG_DATE)}
              </>
            ) : (
              'No end date'
            )}
          </Text>
        </Box>
        <Box>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Form
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
            {medication.form}
          </Text>
        </Box>

        <Box>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Route
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
            {medication.methodOfIntake}
          </Text>
        </Box>
        <Box>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Quantity
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
            {medication.quantity}
          </Text>
        </Box>

        <Box>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Refills
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
            {medication.refills}
          </Text>
        </Box>
        <Box>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Dispense Instructions
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
            {medication.instructions}
          </Text>
        </Box>
      </Grid>

      <Box marginTop="1.5rem">
        <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
          Notes
        </Text>
        <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
          {medication.notes ? medication.notes : 'No notes have been added yet'}
        </Text>
      </Box>
    </PhrItemView>
  );
};

export default MedicationView;
