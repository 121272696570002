import { Box, IconButton } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { XCloseIcon } from '../../../assets/icons';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import ServerValidationBox from '../../../components/ServerValidationBox';
import { PathParamKeysEnum } from '../../../constants';
import { IPatient } from '../../../generated';
import { updateBreadcrumb } from '../../../store/pageHeader/pageHeaderActions';
import { PatientVitalsForm } from './components/PatientVitalsForm/PatientVitalsForm';
import { PatientVitalsList } from './components/PatientVitalsList/PatientVitalsList';
import { usePatientVitalsList } from './components/PatientVitalsList/usePatientVitalsList';
import { usePatientVitals } from './hooks/usePatientVitals';

interface IProps {
  patient?: IPatient;
  onBack?: () => void;
  onDismiss?: () => void;
}
const PatientVitals = ({ patient, onBack, onDismiss }: IProps) => {
  const dispatch = useDispatch();
  const {
    error,
    isLoading,
    phrPatient,
    userDetailSettings,
    showForm,
    setShowForm,
    setShouldLoadVitals,
    unitSettings,
    vitals,
  } = usePatientVitals(patient);

  const { trackerID, tracker, showTracker, setTrackerID, renderTracker, viewState } =
    usePatientVitalsList(phrPatient, userDetailSettings, setShowForm, () =>
      setShouldLoadVitals(true),
    );

  useEffect(() => {
    if (patient) {
      dispatch(updateBreadcrumb([PathParamKeysEnum.PatientID, patient.fullName || '']));
    }
  }, [patient, dispatch]);

  return (
    <>
      {error ? (
        <ServerValidationBox message={error} />
      ) : (
        <>
          {onDismiss && (
            <Box position="absolute" right="2" top="2" zIndex="9">
              <IconButton
                aria-label="close"
                p={0}
                variant="ghost"
                borderRadius="full"
                onClick={onDismiss}
              >
                <XCloseIcon width="24px" height="24px" />
              </IconButton>
            </Box>
          )}
          {!phrPatient ? (
            <CenteredLoadingIndicator />
          ) : (
            <Box bgColor="#F4F4F4" height="100%">
              {showForm ? (
                <PatientVitalsForm
                  trackerID={trackerID!}
                  tracker={tracker!}
                  patient={phrPatient.patient}
                  onBack={onBack}
                  toggleView={() => setShowForm(false)}
                  refreshList={() => setShouldLoadVitals(true)}
                  unitSettings={unitSettings!}
                />
              ) : (
                <PatientVitalsList
                  isLoading={isLoading}
                  phrPatient={phrPatient}
                  onBack={onBack}
                  setShowForm={setShowForm}
                  renderTracker={renderTracker}
                  viewState={viewState}
                  setTrackerID={setTrackerID}
                  tracker={tracker!}
                  showTracker={showTracker}
                  refreshList={() => setShouldLoadVitals(true)}
                  vitals={vitals}
                  userDetailSettings={userDetailSettings}
                  unitSettings={unitSettings!}
                />
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default PatientVitals;
