import React, { useState } from 'react';
import moment from 'moment';
import useInterval from '../hooks/useInterval';

interface IProps {
  timestamp: number;
  duration?: number;
}

const LiveStamp = ({ timestamp, duration = 30 * 1000 }: IProps) => {
  const [fromNow, setFromNow] = useState<string>(moment(timestamp).fromNow());

  useInterval(() => {
    setFromNow(moment(timestamp).fromNow());
  }, duration);

  return <>{fromNow}</>;
};

export default LiveStamp;
