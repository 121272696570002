import { Box, Flex, ModalBody, ModalFooter, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { IFormQuestion, IPHRFormQuestion, PHRFormQuestion } from '../../../../../generated';
import { ButtonRow } from '../../../../Buttons';
import { TextArea } from '../../../../forms';
import useFormBuilderContext from '../../../hooks/useFormBuilderContext/useFormBuilderContext';
import { NextButton } from '../../NavigationButtons/NextButton';
import { PrevButton } from '../../NavigationButtons/PrevButton';
import { QuestionHeader } from '../../QuestionHeader/QuestionHeader';
import { TextQuestionType, useTextQuestion } from './useTextQuestion';

interface IProps {
  formQuestion: IFormQuestion;
  previousAnswer?: IPHRFormQuestion;
  type?: TextQuestionType;
}

export const TextQuestion = ({
  formQuestion,
  previousAnswer,
  type = TextQuestionType.Short,
}: IProps) => {
  const { answer, setAnswer, availableCharacters, limit } = useTextQuestion({ type });
  const { onNext, goToPrevious, canGoBack, formQuestions, state, lastStep } =
    useFormBuilderContext();

  useEffect(() => {
    if (previousAnswer?.value) {
      setAnswer(previousAnswer?.value);
    }
  }, [previousAnswer, setAnswer]);

  return (
    <>
      <ModalBody>
        <QuestionHeader
          title={formQuestion.name}
          description={formQuestion.description}
          currentStep={state.currentIndex + 1}
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
          totalQuestionCount={formQuestions?.length!}
        />
        <Box>
          <TextArea
            name={formQuestion.name}
            placeholder="Answer here"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            maxLength={limit}
            formControlProps={{ mb: 0 }}
            minH={type === TextQuestionType.Long ? '120px' : '60px'}
          />
          <Flex w="100%" justifyContent="flex-end">
            <Text
              color="gray.300"
              fontSize="sm"
            >{`available characters: ${availableCharacters}`}</Text>
          </Flex>
        </Box>
      </ModalBody>
      <ModalFooter>
        <ButtonRow>
          {canGoBack && <PrevButton onClick={goToPrevious} />}
          <NextButton
            lastStep={lastStep}
            onClick={() => {
              const newFormQuestion = new PHRFormQuestion();
              if (previousAnswer) {
                newFormQuestion.init(previousAnswer);
              } else {
                newFormQuestion.formQuestionID = formQuestion.formQuestionID;
              }
              newFormQuestion.value = answer;
              onNext(newFormQuestion);
            }}
            disabled={formQuestion.required && !answer}
          />
        </ButtonRow>
      </ModalFooter>
    </>
  );
};
