import React, { useState } from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import styled from 'styled-components';
import { OutpostTheme } from '../../themes/outpost';
import { Paragraph } from './Paragraph';
import useInterval from '../../hooks/useInterval';

const StyledToolTip = styled.span`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.FontColors.Primary};
  min-height: 20px;
  max-width: 300px;
  border-radius: 10px;
  padding: 10px 20px;
`;

const Text = styled(Paragraph)`
  color: white;
  font-size: 13px;
  text-align: center;
`;

const IconWrapper = styled.span`
  cursor: pointer;
`;

interface IProps {
  tooltip: any;
  children: any;
  timerMs?: number;
}

const ToolTip = ({ tooltip, children, timerMs }: IProps) => {
  const [showPopover, setShowPopover] = useState<boolean>(false);

  useInterval(
    () => {
      setShowPopover(false);
    },
    !!timerMs && showPopover ? timerMs : null,
  );

  return (
    <Popover
      isOpen={showPopover}
      positions={['top']}
      onClickOutside={() => setShowPopover(false)}
      content={({ position, popoverRect, childRect }) => (
        <ArrowContainer
          childRect={childRect}
          position={position}
          popoverRect={popoverRect}
          arrowColor={OutpostTheme.FontColors.Primary}
          arrowSize={10}
        >
          <StyledToolTip onClick={() => setShowPopover(!showPopover)}>
            <Text>{tooltip}</Text>
          </StyledToolTip>
        </ArrowContainer>
      )}
    >
      <IconWrapper onClick={() => setShowPopover(true)}>{children}</IconWrapper>
    </Popover>
  );
};

export default ToolTip;
