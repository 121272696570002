import { Box, Center, Heading, Image, Stack, Text } from '@chakra-ui/react';
import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/images/logo.svg';
import { PrimaryButton } from '../../components/Buttons/PrimaryButton';
import HelmetWrapper from '../../components/HelmetWrapper';
import { COOKIE_NAME, SignInRoutes } from '../../constants';
import { PartnerSettingsEnum } from '../../store/currentPartner/currentPartnerReducers';
import { AppState } from '../../store/root-reducers';
import { expireCookie } from '../../utils/cookieHelper';
import useTranslationComponent from '../../hooks/useTranslationComponent';

const SignOut = () => {
  const { t } = useTranslationComponent('signin');
  const dispatch = useDispatch();

  useEffect(() => {
    expireCookie(COOKIE_NAME);
  }, []);

  const currentPartner = useSelector((state: AppState) => state.currentPartnerState.data);
  const currentPartnerLoading = useSelector(
    (state: AppState) => state.currentPartnerState.isLoading,
  );
  const img =
    currentPartner?.partnerSettings?.find(
      ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.APP_LOGO,
    )?.value || logo;

  return (
    <>
      <HelmetWrapper title="Logout" />
      <Box mb={4}>{!currentPartnerLoading && <Image src={img} />}</Box>
      <Stack spacing={4}>
        <Heading size="lg" textAlign="center">
          {t('You have been logged out')}.
        </Heading>
        <Text fontSize="20px" textAlign="center">
          {t('Thank you for using', {
            name: currentPartner?.name || process.env.REACT_APP_COMPANY_NAME,
          })}
          !
        </Text>
        <Center>
          <PrimaryButton onClick={() => dispatch(push(SignInRoutes.SignIn))}>
            {t('Login Again')}
          </PrimaryButton>
        </Center>
      </Stack>
    </>
  );
};

export default SignOut;
