import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Flex, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/root-reducers';
import { OutpostTheme } from '../../themes/outpost';
import { LinkButton, RoundedButton } from '../Buttons';

interface IProps {
  statusText?: string;
  connectionButtonText?: string;
  onClick: () => void;
  routeTo?: string;
  onTrack?: () => void;
}

const ActiveConsultInfoBanner: FC<IProps> = ({
  statusText,
  connectionButtonText,
  onClick,
  routeTo,
  onTrack,
}) => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data!);

  const isDelegating = currentUser?.delegateJWT;

  const isInMobileWebView =
    currentUser?.isInMobileView &&
    (currentUser.mobileSystem === 'android' || currentUser.mobileSystem === 'ios');

  return statusText ? (
    <Stack
      w="100%"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="0.25rem"
      backgroundColor="#FFEDB3"
      border="1px solid #E4E9F2"
      mb={6}
      px={6}
      py={5}
      spacing={4}
      direction={['column', 'row']}
      boxShadow="md"
    >
      <Flex alignItems="center">
        <InfoOutlineIcon color="#000000" fontSize={OutpostTheme.FontSizes.body} />
        <Text fontWeight="500" color="#000000" ml={4} fontSize={OutpostTheme.FontSizes.body}>
          {statusText}
        </Text>
      </Flex>
      {connectionButtonText && (
        <>
          {isInMobileWebView || isDelegating ? (
            <RoundedButton as="a" onClick={onClick}>
              {connectionButtonText}
            </RoundedButton>
          ) : (
            <LinkButton onClick={onTrack} href={routeTo} hasForwardIcon>
              {connectionButtonText}
            </LinkButton>
          )}
        </>
      )}
    </Stack>
  ) : null;
};

export default ActiveConsultInfoBanner;
