import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { DiscussionItem } from '../../generated';
import { CurrentUserActionTypes, logoutActions } from '../currentUser/currentUserActions';
import {
  getDiscussionItemsActions,
  GetDiscussionItemsActionTypes,
} from './getDiscussionItemsAction';
import {
  replyDiscussionItemsActions,
  ReplyDiscussionItemsActionTypes,
} from './replyDiscussionItemsAction';

const discussionItem = createReducer<
  DiscussionItem | null,
  ReplyDiscussionItemsActionTypes | GetDiscussionItemsActionTypes | CurrentUserActionTypes
>(null)
  .handleAction([getDiscussionItemsActions.success], (_, action) => action.payload)
  .handleAction([replyDiscussionItemsActions.success], (_, action) => {
    if (_) {
      const { isProvider, ...payload } = action.payload;
      _?.replyDiscussionItems?.push(payload as DiscussionItem);
      if (_?.replyCount) _.replyCount += 1;
      else _.replyCount = 1;
      if (isProvider === true) {
        if (_?.providerReplyCount) _.providerReplyCount += 1;
        else _.providerReplyCount = 1;
      }
    }

    return _;
  })
  .handleAction([logoutActions], () => null);

const error = createReducer<
  string,
  ReplyDiscussionItemsActionTypes | GetDiscussionItemsActionTypes
>('').handleAction(
  [replyDiscussionItemsActions.failure, getDiscussionItemsActions.failure],
  (_, action) => action.payload,
);

const isLoading = createReducer<
  boolean,
  ReplyDiscussionItemsActionTypes | GetDiscussionItemsActionTypes
>(false)
  .handleAction(
    [replyDiscussionItemsActions.request, getDiscussionItemsActions.request],
    () => true,
  )
  .handleAction(
    [
      replyDiscussionItemsActions.failure,
      replyDiscussionItemsActions.success,
      getDiscussionItemsActions.failure,
      getDiscussionItemsActions.success,
    ],
    () => false,
  );

export interface IDiscussionItemState {
  error: string;
  isLoading: boolean;
  discussionItem: DiscussionItem | null;
}

export default combineReducers<IDiscussionItemState>({
  discussionItem,
  error,
  isLoading,
});
