import React from 'react';
import moment from 'moment';
import { Box, Grid, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { CalendarIcon2 } from '../../../assets/icons';
import { PHRVaccination } from '../../../generated';
import { TimeFormatEnum } from '../../../constants';
import { verifyForPhrSection } from '../../../store/patient/phr/phrCreators';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { successToastr } from '../../../utils/toastr';

import PhrItemView from '../components/PhrItemView';
import { OutpostTheme } from '../../../themes/outpost';

interface IVaccinationViewProps {
  vaccination: PHRVaccination;
}

const VaccinationView = ({ vaccination }: IVaccinationViewProps) => {
  const dispatch = useDispatch();

  const onVerify = async (id: string, patientID: string) => {
    dispatch(verifyForPhrSection(ReduxPHRCategoryEnum.PHRVaccinations, id, patientID));
    successToastr({ description: 'Successfully verified vaccination' });
  };

  return (
    <PhrItemView
      phrItem={vaccination}
      onVerify={() => onVerify(vaccination.phrVaccinationID, vaccination.patientID)}
    >
      <Grid
        templateColumns="repeat(2, 1fr)"
        templateRows="repeat(2, 1fr)"
        gridColumnGap="1rem"
        gridRowGap="1.5rem"
      >
        <Box>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Vaccination Date
          </Text>
          <Text
            fontSize={OutpostTheme.FontSizes.body}
            marginTop="0.5rem"
            display="flex"
            alignItems="center"
          >
            <CalendarIcon2 fontSize="18px" color="#78819D" />
            &nbsp;
            {moment(vaccination.vaccinationDate).format(TimeFormatEnum.SHORT_LONG_DATE)}
          </Text>
        </Box>

        <Box>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Lot Number
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
            {vaccination.lotNumber ?? '-'}
          </Text>
        </Box>
        <Box>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Vaccine Facility
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
            {vaccination.location ?? '-'}
          </Text>
        </Box>
      </Grid>
      <Box marginTop="1.5rem">
        <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
          Notes
        </Text>
        <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
          {vaccination.notes || 'No notes have been added yet'}
        </Text>
      </Box>
    </PhrItemView>
  );
};

export default VaccinationView;
