import { Flex, Stack } from '@chakra-ui/react';
import { AddressBookViewModeEnum } from '../../AddressBook';
import useAddressBooksContext from '../../context/AddressBooksProvider';
import { AddressBookStateEnum, useNavigation } from '../../hooks/useNavigation';
import { AddressBookListView } from '../AddressBookList/AddressBookListView';
import { ContactForm } from '../ContactForm/ContactForm';

export enum BrowseByEnum {
  All = 'All',
  Private = 'Private',
  Clinic = 'Clinic',
  Organization = 'Organization',
  Lab = 'Lab',
  Pharmacy = 'Pharmacy',
  Other = 'Other',
  Provider = 'Provider',
  Patient = 'Patient',
}

interface IProps {
  onClose?: () => void;
}

export const AddressBookView = ({ onClose }: IProps) => {
  const { viewMode, selectedAddresses, onUnSelect } = useAddressBooksContext();
  const navigation = useNavigation();

  return (
    <Flex {...getPageViewProps(viewMode!)}>
      <Stack spacing={4} flexGrow={1} h={'100%'} width="100%">
        {navigation.state === AddressBookStateEnum.List && (
          <AddressBookListView
            onClose={onClose}
            onAdd={() => navigation.goto(AddressBookStateEnum.Create)}
            onEdit={() => navigation.goto(AddressBookStateEnum.Edit)}
          />
        )}
        {navigation.state === AddressBookStateEnum.Create && (
          <ContactForm
            state={navigation.state}
            onCancel={() => navigation.goto(AddressBookStateEnum.List)}
            onComplete={() => navigation.goto(AddressBookStateEnum.List)}
          />
        )}
        {navigation.state === AddressBookStateEnum.Edit && (
          <ContactForm
            state={navigation.state}
            addressBook={selectedAddresses ? selectedAddresses[0] : null}
            onCancel={() => {
              if (selectedAddresses) onUnSelect(selectedAddresses[0]);

              navigation.goto(AddressBookStateEnum.List);
            }}
            onComplete={() => {
              if (selectedAddresses) onUnSelect(selectedAddresses[0]);

              navigation.goto(AddressBookStateEnum.List);
            }}
          />
        )}
      </Stack>
    </Flex>
  );
};

const getPageViewProps = (viewMode: AddressBookViewModeEnum) => {
  switch (viewMode) {
    case AddressBookViewModeEnum.Page:
      return {
        backgroundColor: 'white',
        borderRadius: '1rem',
        p: 6,
        h: '100%',
      };
    case AddressBookViewModeEnum.Modal:
      return {
        h: '100%',
      };
  }
};
