import type { FC } from 'react';
import { Flex, Box, HStack } from '@chakra-ui/react';

import { FormRadioGroupControl, RadioControl } from '../../../../../../../../../components/forms';

import { Label } from './Label';

type TRadioControlWrapper = {
  label: string;
  name: string;
  children?: React.ReactNode;
};

const RadioControlWrapper: FC<TRadioControlWrapper> = (props) => (
  <Box width="95%">
    <Flex justifyContent="space-between" flexWrap="wrap">
      <Label
        flex={1}
        flexBasis={{ base: '100%', sm: 'auto' }}
        maxW={{ base: '100%', sm: '60%' }}
        mb={{ base: '3', sm: 0 }}
      >
        {props.label}
      </Label>
      <Box minW="10rem">
        <FormRadioGroupControl name={props.name}>{props.children}</FormRadioGroupControl>
      </Box>
    </Flex>
  </Box>
);

const BooleanRadioControl: FC<TRadioControlWrapper> = (props) => (
  <RadioControlWrapper label={props.label} name={props.name}>
    <HStack spacing="5">
      <RadioControl value="yes">Yes</RadioControl>
      <RadioControl value="no">No</RadioControl>
    </HStack>
  </RadioControlWrapper>
);

export { RadioControlWrapper, BooleanRadioControl };
