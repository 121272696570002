import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Center,
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SecondaryButton } from '../../../components/Buttons';
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import {
  AuthClient,
  Country,
  PHRMedication,
  PHRMedicationsClient,
  RolesEnum,
} from '../../../generated';
import useTranslationComponent from '../../../hooks/useTranslationComponent';
import { IPHRPatientCombined } from '../../../store/patient/phr/phrReducers';
import {
  activeMedicationSelector,
  pastMedicationSelector,
  pendingMedicationsSelector,
} from '../../../store/patient/phr/phrSelectors';
import { getAllForPhrSection } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { httpRequest } from '../../../utils';
import { isInRole } from '../../../utils/isInRole';
import MedicationsList from './components/ConsultMedicationsList/ConsultMedicationsList';
import { DiscontinueMedicationModal } from './components/DiscontinueMedicationModal/DiscontinueMedicationModal';
import { PrescriptionOrderModal } from './components/PrescriptionOrderModal/PrescriptionOrderModal';
import { SignatureModal } from './components/SignatureModal/SignatureModal';
import usePrescription from './hooks/usePrescription/usePrescription';
import useProviderPrescriptionCountries from './hooks/useProviderPrescriptionCountries/useProviderPrescriptionCountries';

interface IProps {
  phrPatient: IPHRPatientCombined;
  renderHeader: (addButton?: any, onCreatePrescription?: () => void) => any;
  inverse?: boolean;
  displayPrescriptions?: boolean;
  phrConsultID?: string;
  country: Country;
}

const ConsultMedications = ({
  phrPatient,
  inverse,
  displayPrescriptions,
  phrConsultID,
  country,
}: IProps) => {
  const { t } = useTranslationComponent(['clinic']);
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppState) => state.currentUserState.data!);
  const provider = useSelector((state: AppState) => state.providerState.provider);
  const pastMedications = pastMedicationSelector(phrPatient?.phr?.phrMedications);
  const activeMedications = activeMedicationSelector(phrPatient?.phr?.phrMedications);
  const pendingMedications = pendingMedicationsSelector(phrPatient?.phr?.phrMedications);
  const hasMedications =
    activeMedications.length > 0 || pendingMedications.length > 0 || pastMedications.length > 0;
  const { isPhrLoading } = useSelector((state: AppState) => state.phrPatientState);
  const [medicationsToDiscontinue, setMedicationsToDiscontinue] = useState<PHRMedication[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showSignatureMissingModal, setShowSignatureMissingModal] = useState<boolean>(false);
  const [isDiscontinuing, setDiscontinuing] = useState<boolean>(false);
  const { elegibleCountries, isLoadingEligbleCountries } = useProviderPrescriptionCountries();

  const {
    isLoading: isPrescriptionLoading,
    prescription,
    setPrescription,
    loadByPatientPrescriptionID,
    showCreatePrescription,
    setShowCreatePrescription,
  } = usePrescription({ phrPatient, phrConsultID });

  const onRefill = (patientPrescriptionID: string) => {
    loadByPatientPrescriptionID(patientPrescriptionID);
  };

  useEffect(() => {
    if (phrPatient) {
      dispatch(
        getAllForPhrSection(ReduxPHRCategoryEnum.PHRMedications, phrPatient.patient.patientID),
      );
    }
    // looping issue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (showSignatureMissingModal && provider?.signatureImageDetailID) {
      setShowSignatureMissingModal(false);
      setShowCreatePrescription(true);
    }
  }, [showSignatureMissingModal, provider, setShowCreatePrescription]);

  const onDiscontinue = (phrMedications: PHRMedication[]) => {
    setShowDeleteModal(true);
    setMedicationsToDiscontinue(phrMedications);
  };

  const confirmDiscontinue = async (reason: string) => {
    setShowDeleteModal(false);
    setDiscontinuing(true);
    const medications = activeMedicationSelector(medicationsToDiscontinue).map((x) => {
      x.endDate = new Date();
      x.discontinuedReason = reason;
      x.discontinuedDate = new Date();
      x.discontinuedUserDetailID = currentUser.userDetailID;
      return x;
    });

    const client = new PHRMedicationsClient(new AuthClient());
    const updatePromises: any[] = [];
    medications.forEach((x) => {
      updatePromises.push(httpRequest(() => client.pHRMedicationsPut(x)));
    });

    await Promise.all(updatePromises);
    await dispatch(
      getAllForPhrSection(ReduxPHRCategoryEnum.PHRMedications, phrPatient.patient.patientID),
    );
    setDiscontinuing(false);
  };

  const onCreatePrescription = () => {
    if (!provider?.signatureImageDetailID) {
      setShowSignatureMissingModal(true);
    } else {
      setShowCreatePrescription(true);
    }
  };

  if (isLoadingEligbleCountries || isPhrLoading) {
    return <CenteredLoadingIndicator />;
  }

  const canWritePrescriptions =
    isInRole(currentUser, RolesEnum.Provider) && elegibleCountries && elegibleCountries.length > 0;

  return (
    <>
      {displayPrescriptions && !canWritePrescriptions && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription
            flexGrow={1}
            display="flex"
            pr={8}
            justifyContent="center"
            alignItems="center"
          >
            {t(
              'You have not been approved to write prescription in any of your Locations. Please contact support.',
            )}
          </AlertDescription>
        </Alert>
      )}

      {!hasMedications ? (
        <Center mt={10} width="100%">
          <Box
            width="100%"
            maxWidth="400px"
            py={10}
            px={12}
            backgroundColor="purple.50"
            borderRadius="0.25rem"
          >
            <Center>
              <Stack spacing={4}>
                <Text textAlign="center" fontWeight="500">
                  {t('No Medications added yet')}
                </Text>
                <Text fontSize="12px" textAlign="center">
                  {t("Looks like you haven't added any medications yet")}
                </Text>
                <Center>
                  <SecondaryButton
                    isDisabled={!canWritePrescriptions}
                    onClick={onCreatePrescription}
                  >
                    {t('Add Prescription')}
                  </SecondaryButton>
                </Center>
              </Stack>
            </Center>
          </Box>
        </Center>
      ) : (
        <>
          <Flex justifyContent="flex-end">
            <PrimaryButton isDisabled={!canWritePrescriptions} onClick={onCreatePrescription}>
              {prescription && (prescription?.phrMedications ?? []).length > 0 ? (
                <>{t('Continue Prescription')}</>
              ) : (
                <>{t('New Prescription')}</>
              )}
            </PrimaryButton>
          </Flex>
          {isPrescriptionLoading || isDiscontinuing || isPhrLoading ? (
            <CenteredLoadingIndicator />
          ) : (
            <Stack spacing={12}>
              {pendingMedications.length > 0 && (
                <Box>
                  <Text fontWeight="500">{t('Pending Medications')}</Text>
                  <Text mb={8} fontSize="12px">
                    {t('These medications have been prescribed but are not being taken yet.')}
                  </Text>
                  <MedicationsList
                    phrMedications={pendingMedications}
                    inverse={inverse}
                    allowRefills={false}
                    phrConsultID={phrConsultID}
                    onRefill={onRefill}
                    allMedications={phrPatient?.phr?.phrMedications ?? []}
                  />
                </Box>
              )}

              {activeMedications.length > 0 && (
                <Box>
                  <Text fontWeight="500">{t('Active Medications')}</Text>
                  <Text fontSize="12px">{t('Patient is currently taking these Medications')}</Text>
                  <MedicationsList
                    phrMedications={activeMedications}
                    onDiscontinue={onDiscontinue}
                    inverse={inverse}
                    allowRefills={!!phrConsultID}
                    phrConsultID={phrConsultID}
                    consultCountryID={country.countryID}
                    onRefill={onRefill}
                    allMedications={phrPatient?.phr?.phrMedications ?? []}
                  />
                </Box>
              )}

              {pastMedications.length > 0 && (
                <Box>
                  <Text fontWeight="500">{t('Past Medications')}</Text>
                  <Text fontSize="12px">{t('Discontinued Medications')}</Text>
                  <MedicationsList
                    phrMedications={pastMedications}
                    onDiscontinue={onDiscontinue}
                    inverse={inverse}
                    allowRefills={!!phrConsultID}
                    phrConsultID={phrConsultID}
                    consultCountryID={country.countryID}
                    onRefill={onRefill}
                    allMedications={phrPatient?.phr?.phrMedications ?? []}
                  />
                </Box>
              )}
            </Stack>
          )}
        </>
      )}

      {phrConsultID && phrPatient?.patient && country && showCreatePrescription && (
        <PrescriptionOrderModal
          phrConsultID={phrConsultID}
          onDismiss={() => setShowCreatePrescription(false)}
          isOpen={showCreatePrescription}
          prescription={prescription}
          patientID={phrPatient.patient.patientID}
          setPrescription={setPrescription}
          country={country}
          patient={phrPatient.patient}
        />
      )}
      {provider && (
        <SignatureModal
          provider={provider}
          onCancel={() => setShowSignatureMissingModal(false)}
          isOpen={showSignatureMissingModal}
        />
      )}
      <DiscontinueMedicationModal
        isOpen={showDeleteModal}
        onDismiss={() => setShowDeleteModal(false)}
        onSubmit={confirmDiscontinue}
      />
    </>
  );
};

export default ConsultMedications;
