import { Box, Flex, HStack, Image, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { ChevronRightIcon2 } from '../../../../../../../assets/icons';
import { Tag } from '../../../../../../../generated';
import { OutpostTheme } from '../../../../../../../themes/outpost';
import useTranslationComponent from '../../../../../../../hooks/useTranslationComponent';

type ConsultationTypeCardProps = {
  consultationType: Tag;
  onSelectConsultationCareType?: (consultationCareType: Tag) => void;
};

const ConsultationTypeCard: FC<ConsultationTypeCardProps> = (props) => {
  const { t } = useTranslationComponent(['heal']);

  return (
    <Flex
      role="button"
      bgColor="white"
      borderRadius="xl"
      border="1px solid transparent"
      transition="border-color 0.3s ease"
      px={[4, 8]}
      py="4"
      _hover={{ borderColor: 'rgb(0, 3, 16)' }}
      width="100%"
      alignItems="center"
      onClick={() =>
        props.onSelectConsultationCareType &&
        props.onSelectConsultationCareType(props.consultationType)
      }
    >
      <HStack spacing="5" width="100%" justifyContent="space-between" minH="85px">
        <Box>
          <HStack spacing="2" mb="1">
            <Box bgColor={props.consultationType.color} borderRadius="md" p={2}>
              <Image src={props.consultationType.imageURL} height="20px" width="20px" />
            </Box>
            <Text fontSize="16px" color="rgb(0, 3, 16)" fontWeight="medium">
              {t(props.consultationType.name)}
            </Text>
          </HStack>
          <Text fontSize={OutpostTheme.FontSizes.body} color="rgb(65, 79, 101)">
            {t(props.consultationType.description)}
          </Text>
        </Box>
        <Box>
          <ChevronRightIcon2 fontSize="10px" />
        </Box>
      </HStack>
    </Flex>
  );
};

export { ConsultationTypeCard };
