import { ConsultProviderSearchStoreResult } from '../generated';

const getSortedStoresByDistance = (clinics?: ConsultProviderSearchStoreResult[]) => {
  if (!clinics) return [];
  return clinics.sort((a, b) => {
    if (!a.distance) return 1;
    if (!b.distance) return -1;

    return a.distance - b.distance;
  });
};

export { getSortedStoresByDistance as sortStoresByDistance };
