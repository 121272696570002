import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { useField, useFormikContext } from 'formik';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import ReactSelect, { components } from 'react-select';
import { WindowedMenuList } from 'react-windowed-select';
import styled from 'styled-components';
import { getReactSelectStyle, TimeFormatEnum } from '../../../constants';
import { OutpostTheme } from '../../../themes/outpost';
import { FormControl } from '../FormControl/FormControl';

interface ITimezonePicker {
  label: string;
  name: string;
  timezones: any;
  defaultValueKey: string;
  isLoading?: boolean;
  isRequired?: boolean;
}

const TimezonePicker = ({
  label,
  name,
  timezones,
  defaultValueKey,
  isLoading,
  isRequired = false,
}: ITimezonePicker) => {
  const [tzGroupOptions, setTimezoneGroupOptions] = useState<any[]>([]);
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const getTimezoneOption = (tz) => {
    return {
      value: tz.timeZoneID,
      label: tz.description,
      country: tz.country,
      utcOffset: tz.utcOffSetMinutes,
      isDST: tz.supportsDaylightSavingTime,
      standard: tz.standardName,
    };
  };

  const generateTimezoneGroupOptions = useCallback((timezones) => {
    let previousCountry = '';
    const initailValue = {
      [timezones[0].country]: {
        label: timezones[0].country,
        options: [getTimezoneOption(timezones[0])],
      },
    };
    const reducer = (acc, cur) => {
      const obj = Object.assign(acc, {
        [cur.country]: {
          label: cur.country,
          options:
            cur.country === previousCountry && acc[cur.country]?.options
              ? [...acc[cur.country].options, getTimezoneOption(cur)]
              : [getTimezoneOption(cur)],
        },
      });
      previousCountry = cur.country;
      return obj;
    };
    const compare = (a, b) => a.country.localeCompare(b.country);

    return Object.values(timezones.sort(compare).reduce(reducer, initailValue));
  }, []);

  // group label search
  const filterOption = ({ label, value }, string) => {
    const keyword = string.toLowerCase();
    if (label.toLowerCase().includes(keyword) || value.toLowerCase().includes(keyword)) {
      return true;
    }
    const groupOptions = tzGroupOptions.filter((group) =>
      group.label.toLocaleLowerCase().includes(keyword),
    );
    if (groupOptions) {
      for (const groupOption of groupOptions) {
        const option = groupOption.options.find((opt) => opt.value === value);
        if (option) {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    if (timezones?.length > 0) {
      setTimezoneGroupOptions(generateTimezoneGroupOptions(timezones));
    }
  }, [generateTimezoneGroupOptions, timezones]);

  // Because react-select does a deep equal check, we need to find the exact object that is within the options array
  const findDefaultValue = () => {
    return tzGroupOptions
      .find((x) => x.options.filter((y) => y.value === defaultValueKey).length > 0)
      ?.options.find((z) => z.value === defaultValueKey);
  };

  return (
    <>
      {tzGroupOptions.length > 0 && (
        <FormControl
          isRequired={isRequired}
          label={label}
          error={meta.error}
          touched={meta.touched}
        >
          {/* @ts-ignore */}
          <ReactSelect
            isLoading={isLoading}
            name={field.name}
            isClearable={false}
            menuPosition="fixed"
            styles={getReactSelectStyle()}
            onChange={(option: any) => setFieldValue(field.name, option.value)}
            components={{ Group, Option, MenuList: WindowedMenuList, SingleValue }}
            /* essential properties for Group Select (options, defaultValue) */
            options={tzGroupOptions}
            filterOption={filterOption}
            defaultValue={findDefaultValue()}
          />
        </FormControl>
      )}
    </>
  );
};

export default TimezonePicker;

const Group = (props) => (
  <StyledGroup>
    <components.Group {...props} />
  </StyledGroup>
);

const Option = (props) => {
  delete props.innerProps.onMouseMove;
  delete props.innerProps.onMouseOver;

  const offsetMinute = props.data.isDST
    ? parseInt(props.data.utcOffset) + 60
    : props.data.utcOffset;
  const localtime = moment().utc().add(offsetMinute, 'minute');
  return (
    <components.Option {...props}>
      <Flex
        my={1}
        pl={1}
        flexWrap="wrap"
        _hover={{ backgroundColor: '#f4f6fa', cursor: 'pointer !important' }}
        width="100%"
      >
        <Text mr={1}>{props.children}</Text>

        <Text mr={1} color="gray.500">
          {localtime.format(TimeFormatEnum.TIME)}
        </Text>

        <Text
          mr={1}
          fontSize={OutpostTheme.FontSizes.body}
          display={{ base: 'none', md: 'block' }}
          color="gray.500"
        >{`${props.data.standard}`}</Text>
      </Flex>
    </components.Option>
  );
};

const SingleValue = (props) => {
  const offsetMinute = props.data.isDST
    ? parseInt(props.data.utcOffset) + 60
    : props.data.utcOffset;
  const localtime = moment().utc().add(offsetMinute, 'minute');
  return (
    <Box _hover={{ cursor: 'pointer' }}>
      <components.SingleValue {...props}>
        <HStack spacing={1}>
          <Text>{props.children}</Text>
          <Text color="gray.500">{localtime.format(TimeFormatEnum.TIME)}</Text>
          <Text
            display={{ base: 'none', md: 'block' }}
            color="gray.500"
          >{`- ${props.data.standard}`}</Text>
        </HStack>
      </components.SingleValue>
    </Box>
  );
};

const StyledGroup = styled.div`
  color: ${(props) => props.theme.Colors.Primary};
  padding-top: 5px;
`;
