import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';

import ImageViewer from 'react-simple-image-viewer';
import { Avatar } from '../../../../components/common/Avatar';
import { AddressBook, PHRDocument, PHRNote } from '../../../../generated';
import { getUserThumbnail } from '../../../../utils/getUserThumbnail';
import { ViewPhrDocument } from '../../components/ViewPhrDocument';

interface IProps {
  selectedAddress?: AddressBook;
  referralNote?: PHRNote;
}

export const ProviderCardBase = ({ selectedAddress, referralNote }: IProps) => {
  return (
    <Flex align="center" p={4}>
      <Avatar size="lg" src={getUserThumbnail(selectedAddress?.imageDetailID)} />
      <VStack spacing="1" align="start" flexGrow={1} ml={4}>
        <Text fontSize="sm">
          {selectedAddress?.name || selectedAddress?.fullName || referralNote?.sendToName}{' '}
        </Text>
        <Text fontSize="xs" color="gray.500">
          {selectedAddress?.city && `${selectedAddress?.city}, `}
          {selectedAddress?.province}
        </Text>
        <Text fontSize="xs" color="gray.500">
          {selectedAddress?.addressBookFolderEnum || referralNote?.visibilityEnum}
        </Text>
      </VStack>
    </Flex>
  );
};

interface IChosenProviderCardProps extends IProps {
  ActionButton?: React.ReactNode;
}

export const ChosenProviderCard = ({ selectedAddress, ActionButton }: IChosenProviderCardProps) => {
  return (
    <Flex
      justify="space-between"
      align="center"
      width="100%"
      bgColor="#FCFCFC"
      border="1px solid #C4C4C421"
      borderRadius={5}
      p={4}
      mb={12}
    >
      <ProviderCardBase selectedAddress={selectedAddress} />

      {ActionButton}
    </Flex>
  );
};

interface IProviderCardProps extends IProps {
  downloadNote: (patientID: string, phrDocumentID: string) => void;
}

export const ProviderCard = ({ selectedAddress, referralNote }: IProviderCardProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isImageOpen, setIsImageOpen] = useState<boolean>(false);
  const [downloadDoc, setDownloadDoc] = useState<PHRDocument>();

  const handleView = (document: PHRDocument) => {
    if (document.fileDetail?.contentType === 'application/pdf') {
      setDownloadDoc(document);
      setIsOpen(!isOpen);
    } else {
      setDownloadDoc(document);
      setIsImageOpen(!isImageOpen);
    }
  };

  return (
    <>
      <Box
        width="100%"
        bgColor="#FCFCFC"
        border="1px solid #C4C4C421"
        borderRadius={5}
        height="fit-content"
      >
        <ProviderCardBase selectedAddress={selectedAddress} referralNote={referralNote} />
        <Button
          borderTop="1px solid #C4C4C421"
          width="100%"
          height="40px"
          borderRadius="none"
          backgroundColor="transparent"
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
          onClick={() => handleView(referralNote?.phrDocument!)}
        >
          <Text fontSize="xs">View Referral</Text>
        </Button>
      </Box>

      {isOpen && downloadDoc && downloadDoc.fileDetail?.contentType === 'application/pdf' && (
        <ViewPhrDocument
          patientID={downloadDoc.patientID ?? ''}
          documentID={downloadDoc.phrDocumentID ?? ''}
          onClose={() => setIsOpen(!isOpen)}
          fileName={downloadDoc.fileDetail?.name ?? ''}
        />
      )}

      {isImageOpen && (
        <ImageViewer
          src={[
            `/api/v1/PHRs/PHRDocuments/${downloadDoc?.patientID}/DocumentAsSrc/${downloadDoc?.phrDocumentID}`,
          ]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          onClose={() => {
            setIsImageOpen(false);
            setDownloadDoc(undefined);
          }}
        />
      )}
    </>
  );
};
