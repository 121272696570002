import { ProviderSettingEnum, RolesEnum, IProvider } from '../generated';
import { isInRole } from './isInRole';
import { ICurrentUser } from '../store/currentUser/currentUserReducers';

export function providerHasSetting(
  currentUser: ICurrentUser | null,
  provider: IProvider | null,
  providerSettingEnum: ProviderSettingEnum,
): boolean {
  if (!currentUser || !provider) {
    return false;
  }

  return (
    isInRole(currentUser, RolesEnum.Provider) &&
    (provider.providerSettings ?? []).filter((x) => x.providerSettingEnum === providerSettingEnum)
      .length > 0
  );
}
