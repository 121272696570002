import { ConsultTypeEnum, StoreItemTypeEnum } from '../../../generated';
import { BookingFlowEnum } from '../components/PublicSearchComponents/enums';
import { UsePublicBookingFlowReturnType } from './usePublicBookingFlow';

type UseVisitTypeProps = UsePublicBookingFlowReturnType;

const getConsultTypeEnum = (storeItemTypeEnum: StoreItemTypeEnum) => {
  switch (storeItemTypeEnum) {
    case StoreItemTypeEnum.InPersonConsult:
      return ConsultTypeEnum.InPerson;
    case StoreItemTypeEnum.VideoConsult:
      return ConsultTypeEnum.Video;
  }
};

const getNextStep = (storeItemTypeEnum: StoreItemTypeEnum) => {
  switch (storeItemTypeEnum) {
    case StoreItemTypeEnum.InPersonConsult:
      return BookingFlowEnum.InPersonVisit;
    case StoreItemTypeEnum.VideoConsult:
      return BookingFlowEnum.Appointments;
  }
};

const useVisitType = (props: UseVisitTypeProps) => {
  const handleNextStep = (storeItemType: StoreItemTypeEnum) => {
    props.handleUpdateBookingData({
      storeItemTypeEnum: storeItemType,
      consultTypeEnum: getConsultTypeEnum(storeItemType),
      bookingFlowStepEnum: getNextStep(storeItemType),
    });
  };

  return {
    handleNextStep,
  };
};

export { useVisitType };
