import {
  IPHRConsult,
  NoteTypeEnum,
  PHRNote,
  SendToEnum,
  VisibilityEnum,
} from '../../../../generated';

export const constructReferralNote = (consult: IPHRConsult, sendToID: string, reason: string) => {
  const phrNote = new PHRNote();
  phrNote.noteTypeEnum = NoteTypeEnum.Referral;
  phrNote.notes = reason;

  phrNote.sendSubject = `Patient Referral - ${consult?.patient?.fullName}`;
  phrNote.sendMessage = reason;
  phrNote.sendToEnum = SendToEnum.UserDetail;
  phrNote.sendToID = sendToID;

  phrNote.patientID = consult?.patient?.patientID ?? '';
  phrNote.phrConsultID = consult?.phrConsultID;
  phrNote.visibilityEnum = VisibilityEnum.PatientAndProvider;

  return phrNote;
};
