import { Box, Grid, HStack, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import ImageViewer from 'react-simple-image-viewer';

import { CalendarIcon2 } from '../../../../assets/icons';
import { TimeFormatEnum } from '../../../../constants';
import { PHRDocument, PHRTrackerCovidDTO } from '../../../../generated';
import { usePhrDocumentsByAssociatedID } from '../../../../services/phrDocuments/queries/usePhrDocumentsByAssociatedID';
import {
  getAllForPhrSection,
  verifyForPhrSection,
} from '../../../../store/patient/phr/phrCreators';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import { successToastr } from '../../../../utils/toastr';

import { SecondaryButton } from '../../../../components/Buttons';
import { OutpostTheme } from '../../../../themes/outpost';
import PhrItemView from '../../components/PhrItemView';
import { ViewPhrDocument } from '../../components/ViewPhrDocument';

interface IVaccinationViewProps {
  covidTest: PHRTrackerCovidDTO;
  hasJustVerified: boolean;
  setHasJustVerified: (hasVerified: boolean) => void;
  fetchCovidQRCodeFile?: () => void;
}

const CovidTestView = ({
  covidTest,
  hasJustVerified,
  setHasJustVerified,
  fetchCovidQRCodeFile,
}: IVaccinationViewProps) => {
  const dispatch = useDispatch();
  const [currentDoc, setCurrentDoc] = useState<PHRDocument | null>(null);

  const { phrDocuments: existingDocuments = [] } = usePhrDocumentsByAssociatedID(
    covidTest.patientID!,
    covidTest?.phrTrackerDetailID ?? '',
    hasJustVerified,
  );

  const onVerify = async (id: string, patientID: string) => {
    await dispatch(verifyForPhrSection(ReduxPHRCategoryEnum.PHRTrackers, id, patientID));
    successToastr({ description: 'Successfully verified covid test' });
    setHasJustVerified(true);

    await dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRCovids, patientID));

    await fetchCovidQRCodeFile?.();
  };

  return (
    <>
      <PhrItemView
        phrItem={{ ...covidTest, name: 'Covid Test' }}
        onVerify={() => onVerify(covidTest.phrTrackerDetailID!, covidTest.patientID!)}
      >
        <Grid templateColumns="repeat(2, 1fr)" gridColumnGap="1rem" gridRowGap="1.5rem">
          <Box>
            <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
              Test Type
            </Text>
            <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
              {covidTest.testType}
            </Text>
          </Box>
          <Box>
            <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
              Manufacturer
            </Text>
            <Text
              fontSize={OutpostTheme.FontSizes.body}
              marginTop="0.5rem"
              display="flex"
              alignItems="center"
            >
              {covidTest.covidTestBrandTypeTag?.name || 'Unknown Brand'}
            </Text>
          </Box>

          <Box>
            <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
              Result
            </Text>
            <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
              {covidTest.testResult !== undefined && covidTest.testResult ? 'Postive' : 'Negative'}
            </Text>
          </Box>
          <Box>
            <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
              Test Location
            </Text>
            <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
              {covidTest.city}
            </Text>
          </Box>

          <Box>
            <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
              Test Date
            </Text>
            <Text
              fontSize={OutpostTheme.FontSizes.body}
              marginTop="0.5rem"
              display="flex"
              alignItems="center"
            >
              <CalendarIcon2 fontSize="18px" color="#78819D" />
              &nbsp;{moment(covidTest.trackerDate).format(TimeFormatEnum.SHORT_LONG_DATE)}
            </Text>
          </Box>
          <Box>
            <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
              Test Time
            </Text>
            <Text
              fontSize={OutpostTheme.FontSizes.body}
              marginTop="0.5rem"
              display="flex"
              alignItems="center"
            >
              {moment(covidTest.trackerDate).format(TimeFormatEnum.TIME)}
            </Text>
          </Box>

          <Box>
            <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
              Test Serial Number
            </Text>
            <Text
              fontSize={OutpostTheme.FontSizes.body}
              marginTop="0.5rem"
              display="flex"
              alignItems="center"
            >
              {covidTest.serialNumber || '-'}
            </Text>
          </Box>

          <Box>
            <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
              Passport Number
            </Text>
            <Text
              fontSize={OutpostTheme.FontSizes.body}
              marginTop="0.5rem"
              display="flex"
              alignItems="center"
            >
              {covidTest.passport || '-'}
            </Text>
          </Box>

          <Box gridColumn={{ base: 'span 2', md: '1' }}>
            <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
              Attached Documents
            </Text>

            {existingDocuments.map((doc) => (
              <HStack
                spacing={8}
                justify="space-between"
                fontSize={OutpostTheme.FontSizes.body}
                marginTop="0.5rem"
              >
                <Text>{doc.fileDetail?.name}</Text>
                <SecondaryButton
                  borderRadius="full"
                  height="1.5rem"
                  onClick={() => setCurrentDoc(doc)}
                >
                  View
                </SecondaryButton>
              </HStack>
            ))}
          </Box>
        </Grid>
      </PhrItemView>

      {currentDoc && currentDoc.fileDetail?.contentType === 'application/pdf' && (
        <ViewPhrDocument
          patientID={covidTest.patientID ?? ''}
          documentID={currentDoc.phrDocumentID ?? ''}
          onClose={() => setCurrentDoc(null)}
          fileName={currentDoc.fileDetail?.name ?? ''}
        />
      )}

      {currentDoc && currentDoc.fileDetail?.contentType !== 'application/pdf' && (
        <ImageViewer
          src={[
            `/api/v1/PHRs/PHRDocuments/${covidTest.patientID}/DocumentAsSrc/${currentDoc.phrDocumentID}`,
          ]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          onClose={() => {
            setCurrentDoc(null);
          }}
        />
      )}
    </>
  );
};

export default CovidTestView;
