import React, { useState } from 'react';
import styled from 'styled-components';
import { BorderRadius } from '../../constants';
import { Paragraph } from '../common/Paragraph';
import { Document, Page } from 'react-pdf';
import { CenteredLoadingIndicator } from '../LoadingIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ServerValidationBox from '../ServerValidationBox';
import { saveAs } from 'file-saver';
import { CloseIcon } from '@chakra-ui/icons';
import useTranslationComponent from '../../hooks/useTranslationComponent';

interface IProps {
  pdf?: Blob | string;
  initialScale?: number;
  fileName?: string;
  fixedControls?: boolean;
  onClose?: () => void;
}

export const PdfViewer = React.memo(
  ({
    pdf,
    initialScale = 1,
    fileName = new Date().toISOString(),
    fixedControls = false,
    onClose,
  }: IProps) => {
    const { t } = useTranslationComponent();
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [error, setError] = useState<string>('');
    const [scale, setScale] = useState<number>(initialScale);

    const save = () => {
      saveAs(pdf, fileName + '.pdf');
    };

    return error ? (
      <ServerValidationBox message={error} />
    ) : (
      <Wrapper>
        <Document
          loading={<CenteredLoadingIndicator />}
          file={pdf}
          onLoadError={(error) => setError(error.message)}
          onLoadProgress={({ loaded, total }) =>
            console.log(`${t('Loading a document')}: ` + (loaded / total) * 100 + '%')
          }
          onRenderError={(error) => console.log(t('render error'), error)}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        >
          <Page scale={scale} pageNumber={pageNumber} />
          {pdf && (
            <>
              <ZoomControls fixed={fixedControls}>
                <ZoomControl onClick={save}>
                  <FontAwesomeIcon icon={['far', 'save']} />
                </ZoomControl>
                <ZoomControl onClick={() => setScale((prev) => prev + 0.1)}>
                  <FontAwesomeIcon icon={['far', 'search-plus']} />
                </ZoomControl>
                <ZoomControl onClick={() => setScale((prev) => prev - 0.1)}>
                  <FontAwesomeIcon icon={['far', 'search-minus']} />
                </ZoomControl>
                {onClose && (
                  <ZoomControl onClick={() => onClose()}>
                    <CloseIcon w={3} h={3} />
                  </ZoomControl>
                )}
              </ZoomControls>
              <PageControl fixed={fixedControls}>
                <NavButton
                  disabled={pageNumber === 1}
                  onClick={() => setPageNumber((prev) => prev - 1)}
                >
                  <FontAwesomeIcon icon={['far', 'chevron-left']} />
                </NavButton>
                <PageCount>
                  {pageNumber} {t('of')} {numPages}
                </PageCount>
                <NavButton
                  disabled={pageNumber === numPages}
                  onClick={() => setPageNumber((prev) => prev + 1)}
                >
                  <FontAwesomeIcon icon={['far', 'chevron-right']} />
                </NavButton>
              </PageControl>
            </>
          )}
        </Document>
      </Wrapper>
    );
  },
);

interface IPageControlProps {
  readonly fixed?: boolean;
}

const PageControl = styled.div<IPageControlProps>`
  display: flex;
  align-items: center;
  position: ${(props) => (props.fixed ? 'fixed' : 'absolute')};
  bottom: 20px;
  left: 50%;
  background: white;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.4);
  border-radius: ${BorderRadius.Buttons};
`;

const ZoomControls = styled(PageControl)`
  top: 20px;
  left: 0px;
  flex-direction: column;
  height: 132px;
  @media (max-width: 65rem) {
    left: 25px;
  }
`;

const PageCount = styled(Paragraph)`
  color: ${(props) => props.theme.FontColors.Primary};
  margin: 0 5px;
  font-weight: 500;
`;

const NavButton = styled.button`
  width: 44px;
  height: 44px;
  background-color: white;
  border: none;
  border-radius: ${BorderRadius.Buttons};

  &:hover {
    :not(:disabled) {
      background-color: ${(props) => props.theme.Colors.LightGray};
    }
  }
`;

const ZoomControl = styled(NavButton)``;

const Wrapper = styled.div`
  position: relative;
  &:hover {
    ${PageControl} {
      opacity: 1;
    }
  }

  & > .react-pdf__Document {
    position: relative;
  }

  canvas {
    border-radius: ${BorderRadius.Regular};
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
  }
`;
