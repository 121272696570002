import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { IPartner } from '../../generated';
import {
  clearAction,
  clearErrorAction,
  CurrentPartnerActionTypes,
  setActions,
} from './currentPartnerActions';

const data = createReducer<IPartner | null, CurrentPartnerActionTypes>(null)
  .handleAction([setActions.success], (_state, action) => action.payload)
  .handleAction([clearAction], () => null);

const error = createReducer<string, CurrentPartnerActionTypes>('')
  .handleAction([setActions.failure], (_state, action) => action.payload)
  .handleAction([clearErrorAction], () => '');

const isLoading = createReducer<boolean, CurrentPartnerActionTypes>(false)
  .handleAction([setActions.request], () => true)
  .handleAction([setActions.failure], () => false)
  .handleAction([setActions.success], () => false);

export interface ICurrentPartnerState {
  error: string;
  isLoading: boolean;
  data: IPartner | null;
}

export enum PartnerSettingsEnum {
  APP_LOGO = 'APP_LOGO',
  WELCOME_IMAGE = 'WELCOME_IMAGE',
  STORE_ID = 'STORE_ID',
  SHOW_DASHBOARD_COVID_CARD = 'SHOW_DASHBOARD_COVID_CARD',
  WAITING_ROOM_VIDEO_URL = 'WAITING_ROOM_VIDEO_URL',
  PROHIBIT_PATIENT_REGISTRATION = 'PROHIBIT_PATIENT_REGISTRATION',
  PROHIBIT_PROVIDER_REGISTRATION = 'PROHIBIT_PROVIDER_REGISTRATION',
  SUPPRESS_LOGIN_MENU = 'SUPPRESS_LOGIN_MENU',
  SUPPRESS_MOBILE_APP_STORE = 'SUPPRESS_MOBILE_APP_STORE',
  FAVICON = 'FAVICON',
  BOOKING_WELCOME_SUBTITLE = 'BOOKING_WELCOME_SUBTITLE',
  BOOKING_WELCOME_BUTTON_TEXT = 'BOOKING_WELCOME_BUTTON_TEXT',
  BOOKING_WELCOME_HIDE_OTHER_BUTTON = 'BOOKING_WELCOME_HIDE_OTHER_BUTTON',
  BOOKING_WELCOME_CONSULT_TYPE = 'BOOKING_WELCOME_CONSULT_TYPE',
  BOOKING_SELECT_PATIENT_TITLE = 'BOOKING_SELECT_PATIENT_TITLE',
  BOOKING_IDENTITY_TITLE = 'BOOKING_IDENTITY_TITLE',
  BOOKING_SEE_PROVIDER_TITLE = 'BOOKING_SEE_PROVIDER_TITLE',
  BOOKING_VIDEO_VISIT_ICON = 'BOOKING_VIDEO_VISIT_ICON',
  BOOKING_VIDEO_VISIT_TITLE = 'BOOKING_VIDEO_VISIT_TITLE',
  BOOKING_VIDEO_VISIT_SUBTITLE = 'BOOKING_VIDEO_VISIT_SUBTITLE',
  BOOKING_IN_PERSON_VISIT_ICON = 'BOOKING_IN_PERSON_VISIT_ICON',
  BOOKING_IN_PERSON_VISIT_TITLE = 'BOOKING_IN_PERSON_VISIT_TITLE',
  BOOKING_IN_PERSON_VISIT_SUBTITLE = 'BOOKING_IN_PERSON_VISIT_SUBTITLE',
  BOOKING_PROVIDER_PREFERENCE_DISABLE_GENDER = 'BOOKING_PROVIDER_PREFERENCE_DISABLE_GENDER',
  BOOKING_PROVIDER_PREFERENCE_DISABLE_SPEAKS = 'BOOKING_PROVIDER_PREFERENCE_DISABLE_SPEAKS',
  BOOKING_PROVIDER_PREFERENCE_DISABLE_WORK_FROM = 'BOOKING_PROVIDER_PREFERENCE_DISABLE_WORK_FROM',
  BOOKING_PROVIDER_PREFERENCE_SUBTITLE = 'BOOKING_PROVIDER_PREFERENCE_SUBTITLE',
  BOOKING_PROVIDER_PREFERENCE_TITLE = 'BOOKING_PROVIDER_PREFERENCE_TITLE',
  BOOKING_SEARCH_PROVIDERS_TAB = 'BOOKING_SEARCH_PROVIDERS_TAB',
  BOOKING_SEARCH_CLINIC_TAB = 'BOOKING_SEARCH_CLINIC_TAB',
  BOOKING_SEARCH_PLACEHOLDER = 'BOOKING_SEARCH_PLACEHOLDER',
  BOOKING_SEARCH_NOT_FOUND = 'BOOKING_SEARCH_NOT_FOUND',
  BOOKING_PROVIDER_SUMMARY_CONSULTS = 'BOOKING_PROVIDER_SUMMARY_CONSULTS',
  BOOKING_PROVIDER_SUMMARY_CLINICS = 'BOOKING_PROVIDER_SUMMARY_CLINICS',
  BOOKING_APPOINTMENTS_CLINIC = 'BOOKING_APPOINTMENTS_CLINIC',
  BOOKING_APPOINTMENTS_PROVIDER = 'BOOKING_APPOINTMENTS_PROVIDER',
  BOOKING_APPOINTMENTS_CONSULT_TYPE = 'BOOKING_APPOINTMENTS_CONSULT_TYPE',
  BOOKING_APPOINTMENTS_PATIENT = 'BOOKING_APPOINTMENTS_PATIENT',
  BOOKING_SUMMARY_PATIENT = 'BOOKING_SUMMARY_PATIENT',
  BOOKING_SUMMARY_AGREEMENT = 'BOOKING_SUMMARY_AGREEMENT',
  BOOKING_SUCCESS_CONSULT = 'BOOKING_SUCCESS_CONSULT',
  BOOKING_SUCCESS_THANKS = 'BOOKING_SUCCESS_THANKS',
  BOOKING_SUCCESS_BANNER_IMAGE = 'BOOKING_SUCCESS_BANNER_IMAGE',
  BOOKING_WAITING_ROOM_REVIEW = 'BOOKING_WAITING_ROOM_REVIEW',
  BOOKING_WAITING_ROOM_READY = 'BOOKING_WAITING_ROOM_READY',
  BOOKING_WAITING_ROOM_CHANGE = 'BOOKING_WAITING_ROOM_CHANGE',
  BOOKING_WAITING_ROOM_SEE = 'BOOKING_WAITING_ROOM_SEE',
  BOOKING_WAITING_ROOM_FILL = 'BOOKING_WAITING_ROOM_FILL',
  BOOKING_CHECK_IN_SHOW = 'BOOKING_CHECK_IN_SHOW',
  BOOKING_CHECK_IN_ARRIVED = 'BOOKING_CHECK_IN_ARRIVED',
  DASHBOARD_CALENDAR_BOOK_APPOINTMENT = 'DASHBOARD_CALENDAR_BOOK_APPOINTMENT',
  DASHBOARD_DISABLE_SEE_PROVIDER_CARD = 'DASHBOARD_DISABLE_SEE_PROVIDER_CARD',
  DASHBOARD_APPOINTMENT_CARD_IMAGE = 'DASHBOARD_APPOINTMENT_CARD_IMAGE',
  DASHBOARD_APPOINTMENT_CARD_CHAT = 'DASHBOARD_APPOINTMENT_CARD_CHAT',
  DASHBOARD_APPOINTMENT_CARD_PROVIDER = 'DASHBOARD_APPOINTMENT_CARD_PROVIDER',
  DASHBOARD_APPOINTMENT_CARD_HEALTHCARE = 'DASHBOARD_APPOINTMENT_CARD_HEALTHCARE',
  NETWORK_SEARCH_INPUT_PLACEHOLDER_ALL = 'NETWORK_SEARCH_INPUT_PLACEHOLDER_ALL',
  NETWORK_SEARCH_INPUT_PLACEHOLDER_PROVIDER = 'NETWORK_SEARCH_INPUT_PLACEHOLDER_PROVIDER',
  NETWORK_SEARCH_INPUT_PLACEHOLDER_CLINIC = 'NETWORK_SEARCH_INPUT_PLACEHOLDER_CLINIC',
  NETWORK_SEARCH_HOSPITALS = 'NETWORK_SEARCH_HOSPITALS',
  NETWORK_SEARCH_CLINICS = 'NETWORK_SEARCH_CLINICS',
  NETWORK_SEARCH_PROVIDERS = 'NETWORK_SEARCH_PROVIDERS',
  NETWORK_SEARCH_VIDEO_CONSULTS = 'NETWORK_SEARCH_VIDEO_CONSULTS',
  NETWORK_SEARCH_IN_PERSON_CONSULTS = 'NETWORK_SEARCH_IN_PERSON_CONSULTS',
  NETWORK_SEARCH_HOME_CARE_SELECT = 'NETWORK_SEARCH_HOME_CARE_SELECT',
  NETWORK_SEARCH_HOME_CARE_TITLE = 'NETWORK_SEARCH_HOME_CARE_TITLE',
  NETWORK_SEARCH_HOME_CARE_LOOKING = 'NETWORK_SEARCH_HOME_CARE_LOOKING',
  SYMPTOM_TAG_ID = 'SYMPTOM_TAG_ID',
  CARE_TYPE_TAG_IDs = 'CARE_TYPE_TAG_IDs',
  SYMPTOM_TEXT = 'SYMPTOM_TEXT',
  SYMPTOMS_TEXT = 'SYMPTOMS_TEXT',
}

export default combineReducers<ICurrentPartnerState>({
  data,
  error,
  isLoading,
});
