import {
  AuthClient,
  HeightEnum,
  PHRTrackerBloodOxygenDTO,
  PHRTrackerBloodPressureDTO,
  PHRTrackerHeadCircumferenceDTO,
  PHRTrackerLengthDTO,
  PHRTrackerPulseDTO,
  PHRTrackerRespiratoryDTO,
  PHRTrackersClient,
  PHRTrackerTemperatureDTO,
  PHRTrackerWeightHeightDTO,
  PHRTrackerBloodGlucoseDTO,
  TemperatureTypeEnum,
  WeightEnum,
  BloodGlucoseTypeEnum,
} from '../../../../../generated';
import { httpRequest } from '../../../../../utils';
import {
  convertHeight,
  convertHeight2,
  convertTemperature,
  convertWeight,
  convertBloodGlucose,
} from '../../../../../utils/conversionHelpers';

// edit methods
export const editPHRBloodPressure = async (
  tracker: PHRTrackerBloodPressureDTO,
  values: any,
  patientID: string,
  setError: (error: string) => void,
) => {
  tracker.systolic = Math.round(values.systolic);
  tracker.diastolic = Math.round(values.diastolic);
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerBloodPressurePut(patientID, tracker));
  } catch (error) {
    setError(error as any);
  }
};
export const editPHRPulse = async (
  tracker: PHRTrackerPulseDTO,
  values: any,
  patientID: string,
  setError: (error: string) => void,
) => {
  tracker.pulse = Math.round(values.pulse);
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerPulsePut(patientID, tracker));
  } catch (error) {
    setError(error as any);
  }
};
export const editPHRWeightHeight = async (
  tracker: PHRTrackerWeightHeightDTO,
  values: any,
  patientID: string,
  setError: (error: string) => void,
) => {
  tracker.weight =
    values.weight && convertWeight(true, Number(values.weight), WeightEnum.Kg, values.weightUnit);
  const heightValues = convertHeight2(
    true,
    { centimeters: values.heightCm, feet: values.heightFt, inches: values.heightIn },
    HeightEnum.Cm,
    values.heightUnit,
  );
  tracker.height = heightValues.centimeters!;
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerWeightHeightPut(patientID, tracker));
  } catch (error) {
    setError(error as any);
  }
};
export const editPHRTemperature = async (
  tracker: PHRTrackerTemperatureDTO,
  values: any,
  patientID: string,
  setError: (error: string) => void,
) => {
  tracker.temperature = Number(values.temperature);
  tracker.temperature = convertTemperature(
    true,
    tracker.temperature,
    TemperatureTypeEnum.Celsius,
    values.temperatureUnit,
  );
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerTemperaturePut(patientID, tracker));
  } catch (error) {
    setError(error as any);
  }
};
export const editPHRRespiratory = async (
  tracker: PHRTrackerRespiratoryDTO,
  values: any,
  patientID: string,
  setError: (error: string) => void,
) => {
  tracker.respiratory = Math.round(values.respiratory);
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerRespiratoryPut(patientID, tracker));
  } catch (error) {
    setError(error as any);
  }
};
export const editPHRBloodOxygen = async (
  tracker: PHRTrackerBloodOxygenDTO,
  values: any,
  patientID: string,
  setError: (error: string) => void,
) => {
  tracker.bloodOxygen = Math.round(values.bloodOxygen * 10) / 10;
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerBloodOxygenPut(patientID, tracker));
  } catch (error) {
    setError(error as any);
  }
};
export const editPHRBloodGlucose = async (
  tracker: PHRTrackerBloodGlucoseDTO,
  values: any,
  patientID: string,
  setError: (error: string) => void,
) => {
  tracker.bloodGlucose = Number(values.bloodGlucose);
  tracker.bloodGlucose = convertBloodGlucose(
    true,
    tracker.bloodGlucose,
    BloodGlucoseTypeEnum.MmolL,
    values.bloodGlucoseUnit,
  );
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerBloodGlucosePut(patientID, tracker));
  } catch (error) {
    setError(error as any);
  }
};
export const editPHRHeadCircumference = async (
  tracker: PHRTrackerHeadCircumferenceDTO,
  values: any,
  patientID: string,
  setError: (error: string) => void,
) => {
  tracker.headCircumference = Number(values.headCircumference);
  tracker.headCircumference = convertHeight(
    true,
    tracker.headCircumference,
    HeightEnum.Cm,
    values.headCircumferenceUnit,
  );
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerHeadCircumferencePut(patientID, tracker));
  } catch (error) {
    setError(error as any);
  }
};
export const editPHRLength = async (
  tracker: PHRTrackerLengthDTO,
  values: any,
  patientID: string,
  setError: (error: string) => void,
) => {
  tracker.length = Number(values.length);
  tracker.length = convertHeight(true, tracker.length, HeightEnum.Cm, values.lengthUnit);
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerLengthPut(patientID, tracker));
  } catch (error) {
    setError(error as any);
  }
};
