import { useSelector } from 'react-redux';
import { PartnerSettingsEnum } from '../../store/currentPartner/currentPartnerReducers';
import { AppState } from '../../store/root-reducers';

const usePartnerSettings = () => {
  const currentPartner = useSelector((state: AppState) => state.currentPartnerState.data);
  const currentPartnerLoading = useSelector(
    (state: AppState) => state.currentPartnerState.isLoading,
  );

  const isPartner = !!currentPartner;

  const partnerStoreId = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.STORE_ID,
  )?.value;

  const partnerWelcomeImage = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.WELCOME_IMAGE,
  )?.value;

  const partnerAppLogo = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.APP_LOGO,
  )?.value;

  const partnerFavicon = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.FAVICON,
  )?.value;

  const partnerSuppressLoginMenu =
    !!currentPartner?.partnerSettings?.find(
      ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.SUPPRESS_LOGIN_MENU,
    )?.value || false;

  const partnerBookingWelcomeSubtitle = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.BOOKING_WELCOME_SUBTITLE,
  )?.value;

  const partnerBookingWelcomeButtonText = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_WELCOME_BUTTON_TEXT,
  )?.value;

  const partnerBookingWelcomeHideOtherButton =
    !!currentPartner?.partnerSettings?.find(
      ({ partnerSettingName }) =>
        partnerSettingName === PartnerSettingsEnum.BOOKING_WELCOME_HIDE_OTHER_BUTTON,
    )?.value || false;

  const partnerBookingWelcomeConsultType = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_WELCOME_CONSULT_TYPE,
  )?.value;

  const partnerBookingSelectPatientTitle = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_SELECT_PATIENT_TITLE,
  )?.value;

  const partnerBookingIdentityTitle = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.BOOKING_IDENTITY_TITLE,
  )?.value;

  const partnerBookingSeeProviderTitle = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_SEE_PROVIDER_TITLE,
  )?.value;

  const partnerBookingVideoVisitIcon = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.BOOKING_VIDEO_VISIT_ICON,
  )?.value;

  const partnerBookingVideoVisitTitle = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_VIDEO_VISIT_TITLE,
  )?.value;

  const partnerBookingVideoVisitSubtitle = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_VIDEO_VISIT_SUBTITLE,
  )?.value;

  const partnerBookingInPersonVisitIcon = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_IN_PERSON_VISIT_ICON,
  )?.value;

  const partnerBookingInPersonVisitTitle = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_IN_PERSON_VISIT_TITLE,
  )?.value;

  const partnerBookingInPersonVisitSubtitle = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_IN_PERSON_VISIT_SUBTITLE,
  )?.value;

  const partnerBookingProviderPreferenceTitle = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_PROVIDER_PREFERENCE_TITLE,
  )?.value;

  const partnerBookingProviderPreferenceSubtitle = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_PROVIDER_PREFERENCE_SUBTITLE,
  )?.value;

  const partnerBookingProviderPreferenceDisableWorksFrom =
    !!currentPartner?.partnerSettings?.find(
      ({ partnerSettingName }) =>
        partnerSettingName === PartnerSettingsEnum.BOOKING_PROVIDER_PREFERENCE_DISABLE_WORK_FROM,
    )?.value || false;

  const partnerBookingProviderPreferenceDisableSpeaks =
    !!currentPartner?.partnerSettings?.find(
      ({ partnerSettingName }) =>
        partnerSettingName === PartnerSettingsEnum.BOOKING_PROVIDER_PREFERENCE_DISABLE_SPEAKS,
    )?.value || false;

  const partnerBookingProviderPreferenceDisableGender =
    !!currentPartner?.partnerSettings?.find(
      ({ partnerSettingName }) =>
        partnerSettingName === PartnerSettingsEnum.BOOKING_PROVIDER_PREFERENCE_DISABLE_GENDER,
    )?.value || false;

  const partnerBookingSearchProvidersTab = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_SEARCH_PROVIDERS_TAB,
  )?.value;

  const partnerBookingSearchClinicTab = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_SEARCH_CLINIC_TAB,
  )?.value;

  const partnerBookingSearchNotFound = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.BOOKING_SEARCH_NOT_FOUND,
  )?.value;

  const partnerBookingSearchPlaceholder = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_SEARCH_PLACEHOLDER,
  )?.value;

  const partnerBookingProviderSummaryClinics = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_PROVIDER_SUMMARY_CLINICS,
  )?.value;

  const partnerBookingProviderSummaryConsults = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_PROVIDER_SUMMARY_CONSULTS,
  )?.value;

  const partnerBookingAppointmentsClinic = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_APPOINTMENTS_CLINIC,
  )?.value;

  const partnerBookingAppointmentsProvider = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_APPOINTMENTS_PROVIDER,
  )?.value;

  const partnerBookingAppointmentsPatient = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_APPOINTMENTS_PATIENT,
  )?.value;

  const partnerBookingAppointmentsConsultType = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_APPOINTMENTS_CONSULT_TYPE,
  )?.value;

  const partnerBookingSummaryPatient = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.BOOKING_SUMMARY_PATIENT,
  )?.value;

  const partnerBookingSummaryAgreement = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_SUMMARY_AGREEMENT,
  )?.value;

  const partnerBookingSuccessConsult = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.BOOKING_SUCCESS_CONSULT,
  )?.value;

  const partnerBookingSuccessThanks = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.BOOKING_SUCCESS_THANKS,
  )?.value;

  const partnerBookingSuccessBannerImage = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_SUCCESS_BANNER_IMAGE,
  )?.value;

  const partnerBookingWaitingRoomChange = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_WAITING_ROOM_CHANGE,
  )?.value;

  const partnerBookingWaitingRoomFill = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_WAITING_ROOM_FILL,
  )?.value;

  const partnerBookingWaitingRoomReady = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_WAITING_ROOM_READY,
  )?.value;

  const partnerBookingWaitingRoomReview = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.BOOKING_WAITING_ROOM_REVIEW,
  )?.value;

  const partnerBookingWaitingRoomSee = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.BOOKING_WAITING_ROOM_SEE,
  )?.value;

  const partnerBookingCheckInArrived = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.BOOKING_CHECK_IN_ARRIVED,
  )?.value;

  const partnerBookingCheckInShow = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.BOOKING_CHECK_IN_SHOW,
  )?.value;

  const partnerDashboardAppointmentChat = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.DASHBOARD_APPOINTMENT_CARD_CHAT,
  )?.value;

  const partnerDashboardAppointmentImage = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.DASHBOARD_APPOINTMENT_CARD_IMAGE,
  )?.value;

  const partnerDashboardAppointmentCardProvider = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.DASHBOARD_APPOINTMENT_CARD_PROVIDER,
  )?.value;

  const partnerDashboardAppointmentCardHealthcare = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.DASHBOARD_APPOINTMENT_CARD_HEALTHCARE,
  )?.value;

  const partnerDashboardCalendarBook = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.DASHBOARD_CALENDAR_BOOK_APPOINTMENT,
  )?.value;

  const partnerDashboardDisableSeeProviderCard =
    !!currentPartner?.partnerSettings?.find(
      ({ partnerSettingName }) =>
        partnerSettingName === PartnerSettingsEnum.DASHBOARD_DISABLE_SEE_PROVIDER_CARD,
    )?.value || false;

  const partnerNetworkInputPlaceholderProvider = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.NETWORK_SEARCH_INPUT_PLACEHOLDER_PROVIDER,
  )?.value;

  const partnerNetworkInputPlaceholderAll = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.NETWORK_SEARCH_INPUT_PLACEHOLDER_ALL,
  )?.value;

  const partnerNetworkInputPlaceholderClinic = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.NETWORK_SEARCH_INPUT_PLACEHOLDER_CLINIC,
  )?.value;

  const partnerNetworkProviders = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.NETWORK_SEARCH_PROVIDERS,
  )?.value;

  const partnerNetworkClinics = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.NETWORK_SEARCH_CLINICS,
  )?.value;

  const partnerNetworkHospitals = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.NETWORK_SEARCH_HOSPITALS,
  )?.value;

  const partnerNetworkInPersonConsults = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.NETWORK_SEARCH_IN_PERSON_CONSULTS,
  )?.value;

  const partnerNetworkVideoConsults = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.NETWORK_SEARCH_VIDEO_CONSULTS,
  )?.value;

  const partnerNetworkHomeCareTitle = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.NETWORK_SEARCH_HOME_CARE_TITLE,
  )?.value;

  const partnerNetworkHomeCareSelect = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.NETWORK_SEARCH_HOME_CARE_SELECT,
  )?.value;

  const partnerNetworkHomeCareLooking = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.NETWORK_SEARCH_HOME_CARE_LOOKING,
  )?.value;

  const partnerSymptomTagId = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.SYMPTOM_TAG_ID,
  )?.value;

  const partnerCareTypeTagIds = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.CARE_TYPE_TAG_IDs,
  )?.value;

  const partnerSymptomText = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.SYMPTOM_TEXT,
  )?.value;

  const partnerSymptomsText = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.SYMPTOMS_TEXT,
  )?.value;

  return {
    currentPartner,
    partnerStoreId,
    currentPartnerLoading,
    partnerWelcomeImage,
    partnerAppLogo,
    partnerFavicon,
    partnerBookingWelcomeSubtitle,
    partnerBookingWelcomeButtonText,
    partnerBookingWelcomeHideOtherButton,
    partnerBookingWelcomeConsultType,
    partnerBookingSelectPatientTitle,
    partnerBookingIdentityTitle,
    isPartner,
    partnerBookingSeeProviderTitle,
    partnerBookingVideoVisitIcon,
    partnerBookingVideoVisitTitle,
    partnerBookingVideoVisitSubtitle,
    partnerBookingInPersonVisitIcon,
    partnerBookingInPersonVisitTitle,
    partnerBookingInPersonVisitSubtitle,
    partnerBookingProviderPreferenceTitle,
    partnerBookingProviderPreferenceSubtitle,
    partnerBookingProviderPreferenceDisableWorksFrom,
    partnerBookingProviderPreferenceDisableSpeaks,
    partnerBookingProviderPreferenceDisableGender,
    partnerBookingSearchProvidersTab,
    partnerBookingSearchClinicTab,
    partnerBookingSearchNotFound,
    partnerBookingSearchPlaceholder,
    partnerBookingProviderSummaryClinics,
    partnerBookingProviderSummaryConsults,
    partnerBookingAppointmentsClinic,
    partnerBookingAppointmentsProvider,
    partnerBookingAppointmentsConsultType,
    partnerBookingAppointmentsPatient,
    partnerBookingSummaryPatient,
    partnerBookingSummaryAgreement,
    partnerBookingSuccessConsult,
    partnerBookingSuccessThanks,
    partnerBookingSuccessBannerImage,
    partnerBookingWaitingRoomChange,
    partnerBookingWaitingRoomFill,
    partnerBookingWaitingRoomReady,
    partnerBookingWaitingRoomReview,
    partnerBookingWaitingRoomSee,
    partnerBookingCheckInArrived,
    partnerBookingCheckInShow,
    partnerDashboardAppointmentChat,
    partnerDashboardAppointmentImage,
    partnerDashboardAppointmentCardProvider,
    partnerDashboardCalendarBook,
    partnerDashboardDisableSeeProviderCard,
    partnerDashboardAppointmentCardHealthcare,
    partnerNetworkInputPlaceholderAll,
    partnerNetworkInputPlaceholderClinic,
    partnerNetworkInputPlaceholderProvider,
    partnerNetworkClinics,
    partnerNetworkProviders,
    partnerNetworkVideoConsults,
    partnerNetworkInPersonConsults,
    partnerNetworkHomeCareLooking,
    partnerNetworkHomeCareSelect,
    partnerNetworkHomeCareTitle,
    partnerSuppressLoginMenu,
    partnerSymptomTagId,
    partnerNetworkHospitals,
    partnerCareTypeTagIds,
    partnerSymptomsText,
    partnerSymptomText,
  };
};

export { usePartnerSettings };
