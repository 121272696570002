import { ActionType, createAsyncAction } from 'typesafe-actions';

export type TCurrentApp = {
  isInMobileView?: boolean;
  isMobileSystem: boolean;
  mobileSystem?: 'android' | 'ios'; // Some screens behave different on ios than on android only works with isInMobileView
};

export const getCurrentAppActions = createAsyncAction(
  ['GET_CURRENT_USER_REQUEST', () => {}],
  ['GET_CURRENT_USER_SUCCESS', (res: TCurrentApp) => res],
  ['GET_CURRENT_USER_ERROR', (err: string) => err],
)();

export type CurrentAppActionTypes = ActionType<typeof getCurrentAppActions>;
