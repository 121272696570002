import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ITrackerWeightDTO, TimeFormatEnum } from '../../../../constants';
import { IUserDetailSetting, UserDetailSettingEnum, WeightHeightEnum } from '../../../../generated';
import { IPHRPatientEnhanced } from '../../../../store/patient/phr/phrReducers';
import { phrWeightHeightsSelector } from '../../../../store/patient/phr/phrSelectors';
import { getAllForPhrSection } from '../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import { enumToOptions } from '../../../../utils';
import { calculateBmi, convertHeightWeight } from '../../../../utils/conversionHelpers';
import { Details, ItemName, Value } from '../../components/StyledPhrItems';
import TrackerView from '../TrackerView';
import { WeightAndHeightChart } from './WeightAndHeightChart';
import { WeightAndHeightForm } from './WeightAndHeightForm';

interface IProps {
  patient: IPHRPatientEnhanced;
  userDetailSettings: IUserDetailSetting[];
  renderHeader: (addButton?: any) => any;
}

export const WeightAndHeight = ({ patient, userDetailSettings, renderHeader }: IProps) => {
  const dispatch = useDispatch();
  const phrWeightHeights = phrWeightHeightsSelector(patient.phrWeightHeights);
  const unitValue = userDetailSettings.filter(
    (x) => x.userDetailSettingEnum === UserDetailSettingEnum.WeightHeight,
  )[0].value;
  const [selectedUnit, setSelectedUnit] = useState<WeightHeightEnum>(WeightHeightEnum[unitValue]);
  const [convertedWeightHeights, setConvertedWeightHeights] = useState<ITrackerWeightDTO[]>([]);

  useEffect(() => {
    if (patient) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRWeightHeights, patient.patientID));
    }
  }, [dispatch, patient]);

  useEffect(() => {
    const converted = JSON.parse(JSON.stringify(phrWeightHeights));
    setConvertedWeightHeights(
      converted.map((x) => {
        const { weight, height } = convertHeightWeight(true, x.weight, x.height, selectedUnit);
        // Needs to be calculated first, formula is based on metric system
        if (weight && height) {
          x.bmi = calculateBmi(weight, height);
        }
        x.weight = weight;
        x.height = height;
        return x;
      }),
    );
  }, [selectedUnit, phrWeightHeights]);

  const thinView = (weightHeight: ITrackerWeightDTO) => (
    <>
      {selectedUnit === WeightHeightEnum.Imperial ? (
        <ItemName>
          {!!weightHeight.weight && `${weightHeight.weight} lb`}
          {!!weightHeight.bmi && ' / '}
          {!!weightHeight.height && `${weightHeight.height} in`}
          {!!weightHeight.bmi && ' / '}
          {!!weightHeight.bmi && `${weightHeight.bmi} BMI`}
        </ItemName>
      ) : (
        <ItemName>
          {!!weightHeight.weight && `${weightHeight.weight} kg`}
          {!!weightHeight.bmi && ' / '}
          {!!weightHeight.height && `${weightHeight.height} cm`}
          {!!weightHeight.bmi && ' / '}
          {!!weightHeight.bmi && `${weightHeight.bmi} BMI`}
        </ItemName>
      )}
      <Details>
        <Value>{moment(weightHeight.trackerDate).format(TimeFormatEnum.LONG_DATE_TIME)}</Value>
      </Details>
    </>
  );

  return (
    <TrackerView
      renderHeader={renderHeader}
      phrCategoryEnum={ReduxPHRCategoryEnum.PHRWeightHeights}
      patient={patient}
      trackerData={convertedWeightHeights}
      thinView={thinView}
      Chart={WeightAndHeightChart}
      Form={WeightAndHeightForm}
      createText="Weight and Height"
      unitTypeOptions={enumToOptions(WeightHeightEnum)}
      unitValue={selectedUnit}
      setSelectedUnitValue={setSelectedUnit}
    />
  );
};
