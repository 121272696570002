import {
  AuthClient,
  BloodGlucoseTypeEnum,
  HeightEnum,
  PHRTrackerBloodGlucoseDTO,
  PHRTrackerBloodOxygenDTO,
  PHRTrackerBloodPressureDTO,
  PHRTrackerHeadCircumferenceDTO,
  PHRTrackerLengthDTO,
  PHRTrackerPulseDTO,
  PHRTrackerRespiratoryDTO,
  PHRTrackersClient,
  PHRTrackerTemperatureDTO,
  PHRTrackerWeightHeightDTO,
  TemperatureTypeEnum,
  WeightEnum,
} from '../../../../../generated';
import { httpRequest } from '../../../../../utils';
import {
  convertBloodGlucose,
  convertHeight,
  convertHeight2,
  convertTemperature,
  convertWeight,
} from '../../../../../utils/conversionHelpers';

// create methods
export const createPHRBloodPressure = async (values, patientID, setError) => {
  const bloodPressureDTO = new PHRTrackerBloodPressureDTO();
  bloodPressureDTO.systolic = Math.round(values.systolic);
  bloodPressureDTO.diastolic = Math.round(values.diastolic);
  const tempDate = values.bloodPressureDate;
  // const tempTime = values.bloodPressureTime;

  bloodPressureDTO.trackerDate = tempDate;
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerBloodPressurePost(patientID, bloodPressureDTO));
  } catch (error) {
    setError(error as any);
  }
};

export const createPHRPulse = async (values, patientID, setError) => {
  const pulseDTO = new PHRTrackerPulseDTO();
  pulseDTO.pulse = Math.round(values.pulse);
  const tempDate = values.pulseDate;
  // const tempTime = values.pulseTime;

  pulseDTO.trackerDate = tempDate;
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerPulsePost(patientID, pulseDTO));
  } catch (error) {
    setError(error as any);
  }
};
export const createPHRWeightHeight = async (values, patientID, setError) => {
  values.weight = convertWeight(true, values.weight, WeightEnum.Kg, values.weightUnit);
  const heightValues = convertHeight2(
    true,
    { centimeters: values.heightCm, feet: values.heightFt, inches: values.heightIn },
    HeightEnum.Cm,
    values.heightUnit,
  );
  values.heightCm = heightValues.centimeters;
  const weightHeightDTO = new PHRTrackerWeightHeightDTO();
  weightHeightDTO.weight = values.weight;
  weightHeightDTO.height = values.heightCm;
  weightHeightDTO.trackerDate = values.trackerDate;
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerWeightHeightPost(patientID, weightHeightDTO));
  } catch (error) {
    setError(error as any);
  }
};
export const createPHRTemperature = async (values, patientID, setError) => {
  values.temperature = convertTemperature(
    true,
    values.temperature,
    TemperatureTypeEnum.Celsius,
    values.temperatureUnit,
  );
  const temperatureDTO = new PHRTrackerTemperatureDTO();
  temperatureDTO.temperature = values.temperature;
  const tempDate = values.temperatureDate;
  // const tempTime = values.temperatureTime;

  temperatureDTO.trackerDate = tempDate;
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerTemperaturePost(patientID, temperatureDTO));
  } catch (error) {
    setError(error as any);
  }
};
export const createPHRRespiratory = async (values, patientID, setError) => {
  const respiratoryDTO = new PHRTrackerRespiratoryDTO();
  respiratoryDTO.respiratory = Math.round(values.respiratory);
  const tempDate = values.respiratoryDate;
  // const tempTime = values.respiratoryTime;

  respiratoryDTO.trackerDate = tempDate;
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerRespiratoryPost(patientID, respiratoryDTO));
  } catch (error) {
    setError(error as any);
  }
};
export const createPHRBloodOxygen = async (values, patientID, setError) => {
  const bloodOxygenDTO = new PHRTrackerBloodOxygenDTO();
  bloodOxygenDTO.bloodOxygen = Math.round(values.bloodOxygen * 10) / 10;
  const tempDate = values.bloodOxygenDate;
  // const tempTime = values.bloodOxygenTime;

  bloodOxygenDTO.trackerDate = tempDate;
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerBloodOxygenPost(patientID, bloodOxygenDTO));
  } catch (error) {
    setError(error as any);
  }
};
export const createPHRBloodGlucose = async (values, patientID, setError) => {
  values.bloodGlucose = convertBloodGlucose(
    true,
    values.bloodGlucose,
    BloodGlucoseTypeEnum.MmolL,
    values.bloodGlucoseUnit,
  );
  const bloodGlucoseDTO = new PHRTrackerBloodGlucoseDTO();
  bloodGlucoseDTO.bloodGlucose = values.bloodGlucose;
  const tempDate = values.bloodGlucoseDate;
  // const tempTime = values.bloodGlucoseTime;

  bloodGlucoseDTO.trackerDate = tempDate;
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerBloodGlucosePost(patientID, bloodGlucoseDTO));
  } catch (error) {
    setError(error as any);
  }
};
export const createPHRHeadCircumference = async (values, patientID, setError) => {
  values.headCircumference = convertHeight(
    true,
    values.headCircumference,
    HeightEnum.Cm,
    values.headCircumferenceUnit,
  );
  const headCircumferenceDTO = new PHRTrackerHeadCircumferenceDTO();
  headCircumferenceDTO.headCircumference = values.headCircumference;
  headCircumferenceDTO.trackerDate = values.trackerDate;
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() =>
      client.pHRTrackerHeadCircumferencePost(patientID, headCircumferenceDTO),
    );
  } catch (error) {
    setError(error as any);
  }
};
export const createPHRLength = async (values, patientID, setError) => {
  values.length = convertHeight(true, values.length, HeightEnum.Cm, values.lengthUnit);
  const lengthDTO = new PHRTrackerLengthDTO();
  lengthDTO.length = values.length;
  lengthDTO.trackerDate = values.trackerDate;
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerLengthPost(patientID, lengthDTO));
  } catch (error) {
    setError(error as any);
  }
};
