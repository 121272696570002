import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  AddressBook,
  Country,
  IOrder,
  IPatient,
  PatientOrder,
  PatientOrderFinalizeResultDetail,
} from '../../../../../../../generated';
import { useOrderModalNavigation } from '../../../../hooks/useOrderModalNavigation';

import { useSelectedAddress } from './useFinalizeOrder/useSelectedAddress';
import { useAddOrderTests, UseAddOrderTestsReturnType } from './useAddOrderTests/useAddOrderTests';
import { useFinalizeOrder, UseFinalizeOrderReturnType } from './useFinalizeOrder/useFinalizeOrder';
import { useConfirmOrder } from './useConfirmOrder/useConfirmOrder';
import { useOrderModal, UseOrderModalReturnType } from '../../../../hooks/useOrderModal';

export enum ModalStateEnum {
  SelectOrder = 'SelectOrder',
  Questionair = 'Questionair',
  Build = 'BuildOrder',
  Summary = 'SummaryOrder',
  Confirm = 'ConfirmOrder',
  Success = 'Success',
}

export enum OrderWithQuestionairEnum {
  MRI = 'MRI',
  Mammogram = 'Mammogram',
  CTScan = 'CT Scan',
  Xray = 'Xray',
  NuclearMedicine = 'Nuclear Medicine',
  Ultrasound = 'Ultrasound',
  LabTests = 'Lab Tests',
  Other = 'Other',
}

export type ICreateOrderContext = UseAddOrderTestsReturnType &
  UseFinalizeOrderReturnType & {
    selectedOrderType: IOrder | undefined;
    setSelectedOrderType: Dispatch<SetStateAction<IOrder | undefined>>;
    patientOrder: PatientOrder | undefined;
    setPatientOrder: Dispatch<SetStateAction<PatientOrder | undefined>>;
    modalState: ModalStateEnum;
    setModalState: Dispatch<SetStateAction<ModalStateEnum>>;
    onBack: (() => void) | undefined;
    isLoading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    selectedAddress: AddressBook | undefined;
    setSelectedAddress: Dispatch<SetStateAction<AddressBook | undefined>>;
    isAddressBookInit: boolean;
    patientOrderFinalizeResultDetails: PatientOrderFinalizeResultDetail[] | undefined;
    setPatientOrderFinalizeResultDetails: Dispatch<
      SetStateAction<PatientOrderFinalizeResultDetail[] | undefined>
    >;
    goToNextStep: () => void;
    confirmPatientOrder: () => Promise<void>;
    patientOrderFinalizeLoading: boolean;
    patientOrderFinalizeError: string | null;
    isClinicalOrder?: boolean;

    controller: UseOrderModalReturnType;
  };

export const CreateOrderContext = createContext<ICreateOrderContext>(null!);

interface ICreateProviderProps {
  children: ReactNode;
  draftPatientOrder?: PatientOrder | undefined;
  setPatientOrder: React.Dispatch<React.SetStateAction<PatientOrder | undefined>>;
  patient: IPatient;
  country: Country;
  phrConsultID?: string;
  onOpenNotificationModal?: (content: ReactElement | string[]) => void;
  preSelectedAddressBook?: AddressBook;
  isClinicalOrder?: boolean;
}

export function CreateOrderProvider({
  children,
  draftPatientOrder,
  setPatientOrder,
  patient,
  country,
  phrConsultID,
  onOpenNotificationModal,
  isClinicalOrder,
}: ICreateProviderProps) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const { modalState, setModalState, onBack, goToNextStep } = useOrderModalNavigation({
    draftPatientOrder,
    setPatientOrder,
    setLoading,
  });

  const [selectedOrderType, setSelectedOrderType] = useState<IOrder | undefined>(
    draftPatientOrder?.order,
  );

  const { selectedAddress, setSelectedAddress, isAddressBookInit } = useSelectedAddress({
    patientOrder: draftPatientOrder,
  });

  const [patientOrderFinalizeResultDetails, setPatientOrderFinalizeResultDetails] =
    useState<PatientOrderFinalizeResultDetail[]>();

  const handleOpenSuccessScreen = (resultDetails?: PatientOrderFinalizeResultDetail[]) => {
    setPatientOrderFinalizeResultDetails(resultDetails);
    setModalState(ModalStateEnum.Success);
  };

  const controller = useOrderModal({
    patient,
    countryID: country?.countryID,
    patientID: patient.patientID,
    phrConsultID: phrConsultID,
    onOpenNotificationModal,
    country,
    handleOpenSuccessScreen,
  });

  const countryToUse = controller.selectedLabAddress?.country || country;

  const {
    orderName,
    orderItemOptions,
    addOrderItem,
    removeOrderItem,
    addedOrderItems,
    selectedOrderItem,
    setSelectedOrderItem,
    getOrderItemByOrderItemID,
    isLoadingOrder,
    orderError,
    isUpdating,
    errorUpdating,
    onEdit,
    setIsEditTest,
    isEditTest,
  } = useAddOrderTests({
    selectedOrderType: controller.selectedOrder,
    patientOrder: draftPatientOrder,
    setPatientOrder,
    goToNextStep,
    countryID: countryToUse?.countryID,
    storeID: controller.selectedLabAddress?.associatedID,
    addressBookID: controller.selectedLabAddress?.addressBookID,
    handleUpdateOrderItemList: controller.handleUpdateOrderItemList,
  });

  const { onSelectLab, sendToPatient, isRecalculating, recalcError } = useFinalizeOrder({
    patientOrder: draftPatientOrder,
    setPatientOrder,
    selectedAddress,
    setSelectedAddress,
    isAddressBookInit,
    goToNextStep,
  });

  const {
    confirmPatientOrder,
    isLoading: patientOrderFinalizeLoading,
    error: patientOrderFinalizeError,
  } = useConfirmOrder({
    selectedAddress,
    isAddressBookInit,
    patientOrder: draftPatientOrder,
    setPatientOrder,
    setModalState,
    setPatientOrderFinalizeResultDetails,
  });

  useEffect(() => {
    setModalState(ModalStateEnum.SelectOrder);
    setPatientOrder(undefined);
  }, [setModalState, setPatientOrder]);

  return (
    <CreateOrderContext.Provider
      value={{
        selectedOrderType,
        setSelectedOrderType,
        patientOrder: draftPatientOrder,
        setPatientOrder,
        modalState,
        setModalState,
        onBack,
        isLoading,
        setLoading,
        selectedAddress,
        setSelectedAddress,
        isAddressBookInit,
        patientOrderFinalizeResultDetails,
        setPatientOrderFinalizeResultDetails,
        goToNextStep,

        orderName,
        orderItemOptions,
        addOrderItem,
        removeOrderItem,
        addedOrderItems,
        selectedOrderItem,
        setSelectedOrderItem,
        getOrderItemByOrderItemID,
        isLoadingOrder,
        orderError,
        isUpdating,
        errorUpdating,
        onEdit,
        setIsEditTest,
        isEditTest,

        onSelectLab,
        sendToPatient,
        isRecalculating,
        recalcError,

        confirmPatientOrder,
        patientOrderFinalizeLoading,
        patientOrderFinalizeError,
        isClinicalOrder,
        controller,
      }}
    >
      {children}
    </CreateOrderContext.Provider>
  );
}

export const useCreateOrderContext = () => {
  const context = useContext(CreateOrderContext);
  if (context === undefined) {
    throw new Error('useCreateOrderContext must be used within a CreateOrderProvider');
  }
  return context;
};
