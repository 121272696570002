import { Box, Text } from '@chakra-ui/react';
import { FC, useEffect } from 'react';

import { ProviderStoreItem } from '../../../../../../../generated';

import { useGetRetailAmount } from '../../../hooks/useGetRetailAmount';

import { OutpostTheme } from '../../../../../../../themes/outpost';
import { PrimaryButton } from '../../common/Buttons';
import useTranslationComponent from '../../../../../../../hooks/useTranslationComponent';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../../store/root-reducers';
import { useCurrencyConverter } from '../../../../../../../components/CurrencyConverter/hooks/useCurrencyConverter';
import { CenteredLoadingIndicator } from '../../../../../../../components/LoadingIndicator';

type ProviderAppointmentCardProps = {
  storeItem: ProviderStoreItem;
  onChange?: () => void;
  noChange?: boolean;
};

const ProviderAppointmentCard: FC<ProviderAppointmentCardProps> = (props) => {
  const { t } = useTranslationComponent(['heal']);
  const { getRetailAmount } = useGetRetailAmount();

  const currentPatient = useSelector((state: AppState) => state.patientState.patient);
  const defaultCurrencyEnum = currentPatient?.country?.currencyEnum;
  const fromCurrencyEnum = props.storeItem?.storeItem?.store?.country?.currencyEnum;
  const { conversion, loadingConversion, fetchConversion } = useCurrencyConverter({
    toCurrencyEnum: defaultCurrencyEnum!,
    fromCurrencyEnum: fromCurrencyEnum || defaultCurrencyEnum!,
    fromAmount: parseFloat('1'),
  });

  useEffect(() => {
    const getConversion = async () => {
      await fetchConversion();
    };
    getConversion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      bgColor="white"
      borderRadius="xl"
      border="1px solid transparent"
      transition="border-color 0.3s ease"
      p={{ base: 4, md: 6 }}
      _hover={{ borderColor: 'rgb(0, 3, 16)' }}
      width="100%"
    >
      {loadingConversion ? (
        <CenteredLoadingIndicator />
      ) : (
        <>
          <Box>
            <Text fontSize={OutpostTheme.FontSizes.body} fontWeight="semibold" mb="1">
              {props.storeItem?.storeItem?.item?.name} - {props.storeItem?.storeItem?.minutes}{' '}
              {t('minutes')}
            </Text>
            <Text fontSize={OutpostTheme.FontSizes.body} color="rgb(46, 121, 115)">
              {getRetailAmount(props.storeItem, conversion)}
            </Text>
          </Box>
          {!props.noChange && (
            <Box mt="4">
              <PrimaryButton minW="100px" buttonVariant="secondary" onClick={props.onChange}>
                {t('Change')}
              </PrimaryButton>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export { ProviderAppointmentCard };
