import moment from 'moment';
import React from 'react';
import { Box, Text, HStack, useBreakpointValue } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TimeFormatEnum } from '../../../constants';
import { PHRSurgeryHistory } from '../../../generated';

import PhrItem, { PHRItemList, EmptyPHRItemListCard } from '../components/PhrItem';
import { getTime } from '../../../utils';
import { OutpostTheme } from '../../../themes/outpost';

interface IProps {
  phrSurgeryHistories: PHRSurgeryHistory[];
  onView: (phrSurgeryHistory: PHRSurgeryHistory) => void;
}

const SurgeryHistoryList = ({ phrSurgeryHistories, onView }: IProps) => {
  return (
    <>
      {phrSurgeryHistories.length === 0 ? (
        <EmptyPHRItemListCard>No Surgeries added</EmptyPHRItemListCard>
      ) : (
        <PHRItemList>
          {phrSurgeryHistories
            .sort((a, b) => getTime(b.surgeryDate) - getTime(a.surgeryDate))
            .map((item, index) => (
              <PhrItem
                key={index}
                name={item.name}
                isVerified={item.verified}
                onView={() => onView(item)}
                SecondaryInfo={<SecondaryInfoComponent item={item} />}
              />
            ))}
        </PHRItemList>
      )}
    </>
  );
};

interface ISecondaryInfoComponentProps {
  item: PHRSurgeryHistory;
}

const SecondaryInfoComponent = ({ item }: ISecondaryInfoComponentProps) => {
  const isMobile = useBreakpointValue({ base: false, sm: true });

  return (
    <HStack
      spacing={{ base: 0, md: 2 }}
      fontSize={OutpostTheme.FontSizes.body}
      fontWeight="300"
      color="#011431"
      align="flex-start"
      flexDir={{ base: 'column', md: 'row' }}
      flexWrap="wrap"
    >
      <Box as="span">
        Surgery Date: {moment(item.surgeryDate).format(TimeFormatEnum.SHORT_LONG_DATE)}
      </Box>

      {item.reason && (
        <HStack spacing={2}>
          {isMobile && (
            <FontAwesomeIcon style={{ width: '2px', height: '2px' }} icon={['fas', 'circle']} />
          )}
          <Text as="span">Reason: {item.reason}</Text>
        </HStack>
      )}
    </HStack>
  );
};

export default SurgeryHistoryList;
