import { Box } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Paragraph } from '../../../../components/common/Paragraph';
import Modal from '../../../../components/Modals/Modal';
import { MarketingCampaign } from '../../../../generated';
import { CampaignPropertiesEnum } from '../../../../store/activeMarketingCampaigns/activeMarketingCampaignsReducers';

interface IProps {
  marketingCampaign: MarketingCampaign;
  acknowledgeReward: () => void;
}

export const InviteeNote = ({ marketingCampaign, acknowledgeReward }: IProps) => {
  const [isOpen, setOpen] = useState<boolean>(true);

  const campaignProperties = useMemo(
    () => ({
      inviteeDescription: marketingCampaign.marketingCampaignProperties?.find(
        (property) => property.name === CampaignPropertiesEnum.InviteeDescription,
      ),
      inviteeButtonText: marketingCampaign.marketingCampaignProperties?.find(
        (property) => property.name === CampaignPropertiesEnum.InviteeButtonText,
      ),
      inviteeBannerImage: marketingCampaign.marketingCampaignProperties?.find(
        (property) => property.name === CampaignPropertiesEnum.InviteeBannerImage,
      ),
    }),
    [marketingCampaign.marketingCampaignProperties],
  );

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onSubmit={() => {
        setOpen(false);
        acknowledgeReward();
      }}
      onDismiss={() => {
        setOpen(false);
        acknowledgeReward();
      }}
      submitText={campaignProperties.inviteeButtonText?.value}
      modalFooterProps={{
        px: {
          base: '0',
          md: '10',
        },
      }}
    >
      {campaignProperties.inviteeBannerImage?.value && (
        <Box mt={{ base: '9', md: '5' }}>
          <ImageWrapper img={campaignProperties.inviteeBannerImage?.value} />
        </Box>
      )}

      <Box mt="6">
        {campaignProperties.inviteeDescription?.value && (
          <Paragraph>
            <Box
              as="span"
              dangerouslySetInnerHTML={{
                __html: campaignProperties.inviteeDescription?.value,
              }}
            />
          </Paragraph>
        )}
      </Box>
    </Modal>
  );
};

interface IImageWrapper {
  img: string;
}

const ImageWrapper = styled.div<IImageWrapper>`
  height: 250px;
  background-image: url(${(props) => props.img});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  border-radius: 0.5rem;
`;
