import { FC } from 'react';
import {
  HStack,
  Text,
  StackProps,
  TextProps,
  AvatarProps,
  useBreakpointValue,
} from '@chakra-ui/react';

import { Avatar } from '../../../../../components/common/Avatar';
import { getUserThumbnail } from '../../../../../utils/getUserThumbnail';

type PharmacyCardProps = {
  containerProps?: StackProps;
  avatarProps?: AvatarProps;
  textProps?: TextProps;
  imageID?: string;
  name: string;
};

const PharmacyCard: FC<PharmacyCardProps> = (props) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <HStack {...props.containerProps}>
      <Avatar
        size={isMobile ? 'md' : 'lg'}
        src={getUserThumbnail(props.imageID)}
        {...props.avatarProps}
      />
      <Text fontSize="14px" {...props.textProps}>
        {props.name}
      </Text>
    </HStack>
  );
};

export { PharmacyCard };
