import React from 'react';

import { Checkbox } from '@chakra-ui/react';

import useProviderConsultContext from '../../hooks/useProviderConsultContext';

import { H3 } from '../../../../../../components/common/Header';

import { LargeStyledTextArea } from '../Styled';

const Preceptor = (): JSX.Element => {
  const { isAttested, setAttested, validationMessages, setAttestation, attestation } =
    useProviderConsultContext();

  return (
    <>
      <H3>Attestation: </H3>
      <Checkbox name="isAttested" checked={isAttested} onChange={() => setAttested!(!isAttested)}>
        I reviewed the Resident's recommendations. I agree with the findings and plan of care as
        documented in the Resident's note. Corrections were made to the documentations above and any
        additional information will appear below.
      </Checkbox>

      <LargeStyledTextArea
        name="attestation"
        onChange={(e) => setAttestation(e.target.value)}
        value={attestation || ''}
        label="Attestation Notes"
        formControlProps={{
          error: validationMessages?.attestation,
        }}
      />
    </>
  );
};

export { Preceptor };
