/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { ArrowBackIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  HStack,
  Input,
  TabPanel,
  TabPanels,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { FolderIcon } from '../../../assets/icons';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import { Tab } from '../../../components/Tabs/Tab';
import { TabList } from '../../../components/Tabs/TabList';
import { Tabs } from '../../../components/Tabs/Tabs';
import { AuthClient, PHRDocument, PHRDocumentsClient } from '../../../generated';
import useTranslationComponent from '../../../hooks/useTranslationComponent';
import { ICurrentUser } from '../../../store/currentUser/currentUserReducers';
import { OutpostTheme } from '../../../themes/outpost';
import { httpRequest, prepareForDownload } from '../../../utils';
import PhrDocumentItem from '../components/PhrDocumentItem';
import { ITagSelect } from './Documents';

enum TabViewEnum {
  AllFiles,
  Categories,
}

interface IProps {
  phrDocuments: PHRDocument[];
  currentUser: ICurrentUser;
  onEdit?: (phrDocument: PHRDocument) => void;
  onDelete?: (phrDocumentID: string) => void;
  inverse?: boolean;
  tags?: ITagSelect[];
}

const DocumentsList = React.memo(
  ({ phrDocuments, currentUser, onEdit, onDelete, tags }: IProps) => {
    const { t } = useTranslationComponent(['phr']);
    const [downloadingPhrDocumentIDs, setDownloadingPhrDocumentIDs] = useState<string>('');
    const listOfTags = tags ? [{ label: t('All'), value: 'all', color: undefined }, ...tags] : [];

    const download = async (patientID: string, phrDocumentID: string) => {
      const phrDocumentsClient = new PHRDocumentsClient(new AuthClient());
      setDownloadingPhrDocumentIDs((o) =>
        Object.assign({}, o, {
          [phrDocumentID]: true,
        }),
      );
      try {
        const x = await httpRequest(() => phrDocumentsClient.download(patientID, phrDocumentID));

        const callbackData = JSON.stringify({ patientID, phrDocumentID, fileName: x.fileName! });

        if (currentUser?.isInMobileView && currentUser.mobileSystem === 'android') {
          // @ts-ignore
          AndroidResult.callback(callbackData);
        } else if (currentUser?.isInMobileView && currentUser.mobileSystem === 'ios') {
          // @ts-ignore
          webkit.messageHandlers.downloadPHRDocumentCallback.postMessage(callbackData);
        } else {
          setDownloadingPhrDocumentIDs((o) =>
            Object.assign({}, o, {
              [phrDocumentID]: false,
            }),
          );
          const newBlob = new Blob([x.data]);
          prepareForDownload(x.fileName!, newBlob);
        }
      } catch (err) {}
    };

    const [visibleDocuments, setVisibleDocuments] = useState<PHRDocument[]>(phrDocuments);
    const [searchText, setSearchText] = useState<string>('');
    const [searchCategory, setSearchCategory] = useState<string>('');

    const [activeTab, setActiveTab] = useState<TabViewEnum>(TabViewEnum.AllFiles);
    const [activeFolder, setActiveFolder] = useState<string>('');
    const [folderView, setFolderView] = useState<boolean>(true);

    useEffect(() => {
      setVisibleDocuments(phrDocuments);
    }, [phrDocuments]);

    const filterSearch = (phrDocuments: PHRDocument[], text: string, category: string) => {
      return phrDocuments
        ?.filter((document) => {
          const categoryMatch =
            category === 'all'
              ? true
              : category.length > 0
              ? document?.documentTypeTagID === category
              : true;
          const textMatch =
            text.length > 0
              ? document?.fileDetail?.name.toLowerCase().includes(text.toLowerCase())
              : true;
          return textMatch && categoryMatch;
        })
        .sort((a, b) => (a.createdDate! < b.createdDate! ? 1 : -1));
    };

    const isInMobileWebView =
      currentUser?.isInMobileView &&
      (currentUser.mobileSystem === 'android' || currentUser.mobileSystem === 'ios');

    return (
      <Box height={{ base: 'calc(100vh - 4rem)', md: '600px' }}>
        <Tabs onChange={setActiveTab} index={activeTab}>
          <TabList>
            <Tab>All Files</Tab>
            <Tab>Categories</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {activeTab === TabViewEnum.AllFiles && (
                <>
                  <Flex
                    justify={{ base: 'center', md: 'space-between' }}
                    my={2}
                    direction={{ base: 'column', md: 'row' }}
                  >
                    <Flex
                      alignItems="center"
                      background="#FFFFFF"
                      border="1px solid #E4E9F2"
                      borderRadius="0.25rem"
                      paddingLeft={3}
                      width={{ base: '100%', md: '70%' }}
                    >
                      <SearchIcon color="gray.300" />
                      <Input
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                          setVisibleDocuments(
                            filterSearch(phrDocuments, e.target.value, searchCategory),
                          );
                        }}
                        border="none"
                        focusBorderColor="none"
                        background="none !important"
                        type="text"
                        placeholder={t('Start typing...')}
                      />
                    </Flex>

                    <Box
                      width={{ base: '100%', md: '35%' }}
                      ml={{ base: 0, md: 5 }}
                      mt={{ base: 2, md: 0 }}
                    >
                      <ReactSelect
                        name="documentFilter"
                        options={listOfTags}
                        onChange={(e) => {
                          setSearchCategory(e?.value!);
                          setVisibleDocuments(filterSearch(phrDocuments, searchText, e?.value!));
                        }}
                        placeholder={t('Document Category')}
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            fontSize: '14px',
                            color: state.isSelected || state.isFocused ? '#3E1191' : '#666',
                            background: state.isSelected || state.isFocused ? '#dfd0ff' : '#F7F9FC',
                          }),
                          singleValue: (provided) => {
                            const transition = 'opacity 300ms';
                            return {
                              ...provided,
                              color: '#666',
                              transition,
                              padding: '10px',
                              fontSize: '14px',
                            };
                          },
                          control: (styles, { isFocused }) => ({
                            ...styles,
                            color: '#333',
                            fontSize: '14px',
                            minHeight: '40px',
                            borderColor: isFocused ? 'none !important' : '#E4E9F2',
                          }),
                        }}
                      />
                    </Box>
                  </Flex>

                  {searchText && (
                    <Text fontSize="12px" mt="2" mb="2" color="#b8bdc7">
                      <Box as="span" color="#3E1191">
                        {visibleDocuments.length} results
                      </Box>{' '}
                      found for{' '}
                      <Box as="span" color="#3e1191">
                        "{searchText}"
                      </Box>
                    </Text>
                  )}

                  {visibleDocuments.length === 0 ? (
                    <Flex justify="center" align="center" minH="200px">
                      <VStack align="center">
                        <Text>{t('No Results Found')}</Text>
                      </VStack>
                    </Flex>
                  ) : (
                    visibleDocuments
                      .sort((a, b) => (a?.upsertDate! < b?.upsertDate! ? 1 : -1))
                      .map((x) => (
                        <div style={{ padding: '10px 0px' }} key={x.fileDetailID}>
                          {downloadingPhrDocumentIDs[x.phrDocumentID] && !isInMobileWebView ? (
                            <CenteredLoadingIndicator />
                          ) : (
                            <PhrDocumentItem
                              document={x}
                              download={download}
                              onEdit={onEdit}
                              onDelete={onDelete}
                              currentUser={currentUser}
                            />
                          )}
                        </div>
                      ))
                  )}
                </>
              )}
            </TabPanel>

            <TabPanel>
              {activeTab === TabViewEnum.Categories && (
                <>
                  <Wrap mt={3}>
                    {folderView &&
                      listOfTags?.map((item) => (
                        <WrapItem key={item.value}>
                          <VStack
                            onClick={() => {
                              setVisibleDocuments(filterSearch(phrDocuments, '', item?.value!));
                              setActiveFolder(item?.label!);
                              setFolderView(!folderView);
                            }}
                            spacing="1"
                            alignItems="center"
                            w="130px"
                            p={1}
                            cursor="pointer"
                          >
                            <FolderIcon boxSize="100px" color={item.color} />
                            <Text textAlign="center" fontSize={OutpostTheme.FontSizes.body}>
                              {item.label}
                            </Text>
                          </VStack>
                        </WrapItem>
                      ))}
                  </Wrap>

                  {!folderView && (
                    <>
                      <HStack align="center" spacing="1" mt="2" mb="2">
                        <HStack cursor="pointer" onClick={() => setFolderView(!folderView)}>
                          <ArrowBackIcon boxSize="3" />
                          <Text fontSize={OutpostTheme.FontSizes.body} color="#b8bdc7">
                            {t('Clinical Documents')}
                          </Text>
                        </HStack>

                        <Text fontSize={OutpostTheme.FontSizes.body} color="#3E1191">
                          {' '}
                          {'>'} {activeFolder}
                        </Text>
                      </HStack>
                      {visibleDocuments.length === 0 ? (
                        <Flex justify="center" align="center" minH="200px">
                          <VStack align="center">
                            <Text>{t('Empty Folder')}</Text>
                          </VStack>
                        </Flex>
                      ) : (
                        visibleDocuments
                          .sort((a, b) => (a?.upsertDate! < b?.upsertDate! ? 1 : -1))
                          .map((x) => (
                            <div style={{ padding: '10px 0px' }} key={x.fileDetailID}>
                              {downloadingPhrDocumentIDs[x.phrDocumentID] && !isInMobileWebView ? (
                                <CenteredLoadingIndicator />
                              ) : (
                                <PhrDocumentItem
                                  document={x}
                                  download={download}
                                  onEdit={onEdit}
                                  onDelete={onDelete}
                                  currentUser={currentUser}
                                />
                              )}
                            </div>
                          ))
                      )}
                    </>
                  )}
                </>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    );
  },
);

export default DocumentsList;
