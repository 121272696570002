import { useQuery, UseQueryOptions } from 'react-query';
import { AuthClient, Consult, ConsultsClient } from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

interface IProps {
  providerID?: string;
  options?: UseQueryOptions<any, any, any>;
}

export const useConsultsWaitingRoomPatients = ({ providerID, options = {} }: IProps) => {
  options = { ...options, enabled: !!providerID };
  const {
    isLoading: isLoadingWaitingRoomPatients,
    data: waitingRoomPatients,
    error: waitingRoomPatientsError,
    refetch: refetchWaitingRoomPatients,
  } = useQuery<Consult[], string>(
    QueryKeys.ConsultsWaitingRoomPatients,
    async () =>
      httpRequest(() => new ConsultsClient(new AuthClient()).waitingRoomPatients(providerID!)),
    options,
  );

  return {
    isLoadingWaitingRoomPatients,
    waitingRoomPatients,
    waitingRoomPatientsError,
    refetchWaitingRoomPatients,
  };
};
