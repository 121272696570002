import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  BloodGlucoseTypeEnum,
  IUserDetailSetting,
  PHRTrackerBloodGlucoseDTO,
  UserDetailSettingEnum,
} from '../../../../generated';
import { IPHRPatientEnhanced } from '../../../../store/patient/phr/phrReducers';
import { phrBloodGlucosesSelector } from '../../../../store/patient/phr/phrSelectors';
import { getAllForPhrSection } from '../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import { enumToOptions } from '../../../../utils';
import { convertBloodGlucose } from '../../../../utils/conversionHelpers';
import PhrItem, { SecondaryInfoComponent } from '../../components/PhrItem';
import { VitalsTracker } from '../../vitals/components/PatientVitalsCard/usePatientVitalsCard';
import TrackerView from '../TrackerView';
import BloodGlucoseChart from './BloodGlucoseChart';
import BloodGlucoseForm from './BloodGlucoseForm';

interface IProps {
  patient: IPHRPatientEnhanced;
  userDetailSettings: IUserDetailSetting[];
  renderHeader: (addButton?: any, onBack?: () => void, title?: string) => any;
  defaultBack?: any;
  defaultTitle?: string;
  showSingleTrackerForm?: (id: ReduxPHRCategoryEnum, tracker: VitalsTracker) => void;
}

const BloodGlucoseComponent = ({
  patient,
  userDetailSettings,
  renderHeader,
  showSingleTrackerForm,
  defaultBack,
  defaultTitle,
}: IProps) => {
  const dispatch = useDispatch();
  const unitValue = userDetailSettings.filter(
    (x) => x.userDetailSettingEnum === UserDetailSettingEnum.BloodGlucose,
  )[0].value;
  const [selectedUnitValue, setSelectedUnitValue] = useState<BloodGlucoseTypeEnum>(
    BloodGlucoseTypeEnum[unitValue.charAt(0).toUpperCase() + unitValue.slice(1).replace('/', '')],
  );
  const phrBloodGlucoses = phrBloodGlucosesSelector(patient.phrBloodGlucoses);
  const [convertedBloodGlucoses, setConvertedBloodGlucoses] = useState<PHRTrackerBloodGlucoseDTO[]>(
    [],
  );

  useEffect(() => {
    if (patient) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRBloodGlucoses, patient.patientID));
    }
  }, [dispatch, patient]);

  useEffect(() => {
    const converted = JSON.parse(JSON.stringify(phrBloodGlucoses));
    setConvertedBloodGlucoses(
      converted.map((x) => {
        x.bloodGlucose = convertBloodGlucose(true, x.bloodGlucose, selectedUnitValue);
        return x;
      }),
    );
  }, [selectedUnitValue, phrBloodGlucoses]);

  const thinView = (x: PHRTrackerBloodGlucoseDTO, onView?: (data: any) => void) => (
    <>
      <PhrItem
        name={`${x.bloodGlucose} mol/L`}
        isVerified={x.verified}
        onView={() => {
          if (onView) {
            onView(x);
          }
        }}
        SecondaryInfo={
          <SecondaryInfoComponent
            startDate={moment(x.trackerDate).toDate()}
            startDateTitle="Created on"
            showTime
          />
        }
      />
    </>
  );

  return (
    <TrackerView
      renderHeader={renderHeader}
      phrCategoryEnum={ReduxPHRCategoryEnum.PHRBloodGlucoses}
      patient={patient}
      trackerData={convertedBloodGlucoses}
      thinView={thinView}
      Chart={BloodGlucoseChart}
      Form={BloodGlucoseForm}
      createText="Blood Glucose"
      unitTypeOptions={enumToOptions(BloodGlucoseTypeEnum)}
      unitValue={selectedUnitValue}
      setSelectedUnitValue={setSelectedUnitValue}
      showSingleTrackerForm={showSingleTrackerForm}
      defaultBack={defaultBack}
      defaultTitle={defaultTitle}
    />
  );
};

export default BloodGlucoseComponent;
