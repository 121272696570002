import React from 'react';

import { Box } from '@chakra-ui/react';

import useProviderConsultContext from '../../hooks/useProviderConsultContext';

import { LargeStyledTextArea } from '../Styled';

const CarePlan = (): JSX.Element => {
  const { setPlan, plan, validationMessages, debouncedUpdateSOAPNotes } =
    useProviderConsultContext();

  return (
    <Box id="careplan">
      <LargeStyledTextArea
        name="plan"
        onChange={(e) => {
          setPlan(e.target.value);
          debouncedUpdateSOAPNotes();
        }}
        value={plan || ''}
        label="Plan"
        formControlProps={{ error: validationMessages?.plan }}
      />
    </Box>
  );
};

export { CarePlan };
