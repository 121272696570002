import React from 'react';

import useProviderConsultContext from '../../hooks/useProviderConsultContext';

import { SmallStyledTextArea } from '../Styled';

const Subjective = (): JSX.Element => {
  const { setSubjective, subjective, validationMessages, debouncedUpdateSOAPNotes } =
    useProviderConsultContext();

  return (
    <SmallStyledTextArea
      name="subjective"
      onChange={(e) => {
        setSubjective(e.target.value);
        debouncedUpdateSOAPNotes();
      }}
      value={subjective || ''}
      label="Subjective"
      formControlProps={{ error: validationMessages?.subjective }}
    />
  );
};

export { Subjective };
