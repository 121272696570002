import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertStatus,
  AlertTitle,
  Box,
  CloseButton,
  Collapse,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { SystemMessageType } from '../constants';

interface IProps {
  isDismissed: boolean;
  messageType: SystemMessageType;
  onDismiss: () => void;
  name: string;
  message: string;
}

const SystemMessage = ({ messageType, onDismiss, name, message }: IProps) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const getAlertStatus = (messageType: string): AlertStatus => {
    switch (messageType) {
      case SystemMessageType.info:
        return 'info';
      case SystemMessageType.warning:
        return 'warning';
      case SystemMessageType.danger:
        return 'error';
      default:
        return 'info';
    }
  };

  return (
    <Alert status={getAlertStatus(messageType)} mb={{ base: 4, xl: 0 }}>
      <Box>
        <Flex>
          <AlertIcon />
          <AlertTitle mr={2}>{name}</AlertTitle>
          <Text
            onClick={() => setShowDetails(!showDetails)}
            _hover={{ cursor: 'pointer' }}
            fontSize="xs"
            mt={1}
          >
            {showDetails ? 'Hide details' : 'Show details'}
          </Text>
        </Flex>
        <Collapse in={showDetails} animateOpacity>
          <AlertDescription fontSize="sm">{message}</AlertDescription>
        </Collapse>
        <CloseButton position="absolute" right="8px" top="8px" onClick={onDismiss} />
      </Box>
    </Alert>
  );
};

export default SystemMessage;
