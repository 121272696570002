import {
  Box,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import { CircleIcon } from '../../../assets/icons';
import { TimeFormatEnum } from '../../../constants';
import { PHRDocument } from '../../../generated';
import useTranslationComponent from '../../../hooks/useTranslationComponent';
import { ICurrentUser } from '../../../store/currentUser/currentUserReducers';
import { OutpostTheme } from '../../../themes/outpost';
import { ViewPhrDocument } from './ViewPhrDocument';

interface IProps {
  document: PHRDocument;
  displayVerified?: boolean;
  verified?: boolean;
  verifiedByProviderName?: string;
  verifiedDate?: Date;
  onEdit?: (phrDocument: PHRDocument) => void;
  onDelete?: (phrDocumentID: string) => void;
  download: (patientID: string, phrDocumentID: string) => void;
  currentUser: ICurrentUser;
}

const PhrDocumentItem = ({
  document,

  onEdit,
  onDelete,
  download,
}: IProps) => {
  const { t } = useTranslationComponent(['phr']);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isImageOpen, setIsImageOpen] = useState<boolean>(false);
  const [downloadDoc, setDownloadDoc] = useState<PHRDocument>();
  const [showMenuList, setShowMenuList] = useState<boolean>(false);

  const handleView = () => {
    if (document.fileDetail?.contentType === 'application/pdf') {
      setDownloadDoc(document);
      setIsOpen(!isOpen);
    } else {
      setIsImageOpen(!isImageOpen);
    }
  };

  return (
    <>
      <Box
        width="100%"
        background="#fafafa"
        cursor="pointer"
        onClick={() => {
          if (!showMenuList) {
            setShowMenuList(true);
          }
        }}
      >
        <Flex justify="space-between" align="center" padding="10px" borderRadius="5px">
          <Flex
            align={{ base: 'flex-start', md: 'center' }}
            width="100%"
            justify={{ md: 'space-between' }}
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <HStack>
              <CircleIcon
                boxSize={5}
                color={
                  document?.documentTypeTag?.color ? document?.documentTypeTag?.color : 'gray.500'
                }
              />
              <Box ml={2}>
                <Text cursor="pointer" fontSize={OutpostTheme.FontSizes.body} color="#333">
                  {document?.fileDetail?.name}
                </Text>
                <Text cursor="pointer" fontSize="10px" mt="1">
                  {' '}
                  {moment(document?.createdDate).format(TimeFormatEnum.LONG_DATE_TIME)}
                </Text>
              </Box>
            </HStack>

            <HStack align="center">
              {document?.documentTypeTag && (
                <Box
                  cursor="pointer"
                  mt="1"
                  mr={{ base: 0, md: 2 }}
                  ml={{ base: 5, md: 0 }}
                  borderRadius="10px"
                  p="2"
                  background={
                    document?.documentTypeTag?.color
                      ? `${document?.documentTypeTag.color}26`
                      : 'grey'
                  }
                >
                  <Text
                    color={
                      document?.documentTypeTag?.color
                        ? `${document?.documentTypeTag.color}`
                        : 'gray.500'
                    }
                    fontWeight="bold"
                    fontSize="10px"
                  >
                    {document?.documentTypeTag.name}
                  </Text>
                </Box>
              )}
            </HStack>
          </Flex>

          <Menu
            isOpen={showMenuList}
            onClose={() => setShowMenuList(false)}
            closeOnBlur
            closeOnSelect
          >
            <MenuButton as={IconButton} variant="unstyled" visibility="hidden" />
            <MenuList border="none" boxShadow="lg" minW="120px">
              <MenuItem fontSize="xs" onClick={() => handleView()}>
                {t('View')}
              </MenuItem>
              <MenuItem fontSize="xs" onClick={() => onEdit!(document)}>
                {t('Edit')}
              </MenuItem>
              <MenuItem
                fontSize="xs"
                onClick={() => download(document!.patientID, document!.phrDocumentID)}
              >
                {t('Download')}
              </MenuItem>
              <MenuItem fontSize="xs" onClick={() => onDelete!(document?.phrDocumentID)}>
                {t('Delete File')}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Box>

      {isOpen && downloadDoc && downloadDoc.fileDetail?.contentType === 'application/pdf' && (
        <ViewPhrDocument
          patientID={downloadDoc.patientID ?? ''}
          documentID={downloadDoc.phrDocumentID ?? ''}
          onClose={() => setIsOpen(!isOpen)}
          fileName={downloadDoc.fileDetail?.name ?? ''}
        />
      )}

      {isImageOpen && (
        <ImageViewer
          src={[
            `/api/v1/PHRs/PHRDocuments/${document.patientID}/DocumentAsSrc/${document.phrDocumentID}`,
          ]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          onClose={() => {
            setIsImageOpen(false);
          }}
        />
      )}
    </>
  );
};

export default PhrDocumentItem;
