import { createSelector } from 'reselect';
import { getTime } from '../../utils';
import { IDismissibleSystemMessage } from './systemMessagesReducer';

export const systemMessagesSelector = createSelector(
  (systemMessages: IDismissibleSystemMessage[]) => systemMessages || [],
  (systemMessages) =>
    systemMessages
      .filter((x) => !x.isDismissed)
      .sort((a, b) => getTime(b.systemMessage.startDate) - getTime(a.systemMessage.startDate)),
);
