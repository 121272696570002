import {
  Box,
  Button,
  Center,
  Flex,
  FormControlProps,
  HStack,
  InputRightElement,
  Stack,
  Text,
  useBreakpointValue,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import { components } from 'react-select';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { boolean, date, number, object, string } from 'yup';

import { MagnifyIcon } from '../../../../../assets/icons';
import {
  Form,
  FormError,
  FormInputControl,
  FormMobisPicker,
  FormRadioGroupControl,
  FormSingleCheckbox,
  FormTextArea,
  RadioButtonAltControl,
} from '../../../../../components/forms';
import { AutoComplete, FormSelectComponent } from '../../../../../components/forms/Selects';
import { CenteredLoadingIndicator } from '../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../components/ServerValidationBox';
import { Tag } from '../../../../../components/TagComponent';
import { GLOBAL_MAX_TEXT_LENGTH } from '../../../../../constants';
import {
  AddressBook,
  Country,
  DurationTypeEnum,
  IPatient,
  Medication,
  MedicationPricingTypeEnum,
  MedicationTypeEnum,
  PHRMedication,
} from '../../../../../generated';
import { AppState } from '../../../../../store/root-reducers';
import { OutpostTheme } from '../../../../../themes/outpost';
import { enumToOptions, formatCurrency } from '../../../../../utils';

import useAddNewMedication, {
  DurationCountEnum,
  FormEnum,
  FrequencyEnum,
  IAddNewMedicationForm,
  QuantityPerDoseEnum,
  RefillsEnum,
  RouteEnum,
} from '../../hooks/useAddNewMedication/useAddNewMedication';

import { useSelector } from 'react-redux';
import { DurationHandler } from './DurationHandler';
import { NonFormularyForm } from './NonFormularyForm';
import { OtherHandler } from './OtherHandler';
import useTranslationComponent from '../../../../../hooks/useTranslationComponent';

const defaultFormInputControlProps = {
  hideError: true,
  m: 0,
  minW: '100px',
  formLabelProps: {
    m: 0,
  },
} as Omit<FormControlProps, 'label' | 'children'>;

const defaultFormGroupControlProps = {
  width: 'auto',
  marginInlineStart: 0,
  mb: 0,
  hideError: true,
} as Omit<FormControlProps, 'label' | 'children'>;

interface IProps {
  addMedication: (baseMedication: Medication | null, medication: PHRMedication) => Promise<void>;
  selectedMedication: PHRMedication | null;
  onCancel: () => void;
  country: Country;
  patient: IPatient;
  setShowPrescription: (show: boolean) => void;
  isSubmit?: number;
  isCancel?: number;
  selectedAddress?: AddressBook;
  defaultAddress?: AddressBook;
  handleUpdateMedicationList: (medication: Medication[]) => void;
}

const Control = ({ children, ...rest }) => (
  // @ts-ignore
  <components.Control {...rest}>
    <MagnifyIcon ml={3} fontSize="18px" color="rgba(12,52,104,0.22)" /> {children}
  </components.Control>
);

const Option = ({
  data,
  defaultAddressID,
  selectedAddress,
  ...rest
}: {
  data: Medication;
  defaultAddressID?: string;
  selectedAddress?: AddressBook;
}) => {
  const { t } = useTranslationComponent(['phr']);
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  return (
    // @ts-ignore
    <components.Option {...rest}>
      <HStack justifyContent="space-between">
        <HStack flex="1">
          <Box width="40px">
            <Box
              border="1px solid rgb(0, 3, 16)"
              fontSize="12px"
              borderRadius="100px"
              p="1"
              textAlign="center"
            >
              {data.medicationTypeEnum === MedicationTypeEnum.Medication
                ? 'Rx'
                : data.medicationTypeEnum}
            </Box>
          </Box>
          <Text>{data.name}</Text>
        </HStack>

        {selectedAddress?.associatedID && data.retailAmount && (
          <Box width="100px">
            <Tag
              text={
                data.retailAmount
                  ? formatCurrency(data.retailAmount, data.currencyEnum, currentUser!.cultureInfo)
                  : t('Unavailable')
              }
              containerProps={{
                bgColor: defaultAddressID === data.storeID ? 'rgb(255, 194, 0)' : '#e0f6f4',
                justifyContent: 'center',
              }}
              textProps={{ fontSize: '12px' }}
            />
          </Box>
        )}
      </HStack>
    </components.Option>
  );
};

const AddNewMedication = ({
  addMedication,
  selectedMedication,
  onCancel,
  country,
  setShowPrescription,
  isSubmit,
  isCancel,
  selectedAddress,
  handleUpdateMedicationList,
  defaultAddress,
}: IProps) => {
  const { t } = useTranslationComponent(['phr', 'validation']);

  const {
    initialValues,
    submit,
    listOfMedications,
    medicationSearched,
    clear,
    isLoadingMedications,
    medicationsError,
    onSelectSearchedMedication,
  } = useAddNewMedication({
    onSubmit: addMedication,
    selectedMedication,
    countryID: country.countryID,
    storeID: selectedAddress?.associatedID,
    addressBookID: selectedAddress?.addressBookID,
    onCancel,
    handleUpdateMedicationList,
  });

  const isOnMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    setShowPrescription(!initialValues);
  }, [initialValues, setShowPrescription]);

  const getFrequencyDescription = (frequency: FrequencyEnum) => {
    switch (frequency) {
      case FrequencyEnum.QD:
        return 'every day';
      case FrequencyEnum.BID:
        return 'twice a day';
      case FrequencyEnum.TID:
        return 'three times a day';
      case FrequencyEnum.QID:
        return 'four times a day';
      case FrequencyEnum.QHS:
        return 'every day at bedtime';
      // case FrequencyEnum.FIVEX:
      //   return 'five times a day';
      case FrequencyEnum.Q4H:
        return 'every 4 hours';
      case FrequencyEnum.Q6H:
        return 'every 6 hours';
      // case FrequencyEnum.QOD:
      //   return 'every other day';
    }
  };

  const getRouteDescription = (route: RouteEnum) => {
    switch (route) {
      case RouteEnum.PO:
        return 'orally';
      case RouteEnum.Top:
        return 'topically';
      case RouteEnum.Otic:
        return 'into the ear';
      case RouteEnum.Oph:
        return 'into the eye';
      case RouteEnum.PR:
        return 'rectally';
      case RouteEnum.PV:
        return 'vaginally';
      case RouteEnum.SC:
        return 'under the skin';
    }
  };

  const formRef = useRef<FormikProps<IAddNewMedicationForm>>(null);

  const [newPrescriptionForm, setNewPrescriptionForm] = useState<boolean>(false);

  const searchInputValue = useRef<string>('');

  const showPackQuantity =
    medicationSearched?.medicationPricingTypeEnum === MedicationPricingTypeEnum.PerPackage;

  const onChangeSearchInput = (value: string) => {
    searchInputValue.current = value;
  };

  useEffect(() => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  }, [isSubmit]);

  useEffect(() => {
    setShowPrescription(true);
    clear();
  }, [clear, isCancel, setShowPrescription]);

  const validationSchema: any =
    medicationSearched?.medicationTypeEnum === MedicationTypeEnum.OTC
      ? {
          name: string()
            .required(t('A name is required', { ns: 'validation' }))
            .max(100, t('Name must be at most 100 characters', { ns: 'validation' })),
          notes: string()
            .nullable()
            .max(
              GLOBAL_MAX_TEXT_LENGTH,
              t(`Notes must be at most 10000 characters`, {
                ns: 'validation',
                10000: GLOBAL_MAX_TEXT_LENGTH,
              }),
            ),
        }
      : {
          name: string()
            .required(t('A name is required', { ns: 'validation' }))
            .max(100, t('Name must be at most 100 characters', { ns: 'validation' })),
          frequency: string().test(
            'otherFrequency',
            t('Frequency is required', { ns: 'validation' }),
            function (value) {
              const other = this.parent.otherFrequency;
              return !!other || !!value;
            },
          ),
          otherFrequency: string()
            .trim()
            .max(100, t('Frequency must be at most 100 characters', { ns: 'validation' })),
          quantityPerDose: string().test(
            'otherQuantityPerDose',
            t('Quantity per Dose is required', { ns: 'validation' }),
            function (value) {
              const other = this.parent.otherQuantityPerDose;
              return !!other || !!value;
            },
          ),
          otherQuantityPerDose: string()
            .trim()
            .max(100, t('Quantity per Dose must be at most 100 characters', { ns: 'validation' })),
          form: string().test(
            'otherForm',
            t('Form is required', { ns: 'validation' }),
            function (value) {
              const other = this.parent.otherForm;
              return !!other || !!value;
            },
          ),
          otherForm: string()
            .trim()
            .max(100, t('Form must be at most 100 characters', { ns: 'validation' })),
          methodOfIntake: string().test(
            'otherMethodOfIntake',
            t('Route is required', { ns: 'validation' }),
            function (value) {
              const other = this.parent.otherMethodOfIntake;
              return !!other || !!value;
            },
          ),
          otherMethodOfIntake: string()
            .trim()
            .max(100, t('Route must be at most 100 characters', { ns: 'validation' })),
          otherDurationCount: number()
            .typeError(t('Duration must be a number between 0 and 10000', { ns: 'validation' }))
            .positive(t('Duration must be 0 or higher', { ns: 'validation' }))
            .max(10000, t('Duration must be less than 10000', { ns: 'validation' })),
          durationCount: string().test(
            'otherDurationCount',
            t('Duration is required', { ns: 'validation' }),
            function (value) {
              const other = this.parent.otherDurationCount;
              return !!other || !!value;
            },
          ),

          quantity: number()
            .typeError(t('Must be a number', { ns: 'validation' }))
            .required(t('Quantity is required', { ns: 'validation' }))
            .max(1000, t('Quantity must be at most 1000', { ns: 'validation' })),
          endDate: date()
            .typeError(t('Invalid date', { ns: 'validation' }))
            .test(
              'beforeDate',
              t('End Date must be after the Start Date', { ns: 'validation' }),
              function (value) {
                const startDate = moment(this.parent.startDate);
                return moment(value).isAfter(startDate);
              },
            ),
          notes: string()
            .nullable()
            .max(
              GLOBAL_MAX_TEXT_LENGTH,
              t(`Notes must be at most 10000 characters`, {
                ns: 'validation',
                10000: GLOBAL_MAX_TEXT_LENGTH,
              }),
            ),
          prn: boolean(),
          prnReason: string()
            .trim()
            .max(200, t('Reason must be at most 200 characters', { ns: 'validation' }))
            .when('prn', {
              is: true,
              then: string().required(t('Must enter reason', { ns: 'validation' })),
            }),
          ...(showPackQuantity
            ? {
                packQuantity: number()
                  .typeError(t('Must be a number', { ns: 'validation' }))
                  .required(t('Number of packs is required', { ns: 'validation' }))
                  .max(1000, t('Number of packs must be at most 1000', { ns: 'validation' })),
              }
            : {}),
        };

  return (
    <Box height="100%">
      {medicationsError ? (
        <ServerValidationBox message={medicationsError} />
      ) : isLoadingMedications ? (
        <CenteredLoadingIndicator />
      ) : !initialValues ? (
        <Box width="100%">
          <HStack justifyContent="center">
            <Box width="100%" maxW="475px" pb="4" textAlign="right" fontSize="12px">
              <Text>{t('Pricing and Availability from')} </Text>
              <Wrap
                mt="1"
                spacing="4"
                sx={{
                  '.chakra-wrap__list': {
                    justifyContent: 'flex-end',
                  },
                }}
              >
                <WrapItem>
                  <HStack spacing="1" bgColor="#e0f6f4" px="1" borderRadius="sm">
                    {/* <Box height="15px" width="15px" bgColor="#e0f6f4" /> */}
                    <Text fontWeight="semibold">{selectedAddress?.name}</Text>
                  </HStack>
                </WrapItem>
                <WrapItem>
                  <HStack spacing="1" bgColor="rgb(255, 194, 0)" px="1" borderRadius="sm">
                    {/* <Box height="15px" width="15px" bgColor="rgb(255, 194, 0)" /> */}
                    <Text fontWeight="semibold">{defaultAddress?.name}</Text>
                  </HStack>
                </WrapItem>
              </Wrap>
            </Box>
          </HStack>
          {newPrescriptionForm && (
            <Text
              onClick={() => setNewPrescriptionForm(false)}
              textAlign="right"
              pt={4}
              cursor="pointer"
              color="red.500"
              fontSize="xs"
            >
              {t('Cancel')}
            </Text>
          )}
          <Center>
            <Box width="100%" maxW="475px">
              {!newPrescriptionForm && (
                <AutoComplete
                  formControlProps={{
                    formLabelProps: { fontWeight: 'semibold', color: 'rgb(0, 3, 16)' },
                  }}
                  label={t('Search for Medication')}
                  placeholder={t('Enter name of medication')}
                  value={medicationSearched}
                  isMulti={false}
                  options={listOfMedications}
                  valueKey="medicationID"
                  labelKey="name"
                  name="name"
                  resultLimit={20}
                  onChange={onSelectSearchedMedication}
                  components={{
                    Control,
                    Option: (props) => (
                      <Option
                        {...props}
                        defaultAddressID={defaultAddress?.associatedID}
                        selectedAddress={selectedAddress}
                      />
                    ),
                  }}
                  noOptionsMessage={() => t('No Medication found')}
                  isLoading={isLoadingMedications}
                  onAddNewCallback={() => setNewPrescriptionForm(true)}
                  addNewText={`+ ${t('Add A Non-Formulary Medication')}`}
                  filterKeys={['description']}
                  onInputChangeCallback={onChangeSearchInput}
                  menuPosition="fixed"
                />
              )}

              {newPrescriptionForm && (
                <NonFormularyForm
                  onCancel={() => {
                    clear();
                    onCancel();
                  }}
                  submit={submit}
                  initialValues={initialValues}
                  preFilledName={searchInputValue.current}
                />
              )}
            </Box>
          </Center>
        </Box>
      ) : (
        <Box height="100%" width="100%" overflowX="hidden" overflowY="auto" px={2}>
          {selectedMedication && !selectedMedication.medicationID ? (
            <Box width="100%">
              <Center>
                <Box width="100%" maxW="475px">
                  <NonFormularyForm
                    onCancel={() => {
                      clear();
                      onCancel();
                    }}
                    selectedMedication={selectedMedication}
                    submit={submit}
                    initialValues={initialValues}
                  />
                </Box>
              </Center>
            </Box>
          ) : (
            <MedicationPicker>
              <Formik
                innerRef={formRef}
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={submit}
                validationSchema={object(validationSchema)}
              >
                {({ touched, values, errors }) => (
                  <Form>
                    <Stack spacing={{ base: 2, md: 3 }}>
                      <OtherHandler />
                      <DurationHandler />
                      <Box>
                        <Text fontSize="x-large" fontWeight="bold" color="#322167">
                          {initialValues.name}
                          <Text
                            as="sup"
                            ml={3}
                            color="red.400"
                            cursor="pointer"
                            fontSize="12px"
                            p="1"
                            fontWeight="regular"
                            role="button"
                            onClick={() => {
                              clear();
                              onCancel();
                            }}
                          >
                            {t('Cancel')}
                          </Text>
                        </Text>
                        {medicationSearched?.name === medicationSearched?.description ? (
                          <Text fontSize="sm" color="#9b9fa5">
                            {medicationSearched?.medicationTypeEnum === MedicationTypeEnum.OTC
                              ? t('Over the Counter Medication')
                              : t('Medication')}
                          </Text>
                        ) : (
                          <Text fontSize="sm" color="#9b9fa5">
                            {medicationSearched?.description}
                          </Text>
                        )}

                        {medicationSearched?.medicationID === '0' && (
                          <HStack mt={2} spacing={1} alignItems="center">
                            <FormInputControl
                              formControlProps={{ maxW: '100%', mb: 0 }}
                              backgroundColor="#F7F9FC"
                              fontSize={OutpostTheme.FontSizes.body}
                              p={4}
                              _disabled={{ backgroundColor: '#F7F9FC' }}
                              type="text"
                              isDisabled={medicationSearched?.medicationID !== '0'}
                              name="name"
                              inputRightElement={
                                <InputRightElement width="4rem" height="100%">
                                  <Button
                                    minW="32px"
                                    width="32px"
                                    height="32px"
                                    minH="32px"
                                    maxW="32px"
                                    ml={1}
                                    borderRadius="full"
                                    backgroundColor="blue.100"
                                    color="blue.400"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    onClick={() => {
                                      clear();
                                      onCancel();
                                    }}
                                  >
                                    <FontAwesomeIcon icon={['far', 'times']} />
                                  </Button>
                                </InputRightElement>
                              }
                            />
                          </HStack>
                        )}
                      </Box>

                      {medicationSearched?.medicationTypeEnum === MedicationTypeEnum.OTC ? (
                        <>
                          <WrapItem flexGrow={1}>
                            <FormTextArea
                              formControlProps={{ mt: 0 }}
                              label={t('Additional Information')}
                              name="notes"
                            />
                          </WrapItem>
                        </>
                      ) : (
                        <>
                          <Box mb="4">
                            <HStack alignItems="center" flexWrap={isOnMobile ? 'wrap' : 'nowrap'}>
                              <Text
                                width={isOnMobile ? '100%' : '100px'}
                                fontSize="14px"
                                mb={isOnMobile ? '2' : '0'}
                              >
                                {t('Qty per Dose')}
                              </Text>
                              <Box flex="1">
                                <FormRadioGroupControl
                                  formControlProps={defaultFormGroupControlProps}
                                  name="quantityPerDose"
                                >
                                  {Object.values(QuantityPerDoseEnum)
                                    .filter((y) => y !== QuantityPerDoseEnum.THREE)
                                    .map((x) => (
                                      <WrapItem key={x} alignItems="center">
                                        <RadioButtonAltControl value={x}>
                                          <Text fontSize={isOnMobile ? '14px' : '16px'}>{x}</Text>
                                        </RadioButtonAltControl>
                                      </WrapItem>
                                    ))}
                                  <WrapItem alignItems="center">
                                    <FormInputControl
                                      formControlProps={{
                                        ...defaultFormInputControlProps,
                                        minW: '80px',
                                        width: '80px',
                                      }}
                                      placeholder={t('Qty')}
                                      type="text"
                                      name="otherQuantityPerDose"
                                    />
                                  </WrapItem>
                                </FormRadioGroupControl>
                              </Box>
                            </HStack>
                            <FormGroupError
                              touched={touched.quantityPerDose || touched.otherQuantityPerDose}
                              error={errors.quantityPerDose || errors.otherQuantityPerDose}
                            />
                          </Box>
                          <Box mb="4" mt={isOnMobile ? '4' : '0'}>
                            <HStack alignItems="center" flexWrap={isOnMobile ? 'wrap' : 'nowrap'}>
                              <Text
                                width={isOnMobile ? '100%' : '100px'}
                                fontSize="14px"
                                mb={isOnMobile ? '2' : '0'}
                              >
                                {t('Form')}
                              </Text>
                              <Box flex="1">
                                <FormRadioGroupControl
                                  formControlProps={defaultFormGroupControlProps}
                                  name="form"
                                >
                                  {Object.values(FormEnum).map((x) => (
                                    <WrapItem alignItems="center" key={x}>
                                      <RadioButtonAltControl value={x}>
                                        <Text fontSize={isOnMobile ? '14px' : '16px'}>
                                          {x === FormEnum.Tabs
                                            ? t('Tabs')
                                            : x === FormEnum.Caps
                                            ? t('Caps')
                                            : t('Drops')}
                                        </Text>
                                      </RadioButtonAltControl>
                                    </WrapItem>
                                  ))}
                                  <WrapItem alignItems="center">
                                    <FormInputControl
                                      formControlProps={defaultFormInputControlProps}
                                      type="text"
                                      placeholder={t('Other')}
                                      name="otherForm"
                                    />
                                  </WrapItem>
                                </FormRadioGroupControl>
                              </Box>
                            </HStack>
                            <FormGroupError
                              touched={touched.form || touched.otherForm}
                              error={errors.form || errors.otherForm}
                            />
                          </Box>
                          <Box mb="4">
                            {isOnMobile ? (
                              <Box mt="4">
                                <FormSelectComponent
                                  name="frequency"
                                  options={enumToOptions(FrequencyEnum)}
                                  valueKey="id"
                                  labelKey="description"
                                  isClearable={false}
                                  isSearchable
                                  isCreatable
                                  label={t('Frequency')}
                                />
                              </Box>
                            ) : (
                              <Box>
                                <HStack
                                  alignItems="flex-start"
                                  flexWrap={isOnMobile ? 'wrap' : 'nowrap'}
                                >
                                  <Text
                                    width={isOnMobile ? '100%' : '100px'}
                                    fontSize="14px"
                                    mb={isOnMobile ? '2' : '0'}
                                    mt={isOnMobile ? '0' : '3'}
                                  >
                                    {t('Frequency')}
                                  </Text>
                                  <Box flex="1">
                                    <Portal>
                                      {Object.values(FrequencyEnum).map((x) => (
                                        <ReactTooltip key={x} effect="solid" id={x} />
                                      ))}
                                    </Portal>
                                    <HStack>
                                      <Box>
                                        <FormRadioGroupControl
                                          formControlProps={defaultFormGroupControlProps}
                                          name="frequency"
                                        >
                                          {Object.values(FrequencyEnum).map((x) => (
                                            <WrapItem alignItems="center" key={x}>
                                              <RadioButtonAltControl
                                                dataFor={x}
                                                dataTip={getFrequencyDescription(x)}
                                                value={x}
                                              />
                                            </WrapItem>
                                          ))}
                                          <WrapItem alignItems="flex-start">
                                            <FormInputControl
                                              formControlProps={{
                                                ...defaultFormInputControlProps,
                                                minW: '100px',
                                                width: '100px',
                                              }}
                                              name="otherFrequency"
                                              placeholder={t('Other')}
                                              type="text"
                                            />
                                          </WrapItem>
                                        </FormRadioGroupControl>
                                      </Box>
                                    </HStack>
                                    <HStack alignItems="center" mt="2">
                                      <Flex width="100px" alignItems="center">
                                        <FormSingleCheckbox
                                          formControlProps={defaultFormInputControlProps}
                                          name="prn"
                                        >
                                          {t('PRN')}
                                        </FormSingleCheckbox>
                                      </Flex>
                                      {values.prn && (
                                        <Box flex="1">
                                          <FormInputControl
                                            formControlProps={defaultFormInputControlProps}
                                            label=""
                                            placeholder={t('PRN Reason')}
                                            type="text"
                                            name="prnReason"
                                          />
                                        </Box>
                                      )}
                                    </HStack>
                                  </Box>
                                </HStack>
                                <FormGroupError
                                  touched={touched.frequency || touched.otherFrequency}
                                  error={errors.frequency || errors.otherFrequency}
                                />
                              </Box>
                            )}
                          </Box>
                          <Box mb="4">
                            {isOnMobile ? (
                              <>
                                <FormSelectComponent
                                  name="methodOfIntake"
                                  options={enumToOptions(RouteEnum)}
                                  valueKey="id"
                                  labelKey="description"
                                  isClearable={false}
                                  isSearchable
                                  isCreatable
                                  label={t('Route')}
                                />
                              </>
                            ) : (
                              <Box>
                                <HStack
                                  alignItems="center"
                                  flexWrap={isOnMobile ? 'wrap' : 'nowrap'}
                                >
                                  <Text
                                    width={isOnMobile ? '100%' : '100px'}
                                    fontSize="14px"
                                    mb={isOnMobile ? '2' : '0'}
                                  >
                                    {t('Route')}
                                  </Text>
                                  <Box flex="1">
                                    <Portal>
                                      {Object.values(RouteEnum).map((x) => (
                                        <ReactTooltip key={x} effect="solid" id={x} />
                                      ))}
                                    </Portal>
                                    <FormRadioGroupControl
                                      formControlProps={defaultFormGroupControlProps}
                                      name="methodOfIntake"
                                    >
                                      {Object.values(RouteEnum).map((x) => (
                                        <WrapItem alignItems="center" key={x}>
                                          <RadioButtonAltControl
                                            dataFor={x}
                                            dataTip={getRouteDescription(x)}
                                            value={x}
                                          >
                                            {x}
                                          </RadioButtonAltControl>
                                        </WrapItem>
                                      ))}
                                      <WrapItem alignItems="center">
                                        <FormInputControl
                                          formControlProps={{
                                            ...defaultFormInputControlProps,
                                            minW: '100px',
                                            width: '100px',
                                          }}
                                          name="otherMethodOfIntake"
                                          placeholder={t('Other')}
                                          type="text"
                                        />
                                      </WrapItem>
                                    </FormRadioGroupControl>
                                  </Box>
                                </HStack>
                                <FormGroupError
                                  touched={touched.methodOfIntake || touched.otherMethodOfIntake}
                                  error={errors.methodOfIntake || errors.otherMethodOfIntake}
                                />
                              </Box>
                            )}
                          </Box>
                          <Box mb="4">
                            {isOnMobile ? (
                              <Flex>
                                <FormInputControl
                                  label={t('Duration')}
                                  formControlProps={{ maxW: '320px', mr: 1 }}
                                  name="otherDurationCount"
                                  placeholder={t('Enter Duration')}
                                  type="text"
                                />
                                <FormSelectComponent
                                  formControlProps={{ minW: '125px', ml: 1 }}
                                  name="durationType"
                                  options={enumToOptions(DurationTypeEnum)}
                                  valueKey="id"
                                  labelKey="description"
                                  isClearable={false}
                                  label={<>&nbsp;</>}
                                />
                              </Flex>
                            ) : (
                              <Box>
                                <HStack
                                  alignItems="flex-start"
                                  flexWrap={isOnMobile ? 'wrap' : 'nowrap'}
                                >
                                  <Text
                                    width={isOnMobile ? '100%' : '100px'}
                                    fontSize="14px"
                                    mb={isOnMobile ? '2' : '0'}
                                  >
                                    {t('Duration')}
                                  </Text>
                                  <Box flex="1">
                                    <FormRadioGroupControl
                                      formControlProps={defaultFormGroupControlProps}
                                      name="durationCount"
                                    >
                                      {Object.values(DurationCountEnum).map((x) => (
                                        <WrapItem alignItems="center" key={x}>
                                          <RadioButtonAltControl value={x}>
                                            {x}
                                          </RadioButtonAltControl>
                                        </WrapItem>
                                      ))}
                                      <WrapItem alignItems="center">
                                        <FormInputControl
                                          formControlProps={{
                                            ...defaultFormInputControlProps,
                                            minW: '80px',
                                            width: '80px',
                                          }}
                                          name="otherDurationCount"
                                          placeholder={t('Other')}
                                          type="text"
                                        />
                                      </WrapItem>
                                    </FormRadioGroupControl>
                                    <Box>
                                      <FormRadioGroupControl
                                        formControlProps={defaultFormGroupControlProps}
                                        name="durationType"
                                      >
                                        {Object.values(DurationTypeEnum).map((x) => (
                                          <WrapItem alignItems="center" key={x}>
                                            <RadioButtonAltControl value={x}>
                                              {x}
                                            </RadioButtonAltControl>
                                          </WrapItem>
                                        ))}
                                      </FormRadioGroupControl>
                                    </Box>
                                  </Box>
                                </HStack>
                              </Box>
                            )}
                            <FormGroupError
                              touched={touched.durationCount || touched.otherDurationCount}
                              error={errors.durationCount || errors.otherDurationCount}
                            />
                          </Box>
                          <Box mb="4">
                            {isOnMobile ? (
                              <Box maxW="100%">
                                <FormInputControl
                                  label={t('Refills')}
                                  name="otherRefills"
                                  placeholder={t('Enter # of Refills')}
                                  type="text"
                                />
                              </Box>
                            ) : (
                              <HStack alignItems="center" flexWrap={isOnMobile ? 'wrap' : 'nowrap'}>
                                <Text
                                  width={isOnMobile ? '100%' : '100px'}
                                  fontSize="14px"
                                  mb={isOnMobile ? '2' : '0'}
                                >
                                  {t('Refills')}
                                </Text>
                                <FormRadioGroupControl
                                  formControlProps={defaultFormGroupControlProps}
                                  name="refills"
                                >
                                  {Object.values(RefillsEnum).map((x) => (
                                    <WrapItem alignItems="center" key={x}>
                                      <RadioButtonAltControl value={x}>{x}</RadioButtonAltControl>
                                    </WrapItem>
                                  ))}
                                  <WrapItem alignItems="center">
                                    <FormInputControl
                                      formControlProps={{
                                        ...defaultFormInputControlProps,
                                        minW: '100px',
                                        width: '100px',
                                      }}
                                      name="otherRefills"
                                      placeholder={t('Other')}
                                      type="text"
                                    />
                                  </WrapItem>
                                </FormRadioGroupControl>
                              </HStack>
                            )}
                            <FormGroupError
                              touched={touched.refills || touched.otherRefills}
                              error={errors.refills || errors.otherRefills}
                            />
                          </Box>
                          {isOnMobile ? (
                            <>
                              <Box maxW="100%">
                                <FormInputControl
                                  formControlProps={{ mt: 0 }}
                                  label={t('Quantity')}
                                  type="text"
                                  name="quantity"
                                />
                              </Box>
                              <Flex>
                                <FormMobisPicker
                                  formControlProps={{ mt: 0, mr: 1 }}
                                  name="startDate"
                                  label={t('Start Date')}
                                  min={moment().subtract(1, 'year').toDate()}
                                  max={moment().add(5, 'year').toDate()}
                                />
                                {/* <FormDateTimePicker
                                  formControlProps={{ mt: 0, mr: 1 }}
                                  name="startDate"
                                  label="Start Date"
                                  minDate={moment().subtract(1, 'year').toDate()}
                                  maxDate={moment().add(5, 'year').toDate()}
                                /> */}

                                <FormMobisPicker
                                  formControlProps={{ mt: 0, ml: 1, minW: '165px' }}
                                  name="endDate"
                                  label={t('End Date')}
                                  min={moment().toDate()}
                                  max={moment().add(5, 'year').toDate()}
                                />
                                {/* <FormDateTimePicker
                                  formControlProps={{ mt: 0, ml: 1, minW: '165px' }}
                                  name="endDate"
                                  label="End Date"
                                  minDate={moment().toDate()}
                                  maxDate={moment().add(5, 'year').toDate()}
                                  isClearable={true}
                                /> */}
                              </Flex>
                              {showPackQuantity && (
                                <Box maxW="185px" mt="1">
                                  <FormInputControl
                                    formControlProps={{ mt: 0 }}
                                    label={t('Number of Packs/Boxes')}
                                    type="text"
                                    name="packQuantity"
                                  />
                                </Box>
                              )}
                            </>
                          ) : (
                            <Box>
                              <Wrap>
                                <WrapItem maxW="95px">
                                  <FormInputControl
                                    formControlProps={{ mt: 0 }}
                                    label={t('Quantity')}
                                    type="text"
                                    name="quantity"
                                  />
                                </WrapItem>
                                <WrapItem maxW="150px">
                                  <FormMobisPicker
                                    formControlProps={{ mt: 0 }}
                                    name="startDate"
                                    label={t('Start Date')}
                                    min={moment().subtract(1, 'year').toDate()}
                                    max={moment().add(5, 'year').toDate()}
                                  />
                                  {/* <FormDateTimePicker
                                    formControlProps={{ mt: 0 }}
                                    name="startDate"
                                    label="Start Date"
                                    minDate={moment().subtract(1, 'year').toDate()}
                                    maxDate={moment().add(5, 'year').toDate()}
                                  /> */}
                                </WrapItem>
                                <WrapItem maxW="170px" width="160px">
                                  <FormMobisPicker
                                    formControlProps={{ mt: 0 }}
                                    name="endDate"
                                    label={t('End Date')}
                                    min={moment().toDate()}
                                    max={moment().add(5, 'year').toDate()}
                                    // isClearable={true}
                                  />
                                  {/* <FormDateTimePicker
                                    formControlProps={{ mt: 0 }}
                                    name="endDate"
                                    label="End Date"
                                    minDate={moment().toDate()}
                                    maxDate={moment().add(5, 'year').toDate()}
                                    isClearable={true}
                                  /> */}
                                </WrapItem>
                                <WrapItem flexGrow={1}>
                                  <FormInputControl
                                    formControlProps={{ mt: 0 }}
                                    maxW="485px"
                                    label={t('Additional Information')}
                                    type="text"
                                    name="notes"
                                  />
                                </WrapItem>
                              </Wrap>
                              {showPackQuantity && (
                                <Box maxW="185px" mt="1">
                                  <FormInputControl
                                    formControlProps={{ mt: 0 }}
                                    label={t('Number of Packs/Boxes')}
                                    type="text"
                                    name="packQuantity"
                                  />
                                </Box>
                              )}
                            </Box>
                          )}
                        </>
                      )}
                    </Stack>
                  </Form>
                )}
              </Formik>
            </MedicationPicker>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AddNewMedication;

const FormGroupError = styled(FormError)`
  position: absolute;
  bottom: 0;
`;

const MedicationPicker = styled.div`
  width: 100%;
`;
