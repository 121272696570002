import React, { FC } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { OutpostTheme } from '../../../../../../../themes/outpost';

export type ActionButtonProps = ButtonProps & {
  buttonColor: string;
  buttonHoverColor: string;
  buttonVariant?: 'filled' | 'outlined' | 'ghost' | 'link' | 'secondary';
};

const buttonVariants = (props: ActionButtonProps) => ({
  filled: {
    bgColor: props.buttonColor,
    color: 'white',
    border: '1px solid transparent',
    _hover: {
      bgColor: props.isDisabled || props.disabled ? '#9D9D9D' : props.buttonHoverColor,
      color: props.isDisabled || props.disabled ? 'white' : props.buttonColor,
      borderColor: props.isDisabled || props.disabled ? '#9D9D9D' : props.buttonColor,
    },
    _active: { bgColor: '#f4f4f4', color: props.buttonColor, borderColor: props.buttonColor },
    _disabled: {
      bgColor: '#9D9D9D',
      color: 'white',
      borderColor: '#9D9D9D',
      cursor: 'not-allowed',
    },
    opacity: props.isDisabled || props.disabled ? 0.5 : 1,
  } as ButtonProps,

  outlined: {
    bgColor: 'white',
    border: '1px solid',
    borderColor: props.buttonColor,
    color: props.buttonColor,
    _hover: {
      bgColor: props.buttonColor,
      color: props.buttonHoverColor,
    },
    _active: { bgColor: '#f4f4f4', color: props.buttonColor, borderColor: props.buttonColor },
    opacity: props.isDisabled || props.disabled ? 0.5 : 1,
  } as ButtonProps,

  ghost: {
    bgColor: 'white',
    color: props.buttonColor,
    opacity: props.isDisabled || props.disabled ? 0.5 : 1,
  } as ButtonProps,

  link: {
    bgColor: 'white',
    textDecoration: 'underline',
    color: props.buttonColor,
    _hover: { bgColor: 'white' },
    _active: { bgColor: '#f4f4f4', color: props.buttonColor, borderColor: props.buttonColor },
    opacity: props.isDisabled || props.disabled ? 0.5 : 1,
  } as ButtonProps,

  secondary: {
    bgColor: 'purple.50',
    color: props.buttonColor,
    _hover: {
      bgColor: props.buttonColor,
      color: 'white',
    },
    _active: { bgColor: props.buttonColor, color: 'white' },
    opacity: props.isDisabled || props.disabled ? 0.5 : 1,
  } as ButtonProps,
});

const ActionButton: FC<ActionButtonProps> = (props) => {
  const { children, buttonColor, buttonHoverColor, buttonVariant, ...rest } = props;

  const variantProps = {
    buttonColor,
    buttonHoverColor,
    buttonVariant,
    isDisabled: props.isDisabled,
    disabled: props.disabled,
  };

  return (
    <Button
      fontSize={OutpostTheme.FontSizes.body}
      minW="150px"
      borderRadius="8px"
      type="button"
      transition="all ease 0.3s"
      {...buttonVariants(variantProps)[buttonVariant || 'filled']}
      {...rest}
    >
      {children}
    </Button>
  );
};

export { ActionButton };
