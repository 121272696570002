import { ActionType, createAsyncAction } from 'typesafe-actions';
import { AuthClient, ShoppingCartsClient } from '../../generated';
import { httpRequest } from '../../utils';
import { OHThunkResult } from '../root-reducers';

export const getShoppingCartItemCountActions = createAsyncAction(
  ['GET_SHOPPING_CART_ITEM_COUNT_REQUEST', () => {}],
  ['GET_SHOPPING_CART_ITEM_COUNT_SUCCESS', (res: number) => res],
  ['GET_SHOPPING_CART_ITEM_COUNT_ERROR', (err: string) => err],
)();

export function getShoppingCartItemCount(
  count?: number | undefined,
): OHThunkResult<Promise<number>> {
  return async (dispatch) => {
    return new Promise<number>(async (resolve) => {
      dispatch(getShoppingCartItemCountActions.request());
      const shoppingCartsClient = new ShoppingCartsClient(new AuthClient());
      try {
        const result = count ?? (await httpRequest(() => shoppingCartsClient.itemCount()));
        dispatch(getShoppingCartItemCountActions.success(result));
        resolve(result);
      } catch (err) {
        dispatch(getShoppingCartItemCountActions.failure(err as any));
      }
    });
  };
}

export type GetShoppingCartItemCountActionTypes = ActionType<
  typeof getShoppingCartItemCountActions
>;
