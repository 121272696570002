import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { ICampaign } from '../../generated';
import {
  clearAction,
  clearErrorAction,
  TCurrentCampaignAction,
  setActions,
} from './currentCampaignActions';

const data = createReducer<ICampaign | null, TCurrentCampaignAction>(null)
  .handleAction([setActions.success], (_state, action) => action.payload)
  .handleAction([clearAction], () => null);

const error = createReducer<string, TCurrentCampaignAction>('')
  .handleAction([setActions.failure], (_state, action) => action.payload)
  .handleAction([clearErrorAction], () => '');

const isLoading = createReducer<boolean, TCurrentCampaignAction>(false)
  .handleAction([setActions.request], () => true)
  .handleAction([setActions.failure], () => false)
  .handleAction([setActions.success], () => false);

export interface ICurrentCampaignState {
  error: string;
  isLoading: boolean;
  data: ICampaign | null;
}

export enum CampaignSettingsEnum {
  AUTHENTICATION_LOGO = 'AUTHENTICATION_LOGO',
  AUTHENTICATION_IMAGE = 'AUTHENTICATION_IMAGE',
  AUTHENTICATION_SUBTITLE = 'AUTHENTICATION_SUBTITLE',
  AUTHENTICATION_TITLE = 'AUTHENTICATION_TITLE',
}

export default combineReducers<ICurrentCampaignState>({
  data,
  error,
  isLoading,
});
