import { createSelector } from 'reselect';
import { IProvider, Country, ProviderSettingEnum, ProviderSetting } from '../../generated';

type StateType = {
  provider: IProvider | null;
  countries?: Country[];
};

const providerSelector = (state: StateType): IProvider | null => state?.provider;
const countrySelector = (state: StateType): Country[] | undefined => state?.countries;

export const countriesProviderHasPrescriptionSetting = createSelector(
  providerSelector,
  countrySelector,
  (provider, countries) => {
    const prescriptionCountries: ProviderSetting[] = (provider?.providerSettings ?? []).filter(
      (x) => x.providerSettingEnum === ProviderSettingEnum.PrescriptionCountry,
    );
    return countries?.filter(
      (x) => prescriptionCountries.findIndex((y) => y.value === x.countryID) !== -1,
    );
  },
);

export const countriesProviderCanWritePrescriptions = createSelector(
  countriesProviderHasPrescriptionSetting,
  (countries) => countries?.sort((a, b) => a.name.localeCompare(b.name)),
);
