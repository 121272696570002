import { Box, Link, Text, VStack } from '@chakra-ui/react';
import { Formik } from 'formik';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { boolean, object } from 'yup';
import { PrimaryButton } from '../../../../../components/Buttons';
import { Form, FormPasswordControl, FormSingleCheckbox } from '../../../../../components/forms';
import HelmetWrapper from '../../../../../components/HelmetWrapper';
import { CenteredLoadingIndicator } from '../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../components/ServerValidationBox';
import ToCModalComponent from '../../../../../components/ToCModal';
import { AuthClient, SecurityClient } from '../../../../../generated';
import useTranslationComponent from '../../../../../hooks/useTranslationComponent';
import { mixTrack } from '../../../../../lib/mixpanel';
import { AppState } from '../../../../../store/root-reducers';
import { httpRequest } from '../../../../../utils';
import { checkConfirmPassword, checkPassword } from '../../../../../utils/yupHelper';
import PasswordRequirmentsText from '../../PasswordRequirementsText';
import { SignupFlowEnum } from '../enums';

type SetPasswordProps = {
  controller: {
    setCurrentStage: Dispatch<SetStateAction<SignupFlowEnum>>;
    handleNext: (values: any) => void;
  };
};
interface IForm {
  password: string;
  confirmPassword: string;
  hasAgreedToTC: boolean;
}

const initialValues: IForm = {
  password: '',
  confirmPassword: '',
  hasAgreedToTC: false,
};

const SetPassword: FC<SetPasswordProps> = (props) => {
  const { t } = useTranslationComponent(['signup']);
  const currentPartner = useSelector((state: AppState) => state.currentPartnerState.data);
  const currentPartnerLoading = useSelector(
    (state: AppState) => state.currentPartnerState.isLoading,
  );
  const companyName = currentPartner?.name || process.env.REACT_APP_COMPANY_NAME;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const authClient = new AuthClient();
  const securityClient = new SecurityClient(authClient);

  const [showPrivacyModal, setShowPrivacyModal] = useState<boolean>(false);
  const [showToCModal, setShowToCModal] = useState<boolean>(false);

  const signup = async (values: IForm) => {
    try {
      setIsLoading(true);

      const passwordStrengthResult = await httpRequest(() =>
        securityClient.isValidPasswordStrength(values.password),
      );
      if (passwordStrengthResult.length === 0) {
        props.controller.handleNext(values);
      } else {
        mixTrack({
          eventName: 'sign_up:patient_password_created_failed',
        });
        setError(passwordStrengthResult.map((x) => x.description).join('<br/>'));
        setIsLoading(false);
      }
    } catch (err) {
      mixTrack({
        eventName: 'sign_up:patient_password_created_failed',
      });
      setError(err as string);
      setIsLoading(false);
    }
  };

  return (
    <>
      <HelmetWrapper title={t('Set Password')} />
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          password: checkPassword,
          confirmPassword: checkConfirmPassword,
          hasAgreedToTC: boolean()
            .required(
              t('You must accept the Terms and Conditions/Privacy Policy in order to proceed'),
            )
            .oneOf(
              [true],
              t('You must accept the Terms and Conditions/Privacy Policy in order to proceed'),
            ),
        })}
        onSubmit={(values) => signup(values)}
      >
        {(formik) => (
          <Form error={error} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            {currentPartnerLoading || isLoading ? (
              <CenteredLoadingIndicator />
            ) : (
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <ServerValidationBox message={error} />

                <VStack gap={2} alignItems="flex-start" mt={2}>
                  <FormPasswordControl
                    autoComplete="on"
                    size="lg"
                    name="password"
                    label={t('password')}
                    placeholder={t('minimum 8 characters')}
                    id="passwordId"
                    tabIndex={2}
                    formControlProps={{
                      mb: '0',
                    }}
                  />
                  <FormPasswordControl
                    autoComplete="on"
                    size="lg"
                    name="confirmPassword"
                    label={t('confirm password')}
                    placeholder={t('minimum 8 characters')}
                    id="confirmPasswordId"
                    tabIndex={2}
                    formControlProps={{
                      mb: '0',
                    }}
                  />

                  <FormSingleCheckbox my={4} name="hasAgreedToTC">
                    {
                      <Text fontSize="12px">
                        {t('By signing up, I agree to', { name: companyName })}{' '}
                        <Link
                          color="purple.600"
                          fontWeight="600"
                          textDecoration="none"
                          textTransform="lowercase"
                          onClick={() => setShowToCModal(true)}
                        >
                          {t('Terms and Conditions')}
                        </Link>{' '}
                        {t('and')}{' '}
                        <Link
                          color="purple.600"
                          fontWeight="600"
                          textDecoration="none"
                          textTransform="lowercase"
                          onClick={() => setShowPrivacyModal(true)}
                        >
                          {t('Privacy Policy')}
                        </Link>
                      </Text>
                    }
                  </FormSingleCheckbox>

                  <Text fontSize="lg" mb={3} w="100%">
                    {t('Requirements')}
                  </Text>

                  <PasswordRequirmentsText password={formik.values.password} />
                </VStack>

                <VStack mt="auto" pt={8} pb={1} gap={2}>
                  <PrimaryButton w="100%" tabIndex={3} size="lg" type="submit">
                    {t('continue')}
                  </PrimaryButton>
                </VStack>
              </Box>
            )}
          </Form>
        )}
      </Formik>

      {showToCModal && (
        <ToCModalComponent
          name="PatientSignUpTC"
          onDismiss={() => setShowToCModal(!showToCModal)}
          onSubmit={() => setShowToCModal(!showToCModal)}
          isOpen={showToCModal}
          title={t('Terms and Conditions')}
        />
      )}
      {showPrivacyModal && (
        <ToCModalComponent
          name="PatientSignUpPrivacy"
          title={t('Privacy Policy')}
          onDismiss={() => setShowPrivacyModal(!showPrivacyModal)}
          onSubmit={() => setShowPrivacyModal(!showPrivacyModal)}
          isOpen={showPrivacyModal}
        />
      )}
    </>
  );
};

export { SetPassword };
