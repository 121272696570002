import moment from 'moment';
import { createContext, ReactNode, useCallback, useEffect, useState } from 'react';

import { IFileUpload } from '../../../../../../components/FileUploader/useFileUploader/useFileUploader';
import {
  AuthClient,
  Consult,
  ConsultProviderSearchProviderResult,
  ConsultProviderSearchStoreResult,
  ConsultsClient,
  ConsultStatusEnum,
  ConsultStoreItem,
  ConsultStoreItemTypeEnum,
  ConsultTag,
  ConsultTagTypeEnum,
  ConsultTypeEnum,
  Country,
  GenderEnum,
  Invoice,
  Language,
  Provider,
  ProviderAppointment,
  ProviderAppointmentDTO,
  ProviderStoreItem,
  Store,
  StoreItem,
  StoreItemTypeEnum,
  SubscriptionConsultDetail,
  Tag,
  TagTypeEnum,
} from '../../../../../../generated';
import { useCrmTracking } from '../../../../../../hooks/useCrmTracking/useCrmTracking';
import useQuery from '../../../../../../hooks/useQuery';
import { httpRequest } from '../../../../../../utils';

import { SwipeableHandlers, useSwipeable } from 'react-swipeable';
import { useSessionGet } from '../../../../../../services/sessions/query/useSessionGet';
import { BookingDataType } from '../../../../../Unauthenticated/hooks/usePublicBookingFlow';
import useLoadInitialProviderStoreItem from './useLoadInitialProviderStoreItem/useLoadInitialProviderStoreItem';
import useCareTypeTags from './useProviderCareTypes/useProviderCareTypes';
import { IPersonOption } from './useSelectPatient/useSelectPatient';
import useSubscriptions from './useSubscriptions/useSubscriptions';
import { DurationTimeUnitEnum } from './useSymptomsDuration/useSymptomsDuration';

type ProviderOptionsType = {
  language?: Language;
  country?: Country;
  gender?: GenderEnum;
};

export type LatLng = {
  lat: number;
  lng: number;
};

export interface IBookConsultState {
  emergencyConsultCountryID?: string;
  emergencyStore?: ConsultProviderSearchStoreResult;
  patient?: IPersonOption;
  storeItemTypeEnum?: StoreItemTypeEnum;
  consultTypeEnum?: ConsultTypeEnum;
  careTypeTags?: Tag[];
  prescriptionTag?: string;
  symptoms?: Tag[];
  durationTimeUnit?: DurationTimeUnitEnum;
  durationTime?: number;
  concern?: string;
  uploadedFiles?: IFileUpload[];
  phrDocumentIDs?: string[];
  country?: Country;
  provider?: ConsultProviderSearchProviderResult;
  providerStoreItem?: ProviderStoreItem;
  providerAppointment?: ProviderAppointmentDTO;
  consultID?: string;
  invoiceID?: string;
  invoice?: Invoice | null;
  initialProviderStoreItemID?: string;
  currentStep: BookConsultStepEnum;
  prevState: IBookConsultState | null;
  selectedConsultationCareType?: Tag;
  selectedWalkInProvider?: Provider;
  providerOptionsType?: ProviderOptionsType;
  providerStore?: Store;
  isUsingPartner?: boolean;
  storeItem?: StoreItem;
  store?: Store;
  showInsuranceScreenInContactVerification?: boolean;
  homeCareServiceTags?: Tag[];
  geolocation?: GeolocationPosition;
  latLng?: LatLng;
  selectedStoreItemID?: string;
}

export enum BookConsultStepEnum {
  PreSelectedProvider,
  ConsultationType,
  Disclaimer,
  Patient,
  IsPartner,
  AvailableProviders,
  ProviderSummary,
  IdentityVerification,
  TypeOfProvider,
  CategoryOfProvider,
  CareTeamProviders,
  ProviderVisitType,
  TypeOfTherapy,
  HomeCareServices,
  BookingSummary,
  CheckIn,
  WaitingRoom,
  ContactVerification,
  PrimaryInsurance,
  StoreItemType,
  CareTagType,
  BookingType,
  ReasonForConsult,
  SymptomsList,
  SymptomsDuration,
  SymptomsInfo,
  ProviderTypeSearch,
  ProviderFromFavorites,
  ProviderSearch,
  ProviderStoreItem,
  AppointmentSlot,
  BookingConfirmation,
  ViewInvoice,
  Payment,
  BookingSuccess,
  ReserveBooking,
}

export interface IBookConsultContext {
  state: IBookConsultState;
  updateState: (newState: IBookConsultState, savePrevious?: boolean) => void;
  goToPrevious: () => void;
  bookConsult: (state: IBookConsultState, addOnPatientItem?: ConsultStoreItem) => Promise<Consult>;
  careTypeTags?: Tag[];
  isLoadingCareTypeTags: boolean;
  careTypeTagsLoadingError: string;
  subscriptionDetails: SubscriptionConsultDetail[];
  subscriptionLoading: boolean;
  subscriptionError: string;
  loadSubscriptionDetails: (userDetailID: string) => void;
  initialProviderStoreItemError: string;
  isInitialProviderStoreItemLoading: boolean;
  bookUrgentCareConsult: (state: IBookConsultState) => Promise<Consult>;
  handlers: SwipeableHandlers;
}

export const BookConsultContext = createContext<IBookConsultContext>(null!);

const initialState = (
  emergencyConsultCountryID: string | null,
  initialProviderStoreItemID: string | null,
) => {
  // const currentStep = emergencyConsultCountryID
  //   ? BookConsultStepEnum.Patient
  //   : BookConsultStepEnum.Disclaimer;
  const currentStep = BookConsultStepEnum.ConsultationType;

  return {
    currentStep,
    emergencyConsultCountryID,
    initialProviderStoreItemID,
  } as IBookConsultState;
};

interface BookConsultProviderProps {
  children: ReactNode;
}

export function BookConsultProvider({ children }: BookConsultProviderProps) {
  const query = useQuery();
  const emergencyConsultCountryID = query.get('emergencyConsultCountryID');
  const providerStoreItemID = query.get('providerStoreItemID');
  const sessionId = query.get('sessionId');
  const from = query.get('from');

  const [state, setState] = useState<IBookConsultState>(
    initialState(emergencyConsultCountryID, providerStoreItemID),
  );

  const { trackBookingConsult } = useCrmTracking();

  const { careTypeTags, isLoadingCareTypeTags, careTypeTagsLoadingError } = useCareTypeTags();

  // get session data using url sessionId if exists
  const { isLoadingSession, session: sessionData } = useSessionGet({
    sessionID: sessionId || undefined,
  });

  const { error: initialProviderStoreItemError, isLoading: isInitialProviderStoreItemLoading } =
    useLoadInitialProviderStoreItem({
      providerStoreItemID,
      setProviderStoreItem: useCallback(
        (providerStoreItem: ProviderStoreItem) => {
          const careTypeTags: Tag[] | undefined = (providerStoreItem.storeItem?.storeItemTags ?? [])
            .map((item) => item.tag!)
            .filter((item) => !!item && item.tagTypeEnum === TagTypeEnum.CareType);

          let provider: ConsultProviderSearchProviderResult;

          if (providerStoreItem.provider) {
            provider = new ConsultProviderSearchProviderResult();
            provider.providerID = providerStoreItem.provider.providerID;
            provider.fullName = providerStoreItem.provider.fullName;
            provider.title = providerStoreItem.provider.title;
            provider.firstName = providerStoreItem.provider.firstName;
            provider.middleName = providerStoreItem.provider.middleName;
            provider.lastName = providerStoreItem.provider.lastName;
            provider.imageDetailID = providerStoreItem.provider.imageDetailID;
            provider.genderEnum = providerStoreItem.provider.genderEnum;
            provider.providerTags = providerStoreItem.provider.providerTags?.map((x) => x.tag!);
            provider.nextAvailableAppointmentDate = providerStoreItem.nextAvailableAppointmentDate;
            provider.primaryProviderConditionTag =
              providerStoreItem.provider.primaryProviderSpecialtyTag;
            provider.isFavorite = providerStoreItem.provider.isFavorite;
            provider.providerTypeTag = providerStoreItem.provider.providerTypeTag;
          }

          const storeItemTypeEnum = providerStoreItem.storeItem?.storeItemTypeEnum;

          let consultTypeEnum: ConsultTypeEnum | undefined = undefined;

          switch (storeItemTypeEnum) {
            case StoreItemTypeEnum.InPersonConsult:
              consultTypeEnum = ConsultTypeEnum.InPerson;
              break;
            case StoreItemTypeEnum.VideoConsult:
              consultTypeEnum = ConsultTypeEnum.Video;
              break;
          }

          setState((prev) => ({
            ...prev,
            providerStoreItem,
            careTypeTags,
            provider,
            consultTypeEnum,
            currentStep:
              from === 'network'
                ? BookConsultStepEnum.AppointmentSlot
                : BookConsultStepEnum.PreSelectedProvider,
          }));
        },
        [from],
      ),
    });

  const {
    subscriptionDetails,
    isLoading: subscriptionLoading,
    error: subscriptionError,
    loadSubscriptionDetails,
  } = useSubscriptions();

  const bookConsult = async (state: IBookConsultState, addOnPatientItem?: ConsultStoreItem) => {
    // Create Base Consult
    const consult = new Consult();
    consult.patientID = state.patient!.patient.patientID;

    const symptomsTags = [
      ...(state.prescriptionTag ? [{ tagID: state.prescriptionTag! }] : state.symptoms ?? []),
    ].map((x) => {
      const consultTag = new ConsultTag();
      consultTag.consultTagTypeEnum = ConsultTagTypeEnum.Complaint;
      consultTag.tagID = x.tagID;
      return consultTag;
    });

    consult.consultTags = [
      ...symptomsTags,
      ...(state.homeCareServiceTags?.map((x) => {
        const consultTag = new ConsultTag();
        consultTag.consultTagTypeEnum = ConsultTagTypeEnum.HomeCare;
        consultTag.tagID = x.tagID;
        return consultTag;
      }) ?? []),
    ];

    consult.description = state.concern;
    consult.consultStatusEnum = ConsultStatusEnum.New;
    consult.consultTypeEnum = state.consultTypeEnum!;
    consult.currencyEnum = state.providerStoreItem!.storeItem!.currencyEnum;
    consult.countryID = state.providerStoreItem!.storeItem!.store!.countryID;
    consult.phrDocumentIDs = state.phrDocumentIDs;
    consult.careTypeTagID = state.selectedConsultationCareType?.tagID;
    consult.longitude = state.latLng?.lng || state.geolocation?.coords.longitude;
    consult.latitude = state.latLng?.lat || state.geolocation?.coords.latitude;

    const { durationTimeUnit, durationTime } = state;
    if (durationTimeUnit && durationTime) {
      const today = moment();
      //@ts-ignore
      const symptomStartDate = moment().add(durationTimeUnit.toLocaleLowerCase(), durationTime);

      consult.symptomDurationInDays = Math.abs(
        symptomStartDate.startOf('day').diff(today.startOf('day'), 'days'),
      );
    }

    // Add Consult Store Item
    const consultStoreItem = new ConsultStoreItem();
    consultStoreItem.storeItemID = state.providerStoreItem!.storeItemID;
    consultStoreItem.consultStoreItemTypeEnum = ConsultStoreItemTypeEnum.Primary;
    consult.consultStoreItems = [consultStoreItem];

    if (addOnPatientItem) {
      consult.consultStoreItems.push(addOnPatientItem);
    }

    // Book Appointment if not Walk in
    if (state.providerAppointment) {
      consult.providerAppointmentIDToBook = state.providerAppointment.providerAppointmentID;
      consult.startDate = state.providerAppointment.startDate!;
      consult.endDate = state.providerAppointment.endDate;
    }

    const client = new ConsultsClient(new AuthClient());
    const consultID = await httpRequest(() => client.consultsPost(consult));
    const createdConsult = await httpRequest(() => client.consultsGet(consultID));
    trackBookingConsult(consult.consultTypeEnum);

    return createdConsult;
  };

  const bookUrgentCareConsult = async (state: IBookConsultState) => {
    // Create Base Consult
    const consult = new Consult();

    consult.patientID = state.patient!.patient.patientID;
    consult.consultStatusEnum = ConsultStatusEnum.New;
    consult.consultTypeEnum = state.consultTypeEnum!;
    consult.currencyEnum = state.storeItem!.currencyEnum;
    consult.countryID = state.store!.countryID;
    consult.careTypeTagID = state.selectedConsultationCareType?.tagID;
    consult.longitude = state.geolocation?.coords.longitude;
    consult.latitude = state.geolocation?.coords.latitude;

    // Add Consult Store Item
    const consultStoreItem = new ConsultStoreItem();
    consultStoreItem.storeItemID = state.storeItem!.storeItemID;
    consultStoreItem.consultStoreItemTypeEnum = ConsultStoreItemTypeEnum.Primary;
    consult.consultStoreItems = [consultStoreItem];

    const client = new ConsultsClient(new AuthClient());
    const consultID = await httpRequest(() => client.consultsPost(consult));
    const createdConsult = await httpRequest(() => client.consultsGet(consultID));
    trackBookingConsult(consult.consultTypeEnum);

    return createdConsult;
  };

  const goToPrevious = () => {
    setState((prev) =>
      prev.prevState
        ? { ...prev.prevState }
        : initialState(emergencyConsultCountryID, providerStoreItemID),
    );
  };

  const handlers = useSwipeable({
    onSwipedRight: () => goToPrevious(),
    swipeDuration: 250,
  });

  const updateState = useCallback((newState: IBookConsultState, savePrevious = true) => {
    setState((prev) => {
      if (savePrevious) {
        newState.prevState = { ...prev };
      }
      return newState;
    });
  }, []);

  // Pull data from session if session is available
  useEffect(() => {
    if (!sessionData?.data || isLoadingSession) return;

    const bookingData = JSON.parse(sessionData.data) as BookingDataType;

    const transformedProviderAppointment = new ProviderAppointment();

    transformedProviderAppointment.init(bookingData.providerAppointment);

    if (bookingData.providerAppointment?.startDate) {
      transformedProviderAppointment.startDate = new Date(
        bookingData.providerAppointment?.startDate,
      );
    }
    if (bookingData.providerAppointment?.endDate) {
      transformedProviderAppointment.endDate = new Date(bookingData.providerAppointment?.endDate);
    }

    setState((prev) => ({
      ...prev,
      selectedConsultationCareType: bookingData.selectedCareType,
      careTypeTags: [bookingData.selectedCareType],
      storeItemTypeEnum: bookingData.storeItemTypeEnum,
      country: bookingData.patientCountry,
      providerAppointment: transformedProviderAppointment,
      provider: bookingData.provider,
      consultTypeEnum: bookingData.consultTypeEnum,
      selectedStoreItemID: bookingData.selectedStoreItemID,
      providerStoreItem: bookingData.providerStoreItem,
      store: bookingData.store,
      symptoms: bookingData.selectedSymptoms,
      storeItem: bookingData.providerStoreItem?.storeItem,
      homeCareServiceTags: bookingData.selectedHomeCareServiceTags,
      latLng: bookingData.latLng,
      currentStep: BookConsultStepEnum.Patient,
    }));
  }, [isLoadingSession, sessionData]);

  return (
    <BookConsultContext.Provider
      value={{
        state,
        updateState,
        goToPrevious,
        bookConsult,
        careTypeTags,
        isLoadingCareTypeTags,
        careTypeTagsLoadingError,
        subscriptionDetails,
        subscriptionLoading,
        subscriptionError,
        loadSubscriptionDetails,
        initialProviderStoreItemError,
        isInitialProviderStoreItemLoading,
        bookUrgentCareConsult,
        handlers,
      }}
    >
      {children}
    </BookConsultContext.Provider>
  );
}
