import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { ConsultRoutes } from '../../../../constants';
import { RolesEnum } from '../../../../generated';
import { ICurrentUser } from '../../../../store/currentUser/currentUserReducers';
import { getPatient } from '../../../../store/root-creator';
import { AppState } from '../../../../store/root-reducers';
import { isInRole } from '../../../../utils/isInRole';

interface IProps {
  currentUser: ICurrentUser | null;
}

const useGetAuthenticatedPatient = ({ currentUser }: IProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const isInConsult = !!matchPath(pathname, { path: [ConsultRoutes.Consult], exact: true });
  const { patient } = useSelector((state: AppState) => state.patientState);

  useEffect(() => {
    if (currentUser && isInRole(currentUser, RolesEnum.Patient) && !isInConsult && !patient) {
      dispatch(getPatient(currentUser.userDetailID));
    }
  }, [currentUser, patient, isInConsult, dispatch]);
};

export default useGetAuthenticatedPatient;
