import { IconButton } from '@chakra-ui/react';
import { useState } from 'react';
import { HeartFilledIcon2, HeartIcon } from '../../../../../../../assets/icons';
import { AuthClient, PatientFavoritesClient } from '../../../../../../../generated';
import { httpRequest } from '../../../../../../../utils';

interface IProps {
  isFavorite?: boolean;
  patientID: string;
  providerID: string;
  reload?: () => void;
}

export const PatientFavoriteIcon = ({
  isFavorite = false,
  patientID,
  providerID,
  reload,
}: IProps) => {
  const [favorite, setFavorite] = useState<boolean>(isFavorite);

  const onFavorite = async () => {
    try {
      const client = new PatientFavoritesClient(new AuthClient());
      setFavorite(true);
      await httpRequest(() => client.favoriteProviderPost(patientID, providerID));
      reload?.();
    } catch (err) {
      // Eat Error
    }
  };

  const onUnFavorite = async () => {
    try {
      const client = new PatientFavoritesClient(new AuthClient());
      setFavorite(false);
      await httpRequest(() => client.favoriteProviderDelete(patientID, providerID));
      reload?.();
    } catch (err) {
      // Eat Error
    }
  };

  return favorite ? (
    <IconButton
      aria-label="Remove from favorite"
      bgColor="transparent"
      _hover={{ bgColor: 'rgb(219, 217, 217)' }}
      padding="1"
      onClick={onUnFavorite}
    >
      <HeartFilledIcon2 fontSize="20px" />
    </IconButton>
  ) : (
    <IconButton
      aria-label="Add to favorite"
      bgColor="transparent"
      _hover={{ bgColor: 'rgb(219, 217, 217)' }}
      padding="1"
      onClick={onFavorite}
    >
      <HeartIcon fontSize="20px" />
    </IconButton>
  );
};
