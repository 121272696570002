import { Button, ButtonProps } from '@chakra-ui/react';
import { FC } from 'react';

export type TabButtonProps = ButtonProps & {
  isSelected?: boolean;
};

const TabButton: FC<TabButtonProps> = (props) => {
  const { isSelected, ...rest } = props;
  return (
    <Button
      pl="1"
      pr="1"
      rounded="none"
      borderBottom="2px solid transparent"
      _hover={{
        backgroundColor: 'transparent',
        borderBottomColor: 'purple.600',
      }}
      _focusWithin={{
        backgroundColor: 'transparent',
        borderBottomColor: 'purple.600',
      }}
      borderBottomColor={isSelected ? 'purple.600' : 'transparent'}
      variant="ghost"
      color={isSelected ? 'inherit' : 'rgb(157, 157, 157)'}
      {...rest}
    />
  );
};

export { TabButton };
