import { isJson } from './isJson';

type AddLocalStorageItemsType = {
  key: string;
  value: string;
};

const SUFFIX = process.env.REACT_APP_KEY_SUFFIX;

export const addLocalStorageItems = (items: AddLocalStorageItemsType[]) => {
  items.forEach((item) => {
    localStorage.setItem(`${item.key}${SUFFIX}`, item.value);
  });
};

export const getLocalStorageItems = (keys: string[]) => {
  const suffixedKeys = keys.map((key) => `${key}${SUFFIX}`);

  return Object.entries(localStorage)
    .filter(([key]) => suffixedKeys.includes(key))
    .map(([key, value]) => {
      return {
        key: key.replace(SUFFIX, ''),
        value: isJson(value) ? JSON.parse(value) : value,
      };
    });
};

export const getLocalStorageItem = (key: string) => {
  return localStorage.getItem(`${key}${SUFFIX}`);
};

export const removeLocalStorageItems = (keys: string[]) => {
  keys.forEach((key) => {
    localStorage.removeItem(`${key}${SUFFIX}`);
  });
};

export const getAllLocalStorageKeys = () => Object.keys(localStorage);

export const getAllLocalStorageValues = () => Object.values(localStorage);

export const getAllLocalStorageItems = () => {
  return Object.entries(localStorage).map(([key, value]) => {
    return {
      key: key.replace(SUFFIX, ''),
      value: isJson(value) ? JSON.parse(value) : value,
    };
  });
};
