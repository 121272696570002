import { Box, BoxProps, Text } from '@chakra-ui/react';
import { FC } from 'react';
import useTranslationComponent from '../../hooks/useTranslationComponent';
import { BackIconButton } from '../../routes/heal/patient/BookAppointment/components/common/BackIconButton';
import { OutpostTheme } from '../../themes/outpost';

type HeaderSectionProps = {
  title?: string;
  description?: any;
  heading?: string;
  onGoBack?: () => void;
  noPrevious?: boolean;
  containerProps?: BoxProps;
  showLabel?: boolean;
  label?: string;
};

const HeaderSection: FC<HeaderSectionProps> = (props) => {
  const { t } = useTranslationComponent();

  return props.heading || props.title || !props.noPrevious || props.description ? (
    <Box
      mt={{ base: '4', md: '1' }}
      mb="6"
      textAlign="center"
      position="relative"
      alignSelf="stretch"
      {...props.containerProps}
    >
      {!props.noPrevious && (
        <Box position="absolute" left={{ base: '-2px', md: '-34px' }} top="-17px">
          <BackIconButton iconSize="26px" onClick={props.onGoBack}>
            {props.showLabel && (
              <Text marginInlineStart="0 !important">{t(props.label ?? 'Back')}</Text>
            )}
          </BackIconButton>
        </Box>
      )}
      <Box px="8">
        {props.heading && (
          <Box mb={5}>
            <Text
              fontWeight="semibold"
              fontSize={OutpostTheme.FontSizes.body}
              textTransform="uppercase"
              color="rgb(0, 3, 16)"
            >
              {t(props.heading)}
            </Text>
          </Box>
        )}
        {props.title && (
          <Text mb={1} fontSize="24px" color="rgb(0, 3, 16)" fontWeight="bold">
            {t(props.title)}
          </Text>
        )}
        {props.description && (
          <Box fontSize={OutpostTheme.FontSizes.body} color="rgb(65, 79, 101)">
            {t(props.description)}
          </Box>
        )}
      </Box>
    </Box>
  ) : null;
};

export { HeaderSection };
