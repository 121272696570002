import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ConsultStatusEnum, ConsultTypeEnum } from '../../generated';
import { AppState } from '../../store/root-reducers';
import ActiveConsultInfoBanner from './ActiveConsultInfoBanner';
import { ConsultRoutes, PhoneEventsEnum } from '../../constants';
import { createRoute } from '../../utils/createRoute';
import { push } from 'connected-react-router';
import { useProviderGetActiveConsult } from '../../hooks/useProviderGetActiveConsult';

const ProviderActiveConsultInfoBanner = () => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data!);
  const dispatch = useDispatch();
  const { consult } = useSelector((state: AppState) => state.activeConsultState);

  useProviderGetActiveConsult({ shouldRun: !consult });

  if (consult) {
    let statusText = '';
    let connectionButtonText = '';
    let onClick = () => {};

    const canResumeConsultCallback = consult.consultTypeEnum === ConsultTypeEnum.Video;

    switch (consult.consultStatusEnum) {
      case ConsultStatusEnum.InProgress:
      case ConsultStatusEnum.ConsultReady:
        statusText =
          (consult.consultTypeEnum === ConsultTypeEnum.InPerson ? 'Consult with ' : 'Call with ') +
          consult.patient?.fullName;
        connectionButtonText = 'Resume';
        onClick = () => {
          if (currentUser?.isInMobileView && canResumeConsultCallback) {
            if (currentUser.mobileSystem === 'android') {
              const androidCallbackData = JSON.stringify({
                callbackName: PhoneEventsEnum.RESUME_CONSULT_CALLBACK_HANDLER,
                consultID: consult.consultID,
              });

              // @ts-ignore
              AndroidResult.callback(androidCallbackData);
            } else {
              // @ts-ignore
              webkit.messageHandlers.resumeConsultCallback.postMessage(consult.consultID);
            }
          } else {
            dispatch(push(createRoute(ConsultRoutes.Consult, [['consultID', consult.consultID]])));
          }
        };
        break;
      case ConsultStatusEnum.Cancelled:
        statusText = 'Consult has been canceled';
        break;
    }

    return (
      <ActiveConsultInfoBanner
        statusText={statusText}
        connectionButtonText={connectionButtonText}
        onClick={onClick}
        routeTo={createRoute(ConsultRoutes.Consult, [['consultID', consult.consultID]])}
      />
    );
  }

  return null;
};

export default ProviderActiveConsultInfoBanner;
