import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { NestedViewActions, offNestedView, onNestedView } from './nestedViewActions';

const isOnNested = createReducer<string, NestedViewActions>('')
  .handleAction([onNestedView], (_state, action) => action.payload)
  .handleAction([offNestedView], () => '');

export interface INestedViewState {
  isOnNested: string;
}

export default combineReducers<INestedViewState>({
  isOnNested,
});
