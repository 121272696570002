import { FC } from 'react';
import { ConfirmationModal } from '../../Modals';
import { IFileUpload } from '../useFileUploader/useFileUploader';

type DeleteFileModalProps = {
  id?: string;
  onClose: () => void;
  onDelete: (phrDocumentID: string) => void;
  file?: IFileUpload;
};

export const DeleteFileModal: FC<DeleteFileModalProps> = (props) => {
  return (
    <ConfirmationModal
      isModalOpen={!!props.id}
      description="Are you sure you want to delete this file?"
      onConfirm={() => props.onDelete(props.id!)}
      onCancel={props.onClose}
      modalTitle="Delete File"
    />
  );
};
