import { useQuery } from 'react-query';
import { AuthClient, SearchClient, Tag } from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

interface IProps {
  countryID?: string;
}

export const useProviderSymptomsTags = ({ countryID }: IProps) => {
  const {
    isLoading: isLoadingConsultSymptoms,
    data: consultSymptoms,
    error: consultSymptomsError,
  } = useQuery<Tag[], string>(
    [QueryKeys.ProvidersSymptomsTags, countryID],
    async () => httpRequest(() => new SearchClient(new AuthClient()).consultSymptoms(countryID!)),
    { enabled: !!countryID },
  );

  return {
    isLoadingConsultSymptoms,
    consultSymptoms,
    consultSymptomsError,
  };
};
