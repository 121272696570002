import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Card from '../../../components/Card';
import EmptyListCard from '../../../components/EmptyListCard';
import Modal from '../../../components/Modals/Modal';
import SkeletonLine from '../../../components/Skeleton/SkeletonLine';
import { SkeletonPulse } from '../../../components/Skeleton/SkeletonPulse';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';
import { IPHRPatientEnhanced } from '../../../store/patient/phr/phrReducers';
import { deleteForPhrSection } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import AddPhrItemButton from '../components/AddPhrItemButton';
import PhrChartSkeleton from '../components/PhrChartSkeleton';
import { PhrDetailsButtons } from '../components/PhrItemView';
import TrackerDetailCardComponent from '../trackers/TrackerDetailsCard';
import TrackerDetailsView from '../trackers/TrackerDetailsView';

enum ToggleID {
  TrendView = 'TrendView',
  ListView = 'ListView',
}

enum ViewStateEnum {
  Home = 'home',
  Details = 'details',
  Edit = 'edit',
}

interface IProps {
  phrCategoryEnum: ReduxPHRCategoryEnum;
  patient: IPHRPatientEnhanced;
  trackerData: any;
  thinView: (x: any, onView: any) => any;
  Chart?: any;
  Form: any;
  createText: string;
  unitTypeOptions?: any;
  unitValue?: string;
  setSelectedUnitValue?: (x: any) => void;
  renderHeader: (addButton?: any, optionalBack?: any) => any;
  onClose: () => void;
}

const PhysicalTrackerView = ({
  phrCategoryEnum,
  patient,
  trackerData,
  thinView,
  Chart,
  Form,
  createText,
  unitValue,
  renderHeader,
}: IProps) => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [recordToDelete, setRecordToDelete] = useState<string>('');
  const [selectedData, setSelectedData] = useState<any | null>(null);
  const { isPhrLoading } = useSelector((state: AppState) => state.phrPatientState);

  const [activeId, setActiveId] = useState<ToggleID>(ToggleID.TrendView);
  const [viewState, setViewState] = useState<ViewStateEnum>(ViewStateEnum.Home);

  const onEdit = (data: any) => {
    setSelectedData(data);
    setViewState(ViewStateEnum.Edit);
  };

  const onView = (data: any) => {
    setSelectedData(data);
    setViewState(ViewStateEnum.Details);
  };

  const onDelete = (phrTrackerDetailID: string) => {
    setShowDeleteModal(true);
    setRecordToDelete(phrTrackerDetailID);
  };

  const confirmDelete = async () => {
    setShowDeleteModal(false);
    await dispatch(deleteForPhrSection(phrCategoryEnum, recordToDelete, patient.patientID, true));
    setViewState(ViewStateEnum.Home);
  };

  return (
    <>
      {viewState === ViewStateEnum.Home
        ? renderHeader(() => (
            <AddPhrItemButton
              onClick={() => {
                setSelectedData(null);
                setViewState(ViewStateEnum.Edit);
              }}
            >
              {createText}
            </AddPhrItemButton>
          ))
        : viewState === ViewStateEnum.Details
        ? renderHeader(
            () => (
              <PhrDetailsButtons
                phrItem={selectedData}
                phrItemID={selectedData?.phrTrackerDetailID}
                isVerified={selectedData?.verified}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ),
            () => setViewState(ViewStateEnum.Home),
          )
        : renderHeader(undefined, () =>
            setViewState(selectedData ? ViewStateEnum.Details : ViewStateEnum.Home),
          )}

      {viewState === ViewStateEnum.Home && (
        <>
          {isPhrLoading ? (
            Chart ? (
              <TrackerSkeletonView />
            ) : (
              <StyledCardSkeleton />
            )
          ) : Chart ? (
            <ToggleSwitch initial={activeId} active={activeId}>
              <ToggleSwitch.ButtonWrapper
                display="flex"
                justifyContent="center"
                maxW="100%"
                bgColor="transparent"
                borderRadius="none"
                borderBottom="1px solid #78819D3D"
                padding={0}
                mb="1.5rem"
              >
                <ToggleSwitch.Button
                  id={ToggleID.TrendView}
                  onClick={() => setActiveId(ToggleID.TrendView)}
                  w="150px"
                  activeBgColor="#000000"
                  activeColor="#ffffff"
                  color="#000000"
                  borderRadius="12px 12px 4px 4px"
                >
                  Trend View
                </ToggleSwitch.Button>
                <ToggleSwitch.Button
                  id={ToggleID.ListView}
                  onClick={() => setActiveId(ToggleID.ListView)}
                  w="150px"
                  activeBgColor="#000000"
                  activeColor="#ffffff"
                  color="#000000"
                  borderRadius="12px 12px 4px 4px"
                >
                  List View
                </ToggleSwitch.Button>
              </ToggleSwitch.ButtonWrapper>

              <ToggleSwitch.Area id={ToggleID.TrendView}>
                <Chart dataSet={trackerData} unit={unitValue} />
              </ToggleSwitch.Area>
              <ToggleSwitch.Area id={ToggleID.ListView}>
                {trackerData.length > 0 ? (
                  trackerData.map((x) => (
                    <TrackerDetailCardComponent
                      key={x.phrTrackerDetailID}
                      ThinView={() => thinView(x, onView)}
                    />
                  ))
                ) : (
                  <EmptyListCard>Nothing added yet</EmptyListCard>
                )}
              </ToggleSwitch.Area>
            </ToggleSwitch>
          ) : (
            <>
              {trackerData.length > 0 ? (
                trackerData.map((x) => (
                  <TrackerDetailCardComponent
                    key={x.phrTrackerDetailID}
                    ThinView={() => thinView(x, onView)}
                  />
                ))
              ) : (
                <EmptyListCard>Nothing added yet</EmptyListCard>
              )}
            </>
          )}
        </>
      )}
      {viewState === ViewStateEnum.Edit && (
        <Box h={{ base: '100%', md: '600px' }} overflowY="auto">
          <Card>
            <Form
              selectedData={selectedData}
              patientID={patient.patientID}
              toggleView={() => setViewState(ViewStateEnum.Home)}
              // unit={unitValue}
              // unitSelector={unitTypeOptions && unitSelector}
            />
          </Card>
        </Box>
      )}

      {viewState === ViewStateEnum.Details && (
        <>
          <Box marginX={{ base: '1rem', md: '2.5rem' }} marginBottom="1rem">
            <TrackerDetailsView
              item={selectedData}
              name={selectedData?.trackerTypeEnum}
              createdDate={selectedData?.trackerDate}
              unitValue={unitValue}
              verifiedCallback={() => {
                const index = trackerData.findIndex(
                  ({ phrTrackerDetailID }) =>
                    phrTrackerDetailID === selectedData?.phrTrackerDetailID,
                );
                if (index > -1) {
                  trackerData[index].verified = true;
                }
              }}
            />
          </Box>
        </>
      )}

      <Modal
        title="Delete Record?"
        onDismiss={() => setShowDeleteModal(false)}
        onCancel={() => setShowDeleteModal(false)}
        onSubmit={confirmDelete}
        submitText="Yes"
        cancelText="Cancel"
        isOpen={showDeleteModal}
      >
        <p>Are you sure you want to delete this record?</p>
      </Modal>
    </>
  );
};

export default PhysicalTrackerView;

export const TrackerSkeletonView = () => {
  return (
    <>
      <LinkRow>
        <LinkWrapper>
          <SkeletonLine inverse>Chart View</SkeletonLine>
          <SkeletonLine inverse>List View</SkeletonLine>
        </LinkWrapper>
      </LinkRow>
      <PhrChartSkeleton />
    </>
  );
};

const LinkRow = styled.div`
  display: flex;
  justify-content: center;
  margin: 2.5rem 0;
`;

const LinkWrapper = styled.div`
  width: 75%;
  display: flex;
  justify-content: space-around;
`;

const StyledCardSkeleton = styled(Card)`
  ${SkeletonPulse};
  border-radius: 20px;
  margin-bottom: 0.5rem;
  height: 200px;
`;
