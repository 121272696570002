import { AddressBook, AddressBookFolderEnum } from '../../../generated';

// Name field is used for stores, Title / First / Middle / Last fields is used for providers
// Name for stores and First / Last Name for Providers are mandatory
export const getAddressBookName = (addressBook: AddressBook) => {
  const getAlternativeName = () => {
    switch (addressBook.addressBookFolderEnum) {
      case AddressBookFolderEnum.Provider: {
        const name = `${addressBook.firstName || ''} ${addressBook.middleName || ''} ${
          addressBook.lastName || ''
        }`;
        return name || addressBook.title || '';
      }
      case AddressBookFolderEnum.Organization:
        return addressBook.organizationName || addressBook.name || '';
      default:
        return addressBook.name || '';
    }
  };
  return addressBook.sortName || getAlternativeName();
};
