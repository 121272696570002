import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { TertiaryButton } from '../../../components/Buttons';
import { OutpostTheme } from '../../../themes/outpost';

interface ITrackerChartBaseProps {
  name: string;
  color: string;
  daysRange: number;
  setDaysRange: (daysRange: number) => void;
  children: React.ReactNode;
}

const TrackerChartBase = ({
  name,
  color,
  daysRange,
  setDaysRange,
  children,
}: ITrackerChartBaseProps) => {
  return (
    <Box
      marginX={{ base: 0, md: '1.5rem' }}
      bgColor="#ffffff"
      borderRadius="12px"
      height={{ base: 'calc(100vh - 12rem)', md: '480px' }}
    >
      <Flex padding="0.75rem" justify="space-between">
        <HStack spacing={4}>
          <Box w="28px" h="28px" bgColor={color || '#000000'} borderRadius="8px" />
          <Text fontSize={{ base: '12px', md: '14px' }} color="#414F65" textTransform="uppercase">
            {name}
          </Text>
        </HStack>

        <HStack spacing={0}>
          <TertiaryButton
            color={daysRange === 1 ? '#FFFFFF' : '#414F65'}
            fontSize={OutpostTheme.FontSizes.body}
            fontWeight="normal"
            bgColor={daysRange === 1 ? '#030303' : 'transparent'}
            padding="0.5rem 0.8rem"
            borderRadius="3px"
            _hover={{ textDecor: 'none' }}
            onClick={() => setDaysRange(1)}
          >
            1D
          </TertiaryButton>
          <TertiaryButton
            color={daysRange === 7 ? '#FFFFFF' : '#414F65'}
            fontSize={OutpostTheme.FontSizes.body}
            fontWeight="normal"
            bgColor={daysRange === 7 ? '#030303' : 'transparent'}
            padding="0.5rem 0.8rem"
            borderRadius="3px"
            _hover={{ textDecor: 'none' }}
            onClick={() => setDaysRange(7)}
          >
            1W
          </TertiaryButton>
          <TertiaryButton
            color={daysRange === 30 ? '#FFFFFF' : '#414F65'}
            fontSize={OutpostTheme.FontSizes.body}
            fontWeight="normal"
            bgColor={daysRange === 30 ? '#030303' : 'transparent'}
            padding="0.5rem 0.8rem"
            borderRadius="3px"
            _hover={{ textDecor: 'none' }}
            onClick={() => setDaysRange(30)}
          >
            1M
          </TertiaryButton>
          <TertiaryButton
            color={daysRange === 180 ? '#FFFFFF' : '#414F65'}
            fontSize={OutpostTheme.FontSizes.body}
            fontWeight="normal"
            bgColor={daysRange === 180 ? '#030303' : 'transparent'}
            padding="0.5rem 0.8rem"
            borderRadius="3px"
            _hover={{ textDecor: 'none' }}
            onClick={() => setDaysRange(180)}
          >
            6M
          </TertiaryButton>
          <TertiaryButton
            color={daysRange === 365 ? '#FFFFFF' : '#414F65'}
            fontSize={OutpostTheme.FontSizes.body}
            fontWeight="normal"
            bgColor={daysRange === 365 ? '#030303' : 'transparent'}
            padding="0.5rem 0.8rem"
            borderRadius="3px"
            _hover={{ textDecor: 'none' }}
            onClick={() => setDaysRange(365)}
          >
            1Y
          </TertiaryButton>
        </HStack>
      </Flex>

      {children}
    </Box>
  );
};

export default TrackerChartBase;
