import { Box, HStack, IconButton, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { CloseIcon2 } from '../../../../../../../assets/icons';
import { Tag } from '../../../../../../../generated';
import { OutpostTheme } from '../../../../../../../themes/outpost';

type SelectedSymptomCardProps = {
  symptom: Tag;
  onRemove?: () => void;
};

const SelectedSymptomCard: FC<SelectedSymptomCardProps> = (props) => {
  return (
    <Box borderRadius="100px" py="1" pl="3" pr="1" border="2px solid rgb(0, 3, 16)">
      <HStack spacing="3">
        <Text fontSize={OutpostTheme.FontSizes.body} color="rgb(0, 3, 16)">
          {props.symptom.name}
        </Text>
        {Boolean(props.onRemove) && (
          <IconButton
            aria-label="Remove"
            bgColor="transparent"
            _hover={{ bgColor: 'rgb(219, 217, 217)' }}
            p="0"
            minW="24px"
            minH="24px"
            height="auto"
            borderRadius="full"
            onClick={props.onRemove}
          >
            <CloseIcon2 fontSize="12px" />
          </IconButton>
        )}
      </HStack>
    </Box>
  );
};

export { SelectedSymptomCard };
