import moment from 'moment';
import React, { useState } from 'react';
import { TimeFormatEnum } from '../../../../constants';
import { PHRForm } from '../../../../generated';
import { IntakeForm } from '../../../../routes/heal/Consult/Provider/ConsultNotesComponent';
import { BaseModal } from '../../../Modals';
import { ResultView } from './ResultView';

interface IProps {
  intakeForm: IntakeForm;
  phrForm?: PHRForm;
  loadPhrForms: () => void;
  children: any;
}
export const ResultViewModal = ({ intakeForm, phrForm, loadPhrForms, children }: IProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const openModal = () => phrForm && setShowModal(true);

  return (
    <>
      {children({ openModal })}
      <BaseModal
        isOpen={showModal}
        size="lg"
        title={`Review of ${intakeForm.formShortName} form for ${
          intakeForm.patient?.fullName
        } at ${moment(intakeForm.updatedDate).format(TimeFormatEnum.SHORT_DATE_TIME)}`}
      >
        {phrForm && (
          <ResultView
            intakeForm={intakeForm}
            phrForm={phrForm}
            loadPhrForms={loadPhrForms}
            onClose={() => setShowModal(false)}
          />
        )}
      </BaseModal>
    </>
  );
};
