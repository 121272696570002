import { useCallback } from 'react';

import { ConsultTypeEnum, StoreItemTypeEnum } from '../../../../../../../generated';
import { useMixTrack } from '../../../../../../../lib/mixpanel/mixpanelUtils';

import useBookConsultContext from '../../../hooks/useBookConsultContext/useBookConsultContext';

import { BookConsultStepEnum, IBookConsultState } from '../../BookConsultProvider';

import { useDeviceBreakPoints } from '../useDeviceBreakpoints/useDeviceBreakpoints';

export const getConsultTypeEnum = (storeItemTypeEnum: StoreItemTypeEnum) => {
  switch (storeItemTypeEnum) {
    case StoreItemTypeEnum.InPersonConsult:
      return ConsultTypeEnum.InPerson;
    case StoreItemTypeEnum.VideoConsult:
      return ConsultTypeEnum.Video;
    default:
      return undefined;
  }
};

const useProviderVisitType = () => {
  const { state, updateState, handlers } = useBookConsultContext();

  const { isTabletDevice, isMobileDevice, isSmallDevice } = useDeviceBreakPoints();

  const { mixTrack } = useMixTrack();

  const handleNextStep = useCallback(
    (storeItemTypeEnum: StoreItemTypeEnum, saveCurrentState = true) => {
      mixTrack({
        eventName: 'book_consult:patient_selected_consult_visit_type',
        label: storeItemTypeEnum,
      });

      const currentStep = state.provider
        ? BookConsultStepEnum.AppointmentSlot
        : BookConsultStepEnum.TypeOfProvider;

      const newState: IBookConsultState = {
        ...state,
        storeItemTypeEnum: storeItemTypeEnum,
        consultTypeEnum: getConsultTypeEnum(storeItemTypeEnum),
        currentStep,
      };

      updateState(newState, saveCurrentState);
    },
    [mixTrack, state, updateState],
  );

  const onGoBack = () => {
    const newState: IBookConsultState = {
      ...state,
      currentStep: BookConsultStepEnum.Patient,
    };

    updateState(newState);
  };

  return {
    isSmallDevice,
    isMobileDevice,
    isTabletDevice,
    handleNextStep,
    onGoBack,
    handlers,
  };
};

export { useProviderVisitType };
