import { EMPTY_GUID } from '../../../../constants';
import { AddressBook, SendToEnum } from '../../../../generated';

const getSendToDetailsForAddress = (addressBook?: AddressBook) => {
  const sendToDetails = {
    sendToEnum: SendToEnum.DoNotSend,
    sendToID: undefined as string | undefined,
  };

  if (!addressBook) {
    return sendToDetails;
  }

  if (addressBook.addressBookID === EMPTY_GUID) {
    // It's an internal Store
    sendToDetails.sendToEnum = SendToEnum.Store;
    sendToDetails.sendToID = addressBook.associatedID;
  } else {
    // It's an external Address Book entry
    sendToDetails.sendToEnum = SendToEnum.AddressBookFax;
    sendToDetails.sendToID = addressBook.addressBookID;
  }

  return sendToDetails;
};

export { getSendToDetailsForAddress };
