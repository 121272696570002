import moment from 'moment';

export const getCurrentDate = () => {
  return new Date();
};

export const getYear = (date: Date) => {
  return date.getFullYear();
};

export const getMonth = (date: Date) => {
  return date.getMonth();
};

export const getLast7Days = () => {
  return moment().add(-7, 'day').toDate();
};

export const getLast30Days = () => {
  return moment().add(-30, 'day').toDate();
};
