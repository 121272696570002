import { createAction, ActionType } from 'typesafe-actions';
import { IActionMessage } from '../../generated';

export const sendActionMessageActions = createAction(
  'SIGNAL_SEND_ACTION_MESSAGE',
  (actionMessage: IActionMessage) => actionMessage,
)();

export const disconnectSignalR = createAction('SIGNAL_DISCONNECT', () => {})();

export type ActionMessageActionTypes = ActionType<
  typeof sendActionMessageActions | typeof disconnectSignalR
>;
