import { connectRouter, RouterState } from 'connected-react-router';
import { Action, combineReducers } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import activeConsultReducer, { IActiveConsultState } from './activeConsult/activeConsultReducers';
import activeMarketingCampaignsReducers, {
  IActiveMarketingCampaignsState,
} from './activeMarketingCampaigns/activeMarketingCampaignsReducers';
import appFeaturesReducers, { IAppFeaturesState } from './appFeatures/appFeaturesReducers';
import appVersionReducers, { IAppVersionState } from './appVersion/appVersionReducers';
import currentAppReducers, { TCurrentAppState } from './currentApp/currentAppReducers';
import currentCampaignReducer, {
  ICurrentCampaignState,
} from './currentCampaign/currentCampaignReducers';
import currentChatUserReducer, {
  ICurrentChatUserState,
} from './currentChatUser/currentChatUserReducers';
import currentPartnerReducer, {
  ICurrentPartnerState,
} from './currentPartner/currentPartnerReducers';
import currentUserReducer, { ICurrentUserState } from './currentUser/currentUserReducers';
import nestedViewReducer, { INestedViewState } from './nestedView/nestedViewReducer';
import pageHeaderReducer, { IPageHeaderState } from './pageHeader/pageHeaderReducer';
import patientReducer, { IPatientState } from './patient/patientReducers';
import phrReducer, { IPHRPatientState } from './patient/phr/phrReducers';
import pictureUploadReducer, { IPictureUploadState } from './pictureUpload/pictureUploadReducer';
import providerReducer, { IProviderState } from './provider/providerReducers';
import shoppingCartItemReducer, {
  IShoppingCartItemCountState,
} from './shoppingCartItemCount/shoppingCartItemCountReducer';
import sideMenuReducer, { ISideMenuState } from './sideMenu/sideMenuReducer';
import systemMessagesReducer, {
  ISystemMessagesState,
} from './systemMessages/systemMessagesReducer';
import systemSettingsReducers, {
  ISystemSettingsState,
} from './systemSettings/systemSettingsReducers';
import unacknowledgedRewardReducer, {
  IUnacknowledgedRewardCountState,
} from './unacknowledgedRewardCount/unacknowledgedRewardCountReducer';
import unreadMailMessageReducer, {
  IUnreadMailMessageCountState,
} from './unreadMailMessageCount/unreadMailMessageCountReducer';
import userDetailSettingReducer, {
  IUserDetailSettingState,
} from './userDetailSettings/userDetailSettingsReducer';
import ussdTransactionReducers, {
  IUssdTransactionState,
} from './ussdTransaction/ussdTransactionReducer';
import waitingRoomReducers, { IWaitingRoomState } from './waitingRoom/waitingRoomReducers';
import discussionItemsReducer, {
  IDiscussionItemState,
} from './discussionItems/discussionItemsReducer';

export { phrReducer, currentUserReducer };

export interface AppState {
  router: RouterState;
  currentUserState: ICurrentUserState;
  patientState: IPatientState;
  activeConsultState: IActiveConsultState;
  providerState: IProviderState;
  unreadMailMessageCountState: IUnreadMailMessageCountState;
  shoppingCartItemCountState: IShoppingCartItemCountState;
  unacknowledgedRewardCountState: IUnacknowledgedRewardCountState;
  userDetailSettingState: IUserDetailSettingState;
  pictureUploadState: IPictureUploadState;
  phrPatientState: IPHRPatientState;
  appVersionState: IAppVersionState;
  appFeaturesState: IAppFeaturesState;
  systemMessagesState: ISystemMessagesState;
  systemSettingsState: ISystemSettingsState;
  pageHeaderState: IPageHeaderState;
  waitingRoomState: IWaitingRoomState;
  toastrReducer: any;
  ussdTransactionState: IUssdTransactionState;
  sideMenuState: ISideMenuState;
  activeMarketingCampaignsState: IActiveMarketingCampaignsState;
  nestedViewState: INestedViewState;
  currentPartnerState: ICurrentPartnerState;
  currentChatUserState: ICurrentChatUserState;
  currentAppState: TCurrentAppState;
  currentCampaignState: ICurrentCampaignState;
  discussionItemsState: IDiscussionItemState;
}

export type OHThunkResult<R> = ThunkAction<R, AppState, undefined, Action>;
export type OHThunkDispatch = ThunkDispatch<AppState, undefined, Action>;

export const rootReducer = (history?: any) => {
  const reducers: any = {
    activeConsultState: activeConsultReducer,
    appVersionState: appVersionReducers,
    appFeaturesState: appFeaturesReducers,
    currentUserState: currentUserReducer,
    patientState: patientReducer,
    phrPatientState: phrReducer,
    pictureUploadState: pictureUploadReducer,
    providerState: providerReducer,
    systemMessagesState: systemMessagesReducer,
    systemSettingsState: systemSettingsReducers,
    unreadMailMessageCountState: unreadMailMessageReducer,
    shoppingCartItemCountState: shoppingCartItemReducer,
    unacknowledgedRewardCountState: unacknowledgedRewardReducer,
    userDetailSettingState: userDetailSettingReducer,
    pageHeaderState: pageHeaderReducer,
    waitingRoomState: waitingRoomReducers,
    ussdTransactionState: ussdTransactionReducers,
    sideMenuState: sideMenuReducer,
    activeMarketingCampaignsState: activeMarketingCampaignsReducers,
    nestedViewState: nestedViewReducer,
    currentPartnerState: currentPartnerReducer,
    currentChatUserState: currentChatUserReducer,
    currentAppState: currentAppReducers,
    currentCampaignState: currentCampaignReducer,
    discussionItemsState: discussionItemsReducer,
  };

  if (history) {
    reducers.router = connectRouter(history);
  }
  return combineReducers(reducers);
};
