import { Button, ButtonProps } from '@chakra-ui/react';

export type PrimaryButtonProps = ButtonProps;

export const PrimaryButton = (props: PrimaryButtonProps) => {
  const { children, minWidth = '120px', colorScheme = 'blue', ...rest } = props;

  return (
    <Button
      borderRadius="12px"
      background="purple.600"
      borderWidth="1px"
      borderStyle="solid"
      _active={
        rest.isDisabled
          ? {}
          : {
              background: 'purple.50',
              border: 'purple.600',
              color: 'purple.600',
            }
      }
      _hover={{
        background: 'purple.50',
        border: 'purple.600',
        color: 'purple.600',
      }}
      colorScheme={colorScheme}
      minWidth={minWidth}
      {...rest}
    >
      {children}
    </Button>
  );
};
