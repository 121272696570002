import { Box, VStack } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';
import { useInfiniteScroll } from '../../utils/hooks/useInfiniteScroll';
import { SecondaryButton } from '../Buttons';
import { CenteredLoadingIndicator } from '../LoadingIndicator';

type InfiniteScrollNextProps = {
  isLoading?: boolean;
  handleFetchMore: () => void;
  hasMore?: boolean;
  children?: ReactElement;
};

const InfiniteScrollNext: FC<InfiniteScrollNextProps> = (props) => {
  const { ref: infiniteScrollTriggerRef } = useInfiniteScroll({
    fetchNextPage: () => props.handleFetchMore(),
    hasNextPage: props.hasMore,
  });

  return (
    <>
      {props.children}
      <VStack width="100%" alignItems="center">
        {props.isLoading && <CenteredLoadingIndicator />}
        {!props.isLoading && props.hasMore && (
          <Box alignItems="center" py="2" ref={infiniteScrollTriggerRef}>
            <SecondaryButton onClick={props.handleFetchMore}>Load more</SecondaryButton>
          </Box>
        )}
      </VStack>
    </>
  );
};

export { InfiniteScrollNext };
