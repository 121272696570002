import { Box, Flex } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PHRVaccination, VaccineTypeEnum } from '../../../generated';
import { clearPHRPatientErrorAction } from '../../../store/patient/phr/phrActions';
import { IPHRPatientEnhanced } from '../../../store/patient/phr/phrReducers';
import { phrVaccinationsSelector } from '../../../store/patient/phr/phrSelectors';
import { deleteForPhrSection, getAllForPhrSection } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { scrollToTop } from '../../../utils/scrollToTop';

import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import ServerValidationBox from '../../../components/ServerValidationBox';
import { AddPHRItemButton } from '../components/PhrItem';
import PhrItemDeleteModal from '../components/PhrItemDeleteModal';
import { PhrFormButtons } from '../components/PhrItemForm';
import { PhrDetailsButtons } from '../components/PhrItemView';
import { VaccinationForm } from './VaccinationForm';
import { VaccinationList } from './VaccinationList';
import VaccinationView from './VaccinationView';

enum EVaccinationsViewState {
  List = 'List',
  View = 'View',
  Edit = 'Edit',
}

interface IProps {
  patient: IPHRPatientEnhanced;
  renderHeader: (onCreate?: any, onBack?: () => void, title?: string, showDismiss?: boolean) => any;
}

const Vaccinations = ({ patient, renderHeader }: IProps) => {
  const dispatch = useDispatch();
  const innerBtnRef = useRef<HTMLButtonElement>(null);
  const [currentView, setCurrentView] = useState(EVaccinationsViewState.List);
  const phrVaccinations = phrVaccinationsSelector(patient.phrVaccinations).filter(
    (vaccination) => vaccination.vaccineTypeEnum !== VaccineTypeEnum.Covid,
  );
  const { isPhrLoading, error } = useSelector((state: AppState) => state.phrPatientState);
  const [currentVaccination, setCurrentVaccination] = useState<PHRVaccination | null>(null);
  const [recordToDelete, setRecordToDelete] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    if (patient) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRVaccinations, patient.patientID));
    }
  }, [dispatch, patient]);

  const onCreate = () => {
    setCurrentVaccination(null);
    setCurrentView(EVaccinationsViewState.Edit);
  };

  const onView = (phrVaccination: PHRVaccination) => {
    setCurrentVaccination(phrVaccination);
    setCurrentView(EVaccinationsViewState.View);
  };

  const onEdit = (phrVaccination: PHRVaccination) => {
    setCurrentVaccination(phrVaccination);
    setCurrentView(EVaccinationsViewState.Edit);
  };

  const onDelete = (phrVaccinationID: string) => {
    setShowDeleteModal(true);
    setRecordToDelete(phrVaccinationID);
  };

  const confirmDelete = async () => {
    setCurrentView(EVaccinationsViewState.List);
    setShowDeleteModal(false);
    await dispatch(
      deleteForPhrSection(
        ReduxPHRCategoryEnum.PHRVaccinations,
        recordToDelete,
        patient.patientID,
        false,
      ),
    );
  };

  useEffect(() => {
    dispatch(clearPHRPatientErrorAction());
    scrollToTop();
  }, [currentView, dispatch]);

  return (
    <Box paddingY="2rem" backgroundColor="#f4f4f4" height="100%">
      {currentView === EVaccinationsViewState.List ? (
        renderHeader()
      ) : (
        <Flex>
          {renderHeader(null, () => setCurrentView(EVaccinationsViewState.List), '', false)}
          {currentView === EVaccinationsViewState.View && (
            <PhrDetailsButtons
              isVerified={currentVaccination?.verified}
              phrItem={currentVaccination}
              phrItemID={currentVaccination?.phrVaccinationID}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          )}
          {currentView === EVaccinationsViewState.Edit && (
            <PhrFormButtons
              innerBtnRef={innerBtnRef}
              toggleView={() => setCurrentView(EVaccinationsViewState.List)}
            />
          )}
        </Flex>
      )}

      {currentView === EVaccinationsViewState.List && (
        <>
          <AddPHRItemButton name="Vaccination" onCreate={onCreate} />
          <ServerValidationBox message={error} />
          {isPhrLoading ? (
            <Box marginTop="1rem">
              <CenteredLoadingIndicator />
            </Box>
          ) : (
            <VaccinationList phrVaccinations={phrVaccinations} onView={onView} />
          )}
        </>
      )}

      {currentView === EVaccinationsViewState.View && (
        <VaccinationView vaccination={currentVaccination!} />
      )}

      {currentView === EVaccinationsViewState.Edit && (
        <VaccinationForm
          currentVaccination={currentVaccination}
          patientID={patient.patientID}
          toggleView={() => setCurrentView(EVaccinationsViewState.List)}
          innerBtnRef={innerBtnRef}
        />
      )}

      <PhrItemDeleteModal
        isOpen={showDeleteModal}
        onDismiss={() => setShowDeleteModal(false)}
        onDelete={confirmDelete}
      />
    </Box>
  );
};

export default Vaccinations;
