import { ActionType, createAsyncAction } from 'typesafe-actions';
import { AuthClient, UserDetailsClient, UserDetailSetting } from '../../generated';
import { httpRequest } from '../../utils';
import { OHThunkResult } from '../root-reducers';

export const getUserDetailSettingsActions = createAsyncAction(
  ['GET_USER_DETAIL_SETTINGS_REQUEST', () => {}],
  ['GET_USER_DETAIL_SETTINGS_SUCCESS', (res: UserDetailSetting[]) => res],
  ['GET_USER_DETAIL_SETTINGS_ERROR', (err: string) => err],
)();

export function getUserDetailSettings(): OHThunkResult<Promise<Array<UserDetailSetting>>> {
  return async (dispatch) => {
    return new Promise<Array<UserDetailSetting>>(async (resolve) => {
      dispatch(getUserDetailSettingsActions.request());

      try {
        const userDetailsClient = new UserDetailsClient(new AuthClient());
        const result = await httpRequest(() => userDetailsClient.userDetailSettingsGet());
        dispatch(getUserDetailSettingsActions.success(result));
        resolve(result);
      } catch (err) {
        dispatch(getUserDetailSettingsActions.failure(err as any));
      }
    });
  };
}

export type GetUserDetailSettingsActionTypes = ActionType<typeof getUserDetailSettingsActions>;
