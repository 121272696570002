import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbProps } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { RewindIcon } from '../../assets/icons';
import { RouteConfigs } from '../../constants';

const Breadcrumbs = (props: BreadcrumbProps) => {
  const breadcrumbs = useBreadcrumbs(RouteConfigs, {
    excludePaths: [
      '/heal',
      '/learn',
      '/monitor',
      '/merchant',
      '/myprofile',
      '/community',
      '/virtualclinic',
    ],
    disableDefaults: true,
  });

  return (
    <Breadcrumb separator={<RewindIcon display="flex" />} {...props}>
      {breadcrumbs.map(({ match, breadcrumb }, index) => {
        const isCurrentPage = breadcrumbs.length - 1 === index;

        if (isCurrentPage) {
          return (
            <BreadcrumbItem key={match.url} isCurrentPage={isCurrentPage}>
              <BreadcrumbLink href={match.url} isCurrentPage={isCurrentPage}>
                {breadcrumb}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        } else {
          return (
            <BreadcrumbItem key={match.url} isCurrentPage={isCurrentPage}>
              <BreadcrumbLink to={match.url} as={Link} isCurrentPage={isCurrentPage}>
                {breadcrumb}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        }
      })}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
