import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import BirthDateComponent from '../../../../components/BirthDateComponent';
import { SecondaryButton } from '../../../../components/Buttons/SecondaryButton';
import { Avatar } from '../../../../components/common/Avatar';
import { H3 } from '../../../../components/common/Header';
import { SpacingRelated } from '../../../../constants';
import { IPatient, PatientInsurance } from '../../../../generated';
import { usePatientInsurancesByPatientID } from '../../../../services/patientsInsurances/queries/usePatientInsurancesByPatientID';
import { OutpostTheme } from '../../../../themes/outpost';
import { displayValue } from '../../../../utils/displayValue';
import { getUserThumbnail } from '../../../../utils/getUserThumbnail';
import { Details, Label, Value } from '../../../phr/components/StyledPhrItems';
import { PatientCardStateEnum, usePatientUpdate } from './hooks/usePatientUpdate/usePatientUpdate';
import { PatientUpdateForm } from './PatientUpdateForm';

interface IProps {
  patient: IPatient;
}

const PatientInformationCard = ({ patient: patientDetails }: IProps) => {
  const [patient, setPatient] = useState<IPatient>(patientDetails);

  const getPatientProvinceStateName = (): string => {
    return (patient?.provinceState ? patient?.provinceState.name : patient?.province) ?? '';
  };
  const [primaryInsurance, setPrimaryInsurance] = useState<PatientInsurance>();

  const {
    activeState,
    setActiveState,
    error,
    loading,
    isLoadingTimeZones,
    isLoadingLanguages,
    languages,
    timeZones,
    updatePatientDetails,
  } = usePatientUpdate({
    primaryInsurance,
    patient,
    setPatient: (patient: IPatient) => setPatient(patient),
    setPrimaryInsurance,
  });

  const { patientInsurances } = usePatientInsurancesByPatientID({ patientID: patient.patientID });

  useEffect(() => {
    if (patientInsurances && patientInsurances.length > 0) {
      if (patientInsurances.filter((x) => x.isPrimary).length > 0) {
        setPrimaryInsurance(patientInsurances.find((x) => x.isPrimary === true));
      } else {
        setPrimaryInsurance(patientInsurances[0]);
      }
    }
  }, [patientInsurances]);

  return (
    <>
      {activeState === PatientCardStateEnum.EditPatientInfo && (
        <>
          <Box maxH={{ base: '100%', md: '600px' }} p={2} overflowY="scroll">
            <PatientUpdateForm
              cancel={() => setActiveState(PatientCardStateEnum.PatientInfo)}
              patient={patient}
              primaryInsurance={primaryInsurance}
              error={error}
              loading={loading}
              isLoadingTimeZones={isLoadingTimeZones}
              isLoadingLanguages={isLoadingLanguages}
              languages={languages}
              timeZones={timeZones}
              updatePatientDetails={(values) => {
                updatePatientDetails(values);
              }}
            />
            <Box h="50px" />
          </Box>
        </>
      )}

      {activeState === PatientCardStateEnum.PatientInfo && (
        <Box height="100%">
          <Flex alignItems="center" mb={4}>
            <Avatar
              size={OutpostTheme.Avatar.Size.Extra}
              src={getUserThumbnail(patient.imageDetailID)}
            />
            <Stack mx={4} spacing={1}>
              <Text fontSize="20px">{patient.fullName}</Text>
              <Text fontSize={OutpostTheme.FontSizes.body}>{patient.genderEnum}</Text>
              <Text fontSize={OutpostTheme.FontSizes.body}>
                <BirthDateComponent
                  birthDate={patient.birthDateString}
                  showBirthDate={true}
                  showAge={true}
                />
              </Text>
            </Stack>
            <SecondaryButton onClick={() => setActiveState(PatientCardStateEnum.EditPatientInfo)}>
              EDIT
            </SecondaryButton>
          </Flex>

          <PatientInfo>
            <div className="row">
              <div className="col-md-6 col-12">
                <Details>
                  <Label>Cell Phone Number:</Label>
                  <Value> {patient.formattedCellPhoneNumber || '-'}</Value>
                </Details>
              </div>
              <div className="col-md-6 col-12">
                <Details>
                  <Label>Home Phone Number:</Label>
                  <Value> {patient.formattedHomePhoneNumber || '-'}</Value>
                </Details>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-12">
                <Details>
                  <Label>Timezone:</Label>
                  <Value>{patient.timeZone}</Value>
                </Details>
              </div>
              <div className="col-md-6 col-12">
                <Details>
                  <Label>Primary Language:</Label>
                  <Value>{patient.primaryLanguage?.name}</Value>
                </Details>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Details>
                  <Label>Address:</Label>
                  <Value>
                    {patient.addressLine1 && <Text>{patient.addressLine1}</Text>}
                    {patient.addressLine2 && <Text>{patient.addressLine2}</Text>}
                    {(patient.city || getPatientProvinceStateName() || patient.postCode) && (
                      <Text>
                        {[patient.city, getPatientProvinceStateName(), patient.postCode].join(' ')}
                      </Text>
                    )}
                    <Text>{patient.country!.name}</Text>
                  </Value>
                </Details>
              </div>
              {primaryInsurance && (
                <div className="col-6">
                  <Details>
                    <Label>{primaryInsurance.insuranceProvider?.insuranceNumberLabel}:</Label>
                    <Value>{primaryInsurance.insuranceNumber}</Value>
                  </Details>
                </div>
              )}
            </div>
            {(patient.emergencyContactName ||
              patient.emergencyContactCellPhoneNumber ||
              patient.emergencyContactHomePhoneNumber ||
              patient.emergencyContactRelationship) && (
              <>
                <div className="row">
                  <div className="col-12">
                    <EmergencyContactName>Emergency Contact</EmergencyContactName>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <Details>
                      <Label>Name:</Label>
                      <Value>{displayValue(patient.emergencyContactName)}</Value>
                    </Details>
                  </div>
                  <div className="col-md-6 col-12">
                    <Details>
                      <Label>Relationship:</Label>
                      <Value>{displayValue(patient.emergencyContactRelationship)}</Value>
                    </Details>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <Details>
                      <Label>Mobile Phone Number:</Label>
                      <Value>{displayValue(patient.emergencyContactCellPhoneNumber)}</Value>
                    </Details>
                  </div>
                  <div className="col-md-6 col-12">
                    <Details>
                      <Label>Home Phone Number:</Label>
                      <Value>{displayValue(patient.emergencyContactHomePhoneNumber)}</Value>
                    </Details>
                  </div>
                </div>
              </>
            )}
          </PatientInfo>
        </Box>
      )}
    </>
  );
};

export default PatientInformationCard;

const EmergencyContactName = styled(H3)`
  margin-top: ${SpacingRelated.HalfRegular};
  margin-bottom: 0px;
  color: ${(props) => props.theme.Colors.Danger};
`;

const PatientInfo = styled.div`
  flex-grow: 1;
`;
