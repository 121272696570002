import { Button, ButtonProps, useDisclosure, IconButton } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PowerIcon } from '../../../../assets/icons';
import Modal from '../../../../components/Modals/Modal';
import { RolesEnum } from '../../../../generated';
import { ICurrentUser } from '../../../../store/currentUser/currentUserReducers';
import { logoutUser } from '../../../../store/root-creator';
import { AppState } from '../../../../store/root-reducers';
import { OutpostTheme } from '../../../../themes/outpost';
import { isInRole } from '../../../../utils/isInRole';

interface IProps extends ButtonProps {
  children: ReactNode;
}

export const LogoutButton = ({ children, ...buttonPropsRest }: IProps) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const isMenuOpen = useSelector((state: AppState) => state.sideMenuState.isOpen);
  const activeConsult = useSelector((state: AppState) => state.activeConsultState.consult);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const attemptLogout = () => {
    if (
      // (waitingRoom?.filter((x) => x.consultStatusEnum === ConsultStatusEnum.PatientCompleted) ?? [])
      //   .length > 0 ||
      activeConsult ||
      currentUser?.delegateJWT
    ) {
      onOpen();
    } else {
      dispatch(logoutUser());
    }
  };

  return (
    <>
      {isMenuOpen ? (
        <Button
          variant="menu"
          color="pallete.red"
          fontSize={OutpostTheme.FontSizes.body}
          sx={{
            '> .chakra-button__icon': {
              marginInlineEnd: '0.75rem',
            },
          }}
          leftIcon={<PowerIcon h="18px" w="18px" />}
          onClick={attemptLogout}
          justifyContent="center"
          borderRadius="0.5rem"
          _hover={{
            background: 'pallete.red',
            color: 'pallete.white',
          }}
          {...buttonPropsRest}
        >
          {children}
        </Button>
      ) : (
        <IconButton
          onClick={attemptLogout}
          w="50px"
          p={2}
          borderRadius="0.5rem"
          backgroundColor={'transparent'}
          color="pallete.red"
          _hover={{
            background: 'pallete.red',
            color: 'pallete.white',
          }}
          aria-label="Menu"
          icon={<PowerIcon h="24px" w="24px" />}
        />
      )}
      <Modal
        isOpen={isOpen}
        title="Signing out"
        onCancel={onClose}
        cancelText="No, stay signed in"
        onSubmit={() => dispatch(logoutUser())}
        submitText="Yes, sign out"
      >
        {bodyText(currentUser)}
      </Modal>
    </>
  );
};

const bodyText = (currentUser: ICurrentUser | null): string => {
  if (currentUser?.delegateJWT) {
    return 'You are currently delegating for another member. Are you sure you want to sign out? This will sign out for both accounts.';
  } else if (isInRole(currentUser, RolesEnum.Provider)) {
    return 'You currently are participating in a cosnult. Are you sure you want to sign out?';
  } else if (isInRole(currentUser, RolesEnum.Patient)) {
    return 'You currently are participating in a consult. Are you sure you want to sign out?';
  } else return 'Are you sure you want to sign out?';
};
