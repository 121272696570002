import { useEffect, useMemo, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const Rotation = keyframes`
  from {
    transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 9rem;
  width: 100%;
  background-color: transparent;
`;

const Spinner = styled.div`
  width: 4rem;
  height: 4rem;
  border-top: 3px solid rgba(0, 0, 0, 0.5);
  border-right: 3px solid transparent;
  border-radius: 50%;
  animation: ${Rotation} 0.8s linear infinite;
`;

export const CenteredLoadingIndicator = ({ hideText = true }: { hideText?: boolean }) => {
  const [showText, setShowText] = useState(false);
  const [counter, setCounter] = useState<null | NodeJS.Timer>(null);

  const loadingTexts = useMemo(
    () => [
      'Your request is in progress, please do not refresh.',
      "We're almost there! Just a moment more.",
      "Hang tight, we're working on it. This won't take long.",
      "We appreciate your patience. We're almost done.",
      "Great things take time. You're in good hands.",
      "We're just finalizing things. Sit back and relax for a moment.",
      "Your request is in progress. It'll be worth the wait.",
      "Hold on, we're almost ready to roll.",
      "We're cooking up something special for you. Stay tuned.",
      "Your patience is our priority. We're almost finished.",
      "Don't worry, it's just a few more seconds.",
      "Your request is on the way. We'll notify you when it's done.",
      "We're on the home stretch. Thanks for waiting!",
      'Sit back, relax, and let us do the heavy lifting.',
    ],
    [],
  );
  const [text, setText] = useState(loadingTexts[0]);

  useEffect(() => {
    let isMounted = true;

    if (!hideText) {
      if (!showText) setTimeout(() => (isMounted ? setShowText(true) : null), 4000);

      if (!counter) {
        setCounter(
          isMounted
            ? setInterval(
                () =>
                  isMounted
                    ? setText(
                        (prev) => loadingTexts[loadingTexts.indexOf(prev) + 1] || loadingTexts[0],
                      )
                    : null,
                8000,
              )
            : null,
        );
      }
    }

    return () => {
      if (counter) setCounter(null);
      if (showText) setShowText(false);
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Spinner />
      {!hideText && showText && <p className="mt-4 text-grey-200 text-center">{text}</p>}
    </Container>
  );
};
