import { useCallback, useEffect, useState } from 'react';
import { AuthClient, PHRDocument, PHRDocumentsClient } from '../../../../../../generated';
import { getTime, httpRequest } from '../../../../../../utils';

interface IProps {
  phrConsultID?: string;
  patientID: string;
}

export default function usePhrDocuments({ phrConsultID, patientID }: IProps) {
  const [phrDocuments, setPhrDocuments] = useState<PHRDocument[]>([]);
  const [isPhrDocumentsLoading, setIsPhrDocumentsLoading] = useState<boolean>(false);

  const getPhrDocumentsByPHRConsultID = useCallback(async () => {
    if (phrConsultID) {
      setIsPhrDocumentsLoading(true);
      try {
        const client = new PHRDocumentsClient(new AuthClient());
        let result = await httpRequest(() => client.byPHRConsultID(patientID, phrConsultID));
        result = result.sort((a, b) => getTime(b.upsertDate) - getTime(a.upsertDate));
        setPhrDocuments(result);
      } catch (err) {
        // setConsultError(err);
      }
      setIsPhrDocumentsLoading(false);
    }
  }, [patientID, phrConsultID]);

  useEffect(() => {
    if (patientID && phrConsultID) {
      getPhrDocumentsByPHRConsultID();
    }
  }, [getPhrDocumentsByPHRConsultID, patientID, phrConsultID]);

  return { phrDocuments, isPhrDocumentsLoading, getPhrDocumentsByPHRConsultID };
}
