import {
  Box,
  Center,
  ModalBody,
  ModalFooter,
  Stack,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { FC } from 'react';

import { PrimaryButton } from '../../routes/heal/patient/BookAppointment/components/common/Buttons';
import { BaseModal, HeaderSection } from '.';
import { PrimaryButtonProps, SecondaryButton } from '../Buttons';

type ConfirmationModalProps = {
  description?: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  modalTitle?: string;
  isModalOpen: boolean;
  onDismissModal?: () => void;
  loading?: boolean;
  children?: React.ReactNode;
  confirmButtonProps?: PrimaryButtonProps;
  hideCancelButton?: boolean;
  buttonContainerProps?: StackProps;
};

const ConfirmationModal: FC<ConfirmationModalProps> = (props) => {
  return (
    <BaseModal isOpen={props.isModalOpen} onDismiss={props.onDismissModal || props.onCancel}>
      <ModalBody flex="1" height="100%">
        <VStack width="100%" height="100%" overflowY="auto" alignItems="stretch">
          <HeaderSection heading={props.modalTitle} noPrevious containerProps={{ mb: '3' }} />
          <Box overflowY="auto" flex="1">
            {props.description ? (
              <Center height="100%" flexGrow={1}>
                <Text color="rgb(0, 3, 16)" fontWeight="semibold" textAlign="center">
                  {props.description}
                </Text>
              </Center>
            ) : (
              props.children
            )}
          </Box>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Stack
          justifyContent={{ base: 'center', sm: 'space-between' }}
          spacing="3"
          direction={{ base: 'column', sm: 'row' }}
          width="100%"
          {...props.buttonContainerProps}
        >
          <PrimaryButton
            isLoading={props.loading}
            onClick={props.onConfirm}
            {...props.confirmButtonProps}
          >
            {props.confirmButtonText || 'Yes'}
          </PrimaryButton>
          {!props.hideCancelButton && (
            <SecondaryButton onClick={props.onCancel}>
              {props.cancelButtonText || 'No'}
            </SecondaryButton>
          )}
        </Stack>
      </ModalFooter>
    </BaseModal>
  );
};

export { ConfirmationModal };
