import { ModalBody, ModalFooter } from '@chakra-ui/react';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { object, string } from 'yup';
import {
  AuthClient,
  CellPhoneValidateDetails,
  PatientsClient,
  SecurityClient,
  VerifyPhoneDetails,
} from '../../generated';
import { getPatient } from '../../store/root-creator';
import { AppState } from '../../store/root-reducers';
import { httpRequest } from '../../utils';
import { ButtonRow } from '../Buttons';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import { SecondaryButton } from '../Buttons/SecondaryButton';
import { Paragraph } from '../common/Paragraph';
import { Form, FormPhoneInputControl, FormPinInputControl } from '../forms';
import { CenteredLoadingIndicator } from '../LoadingIndicator';
import ServerValidationBox from '../ServerValidationBox';
import { BaseModal } from './BaseModal';
interface ICellPhoneForm {
  cellPhoneNumber: string;
}

interface IVerifyTokenPhoneForm {
  token: string;
}

interface IProps {
  isOpen: boolean;
  close: () => void;
}

enum ScreenState {
  PhoneNumberStep = 'PhoneNumberStep',
  VerificationStep = 'VerificationStep',
}

const VerifyMobilePhoneModal = ({ isOpen, close }: IProps) => {
  const dispatch = useDispatch();
  const authClient = new AuthClient();
  const securityClient = new SecurityClient(authClient);
  const patientsClient = new PatientsClient(authClient);
  const patient = useSelector((state: AppState) => state.patientState.patient);
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const [step, setStep] = useState<ScreenState>(ScreenState.PhoneNumberStep);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [cellPhoneNumber, setCellPhoneNumber] = useState<string>('+');
  const [token, setToken] = useState<string>('');
  const [formattedCellPhoneNumber, setFormattedCellPhoneNumber] = useState<string>('');

  const sendSMSCode = async (values: ICellPhoneForm) => {
    try {
      setIsLoading(true);
      setError('');
      const details = new VerifyPhoneDetails();
      details.cellPhoneNumber = values.cellPhoneNumber;
      const result = await httpRequest(() => securityClient.sendSMSCode(details));
      setToken(result);
      setCellPhoneNumber(values.cellPhoneNumber);
      setStep(ScreenState.VerificationStep);
    } catch (err) {
      setError(err as any);
    }
    setIsLoading(false);
  };

  const submitToken = async (values: IVerifyTokenPhoneForm) => {
    try {
      setIsLoading(true);
      setError('');
      const signUpEmailValidate = new CellPhoneValidateDetails();
      signUpEmailValidate.cellPhoneNumber = cellPhoneNumber;
      signUpEmailValidate.token = values.token;
      await httpRequest(() => patientsClient.validateSMSCode(signUpEmailValidate));
      dispatch(getPatient(currentUser!.userDetailID));
      close();
    } catch (err) {
      setError(err as any);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (patient?.cellPhoneNumber) {
      setCellPhoneNumber(patient.cellPhoneNumber);
    }
  }, [patient]);

  const renderBody = () => {
    if (isLoading) {
      return <CenteredLoadingIndicator />;
    } else if (step === ScreenState.PhoneNumberStep) {
      return (
        <Formik
          initialValues={{
            cellPhoneNumber: cellPhoneNumber,
          }}
          validationSchema={object({
            cellPhoneNumber: string().test('required', 'Cell Phone is required.', function (value) {
              if (value && (value.match(/\d/g)?.join('').length ?? 0) < 5) {
                return this.createError({
                  message: `Cell Phone Number must be at least 5 digits.`,
                  path: 'cellPhoneNumber',
                });
              }
              return true;
            }),
          })}
          onSubmit={sendSMSCode}
          enableReinitialize
        >
          {({ submitForm }) => (
            <>
              <ModalBody>
                <Form error={error}>
                  <ServerValidationBox message={error} />
                  <div className="row">
                    <div className="col-12">
                      <FormPhoneInputControl
                        name="cellPhoneNumber"
                        label="Cell Phone Number"
                        country={patient?.country?.code.toLocaleLowerCase()}
                        setFormattedValue={setFormattedCellPhoneNumber}
                      />
                    </div>
                  </div>
                </Form>
              </ModalBody>
              <ModalFooter>
                <ButtonRow>
                  <SecondaryButton
                    disabled={isLoading}
                    title={isLoading ? 'Processing.' : ''}
                    onClick={close}
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton isLoading={isLoading} onClick={submitForm}>
                    Send Code
                  </PrimaryButton>
                </ButtonRow>
              </ModalFooter>
            </>
          )}
        </Formik>
      );
    } else if (step === ScreenState.VerificationStep) {
      return (
        <Formik
          initialValues={{ token: token }}
          validationSchema={object({
            token: string().required('The security code is required.'),
          })}
          onSubmit={submitToken}
          enableReinitialize
        >
          {({ submitForm }) => (
            <>
              <ModalBody>
                <Form error={error}>
                  <ServerValidationBox message={error} />
                  <Paragraph>
                    A text message containing your security code has been sent to:
                  </Paragraph>
                  <FormattedNumber>{formattedCellPhoneNumber}</FormattedNumber>
                  <Paragraph>Please enter it below</Paragraph>
                  <CodeWrapper>
                    <FormPinInputControl name="token" label="" pinLength={6} />
                  </CodeWrapper>
                </Form>
              </ModalBody>
              <ModalFooter>
                <ButtonRow>
                  <SecondaryButton
                    disabled={isLoading}
                    title={isLoading ? 'Processing.' : ''}
                    onClick={() => setStep(ScreenState.PhoneNumberStep)}
                  >
                    Back
                  </SecondaryButton>
                  <PrimaryButton isLoading={isLoading} onClick={submitForm}>
                    Verify
                  </PrimaryButton>
                </ButtonRow>
              </ModalFooter>
            </>
          )}
        </Formik>
      );
    }
  };

  return (
    <BaseModal title="Add and Verify Cell Phone" isOpen={isOpen}>
      {renderBody()}
    </BaseModal>
  );
};

export { VerifyMobilePhoneModal };

const FormattedNumber = styled(Paragraph)`
  font-size: 20px;
  font-weight: 500;
`;

const CodeWrapper = styled.div`
  display: flex;
  justify-content: center;

  > div {
    margin-top: 0;
    margin-bottom: 0;
  }

  input {
    text-align: center;
    letter-spacing: 10px;
    font-size: 20px;
  }
`;
