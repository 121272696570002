import { Box, Flex, ModalBody, Text, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { BackIcon } from '../../assets/icons';
import { OutpostTheme } from '../../themes/outpost';

interface ICustomModalBody {
  children: ReactNode;
  title?: string;
  alt?: boolean;
  onBack?: () => void;
  large?: boolean;
}

export const CustomModalBody = ({ children, onBack, title, alt }: ICustomModalBody) => {
  return (
    <ModalBody height="100%" background="#f2F2f2" overflowY="auto">
      <VStack height="100%" overflowY="auto" alignItems="stretch">
        {onBack ? <BackIcon onClick={onBack} cursor="pointer" boxSize="24px" /> : <Box />}
        <Flex flex="1" direction="column" align="center" justify="center" overflowY="auto">
          {title && (
            <Text
              textTransform="uppercase"
              ml={[-6, 0]}
              mb={8}
              fontSize={OutpostTheme.FontSizes.body}
            >
              {title}
            </Text>
          )}
          <Box
            width={['100%', '90%']}
            background={alt ? 'transparent' : '#FFFFFF'}
            borderRadius="0.25rem"
            overflowY="auto"
            flex="1"
          >
            {children}
          </Box>
        </Flex>
      </VStack>
    </ModalBody>
  );
};
