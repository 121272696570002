import { useState } from 'react';

import { Tag, TagTypeEnum } from '../../../generated';

import {
  serviceIsSelected,
  useServiceTags,
} from '../../heal/patient/BookAppointment/components/BookConsultProvider/useHomeCareServices/useHomeCareServices';

import { BookingFlowEnum } from '../components/PublicSearchComponents/enums';

import { UsePublicBookingFlowReturnType } from './usePublicBookingFlow';

type UseHomeCareServicesProps = UsePublicBookingFlowReturnType;

const useHomeCareServices = (props: UseHomeCareServicesProps) => {
  const { selectedServices, onSelectService, isLoadingTags, tags, tagsError } = useServiceTags(
    TagTypeEnum.HomeCareService,
  );

  const [submitButtonTouched, setSubmitButtonTouched] = useState(false);

  const isServiceSelected = (service: Tag) => !!serviceIsSelected(selectedServices, service);

  const serviceNotSelected = selectedServices.length === 0;

  const cannotSubmit = serviceNotSelected;

  const handleNextStep = () => {
    setSubmitButtonTouched(true);

    if (cannotSubmit) return;

    props.handleUpdateBookingData({
      selectedHomeCareServiceTags: selectedServices,
      bookingFlowStepEnum: BookingFlowEnum.Appointments,
    });
  };

  return {
    handleNextStep,
    selectedServices,
    onSelectService,
    isServiceSelected,
    submitButtonTouched,
    serviceNotSelected,
    cannotSubmit,
    isLoadingTags,
    tags,
    tagsError,
  };
};

export { useHomeCareServices };
