import { useQuery } from 'react-query';
import { QueryKeys } from '../../keys';
import { httpRequest } from '../../../utils';
import { AuthClient, CityTown, CityTownsClient } from '../../../generated';

interface IProps {
  provinceStateID?: string;
}

export const useCityTownsByProvinceStateID = ({ provinceStateID }: IProps) => {
  const {
    isLoading: isLoadingCityTowns,
    data: cityTownsResult,
    error: cityTownsError,
  } = useQuery<CityTown[] | undefined, string>(
    [QueryKeys.CityTownsByProvinceStateID, provinceStateID],
    async () =>
      httpRequest(() => new CityTownsClient(new AuthClient()).byProvinceStateID(provinceStateID!)),
    {
      enabled: !!provinceStateID,
    },
  );
  return { isLoadingCityTowns, cityTownsResult, cityTownsError };
};
