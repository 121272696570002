import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Grid, GridItem, Text } from '@chakra-ui/react';

import { CenteredLoadingIndicator } from '../../../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../../../components/ServerValidationBox';
import { useOrdersAll } from '../../../../../../../services/orders/queries/useOrdersAll';

import useCreateOrderContext from '../../hooks/useCreateOrderContext';
import useTranslationComponent from '../../../../../../../hooks/useTranslationComponent';

interface IProps {
  onOpenLabOptionsModal?: () => void;
}

export const SelectOrderForm = ({ onOpenLabOptionsModal }: IProps) => {
  const { t } = useTranslationComponent(['phr']);
  const { goToNextStep, controller } = useCreateOrderContext();

  const { isLoadingOrders, orders = [], ordersError } = useOrdersAll();

  if (isLoadingOrders) {
    return <CenteredLoadingIndicator />;
  }

  if (ordersError) {
    return <ServerValidationBox message={ordersError} />;
  }

  return (
    <Box>
      <Text color="#6B57E5" textAlign="center" mb="5">
        {t('Create Lab Orders')}
      </Text>
      <Grid gap={6} templateColumns={{ base: '1fr', lg: '1fr 1fr' }} mx={[2, 2, 8]} mb={16}>
        {orders
          .sort((a, b) => a.displaySortOrder - b.displaySortOrder)
          .map((o, index) => (
            <GridItem justifyContent="center" display="flex" key={index}>
              <Button
                width="100%"
                backgroundColor="white"
                border="1px solid #6B57E5"
                maxW="480px"
                h="60px"
                borderRadius="lg"
                onClick={async () => {
                  onOpenLabOptionsModal?.();
                  controller.handleUpdateSelectedOrder(o);
                  goToNextStep();
                }}
                _hover={{
                  bgColor: '#6B57E5',
                  color: 'white',
                }}
              >
                <Flex justifyContent="space-between" alignItems="center" w="100%" px={{ md: 3 }}>
                  <Text fontSize={['14px', '16px']}>{o.name.split('Order')}</Text>
                  <ChevronRightIcon fontSize="22px" />
                </Flex>
              </Button>
            </GridItem>
          ))}
      </Grid>
    </Box>
  );
};
