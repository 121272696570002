import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { AppVersionActionTypes, appVersionMissmatchAction } from './appVersionActions';

const showMissmatchModal = createReducer<boolean, AppVersionActionTypes>(false).handleAction(
  [appVersionMissmatchAction],
  () => true,
);

export interface IAppVersionState {
  showMissmatchModal: boolean;
}

export default combineReducers<IAppVersionState>({
  showMissmatchModal,
});
