import styled from 'styled-components';
import { TextArea } from '../../../../../../components/forms';
import { BorderRadius, Device } from '../../../../../../constants';

interface IFormNameProps {
  readable: boolean;
}

export const HeaderNote = styled.p`
  margin: 0.5rem 0;
  font-size: 14px;
  color: ${(props) => props.theme.FontColors.Secondary};
`;

export const LargeStyledTextArea = styled(TextArea)`
  textarea {
    height: 4em;
    resize: vertical;

    @media ${Device.laptopL} {
      height: 6em;
    }
  }
`;

export const SmallStyledTextArea = styled(TextArea)`
  textarea {
    height: 3em;
    resize: vertical;

    @media ${Device.laptopL} {
      height: 3em;
    }
  }
`;

export const ComplaintTag = styled.div`
  margin-right: 5px;
  border-radius: ${BorderRadius.Regular};
  background-color: rgba(55, 70, 89, 0.25);
  color: ${(props) => props.theme.Colors.Primary};
  font-weight: 500;
  padding: 2px 6px;
  font-size: 12px;
  margin-bottom: 5px;
`;

export const ComplaintTags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const IntakeForms = styled.div`
  margin-top: 6px;
  margin-left: 15px;
`;

export const FormInfo = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

export const FormName = styled.p<IFormNameProps>`
  color: ${(props) => (props.readable ? props.theme.Colors.Primary : props.theme.Colors.Gray)};
  margin-right: 8px;
  &:hover {
    ${(props) => props.readable && 'cursor: pointer;'};
    ${(props) => props.readable && 'font-weight: 500;'};
  }
`;

export const FormStatus = styled.p`
  color: ${(props) => props.theme.Colors.Gray};
  font-size: 0.8rem;
  margin-right: 4px;
`;
