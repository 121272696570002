import { Box, Center, Heading, Stack, Text } from '@chakra-ui/react';
import { push } from 'connected-react-router';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ButtonRow, PrimaryButton } from '../../../../../components/Buttons';
import { FileUploader } from '../../../../../components/FileUploader/FileUploader';
import {
  FileUploadStatusEnum,
  IFileUpload,
} from '../../../../../components/FileUploader/useFileUploader/useFileUploader';
import CenteredAnimatedCheckmark from '../../../../../components/presentation/AnimatedCheckmark';
import { FileDetail, InventoryLoaderDetails } from '../../../../../generated';
import { useInventorySubmitInventoryLoader } from '../../../../../services/inventory/mutations/useInventorySubmitInventoryLoader';

export const StoreManagerView = () => {
  const dispatch = useDispatch();
  const [productFiles, setProductFiles] = useState<IFileUpload[]>([]);
  const [inventoryFiles, setInventoryFiles] = useState<IFileUpload[]>([]);
  const submitInventory = useInventorySubmitInventoryLoader();
  const [isSubmitted, setSubmitted] = useState<boolean>(false);

  const canSubmit =
    !!productFiles.find((x) => x.uploadStatus === FileUploadStatusEnum.Completed) &&
    !!inventoryFiles.find((x) => x.uploadStatus === FileUploadStatusEnum.Completed);

  const onSubmit = async () => {
    if (canSubmit) {
      try {
        const inventoryLoaderDetails = new InventoryLoaderDetails();
        inventoryLoaderDetails.inventoryDocumentID = inventoryFiles[0].id;
        inventoryLoaderDetails.productDocumentID = productFiles[0].id;
        await submitInventory.mutateAsync(inventoryLoaderDetails);
        setSubmitted(true);
      } catch (err) {
        // Eat
      }
    }
  };

  if (isSubmitted) {
    return (
      <Center>
        <Stack spacing={8}>
          <CenteredAnimatedCheckmark />
          <Text>
            Your Product List and Inventory List has been submitted and will be processed. You will
            be notified via email once it has been completed.
          </Text>
          <ButtonRow>
            <PrimaryButton onClick={() => dispatch(push('/'))}>Return to Dashboard</PrimaryButton>
          </ButtonRow>
        </Stack>
      </Center>
    );
  }

  return (
    <>
      <Center>
        <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
          <Box
            p={6}
            border="1px dashed"
            borderColor="gray.100"
            backgroundColor="white"
            borderRadius="0.5rem"
          >
            <Heading size="sm">Product List</Heading>
            <FileUploader
              instructions="Upload Product list as xls"
              uploadUrl="/api/v1/inventory/upload"
              files={productFiles}
              setFiles={setProductFiles}
              getRequestBody={(fileDetail: FileDetail) => fileDetail}
              deleteFile={() => {}}
              dropzoneOptions={{
                multiple: false,
              }}
            />
          </Box>
          <Box
            p={6}
            border="1px dashed"
            borderColor="gray.100"
            backgroundColor="white"
            borderRadius="0.5rem"
          >
            <Heading size="sm">Inventory List</Heading>
            <FileUploader
              instructions="Upload Inventory list as xls"
              uploadUrl="/api/v1/inventory/upload"
              files={inventoryFiles}
              setFiles={setInventoryFiles}
              getRequestBody={(fileDetail: FileDetail) => fileDetail}
              deleteFile={() => {}}
              dropzoneOptions={{
                multiple: false,
              }}
            />
          </Box>
        </Stack>
      </Center>
      <ButtonRow>
        <PrimaryButton
          isDisabled={!canSubmit}
          onClick={onSubmit}
          isLoading={submitInventory.isLoading}
        >
          Submit Inventory
        </PrimaryButton>
      </ButtonRow>
    </>
  );
};
