import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ISendTo } from '../../../../../components/AddressBook/constants';
import { ISendInfo } from '../../../../../constants';
import {
  AuthClient,
  Medication,
  PatientPrescription,
  PatientPrescriptionFinalizeDetails,
  PatientPrescriptionsClient,
  PatientsClient,
  PHRMedication,
  PrescriptionStatusEnum,
  SendToEnum,
} from '../../../../../generated';
import { getAllForPhrSection } from '../../../../../store/root-creator';
import { AppState } from '../../../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../../../store/types';
import { httpRequest } from '../../../../../utils';

interface IProps {
  prescription: PatientPrescription | null;
  phrConsultID?: string;
  patientID: string;
  onClose: () => void;
  onQuickMenuClose?: () => void;
  setPrescription: (prescription: PatientPrescription | null) => void;
  countryID: string;
  onOpenNotificationModal?: (content: ReactElement | string[]) => void;
}

const usePrescriptionOrder = ({
  prescription,
  phrConsultID,
  patientID,
  onClose,
  setPrescription,
  countryID,
  onQuickMenuClose,
  onOpenNotificationModal,
}: IProps) => {
  const dispatch = useDispatch();
  const provider = useSelector((state: AppState) => state.providerState.provider!);
  const [selectedMedication, setSelectedMedication] = useState<PHRMedication | null>(null);
  const [showSummary, setShowSummary] = useState<boolean>(false);
  const [prescriptionError, setPrescriptionError] = useState<string>('');
  const [recordToDelete, setRecordToDelete] = useState<string>('');
  const [isFinalizing, setFinalizing] = useState<boolean>(false);
  const [isFinalizingPrescription, setFinalizingPrescription] = useState<boolean>(false);
  const [finalizingPrescriptionError, setFinalizingPrescriptionError] = useState<string>('');
  const [deliveryOptionError, setDeliveryOptionError] = useState<string>('');
  const [isUpdatingPrescription, setUpdatingPrescription] = useState<boolean>(false);
  const [sendToError, setSendToError] = useState<string>('');
  const [showAddressBook, setShowAddressBook] = useState<boolean>(false);
  const [sendTo, setSendTo] = useState<ISendTo>();

  useEffect(() => {
    if (showSummary) {
      setSelectedMedication(null);
    }
  }, [showSummary]);

  const addMedication = async (baseMedication: Medication | null, medication?: PHRMedication) => {
    setUpdatingPrescription(true);
    const prescriptionsClient = new PatientPrescriptionsClient(new AuthClient());
    const patientsClient = new PatientsClient(new AuthClient());
    let prescriptionID = prescription?.patientPrescriptionID;
    if (!prescription) {
      try {
        const patientPrescription = new PatientPrescription();
        patientPrescription.countryID = countryID;
        patientPrescription.addSignature = true;
        patientPrescription.patientID = patientID;
        patientPrescription.phrConsultID = phrConsultID;
        patientPrescription.providerID = provider.providerID;
        patientPrescription.prescriptionStatusEnum = PrescriptionStatusEnum.Draft;
        patientPrescription.phrMedications = medication ? [medication] : undefined;
        const patient = await httpRequest(() => patientsClient.patientsGet(patientID));
        patientPrescription.customerAddressLine1 = patient.addressLine1;
        patientPrescription.customerAddressLine2 = patient.addressLine2;
        patientPrescription.customerCity = patient.cityTown ? patient.cityTown.name : patient.city;
        patientPrescription.customerCountry = patient.country?.name;
        patientPrescription.customerEmailAddress = patient.emailAddress;
        patientPrescription.customerName = patient.fullName;
        patientPrescription.customerPhone = patient.cellPhoneNumber || patient.homePhoneNumber;
        patientPrescription.customerPostCode = patient.postCode;
        patientPrescription.customerProvince = patient.provinceState
          ? patient.provinceState.name
          : patient.province;
        prescriptionID = await httpRequest(() =>
          prescriptionsClient.patientPrescriptionsPost(patientPrescription),
        );
      } catch (err) {
        setPrescriptionError(err as string);
      }
    } else {
      if (!medication) return;
      try {
        medication.patientPrescriptionID = prescription.patientPrescriptionID;
        const phrMedications = [...(prescription.phrMedications ?? []), medication];
        const patientPrescription = new PatientPrescription();
        patientPrescription.init(prescription);
        patientPrescription.phrMedications = phrMedications;
        await httpRequest(() => prescriptionsClient.patientPrescriptionsPut(patientPrescription));
      } catch (err) {
        setPrescriptionError(err as string);
      }
    }

    try {
      const patientPrescription = await httpRequest(() =>
        prescriptionsClient.patientPrescriptionsGet(prescriptionID!),
      );
      setPrescription(patientPrescription);
      setSelectedMedication(null);
    } catch (err) {
      setPrescriptionError(err as string);
    }
    setUpdatingPrescription(false);
  };

  const removeMedication = async (phrMedicationID: string) => {
    setUpdatingPrescription(true);
    const prescriptionsClient = new PatientPrescriptionsClient(new AuthClient());
    try {
      const patientPrescription = new PatientPrescription();
      patientPrescription.init(prescription);
      patientPrescription.phrMedications = [
        ...(patientPrescription.phrMedications ?? []).map((x) => {
          x.isDeleted = x.phrMedicationID === phrMedicationID;
          return x;
        }),
      ];
      await httpRequest(() => prescriptionsClient.patientPrescriptionsPut(patientPrescription));
      patientPrescription.phrMedications = [
        ...(patientPrescription.phrMedications ?? []).filter((x) => !x.isDeleted),
      ];
      setPrescription(patientPrescription);
    } catch (err) {
      setPrescriptionError(err as string);
    }
    setUpdatingPrescription(false);
  };

  const setPrescriptionSendInfo = useCallback(
    async (sendInfo?: ISendInfo, storeID?: string) => {
      setUpdatingPrescription(true);
      try {
        if (sendInfo && prescription) {
          const prescriptionsClient = new PatientPrescriptionsClient(new AuthClient());
          const patientPrescription = new PatientPrescription();
          patientPrescription.init(prescription);
          patientPrescription.sendSubject = sendInfo.sendSubject;
          patientPrescription.sendMessage = sendInfo.sendMessage;
          patientPrescription.sendToID = sendInfo.sendToID;
          patientPrescription.sendToEnum = sendInfo.sendToEnum;
          patientPrescription.sendCopyToPatient = sendInfo.sendCopyToPatient || false;
          patientPrescription.storeID = storeID;
          patientPrescription.patient = undefined;
          patientPrescription.provider = undefined;
          patientPrescription.store = undefined;
          patientPrescription.country = undefined;
          patientPrescription.invoice = undefined;

          await httpRequest(() => prescriptionsClient.patientPrescriptionsPut(patientPrescription));
          // refetch to calculate total amount
          setPrescription(
            await httpRequest(() =>
              prescriptionsClient.patientPrescriptionsGet(
                patientPrescription.patientPrescriptionID,
              ),
            ),
          );
        }
      } catch (err) {
        setPrescriptionError(err as string);
      }
      setUpdatingPrescription(false);
    },
    [prescription, setPrescription],
  );

  const finalizePrescription = async (
    hasUnavailableItems: boolean,
    sendUnavailableItemsToPatient?: boolean,
  ) => {
    if (!sendTo) {
      setSendToError('You need to specify who to send the Prescription to');
    } else if (!prescription?.sendToID || prescription?.sendToEnum === SendToEnum.DoNotSend) {
      setSendToError('You need to specify a Delivery option');
    } else {
      setFinalizingPrescription(true);
      try {
        const client = new PatientPrescriptionsClient(new AuthClient());
        const finalizeDetails = new PatientPrescriptionFinalizeDetails();
        if (hasUnavailableItems) {
          finalizeDetails.sendMessage = prescription?.sendMessage;
          finalizeDetails.sendToEnum = SendToEnum.UserDetail;
          finalizeDetails.sendSubject = prescription?.sendSubject;
          finalizeDetails.sendCopyToPatient = prescription.sendCopyToPatient;
          if (sendUnavailableItemsToPatient) {
            finalizeDetails.sendToID = prescription?.patient?.userDetailID;
          } else {
            // Pharmacist
            finalizeDetails.sendToID = prescription.sendToID;
          }
        }
        finalizeDetails.patientID = prescription?.patientID;
        finalizeDetails.patientPrescriptionIDs = [prescription!.patientPrescriptionID];
        const result = await httpRequest(() => client.finalize(finalizeDetails));

        const notificationMessages: string[] = [];

        (result.patientPrescriptionFinalizeResultDetails ?? []).forEach((x) => {
          if (x.message) {
            notificationMessages.push(x.message);
          }
        });

        setPrescription(null);
        dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRMedications, prescription?.patientID));
        onClose();
        onQuickMenuClose?.();
        onOpenNotificationModal?.(notificationMessages);
      } catch (err) {
        setFinalizingPrescriptionError(err as string);
      }

      setFinalizingPrescription(false);
    }
  };

  const refreshPrescription = async (patientPrescriptionID: string) => {
    setPrescription(
      await httpRequest(() =>
        new PatientPrescriptionsClient(new AuthClient()).patientPrescriptionsGet(
          patientPrescriptionID,
        ),
      ),
    );
  };

  const clearDeliveryInfo = async () => {
    const patientPrescription = new PatientPrescription();
    patientPrescription.init(prescription);
    patientPrescription.sendSubject = undefined;
    patientPrescription.sendToID = undefined;
    patientPrescription.storeID = undefined;
    patientPrescription.store = undefined;
    patientPrescription.sendToEnum = SendToEnum.DoNotSend;
    patientPrescription.sendCopyToPatient = false;
    setSendTo(undefined);
    setPrescription(null);
  };

  const updateSendToEnum = async (sendToEnum: SendToEnum | null) => {
    const patientPrescription = new PatientPrescription();
    patientPrescription.init(prescription);
    patientPrescription.sendToEnum = sendToEnum ?? SendToEnum.DoNotSend;
    if (patientPrescription.sendToEnum !== SendToEnum.DoNotSend) {
      setDeliveryOptionError('');
    }
    setPrescription(patientPrescription);
  };

  return {
    addMedication,
    removeMedication,
    selectedMedication,
    setSelectedMedication,
    showSummary,
    setShowSummary,
    prescriptionError,
    recordToDelete,
    setRecordToDelete,
    setPrescriptionSendInfo,
    isFinalizing,
    setFinalizing,
    finalizePrescription,
    isFinalizingPrescription,
    finalizingPrescriptionError,
    clearDeliveryInfo,
    updateSendToEnum,
    deliveryOptionError,
    isUpdatingPrescription,
    sendToError,
    showAddressBook,
    setShowAddressBook,
    sendTo,
    setSendTo,
    refreshPrescription,
  };
};

export default usePrescriptionOrder;
