import moment from 'moment';
import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import Card from '../../../../../components/Card';
import { CHART_PLUGINS, TimeFormatEnum } from '../../../../../constants';
import { PHRTrackerAnxietyDTO } from '../../../../../generated';
import { OutpostTheme } from '../../../../../themes/outpost';
import { getTime } from '../../../../../utils';
import {
  ChartButtonRow,
  ChartContainer,
  PrimaryButtonSelect,
} from '../../../components/StyledPhrItems';
import { getAnxietyStatusByValue } from '../hooks/useTrackers';

interface IProps {
  dataSet: PHRTrackerAnxietyDTO[];
}
const AnxietyChart: React.FC<IProps> = ({ dataSet }) => {
  const [daysRange, setDaysRange] = useState<number>(14);

  const data = () => {
    const sorted = [...dataSet].sort((a, b) => getTime(a.trackerDate) - getTime(b.trackerDate));

    return {
      labels: [...sorted.map((x) => moment(x.trackerDate).format(TimeFormatEnum.LONG_DATE))],
      datasets: [
        {
          label: 'Anxiety Value',
          data: sorted.map((d) => ({ x: d.trackerDate, y: d.anxietyValue })),
          fill: false,
          borderColor: OutpostTheme.Colors.DarkBlue,
          borderWidth: 2,
        },
      ],
    };
  };

  const options = {
    legend: {
      position: 'bottom',
    },
    scales: {
      yAxes: [
        {
          type: 'linear',
          ticks: {
            suggestedMin: 0,
            suggestedMax: 6,
            callback: getAnxietyStatusByValue,
          },
        },
      ],
      xAxes: [
        {
          type: 'time',
          position: 'bottom',
          distribution: 'linear',
          time: {
            unit: daysRange === 14 ? 'day' : 'month',
          },
          ticks: {
            max: moment(),
            min: moment().subtract(daysRange, 'd'),
          },
        },
      ],
    },
    plugins: CHART_PLUGINS,
    maintainAspectRatio: false,
  };

  return (
    <Card>
      <ChartButtonRow>
        <div>
          <PrimaryButtonSelect active={daysRange === 365} onClick={() => setDaysRange(365)}>
            Year
          </PrimaryButtonSelect>
          <PrimaryButtonSelect active={daysRange === 90} onClick={() => setDaysRange(90)}>
            3 Months
          </PrimaryButtonSelect>
          <PrimaryButtonSelect active={daysRange === 14} onClick={() => setDaysRange(14)}>
            14 Days
          </PrimaryButtonSelect>
        </div>
      </ChartButtonRow>
      <ChartContainer>
        {/* @ts-ignore */}
        <Line data={data} options={options} />
      </ChartContainer>
    </Card>
  );
};

export default AnxietyChart;
