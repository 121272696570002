import { useQuery } from 'react-query';
import {
  AuthClient,
  OrderStatusEnum,
  PatientOrdersClient,
  PatientOrderSearchDetails,
  PatientOrderSearchResults,
} from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

interface IProps {
  patientID?: string | undefined;
  qrCodeOrPatientUniqueLifetimeID?: number | undefined;
  orderStatusEnum?: OrderStatusEnum | undefined;
  taskItemStatusEnums?: string[] | undefined;
}

export const usePatientOrdersSearch = ({
  patientID,
  qrCodeOrPatientUniqueLifetimeID,
  orderStatusEnum,
  taskItemStatusEnums,
}: IProps) => {
  const {
    isLoading: isLoadingOrders,
    data: patientOrdersSearchResults,
    error: errorOrders,
    isFetching: isFetchingOrders,
    refetch: refetchOrders,
  } = useQuery<PatientOrderSearchResults, string>(
    [
      QueryKeys.PatientOrdersSearch,
      { patientID, qrCodeOrPatientUniqueLifetimeID, orderStatusEnum, taskItemStatusEnums },
    ],
    async () => {
      const patientOrderDetails = new PatientOrderSearchDetails();
      patientOrderDetails.patientID = patientID;
      patientOrderDetails.qrCodeOrPatientUniqueLifetimeID = qrCodeOrPatientUniqueLifetimeID;
      patientOrderDetails.orderStatusEnum = orderStatusEnum;
      patientOrderDetails.taskItemStatusEnums = taskItemStatusEnums;
      return httpRequest(() =>
        new PatientOrdersClient(new AuthClient()).search(patientOrderDetails!),
      );
    },
    {
      enabled: !!(
        patientID ||
        qrCodeOrPatientUniqueLifetimeID ||
        orderStatusEnum ||
        taskItemStatusEnums
      ),
    },
  );

  return {
    isLoadingOrders,
    patientOrdersSearchResults,
    errorOrders,
    isFetchingOrders,
    refetchOrders,
  };
};
