import moment from 'moment';
import { createSelector } from 'reselect';
import {
  PHRConsult,
  PHRDocument,
  PHRFamilyHistory,
  PHRHealthCondition,
  PHRHealthJournal,
  PHRMedication,
  PHRNote,
  PHRSurgeryHistory,
  PHRTracker,
  PHRTrackerBloodGlucoseDTO,
  PHRTrackerBloodOxygenDTO,
  PHRTrackerBloodPressureDTO,
  PHRTrackerChinkunguniaDTO,
  PHRTrackerCholesterolDTO,
  PHRTrackerDengueDTO,
  PHRTrackerFetalDopplerDTO,
  PHRTrackerHCVDTO,
  PHRTrackerHeadCircumferenceDTO,
  PHRTrackerHemoglobinDTO,
  PHRTrackerHIVDTO,
  PHRTrackerLengthDTO,
  PHRTrackerMalariaDTO,
  PHRTrackerMoodDTO,
  PHRTrackerAnxietyDTO,
  PHRTrackerStressDTO,
  PHRTrackerSleepDTO,
  PHRTrackerPulseDTO,
  PHRTrackerRapidPregnancyDTO,
  PHRTrackerRespiratoryDTO,
  PHRTrackerStepCounterDTO,
  PHRTrackerSyphilisDTO,
  PHRTrackerTemperatureDTO,
  PHRTrackerTroponinDTO,
  PHRTrackerTyphoidDTO,
  PHRTrackerUricAcidDTO,
  PHRTrackerWaistCircumferenceDTO,
  PHRTrackerWeightHeightDTO,
  PrescriptionStatusEnum,
  PHRVaccination,
} from '../../../generated';
import { getTime } from '../../../utils';

export const phrHealthConditionsSelector = createSelector(
  (phrHealthConditions: PHRHealthCondition[] | undefined) => phrHealthConditions ?? [],
  (healthConditions) => {
    return healthConditions.sort(
      (a, b) =>
        getTime(b.diagnosedStartDate) - getTime(a.diagnosedStartDate) ||
        getTime(b.upsertDate) - getTime(a.upsertDate),
    );
  },
);

export const phrVaccinationsSelector = createSelector(
  (phrVaccinations: PHRVaccination[] | undefined) => phrVaccinations ?? [],
  (vaccinations) => {
    return vaccinations.sort((a, b) => getTime(b.vaccinationDate) - getTime(a.vaccinationDate));
  },
);

export const phrSurgeryHistoriesSelector = createSelector(
  (phrSurgeryHistories: PHRSurgeryHistory[] | undefined) => phrSurgeryHistories ?? [],
  (phrSurgeryHistories) =>
    phrSurgeryHistories.sort(
      (a, b) =>
        getTime(b.surgeryDate) - getTime(a.surgeryDate) ||
        getTime(b.upsertDate) - getTime(a.upsertDate),
    ),
);

export const phrMedicationsSelector = createSelector(
  (phrMedications: PHRMedication[] | undefined) => phrMedications ?? [],
  (phrMedications) =>
    phrMedications
      .filter(
        (x) =>
          x.prescriptionStatusEnum !== PrescriptionStatusEnum.Draft &&
          x.prescriptionStatusEnum !== PrescriptionStatusEnum.Pending,
      )
      .sort(
        (a, b) =>
          getTime(b.startDate) - getTime(a.startDate) ||
          getTime(b.upsertDate) - getTime(a.upsertDate),
      ),
);

export const pastMedicationSelector = createSelector(
  (phrMedications: PHRMedication[] | undefined) => phrMedications ?? [],
  (phrMedications) =>
    phrMedicationsSelector(
      phrMedications.filter(
        (x) =>
          x.isHeadMedication &&
          !!x.endDate &&
          moment(x.endDate).isBefore(moment()) &&
          x.prescriptionStatusEnum !== PrescriptionStatusEnum.Draft &&
          x.prescriptionStatusEnum !== PrescriptionStatusEnum.Pending,
      ),
    ),
);

export const activeMedicationSelector = createSelector(
  (phrMedications: PHRMedication[] | undefined) => phrMedications ?? [],
  (phrMedications) =>
    phrMedicationsSelector(
      phrMedications.filter(
        (x) => x.isHeadMedication && (!x.endDate || moment(x.endDate).isAfter(moment())),
      ),
    ),
);

export const pendingMedicationsSelector = createSelector(
  (phrMedications: PHRMedication[] | undefined) => phrMedications ?? [],
  (phrMedications) =>
    phrMedications.filter(
      (x) =>
        x.prescriptionStatusEnum === PrescriptionStatusEnum.Pending ||
        x.prescriptionStatusEnum === PrescriptionStatusEnum.Processing,
    ),
);

export const phrFamilyHistoriesSelector = createSelector(
  (phrFamilyHistories: PHRFamilyHistory[] | undefined) => phrFamilyHistories ?? [],
  (familyHistories) =>
    familyHistories.sort((a, b) => getTime(b.upsertDate) - getTime(a.upsertDate)),
);

export const phrDocumentsSelector = createSelector(
  (phrDocuments: PHRDocument[] | undefined) => phrDocuments ?? [],
  (documents) => documents.sort((a, b) => getTime(b.upsertDate) - getTime(a.upsertDate)),
);

export const phrConsultsSelector = createSelector(
  (phrConsults: PHRConsult[] | undefined) => phrConsults ?? [],
  (phrConsults) =>
    phrConsults.sort(
      (a, b) =>
        getTime(b.consultDate) - getTime(a.consultDate) ||
        getTime(b.createdDate) - getTime(a.createdDate),
    ),
);

export const phrBloodPressuresSelector = createSelector(
  (phrBloodPressures: PHRTrackerBloodPressureDTO[] | undefined) => phrBloodPressures ?? [],
  (phrBloodPressures) =>
    phrBloodPressures.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrPulsesSelector = createSelector(
  (phrPulses: PHRTrackerPulseDTO[] | undefined) => phrPulses ?? [],
  (phrPulses) => phrPulses.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrWeightHeightsSelector = createSelector(
  (phrWeightHeights: PHRTrackerWeightHeightDTO[] | undefined) => phrWeightHeights ?? [],
  (phrWeightHeights) =>
    phrWeightHeights.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrBloodGlucosesSelector = createSelector(
  (phrBloodGlucoses: PHRTrackerBloodGlucoseDTO[] | undefined) => phrBloodGlucoses ?? [],
  (phrBloodGlucoses) =>
    phrBloodGlucoses.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrBloodOxygensSelector = createSelector(
  (phrBloodOxygens: PHRTrackerBloodOxygenDTO[] | undefined) => phrBloodOxygens ?? [],
  (phrBloodOxygens) =>
    phrBloodOxygens.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrRespiratoriesSelector = createSelector(
  (phrRespiratories: PHRTrackerRespiratoryDTO[] | undefined) => phrRespiratories ?? [],
  (phrRespiratories) =>
    phrRespiratories.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrTemperaturesSelector = createSelector(
  (phrTemperatures: PHRTrackerTemperatureDTO[] | undefined) => phrTemperatures ?? [],
  (phrTemperatures) =>
    phrTemperatures.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrNotesSelector = createSelector(
  (phrNotes: PHRNote[] | undefined) => phrNotes ?? [],
  (phrNotes) => phrNotes.sort((a, b) => getTime(b.createdDate) - getTime(a.createdDate)),
);

// const phrNotesByConsultIDSelector = createSelector(
//   (state: AppState) => state.phrPatientState.phrPatient?.phrNotes ?? [],
//   phrNotes => phrConsultID => phrNotes.filter(x => x.phrConsultID === phrConsultID),
// );

// const expensiveFilter = phrNotesByConsultIDSelector(state)

export const phrStepCounterSelector = createSelector(
  (phrStepCounters: PHRTrackerStepCounterDTO[] | undefined) => phrStepCounters ?? [],
  (phrStepCounters) =>
    phrStepCounters.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrHCVsSelector = createSelector(
  (phrHCVs: PHRTrackerHCVDTO[] | undefined) => phrHCVs ?? [],
  (phrHVCs) => phrHVCs.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrUricAcidsSelector = createSelector(
  (phrUricAcids: PHRTrackerUricAcidDTO[] | undefined) => phrUricAcids ?? [],
  (phrUricAcids) => phrUricAcids.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrHemoglobinsSelector = createSelector(
  (phrHemoglobins: PHRTrackerHemoglobinDTO[] | undefined) => phrHemoglobins ?? [],
  (phrHemoglobins) =>
    phrHemoglobins.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrCholesterolSelector = createSelector(
  (phrCholesterols: PHRTrackerCholesterolDTO[] | undefined) => phrCholesterols ?? [],
  (phrCholesterols) =>
    phrCholesterols.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrFetalDopplersSelector = createSelector(
  (phrFetalDopplers: PHRTrackerFetalDopplerDTO[] | undefined) => phrFetalDopplers ?? [],
  (phrFetalDopplers) =>
    phrFetalDopplers.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrTyphoidsSelector = createSelector(
  (phrTyphoids: PHRTrackerTyphoidDTO[] | undefined) => phrTyphoids ?? [],
  (phrTyphoids) => phrTyphoids.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrDenguesSelector = createSelector(
  (phrDengues: PHRTrackerDengueDTO[] | undefined) => phrDengues ?? [],
  (phrDengues) => phrDengues.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrMalariasSelector = createSelector(
  (phrMalarias: PHRTrackerMalariaDTO[] | undefined) => phrMalarias ?? [],
  (phrMalarias) => phrMalarias.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrChinkunguniasSelector = createSelector(
  (phrChinkungunias: PHRTrackerChinkunguniaDTO[] | undefined) => phrChinkungunias ?? [],
  (phrChinkungunias) =>
    phrChinkungunias.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrTroponinsSelector = createSelector(
  (phrTroponins: PHRTrackerTroponinDTO[] | undefined) => phrTroponins ?? [],
  (phrTroponins) => phrTroponins.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrSyphilisesSelector = createSelector(
  (phrSyphilises: PHRTrackerSyphilisDTO[] | undefined) => phrSyphilises ?? [],
  (phrSyphilises) => phrSyphilises.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrHIVsSelector = createSelector(
  (phrHIVs: PHRTrackerHIVDTO[] | undefined) => phrHIVs ?? [],
  (phrHIVs) => phrHIVs.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrRapidPregnanciesSelector = createSelector(
  (phrRapidPregnancies: PHRTrackerRapidPregnancyDTO[] | undefined) => phrRapidPregnancies ?? [],
  (phrRapidPregnancies) =>
    phrRapidPregnancies.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrTrackerSelector = createSelector(
  (phrTrackers: PHRTracker[] | undefined) => phrTrackers ?? [],
  (phrTrackers) => phrTrackers.sort((a, b) => a.name.localeCompare(b.name)),
);

export const phrHeadCircumferencesSelector = createSelector(
  (phrHeadCircumferences: PHRTrackerHeadCircumferenceDTO[] | undefined) =>
    phrHeadCircumferences ?? [],
  (phrHeadCircumferences) =>
    phrHeadCircumferences.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrWaistCircumferencesSelector = createSelector(
  (phrWaistCircumferences: PHRTrackerWaistCircumferenceDTO[] | undefined) =>
    phrWaistCircumferences ?? [],
  (phrWaistCircumferences) =>
    phrWaistCircumferences.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrHealthJournalsSelector = createSelector(
  (phrHealthJournals: PHRHealthJournal[] | undefined) => phrHealthJournals ?? [],
  (phrHealthJournals) =>
    phrHealthJournals.sort((a, b) => getTime(b.createdDate) - getTime(a.createdDate)),
);

export const phrLengthsSelector = createSelector(
  (phrLengths: PHRTrackerLengthDTO[] | undefined) => phrLengths ?? [],
  (phrLengths) => phrLengths.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrMoodsSelector = createSelector(
  (phrMood: PHRTrackerMoodDTO[] | undefined) => phrMood ?? [],
  (phrLengths) => phrLengths.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrAnxietiesSelector = createSelector(
  (phrAnxiety: PHRTrackerAnxietyDTO[] | undefined) => phrAnxiety ?? [],
  (phrLengths) => phrLengths.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrStressesSelector = createSelector(
  (phrStress: PHRTrackerStressDTO[] | undefined) => phrStress ?? [],
  (phrLengths) => phrLengths.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);

export const phrSleepsSelector = createSelector(
  (phrSleep: PHRTrackerSleepDTO[] | undefined) => phrSleep ?? [],
  (phrLengths) => phrLengths.sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate)),
);
