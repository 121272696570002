import {
  Box,
  chakra,
  Flex,
  IconButton,
  SlideFade,
  Text,
  useBreakpointValue,
  useMediaQuery,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import { XCloseIcon, PlusIconBig } from '../../../assets/icons';
import { FloatingActionButton } from '../../../components/Buttons';
import { ModalBackButton } from '../../../components/Buttons/ModalBackButton';
import SkeletonLine from '../../../components/Skeleton/SkeletonLine';
import { Device } from '../../../constants';
import { IPHRSummaryDTO, IUserDetailSetting, PatientAccessEnum } from '../../../generated';
import { IPHRPatientCombined } from '../../../store/patient/phr/phrReducers';
import PatientInformationCard from '../../heal/provider/MyPatients/PatientInformationCard';
import PHRAllergiesComponent from '../allergies/Allergies';
import PHRConsultsComponent from '../consults/Consults';
import CovidVaccinations from '../covid/Covid';
import PHRDocumentsComponent from '../documents/Documents';
import FamilyHistoryComponent from '../familyHistory/FamilyHistoriesComponent';
import HealthConditionsComponent from '../healthConditions/HealthConditions';
import MedicationsComponent from '../medications/Medication';
import PHRNotesComponent from '../notes/Notes';
import PHRSurgeryHistoryComponent from '../surgeryHistory/SurgeryHistory';
import PHRHealthJournalComponent from '../healthJournal/HealthJournal';
import PhrTrackersDashboard from '../trackers/PhrTrackersDashboard/PhrTrackersDashboard';
import Vaccinations from '../vaccinations/Vaccinations';
import PatientVitals from '../vitals/PatientVitals';
import { EmptyCategoryCard, StyledCard } from './StyledPhrItems';
import useTranslationComponent from '../../../hooks/useTranslationComponent';

const PhrBody = styled.div`
  flex: 1 0 0;
  min-width: 0;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const SwipeableBody = chakra(PhrBody);
const ChakraSlideFade = chakra(SlideFade);

const PhrEmptyBody = styled(PhrBody)`
  display: none;
  background: #f4f4f4;
  @media ${Device.laptop} {
    display: block;
  }
`;

interface IProps {
  phrPatient: IPHRPatientCombined;
  currentCategory: IPHRSummaryDTO | null;
  back: () => void;
  subnavCurrentCategory: string | null;
  setSubnavCurrentCategory: (x: string | null) => void;
  currentSubNavCategoryID: string | null;
  setCurrentSubNavCategoryID: (x: string | null) => void;
  userDetailSettings: IUserDetailSetting[];
  onDismiss?: () => void;
}

const PhrBodyComponent = ({
  phrPatient,
  currentCategory,
  back,
  subnavCurrentCategory,
  setCurrentSubNavCategoryID,
  onDismiss,
}: IProps) => {
  const { t } = useTranslationComponent(['phr']);
  const [hideButton] = useMediaQuery(Device.laptop);
  const backBtnRef = useRef<HTMLButtonElement>(null);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handlers = useSwipeable({
    onSwipedRight: () => backBtnRef.current?.click(),
    swipeDuration: 250,
  });

  const renderHeader = (
    onCreate?: any,
    onBack?: () => void,
    title?: string,
    showDismiss = true,
  ) => {
    return (
      <>
        <Flex
          align="center"
          marginBottom={{ base: '1rem', md: '1.75rem' }}
          marginX={{ base: '1', md: '3' }}
          pt="3"
        >
          {!hideButton
            ? renderBackButton(false, onBack, !hideButton)
            : onBack
            ? renderBackButton(false, onBack, hideButton)
            : renderBackButton(true, onBack, hideButton)}
          <Text
            mx="auto"
            textTransform="uppercase"
            fontSize={{ base: '12px', md: '14px' }}
            fontWeight="semibold"
          >
            {title
              ? title
              : subnavCurrentCategory
              ? subnavCurrentCategory
              : currentCategory?.phrCategoryName}
          </Text>
          {onDismiss && showDismiss && (
            <IconButton
              aria-label="close"
              p={0}
              variant="ghost"
              borderRadius="full"
              onClick={onDismiss}
            >
              <XCloseIcon width="24px" height="24px" />
            </IconButton>
          )}
        </Flex>
        {onCreate && (
          <FloatingActionButton zIndex={1} bottom={10} right={10} onClick={onCreate}>
            <PlusIconBig color="#fff" fontSize="20px" />
          </FloatingActionButton>
        )}
      </>
    );
  };

  const renderBodyComponent = () => {
    if (currentCategory) {
      switch (currentCategory.phrCategory) {
        case PatientAccessEnum.PHRSummary:
          return (
            <>
              {renderHeader()}
              <StyledCard>
                <PatientInformationCard patient={phrPatient.patient} />
              </StyledCard>
            </>
          );
        case PatientAccessEnum.PHRHealthJournal:
          return <PHRHealthJournalComponent patient={phrPatient} renderHeader={renderHeader} />;
        case PatientAccessEnum.PHRAllergy:
          return <PHRAllergiesComponent patient={phrPatient.phr} renderHeader={renderHeader} />;
        case PatientAccessEnum.PHRConsult:
          return <PHRConsultsComponent patient={phrPatient} renderHeader={renderHeader} />;
        case PatientAccessEnum.PHRDocument:
          return <PHRDocumentsComponent patient={phrPatient.phr} renderHeader={renderHeader} />;
        case PatientAccessEnum.PHRHealthCondition:
          return <HealthConditionsComponent patient={phrPatient} renderHeader={renderHeader} />;
        case PatientAccessEnum.PHRSurgeryHistory:
          return <PHRSurgeryHistoryComponent patient={phrPatient} renderHeader={renderHeader} />;
        case PatientAccessEnum.PHRMedication:
          return <MedicationsComponent phrPatient={phrPatient} renderHeader={renderHeader} />;
        case PatientAccessEnum.PHRFamilyHistory:
          return <FamilyHistoryComponent patient={phrPatient.phr} renderHeader={renderHeader} />;
        case PatientAccessEnum.PHRNotes:
          return <PHRNotesComponent phrPatient={phrPatient} renderHeader={renderHeader} />;
        case PatientAccessEnum.PHRVaccination:
          return <Vaccinations patient={phrPatient.phr} renderHeader={renderHeader} />;
        case PatientAccessEnum.PHRCovid:
          return <CovidVaccinations patient={phrPatient.phr} renderHeader={renderHeader} />;
        case 'VitalSigns' as PatientAccessEnum:
          return <PatientVitals patient={phrPatient.patient!} onBack={handleBack} />;
        case PatientAccessEnum.PHRTracker:
          return (
            <PhrTrackersDashboard
              renderDefaultHeader={renderHeader}
              phrPatient={phrPatient}
              onBack={handleBack}
            />
          );
        default:
          return (
            <EmptyCategoryCard>
              <p>{t('Please select a Category')}</p>
            </EmptyCategoryCard>
          );
      }
    }
  };

  const handleBack = () => {
    setCurrentSubNavCategoryID(null);
    if (!subnavCurrentCategory) {
      back();
    }
  };

  const renderBackButton = (hidden: boolean, onBack?: () => void, showButton?: boolean) => {
    return (
      <ModalBackButton
        left="unset"
        position="relative"
        visibility={hidden ? 'hidden' : 'visible'}
        display={showButton ? 'block' : 'none'}
        ref={backBtnRef}
        onClick={() => (onBack ? onBack() : handleBack())}
      />
    );
  };

  return currentCategory ? (
    <Box bgColor="#ffffff" position="relative" w="100%" h="100%">
      <ChakraSlideFade
        in={isMobile ? !!currentCategory : true}
        w="100%"
        h="100%"
        offsetX={isMobile ? '100%' : undefined}
        offsetY={0}
      >
        <SwipeableBody {...handlers}>{renderBodyComponent()}</SwipeableBody>
      </ChakraSlideFade>
    </Box>
  ) : (
    <PhrEmptyBody>
      {onDismiss && (
        <Flex justify="flex-end" padding="1rem">
          <IconButton
            aria-label="close"
            p={0}
            variant="ghost"
            borderRadius="full"
            onClick={onDismiss}
          >
            <XCloseIcon width="24px" height="24px" />
          </IconButton>
        </Flex>
      )}
      <EmptyCategoryCard>
        <p>{t('Please select a Category')}</p>
      </EmptyCategoryCard>
    </PhrEmptyBody>
  );
};

export default PhrBodyComponent;

export const PhrBodySkeletonComponent = () => {
  return (
    <PhrEmptyBody>
      <EmptyCategoryCard>
        <p>
          <SkeletonLine />
        </p>
      </EmptyCategoryCard>
    </PhrEmptyBody>
  );
};
