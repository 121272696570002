import { useCallback, useState } from 'react';
import { HealRoutes, PhoneEventsEnum } from '../../../constants';

import { ConsultationTypeFlowEnum, StoreItemTypeEnum, Tag } from '../../../generated';
import { usePartnerSettings } from '../../../services/partners/usePartnerSettings';
import { useProviderSymptomsTags } from '../../../services/tags/queries/useConsultSymptoms';
import { useTagsByID } from '../../../services/tags/queries/useTagsByID';
import { useUrlQuery } from '../../../utils/hooks/useUrlQuery';
import { errorToastr } from '../../../utils/toastr';

import useCareTypeTags from '../../heal/patient/BookAppointment/components/BookConsultProvider/useProviderCareTypes/useProviderCareTypes';
import { getConsultTypeEnum } from '../../heal/patient/BookAppointment/components/BookConsultProvider/useProviderVisitType/useProviderVisitType';
import useSymptomTags from '../../heal/patient/BookAppointment/components/BookConsultProvider/useSymptomTags/useSymptomTags';

import { BookingFlowEnum } from '../components/PublicSearchComponents/enums';
import { handleSaveBookingSession } from '../utils/handleSaveBookingSession';

import { UsePublicBookingFlowReturnType } from './usePublicBookingFlow';

type UseSymptomsProps = UsePublicBookingFlowReturnType;

const maxSymptoms = 5;

const getNextStep = (selectedCareTypeTag: Tag) => {
  switch (selectedCareTypeTag.externalID) {
    case ConsultationTypeFlowEnum.HomeCare:
      return BookingFlowEnum.HomeCareService;
    default:
      return BookingFlowEnum.VisitType;
  }
};

const homeCareStateData = {
  storeItemTypeEnum: StoreItemTypeEnum.InPersonConsult,
  consultTypeEnum: getConsultTypeEnum(StoreItemTypeEnum.InPersonConsult),
};

const covidTestStateData = {
  storeItemTypeEnum: StoreItemTypeEnum.VideoConsult,
  consultTypeEnum: getConsultTypeEnum(StoreItemTypeEnum.VideoConsult),
};

const useSymptoms = (props: UseSymptomsProps) => {
  const { isMobileDevice, isAndroid, isIOS } = props;

  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const [isSavingSession, setIsSavingSession] = useState(false);

  const [submitButtonTouched, setSubmitButtonTouched] = useState(false);

  const [isAttemptToBookModalOpen, setIsAttemptToBookModalOpen] = useState(false);

  const { partnerSymptomTagId } = usePartnerSettings();

  const { routeWithQuery } = useUrlQuery();

  const careTypeTagId = props.bookingData.selectedCareType.tagID;

  const { careTypeTags, careTypeTagXrefs, isLoadingCareTypeTags, careTypeTagsLoadingError } =
    useCareTypeTags(careTypeTagId);

  const { symptomTags } = useSymptomTags(careTypeTags, careTypeTagXrefs);

  const { consultSymptoms, isLoadingConsultSymptoms, consultSymptomsError } =
    useProviderSymptomsTags({
      countryID: props.bookingData.patientCountry?.countryID,
    });

  const {
    tags: partnerTags,
    isLoadingTags: isLoadingPartnerTags,
    tagsError: partnerTagsError,
  } = useTagsByID({
    tagID: partnerSymptomTagId,
  });

  const isHomeCare =
    props.bookingData.selectedCareType?.externalID === ConsultationTypeFlowEnum.HomeCare;

  const isCovidTest =
    props.bookingData.selectedCareType?.externalID === ConsultationTypeFlowEnum.CovidCare;

  const isUrgentCare =
    props.bookingData.selectedCareType?.externalID === ConsultationTypeFlowEnum.UrgentCare;

  const isSearchDisabled = selectedTags.length >= maxSymptoms;

  const symptomsNotSelected = selectedTags.length === 0;

  const cannotSubmit = symptomsNotSelected;

  const hasSymptomTags = symptomTags?.length;

  const partnerTagsXref = partnerTags
    ? partnerTags.tagXrefs?.filter((tagXRef) => !!tagXRef.toTag).map((tagXRef) => tagXRef.toTag!)
    : undefined;

  const isLoadingSymptoms =
    isLoadingConsultSymptoms || isLoadingCareTypeTags || isLoadingPartnerTags;

  const onUnSelectTag = (tagID: string) => {
    setSelectedTags((prev) => prev.filter((tag) => tag.tagID !== tagID) ?? []);
  };

  const onSelectTags = (tags: Tag[]) => {
    setSelectedTags(tags);
  };

  const handleSaveSessionData = useCallback(async () => {
    try {
      setIsSavingSession(true);

      const sessionId = await handleSaveBookingSession(props.bookingData, {
        selectedSymptoms: selectedTags,
      });

      if (!sessionId) throw new Error('Failed to save session');

      if (isMobileDevice) {
        if (isAndroid) {
          // @ts-ignore
          const androidCallbackData = JSON.stringify({
            callbackName: PhoneEventsEnum.PUBLIC_BOOKING_UID_CALLBACK_HANDLER,
            sessionID: sessionId,
          });

          // @ts-ignore
          AndroidResult.callback(androidCallbackData);
        } else if (isIOS) {
          // @ts-ignore
          webkit.messageHandlers.publicBookingUIDCallbackHandler.postMessage(sessionId);
        } else {
          throw new Error('Unknown mobile device');
        }

        return;
      }

      routeWithQuery({ sessionId }, HealRoutes.BookConsult);
    } catch (error) {
      errorToastr({
        title: JSON.stringify(error),
      });
      setIsSavingSession(false);
    }
  }, [isAndroid, isIOS, isMobileDevice, props.bookingData, routeWithQuery, selectedTags]);

  const onAttemptToBook = useCallback(() => {
    setIsAttemptToBookModalOpen(true);
  }, []);

  const onConfirmContinueBooking = useCallback(() => {
    if (!isAttemptToBookModalOpen) return;

    handleSaveSessionData();
  }, [handleSaveSessionData, isAttemptToBookModalOpen]);

  const onCancelContinueBooking = useCallback(() => {
    setIsAttemptToBookModalOpen(false);
  }, []);

  const handleNextStep = () => {
    setSubmitButtonTouched(true);

    if (cannotSubmit) return;

    if (isUrgentCare) {
      onAttemptToBook();
      return;
    }

    props.handleUpdateBookingData({
      selectedSymptoms: selectedTags,
      bookingFlowStepEnum: getNextStep(props.bookingData.selectedCareType),
      ...(isHomeCare ? homeCareStateData : {}),
      ...(isCovidTest ? covidTestStateData : {}),
    });
  };

  return {
    handleNextStep,
    careTypeTagsLoadingError: partnerSymptomTagId
      ? partnerTagsError
      : careTypeTagsLoadingError || consultSymptomsError,
    // symptomTags: symptomTags?.sort((a, b) => b.rank - a.rank) || [],
    symptomTags: partnerSymptomTagId
      ? partnerTagsXref
      : hasSymptomTags
      ? symptomTags
      : consultSymptoms,
    isLoadingCareTypeTags: isLoadingSymptoms,
    onUnSelectTag,
    onSelectTags,
    isSearchDisabled,
    selectedTags,
    symptomsNotSelected,
    cannotSubmit,
    submitButtonTouched,
    maxSymptoms,
    isSavingSession,
    isAttemptToBookModalOpen,
    onConfirmContinueBooking,
    onCancelContinueBooking,
  };
};

export { useSymptoms };
