import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AddressBooksClient, AddressBookTag, AuthClient } from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

export interface IAddressBooksManyProps {
  addressBookID: string;
  addressBookTags: AddressBookTag[];
}

export const useAddressBooksMany = () => {
  const queryClient = useQueryClient();
  const [error, setError] = useState<string>('');
  const addressBooksMany = useMutation(
    async ({ addressBookID, addressBookTags }: IAddressBooksManyProps) =>
      httpRequest(() =>
        new AddressBooksClient(new AuthClient()).many(addressBookID, addressBookTags),
      ),
    {
      onSuccess: () => {
        setError('');
        queryClient.invalidateQueries(QueryKeys.AddressBooksSearch);
        queryClient.invalidateQueries(QueryKeys.AddressBooksPrivate);
        queryClient.invalidateQueries(QueryKeys.AddressBooksCollection);
      },
      onError: (err: string) => setError(err as any),
    },
  );

  return { error, addressBooksMany };
};
