import { Box } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { IFormQuestion, IPHRFormQuestion } from '../../../../../generated';

interface IProps {
  formQuestion: IFormQuestion;
  previousAnswer?: IPHRFormQuestion;
  showAllOptions?: boolean;
}

export const MultipleChoiceResultView = ({
  formQuestion,
  previousAnswer,
  showAllOptions = false,
}: IProps) => {
  return (
    <Box>
      {formQuestion.formQuestionChoices
        ?.filter((x) =>
          showAllOptions
            ? true
            : !!previousAnswer?.phrFormQuestionChoices?.find(
                (y) => y.formQuestionChoiceID === x.formQuestionChoiceID,
              ),
        )
        .map((x, index) => (
          <Choice
            key={index}
            selected={
              !!previousAnswer?.phrFormQuestionChoices?.find(
                (y) => y.formQuestionChoiceID === x.formQuestionChoiceID,
              )
            }
          >
            {x.choiceLabel}
            {showAllOptions &&
              !!previousAnswer?.phrFormQuestionChoices?.find(
                (y) => y.formQuestionChoiceID === x.formQuestionChoiceID,
              ) && <Checkmark icon={['fas', 'check']} />}
          </Choice>
        ))}
    </Box>
  );
};

interface IChoiceProps {
  selected: boolean;
}

const Choice = styled.div<IChoiceProps>`
  display: flex;
  align-items: center;
  color: ${(props) => (props.selected ? props.theme.Colors.Primary : props.theme.Colors.Gray)};
  padding: 2px;
`;

const Checkmark = styled(FontAwesomeIcon)`
  padding: 2px;
  font-size: 20px;
  color: ${(props) => props.theme.Colors.Green};
  margin-left: 4px;

  @media print {
    display: block;
    background-color: white;
    font-size: 16px;
  }
`;
