import { useQuery } from 'react-query';
import { AuthClient, PHRDocument, PHRDocumentsClient } from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

export const usePhrDocumentsByAssociatedID = (
  patientID: string,
  associatedID: string,
  updateVariable: any,
) => {
  const {
    isLoading: isLoadingPhrDocuments,
    data: phrDocuments,
    error: phrDocumentsError,
  } = useQuery<PHRDocument[], string>(
    [QueryKeys.PHRDocumentsByAssociatedID, updateVariable, patientID, associatedID],
    async () =>
      httpRequest(() =>
        new PHRDocumentsClient(new AuthClient()).byAssociatedID(patientID, associatedID),
      ),
    {
      enabled: !!patientID && !!associatedID,
    },
  );

  return {
    isLoadingPhrDocuments,
    phrDocuments,
    phrDocumentsError,
  };
};
