import { Box, Text } from '@chakra-ui/react';
import { ChangeEventHandler, FC } from 'react';
import { TextArea } from '../../../../../../../components/forms';
import { usePartnerSettings } from '../../../../../../../services/partners/usePartnerSettings';

type SymptomsDescriptionProps = {
  usedDescriptionCharacters: number;
  maxDescriptionCharacters: number;
  symptomDescription: string;
  onChangeSymptomDescription: ChangeEventHandler<HTMLTextAreaElement>;
};

const SymptomsDescription: FC<SymptomsDescriptionProps> = (props) => {
  const { partnerSymptomsText } = usePartnerSettings();

  return (
    <Box>
      <Text textTransform="uppercase" fontSize="12px" color="rgb(0, 3, 16)">
        Tell us more about your {partnerSymptomsText ?? 'symptoms'}
      </Text>
      <Box>
        <Text fontSize="12px" color="rgb(0, 3, 16)" textAlign="right" mb="1" fontWeight="semibold">
          {props.usedDescriptionCharacters}/{props.maxDescriptionCharacters}
        </Text>
        <TextArea
          name="symptomDescription"
          rows={2}
          value={props.symptomDescription}
          onChange={props.onChangeSymptomDescription}
          maxLength={props.maxDescriptionCharacters}
        />
      </Box>
    </Box>
  );
};

export { SymptomsDescription };
