import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getCurrentCampaign } from '../../store/root-creator';
import getUrlParam from '../../utils/getUrlParam';

const useGetCurrentCampaign = () => {
  const dispatch = useDispatch();
  const campaignName = getUrlParam('campaign');

  useEffect(() => {
    if (!campaignName) return;
    dispatch(getCurrentCampaign(campaignName));
  }, [campaignName, dispatch]);
};

export { useGetCurrentCampaign };
