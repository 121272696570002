import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import {
  IPatient,
  PatientCareTeam,
  PatientInsurance,
  UserDetailRelationship,
} from '../../generated';
import { PatientLoginPurposeEnum } from './patientReducers';

export const getPatientActions = createAsyncAction(
  ['GET_PATIENT_REQUEST', () => {}],
  ['GET_PATIENT_SUCCESS', (res: IPatient & { loginPurpose?: PatientLoginPurposeEnum }) => res],
  ['GET_PATIENT_ERROR', (err: string) => err],
)();

export const updatePatientProfileActions = createAsyncAction(
  ['UPDATE_PATIENT_PROFILE_REQUEST', () => {}],
  ['UPDATE_PATIENT_PROFILE_SUCCESS', () => {}],
  ['UPDATE_PATIENT_PROFILE_ERROR', (err: string) => err],
)();

// UserDetailRelationships
export const getAllPHRDependantsActions = createAsyncAction(
  ['GET_ALL_PHR_DEPENDANTS_REQUEST', () => {}],
  ['GET_ALL_PHR_DEPENDANTS_SUCCESS', (res: UserDetailRelationship[]) => res],
  ['GET_ALL_PHR_DEPENDANTS_ERROR', (err: string) => err],
)();

export const updatePHRDependantActions = createAsyncAction(
  ['UPDATE_PHR_DEPENDANTS_REQUEST', () => {}],
  ['UPDATE_PHR_DEPENDANTS_SUCCESS', () => {}],
  ['UPDATE_PHR_DEPENDANTS_ERROR', (err: string) => err],
)();

export const getOnePHRDependantActions = createAsyncAction(
  ['GET_ONE_PHR_DEPENDANT_REQUEST', () => {}],
  ['GET_ONE_PHR_DEPENDANT_SUCCESS', (res: UserDetailRelationship) => res],
  ['GET_ONE_PHR_DEPENDANT_ERROR', (err: string) => err],
)();

export const deletePHRDependantActions = createAsyncAction(
  ['DELETE_PHR_DEPENDANT_REQUEST', () => {}],
  ['DELETE_PHR_DEPENDANT_SUCCESS', (phrDependantID: string) => phrDependantID],
  ['DELETE_PHR_DEPENDANT_ERROR', (err: string) => err],
)();

export const createPHRDependantActions = createAsyncAction(
  ['CREATE_PHR_DEPENDANT_REQUEST', () => {}],
  ['CREATE_PHR_DEPENDANT_SUCCESS', () => {}],
  ['CREATE_PHR_DEPENDANT_ERROR', (err: string) => err],
)();

// Patient Insurances
export const getAllPatientInsuranceActions = createAsyncAction(
  ['GET_ALL_PATIENT_INSURANCES_REQUEST', () => {}],
  ['GET_ALL_PATIENT_INSURANCES_SUCCESS', (res: PatientInsurance[]) => res],
  ['GET_ALL_PATIENT_INSURANCES_ERROR', (err: string) => err],
)();

export const updatePatientInsuranceActions = createAsyncAction(
  ['UPDATE_PATIENT_INSURANCES_REQUEST', () => {}],
  ['UPDATE_PATIENT_INSURANCES_SUCCESS', () => {}],
  ['UPDATE_PATIENT_INSURANCES_ERROR', (err: string) => err],
)();

export const getOnePatientInsuranceActions = createAsyncAction(
  ['GET_ONE_PATIENT_INSURANCES_REQUEST', () => {}],
  ['GET_ONE_PATIENT_INSURANCES_SUCCESS', (res: PatientInsurance) => res],
  ['GET_ONE_PATIENT_INSURANCES_ERROR', (err: string) => err],
)();

export const deletePatientInsuranceActions = createAsyncAction(
  ['DELETE_PATIENT_INSURANCES_REQUEST', () => {}],
  ['DELETE_PATIENT_INSURANCES_SUCCESS', (patientInsuranceID: string) => patientInsuranceID],
  ['DELETE_PATIENT_INSURANCES_ERROR', (err: string) => err],
)();

export const createPatientInsuranceActions = createAsyncAction(
  ['CREATE_PATIENT_INSURANCES_REQUEST', () => {}],
  ['CREATE_PATIENT_INSURANCES_SUCCESS', () => {}],
  ['CREATE_PATIENT_INSURANCES_ERROR', (err: string) => err],
)();

export const updatePatientsPhoneNumber = createAction(
  'UPDATE_PATIENTS_PHONE_NUMBER',
  (patient: IPatient) => patient,
)();

export const setPatientProfile = createAction(
  'SET_PATIENT_PROFILE',
  (patient: IPatient) => patient,
)();

export const clearErrorAction = createAction('CLEAR_PATIENT_ERROR', () => {})();

export const getAllPatientCareTeamActions = createAsyncAction(
  ['GET_ALL_PATIENT_CARE_TEAMS_REQUEST', () => {}],
  ['GET_ALL_PATIENT_CARE_TEAMS_SUCCESS', (res: PatientCareTeam[]) => res],
  ['GET_ALL_PATIENT_CARE_TEAMS_ERROR', (err: string) => err],
)();

export const setPatientLoginPurpose = createAction(
  'SET_PATIENT_LOGIN_PURPOSE',
  (purpose: PatientLoginPurposeEnum) => purpose,
)();

export type PatientActionTypes = ActionType<
  | typeof getPatientActions
  | typeof updatePatientProfileActions
  | typeof getAllPHRDependantsActions
  | typeof updatePHRDependantActions
  | typeof getOnePHRDependantActions
  | typeof deletePHRDependantActions
  | typeof createPHRDependantActions
  | typeof clearErrorAction
  | typeof updatePatientsPhoneNumber
  | typeof getAllPatientInsuranceActions
  | typeof updatePatientInsuranceActions
  | typeof getOnePatientInsuranceActions
  | typeof deletePatientInsuranceActions
  | typeof createPatientInsuranceActions
  | typeof getAllPatientCareTeamActions
  | typeof setPatientProfile
  | typeof setPatientLoginPurpose
>;
