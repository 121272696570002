import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { AuthClient, ConsultsClient, RolesEnum } from '../../../../generated';
import { QueryKeys } from '../../../../services/keys';
import { ICurrentUser } from '../../../../store/currentUser/currentUserReducers';
import { getProvider } from '../../../../store/root-creator';
import { AppState } from '../../../../store/root-reducers';
import { httpRequest } from '../../../../utils';
import { isInRole } from '../../../../utils/isInRole';

interface IProps {
  currentUser: ICurrentUser | null;
}

const useGetAuthenticatedProvider = ({ currentUser }: IProps) => {
  const dispatch = useDispatch();
  const { provider } = useSelector((state: AppState) => state.providerState);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (currentUser && isInRole(currentUser, RolesEnum.Provider) && !provider) {
      dispatch(getProvider(currentUser.userDetailID));
    }
  }, [currentUser, dispatch, provider]);

  useEffect(() => {
    const prefetchWaitingRoom = async (providerID: string) => {
      queryClient.prefetchQuery(QueryKeys.ConsultsWaitingRoomPatients, async () =>
        httpRequest(() => new ConsultsClient(new AuthClient()).waitingRoomPatients(providerID)),
      );
    };
    if (
      currentUser &&
      provider &&
      isInRole(currentUser, RolesEnum.Provider) &&
      !isInRole(currentUser, RolesEnum.HealthcareNavigator)
    ) {
      prefetchWaitingRoom(provider.providerID);
    }
  }, [currentUser, queryClient, provider]);
};

export default useGetAuthenticatedProvider;
