import { Radio, RadioProps, WrapItem } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { radioButtonContext } from './RadioButtonContext';

export type RadioControlProps = RadioProps;

export const RadioControl = (props: RadioControlProps) => {
  const { value, ...rest } = props;
  const { getRadioProps } = useContext(radioButtonContext);
  const radio = getRadioProps({ value });

  return (
    <WrapItem>
      <Radio {...rest} _hover={{ cursor: 'pointer' }} {...radio} />
    </WrapItem>
  );
};
