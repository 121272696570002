import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  UpdateCommunityPictureActionTypes,
  updateCommunityPictureError,
  updateCommunityPicturePatientSuccess,
  updateCommunityPictureProviderSuccess,
  updateCommunityPictureRequest,
} from './updateCommunityPictureAction';
import {
  UpdateProfilePictureActionTypes,
  updateProfilePictureError,
  updateProfilePicturePatientSuccess,
  updateProfilePictureProviderSuccess,
  updateProfilePictureRequest,
} from './updateProfilePictureAction';

const error = createReducer<
  string,
  UpdateCommunityPictureActionTypes | UpdateProfilePictureActionTypes
>('').handleAction(
  [updateCommunityPictureError, updateProfilePictureError],
  (_state, action) => action.payload,
);

const isLoading = createReducer<
  boolean,
  UpdateCommunityPictureActionTypes | UpdateProfilePictureActionTypes
>(false)
  .handleAction([updateProfilePictureRequest, updateCommunityPictureRequest], () => true)
  .handleAction(
    [
      updateCommunityPictureError,
      updateCommunityPictureProviderSuccess,
      updateCommunityPicturePatientSuccess,
      updateProfilePictureError,
      updateProfilePictureProviderSuccess,
      updateProfilePicturePatientSuccess,
    ],
    () => false,
  );

export interface IPictureUploadState {
  error: string;
  isLoading: boolean;
}

export default combineReducers<IPictureUploadState>({
  error,
  isLoading,
});
