import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AddressBook, AddressBookFolderEnum, IPHRConsult } from '../../../../../generated';
import { isValidProvider } from '../../utils/isValidProvider';

import { AddressBookDeliveryModeEnum } from '../../../../../components/AddressBook/AddressBook';
import { AddressBookView } from '../../../../../components/AddressBook/components/AddressBookView/AddressBookView';
import { AddressBooksProvider } from '../../../../../components/AddressBook/context/AddressBooksProvider';
import { TertiaryButton } from '../../../../../components/Buttons';
import { Avatar } from '../../../../../components/common/Avatar';
import { ChosenProviderCard } from '../ProviderCard';

interface IProps {
  consult?: IPHRConsult;
  selectedAddress: AddressBook | undefined;
  setSelectedAddress: any;
}

export const SelectProvider = ({ consult, selectedAddress, setSelectedAddress }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <AddressBooksProvider
      deliveryMode={AddressBookDeliveryModeEnum.Single}
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      defaultCountry={consult?.country!}
      patient={consult?.patient}
      callback={(addressBooks: AddressBook[]) =>
        addressBooks.length ? setSelectedAddress(addressBooks[0]) : setSelectedAddress(undefined)
      }
      validationMessage="You can only select a Provider"
      onValidate={(addressBook: AddressBook) => isValidProvider(addressBook)}
      defaultFolder={AddressBookFolderEnum.Provider}
    >
      {!selectedAddress ? (
        <Button
          height="64px"
          _hover={{ backgroundColor: 'purple.100' }}
          _active={{ backgroundColor: 'purple.100' }}
          bgColor="#762EE70d"
          isFullWidth
          borderRadius={5}
          p={4}
          mb={12}
          onClick={onOpen}
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Avatar size="sm" />
            <Text flexGrow={1} ml={4} color="#3E1191" textAlign="left">
              Select A Provider
            </Text>
            <Box mr={8}>
              <FontAwesomeIcon icon={['far', 'angle-right']} />
            </Box>
          </Flex>
        </Button>
      ) : (
        <ChosenProviderCard
          selectedAddress={selectedAddress}
          ActionButton={
            <TertiaryButton
              fontWeight="normal"
              color="#3E1191"
              onClick={() => setSelectedAddress(undefined)}
            >
              Change Provider
            </TertiaryButton>
          }
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <AddressBookView onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </AddressBooksProvider>
  );
};
