import moment from 'moment';
import { Box, HStack } from '@chakra-ui/react';

import { TimeFormatEnum } from '../../../constants';
import { PHRHealthJournal } from '../../../generated';

import PhrItem, { PHRItemList, EmptyPHRItemListCard } from '../components/PhrItem';
import { getTime } from '../../../utils';
import { OutpostTheme } from '../../../themes/outpost';

interface IProps {
  phrHealthJournal: PHRHealthJournal[];
  onView: (phrSurgeryHistory: PHRHealthJournal) => void;
}

const HealthJournalList = ({ phrHealthJournal, onView }: IProps) => {
  return (
    <>
      {phrHealthJournal.length === 0 ? (
        <EmptyPHRItemListCard>No Health Journals added</EmptyPHRItemListCard>
      ) : (
        <PHRItemList>
          {phrHealthJournal
            .sort((a, b) => getTime(b.journalDate) - getTime(a.journalDate))
            .map((item, index) => (
              <PhrItem
                key={index}
                name={item.title}
                isVerified={true}
                onView={() => onView(item)}
                SecondaryInfo={<SecondaryInfoComponent item={item} />}
              />
            ))}
        </PHRItemList>
      )}
    </>
  );
};

interface ISecondaryInfoComponentProps {
  item: PHRHealthJournal;
}

const SecondaryInfoComponent = ({ item }: ISecondaryInfoComponentProps) => {
  return (
    <HStack
      spacing={{ base: 0, md: 2 }}
      fontSize={OutpostTheme.FontSizes.body}
      fontWeight="300"
      color="#011431"
      align="flex-start"
      flexDir={{ base: 'column', md: 'row' }}
      flexWrap="wrap"
    >
      <Box as="span">
        Journal Date: {moment(item.journalDate).format(TimeFormatEnum.LONG_DATE_TIME)}
      </Box>
    </HStack>
  );
};

export default HealthJournalList;
