import { Accordion, ExpandedIndex, Stack } from '@chakra-ui/react';

import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ChatAltIcon,
  ChatIcon,
  ClinicIcon,
  HeartIcon,
  HouseIcon,
  MessageIcon,
  PaperIcon,
  ProfileIcon,
  StarIcon,
} from '../../../assets/icons';
import AppFeatureComponent from '../../../components/AppFeatureComponent';
import FeatureAccessComponent from '../../../components/FeatureAccessComponent';
import {
  AppFeatures,
  DashboardRoutes,
  HealRoutes,
  LearnRoutes,
  MessageCenterRoutes,
  MonitorRoutes,
} from '../../../constants';
import { RolesEnum } from '../../../generated';
import { AppState } from '../../../store/root-reducers';
import { isInRole } from '../../../utils/isInRole';
import { CustomMenuAccordion } from './components/CustomMenuAccordion';
import { MenuItem } from './components/MenuItem';
import { SideMenu } from './components/SideMenu';

export const ReceptionistSideMenu = () => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const [openIndex, setOpenIndex] = useState<ExpandedIndex | undefined>();
  const isOpen = useSelector((state: AppState) => state.sideMenuState.isOpen);

  return (
    <SideMenu>
      <MenuItem routes={[DashboardRoutes.Dashboard]} Icon={HouseIcon}>
        Dashboard
      </MenuItem>
      <MenuItem
        Icon={MessageIcon}
        routes={[
          MessageCenterRoutes.InboxMail,
          MessageCenterRoutes.Mail,
          MessageCenterRoutes.SentMail,
          MessageCenterRoutes.ComposeMail,
          MessageCenterRoutes.DeletedMail,
          MessageCenterRoutes.AddressBook,
        ]}
      >
        Messages
      </MenuItem>
      <Accordion index={openIndex} onChange={setOpenIndex} allowToggle>
        <Stack spacing={4}>
          <CustomMenuAccordion
            Icon={ClinicIcon}
            text="My Clinic"
            isOpen={isOpen}
            children={
              <>
                <Stack spacing={2}>
                  <MenuItem
                    routes={[
                      HealRoutes.ReceptionistDesk,
                      HealRoutes.ReceptionistWaitingRoom,
                      HealRoutes.ReceptionistPatientRegistration,
                      HealRoutes.ReceptionistPatientSearch,
                      HealRoutes.ReceptionistProviderSearch,
                    ]}
                    Icon={ClinicIcon}
                  >
                    My Desk
                  </MenuItem>
                  {isInRole(currentUser, RolesEnum.FaxAdministratorRight) && (
                    <MenuItem
                      routes={[
                        HealRoutes.Fax,
                        HealRoutes.FaxIncoming,
                        HealRoutes.FaxForwarded,
                        HealRoutes.FaxDeleted,
                      ]}
                      Icon={PaperIcon}
                    >
                      Fax
                    </MenuItem>
                  )}
                </Stack>
              </>
            }
          />

          <CustomMenuAccordion
            Icon={ProfileIcon}
            text="Admin"
            isOpen={isOpen}
            children={
              <>
                <Stack spacing={2}>
                  <AppFeatureComponent featureName={AppFeatures.ClinicalTrials}>
                    <FeatureAccessComponent roles={RolesEnum.ClinicalTrialRight}>
                      <MenuItem
                        routes={[
                          MonitorRoutes.ClinicalTrials,
                          MonitorRoutes.ClinicalTrial,
                          MonitorRoutes.ParticipateClinicalTrial,
                        ]}
                        Icon={StarIcon}
                      >
                        Trials
                      </MenuItem>
                    </FeatureAccessComponent>
                  </AppFeatureComponent>
                  <MenuItem routes={[MonitorRoutes.AddressBook]} Icon={PaperIcon}>
                    Address Book
                  </MenuItem>
                </Stack>
              </>
            }
          />

          <CustomMenuAccordion
            Icon={ChatIcon}
            text="Learning"
            isOpen={isOpen}
            children={
              <>
                <Stack spacing={2}>
                  <AppFeatureComponent featureName={AppFeatures.DiscussionsAvailable}>
                    <FeatureAccessComponent roles={RolesEnum.CommunityForumRight}>
                      <MenuItem
                        routes={[
                          LearnRoutes.Community,
                          LearnRoutes.CommunitySearchResults,
                          LearnRoutes.CommunityDiscussion,
                          LearnRoutes.CommunityDiscussionCreate,
                          LearnRoutes.CommunityDiscussionItem,
                        ]}
                        Icon={ChatAltIcon}
                      >
                        Community Forum
                      </MenuItem>
                    </FeatureAccessComponent>
                  </AppFeatureComponent>
                  <MenuItem routes={[LearnRoutes.CareSpaces]} Icon={HeartIcon}>
                    Health Resources
                  </MenuItem>
                </Stack>
              </>
            }
          />
        </Stack>
      </Accordion>
    </SideMenu>
  );
};
