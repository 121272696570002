import { COOKIE_NAME } from '../../constants';
import { AuthClient, SecurityClient } from '../../generated';
import { httpRequest } from '../../utils';
import { getCookie, setCookie } from '../../utils/cookieHelper';
import { decodeJwtToCurrentUser } from '../../utils/decodeJwtToCurrentUser';
import { signInActions } from '../currentUser/currentUserActions';

export const setWebviewUserInterceptor = async (store) => {
  const urlParams = new URLSearchParams(window.location.search);
  const sysParam = (urlParams.get('sys') as 'android' | 'ios') ?? undefined;
  const webViewID = urlParams.get('webViewID');
  try {
    if (webViewID) {
      const securityClient = new SecurityClient(new AuthClient());
      const jwtParam = await httpRequest(() => securityClient.userDetailWebView(webViewID));
      let cookie = getCookie(COOKIE_NAME);
      if (jwtParam) {
        setCookie(COOKIE_NAME, jwtParam);
        cookie = jwtParam;
      }

      // Check if we have an Outpost.Health cookie
      if (cookie.length > 0) {
        store.dispatch(
          signInActions.success(decodeJwtToCurrentUser(cookie, !!jwtParam, sysParam)!),
        );
      }
    }
  } catch (err) {}
};
