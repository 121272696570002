import { Box, Checkbox, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { BellIcon } from '../../../../assets/icons';
import { CHART_PLUGINS, TimeFormatEnum } from '../../../../constants';
import { PHRTrackerPulseDTO } from '../../../../generated';
import { OutpostTheme } from '../../../../themes/outpost';
import { getTime } from '../../../../utils';
import TrackerChartBase from '../TrackerChartBase';

interface IProps {
  dataSet: PHRTrackerPulseDTO[];
}

const PulseChart = ({ dataSet }: IProps) => {
  const [daysRange, setDaysRange] = useState<number>(180);
  const [showBoundary, setShowBoundary] = useState<boolean>(false);
  const [annotations, setAnnotations] = useState<any[]>([]);
  const chartReference = useRef<any>(null);

  const getAnnotations = (showBoundary: boolean) => {
    let listOfAnnotations: any[] = [];

    if (showBoundary) {
      listOfAnnotations = [
        {
          drawTime: 'afterDatasetsDraw',
          id: 'hline',
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: 100,
          borderColor: '#fa6655',
          borderWidth: 2,
          label: {
            content: 'High',
            enabled: true,
          },
        },
        {
          drawTime: 'afterDatasetsDraw',
          id: 'hline2',
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: 60,
          borderColor: '#fa6655',
          borderWidth: 2,
          label: {
            content: 'Low',
            enabled: true,
          },
        },
      ];
    }

    return listOfAnnotations;
  };

  useEffect(() => {
    setAnnotations(getAnnotations(showBoundary));
  }, [showBoundary]);

  useEffect(() => {
    if (chartReference.current) {
      chartReference.current.chartInstance.update();
    }
  }, [annotations]);

  const data = () => {
    const sorted = [...dataSet].sort((a, b) => getTime(a.trackerDate) - getTime(b.trackerDate));

    return {
      labels: [...sorted.map((x) => moment(x.trackerDate).format(TimeFormatEnum.LONG_DATE))],
      datasets: [
        {
          label: 'Pulse Rate',
          data: sorted.map((d) => ({ x: d.trackerDate, y: d.pulse })),
          fill: false,
          borderColor: '#00A18F',
          borderWidth: 2,
        },
      ],
    };
  };

  const options = {
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          type: 'linear',
          ticks: {
            callback: function (value) {
              if (Math.floor(value) === value) {
                return value;
              }
            },
            suggestedMin: 20,
            suggestedMax: 120,
          },
        },
      ],
      xAxes: [
        {
          type: 'time',
          position: 'bottom',
          distribution: 'linear',
          time: {
            unit: daysRange <= 7 ? 'day' : 'month',
          },
          ticks: {
            max: moment(),
            min: moment().subtract(daysRange, 'd'),
          },
        },
      ],
    },
    annotation: {
      events: ['click'],
      annotations,
    },
    plugins: CHART_PLUGINS,
    maintainAspectRatio: false,
    layout: {
      padding: { top: 20, bottom: 20 },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <TrackerChartBase
      name="Heart Rate"
      color="#7BBFB7"
      daysRange={daysRange}
      setDaysRange={setDaysRange}
    >
      <Flex
        padding="0.75rem"
        justify="space-between"
        bgColor="#F8F8F8"
        flexDir={{ base: 'column', md: 'row' }}
      >
        <HStack>
          <Box w="17px" h="17px" bgColor="#00A18F" borderRadius="4px" />
          <Text fontSize={OutpostTheme.FontSizes.body} color="#414F65">
            Heart Rate
          </Text>
        </HStack>

        <Flex align="center" justify="space-between">
          <Flex align="center">
            <Text fontSize={OutpostTheme.FontSizes.body} mr={4}>
              Alert Limits:
            </Text>
            <HStack spacing={4}>
              <HStack>
                <Checkbox
                  isChecked={showBoundary}
                  onChange={() => setShowBoundary(!showBoundary)}
                />
                <Text fontSize={OutpostTheme.FontSizes.body} color="#414F65">
                  Heart Rate
                </Text>
              </HStack>
            </HStack>
          </Flex>

          <IconButton
            aria-label="Calendar Icon"
            p={0}
            w="38px"
            h="38px"
            variant="ghost"
            bgColor="#FFFFFF"
            borderRadius="full"
            marginRight={{ base: 0, md: '1.75rem' }}
            ml={4}
          >
            <BellIcon w="16px" h="16px" />
          </IconButton>
        </Flex>
      </Flex>

      <Box
        position="relative"
        height={{ base: 'calc(100% - 10rem)', md: 'calc(100% - 7.5rem)' }}
        width="calc(100% - 20px)"
        margin="0 auto"
      >
        {/* @ts-ignore */}
        <Line data={data} options={options} />
      </Box>
    </TrackerChartBase>
  );
};

export default PulseChart;
