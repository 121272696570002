import styled from 'styled-components';

interface IParagraphProps {
  readonly small?: boolean;
}

export const Paragraph = styled.p<IParagraphProps>`
  color: ${(props) => props.theme.FontColors.Secondary};
  margin: 0 0 10px;
  font-size: ${(props) => (props.small ? '14px' : '16px')};
  font-family: 'Inter';
  line-height: 160%;
`;
