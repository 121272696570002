import { useCallback, useState } from 'react';

import { HealRoutes, PhoneEventsEnum } from '../../../constants';
import {
  ConsultProviderSearchBrowseEnum,
  ConsultProviderSearchProviderResult,
  ProviderAppointment,
} from '../../../generated';
import { usePublicConsultSearch } from '../../../services/consults/queries/usePublicConsultSearch';
import { getProviderStoreItem } from '../../../utils/getProviderStoreItem';
import { useUrlQuery } from '../../../utils/hooks/useUrlQuery';
import { getEndOfDay, getStartOfDay } from '../../../utils/momentUtils';
import { errorToastr } from '../../../utils/toastr';

import { handleSaveBookingSession } from '../utils/handleSaveBookingSession';

import { UsePublicBookingFlowReturnType } from './usePublicBookingFlow';

type ProviderAppointmentDataType = {
  provider?: ConsultProviderSearchProviderResult;
  providerAppointment: ProviderAppointment;
  storeItemID?: string;
};

type UseAppointmentsProps = UsePublicBookingFlowReturnType;

const useAppointments = (props: UseAppointmentsProps) => {
  const { bookingData, isMobileDevice, isAndroid, isIOS } = props;

  const { routeWithQuery } = useUrlQuery();

  const [appointmentStartDate, setAppointmentStartDate] = useState<Date>(getStartOfDay(new Date()));
  const [appointmentEndDate, setAppointmentEndDate] = useState<Date>(getEndOfDay(new Date()));

  const [selectedProviderAppointmentData, setProviderAppointmentData] =
    useState<ProviderAppointmentDataType>();

  const [isSavingSession, setIsSavingSession] = useState(false);

  const {
    fetchNextPage: fetchNextPageCategory,
    hasNextPage: hasNextPageCategory,
    isFetchingNextPage: isFetchingNextPageCategory,
    isLoadingSearchResults,
    categoryError,
    categoryExist,
    category,
    searchResults,
  } = usePublicConsultSearch({
    countryId: props.bookingData.patientCountry?.countryID,
    storeItemTypeEnum: props.bookingData.storeItemTypeEnum,
    careTypeTags: [props.bookingData.selectedCareType],
    startDate: appointmentStartDate,
    endDate: appointmentEndDate,
    browseStoreId: props.bookingData.clinic?.storeID,
    homeCareServiceTags: props.bookingData.selectedHomeCareServiceTags,
    categoryToBrowseEnum: ConsultProviderSearchBrowseEnum.Provider,
  });

  const handleSetAppointmentStartDate = useCallback((date: Date) => {
    setAppointmentStartDate(date);
  }, []);

  const handleSetAppointmentEndDate = useCallback((date: Date) => {
    setAppointmentEndDate(date);
  }, []);

  const handleSetAppointmentRangeDate = useCallback((startDate: Date) => {
    setAppointmentStartDate(startDate);
    setAppointmentEndDate(getEndOfDay(startDate));
  }, []);

  const handleSaveSessionData = useCallback(
    (provider?: ConsultProviderSearchProviderResult) =>
      async (providerAppointment: ProviderAppointment, storeItemID?: string) => {
        try {
          if (!provider || !storeItemID) throw new Error('Missing provider or storeItemID');

          setIsSavingSession(true);

          const providerStoreItem = await getProviderStoreItem(
            provider?.providerID ?? '',
            storeItemID,
          );

          const sessionId = await handleSaveBookingSession(bookingData, {
            provider,
            providerAppointment: providerAppointment,
            selectedStoreItemID: storeItemID,
            providerStoreItem: providerStoreItem,
            store: providerStoreItem.storeItem?.store,
          });

          if (!sessionId) throw new Error('Failed to save session');

          if (isMobileDevice) {
            if (isAndroid) {
              // @ts-ignore
              const androidCallbackData = JSON.stringify({
                callbackName: PhoneEventsEnum.PUBLIC_BOOKING_UID_CALLBACK_HANDLER,
                uid: sessionId,
              });

              // @ts-ignore
              AndroidResult.callback(androidCallbackData);
            } else if (isIOS) {
              // @ts-ignore
              webkit.messageHandlers.publicBookingUIDCallbackHandler.postMessage(sessionId);
            } else {
              throw new Error('Unknown mobile device');
            }

            return;
          }

          routeWithQuery({ sessionId }, HealRoutes.BookConsult);
        } catch (error) {
          errorToastr({
            title: JSON.stringify(error),
          });
          setIsSavingSession(false);
        }
      },
    [bookingData, isAndroid, isIOS, isMobileDevice, routeWithQuery],
  );

  const onAttemptToBook = useCallback(
    (provider?: ConsultProviderSearchProviderResult) =>
      (providerAppointment: ProviderAppointment, storeItemID?: string) => {
        setProviderAppointmentData({ provider, providerAppointment, storeItemID });
      },
    [],
  );

  const onConfirmContinueBooking = useCallback(() => {
    if (!selectedProviderAppointmentData) return;

    handleSaveSessionData(selectedProviderAppointmentData?.provider)(
      selectedProviderAppointmentData.providerAppointment,
      selectedProviderAppointmentData?.storeItemID,
    );
  }, [handleSaveSessionData, selectedProviderAppointmentData]);

  const onCancelContinueBooking = useCallback(() => {
    setProviderAppointmentData(undefined);
  }, []);

  return {
    handleNextStep: onAttemptToBook,
    fetchNextPage: fetchNextPageCategory,
    hasNextPage: hasNextPageCategory,
    isFetchingNextPage: isFetchingNextPageCategory,
    isLoadingSearchResults: isLoadingSearchResults || isSavingSession,
    categoryError,
    categoryExist,
    category,
    searchResults,
    handleSetAppointmentStartDate,
    handleSetAppointmentEndDate,
    handleSetAppointmentRangeDate,
    appointmentStartDate,
    appointmentEndDate,
    selectedProviderAppointmentData,
    onConfirmContinueBooking,
    onCancelContinueBooking,
  };
};

export { useAppointments };
