import { Box, Flex, Stack, Text, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { number, object, string } from 'yup';

import moment from 'moment';
import { PurpleButton } from '../../../../../../../../components/Buttons';
import {
  FormDateTimePicker,
  FormInputControl,
  FormMobisPicker,
  FormTextArea,
} from '../../../../../../../../components/forms';
import { CenteredLoadingIndicator } from '../../../../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../../../../components/ServerValidationBox';
import { PatientOrder, PatientOrderQuestion } from '../../../../../../../../generated';
import { OutpostTheme } from '../../../../../../../../themes/outpost';
import { boolenToRadio, radioToBoolean } from '../../../../../../../../utils/radioBooleanHelpers';
import { useQuestionair } from '../../../contexts/CreateOrderContext/useQuestionair/useQuestionair';
import { MammogramForm } from './questionairFormTypes';
import { BooleanRadioControl } from './common/RadioControl';

export const MammogramQuestionair = () => {
  const { patientOrder, onSubmitForm, isLoading, error } = useQuestionair();

  return (
    <Formik
      initialValues={
        {
          reasonForExam: patientOrder?.reasonForExam ?? '',
          familyHistoryOfBreastCancer: boolenToRadio(
            patientOrder?.patientOrderQuestion?.familyHistoryOfBreastCancer,
          ),
          ageAtDiagnosis: patientOrder?.patientOrderQuestion?.ageAtDiagnosis ?? '',
          hormoneReplacementTherapy: boolenToRadio(
            patientOrder?.patientOrderQuestion?.hormoneReplacementTherapy,
          ),
          hormoneReplacementDuration:
            patientOrder?.patientOrderQuestion?.hormoneReplacementDuration ?? '',
          hormoneReplacementDate: moment(
            patientOrder?.patientOrderQuestion?.hormoneReplacementDate,
          ).toDate(),
          hormoneReplacementLocation:
            patientOrder?.patientOrderQuestion?.hormoneReplacementLocation ?? '',
          hormoneReplacementResults:
            patientOrder?.patientOrderQuestion?.hormoneReplacementResults ?? '',
          previousSurgery: boolenToRadio(patientOrder?.patientOrderQuestion?.previousSurgery),
          previousSurgeryDate:
            moment(patientOrder?.patientOrderQuestion?.previousSurgeryDate).toDate() ?? '',
          previousSurgeryDetails: patientOrder?.patientOrderQuestion?.previousSurgeryDetails ?? '',
          lastClinicalExamDate:
            moment(patientOrder?.patientOrderQuestion?.lastClinicalExamDate).toDate() ?? '',
          lastClinicalExamDetails:
            patientOrder?.patientOrderQuestion?.lastClinicalExamDetails ?? '',
        } as MammogramForm
      }
      validationSchema={object({
        reasonForExam: string().max(1024, 'Reason for Exam must be at most 1024 characters'),
        familyHistoryOfBreastCancer: string().required('This field is required.'),
        ageAtDiagnosis: number().max(1024, 'You have exceeded 1024 characters'),
        hormoneReplacementTherapy: string().required('This field is required.'),
        hormoneReplacementDuration: string().max(
          1024,
          'This field must be at most 1024 characters',
        ),
        hormoneReplacementDate: string().required('This field is required.'),
        hormoneReplacementLocation: string().max(
          1024,
          'This field must be at most 1024 characters',
        ),
        hormoneReplacementResults: string().max(1024, 'This field must be at most 1024 characters'),
        previousSurgery: string().required('This field is required.'),
        previousSurgeryDate: string().required('This field is required.'),
        previousSurgeryDetails: string().max(1024, 'This field must be at most 1024 characters'),
        lastClinicalExamDate: string().required('This field is required.'),
        lastClinicalExamDetails: string().max(1024, 'This field must be at most 1024 characters'),
      })}
      onSubmit={async (values: MammogramForm) => {
        const patientOrderQuestion = new PatientOrderQuestion();
        patientOrderQuestion.init(patientOrder?.patientOrderQuestion);

        const po = new PatientOrder();
        po.init(patientOrder);
        po.patientOrderQuestion = patientOrderQuestion;
        po.reasonForExam = values.reasonForExam;
        po.patientOrderQuestion.familyHistoryOfBreastCancer = radioToBoolean(
          values.familyHistoryOfBreastCancer,
        );
        po.patientOrderQuestion.ageAtDiagnosis = values.ageAtDiagnosis;
        po.patientOrderQuestion.hormoneReplacementTherapy = radioToBoolean(
          values.hormoneReplacementTherapy,
        );
        po.patientOrderQuestion.hormoneReplacementDuration = values.hormoneReplacementDuration;
        po.patientOrderQuestion.hormoneReplacementDate = values.hormoneReplacementDate;
        po.patientOrderQuestion.hormoneReplacementLocation = values.hormoneReplacementLocation;
        po.patientOrderQuestion.hormoneReplacementResults = values.hormoneReplacementResults;
        po.patientOrderQuestion.previousSurgery = radioToBoolean(values.previousSurgery);
        po.patientOrderQuestion.previousSurgeryDate = values.previousSurgeryDate;
        po.patientOrderQuestion.previousSurgeryDetails = values.previousSurgeryDetails;
        po.patientOrderQuestion.lastClinicalExamDate = values.lastClinicalExamDate;
        po.patientOrderQuestion.lastClinicalExamDetails = values.lastClinicalExamDetails;

        await onSubmitForm(po);
      }}
    >
      {({}) => (
        <StyledForm>
          {isLoading ? (
            <CenteredLoadingIndicator />
          ) : (
            <VStack h="100%" alignItems="stretch">
              <Stack spacing="5" overflowY="auto" flex="1" px="2">
                <ServerValidationBox message={error} />

                <Text fontSize="12px" fontWeight="semibold">
                  Screening Questions - Answer All
                </Text>

                <BooleanRadioControl
                  label="Family History Of Breast Cancer"
                  name="familyHistoryOfBreastCancer"
                />

                <FormInputControl
                  name="ageAtDiagnosis"
                  label="Age At Diagnosis"
                  formControlProps={{
                    mb: '0',
                  }}
                />

                <BooleanRadioControl
                  label="Hormone Replacement Therapy"
                  name="hormoneReplacementTherapy"
                />

                <FormInputControl
                  name="hormoneReplacementDuration"
                  label="Duration of Therapy"
                  formControlProps={{
                    mb: '0',
                  }}
                />

                <Stack direction={['column', 'row']} justify="space-between" spacing="5">
                  <Box w={['100%', '32%']} mb={[4, 0]}>
                    <FormMobisPicker
                      min={moment().toDate()}
                      name="hormoneReplacementDate"
                      label="Date Performed"
                      formControlProps={{
                        mb: '0',
                      }}
                    />
                  </Box>
                  <Box w={['100%', '32%']} mb={[4, 0]}>
                    <FormInputControl
                      name="hormoneReplacementLocation"
                      label="Facility / Hospitals"
                      formControlProps={{
                        mb: '0',
                      }}
                    />
                  </Box>
                  <Box w={['100%', '35%']} mb={[2, 0]}>
                    <FormInputControl
                      name="hormoneReplacementResults"
                      label="Results If Available"
                      formControlProps={{
                        mb: '0',
                      }}
                    />
                  </Box>
                </Stack>

                <BooleanRadioControl label="Previous Surgery" name="previousSurgery" />

                <Stack direction={['column', 'row']} justify="space-between" spacing="5">
                  <Box w={['100%', '49%']}>
                    <FormMobisPicker
                      min={moment().toDate()}
                      name="previousSurgeryDate"
                      label="Date Performed"
                      formControlProps={{
                        mb: '0',
                      }}
                    />
                  </Box>
                  <Box w={['100%', '49%']}>
                    <FormInputControl
                      name="previousSurgeryDetails"
                      label="Details of Surgery"
                      formControlProps={{
                        mb: '0',
                      }}
                    />
                  </Box>
                </Stack>

                <Box pt="3">
                  <Text fontSize={OutpostTheme.FontSizes.body}>Last Clinical Exam</Text>
                </Box>

                <Stack direction={['column', 'row']} justify="space-between" spacing="5">
                  <Box w={['100%', '49%']}>
                    <FormDateTimePicker
                      minDate={moment().toDate()}
                      name="lastClinicalExamDate"
                      label="Date Of Last Clinical Exam"
                      showTimeSelect={false}
                      formControlProps={{
                        mb: '0',
                      }}
                    />
                  </Box>
                  <Box w={['100%', '49%']}>
                    <FormInputControl
                      name="lastClinicalExamDetails"
                      label="Details of Last Clinical Exam"
                      formControlProps={{
                        mb: '0',
                      }}
                    />
                  </Box>
                </Stack>

                <FormTextArea maxLength={250} name="reasonForExam" label="Reason for Exam" />
              </Stack>
              <Flex justifyContent="flex-end" pt="4">
                <PurpleButton type="submit" width={{ base: '100%', sm: 'auto' }}>
                  Continue
                </PurpleButton>
              </Flex>
            </VStack>
          )}
        </StyledForm>
      )}
    </Formik>
  );
};

const StyledForm = styled(Form)`
  height: 100%;
`;
