import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BloodGlucoseTypeEnum,
  HeightEnum,
  TemperatureTypeEnum,
  UserDetailSettingEnum,
  WeightEnum,
} from '../../../../../generated';
import { AppState } from '../../../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../../../store/types';
import { getUserDetailSettings } from '../../../../../store/userDetailSettings/getUserDetailSettingsAction';
import { parseUserDetailSettings } from '../../../../../utils/parseUserDetailSettings';
import { successToastr } from '../../../../../utils/toastr';
import { IUnitSettings } from '../../hooks/usePatientVitals';
import { VitalsTracker } from '../PatientVitalsCard/usePatientVitalsCard';
import {
  createPHRBloodGlucose,
  createPHRBloodOxygen,
  createPHRBloodPressure,
  createPHRHeadCircumference,
  createPHRLength,
  createPHRPulse,
  createPHRRespiratory,
  createPHRTemperature,
  createPHRWeightHeight,
} from './createMethods';
import {
  deletePHRBloodGlucose,
  deletePHRBloodOxygen,
  deletePHRBloodPressure,
  deletePHRHeadCircumference,
  deletePHRLength,
  deletePHRPulse,
  deletePHRRespiratory,
  deletePHRTemperature,
  deletePHRWeightHeight,
} from './deleteMethods';
import {
  editPHRBloodGlucose,
  editPHRBloodOxygen,
  editPHRBloodPressure,
  editPHRHeadCircumference,
  editPHRLength,
  editPHRPulse,
  editPHRRespiratory,
  editPHRTemperature,
  editPHRWeightHeight,
} from './editMethods';
import {
  renderPHRBloodGlucoses,
  renderPHRBloodOxygens,
  renderPHRBloodPressures,
  renderPHRHeadCircumferences,
  renderPHRLengths,
  renderPHRPulses,
  renderPHRRespiratories,
  renderPHRTemperatures,
  renderPHRWeightHeights,
} from './renderMethods';

export interface IPatientVitalsForm {
  trackerDate: moment.Moment;
  systolic?: string;
  diastolic?: string;
  pulse?: string;
  heightCm?: string;
  heightFt?: string;
  heightIn?: string;
  heightUnit?: HeightEnum;
  weight?: string;
  weightUnit?: WeightEnum;
  temperature?: string;
  temperatureUnit?: TemperatureTypeEnum;
  respiratory?: string;
  bloodOxygen?: string;
  bloodGlucose?: string;
  bloodGlucoseUnit?: BloodGlucoseTypeEnum;
  headCircumference?: string;
  headCircumferenceUnit?: HeightEnum;
  length?: string;
  lengthUnit?: HeightEnum;
}

export const usePatientVitalsForm = (
  trackerID?: ReduxPHRCategoryEnum,
  tracker?: VitalsTracker,
  patientID?: string,
  unitSettings?: IUnitSettings,
) => {
  const { userDetailSettings } = useSelector((state: AppState) => state.userDetailSettingState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userDetailSettings) {
      dispatch(getUserDetailSettings());
    }
  }, [dispatch, userDetailSettings]);

  const [error, setError] = useState<string>('');

  const initialValues: IPatientVitalsForm = {
    trackerDate: moment(),
    systolic: '',
    diastolic: '',
    pulse: '',
    heightCm: '',
    heightFt: '',
    heightIn: '',
    heightUnit: unitSettings?.height,
    weight: '',
    weightUnit: unitSettings?.weight,
    temperature: '',
    temperatureUnit: unitSettings?.temperature,
    respiratory: '',
    bloodOxygen: '',
    bloodGlucose: '',
    bloodGlucoseUnit: parseUserDetailSettings(
      UserDetailSettingEnum.BloodGlucose,
      unitSettings?.bloodGlucose || '',
    ) as BloodGlucoseTypeEnum,
    headCircumference: '',
    headCircumferenceUnit: unitSettings?.height,
    length: '',
    lengthUnit: unitSettings?.height,
  };

  const onCreate = async (values: IPatientVitalsForm) => {
    const promiseJobs: Promise<any>[] = [];
    try {
      if (patientID) {
        if (values.systolic && values.diastolic) {
          promiseJobs.push(createPHRBloodPressure(values, patientID, setError));
        }
        if (values.pulse) {
          promiseJobs.push(createPHRPulse(values, patientID, setError));
        }
        if (values.heightCm || values.heightFt || values.heightIn || values.weight) {
          promiseJobs.push(createPHRWeightHeight(values, patientID, setError));
        }
        if (values.temperature) {
          promiseJobs.push(createPHRTemperature(values, patientID, setError));
        }
        if (values.respiratory) {
          promiseJobs.push(createPHRRespiratory(values, patientID, setError));
        }
        if (values.bloodOxygen) {
          promiseJobs.push(createPHRBloodOxygen(values, patientID, setError));
        }
        if (values.bloodGlucose) {
          promiseJobs.push(createPHRBloodGlucose(values, patientID, setError));
        }
        if (values.headCircumference) {
          promiseJobs.push(createPHRHeadCircumference(values, patientID, setError));
        }
        if (values.length) {
          promiseJobs.push(createPHRLength(values, patientID, setError));
        }

        if (promiseJobs.length > 0) {
          await Promise.all(promiseJobs);
          successToastr({ description: 'Vitals added!' });
        }
      } else {
        setError('patientID is required');
      }
    } catch (error) {
      setError(error as string);
    }
  };

  const onEdit = async (values: IPatientVitalsForm) => {
    try {
      if (patientID && tracker) {
        switch (trackerID) {
          case ReduxPHRCategoryEnum.PHRBloodPressures: {
            await editPHRBloodPressure(tracker, values, patientID, setError);
            break;
          }
          case ReduxPHRCategoryEnum.PHRPulses: {
            await editPHRPulse(tracker, values, patientID, setError);
            break;
          }
          case ReduxPHRCategoryEnum.PHRWeightHeights: {
            await editPHRWeightHeight(tracker, values, patientID, setError);
            break;
          }
          case ReduxPHRCategoryEnum.PHRTemperatures: {
            await editPHRTemperature(tracker, values, patientID, setError);
            break;
          }
          case ReduxPHRCategoryEnum.PHRRespiratories: {
            await editPHRRespiratory(tracker, values, patientID, setError);
            break;
          }
          case ReduxPHRCategoryEnum.PHRBloodOxygens: {
            await editPHRBloodOxygen(tracker, values, patientID, setError);
            break;
          }
          case ReduxPHRCategoryEnum.PHRBloodGlucoses: {
            await editPHRBloodGlucose(tracker, values, patientID, setError);
            break;
          }
          case ReduxPHRCategoryEnum.PHRHeadCircumferences: {
            await editPHRHeadCircumference(tracker, values, patientID, setError);
            break;
          }
          case ReduxPHRCategoryEnum.PHRLengths: {
            await editPHRLength(tracker, values, patientID, setError);
            break;
          }
        }
      } else {
        setError('patientID is required');
      }
    } catch (error) {
      setError(error as string);
    }
  };

  const onDelete = async () => {
    try {
      if (patientID && tracker) {
        switch (trackerID) {
          case ReduxPHRCategoryEnum.PHRBloodPressures: {
            await deletePHRBloodPressure(patientID, tracker.phrTrackerDetailID!, setError);
            break;
          }
          case ReduxPHRCategoryEnum.PHRPulses: {
            await deletePHRPulse(patientID, tracker.phrTrackerDetailID!, setError);
            break;
          }
          case ReduxPHRCategoryEnum.PHRWeightHeights: {
            await deletePHRWeightHeight(patientID, tracker.phrTrackerDetailID!, setError);
            break;
          }
          case ReduxPHRCategoryEnum.PHRTemperatures: {
            await deletePHRTemperature(patientID, tracker.phrTrackerDetailID!, setError);
            break;
          }
          case ReduxPHRCategoryEnum.PHRRespiratories: {
            await deletePHRRespiratory(patientID, tracker.phrTrackerDetailID!, setError);
            break;
          }
          case ReduxPHRCategoryEnum.PHRBloodOxygens: {
            await deletePHRBloodOxygen(patientID, tracker.phrTrackerDetailID!, setError);
            break;
          }
          case ReduxPHRCategoryEnum.PHRBloodGlucoses: {
            await deletePHRBloodGlucose(patientID, tracker.phrTrackerDetailID!, setError);
            break;
          }
          case ReduxPHRCategoryEnum.PHRHeadCircumferences: {
            await deletePHRHeadCircumference(patientID, tracker.phrTrackerDetailID!, setError);
            break;
          }
          case ReduxPHRCategoryEnum.PHRLengths: {
            await deletePHRLength(patientID, tracker.phrTrackerDetailID!, setError);
            break;
          }
        }
      } else {
        setError('patientID is required');
      }
    } catch (error) {
      setError(error as string);
    }
  };

  const renderer = (trackerID, setFieldValue, values, tracker?) => {
    switch (trackerID) {
      case ReduxPHRCategoryEnum.PHRBloodPressures:
        return renderPHRBloodPressures();
      case ReduxPHRCategoryEnum.PHRPulses:
        return renderPHRPulses();
      case ReduxPHRCategoryEnum.PHRWeightHeights:
        return renderPHRWeightHeights(setFieldValue, values, tracker);
      case ReduxPHRCategoryEnum.PHRTemperatures:
        return renderPHRTemperatures(setFieldValue, values, userDetailSettings);
      case ReduxPHRCategoryEnum.PHRRespiratories:
        return renderPHRRespiratories();
      case ReduxPHRCategoryEnum.PHRBloodOxygens:
        return renderPHRBloodOxygens();
      case ReduxPHRCategoryEnum.PHRBloodGlucoses:
        return renderPHRBloodGlucoses(setFieldValue, values, userDetailSettings);
      case ReduxPHRCategoryEnum.PHRHeadCircumferences:
        return renderPHRHeadCircumferences(setFieldValue, values, tracker);
      case ReduxPHRCategoryEnum.PHRLengths:
        return renderPHRLengths(setFieldValue, values, tracker);
    }
  };

  return {
    initialValues,
    error,
    onCreate,
    onEdit,
    onDelete,
    renderer,
  };
};
