import { Button, Flex, IconButton } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { AppState } from '../../../../store/root-reducers';
import useChangeRoute from '../hooks/useChangeRoute';

interface IProps {
  Icon: React.ElementType;
  children: ReactNode;
  routes?: string[];
  id?: string;
  ariaLabel?: string;
  onClick?: () => void;
}

export const MenuItem = ({ Icon, children, routes = [], id, ariaLabel, onClick }: IProps) => {
  const { changeRoute } = useChangeRoute();
  const isOpen = useSelector((state: AppState) => state.sideMenuState.isOpen);
  const location = useLocation();

  const customMatch = (loc) => {
    const route = routes[0];
    return route === loc.pathname + loc.search;
  };

  const pathname = location.pathname;
  const isActive = !!matchPath(pathname, { path: routes, exact: true }) || customMatch(location);

  return (
    <>
      {isOpen ? (
        <Button
          id={id}
          onClick={
            onClick ? onClick : () => (routes.length > 0 ? changeRoute(routes[0]) : undefined)
          }
          borderRadius="0.5rem"
          variant="menu"
          justifyContent="flex-start"
          backgroundColor={isActive ? 'sidebar.menu.background' : 'transparent'}
          color={isActive ? 'sidebar.menu.color' : '#000000'}
          _hover={{
            background: 'sidebar.menu.background',
            color: 'sidebar.menu.color',
          }}
          sx={{
            '> .chakra-button__icon': {
              marginInlineEnd: '0.75rem',
            },
          }}
          // leftIcon={<Icon h="24px" w="24px" />}
        >
          <Flex justify={isOpen ? 'space-between' : 'center'} align="center">
            <Icon h="24px" w="24px" mr={isOpen ? 4 : 0} />
            {children}
          </Flex>
        </Button>
      ) : (
        <IconButton
          id={id}
          onClick={() => (routes.length > 0 ? changeRoute(routes[0]) : undefined)}
          w="50px"
          p={2}
          borderRadius="0.5rem"
          backgroundColor={isActive ? 'sidebar.menu.background' : 'transparent'}
          color={isActive ? 'sidebar.menu.color' : '#000000'}
          _hover={{
            background: 'sidebar.menu.background',
            color: 'sidebar.menu.color',
          }}
          aria-label={ariaLabel ?? 'Menu'}
          icon={<Icon h="24px" w="24px" />}
        />
      )}
    </>
  );
};
