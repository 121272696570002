import { Box, HStack, Text } from '@chakra-ui/react';
import { ChangeEventHandler, FC } from 'react';

import { InputControl, SelectComponent } from '../../../../../../../components/forms';
import { enumToOptions } from '../../../../../../../utils';

import { DurationTimeUnitEnum } from '../enums';
import { DurationTimeUnitType } from '../types';

type SymptomsDurationProps = {
  onChangeSymptomDuration: ChangeEventHandler<HTMLInputElement>;
  durationTimeValue: number | string;
  durationTimeUnit: DurationTimeUnitType;
  onChangeDurationTimeUnit: (value: DurationTimeUnitType) => void;
};

const SymptomsDuration: FC<SymptomsDurationProps> = (props) => (
  <Box>
    <Text textTransform="uppercase" fontSize="12px" color="rgb(0, 3, 16)" mb="3">
      Duration
    </Text>
    <HStack spacing="1">
      <Box>
        <InputControl
          name="symptomDuration"
          type="text"
          placeholder="0"
          value={props.durationTimeValue}
          onChange={props.onChangeSymptomDuration}
        />
      </Box>
      <Box width="150px">
        <SelectComponent
          options={enumToOptions(DurationTimeUnitEnum)}
          name="durationTimeUnit"
          valueKey="id"
          labelKey="description"
          placeholder="Unit"
          value={props.durationTimeUnit}
          onChange={props.onChangeDurationTimeUnit}
          menuPosition="fixed"
          isClearable={false}
        />
      </Box>
    </HStack>
  </Box>
);

export { SymptomsDuration };
