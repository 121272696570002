import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { PhoneEventsEnum } from '../../constants';
import { AppState } from '../../store/root-reducers';

import { useGeolocation } from '../useGeolocation';
import { useLocationPermissionSate } from '../useLocationPermissionSate';

type UseLocationNotificationModal = {
  showOnLoad?: boolean;
};

const useLocationNotificationModal = (props: UseLocationNotificationModal = {}) => {
  const { showOnLoad } = props;

  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  const { permissionState } = useLocationPermissionSate();

  const {
    isOpen: isNotificationModalOpen,
    onClose: onCloseNotificationModal,
    onOpen: onOpenNotificationModal,
  } = useDisclosure();

  const { getCurrentPosition, position, positionError, clearPositionError, clearPosition } =
    useGeolocation();

  const requestLocationCount = useRef(0);

  const isInMobileView = currentUser?.isInMobileView;
  const isMobileSystem = currentUser?.mobileSystem;

  const handleWebViewLocationCallback = useCallback(() => {
    if (isInMobileView && isMobileSystem === 'android') {
      // @ts-ignore
      AndroidResult.callback(PhoneEventsEnum.REQUEST_LOCATION_CALLBACK);
    } else if (isInMobileView && isMobileSystem === 'ios') {
      // @ts-ignore
      webkit.messageHandlers.requestLocationCallbackHandler.postMessage(
        PhoneEventsEnum.REQUEST_LOCATION_CALLBACK,
      );
    }
  }, [isInMobileView, isMobileSystem]);

  const onDismissNotificationModal = useCallback(() => {
    onCloseNotificationModal();
    handleWebViewLocationCallback();
    getCurrentPosition();
  }, [getCurrentPosition, handleWebViewLocationCallback, onCloseNotificationModal]);

  const onDismissNotificationModalError = useCallback(() => {
    onCloseNotificationModal();
    clearPositionError();
  }, [clearPositionError, onCloseNotificationModal]);

  const onRequestLocation = useCallback(() => {
    if (requestLocationCount.current === 0 && permissionState !== 'granted') {
      onOpenNotificationModal();
      requestLocationCount.current += 1;

      return;
    }

    handleWebViewLocationCallback();
    getCurrentPosition();
  }, [getCurrentPosition, handleWebViewLocationCallback, onOpenNotificationModal, permissionState]);

  const onRequestLocationNoPrompt = useCallback(() => {
    handleWebViewLocationCallback();
    getCurrentPosition();
  }, [getCurrentPosition, handleWebViewLocationCallback]);

  useEffect(() => {
    if (showOnLoad && permissionState) {
      onRequestLocation();
    }
  }, [onRequestLocation, permissionState, showOnLoad]);

  return {
    isNotificationModalOpen,
    onDismissNotificationModal,
    onDismissNotificationModalError,
    position,
    positionError,
    getCurrentPosition,
    clearPositionError,
    clearPosition,
    onRequestLocation,
    onRequestLocationNoPrompt,
  };
};

export { useLocationNotificationModal };
