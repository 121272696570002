import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

interface IIconWrapperProps {
  readonly active: boolean;
}

const IconWrapper = styled.div<IIconWrapperProps>`
  text-align: center;
  margin-right: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid
    ${(props) => (props.active ? props.theme.Colors.DarkBlue : 'transparent')};
`;

const NavIcon = styled.button`
  border-radius: 50px;
  height: 50px;
  width: 50px;
  background-color: rgba(27, 61, 113, 0.15);
  color: ${(props) => props.theme.Colors.DarkBlue};
  border: none;
  margin-bottom: 0.25rem;
`;

const Text = styled.p`
  text-transform: uppercase;
  color: ${(props) => props.theme.Colors.DarkBlue};
  font-size: 12px;
  text-align: center;
`;

interface IProps {
  active: boolean;
  onClick: () => void;
  icon: FontAwesomeIconProps['icon'];
  description: string;
}

const RoundNavButton = ({ active, onClick, icon, description }: IProps) => {
  return (
    <IconWrapper active={active}>
      <NavIcon onClick={onClick}>
        <FontAwesomeIcon icon={icon} size="lg" />
      </NavIcon>
      <Text>{description}</Text>
    </IconWrapper>
  );
};

export { RoundNavButton };
