const routeWithWindow = (path: string) => {
  if (window) {
    const currentUrl = window.location.href;
    const currentPath = window.location.pathname;

    if (currentPath !== path) {
      const newUrl = `${currentUrl.split(currentPath)[0]}${path}`;

      window.location.assign(newUrl);
    }
  }
};

export { routeWithWindow };
