import { Form as FormikForm } from 'formik';

interface IProps {
  className?: string;
  error?: string;
  children: any | any[];
  style?: any;
}

const Form = ({ className, children, style }: IProps) => {
  return (
    // @ts-ignore
    <FormikForm style={style} className={className} noValidate>
      {children}
      {/* <FormikErrorContext error={error} /> */}
      {/* <FocusError /> */}
    </FormikForm>
  );
};

export default Form;
