import { Flex, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { HomeCareIcon, UserIconAlt, VideoIcon, WalletIcon } from '../../../../assets/icons';
import useTranslationComponent from '../../../../hooks/useTranslationComponent';
import { usePartnerSettings } from '../../../../services/partners/usePartnerSettings';

type ProviderSupportsProps = {
  supportsVideoConsults?: boolean;
  supportsInPersonConsults?: boolean;
  supportsHomeCare?: boolean;
};

const ProviderSupports: FC<ProviderSupportsProps> = (props) => {
  const { t } = useTranslationComponent(['network']);
  const { partnerNetworkInPersonConsults, partnerNetworkVideoConsults } = usePartnerSettings();

  return (
    <Flex flexDirection="column" flexWrap="wrap">
      <HStack p="2">
        <WalletIcon fontSize="22px" color="#008753" />
        <Text fontSize="14px">{t('Accepts outpost wallet')}</Text>
      </HStack>
      {props.supportsInPersonConsults && (
        <HStack py="1" px="2">
          <UserIconAlt fontSize="26px" color="purple.600" />
          <Text fontSize="14px">{t(partnerNetworkInPersonConsults ?? 'In-Person consults')}</Text>
        </HStack>
      )}
      {props.supportsVideoConsults && (
        <HStack py="1" px="2">
          <VideoIcon fontSize="30px" color="#F69C17" />
          <Text fontSize="14px">{t(partnerNetworkVideoConsults ?? 'Video consults')}</Text>
        </HStack>
      )}
      {props.supportsHomeCare && (
        <HStack py="1" px="2">
          <HomeCareIcon fontSize="26px" />
          <Text fontSize="14px">{t('Home Care')}</Text>
        </HStack>
      )}
    </Flex>
  );
};

export { ProviderSupports };
