import React from 'react';
import { useSelector } from 'react-redux';
import { MarketingCampaignUserDetail } from '../../../../generated';
import { AppState } from '../../../../store/root-reducers';
import { InvitedNote } from './InvitedNote';
import { InviteeNote } from './InviteeNote';

interface IProps {
  marketingCampaignUserDetail: MarketingCampaignUserDetail;
  acknowledgeReward: () => void;
}

export const ClaimNote = ({ marketingCampaignUserDetail, acknowledgeReward }: IProps) => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  return marketingCampaignUserDetail.userDetailID === currentUser?.userDetailID ? (
    <InviteeNote
      acknowledgeReward={acknowledgeReward}
      marketingCampaign={marketingCampaignUserDetail.marketingCampaign!}
    />
  ) : (
    <InvitedNote
      acknowledgeReward={acknowledgeReward}
      marketingCampaign={marketingCampaignUserDetail.marketingCampaign!}
    />
  );
};
