import { Client, Conversation, Message, Participant } from '@twilio/conversations';
import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { ConsultStatusEnum } from '../../../../generated';
import { parseParticipantIdentity } from '../../utils';
// import { Message } from 'twilio-chat/lib/message';

export type ChannelEvents =
  | 'messageAdded'
  | 'messageRemoved'
  | 'typingStarted'
  | 'typingEnded'
  | 'memberJoined'
  | 'memberLeft';
export type ClientEvents = 'channelAdded' | 'channelRemoved' | 'tokenExpired';

interface IProps {
  token?: string;
  uniqueName: string;
  userDetailID: string;
  consultStatus: ConsultStatusEnum;
}

const COLOR_CODES = ['#1b3d71', '#ee6a4c', '#73627c', '#003a45', '#eac234'];

export default function useChat({ token, uniqueName, userDetailID, consultStatus }: IProps) {
  const [client, setClient] = useState<Client | null>(null);
  // const [client, setClient] = useState<Chat | null>(null);
  const [channel, setChannel] = useState<Conversation | null>(null);
  // const [channel, setChannel] = useState<Channel | null>(null);
  const [error, setError] = useState<string>('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [membersTyping, setMembersTyping] = useState<Participant[]>([]);
  // const [membersTyping, setMembersTyping] = useState<Member[]>([]);
  const [members, setMembers] = useState<Participant[]>([]);
  // const [members, setMembers] = useState<Member[]>([]);
  const [isConnected, setConnected] = useState<boolean>(false);
  const [memberColors, setMemberColors] = useState<any>({});
  const [isTypingMessage, setTypingMessage] = useState<string>('');

  const sendMessage = async (message: string) => {
    if (channel) {
      channel.sendMessage(message);
    } else {
      setError('Not Connected to Channel.');
    }
  };

  const sendTypingIndicator = async () => {
    if (channel) {
      channel?.typing();
    }
  };

  const onMessageAdded = async (message: Message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  const onMessageRemoved = async (message: Message) => {
    setMessages((prevMessages) => [...prevMessages.filter((m) => m.sid !== message.sid)]);
  };

  const onMemberJoined = async (member: Participant) => {
    setMemberColors((mc) => {
      const newMemberColors = { ...mc };
      newMemberColors[member.sid] = COLOR_CODES[Object.keys(mc).length];
      return newMemberColors;
    });
    setMembers((prevMembers) => [...prevMembers, member]);
  };

  const onMemberLeft = async (member: Participant) => {
    setMembers((prevMembers) => [...prevMembers.filter((m) => m.sid !== member.sid)]);
  };

  const onTypingsStarted = async (member: Participant) => {
    setMembersTyping((prevMembers) =>
      [...prevMembers, member].filter((v, i, a) => a.findIndex((t) => t.sid === v.sid) === i),
    );
  };

  const onTypingsEnded = async (member: Participant) => {
    setMembersTyping((prevMembers) => [...prevMembers.filter((m) => m.sid !== member.sid)]);
  };

  // Create a member is typing message
  useEffect(() => {
    if (membersTyping.length === 0) {
      setTypingMessage('');
    } else if (membersTyping.length === 1) {
      setTypingMessage(
        `${parseParticipantIdentity(membersTyping[0].identity || '')?.fullName} is typing...`,
        // `${parseParticipantIdentity(membersTyping[0].identity).fullName} is typing...`,
      );
    } else {
      setTypingMessage('Multiple people are typing...');
    }
  }, [membersTyping]);

  // Get all previous messages
  useEffect(() => {
    const loadAllMessages = async (channel) => {
      try {
        const allMessages = await channel.getMessages();
        setMessages(allMessages?.items ?? []);
      } catch (err) {
        // eat error
      }
    };
    if (channel) {
      loadAllMessages(channel);
    }
  }, [channel]);

  // Register Channel Events
  useEffect(() => {
    if (channel) {
      channel.on('messageAdded', onMessageAdded);
      channel.on('messageRemoved', onMessageRemoved);
      channel.on('participantJoined', onMemberJoined);
      channel.on('participantLeft', onMemberLeft);
      // channel.on('memberJoined', onMemberJoined);
      // channel.on('memberLeft', onMemberLeft);
      channel.on('typingStarted', onTypingsStarted);
      channel.on('typingEnded', onTypingsEnded);
      return () => {
        channel.off('messageAdded', onMessageAdded);
        channel.off('messageRemoved', onMessageRemoved);
        channel.on('participantJoined', onMemberJoined);
        channel.on('participantLeft', onMemberLeft);
        // channel.off('memberJoined', onMemberJoined);
        // channel.off('memberLeft', onMemberLeft);
        channel.off('typingStarted', onTypingsStarted);
        channel.off('typingEnded', onTypingsEnded);
      };
    }
  }, [channel]);

  // Set Member Colors
  useEffect(() => {
    if (channel) {
      channel.getParticipants().then((members) => {
        // Set current user as the first member
        const sortedMembers = members.sort((a, b) =>
          parseParticipantIdentity(a.identity || '')?.userDetailID === userDetailID
            ? -1
            : parseParticipantIdentity(b.identity || '')?.userDetailID === userDetailID
            ? 1
            : 0,
        );
        setMembers(sortedMembers);
        sortedMembers.forEach((m) => {
          setMemberColors((mc) => {
            const newMemberColors = { ...mc };
            newMemberColors[m.sid] = COLOR_CODES[Object.keys(mc).length];
            return newMemberColors;
          });
        });
      });
    }
  }, [channel, userDetailID]);

  // Connect to Channel
  useEffect(() => {
    const connectToChannel = async (client: Client) => {
      try {
        const channel = await client.getConversationByUniqueName(uniqueName);
        setChannel(channel);
        if (
          consultStatus !== ConsultStatusEnum.PatientCompleted &&
          consultStatus !== ConsultStatusEnum.Completed
        ) {
          setConnected(true);
        }
      } catch (err) {
        const error: any = err;
        setConnected(false);
        setError(error.message);
      }
    };

    if (client) {
      connectToChannel(client);
    }
  }, [client, consultStatus, uniqueName]);

  // Register Chat Events
  useEffect(() => {
    if (client) {
      client.on('tokenExpired', () => {
        console.log('refresh token');
      });
    }
  }, [client]);

  // Initialize Chat
  useEffect(() => {
    const initializeClient = async (token: string) => {
      try {
        // let client = await Chat.create(token);
        const client = new Client(token);
        setClient(client);
      } catch (err) {
        const error: any = err;
        setConnected(false);
        setError(error.message);
      }
    };

    if (token) {
      const currentTime = Date.now().valueOf() / 1000;
      const decodedToken = jwt_decode(token) as Record<string, any>;
      const expiry = decodedToken.exp;
      // We can do this, because in the ConsultComponent we ensure to renew the token if possible
      // We won't be able to renew the token if the consult is completed.
      if (expiry < currentTime) {
        setConnected(false);
      } else {
        initializeClient(token);
      }
    }
  }, [token]);

  useEffect(() => {
    if (consultStatus === ConsultStatusEnum.PatientCompleted) {
      setConnected(false);
    }
  }, [consultStatus]);

  return {
    messages,
    error,
    isConnected,
    membersTyping,
    members,
    memberColors,
    isTypingMessage,
    sendMessage,
    sendTypingIndicator,
  };
}
