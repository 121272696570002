import { AuthClient, Session, SessionClient } from '../generated';
import { httpRequest } from '../utils';

const sessionPost = async (session: Session) => {
  const response = await httpRequest(() =>
    new SessionClient(new AuthClient()).sessionPost(session),
  );

  return response;
};

export { sessionPost };
