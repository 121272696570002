import { useEffect, useState } from 'react';
import {
  AuthClient,
  ProviderStoreItem,
  ProviderStoreItemsClient,
} from '../../../../../../../generated';
import { httpRequest } from '../../../../../../../utils';

interface IProps {
  providerStoreItemID: string | null;
  setProviderStoreItem: (providerStoreItem: ProviderStoreItem) => void;
}

const useLoadInitialProviderStoreItem = ({ providerStoreItemID, setProviderStoreItem }: IProps) => {
  const [error, setError] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadItem = async (providerStoreItemID: string) => {
      try {
        setLoading(true);
        const result = await httpRequest(() =>
          new ProviderStoreItemsClient(new AuthClient()).providerStoreItemsGet(providerStoreItemID),
        );
        setProviderStoreItem(result);
      } catch (err) {
        setError(err as any);
      }
      setLoading(false);
    };

    if (providerStoreItemID) {
      loadItem(providerStoreItemID);
    }
  }, [providerStoreItemID, setProviderStoreItem]);

  return { error, isLoading };
};

export default useLoadInitialProviderStoreItem;
