import { Accordion, Box } from '@chakra-ui/react';
import useAddressBooksContext from '../../context/AddressBooksProvider';
import { AddressBookCard } from '../AddressBookCard/AddressBookCard';

interface IAddressBookListProps {
  onEdit?: () => void;
}

export const AddressBookList = ({ onEdit }: IAddressBookListProps) => {
  const { searchResult } = useAddressBooksContext();
  return (
    <Accordion allowToggle width="100%">
      {searchResult?.pages?.map((group, indexI) => {
        return group?.items?.map((item, indexJ) => {
          return (
            <Box key={`${indexI}${indexJ}`} mb="3">
              <AddressBookCard addressBook={item} onEdit={onEdit} />
            </Box>
          );
        });
      })}
    </Accordion>
  );
};
