import { PatientOrder } from '../../../../generated';
import { getAdditionalItems } from './getAdditionalItems';
import { getSortedPhrMedications } from './getSortedPhrMedications';

const getLabData = (patientOrder?: PatientOrder) => {
  const { sortByName } = getSortedPhrMedications();

  const patientOrderItems = patientOrder?.patientOrderItems ?? [];

  const invoice = patientOrder?.invoice;

  const currencyEnum = invoice?.currencyEnum;
  const taxLabel = invoice?.taxLabel;
  const totalAmount = invoice?.totalAmount;

  const invoiceStoreItems = invoice?.invoiceStoreItems ?? [];

  const sortedPatientOrderItemsByName = sortByName(patientOrderItems);
  const additionalItems = getAdditionalItems(patientOrderItems, invoiceStoreItems);

  return {
    patientOrderItems,
    sortedPatientOrderItemsByName,
    additionalItems,
    currencyEnum,
    taxLabel,
    totalAmount,
  };
};

export { getLabData };
