import { Box, VStack } from '@chakra-ui/react';
import { Formik } from 'formik';
import { Dispatch, FC, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import { PrimaryButton, OutlineButton } from '../../../../../components/Buttons';
import { Form, FormInputControl, FormPasswordControl } from '../../../../../components/forms';
import HelmetWrapper from '../../../../../components/HelmetWrapper';
import { CenteredLoadingIndicator } from '../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../components/ServerValidationBox';
import { UserSignInDetails } from '../../../../../generated';
import useTranslationComponent from '../../../../../hooks/useTranslationComponent';
import { mixTrack } from '../../../../../lib/mixpanel';
import { signIn } from '../../../../../store/root-creator';
import { AppState } from '../../../../../store/root-reducers';
import { SignupFlowEnum } from '../enums';

type LoginProps = {
  controller: {
    setCurrentStage: Dispatch<SetStateAction<SignupFlowEnum>>;
    onProceed: () => void;
  };
};
interface IForm {
  email: string;
  smsNumber: string;
  careCardNumber: string;
  password: string;
  pin: string;
}

const initialValues: IForm = {
  email: '',
  smsNumber: '',
  careCardNumber: '',
  password: '',
  pin: '',
};

const Login: FC<LoginProps> = (props) => {
  const { t } = useTranslationComponent('signin');
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector((state: AppState) => state.currentUserState);

  const login = (values: IForm) => {
    mixTrack({
      eventName: 'login:login_auth_clicked',
    });

    const details = new UserSignInDetails();
    details.emailOrUniqueLifetimeID = values.email?.trim();
    details.passwordOrPIN = values.password.trim();
    dispatch(signIn(details, props.controller?.onProceed));
  };

  return (
    <>
      <HelmetWrapper title="Login" />
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          email: string()
            .trim()
            .required('Email address is required')
            .email('Must be a valid email address')
            .max(256, 'Email address must be at most 256 characters'),
          password: string()
            .required('Password is required')
            .max(256, 'Password must be at most 256 characters'),
        })}
        onSubmit={(values) => login(values)}
      >
        {() => (
          <Form style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            {isLoading ? (
              <CenteredLoadingIndicator />
            ) : (
              <Box display="flex" flexDirection="column" flexGrow={1} mt={2}>
                <ServerValidationBox message={error} />

                <VStack gap={2}>
                  <FormInputControl
                    size="lg"
                    tabIndex={1}
                    autoComplete="on"
                    name="email"
                    type="email"
                    label={t('Email Address')}
                    placeholder="your email"
                    id="emailId"
                    formControlProps={{
                      mb: '0',
                    }}
                  />
                  <FormPasswordControl
                    autoComplete="on"
                    size="lg"
                    name="password"
                    label="Password"
                    placeholder="minimum 8 characters"
                    id="passwordId"
                    tabIndex={2}
                    formControlProps={{
                      mb: '0',
                    }}
                  />
                </VStack>

                <VStack mt="auto" pt={12} pb={1} gap={2}>
                  <PrimaryButton w="100%" tabIndex={3} size="lg" type="submit">
                    {t('continue')}
                  </PrimaryButton>
                  <OutlineButton
                    w="100%"
                    tabIndex={3}
                    size="lg"
                    onClick={() => props.controller.setCurrentStage(SignupFlowEnum.Signup)}
                  >
                    {t('sign up here')}
                  </OutlineButton>
                </VStack>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export { Login };
