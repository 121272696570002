import { HStack, PinInput, PinInputField, PinInputProps, StackProps } from '@chakra-ui/react';
import { FastField, useFormikContext } from 'formik';
import React from 'react';
import { BaseProps } from '../baseProps';
import { FormControl } from '../FormControl/FormControl';

export type PinInputControlProps = BaseProps &
  Omit<PinInputProps, 'children'> & {
    pinLength: number;
    justify?: string;
    stackProps?: StackProps;
  };

export const PinInputControl = (props: PinInputControlProps) => {
  const { name, label, pinLength, stackProps, justify, formControlProps, ...rest } = props;
  const renderedPinInputFields = [...Array(pinLength)].map((_noop, i) => (
    <PinInputField
      background="white"
      border="1px solid"
      borderColor="purple.500"
      key={i}
      w={[8, 12]}
      h={[9, 12]}
    />
  ));
  const { justifyContent = justify || 'center', ...stackPropsRest } = stackProps || {};

  return (
    <FormControl name={name} label={label} {...formControlProps}>
      <HStack
        justifyContent={justifyContent}
        {...stackPropsRest}
        backgroundColor="#F8F5FE"
        py={[6, 6]}
        px={[6, 12]}
        mx="auto"
        borderRadius="xl"
        width="fit-content"
        maxW="100%"
      >
        <PinInput {...rest}>{renderedPinInputFields}</PinInput>
      </HStack>
    </FormControl>
  );
};

export type FormPinInputControlProps = PinInputControlProps;

export const FormPinInputControl = (props: FormPinInputControlProps) => {
  const { name, formControlProps, ...rest } = props;
  const { setFieldValue } = useFormikContext();

  return (
    <FastField name={name}>
      {({ field, meta }) => (
        <PinInputControl
          {...rest}
          {...field}
          onChange={(value: string) => setFieldValue(name, value)}
          formControlProps={{ ...meta, ...formControlProps }}
          isInvalid={meta.touched && meta.error}
        />
      )}
    </FastField>
  );
};
