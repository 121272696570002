import { Button, ButtonProps } from '@chakra-ui/react';

type FloatingActionButtonProps = ButtonProps;

export const FloatingActionButton = (props: FloatingActionButtonProps) => {
  const {
    children,
    padding = 0,
    position = 'absolute',
    height = '60px',
    width = '60px',
    bottom = '10px',
    right = '10px',
    ...rest
  } = props;

  return (
    <Button
      position={position}
      padding={padding}
      borderRadius="50%"
      height={height}
      width={width}
      backgroundColor="purple.600"
      bottom={bottom}
      right={right}
      {...rest}
    >
      {children}
    </Button>
  );
};
