import { Box, Flex } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PHRHealthJournal } from '../../../generated';
import { clearPHRPatientErrorAction } from '../../../store/patient/phr/phrActions';
import { IPHRPatientCombined } from '../../../store/patient/phr/phrReducers';
import { phrHealthJournalsSelector } from '../../../store/patient/phr/phrSelectors';
import { deleteForPhrSection, getAllForPhrSection } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { scrollToTop } from '../../../utils/scrollToTop';

import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import ServerValidationBox from '../../../components/ServerValidationBox';
import { AddPHRItemButton } from '../components/PhrItem';
import PhrItemDeleteModal from '../components/PhrItemDeleteModal';
import { PhrFormButtons } from '../components/PhrItemForm';
import { PhrDetailsButtons } from '../components/PhrItemView';
import HealthJournalForm from './HealthJournalForm';
import HealthJournalList from './HealthJournalList';
import HealthJournalView from './HealthJournalView';

enum HealthJournalViewState {
  List = 'List',
  View = ' View',
  Edit = 'Edit',
}

interface IProps {
  patient: IPHRPatientCombined;
  renderHeader: (onCreate?: any, onBack?: () => void, title?: string, showDismiss?: boolean) => any;
}

const PHRHealthJournalComponent = ({ patient: phrPatient, renderHeader }: IProps) => {
  const dispatch = useDispatch();
  const innerBtnRef = useRef<HTMLButtonElement>(null);
  const phrHealthJournal = phrHealthJournalsSelector(phrPatient.phr.phrHealthJournals);
  const { isPhrLoading, error } = useSelector((state: AppState) => state.phrPatientState);
  const [recordToDelete, setRecordToDelete] = useState<string>('');
  const [selectedHealthJournal, setSelectedHealthJournal] = useState<PHRHealthJournal | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [currentView, setCurrentView] = useState<HealthJournalViewState>(
    HealthJournalViewState.List,
  );

  useEffect(() => {
    if (phrPatient) {
      dispatch(
        getAllForPhrSection(ReduxPHRCategoryEnum.PHRHealthJournals, phrPatient.phr.patientID),
      );
    }
    // avoid re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onView = (phrHealthJournal: PHRHealthJournal) => {
    setSelectedHealthJournal(phrHealthJournal);
    setCurrentView(HealthJournalViewState.View);
  };

  const onCreate = () => {
    setSelectedHealthJournal(null);
    setCurrentView(HealthJournalViewState.Edit);
  };

  const onEdit = (phrHealthJournal: PHRHealthJournal) => {
    setSelectedHealthJournal(phrHealthJournal);
    setCurrentView(HealthJournalViewState.Edit);
  };

  const onDelete = (phrHealthJournalID: string) => {
    setShowDeleteModal(true);
    setRecordToDelete(phrHealthJournalID);
  };

  const confirmDelete = async () => {
    setCurrentView(HealthJournalViewState.List);
    setShowDeleteModal(false);
    dispatch(
      deleteForPhrSection(
        ReduxPHRCategoryEnum.PHRHealthJournals,
        recordToDelete,
        phrPatient.phr.patientID,
        false,
      ),
    );
  };

  useEffect(() => {
    dispatch(clearPHRPatientErrorAction());
    scrollToTop();
  }, [currentView, dispatch]);

  return (
    <Box paddingY="2rem" backgroundColor="#f4f4f4" height="100%">
      {currentView === HealthJournalViewState.List ? (
        renderHeader()
      ) : (
        <Flex>
          {renderHeader(null, () => setCurrentView(HealthJournalViewState.List), '', false)}
          {currentView === HealthJournalViewState.View && (
            <PhrDetailsButtons
              isVerified={false}
              phrItem={selectedHealthJournal}
              phrItemID={selectedHealthJournal?.phrHealthJournalID}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          )}
          {currentView === HealthJournalViewState.Edit && (
            <PhrFormButtons
              innerBtnRef={innerBtnRef}
              toggleView={() => setCurrentView(HealthJournalViewState.List)}
            />
          )}
        </Flex>
      )}

      {currentView === HealthJournalViewState.List && (
        <>
          <AddPHRItemButton name="Health Journal" onCreate={onCreate} />
          <ServerValidationBox message={error} />
          {isPhrLoading ? (
            <Box marginTop="1rem">
              <CenteredLoadingIndicator />
            </Box>
          ) : (
            <HealthJournalList phrHealthJournal={phrHealthJournal} onView={onView} />
          )}
        </>
      )}

      {currentView === HealthJournalViewState.View && (
        <HealthJournalView phrHealthJournal={selectedHealthJournal!} />
      )}

      {currentView === HealthJournalViewState.Edit && (
        <HealthJournalForm
          selectedHealthJournal={selectedHealthJournal}
          patientID={phrPatient.phr.patientID}
          toggleView={() => setCurrentView(HealthJournalViewState.List)}
          innerBtnRef={innerBtnRef}
        />
      )}

      <PhrItemDeleteModal
        isOpen={showDeleteModal}
        onDismiss={() => setShowDeleteModal(false)}
        onDelete={confirmDelete}
      />
    </Box>
  );
};

export default PHRHealthJournalComponent;
