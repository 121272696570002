import { CheckCircleIcon, NotAllowedIcon } from '@chakra-ui/icons';
import { Box, chakra, List, ListIcon, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import useTranslationComponent from '../../../hooks/useTranslationComponent';

interface IProps {
  password?: string;
}

const PasswordRequirmentsText = ({ password }: IProps) => {
  const { t } = useTranslationComponent(['signup']);

  return (
    <>
      {password ? (
        <Box mb={4} mt={4}>
          <Text mb={2} fontSize="sm">
            {t('Your password must be at least 8 characters long and contain')}:
          </Text>
          <List>
            <Item ml={2} fontSize="12px">
              <ListIcon
                as={/[^a-zA-Z0-9]/.test(password) ? CheckCircleIcon : NotAllowedIcon}
                color={/[^a-zA-Z0-9]/.test(password) ? 'green.500' : '#333'}
              />
              {t('At least one non-alphanumeric character')}
            </Item>
            <Item fontSize="12px">
              <ListIcon
                as={/[A-Z]/.test(password) ? CheckCircleIcon : NotAllowedIcon}
                color={/[A-Z]/.test(password) ? 'green.500' : '#333'}
              />
              {t('At least one uppercase letter')}
            </Item>
            <Item fontSize="12px">
              <ListIcon
                as={/[a-z]/.test(password) ? CheckCircleIcon : NotAllowedIcon}
                color={/[a-z]/.test(password) ? 'green.500' : '#333'}
              />
              {t('At least one lowercase letter')}
            </Item>
            <Item fontSize="12px">
              <ListIcon
                as={/\d/.test(password) ? CheckCircleIcon : NotAllowedIcon}
                color={/\d/.test(password) ? 'green.500' : '#333'}
              />
              {t('At least one digit')}
            </Item>
            <Item fontSize="12px">
              <ListIcon
                as={password.length >= 8 ? CheckCircleIcon : NotAllowedIcon}
                color={password.length >= 8 ? 'green.500' : '#333'}
              />
              {t('At least 8 characters')}
            </Item>
          </List>
        </Box>
      ) : (
        <Box mb={4} mt={4}>
          <Text mb={2} fontSize="sm">
            {t('Your password must be at least 8 characters long and contain')}:
          </Text>
          <UnorderedList>
            <Item ml={2} fontSize="12px">
              {t('At least one non-alphanumeric character')}
            </Item>
            <Item fontSize="12px">{t('At least one uppercase letter')}</Item>
            <Item fontSize="12px">{t('At least one lowercase letter')}</Item>
            <Item fontSize="12px">{t('At least one digit')}</Item>
            <Item fontSize="12px">{t('At least 8 characters')}</Item>
          </UnorderedList>
        </Box>
      )}
    </>
  );
};

export default PasswordRequirmentsText;

const Item = chakra(ListItem, { baseStyle: { ml: 2 } });
