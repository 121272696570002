import { Tab as ChakraTab, chakra } from '@chakra-ui/react';

export const Tab = chakra(ChakraTab, {
  baseStyle: {
    fontSize: '14px',
    fontWeight: '500',
    py: 2,
    px: 8,
    borderTopRadius: '0.7rem',
    borderBottom: 'none',
    _selected: {
      color: 'white',
    },
    mb: 0,
  },
});
