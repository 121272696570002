import { useQuery } from 'react-query';
import { AuthClient, FormQuestionsClient, FormQuestion } from '../../generated';
import { httpRequest } from '../../utils';

interface IProps {
  formID: string;
}

export const useFormQuestions = ({ formID }: IProps) => {
  const {
    isLoading: isLoadingQuestions,
    data: formQuestions,
    error: questionsError,
  } = useQuery<FormQuestion[], string>(
    ['FormQuestionsByFormID', { formID }],
    async ({ queryKey }) => {
      // @ts-ignore
      const [, { formID }] = queryKey;
      const questions = await httpRequest(() =>
        new FormQuestionsClient(new AuthClient()).byFormID(formID),
      );
      return questions.sort((a, b) => a.displaySortOrder - b.displaySortOrder);
    },
    { staleTime: Infinity },
  );

  return {
    isLoadingQuestions,
    formQuestions,
    questionsError,
  };
};
