import { Box, Flex, HStack, Stack, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { PlusIcon, ShoppingCartIcon } from '../../../../../../../assets/icons';
import { PurpleButton } from '../../../../../../../components/Buttons';

import { SwitchControl } from '../../../../../../../components/forms/Switch';
import { CenteredLoadingIndicator } from '../../../../../../../components/LoadingIndicator';

import ServerValidationBox from '../../../../../../../components/ServerValidationBox';
import { Tag } from '../../../../../../../components/TagComponent/Tag';
import { InvoiceStoreItem, PatientOrderItem } from '../../../../../../../generated';
import { AppState } from '../../../../../../../store/root-reducers';
import { OutpostTheme } from '../../../../../../../themes/outpost';
import { formatCurrency } from '../../../../../../../utils';
import { arrayHasData, getSum } from '../../../../../consultMedications/utils';
import { OrdersTypeEnum } from '../../../../enums';
import { getLabData } from '../../../../utils';

import { IOrderItemOption } from '../../contexts/CreateOrderContext/useAddOrderTests/useAddOrderTests';
import useCreateOrderContext from '../../hooks/useCreateOrderContext';

import { NoTestsSelected } from './NoTestsSelected';
import { OrderForm } from './OrderForm';
import { SearchTestForm } from './SearchTestForm';
import useTranslationComponent from '../../../../../../../hooks/useTranslationComponent';

enum TestViewEnum {
  AddTests = 'AddTests',
  ViewTests = 'ViewTests',
}

const showSwitchOnPatientLab = (
  orderTypeEnum: OrdersTypeEnum,
  totalAmount?: number,
  alwaysShow?: boolean,
) => {
  if (alwaysShow) return true;
  if (orderTypeEnum !== OrdersTypeEnum.PatientOnly) return true;
  if (totalAmount && totalAmount > 0) return true;

  return false;
};

export const AddOrderTests = () => {
  const { t } = useTranslationComponent(['phr']);
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  const {
    orderName,
    orderItemOptions,
    getOrderItemByOrderItemID,
    isLoadingOrder,
    orderError,
    errorUpdating,
    setIsEditTest,
    isEditTest,
    controller,
    isClinicalOrder,
  } = useCreateOrderContext();

  const [testView, setTestView] = useState(TestViewEnum.AddTests);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const expressLabOrderData = getLabData(controller.expressLabOrder);
  const selectedLabOrderData = getLabData(controller.selectedLabOrder);
  const patientLabData = getLabData(controller.patientOnlyOrder);

  const ordersTotalAmount = getSum([
    selectedLabOrderData.totalAmount,
    expressLabOrderData.totalAmount,
  ]);

  const changeTestView = useCallback((nextView: TestViewEnum) => {
    setTestView(nextView);
  }, []);

  const showAddTest = isMobile ? testView === TestViewEnum.AddTests : true;
  const showViewTest = isMobile ? testView === TestViewEnum.ViewTests : true;

  const hasOrdersTotalAmount = !!ordersTotalAmount && ordersTotalAmount > 0;

  const currencyEnum = selectedLabOrderData.currencyEnum || expressLabOrderData.currencyEnum;

  const taxLabel = selectedLabOrderData.taxLabel || expressLabOrderData.taxLabel;

  if (orderError) {
    return <ServerValidationBox message={orderError} />;
  }

  if (controller.isLoading) {
    return <CenteredLoadingIndicator />;
  }

  const handleAddPatientOrder = async (
    selectedPatientOrderItem: PatientOrderItem,
    additionalInformation: string,
  ) => {
    await controller.handleAddPatientOrder(
      selectedPatientOrderItem.orderItem?.orderItemID
        ? getOrderItemByOrderItemID(selectedPatientOrderItem.orderItem?.orderItemID) || null
        : null,
      selectedPatientOrderItem,
      additionalInformation,
      controller.selectedPatientOrderTypeEnum,
    );
    controller.handleUpdateSelectedPatientOrderItem(undefined);
  };

  return (
    <Stack
      direction={{
        base: 'column',
        md: 'row',
      }}
      spacing="6"
      height="100%"
    >
      {showAddTest && (
        <VStack flex="1" py={{ md: '2' }} justifyContent="space-between" alignItems="stretch">
          {controller.selectedPatientOrderItem ? (
            <OrderForm
              selectedOrderItem={controller.selectedPatientOrderItem}
              onSubmit={handleAddPatientOrder}
              isUpdating={controller.isLoading}
              errorUpdating={errorUpdating}
              onCancel={() => controller.handleUpdateSelectedPatientOrderItem(undefined)}
              setIsEditTest={setIsEditTest}
              isEditTest={isEditTest}
            />
          ) : (
            <SearchTestForm
              isClinicalOrder={isClinicalOrder}
              orderName={orderName}
              isLoadingOrder={isLoadingOrder}
              orderItemOptions={orderItemOptions}
              onChange={(value: IOrderItemOption) => {
                const patientOrderItem = new PatientOrderItem();
                if (value.orderItemID) {
                  patientOrderItem.orderItemID = value.orderItemID;
                  patientOrderItem.orderItem = getOrderItemByOrderItemID(value.orderItemID);
                } else {
                  patientOrderItem.testDescription = value.description;
                }
                controller.handleUpdateSelectedPatientOrderItem(patientOrderItem);
              }}
              defaultAddress={controller.expressLabAddress}
              selectedAddress={controller.selectedLabAddress}
            />
          )}
          {isMobile && (
            <Box>
              <PurpleButton
                leftIcon={<ShoppingCartIcon />}
                onClick={() => changeTestView(TestViewEnum.ViewTests)}
                width="100%"
              >
                {t('View Added Tests')}
              </PurpleButton>
            </Box>
          )}
        </VStack>
      )}

      {showViewTest && (
        <Box flex="1">
          {isMobile && (
            <Box mb="3" textAlign="right">
              <PurpleButton
                leftIcon={<PlusIcon />}
                width="100%"
                onClick={() => changeTestView(TestViewEnum.AddTests)}
              >
                {t('Add Tests')}
              </PurpleButton>
            </Box>
          )}

          {controller.ordersAdded ? (
            <>
              <Text fontSize="14px" fontWeight="semibold" mb={5} textAlign="center">
                {t('Tests Summary')}
              </Text>
              <Stack>
                <Stack width="100%" spacing="5">
                  {controller.selectedLabAddress &&
                    arrayHasData(selectedLabOrderData.sortedPatientOrderItemsByName) && (
                      <Box>
                        <Box px="3">
                          <Tag
                            text={`${t('Orders to be sent directly to')} ${
                              controller.selectedLabAddress?.name
                            }`}
                            containerProps={{ bgColor: '#e0f6f4' }}
                          />
                        </Box>
                        {selectedLabOrderData.sortedPatientOrderItemsByName.map(
                          (patientOrderItem) =>
                            renderPatientOrderItem(patientOrderItem, OrdersTypeEnum.SelectedLab),
                        )}
                        <Box mt="4">
                          {renderAdditionalItems(selectedLabOrderData.additionalItems)}
                        </Box>
                      </Box>
                    )}
                  {controller.expressLabAddress &&
                    arrayHasData(expressLabOrderData.sortedPatientOrderItemsByName) && (
                      <Box>
                        <Box px="3">
                          <Tag
                            text={`${t('Orders to be sent directly to')} ${
                              controller.expressLabAddress?.name
                            }`}
                            containerProps={{ bgColor: 'rgb(255, 194, 0)' }}
                          />
                        </Box>
                        {expressLabOrderData.sortedPatientOrderItemsByName.map((patientOrderItem) =>
                          renderPatientOrderItem(patientOrderItem, OrdersTypeEnum.ExpressLab),
                        )}
                        <Box mt="4">
                          {renderAdditionalItems(expressLabOrderData.additionalItems)}
                        </Box>
                      </Box>
                    )}
                  {arrayHasData(patientLabData.sortedPatientOrderItemsByName) && (
                    <Box>
                      <Box px="3">
                        <Tag
                          text={`${t('Orders to be sent directly to')} ${
                            controller.patientOnlyOrder?.patient?.fullName
                          }`}
                          containerProps={{ bgColor: 'rgb(211, 211, 211)' }}
                        />
                      </Box>
                      {patientLabData.sortedPatientOrderItemsByName.map((patientOrderItem) =>
                        renderPatientOrderItem(patientOrderItem, OrdersTypeEnum.PatientOnly),
                      )}
                      <Box mt="4">{renderAdditionalItems(patientLabData.additionalItems)}</Box>
                    </Box>
                  )}
                </Stack>
                {hasOrdersTotalAmount && (
                  <Flex
                    py={5}
                    px={4}
                    alignItems="center"
                    justifyContent="space-between"
                    backgroundColor="#F7F9FF"
                    borderRadius="0.25rem"
                    borderColor="#4163CE14"
                    mt="3"
                  >
                    <Box>
                      <Text fontWeight="400" fontSize="12px">
                        {t('Total')}
                      </Text>
                      <Text fontSize="12px" color="gray.600">
                        {t('Includes')} {taxLabel}
                      </Text>
                    </Box>
                    <Text fontWeight="400" fontSize={OutpostTheme.FontSizes.body}>
                      {currentUser && currencyEnum
                        ? formatCurrency(ordersTotalAmount, currencyEnum, currentUser.cultureInfo)
                        : '-'}
                    </Text>
                  </Flex>
                )}
              </Stack>
            </>
          ) : (
            <NoTestsSelected />
          )}
        </Box>
      )}
    </Stack>
  );

  function renderPatientOrderItem(
    patientOrderItem: PatientOrderItem,
    orderTypeEnum: OrdersTypeEnum,
  ): JSX.Element {
    return (
      <Flex
        key={patientOrderItem.patientOrderItemID}
        direction="column"
        bg="#FFFFFF"
        borderBottom="1px solid rgb(211, 211, 211)"
        width="100%"
        justifyContent="space-between"
        p="4"
      >
        <Box>
          <Text mb={3} fontSize={OutpostTheme.FontSizes.body} fontWeight="semibold">
            {patientOrderItem.orderItem
              ? patientOrderItem.orderItem?.item?.name
              : patientOrderItem.testDescription}
          </Text>
          <Box>
            <Text color="#011431A6" fontSize="12px" mb="3">
              {patientOrderItem.additionalInformation}
            </Text>
            <HStack justifyContent="space-between">
              {orderTypeEnum !== OrdersTypeEnum.PatientOnly &&
                !!controller.selectedLabAddress?.addressBookID && (
                  <Box>
                    {patientOrderItem.totalAmount && currentUser ? (
                      <Text color="#011431A6" fontSize="12px">
                        {t('Price')}:{' '}
                        {formatCurrency(
                          patientOrderItem.totalAmount,
                          currencyEnum,
                          currentUser.cultureInfo,
                        ) || '-'}
                      </Text>
                    ) : (
                      <Text color="#011431A6" fontSize="12px">
                        {t('Price')}: {t('Out of Stock')}
                      </Text>
                    )}
                  </Box>
                )}

              <HStack spacing={4} ml="auto">
                <Text
                  onClick={() => {
                    setIsEditTest(true);
                    controller.handleUpdateSelectedPatientOrderItem(
                      patientOrderItem,
                      orderTypeEnum,
                    );
                  }}
                  fontSize="12px"
                  color="#3E1191"
                  cursor="pointer"
                >
                  {t('Edit')}
                </Text>
                <Text
                  onClick={() =>
                    controller.handleSetPatientOrderItemDataToRemove(
                      patientOrderItem,
                      orderTypeEnum,
                    )
                  }
                  fontSize="12px"
                  color="red.500"
                  cursor="pointer"
                >
                  {t('Delete')}
                </Text>
              </HStack>
            </HStack>
            {showSwitchOnPatientLab(
              orderTypeEnum,
              patientOrderItem.totalAmount,
              !!controller.selectedLabAddress?.addressBookID,
            ) &&
              !isClinicalOrder && (
                <HStack mt="3">
                  <Text fontSize="14px" color="#011431A6">
                    {t('Send Directly to Patient')}
                  </Text>
                  <Box ml="3">
                    <SwitchControl
                      colorScheme="teal"
                      name="sendToPatient"
                      isChecked={orderTypeEnum === OrdersTypeEnum.PatientOnly}
                      onChange={() =>
                        orderTypeEnum === OrdersTypeEnum.PatientOnly
                          ? controller.handleUnSendPatientOrderToPatient(
                              orderTypeEnum,
                              patientOrderItem,
                            )
                          : controller.handleSendPatientOrderToPatient(
                              orderTypeEnum,
                              patientOrderItem,
                            )
                      }
                    />
                  </Box>
                </HStack>
              )}
          </Box>
        </Box>
      </Flex>
    );
  }

  function renderAdditionalItems(additionalItems: InvoiceStoreItem[]): JSX.Element {
    return (
      <Stack>
        {additionalItems
          .filter((additionalItem) => additionalItem.storeItem)
          .map((item) => (
            <Text fontSize={OutpostTheme.FontSizes.body} fontWeight="500">
              + {item.storeItem?.item?.name}:{' '}
              {item.storeItem
                ? formatCurrency(
                    item.storeItem.retailAmount,
                    item.storeItem.currencyEnum,
                    currentUser!.cultureInfo,
                  )
                : '-'}
            </Text>
          ))}
      </Stack>
    );
  }
};
