import { FC } from 'react';
import {
  Box,
  VStack,
  BoxProps,
  StackProps,
  HStack,
  Checkbox,
  CheckboxProps,
} from '@chakra-ui/react';

import { ArrowRightIcon } from '../../../../../assets/icons';

type SelectPharmacyOptionsCardProps = {
  containerProps?: BoxProps;
  innerContainerProps?: StackProps;
  isSelected?: boolean;
  showCheckbox?: boolean;
  checkboxProps?: CheckboxProps;
  showRightArrow?: boolean;
  children?: React.ReactNode;
};

const SelectPharmacyOptionsCard: FC<SelectPharmacyOptionsCardProps> = (props) => (
  <HStack
    bgColor="white"
    borderRadius="lg"
    border={`1px solid ${props.isSelected ? '#322167' : 'rgb(211, 211, 211)'}`}
    transition="border-color 0.3s ease"
    p="3"
    _hover={{ borderColor: '#322167' }}
    width={{ base: '100%', md: '65%', lg: '50%' }}
    minH="90px"
    boxShadow="lg"
    justifyContent="space-between"
    {...props.containerProps}
  >
    <HStack flex="1">
      {props.showCheckbox && (
        <VStack>
          <Checkbox size="lg" colorScheme="purple" {...props.checkboxProps} />
        </VStack>
      )}
      {props.children}
    </HStack>
    {props.showRightArrow && (
      <Box>
        <ArrowRightIcon fontSize="14px" />
      </Box>
    )}
  </HStack>
);

export { SelectPharmacyOptionsCard };
