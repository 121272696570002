import { useMutation } from 'react-query';
import { AuthClient, GenerateDocumentResult, GenerateDocumentsClient } from '../../../generated';
import { httpRequest } from '../../../utils';

interface IGenerateNotesParams {
  patientID: string;
  phrNoteID: string;
}

export const useGenerateNote = () => {
  const generateNote = useMutation<GenerateDocumentResult, string, IGenerateNotesParams, string>(
    async ({ patientID, phrNoteID }) =>
      httpRequest(() =>
        new GenerateDocumentsClient(new AuthClient()).generateNote(patientID, phrNoteID),
      ),
  );

  return generateNote;
};
