import { Box, Flex, VStack } from '@chakra-ui/react';
import { FC } from 'react';

import { CameraIcon, PersonIcon } from '../../../../assets/icons';
import { CenteredLoadingIndicator } from '../../../../components/LoadingIndicator';
import { HeaderSection } from '../../../../components/Modals';
import ServerValidationBox from '../../../../components/ServerValidationBox';
import { ConsultTypeEnum, StoreItemTypeEnum } from '../../../../generated';
import useTranslationComponent from '../../../../hooks/useTranslationComponent';
import { usePartnerSettings } from '../../../../services/partners/usePartnerSettings';
import { useAvailableConsultTypes } from '../../../../services/search/queries/useAvailableConsultTypes';
import { ProviderVisitCard } from '../../../heal/patient/BookAppointment/components/ProviderVisitType/components/ProviderVisitCard';

import { UsePublicBookingFlowReturnType } from '../../hooks/usePublicBookingFlow';
import { useVisitType } from '../../hooks/useVisitType';

type VisitTypeProps = {
  controller: UsePublicBookingFlowReturnType;
};

const VisitType: FC<VisitTypeProps> = (props) => {
  const { t } = useTranslationComponent(['heal']);
  const controller = useVisitType(props.controller);

  const {
    partnerStoreId,
    partnerBookingSeeProviderTitle,
    partnerBookingVideoVisitTitle,
    partnerBookingVideoVisitSubtitle,
    partnerBookingInPersonVisitTitle,
    partnerBookingInPersonVisitSubtitle,
  } = usePartnerSettings();

  const { isLoadingConsultTypes, consultTypes, consultTypesError } = useAvailableConsultTypes({
    storeID: partnerStoreId,
  });

  const supportsVideo = consultTypes?.includes(ConsultTypeEnum.Video);
  const supportsInPerson = consultTypes?.includes(ConsultTypeEnum.InPerson);

  const showVideoConsult = partnerStoreId ? !!supportsVideo : true;
  const showInPersonConsult = partnerStoreId ? !!supportsInPerson : true;

  return (
    <VStack height="100%" alignItems="stretch" spacing="0">
      <HeaderSection
        title={t(partnerBookingSeeProviderTitle ?? 'How Would You Like To See Your Provider?')}
        containerProps={{
          mb: '3',
        }}
        onGoBack={props.controller.handleGoToPreviousBookingData}
      />
      <Box flex="1" overflowY="auto">
        <Box maxW={{ md: '65%' }} width="100%" justifyContent="center" mx="auto">
          {isLoadingConsultTypes && !!partnerStoreId ? (
            <CenteredLoadingIndicator />
          ) : consultTypesError ? (
            <ServerValidationBox message={consultTypesError} />
          ) : (
            <Flex
              flexWrap="wrap"
              justifyContent="center"
              flexDirection={{ base: 'column', sm: 'row' }}
            >
              {showVideoConsult && (
                <Box width={{ base: '100%', sm: '50%' }} maxW="320px" p="3" mx="auto">
                  <ProviderVisitCard
                    onSelectVisitType={() =>
                      controller.handleNextStep(StoreItemTypeEnum.VideoConsult)
                    }
                    icon={CameraIcon}
                    title={t(partnerBookingVideoVisitTitle ?? 'Video Visit')}
                    description={t(
                      partnerBookingVideoVisitSubtitle ??
                        'Book a video appointment with a provider',
                    )}
                    // iconBgColor="rgb(0, 3, 16)"
                  />
                </Box>
              )}
              {showInPersonConsult && (
                <Box width={{ base: '100%', sm: '50%' }} maxW="320px" p="3" mx="auto">
                  <ProviderVisitCard
                    onSelectVisitType={() =>
                      controller.handleNextStep(StoreItemTypeEnum.InPersonConsult)
                    }
                    icon={PersonIcon}
                    title={t(partnerBookingInPersonVisitTitle ?? 'In Person Visit')}
                    description={t(
                      partnerBookingInPersonVisitSubtitle ??
                        'Book an in-person appointment with a provider',
                    )}
                    // iconBgColor="rgb(103, 87, 255)"
                  />
                </Box>
              )}
            </Flex>
          )}
        </Box>
      </Box>
    </VStack>
  );
};

export { VisitType };
