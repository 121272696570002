import { Box, useRadio, UseRadioProps } from '@chakra-ui/react';
import { OutpostTheme } from '../../themes/outpost';

interface IProps extends UseRadioProps {
  w?: string | number | undefined;
  clickHandler?: (val: string | number | undefined) => void;
  children: any;
}

export const RadioCard = ({ children, w = 'auto', clickHandler, ...props }: IProps) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        onClick={() => (clickHandler ? clickHandler(props.value) : null)}
        textAlign="center"
        width={w}
        display="flex"
        alignItems="center"
        height="40px"
        whiteSpace="nowrap"
        cursor="pointer"
        borderWidth="1px"
        borderRadius="100px"
        borderColor="purple.50"
        fontWeight="400"
        color="purple.600"
        backgroundColor="purple.50"
        _checked={{
          bg: 'purple.600',
          color: 'white',
          borderColor: 'purple.50',
        }}
        fontSize={OutpostTheme.FontSizes.body}
        px={3}
        py={2}
      >
        {children}
      </Box>
    </Box>
  );
};
