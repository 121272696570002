import { ModalBody, ModalFooter, Wrap, WrapItem } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  IFormQuestion,
  IPHRFormQuestion,
  PHRFormQuestion,
  PHRFormQuestionChoice,
} from '../../../../../generated';
import { ButtonRow } from '../../../../Buttons';
import { CheckboxButtonControl, RadioButtonControl, RadioGroupControl } from '../../../../forms';
import useFormBuilderContext from '../../../hooks/useFormBuilderContext/useFormBuilderContext';
import { NextButton } from '../../NavigationButtons/NextButton';
import { PrevButton } from '../../NavigationButtons/PrevButton';
import { QuestionHeader } from '../../QuestionHeader/QuestionHeader';

interface IProps {
  formQuestion: IFormQuestion;
  previousAnswer?: IPHRFormQuestion;
}

export const MultipleChoiceQuestion = ({ formQuestion, previousAnswer }: IProps) => {
  const [question, setQuestion] = useState<IPHRFormQuestion | undefined>(previousAnswer);
  const { onNext, goToPrevious, canGoBack, formQuestions, state, lastStep } =
    useFormBuilderContext();

  useEffect(() => {
    setQuestion(previousAnswer);
  }, [previousAnswer]);

  const onChangeCheckbox = (val) => {
    const newFormQuestion = new PHRFormQuestion();
    newFormQuestion.init(question);
    newFormQuestion.formQuestionID = formQuestion.formQuestionID!;
    const formQuestionChoiceID = val;
    const formQuestionChoices = [
      ...(newFormQuestion?.phrFormQuestionChoices ? question?.phrFormQuestionChoices ?? [] : []),
    ];
    const findExistingEntryIndex = formQuestionChoices.findIndex(
      (x) => x.formQuestionChoiceID === formQuestionChoiceID,
    );

    if (findExistingEntryIndex !== -1) {
      formQuestionChoices.splice(findExistingEntryIndex, 1);
    } else {
      const choice = new PHRFormQuestionChoice();
      choice.formQuestionChoiceID = formQuestionChoiceID;
      formQuestionChoices.push(choice);
    }
    newFormQuestion.phrFormQuestionChoices = formQuestionChoices;

    setQuestion(newFormQuestion);
  };

  const onChangeRadio = (val) => {
    const newFormQuestion = new PHRFormQuestion();
    newFormQuestion.init(question);
    newFormQuestion.formQuestionID = formQuestion.formQuestionID!;

    const choice = new PHRFormQuestionChoice();
    choice.formQuestionChoiceID = val;
    newFormQuestion.phrFormQuestionChoices = [choice];

    setQuestion(newFormQuestion);
    onNext(newFormQuestion);
  };

  const handleChangeCheckbox = (e) => onChangeCheckbox(e.target.value);

  return (
    <>
      <ModalBody>
        <QuestionHeader
          title={formQuestion.name}
          description={formQuestion.description}
          currentStep={state.currentIndex + 1}
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
          totalQuestionCount={formQuestions?.length!}
        />
        <Group>
          {formQuestion.multipleSelection ? (
            <Wrap spacing={3}>
              {formQuestion.formQuestionChoices
                ?.sort((a, b) => a.displaySortOrder - b.displaySortOrder)
                .map((x) => (
                  <WrapItem>
                    <CheckboxButtonControl
                      name={x.choiceLabel}
                      key={x.formQuestionChoiceID}
                      value={x.formQuestionChoiceID}
                      isChecked={
                        !!question?.phrFormQuestionChoices?.find(
                          (y) => y.formQuestionChoiceID === x.formQuestionChoiceID,
                        )
                      }
                      onChange={handleChangeCheckbox}
                    >
                      {x.choiceLabel}
                    </CheckboxButtonControl>
                  </WrapItem>
                ))}
            </Wrap>
          ) : (
            <RadioGroupControl
              name="singleChoice"
              onChange={onChangeRadio}
              value={
                question?.phrFormQuestionChoices &&
                question?.phrFormQuestionChoices[0].formQuestionChoiceID
              }
              wrapProps={{ spacing: 3 }}
            >
              {formQuestion.formQuestionChoices
                ?.sort((a, b) => a.displaySortOrder - b.displaySortOrder)
                .map((x) => (
                  <>
                    <RadioButtonControl value={x.formQuestionChoiceID} key={x.formQuestionChoiceID}>
                      {x.choiceLabel}
                    </RadioButtonControl>
                  </>
                ))}
            </RadioGroupControl>
          )}
        </Group>
      </ModalBody>
      <ModalFooter>
        <ButtonRow>
          {canGoBack && <PrevButton onClick={goToPrevious} />}
          {(formQuestion.multipleSelection ||
            (question?.phrFormQuestionChoices ?? []).length > 0) && (
            <NextButton
              lastStep={lastStep}
              onClick={() => {
                const newFormQuestion = new PHRFormQuestion();
                if (question) {
                  newFormQuestion.init(question);
                  onNext(newFormQuestion);
                } else {
                  newFormQuestion.formQuestionID = formQuestion.formQuestionID;
                  onNext(newFormQuestion);
                }
              }}
            />
          )}
        </ButtonRow>
      </ModalFooter>
    </>
  );
};

const Group = styled.div`
  display: flex;
  flex-wrap: wrap;

  > label {
    margin-bottom: 10px;
  }

  > label:not(:last-of-type) {
    margin-right: 15px;
  }
`;
