import React from 'react';
import { Box, Flex, Text, HStack, VStack, chakra } from '@chakra-ui/react';
import moment from 'moment';

import { TimeFormatEnum } from '../../../constants';
import { CalendarIcon2, VerifiedShieldIcon, PlusIcon } from '../../../assets/icons';

import BlueGreyCircle from '../../../assets/images/blue-grey-circle.svg';
import LightGreenCircle from '../../../assets/images/light-green-circle.svg';

import { PrimaryButton } from '../../../components/Buttons';
import EmptyListCard from '../../../components/EmptyListCard';
import { OutpostTheme } from '../../../themes/outpost';

export const AddPHRItemButton = ({ name, onCreate, ...rest }) => {
  return (
    <Flex justify="center" width="100%" {...rest}>
      <PrimaryButton borderRadius="full" fontWeight="500" onClick={onCreate} width="fit-content">
        <PlusIcon strokeWidth="2px" color="#ffffff" />
        Add {name}
      </PrimaryButton>
    </Flex>
  );
};

export const PHRItemList = ({ children, mobileHeightOffset = '10rem', height = '500px' }) => {
  return (
    <VStack
      spacing={{ base: 2, md: 4 }}
      marginTop={{ base: '1rem', md: '1.5rem' }}
      paddingX={{ base: '0.5rem', md: '4rem' }}
      height={{ base: `calc(100vh - ${mobileHeightOffset})`, md: height }}
      overflowY="auto"
    >
      {children}
    </VStack>
  );
};

const ChakraEmptyListCard = chakra(EmptyListCard);

export const EmptyPHRItemListCard = ({ children }) => {
  return (
    <ChakraEmptyListCard m={{ base: '1rem', md: '1rem 3rem' }}>{children}</ChakraEmptyListCard>
  );
};
interface IPHRItemProps {
  name?: string;
  isVerified?: boolean;
  SecondaryInfo?: React.ReactNode;
  LeadingIcon?: React.ReactNode;
  onView: () => void;
}

const PhrItem = ({ name, isVerified, onView, SecondaryInfo, LeadingIcon }: IPHRItemProps) => {
  return (
    <Flex
      width="100%"
      height="fit-content"
      backgroundColor="#ffffff"
      border="1.5px solid #616A8891"
      borderRadius="12px"
      paddingY="1.25rem"
      paddingX={{ base: '0.75rem', md: '1.5rem' }}
      cursor="pointer"
      onClick={onView}
      backgroundImage={isVerified ? LightGreenCircle : BlueGreyCircle}
      backgroundRepeat="no-repeat"
      backgroundPosition="calc(100% + 100px) -110px"
    >
      {LeadingIcon}
      <Box flexGrow={1} height="100%">
        <Flex justify="space-between" align="flex-start">
          <Text
            fontSize="16px"
            fontWeight="medium"
            color="#020202"
            marginRight={{ base: '0.5rem', md: '1rem' }}
          >
            {name}
          </Text>
          <VerifiedShieldIcon
            height="22px"
            color={isVerified ? '#418A94' : '#616A88'}
            width="22px"
          />
        </Flex>
        {SecondaryInfo}
        {!isVerified && (
          <Text
            marginTop="1rem"
            fontSize={OutpostTheme.FontSizes.body}
            color="#020202"
            fontWeight="500"
          >
            This record has not been verified
          </Text>
        )}
      </Box>
    </Flex>
  );
};

interface ISecondaryInfoComponentProps {
  startDate?: Date;
  endDate?: Date;
  startDateTitle?: string;
  endDateTitle?: string;
  showTime?: boolean;
}

export const SecondaryInfoComponent = ({
  startDate,
  endDate,
  startDateTitle,
  endDateTitle,
  showTime,
}: ISecondaryInfoComponentProps) => {
  const format = showTime ? TimeFormatEnum.LONG_DATE_TIME : TimeFormatEnum.SHORT_LONG_DATE;

  return (
    <HStack
      mt={2}
      spacing={{ base: 4, md: 8 }}
      fontSize={OutpostTheme.FontSizes.body}
      fontWeight="300"
      color="#011431"
      align="flex-start"
    >
      {startDate && (
        <Box>
          <Text textTransform="uppercase" color="#020202" fontSize="12px" fontWeight="500">
            {startDateTitle || 'Start Date'}
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
            <CalendarIcon2 fontSize="18px" color="#020202" /> {moment(startDate).format(format)}
          </Text>
        </Box>
      )}

      {endDate && (
        <Box>
          <Text textTransform="uppercase" color="#020202" fontSize="12px" fontWeight="500">
            {endDateTitle || 'End Date'}
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
            <CalendarIcon2 fontSize="18px" color="#020202" />{' '}
            {endDate ? moment(endDate).format(format) : 'Ongoing'}
          </Text>
        </Box>
      )}
    </HStack>
  );
};

export default PhrItem;
