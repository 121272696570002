import { useEffect, useState } from 'react';
import {
  AuthClient,
  MarketingCampaignsClient,
  MarketingCampaignTypeEnum,
  MarketingCampaignUserDetailReward,
  RewardStateEnum,
} from '../../../../../generated';
import { getTime, httpRequest } from '../../../../../utils';

// TODO: multiple campaigns support
export const useCheckUnacknowledgedRewards = (currentCampaignEnum: MarketingCampaignTypeEnum) => {
  const [unacknowledgedRewards, setUnacknowledgedRewards] = useState<
    MarketingCampaignUserDetailReward[]
  >([]);
  const [acknowledgedRewards, setAcknowledgedRewards] = useState<
    MarketingCampaignUserDetailReward[] | null
  >(null);
  const [unacknowledgedReward, setUnacknowledgedReward] =
    useState<MarketingCampaignUserDetailReward | null>(null);
  const [isFirstTimeReward, setIsFirstTimeReward] = useState<boolean>(false);

  useEffect(() => {
    const loading = async () => {
      try {
        const client = new MarketingCampaignsClient(new AuthClient());
        const campaignFilter = (campaign) =>
          campaign.marketingCampaignUserDetail?.marketingCampaign?.marketingCampaignTypeEnum ===
            currentCampaignEnum && campaign.rewardStateEnum === RewardStateEnum.Claimed;

        let result = await httpRequest(() => client.myRewards(true));
        setAcknowledgedRewards(result.filter(campaignFilter));
        result = await httpRequest(() => client.myRewards(false));
        setUnacknowledgedRewards(
          result
            .filter(campaignFilter)
            .sort((a, b) => getTime(a.rewardAppliedDate) - getTime(b.rewardAppliedDate)),
        );
      } catch (err) {}
    };

    loading();
  }, [currentCampaignEnum]);

  useEffect(() => {
    const isFirstTimeReward = () => !!acknowledgedRewards && acknowledgedRewards.length === 0;
    if (unacknowledgedRewards.length > 0) {
      setUnacknowledgedReward(unacknowledgedRewards[0]);
      setIsFirstTimeReward(isFirstTimeReward());
    } else {
      setUnacknowledgedReward(null);
    }
  }, [acknowledgedRewards, unacknowledgedRewards]);

  const acknowledgeReward = async (marketingCampaignUserDetailRewardID: string) => {
    try {
      const client = new MarketingCampaignsClient(new AuthClient());
      await httpRequest(() => client.acknowledgeReward(marketingCampaignUserDetailRewardID));
    } catch (err) {}
    setUnacknowledgedRewards(
      unacknowledgedRewards.filter(
        (x) => x.marketingCampaignUserDetailRewardID !== marketingCampaignUserDetailRewardID,
      ),
    );
  };

  return { unacknowledgedReward, acknowledgeReward, isFirstTimeReward, acknowledgedRewards };
};
