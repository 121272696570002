import { useState } from 'react';

interface IProps {
  children: any;
  initOpen?: boolean;
}

const CollapsibleComponent = ({ children, initOpen = false }: IProps) => {
  const [isOpen, open] = useState<boolean>(initOpen);

  const toggle = () => {
    open(!isOpen);
  };

  return children({
    isOpen,
    toggle,
  });
};

export default CollapsibleComponent;
