import { PHRMedication } from '../../../../generated';
import { getTime } from '../../../../utils';

const getSortedPhrMedications = () => {
  const sortByName = (phrMedication: PHRMedication[]) => {
    return phrMedication.sort((a, b) => a.name.localeCompare(b.name));
  };

  const sortByDateDesc = (phrMedication: PHRMedication[]) => {
    return phrMedication.sort((a, b) => getTime(a.createdDate) - getTime(b.createdDate));
  };

  const sortByDateAsc = (phrMedication: PHRMedication[]) => {
    return phrMedication.sort((a, b) => getTime(b.createdDate) - getTime(a.createdDate));
  };
  return { sortByName, sortByDateDesc, sortByDateAsc };
};
export { getSortedPhrMedications };
