import { useEffect, useState } from 'react';

import { usePatientPreferredPharmacyGet } from '../../../../../services/patients/queries/usePatientPreferredPharmacyGet';
import { AddressBook } from '../../../../../generated';

type UseSelectPharmacyOptionsProps = {
  onCloseModal?: () => void;
  patientID: string;
  onChangeSendCopyToPatient: (value: boolean) => void;
  selectedAddress?: AddressBook;
  onChangeAddress: (value: AddressBook) => void;
  isLoading?: boolean;
  sendCopyToPatient?: boolean;
};

const useSelectPharmacyOptions = (props: UseSelectPharmacyOptionsProps) => {
  const { onChangeAddress } = props;

  const [selectMedicationFirst, setSelectMedicationFirst] = useState(false);

  const { isLoadingPreferredPharmacy, preferredPharmacy, preferredPharmacyError } =
    usePatientPreferredPharmacyGet({ patientID: props.patientID });

  const toggleSelectMedicationFirst = () => {
    setSelectMedicationFirst(!selectMedicationFirst);
  };

  const selectedPharmacyID = props.selectedAddress?.associatedID;

  const selectedPharmacyAddressBookID = props.selectedAddress?.addressBookID;

  const preferredPharmacyAddress = preferredPharmacy?.addressBook;

  const isShowingPreferredPharmacy = preferredPharmacyAddress?.associatedID === selectedPharmacyID;

  const hasSelectedPharmacy = !!props.selectedAddress;

  const isContinueDisabled =
    !hasSelectedPharmacy && !selectMedicationFirst && !props.sendCopyToPatient;

  const onContinue = () => {
    if (isContinueDisabled) return;
    props.onCloseModal?.();
  };

  useEffect(() => {
    // We need to select the preferred pharmacy if it is available and no other pharmacy has been selected
    if (!preferredPharmacyAddress || selectedPharmacyID) return;

    if (preferredPharmacyAddress.associatedID === selectedPharmacyID) return;

    if (selectedPharmacyAddressBookID) return;

    onChangeAddress(preferredPharmacyAddress);
  }, [
    preferredPharmacyAddress,
    onChangeAddress,
    selectedPharmacyID,
    selectedPharmacyAddressBookID,
  ]);

  return {
    selectMedicationFirst,
    toggleSelectMedicationFirst,
    onContinue,
    isContinueDisabled,
    isLoadingPreferredPharmacy,
    preferredPharmacy,
    preferredPharmacyError,
    hasSelectedPharmacy,
    isShowingPreferredPharmacy,
  };
};

export { useSelectPharmacyOptions };
