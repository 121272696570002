import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PHRTrackerPulseDTO } from '../../../../generated';
import { IPHRPatientEnhanced } from '../../../../store/patient/phr/phrReducers';
import { phrPulsesSelector } from '../../../../store/patient/phr/phrSelectors';
import { getAllForPhrSection } from '../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import PhrItem, { SecondaryInfoComponent } from '../../components/PhrItem';
import { VitalsTracker } from '../../vitals/components/PatientVitalsCard/usePatientVitalsCard';
import TrackerView from '../TrackerView';
import PulseChart from './PulseChart';
import PulseForm from './PulseForm';

interface IProps {
  patient: IPHRPatientEnhanced;
  renderHeader: (addButton?: any, onBack?: () => void, title?: string) => any;
  showSingleTrackerForm?: (id: ReduxPHRCategoryEnum, tracker: VitalsTracker) => void;
  defaultBack?: any;
  defaultTitle?: string;
}

const PulseComponent: React.FC<IProps> = ({
  patient,
  renderHeader,
  showSingleTrackerForm,
  defaultBack,
  defaultTitle,
}) => {
  const dispatch = useDispatch();
  const phrPulseRates = phrPulsesSelector(patient.phrPulses);

  useEffect(() => {
    if (patient) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRPulses, patient.patientID));
    }
  }, [dispatch, patient]);

  const thinView = (x: PHRTrackerPulseDTO, onView?: (data: any) => void) => (
    <>
      <PhrItem
        name={`${x.pulse} bpm`}
        isVerified={x.verified}
        onView={() => {
          if (onView) {
            onView(x);
          }
        }}
        SecondaryInfo={
          <SecondaryInfoComponent
            startDate={moment(x.trackerDate).toDate()}
            startDateTitle="Created on"
            showTime
          />
        }
      />
    </>
  );

  return (
    <TrackerView
      renderHeader={renderHeader}
      phrCategoryEnum={ReduxPHRCategoryEnum.PHRPulses}
      patient={patient}
      trackerData={phrPulseRates}
      thinView={thinView}
      Chart={PulseChart}
      Form={PulseForm}
      createText="Pulse"
      showSingleTrackerForm={showSingleTrackerForm}
      defaultBack={defaultBack}
      defaultTitle={defaultTitle}
    />
  );
};

export default PulseComponent;
