import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AddressBooksClient, AddressBookTag, AuthClient } from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

export const useAddressBookTagsDelete = () => {
  const queryClient = useQueryClient();
  const [error, setError] = useState<string>('');
  const addressBookTagsDelete = useMutation(
    async (addressBookTags: AddressBookTag[]) => {
      const promiseJobs: Promise<any>[] = [];
      addressBookTags.forEach((x) =>
        promiseJobs.push(
          httpRequest(() =>
            new AddressBooksClient(new AuthClient()).addressBookTagsDelete(x.addressBookTagID),
          ),
        ),
      );
      await Promise.all(promiseJobs);
    },
    {
      onSuccess: () => {
        setError('');
        queryClient.invalidateQueries(QueryKeys.AddressBooksSearch);
        queryClient.invalidateQueries(QueryKeys.AddressBooksPrivate);
        queryClient.invalidateQueries(QueryKeys.AddressBooksCollection);
      },
      onError: (err: string) => setError(err as any),
    },
  );

  return { error, addressBookTagsDelete };
};
