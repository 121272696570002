import { ActionType, createAsyncAction } from 'typesafe-actions';
import { AppFeature } from '../../generated';

export const getAppFeaturesActions = createAsyncAction(
  ['GET_APP_FEATURES_REQUEST', () => {}],
  ['GET_APP_FEATURES_SUCCESS', (res: AppFeature[]) => res],
  ['GET_APP_FEATURES_ERROR', (err: string) => err],
)();

export type AppFeaturesActionTypes = ActionType<typeof getAppFeaturesActions>;
