import { Scrollbars } from 'react-custom-scrollbars';
import styled from 'styled-components';
import { Avatar } from '../../../components/common/Avatar';
import { SpacingRelated } from '../../../constants';
import { Provider } from '../../../generated';
import { OutpostTheme } from '../../../themes/outpost';
import { getUserThumbnail } from '../../../utils/getUserThumbnail';
import Card from '../../Card';
import { CenteredLoadingIndicator } from '../../LoadingIndicator';
import { Paragraph } from '../Paragraph';

const StyledCard = styled(Card)`
  margin-bottom: ${SpacingRelated.Minimum};
  padding: 10px 25px;

  &:hover {
    cursor: pointer;
  }
`;

const InnerCardWrapper = styled.div`
  display: flex;
`;

const ProviderInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
`;

const ProviderName = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => props.theme.Colors.DarkBlue};
  margin-bottom: 0.5rem;
`;

interface IProps {
  isLoading: boolean;
  hasSearched: boolean;
  providers: Provider[];
  handleSelectedProvider: (provider: Provider) => void;
}

const ProviderSearchModalSearchList = ({
  isLoading,
  hasSearched,
  providers,
  handleSelectedProvider,
}: IProps) => {
  if (isLoading) {
    return <CenteredLoadingIndicator />;
  }

  if (!hasSearched) {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <Paragraph>Search Results: Enter criteria first</Paragraph>
          </div>
        </div>
      </>
    );
  }

  if (hasSearched && providers.length === 0) {
    return (
      <div className="row">
        <div className="col-12">
          <Paragraph>Search Results: No {process.env.REACT_APP_PROVIDER_TITLE}s found</Paragraph>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <Paragraph>
            Search Results: {providers.length} {process.env.REACT_APP_PROVIDER_TITLE}
            {providers.length > 1 ? 's' : ''} found
          </Paragraph>
        </div>
      </div>
      <Scrollbars autoHeight autoHeightMax={400}>
        {providers.map((x) => (
          <StyledCard key={x.providerID} onClick={() => handleSelectedProvider(x)} inverse={true}>
            <InnerCardWrapper>
              <Avatar
                size={OutpostTheme.Avatar.Size.Medium}
                src={getUserThumbnail(x.imageDetailID)}
              />
              <ProviderInfo>
                <ProviderName>{x.fullName}</ProviderName>
                <Paragraph>
                  {[x.country?.name, x.providerTypeTag?.name, x.genderEnum, x.primaryLanguage?.name]
                    .filter((x) => x)
                    .join(', ')}
                </Paragraph>
              </ProviderInfo>
            </InnerCardWrapper>
          </StyledCard>
        ))}
      </Scrollbars>
    </>
  );
};

export default ProviderSearchModalSearchList;
