import React from 'react';
import styled from 'styled-components';
import { BASE_URL } from '../../../../../baseUrl';
import { Avatar } from '../../../../../components/common/Avatar';
import LiveStamp from '../../../../../components/LiveStamp';

const FullName = styled.p`
  color: ${(props) => props.color};
  position: absolute;
  left: 76px;
  top: 5px;
  font-size: 14px;
  z-index: 1050;
`;

const TimeStamp = styled.div`
  position: absolute;
  bottom: -18px;
  top: unset;
  color: ${(props) => props.theme.FontColors.Secondary};
  font-size: 12px;
`;

const Texts = styled.div`
  font-size: 14px;
  font-weight: 500;
  position: relative;
  padding: 24px 12px 12px;
  min-height: 65px;
  font-size: 14px;
  line-height: 1.2;
  border-radius: 8px;
  word-wrap: break-word;
  word-break: break-all;
  hyphens: auto;
  display: flex;
  color: black;
`;

interface IMessageProps {
  readonly sent: boolean;
}

const Message = styled.div<IMessageProps>`
  position: relative;
  padding: ${(props) => (props.sent ? '0 50px 0 0' : '0 0 0 50px')};
  margin: 30px 10px 20px 10px;

  > ${TimeStamp} {
    right: ${(props) => (props.sent ? '80px' : '20px')};
  }

  > ${Texts} {
    justify-content: ${(props) => (props.sent ? 'flex-end' : 'flex-start')};
    padding-top: ${(props) => (props.sent ? '12px' : '24px')};
    margin-right: ${(props) => (props.sent ? '14px' : '0')};
    margin-left: ${(props) => (props.sent ? '0' : '14px')};
    background-color: ${(props) => (props.sent ? 'rgba(27, 61, 113, 0.2)' : '#efefef')};
  }

  > ${FullName} {
    display: ${(props) => (props.sent ? 'none' : 'block')};
  }
`;

interface IProps {
  sent: boolean;
  fullName: string;
  color: string;
  imageDetailID: string;
  timestamp: number;
  message: string;
}

const ChatMessage = ({ sent, fullName, color, imageDetailID, timestamp, message }: IProps) => {
  return (
    <Message sent={sent}>
      <FullName color={color}>{fullName}</FullName>
      <Avatar
        color="white"
        backgroundColor={color}
        left={sent ? 'inherit' : '0px'}
        right={sent ? '0px' : 'inherit'}
        position="absolute"
        top="0"
        size="md"
        name={fullName}
        src={`${BASE_URL}/api/v1/imagedetails/${imageDetailID}/ThumbnailAsSrc`}
      />
      <TimeStamp>
        <LiveStamp timestamp={timestamp} />
      </TimeStamp>
      <Texts>{message}</Texts>
    </Message>
  );
};

export default ChatMessage;
