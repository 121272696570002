import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { getSendToByAddressBook } from '../../../../../../../../components/AddressBook/utils';
import {
  AddressBook,
  AuthClient,
  IPatient,
  PatientOrder,
  PatientOrdersClient,
  SendToEnum,
} from '../../../../../../../../generated';
import { usePatientOrdersPut } from '../../../../../../../../services/patientOrders/mutations/usePatientOrdersPut';
import { httpRequest } from '../../../../../../../../utils';

const getSendSubject = (patient?: IPatient) => `Order for ${patient?.fullName}`;
const getSendMessage = (patient?: IPatient) =>
  `Please fullfill this Diagnostic Order for ${patient?.fullName}`;

type UseFinalizeOrderProps = {
  patientOrder?: PatientOrder;
  setPatientOrder: Dispatch<SetStateAction<PatientOrder | undefined>>;
  selectedAddress?: AddressBook;
  setSelectedAddress: Dispatch<SetStateAction<AddressBook | undefined>>;
  isAddressBookInit: boolean;
  goToNextStep: () => void;
};

export const useFinalizeOrder = ({
  patientOrder,
  setPatientOrder,
  selectedAddress,
  setSelectedAddress,
  isAddressBookInit,
  goToNextStep,
}: UseFinalizeOrderProps) => {
  const [isRecalculating, setIsRecalculating] = useState<boolean>(false);
  const [recalcError, setRecalcError] = useState<string>('');
  const patientOrdersPut = usePatientOrdersPut();

  const recalculateOrder = useCallback(
    async (patientOrder: PatientOrder) => {
      try {
        setIsRecalculating(true);
        setRecalcError('');
        const patientOrdersClient = new PatientOrdersClient(new AuthClient());
        await httpRequest(() => patientOrdersClient.patientOrdersPut(patientOrder));
        const po = await httpRequest(() =>
          patientOrdersClient.patientOrdersGet(patientOrder.patientOrderID),
        );
        setPatientOrder(po);
      } catch (err: any) {
        setRecalcError(err);
        throw new Error(err);
      }
      setIsRecalculating(false);
    },
    [setPatientOrder],
  );

  const onSelectLab = useCallback(
    async (selectedAddress?: AddressBook, patientOrderToUse?: PatientOrder) => {
      const po = new PatientOrder();
      po.init(patientOrderToUse || patientOrder);
      if (selectedAddress) {
        const { sendToEnum, sendToID, sendCopyToPatient } = getSendToByAddressBook(
          selectedAddress,
          false,
        );
        po.sendToEnum = sendToEnum;
        po.sendToID = sendToID;
        po.sendCopyToPatient = sendCopyToPatient;
        po.storeID = sendToID;
        po.sendSubject = getSendSubject(patientOrder?.patient);
        po.sendMessage = getSendMessage(patientOrder?.patient);
      } else {
        po.sendToEnum = SendToEnum.DoNotSend;
        po.sendToID = undefined;
        po.sendCopyToPatient = false;
        po.storeID = undefined;
        po.sendSubject = undefined;
        po.sendMessage = undefined;
      }
      setSelectedAddress(selectedAddress);
      try {
        await recalculateOrder(po);
      } catch (err) {
        setSelectedAddress(undefined);
      }
    },
    [patientOrder, recalculateOrder, setSelectedAddress],
  );

  const sendToPatient = useCallback(async () => {
    const po = new PatientOrder();
    po.init(patientOrder);
    po.sendToEnum = SendToEnum.UserDetail;
    po.sendToID = patientOrder?.patient?.userDetailID;
    po.sendCopyToPatient = false;
    po.storeID = undefined;
    po.sendSubject = getSendSubject(patientOrder?.patient);
    po.sendMessage = getSendMessage(patientOrder?.patient);
    try {
      await recalculateOrder(po);
      goToNextStep();
    } catch (err) {}
  }, [recalculateOrder, patientOrder, goToNextStep]);

  const removeOrderItem = useCallback(
    async (orderItemID: string) => {
      try {
        setIsRecalculating(true);
        const updatedOrder = new PatientOrder();
        updatedOrder.init(patientOrder);
        const orderItems = patientOrder?.patientOrderItems ?? [];
        updatedOrder.patientOrderItems = orderItems.map((x) => {
          x.isDeleted = x.orderItemID === orderItemID;
          return x;
        });
        await patientOrdersPut.mutateAsync(updatedOrder);
        updatedOrder.patientOrderItems = orderItems.filter((x) => x.orderItemID !== orderItemID);
        setPatientOrder(updatedOrder);
        recalculateOrder(updatedOrder);
      } catch (err: any) {
        setRecalcError(err);
      }
      setIsRecalculating(false);
    },
    [setPatientOrder, patientOrder, patientOrdersPut, recalculateOrder],
  );

  return {
    selectedAddress,
    onSelectLab,
    sendToPatient,
    isRecalculating,
    recalcError,
    patientOrder,
    removeOrderItem,
    isAddressBookInit,
    goToNextStep,
  };
};

export type UseFinalizeOrderReturnType = ReturnType<typeof useFinalizeOrder>;
