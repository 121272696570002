import { Search2Icon } from '@chakra-ui/icons';

import {
  Box,
  Flex,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  ModalBody,
  ModalFooter,
  Text,
  useBreakpointValue,
  useDisclosure,
  useRadioGroup,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';

import { PlusIconBig } from '../../../../assets/icons';
import InfiniteScroll from '../../../../components/InfiniteScroll/InfiniteScroll';
import {
  AddressBookFolderEnum,
  AddressBookSortByEnum,
  AddressBookTypeEnum,
  TagTypeEnum,
} from '../../../../generated';
import { useGeolocation } from '../../../../hooks/useGeolocation';
import { useCountriesAll } from '../../../../services/countries/useCountriesAll';
import { useTagsAllByTagType } from '../../../../services/tags/queries/useTagsAllByTagType';
import { DeleteButton, FloatingActionButton, SecondaryButton } from '../../../Buttons';
import { PrimaryButton } from '../../../Buttons/PrimaryButton';
import { CountrySelectUnStyled, SelectComponentUnStyled } from '../../../forms/Selects';
import { CenteredLoadingIndicator } from '../../../LoadingIndicator';
import { BaseModal } from '../../../Modals';
import ServerValidationBox from '../../../ServerValidationBox';

import { Tag } from '../../../TagComponent';
import { AddressBookModeEnum, AddressBookViewModeEnum } from '../../AddressBook';
import useAddressBooksContext from '../../context/AddressBooksProvider';
import { CurrentLocationType } from '../../hooks/useAddressBooks';
import { isFolderSelected } from '../../utils';

import { useSelector } from 'react-redux';
import { PhoneEventsEnum } from '../../../../constants';
import { AppState } from '../../../../store/root-reducers';
import { AddressBookList } from './AddressBookList';
import useTranslationComponent from '../../../../hooks/useTranslationComponent';

const options: string[] = [
  AddressBookSortByEnum.All,
  AddressBookSortByEnum.Favourite,
  AddressBookSortByEnum.AaZz,
  AddressBookSortByEnum.ZzAa,
  AddressBookSortByEnum.Speciality,
  AddressBookSortByEnum.Distance,
];

const allTypeTags = [
  {
    text: 'Private contacts',
    value: AddressBookTypeEnum.Private,
  },
];

const AllFolderTags = [
  {
    text: 'Clinics',
    value: AddressBookFolderEnum.Clinic,
  },
  {
    text: 'Hospitals',
    value: AddressBookFolderEnum.Organization,
  },
  {
    text: 'Diagnostic centers',
    value: AddressBookFolderEnum.Lab,
  },
  {
    text: 'Pharmacies',
    value: AddressBookFolderEnum.Pharmacy,
  },
  {
    text: 'Providers',
    value: AddressBookFolderEnum.Provider,
  },
  {
    text: 'Patients',
    value: AddressBookFolderEnum.Patient,
  },
];

const getSortLabel = (option: AddressBookSortByEnum) => {
  switch (option) {
    case AddressBookSortByEnum.AaZz:
      return 'A-Z';
    case AddressBookSortByEnum.ZzAa:
      return 'Z-A';
    default:
      return option;
  }
};

const sortBySelectOptions = options.map((item) => {
  return { value: item, label: getSortLabel(item as AddressBookSortByEnum) };
});
interface IProps {
  onAdd?: () => void;
  onClose?: () => void;
  onEdit?: () => void;
}

export const AddressBookListView = ({ onAdd, onClose, onEdit }: IProps) => {
  const { t } = useTranslationComponent();
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  const {
    mode,
    viewMode,
    callback,
    selectedAddresses,
    setSearchText,
    totalCount,
    addressBookSortByEnum,
    setAddressBookSortByEnum,
    country,
    setCountry,
    specialty,
    isLoading,
    setSpecialty,
    setAddressBookTypeEnum,
    addressBookTypeEnum,
    // -----
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    heading,
    onChangeLocation,
    selectedAddressBookFolders,
    onToggleAddressBookFolder,
    visibleFolders,
    visibleTypes,
  } = useAddressBooksContext();
  const { getRootProps: getSortByRootProps /*getRadioProps: getSortByRadioProps*/ } = useRadioGroup(
    {
      name: 'sortBy',
      defaultValue: AddressBookSortByEnum.All,
      onChange: setAddressBookSortByEnum,
    },
  );
  const sortByGroup = getSortByRootProps();
  const { isLoadingCountries, countries = [] } = useCountriesAll();
  const {
    isLoadingTags: specialtiesLoading,
    tags: specialties,
    tagsError: specialtiesError,
  } = useTagsAllByTagType(TagTypeEnum.Specialties);

  const onComplete = () => {
    callback?.(selectedAddresses);
    onClose?.();
  };

  const {
    isOpen: isFilterModalOpen,
    onOpen: onOpenFilterModal,
    onClose: onCloseFilterModal,
  } = useDisclosure();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const { getCurrentPosition, position, clearPosition } = useGeolocation();

  const isInMobileView = currentUser?.isInMobileView;
  const isMobileSystem = currentUser?.mobileSystem;

  const sendWebViewCallback = useCallback(() => {
    if (isInMobileView && isMobileSystem === 'android') {
      // @ts-ignore
      AndroidResult.callback(PhoneEventsEnum.REQUEST_LOCATION_CALLBACK);
    } else if (isInMobileView && isMobileSystem === 'ios') {
      // @ts-ignore
      webkit.messageHandlers.requestLocationCallbackHandler.postMessage(
        PhoneEventsEnum.REQUEST_LOCATION_CALLBACK,
      );
    }
  }, [isInMobileView, isMobileSystem]);

  const onSetAddressBookType = (type: AddressBookTypeEnum) => {
    setAddressBookTypeEnum(addressBookTypeEnum === type ? AddressBookTypeEnum.Global : type);
  };

  const isAddressBookTypeSelected = (type: AddressBookTypeEnum) => addressBookTypeEnum === type;

  const browseByFolderTags = visibleFolders
    ? AllFolderTags.filter((item) => visibleFolders.includes(item.value))
    : AllFolderTags;

  const browseByTypeTags = visibleTypes
    ? allTypeTags.filter((item) => visibleTypes.includes(item.value))
    : allTypeTags;

  useEffect(() => {
    if (!position) return;
    const currentLocation: CurrentLocationType = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
    onChangeLocation(currentLocation);
  }, [position, onChangeLocation]);

  return (
    <>
      {viewMode === AddressBookViewModeEnum.Modal && (
        <Heading textAlign="center" size="md" pt={{ base: '3', md: '0' }} color="purple.600">
          {t(heading ?? 'Address Book')}
        </Heading>
      )}
      {isLoadingCountries || specialtiesLoading ? (
        <CenteredLoadingIndicator />
      ) : (
        <VStack height="100%" overflowY="auto" alignItems="stretch" position="relative">
          <ServerValidationBox message={specialtiesError} />
          <HStack alignItems="flex-start" flex="1" overflowY="auto">
            {!isMobile && (
              <VStack width="200px" overflowY="auto" height="100%">
                <Box width="100%">
                  <CountrySelectUnStyled
                    name="countryID"
                    value={country}
                    options={countries}
                    label=""
                    valueKey="countryID"
                    labelKey="name"
                    onChange={setCountry}
                    isClearable={false}
                    formControlProps={{ m: 0 }}
                  />
                </Box>
                <Box width="100%">
                  <SelectComponentUnStyled
                    name="tagID"
                    value={specialty}
                    options={specialties || []}
                    label=""
                    valueKey="tagID"
                    labelKey="description"
                    onChange={setSpecialty}
                    isClearable={true}
                    formControlProps={{ m: 0 }}
                    placeholder={t('Specialties')}
                  />
                </Box>
                <VStack
                  spacing={2}
                  css={hiddenScrollbar}
                  minH="40px"
                  alignItems="flex-start"
                  width="100%"
                >
                  {browseByFolderTags.map((item) => {
                    return (
                      <Tag
                        key={item.value}
                        text={t(item.text)}
                        containerProps={{
                          role: 'button',
                          onClick: () => onToggleAddressBookFolder(item.value),
                          bgColor: isFolderSelected(selectedAddressBookFolders, item.value)
                            ? 'purple.600'
                            : 'purple.50',
                          color: isFolderSelected(selectedAddressBookFolders, item.value)
                            ? 'white'
                            : 'purple.600',
                          _hover: { bgColor: 'purple.600', color: 'white' },
                        }}
                        textProps={{
                          color: 'inherit',
                        }}
                      />
                    );
                  })}

                  {browseByTypeTags.map((item) => {
                    return (
                      <Tag
                        key={item.value}
                        text={t(item.text)}
                        containerProps={{
                          role: 'button',
                          onClick: () => onSetAddressBookType(item.value),
                          bgColor: isAddressBookTypeSelected(item.value)
                            ? 'purple.600'
                            : 'purple.50',
                          color: isAddressBookTypeSelected(item.value) ? 'white' : 'purple.600',
                          _hover: { bgColor: 'purple.600', color: 'white' },
                        }}
                        textProps={{
                          color: 'inherit',
                        }}
                      />
                    );
                  })}
                </VStack>
              </VStack>
            )}
            <VStack flex="1" alignItems="stretch" height="100%" overflowY="auto">
              <HStack>
                <Box flex="1">
                  <InputGroup>
                    <Input
                      placeholder={t('Search providers, clinics, hospitals')}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <InputRightElement height="100%" children={<Search2Icon />} mr={2} />
                  </InputGroup>
                </Box>
                <Box>
                  {position ? (
                    <DeleteButton
                      size="md"
                      onClick={() => {
                        clearPosition();
                        onChangeLocation(undefined);
                      }}
                    >
                      {t('Clear my location')}
                    </DeleteButton>
                  ) : (
                    <SecondaryButton
                      size="md"
                      onClick={() => {
                        sendWebViewCallback();
                        getCurrentPosition();
                      }}
                    >
                      {t('Use my location')}
                    </SecondaryButton>
                  )}
                </Box>
              </HStack>
              <HStack width="100%" alignItems="center" wrap="wrap" justifyContent="space-between">
                <Box flex={{ base: '1 1 100%', sm: '1 1' }} mb={{ base: '2', sm: '0' }}>
                  <Text fontSize="14px">
                    {t('Found')} <Text as="strong">{isLoading ? 0 : totalCount}</Text>{' '}
                    {t('results')}
                  </Text>
                </Box>
                {isMobile && (
                  <Box>
                    <SecondaryButton size="sm" onClick={onOpenFilterModal}>
                      {t('Open Filter')}
                    </SecondaryButton>
                  </Box>
                )}
                <Box width="48" ml="auto">
                  <Flex {...sortByGroup} mt={0} alignItems="center">
                    <Text marginRight="2">{t('Sort by')}:</Text>
                    <Flex flexGrow={1}>
                      <SelectComponentUnStyled
                        name="sortBy"
                        value={{
                          label: getSortLabel(addressBookSortByEnum as AddressBookSortByEnum),
                          value: addressBookSortByEnum,
                        }}
                        options={sortBySelectOptions || []}
                        label=""
                        valueKey="value"
                        labelKey="label"
                        onChange={(item) => setAddressBookSortByEnum(item?.value)}
                        isClearable={false}
                        formControlProps={{ m: 0 }}
                        placeholder={t('Sort By')}
                      />
                    </Flex>
                  </Flex>
                </Box>
              </HStack>
              <Box flex="1" overflowY="auto">
                <InfiniteScroll
                  hasMoreData={hasNextPage || false}
                  isInitLoading={isLoading}
                  isLoading={isLoading || isFetchingNextPage}
                  isFetchingNextPage={isFetchingNextPage}
                  onBottomHit={fetchNextPage}
                  loadOnMount={true}
                  height="100%"
                >
                  <AddressBookList onEdit={onEdit} />
                </InfiniteScroll>
              </Box>
            </VStack>
          </HStack>
          {mode !== AddressBookModeEnum.InAppMail &&
            (country.code === 'CA' || country.code === 'US') && (
              <FloatingActionButton
                onClick={onAdd}
                right="auto"
                left={{ base: '0', md: '15px' }}
                height={{ base: '40px', md: '60px' }}
                width={{ base: '40px', md: '60px' }}
              >
                <PlusIconBig color="#fff" fontSize={{ base: '14px', md: '20px' }} />
              </FloatingActionButton>
            )}
          {mode !== AddressBookModeEnum.Browse && (
            <Flex justifyContent="flex-end" alignItems="flex-end">
              <PrimaryButton onClick={onComplete} minW="16">
                {t('Done')}
              </PrimaryButton>
            </Flex>
          )}
        </VStack>
      )}

      {isMobile && (
        <BaseModal isOpen={isFilterModalOpen} onDismiss={onCloseFilterModal} title={t('Filter')}>
          <ModalBody flex="1" overflowY="auto">
            <VStack overflowY="auto" height="100%" alignItems="stretch" px="1" spacing="4">
              <Box width="100%">
                <CountrySelectUnStyled
                  name="countryID"
                  value={country}
                  options={countries}
                  label=""
                  valueKey="countryID"
                  labelKey="name"
                  onChange={setCountry}
                  isClearable={false}
                  formControlProps={{ m: 0 }}
                />
              </Box>
              <Box width="100%">
                <SelectComponentUnStyled
                  name="tagID"
                  value={specialty}
                  options={specialties || []}
                  label=""
                  valueKey="tagID"
                  labelKey="description"
                  onChange={setSpecialty}
                  isClearable={true}
                  formControlProps={{ m: 0 }}
                  placeholder={t('Specialties')}
                />
              </Box>
              <Wrap
                spacing={2}
                css={hiddenScrollbar}
                minH="40px"
                alignItems="flex-start"
                width="100%"
              >
                {browseByFolderTags.map((item) => {
                  return (
                    <WrapItem key={item.value}>
                      <Tag
                        text={item.text}
                        containerProps={{
                          role: 'button',
                          onClick: () => onToggleAddressBookFolder(item.value),
                          bgColor: isFolderSelected(selectedAddressBookFolders, item.value)
                            ? 'purple.600'
                            : 'purple.50',
                          color: isFolderSelected(selectedAddressBookFolders, item.value)
                            ? 'white'
                            : 'purple.600',
                          _hover: { bgColor: 'purple.600', color: 'white' },
                        }}
                        textProps={{
                          color: 'inherit',
                        }}
                      />
                    </WrapItem>
                  );
                })}

                {browseByTypeTags.map((item) => {
                  return (
                    <WrapItem key={item.value}>
                      <Tag
                        text={item.text}
                        containerProps={{
                          role: 'button',
                          onClick: () =>
                            setAddressBookTypeEnum(
                              addressBookTypeEnum === item.value ? undefined : item.value,
                            ),
                          bgColor: addressBookTypeEnum === item.value ? 'purple.600' : 'purple.50',
                          color: addressBookTypeEnum === item.value ? 'white' : 'purple.600',
                          _hover: { bgColor: 'purple.600', color: 'white' },
                        }}
                        textProps={{
                          color: 'inherit',
                        }}
                      />
                    </WrapItem>
                  );
                })}
              </Wrap>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <PrimaryButton onClick={onCloseFilterModal}>{t('Close')}</PrimaryButton>
          </ModalFooter>
        </BaseModal>
      )}
    </>
  );
};

const hiddenScrollbar = {
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
};
