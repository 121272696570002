import React from 'react';
import { useTransition, animated } from 'react-spring';

interface IProps {
  isActive?: boolean;
  children: any;
  className?: string;
}

const FadeInComponent: React.FC<IProps> = ({ isActive = true, children, className }) => {
  const fadeIn = useTransition(isActive, null, {
    from: { opacity: 0, top: -10 },
    enter: { opacity: 1, top: 0 },
    leave: { opacity: 0, top: -10 },
    config: { duration: 500 },
  });

  return (
    <>
      {fadeIn.map(
        ({ item, props, key }) =>
          item && (
            <animated.div
              className={className}
              style={{
                ...props,
                top: props.top!.interpolate((x) => `${x}px`),
                position: 'relative',
              }}
              key={key}
            >
              {children}
            </animated.div>
          ),
      )}
    </>
  );
};

export default FadeInComponent;
