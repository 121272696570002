import { createAsyncAction, ActionType } from 'typesafe-actions';
import { IProvider } from '../../generated';

export const getProviderActions = createAsyncAction(
  ['GET_PROVIDER_REQUEST', () => {}],
  ['GET_PROVIDER_SUCCESS', (res: IProvider) => res],
  ['GET_PROVIDER_ERROR', (err: string) => err],
)();

export const updateProviderProfileActions = createAsyncAction(
  ['UPDATE_PROVIDER_PROFILE_REQUEST', () => {}],
  ['UPDATE_PROVIDER_PROFILE_SUCCESS', () => {}],
  ['UPDATE_PROVIDER_PROFILE_ERROR', (err: string) => err],
)();

export type ProviderActionTypes = ActionType<
  typeof getProviderActions | typeof updateProviderProfileActions
>;
