import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { COOKIE_NAME, Device } from '../../constants';
import { AuthClient, VersionClient } from '../../generated';
import { httpRequest } from '../../utils';
import { scrollToTop } from '../../utils/scrollToTop';

import tempImage from './assets/outpost_background.svg';
import { useViewHeight } from '../../hooks/useViewHeight';
import {
  PublicSearchFooter,
  PublicSearchHeader,
} from '../Unauthenticated/components/PublicSearchComponents';
import { useUnAuthenticatedLayout } from './hooks/useUnAuthenticatedLayout/useUnAuthenticatedLayout';
import { IAuthenticateUserSignIn } from '../../types';
import { setCookie } from '../../utils/cookieHelper';
import { useDispatch } from 'react-redux';
import { signIn } from '../../store/root-creator';

const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 18px;
  min-height: 70%;
  align-items: center;
  width: 100%;
  form {
    width: 90%;
    max-width: 450px;
    @media (min-width: 500px) {
      max-width: 450px;
    }
    margin: 0 auto;

    @media ${Device.laptop} {
      margin: 0;
    }
  }

  @media ${Device.mobileL} {
    width: 90%;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`;

interface IPartnerSideDisplayProps {
  children?: React.ReactNode;
}

export const PartnerSideDisplay = ({ children }: IPartnerSideDisplayProps) => {
  const {
    campaignImage,
    campaignLogo,
    campaignSubTitle,
    campaignTitle,
    currentCampaignLoading,
    currentPartnerLoading,
    partnerImage,
    partnerLogo,
  } = useUnAuthenticatedLayout();
  const routeLocation = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      ['health', 'devhealth', 'demohealth'].includes(window.location.hostname?.split('.')[0]) &&
      !routeLocation.pathname?.includes('/heal/health-records')
    ) {
      const fromSearchParam = new URLSearchParams(routeLocation.search?.split('?')[1]);
      const authenticateSignIn: IAuthenticateUserSignIn = {};
      if (fromSearchParam.get('t')) {
        authenticateSignIn.t = fromSearchParam.get('t') as string;
        setCookie(COOKIE_NAME, authenticateSignIn.t!);
        fromSearchParam.delete('t');
      }
      if (fromSearchParam.get('u')) {
        authenticateSignIn.u = fromSearchParam.get('u') as string;
        fromSearchParam.delete('u');
      }
      routeLocation.search = fromSearchParam.toString();

      if (authenticateSignIn && authenticateSignIn.t && authenticateSignIn.u) {
        try {
          dispatch(signIn({}, () => '', authenticateSignIn));
        } catch (error) {}
      }

      window.location.href = '/heal/health-records';
    }
  }, [dispatch, routeLocation, routeLocation.pathname]);

  return (
    <>
      {!currentPartnerLoading && !currentCampaignLoading && (
        <>
          {campaignImage ? (
            <Flex direction="column" height="100%" px={{ base: '8', lg: '16' }}>
              <VStack
                alignItems="flex-start"
                height="100%"
                justifyContent={{ md: 'center', xl: 'flex-start' }}
              >
                <Box>
                  {campaignLogo && (
                    <Box maxW="7.5rem">
                      <Image src={campaignLogo} />
                    </Box>
                  )}
                  <VStack maxW="40rem" alignItems="flex-start">
                    {campaignTitle && (
                      <Text
                        fontSize={{ base: '20px', lg: '32px' }}
                        fontWeight="semibold"
                        dangerouslySetInnerHTML={{ __html: campaignTitle }}
                      />
                    )}
                    {campaignSubTitle && (
                      <Text dangerouslySetInnerHTML={{ __html: campaignSubTitle }} />
                    )}
                  </VStack>
                </Box>
                {campaignImage && (
                  <Box width="100%" flex={{ xl: '1' }}>
                    <Image loading="eager" maxH="100%" src={campaignImage} />
                  </Box>
                )}
              </VStack>
            </Flex>
          ) : partnerImage ? (
            <Flex
              direction="column"
              justify="flex-end"
              background={`url(${partnerImage})`}
              backgroundSize="cover"
              height="100%"
              w="100%"
            >
              <Box
                display={{ base: 'none', md: 'block' }}
                w="70%"
                borderTopRightRadius="30px"
                p={4}
                background="#FFFFFF"
              >
                {partnerLogo && (
                  <Box maxW="7.5rem">
                    <Image src={partnerLogo} />
                  </Box>
                )}
                <Text textTransform="unset" fontSize="12px" mt={2}>
                  Powered BY {process.env.REACT_APP_COMPANY_NAME}
                </Text>
              </Box>
            </Flex>
          ) : (
            <>{children}</>
          )}
        </>
      )}
    </>
  );
};

const UnauthenticatedLayout = ({ children }) => {
  const { showHeaderAndFooter } = useUnAuthenticatedLayout();

  const [, setVersion] = useState<string>('');

  const { getVH } = useViewHeight();

  useEffect(() => {
    const getApiVerion = async () => {
      const authClient = new AuthClient();
      const versionClient = new VersionClient(authClient);
      try {
        const x = await httpRequest(() => versionClient.aPIVersion());
        setVersion(x);
      } catch (err) {}
    };
    getApiVerion();
  }, []);
  const location = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  return (
    <>
      <Box width="100%" minH={{ base: getVH(90), md: '100vh' }} backgroundColor="#F4F4F4">
        {showHeaderAndFooter && <PublicSearchHeader />}
        <Flex
          w="100%"
          direction={{ base: 'column', md: 'row' }}
          minH={{ base: getVH(90), md: showHeaderAndFooter ? '90vh' : '100vh' }}
          justify={{ md: 'space-between' }}
          pt={{ base: '4', md: '0' }}
        >
          <Flex
            alignItems={{ base: 'flex-end', md: 'center' }}
            justifyContent="center"
            w={{ base: '100%', md: '40%', lg: '60%' }}
          >
            <PartnerSideDisplay>
              <Flex width="100%" justifyContent="center" maxW={{ base: '400px', md: '80%' }}>
                <Image loading="eager" src={tempImage} />
              </Flex>
            </PartnerSideDisplay>
          </Flex>

          <Flex
            align="center"
            justify="center"
            background="#F4F4F4"
            borderRadius="0.5rem"
            borderTopRadius={{ base: '2rem', md: '0.5rem' }}
            pt={{ base: 4, md: 0 }}
            pb={{ base: 4, md: 0 }}
            w={{ base: '100%', md: '60%', lg: '40%' }}
          >
            <RightSideWrapper>{children}</RightSideWrapper>
          </Flex>
        </Flex>
        {showHeaderAndFooter && <PublicSearchFooter />}
      </Box>
    </>
  );
};

export default UnauthenticatedLayout;
