import reduceReducers from 'reduce-reducers';
import { AnyAction, combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { IPatient, Patient } from '../../generated';
import { updateOrAdd } from '../../utils';
import { CurrentUserActionTypes, logoutActions } from '../currentUser/currentUserActions';
import {
  UpdateCommunityPictureActionTypes,
  updateCommunityPicturePatientSuccess,
} from '../pictureUpload/updateCommunityPictureAction';
import {
  UpdateProfilePictureActionTypes,
  updateProfilePicturePatientSuccess,
} from '../pictureUpload/updateProfilePictureAction';
import {
  clearErrorAction,
  createPatientInsuranceActions,
  createPHRDependantActions,
  deletePatientInsuranceActions,
  deletePHRDependantActions,
  getAllPatientCareTeamActions,
  getAllPatientInsuranceActions,
  getAllPHRDependantsActions,
  getOnePatientInsuranceActions,
  getOnePHRDependantActions,
  getPatientActions,
  PatientActionTypes,
  setPatientLoginPurpose,
  setPatientProfile,
  updatePatientInsuranceActions,
  updatePatientProfileActions,
  updatePatientsPhoneNumber,
  updatePHRDependantActions,
} from './patientActions';

const patient = createReducer<
  IPatient | null,
  | PatientActionTypes
  | CurrentUserActionTypes
  | UpdateCommunityPictureActionTypes
  | UpdateProfilePictureActionTypes
>(null)
  .handleAction([getPatientActions.success], (_state, action) => action.payload)
  .handleAction([logoutActions], () => initialState.patient)
  .handleAction([updateCommunityPicturePatientSuccess], (state, action) =>
    Object.assign({}, state, {
      discussionsImageDetailID: action.payload,
    }),
  )
  .handleAction([updateProfilePicturePatientSuccess], (state, action) =>
    Object.assign({}, state, {
      imageDetailID: action.payload,
    }),
  )
  .handleAction([getAllPHRDependantsActions.success], (state, action) =>
    Object.assign({}, state, {
      patientDependants: action.payload,
    }),
  )
  .handleAction([getAllPatientInsuranceActions.success], (state, action) =>
    Object.assign({}, state, {
      patientInsurances: action.payload,
    }),
  )
  .handleAction([getOnePHRDependantActions.success], (state, action) =>
    Object.assign({}, state, {
      patientDependants: updateOrAdd(
        (state && state.userDetail?.userDetailRelationships) || [],
        'userDetailRelationshipID',
        action.payload.userDetailRelationshipID,
        action.payload,
      ),
    }),
  )
  .handleAction([getOnePatientInsuranceActions.success], (state, action) =>
    Object.assign({}, state, {
      patientInsurances: updateOrAdd(
        (state && state.patientInsurances) || [],
        'patientInsuranceID',
        action.payload.patientInsuranceID,
        action.payload,
      ),
    }),
  )
  .handleAction([deletePHRDependantActions.success], (state, action) =>
    Object.assign({}, state, {
      patientDependants: ((state && state.userDetail?.userDetailRelationships) || []).filter(
        (x) => x.userDetailRelationshipID !== action.payload,
      ),
    }),
  )
  .handleAction([deletePatientInsuranceActions.success], (state, action) =>
    Object.assign({}, state, {
      patientInsurances: ((state && state.patientInsurances) || []).filter(
        (x) => x.patientInsuranceID !== action.payload,
      ),
    }),
  )
  .handleAction([updatePatientsPhoneNumber], (state, action) => {
    if (!state) {
      return state;
    }

    if (action.payload.patientID === state.patientID) {
      return Object.assign({}, state, {
        cellPhoneNumber: action.payload.cellPhoneNumber,
        homePhoneNumber: action.payload.homePhoneNumber,
      });
    } else {
      return Object.assign({}, state, {
        patientDependants: (state.userDetail?.userDetailRelationships ?? []).map((x) => {
          if (x.userDetail?.patient?.patientID === action.payload.patientID) {
            const patient = new Patient();
            patient.init(x.userDetail?.patient);
            patient.cellPhoneNumber = action.payload.cellPhoneNumber;
            patient.homePhoneNumber = action.payload.homePhoneNumber;
            return Object.assign({}, x, {
              patient,
            });
          }
          return x;
        }),
      });
    }
  })
  .handleAction([setPatientProfile], (state, action) => {
    if (!state) {
      return state;
    }

    if (action.payload.patientID === state.patientID) {
      return Object.assign({}, state, action.payload);
    } else {
      return Object.assign({}, state, {
        patientDependants: (state.userDetail?.userDetailRelationships ?? []).map((x) => {
          if (x.userDetail?.patient?.patientID === action.payload.patientID) {
            return Object.assign({}, x, action.payload);
          }
          return x;
        }),
      });
    }
  })
  .handleAction([getAllPatientCareTeamActions.success], (state, action) =>
    Object.assign({}, state, {
      patientCareTeams: action.payload,
    }),
  )
  .handleAction([setPatientLoginPurpose], (state, action) =>
    Object.assign({}, state, {
      loginPurpose: action.payload,
    }),
  );

const error = createReducer<string, PatientActionTypes>('')
  .handleAction(
    [
      updatePatientProfileActions.failure,
      getPatientActions.failure,
      getAllPHRDependantsActions.failure,
      getOnePHRDependantActions.failure,
      updatePHRDependantActions.failure,
      deletePHRDependantActions.failure,
      createPHRDependantActions.failure,
      getAllPatientInsuranceActions.failure,
      getOnePatientInsuranceActions.failure,
      updatePatientInsuranceActions.failure,
      deletePatientInsuranceActions.failure,
      createPatientInsuranceActions.failure,
      getAllPatientCareTeamActions.failure,
    ],
    (_state, action) => action.payload || '',
  )
  .handleAction([clearErrorAction], () => '');

const isLoading = createReducer<boolean, PatientActionTypes>(false)
  .handleAction([getPatientActions.request, updatePatientProfileActions.request], () => true)
  .handleAction(
    [
      getPatientActions.failure,
      getPatientActions.success,
      updatePatientProfileActions.success,
      updatePatientProfileActions.failure,
    ],
    () => false,
  );

const isPhrLoading = createReducer<boolean, AnyAction>(false)
  .handleAction(
    [
      getAllPHRDependantsActions.request,
      getOnePHRDependantActions.request,
      updatePHRDependantActions.request,
      deletePHRDependantActions.request,
      createPHRDependantActions.request,
      getAllPatientInsuranceActions.request,
      getOnePatientInsuranceActions.request,
      updatePatientInsuranceActions.request,
      deletePatientInsuranceActions.request,
      createPatientInsuranceActions.request,
      getAllPatientCareTeamActions.request,
    ],
    () => true,
  )
  .handleAction(
    [
      getAllPHRDependantsActions.success,
      getAllPHRDependantsActions.failure,
      getOnePHRDependantActions.success,
      getOnePHRDependantActions.failure,
      updatePHRDependantActions.success,
      updatePHRDependantActions.failure,
      deletePHRDependantActions.success,
      deletePHRDependantActions.failure,
      createPHRDependantActions.success,
      createPHRDependantActions.failure,
      getAllPatientInsuranceActions.success,
      getOnePatientInsuranceActions.success,
      updatePatientInsuranceActions.success,
      deletePatientInsuranceActions.success,
      createPatientInsuranceActions.success,
      getAllPatientInsuranceActions.failure,
      getOnePatientInsuranceActions.failure,
      updatePatientInsuranceActions.failure,
      deletePatientInsuranceActions.failure,
      createPatientInsuranceActions.failure,
      getAllPatientCareTeamActions.success,
      getAllPatientCareTeamActions.failure,
    ],
    () => false,
  );

export interface IPatientState {
  error: string;
  isLoading: boolean;
  isPhrLoading: boolean;
  patient: (IPatient & { loginPurpose?: PatientLoginPurposeEnum }) | null;
}

const patientReducers = combineReducers<IPatientState>({
  patient,
  error,
  isLoading,
  isPhrLoading,
});

const initialState: IPatientState = {
  error: '',
  isLoading: false,
  isPhrLoading: false,
  patient: null,
};

// @ts-ignore
const reduce = reduceReducers(initialState, patientReducers);

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function patientReducer(state: IPatientState = initialState, action): IPatientState {
  // @ts-ignore
  return reduce(state, action);
}

export enum PatientLoginPurposeEnum {
  SEND_FUNDS,
  BOOK_CONSULT,
  OTHERS,
}
