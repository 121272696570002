import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { CurrentUserActionTypes, logoutActions } from '../currentUser/currentUserActions';
import {
  getUnreadMailMessageCountActions,
  GetUnreadMailMessageCountActionTypes,
} from './getUnreadMailMessageCountAction';

export interface IUnreadMailMessageCountState {
  error: string;
  isLoading: boolean;
  count: number | null;
}

const count = createReducer<
  number | null,
  GetUnreadMailMessageCountActionTypes | CurrentUserActionTypes
>(null)
  .handleAction([getUnreadMailMessageCountActions.success], (_, action) => action.payload)
  .handleAction([logoutActions], () => null);

const error = createReducer<string, GetUnreadMailMessageCountActionTypes>('')
  .handleAction([getUnreadMailMessageCountActions.failure], (_, action) => action.payload)
  .handleAction([getUnreadMailMessageCountActions.success], () => '');

const isLoading = createReducer<boolean, GetUnreadMailMessageCountActionTypes>(false)
  .handleAction([getUnreadMailMessageCountActions.request], () => true)
  .handleAction(
    [getUnreadMailMessageCountActions.failure, getUnreadMailMessageCountActions.success],
    () => false,
  );

export default combineReducers<IUnreadMailMessageCountState>({
  count,
  error,
  isLoading,
});
