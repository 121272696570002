import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import styled from 'styled-components';
import Image from '../assets/images/Art_Monitor03.png';
import { PrimaryButton } from '../components/Buttons/PrimaryButton';
import { DashboardRoutes, Device, PhoneEventsEnum, SignInRoutes } from '../constants';
import { AuthClient, ErrorLog, ErrorLogsClient } from '../generated';
import { AppState } from '../store/root-reducers';
import AuthenticatedLayout from './layouts/AuthenticatedLayout';
import useTranslationComponent from '../hooks/useTranslationComponent';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100vh - 207px);
`;

const Container = styled.div`
  width: 75%;
  max-width: 600px;
`;

const ErrorImage = styled.img`
  margin-bottom: 20px;
  height: 315px;
`;

const ImageWrapper = styled.div`
  display: none;

  @media ${Device.mobileL} {
    display: flex;
    justify-content: center;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
`;

const TopMessage = styled.h3``;

const ErrorDescriptionLine = styled.p``;

const BottomMessage = styled.p`
  margin: 20px 0;
  font-size: 14px;
`;

interface IProps {
  errorLog: ErrorLog;
}

const Status500: React.FC<IProps> = ({ errorLog }) => {
  const { t } = useTranslationComponent();
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const [referenceId, setReferenceId] = useState<string>('-');

  const location = useLocation();

  useEffect(() => {
    const handleError = async () => {
      try {
        const errorLogsClient = new ErrorLogsClient(new AuthClient(''));
        const result = await errorLogsClient.errorLogsPost(errorLog);
        setReferenceId(result);
      } catch (err) {}
    };
    if (errorLog) {
      handleError();
    }
  }, [errorLog]);

  if (currentUser) {
    const onButtonClick = matchPath(location.pathname, {
      path: DashboardRoutes.Dashboard,
      exact: true,
    })
      ? () => window.location.reload()
      : () => (window.location.href = DashboardRoutes.Dashboard);

    const onClick = () => {
      if (currentUser?.isInMobileView) {
        if (currentUser?.mobileSystem === 'android') {
          // @ts-ignore
          AndroidResult.callback(PhoneEventsEnum.PAGE_ERROR_REDIRECT);
        } else {
          // @ts-ignore
          webkit.messageHandlers.pageErrorCallbackHandler.postMessage(
            PhoneEventsEnum.PAGE_ERROR_REDIRECT,
          );
        }
      } else onButtonClick();
    };

    return (
      <AuthenticatedLayout hasError={true}>
        <Wrapper>
          <Container>
            <ImageWrapper>
              <ErrorImage src={Image} />
            </ImageWrapper>
            <TopMessage>{t('Well this is unexpected...')}</TopMessage>
            {/* <ErrorDescriptionLine>
              Message:
            </ErrorDescriptionLine>
            <ErrorDescriptionLine>Error code: 500</ErrorDescriptionLine> */}
            <ErrorDescriptionLine>
              {t('Reference')} #: {referenceId}
            </ErrorDescriptionLine>

            <BottomMessage>
              {t(
                "The error has been logged and our technicians will investigate as soon as possible. If you need immediate help from our customer service team with a virtual visit, please call us. If it isn't an urgent matter, please visit our Help Center for additional information. Thank you for your patience.",
              )}
            </BottomMessage>
            <ButtonRow>
              <PrimaryButton onClick={onClick}>{t('Return to Dashboard')}</PrimaryButton>
            </ButtonRow>
          </Container>
        </Wrapper>
      </AuthenticatedLayout>
    );
  } else {
    const onClick = matchPath(location.pathname, {
      path: SignInRoutes.SignIn,
      exact: true,
    })
      ? () => window.location.reload()
      : () => (window.location.href = SignInRoutes.SignIn);
    return (
      <Wrapper>
        <Container>
          <ImageWrapper>
            <ErrorImage src={Image} />
          </ImageWrapper>
          <TopMessage>{t('Well this is unexpected...')}</TopMessage>
          <ErrorDescriptionLine>
            {t('Message')}:<b>{errorLog ? errorLog.message : ''}</b>
          </ErrorDescriptionLine>
          <ErrorDescriptionLine>{t('Error code')}: 500</ErrorDescriptionLine>
          <ErrorDescriptionLine>
            {t('Reference')} #: {referenceId}
          </ErrorDescriptionLine>

          <BottomMessage>
            {t(
              "The error has been logged and our technicians will investigate as soon as possible. If you need immediate help from our customer service team with a virtual visit, please call us. If it isn't an urgent matter, please visit our Help Center for additional information. Thank you for your patience.",
            )}
          </BottomMessage>
          <ButtonRow>
            <PrimaryButton onClick={onClick}>{t('Return to Login')}</PrimaryButton>
          </ButtonRow>
        </Container>
      </Wrapper>
    );
  }
};

export default Status500;
