const eventNames = {
  // Common events
  'login:login_auth_clicked': 'login:login_auth_clicked', // Posted when the user clicks the login button after entering their credentials
  'login:login_result_success': 'login:login_result_success', // Posted when the login is successful
  'login:login_result_failed': 'login:login_result_failed', // Posted when the login fails
  'login:login_not_valid': 'login:login_not_valid', //Posted when the login credentials are invalid (e.g. Not a valid email/Password field empty)
  'login:forgot_password_clicked': 'login:forgot_password_clicked', // Posted when the User clicked on the Forgot Password button
  'login:patient_sign_up_clicked': 'login:patient_sign_up_clicked', // Posted when the Patient clicked on Sign Up
  'login:provider_sign_up_clicked': 'login:provider_sign_up_clicked', // Posted when the Provider clicked on Sign Up

  'reset_password:reset_sent_result_success': 'reset_password:reset_sent_result_success', //Posted when the reset request is sent successfully after entering their email
  'reset_password:reset_sent_result_failed': 'reset_password:reset_sent_result_failed', // Posted when the reset request is unsuccessfully sent
  'reset_password:email_not_valid': 'reset_password:email_not_valid', // Posted when the email for reset request is not valid
  'reset_password:security_code_result_success': 'reset_password:security_code_result_success', // Posted when the reset security code is validated successfully
  'reset_password:security_code_invalid': 'reset_password:security_code_invalid', // Posted when the reset security code is invalid
  'reset_password:security_code_result_failed': 'reset_password:security_code_result_failed', // Posted when the reset security code validation fails
  'reset_password:security_code_not_valid': 'reset_password:security_not_valid', // Posted when the security code is valid (e.g. not long enough or empty)
  'reset_password:new_password_result_success': 'reset_password:new_password_result_success', // Posted when the new password is successfully set
  'reset_password:new_password_result_failed': 'reset_password:new_password_result_failed', // Posted when setting the new password fails
  'reset_password:new_password_not_valid': 'reset_password:new_password_not_valid', // Posted when the new password is not valid

  'sign_up:patient_sign_up_process_started': 'sign_up:patient_sign_up_process_started', // Posted when the patient clicks next on selecting sign up type
  'sign_up:patient_security_code_sent': 'sign_up:patient_security_code_sent', //Posted when the patient enters a valid email/phone number and the code is sent to them
  'sign_up:patient_security_code_sent_failed': 'sign_up:patient_security_code_sent_failed', // Posted when the patient enters a valid email/phone number but the code fails to send
  'sign_up:patient_security_code_entered_successfully':
    'sign_up:patient_security_code_entered_successfully', // Posted when the patient enters the security code and it is successfully validated
  'sign_up:patient_security_code_entered_failed': 'sign_up:patient_security_code_entered_failed', // Posted when the patient enters the security code but it fails
  'sign_up:patient_password_created_successfully': 'sign_up:patient_password_created_successfully', // Posted when the patient creates their password successfully
  'sign_up:patient_password_created_failed': 'sign_up:patient_password_created_failed', // Posted when the patient creates their password but it fails
  'sign_up:patient_signed_up_successfully': 'sign_up:patient_signed_up_successfully', // Posted when the patient enters in the final details (Gender/DOB) successfully
  'sign_up:patient_signed_up_failed': 'sign_up:patient_signed_up_failed', // Posted when the patient enters in the final details (Gender/DOB) but it fails
  'sign_up:patient_sign_up_complete': 'sign_up:patient_sign_up_complete', // Posted when the patient is created successfully and the process is complete

  'sign_up:provider_sign_up_process_started': 'sign_up:provider_sign_up_process_started', // Posted when the provider clicks next on selecting sign up type
  'sign_up:provider_security_code_sent': 'sign_up:provider_security_code_sent', //Posted when the provider enters a valid email/phone number and the code is sent to them
  'sign_up:provider_security_code_sent_failed': 'sign_up:provider_security_code_sent_failed', // Posted when the provider enters a valid email/phone number but the code fails to send
  'sign_up:provider_security_code_entered_successfully':
    'sign_up:provider_security_code_entered_successfully', // Posted when the provider enters the security code and it is successfully validated
  'sign_up:provider_security_code_entered_failed': 'sign_up:provider_security_code_entered_failed', // Posted when the provider enters the security code but it fails
  'sign_up:provider_password_created_successfully':
    'sign_up:provider_password_created_successfully', // Posted when the provider creates their password successfully
  'sign_up:provider_password_created_failed': 'sign_up:provider_password_created_failed', // Posted when the provider creates their password but it fails
  'sign_up:provider_signed_up_successfully': 'sign_up:provider_signed_up_successfully', // Posted when the provider enters in the final details (Gender/DOB) successfully
  'sign_up:provider_signed_up_failed': 'sign_up:provider_signed_up_failed', // Posted when the provider enters in the final details (Gender/DOB) but it fails
  'sign_up:provider_sign_up_complete': 'sign_up:provider_sign_up_complete', // Posted when the provider has submitted documents and the process is complete

  'mail:patient_mail_button_clicked': 'mail:patient_mail_button_clicked',

  'mail:provider_mail_button_clicked': 'mail:provider_mail_button_clicked',

  'profile:patient_profile_button_clicked': 'profile:patient_profile_button_clicked',

  'profile:provider_profile_button_clicked': 'profile:provider_profile_button_clicked',

  'waiting_room:patient_waiting_room_selected': 'waiting_room:patient_waiting_room_selected',
  'waiting_room:patient_clicked_add_symptoms': 'waiting_room:patient_clicked_add_symptoms', // Posted when the patient clicks on add symptoms
  'waiting_room:patient_clicked_health_records': 'waiting_room:patient_clicked_health_records', // Posted when the patient clicks on health records
  'waiting_room:patient_clicked_fill_intake': 'waiting_room:patient_clicked_fill_intake', // Posted when the patient clicks on fill intake form
  'waiting_room:patient_clicked_check_in': 'waiting_room:patient_clicked_check_in', // Posted when the patient clicks on check-in
  'waiting_room:patient_clicked_cancel_appointment':
    'waiting_room:patient_clicked_cancel_appointment', // Posted when the patient clicks on cancel appointment

  'waiting_room:provider_waiting_room_selected': 'waiting_room:provider_waiting_room_selected',

  'dashboard:go_to_appointment_nag_bar_clicked': 'dashboard:go_to_appointment_nag_bar_clicked',
  'dashboard:patient_accepted_terms_and_conditions':
    'dashboard:patient_accepted_terms_and_conditions', // Posted when the patient accepts terms and conditions
  'dashboard:patient_accepted_terms_and_conditions_failed':
    'dashboard:patient_accepted_terms_and_conditions_failed', // Posted when the patient accepts terms and conditions but it fails
  'dashboard:provider_accepted_terms_and_conditions':
    'dashboard:provider_accepted_terms_and_conditions', // Posted when the provider accepts terms and conditions
  'dashboard:provider_accepted_terms_and_conditions_failed':
    'dashboard:provider_accepted_terms_and_conditions_failed', // Posted when the provider accepts terms and conditions but it fails

  'book_consult:patient_selected_consult_type': 'book_consult:patient_selected_consult_type', // Posted when the patient selects a consult type
  'book_consult:patient_selected_consult_patient': 'book_consult:patient_selected_consult_patient', // Posted when the patient selects a consult patient
  'book_consult:patient_clicked_add_dependent': 'book_consult:patient_clicked_add_dependent', // Posted when the patient clicks to add a dependent patient
  'book_consult:patient_clicked_edit_details': 'book_consult:patient_clicked_edit_details', // Posted when the patient clicks to update their information
  'book_consult:patient_edit_details_successful': 'book_consult:patient_edit_details_successful', // Posted when the patient successfully updates their information
  'book_consult:patient_edit_details_failed': 'book_consult:patient_edit_details_failed', // Posted when the patient failed to update their information
  'book_consult:patient_clicked_to_verify_identity':
    'book_consult:patient_clicked_to_verify_identity', // Posted when the patient clicks continue after verifying their identity
  'book_consult:patient_selected_consult_visit_type':
    'book_consult:patient_selected_consult_visit_type', // Posted when the patient selects a visit type (Video/In-person)
  'book_consult:patient_selected_provider_preference':
    'book_consult:patient_selected_provider_preference', // Posted when the patient clicks continue after selecting provider preferences
  'book_consult:patient_clicked_care_team_providers':
    'book_consult:patient_clicked_care_team_providers', // Posted when the patient clicks care team providers
  'book_consult:patient_clicked_search_providers': 'book_consult:patient_clicked_search_providers', // Posted when the patient clicks to search for a provider
  'book_consult:patient_selected_provider': 'book_consult:patient_selected_provider', // Posted when the patient selects a provider
  'book_consult:patient_show_provider_details': 'book_consult:patient_show_provider_details', // Posted when the patient proceeds to view the selected provider details
  'book_consult:patient_selected_storeItem': 'book_consult:patient_selected_storeItem', // Posted when the patient selects a store item
  'book_consult:patient_reserve_appointment_slot_successful':
    'book_consult:patient_reserve_appointment_slot_successful', // Posted when the patient successfully reserves an appointment slot
  'book_consult:patient_reserve_appointment_slot_failed':
    'book_consult:patient_reserve_appointment_slot_failed', // Posted when the patient failed to reserve an appointment slot
  'book_consult:patient_coupon_application_successful':
    'book_consult:patient_coupon_application_successful', // Posted when the patient adds a coupon successfully
  'book_consult:patient_coupon_application_failed':
    'book_consult:patient_coupon_application_failed', // Posted when the patient fails to add a coupon successfully
  'book_consult:patient_clicked_pay': 'book_consult:patient_clicked_pay', // Posted when the patient clicks to pay for consult
  'book_consult:patient_booking_successful': 'book_consult:patient_booking_successful', // Posted when the consult booking is successful
  'book_consult:patient_booking_failed': 'book_consult:patient_booking_failed', // Posted when the consult booking fails
  'book_consult:patient_booking_complete': 'book_consult:patient_booking_complete', // Posted when the patient clicks on done and goes to waiting room

  'video_consult:patient_go_to_video_consult_clicked':
    'video_consult:patient_go_to_video_consult_clicked', // Posted when a Patient enters the preview room before joining a video consult
  'video_consult:patient_join_video_consult_clicked':
    'video_consult:patient_join_video_consult_clicked', // Posted when a Patient clicks 'Join' video consult
  'video_consult:patient_end_video_consult_clicked':
    'video_consult:patient_end_video_consult_clicked', // Posted when the patient clicks to end consult
  'video_consult:patient_video_consult_ended': 'video_consult:patient_video_consult_ended', // Posted when the video consult has been ended - "label" : "<Who ended it (Patient/Provider>"

  'video_consult:provider_begin_video_consult_clicked':
    'video_consult:provider_begin_video_consult_clicked', // Posted when a provider clicks 'Begin' video consult
  'video_consult:provider_re_join_video_consult_clicked':
    'video_consult:provider_re_join_video_consult_clicked', // Posted when a provider clicks 're-join' video consult
  'video_consult:provider_end_video_consult_clicked':
    'video_consult:provider_end_video_consult_clicked', // Posted when the provider clicks to end consult

  'video_consult:ending_video_consult_result_success':
    'video_consult:ending_video_consult_result_success', // Posted when ending a video consult is successful
  'video_consult:ending_video_consult_result_failed':
    'video_consult:ending_video_consult_result_failed', // Posted when ending a video consult fails - "label" : "<Failure Reason>"
  'video_consult:joining_video_consult_result_success':
    'video_consult:joining_video_consult_result_success', // Posted when joining a video consult is successful
  'video_consult:joining_video_consult_result_failed':
    'video_consult:joining_video_consult_result_failed', // Posted when joining a video consult fails - "label" : "<Failure Reason>"
  'video_consult:error_occurred': 'video_consult:error_occurred', // Posted when an error occurs - "label" : "<Error>"

  'video_consult:background_changed': 'video_consult:background_changed', // Posted when virtual background changes
  'video_consult:background_changed_error': 'video_consult:background_changed_error', // Posted when virtual background changed failed - "label" : "<Error>"

  'payment:selected_payment_method': 'payment:selected_payment_method', // Posted when user selects a payment method
  'payment:payment_successful': 'payment:payment_successful', // Posted when payment is successful
  'payment:payment_failed': 'payment:payment_failed', // Posted when payment fails

  'dependent:patient_add_dependent_successful': 'dependent:patient_add_dependent_successful', // Posted when the patient successfully adds a dependent
  'dependent:patient_add_dependent_failed': 'dependent:patient_add_dependent_failed', // Posted when the patient fails to add a dependent

  'symptoms:patient_symptoms_added_successfully': 'symptoms:patient_symptoms_added_successfully', // Posted when the patient symptoms are added successfully
  'symptoms:patient_symptoms_added_failed': 'symptoms:patient_symptoms_added_failed', // Posted when the patient symptoms fails to add

  'health_wallet:add_funds_clicked': 'health_wallet:add_funds_clicked', // Posted when the Patient clicks the Add Funds button
  'health_wallet:add_funds_wallet_selected': 'health_wallet:add_funds_wallet_selected', // Posted when the Patient selects a wallet and clicks 'Continue' - "label" : "<Saving or Spending>"
  'health_wallet:add_funds_amount_entered': 'health_wallet:add_funds_amount_entered', // Posted when the Patient enters the amount and clicks 'Continue' - "label" : "<Value amount"
  'health_wallet:add_funds_recurring_payment': 'health_wallet:add_funds_recurring_payment', // Posted when the Patient enters the amount and clicks 'Continue' if they have turned on recurring payments
  'health_wallet:add_funds_pay_transaction_selected':
    'health_wallet:add_funds_pay_transaction_selected', // Posted when the Patient reviews the transaction and clicks the 'Pay' button - "label" : "<Currency Code"

  'health_wallet:send_funds_clicked': 'health_wallet:send_funds_clicked', // Posted when the Patient clicks the Send Funds button
  'health_wallet:send_funds_beneficiary__selected':
    'health_wallet:send_funds_beneficiary__selected', // Posted when the Patient selects a Beneficiary to Send Funds to

  'health_wallet:save_funds_clicked': 'health_wallet:save_funds_clicked', // Posted when the Patient clicks the Save Funds button

  'health_wallet:move_funds_clicked': 'health_wallet:move_funds_clicked', // Posted when the Patient clicks the Move Funds to Savings button "label" : "<Clicked wallet>"
  'health_wallet:move_funds_amount_entered': 'health_wallet:move_funds_amount_entered', // Posted when the Patient enters the amount and clicks 'Continue' - "label" : "<Value amount>"
  'health_wallet:move_funds_result_success': 'health_wallet:move_funds_result_success', // Posted when the transaction is successful "label" : "<Currency Code>"
  'health_wallet:move_funds_result_failed': 'health_wallet:move_funds_result_failed', // Posted when the transaction fails - "label" : "<Failure Reason>"

  'health_wallet:add_new_beneficiary_clicked': 'health_wallet:add_new_beneficiary_clicked', // Posted when the Patient clicks the Add New Beneficiary button
  'health_wallet:add_new_beneficiary_result_success':
    'health_wallet:add_new_beneficiary_result_success', // Posted when adding a new beneficiary is successful
  'health_wallet:add_new_beneficiary_result_failed':
    'health_wallet:add_new_beneficiary_result_failed', // Posted when adding a new beneficiary fails - "label" : "<Failure Reason>"

  'neo_clinical_cart:inventory_clicked': 'neo_clinical_cart:inventory_clicked', // Posted when the user clicks inventory
  'neo_clinical_cart:cart_clicked': 'neo_clinical_cart:cart_clicked', // Posted when the user clicks patient cart
  'neo_clinical_cart:custom_item_clicked': 'neo_clinical_cart:custom_item_clicked', // Posted when the user clicks add custom item
  'neo_clinical_cart:add_item_to_cart_clicked': 'neo_clinical_cart:add_item_to_cart_clicked', // Posted when the user clicks add item to cart "label" : "<Item name and Quantity>"
  'neo_clinical_cart:add_item_to_cart_successful': 'neo_clinical_cart:add_item_to_cart_successful', // Posted when add item to cart is successful
  'neo_clinical_cart:add_item_to_cart_failed': 'neo_clinical_cart:add_item_to_cart_failed', // Posted when add item to cart failed
  'neo_clinical_cart:remove_item_from_cart_clicked':
    'neo_clinical_cart:remove_item_from_cart_clicked', // Posted when the user clicks remove item from cart
  'neo_clinical_cart:remove_item_from_cart_successful':
    'neo_clinical_cart:remove_item_from_cart_successful', // Posted when remove item from cart is successful
  'neo_clinical_cart:remove_item_from_cart_failed':
    'neo_clinical_cart:remove_item_from_cart_failed', // Posted when remove item from cart failed
  'neo_clinical_cart:update_item_in_cart_clicked': 'neo_clinical_cart:update_item_in_cart_clicked', // Posted when the user clicks update item in cart "label" : "<Item name and Quantity>"
  'neo_clinical_cart:update_item_in_cart_successful':
    'neo_clinical_cart:update_item_in_cart_successful', // Posted when update item in cart is successful
  'neo_clinical_cart:update_item_in_cart_failed': 'neo_clinical_cart:update_item_in_cart_failed', // Posted when update item in cart failed
  'neo_clinical_cart:add_custom_item_clicked': 'neo_clinical_cart:add_custom_item_clicked', // Posted when the user clicks add custom item "label" : "<Item name and Quantity>"
  'neo_clinical_cart:add_custom_item_successful': 'neo_clinical_cart:add_custom_item_successful', // Posted when add custom item is successful
  'neo_clinical_cart:add_custom_item_failed': 'neo_clinical_cart:add_custom_item_failed', // Posted when add custom item failed
  'neo_clinical_cart:pay_clicked': 'neo_clinical_cart:pay_clicked', // Posted when the user clicks pay button
  'neo_clinical_cart:cancel_clicked': 'neo_clinical_cart:cancel_clicked', // Posted when the user clicks cancel button
  'neo_clinical_order:complete_clicked': 'neo_clinical_order:complete_clicked', // Posted when the user clicks confirm in lab order for neo clinical order

  // Web events
  'login:provider_login_as_delegate_success': 'login:provider_login_as_delegate_success', // Posted when a Provider logs in as a delegate
  'login:provider_login_as_delegate_failed': 'login:provider_login_as_delegate_failed', // Posted when a Provider logs in as a delegate but it fails

  'location:location_access_successful': 'location:location_access_successful', // Posted when access to location is successful
  'location:location_access_failed': 'location:location_access_failed', // Posted when access to location failed
};

export type TEventNames = keyof typeof eventNames;

export { eventNames };
