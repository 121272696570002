import moment from 'moment';
import { useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import EmptyListCard from '../../../components/EmptyListCard';
import { PdfViewModal } from '../../../components/PdfViewer/PdfViewModal';
import { TimeFormatEnum } from '../../../constants';
import {
  AuthClient,
  ConsultChatsClient,
  ConsultTagTypeEnum,
  IPHRConsult,
  PHRConsult,
  PHRDocument,
  PHRDocumentsClient,
} from '../../../generated';
import { useCountriesAll } from '../../../services/countries/useCountriesAll';
import { httpRequest, prepareForDownload } from '../../../utils';
import { ViewPhrDocument } from '../components/ViewPhrDocument';
import { ConsultDetailBody } from './ConsultDetailBody';
import ConsultDetails from './ConsultDetails';
import { ConsultItem } from './ConsultItem';
import { ViewStateEnum } from './Consults';

const renderDescription = (consult: PHRConsult) => {
  let description = ' ';
  if ((consult.phrConsultTags?.length ?? 0) > 0) {
    consult.phrConsultTags
      ?.filter((x) => x.consultTagTypeEnum === ConsultTagTypeEnum.Complaint)
      .forEach((x, index) => {
        if (index === consult.phrConsultTags!.length - 1) {
          description += `${x.tag?.description.trim()}`;
        } else {
          description += `${x.tag?.description.trim()}, `;
        }
      });
  }
  if (consult.complaint) {
    description += ` ${consult.complaint}`;
  }
  return description === ' ' ? 'Consult' : description;
};

interface IProps {
  phrConsults: PHRConsult[];
  inverse?: boolean;
  onEdit: (phrConsult: PHRConsult) => void;
  onView: (phrConsult: PHRConsult) => void;
  onDelete: (phrConsultID: string) => void;
  activeView: ViewStateEnum;
  currentConsult: IPHRConsult | null;
}

const ConsultsList = ({
  phrConsults,
  onEdit,
  onDelete,
  onView,
  currentConsult,
  activeView,
}: IProps) => {
  const authClient = new AuthClient();
  const consultChatsClient = new ConsultChatsClient(authClient);
  const { countries } = useCountriesAll();
  const [downloadingPhrDocumentIDs, setDownloadingPhrDocumentIDs] = useState<any>({});

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isImageOpen, setIsImageOpen] = useState<boolean>(false);
  const [downloadDoc, setDownloadDoc] = useState<PHRDocument>();

  const [transcriptOpen, setTranscriptOpen] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [activeTranscript, setActiveTransacript] = useState<Blob | undefined>(undefined);
  const [fileName, setFileName] = useState<string>('');

  const handleView = (document: PHRDocument) => {
    if (document.fileDetail?.contentType === 'application/pdf') {
      setDownloadDoc(document);
      setIsOpen(!isOpen);
    } else {
      setDownloadDoc(document);
      setIsImageOpen(!isImageOpen);
    }
  };

  const downloadChatTranscript = async (phrConsult: PHRConsult) => {
    try {
      setDownloadingPhrDocumentIDs((o) =>
        Object.assign({}, o, {
          [phrConsult.phrConsultID]: true,
        }),
      );
      setIsDownloading(true);
      let blob;
      if (phrConsult.consultID) {
        blob = await httpRequest(() =>
          consultChatsClient.downloadByConsultID(phrConsult.patientID, phrConsult.consultID!),
        );
      } else {
        blob = await httpRequest(() =>
          consultChatsClient.downloadByPHRConsultID(phrConsult.patientID, phrConsult.phrConsultID),
        );
      }
      setDownloadingPhrDocumentIDs((o) =>
        Object.assign({}, o, {
          [phrConsult.phrConsultID]: false,
        }),
      );
      const newBlob = new Blob([blob.data], { type: 'application/pdf' });
      if (newBlob) {
        setTranscriptOpen(true);
        setActiveTransacript(newBlob);
        setIsDownloading(false);
      }
      const filename = `ConsultDetails-${moment(phrConsult.createdDate).format(
        TimeFormatEnum.LONG_DATE,
      )}.pdf`;
      setFileName(filename);
      // prepareForDownload(filename, newBlob);
    } catch (err) {
      setIsDownloading(false);
      setFileName('');
      setDownloadingPhrDocumentIDs((o) =>
        Object.assign({}, o, {
          [phrConsult.phrConsultID]: false,
        }),
      );
    }
  };

  const download = async (patientID: string, phrDocumentID: string) => {
    try {
      const phrDocumentsClient = new PHRDocumentsClient(new AuthClient());
      const x = await httpRequest(() => phrDocumentsClient.download(patientID, phrDocumentID));
      const newBlob = new Blob([x.data]);
      prepareForDownload(x.fileName!, newBlob);
    } catch (err) {}
  };

  const displayCancelled = (isCancelled: boolean) => (isCancelled ? ` - Cancelled` : '');

  return (
    <>
      {activeView === ViewStateEnum.Home && (
        <>
          {phrConsults.length === 0 ? (
            <EmptyListCard>No Notes added</EmptyListCard>
          ) : (
            phrConsults.map((x) => (
              <ConsultItem
                key={x.phrConsultID}
                title={renderDescription(x)}
                isDownloading={downloadingPhrDocumentIDs[x.phrConsultID]}
                date={`${moment(x.consultDate).format(
                  TimeFormatEnum.LONG_DATE_TIME,
                )}${displayCancelled(!!x.isCancelled)}`}
                phrConsult={x}
                countries={countries}
                downloadChatTranscript={downloadChatTranscript}
                handleView={handleView}
                download={download}
                onEdit={onEdit}
                onView={onView}
                onDelete={onDelete}
              />
            ))
          )}
        </>
      )}

      {activeView === ViewStateEnum.Details && currentConsult && (
        <>
          <ConsultDetails
            phrItem={currentConsult}
            name={renderDescription(currentConsult as PHRConsult)}
          >
            <ConsultDetailBody
              isDownloading={downloadingPhrDocumentIDs[currentConsult.phrConsultID]}
              phrConsult={currentConsult as PHRConsult}
              countries={countries}
              downloadChatTranscript={downloadChatTranscript}
              handleView={handleView}
              download={download}
              onView={onView}
            />
          </ConsultDetails>
        </>
      )}

      {isOpen && downloadDoc && downloadDoc.fileDetail?.contentType === 'application/pdf' && (
        <ViewPhrDocument
          patientID={downloadDoc.patientID ?? ''}
          documentID={downloadDoc.phrDocumentID ?? ''}
          onClose={() => setIsOpen(!isOpen)}
          fileName={downloadDoc.fileDetail?.name ?? ''}
        />
      )}

      {isImageOpen && (
        <ImageViewer
          src={[
            `/api/v1/PHRs/PHRDocuments/${downloadDoc?.patientID}/DocumentAsSrc/${downloadDoc?.phrDocumentID}`,
          ]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          onClose={() => {
            setIsImageOpen(false);
            setDownloadDoc(undefined);
          }}
        />
      )}

      <PdfViewModal
        isOpen={transcriptOpen}
        isLoading={isDownloading}
        initialScale={1.5}
        pdf={activeTranscript}
        fileName={fileName}
        onClose={() => {
          setFileName('');
          setActiveTransacript(undefined);
          setTranscriptOpen(false);
        }}
      />
    </>
  );
};

export default ConsultsList;
