import React from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { Formik } from 'formik';
import { object, string, array } from 'yup';

import {
  FormInputControl,
  FormSelectComponent,
  RadioGroupControl,
  Form,
  FormTextArea,
} from '../../../../../components/forms';
import { GLOBAL_MAX_TEXT_LENGTH } from '../../../../../constants';
import { AllergySeverityEnum, PHRAllergy, Tag } from '../../../../../generated';

import AllergyRadioControl from '../AllergyRadioControl';
import PhrItemForm from '../../../components/PhrItemForm';
import { OutpostTheme } from '../../../../../themes/outpost';

export interface IAllergyForm {
  name: string;
  reactionTags?: Tag[];
  otherReactions?: string;
  severity?: AllergySeverityEnum;
  notes?: string;
}

interface IProps {
  selectedAllergy?: PHRAllergy;
  allergyReactionTags: Tag[];
  onSave: (values) => void;
  innerBtnRef: React.RefObject<HTMLButtonElement>;
}

const AllergyForm = ({ selectedAllergy, allergyReactionTags, onSave, innerBtnRef }: IProps) => {
  const selectedAllergyTagIDs = selectedAllergy?.phrAllergyTags?.map((tag) => tag.tagID);

  return (
    <PhrItemForm>
      <Formik
        initialValues={{
          name: selectedAllergy?.otherName || '',
          reactionTags:
            allergyReactionTags.filter((tag) => selectedAllergyTagIDs?.includes(tag.tagID)) || [],
          otherReactions: selectedAllergy?.otherReaction || '',
          severity: selectedAllergy?.allergySeverityEnum || AllergySeverityEnum.Moderate,
          notes: selectedAllergy?.notes || '',
        }}
        validationSchema={object({
          name: string()
            .required('A condition name is required')
            .max(100, 'Name must be at most 100 characters'),
          severity: string().required('A condition name is required'),
          reactionTags: array().min(1, 'Required. Select at least one reaction'),
          otherReactions: string().max(
            GLOBAL_MAX_TEXT_LENGTH,
            'Notes must be at most 10000 characters',
          ),
          notes: string().max(GLOBAL_MAX_TEXT_LENGTH, 'Notes must be at most 10000 characters'),
        })}
        onSubmit={onSave}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FormInputControl
              type="text"
              name="name"
              label="Name of Allergy"
              placeholder="Enter name of Allergy"
              height="48px"
              borderRadius="lg"
              formControlProps={{
                marginBottom: '2rem',
                formLabelProps: {
                  textTransform: 'uppercase',
                  color: '#01143173',
                  fontSize: '12px',
                  fontWeight: 'light',
                },
              }}
            />

            <Box marginBottom="2rem">
              <Text
                textTransform="uppercase"
                color="#01143173"
                fontSize="12px"
                fontWeight="light"
                marginBottom="1rem"
              >
                Severity
              </Text>
              <RadioGroupControl
                label="How severe is your allergy?"
                wrapProps={{ spacing: { base: 4, md: 8 } }}
                formControlProps={{ formLabelProps: { marginBottom: '1rem' } }}
                value={values.severity}
                name="severity"
                onChange={(value) => {
                  setFieldValue('severity', value);
                }}
              >
                <AllergyRadioControl value={AllergySeverityEnum.Mild} name="severity" />
                <AllergyRadioControl value={AllergySeverityEnum.Moderate} name="severity" />
                <AllergyRadioControl value={AllergySeverityEnum.Severe} name="severity" />
              </RadioGroupControl>
            </Box>

            <Box marginBottom="2rem" maxW="380px">
              <Text
                textTransform="uppercase"
                color="#01143173"
                fontSize="12px"
                fontWeight="light"
                marginBottom="1rem"
              >
                Reactions
              </Text>
              <FormSelectComponent
                label="What sort of Reactions do you have? (Pick one or more)"
                name="reactionTags"
                valueKey="tagID"
                labelKey="name"
                isMulti
                options={allergyReactionTags}
                isClearable={false}
                styles={{
                  control: (base) => ({
                    ...base,
                    height: 'fit-content',
                    minHeight: '48px',
                    borderRadius: '8px',
                    backgroundColor: '#F7F9FC',
                  }),
                }}
                components={{
                  Placeholder: () => (
                    <Text
                      fontSize={OutpostTheme.FontSizes.body}
                      color="#0516466E"
                      paddingLeft="0.5rem"
                    >
                      Select Reactions
                    </Text>
                  ),
                  IndicatorSeparator: null,
                }}
                formControlProps={{
                  formLabelProps: { fontWeight: 'normal', marginBottom: '1rem' },
                }}
              />
            </Box>

            <FormTextArea
              name="notes"
              label="Notes"
              borderRadius="lg"
              height="120px"
              resize="none"
              placeholder="Type a short note here"
              formControlProps={{
                marginTop: '1rem',
                formLabelProps: {
                  textTransform: 'uppercase',
                  color: '#01143173',
                  fontSize: '12px',
                  fontWeight: 'light',
                },
              }}
            />
            <Button display="none" ref={innerBtnRef} type="submit" />
          </Form>
        )}
      </Formik>
    </PhrItemForm>
  );
};

export default AllergyForm;
