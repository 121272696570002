import { Box, Flex, Heading, ModalBody, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton, SecondaryButton } from '../../../../components/Buttons';
import { CenteredLoadingIndicator } from '../../../../components/LoadingIndicator';
import { BaseModal } from '../../../../components/Modals';
import ServerValidationBox from '../../../../components/ServerValidationBox';
import { API_KEY } from '../../../../constants';
import {
  AuthClient,
  GenderEnum,
  Language,
  SecurityClient,
  SignUpPatient,
  UserSignInDetails,
} from '../../../../generated';
import useTranslationComponent from '../../../../hooks/useTranslationComponent';
import { mixTrack } from '../../../../lib/mixpanel';
// import { useCountriesAll } from '../../../../services/countries/useCountriesAll';
import { useLanguagesAll } from '../../../../services/languages/queries/useLanguagesAll';
import { useTimeZonesAll } from '../../../../services/timeZones/queries/useTimeZonesAll';
import { signIn } from '../../../../store/root-creator';
import { AppState } from '../../../../store/root-reducers';
import { httpRequest } from '../../../../utils';
import { languageFromBrowser } from '../../../../utils/languageFromBrowser';
import { selectNearestTimeZone } from '../../../../utils/selectNearestTimeZone';
import { Login } from './components/Login';
import { SecurityCode } from './components/SecurityCode';
import { SetPassword } from './components/SetPassword';
import { Signup } from './components/Signup';
import {
  CurrentFlowHeaderEnum,
  CurrentFlowSubHeaderEnum,
  SignupFlowEnum,
  SignupFlowSteps,
} from './enums';

interface IProps {
  isOpen: boolean;
  onClose?: () => void;
  onConfirmationOpen: () => void;
  onLogin: () => void;
}

export interface ISignupDetailsForm {
  email: string;
  firstName: string;
  lastName: string;
  smsNumber: string;
  referralCode: string;
  countryID: string;
  birthDate: string;
}

const SignupModal = ({ isOpen, onConfirmationOpen, onClose, onLogin }: IProps) => {
  const { t } = useTranslationComponent(['signup']);
  const [currentStage, setCurrentStage] = useState(SignupFlowEnum.Initiate);
  const [token, setToken] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [signupData, setSignupData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    smsNumber: '',
    password: '',
    confirmPassword: '',
    hasAgreedToTC: false,
    countryID: '',
    birthDate: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const dispatch = useDispatch();
  const currentPartner = useSelector((state: AppState) => state.currentPartnerState.data);

  const companyName = currentPartner?.name || process.env.REACT_APP_COMPANY_NAME;

  const authClient = new AuthClient();
  const securityClient = new SecurityClient(authClient);

  const handleGoBack = () => {
    const enumKeys = Object.keys(SignupFlowEnum);
    setCurrentStage(
      SignupFlowEnum[
        enumKeys.indexOf(currentStage) >= 0
          ? enumKeys[enumKeys.indexOf(currentStage) - 1] ?? enumKeys[0]
          : enumKeys[0]
      ],
    );
  };

  // const { isLoadingCountries, countries = [] } = useCountriesAll();
  const { timeZones = [] } = useTimeZonesAll();
  const { languages = [] } = useLanguagesAll();

  const getNearestTimezone = async () => {
    const nearestTimeZone = await selectNearestTimeZone(timeZones);
    return nearestTimeZone?.timeZoneID ?? timeZones[0].timeZoneID;
  };

  const handleSignUpPatient = async (emailToken: string) => {
    setIsLoading(true);
    const language = new Language();
    language.init(languageFromBrowser(languages, navigator.language, navigator.languages)!);
    const patient = new SignUpPatient();
    patient.init({ ...signupData, token: emailToken });
    patient.confirmEmail = patient.email;
    patient.genderEnum = GenderEnum.NotDisclosed;
    patient.timeZone = await getNearestTimezone();
    patient.language = language;
    patient.subscriptionUserID = undefined;
    patient.subscriptionPIN = undefined;
    patient.marketingCampaignReferralCode = referralCode;
    patient.birthDateString = signupData.birthDate;

    try {
      mixTrack({
        eventName: 'sign_up:patient_signed_up_successfully',
      });

      await httpRequest(() => securityClient.signUpPatient(patient, API_KEY));

      const details = new UserSignInDetails();
      details.emailOrUniqueLifetimeID = signupData.email;
      details.passwordOrPIN = signupData.password;

      mixTrack({
        eventName: 'sign_up:patient_sign_up_complete',
      });

      dispatch(signIn(details, onClose));
    } catch (err) {
      mixTrack({
        eventName: 'sign_up:patient_signed_up_failed',
      });
      setError(err as string);
      setIsLoading(false);
    }
  };

  const handleSignupDetails = (values: ISignupDetailsForm, resultToken?: string) => {
    setSignupData((prev) => ({
      ...prev,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      smsNumber: values.smsNumber,
      countryID: values.countryID,
      birthDate: values.birthDate,
    }));
    setReferralCode(values.referralCode);

    setCurrentStage(SignupFlowEnum.SetPassword);
    if (resultToken) {
      setToken(token);
    }
  };

  const handleSetPassword = (values: {
    password: string;
    confirmPassword: string;
    hasAgreedToTC: boolean;
  }) => {
    setSignupData((prev) => ({
      ...prev,
      password: values.password,
      confirmPassword: values.confirmPassword,
      hasAgreedToTC: values.hasAgreedToTC,
    }));

    setCurrentStage(SignupFlowEnum.SecurityCode);
  };

  const handleToken = (token: string) => {
    setToken(token);
    handleSignUpPatient(token);
  };

  useEffect(() => {
    if (currentStage !== SignupFlowEnum.Initiate) {
      setError('');
      setCurrentStage(SignupFlowEnum.Initiate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseModal
      size="xl"
      isOpen={isOpen}
      onDismiss={onConfirmationOpen}
      fullScreen={false}
      onBack={Object.keys(SignupFlowEnum).indexOf(currentStage) === 0 ? undefined : handleGoBack}
      modalContentProps={{ pt: { base: '10', md: '52px' }, pb: { base: '4', md: '6' } }}
    >
      <Box
        overflowY="auto"
        px={{ base: '4', sm: '6' }}
        display="flex"
        flexDirection="column"
        flexGrow={1}
      >
        {SignupFlowSteps.includes(currentStage) && (
          <Flex gap={9} flexBasis={1} mt={4} mb={6}>
            {SignupFlowSteps.map((step, i) => (
              <Box
                flexGrow={1}
                key={step}
                height="4px"
                borderRadius="lg"
                background={SignupFlowSteps.indexOf(currentStage) >= i ? 'purple.600' : '#9D9D9D'}
              />
            ))}
          </Flex>
        )}
        {currentStage !== SignupFlowEnum.Initiate && (
          <VStack mb={3}>
            <Heading size="sm" color="#000000" fontSize={{ base: '20px', md: '24px' }} w="100%">
              {CurrentFlowHeaderEnum[currentStage]}
            </Heading>
            {CurrentFlowSubHeaderEnum[currentStage] && (
              <Text
                fontSize={{ base: '12px', md: '16px' }}
                color="gray.300"
                mb={3}
                w="100%"
                textTransform="lowercase"
                dangerouslySetInnerHTML={{
                  __html: t(CurrentFlowSubHeaderEnum[currentStage], {
                    name: companyName ?? 'outpost health',
                  }),
                }}
              ></Text>
            )}
          </VStack>
        )}
        <ModalBody display="flex" flexDirection="column" p={0} pb={4}>
          {isLoading ? (
            <CenteredLoadingIndicator />
          ) : (
            <>
              <ServerValidationBox message={error} />
              {currentStage === SignupFlowEnum.Initiate && (
                <VStack gap={2} py={3} flexGrow={1} justifyContent="center">
                  <Heading
                    textAlign="center"
                    mt={2}
                    mb={6}
                    size="sm"
                    color="#000000"
                    fontSize={{ base: '18px', md: '20px' }}
                  >
                    {t(CurrentFlowHeaderEnum[currentStage])}
                  </Heading>
                  <PrimaryButton w="100%" onClick={() => setCurrentStage(SignupFlowEnum.Signup)}>
                    sign up
                  </PrimaryButton>
                  <SecondaryButton w="100%" onClick={() => setCurrentStage(SignupFlowEnum.Login)}>
                    sign in
                  </SecondaryButton>
                </VStack>
              )}

              {currentStage === SignupFlowEnum.Signup && (
                <Signup
                  controller={{ setCurrentStage, handleNext: handleSignupDetails }}
                  signupData={signupData}
                  referralCode={referralCode}
                />
              )}

              {currentStage === SignupFlowEnum.Login && (
                <Login controller={{ setCurrentStage, onProceed: onLogin }} />
              )}

              {signupData.email && currentStage === SignupFlowEnum.SetPassword && (
                <SetPassword controller={{ setCurrentStage, handleNext: handleSetPassword }} />
              )}

              {signupData.password && currentStage === SignupFlowEnum.SecurityCode && (
                <SecurityCode
                  controller={{ setCurrentStage, handleNext: handleToken }}
                  token={token}
                  email={signupData.email}
                  smsNumber={signupData.smsNumber}
                />
              )}
            </>
          )}
        </ModalBody>
      </Box>
    </BaseModal>
  );
};

export default SignupModal;
