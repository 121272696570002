import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import {
  AddressBook,
  AddressBooksClient,
  AddressBookTypeEnum,
  AuthClient,
} from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

export const useAddressBooksDelete = () => {
  const queryClient = useQueryClient();
  const [error, setError] = useState<string>('');
  const addressBooksDelete = useMutation(
    async (addressBook: AddressBook) =>
      addressBook.addressBookTypeEnum === AddressBookTypeEnum.Private &&
      (await httpRequest(() =>
        new AddressBooksClient(new AuthClient()).addressBooksDelete(addressBook.addressBookID),
      )),
    {
      onSuccess: () => {
        setError('');
        queryClient.invalidateQueries(QueryKeys.AddressBooksSearch);
        queryClient.invalidateQueries(QueryKeys.AddressBooksPrivate);
        queryClient.invalidateQueries(QueryKeys.AddressBooksCollection);
      },
      onError: (err: string) => setError(err as any),
    },
  );

  return { error, addressBooksDelete };
};
