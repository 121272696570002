import React from 'react';
import moment from 'moment';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { CalendarIcon2 } from '../../../assets/icons';
import { PHRHealthJournal } from '../../../generated';
import { TimeFormatEnum } from '../../../constants';
import { verifyForPhrSection } from '../../../store/patient/phr/phrCreators';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { successToastr } from '../../../utils/toastr';

import PhrItemView from '../components/PhrItemView';
import { OutpostTheme } from '../../../themes/outpost';
import { JournalImages } from '../../monitor/HealthJournal/components/JournalList/JournalImages';

interface IHealthJournalViewProps {
  phrHealthJournal: PHRHealthJournal;
}

const HealthJournalView = ({ phrHealthJournal }: IHealthJournalViewProps) => {
  const dispatch = useDispatch();

  const onVerify = async (id: string, patientID: string) => {
    dispatch(verifyForPhrSection(ReduxPHRCategoryEnum.PHRHealthJournals, id, patientID));
    successToastr({ description: 'Successfully verified Health Journal' });
  };

  return (
    <PhrItemView
      phrItem={phrHealthJournal}
      onVerify={() => onVerify(phrHealthJournal.phrHealthJournalID, phrHealthJournal.patientID)}
      name={phrHealthJournal.title}
      isVerified
    >
      <Flex justify="space-between" align="center">
        <Box>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Journal Date
          </Text>
          <Text
            fontSize={OutpostTheme.FontSizes.body}
            marginTop="0.5rem"
            display="flex"
            alignItems="center"
          >
            <CalendarIcon2 fontSize="18px" color="#78819D" />
            &nbsp;
            {moment(phrHealthJournal.journalDate).format(TimeFormatEnum.LONG_DATE_TIME)}
          </Text>
        </Box>
      </Flex>

      <Box marginTop="1.5rem">
        <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
          Notes
        </Text>
        <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
          {phrHealthJournal.notes ? phrHealthJournal.notes : 'No notes have been added yet'}
        </Text>
      </Box>

      <Box marginTop="1.5rem">
        <JournalImages
          phrDocumentIDs={(phrHealthJournal.phrDocuments ?? [])?.map((x) => x.phrDocumentID)}
          patientID={phrHealthJournal.patientID}
        />
      </Box>
    </PhrItemView>
  );
};

export default HealthJournalView;
