import React from 'react';
import styled from 'styled-components';
import { IStore } from '../../../../generated';
import { Paragraph } from '../../../common/Paragraph';
import { StyledOption } from '../Select';

export const StoreOption = (store: IStore, isSelected: boolean, isActive: boolean) => (
  <StyledOption selected={isSelected} active={isActive}>
    <div>
      <StoreName>{store.name}</StoreName>
      <Info>
        {store.addressLine1 && <AddressLine>{store.addressLine1}</AddressLine>}
        {store.addressLine2 && <AddressLine>{store.addressLine2}</AddressLine>}
        {(store.city || store.province || store.postCode) && (
          <AddressLine>{[store.city, store.province, store.postCode].join(' ')}</AddressLine>
        )}
        {store.country && <AddressLine>{store.country.name}</AddressLine>}
      </Info>
    </div>
  </StyledOption>
);

const StoreName = styled(Paragraph)`
  margin: 0;
`;

const AddressLine = styled(Paragraph)`
  margin: 0;
  line-height: 120%;
  font-size: 13px;
  color: ${(props) => props.theme.FontColors.Light};
`;

const Info = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
`;
