import { HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { RatingStar } from '../../../../../../../assets/icons';

type RatingCardProps = {
  rating: number | string;
};

const RatingCard: FC<RatingCardProps> = (props) => (
  <HStack
    position="absolute"
    bottom="-5px"
    left="50%"
    transform="translateX(-50%)"
    border="1px solid rgb(211, 211, 211)"
    borderRadius="100px"
    bgColor="white"
    px="1"
    width="45px"
    justifyContent="center"
    spacing="1"
  >
    <RatingStar fontSize="12px" />
    <Text fontWeight="medium" fontSize="12px">
      {props.rating}
    </Text>
  </HStack>
);

export { RatingCard };
