import { Field } from 'formik';
import React from 'react';
import { FormInputControlProps, InputControl } from './InputControl';

export const FormPasswordControl = (props: FormInputControlProps) => {
  const { name, formControlProps, ...rest } = props;

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <InputControl
          type="password"
          {...rest}
          formControlProps={{
            ...meta,
            ...formControlProps,
          }}
          {...field}
        />
      )}
    </Field>
  );
};
