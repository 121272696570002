import React from 'react';
import { Checkbox, CheckboxProps, Circle, Box, useCheckbox } from '@chakra-ui/react';
import { FastField } from 'formik';

export type CheckboxButtonControlProps = Overwrite<CheckboxProps, { value: string | number }> & {
  name: string;
};

export const CheckboxButtonControl = (props: CheckboxButtonControlProps) => {
  return (
    <Checkbox
      backgroundColor="#f1f1f1"
      borderRadius="0.25rem"
      p={2}
      color="#666666"
      sx={{ '.chakra-checkbox__control': { borderColor: '#666666' } }}
      {...props}
    />
  );
};

export const CheckboxButtonControlAlt = (props: CheckboxButtonControlProps) => {
  const { getInputProps, getCheckboxProps } = useCheckbox(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Circle
        {...checkbox}
        p={2}
        size="50px"
        border="1px solid #000"
        color="#000"
        bg="#FFFFFF"
        mt={1}
        cursor="pointer"
        _checked={{
          bg: '#006250',
          color: 'white',
          border: '1px solid #006250',
        }}
      >
        {props.children}
      </Circle>
    </Box>
  );
};

export type FormCheckboxButtonControlProps = CheckboxButtonControlProps & { alt?: boolean };

const isChecked = (formValue: any, fieldValue: any) => {
  let checked;
  if (formValue instanceof Array) {
    checked = formValue.includes(fieldValue) ?? false;
  }
  return checked;
};

export const FormCheckboxButtonControl = (props: FormCheckboxButtonControlProps) => {
  const { name, children, alt, ...rest } = props;
  const Component = alt ? CheckboxButtonControlAlt : CheckboxButtonControl;
  return (
    <FastField name={name}>
      {({ field, meta }) => (
        <Component
          {...field}
          isInvalid={!!meta.error && meta.touched}
          isChecked={isChecked(field.value, props.value)}
          name={name}
          {...rest}
        >
          {children}
        </Component>
      )}
    </FastField>
  );
};
