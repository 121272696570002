import { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';
// import { getAppFeatures } from '../../../../store/appFeatures/appFeaturesCreators';
import { ICurrentUser } from '../../../../store/currentUser/currentUserReducers';
import { scrollToTop } from '../../../../utils/scrollToTop';
import useGetAuthenticatedPatient from '../useGetAuthenticatedPatient/useGetAuthenticatedPatient';
import useGetAuthenticatedProvider from '../useGetAuthenticatedProvider/useGetAuthenticatedProvider';

interface IProps {
  currentUser: ICurrentUser | null;
  pathname: string;
}

const useGetAuthenticatedLayout = ({ currentUser, pathname }: IProps) => {
  // const dispatch = useDispatch();
  // const [loadedAppFeatures, setLoadedAppFeatures] = useState<boolean>(false);

  // useEffect(() => {
  //   if (currentUser && !loadedAppFeatures) {
  //     dispatch(getAppFeatures());
  //     setLoadedAppFeatures(true);
  //   }
  // }, [currentUser, dispatch, loadedAppFeatures]);

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  useGoogleAnalytics({ currentUser });
  useGetAuthenticatedProvider({ currentUser });
  useGetAuthenticatedPatient({ currentUser });
};

export default useGetAuthenticatedLayout;
