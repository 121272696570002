import { AddressBook } from '../../../generated';
import { EMPTY_GUID } from '../../../constants';

export const getAddressBookID = (addressBook: AddressBook | null) => {
  if (!addressBook) {
    return '';
  } else if (!addressBook.addressBookID) {
    return addressBook.associatedID;
  } else if (addressBook.addressBookID === EMPTY_GUID) {
    return addressBook.associatedID;
  } else {
    return addressBook.addressBookID;
  }
};
