import { TabPanel, TabPanelProps } from '@chakra-ui/react';

type IProps = TabPanelProps & {
  isInConsult: boolean;
};

export const SectionPanel = ({ isInConsult, children, ...props }: IProps) => {
  return (
    <TabPanel
      padding={!isInConsult ? 0 : 1}
      height="100%"
      borderTop={!isInConsult ? 'none' : { md: '1px solid #EDEFF1', xl: 'none' }}
      borderLeft={!isInConsult ? 'none' : { md: '1px solid #EDEFF1', xl: 'none' }}
      borderRight={!isInConsult ? 'none' : { md: '1px solid #EDEFF1', xl: 'none' }}
      borderTopRadius="0.5rem"
      backgroundColor={!isInConsult ? 'transparent' : { md: 'white', xl: 'transparent' }}
      {...props}
    >
      {children}
    </TabPanel>
  );
};
