import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { components } from 'react-select';
import { date, object, string } from 'yup';

import { MagnifyIcon } from '../../../../../assets/icons';
import {
  AuthClient,
  Medication,
  MedicationsClient,
  PHRMedication,
  PrescriptionStatusEnum,
} from '../../../../../generated';
import { createForPhrSection, updateForPhrSection } from '../../../../../store/root-creator';
import { AppState } from '../../../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../../../store/types';
import { httpRequest } from '../../../../../utils';
import { scrollToTop } from '../../../../../utils/scrollToTop';
import { errorToastr } from '../../../../../utils/toastr';

import {
  AutoComplete,
  Form,
  FormInputControl,
  FormMobisPicker,
  FormTextArea,
} from '../../../../../components/forms';
import { CenteredLoadingIndicator } from '../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../components/ServerValidationBox';
import { GLOBAL_MAX_TEXT_LENGTH } from '../../../../../constants';
import { OutpostTheme } from '../../../../../themes/outpost';
import PhrItemForm from '../../../components/PhrItemForm';

const Control = ({ children, ...rest }) => (
  // @ts-ignore
  <components.Control {...rest}>
    {children} <MagnifyIcon marginRight={3} fontSize="20px" color="#78819D" />
  </components.Control>
);

interface IForm {
  name: string;
  dosage?: string;
  form?: string;
  quantity?: string;
  frequency?: string;
  methodOfIntake?: string;
  startDate: Date;
  endDate?: Date | string;
  notes?: string;
}

interface IProps {
  selectedMedication: PHRMedication | null;
  patientID: string;
  toggleView: () => void;
  innerBtnRef: React.RefObject<HTMLButtonElement>;
}

export const MedicationsForm = ({
  selectedMedication,
  patientID,
  toggleView,
  innerBtnRef,
}: IProps) => {
  const dispatch = useDispatch();
  const [medicationsList, setMedicationsList] = useState<Medication[]>([]);
  const countryID = useSelector(
    (state: AppState) =>
      state.providerState.provider?.countryID || state.patientState.patient?.countryID,
  );
  const { error, isPhrLoading } = useSelector((state: AppState) => state.phrPatientState);

  const [customCondition, setCustomCondition] = useState('');
  const [menuState, setMenuState] = useState<{ toggle: ((isOpen: boolean) => void | null) | null }>(
    {
      toggle: null,
    },
  );

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    const loadData = async (countryID: string) => {
      try {
        const medicationsClient = new MedicationsClient(new AuthClient());
        const result = await httpRequest(() => medicationsClient.byCountryID(countryID));
        setMedicationsList(result);
      } catch (err) {
        errorToastr({ description: 'An error occured while fetching medications' });
      }
    };
    loadData(countryID!);
  }, [countryID]);

  const submit = async (values: IForm) => {
    const medication = new PHRMedication();
    medication.init(selectedMedication);
    medication.patientID = patientID;
    medication.name = values.name;
    medication.dosage = values.dosage;
    medication.quantity = values.quantity;
    medication.form = values.form ? values.form : undefined;
    medication.methodOfIntake = values.methodOfIntake;
    medication.frequency = values.frequency;
    medication.startDate = new Date(values.startDate);
    medication.endDate = values.endDate ? new Date(values.endDate) : undefined;
    medication.notes = values.notes;

    // Needed
    medication.isHeadMedication = true;
    medication.prescriptionStatusEnum = PrescriptionStatusEnum.Completed;

    if (selectedMedication) {
      await dispatch(
        updateForPhrSection(
          ReduxPHRCategoryEnum.PHRMedications,
          medication,
          medication.patientID,
          false,
        ),
      );

      toggleView();
    } else {
      await dispatch(
        createForPhrSection(
          ReduxPHRCategoryEnum.PHRMedications,
          medication,
          medication.patientID,
          false,
        ),
      );

      toggleView();
    }
  };

  const initialValues: IForm = {
    name: selectedMedication ? selectedMedication.name : '',
    dosage: selectedMedication ? selectedMedication.dosage : '',
    quantity: selectedMedication ? selectedMedication.quantity : '',
    form: selectedMedication ? selectedMedication.form : '',
    methodOfIntake: selectedMedication ? selectedMedication.methodOfIntake : '',
    frequency: selectedMedication ? selectedMedication.frequency : '',
    startDate: selectedMedication ? selectedMedication.startDate : new Date(),
    endDate: selectedMedication ? selectedMedication.endDate : undefined,
    notes: selectedMedication ? selectedMedication.notes : '',
  };
  return (
    <PhrItemForm>
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          name: string()
            .required('A name is required')
            .max(100, 'Name must be at most 100 characters'),
          dosage: string().max(100, 'Dosage must be at most 100 characters'),
          form: string().nullable().max(100, 'Form must be at most 100 characters'),
          quantity: string().nullable().max(100, 'Quantity must be at most 100 characters'),
          frequency: string().nullable().max(100, 'Frequency must be at most 100 characters'),
          methodOfIntake: string()
            .nullable()
            .max(100, 'Intake Method must be at most 100 characters'),
          startDate: date().required('A start date is required').typeError('Invalid date'),
          endDate: date()
            .typeError('Invalid date')
            .test('beforeDate', 'End Date must be after the Start Date', function (value) {
              const startDate = moment(this.parent.startDate);
              return moment(value).isAfter(startDate);
            }),
          notes: string()
            .nullable()
            .max(
              GLOBAL_MAX_TEXT_LENGTH,
              `Notes must be at most ${GLOBAL_MAX_TEXT_LENGTH} characters`,
            ),
        })}
        onSubmit={(values) => submit(values)}
      >
        {({ status, setFieldValue, values, errors }) => (
          <Form error={error}>
            <>
              <ServerValidationBox message={status} />
              {isPhrLoading ? (
                <CenteredLoadingIndicator />
              ) : (
                <>
                  <HStack
                    marginBottom="2rem"
                    spacing={{ base: 0, md: 4 }}
                    flexDir={{ base: 'column', md: 'row' }}
                    align="flex-start"
                  >
                    <Box marginBottom="1rem" width={'100%'}>
                      <AutoComplete
                        name="name"
                        label="Name of Medicine"
                        placeholder="Enter name of Medicine"
                        isClearable={false}
                        isMulti={false}
                        options={medicationsList}
                        value={[{ name: values.name }]}
                        valueKey="medicationID"
                        labelKey="name"
                        onChange={(value) => setFieldValue('name', value.name)}
                        components={{ Control }}
                        onInputChangeCallback={(value, menuToggle) => {
                          if (!menuState.toggle) {
                            setMenuState({ toggle: menuToggle });
                          }
                          setCustomCondition(value);
                        }}
                        noOptionsMessage={() => 'No medications found'}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: '48px',
                            backgroundColor: '#F7F9FC',
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 99,
                          }),
                        }}
                        onAddNewCallback={() => {
                          setFieldValue('name', customCondition);
                          if (menuState.toggle) {
                            menuState.toggle(false);
                          }
                        }}
                        addNewText="+ Add As A New Medication"
                        formControlProps={{
                          marginBottom: 0,
                          formLabelProps: {
                            textTransform: 'uppercase',
                            color: '#01143173',
                            fontSize: '12px',
                            fontWeight: 'light',
                          },
                        }}
                      />
                      {errors.name && (
                        <Text
                          fontSize={OutpostTheme.FontSizes.body}
                          color="#E53E3E"
                          marginTop="0.5rem"
                        >
                          {errors.name}
                        </Text>
                      )}
                    </Box>

                    <FormInputControl
                      type="text"
                      name="dosage"
                      label="Dosage"
                      placeholder="Enter Dosage"
                      height="48px"
                      borderRadius="lg"
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </HStack>

                  <HStack
                    marginBottom="2rem"
                    spacing={{ base: 0, md: 4 }}
                    flexDir={{ base: 'column', md: 'row' }}
                    align="flex-start"
                  >
                    <FormInputControl
                      type="text"
                      name="form"
                      label="Form"
                      placeholder="Enter form"
                      height="48px"
                      borderRadius="lg"
                      formControlProps={{
                        marginBottom: { base: '2rem', md: 0 },
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                    <FormInputControl
                      type="text"
                      name="frequency"
                      label="How often do you take it?"
                      placeholder="Enter Frequency"
                      height="48px"
                      borderRadius="lg"
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </HStack>

                  <HStack
                    marginBottom="2rem"
                    spacing={{ base: 0, md: 4 }}
                    flexDir={{ base: 'column', md: 'row' }}
                    align="flex-start"
                  >
                    <FormInputControl
                      type="text"
                      name="quantity"
                      label="Quantity"
                      placeholder="Enter Quantity"
                      height="48px"
                      borderRadius="lg"
                      formControlProps={{
                        marginBottom: { base: '2rem', md: 0 },
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                    <FormInputControl
                      type="text"
                      name="methodOfIntake"
                      label="Intake Method"
                      placeholder="Enter Intake Method"
                      height="48px"
                      borderRadius="lg"
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </HStack>

                  <HStack
                    marginBottom="2rem"
                    spacing={{ base: 0, md: 4 }}
                    flexDir={{ base: 'column', md: 'row' }}
                    align="flex-start"
                  >
                    <FormMobisPicker
                      name="startDate"
                      label="Start Date"
                      max={moment().add(1, 'year').toDate()}
                      min={moment().add(-150, 'year').toDate()}
                      placeholder="DD / MM / YY"
                      containerProps={{
                        height: '48px',
                        borderRadius: 'lg',
                        // inputElementProps: {
                        //   height: '48px',
                        // },
                      }}
                      formControlProps={{
                        marginBottom: { base: '2rem', md: 0 },
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                    {/* <FormDateTimePicker
                      name="startDate"
                      label="Start Date"
                      maxDate={moment().add(1, 'year').toDate()}
                      minDate={moment().add(-150, 'year').toDate()}
                      placeholderText="DD / MM / YY"
                      customInputProps={{
                        height: '48px',
                        borderRadius: 'lg',
                        inputElementProps: {
                          height: '48px',
                        },
                      }}
                      formControlProps={{
                        marginBottom: { base: '2rem', md: 0 },
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    /> */}

                    <FormMobisPicker
                      name="endDate"
                      label="End Date"
                      placeholder="DD / MM / YY"
                      max={moment().add(1, 'year').toDate()}
                      min={moment().add(-150, 'year').toDate()}
                      borderRadius="lg"
                      containerProps={{
                        height: '48px',
                        // inputElementProps: {
                        //   height: '48px',
                        // },
                      }}
                      formControlProps={{
                        marginBottom: 0,
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                    {/* <FormDateTimePicker
                      name="endDate"
                      label="End Date"
                      placeholderText="DD / MM / YY"
                      maxDate={moment().add(1, 'year').toDate()}
                      minDate={moment().add(-150, 'year').toDate()}
                      customInputProps={{
                        height: '48px',
                        borderRadius: 'lg',
                        inputElementProps: {
                          height: '48px',
                        },
                      }}
                      formControlProps={{
                        marginBottom: 0,
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    /> */}
                  </HStack>

                  <FormTextArea
                    name="notes"
                    label="Reason / Notes"
                    borderRadius="lg"
                    height="120px"
                    resize="none"
                    placeholder="Type a short note here"
                    formControlProps={{
                      marginTop: '1rem',
                      formLabelProps: {
                        textTransform: 'uppercase',
                        color: '#01143173',
                        fontSize: '12px',
                        fontWeight: 'light',
                      },
                    }}
                  />

                  <Button display="none" ref={innerBtnRef} type="submit" />
                </>
              )}
            </>
          </Form>
        )}
      </Formik>
    </PhrItemForm>
  );
};
