import { library } from '@fortawesome/fontawesome-svg-core';
import { faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';
import {
  faAnalytics,
  faBaby,
  faBookOpen as faBookOpenLight,
  faBookUser,
  faBrain,
  faCalendarAlt as faCalendarAltLight,
  faCalendarWeek as faCalendarWeekLight,
  faChartNetwork,
  faChild,
  faClinicMedical as falClinicMedical,
  faCog,
  faComment,
  faCommentAltSmile,
  faCreditCardBlank as faCreditCardBlankLight,
  faCreditCardFront,
  faDna,
  faDumbbell,
  faEnvelope as faEnvelopeLight,
  faFemale,
  faGripHorizontal,
  faHistory,
  faHospital as faHospitalLight,
  faMale,
  faNetworkWired,
  faNewspaper,
  faNotesMedical as faNotesMedicalLight,
  faPoll,
  faRibbon,
  faSparkles,
  faStream,
  faTimesCircle,
  faUserChart,
  faUserCircle as falUserCircle,
  faUserEdit,
  faUserMd as faUserMdLight,
  faUserMdChat,
  faUsersMedical as faUsersMedicalLight,
  faWallet as falWallet,
  faSearch as falSearch,
  faUsers as falUsers,
  faStar as falStar,
  faChartBar,
  faExclamationTriangle as falExclamationTriangle,
  faFileInvoiceDollar,
  faBookMedical as falBookMedical,
  faFlask,
  faPhoneRotary as falPhoneRotary,
  faReceipt as falFaReceipt,
  faShoppingCart as falShoppingCart,
  faHospitalAlt as faHospitalAltLight,
  faHandHeart,
  faUserShield,
  faBookMedical as faBookMedicalLight,
  faHeartbeat as faHeartbeatLight,
  faWalking as falWalking,
  faVideo as falVideo,
  faMapMarkerAlt,
  faPlusCircle,
  faFileAlt as falFileAlt,
  faMobileAlt as falMobileAlt,
  faFax as falFax,
  faHeadSideMedical as falHeadSideMedical,
  faMonitorHeartRate as falMonitorHeartRate,
  faAddressBook as falAddressBook,
  faFileInvoice as falFileInvoice,
  faGlobe as falGlobe,
} from '@fortawesome/pro-light-svg-icons';
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faArrowToLeft,
  faBadge,
  faBars,
  faBirthdayCake,
  faBookMedical,
  faBookOpen,
  faBriefcaseMedical,
  faCalendarAlt,
  faCalendarCheck,
  faCameraAlt,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faClinicMedical,
  faClipboardList,
  faClipboardUser,
  faClock,
  faCloudDownload,
  faCloudUpload,
  faCommentAlt,
  faCommentAltLines,
  faCreditCardBlank,
  faDoNotEnter,
  faDownload,
  faEdit,
  faEnvelope,
  faEnvelopeOpenText,
  faEnvelopeSquare,
  faExclamationSquare,
  faExclamationTriangle,
  faFileAlt,
  faGenderless,
  faHomeAlt,
  faHospital,
  faImage,
  faInboxIn,
  faInboxOut,
  faInfoCircle,
  faListAlt,
  faMars,
  faMicrophoneAlt,
  faMicrophoneAltSlash,
  faMinus,
  faMoneyBill,
  faNotesMedical,
  faPaperclip,
  faPaperPlane,
  faPennant,
  faPhone,
  faPhoneVolume,
  faPlus,
  faQuestion,
  faSearch,
  faSignOut,
  faStethoscope,
  faSync,
  faTimes,
  faTrashAlt,
  faUmbrellaBeach,
  faUser,
  faUserAlt,
  faUserCircle as farUserCircle,
  faUserHeadset,
  faUserInjured,
  faUserMd,
  faUserNurse,
  faUsers,
  faUsersClass,
  faUsersMedical,
  faVenus,
  faVideo,
  faWallet,
  faWebcam,
  faWebcamSlash,
  faVolume,
  faVolumeMute,
  faPrescription,
  faStickyNote,
  faAddressBook,
  faFolder,
  faPhoneRotary,
  faQuestionCircle,
  faChevronSquareDown,
  faChevronSquareUp,
  faShoppingCart,
  faCopy,
  faMicrophone,
  faComments,
  faWalking,
  faHospitalUser,
  faPrint,
  faTrophyAlt,
  faTimes as farTimes,
  faPlus as farPlus,
  faVial,
  faFilePrescription,
  faLongArrowRight,
  faHeart,
  faLocation,
  faHospitalAlt as faHospitalALtRegular,
  faSparkles as farSparkles,
  faHistory as farHistory,
  faFileCertificate,
  faEye,
  faShareSquare,
  faSortAmountDown,
  faQrcode,
  faCode,
  faTasks,
  faSearchPlus,
  faSearchMinus,
  faChevronDoubleRight,
  faSave,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faAlignLeft,
  faArrowLeft as faArrowLeftSolid,
  faArrowRight as faArrowRightSolid,
  faBadgeCheck,
  faCapsules,
  faCaretDown,
  faCommentAlt as solidCommentAlt,
  faExclamationTriangle as fasExclamationTriangle,
  faNotesMedical as faNotesMedicalSolid,
  faShieldCheck,
  faSignOut as faSignOutSolid,
  faUser as faUserSolid,
  faVideo as faVideoSolid,
  faVideoSlash as faVideoSlashSolid,
  faPhone as faPhoneSolid,
  faMicrophone as faMicrophoneSolid,
  faMicrophoneSlash as faMicrophoneSlashSolid,
  faHandsHeart,
  faPlus as faPlusSolid,
  faStar,
  faPowerOff,
  faCheck as faCheckSolid,
  faMobileAlt,
  faTimes as fasTimes,
  faCog as faCogSolid,
  faExpand,
  faCompress,
  faShare,
  faComment as fasComment,
  faCommentSlash as fasCommentSlash,
  faComments as fasComments,
  faUserMd as fasUserMd,
  faHospitalUser as fasHospitalUser,
  faWalking as fasWalking,
  faExclamationCircle,
  faShield,
  faHeart as fasHeart,
  faHeadSideMedical as fasHeadSideMedical,
  faTree as fasTree,
  faSignalStream as fasSignalStream,
  faCalendarAlt as fasCalendarAlt,
  faFax as fasFax,
  faUserFriends as fasUserFriends,
  faUndoAlt,
  faSyncAlt,
  faCircle as fasCircle,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faSignalAlt1,
  faSignalAlt2,
  faSignalAlt3,
  faSignalAlt,
  faSignalSlash,
} from '@fortawesome/pro-duotone-svg-icons';

library.add(
  // @ts-ignore
  faCcVisa,
  faCcMastercard,
  faTimes,
  faArrowToLeft,
  faArrowRight,
  faDownload,
  faArrowRight,
  faArrowLeft,
  faExclamationTriangle,
  faMinus,
  faPlus,
  faImage,
  faFileAlt,
  faTrashAlt,
  faCloudUpload,
  faPaperPlane,
  faCircle,
  farUserCircle,
  falUserCircle,
  faCommentAlt,
  faUserAlt,
  faUserNurse,
  faMicrophoneAlt,
  faMicrophoneAltSlash,
  faWebcam,
  faWebcamSlash,
  faPhone,
  faCameraAlt,
  faGenderless,
  faMars,
  faVenus,
  faCalendarCheck,
  faCommentAltLines,
  faNotesMedical,
  faBriefcaseMedical,
  faChevronDown,
  faChevronUp,
  faEdit,
  faBirthdayCake,
  faChevronLeft,
  faCalendarAlt,
  faListAlt,
  faHomeAlt,
  faClinicMedical,
  faHospital,
  faUsersClass,
  faUsers,
  faCaretDown,
  faAlignLeft,
  faPaperclip,
  faClipboardList,
  faChevronRight,
  faClipboardUser,
  faUser,
  faSignOut,
  faEnvelopeOpenText,
  faBadge,
  faInboxIn,
  faInboxOut,
  faSync,
  faEnvelope,
  faBookMedical,
  faAngleLeft,
  faAngleRight,
  faStethoscope,
  faUserInjured,
  faCloudDownload,
  faBadgeCheck,
  faCreditCardBlank,
  faEnvelopeSquare,
  faCapsules,
  faShieldCheck,
  fasExclamationTriangle,
  faUsersMedical,
  faFemale,
  faNetworkWired,
  faRibbon,
  faDumbbell,
  faBrain,
  faBaby,
  faSparkles,
  falClinicMedical,
  faUmbrellaBeach,
  faCreditCardFront,
  faWallet,
  falWallet,
  faMale,
  faUserMdChat,
  faInfoCircle,
  faDoNotEnter,
  faTimesCircle,
  faExclamationSquare,
  faPennant,
  faMoneyBill,
  faBookOpen,
  faSearch,
  faUserHeadset,
  faPhoneVolume,
  faQuestion,
  faCommentAltSmile,
  faUserSolid,
  solidCommentAlt,
  faUserMd,
  faSignOutSolid,
  faArrowLeftSolid,
  faArrowRightSolid,
  faHistory,
  faChild,
  faCog,
  faGripHorizontal,
  faUserEdit,
  faDna,
  faNotesMedicalLight,
  faUserMdLight,
  faAnalytics,
  faStream,
  faBookUser,
  faComment,
  faNewspaper,
  faUserChart,
  faPoll,
  faBookOpenLight,
  faBars,
  faEnvelopeLight,
  faCreditCardBlankLight,
  faChartNetwork,
  faClock,
  faVideo,
  faUsersMedicalLight,
  faCalendarWeekLight,
  faCalendarAltLight,
  faCheck,
  faNotesMedicalSolid,
  faHospitalLight,
  faVideoSolid,
  faVideoSlashSolid,
  faPhoneSolid,
  faMicrophoneSlashSolid,
  faMicrophoneSolid,
  faHandsHeart,
  faPlusSolid,
  faVolume,
  faVolumeMute,
  faPrescription,
  faStickyNote,
  falSearch,
  falUsers,
  falStar,
  faStar,
  falExclamationTriangle,
  faTasks,
  faChartBar,
  faFileInvoiceDollar,
  falBookMedical,
  faFlask,
  faPowerOff,
  faAddressBook,
  faFolder,
  faSignalAlt1,
  faSignalAlt2,
  faSignalAlt3,
  faSignalAlt,
  faCheckSolid,
  faPhoneRotary,
  falPhoneRotary,
  falFaReceipt,
  falShoppingCart,
  faMobileAlt,
  fasTimes,
  faHospitalAltLight,
  faCogSolid,
  faHandHeart,
  faUserShield,
  faQuestionCircle,
  faExpand,
  faCompress,
  faShare,
  faShoppingCart,
  faChevronSquareDown,
  faChevronSquareUp,
  fasComment,
  fasCommentSlash,
  faCopy,
  faMicrophone,
  faComments,
  faWalking,
  faHospitalUser,
  faBookMedicalLight,
  faHeartbeatLight,
  faPrint,
  faTrophyAlt,
  fasComments,
  fasUserMd,
  fasHospitalUser,
  fasWalking,
  faSignalSlash,
  farTimes,
  farPlus,
  faExclamationCircle,
  faFilePrescription,
  faShield,
  falWalking,
  falVideo,
  faMapMarkerAlt,
  faVial,
  faPlusCircle,
  faLongArrowRight,
  falFileAlt,
  faHeart,
  faLocation,
  faHospitalALtRegular,
  farSparkles,
  fasHeart,
  farHistory,
  falMobileAlt,
  faFileCertificate,
  faEye,
  fasHeadSideMedical,
  fasTree,
  fasSignalStream,
  fasCalendarAlt,
  falFax,
  fasFax,
  faShareSquare,
  fasUserFriends,
  faSortAmountDown,
  faUndoAlt,
  faSyncAlt,
  falHeadSideMedical,
  falMonitorHeartRate,
  faQrcode,
  faCode,
  faSearchPlus,
  faSearchMinus,
  falAddressBook,
  fasCircle,
  faChevronDoubleRight,
  faSave,
  falFileInvoice,
  falGlobe,
);
