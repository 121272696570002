import React from 'react';
import moment from 'moment';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { CalendarIcon2 } from '../../../assets/icons';
import { PHRHealthCondition } from '../../../generated';
import { TimeFormatEnum } from '../../../constants';
import { verifyForPhrSection } from '../../../store/patient/phr/phrCreators';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { successToastr } from '../../../utils/toastr';

import PhrItemView from '../components/PhrItemView';
import { OutpostTheme } from '../../../themes/outpost';

interface IHealthConditionViewProps {
  healthCondition: PHRHealthCondition;
}

const HealthConditionView = ({ healthCondition }: IHealthConditionViewProps) => {
  const dispatch = useDispatch();

  const onVerify = async (id: string, patientID: string) => {
    dispatch(verifyForPhrSection(ReduxPHRCategoryEnum.PHRHealthConditions, id, patientID));
    successToastr({ description: 'Successfully verified health condition' });
  };

  return (
    <PhrItemView
      phrItem={healthCondition}
      onVerify={() => onVerify(healthCondition.phrHealthConditionID, healthCondition.patientID)}
    >
      <Flex justify="space-between" align="center">
        <Box>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Diagnosis Start Date
          </Text>
          <Text
            fontSize={OutpostTheme.FontSizes.body}
            marginTop="0.5rem"
            display="flex"
            alignItems="center"
          >
            <CalendarIcon2 fontSize="18px" color="#78819D" />
            &nbsp;
            {moment(healthCondition.diagnosedStartDate).format(TimeFormatEnum.SHORT_LONG_DATE)}
          </Text>
        </Box>
        <Box>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Diagnosis End Date
          </Text>
          <Text
            fontSize={OutpostTheme.FontSizes.body}
            marginTop="0.5rem"
            display="flex"
            alignItems="center"
          >
            {healthCondition.diagnosedEndDate ? (
              <>
                <CalendarIcon2 fontSize="18px" color="#78819D" />
                &nbsp;
                {moment(healthCondition.diagnosedEndDate).format(TimeFormatEnum.SHORT_LONG_DATE)}
              </>
            ) : (
              'No end date'
            )}
          </Text>
        </Box>
      </Flex>

      <Box marginTop="1.5rem">
        <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
          Notes
        </Text>
        <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
          {healthCondition.notes ? healthCondition.notes : 'No notes have been added yet'}
        </Text>
      </Box>
    </PhrItemView>
  );
};

export default HealthConditionView;
