export enum SignupFlowEnum {
  Initiate = 'Initiate',
  Login = 'Login',
  Signup = 'Signup',
  SetPassword = 'SetPassword',
  SecurityCode = 'SecurityCode',
}

export enum CurrentFlowHeaderEnum {
  Initiate = 'you need to sign up or sign in to proceed',
  Login = 'sign in here',
  Signup = 'you need to sign up or sign in to proceed',
  SetPassword = 'set your password',
  SecurityCode = 'enter security code',
}

export enum CurrentFlowSubHeaderEnum {
  Login = 'sign in here to login to your outpost health account.',
  SetPassword = "create your password, this is the password you'd need to login.",
  SecurityCode = 'please enter the 6-digit security code we sent to your email or phone number',
}

export const SignupFlowSteps = ['Signup', 'SetPassword', 'SecurityCode'];
