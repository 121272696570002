import { Box, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { Tag } from '../../../../../../../generated';
import { OutpostTheme } from '../../../../../../../themes/outpost';

type ServiceCardProps = {
  selected: boolean;
  service: Tag;
  onSelect: () => void;
};

const ServiceCard: FC<ServiceCardProps> = (props) => (
  <Box
    role="button"
    borderRadius="100px"
    p="1"
    py="2"
    bgColor={props.selected ? 'purple.600' : 'white'}
    borderColor={props.selected ? 'purple.600' : 'rgb(157, 157, 157)'}
    border="1px solid"
    onClick={props.onSelect}
  >
    <Text
      fontSize={OutpostTheme.FontSizes.body}
      color={props.selected ? 'white' : 'rgb(157, 157, 157)'}
      textTransform="capitalize"
      textAlign="center"
    >
      {props.service.name}
    </Text>
  </Box>
);

export { ServiceCard };
