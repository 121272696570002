import { EMPTY_GUID } from '../../../constants';
import { AddressBook, AddressBookFolderEnum, SendToEnum } from '../../../generated';
import { ISendTo } from '../constants';

export const getSendToByAddressBook = (
  addressBook: AddressBook,
  sendCopyToPatient?: boolean,
): ISendTo => {
  let sendToEnum = SendToEnum.DoNotSend;
  let sendToID = '';

  switch (addressBook.addressBookFolderEnum) {
    case AddressBookFolderEnum.Clinic:
    case AddressBookFolderEnum.Lab:
    case AddressBookFolderEnum.Pharmacy:
    case AddressBookFolderEnum.Organization:
      if (addressBook.addressBookID === EMPTY_GUID) {
        // It's an internal Store
        sendToEnum = SendToEnum.Store;
        break;
      } else {
        // It's an external Address Book entry
        sendToEnum = SendToEnum.Fax;
        break;
      }
    case AddressBookFolderEnum.Provider:
    case AddressBookFolderEnum.Patient:
      if (addressBook.addressBookID === EMPTY_GUID) {
        // Internal Provider / Patient
        sendToEnum = SendToEnum.UserDetail;
        break;
      } else {
        // External Provider / Patient
        sendToEnum = SendToEnum.Fax;
        break;
      }
  }

  switch (sendToEnum) {
    case SendToEnum.Fax:
      sendToID = addressBook.addressBookID!;
      break;
    case SendToEnum.UserDetail:
    case SendToEnum.Store:
      sendToID = addressBook.associatedID!;
      break;
  }

  if (!sendToID && sendToEnum !== SendToEnum.DoNotSend) {
    throw new Error('Unable to send to selected Contact.');
  }

  return {
    sendToEnum,
    sendToID,
    sendCopyToPatient: sendCopyToPatient || false,
  } as ISendTo;
};
