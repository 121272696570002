import {
  IconButton,
  Modal as ChakraModal,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  ModalProps,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FC } from 'react';
import { use100vh } from 'react-div-100vh';
import { ModalBackButton, ModalBackButtonButtonProps } from '../Buttons/ModalBackButton';
import { OutpostLogoWatermark } from './OutpostLogoWatermark';

import { XCloseIcon } from '../../assets/icons';

export type BaseModalProps = Overwrite<ModalProps, { onClose?: () => void }> & {
  modalContentProps?: ModalContentProps;
  modalHeaderProps?: ModalHeaderProps;
  title?: any;
  onDismiss?: () => void;
  showWaterMark?: boolean;
  onBack?: () => void;
  backButtonProps?: ModalBackButtonButtonProps;
  fullScreen?: boolean;
};

type CloseIconButtonProps = {
  onClick?: () => void;
};

export const CloseIconButton: FC<CloseIconButtonProps> = (props: any) => (
  <IconButton
    aria-label="Go back"
    bgColor="transparent"
    _hover={{ bgColor: 'rgb(219, 217, 217)' }}
    padding={1}
    onClick={props.onClick}
    position="absolute"
    right="6px"
    top="6px"
    p="2"
    zIndex={3}
    borderRadius="full"
  >
    <XCloseIcon fontSize="26px" />
  </IconButton>
);

export const BaseModal = (props: BaseModalProps) => {
  const {
    children,
    title,
    onDismiss,
    modalHeaderProps,
    modalContentProps,
    closeOnOverlayClick = false,
    closeOnEsc = false,
    size,
    showWaterMark = false,
    onBack,
    backButtonProps,
    fullScreen = true,
    ...rest
  } = props;
  const height = use100vh();
  const isLargeScreen = useBreakpointValue({ base: false, lg: true });

  return (
    <ChakraModal
      closeOnOverlayClick={closeOnOverlayClick}
      closeOnEsc={closeOnEsc}
      size={size}
      onClose={() => (onDismiss ? onDismiss() : () => {})}
      trapFocus={false}
      isCentered
      {...rest}
    >
      <ModalOverlay />
      <ModalContent
        height={{
          base: fullScreen ? height || '80%' : 'auto',
          sm: size === 'xl' ? '690px' : '430px',
        }}
        minH={{
          base: fullScreen ? 'fit-content' : '80vh',
          sm: fullScreen ? 'fit-content' : '60vh',
        }}
        maxH={fullScreen ? height || '100vh' : '90vh'}
        width={fullScreen ? '100%' : '660px'}
        m={fullScreen ? 0 : '16px'}
        borderRadius={{
          base: fullScreen ? 0 : '12px',
          sm: fullScreen ? '12px' : 0,
        }}
        overflowY="auto"
        {...modalContentProps}
      >
        {Boolean(onBack) && (
          <ModalBackButton
            position="absolute"
            top="6px"
            left="6px"
            onClick={onBack}
            {...backButtonProps}
          />
        )}
        {title && <ModalHeader {...modalHeaderProps}>{title}</ModalHeader>}
        {Boolean(onDismiss) && <CloseIconButton onClick={onDismiss} />}
        {children}
        {size === 'xl' && showWaterMark && isLargeScreen && (
          <OutpostLogoWatermark
            zIndex={-1}
            position="absolute"
            bottom="72px"
            color="purple.600"
            left="32px"
            width="145px"
            height="130px"
          />
        )}
      </ModalContent>
    </ChakraModal>
  );
};
