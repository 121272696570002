import React from 'react';
import { Line } from 'react-chartjs-2';
import SkeletonButton from '../../../components/Buttons/SkeletonButton';
import Card from '../../../components/Card';
import { ChartButtonRow, ChartContainer } from './StyledPhrItems';

const PhrChartSkeleton: React.FC = () => {
  const randomScalingFactor = function () {
    return Math.ceil(Math.random() * 10.0);
  };

  const data = () => {
    return {
      labels: ['January', 'April', 'August', 'December'],
      datasets: [
        {
          label: '',
          borderColor: '#EEE',
          borderWidth: 2,
          fill: false,
          data: [
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
            randomScalingFactor(),
          ],
        },
      ],
    };
  };

  const options = {
    legend: {
      position: 'bottom',
    },
    title: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          display: true,
        },
      ],
      yAxes: [
        {
          display: true,
          ticks: {
            suggestedMin: 0,
            suggestedMax: 11,
          },
        },
      ],
    },
    maintainAspectRatio: false,
  };

  return (
    <>
      <Card>
        <ChartButtonRow>
          <div>
            <SkeletonButton>Year</SkeletonButton>
            <SkeletonButton>3 Months</SkeletonButton>
            <SkeletonButton>14 Days</SkeletonButton>
          </div>
        </ChartButtonRow>
        <ChartContainer>
          {/* @ts-ignore */}
          <Line data={data} options={options} />
        </ChartContainer>
      </Card>
    </>
  );
};

export default PhrChartSkeleton;
