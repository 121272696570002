import { Stack, StackProps, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';

export const ButtonRow = (props: StackProps) => {
  const { children, mt = 2, justifyContent = 'flex-end', ...rest } = props;

  // Stack add margin to the second button on the bottom, which leads to undesired behaviour
  const useHStack = useBreakpointValue({ base: false, sm: true });

  return (
    <Stack
      spacing={4}
      width="100%"
      justifyContent={justifyContent}
      direction={['column-reverse', 'row']}
      mt={mt}
      sx={
        useHStack
          ? {
              '> *': {
                transition: 'none !important',
                marginBottom: '0 !important',
              },
            }
          : {}
      }
      {...rest}
    >
      {children}
    </Stack>
  );
};
