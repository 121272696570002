import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { PrimaryButton } from '../../../components/Buttons';

interface IProps {
  id?: string;
  disabled?: boolean;
  children: any;
  onClick: () => void;
}

const AddPhrItemButton = ({ id, disabled = false, children, onClick }: IProps) => {
  return (
    <PrimaryButton
      id={id}
      onClick={onClick}
      disabled={disabled}
      background="#3E1191"
      fontSize="12px"
      _hover={{
        backgroundColor: '#dfd0ff',
        color: '#3E1191',
      }}
      leftIcon={<FontAwesomeIcon icon={['far', 'plus']} />}
    >
      {children}
    </PrimaryButton>
  );
};

export default AddPhrItemButton;
