import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AllergySeverityEnum, PHRAllergy, PHRAllergyTag } from '../../../../generated';
import { verifyForPhrSection } from '../../../../store/patient/phr/phrCreators';
import { IPHRPatientEnhanced } from '../../../../store/patient/phr/phrReducers';
import {
  createForPhrSection,
  deleteForPhrSection,
  updateForPhrSection,
} from '../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import { successToastr } from '../../../../utils/toastr';
import { IAllergyForm } from '../components/AllergyForm/AllergyForm';

export const useAllergiesAPI = (phr: IPHRPatientEnhanced) => {
  const dispatch = useDispatch();
  const [isEpipenLoading, setIsEpipenLoading] = useState<boolean>(false);
  const [isApiLoading, setIsApiLoading] = useState<boolean>(false);

  const upsertAllergy = async (values: IAllergyForm, selectedAllergy: PHRAllergy | null) => {
    const allergy = selectedAllergy ? selectedAllergy : new PHRAllergy();
    setIsApiLoading(true);
    allergy.patientID = phr.patientID;
    allergy.otherName = values.name;
    allergy.allergySeverityEnum = values.severity ?? AllergySeverityEnum.Moderate;
    allergy.otherReaction = values.otherReactions;
    allergy.notes = values.notes;

    if (values.reactionTags) {
      allergy.phrAllergyTags = values.reactionTags.map((x) => {
        const allergyTag = new PHRAllergyTag();
        allergyTag.tagID = x.tagID;
        return allergyTag;
      });
    }

    if (selectedAllergy) {
      await dispatch(
        updateForPhrSection(ReduxPHRCategoryEnum.PHRAllergies, allergy, phr.patientID, false),
      );
    } else {
      await dispatch(
        createForPhrSection(ReduxPHRCategoryEnum.PHRAllergies, allergy, phr.patientID, false),
      );
      successToastr({ description: 'Allergy added' });
    }
    setIsApiLoading(false);
  };

  const verifyAllergy = async (phrAllergyID: string) => {
    setIsApiLoading(true);
    dispatch(verifyForPhrSection(ReduxPHRCategoryEnum.PHRAllergies, phrAllergyID, phr.patientID));
    setIsApiLoading(false);
  };

  const deleteAllergy = async (phrAllergyID: string) => {
    setIsApiLoading(true);
    await dispatch(
      deleteForPhrSection(ReduxPHRCategoryEnum.PHRAllergies, phrAllergyID, phr.patientID, false),
    );
    setIsApiLoading(false);
  };

  const updateEpiPen = async (epiPen: boolean | undefined) => {
    if (phr) {
      setIsEpipenLoading(true);
      phr.epiPen = epiPen;
      await dispatch(
        updateForPhrSection(ReduxPHRCategoryEnum.PHRPatients, phr, phr.patientID, false),
      );
      setIsEpipenLoading(false);
    }
  };

  return {
    isApiLoading,
    isEpipenLoading,
    upsertAllergy,
    verifyAllergy,
    deleteAllergy,
    updateEpiPen,
  };
};
