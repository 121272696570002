import { AuthClient, CampaignsClient } from '../../generated';
import { httpRequest } from '../../utils';
import { OHThunkResult } from '../root-reducers';
import { setActions } from './currentCampaignActions';

export function getCurrentCampaign(campaignName: string): OHThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    return new Promise<void>(async () => {
      dispatch(setActions.request());
      const campaignClient = new CampaignsClient(new AuthClient());
      try {
        const result = await httpRequest(() => campaignClient.search(campaignName));
        if (result) {
          dispatch(setActions.success(result));
        } else {
          throw new Error('Unable to retrieve Campaign.');
        }
      } catch (err) {
        dispatch(setActions.failure(err as string));
      }
    });
  };
}
