import { Box, Button, Checkbox, Flex, Text } from '@chakra-ui/react';
import { Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { date, object, string } from 'yup';

import { GLOBAL_MAX_TEXT_LENGTH } from '../../../constants';
import { IPHRHealthCondition, PHRHealthCondition, TagTypeEnum } from '../../../generated';
import { useTagsAllByTagType } from '../../../services/tags/queries/useTagsAllByTagType';
import { createForPhrSection, updateForPhrSection } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { scrollToTop } from '../../../utils/scrollToTop';

import { AutoCompleteAlt, Form, FormMobisPicker, FormTextArea } from '../../../components/forms';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import ServerValidationBox from '../../../components/ServerValidationBox';
import { OutpostTheme } from '../../../themes/outpost';
import PhrItemForm from '../components/PhrItemForm';

interface IProps {
  currentHealthCondition: IPHRHealthCondition | null;
  patientID: string;
  toggleView: () => void;
  innerBtnRef: React.RefObject<HTMLButtonElement>;
}

interface IForm {
  name: string;
  notes: string;
  diagnosedStartDate: Date;
  diagnosedEndDate?: Date;
}

const HealthConditionForm = ({
  currentHealthCondition,
  patientID,
  toggleView,
  innerBtnRef,
}: IProps) => {
  const dispatch = useDispatch();
  const { error, isPhrLoading } = useSelector((state: AppState) => state.phrPatientState);
  const [isOngoing, setIsOngoing] = useState<boolean>(
    currentHealthCondition?.diagnosedEndDate ? false : true,
  );

  const { tags = [] } = useTagsAllByTagType(TagTypeEnum.Condition);

  useEffect(() => {
    scrollToTop();
  }, []);

  const submit = async (values: IForm) => {
    const condition = new PHRHealthCondition();
    condition.init(currentHealthCondition);
    condition.patientID = patientID;
    condition.name = values.name;
    condition.tagID = tags.filter(
      (tag) => tag.name.toLowerCase() === values.name.toLowerCase(),
    )[0]?.tagID;
    condition.notes = values.notes;

    condition.diagnosedStartDate = new Date(values.diagnosedStartDate);
    if (!isOngoing) {
      condition.diagnosedEndDate = new Date(values.diagnosedEndDate!);
    } else {
      condition.diagnosedEndDate = undefined;
    }
    if (currentHealthCondition) {
      await dispatch(
        updateForPhrSection(
          ReduxPHRCategoryEnum.PHRHealthConditions,
          condition,
          condition.patientID,
          false,
        ),
      );
    } else {
      await dispatch(
        createForPhrSection(
          ReduxPHRCategoryEnum.PHRHealthConditions,
          condition,
          condition.patientID,
          false,
        ),
      );
    }
    toggleView();
  };

  const initialValues: IForm = {
    name: currentHealthCondition?.name || '',
    notes: currentHealthCondition?.notes || '',
    diagnosedStartDate: currentHealthCondition
      ? currentHealthCondition.diagnosedStartDate
      : new Date(),
    diagnosedEndDate: currentHealthCondition ? currentHealthCondition.diagnosedEndDate : undefined,
  };

  return (
    <PhrItemForm>
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          name: string()
            .required('A condition name is required')
            .max(100, 'Name must be at most 100 characters'),
          notes: string().max(GLOBAL_MAX_TEXT_LENGTH, 'Notes must be at most 10000 characters'),
          diagnosedStartDate: date()
            .required('Diagnosed start date is required')
            .typeError('Invalid date')
            .test('beforeDate', 'Start Date must be in the past', function (value) {
              return moment(value).isBefore(moment());
            }),
          diagnosedEndDate: date()
            .typeError('Invalid date')
            .test(
              'diagnosedStartDate',
              'Diagnosed end date must be after the diagnosed start date',
              function (value) {
                if (isOngoing) {
                  return true;
                }
                if (!value) {
                  return false;
                }
                const startDate = moment(this.parent.diagnosedStartDate);
                return value && moment(value).isValid() && moment(value).isAfter(startDate);
              },
            ),
        })}
        onSubmit={submit}
      >
        {({ status, values }) => (
          <Form error={error}>
            <>
              <ServerValidationBox message={status} />
              {isPhrLoading ? (
                <CenteredLoadingIndicator />
              ) : (
                <>
                  <Box marginBottom="1rem">
                    <AutoCompleteAlt
                      label="Name of Condition"
                      name="name"
                      options={tags}
                      selected={{ name: values.name }}
                      valueKey="name"
                      labelKey="name"
                      noOptionsMessage={'No conditions found'}
                      mustSelectFromOptions={false}
                      addNewText="+ Add As A New Condition"
                      saveNewValueOnAddNew
                      isForm
                    />
                  </Box>

                  <Flex justify="space-between" flexDir={{ base: 'column', md: 'row' }}>
                    <Box width={{ base: '100%', md: '48%' }}>
                      <FormMobisPicker
                        name="diagnosedStartDate"
                        label="Diagnosed Start Date"
                        placeholder="DD / MM / YY"
                        max={moment().toDate()}
                        min={moment().add(-150, 'year').toDate()}
                        borderRadius="lg"
                        containerProps={{
                          height: '48px',
                          // inputElementProps: {
                          //   height: '48px',
                          // },
                        }}
                        formControlProps={{
                          marginBottom: 0,
                          formLabelProps: {
                            textTransform: 'uppercase',
                            color: '#01143173',
                            fontSize: '12px',
                            fontWeight: 'light',
                          },
                        }}
                      />
                      {/* <FormDateTimePicker
                        name="diagnosedStartDate"
                        label="Diagnosed Start Date"
                        placeholderText="DD / MM / YY"
                        maxDate={moment().toDate()}
                        minDate={moment().add(-150, 'year').toDate()}
                        customInputProps={{
                          height: '48px',
                          borderRadius: 'lg',
                          inputElementProps: {
                            height: '48px',
                          },
                        }}
                        formControlProps={{
                          marginBottom: 0,
                          formLabelProps: {
                            textTransform: 'uppercase',
                            color: '#01143173',
                            fontSize: '12px',
                            fontWeight: 'light',
                          },
                        }}
                      /> */}
                      <Checkbox
                        marginTop="0.5rem"
                        defaultChecked={isOngoing}
                        checked={isOngoing}
                        colorScheme="cyan"
                        onChange={() => setIsOngoing((prev) => !prev)}
                      >
                        <Text fontSize={OutpostTheme.FontSizes.body} color="#011431F0">
                          This condition is ongoing
                        </Text>
                      </Checkbox>
                    </Box>

                    {!isOngoing && (
                      <FormMobisPicker
                        name="diagnosedEndDate"
                        label="Diagnosed End Date"
                        placeholder="DD / MM / YY"
                        max={moment().add(1, 'year').toDate()}
                        min={moment().add(-150, 'year').toDate()}
                        borderRadius="lg"
                        containerProps={{
                          height: '48px',
                          // inputElementProps: {
                          //   height: '48px',
                          // },
                        }}
                        formControlProps={{
                          width: { base: '100%', md: '48%' },
                          marginTop: { base: '1rem', md: 0 },
                          formLabelProps: {
                            textTransform: 'uppercase',
                            color: '#01143173',
                            fontSize: '12px',
                            fontWeight: 'light',
                          },
                        }}
                      />

                      // <FormDateTimePicker
                      //   name="diagnosedEndDate"
                      //   label="Diagnosed End Date"
                      //   placeholderText="DD / MM / YY"
                      //   maxDate={moment().add(1, 'year').toDate()}
                      //   minDate={moment().add(-150, 'year').toDate()}
                      //   customInputProps={{
                      //     height: '48px',
                      //     borderRadius: 'lg',
                      //     inputElementProps: {
                      //       height: '48px',
                      //     },
                      //   }}
                      //   formControlProps={{
                      //     width: { base: '100%', md: '48%' },
                      //     marginTop: { base: '1rem', md: 0 },
                      //     formLabelProps: {
                      //       textTransform: 'uppercase',
                      //       color: '#01143173',
                      //       fontSize: '12px',
                      //       fontWeight: 'light',
                      //     },
                      //   }}
                      // />
                    )}
                  </Flex>

                  <FormTextArea
                    name="notes"
                    label="Notes"
                    borderRadius="lg"
                    height="120px"
                    resize="none"
                    placeholder="Type a short note here"
                    formControlProps={{
                      marginTop: '1rem',
                      formLabelProps: {
                        textTransform: 'uppercase',
                        color: '#01143173',
                        fontSize: '12px',
                        fontWeight: 'light',
                      },
                    }}
                  />

                  <Button display="none" ref={innerBtnRef} type="submit" />
                </>
              )}
            </>
          </Form>
        )}
      </Formik>
    </PhrItemForm>
  );
};

export default HealthConditionForm;
