import { useCallback, useEffect, useState } from 'react';
import { AuthClient, Tag, TagsClient, TagTypeEnum } from '../../generated';
import { httpRequest } from '../../utils';

interface IEmojiPickerProps {
  tagTypeEnum: TagTypeEnum;
}

const authClient = new AuthClient();

const useEmojiPicker = ({ tagTypeEnum }: IEmojiPickerProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emojis, setEmojis] = useState<Tag[]>([]);
  const [tagMap, setTagMap] = useState<any>({});
  const [error, setError] = useState<string>('');

  const getTags = useCallback(async () => {
    try {
      setIsLoading(true);
      const tagsClient = new TagsClient(authClient);
      const tags = await httpRequest(() => tagsClient.allByTagType(tagTypeEnum));
      setEmojis(tags);
    } catch (err) {
      setError(err as string);
    }
    setIsLoading(false);
  }, [tagTypeEnum]);

  const getTagList = (value: number | undefined) => {
    const tags = !value ? [] : emojis.find((x) => x.externalID === String(value))?.tagXrefs;
    return tags || [];
  };

  useEffect(() => {
    getTags();
  }, [getTags, tagTypeEnum]);

  useEffect(() => {
    if (emojis) {
      setTagMap(arrayToMap(emojis));
    }
  }, [emojis]);

  return {
    isLoading,
    emojis,
    getTagList,
    tagMap,
    tagsError: error,
  };
};

const arrayToMap = (tags: Tag[]) => {
  const reducer = (acc, cur) => {
    return Object.assign(acc, {
      [cur.tagID]: cur,
    });
  };
  return tags.reduce(reducer, {});
};

export default useEmojiPicker;
