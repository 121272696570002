import { useContext } from 'react';
import { BookConsultContext } from '../../components/BookConsultProvider';

export default function useBookConsultContext() {
  const context = useContext(BookConsultContext);
  if (!context) {
    throw new Error('useBookConsultContext must be used within a BookConsultProvider');
  }
  return context;
}
