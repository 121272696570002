import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ConsultTypeEnum } from '../../generated';
import { AppState } from '../../store/root-reducers';

export const useCrmTracking = () => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  const trackBookingConsult = useCallback(
    (consultType: ConsultTypeEnum) => {
      window.fwcrm.trackCustomEvent('Booked Consult', {
        email: currentUser?.emailAddress,
        consultType,
      });
    },
    [currentUser],
  );

  return { trackBookingConsult };
};
