import { useEffect } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

type UseLocationSearchProps = {
  handleSetPlaceDetails?: (placeResult: google.maps.places.PlaceResult | null) => void;
  debounceTimeout?: number;
  selectedPlace?: google.maps.places.PlaceResult;
};

const useLocationSearch = (props: UseLocationSearchProps) => {
  const { handleSetPlaceDetails, debounceTimeout, selectedPlace } = props;

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading, placesService } =
    usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
      debounce: debounceTimeout || 500,
    });

  // console.log({ placePredictions });
  useEffect(() => {
    // fetch place details for the selected place if allowed
    if (Boolean(handleSetPlaceDetails) && selectedPlace?.place_id)
      placesService?.getDetails(
        {
          placeId: selectedPlace.place_id,
        },
        (placeResult) => handleSetPlaceDetails?.(placeResult),
      );
  }, [handleSetPlaceDetails, placesService, selectedPlace]);

  return {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    selectedPlace,
  };
};

export { useLocationSearch };
