import { Box, Flex } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PHRSurgeryHistory } from '../../../generated';
import { clearPHRPatientErrorAction } from '../../../store/patient/phr/phrActions';
import { IPHRPatientCombined } from '../../../store/patient/phr/phrReducers';
import { phrSurgeryHistoriesSelector } from '../../../store/patient/phr/phrSelectors';
import { deleteForPhrSection, getAllForPhrSection } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { scrollToTop } from '../../../utils/scrollToTop';

import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import ServerValidationBox from '../../../components/ServerValidationBox';
import { AddPHRItemButton } from '../components/PhrItem';
import PhrItemDeleteModal from '../components/PhrItemDeleteModal';
import { PhrFormButtons } from '../components/PhrItemForm';
import { PhrDetailsButtons } from '../components/PhrItemView';
import SurgeryHistoryForm from './SurgeryHistoryForm';
import SurgeryHistoryList from './SurgeryHistoryList';
import SurgeryHistoryView from './SurgeryHistoryView';

enum EPHRSurgeryViewState {
  List = 'List',
  View = ' View',
  Edit = 'Edit',
}

interface IProps {
  patient: IPHRPatientCombined;
  renderHeader: (onCreate?: any, onBack?: () => void, title?: string, showDismiss?: boolean) => any;
}

const PHRSurgeryHistoryComponent = ({ patient: phrPatient, renderHeader }: IProps) => {
  const dispatch = useDispatch();
  const innerBtnRef = useRef<HTMLButtonElement>(null);
  const phrSurgeryHistories = phrSurgeryHistoriesSelector(phrPatient.phr.phrSurgeryHistories);
  const { isPhrLoading, error } = useSelector((state: AppState) => state.phrPatientState);
  const [recordToDelete, setRecordToDelete] = useState<string>('');
  const [selectedSurgeryHistory, setSelectedSurgeryHistory] = useState<PHRSurgeryHistory | null>(
    null,
  );
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [currentView, setCurrentView] = useState<EPHRSurgeryViewState>(EPHRSurgeryViewState.List);

  useEffect(() => {
    if (phrPatient) {
      dispatch(
        getAllForPhrSection(ReduxPHRCategoryEnum.PHRSurgeryHistories, phrPatient.phr.patientID),
      );
    }
    // avoid re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onView = (phrSurgeryHistory: PHRSurgeryHistory) => {
    setSelectedSurgeryHistory(phrSurgeryHistory);
    setCurrentView(EPHRSurgeryViewState.View);
  };

  const onCreate = () => {
    setSelectedSurgeryHistory(null);
    setCurrentView(EPHRSurgeryViewState.Edit);
  };

  const onEdit = (phrSurgeryHistory: PHRSurgeryHistory) => {
    setSelectedSurgeryHistory(phrSurgeryHistory);
    setCurrentView(EPHRSurgeryViewState.Edit);
  };

  const onDelete = (phrSurgeryHistoryID: string) => {
    setShowDeleteModal(true);
    setRecordToDelete(phrSurgeryHistoryID);
  };

  const confirmDelete = async () => {
    setCurrentView(EPHRSurgeryViewState.List);
    setShowDeleteModal(false);
    await dispatch(
      deleteForPhrSection(
        ReduxPHRCategoryEnum.PHRSurgeryHistories,
        recordToDelete,
        phrPatient.phr.patientID,
        false,
      ),
    );
  };

  useEffect(() => {
    dispatch(clearPHRPatientErrorAction());
    scrollToTop();
  }, [currentView, dispatch]);

  return (
    <Box paddingY="2rem" backgroundColor="#f4f4f4" height="100%">
      {currentView === EPHRSurgeryViewState.List ? (
        renderHeader()
      ) : (
        <Flex>
          {renderHeader(null, () => setCurrentView(EPHRSurgeryViewState.List), '', false)}
          {currentView === EPHRSurgeryViewState.View && (
            <PhrDetailsButtons
              isVerified={selectedSurgeryHistory?.verified}
              phrItem={selectedSurgeryHistory}
              phrItemID={selectedSurgeryHistory?.phrSurgeryHistoryID}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          )}
          {currentView === EPHRSurgeryViewState.Edit && (
            <PhrFormButtons
              innerBtnRef={innerBtnRef}
              toggleView={() => setCurrentView(EPHRSurgeryViewState.List)}
            />
          )}
        </Flex>
      )}

      {currentView === EPHRSurgeryViewState.List && (
        <>
          <AddPHRItemButton name="Surgery" onCreate={onCreate} />
          <ServerValidationBox message={error} />
          {isPhrLoading ? (
            <Box marginTop="1rem">
              <CenteredLoadingIndicator />
            </Box>
          ) : (
            <SurgeryHistoryList phrSurgeryHistories={phrSurgeryHistories} onView={onView} />
          )}
        </>
      )}

      {currentView === EPHRSurgeryViewState.View && (
        <SurgeryHistoryView phrSurgeryHistory={selectedSurgeryHistory!} />
      )}

      {currentView === EPHRSurgeryViewState.Edit && (
        <SurgeryHistoryForm
          selectedSurgeryHistory={selectedSurgeryHistory}
          patientID={phrPatient.phr.patientID}
          toggleView={() => setCurrentView(EPHRSurgeryViewState.List)}
          innerBtnRef={innerBtnRef}
        />
      )}

      <PhrItemDeleteModal
        isOpen={showDeleteModal}
        onDismiss={() => setShowDeleteModal(false)}
        onDelete={confirmDelete}
      />
    </Box>
  );
};

export default PHRSurgeryHistoryComponent;
