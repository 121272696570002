import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import {} from 'use-debounce';
import { DebouncedState } from 'use-debounce/dist/useDebouncedCallback';
import { ConsultTabTypeEnum } from '../../../../constants';
import {
  ConsultStatusEnum,
  Country,
  IConsult,
  IPHRConsult,
  PHRConsultTag,
  PHRDocument,
  PHRForm,
  Provider,
  Tag,
  TagTypeEnum,
} from '../../../../generated';
import { useCountriesAll } from '../../../../services/countries/useCountriesAll';
import { useTagsAllByTagType } from '../../../../services/tags/queries/useTagsAllByTagType';
import useConsultTimer from '../hooks/useConsultTimer';
import { useConsultReopen } from './hooks/useConsultReopen/useConsultReopen';
import usePhrConsult, { ValidationMessages } from './hooks/usePhrConsult/usePhrConsult';
import usePhrDocuments from './hooks/usePhrDocuments/usePhrDocuments';

interface IConsultActionAreaContext {
  isInConsult: boolean;
  phrConsultIsLoading: boolean;
  phrConsult: IPHRConsult | undefined;
  loadingPhrConsultError: string;
  tags?: Tag[];
  setPhrConsultTags: React.Dispatch<React.SetStateAction<PHRConsultTag[]>>;
  phrConsultTags: PHRConsultTag[];
  setValidationMessages: React.Dispatch<any>;
  validationMessages: ValidationMessages | null;
  complaint: string;
  setComplaint: React.Dispatch<React.SetStateAction<string>>;
  subjective: string;
  setSubjective: React.Dispatch<React.SetStateAction<string>>;
  objective: string;
  setObjective: React.Dispatch<React.SetStateAction<string>>;
  diagnosis: string;
  setDiagnosis: React.Dispatch<React.SetStateAction<string>>;
  plan: string;
  setPlan: React.Dispatch<React.SetStateAction<string>>;
  attestation: string;
  setAttestation: React.Dispatch<React.SetStateAction<string>>;
  attestationProvider: Provider | null;
  setAttestationProvider: React.Dispatch<React.SetStateAction<Provider | null>>;
  patientID: string;
  isPhrDocumentsLoading: boolean;
  phrDocuments: PHRDocument[];
  currentTab: ConsultTabTypeEnum;
  setCurrentTab: React.Dispatch<React.SetStateAction<ConsultTabTypeEnum>>;
  getPhrDocumentsByPHRConsultID: () => void;
  updateSOAPNotes: (isSilentUpdate?: boolean) => void;
  isUpdatingPhrConsult: boolean;
  completeConsult: (isDraft: boolean) => Promise<boolean>;
  updatingPhrError: string;
  sessionTimer: string;
  patientCountry?: Country;
  setPatientCountry: React.Dispatch<React.SetStateAction<Country | undefined>>;
  consultDate?: Date;
  setConsultDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  isAttested: boolean;
  setAttested: React.Dispatch<React.SetStateAction<boolean>>;
  countries: Country[];
  isLoadingCountries: boolean;
  phrForms: PHRForm[];
  loadPhrForms: (phrConsultID: string) => void;
  showHealthRecords: boolean;
  setShowHealthRecords: (val: boolean) => void;
  shouldBlockNavigation: boolean;
  showEndCallModal: boolean;
  setShowEndCallModal: (val: boolean) => void;
  showMobileSubMenu: boolean;
  setShowMobileSubMenu: (val: boolean) => void;
  openIndexes: number[];
  setOpenIndexes: (val: number[]) => void;
  quickMenuDescription?: string;
  debouncedUpdateSOAPNotes: DebouncedState<(isSilentUpdate?: any) => void>;
  reOpenConsult: () => void;
  reOpenConsultLoading: boolean;
  nurseNotes: string;
  setNurseNotes: React.Dispatch<React.SetStateAction<string>>;
}

export const ProviderConsultContext = React.createContext<IConsultActionAreaContext>(null!);

interface IProps {
  children: ReactNode;
  isInConsult: boolean;
  patientID: string;
  consult?: IConsult;
  consultID?: string;
  phrConsultID?: string;
  quickMenuDescription?: string;
}

const ProviderConsult = ({
  children,
  isInConsult,
  patientID,
  consult,
  consultID,
  phrConsultID,
  quickMenuDescription,
}: IProps) => {
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);
  const [currentTab, setCurrentTab] = useState<ConsultTabTypeEnum>(ConsultTabTypeEnum.ConsultNotes);
  const [showHealthRecords, setShowHealthRecords] = useState<boolean>(false);
  const [completedConsultTime, setCompletedConsultTime] = useState<string>('');
  const { isLoadingCountries, countries = [] } = useCountriesAll();
  const [showEndCallModal, setShowEndCallModal] = useState<boolean>(false);
  const [showMobileSubMenu, setShowMobileSubMenu] = useState<boolean>(false);

  const { reOpenConsult, reOpenConsultLoading } = useConsultReopen({
    consultID: consultID || '',
  });
  const {
    phrConsult,
    phrConsultIsLoading,
    loadingPhrConsultError,
    setPhrConsultTags,
    phrConsultTags,
    setValidationMessages,
    validationMessages,
    subjective,
    setSubjective,
    objective,
    setObjective,
    diagnosis,
    setDiagnosis,
    plan,
    setPlan,
    attestation,
    setAttestation,
    attestationProvider,
    setAttestationProvider,
    isUpdatingPhrConsult,
    updateSOAPNotes,
    completeConsult,
    updatingPhrError,
    patientCountry,
    setPatientCountry,
    consultDate,
    setConsultDate,
    isAttested,
    setAttested,
    complaint,
    setComplaint,
    phrForms,
    loadPhrForms,
    shouldBlockNavigation,
    debouncedUpdateSOAPNotes,
    nurseNotes,
    setNurseNotes,
  } = usePhrConsult({
    isInConsult,
    countries,
    patientID,
    consultID,
    phrConsultID,
    showEndCallModal,
    setOpenIndexes,
  });
  const { phrDocuments, isPhrDocumentsLoading, getPhrDocumentsByPHRConsultID } = usePhrDocuments({
    phrConsultID: phrConsult?.phrConsultID,
    patientID: patientID,
  });
  const { tags } = useTagsAllByTagType(TagTypeEnum.Diagnosis);
  const { sessionTimer, startTimer } = useConsultTimer({ consult });

  useEffect(() => {
    if (consult?.sessionEndDate) {
      const from = moment(consult.sessionEndDate).utc();
      const to = moment(consult.sessionStartDate).utc();
      setCompletedConsultTime(moment.utc(from.diff(to)).format('HH:mm:ss'));
    } else if (
      consult &&
      consult.consultStatusEnum !== ConsultStatusEnum.PatientCompleted &&
      moment(consult.sessionStartDate).isValid()
    ) {
      startTimer(consult.sessionStartDate);
    }
  }, [consult, startTimer]);

  return (
    <ProviderConsultContext.Provider
      value={{
        isInConsult,
        phrConsultIsLoading,
        loadingPhrConsultError,
        phrConsult,
        tags,
        setPhrConsultTags,
        phrConsultTags,
        setValidationMessages,
        validationMessages,
        subjective,
        setSubjective,
        objective,
        setObjective,
        diagnosis,
        setDiagnosis,
        plan,
        setPlan,
        attestation,
        setAttestation,
        attestationProvider,
        setAttestationProvider,
        patientID,
        isPhrDocumentsLoading,
        phrDocuments,
        currentTab,
        setCurrentTab,
        getPhrDocumentsByPHRConsultID,
        updateSOAPNotes,
        isUpdatingPhrConsult,
        completeConsult,
        updatingPhrError,
        sessionTimer: completedConsultTime || sessionTimer || '00:00:00',
        patientCountry,
        setPatientCountry,
        consultDate,
        setConsultDate,
        isAttested,
        setAttested,
        complaint,
        setComplaint,
        countries,
        isLoadingCountries,
        phrForms,
        loadPhrForms,
        showHealthRecords,
        setShowHealthRecords,
        shouldBlockNavigation,
        showEndCallModal,
        setShowEndCallModal,
        showMobileSubMenu,
        setShowMobileSubMenu,
        openIndexes,
        setOpenIndexes,
        quickMenuDescription,
        debouncedUpdateSOAPNotes,
        reOpenConsult,
        reOpenConsultLoading,
        nurseNotes,
        setNurseNotes,
      }}
    >
      {children}
    </ProviderConsultContext.Provider>
  );
};

export default ProviderConsult;
