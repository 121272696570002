import { Box, Center, Flex, Stack, Text, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '../../../components/Buttons';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import ServerValidationBox from '../../../components/ServerValidationBox';
import { IPHRConsult, OrderStatusEnum, PatientOrder } from '../../../generated';
import { OrderList } from './components/OrderList/OrderList';
import { OrderModal } from './components/OrderModal/OrderModal';
import { useConsultPatientOrders } from './hooks/useConsultPatientOrders';
import { pastOrdersSelector } from './selectors/pastOrdersSelector';
import { pendingOrdersSelector } from './selectors/pendingOrdersSelector';

interface IProps {
  consult: IPHRConsult;
}

export const OrderComponent = ({ consult }: IProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [patientOrder, setPatientOrder] = useState<PatientOrder | undefined>();
  const { isLoadingOrders, errorOrders, consultPatientOrders, refetchOrders } =
    useConsultPatientOrders({
      patientID: consult.patientID,
      phrConsultID: consult.phrConsultID,
    });
  const pendingOrders = pendingOrdersSelector(consultPatientOrders);
  const pastOrders = pastOrdersSelector(consultPatientOrders);
  const hasOrders = pendingOrders.length !== 0 || pastOrders.length !== 0;

  useEffect(() => {
    const existingDraft = consultPatientOrders.find(
      (x) => x.phrConsultID === consult.phrConsultID && x.orderStatusEnum === OrderStatusEnum.Draft,
    );
    setPatientOrder(existingDraft);
  }, [consultPatientOrders, consult]);

  if (errorOrders) {
    return <ServerValidationBox message={errorOrders} />;
  }

  if (isLoadingOrders) {
    return <CenteredLoadingIndicator />;
  }

  return (
    <>
      {isOpen && consult.country && (
        <OrderModal
          patient={consult.patient!}
          isOpen={isOpen}
          onClose={onClose}
          refetchOrders={refetchOrders}
          draftPatientOrder={patientOrder}
          setPatientOrder={setPatientOrder}
          country={consult.country}
        />
      )}

      {!hasOrders ? (
        <Center mt={10} width="100%">
          <Box
            width="100%"
            maxWidth="400px"
            py={10}
            px={12}
            backgroundColor="purple.50"
            borderRadius="0.25rem"
          >
            <Center>
              <Stack spacing={4}>
                <Text textAlign="center" fontWeight="500">
                  No Orders added yet
                </Text>
                <Text fontSize="12px" textAlign="center">
                  Looks like you haven't added any orders yet
                </Text>
                <Center>
                  <SecondaryButton onClick={onOpen}>Add Order</SecondaryButton>
                </Center>
              </Stack>
            </Center>
          </Box>
        </Center>
      ) : (
        <>
          <Flex justifyContent="flex-end">
            <PrimaryButton onClick={onOpen}>
              {patientOrder ? 'Continue' : 'Add'} Order
            </PrimaryButton>
          </Flex>
          <Stack spacing={12}>
            {pendingOrders.length > 0 && (
              <Box>
                <Text fontWeight="500">Pending Orders</Text>
                <Text mb={8} fontSize="12px">
                  These Orders are still awaiting results
                </Text>
                <OrderList patientOrders={pendingOrders!} />
              </Box>
            )}

            {pastOrders.length > 0 && (
              <Box>
                <Text fontWeight="500">Past Orders</Text>
                <Text fontSize="12px" mb={8}>
                  Order results are available in patients PHR
                </Text>
                <OrderList patientOrders={pastOrders!} />
              </Box>
            )}
          </Stack>
        </>
      )}
    </>
  );
};
