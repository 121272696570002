import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { IUserDetailSetting } from '../../generated';
import { CurrentUserActionTypes, logoutActions } from '../currentUser/currentUserActions';
import {
  getUserDetailSettingsActions,
  GetUserDetailSettingsActionTypes,
} from './getUserDetailSettingsAction';
import {
  updateUserDetailSettingsActions,
  UpdateUserDetailSettingsActionTypes,
} from './updateUserDetailSettingsAction';

const userDetailSettings = createReducer<
  IUserDetailSetting[] | null,
  UpdateUserDetailSettingsActionTypes | GetUserDetailSettingsActionTypes | CurrentUserActionTypes
>(null)
  .handleAction(
    [updateUserDetailSettingsActions.success, getUserDetailSettingsActions.success],
    (_, action) => action.payload,
  )
  .handleAction([logoutActions], () => null);

const error = createReducer<
  string,
  UpdateUserDetailSettingsActionTypes | GetUserDetailSettingsActionTypes
>('').handleAction(
  [updateUserDetailSettingsActions.failure, getUserDetailSettingsActions.failure],
  (_, action) => action.payload,
);

const isLoading = createReducer<
  boolean,
  UpdateUserDetailSettingsActionTypes | GetUserDetailSettingsActionTypes
>(false)
  .handleAction(
    [updateUserDetailSettingsActions.request, getUserDetailSettingsActions.request],
    () => true,
  )
  .handleAction(
    [
      updateUserDetailSettingsActions.failure,
      updateUserDetailSettingsActions.success,
      getUserDetailSettingsActions.failure,
      getUserDetailSettingsActions.success,
    ],
    () => false,
  );

export interface IUserDetailSettingState {
  error: string;
  isLoading: boolean;
  userDetailSettings: IUserDetailSetting[] | null;
}

export default combineReducers<IUserDetailSettingState>({
  userDetailSettings,
  error,
  isLoading,
});
