import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { CurrentUserActionTypes, logoutActions } from '../currentUser/currentUserActions';
import {
  getUnacknowledgedRewardCountActions,
  GetUnacknowledgedRewardCountActionTypes,
} from './getUnacknowledgedRewardCountAction';

export interface IUnacknowledgedRewardCountState {
  error: string;
  isLoading: boolean;
  count: number | null;
}

const count = createReducer<
  number | null,
  GetUnacknowledgedRewardCountActionTypes | CurrentUserActionTypes
>(null)
  .handleAction([getUnacknowledgedRewardCountActions.success], (_state, action) => action.payload)
  .handleAction([logoutActions], () => null);

const error = createReducer<string, GetUnacknowledgedRewardCountActionTypes>('')
  .handleAction([getUnacknowledgedRewardCountActions.failure], (_state, action) => action.payload)
  .handleAction([getUnacknowledgedRewardCountActions.success], () => '');

const isLoading = createReducer<boolean, GetUnacknowledgedRewardCountActionTypes>(false)
  .handleAction([getUnacknowledgedRewardCountActions.request], () => true)
  .handleAction(
    [getUnacknowledgedRewardCountActions.failure, getUnacknowledgedRewardCountActions.success],
    () => false,
  );

export default combineReducers<IUnacknowledgedRewardCountState>({
  count,
  error,
  isLoading,
});
