import { useEffect, useState } from 'react';

import { getGeolocationPermissionState } from '../../utils/getGeolocationPermissionState';

const useLocationPermissionSate = () => {
  const [permissionState, setPermissionState] = useState<PermissionState | 'error' | undefined>();

  useEffect(() => {
    (async () => {
      const permissionStatus = await getGeolocationPermissionState();

      setPermissionState(permissionStatus);
    })();
  }, []);

  return { permissionState };
};

export { useLocationPermissionSate };
