import {
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  InputLeftElement,
} from '@chakra-ui/react';
import { FastField, Field } from 'formik';
import React, { ReactNode, useState } from 'react';
import { EyeIcon } from '../../../assets/icons';
import { BaseProps } from '../baseProps';
import { FormControl } from '../FormControl/FormControl';

export type InputControlProps = BaseProps &
  InputProps & {
    inputLeftElement?: ReactNode;
    inputRightElement?: ReactNode;
    formHelperText?: ReactNode;
  };

export const InputControl = (props: InputControlProps) => {
  const {
    type = 'text',
    label,
    formHelperText,
    formControlProps,
    inputLeftElement,
    inputRightElement,
    testid,
    ...inputProps
  } = props;
  const isPassword = type === 'password';
  const [show, setShow] = useState<boolean>(!isPassword);

  return (
    <FormControl label={label} testid={testid} {...formControlProps}>
      <InputGroup>
        {inputLeftElement && <InputLeftElement height="100%" children={inputLeftElement} />}
        <Input
          type={isPassword ? (show ? 'text' : 'password') : type}
          data-testid={testid}
          minHeight="40px"
          backgroundColor="rgba(243, 243, 243, 0.50) !important"
          borderColor="transparent"
          borderRadius="xl"
          _focus={{ boxShadow: 'none', borderColor: 'rgb(0, 3, 16)' }}
          _invalid={{ boxShadow: 'none', borderColor: 'rgb(201, 0, 24)' }}
          {...inputProps}
        />
        {isPassword && (
          <InputRightElement
            cursor="pointer"
            role="button"
            width="3.5rem"
            height="100%"
            onClick={() => setShow(!show)}
          >
            <EyeIcon fontSize="22px" />
          </InputRightElement>
        )}
        {inputRightElement && (
          <InputRightElement width="3.5rem" height="100%">
            {inputRightElement}
          </InputRightElement>
        )}
      </InputGroup>
      {formHelperText}
    </FormControl>
  );
};

export type FormInputControlProps = InputControlProps & {
  name: string;
  fast?: boolean;
};

export const FormInputControl = (props: FormInputControlProps) => {
  const { name, formControlProps, fast = true, ...rest } = props;

  if (fast) {
    return (
      <FastField name={name}>
        {({ field, meta }) => (
          <InputControl
            {...rest}
            formControlProps={{
              ...meta,
              ...formControlProps,
            }}
            {...field}
          />
        )}
      </FastField>
    );
  } else {
    return (
      <Field name={name}>
        {({ field, meta }) => (
          <InputControl
            {...rest}
            formControlProps={{
              ...meta,
              ...formControlProps,
            }}
            {...field}
          />
        )}
      </Field>
    );
  }
};
