import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useConsultsWaitingRoomPatients } from '../../services/consults/queries/useConsultsWaitingRoomPatients';
import { AppState } from '../../store/root-reducers';
import { ConsultStatusEnum } from '../../generated';
import { setActiveConsult } from '../../store/activeConsult/activeConsultActions';

type UseProviderGetActiveConsultProps = {
  shouldRun?: boolean;
};

export const useProviderGetActiveConsult = ({
  shouldRun = true,
}: UseProviderGetActiveConsultProps) => {
  const dispatch = useDispatch();

  const { provider } = useSelector((state: AppState) => state.providerState);

  const { waitingRoomPatients } = useConsultsWaitingRoomPatients({
    providerID: provider?.providerID,
    options: {
      refetchInterval: shouldRun ? 30000 : 0,
      enabled: shouldRun,
    },
  });

  // Check for Active Consult
  useEffect(() => {
    if (!shouldRun) return;
    (waitingRoomPatients ?? []).forEach((x) => {
      if (
        x.consultStatusEnum === ConsultStatusEnum.ConsultReady ||
        x.consultStatusEnum === ConsultStatusEnum.InProgress
      ) {
        dispatch(setActiveConsult(x));
      }
    });
  }, [dispatch, shouldRun, waitingRoomPatients]);
};
