import { createStandaloneToast, UseToastOptions } from '@chakra-ui/react';
import { theme } from '../themes/theme';

export const successToastr = (options?: UseToastOptions | undefined) => {
  const toast = createStandaloneToast({ theme: theme });
  toast({
    status: 'success',
    position: 'top',
    ...options,
  });
};

export const errorToastr = (options?: UseToastOptions | undefined) => {
  const toast = createStandaloneToast({ theme: theme });
  toast({
    status: 'error',
    position: 'top',
    ...options,
  });
};
