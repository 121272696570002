import { Box, Modal, ModalContent, ModalOverlay, useBreakpointValue } from '@chakra-ui/react';
import { CenteredLoadingIndicator } from '../LoadingIndicator';
import ServerValidationBox from '../ServerValidationBox';
import { PdfViewer } from './PdfViewer';

interface IProps {
  pdf?: Blob | string;
  initialScale?: number;
  fileName?: string;
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  loadingError?: string;
}

export const PdfViewModal = ({
  isLoading,
  loadingError,
  pdf,
  initialScale,
  fileName,
  isOpen,
  onClose,
}: IProps) => {
  const fixedControls = useBreakpointValue({ base: true, sm: false });

  return (
    <Modal onClose={onClose} isOpen={isOpen} closeOnOverlayClick={true} closeOnEsc={true}>
      <ModalOverlay />
      <ModalContent
        maxWidth="unset"
        display="flex"
        alignItems={{ md: 'center' }}
        backgroundColor="transparent"
      >
        {isLoading ? (
          <CenteredLoadingIndicator />
        ) : (
          <>
            <Box position="relative" minWidth="320px">
              <ServerValidationBox message={loadingError} />
              <PdfViewer
                fixedControls={fixedControls}
                pdf={pdf}
                initialScale={initialScale}
                fileName={fileName}
                onClose={onClose}
              />
            </Box>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
