import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SystemMessageType } from '../constants';
import useInterval from '../hooks/useInterval';
import { AppState } from '../store/root-reducers';
import {
  dismissSystemMessage,
  getSystemMessages,
} from '../store/systemMessages/getSystemMessagesAction';
import { systemMessagesSelector } from '../store/systemMessages/systemMessagesSelector';
import SystemMessage from './SystemMessage';

const SystemMessagesBanner: React.FC = () => {
  const THIRTY_MINUTES = 1800000;
  const dispatch = useDispatch();
  const systemMessages = useSelector((state: AppState) =>
    systemMessagesSelector(state.systemMessagesState.systemMessages),
  );
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  useInterval(() => {
    dispatch(getSystemMessages());
  }, THIRTY_MINUTES);

  useEffect(() => {
    dispatch(getSystemMessages());
  }, [dispatch]);

  const markDismissed = (systemMessageID: string) => {
    dispatch(dismissSystemMessage(systemMessageID));
  };

  return (
    <div
      className={`system-messages-container ${
        systemMessages.length > 0 && currentUser ? 'system-messages-container--margin' : ''
      }`}
    >
      {systemMessages.length > 0 &&
        systemMessages.map((x, index) => (
          <SystemMessage
            key={index}
            isDismissed={x.isDismissed}
            messageType={SystemMessageType[x.systemMessage.messageType]}
            onDismiss={() => markDismissed(x.systemMessage.systemMessageID)}
            name={x.systemMessage.name}
            message={x.systemMessage.value}
          />
        ))}
    </div>
  );
};

export default SystemMessagesBanner;
