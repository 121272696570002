import { useState } from 'react';
import {
  ConsultProviderSearchBrowseEnum,
  ConsultProviderSearchStoreResult,
} from '../../../generated';
import { useLocationNotificationModal } from '../../../hooks/useLocationNotificationModal';
import { usePublicConsultSearch } from '../../../services/consults/queries/usePublicConsultSearch';

import { LatLng } from '../../heal/patient/BookAppointment/components/BookConsultProvider';

import { BookingFlowEnum } from '../components/PublicSearchComponents/enums';

import { UsePublicBookingFlowReturnType } from './usePublicBookingFlow';

type UseInPersonVisitProps = UsePublicBookingFlowReturnType;

const defaultCanadaCoords = {
  lat: 43.65107,
  lng: -79.347015,
};

const getUsablePosition = (
  selectedPosition?: google.maps.LatLng,
  currentPosition?: GeolocationCoordinates,
) => {
  const latLng: LatLng | undefined = undefined;

  if (!selectedPosition && !currentPosition) return latLng;

  if (selectedPosition) {
    return {
      lat: selectedPosition.lat(),
      lng: selectedPosition.lng(),
    };
  }

  if (currentPosition) {
    return {
      lat: currentPosition.latitude,
      lng: currentPosition.longitude,
    };
  }
};

const useInPersonVisit = (props: UseInPersonVisitProps) => {
  const {
    position,
    positionError,
    isNotificationModalOpen,
    onDismissNotificationModal,
    onDismissNotificationModalError,
    onRequestLocation,
    clearPosition,
  } = useLocationNotificationModal();

  const [selectedPosition, setSelectedPosition] = useState<google.maps.places.PlaceResult>();
  const [selectedClinicPosition, setSelectedClinicPosition] = useState<LatLng>();

  const {
    fetchNextPage: fetchNextPageCategory,
    hasNextPage: hasNextPageCategory,
    isFetchingNextPage: isFetchingNextPageCategory,
    isLoadingSearchResults,
    categoryError,
    categoryExist,
    category,
    searchResults,
  } = usePublicConsultSearch({
    countryId: props.bookingData.patientCountry?.countryID,
    storeItemTypeEnum: props.bookingData.storeItemTypeEnum,
    careTypeTags: [props.bookingData.selectedCareType],
    categoryToBrowseEnum: ConsultProviderSearchBrowseEnum.StoreClinic,
    categoryKey: 'clinics',
    currentLatitude: position?.coords.latitude || selectedPosition?.geometry?.location?.lat(),
    currentLongitude: position?.coords.longitude || selectedPosition?.geometry?.location?.lng(),
  });

  const latLng: LatLng = selectedClinicPosition ??
    selectedPosition?.geometry?.location?.toJSON() ?? {
      lat:
        position?.coords.latitude ||
        props.bookingData.patientCountry?.latitude ||
        defaultCanadaCoords.lat,
      lng:
        position?.coords.longitude ||
        props.bookingData.patientCountry?.longitude ||
        defaultCanadaCoords.lng,
    };

  const onGetCurrentLocation = () => {
    setSelectedPosition(undefined);
    setSelectedClinicPosition(undefined);
    onRequestLocation();
  };

  const onSelectPlace = (place?: google.maps.places.PlaceResult) => {
    clearPosition();
    setSelectedClinicPosition(undefined);
    setSelectedPosition(place);
  };

  const onSetClinicPosition = (latitude?: number, longitude?: number) => {
    if (!latitude || !longitude) return;

    const latLng: LatLng = {
      lat: latitude,
      lng: longitude,
    };

    setSelectedClinicPosition(latLng);
  };

  const handleNextStep = (selectedClinic: ConsultProviderSearchStoreResult) => {
    props.handleUpdateBookingData({
      latLng: getUsablePosition(selectedPosition?.geometry?.location, position?.coords),
      clinic: selectedClinic,
      appointmentsCategoryToBrowseEnum: ConsultProviderSearchBrowseEnum.Provider,
      bookingFlowStepEnum: BookingFlowEnum.Appointments,
    });
  };

  return {
    handleNextStep,
    position,
    positionError,
    isNotificationModalOpen,
    latLng,
    selectedPosition,
    onDismissNotificationModal,
    onDismissNotificationModalError,
    onGetCurrentLocation,
    clearPosition,
    onSelectPlace,
    fetchNextPage: fetchNextPageCategory,
    hasNextPage: hasNextPageCategory,
    isFetchingNextPage: isFetchingNextPageCategory,
    isLoadingSearchResults: isLoadingSearchResults,
    categoryError,
    categoryExist,
    category,
    searchResults,
    onSetClinicPosition,
  };
};

export { useInPersonVisit };
