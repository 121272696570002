import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { Formik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactSelect from 'react-select';
import { object, string } from 'yup';
import { CalendarIcon, JpgIcon, PdfIcon, User2Icon } from '../../../assets/icons';
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton';
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton';
import { Form, FormInputControl, FormMobisPicker, FormTextArea } from '../../../components/forms';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import ServerValidationBox from '../../../components/ServerValidationBox';
import { ButtonSizeEnum, TimeFormatEnum } from '../../../constants';
import {
  AuthClient,
  FileDetailsClient,
  IPHRDocument,
  PHRDocument,
  PHRDocumentsClient,
} from '../../../generated';
import { getAllForPhrSection } from '../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { httpRequest } from '../../../utils';
import { ButtonRow } from '../components/StyledPhrItems';
import { ITagSelect } from './Documents';

interface IProps {
  currentPhrDocument: IPHRDocument;
  toggleView: () => void;
  patientID: string;
  tags?: ITagSelect[];
}

interface IForm {
  name: string;
  notes: string;
  tag?: string;
  documentDate: string | Date;
}

const DocumentsEditForm = ({ currentPhrDocument, toggleView, patientID, tags }: IProps) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const updateDocument = async (values) => {
    setLoading(true);
    const phrDocumentsClient = new PHRDocumentsClient(new AuthClient());
    const fileDetailsClient = new FileDetailsClient(new AuthClient());
    try {
      const currentDocument = new PHRDocument();
      currentDocument.init(currentPhrDocument);
      currentDocument.notes = values.notes;
      currentDocument.documentTypeTagID = values.tag;
      currentDocument.documentDate = values.documentDate;
      await httpRequest(() => phrDocumentsClient.pHRDocumentsPut(currentDocument));

      if (currentDocument!.fileDetail!.name.split('.').slice(0, -1).join('.') !== values.name) {
        const fileExtension = currentDocument!.fileDetail!.name.substring(
          currentDocument!.fileDetail!.name.lastIndexOf('.') + 1,
        );
        currentDocument!.fileDetail!.name = `${values.name}.${fileExtension}`;

        try {
          await httpRequest(() => fileDetailsClient.fileDetailsPut(currentDocument!.fileDetail!));
        } catch (err) {
          setError(err as string);
        }
      }
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRDocuments, patientID));

      toggleView();
    } catch (err) {
      setError(err as string);
      setLoading(false);
    }
  };

  const initialSelect = currentPhrDocument.documentTypeTagID
    ? tags?.find(({ value }) => value === currentPhrDocument.documentTypeTagID)
    : {};

  const initialValues: IForm = {
    name: currentPhrDocument.fileDetail?.name.split('.').slice(0, -1).join('.') || '',
    notes: currentPhrDocument.notes || '',
    tag: currentPhrDocument.documentTypeTagID || '',
    documentDate: moment(currentPhrDocument.documentDate).toDate() || '',
  };

  return (
    <Box m="1rem" height={{ base: 'calc(100vh - 4rem)', md: '600px' }}>
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          name: string()
            .max(128, 'File name must be at most 128 characters')
            .matches(/^[a-zA-Z0-9_.-]*$/, 'File name can only contain A-Z, a-z, 0-9 and -.')
            .required('A file name is required'),
          notes: string().max(1024, 'Notes must be at most 1024 characters'),
          tag: string().required('A file category is required'),
          documentDate: string().required('Document date is required'),
        })}
        onSubmit={updateDocument}
      >
        {({ status, setFieldValue, errors }) => (
          <Form error={error}>
            {isLoading ? (
              <CenteredLoadingIndicator />
            ) : (
              <>
                <ServerValidationBox message={status} />
                <div className="row">
                  <div className="col-12">
                    <Box
                      p="5"
                      borderRadius="1"
                      background="#F7F9FC"
                      border="1px solid #E4E9F2"
                      mb="2"
                    >
                      <HStack spacing="3" align="center">
                        {currentPhrDocument.fileDetail?.contentType === 'application/pdf' ? (
                          <PdfIcon />
                        ) : (
                          <JpgIcon />
                        )}
                        <Text fontSize="sm">{currentPhrDocument.fileDetail?.name}</Text>
                      </HStack>
                    </Box>
                  </div>
                </div>
                <Flex justify="space-between" m="20px 0px" direction={['column', 'row']}>
                  <Box w={['100%', '49%']}>
                    <FormInputControl type="text" name="name" label="File Name" />
                  </Box>
                  <Box w={['100%', '49%']}>
                    <Text fontSize="sm" mb="2">
                      Document Category
                    </Text>
                    <ReactSelect
                      name="tag"
                      options={tags}
                      defaultValue={initialSelect}
                      onChange={(e) => {
                        setFieldValue('tag', e?.value);
                      }}
                      placeholder="Select Category"
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          fontSize: '14px',
                          color: state.isSelected || state.isFocused ? '#3E1191' : '#666',
                          background: state.isSelected || state.isFocused ? '#dfd0ff' : '#F7F9FC',
                        }),
                        singleValue: (provided) => {
                          const transition = 'opacity 300ms';
                          return {
                            ...provided,
                            color: '#666',
                            transition,
                            padding: '10px',
                            fontSize: '14px',
                          };
                        },
                        control: (styles, { isFocused }) => ({
                          ...styles,
                          color: '#333',
                          fontSize: '14px',
                          minHeight: '40px',
                          borderColor: isFocused ? 'none !important' : '#E4E9F2',
                          background: '#f7f9fc',
                        }),
                        menu: (styles) => ({
                          ...styles,
                          zIndex: 99,
                        }),
                      }}
                    />
                    <Text fontSize="sm" mt="1" color="red.400">
                      {errors.tag}
                    </Text>
                  </Box>
                </Flex>

                <Flex justify="space-between" m="20px 0px" direction={['column', 'row']}>
                  <Box w={['100%', '100%']}>
                    <FormMobisPicker
                      min={moment().toDate()}
                      name="documentDate"
                      label="Document date"
                    />
                    {/* <FormDateTimePicker
                      minDate={moment().toDate()}
                      name="documentDate"
                      label="Document date"
                      dateFormat={'MM/dd/yyyy'}
                      showTimeSelect={false}
                    /> */}
                  </Box>
                </Flex>

                <div className="row">
                  <div className="col-12">
                    <FormTextArea name="notes" label="Notes" />

                    <Flex p="2" mt="2" flexDirection={['column', 'row']} justify="space-between">
                      <Box w="100%">
                        {currentPhrDocument.createdByName && (
                          <Flex
                            p={2}
                            w={['100%', '100%']}
                            background="#dfd0ff40"
                            direction={['column', 'row']}
                            mb={3}
                          >
                            <Text>
                              <User2Icon color="#3E1191" />
                              <Text
                                fontWeight="600"
                                ml={1}
                                fontSize="xs"
                                color="gray.700"
                                as="span"
                              >
                                Created By:
                              </Text>
                            </Text>
                            <Text fontSize="xs" mt={[0, 1]} ml={[5, 4]}>
                              {' '}
                              {currentPhrDocument.createdByName}
                            </Text>
                          </Flex>
                        )}

                        {currentPhrDocument.createdDate && (
                          <Flex
                            p={2}
                            w={['100%', '100%']}
                            background="#dfd0ff40"
                            direction={['column', 'row']}
                            mb={3}
                          >
                            <Text>
                              <CalendarIcon color="#3E1191" />
                              <Text
                                fontWeight="600"
                                ml={1}
                                fontSize="xs"
                                color="gray.700"
                                as="span"
                              >
                                Created Date:
                              </Text>
                            </Text>
                            <Text fontSize="xs" mt={[0, 1]} ml={[5, 4]}>
                              {' '}
                              {moment(currentPhrDocument.createdDate).format(
                                TimeFormatEnum.LONG_DATE_TIME,
                              )}
                            </Text>
                          </Flex>
                        )}
                      </Box>

                      <Box w="100%">
                        {currentPhrDocument.updatedByName && (
                          <Flex
                            p={2}
                            w={['100%', '100%']}
                            background="#dfd0ff40"
                            direction={['column', 'row']}
                            mb={3}
                          >
                            <Text>
                              <User2Icon color="#3E1191" />
                              <Text
                                fontWeight="600"
                                ml={1}
                                fontSize="xs"
                                color="gray.700"
                                as="span"
                              >
                                Created By:
                              </Text>
                            </Text>
                            <Text fontSize="xs" mt={[0, 1]} ml={[5, 4]}>
                              {' '}
                              {currentPhrDocument.updatedByName}
                            </Text>
                          </Flex>
                        )}

                        {currentPhrDocument.updatedDate && (
                          <Flex
                            p={2}
                            w={['100%', '100%']}
                            background="#dfd0ff40"
                            direction={['column', 'row']}
                            mb={3}
                          >
                            <Text>
                              <CalendarIcon color="#3E1191" />
                              <Text
                                fontWeight="600"
                                ml={1}
                                fontSize="xs"
                                color="gray.700"
                                as="span"
                              >
                                Created Date:
                              </Text>
                            </Text>
                            <Text fontSize="xs" mt={[0, 1]} ml={[5, 4]}>
                              {' '}
                              {moment(currentPhrDocument.updatedDate).format(
                                TimeFormatEnum.LONG_DATE_TIME,
                              )}
                            </Text>
                          </Flex>
                        )}
                      </Box>
                    </Flex>
                  </div>
                </div>
                <ButtonRow>
                  <SecondaryButton size={ButtonSizeEnum.medium} onClick={toggleView}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton size={ButtonSizeEnum.medium} type="submit">
                    Save
                  </PrimaryButton>
                </ButtonRow>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default DocumentsEditForm;
