import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { MapMarkerIcon } from '../../../../assets/icons';

import { OutlineButton, PrimaryButton } from '../../../../components/Buttons';
import { Avatar } from '../../../../components/common/Avatar';
import { ThumbnailTypeEnum } from '../../../../constants';
import { OutpostTheme } from '../../../../themes/outpost';
import { getUserThumbnail } from '../../../../utils/getUserThumbnail';
import { CustomMenuAccordion } from '../../../layouts/Sidemenu/components/CustomMenuAccordion';

interface ClinicInfoProps {
  clinic: {
    name: string;
    description: string;
    fullAddress: string;
    imageDetailID?: string;
    cityTown?: string;
    provinceState?: string;
    postCode?: string;
    distance?: string;
  };
  onSelectClinic: () => void;
  onGetPosition?: () => void;
}

const ClinicInfo: FC<ClinicInfoProps> = (props) => {
  return (
    <HStack spacing="3" alignItems="flex-start">
      <Avatar
        size={OutpostTheme.Avatar.Size.Large}
        src={getUserThumbnail(props.clinic.imageDetailID, ThumbnailTypeEnum.Clinic)}
      />
      <Box flex="1">
        <Text color="rgb(4, 16, 31)" fontWeight="semibold" fontSize="14px">
          {props.clinic.name}
        </Text>
        <Text color="rgb(89, 101, 120)" fontSize="14px">
          {props.clinic.description}
        </Text>
        <HStack spacing="2" mt="1" mb="2" alignItems="center">
          <Text color="rgb(89, 101, 120)" fontSize="14px">
            {props.clinic.fullAddress}{' '}
            {[props.clinic.cityTown, props.clinic.provinceState, props.clinic.postCode].join(' ')}
          </Text>
        </HStack>
        {props.clinic.distance && (
          <Text align="left" fontSize="14px" mb="2" color="rgb(89, 101, 120)">
            Distance:{' '}
            <Text as="strong" color="rgb(4, 16, 31)">
              {props.clinic.distance}
            </Text>
          </Text>
        )}

        {/* <Flex alignItems="flex-end" justifyContent="flex-end"> */}
        <CustomMenuAccordion
          Icon={ChevronDownIcon}
          text="view more+"
          iconPosition="right"
          bgColor="transparent"
          children={
            <HStack justifyContent="space-between">
              <PrimaryButton borderRadius="lg" size="sm" onClick={props.onSelectClinic}>
                Select
              </PrimaryButton>

              <OutlineButton
                minWidth="50px"
                buttonColor="green.600"
                onClick={props.onGetPosition}
                textDecoration="none"
                borderRadius="lg"
                bgColor="white"
                size="sm"
              >
                Locate
                <Box as="span" ml="1">
                  <MapMarkerIcon />
                </Box>
              </OutlineButton>
            </HStack>
          }
        />
        {/* </Flex> */}
      </Box>
    </HStack>
  );
};

export { ClinicInfo };
