import { Box, ModalBody, useDisclosure, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { object } from 'yup';
import { PrimaryButton } from '../../../components/Buttons';
import { FormInputControl } from '../../../components/forms';
import { BaseModal, HeaderSection } from '../../../components/Modals';
import ServerValidationBox from '../../../components/ServerValidationBox';
import {
  AuthClient,
  ChangePasswordDetails,
  ResetPasswordEnum,
  SecurityClient,
} from '../../../generated';
import { useMixTrack } from '../../../lib/mixpanel/mixpanelUtils';
import { AppState } from '../../../store/root-reducers';
import { httpRequest } from '../../../utils';
import { successToastr } from '../../../utils/toastr';
import { checkConfirmPassword, checkPassword } from '../../../utils/yupHelper';
import PasswordRequirmentsText from '../../Unauthenticated/components/PasswordRequirementsText';

type InitialPasswordValuesType = {
  password: string;
  confirmPassword: string;
};

const ChangePasswordModal: FC = () => {
  const patient = useSelector((state: AppState) => state.patientState.patient);
  const provider = useSelector((state: AppState) => state.providerState.provider);
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { mixTrack } = useMixTrack();

  const {
    isOpen: isResetPasswordOpen,
    onOpen: onOpenResetPassword,
    onClose: onCloseResetPassword,
  } = useDisclosure();

  const onSaveNewPassword = async (values: InitialPasswordValuesType) => {
    const authClient = new AuthClient();
    const securityClient = new SecurityClient(authClient);

    try {
      setIsLoading(true);
      setError('');

      const changePasswordDetails = new ChangePasswordDetails();

      changePasswordDetails.newPassword = values.password;

      await httpRequest(() => securityClient.changePassword(changePasswordDetails));

      mixTrack({
        eventName: 'reset_password:new_password_result_success',
      });

      successToastr({
        description: 'Password changed successfully!',
      });

      onCloseResetPassword();
    } catch (err) {
      mixTrack({
        eventName: 'reset_password:new_password_result_failed',
      });
      setError((err as any).split('. ').join('.<br/>'));
    } finally {
      setIsLoading(false);
    }
  };

  // Check if patient needs to reset password
  useEffect(() => {
    const needsToResetPassword =
      patient?.userDetail?.resetPasswordEnum === ResetPasswordEnum.NewAccount;

    if (needsToResetPassword) {
      onOpenResetPassword();
    }
  }, [onOpenResetPassword, patient?.userDetail?.resetPasswordEnum]);

  // Check if provider needs to reset password
  useEffect(() => {
    const needsToResetPassword =
      provider?.userDetail?.resetPasswordEnum === ResetPasswordEnum.NewAccount;

    if (needsToResetPassword) {
      onOpenResetPassword();
    }
  }, [onOpenResetPassword, provider?.userDetail?.resetPasswordEnum]);

  return (
    <BaseModal isOpen={isResetPasswordOpen} size="xl">
      <ModalBody overflowY="auto">
        <VStack height="100%" alignItems="stretch" spacing="0">
          <HeaderSection
            heading="Please enter a secured password"
            containerProps={{
              mb: '6',
            }}
            noPrevious
            description="You need to set a new password for your account to continue"
          />
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
            validationSchema={object({
              password: checkPassword,
              confirmPassword: checkConfirmPassword,
            })}
            onSubmit={(values) => {
              onSaveNewPassword(values);
            }}
          >
            {({ values }) => (
              <Form>
                <VStack flex="1" justifyContent="center" alignItems="stretch">
                  <ServerValidationBox message={error} />

                  <VStack spacing="6" alignItems="center">
                    <Box width="100%" maxWidth="400px">
                      <FormInputControl name="password" type="password" label="Password" />
                      <Box pb="2">
                        <PasswordRequirmentsText password={values.password} />
                      </Box>
                      <FormInputControl
                        name="confirmPassword"
                        type="password"
                        label="Confirm Password"
                      />
                    </Box>
                    <Box>
                      <PrimaryButton isLoading={isLoading} type="submit">
                        Save
                      </PrimaryButton>
                    </Box>
                  </VStack>
                </VStack>
              </Form>
            )}
          </Formik>
        </VStack>
      </ModalBody>
    </BaseModal>
  );
};

export { ChangePasswordModal };
