import { Box } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import useTranslationComponent, { languages } from '../hooks/useTranslationComponent';
import ReactSelect from 'react-select';
import { getReactSelectStyle } from '../constants';

const LanguageToggle: FC = () => {
  const { i18n } = useTranslationComponent();

  const changeLanguage = (e: any) => {
    i18n.changeLanguage(e.value);
  };

  return (
    <Box width="85px" height="50px" display="flex" alignItems="center">
      <FontAwesomeIcon style={{ marginRight: '8px' }} icon={['fal', 'globe']} />

      <ReactSelect
        defaultValue={{ label: i18n.language, value: i18n.language }}
        isClearable={false}
        isSearchable={false}
        placeholder="LNG"
        styles={{
          ...getReactSelectStyle(),
          control: (style) => ({
            ...style,
            width: '65px',
            border: 'none',
            padding: 0,
            margin: 0,
            outline: 'none',
            boxShadow: 'none',
            '&active': {
              outline: 'none',
              border: 0,
              boxShadow: 'none',
            },
          }),
          valueContainer: (style) => ({
            ...style,
            padding: 0,
            margin: 0,
          }),
        }}
        options={languages}
        onChange={changeLanguage}
        getOptionLabel={(option) => option?.label?.toUpperCase()}
      />
    </Box>
  );
};

export default LanguageToggle;
