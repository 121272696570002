import { Box, Button, Flex, Link, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { EyeIcon } from '../../../assets/icons';
import { TimeFormatEnum } from '../../../constants';
import {
  ConsultTagTypeEnum,
  Country,
  PHRConsult,
  PHRDocument,
  PHRDocumentCategoryEnum,
  RolesEnum,
} from '../../../generated';
import { AppState } from '../../../store/root-reducers';
import { getTime } from '../../../utils';
import { displayValue } from '../../../utils/displayValue';
import { isInRole } from '../../../utils/isInRole';

const ListItem = ({ title, description }) => (
  <Flex w="100%" m={1}>
    <Box w="30%">
      <Text variant="body">{title}</Text>
    </Box>
    <Box>{description}</Box>
  </Flex>
);

const renderTags = (phrConsult: PHRConsult, consultTagTypeEnum: ConsultTagTypeEnum) => {
  //TODO: Change these to theme
  return phrConsult.phrConsultTags
    ?.filter((y) => y.consultTagTypeEnum === consultTagTypeEnum)
    .map((z) => (
      <Box
        backgroundColor="#F4F6FA"
        color="#1B3D71"
        key={z.phrConsultTagID}
        p={1}
        px={2}
        borderRadius="full"
        fontSize="12px"
      >
        {z.tag?.description}
      </Box>
    ));
};

interface IProps {
  isDownloading: boolean;
  phrConsult: PHRConsult;
  countries: Country[] | undefined;
  downloadChatTranscript: (phrConsult: PHRConsult) => Promise<void>;
  handleView: (document: PHRDocument) => void;
  download: (patientID: string, phrDocumentID: string) => Promise<void>;
  onView: (phrConsult: PHRConsult) => void;
}

export const ConsultDetailBody = ({
  isDownloading,
  phrConsult,
  countries,
  downloadChatTranscript,
  handleView,
  download,
}: IProps) => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  return (
    <>
      <Box
        transition=".2s ease"
        p={4}
        overflow="hidden"
        background={'#e2f3f1'}
        mt={4}
        borderRadius="18px"
      >
        <ListItem
          title="Patient Location"
          description={
            <Text variant="body">
              {phrConsult.patientCountryID
                ? (countries ?? []).find((y) => y.countryID === phrConsult.patientCountryID)?.name
                : phrConsult.country?.name}
            </Text>
          }
        />

        <ListItem
          title={isInRole(currentUser, RolesEnum.Patient) ? 'Complaints' : 'Subjective'}
          description={
            <Text variant="body">
              {phrConsult.subjective}
              {renderTags(phrConsult, ConsultTagTypeEnum.Subjective)}
            </Text>
          }
        />
        <ListItem
          title={isInRole(currentUser, RolesEnum.Patient) ? 'What we found' : 'Objective'}
          description={
            <Text variant="body">
              {phrConsult.objective}
              {renderTags(phrConsult, ConsultTagTypeEnum.Objective)}
            </Text>
          }
        />
        <ListItem
          title={isInRole(currentUser, RolesEnum.Patient) ? 'Diagnosis' : 'Diagnosis Notes'}
          description={
            <Text variant="body">
              {phrConsult.diagnosis}
              {renderTags(phrConsult, ConsultTagTypeEnum.Diagnosis)}
            </Text>
          }
        />
        <ListItem
          title={isInRole(currentUser, RolesEnum.Patient) ? 'What you should do now' : 'Plan'}
          description={
            <Text variant="body">
              {phrConsult.plan}
              {renderTags(phrConsult, ConsultTagTypeEnum.Plan)}
            </Text>
          }
        />
        <ListItem
          title="Reason"
          description={
            <Text variant="body">
              {phrConsult.complaint}
              {renderTags(phrConsult, ConsultTagTypeEnum.Complaint)}
            </Text>
          }
        />
        <ListItem
          title="Consult Details"
          description={
            <Button
              variant="link"
              fontSize={'14px'}
              onClick={() => downloadChatTranscript(phrConsult)}
              isLoading={isDownloading}
              rightIcon={<FontAwesomeIcon icon={['far', 'cloud-download']} />}
              color="#000"
            >
              Download
            </Button>
          }
        />
        {phrConsult.isCancelled && (
          <ListItem
            title="Cancel Reason"
            description={<Text variant="body">{phrConsult.consult?.cancelReason}</Text>}
          />
        )}
        <ListItem
          title="Consult Documents"
          description={
            <Text variant="body">
              {(phrConsult.phrDocuments?.length ?? 0) === 0 ? (
                <span>-</span>
              ) : (
                phrConsult.phrDocuments
                  ?.sort((a, b) => getTime(b.upsertDate) - getTime(a.upsertDate))
                  .filter(
                    (x) =>
                      (isInRole(currentUser, RolesEnum.Patient) &&
                        x.phrDocumentCategoryEnum !== PHRDocumentCategoryEnum.PrescriptionNote) ||
                      isInRole(currentUser, RolesEnum.Provider),
                  )
                  .map((x) => (
                    <React.Fragment key={x.phrDocumentID}>
                      <Link onClick={() => handleView(x)}>
                        {x.fileDetail?.name} <EyeIcon />
                      </Link>
                      <br />
                      <Button
                        variant="link"
                        fontSize={'14px'}
                        onClick={() => download(x.patientID, x.phrDocumentID)}
                        rightIcon={<FontAwesomeIcon icon={['far', 'cloud-download']} />}
                      >
                        Download
                      </Button>
                      <br />
                    </React.Fragment>
                  ))
              )}
            </Text>
          }
        />

        {isInRole(currentUser, RolesEnum.Provider) && phrConsult.attestationUserDetailID && (
          <>
            <ListItem
              title={phrConsult.isAttested ? 'Attested By' : 'To Be Attested By'}
              description={
                <Text variant="body">{displayValue(phrConsult.attestationProvider?.fullName)}</Text>
              }
            />
            <ListItem
              title="Attestation Date"
              description={
                <Text variant="body">
                  {phrConsult.attestationDate
                    ? moment(phrConsult.attestationDate).format(TimeFormatEnum.LONG_DATE_TIME)
                    : '-'}
                </Text>
              }
            />
            <ListItem
              title="Attestation"
              description={<Text variant="body">{displayValue(phrConsult.attestation)}</Text>}
            />
          </>
        )}
      </Box>
    </>
  );
};
