import { IconButton, Input, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import useChat from '../../../../../components/twilio/hooks/useChat/useChat';
import useChatContext from '../../../../../components/twilio/hooks/useChatContext/useChatContext';
import { parseParticipantIdentity } from '../../../../../components/twilio/utils';
import {
  AuthClient,
  ConsultChat as ConsultChatMessage,
  ConsultChatsClient,
  ConsultStatusEnum,
} from '../../../../../generated';
import { AppState } from '../../../../../store/root-reducers';
import { OutpostTheme } from '../../../../../themes/outpost';
import ChatMessage from './ChatMessage';

interface IProps {
  token?: string;
  channelUniqueName: string;
  consultID: string;
  consultStatus: ConsultStatusEnum;
}

const ConsultChat = React.memo(({ token, channelUniqueName, consultID, consultStatus }: IProps) => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data!);
  const { setMessageCount } = useChatContext();
  const scrollbarRef = useRef(null);
  const [message, setMessage] = useState<string>('');
  const MAX_LENGTH = 1000;

  // Bottom Ref, used to scroll to everytime a new message comes in
  const scrollToElementRef = useRef<HTMLElement | null>(null);

  const updateMessage = (message: string) => {
    sendTypingIndicator();
    setMessage(message);
  };

  const { isConnected, memberColors, isTypingMessage, sendMessage, sendTypingIndicator, messages } =
    useChat({
      token: token,
      uniqueName: channelUniqueName,
      userDetailID: currentUser.userDetailID,
      consultStatus: consultStatus,
    });

  const archiveMessage = async (message) => {
    if (message) {
      const consultChatsClient = new ConsultChatsClient(new AuthClient());
      try {
        const consultChat = new ConsultChatMessage();
        consultChat.message = message;
        consultChat.consultID = consultID;
        consultChat.messageDate = new Date();
        consultChat.userDetailID = currentUser!.userDetailID;
        consultChatsClient.consultChatsPost(consultChat);
      } catch (err) {
        // don't do anything
      }
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const trimmedMessage = message.trim();
    if (trimmedMessage.length > 0) {
      sendMessage(trimmedMessage);
      archiveMessage(trimmedMessage);
    }
    setMessage('');
  };

  const characterCountColor = () => {
    let color = '';
    const charactersLeft = MAX_LENGTH - message.length;
    if (charactersLeft === 0) {
      color = '#f1624e';
    } else if (charactersLeft <= 25) {
      color = '#f06e1d';
    } else if (charactersLeft <= 250) {
      color = '#46aa78;';
    }

    return color;
  };

  // Scroll to latest Message
  useEffect(() => {
    if (scrollbarRef && scrollbarRef.current && messages.length > 0) {
      // @ts-ignore
      const scrollHeight = scrollbarRef.current.getScrollHeight();
      // @ts-ignore
      scrollbarRef.current.scrollTop(scrollHeight);
    }
  }, [messages, isTypingMessage]);

  // Update Parents Total Message Count
  useEffect(() => {
    if (setMessageCount) {
      setMessageCount(messages.length);
    }
  }, [messages, setMessageCount]);

  return (
    <>
      <MessageHolder>
        <StyledScrollbar ref={scrollbarRef}>
          <InnerMessageContainer>
            <div>
              <Text mt={4} textAlign="center" color="#1F3F4D8C" fontSize="12px">
                A transcript of this conversation will be sent to you after the consult.
              </Text>
              {messages.map((m) => {
                const { userDetailID, fullName, imageDetailID } = parseParticipantIdentity(
                  m.author || '',
                );
                return (
                  <ChatMessage
                    key={m.sid}
                    sent={userDetailID === currentUser.userDetailID}
                    fullName={fullName}
                    color={memberColors[m.participantSid || '']}
                    imageDetailID={imageDetailID}
                    timestamp={m.dateCreated?.valueOf() || 0}
                    message={m.body || ''}
                  />
                );
              })}
            </div>
            <UserIsTyping ref={scrollToElementRef}>{isTypingMessage}</UserIsTyping>
          </InnerMessageContainer>
        </StyledScrollbar>
      </MessageHolder>
      <SendForm onSubmit={onSubmit} disabled={!isConnected}>
        <Input
          height="48px"
          p={3}
          color="black"
          backgroundColor="#f0f4f7"
          borderRadius="20px"
          disabled={!isConnected}
          title={!isConnected ? 'Not connected.' : ''}
          placeholder="Type Something..."
          value={message}
          fontSize={OutpostTheme.FontSizes.body}
          onChange={(e) => updateMessage(e.target.value)}
          maxLength={1000}
          border="none"
          mr={4}
        />
        {/* <SendTextArea
          placeholder="Type your message..."
          disabled={!isConnected}
          type="text"
          value={message}
          onChange={(e) => updateMessage(e.target.value)}
          maxLength={1000}
        /> */}
        <IconButton
          width="44px"
          height="44px"
          minWidth="44px"
          borderRadius="full"
          backgroundColor="purple.500"
          aria-label="send message"
          fontSize="20px"
          icon={<FontAwesomeIcon color="white" icon={['far', 'paper-plane']} />}
          type="submit"
          disabled={!isConnected}
          title={!isConnected ? 'Not connected.' : ''}
        />
        <CharacterCount color={characterCountColor()}>{MAX_LENGTH - message.length}</CharacterCount>
      </SendForm>
    </>
  );
});

export default ConsultChat;

const MessageHolder = styled.div`
  flex: 1;
  height: 200px;
`;

interface ISendFormProps {
  readonly disabled: boolean;
}

const SendForm = styled.form<ISendFormProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  background-color: ${(props) =>
    props.disabled ? `${(props) => props.theme.Colors.BackgroundColor}` : 'transparent'};
`;

const CharacterCount = styled.span`
  font-size: 12px;
  position: absolute;
  bottom: 0px;
  right: 62px;
  color: ${(props) => props.color};
  display: ${(props) => (props.color ? 'block' : 'none')};
`;

const InnerMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;

  > div {
    padding-right: 17px;
  }
`;

interface IUserIsTypingProps {
  ref: any;
}

const UserIsTyping = styled.div<IUserIsTypingProps>`
  position: relative;
  bottom: 20px;
  margin-top: 20px;
`;

const StyledScrollbar = styled(Scrollbars)`
  > div:first-child {
    overflow-x: hidden !important;
  }
`;
