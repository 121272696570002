import { PatientOrderItem } from '../../../../generated';

const getFreshPatientOrderItem = (patientOrderItem: PatientOrderItem) => {
  const newPatientOrderItem: Partial<PatientOrderItem> = {
    totalAmount: patientOrderItem.totalAmount,
    testDescription: patientOrderItem.testDescription,
    additionalInformation: patientOrderItem.additionalInformation,
    orderItem: patientOrderItem.orderItem,
    orderItemID: patientOrderItem.orderItemID,
  };

  return newPatientOrderItem;
};

export { getFreshPatientOrderItem };
