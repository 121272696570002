import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton';
import { Form, FormTextArea } from '../../../components/forms';
import { CountrySelect, FormSelectComponent } from '../../../components/forms/Selects';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import Modal from '../../../components/Modals/Modal';
import ServerValidationBox from '../../../components/ServerValidationBox';
import { AppFeatures } from '../../../constants';
import {
  AuthClient,
  Country,
  GenerateDocumentsClient,
  NoteTypeEnum,
  PHRNote,
  Provider,
  SendToEnum,
  VisibilityEnum,
} from '../../../generated';
import { useCountriesAll } from '../../../services/countries/useCountriesAll';
import { getAppFeatures } from '../../../store/appFeatures/appFeaturesCreators';
import { IPHRPatientCombined } from '../../../store/patient/phr/phrReducers';
import { createForPhrSection, getAllForPhrSection } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { enumToOptions, httpRequest } from '../../../utils';
import { scrollToTop } from '../../../utils/scrollToTop';
import { ButtonRow, FormCard } from '../components/StyledPhrItems';

interface IForm {
  noteTypeEnum: NoteTypeEnum | string;
  notes: string;
  addSignature: boolean;
}

interface IProps {
  phrPatient: IPHRPatientCombined;
  toggleView: () => void;
  phrConsultID?: string;
  isInConsult?: boolean;
  selectedPatientCountry?: Country;
  setSelectedPatientCountry?: (country: Country) => void;
}

const NotesForm = ({
  phrPatient,
  toggleView,
  phrConsultID,
  isInConsult,
  selectedPatientCountry,
  setSelectedPatientCountry,
}: IProps) => {
  const dispatch = useDispatch();
  const appFeatures = useSelector((state: AppState) => state.appFeaturesState.appFeatures);
  const { isLoadingCountries, countries } = useCountriesAll();
  const { error: patientError, isPhrLoading } = useSelector(
    (state: AppState) => state.phrPatientState,
  );
  const [generateError, setGenerateError] = useState<string>('');
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [createdPhrNote, setPhrCreatedNote] = useState<PHRNote>();
  const provider = useSelector((state: AppState) => state.providerState.provider);
  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(!isInConsult);
  const [, setCanSendToPatient] = useState<boolean>(false);

  const submit = async (values: IForm) => {
    if (values.noteTypeEnum === NoteTypeEnum.Referral && !selectedProvider) {
      setGenerateError('You must select a provider to send this referral to');
      scrollToTop();
    } else {
      setGenerateError('');
      setSubmitting(true);
      const phrNote = new PHRNote();
      phrNote.noteTypeEnum = NoteTypeEnum[values.noteTypeEnum.replaceAll(' ', '')];
      phrNote.notes = values.notes;

      if (phrNote.noteTypeEnum === NoteTypeEnum.Referral) {
        phrNote.sendSubject = `Patient Referral - ${phrPatient.patient.fullName}`;
        phrNote.sendMessage = values.notes;
      }

      phrNote.sendToEnum = SendToEnum.UserDetail;
      if (selectedProvider) {
        phrNote.sendToID = selectedProvider.userDetailID;
      } else {
        phrNote.sendToID = phrPatient.patient.userDetailID;
      }
      phrNote.patientID = phrPatient.patient.patientID;
      phrNote.addSignature = values.addSignature;
      phrNote.phrConsultID = phrConsultID;
      phrNote.visibilityEnum = VisibilityEnum.PatientAndProvider;

      const createdNote = await dispatch(
        createForPhrSection(ReduxPHRCategoryEnum.PHRNotes, phrNote, phrNote.patientID, false),
      );
      // @ts-ignore
      setPhrCreatedNote(createdNote);
    }
  };

  useEffect(() => {
    const generateNote = async (patientID: string, phrNoteID: string) => {
      const generateNotesClient = new GenerateDocumentsClient(new AuthClient(''));
      try {
        await httpRequest(() => generateNotesClient.generateNote(patientID, phrNoteID));
      } catch (err) {
        setGenerateError(err as string);
        setSubmitting(false);
      }

      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRNotes, phrPatient.patient.patientID));
      setIsOpen(false);
      toggleView();
    };

    if (isSubmitting && !isPhrLoading && createdPhrNote) {
      generateNote(createdPhrNote.patientID, createdPhrNote.phrNoteID);
    }
  }, [
    isSubmitting,
    createdPhrNote,
    isPhrLoading,
    dispatch,
    phrPatient.patient.patientID,
    toggleView,
  ]);

  useEffect(() => {
    if (selectedProvider) {
      setGenerateError('');
    }
  }, [selectedProvider]);

  useEffect(() => {
    if (appFeatures.length > 0) {
      const patientMessagingEnabled = appFeatures.find(
        (x) => x.name === AppFeatures.PatientMessagingEnabled,
      );

      if (patientMessagingEnabled?.enabled) {
        setCanSendToPatient(true);
      }
    } else {
      dispatch(getAppFeatures());
    }
  }, [appFeatures, dispatch, setCanSendToPatient]);

  const renderForm = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          noteTypeEnum: '',
          notes: '',
          addSignature: provider?.signatureImageDetailID ? true : false,
        }}
        validationSchema={object({
          noteTypeEnum: string().required('Select a Note Type'),
          notes: string().required('Notes are required'),
        })}
        onSubmit={submit}
      >
        {({ status, setFieldValue }) => (
          <Form error={patientError || generateError}>
            {isSubmitting || isPhrLoading ? (
              <CenteredLoadingIndicator />
            ) : (
              <div>
                <ServerValidationBox message={status} />
                {setSelectedPatientCountry && (
                  <div className="row">
                    <div className="col-12">
                      <CountrySelect
                        name="countryID"
                        value={selectedPatientCountry}
                        options={countries ?? []}
                        label="Where is the patient currently located?"
                        valueKey="countryID"
                        labelKey="name"
                        onChange={(val) => setSelectedPatientCountry(val)}
                        isClearable={false}
                        isLoading={isLoadingCountries}
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-12">
                    <FormSelectComponent
                      name="noteTypeEnum"
                      options={enumToOptions(NoteTypeEnum).filter(
                        (noteType) => noteType.id !== NoteTypeEnum.Referral,
                      )}
                      label="Note Type"
                      valueKey="id"
                      labelKey="description"
                      menuPortalTarget={isInConsult ? document.body : null}
                      onChange={(val) => {
                        if (val !== NoteTypeEnum.Referral && selectedProvider) {
                          setSelectedProvider(null);
                          setGenerateError('');
                          setFieldValue('noteTypeEnum', val);
                        }
                      }}
                    />
                  </div>
                </div>
                <FormTextArea
                  height="219px"
                  borderRadius="xl"
                  mb={8}
                  resize="none"
                  placeholder="Enter reason for referral"
                  label="Note"
                  name="notes"
                />
                <ButtonRow>
                  <PrimaryButton type="submit" bgColor="#3E1191" size="lg" fontSize="md">
                    Add Note
                  </PrimaryButton>
                </ButtonRow>
              </div>
            )}
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <>
      {isInConsult ? (
        <FormCard>{renderForm()}</FormCard>
      ) : (
        <Modal
          title="Add Note"
          onDismiss={() => (toggleView ? toggleView() : setIsOpen(false))}
          isOpen={isOpen}
          size="md"
        >
          {renderForm()}
        </Modal>
      )}
    </>
  );
};

export default NotesForm;
