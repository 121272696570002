import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ToolTip from './common/ToolTip';
import styled from 'styled-components';

const Container = styled.span`
  margin-left: 8px;
  display: inline-block;
`;

interface IProps {
  tooltip?: any;
  className?: string;
}

const NoticeIcon = ({ tooltip, className }: IProps) => {
  return (
    <>
      {tooltip && (
        <Container className={className}>
          <ToolTip tooltip={tooltip}>
            <FontAwesomeIcon icon={['far', 'question-circle']} />
          </ToolTip>
        </Container>
      )}
    </>
  );
};

export default NoticeIcon;
