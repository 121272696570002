import { useContext } from 'react';
import { CreateOrderContext } from '../contexts/CreateOrderContext';

export default function useCreateOrderContext() {
  const context = useContext(CreateOrderContext);
  if (!context) {
    throw new Error('useCreateOrderContext must be used within a CreateOrderProvider');
  }
  return context;
}
