import { AuthClient, PHRTrackersClient } from '../../../../../generated';
import { httpRequest } from '../../../../../utils';

// delete methods
export const deletePHRBloodPressure = async (
  patientID: string,
  phrTrackerDetailID: string,
  setError: (error: string) => void,
) => {
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerBloodPressureDelete(patientID, phrTrackerDetailID));
  } catch (error) {
    setError(error as any);
  }
};
export const deletePHRPulse = async (
  patientID: string,
  phrTrackerDetailID: string,
  setError: (error: string) => void,
) => {
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerPulseDelete(patientID, phrTrackerDetailID));
  } catch (error) {
    setError(error as any);
  }
};
export const deletePHRWeightHeight = async (
  patientID: string,
  phrTrackerDetailID: string,
  setError: (error: string) => void,
) => {
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerWeightHeightDelete(patientID, phrTrackerDetailID));
  } catch (error) {
    setError(error as any);
  }
};
export const deletePHRTemperature = async (
  patientID: string,
  phrTrackerDetailID: string,
  setError: (error: string) => void,
) => {
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerTemperatureDelete(patientID, phrTrackerDetailID));
  } catch (error) {
    setError(error as any);
  }
};
export const deletePHRRespiratory = async (
  patientID: string,
  phrTrackerDetailID: string,
  setError: (error: string) => void,
) => {
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerRespiratoryDelete(patientID, phrTrackerDetailID));
  } catch (error) {
    setError(error as any);
  }
};
export const deletePHRBloodOxygen = async (
  patientID: string,
  phrTrackerDetailID: string,
  setError: (error: string) => void,
) => {
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerBloodOxygenDelete(patientID, phrTrackerDetailID));
  } catch (error) {
    setError(error as any);
  }
};
export const deletePHRBloodGlucose = async (
  patientID: string,
  phrTrackerDetailID: string,
  setError: (error: string) => void,
) => {
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerBloodGlucoseDelete(patientID, phrTrackerDetailID));
  } catch (error) {
    setError(error as any);
  }
};
export const deletePHRHeadCircumference = async (
  patientID: string,
  phrTrackerDetailID: string,
  setError: (error: string) => void,
) => {
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() =>
      client.pHRTrackerHeadCircumferenceDelete(patientID, phrTrackerDetailID),
    );
  } catch (error) {
    setError(error as any);
  }
};
export const deletePHRLength = async (
  patientID: string,
  phrTrackerDetailID: string,
  setError: (error: string) => void,
) => {
  try {
    const client = new PHRTrackersClient(new AuthClient());
    await httpRequest(() => client.pHRTrackerLengthDelete(patientID, phrTrackerDetailID));
  } catch (error) {
    setError(error as any);
  }
};
