import { SystemSettingsEnum } from '../../constants';
import { AuthClient, SystemSettingsClient } from '../../generated';
import { httpRequest } from '../../utils';
import { AppState, OHThunkResult } from '../root-reducers';
import { getSystemSettingActions } from './systemSettingsAction';
import { ISystemSettingValue } from './systemSettingsReducers';

export function getSystemSettingByName(
  systemSetting: SystemSettingsEnum,
): OHThunkResult<Promise<void>> {
  return async (dispatch, getState: () => AppState): Promise<void> => {
    return new Promise<void>(async () => {
      dispatch(getSystemSettingActions.request());
      const systemSettingsClient = new SystemSettingsClient(new AuthClient());
      try {
        const currentSystemSettings = [...getState().systemSettingsState.systemSettings];

        // If that system setting already exists in the store don't fetch again
        // unlikely to change often
        if (currentSystemSettings.findIndex((x) => x.name === systemSetting) !== -1) {
          dispatch(getSystemSettingActions.success(currentSystemSettings));
        } else {
          const result = await httpRequest(() => systemSettingsClient.name(systemSetting));
          if (result.value) {
            const newSystemSetting: ISystemSettingValue = {
              name: systemSetting,
              value: result.value,
            };
            currentSystemSettings.push(newSystemSetting);
            dispatch(getSystemSettingActions.success(currentSystemSettings));
          }
        }
      } catch (err) {
        dispatch(getSystemSettingActions.failure(err as any));
      }
    });
  };
}
