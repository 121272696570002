import { Box, Text } from '@chakra-ui/react';
import { useField } from 'formik';
import React, { ReactNode } from 'react';
import ReactSelect, { ActionMeta, components, NamedProps } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';
import { getReactSelectStyle, getReactSelectUnStyled } from '../../../constants';
import useTranslationComponent from '../../../hooks/useTranslationComponent';
import { BaseProps } from '../baseProps';
import { FormControl, FormControlProps } from '../FormControl';

export type SelectComponentProps = Overwrite<
  NamedProps,
  {
    options: any[];
    value?: any;
    isMulti?: boolean;
    onChange?: (value: any, action?: ActionMeta<any>) => any;
    getOptionValue?: (option: any) => any;
    getOptionLabel?: (option: any) => any;
  }
> &
  BaseProps & {
    valueKey?: string;
    labelKey?: string;
    optionTemplate?: any;
    singleValueTemplate?: any;
    isCreatable?: boolean;
    getNewOptionData?: (inputValue: string, optionLabel: React.ReactNode) => any;
    formHelperText?: ReactNode;
    onAddNewCallback?: () => void;
    addNewText?: string;
    placeholder?: string;
    customStyles?: Omit<FormControlProps, 'label' | 'children'>;
  };

export const SelectComponent = (props: SelectComponentProps) => {
  const { t } = useTranslationComponent();
  const {
    label,
    formControlProps,
    isMulti = false,
    closeMenuOnSelect = !isMulti,
    isClearable = true,
    noOptionsMessage = () => 'Empty',
    menuPosition = 'absolute',
    styles = {
      ...getReactSelectStyle(),
      control: (styles, { isDisabled, isFocused }) => ({
        ...getReactSelectStyle().control(
          { ...styles, ...(props.customStyles ?? {}) },
          { isDisabled, isFocused },
        ),
      }),
    },
    valueKey = 'value',
    labelKey = 'label',
    getOptionValue = props.getOptionValue ?? ((option: any) => option[valueKey]),
    getOptionLabel = props.getOptionLabel ?? ((option: any) => option[labelKey]),
    optionTemplate,
    singleValueTemplate,
    isCreatable = false,
    getNewOptionData = (inputValue, optionLabel) => ({
      [labelKey]: optionLabel,
      [valueKey]: inputValue,
    }),
    components: optionalComponents,
    formHelperText,
    onAddNewCallback,
    addNewText,
    ...rest
  } = props;
  const { error, touched = !!error, ...formControlPropsRest } = formControlProps || {};

  const Option = ({ children, ...props }) => (
    //@ts-ignore
    <components.Option {...props}>
      {optionTemplate ? optionTemplate(props.data, props.isSelected) : children}
    </components.Option>
  );

  const SingleValue = ({ children, ...props }) => (
    //@ts-ignore
    <components.SingleValue {...props}>
      {singleValueTemplate ? singleValueTemplate(props.data) : children}
    </components.SingleValue>
  );

  const MenuList = (props) => {
    return (
      <components.MenuList {...props}>
        <Box
          cursor="pointer"
          p={4}
          borderBottom="1px solid rgb(211, 211, 211)"
          onClick={onAddNewCallback}
        >
          <Text onClick={onAddNewCallback} textAlign="left" color="rgb(0, 3, 16)">
            {addNewText}
          </Text>
        </Box>
        {props.children}
      </components.MenuList>
    );
  };

  const optionalMenu = addNewText ? { MenuList } : {};

  return (
    <FormControl label={label} error={error} touched={touched} {...formControlPropsRest}>
      {isCreatable ? (
        <CreatableSelect
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          menuPosition={menuPosition}
          noOptionsMessage={noOptionsMessage}
          closeMenuOnSelect={closeMenuOnSelect}
          isClearable={isClearable}
          placeholder={props.placeholder || t('Start typing...')}
          styles={styles}
          getNewOptionData={getNewOptionData}
          components={optionalComponents}
          {...rest}
        />
      ) : (
        <ReactSelect
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          menuPosition={menuPosition}
          noOptionsMessage={noOptionsMessage}
          // @ts-ignore
          isMulti={isMulti}
          closeMenuOnSelect={closeMenuOnSelect}
          isClearable={isClearable}
          styles={styles}
          placeholder={props.placeholder || t('Start typing...')}
          components={{
            Option: Option,
            SingleValue: SingleValue,
            ...optionalMenu,
            ...optionalComponents,
          }}
          {...rest}
        />
      )}
      {formHelperText}
    </FormControl>
  );
};

export type FormSelectComponentProps = Omit<SelectComponentProps, 'value'>;

export const FormSelectComponent = (props: FormSelectComponentProps) => {
  const {
    name,
    options = [],
    valueKey = 'value',
    formControlProps,
    isMulti,
    onChange,
    ...rest
  } = props;
  const [field, meta, { setValue, setTouched }] = useField(name);
  const selectedOption = isMulti
    ? options.filter(
        (option) => field.value && field.value.find((x) => x[valueKey] === option[valueKey]),
      )
    : options.find((option) => option[valueKey] === field.value);

  const handleChange = (value) => {
    if (value && valueKey) {
      if (isMulti) {
        setValue(value);
      } else {
        setValue(value[valueKey]);
      }
    } else {
      setValue('');
    }
    onChange?.(value);
  };

  const handleBlur = () => setTouched(true);

  return (
    <SelectComponent
      {...field}
      options={options}
      value={selectedOption}
      valueKey={valueKey}
      formControlProps={{ ...meta, ...formControlProps }}
      onChange={handleChange}
      onBlur={handleBlur}
      isMulti={isMulti}
      {...rest}
    />
  );
};

export const SelectComponentUnStyled = (props: SelectComponentProps) => (
  <SelectComponent styles={getReactSelectUnStyled()} {...props} />
);

interface IOptionProps {
  readonly selected: boolean;
  readonly active: boolean;
}

export const StyledOption = styled.div<IOptionProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  :hover {
    cursor: pointer;
  }

  .flag {
    margin-right: 5px;
  }

  p {
    color: ${(props) =>
      props.selected || props.active ? 'white' : props.theme.FontColors.Primary};
  }
`;

export const StyledSingleValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
