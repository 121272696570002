import { Switch, SwitchProps } from '@chakra-ui/react';
import { FastField } from 'formik';
import React from 'react';

export type SwitchControlProps = SwitchProps & {
  name: string;
};

export const SwitchControl = (props: SwitchControlProps) => {
  return <Switch {...props} />;
};

export default SwitchControl;

export type FormSwitchControlProps = SwitchControlProps;

const isChecked = (formValue: any, fieldValue: any) => {
  let checked;
  if (formValue instanceof Array) {
    checked = formValue.includes(fieldValue) ?? false;
  } else {
    checked = formValue;
  }
  return checked;
};

export const FormSwitchControl = (props: FormSwitchControlProps) => {
  const { name, children, ...rest } = props;

  return (
    <FastField name={name}>
      {({ field, meta }) => (
        <SwitchControl
          {...field}
          isInvalid={!!meta.error && meta.touched}
          isChecked={isChecked(field.value, props.value)}
          name={name}
          {...rest}
        >
          {children}
        </SwitchControl>
      )}
    </FastField>
  );
};
