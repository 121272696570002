import { FC } from 'react';

import { ArrowRightIcon } from '../../../../../../../assets/icons';
import useTranslationComponent from '../../../../../../../hooks/useTranslationComponent';

import { PrimaryButton, PrimaryButtonProps } from './PrimaryButton';

type ContinueButtonProps = PrimaryButtonProps;

const ContinueButton: FC<ContinueButtonProps> = (props) => {
  const { t } = useTranslationComponent();

  return (
    <PrimaryButton rightIcon={<ArrowRightIcon fontSize="16px" ml="1" />} {...props}>
      {props.children || t('Continue')}
    </PrimaryButton>
  );
};

export { ContinueButton };
