import { Center, Container, HStack, Text } from '@chakra-ui/react';
import { PrimaryButton, SecondaryButton } from '../../../../../../../components/Buttons';
import CenteredAnimatedCheckmark from '../../../../../../../components/presentation/AnimatedCheckmark';
import { OutpostTheme } from '../../../../../../../themes/outpost';
import { useSuccessScreen } from '../../contexts/CreateOrderContext/useSuccessScreen/useSuccessScreen';

interface IProps {
  onClose: () => void;
  isClinicalOrder?: boolean;
}

export const SuccessScreen = ({ onClose, isClinicalOrder }: IProps) => {
  const { onResetForm, patientOrderFinalizeResultDetails, onCompleteClinicalOrderCallback } =
    useSuccessScreen();
  return (
    <Center flexGrow={1} flexDirection="column">
      <Text mb={10} size="sm">
        Order Sent Successfully
      </Text>
      <CenteredAnimatedCheckmark />
      <Container size="sm">
        <Text textAlign="center" mt={5} mb={8} fontSize={OutpostTheme.FontSizes.body}>
          {patientOrderFinalizeResultDetails ? (
            <>
              {patientOrderFinalizeResultDetails.map((x) => (
                <>
                  {x.message}.<br />
                </>
              ))}{' '}
            </>
          ) : (
            <>Your lab order has been sent successfully. </>
          )}
          <br />
          {!isClinicalOrder && 'Would you like to create another diagnostics order?'}
        </Text>
      </Container>
      {isClinicalOrder ? (
        <HStack spacing={6}>
          <PrimaryButton onClick={onCompleteClinicalOrderCallback}>Confirm</PrimaryButton>
        </HStack>
      ) : (
        <HStack spacing={6}>
          <PrimaryButton onClick={onResetForm}>Yes</PrimaryButton>
          <SecondaryButton onClick={onClose}>No</SecondaryButton>
        </HStack>
      )}
    </Center>
  );
};
