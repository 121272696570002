import { useBreakpointValue } from '@chakra-ui/react';

const useDeviceBreakPoints = () => {
  const isTabletDevice = useBreakpointValue({ base: true, lg: false });
  const isMobileDevice = useBreakpointValue({ base: true, md: false });
  const isSmallDevice = useBreakpointValue({ base: true, sm: false });

  return {
    isTabletDevice,
    isMobileDevice,
    isSmallDevice,
  };
};

export { useDeviceBreakPoints };
