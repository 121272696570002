import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CenteredLoadingIndicator } from '../../../../components/LoadingIndicator';
import { getPHRPatient } from '../../../../store/patient/phr/phrCreators';
import { AppState } from '../../../../store/root-reducers';
import PHRNotesComponent from '../../../phr/notes/Notes';

interface IProps {
  patientID: string;
  phrConsultID?: string;
  isInConsult?: boolean;
}

const ConsultPHRNotesComponent = React.memo(({ patientID, phrConsultID, isInConsult }: IProps) => {
  const dispatch = useDispatch();
  const { phrPatient } = useSelector((state: AppState) => state.phrPatientState);

  const renderHeader = (addButton?: any) => {
    return (
      <>
        <Flex justify="space-between" align="center" mb="40px">
          <Box>
            <Text fontWeight="bold" fontSize="lg">
              Additional Notes
            </Text>
            <Text fontSize="sm" color="#04101F94">
              Quickly add a note for your patient
            </Text>
          </Box>
          {addButton && addButton()}
        </Flex>
      </>
    );
  };

  useEffect(() => {
    if (!phrPatient || phrPatient.patient.patientID !== patientID) {
      dispatch(getPHRPatient(patientID));
    }
  }, [dispatch, phrPatient, patientID]);

  return (
    <div>
      {phrPatient === null ? (
        <CenteredLoadingIndicator />
      ) : (
        <PHRNotesComponent
          phrPatient={phrPatient}
          renderHeader={renderHeader}
          inverse={true}
          phrConsultID={phrConsultID}
          isInConsult={isInConsult}
        />
      )}
    </div>
  );
});

export default ConsultPHRNotesComponent;
