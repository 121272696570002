import { ActionType, createAsyncAction } from 'typesafe-actions';
import { AuthClient, MailMessagesClient } from '../../generated';
import { httpRequest } from '../../utils';
import { OHThunkResult } from '../root-reducers';

export const getUnreadMailMessageCountActions = createAsyncAction(
  ['GET_UNREAD_MAIL_MESSAGE_COUNT_REQUEST', () => {}],
  ['GET_UNREAD_MAIL_MESSAGE_COUNT_SUCCESS', (res: number) => res],
  ['GET_UNREAD_MAIL_MESSAGE_COUNT_ERROR', (err: string) => err],
)();

export function getUnreadMailMessageCount(): OHThunkResult<Promise<number>> {
  return async (dispatch) => {
    return new Promise<number>(async (resolve) => {
      dispatch(getUnreadMailMessageCountActions.request());
      const mailMessagesClient = new MailMessagesClient(new AuthClient());
      try {
        const result = await httpRequest(() => mailMessagesClient.unReadCount());
        dispatch(getUnreadMailMessageCountActions.success(result));
        resolve(result);
      } catch (err) {
        dispatch(getUnreadMailMessageCountActions.failure(err as any));
      }
    });
  };
}

export type GetUnreadMailMessageCountActionTypes = ActionType<
  typeof getUnreadMailMessageCountActions
>;
