import { Flex, Text, useBreakpointValue } from '@chakra-ui/react';

import { useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import BirthDateComponent from '../../../components/BirthDateComponent';
import { Avatar } from '../../../components/common/Avatar';
import { IPatient, RolesEnum } from '../../../generated';
import { AppState } from '../../../store/root-reducers';
import { OutpostTheme } from '../../../themes/outpost';
import { getUserThumbnail } from '../../../utils/getUserThumbnail';
import { isInRole } from '../../../utils/isInRole';
import { IDependantsOption } from '../PhrComponent';

interface IPhrPatientSummaryProps {
  patient: IPatient;
  currentPHRDependant?: IDependantsOption;
  phrOptionsList?: IDependantsOption[];
  setCurrentPHRDependant?: (dependant: IDependantsOption) => void;
}

const PhrPatientSummary = ({
  patient,
  currentPHRDependant,
  phrOptionsList,
  setCurrentPHRDependant,
}: IPhrPatientSummaryProps) => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const isPatient = isInRole(currentUser, RolesEnum.Patient);
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex flexDir="column" align="center" marginBottom={{ base: '0.5rem', md: '1rem' }}>
      {!currentUser?.mobileSystem && (
        <Avatar
          size={isMobile ? OutpostTheme.Avatar.Size.Large : OutpostTheme.Avatar.Size.Medium}
          src={getUserThumbnail(patient.imageDetailID)}
          marginBottom="0"
        />
      )}

      {isPatient ? (
        <ReactSelect
          value={currentPHRDependant}
          options={phrOptionsList}
          getOptionValue={(option: any) => option.phrDependantID}
          getOptionLabel={(option: any) => option.label}
          name="currentPHRDependantID"
          onChange={(value) => setCurrentPHRDependant?.(value as IDependantsOption)}
          components={{
            IndicatorSeparator: null,
          }}
          isOptionDisabled={(option: any) => option.healthRecords === false}
          styles={{
            control: (base) => ({
              ...base,
              borderRadius: '8px',
              backgroundColor: '#F7F9FC',
              fontSize: '14px',
              margin: '0.5rem 0',
              width: isMobile ? '240px' : '200px',
            }),
          }}
        />
      ) : (
        <Text
          fontSize={OutpostTheme.FontSizes.body}
          fontWeight="semibold"
          color="#030303F7"
          marginBottom="0.25rem"
        >
          {patient.fullName}
        </Text>
      )}
      <Text fontSize={{ base: '14px', md: '12px' }} color="#4B5265" marginBottom="0.25rem">
        {patient.genderEnum} |{' '}
        <BirthDateComponent
          birthDate={patient.birthDateString}
          showBirthDate={true}
          showAge={true}
        />
      </Text>
    </Flex>
  );
};

export default PhrPatientSummary;
