import { ModalBody, ModalFooter } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { lazy, object, string } from 'yup';
import {
  Country,
  IPatient,
  IPatientOrder,
  IPatientPrescription,
  Patient,
  PatientOrder,
  PatientPrescription,
} from '../../generated';
import useTranslationComponent from '../../hooks/useTranslationComponent';
import { usePatientsPut } from '../../services/patients/mutators/usePatientsPut';
import { errorToastr, successToastr } from '../../utils/toastr';
import { ButtonRow, PurpleButton, SecondaryButton } from '../Buttons';
import { FormSingleCheckbox } from '../forms';
import { AddressPicker } from '../forms/AddressPicker/AddressPicker';
import { AddressPickerProvider } from '../forms/AddressPicker/AddressPickerProvider';
import { CenteredLoadingIndicator } from '../LoadingIndicator';
import { BaseModal } from '../Modals';
import ServerValidationBox from '../ServerValidationBox';

interface IProps {
  prescription?: IPatientPrescription | null;
  order?: IPatientOrder | null;
  patient: IPatient;
  isOpen: boolean;
  close: () => void;
  onUpdate?: (primaryKey: string) => void;
  updatingDelivery: boolean;
  country?: Country;
  refreshPhrPatient?: () => void;
  onUpdatePatientPrescriptionAddress?: (
    prescription: Partial<PatientPrescription>,
  ) => Promise<void>;
  onUpdatePatientOrderAddress?: (
    order: Partial<PatientOrder>,
    setLoading?: boolean,
  ) => Promise<void>;
}

export interface IForm {
  addressLine1: string;
  addressLine2: string;
  provinceStateID?: string;
  province?: string;
  cityTownID?: string;
  city?: string;
  postCode: string;
  updatePatient?: boolean;
}

export const UpdatePatientsAddressModal = ({
  patient,
  isOpen,
  close,
  order,
  prescription,
  updatingDelivery,
  country,
  refreshPhrPatient,
  onUpdatePatientPrescriptionAddress,
  onUpdatePatientOrderAddress,
}: IProps) => {
  const { t } = useTranslationComponent(['common', 'validation']);
  const patientsPut = usePatientsPut();
  const [updateError, setUpdateError] = useState<string>('');
  const [isUpdating, setUpdating] = useState<boolean>(false);
  const [errorStateHelper, setErrorStateHelper] = useState({
    city: 'cityTown',
    province: 'provinceState',
  });

  return (
    <BaseModal
      title={
        updatingDelivery
          ? t('Update delivery address')
          : t(`Update address`, { name: patient.fullName })
      }
      isOpen={isOpen}
      onDismiss={close}
      size="lg"
    >
      <Formik
        initialValues={
          {
            addressLine1: (prescription || order)?.customerAddressLine1 || '',
            addressLine2: (prescription || order)?.customerAddressLine2 || '',
            provinceStateID: (prescription || order)?.customerProvinceStateID,
            province: (prescription || order)?.customerProvince,
            cityTownID: (prescription || order)?.customerCityTownID,
            city: (prescription || order)?.customerCity,
            postCode: (prescription || order)?.customerPostCode || '',
            updatePatient: !updatingDelivery,
          } as IForm
        }
        validationSchema={object({
          addressLine1: string()
            .required(t('Address Line 1 is required', { ns: 'validation' }))
            .max(250, t('Address Line 1 must be at most 250 characters', { ns: 'validation' })),
          addressLine2: string().max(
            250,
            t('Address Line 2 must be at most 250 characters', { ns: 'validation' }),
          ),
          postCode: string().max(
            50,
            t('Postal Code must be at most 50 characters', { ns: 'validation' }),
          ),

          province: lazy(() =>
            errorStateHelper.province === 'province'
              ? string().required(t('State is required', { ns: 'validation' }))
              : string().nullable(),
          ),
          provinceState: lazy(() =>
            errorStateHelper.province === 'province'
              ? object().nullable()
              : object().required(t('State is required', { ns: 'validation' })),
          ),
          city: lazy(() =>
            errorStateHelper.city === 'city'
              ? string().required(t('City is required', { ns: 'validation' }))
              : string().nullable(),
          ),
          cityTown: lazy(() =>
            errorStateHelper.city === 'city'
              ? string().nullable()
              : object().required(t('City is required', { ns: 'validation' })),
          ),
        })}
        onSubmit={async (values: IForm) => {
          setUpdateError('');
          setUpdating(true);
          try {
            const {
              addressLine1,
              addressLine2,
              provinceStateID,
              province,
              cityTownID,
              city,
              postCode,
              updatePatient,
            }: IForm = values;
            if (updatePatient) {
              const updatedPatient = new Patient();
              updatedPatient.init(
                Object.assign({}, patient, {
                  addressLine1,
                  addressLine2,
                  postCode,
                  provinceStateID,
                  province,
                  cityTownID,
                  city,
                }),
              );
              await patientsPut.mutateAsync(updatedPatient);
              successToastr({ description: t(`Updated Patient's Address`) });
            }

            refreshPhrPatient?.();

            if (Boolean(onUpdatePatientPrescriptionAddress)) {
              const partialAddressUpdate: Partial<PatientPrescription> = {
                customerAddressLine1: addressLine1,
                customerAddressLine2: addressLine2,
                customerCity: city,
                customerCityTownID: cityTownID,
                customerCountry: patient.country?.name,
                customerEmailAddress: patient.emailAddress,
                customerName: patient.fullName,
                customerPhone: patient.cellPhoneNumber || patient.homePhoneNumber,
                customerPostCode: postCode,
                customerProvince: province,
                customerProvinceStateID: provinceStateID,
              };

              // let updatedPrescription = new PatientPrescription();
              // // TODO: Include provinceCodeID // cityTownID
              // updatedPrescription.init({
              //   ...prescription,
              //   ...partialAddressUpdate,
              // });
              // await patientPrescriptionsPut.mutateAsync(updatedPrescription);

              close();

              await onUpdatePatientPrescriptionAddress?.(partialAddressUpdate);

              successToastr({ description: t('Updated Delivery Address') });
            }

            if (Boolean(onUpdatePatientOrderAddress)) {
              const partialAddressUpdate: Partial<PatientOrder> = {
                customerAddressLine1: addressLine1,
                customerAddressLine2: addressLine2,
                customerCity: city,
                customerCityTownID: cityTownID,
                customerCountry: patient.country?.name,
                customerEmailAddress: patient.emailAddress,
                customerName: patient.fullName,
                customerPhone: patient.cellPhoneNumber || patient.homePhoneNumber,
                customerPostCode: postCode,
                customerProvince: province,
                customerProvinceStateID: provinceStateID,
              };

              // let updatedPrescription = new PatientPrescription();
              // // TODO: Include provinceCodeID // cityTownID
              // updatedPrescription.init({
              //   ...prescription,
              //   ...partialAddressUpdate,
              // });
              // await patientPrescriptionsPut.mutateAsync(updatedPrescription);

              close();

              await onUpdatePatientOrderAddress?.(partialAddressUpdate, true);

              successToastr({ description: t('Updated Delivery Address') });
            }

            // if (prescription) {
            //   await onUpdate(prescription.patientPrescriptionID);
            // }

            // if (order) {
            //   const updatedOrder = new PatientOrder();
            //   updatedOrder.init({
            //     ...order,
            //     ...{
            //       customerAddressLine1: addressLine1,
            //       customerAddressLine2: addressLine2,
            //       customerCity: city,
            //       customerCityTownID: cityTownID,
            //       customerCountry: patient.country?.name,
            //       customerEmailAddress: patient.emailAddress,
            //       customerName: patient.fullName,
            //       customerPhone: patient.cellPhoneNumber || patient.homePhoneNumber,
            //       customerPostCode: postCode,
            //       customerProvince: province,
            //       customerProvinceStateID: provinceStateID,
            //     },
            //   });

            //   close();

            //   await patientOrdersPut.mutateAsync(updatedOrder);

            //   successToastr({ description: 'Updated Delivery Address' });

            //   await onUpdate(order.orderID);
            // }
          } catch (err) {
            errorToastr({ description: t('An Error Occurred While Updating Delivery Address') });

            setUpdateError(err as any);
          }

          setUpdating(false);
        }}
      >
        {({ setFieldValue, touched, setFieldError, errors, values, validateForm }) => (
          <Form>
            <ModalBody>
              <AddressPickerProvider
                country={country}
                initialValues={
                  updatingDelivery
                    ? {
                        addressLine1: (prescription || order)?.customerAddressLine1,
                        addressLine2: (prescription || order)?.customerAddressLine2,
                        cityTown: (prescription || order)?.customerCityTown,
                        city: (prescription || order)?.customerCity,
                        provinceState: (prescription || order)?.customerProvinceState,
                        province: (prescription || order)?.customerProvince,
                        postCode: (prescription || order)?.customerPostCode,
                      }
                    : {
                        addressLine1: patient.addressLine1,
                        addressLine2: patient.addressLine2,
                        cityTown: patient.cityTown,
                        city: patient.city,
                        provinceState: patient.provinceState,
                        province: patient.province,
                        postCode: patient.postCode,
                      }
                }
              >
                {isUpdating ? (
                  <CenteredLoadingIndicator />
                ) : (
                  <>
                    <ServerValidationBox message={updateError} />
                    <AddressPicker
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      touched={touched}
                      errors={errors}
                      values={values}
                      validateForm={validateForm}
                      errorStateHelper={errorStateHelper}
                      setErrorStateHelper={setErrorStateHelper}
                    />
                    {updatingDelivery && (
                      <FormSingleCheckbox mb={4} name="updatePatient">
                        Update Patient&apos;s Address
                      </FormSingleCheckbox>
                    )}
                  </>
                )}
              </AddressPickerProvider>
            </ModalBody>
            <ModalFooter>
              <ButtonRow>
                <SecondaryButton onClick={close}>{t('Cancel')}</SecondaryButton>
                <PurpleButton type="submit">{t('Save')}</PurpleButton>
              </ButtonRow>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </BaseModal>
  );
};
