import { Box, Button, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { FastField, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { date, object, string } from 'yup';
import { PrimaryButton } from '../../../components/Buttons';
import { FormInputControl, FormMobisPicker, FormTextArea } from '../../../components/forms';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import ServerValidationBox from '../../../components/ServerValidationBox';
import { GLOBAL_MAX_TEXT_LENGTH } from '../../../constants';
import {
  AuthClient,
  FileDetail,
  IPHRVaccination,
  PHRDocumentCategoryEnum,
  PHRDocumentsClient,
  PHRDocumentUploadDetails,
  PHRVaccination,
  VaccineTypeEnum,
} from '../../../generated';
import { createForPhrSection, updateForPhrSection } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { OutpostTheme } from '../../../themes/outpost';
import { getFileDetail } from '../../../utils/fileHelpers';
import { scrollToTop } from '../../../utils/scrollToTop';
import { errorToastr } from '../../../utils/toastr';
import PhrItemForm from '../components/PhrItemForm';

interface IProps {
  currentVaccination: IPHRVaccination | null;
  patientID: string;
  toggleView: () => void;
  innerBtnRef: React.RefObject<HTMLButtonElement>;
}

interface IForm {
  name: string;
  vaccinationDate: Date;
  facility: string;
  lotNumber: string;
  frontDocumentID: string;
  backDocumentID: string;
  notes: string | undefined;
}

export const VaccinationForm = ({
  currentVaccination,
  patientID,
  toggleView,
  innerBtnRef,
}: IProps) => {
  const dispatch = useDispatch();
  const frontDocInputRef = useRef<HTMLInputElement>(null);
  const backDocInputRef = useRef<HTMLInputElement>(null);
  const { error, isPhrLoading } = useSelector((state: AppState) => state.phrPatientState);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleFileUpload = async (file: File, cb: any) => {
    const reader = new FileReader();
    let fileDetail: FileDetail;

    reader.onloadend = async function () {
      setIsUploading(true);
      try {
        fileDetail = getFileDetail(file.type, file.size, file.name, reader.result as ArrayBuffer);
        const documentUploadDetail = new PHRDocumentUploadDetails();
        documentUploadDetail.fileDetail = fileDetail;
        documentUploadDetail.patientID = patientID;
        documentUploadDetail.phrDocumentCategoryEnum = PHRDocumentCategoryEnum.Other;

        if (fileDetail) {
          const documentID = await new PHRDocumentsClient(new AuthClient()).uploadPHRDocument(
            documentUploadDetail,
          );
          cb(documentID);
        }
      } catch (e) {
        errorToastr({ description: 'An error occured while uploading license' });
      } finally {
        setIsUploading(false);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const submit = async (values: IForm) => {
    const vaccination = new PHRVaccination();
    vaccination.init(values);

    vaccination.patientID = patientID;
    vaccination.name = values.name;
    vaccination.vaccinationDate = values.vaccinationDate;
    vaccination.location = values.facility;
    vaccination.lotNumber = values.lotNumber;
    vaccination.frontPHRDocumentID = values.frontDocumentID;
    vaccination.backPHRDocumentID = values.backDocumentID;
    vaccination.notes = values.notes;
    vaccination.vaccineTypeEnum = VaccineTypeEnum.Other;

    if (currentVaccination) {
      vaccination.phrVaccinationID = currentVaccination?.phrVaccinationID;
      await dispatch(
        updateForPhrSection(
          ReduxPHRCategoryEnum.PHRVaccinations,
          vaccination,
          vaccination.patientID,
          false,
        ),
      );
    } else {
      await dispatch(
        createForPhrSection(
          ReduxPHRCategoryEnum.PHRVaccinations,
          vaccination,
          vaccination.patientID,
          false,
        ),
      );
    }
    toggleView();
  };

  const initialValues: IForm = {
    name: currentVaccination?.name || '',
    vaccinationDate: currentVaccination?.vaccinationDate || new Date(),
    facility: currentVaccination?.location || '',
    lotNumber: currentVaccination?.lotNumber || '',
    frontDocumentID: currentVaccination?.frontPHRDocumentID || '',
    backDocumentID: currentVaccination?.backPHRDocumentID || '',
    notes: currentVaccination ? currentVaccination.notes : '',
  };

  return (
    <PhrItemForm>
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          name: string()
            .required('A vaccination name is required')
            .max(100, 'Name must be at most 100 characters'),
          vaccinationDate: date()
            .typeError('Invalid date')
            .required('A vaccination date is required'),
          facility: string().max(100, 'Name must be at most 100 characters'),
          lotNumber: string().max(100, 'Name must be at most 100 characters'),
          frontDocumentID: string(),
          backDocumentID: string(),
          notes: string().max(GLOBAL_MAX_TEXT_LENGTH, 'Notes must be at most 10000 characters'),
        })}
        onSubmit={(values) => submit(values)}
      >
        {({ setFieldValue, errors, values, touched }) => (
          <Form>
            {isPhrLoading ? (
              <CenteredLoadingIndicator />
            ) : (
              <>
                <ServerValidationBox message={error} />
                <Grid gap={4} templateColumns={{ base: '1fr', md: '1fr 1fr' }} templateRows="auto">
                  <GridItem>
                    <FormInputControl
                      name="name"
                      label="Vaccination Name"
                      placeholder="Enter Vaccination Name"
                      height="48px"
                      borderRadius="lg"
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <FormMobisPicker
                      name="vaccinationDate"
                      label="Vaccination Date"
                      placeholder="DD/MM/YY"
                      borderRadius="lg"
                      containerProps={{
                        height: '48px',
                      }}
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                    {/* <FormDateTimePicker
                      name="vaccinationDate"
                      label="Vaccination Date"
                      placeholderText="DD/MM/YY"
                      customInputProps={{
                        height: '48px',
                        borderRadius: 'lg',
                      }}
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    /> */}
                  </GridItem>
                  <GridItem>
                    <FormInputControl
                      name="facility"
                      label="Facility Where Vaccine Was Given"
                      placeholder="Enter Healthcare Facility"
                      height="48px"
                      borderRadius="lg"
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <FormInputControl
                      name="lotNumber"
                      label="Lot Number"
                      placeholder="Enter Lot Number"
                      height="48px"
                      borderRadius="lg"
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <Box flexShrink={1}>
                      <Text
                        marginBottom="1rem"
                        textTransform="uppercase"
                        color="#01143173"
                        fontSize="12px"
                        fontWeight="light"
                      >
                        Upload Front of Document
                      </Text>

                      {values.frontDocumentID ? (
                        <Flex justify="space-between">
                          <Flex
                            bgColor="#3E11911C"
                            color="#3E1191"
                            borderRadius="lg"
                            fontSize={OutpostTheme.FontSizes.body}
                            height="48px"
                            flexGrow={1}
                            paddingX="1rem"
                            align="center"
                            justify="center"
                          >
                            <Text>{getDocumentDisplayName(values.frontDocumentID)}</Text>
                          </Flex>
                          <PrimaryButton
                            height="48px"
                            color="#FF3B46"
                            bgColor="#FFFFFF"
                            _hover={{
                              backgroundColor: '#FFFFFF',
                            }}
                            _active={{
                              backgroundColor: '#FFFFFF',
                            }}
                            onClick={() => setFieldValue('frontDocumentID', '')}
                          >
                            Delete
                          </PrimaryButton>
                        </Flex>
                      ) : (
                        <>
                          <FastField name="frontDocumentID">
                            {() => (
                              <input
                                ref={frontDocInputRef}
                                type="file"
                                id="frontDocumentID"
                                accept="image/png, image/jpeg"
                                style={{ display: 'none' }}
                                onChange={(event) => {
                                  handleFileUpload(
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                                    event?.currentTarget?.files?.[0]!,
                                    (documentID: string) =>
                                      setFieldValue('frontDocumentID', documentID),
                                  );
                                }}
                              />
                            )}
                          </FastField>

                          <PrimaryButton
                            bgColor="#3E11911C"
                            color="#707070"
                            _hover={{ bgColor: '#3E11911C' }}
                            _active={{ bgColor: '#3E11911C' }}
                            borderRadius="lg"
                            fontSize={OutpostTheme.FontSizes.body}
                            height="48px"
                            width="100%"
                            isLoading={isUploading}
                            onClick={() => {
                              frontDocInputRef?.current?.click();
                            }}
                          >
                            Tap To Upload Document
                          </PrimaryButton>
                          {errors.frontDocumentID && touched.frontDocumentID && (
                            <Text
                              fontSize={OutpostTheme.FontSizes.body}
                              color="red"
                              marginTop="0.5rem"
                            >
                              {errors.frontDocumentID}
                            </Text>
                          )}
                        </>
                      )}
                    </Box>
                  </GridItem>
                  <GridItem>
                    <Box flexShrink={1}>
                      <Text
                        marginBottom="1rem"
                        textTransform="uppercase"
                        color="#01143173"
                        fontSize="12px"
                        fontWeight="light"
                      >
                        Upload Back of Document
                      </Text>

                      {values.backDocumentID ? (
                        <Flex justify="space-between">
                          <Flex
                            bgColor="#3E11911C"
                            color="#3E1191"
                            borderRadius="lg"
                            fontSize={OutpostTheme.FontSizes.body}
                            height="48px"
                            flexGrow={1}
                            paddingX="1rem"
                            align="center"
                            justify="center"
                          >
                            <Text>{getDocumentDisplayName(values.backDocumentID)}</Text>
                          </Flex>
                          <PrimaryButton
                            height="48px"
                            color="#FF3B46"
                            bgColor="#FFFFFF"
                            _hover={{
                              backgroundColor: '#FFFFFF',
                            }}
                            _active={{
                              backgroundColor: '#FFFFFF',
                            }}
                            onClick={() => setFieldValue('backDocumentID', '')}
                          >
                            Delete
                          </PrimaryButton>
                        </Flex>
                      ) : (
                        <>
                          <FastField name="backDocumentID">
                            {({}) => (
                              <input
                                ref={backDocInputRef}
                                type="file"
                                id="backDocumentID"
                                accept="image/png, image/jpeg"
                                style={{ display: 'none' }}
                                onChange={(event) => {
                                  handleFileUpload(
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                                    event?.currentTarget?.files?.[0]!,
                                    (documentID: string) =>
                                      setFieldValue('backDocumentID', documentID),
                                  );
                                }}
                              />
                            )}
                          </FastField>

                          <PrimaryButton
                            bgColor="#3E11911C"
                            color="#707070"
                            _hover={{ bgColor: '#3E11911C' }}
                            _active={{ bgColor: '#3E11911C' }}
                            borderRadius="lg"
                            fontSize={OutpostTheme.FontSizes.body}
                            height="48px"
                            width="100%"
                            isLoading={isUploading}
                            onClick={() => {
                              backDocInputRef?.current?.click();
                            }}
                          >
                            Tap To Upload Document
                          </PrimaryButton>
                          {errors.backDocumentID && touched.backDocumentID && (
                            <Text
                              fontSize={OutpostTheme.FontSizes.body}
                              color="red"
                              marginTop="0.5rem"
                            >
                              {errors.backDocumentID}
                            </Text>
                          )}
                        </>
                      )}
                    </Box>
                  </GridItem>
                  <GridItem colSpan={[1, 1, 2]}>
                    <FormTextArea
                      name="notes"
                      label="Notes"
                      borderRadius="lg"
                      height="120px"
                      resize="none"
                      placeholder="Type a short note here"
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </GridItem>
                </Grid>
                <Button display="none" ref={innerBtnRef} type="submit" />
              </>
            )}
          </Form>
        )}
      </Formik>
    </PhrItemForm>
  );
};

const getDocumentDisplayName = (documentName: string) => {
  if (!documentName) return '';

  return documentName.length > 15 ? documentName.slice(0, 15) + '...' : documentName;
};
