import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RolesEnum } from '../../generated';
import { actAsDelegate } from '../../store/currentUser/currentUserCreators';
import { ICurrentUser } from '../../store/currentUser/currentUserReducers';
import { LocalStorageKeysEnum } from '../enums';
import { isInRole } from '../isInRole';
import { getLocalStorageItem } from '../localStorageActions';

type UseDelegateFromStorageProps = {
  currentUser: ICurrentUser | null;
};

const useDelegateFromStorage = ({ currentUser }: UseDelegateFromStorageProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const canDelegate = isInRole(currentUser, RolesEnum.HealthcareNavigator);

    const delegateUserDetailID = getLocalStorageItem(LocalStorageKeysEnum.DelegateAsUserDetailID);

    const isDelegating = currentUser?.delegateJWT;

    if (canDelegate && delegateUserDetailID && !isDelegating) {
      dispatch(actAsDelegate(delegateUserDetailID));
    }
  }, [currentUser, dispatch]);
};

export { useDelegateFromStorage };
