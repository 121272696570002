import { useQuery } from 'react-query';
import { AuthClient, Order, OrdersClient } from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

interface IProps {
  orderID?: string;
  countryID: string;
  storeID?: string;
  addressBookID?: string;
}

export const useOrderByID = ({ orderID, countryID, storeID, addressBookID }: IProps) => {
  const {
    isLoading: isLoadingOrder,
    data: order,
    error: orderError,
  } = useQuery<Order, string>(
    [QueryKeys.OrderByID, { orderID, countryID, storeID, addressBookID }],
    async () =>
      httpRequest(() =>
        new OrdersClient(new AuthClient()).byOrderIDAndCountryID(
          orderID!,
          countryID!,
          storeID,
          addressBookID,
        ),
      ),
    { enabled: !!orderID && !!countryID },
  );

  return {
    isLoadingOrder,
    order,
    orderError,
  };
};
