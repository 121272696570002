import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { ICurrentUser } from './currentUserReducers';

export const signInActions = createAsyncAction(
  ['SIGNIN_REQUEST', () => {}],
  ['SIGNIN_SUCCESS', (res: ICurrentUser) => res],
  ['SIGNIN_ERROR', (err: string) => err],
)();

export const delegateActions = createAsyncAction(
  ['DELEGATE_REQUEST', () => {}],
  ['DELEGATE_SUCCESS', (res: ICurrentUser) => res],
  ['DELEGATE_ERROR', (err: string) => err],
)();

export const logoutActions = createAction('LOGOUT')();

export const refreshTokenActions = createAsyncAction(
  ['REFRESH_TOKEN_REQUEST', () => {}],
  ['REFRESH_TOKEN_SUCCESS', (res: ICurrentUser) => res],
  ['REFRESH_TOKEN_ERROR', (err: string) => err],
)();

export const clearErrorAction = createAction('CLEAR_CURRENTUSER_ERROR', () => {})();

export type CurrentUserActionTypes = ActionType<
  | typeof signInActions
  | typeof delegateActions
  | typeof logoutActions
  | typeof refreshTokenActions
  | typeof clearErrorAction
>;
