import { Button, ButtonProps } from '@chakra-ui/react';

export type PurpleButtonProps = ButtonProps;

export const PurpleButton = (props: PurpleButtonProps) => {
  const { children, minWidth = '120px', colorScheme = 'purple', ...rest } = props;

  return (
    <Button
      borderRadius="12px"
      background="purple.600"
      borderWidth="1px"
      borderStyle="solid"
      _active={
        rest.isDisabled
          ? {}
          : {
              color: 'white',
              bgColor: 'purple.600',
            }
      }
      _hover={{
        background: 'transparent',
        border: '1px solid purple.600',
        color: 'purple.600',
      }}
      colorScheme={colorScheme}
      minWidth={minWidth}
      {...rest}
    >
      {children}
    </Button>
  );
};
