import { useEffect, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import {
  IPHRSummaryDTO,
  PatientAccessEnum,
  UserDetailSetting,
  UserDetailSettingEnum,
} from '../../generated';
import useTranslationComponent from '../../hooks/useTranslationComponent';
import { AppState } from '../../store/root-reducers';
import { getUserDetailSettings } from '../../store/userDetailSettings/getUserDetailSettingsAction';
import { updateUserDetailSettings } from '../../store/userDetailSettings/updateUserDetailSettingsAction';
import TooltipComponent from './Tooltip';

interface IProps {
  setHealthConditionsCategory: () => void;
  clearCategory: () => void;
  selectedCategory: IPHRSummaryDTO | null;
}

const PatientMedicationTour = ({}: IProps) => {
  const { t } = useTranslationComponent(['tour']);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const dispatch = useDispatch();
  const userDetailSettings = useSelector(
    (state: AppState) => state.userDetailSettingState.userDetailSettings,
  );
  const [run, setRun] = useState<boolean>(false);
  const steps = [
    {
      target: 'body',
      content: t(
        `Welcome to your Personal Health Records! Ensuring these are up to date will help your Provider to make better decision.`,
      ),
      placement: 'center' as const,
    },
    {
      target: `#${PatientAccessEnum.PHRHealthCondition}`,
      content: t('Adding Entries is simple! Check out Health Conditions.'),
      placement: 'top' as const,
    },
    {
      target: `#${PatientAccessEnum.PHRAllergy}`,
      content: t(`Make sure to enter your Allergies.`),
      placement: 'top' as const,
    },
    {
      target: 'body',
      content: t(
        `Awesome, give yourself some time before the next Appointment to ensure everything is up to date!`,
      ),
      placement: 'center' as const,
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      const updatedUserDetailSettings = (userDetailSettings ?? []).map((x) => {
        const updated = new UserDetailSetting();
        updated.init(x);
        if (x.userDetailSettingEnum === UserDetailSettingEnum.PatientMedicationTour) {
          updated.value = 'true';
        }
        return updated;
      });
      dispatch(updateUserDetailSettings(updatedUserDetailSettings));
      // Need to set our running state to false, so we can restart if we click start again.
      //this.setState({ run: false });
    }
  };

  useEffect(() => {
    if (!userDetailSettings) {
      dispatch(getUserDetailSettings());
    }
  }, [dispatch, userDetailSettings]);

  useEffect(() => {
    if (userDetailSettings) {
      const hasTakenTour = userDetailSettings.find(
        (x) => x.userDetailSettingEnum === UserDetailSettingEnum.PatientMedicationTour,
      );
      if (!hasTakenTour || hasTakenTour.value === 'false') {
        setRun(true);
      }
    }
  }, [userDetailSettings]);

  return (
    <Joyride
      disableOverlayClose={true}
      disableCloseOnEsc={true}
      callback={handleJoyrideCallback}
      showSkipButton={true}
      run={run}
      locale={{
        back: t('Back'),
        close: t('Close'),
        last: t('Done'),
        next: t('Next'),
        skip: t('Skip'),
      }}
      stepIndex={stepIndex}
      steps={steps}
      continuous={true}
      tooltipComponent={TooltipComponent}
      styles={{
        options: {
          zIndex: 9999,
        },
      }}
    />
  );
};

export default PatientMedicationTour;
