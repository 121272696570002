import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import moment from 'moment';

import { PHRVaccination } from '../../../generated';
import { TimeFormatEnum } from '../../../constants';
import { CalendarIcon2 } from '../../../assets/icons';

import PhrItem, { PHRItemList, EmptyPHRItemListCard } from '../components/PhrItem';
import { getTime } from '../../../utils';
import { OutpostTheme } from '../../../themes/outpost';

interface IProps {
  phrVaccinations: PHRVaccination[];
  onView: (phrVaccination: PHRVaccination) => void;
}

export const VaccinationList = ({ phrVaccinations = [], onView }: IProps) => {
  if (phrVaccinations.length === 0) {
    return <EmptyPHRItemListCard>No Vaccinations added</EmptyPHRItemListCard>;
  }

  return (
    <PHRItemList>
      {phrVaccinations
        .sort((a, b) => getTime(b.vaccinationDate) - getTime(a.vaccinationDate))
        .map((item, index) => (
          <PhrItem
            key={index}
            name={item.name}
            isVerified={item.verified}
            onView={() => onView(item)}
            SecondaryInfo={<SecondaryInfoComponent item={item} />}
          />
        ))}
    </PHRItemList>
  );
};

interface ISecondaryInfoComponentProps {
  item: PHRVaccination;
}

const SecondaryInfoComponent = ({ item }: ISecondaryInfoComponentProps) => {
  return (
    <Box marginTop="1rem">
      <Text textTransform="uppercase" color="#020202" fontSize="12px" fontWeight="normal">
        Vacination Date
      </Text>
      <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
        <CalendarIcon2 fontSize="18px" color="#020202" />{' '}
        {moment(item.vaccinationDate).format(TimeFormatEnum.SHORT_LONG_DATE)}
      </Text>
    </Box>
  );
};
