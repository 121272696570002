import { Box, Flex, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { object, string } from 'yup';
import { PurpleButton } from '../../../../../../../components/Buttons';
import { FormTextArea } from '../../../../../../../components/forms';
import { CenteredLoadingIndicator } from '../../../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../../../components/ServerValidationBox';
import { GLOBAL_MAX_TEXT_LENGTH } from '../../../../../../../constants';
import { PatientOrderItem } from '../../../../../../../generated';
import { OutpostTheme } from '../../../../../../../themes/outpost';

interface IProps {
  selectedOrderItem: PatientOrderItem;
  onSubmit: (selectedOrderItem: PatientOrderItem, additionalInformation: string) => void;
  isUpdating: boolean;
  errorUpdating?: string;
  onCancel: () => void;
  isEditTest: boolean;
  setIsEditTest: Dispatch<SetStateAction<boolean>>;
}

export const OrderForm = ({
  selectedOrderItem,
  onSubmit,
  isUpdating,
  errorUpdating,
  onCancel,
  isEditTest,
  setIsEditTest,
}: IProps) => {
  return (
    <Box>
      {!isUpdating && (
        <Flex mb={6} alignItems="center" justifyContent="space-between">
          <Text fontWeight="400" fontSize={OutpostTheme.FontSizes.body} flex={1}>
            {selectedOrderItem.orderItem
              ? selectedOrderItem.orderItem?.item?.description
              : selectedOrderItem.testDescription}
          </Text>
          <Text
            onClick={onCancel}
            cursor="pointer"
            color="red.500"
            fontSize="xs"
            minW="4rem"
            textAlign="right"
          >
            Cancel
          </Text>
        </Flex>
      )}
      <Formik
        initialValues={{
          additionalInformation: selectedOrderItem?.additionalInformation || '',
        }}
        validationSchema={object({
          additionalInformation: string().max(
            GLOBAL_MAX_TEXT_LENGTH,
            `Note can't exceed ${GLOBAL_MAX_TEXT_LENGTH} characters`,
          ),
        })}
        onSubmit={({ additionalInformation }) => {
          setIsEditTest(false);
          onSubmit(selectedOrderItem, additionalInformation);
        }}
      >
        <Form>
          {isUpdating ? (
            <CenteredLoadingIndicator />
          ) : (
            <>
              <ServerValidationBox message={errorUpdating} />
              <Flex flexDirection={{ base: 'column', md: 'row' }}>
                <Box flex="1">
                  <FormTextArea
                    name="additionalInformation"
                    placeholder="Additional Information"
                    rows={1}
                  />
                  {selectedOrderItem.testDescription && (
                    <Text fontWeight="400" my={3} fontSize="12px">
                      <Text as="span" fontWeight="500">
                        Note:
                      </Text>{' '}
                      You are about to order a test that is not within Outpost's database. Please
                      provide more information about this test. This test will be sent directly to
                      the patient only and not to a laboratory or diagnostic center.
                    </Text>
                  )}
                </Box>
                <Flex
                  justifyContent="flex-start"
                  alignSelf="flex-start"
                  ml={{ base: '0', md: '3' }}
                >
                  <PurpleButton type="submit">{isEditTest ? 'Update' : '+ Add Test'}</PurpleButton>
                </Flex>
              </Flex>
            </>
          )}
        </Form>
      </Formik>
    </Box>
  );
};
