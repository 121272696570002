import {
  AuthClient,
  Consult,
  ConsultActionMessage,
  ConsultsClient,
  ConsultStatusEnum,
  RolesEnum,
} from '../../generated';
import { httpRequest } from '../../utils';
import { isInRole } from '../../utils/isInRole';
import { AppState, OHThunkResult } from '../root-reducers';
import {
  canceledConsultAction,
  getActiveConsultActions,
  getActiveConsultByConsultIDActions,
  refreshActiveConsultByConsultIDActions,
  reInitializeActiveConsultByConsultIDActions,
  updateActiveConsultStatusAction,
} from './activeConsultActions';

export function getActiveConsult(userDetailID: string): OHThunkResult<Promise<Consult>> {
  return async (dispatch) => {
    return new Promise<Consult>(async (resolve) => {
      dispatch(getActiveConsultActions.request());
      const consultsClient = new ConsultsClient(new AuthClient());
      try {
        const result = await httpRequest(() =>
          consultsClient.activeConsultByUserDetailID(userDetailID),
        );
        dispatch(getActiveConsultActions.success(result));
        resolve(result);
      } catch (err) {
        dispatch(getActiveConsultActions.failure(err as string));
      }
    });
  };
}

export function getActiveConsultByConsultID(consultID: string): OHThunkResult<Promise<Consult>> {
  return async (dispatch) => {
    return new Promise<Consult>(async (resolve) => {
      dispatch(getActiveConsultByConsultIDActions.request());
      const consultsClient = new ConsultsClient(new AuthClient());
      try {
        const result = await httpRequest(() => consultsClient.consultsGet(consultID));
        dispatch(getActiveConsultByConsultIDActions.success(result));
        resolve(result);
      } catch (err) {
        dispatch(getActiveConsultByConsultIDActions.failure(err as string));
      }
    });
  };
}

export function refreshActiveConsultByConsultID(
  consultID: string,
): OHThunkResult<Promise<Consult>> {
  return async (dispatch) => {
    return new Promise<Consult>(async (resolve) => {
      dispatch(refreshActiveConsultByConsultIDActions.request());
      const consultsClient = new ConsultsClient(new AuthClient());
      try {
        const result = await httpRequest(() => consultsClient.consultsGet(consultID));
        dispatch(refreshActiveConsultByConsultIDActions.success(result));
        resolve(result);
      } catch (err) {
        dispatch(refreshActiveConsultByConsultIDActions.failure(err as string));
      }
    });
  };
}

export function reInitializeActiveConsultByConsultID(
  consultID: string,
): OHThunkResult<Promise<Consult>> {
  return async (dispatch) => {
    return new Promise<Consult>(async (resolve) => {
      dispatch(reInitializeActiveConsultByConsultIDActions.request());
      const consultsClient = new ConsultsClient(new AuthClient());
      try {
        const result = await httpRequest(() => consultsClient.reinitializeConsult(consultID));
        dispatch(reInitializeActiveConsultByConsultIDActions.success(result));
        resolve(result);
      } catch (err) {
        dispatch(reInitializeActiveConsultByConsultIDActions.failure(err as string));
      }
    });
  };
}

export function updateActiveConsultStatus(
  updateConsultStatus: ConsultActionMessage,
): OHThunkResult<Promise<ConsultActionMessage>> {
  return async (dispatch, getState: () => AppState) => {
    return new Promise<ConsultActionMessage>(async () => {
      if (isInRole(getState().currentUserState.data!, RolesEnum.Patient)) {
        if (
          updateConsultStatus.consultStatusEnum === ConsultStatusEnum.PatientReady ||
          updateConsultStatus.consultStatusEnum === ConsultStatusEnum.ConsultReady ||
          updateConsultStatus.consultStatusEnum === ConsultStatusEnum.Scheduled
        ) {
          dispatch(getActiveConsult(getState().currentUserState.data!.userDetailID));
        } else if (updateConsultStatus.consultStatusEnum === ConsultStatusEnum.InProgress) {
          dispatch(updateActiveConsultStatusAction(updateConsultStatus));
        } else if (updateConsultStatus.consultStatusEnum === ConsultStatusEnum.PatientCompleted) {
          dispatch(updateActiveConsultStatusAction(updateConsultStatus));
        } else if (updateConsultStatus.consultStatusEnum === ConsultStatusEnum.Cancelled) {
          const consult = new Consult();
          consult.consultID = updateConsultStatus.consultID!;
          dispatch(canceledConsultAction(consult));
        }
      } else if (isInRole(getState().currentUserState.data!, RolesEnum.Provider)) {
        if (
          updateConsultStatus.consultStatusEnum === ConsultStatusEnum.InProgress ||
          updateConsultStatus.consultStatusEnum === ConsultStatusEnum.PatientCompleted ||
          updateConsultStatus.consultStatusEnum === ConsultStatusEnum.Cancelled
        ) {
          dispatch(updateActiveConsultStatusAction(updateConsultStatus));
        }
      }
    });
  };
}
