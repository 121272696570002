import React, { FC } from 'react';

import { useHistory } from 'react-router';

import { IconButton, HStack, StackProps, IconButtonProps } from '@chakra-ui/react';

import { BackArrowIcon, BackIcon } from '../../../../../../../assets/icons';

type BackIconButtonProps = {
  onClick?: IconButtonProps['onClick'];
  containerProps?: StackProps;
  iconSize?: string;
  children?: React.ReactNode;
};

const defaultIconSize = '20px';

const BackIconButton: FC<BackIconButtonProps> = (props) => {
  const history = useHistory();

  return (
    <HStack spacing="3" {...props.containerProps}>
      <IconButton
        aria-label="Go back"
        bgColor="transparent"
        _hover={{ bgColor: 'rgb(219, 217, 217)' }}
        onClick={props.onClick || history.goBack}
        p="2"
        borderRadius="full"
      >
        <BackArrowIcon
          width={props.iconSize || defaultIconSize}
          height={props.iconSize || defaultIconSize}
        />
      </IconButton>
      {props.children}
    </HStack>
  );
};

export { BackIconButton };
