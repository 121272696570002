import { InvoiceStoreItem, PatientOrderItem } from '../../../../generated';

const getAdditionalItems = (
  patientOrderItems: PatientOrderItem[],
  invoiceStoreItems: InvoiceStoreItem[],
) => {
  // const invoiceStoreItemsInPhrMedications = invoiceStoreItems.filter((invoiceStoreItem) =>
  //   phrMedications.find(
  //     (phrMedication) => phrMedication.storeItemID === invoiceStoreItem.storeItemID,
  //   ),
  // );

  // return invoiceStoreItemsInPhrMedications.filter(
  //   (invoiceStoreItem) => invoiceStoreItem.storeItem?.showOnInvoice,
  // );

  return invoiceStoreItems
    ?.filter(
      (invoiceStoreItem) =>
        !(patientOrderItems ?? []).some(
          (phrMed) => phrMed.storeItemID === invoiceStoreItem.storeItemID,
        ),
    )
    .filter((invoiceStoreItem) => invoiceStoreItem.storeItem?.showOnInvoice);
};

export { getAdditionalItems };
