import { AuthClient, PatientPrescription, PatientPrescriptionsClient } from '../../../../generated';
import { httpRequest } from '../../../../utils';

const patientPrescriptionsPut = async (patientPrescription: PatientPrescription) => {
  const prescriptionsClient = new PatientPrescriptionsClient(new AuthClient());

  const prescriptionID = await httpRequest(() =>
    prescriptionsClient.patientPrescriptionsPut(patientPrescription),
  );

  return prescriptionID;
};

export { patientPrescriptionsPut };
