import { useState } from 'react';
import { IUserDetailSetting } from '../../../../../generated';
import useTranslationComponent from '../../../../../hooks/useTranslationComponent';
import { IPHRPatientCombined } from '../../../../../store/patient/phr/phrReducers';
import { ReduxPHRCategoryEnum } from '../../../../../store/types';
import { EmptyCategoryCard } from '../../../../phr/components/StyledPhrItems';
import BloodGlucoseComponent from '../../../../phr/trackers/bloodGlucose/BloodGlucose';
import BloodOxygenComponent from '../../../../phr/trackers/bloodOxygen/BloodOxygen';
import BloodPressureComponent from '../../../../phr/trackers/bloodPressure/BloodPressure';
import HeadCircumferenceComponent from '../../../../phr/trackers/headCircumference/HeadCircumference';
import LengthComponent from '../../../../phr/trackers/length/Length';
import PulseComponent from '../../../../phr/trackers/pulse/Pulse';
import RespiratoryComponent from '../../../../phr/trackers/respiratory/Respiratory';
import TemperatureComponent from '../../../../phr/trackers/temperature/Temperature';
import { WeightAndHeight } from '../../../../phr/trackers/weightAndHeight/WeightAndHeight';
import { VitalsTracker } from '../PatientVitalsCard/usePatientVitalsCard';
import { addButton, renderVitalsHeader } from '../PatientVitalsTitle/PatientVitalsTitle';

export enum ListViewStateEnum {
  List = 'List',
  Tracker = 'Tracker',
  Modal = 'Modal',
}

export const trackerTitles = {
  [ReduxPHRCategoryEnum.PHRBloodPressures]: 'Blood Pressure',
  [ReduxPHRCategoryEnum.PHRPulses]: 'Heart Rate',
  [ReduxPHRCategoryEnum.PHRWeightHeights]: 'Weight and Height',
  [ReduxPHRCategoryEnum.PHRTemperatures]: 'Temperature',
  [ReduxPHRCategoryEnum.PHRRespiratories]: 'Respiration',
  [ReduxPHRCategoryEnum.PHRBloodOxygens]: 'SP02',
  [ReduxPHRCategoryEnum.PHRBloodGlucoses]: 'Blood Glucose',
  [ReduxPHRCategoryEnum.PHRHeadCircumferences]: 'Head Circumference',
  [ReduxPHRCategoryEnum.PHRLengths]: 'Length',
};

export const usePatientVitalsList = (
  phrPatient?: IPHRPatientCombined | null,
  userDetailSettings?: IUserDetailSetting[] | null,
  setShowForm?: (showForm: boolean) => void,
  refreshList?: () => void,
) => {
  const { t } = useTranslationComponent(['phr']);
  const [viewState, setViewState] = useState<ListViewStateEnum>(ListViewStateEnum.List);
  const [trackerID, setTrackerID] = useState<ReduxPHRCategoryEnum | null>(null);
  const [tracker, setTracker] = useState<VitalsTracker | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const showList = () => {
    setViewState(ListViewStateEnum.List);
    setTrackerID(null);
    setTracker(null);
    setIsOpen(false);
    if (refreshList) {
      refreshList();
    }
  };

  const showSingleTrackerForm = (id: ReduxPHRCategoryEnum, tracker: VitalsTracker) => {
    setTrackerID(id);
    setTracker(tracker);
    setShowForm?.(true);
  };

  const showTracker = (id: ReduxPHRCategoryEnum | null) => {
    setViewState(ListViewStateEnum.Tracker);
    setTrackerID(id);
  };

  const renderTracker = () => {
    if (phrPatient) {
      switch (trackerID) {
        case ReduxPHRCategoryEnum.PHRBloodPressures:
          return (
            <BloodPressureComponent
              patient={phrPatient?.phr}
              showSingleTrackerForm={showSingleTrackerForm}
              defaultBack={showList}
              defaultTitle={t(trackerTitles[trackerID])}
              renderHeader={renderVitalsHeader}
            />
          );
        case ReduxPHRCategoryEnum.PHRPulses:
          return (
            <PulseComponent
              patient={phrPatient?.phr}
              showSingleTrackerForm={showSingleTrackerForm}
              defaultBack={showList}
              defaultTitle={t(trackerTitles[trackerID])}
              renderHeader={renderVitalsHeader}
            />
          );
        case ReduxPHRCategoryEnum.PHRWeightHeights:
          return (
            <WeightAndHeight
              patient={phrPatient?.phr}
              userDetailSettings={userDetailSettings!}
              renderHeader={() =>
                renderVitalsHeader(
                  () => addButton(() => setShowForm && setShowForm(true)),
                  showList,
                  t(trackerTitles[trackerID]),
                )
              }
            />
          );
        case ReduxPHRCategoryEnum.PHRTemperatures:
          return (
            <TemperatureComponent
              patient={phrPatient?.phr}
              showSingleTrackerForm={showSingleTrackerForm}
              userDetailSettings={userDetailSettings!}
              defaultBack={showList}
              defaultTitle={t(trackerTitles[trackerID])}
              renderHeader={renderVitalsHeader}
            />
          );
        case ReduxPHRCategoryEnum.PHRRespiratories:
          return (
            <RespiratoryComponent
              patient={phrPatient?.phr}
              showSingleTrackerForm={showSingleTrackerForm}
              defaultBack={showList}
              defaultTitle={t(trackerTitles[trackerID])}
              renderHeader={renderVitalsHeader}
            />
          );
        case ReduxPHRCategoryEnum.PHRBloodOxygens:
          return (
            <BloodOxygenComponent
              patient={phrPatient?.phr}
              showSingleTrackerForm={showSingleTrackerForm}
              defaultBack={showList}
              defaultTitle={t(trackerTitles[trackerID])}
              renderHeader={renderVitalsHeader}
            />
          );
        case ReduxPHRCategoryEnum.PHRBloodGlucoses:
          return (
            <BloodGlucoseComponent
              patient={phrPatient?.phr}
              showSingleTrackerForm={showSingleTrackerForm}
              userDetailSettings={userDetailSettings!}
              defaultBack={showList}
              defaultTitle={t(trackerTitles[trackerID])}
              renderHeader={renderVitalsHeader}
            />
          );
        case ReduxPHRCategoryEnum.PHRHeadCircumferences:
          return (
            <HeadCircumferenceComponent
              patient={phrPatient?.phr}
              userDetailSettings={userDetailSettings!}
              renderHeader={() =>
                renderVitalsHeader(
                  () => addButton(() => setShowForm && setShowForm(true)),
                  showList,
                  t(trackerTitles[trackerID]),
                )
              }
            />
          );
        case ReduxPHRCategoryEnum.PHRLengths:
          return (
            <LengthComponent
              patient={phrPatient?.phr}
              userDetailSettings={userDetailSettings!}
              renderHeader={() =>
                renderVitalsHeader(
                  () => addButton(() => setShowForm && setShowForm(true)),
                  showList,
                  t(trackerTitles[trackerID]),
                )
              }
            />
          );
        default:
          return (
            <EmptyCategoryCard>
              <p>{t('Please select a Category')}</p>
            </EmptyCategoryCard>
          );
      }
    }
  };

  return {
    viewState,
    trackerID,
    tracker,
    isOpen,
    setIsOpen,
    showList,
    showSingleTrackerForm,
    showTracker,
    renderTracker,
    setTrackerID,
  };
};
