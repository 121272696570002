import { Box, Flex, HStack } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { RadioGroupControl } from '../../../components/forms';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import ServerValidationBox from '../../../components/ServerValidationBox';
import { clearPHRPatientErrorAction } from '../../../store/patient/phr/phrActions';
import { IPHRPatientEnhanced } from '../../../store/patient/phr/phrReducers';
import { scrollToTop } from '../../../utils/scrollToTop';
import { AddPHRItemButton } from '../components/PhrItem';
import PhrItemDeleteModal from '../components/PhrItemDeleteModal';
import { PhrFormButtons } from '../components/PhrItemForm';
import { PhrDetailsButtons } from '../components/PhrItemView';
import AllergyForm from './components/AllergyForm/AllergyForm';
import AllergyList from './components/AllergyList/AllergyList';
import AllergyRadioControl from './components/AllergyRadioControl';
import AllergyView from './components/AllergyView';
import { useAllergiesData } from './hooks/useAllergiesData';
import { EPHRAllergiesViewState, useAllergiesForm } from './hooks/useAllergiesForm';

interface IProps {
  patient: IPHRPatientEnhanced;
  renderHeader: (onCreate?: any, onBack?: () => void, title?: string, showDismiss?: boolean) => any;
}

const PHRAllergiesComponent = ({ patient, renderHeader }: IProps) => {
  const dispatch = useDispatch();
  const innerBtnRef = useRef<HTMLButtonElement>(null);
  const { isLoading, error, phrPatient, phrAllergies, allergyReactionTags } =
    useAllergiesData(patient);
  const {
    isApiLoading,
    currentView,
    setCurrentView,
    selectedAllergy,
    showDeleteModal,
    setShowDeleteModal,
    onView,
    onCreate,
    onEdit,
    onSave,
    onDelete,
    onConfirmDelete,
    onClickEpipen,
  } = useAllergiesForm(patient);

  useEffect(() => {
    dispatch(clearPHRPatientErrorAction());
    scrollToTop();
  }, [dispatch, currentView]);

  return (
    <Box paddingY="2rem" backgroundColor="#f4f4f4" height="100%">
      {currentView === EPHRAllergiesViewState.List ? (
        renderHeader()
      ) : (
        <Flex>
          {renderHeader(null, () => setCurrentView(EPHRAllergiesViewState.List), '', false)}
          {currentView === EPHRAllergiesViewState.View && (
            <PhrDetailsButtons
              isVerified={selectedAllergy?.verified}
              phrItem={selectedAllergy}
              phrItemID={selectedAllergy?.phrAllergyID}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          )}
          {currentView === EPHRAllergiesViewState.Edit && (
            <PhrFormButtons
              innerBtnRef={innerBtnRef}
              toggleView={() => setCurrentView(EPHRAllergiesViewState.List)}
            />
          )}
        </Flex>
      )}

      {currentView === EPHRAllergiesViewState.List && (
        <>
          <AddPHRItemButton name="Allergy" onCreate={onCreate} marginTop={0} />

          <RadioGroupControl
            label="Do you use an EpiPen?"
            value={
              typeof phrPatient?.phr.epiPen === 'undefined'
                ? undefined
                : phrPatient?.phr.epiPen
                ? 'Yes'
                : 'No'
            }
            name="EpiPen"
            onChange={(nextValue) => onClickEpipen(nextValue === 'Yes')}
            formControlProps={{
              display: 'flex',
              flexDir: { base: 'column', md: 'row' },
              alignItems: 'center',
              paddingX: '3rem',
              paddingY: '2rem',
              bgColor: '#ffffff',
              borderRadius: 'lg',
              marginX: 'auto',
              marginBottom: '0.5rem',
              marginTop: '1rem',
              maxWidth: { base: '95%', md: '90%' },
              formLabelProps: {
                justifyContent: 'center',
                fontWeight: '500',
                marginBottom: '0.5rem',
                marginRight: { base: 0, md: '2rem' },
              },
            }}
          >
            <HStack spacing={8}>
              <AllergyRadioControl value="Yes" name="EpiPen" />
              <AllergyRadioControl value="No" name="EpiPen" />
            </HStack>
          </RadioGroupControl>

          <ServerValidationBox message={error} />
          {isLoading || isApiLoading ? (
            <Box marginTop="1rem">
              <CenteredLoadingIndicator />
            </Box>
          ) : (
            <AllergyList phrAllergies={phrAllergies} onView={onView} />
          )}
        </>
      )}

      {currentView === EPHRAllergiesViewState.View && <AllergyView phrAllergy={selectedAllergy!} />}

      {currentView === EPHRAllergiesViewState.Edit && (
        <AllergyForm
          selectedAllergy={selectedAllergy!}
          allergyReactionTags={allergyReactionTags}
          onSave={onSave}
          innerBtnRef={innerBtnRef}
        />
      )}

      <PhrItemDeleteModal
        isOpen={showDeleteModal}
        onDismiss={() => setShowDeleteModal(false)}
        onDelete={onConfirmDelete}
      />
    </Box>
  );
};

export default PHRAllergiesComponent;
