import React, { FC } from 'react';
import { ButtonProps } from '@chakra-ui/react';

import { ActionButton, ActionButtonProps } from './ActionButton';

export type LinkButtonProps = ButtonProps & {
  buttonVariant?: ActionButtonProps['buttonVariant'];
};

const LinkButton: FC<LinkButtonProps> = (props) => (
  <ActionButton
    buttonColor="rgb(45, 12, 105)"
    buttonHoverColor="rgb(45, 12, 105)"
    buttonVariant="link"
    borderRadius="2"
    p="2"
    height="auto"
    {...props}
  >
    {props.children}
  </ActionButton>
);

export { LinkButton };
