import { Box } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { CenteredLoadingIndicator } from '../../../../components/LoadingIndicator';
import { Country } from '../../../../generated';
import useTranslationComponent from '../../../../hooks/useTranslationComponent';
import { getPHRPatient } from '../../../../store/patient/phr/phrCreators';
import { AppState } from '../../../../store/root-reducers';
import PHRMedicationsComponent from '../../../phr/consultMedications/ConsultMedications';

const PhrHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CategoyTitle = styled.p`
  font-size: 16px;
  margin-left: 1.5rem;
  margin-bottom: 0;
  margin-top: 1rem;
`;

interface IProps {
  patientID: string;
  isInConsult: boolean;
  phrConsultID?: string;
  country?: Country;
  storeID?: string;
}

const MedicationsComponent = React.memo(({ patientID, phrConsultID, country, storeID }: IProps) => {
  const { t } = useTranslationComponent(['clinic']);
  const dispatch = useDispatch();
  const { phrPatient, isLoading } = useSelector((state: AppState) => state.phrPatientState);

  const renderHeader = (addButton?: any) => {
    return (
      <PhrHeader>
        <CategoyTitle>{!addButton && t('Add Medication')}</CategoyTitle>
        {addButton ? addButton() : <span></span>}
      </PhrHeader>
    );
  };

  useEffect(() => {
    if (!phrPatient || phrPatient.patient.patientID !== patientID) {
      dispatch(getPHRPatient(patientID));
    }
  }, [dispatch, phrPatient, patientID]);

  return (
    <Box>
      {phrPatient === null || isLoading ? (
        <CenteredLoadingIndicator />
      ) : !country || !storeID ? (
        <div>{t('Collect Country')}</div>
      ) : (
        <PHRMedicationsComponent
          phrPatient={phrPatient}
          renderHeader={renderHeader}
          inverse={true}
          displayPrescriptions={true}
          phrConsultID={phrConsultID}
          country={country}
        />
      )}
    </Box>
  );
});

export default MedicationsComponent;
