import { useEffect, useState } from 'react';
import { AuthClient, Tag, TagsClient, TagTypeEnum, TagXref } from '../../../../../../../generated';
import { httpRequest } from '../../../../../../../utils';

const useCareTypeTags = (careTypeTagId?: string) => {
  const [careTypeTags, setCareTypeTags] = useState<Tag[]>();
  const [careTypeTagXrefs, setCareTypeTagXrefs] = useState<TagXref[]>();
  const [isLoadingCareTypeTags, setLoadingCareTypeTags] = useState<boolean>(false);
  const [careTypeTagsLoadingError, setCareTypeTagsLoadingError] = useState<string>('');

  useEffect(() => {
    if (!careTypeTagId) return;

    const getTags = async () => {
      setCareTypeTagsLoadingError('');
      setLoadingCareTypeTags(true);
      try {
        const tagsClient = new TagsClient(new AuthClient());
        const tags = await httpRequest(() => tagsClient.allByTagType(TagTypeEnum.CareType));

        const xRefTags = await httpRequest(() => tagsClient.tagsGet(careTypeTagId));

        const careTypeTagXrefs = xRefTags.tagXrefs
          ?.filter((tagXref) => tagXref.toTag !== undefined)
          .map((tagXref) => tagXref);

        const sortedCareTypeTags = tags?.sort((a, b) => a.description.localeCompare(b.description));

        const sortedCareTypeTagXrefs = careTypeTagXrefs?.sort((a, b) =>
          a.toTag!.description.localeCompare(b.toTag!.description),
        );

        setCareTypeTagXrefs(sortedCareTypeTagXrefs || []);
        setCareTypeTags(sortedCareTypeTags || []);
      } catch (err) {
        setCareTypeTagsLoadingError(err as any);
      }
      setLoadingCareTypeTags(false);
    };

    getTags();
  }, [careTypeTagId]);

  return { careTypeTags, careTypeTagXrefs, isLoadingCareTypeTags, careTypeTagsLoadingError };
};

export default useCareTypeTags;
