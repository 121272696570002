import { Box, Heading, Text } from '@chakra-ui/react';

interface IProps {
  title: string | undefined;
  description: string | undefined;
  currentStep: number;
  totalQuestionCount: number;
}

export const QuestionHeader = ({ title, description, currentStep, totalQuestionCount }: IProps) => {
  return (
    <Box mb={4}>
      <Heading as="h2" size="md">
        {title && `${title}`}
      </Heading>
      <Text color="gray.400">{description && `${description} `}</Text>
      <Text fontSize="24px" fontWeight={700} color="gray.600">
        <Text as="span" fontSize="28px" color="orange.400">
          {currentStep}
        </Text>
        /{totalQuestionCount}
      </Text>
    </Box>
  );
};
