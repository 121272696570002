import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { BorderRadius, SpacingRelated } from '../../../../constants';

export const Arrow = styled(FontAwesomeIcon)`
  color: white;
  font-size: 28px;
`;

export const Button = styled.button`
  color: white;
  margin-top: ${SpacingRelated.Regular};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: ${BorderRadius.Buttons};
  width: 86px;
  height: 66px;
  background-color: ${(props) => props.theme.FontColors.Primary};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;
