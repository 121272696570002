import { useQuery } from 'react-query';
import { AuthClient, PatientInsurance, PatientInsurancesClient } from '../../../generated';
import { getTime, httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

interface IProps {
  patientID: string;
}

export const usePatientInsurancesByPatientID = ({ patientID }: IProps) => {
  const {
    isFetched: isFetchedPatientInsurances,
    isLoading: isLoadingPatientInsurances,
    data: patientInsurances,
    error: patientInsurancesError,
  } = useQuery<PatientInsurance[], string>(
    [QueryKeys.PatientInsurancesByPatientID, { patientID }],
    async ({ queryKey }) => {
      // @ts-ignore
      const [, { patientID }] = queryKey;
      const insurances = await httpRequest(() =>
        new PatientInsurancesClient(new AuthClient()).byPatientID(patientID),
      );
      return insurances.sort(
        (a: PatientInsurance, b: PatientInsurance) => getTime(b.expiryDate) - getTime(a.expiryDate),
      );
    },
  );

  return {
    isFetchedPatientInsurances,
    isLoadingPatientInsurances,
    patientInsurances,
    patientInsurancesError,
  };
};
