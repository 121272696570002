import { useQuery } from 'react-query';
import { AuthClient, Tag, TagsClient } from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

type UseTagsByIDProps = {
  tagID?: string;
};

export const useTagsByID = ({ tagID }: UseTagsByIDProps) => {
  const {
    isLoading: isLoadingTags,
    data: tags,
    error: tagsError,
  } = useQuery<Tag, string>(
    [QueryKeys.TagsByID, { tagID }],
    async () => httpRequest(() => new TagsClient(new AuthClient()).tagsGet(tagID!)),
    { enabled: !!tagID },
  );

  return {
    isLoadingTags,
    tags,
    tagsError,
  };
};
