import { AuthClient, IProvider, Provider, ProvidersClient } from '../../generated';
import { httpRequest } from '../../utils';
import { refreshToken } from '../currentUser/currentUserCreators';
import { OHThunkResult } from '../root-reducers';
import { getProviderActions, updateProviderProfileActions } from './providerActions';

export function getProvider(userDetailID: string): OHThunkResult<Promise<Provider>> {
  return async (dispatch) => {
    return new Promise<Provider>(async (resolve) => {
      dispatch(getProviderActions.request());
      const providersClient = new ProvidersClient(new AuthClient());
      try {
        const result = await httpRequest(() => providersClient.byUserDetailID(userDetailID));
        dispatch(getProviderActions.success(result));
        resolve(result);
      } catch (err) {
        dispatch(getProviderActions.failure(err as any));
      }
    });
  };
}

export function updateProviderProfile(data: IProvider): OHThunkResult<Promise<void>> {
  return async (dispatch) => {
    return new Promise<void>(async (resolve) => {
      dispatch(updateProviderProfileActions.request());
      const providersClient = new ProvidersClient(new AuthClient());
      try {
        const provider = new Provider();
        provider.init(data);
        await httpRequest(() => providersClient.providersPut(provider));
        dispatch(refreshToken());
        dispatch(updateProviderProfileActions.success());
        resolve();
      } catch (err) {
        dispatch(updateProviderProfileActions.failure(err as any));
      }
    });
  };
}
