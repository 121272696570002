import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { DashboardRoutes, PhoneEventsEnum } from '../../constants';
import { AppState } from '../../store/root-reducers';
import { Paragraph } from '../common/Paragraph';
import Modal from './Modal';

const Title = styled.h2`
  margin-top: 0;
  color: ${(props) => props.theme.Colors.Primary};
`;

const LogoWrapper = styled.div`
  /* background-color: #dedede; */
  border-radius: 50%;
  padding: 10px;
  width: 68px;
  height: 68px;
`;

interface IProps {
  toggle: () => void;
  isOpen: boolean;
}

const EmergencyModal = ({ toggle, isOpen }: IProps) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  const onCancel = () => {
    if (currentUser?.isInMobileView && currentUser.mobileSystem === 'android') {
      // @ts-ignore
      AndroidResult.callback(PhoneEventsEnum.CANCEL_BOOKING);
    } else if (currentUser?.isInMobileView && currentUser.mobileSystem === 'ios') {
      // @ts-ignore
      webkit.messageHandlers.cancelBookingCallbackHandler.postMessage(
        PhoneEventsEnum.CANCEL_BOOKING,
      );
    } else {
      dispatch(push(DashboardRoutes.Dashboard));
    }
  };

  return (
    <Modal
      title={
        <LogoWrapper>
          <img width="48" height="48" alt="" src={process.env.REACT_APP_COMPANY_LOGO_ICON} />
        </LogoWrapper>
      }
      isOpen={isOpen}
      cancelText="Cancel Booking"
      submitText="Continue Booking Visit"
      onCancel={() => onCancel()}
      onSubmit={toggle}
    >
      <Title>Is this an Emergency?</Title>
      <Paragraph>
        {process.env.REACT_APP_COMPANY_NAME} is <b>NOT</b> intended to be used for medical or mental
        health emergencies.
      </Paragraph>
      <Paragraph>
        If you believe you are experiencing an emergency, please exit the Platform and proceed
        immediately to your nearest emergency services.
      </Paragraph>
      <Paragraph>
        By clicking <b>Continue Booking Visit</b>, I consent to see a Health Provider via Virtual
        Consultation Services.
      </Paragraph>
    </Modal>
  );
};

export { EmergencyModal };
