import { Flex, Text } from '@chakra-ui/react';
import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { object, string } from 'yup';

import { PrimaryButton } from '../../../../../components/Buttons';
import { Form, FormTextArea } from '../../../../../components/forms';
import { AddressBook, IPHRConsult } from '../../../../../generated';
import { SelectProvider } from './SelectProvider';

import { CenteredLoadingIndicator } from '../../../../../components/LoadingIndicator';
import { GLOBAL_MAX_TEXT_LENGTH } from '../../../../../constants';
import { useGenerateNote } from '../../../../../services/documents/mutations/useGenerateNote';
import { usePhrNotesPost } from '../../../../../services/notes/mutations/usePhrNotesPost';
import { constructReferralNote } from '../../utils/constructReferralNote';

interface IProps {
  consult?: IPHRConsult;
  onSentReferral: () => void;
}

export const SendReferral = ({ consult, onSentReferral }: IProps) => {
  const [selectedAddress, setSelectedAddress] = useState<AddressBook | undefined>();
  const [error, setError] = useState('');
  const [isSendingNote, setIsSendingNote] = useState(false);

  const phrNotesPost = usePhrNotesPost();
  const generateNote = useGenerateNote();

  const sendReferralNote = useCallback(
    async (reason: string) => {
      if (!selectedAddress || selectedAddress.userDetailID.startsWith('0000')) {
        setError('You must select a valid provider to send this referral to');
      } else {
        setError('');
        setIsSendingNote(true);

        try {
          const phrNote = constructReferralNote(consult!, selectedAddress?.userDetailID, reason);

          const createdNoteID = await phrNotesPost.mutateAsync(phrNote);

          if (createdNoteID) {
            const generatedId = await generateNote.mutateAsync({
              patientID: consult?.patient?.patientID ?? '',
              phrNoteID: createdNoteID,
            });

            if (generatedId) {
              setIsSendingNote(false);
              onSentReferral();
            }
          }
        } catch (e) {
          setError('An error occured while sending your note');
        }
      }
    },
    [consult, selectedAddress, onSentReferral, generateNote, phrNotesPost],
  );

  if (isSendingNote) return <CenteredLoadingIndicator />;

  return (
    <React.Fragment>
      <SelectProvider
        consult={consult}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
      />

      <Formik
        initialValues={{
          reason: '',
        }}
        validationSchema={object({
          reason: string().max(
            GLOBAL_MAX_TEXT_LENGTH,
            `Note can't exceed ${GLOBAL_MAX_TEXT_LENGTH} characters`,
          ),
        })}
        onSubmit={({ reason }) => {
          sendReferralNote(reason);
        }}
      >
        <Form error={error}>
          <Text mb={4} color="#313233">
            Reason For Referral
          </Text>
          <FormTextArea
            name="reason"
            height="219px"
            borderRadius="xl"
            mb={8}
            resize="none"
            placeholder="Enter reason for referral"
          />
          <Flex justifyContent="flex-end">
            <PrimaryButton bgColor="#3E1191" size="lg" fontSize="md" type="submit">
              Send Referral
            </PrimaryButton>
          </Flex>
        </Form>
      </Formik>
    </React.Fragment>
  );
};
