import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  AddressBook,
  Country,
  IPatient,
  Order,
  OrderItem,
  PatientOrder,
  PatientOrderFinalizeResultDetail,
  PatientOrderItem,
  SendToEnum,
  StoreTypeEnum,
} from '../../../../generated';
import { AppState } from '../../../../store/root-reducers';
import { getStrippedHtmlTags } from '../../../../utils/getStrippedHtmlTags';
import { errorToastr } from '../../../../utils/toastr';

import { arrayHasData } from '../../consultMedications/utils';

import { OrdersTypeEnum } from '../enums';
import {
  addressBookGet,
  countryDefaultStorePost,
  finalizeOrders,
  getCountryDefaultStoreDetails,
  getFreshPatientOrderItem,
  getInitializedOrder,
  getSendToDetailsForAddress,
  getUpdatedPatientOrder,
  patientOrderGet,
  patientOrderPost,
  patientOrderPut,
} from '../utils';

type UseOrderModalProps = {
  patientID: string;
  countryID: string;
  country: Country;
  phrConsultID?: string;
  patient?: IPatient;
  onOpenNotificationModal?: (content: ReactElement | string[]) => void;
  handleOpenSuccessScreen?: (resultDetails?: PatientOrderFinalizeResultDetail[]) => void;
};

type OrderItemDataToRemoveType = {
  patientOrderItem: PatientOrderItem;
  orderTypeEnum: OrdersTypeEnum;
};

export const getSendSubject = (patient?: IPatient | null) => `Order for ${patient?.fullName}`;
export const getSendMessage = (patient?: IPatient | null) =>
  `Please fullfil this Diagnostic Order for ${patient?.fullName}`;

const useOrderModal = (props: UseOrderModalProps) => {
  const provider = useSelector((state: AppState) => state.providerState.provider);

  const [patientOrderItemDataToRemove, setPatientOrderItemDataToRemove] = useState<
    OrderItemDataToRemoveType | undefined
  >();

  const [expressLabOrder, setExpressLabOrder] = useState<PatientOrder | undefined>();
  const [selectedLabOrder, setSelectedLabOrder] = useState<PatientOrder | undefined>();
  const [patientOnlyOrder, setPatientOnlyOrder] = useState<PatientOrder | undefined>();

  const [sendCopyToPatient, setSendCopyToPatient] = useState(false);
  const [expressLabAddress, setExpressLabAddress] = useState<AddressBook | undefined>();
  const [selectedLabAddress, setSelectedLabAddress] = useState<AddressBook | undefined>();

  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>();
  const [selectedPatientOrderItem, setSelectedPatientOrderItem] = useState<
    PatientOrderItem | undefined
  >();
  const [selectedPatientOrderTypeEnum, setSelectedPatientOrderTypeEnum] = useState<
    OrdersTypeEnum | undefined
  >();

  const [orderItemList, setOrderItemList] = useState<OrderItem[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const providerID = provider?.providerID;

  const selectedLabCountry = selectedLabAddress?.country;

  const countryIDToUse = selectedLabCountry?.countryID || props.countryID;

  const selectedOrderID = selectedOrder?.orderID;

  const ordersAdded =
    arrayHasData(selectedLabOrder?.patientOrderItems) ||
    arrayHasData(expressLabOrder?.patientOrderItems) ||
    arrayHasData(patientOnlyOrder?.patientOrderItems);

  const initializeGlobalOrder = useCallback(async () => {
    if (!providerID || !props.patient || !selectedOrderID) return;

    const defaultPatientOrder = getInitializedOrder({
      patient: props.patient,
      patientID: props.patientID,
      providerID: providerID,
      phrConsultID: props.phrConsultID,
      orderID: selectedOrderID,
      country: props.country,
    });

    const patientOrderID = await patientOrderPost(defaultPatientOrder);

    const patientOrderData = await patientOrderGet(patientOrderID);

    return patientOrderData;
  }, [
    props.country,
    props.patient,
    props.patientID,
    props.phrConsultID,
    providerID,
    selectedOrderID,
  ]);

  const initializeExpressLabOrder = useCallback(async () => {
    const patientOrder = await initializeGlobalOrder();

    setExpressLabOrder(patientOrder);
  }, [initializeGlobalOrder]);

  const initializeSelectedLabOrder = useCallback(async () => {
    const patientOrder = await initializeGlobalOrder();

    setSelectedLabOrder(patientOrder);
  }, [initializeGlobalOrder]);

  const initializePatientOnlyOrder = useCallback(async () => {
    const patientOrder = await initializeGlobalOrder();

    setPatientOnlyOrder(patientOrder);
  }, [initializeGlobalOrder]);

  const updateExpressLabOrder = useCallback(async () => {
    if (!expressLabOrder || !expressLabAddress) return;

    const patientOrder = getUpdatedPatientOrder({
      patientOrder: expressLabOrder,
    });

    // Both are null and undefined respectively, we just want to check if they are roughly the same
    // eslint-disable-next-line eqeqeq
    if (expressLabOrder.sendToID == expressLabAddress.associatedID) return;

    patientOrder.sendToID = expressLabAddress.associatedID;
    patientOrder.sendToEnum = SendToEnum.Store;
    patientOrder.storeID = expressLabAddress.associatedID;
    patientOrder.sendSubject = getSendSubject(props.patient);
    patientOrder.sendMessage = getSendMessage(props.patient);

    const newPatientOrderID = await patientOrderPut(patientOrder);

    const newPatientOrder = await patientOrderGet(newPatientOrderID);

    setExpressLabOrder(newPatientOrder);
  }, [expressLabAddress, expressLabOrder, props.patient]);

  const updateSelectedLabOrder = useCallback(async () => {
    if (!selectedLabOrder) return;

    const patientOrder = getUpdatedPatientOrder({
      patientOrder: selectedLabOrder,
    });

    const sendToDetails = getSendToDetailsForAddress(selectedLabAddress);

    // Both are null and undefined respectively, we just want to check if they are roughly the same
    // eslint-disable-next-line eqeqeq
    if (selectedLabOrder.sendToID == sendToDetails.sendToID) return;

    patientOrder.sendToID = sendToDetails.sendToID;
    patientOrder.sendToEnum = sendToDetails.sendToEnum;
    patientOrder.storeID =
      sendToDetails.sendToEnum === SendToEnum.Store ? sendToDetails.sendToID : undefined;

    patientOrder.sendSubject = getSendSubject(props.patient);
    patientOrder.sendMessage = getSendMessage(props.patient);

    const newPatientOrderID = await patientOrderPut(patientOrder);

    const newPatientOrder = await patientOrderGet(newPatientOrderID);

    setSelectedLabOrder(newPatientOrder);
  }, [props.patient, selectedLabAddress, selectedLabOrder]);

  const updatePatientOnlyOrder = useCallback(async () => {
    if (!patientOnlyOrder || !props.patient) return;

    const patientOrder = getUpdatedPatientOrder({
      patientOrder: patientOnlyOrder,
    });

    if (patientOnlyOrder.sendToID === props.patient.userDetailID) return;

    patientOrder.sendToID = props.patient.userDetailID;
    patientOrder.sendToEnum = SendToEnum.UserDetail;
    patientOrder.sendCopyToPatient = sendCopyToPatient;
    patientOrder.sendSubject = `Order for ${props.patient.fullName}`;
    patientOrder.sendMessage = `Order for ${props.patient.fullName}`;

    const newPatientOrderID = await patientOrderPut(patientOrder);

    const newPatientOrder = await patientOrderGet(newPatientOrderID);

    setPatientOnlyOrder(newPatientOrder);
  }, [props.patient, patientOnlyOrder, sendCopyToPatient]);

  const getOrderToUse = (orderItem: OrderItem | null) => {
    let orderToUse = {
      type: OrdersTypeEnum.PatientOnly,
      data: patientOnlyOrder,
    };

    if (
      orderItem?.storeID === selectedLabAddress?.associatedID ||
      !!selectedLabAddress?.addressBookID
    ) {
      return (orderToUse = {
        type: OrdersTypeEnum.SelectedLab,
        data: selectedLabOrder,
      });
    }

    if (orderItem?.storeID === expressLabAddress?.associatedID) {
      return (orderToUse = {
        type: OrdersTypeEnum.ExpressLab,
        data: expressLabOrder,
      });
    }

    return orderToUse;
  };

  const setOrderToUpdate = (orderTypeEnum: OrdersTypeEnum, patientOrder: PatientOrder) => {
    switch (orderTypeEnum) {
      case OrdersTypeEnum.SelectedLab:
        setSelectedLabOrder(patientOrder);
        break;
      case OrdersTypeEnum.ExpressLab:
        setExpressLabOrder(patientOrder);
        break;
      case OrdersTypeEnum.PatientOnly:
      default:
        setPatientOnlyOrder(patientOrder);
    }
  };

  const getOrderDataFromType = (orderTypeEnum: OrdersTypeEnum) => {
    switch (orderTypeEnum) {
      case OrdersTypeEnum.SelectedLab:
        return {
          type: orderTypeEnum,
          data: selectedLabOrder,
        };
      case OrdersTypeEnum.ExpressLab:
        return {
          type: orderTypeEnum,
          data: expressLabOrder,
        };
      case OrdersTypeEnum.PatientOnly:
      default:
        return {
          type: orderTypeEnum,
          data: patientOnlyOrder,
        };
    }
  };

  const handleAddPatientOrder = async (
    orderItem: OrderItem | null,
    patientOrderItem: PatientOrderItem,
    additionalInformation?: string,
    orderTypeEnum?: OrdersTypeEnum,
  ) => {
    try {
      setIsLoading(true);
      const orderToUse = orderTypeEnum
        ? getOrderDataFromType(orderTypeEnum)
        : getOrderToUse(orderItem);

      if (!orderToUse.data) return;

      const patientOrder = getUpdatedPatientOrder({
        patientOrder: orderToUse.data,
      });

      patientOrderItem.patientOrderID = patientOrder.patientOrderID;
      patientOrderItem.additionalInformation = additionalInformation;

      // Add order to existing  patient order list
      const patientOrderItems = [...(patientOrder.patientOrderItems ?? []), patientOrderItem];

      patientOrder.patientOrderItems = patientOrderItems;

      const newPatientOrderID = await patientOrderPut(patientOrder);

      const newPatientOrder = await patientOrderGet(newPatientOrderID);

      setOrderToUpdate(orderToUse.type, newPatientOrder);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemovePatientOrder = async (
    orderTypeEnum: OrdersTypeEnum,
    patientOrderItem: PatientOrderItem | null,
    all = false,
  ) => {
    try {
      setIsLoading(true);
      const orderToUse = getOrderDataFromType(orderTypeEnum);

      if (!orderToUse.data) return;

      const patientOrder = getUpdatedPatientOrder({
        patientOrder: orderToUse.data,
      });

      // Set order to be removed isDeleted prop to true
      const patientOrderItems = patientOrder.patientOrderItems?.map((order) => {
        order.isDeleted = all ? true : order.orderItemID === patientOrderItem?.orderItemID;
        return order;
      });

      patientOrder.patientOrderItems = patientOrderItems;

      const newPatientOrderID = await patientOrderPut(patientOrder);

      const newPatientOrder = await patientOrderGet(newPatientOrderID);

      setOrderToUpdate(orderToUse.type, newPatientOrder);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendPatientOrderToPatient = async (
    orderTypeEnum: OrdersTypeEnum,
    patientOrderItem: PatientOrderItem,
  ) => {
    try {
      setIsLoading(true);

      if (!patientOnlyOrder) return;

      const newPatientOrderItem = new PatientOrderItem();
      newPatientOrderItem.init(getFreshPatientOrderItem(patientOrderItem));

      // Remove patient order item from original order list
      await handleRemovePatientOrder(orderTypeEnum, patientOrderItem);

      // Add patient order item to patient only order
      await handleAddPatientOrder(
        null,
        newPatientOrderItem,
        newPatientOrderItem.additionalInformation,
        OrdersTypeEnum.PatientOnly,
      );
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnSendPatientOrderToPatient = async (
    orderTypeEnum: OrdersTypeEnum,
    patientOrderItem: PatientOrderItem,
  ) => {
    try {
      setIsLoading(true);

      if (!patientOnlyOrder) return;

      // Add order to original order list
      const orderItem = orderItemList.find(
        (orderItem) => orderItem.orderItemID === patientOrderItem.orderItemID,
      );

      const newPatientOrderItem = new PatientOrderItem();
      newPatientOrderItem.init(getFreshPatientOrderItem(patientOrderItem));

      // Remove phr order from patient only order list
      await handleRemovePatientOrder(orderTypeEnum, patientOrderItem);

      // Add phr order to original order list
      await handleAddPatientOrder(
        orderItem || null,
        newPatientOrderItem,
        newPatientOrderItem.additionalInformation,
      );
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetPatientOrderItemDataToRemove = (
    patientOrderItem: PatientOrderItem,
    orderTypeEnum: OrdersTypeEnum,
  ) => {
    setPatientOrderItemDataToRemove({
      orderTypeEnum,
      patientOrderItem,
    });
  };

  const handleUpdateSelectedOrder = (order: Order) => setSelectedOrder(order);

  const handleClearPatientOrderDataToRemove = () => setPatientOrderItemDataToRemove(undefined);

  const handleUpdateIsLoading = (loadingState: boolean) => setIsLoading(loadingState);

  const handleUpdateSendCopyToPatient = (sendCopyToPatientState: boolean) =>
    setSendCopyToPatient(sendCopyToPatientState);

  const handleUpdateExpressLabAddress = (address: AddressBook) => setExpressLabAddress(address);

  const handleUpdateSelectedLabAddress = (address: AddressBook) => setSelectedLabAddress(address);

  const handleRemoveSelectedLabAddress = async () => {
    try {
      await handleRemovePatientOrder(OrdersTypeEnum.SelectedLab, null, true);
      setSelectedLabAddress(undefined);
    } catch (error) {}
  };

  const handleUpdateOrderItemList = useCallback(
    (orderItemList: OrderItem[]) => setOrderItemList(orderItemList),
    [],
  );

  const handleUpdateSelectedPatientOrderItem = useCallback(
    (orderItem?: PatientOrderItem, orderTypeEnum?: OrdersTypeEnum) => {
      setSelectedPatientOrderItem(orderItem);
      setSelectedPatientOrderTypeEnum(orderTypeEnum);
    },
    [],
  );

  const clearAllOrders = () => {
    setExpressLabOrder(undefined);
    setSelectedLabOrder(undefined);
    setPatientOnlyOrder(undefined);
    setSelectedPatientOrderItem(undefined);
    setSelectedOrder(undefined);
  };

  const handleUpdatePatientOrderExtras = async (
    partialPatientOrder: Partial<PatientOrder>,
    orderTypeEnum: OrdersTypeEnum,
    patientOrder?: PatientOrder,
  ) => {
    if (!patientOrder) return;

    const updatedPatientOrder = getUpdatedPatientOrder({
      patientOrder,
      rest: partialPatientOrder,
    });

    const newPatientOrderID = await patientOrderPut(updatedPatientOrder);

    const newPatientOrder = await patientOrderGet(newPatientOrderID);

    setOrderToUpdate(orderTypeEnum, newPatientOrder);
  };

  const handleUpdatePatientPartialProperty = async (
    partialProperty: Partial<PatientOrder>,
    setLoading = false,
  ) => {
    try {
      if (setLoading) setIsLoading(true);

      await handleUpdatePatientOrderExtras(
        partialProperty,
        OrdersTypeEnum.SelectedLab,
        selectedLabOrder,
      );
      await handleUpdatePatientOrderExtras(
        partialProperty,
        OrdersTypeEnum.ExpressLab,
        expressLabOrder,
      );
      await handleUpdatePatientOrderExtras(
        partialProperty,
        OrdersTypeEnum.PatientOnly,
        patientOnlyOrder,
      );
    } catch (error) {
      errorToastr({ description: getStrippedHtmlTags(error as string), isClosable: true });
    } finally {
      if (setLoading) setIsLoading(false);
    }
  };

  const handleFinalizeOrder = async () => {
    try {
      setIsLoading(true);

      const notificationMessages: string[] = [];

      const validPatientOrderIDs: string[] = [];

      if (selectedLabOrder && arrayHasData(selectedLabOrder?.patientOrderItems)) {
        validPatientOrderIDs.push(selectedLabOrder.patientOrderID);
      }

      if (expressLabOrder && arrayHasData(expressLabOrder?.patientOrderItems)) {
        validPatientOrderIDs.push(expressLabOrder.patientOrderID);
      }

      if (patientOnlyOrder && arrayHasData(patientOnlyOrder?.patientOrderItems)) {
        validPatientOrderIDs.push(patientOnlyOrder.patientOrderID);
      }

      const result = await finalizeOrders(props.patientID, validPatientOrderIDs);

      result.patientOrderFinalizeResultDetails?.forEach((orderResult) => {
        if (orderResult.message) {
          notificationMessages.push(orderResult.message);
        }
      });

      props.handleOpenSuccessScreen?.(result.patientOrderFinalizeResultDetails);
    } catch (error) {
      setError(error as any);
      errorToastr({ description: getStrippedHtmlTags(error as string), isClosable: true });
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch default store and store address book
  useEffect(() => {
    (async () => {
      try {
        const countryDefaultStoreDetails = getCountryDefaultStoreDetails({
          countryID: countryIDToUse,
          storeTypeEnum: StoreTypeEnum.Lab,
        });

        const expressLab = await countryDefaultStorePost(countryDefaultStoreDetails);

        const expressLabAddressBook = await addressBookGet(expressLab.storeID);

        setExpressLabAddress(expressLabAddressBook);
      } catch (error) {
      } finally {
      }
    })();
  }, [countryIDToUse]);

  // Initialize global orders
  useEffect(() => {
    // We need to select an initial order type to use
    if (!selectedOrderID) return;
    (async () => {
      try {
        await initializeExpressLabOrder();
        await initializeSelectedLabOrder();
        await initializePatientOnlyOrder();
      } catch (error) {
        setError(error as any);
      }
    })();
  }, [
    initializeExpressLabOrder,
    initializeSelectedLabOrder,
    initializePatientOnlyOrder,
    selectedOrderID,
  ]);

  // Update global orders
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await updateExpressLabOrder();
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [updateExpressLabOrder]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await updateSelectedLabOrder();
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [updateSelectedLabOrder]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await updatePatientOnlyOrder();
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [updatePatientOnlyOrder]);

  return {
    expressLabOrder,
    selectedLabOrder,
    patientOnlyOrder,
    isLoading,
    handleUpdateIsLoading,
    generalOrderError: error,
    sendCopyToPatient,
    handleUpdateSendCopyToPatient,
    expressLabAddress,
    handleUpdateExpressLabAddress,
    selectedLabAddress,
    handleUpdateSelectedLabAddress,
    handleRemoveSelectedLabAddress,
    handleAddPatientOrder,
    handleRemovePatientOrder,
    handleSendPatientOrderToPatient,
    handleUnSendPatientOrderToPatient,
    patientOrderItemDataToRemove,
    handleSetPatientOrderItemDataToRemove,
    handleClearPatientOrderDataToRemove,
    orderItemList,
    handleUpdateOrderItemList,
    handleUpdatePatientPartialProperty,
    handleFinalizeOrder,
    selectedLabCountry,
    handleUpdateSelectedOrder,
    selectedOrder,
    clearAllOrders,
    ordersAdded,
    selectedPatientOrderItem,
    handleUpdateSelectedPatientOrderItem,
    selectedPatientOrderTypeEnum,
  };
};

export { useOrderModal };

export type UseOrderModalReturnType = ReturnType<typeof useOrderModal>;
