import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { MarketingCampaign } from '../../generated';
import {
  ActiveMarketingCampaignActionTypes,
  getActiveMarketingCampaignsActions,
} from './activeMarketingCampaignsActions';

const activeMarketingCampaigns = createReducer<
  MarketingCampaign[],
  ActiveMarketingCampaignActionTypes
>([]).handleAction(
  [getActiveMarketingCampaignsActions.success],
  (_state, action) => action.payload,
);

const isLoading = createReducer<boolean, ActiveMarketingCampaignActionTypes>(false)
  .handleAction([getActiveMarketingCampaignsActions.request], () => true)
  .handleAction(
    [getActiveMarketingCampaignsActions.success, getActiveMarketingCampaignsActions.failure],
    () => false,
  );

const error = createReducer<string, ActiveMarketingCampaignActionTypes>('')
  .handleAction([getActiveMarketingCampaignsActions.failure], (_state, action) => action.payload)
  .handleAction(
    [getActiveMarketingCampaignsActions.success, getActiveMarketingCampaignsActions.request],
    () => '',
  );

export interface IActiveMarketingCampaignsState {
  activeMarketingCampaigns: MarketingCampaign[];
  error: string;
  isLoading: boolean;
}

export default combineReducers<IActiveMarketingCampaignsState>({
  activeMarketingCampaigns,
  error,
  isLoading,
});

export enum CampaignPropertiesEnum {
  BannerImage = 'BannerImage',
  Disclaimer = 'Disclaimer',
  Heading = 'Heading',
  SubHeading = 'SubHeading',
  TermsOfUse = 'TermsOfUse',
  Description = 'Description',
  PreviewButtonText = 'PreviewButtonText',
  PreviewDescription = 'PreviewDescription',
  PreviewTitle = 'PreviewTitle',
  PreviewBannerImage = 'PreviewBannerImage',
  WelcomeDescription = 'WelcomeDescription',
  WelcomeButtonText = 'WelcomeButtonText',
  WelcomeBannerImage = 'WelcomeBannerImage',
  QRCodeHeading = 'QRCodeHeading',
  QRCodeSubHeading = 'QRCodeSubHeading',
  QRCodeVerificationText = 'QRCodeVerificationText',
  InviteeDescription = 'InviteeDescription',
  InviteeButtonText = 'InviteeButtonText',
  InviteeBannerImage = 'InviteeBannerImage',
}
