import { ChakraProvider, VStack } from '@chakra-ui/react';
import { CometChat } from '@cometchat-pro/chat';
import '@fontsource/inter';
import '@fontsource/open-sans';
import '@fontsource/open-sans/300.css';
import '@fontsource/poppins';
import '@fontsource/rubik';
import '@fontsource/rubik/300.css';
import '@fontsource/rubik/500.css';
import '@fontsource/rubik/600.css';
import '@fontsource/rubik/700.css';
import '@fontsource/ubuntu';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import { ConnectedRouter } from 'connected-react-router';
import 'focus-visible/dist/focus-visible';
import { createBrowserHistory } from 'history';
import { Suspense } from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import reactGA4 from 'react-ga4';
import { pdfjs } from 'react-pdf';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import 'whatwg-fetch';
import './assets/styles/app.scss';
import './assets/styles/themes/outpost/outpost-app.scss';
import { CenteredLoadingIndicator } from './components/LoadingIndicator';
import { ChatSettingsEnum } from './constants';
import './i18n';
import './importIcons';
import Root from './routes';
import { unregister } from './serviceWorker';
import { initializeStore } from './store';
import { setWebviewUserInterceptor } from './store/middlewares/webViewMiddleware';
import { OutpostTheme } from './themes/outpost';
import { theme } from './themes/theme';
import { pageview } from './hooks/useGoogleAnalytics';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// @ts-ignore
window.signalStore = {};

declare global {
  interface Array<T> {
    diff(o: T): Array<T>;
    contains(o: T): boolean;
  }

  interface Uint8Array {
    toByteArray(): Array<any>;
  }

  interface Window {
    signalStore: any;
    store: any;
    fcWidget: any;
    queryClient: any;
    fwcrm: any;
    _log: any;
  }
}

// eslint-disable-next-line no-extend-native
Array.prototype.contains = function (needle) {
  for (const i in this) {
    if (typeof this[i] === 'string' && typeof needle === 'string') {
      if (this[i].toLowerCase() === needle.toLowerCase()) return true;
    } else {
      if (this[i] === needle) return true;
    }
  }
  return false;
};

// eslint-disable-next-line no-extend-native
Uint8Array.prototype.toByteArray = function () {
  const byteArray: Array<any> = [];
  Object.keys(this).forEach((key) => {
    const byteForKey = this[key];
    byteArray.push(byteForKey);
  });
  return byteArray;
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
const history = createBrowserHistory();

history.listen((location) => {
  reactGA4.set({ page: location.pathname });
  pageview(location.pathname);
});

const store = initializeStore(history);
window.store = store;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
window.queryClient = queryClient;

const appSettings = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(ChatSettingsEnum.REACT_APP_COMETCHAT_REGION)
  .autoEstablishSocketConnection(true)
  .build();

const render = async () => {
  setWebviewUserInterceptor(store);
  try {
    await CometChat.init(ChatSettingsEnum.REACT_APP_COMETCHAT_APP_ID, appSettings);
  } catch (err) {}
  ReactDOM.render(
    <ThemeProvider theme={OutpostTheme}>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            {/* @ts-ignore */}
            <ConnectedRouter history={history}>
              <Suspense
                fallback={
                  <VStack height="100vh" width="100%" justifyContent="center" alignItems="center">
                    <CenteredLoadingIndicator />
                  </VStack>
                }
              >
                <Root />
              </Suspense>
            </ConnectedRouter>
          </Provider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ChakraProvider>
    </ThemeProvider>,
    document.getElementById('root') || document.createElement('div'),
  );
};

render();
