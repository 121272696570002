import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

type UseInfiniteScrollProperty = {
  hasNextPage?: boolean;
  fetchNextPage: () => void;
};

const useInfiniteScroll = (props: UseInfiniteScrollProperty) => {
  const { hasNextPage, fetchNextPage } = props;

  const { ref, inView } = useInView();

  useEffect(() => {
    if (!ref) return;
    if (!hasNextPage) return;

    if (!inView) return;

    fetchNextPage();
  }, [fetchNextPage, hasNextPage, inView, ref]);

  return {
    inView,
    ref,
  };
};

export { useInfiniteScroll };
