import { useMutation } from 'react-query';
import { AuthClient, PHRNote, PHRNotesClient } from '../../../generated';
import { httpRequest } from '../../../utils';

export const usePhrNotesPost = () => {
  const phrNotesPost = useMutation<string, string, PHRNote, string>(async (phrNote: PHRNote) =>
    httpRequest(() => new PHRNotesClient(new AuthClient()).pHRNotesPost(phrNote)),
  );

  return phrNotesPost;
};
