import { Box, Grid, GridItem, Heading } from '@chakra-ui/react';
import { Formik } from 'formik';
import moment from 'moment';
import { date, object, string } from 'yup';
import { ButtonRow, PrimaryButton, SecondaryButton } from '../../../../components/Buttons';
import {
  Form,
  FormInputControl,
  FormMobisPicker,
  FormPhoneInputControl,
  FormSelectComponent,
  TimezonePicker,
} from '../../../../components/forms';
import { AddressPicker } from '../../../../components/forms/AddressPicker/AddressPicker';
import { AddressPickerProvider } from '../../../../components/forms/AddressPicker/AddressPickerProvider';
import { CenteredLoadingIndicator } from '../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../components/ServerValidationBox';
import {
  GenderEnum,
  IPatient,
  Language,
  MaritalStatusEnum,
  PatientInsurance,
  TimeZone,
} from '../../../../generated';
import { enumToOptions } from '../../../../utils';

interface IProps {
  patient: IPatient;
  cancel: () => void;
  primaryInsurance: PatientInsurance | undefined;
  error?: string;
  loading: boolean;
  isLoadingTimeZones: boolean;
  isLoadingLanguages: boolean;
  languages: Language[] | undefined;
  timeZones: TimeZone[] | undefined;
  updatePatientDetails: (values: any) => void;
}

export const PatientUpdateForm = ({
  patient,
  cancel,
  primaryInsurance,
  error,
  loading,
  isLoadingTimeZones,
  isLoadingLanguages,
  languages,
  timeZones,
  updatePatientDetails,
}: IProps) => {
  // This is to ensure the correct day is displayed.  We don't care about timezones for
  // birthdates so everything should be at midnight UTC on the date the user selects
  let birthDate: Date | string = '';
  if (patient?.birthDateString && moment(patient?.birthDateString).isValid()) {
    birthDate = moment(patient?.birthDateString)
      .add(Math.abs(moment(patient?.birthDateString).utcOffset()), 'm')
      .toDate();
  }

  const initialValues = {
    title: patient.title || '',
    firstName: patient.firstName || '',
    middleName: patient.middleName ? patient.middleName : patient.verified ? ' ' : '',
    lastName: patient.lastName || '',
    suffix: patient.suffix || '',
    genderEnum: patient.genderEnum || '',
    timeZoneID: patient.timeZone || '',
    primaryLanguageID: patient.primaryLanguageID || '',
    birthDate: birthDate || '',
    maritalStatusEnum: patient.maritalStatusEnum || '',
    homePhoneNumber: patient.homePhoneNumber || '',
    cellPhoneNumber: patient.cellPhoneNumber || '',
    addressLine1: patient.addressLine1 || '',
    addressLine2: patient.addressLine2 || '',
    provinceStateID: patient.provinceStateID,
    province: patient.province,
    cityTownID: patient.cityTownID,
    city: patient.city,
    postCode: patient.postCode || '',
    emergencyContactCellPhoneNumber: patient.emergencyContactCellPhoneNumber || '',
    emergencyContactHomePhoneNumber: patient.emergencyContactHomePhoneNumber || '',
    emergencyContactName: patient.emergencyContactName || '',
    emergencyContactRelationship: patient.emergencyContactRelationship || '',
    insurance: primaryInsurance?.insuranceNumber || '',
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          firstName: string()
            .required('First Name is required')
            .max(250, 'First Name must be at most 250 characters'),
          middleName: string().max(250, 'Middle Name must be at most 250 characters'),
          lastName: string()
            .required('Last Name is required')
            .max(250, 'Last Name must be at most 250 characters'),
          insurance: string().max(250, 'Insurance number must be at most 250 characters'),
          genderEnum: string().required('Gender is required'),
          primaryLanguageID: string().required('Language is required'),
          birthDate: date()
            .nullable()
            .typeError('Invalid date')
            .max(new Date(), 'Birth Date must be in the past'),
          addressLine1: string().max(250, 'Address Line 1 must be at most 250 characters'),
          addressLine2: string().max(250, 'Address Line 2 must be at most 250 characters'),
          postCode: string().max(50, 'Postal Code must be at most 50 characters'),
          cellPhoneNumber: string().test(
            'required',
            'Either Cell Phone or Home Phone Number is required.',
            function (value) {
              const { homePhoneNumber } = this.parent;
              if (!homePhoneNumber || (homePhoneNumber?.match(/\d/g)?.join('').length ?? 0) < 5) {
                if (!value) {
                  return this.createError({
                    message: `Either Cell Phone or Home Phone Number is required`,
                    path: 'cellPhoneNumber',
                  });
                }
                if ((value?.match(/\d/g)?.join('').length ?? 0) < 5) {
                  return this.createError({
                    message: `Cell Phone Number must be at least 5 digits.`,
                    path: 'cellPhoneNumber',
                  });
                }
              }

              return true;
            },
          ),
          homePhoneNumber: string().test(
            'required',
            'Either Cell Phone or Home Phone Number is required.',
            function (value) {
              const { cellPhoneNumber } = this.parent;
              if (!cellPhoneNumber || (cellPhoneNumber?.match(/\d/g)?.join('').length ?? 0) < 5) {
                if (!value) {
                  return this.createError({
                    message: `Either Cell Phone or Home Phone Number is required`,
                    path: 'homePhoneNumber',
                  });
                }
                if ((value?.match(/\d/g)?.join('').length ?? 0) < 5) {
                  return this.createError({
                    message: `Home Phone Number must be at least 5 digits.`,
                    path: 'homePhoneNumber',
                  });
                }
              }

              return true;
            },
          ),
        })}
        onSubmit={(values) => {
          updatePatientDetails(values);
        }}
      >
        {({ setFieldValue }) => (
          <Form error={error}>
            <AddressPickerProvider
              country={patient.country}
              initialValues={{
                addressLine1: patient.addressLine1,
                addressLine2: patient.addressLine2,
                cityTown: patient.cityTown,
                city: patient.city,
                provinceState: patient.provinceState,
                province: patient.province,
                postCode: patient.postCode,
              }}
            >
              {isLoadingTimeZones || isLoadingLanguages ? (
                <CenteredLoadingIndicator />
              ) : (
                <>
                  <ServerValidationBox message={error} />
                  <Heading size="sm">Basic Details</Heading>
                  <Grid templateColumns={'repeat(8, 1fr)'} gap={4} mt={10}>
                    <GridItem colSpan={{ base: 8, md: 2, sm: 4 }}>
                      <FormInputControl name="title" type="text" label="Title" />
                    </GridItem>
                    <GridItem colSpan={{ base: 8, md: 4, sm: 4 }}>
                      <FormInputControl
                        name="firstName"
                        type="text"
                        label="First Name"
                        formControlProps={{
                          isRequired: true,
                        }}
                      />
                    </GridItem>
                    <GridItem colSpan={{ base: 8, md: 4, sm: 4 }}>
                      <FormInputControl name="middleName" type="text" label="Middle Name" />
                    </GridItem>
                    <GridItem colSpan={{ base: 8, md: 4, sm: 4 }}>
                      <FormInputControl
                        isRequired
                        name="lastName"
                        type="text"
                        label="Last Name"
                        formControlProps={{
                          isRequired: true,
                        }}
                      />
                    </GridItem>
                    <GridItem colSpan={{ base: 8, md: 2, sm: 4 }}>
                      <FormInputControl name="suffix" type="text" label="Suffix" />
                    </GridItem>
                    <GridItem colSpan={{ base: 8, md: 2, sm: 4 }}>
                      <FormSelectComponent
                        formControlProps={{ isRequired: true }}
                        name="genderEnum"
                        options={enumToOptions(GenderEnum)}
                        valueKey="id"
                        labelKey="description"
                        label="Gender"
                        isClearable={false}
                      />
                    </GridItem>
                    <GridItem colSpan={{ base: 8, md: 4, sm: 4 }}>
                      <FormMobisPicker
                        name="birthDate"
                        label="Birth Date"
                        max={moment().toDate()}
                        min={moment().add(-150, 'year').toDate()}
                        formControlProps={{
                          isRequired: true,
                        }}
                        disabled={!!birthDate && patient.verified}
                      />
                    </GridItem>
                    <GridItem colSpan={{ base: 8, md: 4, sm: 4 }}>
                      <FormSelectComponent
                        name="maritalStatusEnum"
                        options={enumToOptions(MaritalStatusEnum)}
                        valueKey="id"
                        labelKey="description"
                        label="Marital Status"
                      />
                    </GridItem>
                    <GridItem colSpan={{ base: 8, md: 4, sm: 4 }}>
                      <Box maxW={['100%', '300px']}>
                        <FormPhoneInputControl
                          name="cellPhoneNumber"
                          label="Cell Phone Number"
                          country={patient?.country?.code.toLocaleLowerCase()}
                        />
                      </Box>
                    </GridItem>
                    <GridItem colSpan={{ base: 8, md: 4, sm: 4 }}>
                      <Box maxW={['100%', '300px']}>
                        <FormPhoneInputControl
                          name="homePhoneNumber"
                          label="Home Phone Number"
                          country={patient?.country?.code.toLocaleLowerCase()}
                        />
                      </Box>
                    </GridItem>
                    <GridItem colSpan={{ base: 8, md: 4, sm: 4 }}>
                      <TimezonePicker
                        isRequired
                        name="timeZoneID"
                        label="Timezone"
                        timezones={timeZones}
                        defaultValueKey={patient.timeZone}
                      />
                    </GridItem>
                    <GridItem colSpan={{ base: 8, md: 4, sm: 4 }}>
                      <FormSelectComponent
                        name="primaryLanguageID"
                        formControlProps={{ isRequired: true }}
                        options={languages || []}
                        valueKey="languageID"
                        labelKey="name"
                        label="Primary Language"
                      />
                    </GridItem>
                  </Grid>

                  {primaryInsurance && (
                    <>
                      <Heading size="sm" mt={10}>
                        Insurance
                      </Heading>

                      <Grid templateColumns={'repeat(6, 1fr)'} gap={4} mt={10}>
                        <GridItem colSpan={{ base: 8, md: 4, sm: 4 }}>
                          <FormInputControl
                            name="insurance"
                            type="text"
                            label={primaryInsurance.insuranceProvider?.insuranceNumberLabel}
                            formControlProps={{
                              isRequired: false,
                            }}
                          />
                        </GridItem>
                      </Grid>
                    </>
                  )}
                  <Heading size="sm" mt={10}>
                    Contact Address
                  </Heading>
                  <AddressPicker setFieldValue={setFieldValue} />

                  <Heading size="sm" mt={10}>
                    Emergency Contact
                  </Heading>

                  <Grid templateColumns={'repeat(6, 1fr)'} gap={4} mt={10}>
                    <GridItem colSpan={{ base: 6, md: 2, sm: 2 }}>
                      <FormInputControl name="emergencyContactName" type="text" label="Full Name" />
                    </GridItem>
                    <GridItem colSpan={{ base: 6, md: 2, sm: 2 }}>
                      <FormPhoneInputControl
                        name="emergencyContactCellPhoneNumber"
                        label="Cell Phone Number"
                        country={patient.country?.code.toLocaleLowerCase()}
                      />
                    </GridItem>
                    <GridItem colSpan={{ base: 6, md: 2, sm: 2 }}>
                      <FormPhoneInputControl
                        name="emergencyContactHomePhoneNumber"
                        label="Home Phone Number"
                        country={patient.country?.code.toLocaleLowerCase()}
                      />
                    </GridItem>
                    <GridItem colSpan={{ base: 6, md: 2, sm: 2 }}>
                      <FormInputControl
                        name="emergencyContactRelationship"
                        type="text"
                        label="Relationship"
                      />
                    </GridItem>
                  </Grid>

                  <ButtonRow>
                    <SecondaryButton
                      onClick={() => {
                        cancel();
                      }}
                    >
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton isLoading={loading} type="submit">
                      Save
                    </PrimaryButton>
                  </ButtonRow>
                </>
              )}
            </AddressPickerProvider>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
