import { Box, useBreakpointValue } from '@chakra-ui/react';
import { Formik, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { date, number, object } from 'yup';
import { TertiaryButton } from '../../../../../components/Buttons';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';
import { Form } from '../../../../../components/forms';
import ServerValidationBox from '../../../../../components/ServerValidationBox';
import {
  BloodGlucoseTypeEnum,
  HeightEnum,
  IPatient,
  PHRTrackerBloodGlucoseDTO,
  PHRTrackerBloodOxygenDTO,
  PHRTrackerBloodPressureDTO,
  PHRTrackerHeadCircumferenceDTO,
  PHRTrackerLengthDTO,
  PHRTrackerPulseDTO,
  PHRTrackerRespiratoryDTO,
  PHRTrackerTemperatureDTO,
  PHRTrackerWeightHeightDTO,
  RolesEnum,
  TemperatureTypeEnum,
  WeightEnum,
} from '../../../../../generated';
import { AppState } from '../../../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../../../store/types';
import {
  convertBloodGlucose,
  convertHeight,
  convertHeight2,
  convertTemperature,
  convertWeight,
} from '../../../../../utils/conversionHelpers';
import { isInRole } from '../../../../../utils/isInRole';
import { errorToastr } from '../../../../../utils/toastr';
import { IUnitSettings } from '../../hooks/usePatientVitals';
import { VitalsTracker } from '../PatientVitalsCard/usePatientVitalsCard';
import { renderVitalsHeader } from '../PatientVitalsTitle/PatientVitalsTitle';
import { IPatientVitalsForm, usePatientVitalsForm } from './usePatientVitalsForm';

interface IProps {
  patient?: IPatient;
  onBack?: () => void;
  toggleView: () => void;
  refreshList: () => void;
  unitSettings: IUnitSettings;
  trackerID?: ReduxPHRCategoryEnum;
  tracker?: VitalsTracker;
}
export const PatientVitalsForm = ({
  patient,
  toggleView,
  refreshList,
  unitSettings,
  trackerID,
  tracker,
}: IProps) => {
  const { initialValues, error, onCreate, renderer } = usePatientVitalsForm(
    trackerID,
    tracker,
    patient?.patientID,
    unitSettings,
  );
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values: IPatientVitalsForm) => {
    try {
      setIsLoading(true);
      await onCreate(values);
      refreshList();
    } catch (error) {
      errorToastr({
        description: JSON.stringify(error),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={object({
        pulse: number()
          .positive(`Heart Rate can't be negative or zero`)
          .typeError(`Heart Rate must be a number`)
          .lessThan(1000, `Heart Rate can't exceed 1000`)
          .test('pulse', 'Heart Rate is required', (value) =>
            trackerID === ReduxPHRCategoryEnum.PHRPulses && !value ? false : true,
          ),
        pulseDate: date()
          .typeError('Invalid date')
          .when('pulse', {
            is: (value) => value && value > 0,
            then: date().required('Pulse Date is required'),
            otherwise: date(),
          }),
        heightCm: number()
          .nullable()
          .positive(`Height can't be negative or zero`)
          .typeError('Height must be a number')
          .lessThan(1000, `Height can't exceed 1000`),
        heightFt: number()
          .nullable()
          .positive(`Height can't be negative or zero`)
          .typeError('Height must be a number')
          .lessThan(100, `Height in feet can't exceed 100`),
        heightIn: number()
          .nullable()
          .positive(`Height can't be negative or zero`)
          .typeError('Height must be a number')
          .lessThan(1000, `Height in inches can't exceed 1000`),
        weight: number()
          .nullable()
          .positive(`Weight can't be negative or zero`)
          .typeError('Weight must be a number')
          .lessThan(1000, `Weight can't exceed 1000`),
        temperature: number()
          .positive(`Temperature can't be negative or zero`)
          .typeError('Temperature must be a number')
          .lessThan(1000, `Temperature can't exceed 1000`)
          .test('temperature', 'Temperature is required', (value) =>
            trackerID === ReduxPHRCategoryEnum.PHRTemperatures && !value ? false : true,
          ),
        temperatureDate: date()
          .typeError('Invalid date')
          .when('temperature', {
            is: (value) => value && value > 0,
            then: date().required('Temperature Date is required'),
            otherwise: date(),
          }),
        respiratory: number()
          .positive(`Respiratory can't be negative or zero`)
          .typeError('Respiratory must be a number')
          .lessThan(1000, `Respiratory can't exceed 1000`)
          .test('respiratory', 'Respiratory is required', (value) =>
            trackerID === ReduxPHRCategoryEnum.PHRRespiratories && !value ? false : true,
          ),
        respiratoryDate: date()
          .typeError('Invalid date')
          .when('respiratory', {
            is: (value) => value && value > 0,
            then: date().required('Respiratory Date is required'),
            otherwise: date(),
          }),
        bloodOxygen: number()
          .positive(`Blood Oxygen (SPO2) can't be negative or zero`)
          .typeError('Blood Oxygen (SPO2) must be a number')
          .lessThan(100, `Blood Oxygen (SPO2) can't exceed 100`)
          .test('bloodOxygen', 'Blood Oxygen (SPO2) is required', (value) =>
            trackerID === ReduxPHRCategoryEnum.PHRBloodOxygens && !value ? false : true,
          ),
        bloodOxygenDate: date()
          .typeError('Invalid date')
          .when('bloodOxygen', {
            is: (value) => value && value > 0,
            then: date().required('Blood Oxygen Date is required'),
            otherwise: date(),
          }),
        bloodGlucose: number()
          .positive(`Blood Glucose can't be negative or zero`)
          .typeError('Blood Glucose must be a number')
          .lessThan(1000, `Blood Glucose can't exceed 1000`)
          .test('bloodGlucose', 'Blood Glucose is required', (value) =>
            trackerID === ReduxPHRCategoryEnum.PHRBloodGlucoses && !value ? false : true,
          ),
        bloodGlucoseDate: date()
          .typeError('Invalid date')
          .when('bloodGlucose', {
            is: (value) => value && value > 0,
            then: date().required('Blood Glucose Date is required'),
            otherwise: date(),
          }),
        headCircumference: number()
          .positive(`Head Circumference can't be negative or zero`)
          .typeError('Head Circumference must be a number')
          .lessThan(100, `Head Circumference can't exceed 100`)
          .test('headCircumference', 'Head Circumference is required', (value) =>
            trackerID === ReduxPHRCategoryEnum.PHRHeadCircumferences && !value ? false : true,
          ),
        length: number()
          .positive(`Length can't be negative or zero`)
          .typeError('Length must be a number')
          .lessThan(100, `Length can't exceed 100`)
          .test('length', 'Length is required', (value) =>
            trackerID === ReduxPHRCategoryEnum.PHRLengths && !value ? false : true,
          ),
      }).shape(
        {
          systolic: number()
            .positive(`Systolic can't be negative or zero`)
            .typeError('Systolic must be a number')
            .lessThan(1000, `Systolic can't exceed 1000`)
            .when('diastolic', {
              is: (value) => value && value > 0,
              then: number().required('Both Systolic and Diastolic are required'),
              otherwise: number(),
            }),
          diastolic: number()
            .positive(`Diastolic can't be negative or zero`)
            .typeError('Diastolic must be a number')
            .lessThan(1000, `Diastolic can't exceed 1000`)
            .when('systolic', {
              is: (value) => value && value > 0,
              then: number().required('Both Systolic and Diastolic are required'),
              otherwise: number(),
            }),
          bloodPressureDate: date()
            .typeError('Invalid date')
            .when('systolic', {
              is: (value) => value && value > 0,
              then: date().required('Blood Pressure Date is required'),
              otherwise: date(),
            }),
        },
        [['systolic', 'diastolic']],
      )}
      onSubmit={handleSubmit}
    >
      {({ status, setFieldValue, values }) => (
        <Form error={error}>
          {trackerID ? (
            <Box paddingX={{ base: '1rem', md: '3rem' }}>
              <DataLoader trackerID={trackerID!} tracker={tracker!} unitSettings={unitSettings} />
              {/* Title */}
              {renderVitalsHeader(
                () => (
                  <>
                    <PrimaryButton
                      borderRadius="full"
                      bgColor="#020202"
                      _hover={{ bgColor: '#020202E6' }}
                      fontWeight="semibold"
                      marginRight={{ base: 0, md: '1rem' }}
                      height="45px"
                      type="submit"
                      isLoading={isLoading}
                    >
                      Save
                    </PrimaryButton>
                    <TertiaryButton
                      color="#020202"
                      display={{ base: 'none', md: 'block' }}
                      onClick={toggleView}
                      isLoading={isLoading}
                    >
                      Cancel
                    </TertiaryButton>
                  </>
                ),
                isMobile ? toggleView : undefined,
                isInRole(currentUser, RolesEnum.Patient) ? '' : 'Add Tracker',
              )}
              {/* rendering tracker form */}
              {renderer(trackerID, setFieldValue, values, tracker)}
            </Box>
          ) : (
            <Box>
              {/* Title & Buttons */}
              {renderVitalsHeader(
                () => (
                  <>
                    <PrimaryButton
                      borderRadius="full"
                      bgColor="#020202"
                      _hover={{ bgColor: '#020202E6' }}
                      fontWeight="semibold"
                      marginRight={{ base: 0, md: '1rem' }}
                      height="45px"
                      type="submit"
                    >
                      Save
                    </PrimaryButton>
                    <TertiaryButton
                      color="#020202"
                      display={{ base: 'none', md: 'block' }}
                      onClick={toggleView}
                    >
                      Cancel
                    </TertiaryButton>
                  </>
                ),
                isMobile ? toggleView : undefined,
                isInRole(currentUser, RolesEnum.Patient) ? '' : `Add Vitals`,
              )}
              <Box
                height={{ base: 'calc(100vh - 4rem)', md: '580px' }}
                paddingX={{ base: '1rem', md: '3rem' }}
                overflowY="auto"
              >
                <ServerValidationBox message={status} />
                {/* rendering tracker forms */}
                {renderer(ReduxPHRCategoryEnum.PHRBloodPressures, setFieldValue, values)}
                {renderer(ReduxPHRCategoryEnum.PHRBloodOxygens, setFieldValue, values)}
                {renderer(ReduxPHRCategoryEnum.PHRRespiratories, setFieldValue, values)}
                {renderer(ReduxPHRCategoryEnum.PHRTemperatures, setFieldValue, values)}
                {renderer(ReduxPHRCategoryEnum.PHRBloodGlucoses, setFieldValue, values)}
              </Box>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

interface IDataLoaderProps {
  trackerID: ReduxPHRCategoryEnum;
  tracker: VitalsTracker;
  unitSettings?: IUnitSettings;
}
export const DataLoader = ({ trackerID, tracker, unitSettings }: IDataLoaderProps) => {
  const { setFieldValue } = useFormikContext<VitalsTracker>();

  useEffect(() => {
    if (trackerID && tracker) {
      switch (trackerID) {
        case ReduxPHRCategoryEnum.PHRBloodPressures: {
          const t: PHRTrackerBloodPressureDTO = tracker;
          setFieldValue('systolic', t.systolic);
          setFieldValue('diastolic', t.diastolic);
          break;
        }
        case ReduxPHRCategoryEnum.PHRPulses: {
          const t: PHRTrackerPulseDTO = tracker;
          setFieldValue('pulse', t.pulse);
          break;
        }
        case ReduxPHRCategoryEnum.PHRWeightHeights: {
          const t: PHRTrackerWeightHeightDTO = tracker;
          if (unitSettings?.weight === WeightEnum.Lbs) {
            t.weight = convertWeight(true, t.weight, WeightEnum.Lbs, WeightEnum.Kg);
          }
          setFieldValue('weight', t.weight);
          if (unitSettings?.height === HeightEnum.In) {
            const heightValues = !t
              ? { feet: null, inches: null }
              : convertHeight2(true, { centimeters: t.height }, HeightEnum.In, HeightEnum.Cm);
            setFieldValue('heightFt', heightValues.feet);
            setFieldValue('heightIn', heightValues.inches);
          } else {
            setFieldValue('heightCm', t.height);
          }
          break;
        }
        case ReduxPHRCategoryEnum.PHRTemperatures: {
          const t: PHRTrackerTemperatureDTO = tracker;
          if (unitSettings?.temperature === TemperatureTypeEnum.Fahrenheit) {
            t.temperature = convertTemperature(
              true,
              t.temperature,
              TemperatureTypeEnum.Fahrenheit,
              TemperatureTypeEnum.Celsius,
            );
          }
          setFieldValue('temperature', t.temperature);
          break;
        }
        case ReduxPHRCategoryEnum.PHRRespiratories: {
          const t: PHRTrackerRespiratoryDTO = tracker;
          setFieldValue('respiratory', t.respiratory);
          break;
        }
        case ReduxPHRCategoryEnum.PHRBloodOxygens: {
          const t: PHRTrackerBloodOxygenDTO = tracker;
          setFieldValue('bloodOxygen', t.bloodOxygen);
          break;
        }
        case ReduxPHRCategoryEnum.PHRBloodGlucoses: {
          const t: PHRTrackerBloodGlucoseDTO = tracker;
          if (unitSettings?.bloodGlucose === BloodGlucoseTypeEnum.MgdL) {
            t.bloodGlucose = convertBloodGlucose(
              true,
              t.bloodGlucose,
              BloodGlucoseTypeEnum.MgdL,
              BloodGlucoseTypeEnum.MmolL,
            );
          }
          setFieldValue('bloodGlucose', t.bloodGlucose);
          break;
        }
        case ReduxPHRCategoryEnum.PHRHeadCircumferences: {
          const t: PHRTrackerHeadCircumferenceDTO = tracker;
          if (unitSettings?.height === HeightEnum.In) {
            t.headCircumference = convertHeight(
              true,
              t.headCircumference,
              HeightEnum.In,
              HeightEnum.Cm,
            );
          }
          setFieldValue('headCircumference', t.headCircumference);
          break;
        }
        case ReduxPHRCategoryEnum.PHRLengths: {
          const t: PHRTrackerLengthDTO = tracker;
          if (unitSettings?.height === HeightEnum.In) {
            t.length = convertHeight(true, t.length, HeightEnum.In, HeightEnum.Cm);
          }
          setFieldValue('length', t.length);
          break;
        }
      }
    }
  }, [setFieldValue, tracker, trackerID, unitSettings]);

  return null;
};
