import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Button, ButtonProps, HStack, Text } from '@chakra-ui/react';

export const RoundedButton = (props: ButtonProps) => {
  const {
    children,
    minWidth = '120px',
    background = 'purple.600',
    color = 'white',
    borderRadius = '2rem',
    fontSize = '12px',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _hover = {
      background: 'white',
      border: '1px solid purple.600',
      color: 'purple.600',
    },
    ...rest
  } = props;
  return (
    <Button
      color={color}
      minWidth={minWidth}
      borderRadius={borderRadius}
      fontSize={fontSize}
      background={background}
      _hover={_hover}
      cursor="pointer"
      {...rest}
    >
      <HStack>
        <Text>{children}</Text>
        <ArrowForwardIcon />
      </HStack>
    </Button>
  );
};
