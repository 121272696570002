import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../store/root-reducers';
import { closeSideMenu, openSideMenu } from '../../../../store/sideMenu/sideMenuActions';

const useToggleSideMenu = () => {
  const dispatch = useDispatch();
  const isSideMenuOpen = useSelector((state: AppState) => state.sideMenuState.isOpen);

  const toggleSideMenu = () => {
    if (isSideMenuOpen) {
      dispatch(closeSideMenu());
    } else {
      dispatch(openSideMenu());
    }
  };
  return { toggleSideMenu };
};

export default useToggleSideMenu;
