import moment from 'moment';
import { useEffect, useState } from 'react';
import { ConsultStatusEnum, IConsult } from '../../../../generated';
import useInterval from '../../../../hooks/useInterval';

interface IProps {
  consult?: IConsult;
}

const ONE_SECOND = 1000;

const useConsultTimer = ({ consult }: IProps) => {
  const [sessionTimer, setSessionTimer] = useState<string>();
  const [timerStopped, setTimerStopped] = useState<boolean>(true);
  const [sessionStartDateTime, setSessionStartDateTime] = useState<Date | undefined>();
  const stopTimer = () => setTimerStopped(true);
  const startTimer = (sessionStartDate: Date | undefined) => {
    setTimerStopped(false);
    setSessionStartDateTime(sessionStartDate);
  };

  useInterval(
    () => {
      const from = moment.utc();
      const to = moment(sessionStartDateTime).utc();
      const timeToDisplay = moment.utc(from.diff(to)).format('HH:mm:ss');
      setSessionTimer(timeToDisplay);
    },
    !sessionStartDateTime || timerStopped ? null : ONE_SECOND,
  );

  /**
   * Stop Timer and notify that the consultation has ended by the other party
   */
  useEffect(() => {
    if (consult?.consultStatusEnum === ConsultStatusEnum.PatientCompleted) {
      stopTimer();
    }
  }, [consult]);

  return {
    sessionTimer,
    startTimer,
    stopTimer,
  };
};

export default useConsultTimer;
