import { Box, useBreakpointValue } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';
import CollapsibleComponent from '../../../../../components/CollapsibleComponent';
import { H4 } from '../../../../../components/common/Header';
import { Paragraph } from '../../../../../components/common/Paragraph';
import ToggleIcon from '../../../../../components/common/ToggleIcon';
import Modal from '../../../../../components/Modals/Modal';
import { Cell, Row, Table, TableHead } from '../../../../../components/Table';
import Tag from '../../../../../components/Tag';
import { SpacingRelated, TimeFormatEnum } from '../../../../../constants';
import { PHRMedication, RolesEnum } from '../../../../../generated';
import { AppState } from '../../../../../store/root-reducers';
import { OutpostTheme } from '../../../../../themes/outpost';
import { getTime } from '../../../../../utils';
import { displayValue } from '../../../../../utils/displayValue';
import { isInRole } from '../../../../../utils/isInRole';
import { Details, Label, ToggleElement, Value } from '../../../components/StyledPhrItems';
import { Refill } from '../Refill/Refill';

const buildPreviousMedication = (
  prevMedications: PHRMedication[],
  allMedications: PHRMedication[],
  refillForPHRMedicationID?: string,
) => {
  if (refillForPHRMedicationID) {
    const prevMedication = allMedications.find(
      (x) => x.phrMedicationID === refillForPHRMedicationID,
    );
    if (prevMedication) {
      prevMedications.push(prevMedication);
      buildPreviousMedication(
        prevMedications,
        allMedications,
        prevMedication.refillForPHRMedicationID,
      );
    } else {
      return prevMedications;
    }
  }
  return prevMedications;
};

interface IProps {
  medication: PHRMedication;
  children: any;
  phrConsultID?: string;
  consultCountryID?: string;
  patientID: string;
  onRefill: (patientPrescriptionID: string) => void;
  allMedications: PHRMedication[];
}

export const MedicationInfo = ({
  medication,
  children,
  phrConsultID,
  consultCountryID,
  patientID,
  onRefill,
  allMedications,
}: IProps) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [previousMedications, setPreviousMedications] = useState<PHRMedication[]>([]);
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const openMedicationInfo = () => setOpen(true);
  const isDiscontinued = !!medication.discontinuedDate;
  const isOnTablet = useBreakpointValue({ base: true, lg: false });

  useEffect(() => {
    if (medication.refillForPHRMedicationID) {
      const prevMedications = buildPreviousMedication(
        [],
        allMedications,
        medication.refillForPHRMedicationID,
      );
      setPreviousMedications(
        prevMedications.sort(
          (a, b) =>
            getTime(b.startDate) - getTime(a.startDate) ||
            getTime(b.upsertDate) - getTime(a.upsertDate),
        ),
      );
    }
  }, [allMedications, medication]);

  const medicationStatus = isDiscontinued
    ? 'DISCONTINUED'
    : !!medication.endDate && moment(medication.endDate).isBefore(moment())
    ? 'PAST'
    : 'ACTIVE';

  const content = (
    <>
      <Title>
        <TitleRow>
          <MedicationName>{[medication.name, medication.dosage].join(' ')}</MedicationName>
          <Status discontinued={isDiscontinued}>{medicationStatus}</Status>
          {!isOnTablet && (
            <CloseIcon onClick={() => setOpen(false)} icon={['far', 'times']} size="2x" />
          )}
        </TitleRow>
        <Date small>
          {moment(medication.startDate).format(TimeFormatEnum.SHORT_LONG_DATE)}{' '}
          {medication.endDate &&
            `- ${moment(medication.endDate).format(TimeFormatEnum.SHORT_LONG_DATE)}`}
        </Date>
      </Title>
      <div className="row">
        <div className="col-12">
          <Details>
            <Label>Instructions:</Label>
            <Value>{displayValue(medication.instructions)}</Value>
          </Details>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Details>
            <Label>Form:</Label>
            <Value>{displayValue(medication.form)}</Value>
          </Details>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Details>
            <Label>Route:</Label>
            <Value>{displayValue(medication.methodOfIntake)}</Value>
          </Details>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Details>
            <Label>Quantity:</Label>
            <Value>{displayValue(medication.quantity)}</Value>
          </Details>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Details>
            <Label>Refills:</Label>
            <Value>{displayValue(medication.refills)}</Value>
          </Details>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Details>
            <Label>Dispense Instructions:</Label>
            <Value>{displayValue(medication.dispensingInstructions)}</Value>
          </Details>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Details>
            <Label>Additional Notes:</Label>
            <Value>{displayValue(medication.notes)}</Value>
          </Details>
        </div>
      </div>
      {isInRole(currentUser, RolesEnum.Provider) &&
        medication.patientPrescriptionID &&
        phrConsultID &&
        consultCountryID && (
          <ButtonRow>
            <Refill
              patientID={patientID}
              medications={[medication]}
              phrConsultID={phrConsultID}
              countryID={consultCountryID}
              onRefill={onRefill}
            >
              {({ openRefills }) => (
                <ButtonWrapper>
                  <PrimaryButton
                    leftIcon={<FontAwesomeIcon icon={['far', 'plus']} />}
                    onClick={openRefills}
                    disabled={true}
                  >
                    Refill
                  </PrimaryButton>
                </ButtonWrapper>
              )}
            </Refill>
          </ButtonRow>
        )}
      <PreviousArea>
        <CollapsibleComponent>
          {({ toggle, isOpen }) => (
            <>
              <PreviousRow onClick={toggle}>
                <ExpandToggleIcon isOpen={isOpen} />
                Previous
                <PreviousCount
                  backgroundColor={OutpostTheme.Colors.Highlight}
                  textColor={OutpostTheme.Colors.White}
                >
                  {previousMedications.length}
                </PreviousCount>
              </PreviousRow>
              {isOpen &&
                (previousMedications.length === 0 ? (
                  <EmptyText small>No previous entries</EmptyText>
                ) : (
                  <PreviousTable>
                    <TableHead>
                      <tr>
                        <Cell>Name {'&'} Dose</Cell>
                        <Cell>Start Date</Cell>
                        <Cell>End Date</Cell>
                        <Cell>Refills</Cell>
                        <Cell>Added by</Cell>
                      </tr>
                    </TableHead>
                    <tbody>
                      {previousMedications.map((x) => (
                        <Row inverse={true} key={x.phrMedicationID}>
                          <Cell>{[x.name, x.dosage].join(' ')}</Cell>
                          <Cell>{moment(x.startDate).format(TimeFormatEnum.SHORT_LONG_DATE)}</Cell>
                          <Cell>
                            {x.endDate
                              ? moment(x.endDate).format(TimeFormatEnum.SHORT_LONG_DATE)
                              : null}
                          </Cell>
                          <Cell>{x.refills}</Cell>
                          <Cell>{x.createdByName}</Cell>
                        </Row>
                      ))}
                    </tbody>
                  </PreviousTable>
                ))}
            </>
          )}
        </CollapsibleComponent>
      </PreviousArea>

      <CreatedInfoRow>
        <div className="row">
          <div className="col-12">
            <Details>
              <Label>Created on</Label>
              <Value>
                {medication.createdDate
                  ? moment(medication.createdDate).format(TimeFormatEnum.SHORT_DATE_TIME)
                  : '-'}{' '}
                by {displayValue(medication.createdByName)}
              </Value>
            </Details>
          </div>
        </div>
      </CreatedInfoRow>
      {isDiscontinued && (
        <DiscontinuedInfoRow>
          <div className="row">
            <div className="col-12">
              <Details>
                <Label>Discontinued on</Label>
                <Value>
                  {medication.discontinuedDate
                    ? moment(medication.discontinuedDate).format(TimeFormatEnum.SHORT_DATE_TIME)
                    : '-'}{' '}
                  by {displayValue(medication.discontinuedProviderName)}
                </Value>
              </Details>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Details>
                <Label>Discontinued reason:</Label>
                <Value>{displayValue(medication.discontinuedReason)}</Value>
              </Details>
            </div>
          </div>
        </DiscontinuedInfoRow>
      )}
    </>
  );

  return (
    <>
      <Modal
        isOpen={isOnTablet ? isOpen : false}
        onCancel={() => setOpen(false)}
        cancelText="Close"
      >
        {content}
      </Modal>
      <Popover
        isOpen={isOnTablet ? false : isOpen}
        positions={['top', 'bottom', 'left']}
        onClickOutside={() => setOpen(false)}
        padding={10}
        content={() => (
          <Box
            backgroundColor="white"
            borderRadius="0.25rem"
            minWidth={isOnTablet ? '600px' : 'unset'}
            maxWidth={isOnTablet ? '90%' : 'unset'}
            p={4}
            border={isOnTablet ? 'none' : '1px solid black'}
          >
            {content}
          </Box>
        )}
      >
        {children({ openMedicationInfo })}
      </Popover>
    </>
  );
};

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  margin: ${SpacingRelated.HalfRegular};
`;

const Date = styled(Paragraph)`
  margin-top: -5px;
`;

interface IStatusProps {
  readonly discontinued: boolean;
}

const Status = styled.span<IStatusProps>`
  ${(props) => props.discontinued && `color: ${props.theme.Colors.Danger}`};
  font-size: 14px;
  margin-right: 15px;
  font-weight: 500;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: ${SpacingRelated.HalfRegular};
`;

const CreatedInfoRow = styled.div`
  padding: 20px 24px;
  background-color: rgba(196, 196, 196, 0.15);
  margin: 15px 0;
  border-radius: 10px;
`;

const DiscontinuedInfoRow = styled.div`
  padding: 20px 24px;
  background-color: rgba(242, 146, 146, 0.15);
  border-radius: 10px;
`;

const Title = styled.div``;

const MedicationName = styled(H4)`
  font-size: 17px;
  font-weight: 500;
  margin: 0;
  line-height: 25px;
  width: 100%;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  color: #57575c;
  font-size: 1.5em;

  &:hover {
    cursor: pointer;
  }
`;

const PreviousRow = styled(ToggleElement)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ExpandToggleIcon = styled(ToggleIcon)`
  top: 0px;
`;

const PreviousCount = styled(Tag)`
  margin-right: 50px;
`;

const PreviousArea = styled.div`
  padding: 15px 0;
  margin: 15px 0;
`;

const PreviousTable = styled(Table)`
  margin-top: 15px;
  border-top: 1px solid #c3c3c3;
  border-bottom: 1px solid #c3c3c3;
`;

const EmptyText = styled(Paragraph)`
  margin-bottom: 0;
  text-align: center;
  font-style: italic;
`;
