import { IconButton, IconButtonProps } from '@chakra-ui/react';
import React, { LegacyRef } from 'react';
import { BackArrowIcon } from '../../assets/icons';
import { Optional } from '../../utils/types';

export type ModalBackButtonButtonProps = Optional<IconButtonProps, 'aria-label'>;

export const ModalBackButton = React.forwardRef(
  (props: ModalBackButtonButtonProps, ref?: LegacyRef<HTMLButtonElement>) => {
    const { children, fontSize = '16px', position = 'absolute', left = '24px', ...rest } = props;

    return (
      <IconButton
        aria-label="back"
        width="40px"
        height="40px"
        justifyContent="center"
        alignItems="center"
        display="flex"
        minWidth="auto"
        variant="ghost"
        borderRadius="full"
        ref={ref}
        fontSize={fontSize}
        p={0}
        left={left}
        position={position}
        icon={<BackArrowIcon fontSize="24px" />}
        {...rest}
      />
    );
  },
);
