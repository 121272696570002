import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AuthClient,
  BloodGlucoseTypeEnum,
  HeightEnum,
  IPatient,
  PHRTrackersClient,
  PHRTrackerSearchDetails,
  PHRVitalsDTO,
  TemperatureTypeEnum,
  UserDetailSettingEnum,
  WeightEnum,
} from '../../../../generated';
import { updatePageTitle } from '../../../../store/pageHeader/pageHeaderActions';
import { AppState } from '../../../../store/root-reducers';
import { getUserDetailSettings } from '../../../../store/userDetailSettings/getUserDetailSettingsAction';
import { httpRequest } from '../../../../utils';
import { usePatient } from './usePatient';

export interface IUnitSettings {
  weight: WeightEnum;
  height: HeightEnum;
  temperature: TemperatureTypeEnum;
  bloodGlucose: BloodGlucoseTypeEnum;
}
class UnitSettings implements IUnitSettings {
  weight: WeightEnum;

  height: HeightEnum;

  temperature: TemperatureTypeEnum;

  bloodGlucose: BloodGlucoseTypeEnum;

  constructor(
    w: WeightEnum = WeightEnum.Kg,
    h: HeightEnum = HeightEnum.Cm,
    t: TemperatureTypeEnum = TemperatureTypeEnum.Celsius,
    bg: BloodGlucoseTypeEnum = BloodGlucoseTypeEnum.MmolL,
  ) {
    this.weight = w;
    this.height = h;
    this.temperature = t;
    this.bloodGlucose = bg;
  }
}

export const usePatientVitals = (patient?: IPatient) => {
  const { phrPatient, isLoading: isLoadingPhr } = usePatient(patient);
  const [error, setError] = useState<string>('');
  const [showForm, setShowForm] = useState<boolean>(false);
  const [shouldLoadVitals, setShouldLoadVitals] = useState<boolean>(true);
  const [vitals, setVitals] = useState<PHRVitalsDTO>();
  const [isLoadingApi, setLoadingApi] = useState<boolean>(false);
  const [unitSettings, setUnitSettings] = useState<IUnitSettings>();
  const [isLoading, setLoading] = useState<boolean>(isLoadingApi || isLoadingPhr);

  const dispatch = useDispatch();
  const { userDetailSettings, isLoading: isLoadingUserSettings } = useSelector(
    (state: AppState) => state.userDetailSettingState,
  );

  useEffect(() => {
    dispatch(updatePageTitle('Vitals'));
  }, [dispatch]);

  useEffect(() => {
    if (!userDetailSettings) {
      dispatch(getUserDetailSettings());
    }
  }, [dispatch, userDetailSettings]);

  useEffect(() => {
    const getRecentPHRVitals = async (patient) => {
      const searchDetails = new PHRTrackerSearchDetails();
      const startDate = new Date();
      startDate.setFullYear(startDate.getFullYear() - 1);
      searchDetails.startDate = startDate;
      searchDetails.endDate = moment().add(1, 'day').toDate();
      searchDetails.patientID = patient.patientID;

      setLoadingApi(true);
      try {
        const client = new PHRTrackersClient(new AuthClient());
        const result = await httpRequest(() => client.recentPHRVitals(searchDetails));
        setVitals(result);
      } catch (error) {
        setError(error as any);
      }
      setLoadingApi(false);
    };

    if (phrPatient && shouldLoadVitals) {
      setShouldLoadVitals(false);
      getRecentPHRVitals(phrPatient.patient);
      setShowForm(false);
    }
  }, [phrPatient, shouldLoadVitals]);

  useEffect(() => {
    const getUnitSettings = (userDetailSettings) => {
      const unitWeight: WeightEnum =
        userDetailSettings.find((x) => x.userDetailSettingEnum === UserDetailSettingEnum.Weight)
          ?.value ?? WeightEnum.Kg;
      const unitHeight: HeightEnum =
        userDetailSettings.find((x) => x.userDetailSettingEnum === UserDetailSettingEnum.Height)
          ?.value ?? HeightEnum.Cm;
      const unitTemperature: TemperatureTypeEnum =
        userDetailSettings.find(
          (x) => x.userDetailSettingEnum === UserDetailSettingEnum.Temperature,
        )?.value ?? TemperatureTypeEnum.Celsius;
      const bloodGlucose: BloodGlucoseTypeEnum =
        userDetailSettings.find(
          (x) => x.userDetailSettingEnum === UserDetailSettingEnum.BloodGlucose,
        )?.value ?? BloodGlucoseTypeEnum.MmolL;

      setUnitSettings(new UnitSettings(unitWeight, unitHeight, unitTemperature, bloodGlucose));
    };

    if (userDetailSettings) {
      getUnitSettings(userDetailSettings);
    }
  }, [userDetailSettings]);

  useEffect(() => {
    // combines all loading conditions here
    setLoading(
      !phrPatient || !userDetailSettings || isLoadingApi || isLoadingPhr || isLoadingUserSettings,
    );
  }, [phrPatient, userDetailSettings, isLoadingApi, isLoadingPhr, isLoadingUserSettings]);

  return {
    error,
    isLoading,
    phrPatient,
    userDetailSettings,
    showForm,
    setShowForm,
    setShouldLoadVitals,
    unitSettings,
    vitals,
  };
};
