import moment from 'moment';
import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import Card from '../../../../components/Card';
import { CHART_PLUGINS } from '../../../../constants';
import { HeightEnum, PHRTrackerLengthDTO } from '../../../../generated';
import { OutpostTheme } from '../../../../themes/outpost';
import { getTime } from '../../../../utils';
import {
  ChartButtonRow,
  ChartContainer,
  PrimaryButtonSelect,
} from '../../components/StyledPhrItems';

interface IProps {
  dataSet: PHRTrackerLengthDTO[];
  unit: HeightEnum;
}
const LengthChart = ({ dataSet, unit }: IProps) => {
  const [daysRange, setDaysRange] = useState<number>(14);

  const data = () => {
    const sorted = [...dataSet].sort((a, b) => getTime(a.trackerDate) - getTime(b.trackerDate));

    return {
      datasets: [
        {
          label: 'Length',
          data: sorted.map((d) => ({ x: d.trackerDate, y: d.length })),
          fill: false,
          borderColor: OutpostTheme.Colors.DarkBlue,
          borderWidth: 2,
        },
      ],
    };
  };

  const options = {
    legend: {
      position: 'bottom',
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function (value) {
              if (unit === HeightEnum.In) {
                const inches = Math.floor(value);
                return `${inches}"`;
              } else {
                if (Math.floor(value) === value) {
                  return value;
                }
              }
            },
            stepSize: unit === HeightEnum.In ? 10 : 20,
            suggestedMin: 0,
            suggestedMax: unit === HeightEnum.In ? 30 : 60,
          },
        },
      ],
      xAxes: [
        {
          type: 'time',
          position: 'bottom',
          distribution: 'linear',
          time: {
            unit: daysRange === 14 ? 'day' : 'month',
          },
          ticks: {
            max: moment(),
            min: moment().subtract(daysRange, 'd'),
          },
        },
      ],
    },
    maintainAspectRatio: false,
    plugins: CHART_PLUGINS,
  };

  return (
    <Card>
      <ChartButtonRow>
        <div>
          <PrimaryButtonSelect active={daysRange === 365} onClick={() => setDaysRange(365)}>
            Year
          </PrimaryButtonSelect>
          <PrimaryButtonSelect active={daysRange === 90} onClick={() => setDaysRange(90)}>
            3 Months
          </PrimaryButtonSelect>
          <PrimaryButtonSelect active={daysRange === 14} onClick={() => setDaysRange(14)}>
            14 Days
          </PrimaryButtonSelect>
        </div>
      </ChartButtonRow>
      <ChartContainer>
        {/* @ts-ignore */}
        <Line data={data} options={options} />
      </ChartContainer>
    </Card>
  );
};

export default LengthChart;
