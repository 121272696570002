import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Collapse,
  Flex,
  IconButton,
  Spacer,
} from '@chakra-ui/react';
import React, { ReactNode, useState } from 'react';

interface IProps {
  Icon: React.ElementType;
  children: ReactNode;
  isOpen?: boolean;
  text: string;
  iconPosition?: string;
  bgColor?: string;
}

export const CustomMenuAccordion = ({
  Icon,
  children,
  isOpen,
  text,
  iconPosition,
  bgColor,
}: IProps) => {
  const [expand, setExpand] = useState<boolean>(false);

  return (
    <>
      {isOpen ? (
        <AccordionItem>
          <Button
            as={AccordionButton}
            variant="menu"
            borderRadius="0.5rem"
            justifyContent="flex-start"
            backgroundColor={bgColor ? bgColor : 'transparent'}
            color="#000000"
            _hover={{
              background: '#000000',
              color: '#FFFFFF',
            }}
            sx={{
              '> .chakra-button__icon': {
                marginInlineEnd: '0.75rem',
              },
            }}
            pr="0 !important"
            leftIcon={<Icon fontSize="24px" />}
          >
            {text} <Spacer />
            <AccordionIcon fontSize="24px" />
          </Button>

          <AccordionPanel display={isOpen ? 'block' : 'none'} pb="0" pr="0">
            {children}
          </AccordionPanel>
        </AccordionItem>
      ) : (
        <Flex
          flexDirection="column"
          p={0}
          transition=".3s ease"
          background={bgColor === 'transparent' ? bgColor : expand ? '#f2f2f2' : 'transparent'}
          alignItems={iconPosition === 'right' ? 'flex-end' : 'flex-start'}
          justifyContent={iconPosition === 'right' ? 'flex-end' : 'flex-start'}
        >
          <IconButton
            onClick={() => setExpand(!expand)}
            w="50px"
            p={2}
            borderRadius="0.5rem"
            backgroundColor={bgColor ? bgColor : expand ? '#666' : 'transparent'}
            color={bgColor ? '#000000' : expand ? '#FFFFFF' : '#000000'}
            _hover={{
              background: bgColor ? 'transparent' : '#000000',
              color: bgColor ? '#000000' : '#FFFFFF',
            }}
            aria-label="Menu"
            icon={<Icon h="24px" w="24px" />}
            mb={expand ? 5 : 0}
          />
          <Collapse in={expand} animateOpacity style={{ width: '100%' }}>
            {children}
          </Collapse>
        </Flex>
      )}
    </>
  );
};
