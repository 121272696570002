import { Formik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { date, number, object } from 'yup';
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton';
import { SecondaryButton } from '../../../../components/Buttons/SecondaryButton';
import { Form, FormInputControl, FormMobisPicker } from '../../../../components/forms';
import { CenteredLoadingIndicator } from '../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../components/ServerValidationBox';
import { ButtonSizeEnum } from '../../../../constants';
import { BloodGlucoseTypeEnum, PHRTrackerBloodGlucoseDTO } from '../../../../generated';
import { createForPhrSection, updateForPhrSection } from '../../../../store/root-creator';
import { AppState } from '../../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import { convertBloodGlucose } from '../../../../utils/conversionHelpers';
import { ButtonRow, UnitWrapper } from '../../components/StyledPhrItems';

interface IProps {
  selectedData: PHRTrackerBloodGlucoseDTO | null;
  patientID: string;
  toggleView: () => void;
  unit: BloodGlucoseTypeEnum;
  unitSelector: any;
}

interface IForm {
  trackerDate: moment.Moment;
  bloodGlucose?: number;
}

const BloodGlucoseForm = ({ selectedData, patientID, toggleView, unit, unitSelector }: IProps) => {
  const dispatch = useDispatch();
  const { error, isPhrLoading } = useSelector((state: AppState) => state.phrPatientState);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const onSubmit = async (values: IForm) => {
    const obj = Object.assign({}, values);
    obj.bloodGlucose = convertBloodGlucose(
      false,
      values.bloodGlucose,
      BloodGlucoseTypeEnum.MmolL,
      unit,
    );
    setSubmitting(true);
    if (selectedData) {
      selectedData.trackerDate = moment(obj.trackerDate).toDate();
      selectedData.bloodGlucose = obj.bloodGlucose;
      await dispatch(
        updateForPhrSection(ReduxPHRCategoryEnum.PHRBloodGlucoses, selectedData, patientID, true),
      );
    } else {
      await dispatch(
        createForPhrSection(ReduxPHRCategoryEnum.PHRBloodGlucoses, obj, patientID, true),
      );
    }
  };

  const handleUnitChange = (setFieldValue, bloodGlucose, toUnit) => {
    setFieldValue('bloodGlucose', convertBloodGlucose(true, bloodGlucose, toUnit, unit));
  };

  useEffect(() => {
    if (isSubmitting && !isPhrLoading) {
      if (error) {
        setSubmitting(false);
      } else {
        toggleView();
      }
    }
  }, [error, isPhrLoading, isSubmitting, toggleView]);

  const initialValues: IForm = {
    trackerDate: selectedData ? moment(selectedData.trackerDate!) : moment(),
    bloodGlucose: selectedData ? selectedData.bloodGlucose : undefined,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={object({
        trackerDate: date().required('Date is required').typeError('Invalid date'),
        bloodGlucose: number()
          .required('Blood Glucose is required')
          .positive('Blood Glucose must be a postive number')
          .typeError('Blood Glucose must be a postive number')
          .moreThan(0, 'Value must be greater than 0')
          .lessThan(300, 'Value must be less than 300'),
      })}
      onSubmit={onSubmit}
    >
      {({ status, setFieldValue, values }) => (
        <Form error={error}>
          {isSubmitting ? (
            <CenteredLoadingIndicator />
          ) : (
            <>
              <ServerValidationBox message={status} />
              <UnitWrapper>
                {unitSelector((x) => {
                  handleUnitChange(setFieldValue, values.bloodGlucose, x);
                })}
              </UnitWrapper>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <FormMobisPicker
                    name="trackerDate"
                    label="Tracked at"
                    controls={['calendar', 'time']}
                    max={moment().toDate()}
                    min={moment().add(-150, 'year').toDate()}
                  />
                  {/* <FormDateTimePicker
                    name="trackerDate"
                    label="Tracked at"
                    showTimeSelect
                    maxDate={moment().toDate()}
                    minDate={moment().add(-150, 'year').toDate()}
                  /> */}
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <FormInputControl name="bloodGlucose" label="Blood Glucose" type="text" />
                </div>
              </div>
              <ButtonRow>
                <SecondaryButton size={ButtonSizeEnum.medium} onClick={toggleView}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size={ButtonSizeEnum.medium} type="submit">
                  Save
                </PrimaryButton>
              </ButtonRow>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default BloodGlucoseForm;
