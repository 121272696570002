import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';
import Card from '../../../../../components/Card';
import { FormError, InputControl } from '../../../../../components/forms';
import ButtonGroup from '../../../../../components/forms/ButtonGroup';
import { CenteredLoadingIndicator } from '../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../components/ServerValidationBox';
import { SpacingRelated } from '../../../../../constants';
import {
  AuthClient,
  PatientPrescriptionRefillDetails,
  PatientPrescriptionsClient,
  PHRMedication,
} from '../../../../../generated';
import { AppState } from '../../../../../store/root-reducers';
import { httpRequest } from '../../../../../utils';

interface IProps {
  children: any;
  medications: PHRMedication[];
  phrConsultID: string;
  countryID: string;
  patientID: string;
  onRefill: (patientPrescriptionID: string) => void;
}

export const Refill = ({ children, medications, phrConsultID, patientID, onRefill }: IProps) => {
  const provider = useSelector((state: AppState) => state.providerState.provider);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [refills, setRefills] = useState<string>('');
  const [otherRefills, setOtherRefills] = useState<string>('');
  const [validationError, setValidationError] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const openRefills = () => setOpen(true);

  useEffect(() => {
    if (refills) {
      setValidationError('');
      setError('');
      setOtherRefills('');
    }
  }, [refills]);

  useEffect(() => {
    if (otherRefills) {
      setValidationError('');
      setError('');
      setRefills('');
    }
  }, [otherRefills]);

  const refillMedication = async () => {
    if (!refills && !otherRefills) {
      setValidationError('A Refill amount is required');
    } else if (!!otherRefills && isNaN(parseInt(otherRefills))) {
      setValidationError('Refill amount has to be a number');
    } else if (!!otherRefills && parseInt(otherRefills) <= 0) {
      setValidationError('Refill amount has to be greater than 0');
    } else {
      // refill logic
      try {
        setLoading(true);
        const client = new PatientPrescriptionsClient(new AuthClient());
        const refillDetails = new PatientPrescriptionRefillDetails();
        refillDetails.patientID = patientID;
        refillDetails.phrMedications = medications;
        refillDetails.phrConsultID = phrConsultID;
        refillDetails.providerID = provider!.providerID;
        refillDetails.refillCount =
          (refills && parseInt(refills)) || (otherRefills && parseInt(otherRefills)) || 0;

        const prescriptionID = await httpRequest(() => client.refill(refillDetails));
        onRefill(prescriptionID);
        setLoading(false);
        setOpen(false);
      } catch (err) {
        setLoading(false);
        setError(err as any);
      }
    }
  };

  return (
    <Popover
      isOpen={isOpen}
      positions={['top', 'bottom', 'left']}
      padding={10}
      content={() => (
        <InfoWrapper
          title="Refill medication"
          actionText="Close"
          onAction={() => {
            setOpen(false);
            setRefills('');
            setOtherRefills('');
            setError('');
          }}
        >
          {isLoading ? (
            <CenteredLoadingIndicator />
          ) : (
            <>
              <ServerValidationBox message={error} />
              <Group>
                <ButtonGroup label="1" value="1" onChange={setRefills} checked={refills === '1'} />
                <ButtonGroup label="2" value="2" onChange={setRefills} checked={refills === '2'} />
                <ButtonGroup label="3" value="3" onChange={setRefills} checked={refills === '3'} />
                <InputControl
                  name="other"
                  label="Other"
                  type="number"
                  value={otherRefills}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setOtherRefills(e.target.value)
                  }
                />
              </Group>
              <FormError touched={!!validationError} error={validationError} />
              <ButtonWrapper>
                <PrimaryButton onClick={() => refillMedication()}>Create Refill</PrimaryButton>
              </ButtonWrapper>
            </>
          )}
        </InfoWrapper>
      )}
    >
      {children({ openRefills })}
    </Popover>
  );
};

const InfoWrapper = styled(Card)`
  min-width: 450px;
  max-width: 90%;
  border-radius: 5px;
  padding: ${SpacingRelated.HalfRegular};
  border: ${(props) => `1px solid ${props.theme.FontColors.Secondary}`};
`;

const Group = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${SpacingRelated.Regular};

  label {
    margin-right: 15px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
