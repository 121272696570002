const getUrl = () => {
  const url = window.location.href;
  return url;
};

export const getBaseUrl = () => {
  const url =
    process.env.NODE_ENV === 'development' ? 'https://devapp.outpost.health' : window.location.href;
  const { hostname } = new URL(url);
  return hostname;
};

export default getUrl;
