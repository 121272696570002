import React from 'react';
import { Box } from '@chakra-ui/react';

import { RXIcon } from '../../../../../assets/icons';
import { PHRMedication } from '../../../../../generated';

import PhrItem, {
  SecondaryInfoComponent,
  PHRItemList,
  EmptyPHRItemListCard,
} from '../../../components/PhrItem';
import { getTime } from '../../../../../utils';

interface IProps {
  phrMedications: PHRMedication[];
  onView: (phrMedication: PHRMedication) => void;
  emptyMessage: string;
}

export const MedicationList = ({ phrMedications, onView, emptyMessage }: IProps) => {
  return (
    <Box>
      {phrMedications.length === 0 ? (
        <EmptyPHRItemListCard>{emptyMessage}</EmptyPHRItemListCard>
      ) : (
        <PHRItemList mobileHeightOffset="13.5rem" height="450px">
          {phrMedications
            .sort((a, b) => getTime(b.startDate) - getTime(a.startDate))
            .map((item, index) => (
              <PhrItem
                key={index}
                name={item.name}
                isVerified={item.verified}
                onView={() => onView(item)}
                LeadingIcon={
                  <RXIcon
                    marginRight={{ base: '0.5rem', md: '1rem' }}
                    opacity={item.patientPrescriptionID ? 1 : 0}
                  />
                }
                SecondaryInfo={
                  <SecondaryInfoComponent startDate={item.startDate} endDate={item.endDate} />
                }
              />
            ))}
        </PHRItemList>
      )}
    </Box>
  );
};
