import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import SimpleValue from 'react-select-simple-value';
import styled from 'styled-components';
import Card from '../../../components/Card';
import EmptyListCard from '../../../components/EmptyListCard';
import Modal from '../../../components/Modals/Modal';
import SkeletonLine from '../../../components/Skeleton/SkeletonLine';
import { SkeletonPulse } from '../../../components/Skeleton/SkeletonPulse';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';
import { getReactSelectStyle } from '../../../constants';
import { IPHRPatientEnhanced } from '../../../store/patient/phr/phrReducers';
import { deleteForPhrSection } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import PhrChartSkeleton from '../components/PhrChartSkeleton';
import { AddPHRItemButton } from '../components/PhrItem';
import { PhrDetailsButtons } from '../components/PhrItemView';
import { VitalsTracker } from '../vitals/components/PatientVitalsCard/usePatientVitalsCard';
import TrackerDetailCardComponent from './TrackerDetailsCard';
import TrackerDetailsView from './TrackerDetailsView';

enum ToggleID {
  TrendView = 'TrendView',
  ListView = 'ListView',
}

enum ViewStateEnum {
  Home = 'home',
  Details = 'details',
  Edit = 'edit',
}
interface IProps {
  phrCategoryEnum: ReduxPHRCategoryEnum;
  patient: IPHRPatientEnhanced;
  trackerData: any;
  thinView: (x: any, onView?: (data: any) => void) => any;
  Chart?: any;
  Form: any;
  createText: string;
  unitTypeOptions?: any;
  unitValue?: string;
  setSelectedUnitValue?: (x: any) => void;
  renderHeader: any;
  showSingleTrackerForm?: (id: ReduxPHRCategoryEnum, tracker: VitalsTracker) => void;
  defaultBack?: any;
  defaultTitle?: string;
}

const TrackerView = ({
  phrCategoryEnum,
  patient,
  trackerData,
  thinView,
  Chart,
  Form,
  createText,
  unitTypeOptions,
  unitValue,
  setSelectedUnitValue,
  renderHeader,
  showSingleTrackerForm,
  defaultBack,
  defaultTitle,
}: IProps) => {
  const dispatch = useDispatch();
  const [isNew, setIsNew] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [recordToDelete, setRecordToDelete] = useState<string>('');
  const [selectedData, setSelectedData] = useState<any | null>(null);
  const { isPhrLoading } = useSelector((state: AppState) => state.phrPatientState);

  const [viewState, setViewState] = useState<ViewStateEnum>(ViewStateEnum.Home);

  const onEdit = (data: any) => {
    setSelectedData(data);
    setViewState(ViewStateEnum.Edit);
  };

  const onView = (data: any) => {
    setSelectedData(data);
    setViewState(ViewStateEnum.Details);
  };

  const onDelete = (phrTrackerDetailID: string) => {
    setShowDeleteModal(true);
    setRecordToDelete(phrTrackerDetailID);
  };

  const confirmDelete = async () => {
    setShowDeleteModal(false);
    await dispatch(deleteForPhrSection(phrCategoryEnum, recordToDelete, patient.patientID, true));
    setViewState(ViewStateEnum.Home);
  };

  const unitSelector = (handleChange) => {
    return (
      <div style={{ width: 300 + 'px' }}>
        <label>Unit</label>
        <SimpleValue
          options={unitTypeOptions}
          value={unitValue}
          getOptionValue={(option: any) => option.description}
        >
          {(simpleProps) => {
            return (
              <ReactSelect
                name="unit"
                getOptionLabel={(option: any) => option.description}
                onChange={(val: any) => {
                  handleChange?.(val.description);
                  setSelectedUnitValue!(val.description);
                }}
                styles={getReactSelectStyle()}
                {...simpleProps}
              />
            );
          }}
        </SimpleValue>
      </div>
    );
  };

  const [activeId, setActiveId] = useState<ToggleID>(ToggleID.TrendView);

  return (
    <>
      {viewState === ViewStateEnum.Edit
        ? renderHeader(
            null,
            () => {
              if (isNew) setViewState(ViewStateEnum.Home);
              else setViewState(ViewStateEnum.Details);
            },
            defaultTitle,
          )
        : viewState === ViewStateEnum.Details
        ? renderHeader(
            () => (
              <PhrDetailsButtons
                phrItem={selectedData}
                phrItemID={selectedData?.phrTrackerDetailID}
                isVerified={selectedData?.verified}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ),
            () => {
              setActiveId(ToggleID.ListView);
              setViewState(ViewStateEnum.Home);
            },
            defaultTitle,
          )
        : renderHeader(
            null,
            //   () => (
            //   <AddPhrItemButton onClick={() => {
            //     setIsNew(true)
            //     setSelectedData(null);
            //     setViewState(ViewStateEnum.Edit)
            //   }}>
            //     {createText}
            //   </AddPhrItemButton>
            // )
            defaultBack,
            defaultTitle,
          )}

      {viewState === ViewStateEnum.Home && (
        <>
          {isPhrLoading ? (
            Chart ? (
              <Box marginX={{ base: '1rem', md: '2.5rem' }} marginBottom="1rem">
                <TrackerSkeletonView />
              </Box>
            ) : (
              <Box marginX={{ base: '1rem', md: '2.5rem' }} marginBottom="1rem">
                <StyledCardSkeleton />
              </Box>
            )
          ) : Chart ? (
            <ToggleSwitch initial={activeId} active={activeId}>
              {/* <ButtonSwitchWrapper> */}
              <ToggleSwitch.ButtonWrapper
                display="flex"
                justifyContent="center"
                maxW="100%"
                bgColor="transparent"
                borderRadius="none"
                borderBottom="1px solid #78819D3D"
                padding={0}
                mb="1.5rem"
              >
                <ToggleSwitch.Button
                  id={ToggleID.TrendView}
                  onClick={() => setActiveId(ToggleID.TrendView)}
                  w="150px"
                  activeBgColor="#000000"
                  activeColor="#ffffff"
                  color="#000000"
                  borderRadius="12px 12px 4px 4px"
                >
                  Trend View
                </ToggleSwitch.Button>
                <ToggleSwitch.Button
                  id={ToggleID.ListView}
                  onClick={() => setActiveId(ToggleID.ListView)}
                  w="150px"
                  activeBgColor="#000000"
                  activeColor="#ffffff"
                  color="#000000"
                  borderRadius="12px 12px 4px 4px"
                >
                  List View
                </ToggleSwitch.Button>
              </ToggleSwitch.ButtonWrapper>
              {/* </ButtonSwitchWrapper> */}
              <AddPHRItemButton
                name={createText}
                onCreate={() => {
                  showSingleTrackerForm?.(phrCategoryEnum, trackerData);
                }}
                marginBottom="1rem"
              />

              <ToggleSwitch.Area id={ToggleID.TrendView}>
                <Chart dataSet={trackerData} unit={unitValue} />
              </ToggleSwitch.Area>

              <ToggleSwitch.Area id={ToggleID.ListView}>
                <Box height={{ base: 'calc(100vh - 11rem)', md: '470px' }} overflowY="auto">
                  {trackerData.length > 0 ? (
                    trackerData.map((x) => {
                      return (
                        <TrackerDetailCardComponent
                          key={x.phrTrackerDetailID}
                          ThinView={() => thinView(x, onView)}
                        />
                      );
                    })
                  ) : (
                    <EmptyListCard>Nothing added yet</EmptyListCard>
                  )}
                </Box>
              </ToggleSwitch.Area>
            </ToggleSwitch>
          ) : (
            <>
              {trackerData.length > 0 ? (
                trackerData.map((x) => (
                  <TrackerDetailCardComponent
                    key={x.phrTrackerDetailID}
                    ThinView={() => thinView(x, onView)}
                  />
                ))
              ) : (
                <EmptyListCard>Nothing added yet</EmptyListCard>
              )}
            </>
          )}
        </>
      )}

      {viewState === ViewStateEnum.Edit && (
        <Box marginX={{ base: '1rem', md: '2.5rem' }} marginBottom="1rem">
          <Form
            selectedData={selectedData}
            patientID={patient.patientID}
            toggleView={() => {
              setIsNew(false);
              setSelectedData(null);
              setViewState(ViewStateEnum.Home);
            }}
            unit={unitValue}
            unitSelector={unitTypeOptions && unitSelector}
          />
        </Box>
      )}

      {viewState === ViewStateEnum.Details && (
        <>
          <Box marginX={{ base: '1rem', md: '2.5rem' }} marginBottom="1rem">
            <TrackerDetailsView
              item={selectedData}
              name={selectedData?.trackerTypeEnum}
              createdDate={selectedData?.trackerDate}
              unitValue={unitValue}
              verifiedCallback={() => {
                const index = trackerData.findIndex(
                  ({ phrTrackerDetailID }) =>
                    phrTrackerDetailID === selectedData?.phrTrackerDetailID,
                );
                if (index > -1) {
                  trackerData[index].verified = true;
                }
              }}
            />
          </Box>
        </>
      )}

      <Modal
        title="Delete Record?"
        onDismiss={() => setShowDeleteModal(false)}
        onCancel={() => setShowDeleteModal(false)}
        onSubmit={confirmDelete}
        submitText="Yes"
        cancelText="Cancel"
        isOpen={showDeleteModal}
      >
        <p>Are you sure you want to delete this record?</p>
      </Modal>
    </>
  );
};

export default TrackerView;

export const TrackerSkeletonView = () => {
  return (
    <>
      <LinkRow>
        <LinkWrapper>
          <SkeletonLine inverse>Chart View</SkeletonLine>
          <SkeletonLine inverse>List View</SkeletonLine>
        </LinkWrapper>
      </LinkRow>
      <PhrChartSkeleton />
    </>
  );
};

const LinkRow = styled.div`
  display: flex;
  justify-content: center;
  margin: 2.5rem 0;
`;

const LinkWrapper = styled.div`
  width: 75%;
  display: flex;
  justify-content: space-around;
`;

const StyledCardSkeleton = styled(Card)`
  ${SkeletonPulse};
  border-radius: 20px;
  margin-bottom: 0.5rem;
  height: 200px;
`;
