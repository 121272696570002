import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton';
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton';
import Card from '../../../components/Card';
import DropzoneComponent from '../../../components/DropzoneComponent';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import ServerValidationBox from '../../../components/ServerValidationBox';
import {
  AuthClient,
  PHRDocument,
  PHRDocumentCategoryEnum,
  PHRDocumentsClient,
  PHRDocumentUploadDetails,
} from '../../../generated';
import { getAllForPhrSection } from '../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { httpRequest } from '../../../utils';
import { getFileDetail } from '../../../utils/fileHelpers';
import { ButtonRow } from '../components/StyledPhrItems';

interface IProps {
  toggleView: () => void;
  patientID: string;
  onEdit?: (phrDocument: PHRDocument) => void;
}

const DocumentsAddForm = ({ toggleView, patientID, onEdit }: IProps) => {
  const dispatch = useDispatch();
  const phrDocumentsClient = new PHRDocumentsClient(new AuthClient());
  const [files, setFiles] = useState<File[]>([]);
  const [isUploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [editDocument, setEditDocument] = useState<PHRDocument>();

  const uploadDocuments = async () => {
    const filesPromise: Promise<any>[] = [];
    for (const file of files) {
      setUploading(true);
      const promise: Promise<any> = new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', async (t: any) => {
          const fileDetail = getFileDetail(file.type, file.size, file.name, t.currentTarget.result);
          try {
            const newPhrDocument = new PHRDocumentUploadDetails();
            newPhrDocument.fileDetail = fileDetail;
            newPhrDocument.phrDocumentCategoryEnum = PHRDocumentCategoryEnum.Other;
            newPhrDocument.patientID = patientID;

            const phrDocumentID = await httpRequest(() =>
              phrDocumentsClient.uploadPHRDocument(newPhrDocument),
            );

            const docToEdit = await phrDocumentsClient.pHRDocumentsGet(patientID, phrDocumentID);
            setEditDocument(docToEdit);

            dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRDocuments, patientID));
            resolve(newPhrDocument);
          } catch (err) {
            setUploading(false);
            setError(err as any);
          }
        });
        reader.readAsArrayBuffer(file);
      });
      filesPromise.push(promise);
    }

    Promise.all(filesPromise).then(async () => {
      setUploading(false);
    });
  };

  useEffect(() => {
    onEdit!(editDocument!);
  }, [editDocument, onEdit]);

  return (
    <Card>
      {isUploading ? (
        <CenteredLoadingIndicator />
      ) : (
        <>
          <ServerValidationBox message={error} />
          <Box minHeight="300px" width="100%">
            <DropzoneComponent files={files} setFiles={setFiles} multiple={false} />
          </Box>
          <ButtonRow>
            <SecondaryButton onClick={toggleView}>Cancel</SecondaryButton>
            <PrimaryButton onClick={uploadDocuments}>Upload All</PrimaryButton>
          </ButtonRow>
        </>
      )}
    </Card>
  );
};

export default DocumentsAddForm;
