import { BASE_URL } from '../baseUrl';
import { EMPTY_GUID, ThumbnailTypeEnum } from '../constants';

export const getUserThumbnail = (
  imageDetailID: string | undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  thumbnailType: ThumbnailTypeEnum = ThumbnailTypeEnum.User,
) => {
  return imageDetailID && imageDetailID !== EMPTY_GUID
    ? `${BASE_URL}/api/v1/imagedetails/${imageDetailID || ''}/ThumbnailAsSrc`
    : undefined;
};
