import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';

import { CenteredLoadingIndicator } from '../../../../components/LoadingIndicator';

import { ConfirmationModal, HeaderSection } from '../../../../components/Modals';
import { usePartnerSettings } from '../../../../services/partners/usePartnerSettings';
import { ContinueButton } from '../../../heal/patient/BookAppointment/components/common/Buttons';
import {
  SelectedSymptomsContainer,
  SymptomsSearch,
} from '../../../heal/patient/VirtualClinic/components/Symptoms/components';

import { UsePublicBookingFlowReturnType } from '../../hooks/usePublicBookingFlow';
import { useSymptoms } from '../../hooks/useSymptoms';

type SymptomsProps = {
  controller: UsePublicBookingFlowReturnType;
};

const Symptoms: FC<SymptomsProps> = (props) => {
  const controller = useSymptoms(props.controller);

  const { partnerSymptomText, partnerSymptomsText } = usePartnerSettings();

  return (
    <VStack height="100%" alignItems="stretch" spacing="0">
      <HeaderSection
        heading="What brings you in today?"
        containerProps={{
          mb: '3',
        }}
        onGoBack={props.controller.handleGoToPreviousBookingData}
      />

      {controller.isSavingSession ? (
        <CenteredLoadingIndicator />
      ) : (
        <Box flex="1" overflowY="auto">
          <HStack justifyContent="center" pb="4">
            <ContinueButton onClick={controller.handleNextStep}>Continue</ContinueButton>
          </HStack>

          <Box flex="1" overflowY="auto">
            <Box maxW={{ md: '65%' }} width="100%" justifyContent="center" mx="auto">
              <Box>
                <SelectedSymptomsContainer
                  selectedSymptoms={controller.selectedTags}
                  onRemoveSymptom={controller.onUnSelectTag}
                />
              </Box>
              <Box mt="5">
                {controller.submitButtonTouched && controller.symptomsNotSelected && (
                  <Box mb="2">
                    <Text fontSize="14px" color="rgb(255, 43, 79)">
                      You need to select at least one {partnerSymptomText ?? 'symptom'} to continue
                    </Text>
                  </Box>
                )}
                {controller.isSearchDisabled && (
                  <Box mb="2">
                    <Text fontSize="14px" color="rgb(65, 79, 101)">
                      You can select {controller.maxSymptoms} {partnerSymptomsText ?? 'symptoms'} at
                      most
                    </Text>
                  </Box>
                )}
                <SymptomsSearch
                  selectedTags={controller.selectedTags}
                  symptomTags={controller.symptomTags}
                  onSelectTags={controller.onSelectTags}
                  isLoadingCareTypeTags={controller.isLoadingCareTypeTags}
                  isDisabled={controller.isSearchDisabled}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <ConfirmationModal
        isModalOpen={!!controller.isAttemptToBookModalOpen && !controller.isSavingSession}
        description="We will need to collect a bit more information about you to proceed with this appointment.  Once you sign in / sign up, the booking of this appointment will continue."
        onConfirm={controller.onConfirmContinueBooking}
        onCancel={controller.onCancelContinueBooking}
        confirmButtonText="Continue"
        cancelButtonText="Cancel"
      />
    </VStack>
  );
};

export { Symptoms };
