import { Tag } from '../generated';

type TGetGroupedTags = {
  tags?: Tag[];
  commonLabel?: string;
  allLabel?: string;
};

type TGroupTags = {
  common: Tag[];
  all: Tag[];
};

type TSelectGroup = {
  label: string;
  options: Tag[];
};

const commonTagsIndex = 9;

export const getGroupedTags = (tags?: Tag[]) => {
  const groupedTags = tags?.reduce(
    (total, current) => {
      if (current.rank > commonTagsIndex) {
        total.common.push(current);
      } else {
        total.all.push(current);
      }

      return total;
    },
    { common: [], all: [] } as TGroupTags,
  );

  return groupedTags;
};

export const getSelectGroup = ({
  tags,
  commonLabel = 'Common Symptoms',
  allLabel = 'All Symptoms',
}: TGetGroupedTags) => {
  const groupedTags = getGroupedTags(tags);

  const selectGroups: TSelectGroup[] = [
    { label: commonLabel, options: groupedTags?.common ?? [] },
    {
      label: allLabel,
      options: groupedTags?.all ?? [],
    },
  ];

  return selectGroups;
};
