import CookieConsent from 'react-cookie-consent';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MyNetworkRoutes, PublicSearchRoutes } from '../../constants';
import { AppState } from '../../store/root-reducers';

const CookieConsentBanner = () => {
  const currentApp = useSelector((state: AppState) => state.currentAppState.data);

  const location = useLocation();

  if (
    currentApp?.isInMobileView ||
    currentApp?.isMobileSystem ||
    !!currentApp?.mobileSystem ||
    MyNetworkRoutes.HomePage === location.pathname ||
    PublicSearchRoutes.Search === location.pathname
  ) {
    return null;
  }

  const SUFFIX = process.env.REACT_APP_KEY_SUFFIX;

  return (
    <CookieConsent
      location="bottom"
      buttonText="I Understand"
      cookieName={`cookie.consent${SUFFIX}`}
      style={{ background: 'rgb(0, 3, 16)', zIndex: 10000 }}
      buttonStyle={{
        color: 'rgb(0, 3, 16)',
        fontSize: '14px',
        background: ' rgb(255, 194, 0)',
        borderRadius: '6px',
      }}
      expires={365}
      sameSite="strict"
    >
      We use cookies to ensure that we give you the best experience on our website. If you continue
      to use this site you will accept all cookies.
    </CookieConsent>
  );
};

export { CookieConsentBanner };
