import {
  Avatar,
  Checkbox,
  Flex,
  HStack,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AddressBook } from '../../../../generated';
import { AppState } from '../../../../store/root-reducers';
import { getDistanceToShow } from '../../../../utils/getDistanceToShow';
import { getUserThumbnail } from '../../../../utils/getUserThumbnail';

import { AddressBookModeEnum } from '../../AddressBook';
import useAddressBooksContext from '../../context/AddressBooksProvider';
import {
  getAddressBookName,
  getOneLineAddress,
  getSortedAddressBookTags,
  hasAddress,
} from '../../utils';

interface IProps {
  addressBook: AddressBook;
  onToggle?: (addressBook: AddressBook) => void;
  small?: boolean;
  noAddress?: boolean;
  noBadge?: boolean;
  isCardSelected?: boolean;
}

export const AddressBookCardContents = ({
  addressBook,
  onToggle,
  small = false,
  noAddress = false,
  isCardSelected = false,
}: IProps) => {
  const { mode, validationMessage, onValidate } = useAddressBooksContext();
  const [isValidate, setIsValidate] = useState<boolean>(false);
  const [, /*specialty*/ setSpecialty] = useState<string>('');
  const currentProvider = useSelector((state: AppState) => state.providerState.provider);
  const providerMeasurementType = currentProvider?.country?.measurementTypeEnum;

  const isMobile = useBreakpointValue({ base: true, md: false });

  const distanceToShow = getDistanceToShow(addressBook.distance, providerMeasurementType);

  useEffect(() => {
    if (addressBook) {
      // validate address book
      setIsValidate(onValidate(addressBook));

      // set a specialty tag
      setSpecialty(getSortedAddressBookTags(addressBook.addressBookTags)[0]?.tag?.name || '');
    }
  }, [addressBook, onValidate]);

  return (
    <Tooltip
      label={validationMessage}
      bg="rgb(255, 43, 79)"
      isDisabled={isValidate}
      placement="top"
      offset={[0, -20]}
    >
      <HStack
        spacing={{ base: 2, md: 4 }}
        width="100%"
        role="button"
        onClick={(e) => {
          if (mode !== AddressBookModeEnum.Browse) {
            if (isValidate) onToggle?.(addressBook);
            e.preventDefault();
          }
        }}
        _hover={!isValidate ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
        py="1"
      >
        <VStack>
          <Checkbox
            size="lg"
            sx={{
              '.chakra-checkbox__control[data-checked]': {
                background: '#322167',
                borderColor: '#322167',
              },
            }}
            isChecked={isCardSelected}
          />
        </VStack>
        <Flex alignItems="center">
          <Avatar
            size={isMobile ? 'sm' : 'md'}
            src={getUserThumbnail(addressBook?.imageDetailID)}
          />
        </Flex>
        <Stack spacing={0} w="100%" alignItems="flex-start" justifyContent="center">
          <Flex justifyContent="space-between" alignItems="center" w="100%">
            <Stack spacing={0}>
              <Flex alignItems="center" mb={0} textAlign="left" fontSize={small ? '14px' : '16px'}>
                {getAddressBookName(addressBook)}
              </Flex>
              {!noAddress && !small && hasAddress(addressBook) && (
                <Text align="left" noOfLines={1} fontSize={small ? '11px' : '12px'}>
                  {getOneLineAddress(addressBook)}
                </Text>
              )}
              {addressBook.faxPhoneNumber && (
                <Text align="left" fontSize={small ? '11px' : '12px'}>
                  Fax: {addressBook?.faxPhoneNumber}
                </Text>
              )}
              <Text align="left" fontSize={small ? '11px' : '12px'}>
                {addressBook?.addressBookFolderEnum}
              </Text>
              {distanceToShow && (
                <Text align="left" fontSize={small ? '11px' : '12px'}>
                  Distance: <Text as="strong">{distanceToShow}</Text>
                </Text>
              )}
            </Stack>
          </Flex>
          {!noAddress && small && hasAddress(addressBook) && (
            <Text mb={1} noOfLines={1} fontSize={small ? '11px' : '12px'}>
              {getOneLineAddress(addressBook)}
            </Text>
          )}
        </Stack>
      </HStack>
    </Tooltip>
  );
};
