import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../components/Modals/Modal';
import { RolesEnum } from '../../../generated';
import useInterval from '../../../hooks/useInterval';
import { logoutUser } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { isInRole } from '../../../utils/isInRole';

const AutoLogout = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const shouldRunLogoutTimer =
    window.location.pathname.toLowerCase().indexOf('/virtualclinic/queue') === -1;

  // Dont add <boolean> to these useStates, it for some reason breaks the resetTimer
  const [longTimer, setLongTimer] = useState(false);
  const [shortTimer, setShortTimer] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  // 29 minutes for patients, 59 minutes for providers
  const warningTimeout = isInRole(currentUser, RolesEnum.Patient) ? 1740000 : 3540000;
  const timeoutNow = 60000;

  const resetTimer = (longTimer) => {
    if (longTimer) {
      setLongTimer(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', resetTimer, false);
    document.addEventListener('mousedown', resetTimer, false);
    document.addEventListener('keypress', resetTimer, false);
    document.addEventListener('touchmove', resetTimer, false);
    document.addEventListener('onscroll', resetTimer, false);
    const wakeupWorker = new Worker('/detectWakeup.js');
    wakeupWorker.onmessage = function (ev) {
      const userAgent = navigator.userAgent || navigator.vendor;
      // don't logout if a user locks phone or puts thier browser in the background
      // https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
      //@ts-ignore
      if (/android/i.test(userAgent) || (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)) {
        return;
      }
      if (ev && ev.data === 'wakeup') {
        dispatch(logoutUser());
      }
    };

    return () => {
      document.removeEventListener('mousemove', resetTimer, false);
      document.removeEventListener('mousedown', resetTimer, false);
      document.removeEventListener('keypress', resetTimer, false);
      document.removeEventListener('touchmove', resetTimer, false);
      document.removeEventListener('onscroll', resetTimer, false);
      wakeupWorker.terminate();
    };
  }, [dispatch]);

  useEffect(() => {
    if (shouldRunLogoutTimer) {
      if (!longTimer && !shortTimer) {
        setLongTimer(true);
      }

      if (shortTimer && longTimer) {
        setLongTimer(false);
      }
    }
  }, [longTimer, shortTimer, shouldRunLogoutTimer]);

  useInterval(
    () => {
      setShowLogoutModal(true);
      setShortTimer(true);
    },
    longTimer ? warningTimeout : null,
  );

  useInterval(
    () => {
      setShowLogoutModal(false);
      dispatch(logoutUser());
    },
    shortTimer ? timeoutNow : null,
  );

  return (
    <Modal
      isOpen={showLogoutModal}
      title="Inactivity Warning"
      onSubmit={() => {
        setLongTimer(false);
        setShortTimer(false);
        setShowLogoutModal(false);
      }}
      submitText={'Stay Logged In'}
    >
      <p>Due to inactivity, we will automatically log you out in 1 minute.</p>
    </Modal>
  );
};

export default AutoLogout;
