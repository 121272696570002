import { useCallback, useState } from 'react';
import { PatientOrder, SendToEnum } from '../../../../generated';
import { usePatientOrdersDelete } from '../../../../services/patientOrders/mutations/usePatientOrdersDelete';
// import { usePatientOrdersPut } from '../../../../services/patientOrders/mutations/usePatientOrdersPut';
// import { httpRequest } from '../../../../utils';
import {
  ModalStateEnum,
  OrderWithQuestionairEnum,
} from '../components/CreateOrder/contexts/CreateOrderContext';

interface IProps {
  draftPatientOrder?: PatientOrder | undefined;
  setPatientOrder: React.Dispatch<React.SetStateAction<PatientOrder | undefined>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useOrderModalNavigation = ({
  draftPatientOrder,
  setPatientOrder,
  setLoading,
}: IProps) => {
  const [modalState, setModalState] = useState<ModalStateEnum>(
    !draftPatientOrder
      ? ModalStateEnum.SelectOrder
      : draftPatientOrder.sendToEnum === SendToEnum.DoNotSend
      ? (draftPatientOrder.patientOrderItems ?? []).length > 0
        ? ModalStateEnum.Build
        : ModalStateEnum.Questionair
      : ModalStateEnum.Summary,
  );
  const patientOrdersDelete = usePatientOrdersDelete();
  // const patientOrdersPut = usePatientOrdersPut();

  const onBack =
    modalState === ModalStateEnum.SelectOrder ||
    modalState === ModalStateEnum.Success ||
    modalState === ModalStateEnum.Questionair
      ? undefined
      : async () => {
          switch (modalState) {
            // case ModalStateEnum.Questionair:
            //   if (draftPatientOrder) {
            //     setLoading(true);
            //     await patientOrdersDelete.mutateAsync(draftPatientOrder.patientOrderID);
            //     setLoading(false);
            //   }
            //   setPatientOrder(undefined);
            //   setModalState(ModalStateEnum.SelectOrder);
            //   break;
            case ModalStateEnum.Build:
              setLoading(true);
              if (
                draftPatientOrder &&
                !Object.values(OrderWithQuestionairEnum).includes(
                  (draftPatientOrder.order?.name || '') as OrderWithQuestionairEnum,
                )
              ) {
                await patientOrdersDelete.mutateAsync(draftPatientOrder.patientOrderID);
                setPatientOrder(undefined);
                setModalState(ModalStateEnum.SelectOrder);
              } else {
                // if ((draftPatientOrder?.patientOrderItems ?? []).length > 0) {
                //   let po = new PatientOrder();
                //   po.init(draftPatientOrder);
                //   po.patientOrderItems = draftPatientOrder!.patientOrderItems!.map((x) => {
                //     x.isDeleted = true;
                //     return x;
                //   });
                //   await patientOrdersPut.mutateAsync(po);
                //   po = await httpRequest(() =>
                //     new PatientOrdersClient(new AuthClient()).patientOrdersGet(po.patientOrderID!),
                //   );
                //   setPatientOrder(po);
                // }

                setModalState(ModalStateEnum.Questionair);
              }
              setLoading(false);
              break;
            case ModalStateEnum.Summary:
              setModalState(ModalStateEnum.Build);
              break;
            case ModalStateEnum.Confirm:
              setModalState(ModalStateEnum.Summary);
              break;
          }
        };

  const goToNextStep = useCallback(() => {
    switch (modalState) {
      case ModalStateEnum.SelectOrder:
        setModalState(ModalStateEnum.Questionair);
        break;
      case ModalStateEnum.Questionair:
        setModalState(ModalStateEnum.Build);
        break;
      case ModalStateEnum.Build:
        setModalState(ModalStateEnum.Summary);
        break;
      case ModalStateEnum.Summary:
        setModalState(ModalStateEnum.Confirm);
        break;
      case ModalStateEnum.Confirm:
        setModalState(ModalStateEnum.Success);
        break;
    }
  }, [modalState]);

  return { modalState, setModalState, onBack, setLoading, goToNextStep };
};
