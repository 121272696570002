import React, { FC } from 'react';
import { ButtonProps } from '@chakra-ui/react';

import { ActionButton, ActionButtonProps } from './ActionButton';

export type PrimaryButtonProps = ButtonProps & {
  buttonVariant?: ActionButtonProps['buttonVariant'];
};

const PrimaryButton: FC<PrimaryButtonProps> = (props) => (
  <ActionButton buttonColor="purple.600" buttonHoverColor="white" {...props}>
    {props.children}
  </ActionButton>
);

export { PrimaryButton };
