import { Box, Flex } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PHRFamilyHistory } from '../../../generated';
import { clearPHRPatientErrorAction } from '../../../store/patient/phr/phrActions';
import { IPHRPatientEnhanced } from '../../../store/patient/phr/phrReducers';
import { phrFamilyHistoriesSelector } from '../../../store/patient/phr/phrSelectors';
import { deleteForPhrSection, getAllForPhrSection } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../store/types';

import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import ServerValidationBox from '../../../components/ServerValidationBox';
import { scrollToTop } from '../../../utils/scrollToTop';
import { AddPHRItemButton } from '../components/PhrItem';
import PhrItemDeleteModal from '../components/PhrItemDeleteModal';
import { PhrFormButtons } from '../components/PhrItemForm';
import { PhrDetailsButtons } from '../components/PhrItemView';
import FamilyHistoryForm from './FamilyHistoriesForm';
import FamilyHistoriesList from './FamilyHistoriesList';
import FamilyHistoryView from './FamilyHistoryView';

enum EFamilyHistoryViewState {
  List = 'List',
  View = ' View',
  Edit = 'Edit',
}

interface IProps {
  patient: IPHRPatientEnhanced;
  renderHeader: (onCreate?: any, onBack?: () => void, title?: string, showDismiss?: boolean) => any;
}

const FamilyHistoryComponent = ({ patient, renderHeader }: IProps) => {
  const dispatch = useDispatch();
  const innerBtnRef = useRef<HTMLButtonElement>(null);
  const phrFamilyHistories = phrFamilyHistoriesSelector(patient.phrFamilyHistories);
  const { isPhrLoading, error } = useSelector((state: AppState) => state.phrPatientState);
  const [selectedFamilyHistory, setSelectedFamilyHistory] = useState<PHRFamilyHistory | null>(null);
  const [familyHistoryIDToDelete, setFamilyHistoryIDToDelete] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [currentView, setCurrentView] = useState<EFamilyHistoryViewState>(
    EFamilyHistoryViewState.List,
  );

  useEffect(() => {
    if (patient) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRFamilyHistories, patient.patientID));
    }
  }, [dispatch, patient]);

  const onCreate = () => {
    setSelectedFamilyHistory(null);
    setCurrentView(EFamilyHistoryViewState.Edit);
  };

  const onEdit = (phrFamilyHistory: PHRFamilyHistory) => {
    setSelectedFamilyHistory(phrFamilyHistory);
    setCurrentView(EFamilyHistoryViewState.Edit);
  };

  const onView = (phrFamilyHistory: PHRFamilyHistory) => {
    setSelectedFamilyHistory(phrFamilyHistory);
    setCurrentView(EFamilyHistoryViewState.View);
  };

  const onDelete = (phrFamilyHistoryID: string) => {
    setShowDeleteModal(true);
    setFamilyHistoryIDToDelete(phrFamilyHistoryID);
  };

  const confirmDelete = async () => {
    setCurrentView(EFamilyHistoryViewState.List);
    setShowDeleteModal(false);
    await dispatch(
      deleteForPhrSection(
        ReduxPHRCategoryEnum.PHRFamilyHistories,
        familyHistoryIDToDelete,
        patient.patientID,
        false,
      ),
    );
  };

  useEffect(() => {
    dispatch(clearPHRPatientErrorAction());
    scrollToTop();
  }, [currentView, dispatch]);

  return (
    <Box paddingY="2rem" backgroundColor="#f4f4f4" height="100%">
      {currentView === EFamilyHistoryViewState.List ? (
        renderHeader()
      ) : (
        <Flex>
          {renderHeader(null, () => setCurrentView(EFamilyHistoryViewState.List), '', false)}
          {currentView === EFamilyHistoryViewState.View && (
            <PhrDetailsButtons
              isVerified={selectedFamilyHistory?.verified}
              phrItem={selectedFamilyHistory}
              phrItemID={selectedFamilyHistory?.phrFamilyHistoryID}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          )}
          {currentView === EFamilyHistoryViewState.Edit && (
            <PhrFormButtons
              innerBtnRef={innerBtnRef}
              toggleView={() => setCurrentView(EFamilyHistoryViewState.List)}
            />
          )}
        </Flex>
      )}

      {currentView === EFamilyHistoryViewState.List && (
        <>
          <AddPHRItemButton name="History" onCreate={onCreate} />
          <ServerValidationBox message={error} />
          {isPhrLoading ? (
            <Box marginTop="1rem">
              <CenteredLoadingIndicator />
            </Box>
          ) : (
            <FamilyHistoriesList phrFamilyHistories={phrFamilyHistories} onView={onView} />
          )}
        </>
      )}

      {currentView === EFamilyHistoryViewState.View && (
        <FamilyHistoryView familyHistory={selectedFamilyHistory!} />
      )}

      {currentView === EFamilyHistoryViewState.Edit && (
        <FamilyHistoryForm
          selectedFamilyHistory={selectedFamilyHistory}
          patientID={patient.patientID}
          toggleView={() => setCurrentView(EFamilyHistoryViewState.List)}
          innerBtnRef={innerBtnRef}
        />
      )}

      <PhrItemDeleteModal
        isOpen={showDeleteModal}
        onDismiss={() => setShowDeleteModal(false)}
        onDelete={confirmDelete}
      />
    </Box>
  );
};

export default FamilyHistoryComponent;
