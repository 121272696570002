import { Box, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';

import { Tag } from '../../../../../../../generated';

import { ConsultationTypeCard } from './ConsultationTypeCard';
import useTranslationComponent from '../../../../../../../hooks/useTranslationComponent';

type ConsultationTypesContainerProps = {
  consultationTypes: Tag[];
  onSelectConsultationCareType: (consultationCareType: Tag) => void;
  patientCountryId: string;
};

const shouldRenderTag = (patientCountryId: string, tag: Tag) => {
  // if (!tag.showInCountryIDs) return true;
  // if (tag.showInCountryIDs.includes(patientCountryId)) return true;
  if (tag.name && ['Routine Care', 'Homecare'].includes(tag.name)) return true;

  return false;
};

const ConsultationTypesContainer: FC<ConsultationTypesContainerProps> = (props) => {
  const { t } = useTranslationComponent(['heal']);

  return (
    <Box>
      <Text fontWeight="bold" fontSize="24px" color="rgb(0, 3, 16)" mb="4">
        {t('Choose a Consultation')}
      </Text>
      <VStack spacing="3" alignItems="flex-start" maxW="500px" maxH="650px" overflowY="auto" pr="3">
        {props.consultationTypes.map(
          (consultationType) =>
            shouldRenderTag(props.patientCountryId, consultationType) && (
              <ConsultationTypeCard
                key={consultationType.tagID}
                consultationType={consultationType}
                onSelectConsultationCareType={props.onSelectConsultationCareType}
              />
            ),
        )}
      </VStack>
    </Box>
  );
};

export { ConsultationTypesContainer, shouldRenderTag };
