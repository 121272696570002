import { ActionType, createAsyncAction } from 'typesafe-actions';
import { AuthClient, MarketingCampaignsClient } from '../../generated';
import { httpRequest } from '../../utils';
import { OHThunkResult } from '../root-reducers';

export const getUnacknowledgedRewardCountActions = createAsyncAction(
  ['GET_UNACKNOWLEDGED_REWARD_COUNT_REQUEST', () => {}],
  ['GET_UNACKNOWLEDGED_REWARD_COUNT_SUCCESS', (res: number) => res],
  ['GET_UNACKNOWLEDGED_REWARD_COUNT_ERROR', (err: string) => err],
)();

export function getUnacknowledgedRewardCount(
  marketingCampaignID: string,
): OHThunkResult<Promise<number>> {
  return async (dispatch) => {
    return new Promise<number>(async (resolve) => {
      dispatch(getUnacknowledgedRewardCountActions.request());
      const marketingCampaignsClient = new MarketingCampaignsClient(new AuthClient());
      try {
        const result = await httpRequest(() =>
          marketingCampaignsClient.lastAcknowledgedCount(marketingCampaignID),
        );
        dispatch(getUnacknowledgedRewardCountActions.success(result));
        resolve(result);
      } catch (err) {
        dispatch(getUnacknowledgedRewardCountActions.failure(err as any));
      }
    });
  };
}

export type GetUnacknowledgedRewardCountActionTypes = ActionType<
  typeof getUnacknowledgedRewardCountActions
>;
