import { useState } from 'react';
import {
  AuthClient,
  IPatient,
  Patient,
  PatientInsurance,
  PatientInsurancesClient,
  PatientsClient,
} from '../../../../../../generated';
import { useLanguagesAll } from '../../../../../../services/languages/queries/useLanguagesAll';
import { useTimeZonesAll } from '../../../../../../services/timeZones/queries/useTimeZonesAll';
import moment from 'moment';
import { httpRequest } from '../../../../../../utils';
import { errorToastr, successToastr } from '../../../../../../utils/toastr';

export enum PatientCardStateEnum {
  PatientInfo = 'PatientInfo',
  EditPatientInfo = 'EditPatientInfo',
}

interface IProps {
  primaryInsurance: PatientInsurance | undefined;
  patient: IPatient;
  setPatient: (patient: IPatient) => void;
  setPrimaryInsurance: (ins: PatientInsurance) => void;
}

export const usePatientUpdate = ({
  primaryInsurance = undefined,
  patient,
  setPrimaryInsurance,
  setPatient,
}: IProps) => {
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [activeState, setActiveState] = useState<PatientCardStateEnum>(
    PatientCardStateEnum.PatientInfo,
  );

  const updatePatientDetails = async (values) => {
    setLoading(true);
    const patientsClient = new PatientsClient(new AuthClient());
    const insuranceClient = new PatientInsurancesClient(new AuthClient());

    const { birthDate } = values;
    const insurance = values.insurance;
    delete values.insurance;
    const updatedPatient = { ...patient, ...values } as IPatient;

    if (birthDate && moment(birthDate).isValid()) {
      updatedPatient.birthDateString = moment(birthDate)
        .startOf('day')
        .format('YYYY-MM-DD')
        .toString();
    }

    try {
      const patientRequest = new Patient();
      patientRequest.init(updatedPatient);
      await httpRequest(() => patientsClient.patientsPut(patientRequest));

      if (insurance && primaryInsurance) {
        primaryInsurance.insuranceNumber = insurance;
        await httpRequest(() => insuranceClient.patientInsurancesPut(primaryInsurance));
        setPrimaryInsurance(primaryInsurance);
      }
      setLoading(false);
      setActiveState(PatientCardStateEnum.PatientInfo);
      successToastr({
        description: "Patient's details updated successfully.",
      });
      updatedPatient.fullName = `${updatedPatient.firstName} ${updatedPatient.lastName}`;
      setPatient(updatedPatient);
      return updatedPatient;
    } catch (error) {
      setLoading(false);
      setError(error as string);
      errorToastr({
        description: error as string,
      });
    }
  };

  const { isLoadingTimeZones, timeZones } = useTimeZonesAll();
  const { isLoadingLanguages, languages } = useLanguagesAll();

  const toggleView = () => {
    setActiveState(PatientCardStateEnum.PatientInfo);
  };

  return {
    error,
    loading,
    activeState,
    toggleView,
    isLoadingTimeZones,
    isLoadingLanguages,
    languages,
    timeZones,
    setActiveState,
    updatePatientDetails,
  };
};
