import { Box, Checkbox, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { BellIcon } from '../../../../assets/icons';
import { CHART_PLUGINS } from '../../../../constants';
import { PHRTrackerBloodPressureDTO } from '../../../../generated';
import { OutpostTheme } from '../../../../themes/outpost';
import { getTime } from '../../../../utils';
import TrackerChartBase from '../TrackerChartBase';

interface IProps {
  dataSet: PHRTrackerBloodPressureDTO[];
}

const BloodPressureChartComponent: React.FC<IProps> = ({ dataSet }) => {
  const [daysRange, setDaysRange] = useState<number>(30);
  const [showSystolicBoundary, setShowSystolicBoundary] = useState<boolean>(false);
  const [showDiastolicBoundary, setShowDiastolicBoundary] = useState<boolean>(false);
  const [annotations, setAnnotations] = useState<any[]>([]);
  const chartReference = useRef<any>(null);

  const getAnnotations = (showSystolicBoundary: boolean, showDiastolicBoundary: boolean) => {
    let listOfAnnotations: any[] = [];

    if (showSystolicBoundary) {
      listOfAnnotations = [
        ...listOfAnnotations,
        {
          drawTime: 'afterDatasetsDraw',
          id: 'hline',
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: 140,
          borderColor: '#fa6655',
          borderWidth: 2,
          label: {
            content: 'High Systolic',
            enabled: true,
          },
        },
      ];
    }

    if (showDiastolicBoundary) {
      listOfAnnotations = [
        ...listOfAnnotations,
        {
          drawTime: 'afterDatasetsDraw',
          id: 'hline4',
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: 60,
          borderColor: '#fa6655',
          borderWidth: 2,
          label: {
            content: 'Low Diastolic',
            enabled: true,
          },
        },
      ];
    }

    if (showDiastolicBoundary || showSystolicBoundary) {
      const strings: string[] = [];
      if (showDiastolicBoundary) {
        strings.push('High Diastolic');
      }
      if (showSystolicBoundary) {
        strings.push('Low Systolic');
      }
      listOfAnnotations = [
        ...listOfAnnotations,
        {
          drawTime: 'afterDatasetsDraw',
          id: strings.join('-'),
          type: 'line',
          mode: 'horizontal',
          scaleID: 'y-axis-0',
          value: 90,
          borderColor: '#fa6655',
          borderWidth: 2,
          label: {
            content: strings.join(' / '),
            enabled: true,
          },
        },
      ];
    }

    return listOfAnnotations;
  };

  useEffect(() => {
    setAnnotations(getAnnotations(showSystolicBoundary, showDiastolicBoundary));
  }, [showSystolicBoundary, showDiastolicBoundary]);

  useEffect(() => {
    if (chartReference.current) {
      chartReference.current.chartInstance.update();
    }
  }, [annotations]);

  const data = () => {
    const sorted = [...dataSet].sort((a, b) => getTime(a.trackerDate) - getTime(b.trackerDate));

    return {
      datasets: [
        {
          label: 'Systolic',
          data: sorted.map((d) => ({ x: d.trackerDate, y: d.systolic })),
          fill: false,
          borderColor: '#FF84A1',
          borderWidth: 2,
        },
        {
          label: 'Diastolic',
          data: sorted.map((d) => ({ x: d.trackerDate, y: d.diastolic })),
          fill: false,
          borderColor: '#00A18F',
          borderWidth: 2,
        },
      ],
    };
  };

  const options = {
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          type: 'linear',
          ticks: {
            suggestedMin: 40,
            suggestedMax: 150,
          },
        },
      ],
      xAxes: [
        {
          type: 'time',
          position: 'bottom',
          distribution: 'linear',
          time: {
            unit: daysRange <= 7 ? 'day' : 'month',
          },
          ticks: {
            max: moment(),
            min: moment().subtract(daysRange, 'd'),
          },
        },
      ],
    },
    annotation: {
      events: ['click'],
      annotations,
    },
    plugins: CHART_PLUGINS,
    maintainAspectRatio: false,
    layout: {
      padding: { top: 20, bottom: 20 },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <TrackerChartBase
      name="Blood Pressure"
      color="#FD7024"
      daysRange={daysRange}
      setDaysRange={setDaysRange}
    >
      <Flex
        padding="0.75rem"
        justify="space-between"
        bgColor="#F8F8F8"
        flexDir={{ base: 'column', md: 'row' }}
      >
        <HStack spacing={4} mr={8}>
          <HStack>
            <Box w="17px" h="17px" bgColor="#FF84A1" borderRadius="4px" />
            <Text fontSize={OutpostTheme.FontSizes.body} color="#414F65">
              Systolic
            </Text>
          </HStack>

          <HStack>
            <Box w="17px" h="17px" bgColor="#00A18F" borderRadius="4px" />
            <Text fontSize={OutpostTheme.FontSizes.body} color="#414F65">
              Diastolic
            </Text>
          </HStack>
        </HStack>

        <Flex align="center" justify="space-between" flexGrow={1}>
          <Flex align="center">
            <Text fontSize={OutpostTheme.FontSizes.body} mr={4}>
              Alert Limits:
            </Text>
            <HStack spacing={4}>
              <HStack>
                <Checkbox
                  isChecked={showSystolicBoundary}
                  onChange={() => setShowSystolicBoundary(!showSystolicBoundary)}
                />
                <Text fontSize={OutpostTheme.FontSizes.body} color="#414F65">
                  Systolic
                </Text>
              </HStack>
              <HStack>
                <Checkbox
                  isChecked={showDiastolicBoundary}
                  onChange={() => setShowDiastolicBoundary(!showDiastolicBoundary)}
                />
                <Text fontSize={OutpostTheme.FontSizes.body} color="#414F65">
                  Diastolic
                </Text>
              </HStack>
            </HStack>
          </Flex>

          <IconButton
            aria-label="Calendar Icon"
            p={0}
            w="38px"
            h="38px"
            variant="ghost"
            bgColor="#FFFFFF"
            borderRadius="full"
            marginRight={{ base: 0, md: '1.75rem' }}
          >
            <BellIcon w="16px" h="16px" />
          </IconButton>
        </Flex>
      </Flex>
      <Box
        position="relative"
        height={{ base: 'calc(100% - 10rem)', md: 'calc(100% - 7.5rem)' }}
        width="calc(100% - 20px)"
        margin="0 auto"
      >
        {/* @ts-ignore */}
        <Line data={data} options={options} />
      </Box>
    </TrackerChartBase>
  );
};

export default BloodPressureChartComponent;
