import { useBreakpointValue } from '@chakra-ui/react';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { closeSideMenu } from '../../../../store/sideMenu/sideMenuActions';

const useChangeRoute = () => {
  const status = useBreakpointValue({ base: true, md: false });
  const dispatch = useDispatch();

  const changeRoute = (route: string) => {
    if (status) {
      dispatch(closeSideMenu());
    }
    dispatch(push(route));
  };

  return { changeRoute };
};

export default useChangeRoute;
