import React from 'react';
import { Box, Flex, Text, HStack } from '@chakra-ui/react';
import { PHRTracker, TrackerTypeEnum } from '../../../../generated';
import moment from 'moment';

interface IProps {
  onClick: () => void;
  color?: string;
  value?: any;
  getTrackerDisplayValue: any;
  type: TrackerTypeEnum;
  physicalTrackers: PHRTracker[];
}

const PhysicalTrackerItem = ({
  onClick,
  color,
  getTrackerDisplayValue,
  type,
  physicalTrackers,
}: IProps) => {
  const item = physicalTrackers.find((x) => x.trackerTypeEnum === type);
  const value = getTrackerDisplayValue(type)?.value;
  const date = getTrackerDisplayValue(type)?.date;

  return (
    <Flex
      flexDir="column"
      justify="space-between"
      padding="1.25rem"
      background="#FFFFFF"
      borderRadius="lg"
      h="225px"
      cursor="pointer"
      onClick={onClick}
    >
      <>
        <HStack spacing={4}>
          <Box w="28px" h="28px" borderRadius="lg" bgColor={color} />
          <Text fontSize="12px" textTransform="uppercase" color="#414F65">
            {item?.name}
          </Text>
        </HStack>

        <Text fontSize="24px" fontWeight="semibold">
          {value}
        </Text>

        <Text fontSize="12px" color="#414F65">
          Updated {moment(date).fromNow()}
        </Text>
      </>
    </Flex>
  );
};

export default PhysicalTrackerItem;
