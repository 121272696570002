import { useEffect, useState } from 'react';
import {
  AuthClient,
  PatientPrescription,
  PatientPrescriptionsClient,
  PatientPrescriptionSearchDetails,
  PrescriptionStatusEnum,
} from '../../../../../generated';
import { IPHRPatientCombined } from '../../../../../store/patient/phr/phrReducers';
import { httpRequest } from '../../../../../utils';

interface IProps {
  phrPatient?: IPHRPatientCombined;
  phrConsultID?: string;
}

const usePrescription = ({ phrPatient, phrConsultID }: IProps) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [prescription, setPrescription] = useState<PatientPrescription | null>(null);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [showCreatePrescription, setShowCreatePrescription] = useState<boolean>(false);

  const loadByPatientPrescriptionID = async (patientPrescriptionID: string) => {
    try {
      setError('');
      setLoading(true);
      const prescriptionsClient = new PatientPrescriptionsClient(new AuthClient());
      const result = await httpRequest(() =>
        prescriptionsClient.patientPrescriptionsGet(patientPrescriptionID),
      );
      setPrescription(result);
      setShowCreatePrescription(true);
    } catch (err) {
      setError(err as string);
    }
    setLoading(false);
  };

  useEffect(() => {
    const loadPrescriptions = async () => {
      try {
        setError('');
        setLoading(true);
        const prescriptionsClient = new PatientPrescriptionsClient(new AuthClient());
        const searchDetails = new PatientPrescriptionSearchDetails();
        searchDetails.init({
          patientID: phrPatient?.patient?.patientID,
          phrConsultID: phrConsultID,
          prescriptionStatusEnum: PrescriptionStatusEnum.Draft,
          recordsToSkip: 0,
          recordsToTake: 1,
        });
        const result = await httpRequest(() => prescriptionsClient.search(searchDetails));
        const prescription = result.patientPrescriptions?.items?.length
          ? // @ts-ignore
            result.patientPrescriptions.items[0]
          : null;
        setPrescription(prescription);
      } catch (err) {
        setError(err as string);
      }
      setInitialized(true);
      setLoading(false);
    };

    if (phrPatient && phrConsultID && !initialized) {
      loadPrescriptions();
    }
  }, [phrConsultID, phrPatient, initialized]);

  return {
    isLoading,
    error,
    prescription,
    setPrescription,
    loadByPatientPrescriptionID,
    showCreatePrescription,
    setShowCreatePrescription,
  };
};

export default usePrescription;
