import React from 'react';
import styled from 'styled-components';
import Card from './Card';
import { Device } from '../constants';

const Text = styled.p`
  text-align: center;
  margin: 12px 0;
  font-style: italic;

  @media ${Device.tablet} {
    margin: 12px 0 0;
  }
`;

interface IProps {
  children: any;
  title?: string;
  className?: string;
}

const EmptyListCard = ({ children, title, className }: IProps) => {
  return (
    <Card title={title} className={className}>
      <Text>{children}</Text>
    </Card>
  );
};

export default EmptyListCard;
