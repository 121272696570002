import { createAction } from 'typesafe-actions';
import { PathParamKeysEnum } from '../../constants';

export const updatePageTitle = createAction('UPDATE_PAGE_TITLE', (title: string) => title)();

export const clearPageTitle = createAction('CLEAR_PAGE_TITLE', () => null)();

export const updateBreadcrumb = createAction(
  'UPDATE_BREADCRUMBS',
  (keyValuePair: [PathParamKeysEnum, string]) => keyValuePair,
)();

export const clearBreadcrumb = createAction(
  'CLEAR_BREADCRUMBS',
  (paramKey: PathParamKeysEnum) => paramKey,
)();
