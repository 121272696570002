import { useDisclosure, VStack, Box, Text, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { PrimaryButton } from '../../../components/Buttons';
import { NotificationModal } from '../../../components/Modals/NotificationModal';
import outpostMobilePng from '../../../assets/images/outpost-mobile.png';
import outpostLogoIconSvg from '../../../assets/images/outpost-logo-icon.svg';
import { PersonIcon, ProviderIcon } from '../../../assets/icons';
import { AppState } from '../../../store/root-reducers';
import { PartnerSettingsEnum } from '../../../store/currentPartner/currentPartnerReducers';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';

import { LinkButton } from '../../heal/patient/BookAppointment/components/common/Buttons';

import { useMobileRedirect } from '../hooks/useMobileRedirect';

const MobileRedirect = () => {
  const controller = useMobileRedirect();

  const currentPartner = useSelector((state: AppState) => state.currentPartnerState.data);
  const currentPartnerLoading = useSelector(
    (state: AppState) => state.currentPartnerState.isLoading,
  );

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  const suppressAppStorePopup =
    !!currentPartner?.partnerSettings?.find(
      ({ partnerSettingName }) =>
        partnerSettingName === PartnerSettingsEnum.SUPPRESS_MOBILE_APP_STORE,
    )?.value || false;

  const modalIsOpen =
    (controller.isAndroidDevice || controller.isIosDevice) &&
    controller.isMobile &&
    isOpen &&
    !suppressAppStorePopup;

  const onContinueInBrowser = () => {
    onClose();
  };

  const deviceType = controller.isAndroidDevice ? 'Android' : controller.isIosDevice ? 'iOS' : '';

  const storeName = controller.isAndroidDevice
    ? 'Play Store'
    : controller.isIosDevice
    ? 'App Store'
    : '';

  const logo =
    currentPartner?.partnerSettings?.find(
      ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.APP_LOGO,
    )?.value || outpostLogoIconSvg;

  const companyName = currentPartner?.name || process.env.REACT_APP_COMPANY_NAME;

  return (
    <NotificationModal isModalOpen={!!modalIsOpen}>
      {currentPartnerLoading ? (
        <CenteredLoadingIndicator />
      ) : (
        <VStack justifyContent="space-between" height="100%" overflowY="auto">
          <VStack textAlign="center" flex="1" overflowY="auto">
            <Box mb="4">
              <Image src={logo} alt={companyName} objectFit="cover" maxH="60px" />
            </Box>
            <Box pb="4">
              <Text fontSize="18px" fontWeight="bold">
                We are available on mobile!
              </Text>
            </Box>
            <Box pb="1">
              <Image src={outpostMobilePng} alt="Mobile app" objectFit="cover" maxH="200px" />
            </Box>
            <Box pb="4" maxW="295px">
              <Text fontSize="14px" mb="3">
                {companyName} is available on {deviceType} Devices.
              </Text>
              <Text fontSize="14px">
                Download the {companyName} Mobile App in the <strong>{storeName}</strong> for a
                personalized experience.
              </Text>
            </Box>
            <Box>
              <Box mb="2">
                <PrimaryButton
                  borderRadius="md"
                  width="200px"
                  onClick={() => controller.handleRedirectOnMobile('patient')}
                  isDisabled={controller.isLoading}
                  isLoading={controller.isLoading}
                  leftIcon={<PersonIcon />}
                >
                  Download as Patient
                </PrimaryButton>
              </Box>
              <Box>
                <PrimaryButton
                  borderRadius="md"
                  width="200px"
                  onClick={() => controller.handleRedirectOnMobile('provider')}
                  isDisabled={controller.isLoading}
                  isLoading={controller.isLoading}
                  leftIcon={<ProviderIcon />}
                >
                  Download as Provider
                </PrimaryButton>
              </Box>
            </Box>
          </VStack>
          <Box py="3">
            <LinkButton fontWeight="bold" onClick={onContinueInBrowser}>
              or Continue in Browser
            </LinkButton>
          </Box>
        </VStack>
      )}
    </NotificationModal>
  );
};

export { MobileRedirect };
