import { useState } from 'react';
import { PHRAllergy } from '../../../../generated';
import { IPHRPatientEnhanced } from '../../../../store/patient/phr/phrReducers';
import { IAllergyForm } from '../components/AllergyForm/AllergyForm';
import { useAllergiesAPI } from './useAllergiesAPI';

export enum EPHRAllergiesViewState {
  List = 'List',
  View = ' View',
  Edit = 'Edit',
}

export const useAllergiesForm = (phr: IPHRPatientEnhanced) => {
  const {
    isApiLoading,
    isEpipenLoading,
    upsertAllergy,
    verifyAllergy,
    deleteAllergy,
    updateEpiPen,
  } = useAllergiesAPI(phr);

  const [currentView, setCurrentView] = useState<EPHRAllergiesViewState>(
    EPHRAllergiesViewState.List,
  );
  const [selectedAllergy, setSelectedAllergy] = useState<PHRAllergy | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [recordToDelete, setRecordToDelete] = useState<string>('');

  const onView = (allergy: PHRAllergy) => {
    setSelectedAllergy(allergy);
    setCurrentView(EPHRAllergiesViewState.View);
  };

  const onCreate = () => {
    setSelectedAllergy(null);
    setCurrentView(EPHRAllergiesViewState.Edit);
  };

  const onEdit = (allergy: PHRAllergy) => {
    setSelectedAllergy(allergy);
    setCurrentView(EPHRAllergiesViewState.Edit);
  };

  const onCancel = () => {
    setSelectedAllergy(null);
    setCurrentView(EPHRAllergiesViewState.List);
  };

  const onVerify = async (phrAllergyID: string) => {
    verifyAllergy(phrAllergyID);
  };

  const onSave = async (values: IAllergyForm) => {
    upsertAllergy(values, selectedAllergy);
    setSelectedAllergy(null);
    setCurrentView(EPHRAllergiesViewState.List);
  };

  const onDelete = (phrAllergyID: string) => {
    setShowDeleteModal(true);
    setRecordToDelete(phrAllergyID);
  };

  const onConfirmDelete = async () => {
    setShowDeleteModal(false);
    deleteAllergy(recordToDelete);
    setCurrentView(EPHRAllergiesViewState.List);
  };

  const onClickEpipen = (epiPen: boolean | undefined) => {
    updateEpiPen(epiPen);
  };

  return {
    isApiLoading,
    isEpipenLoading,
    currentView,
    setCurrentView,
    selectedAllergy,
    setSelectedAllergy,
    showDeleteModal,
    setShowDeleteModal,
    recordToDelete,
    setRecordToDelete,
    onView,
    onCreate,
    onEdit,
    onCancel,
    onVerify,
    onSave,
    onDelete,
    onConfirmDelete,
    onClickEpipen,
  };
};
