import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import Card from './Card';

const Box = styled(Card)`
  background-color: rgba(255, 72, 94, 0.2);
  color: ${(props) => props.theme.Colors.Danger};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  margin: 0.5rem;
  font-size: 14px;

  svg {
    margin-right: 1rem;
  }
`;

interface IProps {
  message?: string | null;
}

const ServerValidationBox = ({ message }: IProps) => {
  if (!message) {
    return null;
  }

  return (
    <Box>
      <FontAwesomeIcon icon={['far', 'exclamation-triangle']} size="lg" />
      <div dangerouslySetInnerHTML={{ __html: message }}></div>
    </Box>
  );
};

export default ServerValidationBox;
