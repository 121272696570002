import { Box, Button, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { boolean, date, object, string } from 'yup';

import { ButtonSizeEnum } from '../../../../constants';
import {
  AuthClient,
  FileDetail,
  PHRConsultsClient,
  PHRDocumentCategoryEnum,
  PHRDocumentsClient,
  PHRDocumentUploadDetails,
  PHRTrackerCovidDTO,
  Tag,
  TagTypeEnum,
} from '../../../../generated';
import {
  createForPhrSection,
  getAllForPhrSection,
  updateForPhrSection,
} from '../../../../store/root-creator';
import { AppState } from '../../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import { scrollToTop } from '../../../../utils/scrollToTop';

import { PrimaryButton, TertiaryButton } from '../../../../components/Buttons';
import {
  FormInputControl,
  FormMobisPicker,
  FormSelectComponent,
  FormTextArea,
  FormTimePicker,
} from '../../../../components/forms';
import { CenteredLoadingIndicator } from '../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../components/ServerValidationBox';
import { usePhrDocumentsByAssociatedID } from '../../../../services/phrDocuments/queries/usePhrDocumentsByAssociatedID';
import { useTagsAllByTagType } from '../../../../services/tags/queries/useTagsAllByTagType';
import { OutpostTheme } from '../../../../themes/outpost';
import { httpRequest } from '../../../../utils';
import { getFileDetail } from '../../../../utils/fileHelpers';
import { errorToastr, successToastr } from '../../../../utils/toastr';
import PhrItemForm from '../../components/PhrItemForm';

interface IForm {
  covidTestTypeTagID: string;
  covidTestBrandTypeTagID: string;
  testDate: Date;
  testTime: Date;
  result: boolean;
  testLocation: string;
  serialNumber: string;
  passportNumber: string;
  notes: string;
}

interface IProps {
  currentTest: PHRTrackerCovidDTO | null;
  patientID: string;
  toggleView: () => void;
  innerBtnRef: React.RefObject<HTMLButtonElement>;
  fetchCovidQRCodeFile?: () => void;
}

const CovidTestForm = ({
  currentTest,
  patientID,
  toggleView,
  innerBtnRef,
  fetchCovidQRCodeFile,
}: IProps) => {
  const { consult } = useSelector((state: AppState) => state.activeConsultState);
  const dispatch = useDispatch();
  const documentInputRef = useRef<HTMLInputElement>(null);
  const { error, isPhrLoading } = useSelector((state: AppState) => state.phrPatientState);
  const { tags: covidTestTypeTags = [] } = useTagsAllByTagType(TagTypeEnum.CovidTestType);
  const [existingDocDeleteCount, setExistingDocDeleteCount] = useState(0);
  const { phrDocuments: existingDocuments = [] } = usePhrDocumentsByAssociatedID(
    patientID,
    currentTest?.phrTrackerDetailID ?? '',
    existingDocDeleteCount,
  );
  const [selectedCovidTestTypeTagID, setSelectedCovidTestTypeTagID] = useState(
    currentTest?.covidTestTypeTagID,
  );
  const [brandTags, setBrandTags] = useState<Tag[]>([]);
  const [attachedFiles, setAttachedFiles] = useState<FileDetail[]>([]);
  const [showUploadBtn, setShowUploadBtn] = useState(true);

  useEffect(() => {
    const selectedBrandTags = covidTestTypeTags
      .filter((testType) => testType.tagID === selectedCovidTestTypeTagID)[0]
      ?.tagXrefs?.map((tagXRef) => tagXRef.toTag!)
      .sort((a, b) => a.name.localeCompare(b.name));
    setBrandTags(selectedBrandTags || []);
  }, [covidTestTypeTags, selectedCovidTestTypeTagID]);

  useEffect(() => {
    scrollToTop();
  }, []);

  const deleteExistingDocument = async (documentID: string) => {
    try {
      await new PHRDocumentsClient(new AuthClient()).pHRDocumentsDelete(patientID, documentID);
      setExistingDocDeleteCount((prev) => prev + 1);
    } catch (e) {
      errorToastr({ description: 'An error occured while deleting document' });
    } finally {
      successToastr({ description: 'Successfully deleted document' });
    }
  };

  const prepareDocument = (file: File) => {
    const reader = new FileReader();
    let fileDetail: FileDetail;

    reader.onloadend = async function () {
      fileDetail = getFileDetail(file.type, file.size, file.name, reader.result as ArrayBuffer);
      setAttachedFiles((prev) => [...prev, fileDetail]);
      setShowUploadBtn(false);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleFileUpload = async (associatedID: string, fileDetail: FileDetail) => {
    try {
      const documentUploadDetails = new PHRDocumentUploadDetails();
      documentUploadDetails.fileDetail = fileDetail;
      documentUploadDetails.patientID = patientID;
      documentUploadDetails.phrDocumentCategoryEnum = PHRDocumentCategoryEnum.Other;
      documentUploadDetails.associatedID = associatedID;

      await new PHRDocumentsClient(new AuthClient()).uploadPHRDocument(documentUploadDetails);
    } catch (e) {
      errorToastr({ description: `An error occured while uploading document ${fileDetail.name}` });
    }
  };

  const submit = async (values: IForm) => {
    const phrCovid = new PHRTrackerCovidDTO();
    phrCovid.init(values);

    phrCovid.patientID = patientID;
    phrCovid.covidTestBrandTypeTagID = values.covidTestBrandTypeTagID;
    phrCovid.covidTestTypeTagID = values.covidTestTypeTagID;
    phrCovid.testType = covidTestTypeTags.filter(
      (tag) => tag.tagID === values.covidTestTypeTagID,
    )[0]?.name;
    phrCovid.testResult = values.result;
    phrCovid.city = values.testLocation;
    phrCovid.serialNumber = values.serialNumber;
    phrCovid.passport = values.passportNumber;
    phrCovid.notes = values.notes;

    values.testDate.setTime(values.testTime.getTime());
    phrCovid.trackerDate = values.testDate;

    if (currentTest) {
      phrCovid.phrTrackerDetailID = currentTest.phrTrackerDetailID;
      await dispatch(
        updateForPhrSection(ReduxPHRCategoryEnum.PHRCovids, phrCovid, patientID, true),
      );

      toggleView();

      await Promise.all(
        attachedFiles.map((file) => handleFileUpload(currentTest.phrTrackerDetailID!, file)),
      );

      await dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRCovids, patientID));

      await fetchCovidQRCodeFile?.();
    } else {
      if (consult) {
        const phrConsult = await httpRequest(() =>
          new PHRConsultsClient(new AuthClient()).byConsultID(patientID, consult.consultID),
        );
        phrCovid.phrConsultID = phrConsult.phrConsultID;
      }

      const result = await dispatch(
        createForPhrSection(ReduxPHRCategoryEnum.PHRCovids, phrCovid, patientID, true),
      );
      const createdCovidTest = result as unknown as PHRTrackerCovidDTO;

      toggleView();

      await Promise.all(
        attachedFiles.map((file) => handleFileUpload(createdCovidTest.phrTrackerDetailID!, file)),
      );

      await dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRCovids, patientID));

      await fetchCovidQRCodeFile?.();
    }
  };

  const initialValues: IForm = {
    covidTestTypeTagID: currentTest?.covidTestTypeTagID || '',
    covidTestBrandTypeTagID: currentTest?.covidTestBrandTypeTagID || '',
    testDate: currentTest?.trackerDate || new Date(),
    testTime: currentTest?.trackerDate || new Date(),
    result: currentTest?.testResult || false,
    testLocation: currentTest?.city || '',
    serialNumber: currentTest?.serialNumber || '',
    passportNumber: currentTest?.passport || '',
    notes: currentTest?.notes || '',
  };

  return (
    <PhrItemForm>
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          covidTestTypeTagID: string().required('Covid test type is required'),
          covidTestBrandTypeTagID: string(),
          testDate: date().typeError('Invalid date').required('Test date is required'),
          result: boolean().required('Test result is required'),
          testLocation: string().required('Test location is required'),
          serialNumber: string().required('Serial number is required'),
          passportNumber: string(),
          notes: string().max(100, 'Name must be at most 100 characters'),
        })}
        onSubmit={submit}
      >
        {({ setFieldValue }) => (
          <Form>
            {isPhrLoading ? (
              <CenteredLoadingIndicator />
            ) : (
              <>
                <ServerValidationBox message={error} />
                <Grid gap={4} templateColumns={{ base: '1fr', md: '1fr 1fr' }} templateRows="auto">
                  <GridItem>
                    <FormSelectComponent
                      label="Test Type"
                      name="covidTestTypeTagID"
                      valueKey="tagID"
                      labelKey="name"
                      isClearable={false}
                      options={covidTestTypeTags}
                      defaultInputValue={
                        covidTestTypeTags.filter(
                          (tag) => tag.tagID === currentTest?.covidTestTypeTagID,
                        )[0]?.name
                      }
                      components={{
                        Placeholder: () => (
                          <Text
                            fontSize={OutpostTheme.FontSizes.body}
                            color="#0516466E"
                            paddingLeft="0.5rem"
                          >
                            Select Test Type
                          </Text>
                        ),
                        IndicatorSeparator: null,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: '48px',
                          borderRadius: '8px',
                          backgroundColor: '#F7F9FC',
                        }),
                      }}
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                      onChange={(selectedTestTypeTag: Tag) => {
                        setFieldValue('covidTestBrandTypeTagID', '');
                        setSelectedCovidTestTypeTagID(selectedTestTypeTag.tagID);
                      }}
                    />
                  </GridItem>

                  <GridItem>
                    <FormSelectComponent
                      label="Manufacturer"
                      name="covidTestBrandTypeTagID"
                      valueKey="tagID"
                      labelKey="name"
                      isClearable={false}
                      options={brandTags}
                      defaultInputValue={
                        brandTags.filter(
                          (tag) => tag.tagID === currentTest?.covidTestBrandTypeTagID,
                        )[0]?.name
                      }
                      components={{
                        Placeholder: () => (
                          <Text
                            fontSize={OutpostTheme.FontSizes.body}
                            color="#0516466E"
                            paddingLeft="0.5rem"
                          >
                            Select Test Type
                          </Text>
                        ),
                        IndicatorSeparator: null,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: '48px',
                          borderRadius: '8px',
                          backgroundColor: '#F7F9FC',
                        }),
                      }}
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </GridItem>

                  <GridItem>
                    <FormSelectComponent
                      label="Result"
                      name="result"
                      labelKey="name"
                      isClearable={false}
                      options={[
                        { name: 'Positive', value: true },
                        { name: 'Negative', value: false },
                      ]}
                      components={{
                        Placeholder: () => (
                          <Text
                            fontSize={OutpostTheme.FontSizes.body}
                            color="#0516466E"
                            paddingLeft="0.5rem"
                          >
                            Select Result
                          </Text>
                        ),
                        IndicatorSeparator: null,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: '48px',
                          borderRadius: '8px',
                          backgroundColor: '#F7F9FC',
                        }),
                      }}
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <FormInputControl
                      name="testLocation"
                      label="Test Location"
                      placeholder="Enter Location Of Test"
                      height="48px"
                      borderRadius="lg"
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <FormMobisPicker
                      name="testDate"
                      label="Test Date"
                      placeholder="DD/MM/YY"
                      borderRadius="lg"
                      containerProps={{
                        height: '48px',
                      }}
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                    {/* <FormDateTimePicker
                      name="testDate"
                      label="Test Date"
                      placeholderText="DD/MM/YY"
                      customInputProps={{
                        height: '48px',
                        borderRadius: 'lg',
                      }}
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    /> */}
                  </GridItem>
                  <GridItem>
                    <FormTimePicker
                      name="testTime"
                      label="Test Time"
                      placeholderText="HH:MM"
                      customInputProps={{
                        height: '48px',
                        borderRadius: 'lg',
                      }}
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <FormInputControl
                      name="serialNumber"
                      label="Serial Number"
                      placeholder="Test Serial Number"
                      height="48px"
                      borderRadius="lg"
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <FormInputControl
                      name="passportNumber"
                      label="Passport Number"
                      placeholder="Enter Passport Number"
                      height="48px"
                      borderRadius="lg"
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <Box flexShrink={1}>
                      <Text
                        marginBottom="0.5rem"
                        textTransform="uppercase"
                        color="#01143173"
                        fontSize="12px"
                        fontWeight="light"
                      >
                        Upload Test Result
                      </Text>
                      {existingDocuments.map((doc) => (
                        <Flex justify="space-between" marginBottom="0.5rem">
                          <Flex
                            bgColor="#3E11911C"
                            color="#3E1191"
                            borderRadius="lg"
                            fontSize={OutpostTheme.FontSizes.body}
                            height="48px"
                            flexGrow={1}
                            paddingX="1rem"
                            align="center"
                            justify="center"
                          >
                            <Text>{getDocumentDisplayName(doc.fileDetail?.name ?? '')}</Text>
                          </Flex>
                          <PrimaryButton
                            height="48px"
                            color="#FF3B46"
                            bgColor="#FFFFFF"
                            _hover={{
                              backgroundColor: '#FFFFFF',
                            }}
                            _active={{
                              backgroundColor: '#FFFFFF',
                            }}
                            onClick={() => deleteExistingDocument(doc.phrDocumentID)}
                          >
                            Delete
                          </PrimaryButton>
                        </Flex>
                      ))}
                      {attachedFiles.map((file, index) => (
                        <Flex justify="space-between" marginBottom="0.5rem">
                          <Flex
                            bgColor="#3E11911C"
                            color="#3E1191"
                            borderRadius="lg"
                            fontSize={OutpostTheme.FontSizes.body}
                            height="48px"
                            flexGrow={1}
                            paddingX="1rem"
                            align="center"
                            justify="center"
                          >
                            <Text>{getDocumentDisplayName(file.name)}</Text>
                          </Flex>
                          <PrimaryButton
                            height="48px"
                            color="#FF3B46"
                            bgColor="#FFFFFF"
                            _hover={{
                              backgroundColor: '#FFFFFF',
                            }}
                            _active={{
                              backgroundColor: '#FFFFFF',
                            }}
                            onClick={() => {
                              setAttachedFiles((prevFiles) =>
                                prevFiles.filter((file, idx) => idx !== index),
                              );

                              if (attachedFiles.length === 1) {
                                setShowUploadBtn(true);
                              }
                            }}
                          >
                            Delete
                          </PrimaryButton>
                        </Flex>
                      ))}

                      {showUploadBtn && (
                        <>
                          <input
                            ref={documentInputRef}
                            type="file"
                            id="frontDocumentName"
                            accept="image/png, image/jpeg, application/pdf"
                            style={{ display: 'none' }}
                            onChange={(event) => {
                              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                              prepareDocument(event?.currentTarget?.files?.[0]!);
                            }}
                          />

                          <PrimaryButton
                            bgColor="#3E11911C"
                            color="#707070"
                            _hover={{ bgColor: '#3E11911C' }}
                            _active={{ bgColor: '#3E11911C' }}
                            borderRadius="lg"
                            fontSize={OutpostTheme.FontSizes.body}
                            height="48px"
                            width="100%"
                            onClick={() => {
                              documentInputRef?.current?.click();
                            }}
                          >
                            Tap To Upload Document
                          </PrimaryButton>
                        </>
                      )}

                      {attachedFiles.length !== 0 && !showUploadBtn && (
                        <TertiaryButton
                          size={ButtonSizeEnum.medium}
                          fontWeight="normal"
                          color="#3E1191"
                          marginTop="0.5rem"
                          onClick={() => setShowUploadBtn(true)}
                        >
                          + Add Another File
                        </TertiaryButton>
                      )}
                    </Box>
                  </GridItem>
                  <GridItem colSpan={[1, 1, 2]}>
                    <FormTextArea
                      name="notes"
                      label="Notes"
                      borderRadius="lg"
                      height="120px"
                      resize="none"
                      placeholder="Type a short note here"
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </GridItem>
                </Grid>
                <Button display="none" ref={innerBtnRef} type="submit" />
              </>
            )}
          </Form>
        )}
      </Formik>
    </PhrItemForm>
  );
};

export default CovidTestForm;

const getDocumentDisplayName = (documentName: string) => {
  if (!documentName) return '';

  return documentName.length > 15 ? documentName.slice(0, 15) + '...' : documentName;
};
