import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Accordion, Box, Flex, useBreakpointValue } from '@chakra-ui/react';

import { FormStatusEnum, IConsult, Patient, ProviderSettingEnum } from '../../../../generated';

import { AppState } from '../../../../store/root-reducers';

import { providerHasSetting } from '../../../../utils/providerHasSetting';

import useProviderConsultContext from './hooks/useProviderConsultContext';

import { ConsultNotesAccordionItem } from './components/ConsultNotesAccordionItem';
import {
  CarePlan,
  Complaint,
  ComplaintInConsult,
  Diagnosis,
  IntakeForms,
  Objective,
  Preceptor,
  Resident,
  Subjective,
} from './components/ConsultNotesItems';
import { NurseNotes } from './components/ConsultNotesItems/NurseNotes';

export interface IntakeForm {
  formID: string;
  formName?: string;
  formShortName?: string;
  formStatusEnum?: FormStatusEnum;
  verified?: boolean;
  patient?: Patient;
  updatedDate?: Date;
}

interface IProps {
  consult?: IConsult;
  isNurse?: boolean;
}

const ConsultNotesComponent = ({ consult, isNurse = false }: IProps) => {
  const { phrConsult, isInConsult, phrForms, openIndexes, setOpenIndexes } =
    useProviderConsultContext();

  const [intakeForms, setIntakeForms] = useState<IntakeForm[]>();
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const provider = useSelector((state: AppState) => state.providerState.provider);

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const loadIntakeForms = () => {
      const intakeForms: IntakeForm[] = [];
      const [{ storeItem }] = consult?.consultStoreItems || [{}];

      if (storeItem) {
        storeItem?.storeItemForms?.forEach((x) => {
          const phrForm = phrForms.find((y) => y.formID === x.formID);
          intakeForms.push({
            formID: x.formID,
            formName: x?.formName,
            formShortName: x?.formShortName,
            formStatusEnum: phrForm?.formStatusEnum,
            verified: phrForm?.verified,
            patient: consult?.patient,
            updatedDate: phrForm?.updatedDate,
          });
        });
        setIntakeForms(intakeForms);
      }
    };

    loadIntakeForms();
  }, [consult, phrForms]);

  return (
    <Accordion
      height="100%"
      allowToggle
      allowMultiple
      index={openIndexes}
      onChange={setOpenIndexes}
    >
      <Flex height="100%">
        <Box
          overflowY="auto"
          width="100%"
          id="container"
          backgroundColor="white"
          p={isInConsult ? 6 : 0}
          pr={isInConsult ? '6' : '3'}
          pt={isMobile ? '10' : '5'}
        >
          {isInConsult &&
            (isMobile ? (
              <ConsultNotesAccordionItem title="Complaint">
                <ComplaintInConsult />
                {intakeForms && <IntakeForms intakeForms={intakeForms} />}
              </ConsultNotesAccordionItem>
            ) : (
              <>
                <ComplaintInConsult />
                {intakeForms && <IntakeForms intakeForms={intakeForms} />}
              </>
            ))}

          {!isInConsult &&
            (isMobile ? (
              <ConsultNotesAccordionItem title="Complaint">
                <Complaint />
                {intakeForms && <IntakeForms intakeForms={intakeForms} />}
              </ConsultNotesAccordionItem>
            ) : (
              <>
                <Complaint />
                {intakeForms && <IntakeForms intakeForms={intakeForms} />}
              </>
            ))}

          {isMobile ? (
            isNurse ? (
              <ConsultNotesAccordionItem title="Nurse Notes">
                <NurseNotes />
              </ConsultNotesAccordionItem>
            ) : (
              <>
                <ConsultNotesAccordionItem title="Subjective">
                  <Subjective />
                </ConsultNotesAccordionItem>
                <ConsultNotesAccordionItem title="Objective">
                  <Objective />
                </ConsultNotesAccordionItem>
                <ConsultNotesAccordionItem title="Diagnosis">
                  <Diagnosis />
                </ConsultNotesAccordionItem>
                <ConsultNotesAccordionItem title="Care Plan">
                  <CarePlan />
                </ConsultNotesAccordionItem>
              </>
            )
          ) : isNurse ? (
            <NurseNotes />
          ) : (
            <>
              <Subjective />
              <Objective />
              <Diagnosis />
              <CarePlan />
            </>
          )}

          {providerHasSetting(currentUser, provider, ProviderSettingEnum.ResidentProvider) &&
            (isMobile ? (
              <ConsultNotesAccordionItem title="Resident">
                <Resident />
              </ConsultNotesAccordionItem>
            ) : (
              <Resident />
            ))}

          {providerHasSetting(currentUser, provider, ProviderSettingEnum.PreceptorProvider) &&
            phrConsult?.attestationUserDetailID === provider?.userDetailID &&
            !isInConsult &&
            (isMobile ? (
              <ConsultNotesAccordionItem title="Preceptor">
                <Preceptor />
              </ConsultNotesAccordionItem>
            ) : (
              <Preceptor />
            ))}

          {/* Keeps form above "Complete" button. */}
          <Box h="30px" />
        </Box>
      </Flex>
    </Accordion>
  );
};

export default ConsultNotesComponent;
