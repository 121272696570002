import { isInNetwork } from '.';
import { AddressBook } from '../../../generated';
import { EMPTY_GUID } from '../../../constants';

export const isValidAddressBookID = (addressBook: AddressBook) =>
  !!(addressBook.addressBookID && addressBook.addressBookID !== EMPTY_GUID);

export const isValidUserDetailID = (addressBook: AddressBook) =>
  !!(addressBook.userDetailID && addressBook.userDetailID !== EMPTY_GUID);

export const isValidAddress = (addressBook: AddressBook) =>
  !!(isInNetwork(addressBook) || addressBook.faxPhoneNumber);
