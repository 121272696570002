import { Box, ComponentWithAs, IconProps, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { SecondaryButton } from '../../../../../../../components/Buttons';
import useTranslationComponent from '../../../../../../../hooks/useTranslationComponent';
import { OutpostTheme } from '../../../../../../../themes/outpost';
import { PrimaryButton } from '../../common/Buttons';

type ProviderVisitCardProps = {
  onSelectVisitType: () => void;
  title: string;
  description: string;
  icon: ComponentWithAs<'svg', IconProps>;
  iconBgColor?: string;
};

const ProviderVisitCard: FC<ProviderVisitCardProps> = (props) => {
  const { t } = useTranslationComponent(['heal']);

  return (
    <Box
      borderRadius="xl"
      bgColor="transparent"
      width="100%"
      height="100%"
      role="button"
      px="5"
      py="8"
      mx="auto"
      onClick={props.onSelectVisitType}
      display="flex"
      flexDirection="column"
    >
      <Box
        p={props.iconBgColor ? '3' : '0'}
        bgColor={props.iconBgColor ?? 'transparent'}
        maxW="max-content"
        borderRadius="md"
        mb="5"
      >
        <props.icon fontSize="63px" />
      </Box>
      <Box maxW="30ch" mb="6">
        <Text fontWeight="semibold" mb="2" color="rgb(0, 3, 16)">
          {props.title}
        </Text>
        <Text fontSize={OutpostTheme.FontSizes.body} color="#9D9D9D">
          {props.description}
        </Text>
      </Box>
      <Box mt="auto">
        <PrimaryButton minW="100%" type="button">
          {t('Book Visit')}
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export { ProviderVisitCard };
