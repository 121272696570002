import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Wrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 1px;
  color: ${(props) => props.theme.FontColors.Primary};
`;

interface IProps {
  isOpen: boolean;
  className?: string;
}

const ToggleIcon = ({ isOpen, className }: IProps) => (
  <Wrapper className={className}>
    <ChevronIcon open={isOpen} icon={['far', 'chevron-up']} size="lg" />
  </Wrapper>
);

export default ToggleIcon;

interface IChevronProps {
  readonly open: boolean;
}

const ChevronIcon = styled(FontAwesomeIcon)<IChevronProps>`
  transition: all 0.3s ease-in-out;

  ${(props) => !props.open && `transform: rotate(180deg);`}
`;
