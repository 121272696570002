import { createSelector } from 'reselect';
import { OrderStatusEnum } from '../../../../generated';
import { sortedOrderSelector } from './sortedOrderSelector';

export const pendingOrdersSelector = createSelector(sortedOrderSelector, (orders) =>
  orders.filter(
    (x) =>
      x.orderStatusEnum === OrderStatusEnum.Pending ||
      x.orderStatusEnum === OrderStatusEnum.Processing,
  ),
);
