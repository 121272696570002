import * as React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import AppFeatureComponent from '../components/AppFeatureComponent';
import asyncComponent from '../components/AsyncComponent/AsyncComponent';
import { CookieConsentBanner } from '../components/CookieConsent';
import FeatureAccessComponent from '../components/FeatureAccessComponent';
import {
  AppFeatures,
  CampaignRoutes,
  ConsultRoutes,
  DashboardRoutes,
  DefaultRoutes,
  DelegateRoutes,
  ExternalRoutes,
  GlobalStyle,
  HealRoutes,
  LearnRoutes,
  LocumsRoutes,
  MerchantProvider,
  MessageCenterRoutes,
  MonitorRoutes,
  MyNetworkRoutes,
  ProfileRoutes,
  ProviderOnboardingRoutes,
  PublicCommunityRoutes,
  PublicSearchRoutes,
  SignInRoutes,
  StoreManagerRoutes,
  SupportCenterRoutes,
  WalletRoutes,
} from '../constants';
import { RolesEnum } from '../generated';
import useGoogleTagManager from '../hooks/useGoogleTagManager';
import { mixInitialize } from '../lib/mixpanel';
import { useGetCurrentCampaign } from '../services/campaigns';
import { useGetCurrentPartner } from '../services/partners';
import { usePartnerSettings } from '../services/partners/usePartnerSettings';
import { getAppFeatures } from '../store/appFeatures/appFeaturesCreators';
import { AppState } from '../store/root-reducers';
import { isInRole } from '../utils/isInRole';
import AuthenticatedRoute from './AuthenticatedRoute';
import GlobalErrorBoundary from './GlobalErrorBoundary';
import AuthenticatedLayout from './layouts/AuthenticatedLayout';
import UnauthenticatedLayout from './layouts/UnauthenticatedLayout';
import StoreManagerView from './merchant/StoreManager';
import LoginView from './Unauthenticated/Login';
import SignOutView from './Unauthenticated/SignOut';
import { pageview } from '../hooks/useGoogleAnalytics';

const AsyncMerchantDashboard = asyncComponent(
  () => import('./Dashboard/components/MerchantDashboard'),
);
const AsyncStoreManagerDashboard = asyncComponent(
  () => import('./Dashboard/components/StoreManagerDashboard'),
);
const AsyncHomeCareAdminDashboard = asyncComponent(
  () => import('./Dashboard/components/HomeCareAdminDashboard'),
);
const AsyncMyNetworkComponent = asyncComponent(() => import('./MyNetwork'));
const AsyncCareSpacesView = asyncComponent(() => import('./careSpaces/CareSpaces'));
const AsyncModuleView = asyncComponent(() => import('./careSpaces/modules/Module'));
const AsyncModuleResourceView = asyncComponent(
  () => import('./careSpaces/modules/ModuleResources'),
);
const AsyncDashboardView = asyncComponent(() => import('./Dashboard'));
const AsyncSubMenuDashboardView = asyncComponent(
  () => import('./Dashboard/components/SubMenuDashboard'),
);
const AsyncSubMenuHealthRecordsFullView = asyncComponent(
  () => import('./Dashboard/components/SubMenuDashboard/HealthRecordsFullView'),
);

const AsyncBlogPostView = asyncComponent(() => import('./Dashboard/components/BlogPost/BlogPost'));
const AsyncAcceptInvite = asyncComponent(
  () => import('./heal/Consult/AdditionalPatient/AcceptInvite'),
);
const AsyncConsultView = asyncComponent(() => import('./heal/Consult'));
const AsyncQueueView = asyncComponent(
  () => import('./heal/patient/VirtualClinic/Queue/QueueContainer'),
);
const AsyncProviderAppointmentsView = asyncComponent(
  () => import('./heal/provider/Calendar/Appointments/Appointsments'),
);
const AsyncReceptionistDeskView = asyncComponent(
  () => import('./heal/receptionist/Desk/ReceptionistDesk'),
);
const AsyncProviderClinicView = asyncComponent(
  () => import('./heal/provider/Clinic/ProviderClinic'),
);
const AsyncWaitingRoomView = asyncComponent(() => import('./heal/provider/Clinic/WaitingRoom'));
const AsyncMyPatientsComponent = asyncComponent(
  () => import('./heal/provider/MyPatients/MyPatients'),
);
const AsyncIncompleteConsults = asyncComponent(
  () => import('./heal/provider/Clinic/IncompleteConsults/IncompleteConsults'),
);

const AsyncPreceptorConsults = asyncComponent(
  () => import('./heal/provider/Clinic/ConsultAttestations/ConsultAttestations'),
);
// const AsyncProviderScheduleView = asyncComponent(() => import('./heal/provider/Schedule/Schedule'));
const AsyncProviderScheduleView = asyncComponent(() => import('./heal/provider/Calendar/Calendar'));
const AsyncCommunityView = asyncComponent(() => import('./learn/Community/Community'));
const AsyncCreateDiscussionView = asyncComponent(
  () => import('./learn/Community/CreateDiscussion'),
);
const AsyncDiscussionView = asyncComponent(() => import('./learn/Community/Discussion'));
const AsyncDiscussionItemView = asyncComponent(() => import('./learn/Community/DiscussionItem'));
const AsyncDiscussionSearch = asyncComponent(() => import('./learn/Community/DiscussionSearch'));
const AsyncMailView = asyncComponent(() => import('./Mail/Mail'));
const AsyncClinicalTrialView = asyncComponent(
  () => import('./monitor/ClinicalTrials/ClinicalTrial'),
);
const AsyncClinicalTrialsSearchView = asyncComponent(
  () => import('./monitor/ClinicalTrials/ClinicalTrialsSearch'),
);
const AsyncClinicalTrialParticipate = asyncComponent(
  () => import('./monitor/ClinicalTrials/Participate'),
);
const AsyncProviderAddressBookView = asyncComponent(
  () => import('./monitor/AddressBook/AddressBook'),
);
const AsyncPatientProfileView = asyncComponent(
  () => import('./myProfile/PatientProfile/MyProfile'),
);
const AsyncProviderProfileView = asyncComponent(
  () => import('./myProfile/ProviderProfile/MyProfile'),
);
const AsyncShoppingCartView = asyncComponent(
  () => import('./myProfile/PatientProfile/ShoppingCart/ShoppingCartView'),
);
const AsyncSubscriptionDetailsView = asyncComponent(
  () => import('./myProfile/Subscriptions/components/SubscriptionDetails/SubscriptionDetails'),
);
const AsyncWalletView = asyncComponent(() => import('./myProfile/Wallet/Wallet'));
const AsyncBeneficiaries = asyncComponent(
  () => import('./myProfile/Wallet/Beneficiaries/Beneficiaries'),
);

const AsyncWalletTransactions = asyncComponent(
  () => import('./myProfile/Wallet/WalletTransactions/WalletTransactions'),
);

const AsyncPersonalHealthRecordView = asyncComponent(() => import('./phr/PersonalHealthRecord'));
const AsyncCareTeamView = asyncComponent(() => import('./heal/patient/CareTeam/CareTeamComponent'));
const AsyncStatus403 = asyncComponent(() => import('./Status403'));
const AsyncStatus404 = asyncComponent(() => import('./Status404'));
const AsyncSubscriptionDashboard = asyncComponent(
  () => import('./subscriptions/SubscriptionDashboard'),
);
const AsyncSupportView = asyncComponent(() => import('./support/Support'));
const AsyncProviderSignUp = asyncComponent(() => import('./onboarding/ProviderSignUp'));
const AsyncResetPassword = asyncComponent(() => import('./Unauthenticated/ResetPassword'));
const AsyncSignUpType = asyncComponent(() => import('./Unauthenticated/SignUpType'));
const AsyncPatientSignUp = asyncComponent(() => import('./Unauthenticated/SignUpTypeEmail'));
const AsyncSignUpTypeHealthCard = asyncComponent(
  () => import('./Unauthenticated/SignUpTypeHealthCard'),
);
const AsyncSignUpTypeNotInitializedPatient = asyncComponent(
  () => import('./Unauthenticated/SignUpTypeNotInitializedPatient'),
);

const AsyncProviderOnboardingSubmitDocuments = asyncComponent(
  () => import('./onboarding/SubmitDocuments'),
);

const AsyncCampaign = asyncComponent(() => import('./Campaign'));
const AsyncHealthJournal = asyncComponent(() => import('./monitor/HealthJournal'));
const AsyncVitals = asyncComponent(() => import('./phr/vitals/'));
const AsyncMoodTrackers = asyncComponent(() => import('./phr/trackers/moodTrackers'));

const AsyncBookingComponent = asyncComponent(() => import('./heal/patient/BookAppointment'));

const AsyncFaxComponent = asyncComponent(() => import('./heal/receptionist/Fax/Fax'));

const AsyncMerchantTasksComponent = asyncComponent(() => import('./merchant/Tasks'));
const AsyncMerchantStatementsComponent = asyncComponent(() => import('./merchant/Statements'));
const AsyncMerchantTransactionHistory = asyncComponent(
  () => import('./merchant/Statements/TransactionHistory'),
);
const AsyncMerchantPayoutSettings = asyncComponent(
  () => import('./merchant/Statements/PayoutSettings'),
);
const AsyncMerchantDownloadStatements = asyncComponent(
  () => import('./merchant/Statements/DownloadStatements'),
);

const AsyncDelegateRedirect = asyncComponent(() => import('./DelegateRedirect'));
const AsyncSsoRedirect = asyncComponent(() => import('./SsoRedirect'));

const AsyncReferralHub = asyncComponent(() => import('./referralHub'));
const AsyncStatements = asyncComponent(() => import('./provider/Statements'));
const AsyncPayoutSettings = asyncComponent(() => import('./provider/Statements/PayoutSettings'));
const AsyncDownloadStatements = asyncComponent(
  () => import('./provider/Statements/DownloadStatements'),
);
const AsyncTransactionHistory = asyncComponent(
  () => import('./provider/Statements/TransactionHistory'),
);

const AsyncClinicSettings = asyncComponent(() => import('./heal/provider/Clinic/Settings'));
const AsyncGiveTheGift = asyncComponent(() => import('./Campaign/GiveTheGift'));
const AsyncLocums = asyncComponent(() => import('./Locums/LocumsView'));

const AsyncPublicSearch = asyncComponent(() => import('./Unauthenticated/PublicSearch'));
// const AsyncProviderDetails = asyncComponent(() => import('./Unauthenticated/ProviderDetails'));
const AsyncProviderPage = asyncComponent(() => import('./Unauthenticated/PublicPage'));

const AsyncPublicLocums = asyncComponent(() => import('./Unauthenticated/PublicLocumsPage'));

const AsyncClinicalCart = asyncComponent(
  () => import('./myProfile/PatientProfile/ClinicalCart/ClinicalCart'),
);

const AsyncClinicalOrders = asyncComponent(
  () => import('./myProfile/PatientProfile/ClinicalOrders/ClinicalOrders'),
);

const AsyncSelfLabOrder = asyncComponent(
  () => import('./myProfile/PatientProfile/SelfOrders/SelfLabOrder'),
);

const AsyncPaymentLinkView = asyncComponent(() => import('./paymentLink/PaymentLink/PaymentLink'));

const AsyncPublicCommunity = asyncComponent(() => import('./Unauthenticated/PublicCommunity'));
const AsyncPublicCommunityDiscussion = asyncComponent(
  () => import('./Unauthenticated/PublicCommunityDiscussion'),
);

const RootView = () => {
  const dispatch = useDispatch();
  const [loadedAppFeatures, setLoadedAppFeatures] = useState<boolean>(false);

  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const appFeatures = useSelector((state: AppState) => state.appFeaturesState.appFeatures);

  useGetCurrentPartner();
  useGetCurrentCampaign();

  const { i18n } = useTranslation();

  const [initialLoad, setInitialLoad] = React.useState<boolean>(false);
  const patient = useSelector((state: AppState) => state.patientState.patient);
  const { provider } = useSelector((state: AppState) => state.providerState);
  const location = useLocation();

  const { partnerFavicon } = usePartnerSettings();

  const faviconIconUrl = partnerFavicon ?? '/outpost/icons/favicon-32x32.png';
  const faviconAppleIcon = partnerFavicon ?? '/outpost/icons/icon-152x152.png';

  const mixPanelFeature = appFeatures.find((feature) => feature.name === AppFeatures.Mixpanel);

  const mixPanelToken = mixPanelFeature?.description;

  const authenticatedLayoutPaths: string[] = [
    ...Object.values(DashboardRoutes),
    ...Object.values(ProviderOnboardingRoutes),
    ...Object.values(ProfileRoutes),
    ...Object.values(LearnRoutes),
    ...Object.values(HealRoutes),
    ...Object.values(MessageCenterRoutes),
    ...Object.values(MonitorRoutes),
    ...Object.values(WalletRoutes),
    ...Object.values(DefaultRoutes),
    ...Object.values(CampaignRoutes),
    ...Object.values(MerchantProvider),
    ...Object.values(SupportCenterRoutes),
    ...Object.values(MyNetworkRoutes),
    ...Object.values(StoreManagerRoutes),
    ...Object.values(LocumsRoutes),
    ...Object.values(ExternalRoutes),
  ];

  const unauthenticatedLayoutPaths: string[] = [
    ...Object.values(SignInRoutes),
    ...Object.values(PublicSearchRoutes),
    ...Object.values(PublicCommunityRoutes),
    // HealRoutes.HealthRecordsFull,
  ];

  useEffect(() => {
    if (!loadedAppFeatures) {
      dispatch(getAppFeatures());
      setLoadedAppFeatures(true);
    }
  }, [dispatch, loadedAppFeatures]);

  useEffect(() => {
    if (!initialLoad && i18n.isInitialized) {
      pageview(location.pathname);
      setInitialLoad(true);
    }
  }, [i18n, initialLoad, location.pathname]);

  useEffect(() => {
    if (patient || provider) {
      const language =
        (typeof Window !== 'undefined' ? localStorage.getItem('i18nextLng') : '') ||
        patient?.primaryLanguageID?.split('-')[0] ||
        provider?.primaryLanguageID?.split('-')[0] ||
        '';
      if (language) {
        i18n.changeLanguage(language);
      }
    }
  }, [i18n, patient, provider]);

  useGoogleTagManager();

  // Initialize mix panel sdk with the correct access token
  useEffect(() => {
    mixInitialize(mixPanelToken);
  }, [mixPanelToken]);

  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" sizes="32x32" href={faviconIconUrl} />
        <link rel="apple-touch-icon" href={faviconAppleIcon} />
      </Helmet>
      <GlobalStyle />
      <CookieConsentBanner />
      <GlobalErrorBoundary>
        <Switch>
          <Route exact path={DelegateRoutes.DelegateRedirect} component={AsyncDelegateRedirect} />
          <Route exact path={DelegateRoutes.Redirect} component={AsyncSsoRedirect} />
          <Route path={HealRoutes.HealthRecordsFull} exact>
            <AuthenticatedLayout hideNav>
              <AuthenticatedRoute
                // roles={[RolesEnum.Patient, RolesEnum.Provider]}
                path={HealRoutes.HealthRecordsFull}
                exact
                component={AsyncSubMenuHealthRecordsFullView}
              />
            </AuthenticatedLayout>
          </Route>
          <Route exact path={unauthenticatedLayoutPaths}>
            <Switch>
              {/* <Route
                path={PublicSearchRoutes.SearchByLocation}
                exact
                component={AsyncPublicSearchByLocation}
              /> */}
              <Route
                exact
                path={PublicCommunityRoutes.Community}
                component={AsyncPublicCommunity}
              />
              <Route
                exact
                path={PublicCommunityRoutes.CommunityDiscussion}
                component={AsyncPublicCommunityDiscussion}
              />
              <Redirect
                exact
                from={PublicSearchRoutes.PublicPageBooking}
                to={PublicSearchRoutes.PublicPage}
              />
              <Route path={PublicSearchRoutes.PublicPage} exact component={AsyncProviderPage} />
              <Route
                path={PublicSearchRoutes.ProviderDetails}
                exact
                // component={AsyncProviderDetails}
                component={AsyncPublicSearch}
              />
              <Route path={PublicSearchRoutes.Locums} exact component={AsyncPublicLocums} />
              <Route path={PublicSearchRoutes.Search} exact component={AsyncPublicSearch} />
              <Route path={SignInRoutes.ProviderSignUp} exact component={AsyncProviderSignUp} />
              <Route>
                <UnauthenticatedLayout>
                  <Route path={SignInRoutes.SignIn} exact component={LoginView} />
                  <Route path={SignInRoutes.SignUpType} exact component={AsyncSignUpType} />
                  <Route path={SignInRoutes.SignUpTypeEmail} exact component={AsyncPatientSignUp} />
                  <Route
                    path={SignInRoutes.SignUpTypeNotInitializedPatient}
                    exact
                    component={AsyncSignUpTypeNotInitializedPatient}
                  />
                  <Route
                    path={SignInRoutes.SignUpTypeHealthCard}
                    exact
                    component={AsyncSignUpTypeHealthCard}
                  />
                  <Route path={SignInRoutes.ResetPassword} exact component={AsyncResetPassword} />
                  <Route path={SignInRoutes.SignOut} exact component={SignOutView} />
                </UnauthenticatedLayout>
              </Route>
            </Switch>
          </Route>
          <Route exact path={authenticatedLayoutPaths}>
            {/* <AuthenticatedLayout hideNav>
              <AuthenticatedRoute
                roles={[RolesEnum.Patient, RolesEnum.Provider]}
                path={HealRoutes.HealthRecordsFull}
                exact
                component={AsyncSubMenuHealthRecordsFullView}
              />
            </AuthenticatedLayout> */}

            {isInRole(currentUser, [
              RolesEnum.MerchantProvider,
              RolesEnum.StoreManager,
              RolesEnum.HomeCareAdministrator,
            ]) ? (
              <AuthenticatedLayout>
                {isInRole(currentUser, RolesEnum.MerchantProvider) && (
                  <AuthenticatedRoute
                    exact
                    path={DashboardRoutes.Dashboard}
                    component={AsyncMerchantDashboard}
                  />
                )}
                {isInRole(currentUser, RolesEnum.StoreManager) && (
                  <AuthenticatedRoute
                    exact
                    path={DashboardRoutes.Dashboard}
                    component={AsyncStoreManagerDashboard}
                  />
                )}
                {isInRole(currentUser, RolesEnum.HomeCareAdministrator) && (
                  <AuthenticatedRoute
                    exact
                    path={DashboardRoutes.Dashboard}
                    component={AsyncHomeCareAdminDashboard}
                  />
                )}
                <AuthenticatedRoute
                  exact
                  path={MerchantProvider.Tasks}
                  component={AsyncMerchantTasksComponent}
                  roles={[RolesEnum.MerchantProvider]}
                />
                <AuthenticatedRoute
                  path={MerchantProvider.StoreManager}
                  component={StoreManagerView}
                  roles={[RolesEnum.MerchantProvider]}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.MerchantProvider]}
                  path={HealRoutes.ClinicalCart}
                  component={AsyncClinicalCart}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.MerchantProvider]}
                  path={HealRoutes.ClinicalOrder}
                  component={AsyncClinicalOrders}
                />
                <AuthenticatedRoute
                  exact
                  path={StoreManagerRoutes.Schedule}
                  component={AsyncProviderScheduleView}
                  roles={[RolesEnum.StoreManager]}
                />
                <AuthenticatedRoute
                  exact
                  path={MonitorRoutes.Statements}
                  component={AsyncStatements}
                  roles={[RolesEnum.StoreManager]}
                />
                <AuthenticatedRoute
                  path={DashboardRoutes.Submenu}
                  exact
                  component={AsyncSubMenuDashboardView}
                />
                <AuthenticatedRoute
                  exact
                  path={MyNetworkRoutes.HomePage}
                  roles={[RolesEnum.Provider]}
                  component={AsyncMyNetworkComponent}
                />
                <AuthenticatedRoute
                  exact
                  path={LocumsRoutes.Home}
                  roles={[RolesEnum.Provider]}
                  component={AsyncLocums}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient, RolesEnum.Provider]}
                  path={MessageCenterRoutes.Mail}
                  component={AsyncMailView}
                />
                <AuthenticatedRoute
                  path={ProfileRoutes.ProviderProfile}
                  component={AsyncProviderProfileView}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  exact
                  path={[MerchantProvider.Statements]}
                  component={AsyncMerchantStatementsComponent}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  exact
                  path={[MerchantProvider.PayoutSettings]}
                  component={AsyncMerchantPayoutSettings}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  exact
                  path={[MerchantProvider.DownloadStatements]}
                  component={AsyncMerchantDownloadStatements}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  exact
                  path={[MerchantProvider.TransactionHistory]}
                  component={AsyncMerchantTransactionHistory}
                />
                <AuthenticatedRoute
                  path={SupportCenterRoutes.Support}
                  exact
                  component={AsyncSupportView}
                />
              </AuthenticatedLayout>
            ) : (
              <AuthenticatedLayout>
                <FeatureAccessComponent roles={RolesEnum.CommunityForumRight}>
                  <AuthenticatedRoute
                    roles={[RolesEnum.Patient, RolesEnum.Provider, RolesEnum.NotApprovedProvider]}
                    exact
                    path={LearnRoutes.Community}
                    component={AsyncCommunityView}
                  />
                  <AuthenticatedRoute
                    exact
                    path={MyNetworkRoutes.HomePage}
                    roles={[RolesEnum.Provider]}
                    component={AsyncMyNetworkComponent}
                  />
                  <AuthenticatedRoute
                    exact
                    path={LocumsRoutes.Home}
                    roles={[RolesEnum.Provider]}
                    component={AsyncLocums}
                  />
                  <AuthenticatedRoute
                    roles={[RolesEnum.Patient, RolesEnum.Provider, RolesEnum.NotApprovedProvider]}
                    exact
                    path={LearnRoutes.CommunitySearchResults}
                    component={AsyncDiscussionSearch}
                  />
                  <AuthenticatedRoute
                    roles={[RolesEnum.Patient, RolesEnum.Provider, RolesEnum.NotApprovedProvider]}
                    exact
                    path={LearnRoutes.CommunityDiscussion}
                    component={AsyncDiscussionView}
                  />
                  <AuthenticatedRoute
                    roles={[RolesEnum.Patient, RolesEnum.Provider, RolesEnum.NotApprovedProvider]}
                    exact
                    path={LearnRoutes.CommunityDiscussionCreate}
                    component={AsyncCreateDiscussionView}
                  />
                  <AuthenticatedRoute
                    roles={[RolesEnum.Patient, RolesEnum.Provider, RolesEnum.NotApprovedProvider]}
                    exact
                    path={LearnRoutes.CommunityDiscussionItem}
                    component={AsyncDiscussionItemView}
                  />
                </FeatureAccessComponent>
                <AppFeatureComponent featureName={AppFeatures.ClinicalTrials}>
                  <FeatureAccessComponent roles={RolesEnum.ClinicalTrialRight}>
                    <AuthenticatedRoute
                      roles={[RolesEnum.Patient, RolesEnum.Provider]}
                      exact
                      path={MonitorRoutes.ClinicalTrials}
                      component={AsyncClinicalTrialsSearchView}
                    />
                    <AuthenticatedRoute
                      roles={[RolesEnum.Patient, RolesEnum.Provider]}
                      exact
                      path={MonitorRoutes.ClinicalTrial}
                      component={AsyncClinicalTrialView}
                    />
                    <AuthenticatedRoute
                      roles={[RolesEnum.Patient, RolesEnum.Provider]}
                      exact
                      path={MonitorRoutes.ParticipateClinicalTrial}
                      component={AsyncClinicalTrialParticipate}
                    />
                  </FeatureAccessComponent>
                </AppFeatureComponent>
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  exact
                  path={HealRoutes.SelfLabOrder}
                  component={AsyncSelfLabOrder}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  exact
                  path={MonitorRoutes.AddressBook}
                  component={AsyncProviderAddressBookView}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  notInRoles={[RolesEnum.HealthcareNavigator]}
                  path={HealRoutes.Clinic}
                  exact
                  component={AsyncProviderClinicView}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  path={HealRoutes.Incomplete}
                  exact
                  component={AsyncIncompleteConsults}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  path={HealRoutes.PreceptorConsults}
                  exact
                  component={AsyncPreceptorConsults}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  exact
                  path={HealRoutes.WaitingRoom}
                  component={AsyncWaitingRoomView}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider, RolesEnum.Receptionist]}
                  path={HealRoutes.ReceptionistDesk}
                  component={AsyncReceptionistDeskView}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  path={HealRoutes.MyClinicSettings}
                  component={AsyncClinicSettings}
                />
                {/* <AuthenticatedRoute
                roles={[RolesEnum.Patient]}
                notInRoles={[RolesEnum.HealthcareNavigator]}
                exact
                path={HealRoutes.VirtualClinic}
                component={AsyncVirtualClinicView}
              /> */}
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  notInRoles={[RolesEnum.HealthcareNavigator]}
                  exact
                  path={HealRoutes.ClinicQueue}
                  component={AsyncQueueView}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  notInRoles={[RolesEnum.HealthcareNavigator]}
                  exact
                  path={HealRoutes.ProviderSchedule}
                  component={AsyncProviderScheduleView}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  notInRoles={[RolesEnum.HealthcareNavigator]}
                  exact
                  path={HealRoutes.ProviderAppointments}
                  component={AsyncProviderAppointmentsView}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  notInRoles={[RolesEnum.HealthcareNavigator]}
                  exact
                  path={HealRoutes.MyPatients}
                  component={AsyncMyPatientsComponent}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  path={ProfileRoutes.PatientProfile}
                  component={AsyncPatientProfileView}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  path={ProfileRoutes.ProviderProfile}
                  component={AsyncProviderProfileView}
                />
                {/* <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  path={ProfileRoutes.Dependants}
                  component={AsyncDependantsView}
                /> */}
                {/* <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  path={ProfileRoutes.PurchaseHistory}
                  component={AsyncPurchaseHistoryView}
                /> */}
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  path={HealRoutes.ShoppingCart}
                  component={AsyncShoppingCartView}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  path={HealRoutes.ClinicalCart}
                  component={AsyncClinicalCart}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  path={HealRoutes.ClinicalOrder}
                  component={AsyncClinicalOrders}
                />
                {/* <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  path={[ProfileRoutes.Subscriptions]}
                  exact
                  component={AsyncSubscriptionsView}
                /> */}
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  path={[ProfileRoutes.SubscriptionDetails]}
                  exact
                  component={AsyncSubscriptionDetailsView}
                />
                {/* <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  path={ProfileRoutes.Preferences}
                  component={AsyncPreferenceView}
                /> */}
                <AuthenticatedRoute
                  exact
                  roles={[RolesEnum.Patient]}
                  path={WalletRoutes.Wallet}
                  component={AsyncWalletView}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  exact
                  path={WalletRoutes.WalletPayees}
                  component={AsyncBeneficiaries}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  exact
                  path={WalletRoutes.WalletTransactions}
                  component={AsyncWalletTransactions}
                />
                {/* <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  path={ProfileRoutes.Relationships}
                  component={AsyncRelationshipView}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  path={ProfileRoutes.Insurances}
                  component={AsyncInsuranceView}
                /> */}
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient, RolesEnum.Provider]}
                  path={HealRoutes.HealthRecords}
                  component={AsyncPersonalHealthRecordView}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  path={HealRoutes.CareTeam}
                  component={AsyncCareTeamView}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  path={HealRoutes.BookConsult}
                  component={AsyncBookingComponent}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  path={CampaignRoutes.GiveTheGift}
                  component={AsyncGiveTheGift}
                />
                {isInRole(currentUser, RolesEnum.Patient) && (
                  <AppFeatureComponent featureName={AppFeatures.PatientMessagingEnabled}>
                    <AuthenticatedRoute
                      roles={[RolesEnum.Patient, RolesEnum.Provider]}
                      path={MessageCenterRoutes.Mail}
                      component={AsyncMailView}
                    />
                  </AppFeatureComponent>
                )}
                {isInRole(currentUser, RolesEnum.Provider) && (
                  <AuthenticatedRoute
                    roles={[RolesEnum.Patient, RolesEnum.Provider]}
                    path={MessageCenterRoutes.Mail}
                    component={AsyncMailView}
                  />
                )}
                <AuthenticatedRoute
                  path={DashboardRoutes.Dashboard}
                  exact
                  component={AsyncDashboardView}
                />
                <AuthenticatedRoute
                  path={DashboardRoutes.Submenu}
                  exact
                  component={AsyncSubMenuDashboardView}
                />
                <AuthenticatedRoute
                  path={ProviderOnboardingRoutes.SubmitDocuments}
                  exact
                  roles={[RolesEnum.NotApprovedProvider]}
                  component={AsyncProviderOnboardingSubmitDocuments}
                />
                <AuthenticatedRoute
                  path={DashboardRoutes.BlogPost}
                  exact
                  component={AsyncBlogPostView}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient, RolesEnum.Provider]}
                  path={DashboardRoutes.AcceptConsult}
                  exact
                  component={AsyncAcceptInvite}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.SubscriptionOwner]}
                  path="/subscriptions"
                  exact
                  component={AsyncSubscriptionDashboard}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient, RolesEnum.Provider]}
                  path={LearnRoutes.CareSpaces}
                  exact
                  component={AsyncCareSpacesView}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient, RolesEnum.Provider]}
                  path={LearnRoutes.Module}
                  exact
                  component={AsyncModuleView}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient, RolesEnum.Provider]}
                  path={LearnRoutes.ModuleResource}
                  exact
                  component={AsyncModuleResourceView}
                />
                <AuthenticatedRoute
                  path={SupportCenterRoutes.Support}
                  exact
                  component={AsyncSupportView}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient, RolesEnum.Provider]}
                  path={CampaignRoutes.CampaignDetails}
                  exact
                  component={AsyncCampaign}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  exact
                  path={MonitorRoutes.HealthJournal}
                  component={AsyncHealthJournal}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  exact
                  path={MonitorRoutes.Vitals}
                  component={AsyncVitals}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Patient]}
                  exact
                  path={MonitorRoutes.MoodTrackers}
                  component={AsyncMoodTrackers}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  exact
                  path={[
                    HealRoutes.Fax,
                    HealRoutes.FaxIncoming,
                    HealRoutes.FaxForwarded,
                    HealRoutes.FaxDeleted,
                    HealRoutes.FaxOutbound,
                  ]}
                  component={AsyncFaxComponent}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  exact
                  path={[MonitorRoutes.ReferralHub]}
                  component={AsyncReferralHub}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  exact
                  path={[MonitorRoutes.Statements]}
                  component={AsyncStatements}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  exact
                  path={[MonitorRoutes.PayoutSettings]}
                  component={AsyncPayoutSettings}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  exact
                  path={[MonitorRoutes.DownloadStatements]}
                  component={AsyncDownloadStatements}
                />
                <AuthenticatedRoute
                  roles={[RolesEnum.Provider]}
                  exact
                  path={[MonitorRoutes.TransactionHistory]}
                  component={AsyncTransactionHistory}
                />
                <AuthenticatedRoute
                  path={DefaultRoutes.Unauthorized}
                  exact
                  component={AsyncStatus403}
                />
                <AuthenticatedRoute
                  path={DefaultRoutes.NotFound}
                  exact
                  component={AsyncStatus404}
                />
                <AuthenticatedRoute
                  exact
                  path={ExternalRoutes.PaymentLink}
                  component={AsyncPaymentLinkView}
                />
              </AuthenticatedLayout>
            )}
          </Route>
          <AuthenticatedRoute
            roles={[RolesEnum.Patient, RolesEnum.Provider]}
            exact
            path={ConsultRoutes.Consult}
            component={AsyncConsultView}
          />
          <AuthenticatedLayout>
            <Route component={AsyncStatus404} />
          </AuthenticatedLayout>
        </Switch>
      </GlobalErrorBoundary>
    </>
  );
};

export default RootView;
