import React, { useState } from 'react';
import moment from 'moment';
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  IconButton,
  Center,
  useMediaQuery,
} from '@chakra-ui/react';
import { EditIcon, BinIcon, VerifiedShieldIcon } from '../../../assets/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LightGreenCircle from '../../../assets/images/light-green-circle.svg';

import { DeleteButton, PrimaryButton } from '../../../components/Buttons';
import { Device, TimeFormatEnum } from '../../../constants';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/root-reducers';
import { RolesEnum } from '../../../generated';
import { isInRole } from '../../../utils/isInRole';

import PhrVerifyModal from './PhrVerifyModal';
import { errorToastr } from '../../../utils/toastr';
import { OutpostTheme } from '../../../themes/outpost';

interface IPhrItemCommon {
  name?: string;
  verified?: boolean;
  createdDate?: Date;
  createdByName?: string;
  updatedDate?: Date;
  updatedByName?: string;
  verifiedDate?: Date;
  verifiedByProviderName?: string;
}

interface IPhrItemViewProps {
  phrItem: IPhrItemCommon;
  onVerify: () => void;
  children: React.ReactNode;
  name?: string;
  isVerified?: boolean;
}

const PhrItemView = ({ phrItem, onVerify, children, name, isVerified }: IPhrItemViewProps) => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data!);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [hasVerified, setHasVerified] = useState(isVerified || phrItem?.verified);
  const isProvider = isInRole(currentUser, RolesEnum.Provider);

  const handleVerification = async () => {
    setIsOpenModal(false);
    setIsVerifying(true);
    try {
      await onVerify();
      setHasVerified(true);
    } catch (e) {
      errorToastr({ description: 'Unable to verify patient' });
    } finally {
      setIsVerifying(false);
    }
  };

  const handleModal = (modalState: boolean) => {
    if (modalState) {
      if (isProvider && !hasVerified) {
        setIsOpenModal(modalState);
      }
    } else {
      setIsOpenModal(modalState);
    }
  };

  return (
    <>
      <PhrVerifyModal
        isOpen={isOpenModal}
        onDismiss={() => handleModal(false)}
        onVerify={handleVerification}
      />

      <Box
        bgColor="#ffffff"
        borderRadius="lg"
        position="relative"
        overflowY="auto"
        marginX={{ base: '0.5rem', md: '1.5rem' }}
        paddingX={{ base: '0.5rem', md: '1.5rem' }}
        paddingBottom="1.5rem"
        border={{ base: 'none', md: '1px solid #0C346891' }}
        height={{ base: 'calc(100vh - 8rem)', md: '550px' }}
      >
        <Flex
          justify="space-between"
          align="center"
          position="sticky"
          top="-1px"
          bgColor="#ffffff"
          padding={{ base: '1rem', md: '1.5rem' }}
          marginX={{ base: '-0.5rem', md: '-1.5rem' }}
          backgroundImage={hasVerified ? LightGreenCircle : undefined}
          backgroundRepeat="no-repeat"
          backgroundPosition={{ base: 'calc(100% + 90px) -135px', md: 'calc(100% + 90px) -120px' }}
        >
          <Text fontSize="1rem" color="#3E1191" fontWeight="semibold">
            {name || phrItem?.name}
          </Text>{' '}
          {hasVerified ? (
            <VerifiedShieldIcon height="22px" color="#418A94" width="22px" />
          ) : (
            <PrimaryButton
              onClick={() => handleModal(true)}
              cursor="pointer"
              display={isProvider ? 'block' : 'none'}
              flexShrink={0}
              borderRadius="full"
              isLoading={isVerifying}
            >
              Verify Record
            </PrimaryButton>
          )}
        </Flex>

        {children}

        <Box marginTop="1.5rem">
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Details
          </Text>
          <VStack
            marginTop="0.5rem"
            spacing={4}
            align="flex-start"
            fontSize={OutpostTheme.FontSizes.body}
          >
            {phrItem.createdDate && (
              <HStack>
                <FontAwesomeIcon size="xs" color="#F2A1AE" icon={['far', 'circle']} />
                <Text>
                  Created by {phrItem.createdByName} on{' '}
                  {moment(phrItem.createdDate).format(TimeFormatEnum.LONG_DATE)}
                </Text>
              </HStack>
            )}

            {phrItem.updatedDate && (
              <HStack>
                <FontAwesomeIcon size="xs" color="#2E8AE7" icon={['far', 'circle']} />
                <Text>
                  Updated by {phrItem.updatedByName} on{' '}
                  {moment(phrItem.updatedDate).format(TimeFormatEnum.LONG_DATE)}
                </Text>
              </HStack>
            )}

            {phrItem.verified && (
              <HStack>
                <FontAwesomeIcon size="xs" color="#2B7D88" icon={['far', 'circle']} />
                <Text>
                  Verified by {phrItem.verifiedByProviderName} on{' '}
                  {moment(phrItem.verifiedDate).format(TimeFormatEnum.LONG_DATE)}
                </Text>
              </HStack>
            )}
          </VStack>
        </Box>
      </Box>
    </>
  );
};

export default PhrItemView;

export const PhrDetailsButtons = ({ isVerified, onEdit, onDelete, phrItem, phrItemID }) => {
  const [showBtn] = useMediaQuery(Device.mobileL);
  const currentUser = useSelector((state: AppState) => state.currentUserState.data!);
  const isPatient = isInRole(currentUser, RolesEnum.Patient);

  if (isPatient && isVerified) return null;

  return (
    <Flex
      marginLeft="auto"
      marginRight={{ base: '1.5rem', md: '2.5rem' }}
      justify="flex-end"
      align="center"
      alignSelf="flex-start"
      position="sticky"
      bottom="-1px"
    >
      {showBtn ? (
        <PrimaryButton borderRadius="full" onClick={() => onEdit(phrItem!)}>
          Edit
        </PrimaryButton>
      ) : (
        <IconButton
          aria-label="close"
          p={0}
          variant="ghost"
          borderRadius="full"
          onClick={() => onEdit(phrItem!)}
        >
          <Center alignItems="center" justifyContent="center">
            <EditIcon width="20px" height="20px" />
          </Center>
        </IconButton>
      )}

      {showBtn ? (
        <DeleteButton fontWeight="normal" marginLeft="1rem" onClick={() => onDelete(phrItemID)}>
          Delete
        </DeleteButton>
      ) : (
        <IconButton
          aria-label="close"
          p={0}
          variant="ghost"
          borderRadius="full"
          onClick={() => onDelete(phrItemID)}
        >
          <Center width="24px" height="24px" border="1px solid red" borderRadius="full">
            <BinIcon stroke="red" />
          </Center>
        </IconButton>
      )}
    </Flex>
  );
};
