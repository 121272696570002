/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { useState } from 'react';
import FadeInComponent from '../../../../components/animations/FadeInComponent';
import { CenteredLoadingIndicator } from '../../../../components/LoadingIndicator';
import { IPatient, PHRTracker, TrackerTypeEnum } from '../../../../generated';
import StepCounterComponent from '../stepCounter/StepCounter';
import AnxietyComponent from './anxiety/Anxiety';
import { renderHeader } from './components/Header';
import MoodTrackerItem from './components/MoodTrackerItem';
import useTrackers, {
  getAnxietyStatusByValue,
  getMoodStatusTextByValue,
  getSleepStatusByValue,
  getStressStatusByValue,
  useMentalTrackerValue,
} from './hooks/useTrackers';
import MoodComponent from './mood/Mood';
import SleepComponent from './sleep/Sleep';
import StressComponent from './stress/Stress';

interface IProps {
  patient?: IPatient;
  trackers: PHRTracker[];
  onBack?: () => void;
  renderDefaultHeader?: any;
}
const MoodTrackers = ({ patient, trackers, onBack, renderDefaultHeader }: IProps) => {
  const { isLoading, moodTrackers, phrPatient } = useTrackers(patient, trackers);
  const [currentTrackerType, setCurrentTrackerType] = useState<TrackerTypeEnum | null>(null);

  const { phrMoods, phrAnxieties, phrSleep, phrStresses } = useMentalTrackerValue({
    patient: phrPatient?.phr,
  });

  const renderMoodHeader = renderHeader(() => setCurrentTrackerType(null), 'Mood Tracker');
  const renderAnxietyHeader = renderHeader(() => setCurrentTrackerType(null), 'Anxiety Tracker');
  const renderStressHeader = renderHeader(() => setCurrentTrackerType(null), 'Stress Tracker');
  const renderSleepHeader = renderHeader(() => setCurrentTrackerType(null), 'Sleep Tracker');
  const rendeStepCounterHeader = renderHeader(() => setCurrentTrackerType(null), 'Step Counter');

  const renderTracker = () => {
    switch (currentTrackerType) {
      case TrackerTypeEnum.Mood:
        return (
          <MoodComponent
            patient={phrPatient?.phr!}
            renderHeader={renderMoodHeader}
            onClose={() => setCurrentTrackerType(null)}
          />
        );
      case TrackerTypeEnum.Anxiety:
        return (
          <AnxietyComponent
            patient={phrPatient?.phr!}
            renderHeader={renderAnxietyHeader}
            onClose={() => setCurrentTrackerType(null)}
          />
        );
      case TrackerTypeEnum.Stress:
        return (
          <StressComponent
            patient={phrPatient?.phr!}
            renderHeader={renderStressHeader}
            onClose={() => setCurrentTrackerType(null)}
          />
        );
      case TrackerTypeEnum.Sleep:
        return (
          <SleepComponent
            patient={phrPatient?.phr!}
            renderHeader={renderSleepHeader}
            onClose={() => setCurrentTrackerType(null)}
          />
        );
      case TrackerTypeEnum.StepCounter:
        return (
          <StepCounterComponent
            onClose={() => setCurrentTrackerType(null)}
            patient={phrPatient?.phr!}
            renderHeader={rendeStepCounterHeader}
          />
        );
    }
  };

  const getTrackerDisplayValue = (currentTrackerType) => {
    switch (currentTrackerType) {
      case TrackerTypeEnum.Mood:
        const mood = phrMoods.sort()[0];
        return {
          value: getMoodStatusTextByValue(mood?.moodValue),
          date: mood?.upsertDate,
        };
      case TrackerTypeEnum.Anxiety:
        const anxiety = phrAnxieties.sort()[0];
        return {
          value: getAnxietyStatusByValue(anxiety?.anxietyValue),
          date: anxiety?.upsertDate,
        };
      case TrackerTypeEnum.Stress:
        const stress = phrStresses.sort()[0];
        return {
          value: getStressStatusByValue(stress?.stressValue),
          date: stress?.upsertDate,
        };
      case TrackerTypeEnum.Sleep:
        const sleep = phrSleep.sort()[0];
        return {
          value: getSleepStatusByValue(sleep?.sleepValue),
          date: sleep?.upsertDate,
        };
      case TrackerTypeEnum.StepCounter:
        return {
          value: '0 Steps',
          date: undefined,
        };
    }
  };

  const renderTrackerList = () => {
    const colors = ['#fd7024', '#7BBFB7', '#FFC235', '#F0E9D4', '#3FA884', '#FF84A1'];

    return (
      <>
        <Box bgColor="#f4f4f4" height="100%" overflowY="hidden" pt={4}>
          {onBack
            ? renderDefaultHeader(undefined, onBack, 'Mental Health', true)
            : renderDefaultHeader(undefined, undefined, 'Mental & Wellbeing', true)}

          <Box
            height={{ base: 'calc(100vh - 4rem)', md: '580px' }}
            paddingX={{ base: '1rem', md: '70px' }}
            overflowY="auto"
            w="100%"
          >
            <FadeInComponent>
              {moodTrackers && (
                <Grid
                  templateColumns={{ base: '1fr', md: '1fr 1fr' }}
                  gridTemplateRows={{ base: 'auto', md: 'repeat(10, 75px)' }}
                  gridGap={4}
                >
                  <GridItem
                    gridColumn={{ base: 'auto', md: '1/2' }}
                    gridRow={{ base: 'auto', md: '1/4' }}
                  >
                    <MoodTrackerItem
                      type={TrackerTypeEnum.Anxiety}
                      getTrackerDisplayValue={getTrackerDisplayValue}
                      color={colors[0]}
                      onClick={() => setCurrentTrackerType(TrackerTypeEnum.Anxiety)}
                      moodTrackers={moodTrackers}
                    />
                  </GridItem>

                  <GridItem
                    gridColumn={{ base: 'auto', md: '2' }}
                    gridRow={{ base: 'auto', md: '2/5' }}
                    mt={{ base: '0', md: '-2rem' }}
                  >
                    <MoodTrackerItem
                      type={TrackerTypeEnum.Mood}
                      getTrackerDisplayValue={getTrackerDisplayValue}
                      color={colors[1]}
                      onClick={() => setCurrentTrackerType(TrackerTypeEnum.Mood)}
                      moodTrackers={moodTrackers}
                    />
                  </GridItem>

                  <GridItem
                    gridColumn={{ base: 'auto', md: '1/2' }}
                    gridRow={{ base: 'auto', md: '4/7' }}
                    mt={{ base: '0', md: '-2rem' }}
                  >
                    <MoodTrackerItem
                      type={TrackerTypeEnum.Sleep}
                      getTrackerDisplayValue={getTrackerDisplayValue}
                      color={colors[2]}
                      onClick={() => setCurrentTrackerType(TrackerTypeEnum.Sleep)}
                      moodTrackers={moodTrackers}
                    />
                  </GridItem>
                  <GridItem
                    gridColumn={{ base: 'auto', md: '2' }}
                    gridRow={{ base: 'auto', md: '5/8' }}
                    mt={{ base: '0', md: '-4rem' }}
                  >
                    <MoodTrackerItem
                      type={TrackerTypeEnum.Stress}
                      getTrackerDisplayValue={getTrackerDisplayValue}
                      color={colors[3]}
                      onClick={() => setCurrentTrackerType(TrackerTypeEnum.Stress)}
                      moodTrackers={moodTrackers}
                    />
                  </GridItem>
                </Grid>
              )}
            </FadeInComponent>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <Box w="100%" h="100%" p={4}>
          <CenteredLoadingIndicator />
        </Box>
      ) : (
        <>
          {currentTrackerType ? (
            <Box bg="#f4f4f4" h="100%" w="100%" p={4}>
              {renderTracker()}
            </Box>
          ) : (
            renderTrackerList()
          )}
        </>
      )}
    </>
  );
};

export default MoodTrackers;

// const Wrapper = styled.div`
//   display: flex;
//   flex-wrap: wrap;
// `;

// const SubCategoryItem = styled(Card)`
//   flex: 1 0 35%;
//   margin: 10px 0;
//   min-width: 200px;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   padding: 20px;
//   max-width: calc(50% - 20px);

//   @media ${Device.mobileL} {
//     margin: 10px 10px;
//   }

//   &:hover {
//     cursor: pointer;
//   }
// `;

// const StyledToggle = styled(ToggleElement)`
//   font-size: 1.25rem;
// `;

// const IconWrapper = styled.div`
//   margin-left: 10px;
// `;

// const ChevronRightIcon = styled(FontAwesomeIcon)`
//   color: ${(props) => props.theme.FontColors.Primary};
// `;
