import { ModalBody, VStack } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { BaseModal } from '../Modals/BaseModal';

import AddressBook, { AddressBookComponentProps } from './AddressBook';

type AddressBookModalProps = {
  addressBookProps: AddressBookComponentProps;
  /**
   * Callback called to close modal
   */
  onCloseModal?: () => void;
  /**
   * Callback attached to the dismiss action of base modal
   */
  onDismissModal?: () => void;
  isModalOpen: boolean;
  modalTitle?: ReactNode;
};

const AddressBookModal: FC<AddressBookModalProps> = (props) => {
  return (
    <BaseModal
      isOpen={props.isModalOpen}
      size="xl"
      title={props.modalTitle}
      onDismiss={props.onDismissModal}
    >
      <VStack height="100%" alignItems="stretch" overflowY="auto">
        <ModalBody flex="1" overflowY="auto" p="1rem !important">
          <AddressBook {...props.addressBookProps} />
        </ModalBody>
      </VStack>
    </BaseModal>
  );
};

export { AddressBookModal };
