import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PHRTrackerStepCounterDTO } from '../../../../generated';
import { IPHRPatientEnhanced } from '../../../../store/patient/phr/phrReducers';
import { phrStepCounterSelector } from '../../../../store/patient/phr/phrSelectors';
import { getAllForPhrSection } from '../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import PhrItem, { SecondaryInfoComponent } from '../../components/PhrItem';
import PhysicalTrackerView from '../../physicalTrackers/PhysicalTrackerView';
import StepCounterChart from './StepCounterChart';
import StepCounterForm from './StepCounterForm';

interface IProps {
  patient: IPHRPatientEnhanced;
  renderHeader: (addButton?: any) => any;
  onClose?: () => void;
}

const StepCounterComponent = ({ patient, renderHeader, onClose }: IProps) => {
  const dispatch = useDispatch();
  const phrStepCounters = phrStepCounterSelector(patient.phrStepCounters);
  const [stepCounters, setStepCounters] = useState<PHRTrackerStepCounterDTO[]>([]);

  useEffect(() => {
    if (patient) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRStepCounters, patient.patientID));
    }
  }, [dispatch, patient]);

  useEffect(() => {
    setStepCounters(phrStepCounters);
  }, [phrStepCounters]);

  const thinView = (stepCounter: PHRTrackerStepCounterDTO, onView?: (data: any) => void) => (
    <>
      <PhrItem
        name={`${stepCounter.stepCount}`}
        isVerified={stepCounter.verified}
        onView={() => {
          if (onView) {
            onView(stepCounter);
          }
        }}
        SecondaryInfo={
          <SecondaryInfoComponent
            startDate={stepCounter.trackerDate}
            startDateTitle="Tracked on:"
            showTime
          />
        }
      />
    </>
  );

  return (
    <PhysicalTrackerView
      onClose={() => {
        if (onClose) onClose();
      }}
      renderHeader={renderHeader}
      phrCategoryEnum={ReduxPHRCategoryEnum.PHRStepCounters}
      patient={patient}
      trackerData={stepCounters}
      thinView={thinView}
      Chart={StepCounterChart}
      Form={StepCounterForm}
      createText="Step Count"
    />
  );
};

export default StepCounterComponent;
