import { Box, Flex, HStack } from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  AuthClient,
  PatientsClient,
  PHRTrackerCovidDTO,
  PHRVaccination,
  VaccineTypeEnum,
} from '../../../generated';
import { clearPHRPatientErrorAction } from '../../../store/patient/phr/phrActions';
import { IPHRPatientEnhanced } from '../../../store/patient/phr/phrReducers';
import {
  phrDenguesSelector,
  phrVaccinationsSelector,
} from '../../../store/patient/phr/phrSelectors';
import { deleteForPhrSection, getAllForPhrSection } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { scrollToTop } from '../../../utils/scrollToTop';

import { TertiaryButton } from '../../../components/Buttons';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import ServerValidationBox from '../../../components/ServerValidationBox';
import PhrItemDeleteModal from '../components/PhrItemDeleteModal';
import { PhrFormButtons } from '../components/PhrItemForm';
import { PhrDetailsButtons } from '../components/PhrItemView';
import CovidTestForm from './CovidTests/CovidTestForm';
import CovidTestsList from './CovidTests/CovidTestsList';
import CovidTestView from './CovidTests/CovidTestView';
import CovidVaccinationForm from './CovidVaccinations/CovidVaccinationsForm';
import CovidVaccinationsList from './CovidVaccinations/CovidVaccinationsList';
import CovidVaccinationView from './CovidVaccinations/CovidVaccinationView';

enum EVaccinationsViewState {
  List = 'List',
  View = 'View',
  Edit = 'Edit',
}

enum ECovidTab {
  Summary = 'Summary',
  Vaccines = 'Vaccines',
  Tests = 'Tests',
}

interface IProps {
  patient: IPHRPatientEnhanced;
  renderHeader: (onCreate?: any, onBack?: () => void, title?: string, showDismiss?: boolean) => any;
}

const Covid = ({ patient, renderHeader }: IProps) => {
  const dispatch = useDispatch();
  const innerBtnRef = useRef<HTMLButtonElement>(null);
  const [currentView, setCurrentView] = useState(EVaccinationsViewState.List);
  const [currentTab, setCurrentTab] = useState(ECovidTab.Vaccines);
  const phrCovidTests = phrDenguesSelector(patient.phrCovids);
  const [currentItem, setCurrentItem] = useState<PHRVaccination | PHRTrackerCovidDTO | null>(null);
  const [recordToDelete, setRecordToDelete] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const phrVaccinations = phrVaccinationsSelector(patient.phrVaccinations).filter(
    (vaccination) => vaccination.vaccineTypeEnum === VaccineTypeEnum.Covid,
  );
  const { isPhrLoading, error } = useSelector((state: AppState) => state.phrPatientState);
  const [hasJustVerifiedTest, setHasJustVerifiedTest] = useState(false);

  const [covidVaccineQRCode, setCovidVaccineQRCode] = useState('');
  const [covidTestQRCode, setCovidTestQRCode] = useState('');

  const fetchCovidQRCodeFile = useCallback(async () => {
    let qrCodeUrl = '';
    try {
      const qrCodeImg = await new PatientsClient(new AuthClient()).downloadCovidTestQRCode(
        patient.patientID,
      );
      if (qrCodeImg) {
        qrCodeUrl = URL.createObjectURL(qrCodeImg.data);
      }
    } catch (e) {
      qrCodeUrl = '';
    } finally {
      setCovidTestQRCode(qrCodeUrl);
    }
  }, [patient.patientID]);

  useEffect(() => {
    if (patient) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRVaccinations, patient.patientID));
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRCovids, patient.patientID));
    }
  }, [dispatch, hasJustVerifiedTest, patient]);

  useEffect(() => {
    const fetchQRCodeFile = async () => {
      let qrCodeUrl = '';
      try {
        const qrCodeImg = await new PatientsClient(new AuthClient()).downloadCovidVaccineQRCode(
          patient.patientID,
        );
        if (qrCodeImg) {
          qrCodeUrl = URL.createObjectURL(qrCodeImg.data);
        }
      } catch (e) {
        qrCodeUrl = '';
      } finally {
        setCovidVaccineQRCode(qrCodeUrl);
      }
    };
    fetchQRCodeFile();

    return () => URL.revokeObjectURL(covidVaccineQRCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient.patientID]);

  useEffect(() => {
    fetchCovidQRCodeFile();

    return () => URL.revokeObjectURL(covidTestQRCode);
  }, [covidTestQRCode, fetchCovidQRCodeFile]);

  const onVaccineView = (phrVaccination: PHRVaccination) => {
    setCurrentItem(phrVaccination);
    setCurrentView(EVaccinationsViewState.View);
  };

  const onTestView = (phrCovidTest: PHRTrackerCovidDTO) => {
    setCurrentItem(phrCovidTest);
    setCurrentView(EVaccinationsViewState.View);
  };

  const onVaccineCreate = () => {
    setCurrentItem(null);
    setCurrentTab(ECovidTab.Vaccines);
    setCurrentView(EVaccinationsViewState.Edit);
  };

  const onTestCreate = () => {
    setCurrentItem(null);
    setCurrentTab(ECovidTab.Tests);
    setCurrentView(EVaccinationsViewState.Edit);
  };

  const onVaccineEdit = (phrVaccination: PHRVaccination) => {
    setCurrentItem(phrVaccination);
    setCurrentTab(ECovidTab.Vaccines);
    setCurrentView(EVaccinationsViewState.Edit);
  };

  const onTestEdit = (covidTest: PHRTrackerCovidDTO) => {
    setCurrentItem(covidTest);
    setCurrentTab(ECovidTab.Tests);
    setCurrentView(EVaccinationsViewState.Edit);
  };

  const onDelete = (phrVaccinationID: string) => {
    setShowDeleteModal(true);
    setRecordToDelete(phrVaccinationID);
  };

  const confirmCovidDelete = async () => {
    setCurrentView(EVaccinationsViewState.List);
    setShowDeleteModal(false);
    await dispatch(
      deleteForPhrSection(
        ReduxPHRCategoryEnum.PHRVaccinations,
        recordToDelete,
        patient.patientID,
        false,
      ),
    );
  };

  const confirmTestDelete = async () => {
    setCurrentView(EVaccinationsViewState.List);
    setShowDeleteModal(false);
    await dispatch(
      deleteForPhrSection(ReduxPHRCategoryEnum.PHRCovids, recordToDelete, patient.patientID, true),
    );
  };

  useEffect(() => {
    dispatch(clearPHRPatientErrorAction());
    scrollToTop();
  }, [currentView, dispatch]);

  return (
    <Box paddingY="2rem" backgroundColor="#f4f4f4" height="100%">
      {currentView === EVaccinationsViewState.List ? (
        renderHeader()
      ) : (
        <Flex>
          {renderHeader(null, () => setCurrentView(EVaccinationsViewState.List), '', false)}
          {currentView === EVaccinationsViewState.View && (
            <PhrDetailsButtons
              isVerified={currentItem?.verified}
              phrItem={currentItem}
              phrItemID={
                'phrVaccinationID' in currentItem!
                  ? currentItem?.phrVaccinationID
                  : currentItem?.phrTrackerDetailID
              }
              onEdit={currentTab === ECovidTab.Vaccines ? onVaccineEdit : onTestEdit}
              onDelete={onDelete}
            />
          )}
          {currentView === EVaccinationsViewState.Edit && (
            <PhrFormButtons
              innerBtnRef={innerBtnRef}
              toggleView={() => setCurrentView(EVaccinationsViewState.List)}
            />
          )}
        </Flex>
      )}

      {currentView === EVaccinationsViewState.List && (
        <>
          <ServerValidationBox message={error} />
          {isPhrLoading ? (
            <Box marginTop="1rem">
              <CenteredLoadingIndicator />
            </Box>
          ) : (
            <Box marginTop="1.5rem">
              <HStack spacing={12} justify="center">
                <CovidTabButton
                  name="Vaccines"
                  tab={ECovidTab.Vaccines}
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <CovidTabButton
                  name="Tests"
                  tab={ECovidTab.Tests}
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
              </HStack>
              {currentTab === ECovidTab.Vaccines && (
                <CovidVaccinationsList
                  phrVaccinations={phrVaccinations}
                  covidVaccineQRCode={covidVaccineQRCode}
                  patient={patient}
                  onView={onVaccineView}
                  onCreate={onVaccineCreate}
                />
              )}
              {currentTab === ECovidTab.Tests && (
                <CovidTestsList
                  phrCovidTests={phrCovidTests}
                  covidTestQRCode={covidTestQRCode}
                  onView={onTestView}
                  onCreate={onTestCreate}
                />
              )}
            </Box>
          )}
        </>
      )}

      {currentView === EVaccinationsViewState.View && 'phrVaccinationID' in currentItem! && (
        <CovidVaccinationView vaccination={currentItem!} />
      )}

      {currentView === EVaccinationsViewState.View && 'phrTrackerDetailID' in currentItem! && (
        <CovidTestView
          covidTest={currentItem!}
          hasJustVerified={hasJustVerifiedTest}
          setHasJustVerified={setHasJustVerifiedTest}
          fetchCovidQRCodeFile={fetchCovidQRCodeFile}
        />
      )}

      {currentView === EVaccinationsViewState.Edit && currentTab === ECovidTab.Vaccines && (
        <CovidVaccinationForm
          currentVaccination={currentItem as PHRVaccination}
          patientID={patient.patientID}
          toggleView={() => setCurrentView(EVaccinationsViewState.List)}
          innerBtnRef={innerBtnRef}
        />
      )}

      {currentView === EVaccinationsViewState.Edit && currentTab === ECovidTab.Tests && (
        <CovidTestForm
          currentTest={currentItem as PHRTrackerCovidDTO}
          patientID={patient.patientID}
          toggleView={() => setCurrentView(EVaccinationsViewState.List)}
          innerBtnRef={innerBtnRef}
          fetchCovidQRCodeFile={fetchCovidQRCodeFile}
        />
      )}

      {currentView === EVaccinationsViewState.View && (
        <PhrItemDeleteModal
          isOpen={showDeleteModal}
          onDismiss={() => setShowDeleteModal(false)}
          onDelete={'phrVaccinationID' in currentItem! ? confirmCovidDelete : confirmTestDelete}
        />
      )}
    </Box>
  );
};

export default Covid;

const CovidTabButton = ({ name, tab, currentTab, setCurrentTab }) => {
  return (
    <TertiaryButton
      padding="0.5rem"
      width="148px"
      _hover={{
        textDecoration: 'none',
      }}
      borderRadius="none"
      fontWeight="500"
      color={currentTab === tab ? '#020202' : '#0C34685E'}
      borderBottom={currentTab === tab ? '2px solid #020202' : ''}
      onClick={() => setCurrentTab(tab)}
    >
      {name}
    </TertiaryButton>
  );
};
