import { ModalBody } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AuthClient,
  Provider,
  ProvidersClient,
  ProviderSearchDetails,
  ProviderSettingEnum,
} from '../../../generated';
import { AppState } from '../../../store/root-reducers';
import { httpRequest } from '../../../utils';
import { ButtonRow, PrimaryButton, SecondaryButton } from '../../Buttons';
import { FormInputControl } from '../../forms';
import { BaseModal } from '../../Modals';
import ServerValidationBox from '../../ServerValidationBox';
import ProviderSearchModalSearchList from './ProviderSearchModalSearchList';

interface IProps {
  isOpen: boolean;
  close: () => void;
  handleSelectedProvider: (selectedProvider: Provider) => void;
  providerSettingEnum?: ProviderSettingEnum;
  providerSettingValue?: string;
  defaultCountryID?: string;
  modalTitle?: string;
}

interface IForm {
  searchText: string;
}

const ProviderSearchModal = ({
  isOpen,
  close,
  handleSelectedProvider,
  providerSettingEnum,
  providerSettingValue,
  modalTitle,
}: IProps) => {
  const authClient = new AuthClient();
  const providersClient = new ProvidersClient(authClient);
  const provider = useSelector((state: AppState) => state.providerState.provider);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [providers, setProviders] = useState<Provider[]>([]);
  const [hasSearched, setHasSearched] = useState<boolean>(false);

  const onSearch = async (values: IForm) => {
    setIsLoading(true);
    try {
      const providerSearchDetails = new ProviderSearchDetails();
      providerSearchDetails.recordsToTake = 100;
      providerSearchDetails.recordsToSkip = 0;
      providerSearchDetails.searchText = values.searchText;
      providerSearchDetails.providerSettingEnum = providerSettingEnum
        ? providerSettingEnum
        : undefined;
      providerSearchDetails.providerSettingValue = providerSettingValue
        ? providerSettingValue
        : undefined;

      const organizationSetting = provider?.providerSettings?.find(
        (x) => x.providerSettingEnum === ProviderSettingEnum.Organization,
      );

      providerSearchDetails.organizationID = organizationSetting
        ? organizationSetting.value
        : undefined;

      const departmentSetting = provider?.providerSettings?.find(
        (x) => x.providerSettingEnum === ProviderSettingEnum.Department,
      );
      providerSearchDetails.departmentID = departmentSetting ? departmentSetting.value : undefined;

      const result = await httpRequest(() => providersClient.search(providerSearchDetails));
      const sortedResults =
        result.items?.sort((a, b) => a.fullName!.localeCompare(b.fullName!)) ?? [];
      setProviders(sortedResults);
      setHasSearched(true);
    } catch (err) {
      setError(err as string);
    }
    setIsLoading(false);
  };

  const onReset = () => {
    setProviders([]);
    setError('');
    setHasSearched(false);
  };

  const onSelectProvider = (provider: Provider) => {
    handleSelectedProvider(provider);
    close();
  };

  return (
    <BaseModal
      title={modalTitle ? modalTitle : `Search For A ${process.env.REACT_APP_PROVIDER_TITLE}`}
      isOpen={isOpen}
      onDismiss={() => close()}
      size="xl"
    >
      <ModalBody>
        <Formik
          initialValues={{
            searchText: '',
          }}
          onSubmit={onSearch}
          onReset={() => {
            setError('');
            onReset();
          }}
        >
          {({ handleReset }) => (
            <Form>
              <ServerValidationBox message={error} />
              <FormInputControl type="text" name="searchText" label="Search by name" />

              <ButtonRow>
                <SecondaryButton
                  disabled={isLoading}
                  title={isLoading ? 'Loading search results.' : ''}
                  onClick={handleReset}
                >
                  Reset
                </SecondaryButton>
                <PrimaryButton type="submit" isLoading={isLoading}>
                  Search
                </PrimaryButton>
              </ButtonRow>
            </Form>
          )}
        </Formik>

        <ProviderSearchModalSearchList
          isLoading={isLoading}
          hasSearched={hasSearched}
          providers={providers}
          handleSelectedProvider={onSelectProvider}
        />
      </ModalBody>
    </BaseModal>
  );
};

export default ProviderSearchModal;
