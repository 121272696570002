import React, { useContext } from 'react';
import { toggleSwitchContext } from './ToggleSwitchContext';

interface IProps {
  id: string;
  children: any;
}

export const ToggleArea = ({ id, children }: IProps) => {
  const { activeId } = useContext(toggleSwitchContext);
  return <>{activeId === id && children}</>;
};
