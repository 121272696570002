import React from 'react';
import { COOKIE_NAME } from '../../../constants';
import { FileDetail } from '../../../generated';
import { getCookie } from '../../../utils/cookieHelper';
import { getFileDetail } from '../../../utils/fileHelpers';

interface IProps {
  files: IFileUpload[];
  setFiles: React.Dispatch<React.SetStateAction<IFileUpload[]>>;
  getRequestBody: (fileDetail: FileDetail) => any;
  uploadUrl: string;
  multiple?: boolean;
}

export enum FileUploadStatusEnum {
  ReadyToUpload = 'ReadyToUpload',
  Uploading = 'Uploading',
  Completed = 'Completed',
  Failed = 'Failed',
}

export interface IFileUpload {
  file: File;
  name: string;
  size: number;
  type: string;
  uploadStatus: FileUploadStatusEnum;
  progress: number;
  id?: string;
  error: string;
}

const useFileUploader = ({
  files,
  setFiles,
  getRequestBody,
  uploadUrl,
  multiple = true,
}: IProps) => {
  const onFileAdded = async (acceptedFiles: File[]) => {
    const uniqueFiles = acceptedFiles
      .filter((x) => !files.find((y) => y.name === x.name))
      .map((x) => ({
        file: x,
        name: x.name,
        size: x.size,
        type: x.type,
        uploadStatus: FileUploadStatusEnum.ReadyToUpload,
        progress: 0,
        id: undefined,
        error: '',
      }));
    let newFiles: IFileUpload[] = [];
    if (multiple) {
      newFiles = [...files, ...uniqueFiles];
    } else {
      newFiles = [...uniqueFiles];
    }

    setFiles(newFiles);

    if (newFiles.length > 0) {
      for (const file of newFiles) {
        if (file.uploadStatus === FileUploadStatusEnum.ReadyToUpload) {
          const reader = new FileReader();
          reader.addEventListener('load', async (t: any) => {
            const request = new XMLHttpRequest();
            request.upload.addEventListener(
              'progress',
              (event) => {
                const progress = Math.round((event.loaded / event.total) * 100);
                setFiles((prevFiles) =>
                  prevFiles.map((x) => {
                    if (x.name === file.name) {
                      x.progress = progress;
                    }
                    return x;
                  }),
                );
              },
              false,
            );
            request.addEventListener(
              'load',
              (event) => {
                if ((event.currentTarget as XMLHttpRequest).status === 200) {
                  setFiles((prevFiles) =>
                    prevFiles.map((x) => {
                      if (x.name === file.name) {
                        x.uploadStatus = FileUploadStatusEnum.Completed;
                        x.progress = 100;
                        x.id = JSON.parse((event.currentTarget as XMLHttpRequest).response);
                      }
                      return x;
                    }),
                  );
                } else {
                  const response = JSON.parse(
                    (event.currentTarget as XMLHttpRequest).responseText || '{}',
                  );
                  setFiles((prevFiles) =>
                    prevFiles.map((x) => {
                      if (x.name === file.name) {
                        x.error =
                          response.ValidationMessage ||
                          response.Error ||
                          'Error occured during file upload.';
                        x.uploadStatus = FileUploadStatusEnum.Failed;
                      }
                      return x;
                    }),
                  );
                }
              },
              false,
            );
            request.open('POST', uploadUrl);
            request.setRequestHeader('Authorization', 'Bearer ' + getCookie(COOKIE_NAME));
            request.setRequestHeader('Content-Type', 'application/json');
            request.send(
              JSON.stringify(
                getRequestBody(
                  getFileDetail(file.type, file.size, file.name, t.currentTarget.result),
                ),
              ),
            );
            setFiles((prevFiles) =>
              prevFiles.map((x) => {
                if (x.name === file.name) {
                  x.uploadStatus = FileUploadStatusEnum.Uploading;
                }
                return x;
              }),
            );
          });
          reader.readAsArrayBuffer(file.file);
        }
      }
    }
  };

  return { onFileAdded };
};

export default useFileUploader;
