import { Flex, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { ModalBackButton } from '../../../../../components/Buttons/ModalBackButton';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';
import { OutpostTheme } from '../../../../../themes/outpost';

interface IProps {
  title: string;
  onBack?: () => void;
  children?: any;
}
export const PatientVitalsTitle = ({ title, onBack, children }: IProps) => (
  <Container>
    <TitleRow>
      {onBack ? (
        <BackButton onClick={onBack}>
          <ChevronIcon icon={['far', 'chevron-left']} size="lg" />
          {title}
        </BackButton>
      ) : (
        <>{title}</>
      )}
      {children}
    </TitleRow>
  </Container>
);

const Container = styled.div`
  margin-bottom: 20px;
  padding-right: 16px;
`;
const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
`;
const BackButton = styled.button`
  border: none;
  background-color: transparent;
  font-weight: 700;
`;
const ChevronIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

export const renderVitalsHeader = (addButton?: any, onBack?: () => void, title?: string) => (
  <>
    <Flex
      align="center"
      marginY={{ base: '0.5rem', md: '1.25rem' }}
      marginX={{ base: '1rem', md: '1.75rem' }}
      pr="8"
    >
      {onBack && renderBackButton(false, onBack)}
      <Text
        mx="auto"
        textTransform="uppercase"
        fontSize={OutpostTheme.FontSizes.body}
        fontWeight="semibold"
      >
        {title}
      </Text>
      {addButton ? addButton() : renderBackButton(true, onBack)}
    </Flex>
  </>
);

const renderBackButton = (hidden: boolean, onBack?: () => void) => (
  <ModalBackButton
    left="unset"
    position="relative"
    visibility={hidden ? 'hidden' : 'visible'}
    onClick={() => onBack && onBack()}
  />
);

export const addButton = (handleClick: () => void, label?: string) => (
  <>
    {/* <IconButton
      aria-label="Calendar Icon"
      p={0}
      variant="ghost"
      border="1px solid #020101"
      borderRadius="full"
      marginRight="1.75rem"
    >
      <CalendarIcon2 w="24px" h="24px" />
    </IconButton> */}

    <PrimaryButton
      borderRadius="full"
      bgColor="#020202"
      _hover={{ bgColor: '#020202E6' }}
      onClick={handleClick}
      fontWeight="semibold"
      w="175px"
      height="45px"
      display={{ base: 'none', md: 'block' }}
    >
      {label ?? 'Add Records'}
    </PrimaryButton>
  </>
);
