import { ICurrentUser } from '../store/currentUser/currentUserReducers';
import { RolesEnum } from '../generated';

export function isInRole(
  currentUser: ICurrentUser | null,
  roles: RolesEnum | RolesEnum[],
): boolean {
  if (!currentUser) {
    return false;
  }

  let rolesNeeded: RolesEnum[] = [];
  if (!Array.isArray(roles)) {
    rolesNeeded.push(roles);
  } else {
    rolesNeeded = [...roles];
  }

  let defaultRoles: RolesEnum[] = [];
  const rolesGranted = new Set(currentUser.roles);
  if (rolesGranted.has(RolesEnum.Patient)) {
    defaultRoles = (process.env.REACT_APP_DEFAULT_PATIENT_ROLES ?? '').split(',') as RolesEnum[];
  } else if (rolesGranted.has(RolesEnum.Provider)) {
    defaultRoles = (process.env.REACT_APP_DEFAULT_PROVIDER_ROLES ?? '').split(',') as RolesEnum[];
  }

  const rolesGrantedWithDefaults = new Set([...currentUser.roles, ...defaultRoles]);
  return rolesNeeded.some((r) => rolesGrantedWithDefaults.has(r));
}
