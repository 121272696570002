import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';

import { CenteredLoadingIndicator } from '../../../../components/LoadingIndicator';
import { HeaderSection } from '../../../../components/Modals';
import ServerValidationBox from '../../../../components/ServerValidationBox';
import { usePartnerSettings } from '../../../../services/partners/usePartnerSettings';

import { ContinueButton } from '../../../heal/patient/BookAppointment/components/common/Buttons';
import { ServiceCard } from '../../../heal/patient/BookAppointment/components/HomeCareServices/components/ServiceCard';

import { useHomeCareServices } from '../../hooks/useHomeCareServices';
import { UsePublicBookingFlowReturnType } from '../../hooks/usePublicBookingFlow';

type HomeCareServicesProps = {
  controller: UsePublicBookingFlowReturnType;
};

const HomeCareServices: FC<HomeCareServicesProps> = (props) => {
  const controller = useHomeCareServices(props.controller);

  const {
    partnerNetworkHomeCareTitle,
    partnerNetworkHomeCareSelect,
    partnerNetworkHomeCareLooking,
  } = usePartnerSettings();

  return (
    <VStack height="100%" alignItems="stretch" spacing="0">
      <HeaderSection
        heading={partnerNetworkHomeCareTitle ?? 'What brings you in today?'}
        containerProps={{
          mb: '3',
        }}
        onGoBack={props.controller.handleGoToPreviousBookingData}
      />

      {controller.tagsError ? (
        <ServerValidationBox message={controller.tagsError} />
      ) : controller.isLoadingTags ? (
        <CenteredLoadingIndicator />
      ) : (
        <Box flex="1" overflowY="auto">
          <HStack justifyContent="center" pb="4">
            <ContinueButton onClick={controller.handleNextStep}>Continue</ContinueButton>
          </HStack>

          <Box flex="1" overflowY="auto">
            <Box maxW={{ md: '65%' }} width="100%" justifyContent="center" mx="auto">
              {controller.submitButtonTouched && controller.serviceNotSelected && (
                <Box mb="2">
                  <Text fontSize="14px" color="rgb(255, 43, 79)" textAlign="center">
                    {partnerNetworkHomeCareSelect ??
                      'You need to select at least one service to continue'}
                  </Text>
                </Box>
              )}
              <Box mb="4">
                <Text color="rgb(0, 3, 16)" mb="1" textAlign="center">
                  {partnerNetworkHomeCareLooking ??
                    'I am looking for a Home care provider that can perform these services'}
                  : (Can select multiple)
                </Text>
              </Box>
              <Box>
                <Flex flexWrap="wrap">
                  {controller.tags?.map((service) => (
                    <Box p="2" key={service.tagID} width={{ base: '100%', sm: '50%' }}>
                      <ServiceCard
                        service={service}
                        selected={controller.isServiceSelected(service)}
                        onSelect={() => controller.onSelectService(service)}
                      />
                    </Box>
                  ))}
                </Flex>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </VStack>
  );
};

export { HomeCareServices };
