import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { ICampaign } from '../../generated';

export const setActions = createAsyncAction(
  ['SET_CURRENT_CAMPAIGN_REQUEST', () => {}],
  ['SET_CURRENT_CAMPAIGN_SUCCESS', (res: ICampaign) => res],
  ['SET_CURRENT_CAMPAIGN_ERROR', (err: string) => err],
)();

export const clearAction = createAction('CLEAR_CURRENT_CAMPAIGN')();

export const clearErrorAction = createAction('CLEAR_CURRENT_CAMPAIGN_ERROR', () => {})();

export type TCurrentCampaignAction = ActionType<
  typeof setActions | typeof clearAction | typeof clearErrorAction
>;
