import styled from 'styled-components';

export const H1 = styled.h1`
  font-size: 48px;
  font-family: 'Inter';
  line-height: 120%;
  margin: 0 0 20px;
`;

export const H2 = styled.h2`
  font-size: 30px;
  font-family: 'Inter';
  line-height: 120%;
  margin: 0 0 20px;
`;

export const H3 = styled.h3`
  font-size: 18px;
  font-family: 'Inter';
  line-height: 120%;
  margin: 0 0 15px;
  font-weight: 500;
`;

export const H4 = styled.h4`
  font-size: 18px;
  line-height: 120%;
  margin: 0 0 10px;
  font-weight: 400;
`;
