import {
  AuthClient,
  IPatient,
  Patient,
  PatientCareTeam,
  PatientCareTeamsClient,
  PatientInsurance,
  PatientInsurancesClient,
  PatientsClient,
  UserDetailRelationship,
  UserDetailsClient,
} from '../../generated';
import { httpRequest } from '../../utils';
import { getLocalStorageItem } from '../../utils/localStorageActions';
import { refreshToken } from '../currentUser/currentUserCreators';
import { AppState, OHThunkResult } from '../root-reducers';
import {
  createPHRDependantActions,
  deletePatientInsuranceActions,
  deletePHRDependantActions,
  getAllPatientCareTeamActions,
  getAllPatientInsuranceActions,
  getAllPHRDependantsActions,
  getOnePatientInsuranceActions,
  getOnePHRDependantActions,
  getPatientActions,
  updatePatientInsuranceActions,
  updatePatientProfileActions,
  updatePHRDependantActions,
} from './patientActions';

export function getPatient(userDetailID = '', patientID?: string): OHThunkResult<Promise<Patient>> {
  return async (dispatch) => {
    return new Promise<Patient>(async (resolve) => {
      dispatch(getPatientActions.request());

      const patientClient = new PatientsClient(new AuthClient());
      try {
        const loginPurpose =
          typeof getLocalStorageItem('loginPurpose') === 'string' &&
          !isNaN(Number(getLocalStorageItem('loginPurpose')))
            ? Number(getLocalStorageItem('loginPurpose'))
            : undefined;

        let result = new Patient();
        if (patientID) {
          result = await httpRequest(() => patientClient.patientsGet(patientID));
        } else {
          result = await httpRequest(() => patientClient.byUserDetailID(userDetailID));
        }
        dispatch(getPatientActions.success({ ...result, loginPurpose }));
        resolve(result);
      } catch (err) {
        dispatch(getPatientActions.failure(err as any));
      }
    });
  };
}

export function updatePatientProfile(data: IPatient): OHThunkResult<Promise<void>> {
  return async (dispatch, getState: () => AppState) => {
    return new Promise<void>(async (resolve) => {
      dispatch(updatePatientProfileActions.request());
      const patient = new Patient();
      patient.init(data);
      const patientsClient = new PatientsClient(new AuthClient());
      try {
        await httpRequest(() => patientsClient.patientsPut(patient));
        if (getState().currentUserState.data?.delegateJWT) {
          dispatch(getPatient(patient.userDetailID));
          dispatch(updatePatientProfileActions.success());
        } else if (getState().patientState.patient) {
          dispatch(refreshToken());
        } else {
          dispatch(updatePatientProfileActions.success());
        }
        resolve();
      } catch (err) {
        dispatch(updatePatientProfileActions.failure(err as any));
      }
    });
  };
}

export function getPatientPHRDependants(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  patientID: string,
): OHThunkResult<Promise<UserDetailRelationship[]>> {
  return async (dispatch) => {
    return new Promise<UserDetailRelationship[]>(async (resolve) => {
      dispatch(getAllPHRDependantsActions.request());
      const phrDependantsClient = new UserDetailsClient(new AuthClient());

      try {
        const result = await httpRequest(() => phrDependantsClient.userDetailRelationships());

        const dependents = result.filter((relationship) => !!relationship.dependant);

        dispatch(getAllPHRDependantsActions.success(dependents));

        resolve(dependents);
      } catch (err) {
        dispatch(getAllPHRDependantsActions.failure(err as any));
      }
    });
  };
}

export function getOnePatientPHRDependant(
  phrDependantID?: string,
): OHThunkResult<Promise<UserDetailRelationship>> {
  return async (dispatch) => {
    return new Promise<UserDetailRelationship>(async (resolve) => {
      dispatch(getOnePHRDependantActions.request());
      const phrDependantsClient = new UserDetailsClient(new AuthClient());
      try {
        const result = await httpRequest(() => phrDependantsClient.userDetailRelationships());
        const dependent = result.find(
          (relationship) => relationship.userDetailRelationshipID === phrDependantID,
        );
        dispatch(getOnePHRDependantActions.success(dependent!));
        if (!dependent) throw new Error('No dependent found');

        resolve(dependent);
      } catch (err) {
        dispatch(getOnePHRDependantActions.failure(err as any));
      }
    });
  };
}

export function updatePhrDependant(
  phrDependant: UserDetailRelationship,
  patient: IPatient | undefined,
): OHThunkResult<Promise<UserDetailRelationship>> {
  return async (dispatch) => {
    return new Promise<UserDetailRelationship>(async (resolve, reject) => {
      dispatch(updatePHRDependantActions.request());
      const phrDependantsClient = new UserDetailsClient(new AuthClient());
      const patientClient = new PatientsClient(new AuthClient());
      const phr = new UserDetailRelationship();
      phr.init(phrDependant);
      let updatedPatient;

      try {
        if (patient) {
          updatedPatient = new Patient();
          updatedPatient.init(patient);
          await httpRequest<string>(() => patientClient.patientsPut(updatedPatient));
        }
        const phrDependantId = await httpRequest<string>(() =>
          phrDependantsClient.userDetailRelationshipPut(phr),
        );
        const result = await dispatch(getOnePatientPHRDependant(phrDependantId));
        dispatch(updatePHRDependantActions.success());
        resolve(result);
      } catch (err) {
        dispatch(updatePHRDependantActions.failure(err as any));
        reject(err);
      }
    });
  };
}

export function createPhrDependant(
  phrDependant: UserDetailRelationship,
): OHThunkResult<Promise<UserDetailRelationship>> {
  return async (dispatch) => {
    return new Promise<UserDetailRelationship>(async (resolve, reject) => {
      dispatch(createPHRDependantActions.request());

      try {
        const client = new UserDetailsClient(new AuthClient());
        const patientRelationship = await httpRequest(() =>
          client.userDetailRelationshipPost(phrDependant),
        );
        dispatch(createPHRDependantActions.success());
        const result = await dispatch(
          getOnePatientPHRDependant(patientRelationship.userDetailRelationshipID),
        );
        resolve(result);
      } catch (err) {
        dispatch(createPHRDependantActions.failure(err as any));
        reject(err);
      }
    });
  };
}

export function deletePhrDependant(patientDependantID: string): OHThunkResult<Promise<void>> {
  return async (dispatch) => {
    return new Promise<void>(async (resolve) => {
      dispatch(deletePHRDependantActions.request());

      try {
        const client = new UserDetailsClient(new AuthClient());
        await httpRequest(() => client.userDetailRelationshipDelete(patientDependantID));
        dispatch(deletePHRDependantActions.success(patientDependantID));
        resolve();
      } catch (err) {
        dispatch(deletePHRDependantActions.failure(err as any));
      }
    });
  };
}

export function getPatientInsurances(
  patientID: string,
): OHThunkResult<Promise<PatientInsurance[]>> {
  return async (dispatch) => {
    return new Promise<PatientInsurance[]>(async (resolve) => {
      dispatch(getAllPatientInsuranceActions.request());
      const patientInsurancesClient = new PatientInsurancesClient(new AuthClient());
      try {
        const result = await httpRequest(() => patientInsurancesClient.byPatientID(patientID));
        dispatch(getAllPatientInsuranceActions.success(result));
        resolve(result);
      } catch (err) {
        dispatch(getAllPatientInsuranceActions.failure(err as any));
      }
    });
  };
}

export function getOnePatientInsurance(
  patientInsurannceID: string,
): OHThunkResult<Promise<PatientInsurance>> {
  return async (dispatch) => {
    return new Promise<PatientInsurance>(async (resolve) => {
      dispatch(getOnePatientInsuranceActions.request());
      const patientInsurancesClient = new PatientInsurancesClient(new AuthClient());
      try {
        const result = await httpRequest(() =>
          patientInsurancesClient.patientInsurancesGet(patientInsurannceID),
        );
        dispatch(getOnePatientInsuranceActions.success(result));
        resolve(result);
      } catch (err) {
        dispatch(getOnePatientInsuranceActions.failure(err as any));
      }
    });
  };
}

export function updatePatientInsurance(
  patientInsurance: PatientInsurance,
): OHThunkResult<Promise<PatientInsurance>> {
  return async (dispatch) => {
    return new Promise<PatientInsurance>(async (resolve, reject) => {
      dispatch(updatePatientInsuranceActions.request());
      const patientInsurancesClient = new PatientInsurancesClient(new AuthClient());

      try {
        const patientDependantID = await httpRequest<string>(() =>
          patientInsurancesClient.patientInsurancesPut(patientInsurance),
        );
        const result = await dispatch(getOnePatientInsurance(patientDependantID));
        dispatch(updatePatientInsuranceActions.success());
        resolve(result);
      } catch (err) {
        dispatch(updatePatientInsuranceActions.failure(err as any));
        reject(err);
      }
    });
  };
}

export function createPatientInsurance(
  patientInsurance: PatientInsurance,
): OHThunkResult<Promise<PatientInsurance>> {
  return async (dispatch) => {
    return new Promise<PatientInsurance>(async (resolve, reject) => {
      dispatch(updatePatientInsuranceActions.request());

      try {
        const client = new PatientInsurancesClient(new AuthClient());
        const patientInsuranceID = await httpRequest(() =>
          client.patientInsurancesPost(patientInsurance),
        );
        dispatch(updatePatientInsuranceActions.success());
        const result = await dispatch(getOnePatientInsurance(patientInsuranceID));
        resolve(result);
      } catch (err) {
        dispatch(updatePatientInsuranceActions.failure(err as any));
        reject(err);
      }
    });
  };
}

export function deletePatientInsurance(patientInsuranceID: string): OHThunkResult<Promise<void>> {
  return async (dispatch) => {
    return new Promise<void>(async (resolve) => {
      dispatch(deletePatientInsuranceActions.request());

      try {
        const client = new PatientInsurancesClient(new AuthClient());
        await httpRequest(() => client.patientInsurancesDelete(patientInsuranceID));
        dispatch(deletePatientInsuranceActions.success(patientInsuranceID));
        resolve();
      } catch (err) {
        dispatch(deletePatientInsuranceActions.failure(err as any));
      }
    });
  };
}

export function getPatientCareTeam(patientID: string): OHThunkResult<Promise<PatientCareTeam[]>> {
  return async (dispatch) => {
    return new Promise<PatientCareTeam[]>(async (resolve) => {
      dispatch(getAllPatientCareTeamActions.request());
      const client = new PatientCareTeamsClient(new AuthClient());
      try {
        const result = await httpRequest(() => client.byPatientID(patientID));
        dispatch(getAllPatientCareTeamActions.success(result));
        resolve(result);
      } catch (err) {
        dispatch(getAllPatientCareTeamActions.failure(err as any));
      }
    });
  };
}
