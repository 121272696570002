import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, Grid, GridItem, Stack, Text, HStack } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { UserIcon } from '../../../../../../../assets/icons';
import { PrimaryButton } from '../../../../../../../components/Buttons';
import { CenteredLoadingIndicator } from '../../../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../../../components/ServerValidationBox';
import { ICountry, IPatient } from '../../../../../../../generated';
import { AppState } from '../../../../../../../store/root-reducers';
import { OutpostTheme } from '../../../../../../../themes/outpost';
import { formatCurrency } from '../../../../../../../utils';

import useCreateOrderContext from '../../hooks/useCreateOrderContext';
import { ChooseDiagnostics } from '../ChooseDiagnostics/ChooseDiagnostics';

import { ItemCard } from '../ItemCard/ItemCard';

interface IProps {
  patient: IPatient;
  country: ICountry;
}

export const FinalizeOrder = ({ patient, country }: IProps) => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  const {
    selectedAddress,
    onSelectLab,
    sendToPatient,
    isRecalculating,
    recalcError,
    patientOrder,
    removeOrderItem,
    isAddressBookInit,
    goToNextStep,
  } = useCreateOrderContext();

  if (!isAddressBookInit) {
    return <CenteredLoadingIndicator />;
  }

  if (recalcError) {
    return <ServerValidationBox message={recalcError} />;
  }

  return (
    <Grid templateColumns={{ base: '1fr', md: '500px 1fr' }} gap={5}>
      <GridItem>
        <Stack spacing={4}>
          <Box
            width="100%"
            px={7}
            py={8}
            borderRadius="0.25rem"
            borderWidth="1px"
            borderColor="#4163CE14"
          >
            <Stack spacing={4}>
              <Text fontSize="12px">Where do you want to send this order?</Text>
              <Flex justify="space-between" direction={['column', 'row']}>
                <Box mb={[2, 0]} w={{ base: '100%', md: selectedAddress ? '100%' : '49%' }}>
                  <ChooseDiagnostics
                    selectedAddress={selectedAddress}
                    setSelectedAddress={onSelectLab}
                    patient={patient}
                    country={country}
                  />
                </Box>
                {!selectedAddress && (
                  <Box mb={[2, 0]} w={['100%', '49%']}>
                    <Flex
                      justify="space-between"
                      align="center"
                      border="1px solid #E4E9F2"
                      borderRadius="5px"
                      height={{ base: '48px', md: '119px' }}
                      paddingX="15px"
                      paddingY={{ base: '20px' }}
                      cursor="pointer"
                      onClick={sendToPatient}
                      _hover={{ backgroundColor: 'purple.100', transition: '.2s ease-in-out' }}
                    >
                      <HStack align="center">
                        <UserIcon color="#3E1191" boxSize={5} />
                        <Text fontSize="12px" color="#3E1191">
                          Patient Only
                        </Text>
                      </HStack>
                      <ChevronRightIcon boxSize={5} color="#3E1191" />
                    </Flex>
                  </Box>
                )}
              </Flex>

              <Text color="gray.600" fontSize="12px">
                A copy of the lab order is always sent to the patient.
              </Text>
            </Stack>
          </Box>

          {selectedAddress && (
            <Flex justifyContent="flex-start">
              <PrimaryButton isLoading={isRecalculating} onClick={goToNextStep}>
                Send Order
              </PrimaryButton>
            </Flex>
          )}
        </Stack>
      </GridItem>
      <GridItem>
        {isRecalculating ? (
          <CenteredLoadingIndicator />
        ) : (
          <>
            <Text fontSize={OutpostTheme.FontSizes.body} fontWeight="500" mb={5}>
              ORDERED TESTS
            </Text>
            <Stack>
              {(patientOrder?.patientOrderItems ?? []).map((x) => (
                <ItemCard
                  key={x.orderItemID}
                  onRemoveOrderItem={() => removeOrderItem(x.orderItemID!)}
                  patientOrderItem={x}
                  Cost={
                    x.totalAmount ? (
                      <Flex mb={4} mt={8} justifyContent="space-between">
                        <Text fontSize="12px" fontWeight="400">
                          Cost
                        </Text>
                        {patientOrder?.invoice ? (
                          <Text fontWeight="400" fontSize={OutpostTheme.FontSizes.body}>
                            {formatCurrency(
                              x.totalAmount,
                              patientOrder.invoice.currencyEnum,
                              currentUser?.cultureInfo,
                            )}
                          </Text>
                        ) : (
                          <></>
                        )}
                      </Flex>
                    ) : (
                      <></>
                    )
                  }
                />
              ))}
              {patientOrder?.invoice && patientOrder?.invoice?.totalAmount > 0 && (
                <Flex
                  py={5}
                  px={4}
                  mt={10}
                  alignItems="center"
                  justifyContent="space-between"
                  backgroundColor="#F7F9FF"
                  borderRadius="0.25rem"
                  borderColor="#4163CE14"
                >
                  <Text fontWeight="400" fontSize="12px">
                    Total
                  </Text>
                  <Text fontWeight="400" fontSize={OutpostTheme.FontSizes.body}>
                    {patientOrder?.invoice &&
                      formatCurrency(
                        patientOrder?.invoice?.totalAmount,
                        patientOrder.invoice.currencyEnum,
                        currentUser?.cultureInfo,
                      )}
                  </Text>
                </Flex>
              )}
            </Stack>
          </>
        )}
      </GridItem>
    </Grid>
  );
};
