import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { SecondaryButton } from '../../../components/Buttons';
import { EmptyNotesCard } from '../notes/EmptyNotesCard';
import { ReferralModal } from './components/ReferralModal';
import { SentReferralsList } from './components/SentReferralsList';

import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import { AuthClient, IPHRConsult, NoteTypeEnum, PHRNote, PHRNotesClient } from '../../../generated';
import { httpRequest } from '../../../utils';

interface IProps {
  consult?: IPHRConsult;
}

const ReferralsComponent = React.memo(({ consult }: IProps) => {
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [referralNotes, setReferralNotes] = useState<PHRNote[]>([]);
  const [referralSentCount, setReferralSentCount] = useState(0);
  const [isLoadingNotes, setIsLoadingNotes] = useState(false);

  const { patientID, phrConsultID } = consult!;

  const toggleModal = () => {
    setShowReferralModal(!showReferralModal);
  };

  const onReferralSent = () => {
    toggleModal();
    setReferralSentCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    const getSentReferrals = async () => {
      setIsLoadingNotes(true);
      try {
        const phrNotes = await httpRequest(() =>
          new PHRNotesClient(new AuthClient()).byPatientID(patientID),
        );
        const referrals = phrNotes.filter(
          (x) => x.phrConsultID === phrConsultID && x.noteTypeEnum === NoteTypeEnum.Referral,
        );
        setReferralNotes(referrals);
      } catch (e) {
      } finally {
        setIsLoadingNotes(false);
      }
    };

    getSentReferrals();
    // Infinite loop issue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralSentCount]);

  if (isLoadingNotes) return <CenteredLoadingIndicator />;

  return (
    <React.Fragment>
      <ReferralModal
        isOpen={showReferralModal}
        onClose={toggleModal}
        consult={consult}
        onReferralSent={onReferralSent}
      />
      <Flex justify="space-between" align="center" mb="40px">
        <Box>
          <Text fontWeight="bold" fontSize="lg">
            Send a referral
          </Text>
          <Text fontSize="sm" color="#04101F94">
            Send your patient to one of the providers in your network
          </Text>
        </Box>
        <SecondaryButton onClick={toggleModal}>Add Referral</SecondaryButton>
      </Flex>

      {referralNotes?.length === 0 ? (
        <EmptyNotesCard
          toggleModal={toggleModal}
          primaryText="No Referrals Created Yet"
          secondaryText="Looks like you haven't added any referrals yet"
        />
      ) : (
        <SentReferralsList referrals={referralNotes} />
      )}
    </React.Fragment>
  );
});

export default ReferralsComponent;
