import moment from 'moment';
import styled from 'styled-components';
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton';
import Card from '../../../components/Card';
import { Device, SpacingRelated, TimeFormatEnum } from '../../../constants';

export const StyledCard = styled(Card)`
  margin: 0 0 1rem 0;
  padding: 1rem;
`;

export const FormCard = styled(Card)`
  margin: 1rem 0;

  > div {
    max-height: none;
  }
`;

interface IItemNameProps {
  readonly open: boolean;
}

export const ItemNameRow = styled.div<IItemNameProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${SpacingRelated.HalfRegular};
  margin-bottom: ${SpacingRelated.HalfRegular};
  border-bottom: ${(props) =>
    props.open ? '1px solid transparent' : '1px solid ' + props.theme.Colors.LightGray};
  padding-right: 50px;
  transition: border-bottom 0.3s;
`;

interface IEndDateProps {
  endDate?: Date;
}
export const EndDate = ({ endDate }: IEndDateProps) =>
  endDate ? (
    <Value>{moment(endDate).format(TimeFormatEnum.LONG_DATE)}</Value>
  ) : (
    <OnGoingValue>on-going</OnGoingValue>
  );

export const PreviewItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ItemName = styled.p`
  font-weight: 500;
  font-size: 18px;
  color: ${(props) => props.theme.FontColors.Primary};
`;

export const Badge = styled.span`
  font-size: 14px;
`;

export const Details = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 4px 0;
`;

interface ILabelProps {
  readonly basis?: number;
}

export const Label = styled.p<ILabelProps>`
  font-size: 14px;
  flex-basis: ${(props) => (props.basis ? props.basis : 165)}px;
  font-weight: 500;
`;

export const Value = styled.div`
  font-size: 14px;
  flex-grow: 1;
  color: ${(props) => props.theme.FontColors.Secondary};
`;

export const OnGoingValue = styled(Value)`
  text-transform: uppercase;
  color: ${(props) => props.theme.Colors.CallToAction};
`;

export const ToggleElement = styled.div`
  position: relative;
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column-reverse;
  margin: 10px 0;

  @media ${Device.tablet} {
    flex-direction: row;
  }

  button {
    margin-top: 10px;
  }

  @media ${Device.tablet} {
    button {
      margin-top: 0px;
    }
    button:not(:first-child) {
      margin-left: 10px;
    }
  }
`;

export const EmptyCategoryCard = styled(Card)`
  height: 300px;
  display: flex;
  background: #f4f4f4;
  justify-content: center;
  align-items: center;
`;

interface IPrimaryButtonSelectProps {
  readonly active?: boolean;
}

export const PrimaryButtonSelect = styled(PrimaryButton)<IPrimaryButtonSelectProps>`
  background-color: ${(props) =>
    props.active ? props.theme.Colors.Primary : props.theme.Colors.White};
  color: ${(props) => (props.active ? props.theme.Colors.White : props.theme.FontColors.Primary)};
`;

export const ChartButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;

  @media ${Device.tablet} {
    flex-direction: row;
  }

  button:not(:last-of-type) {
    margin-right: 0.5rem;
  }
`;

export const ChartContainer = styled.div`
  position: relative;
  margin: 0 auto;
  min-height: 400px;
  height: calc(100% - 310px);
  width: calc(100% - 20px);
`;

export const BoundaryWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;

  p {
    margin-right: 0.5rem;
  }

  @media ${Device.tablet} {
    margin-top: 0;
  }
`;

export const UnitWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const NoItems = styled.div`
  display: flex;
  justify-content: center;
  font-style: italic;
`;
