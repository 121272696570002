import { Field, useFormikContext } from 'formik';
import React from 'react';
import InputMask, { Props as ReactInputMaskProps } from 'react-input-mask';
import { FormInputControlProps, InputControl } from './InputControl';

export type FormMaskedInputControlProps = FormInputControlProps & ReactInputMaskProps;

export const FormMaskedInputControl = (props: FormMaskedInputControlProps) => {
  const { name, formControlProps, ...rest } = props;
  const { setFieldValue } = useFormikContext();

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <InputMask
          {...rest}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(name, e.target.value)}
          value={field.value}
        >
          {/* @ts-ignore */}
          {(inputProps: any) => (
            /* @ts-ignore */
            <InputControl
              formControlProps={{
                ...meta,
                ...formControlProps,
              }}
              {...inputProps}
              {...rest}
            />
          )}
        </InputMask>
      )}
    </Field>
  );
};
