import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../components/Modals/Modal';
import { PHRDocument, TagTypeEnum } from '../../../generated';
import { useTagsAllByTagType } from '../../../services/tags/queries/useTagsAllByTagType';
import { clearPHRPatientErrorAction } from '../../../store/patient/phr/phrActions';
import { deleteForPhrSection } from '../../../store/patient/phr/phrCreators';
import { IPHRPatientEnhanced } from '../../../store/patient/phr/phrReducers';
import { phrDocumentsSelector } from '../../../store/patient/phr/phrSelectors';
import { getAllForPhrSection } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { scrollToTop } from '../../../utils/scrollToTop';
import DocumentsAddForm from './DocumentsAddForm';
import DocumentsEditForm from './DocumentsEditForm';
import DocumentsList from './DocumentsList';

interface IProps {
  patient: IPHRPatientEnhanced;
  renderHeader: (addButton?: any) => any;
}
export interface ITagSelect {
  value?: string;
  label?: string;
  color?: string;
}

const PHRDocumentsComponent = ({ patient, renderHeader }: IProps) => {
  const dispatch = useDispatch();
  const phrDocuments = phrDocumentsSelector(patient.phrDocuments);
  const currentUser = useSelector((state: AppState) => state.currentUserState.data!);
  const [recordToDelete, setRecordToDelete] = useState<string>('');
  const [currentDocument, setCurrentDocument] = useState<PHRDocument | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);

  useEffect(() => {
    if (patient) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRDocuments, patient.patientID));
    }
  }, [dispatch, patient]);

  const onCreate = () => {
    setCurrentDocument(null);
    setShowForm(true);
  };

  const onEdit = (phrDocument: PHRDocument) => {
    setCurrentDocument(phrDocument);
    setShowForm(true);
  };

  const onDelete = (phrHealthConditionID: string) => {
    setShowDeleteModal(true);
    setRecordToDelete(phrHealthConditionID);
  };

  const confirmDelete = async () => {
    setShowDeleteModal(false);
    dispatch(
      deleteForPhrSection(
        ReduxPHRCategoryEnum.PHRDocuments,
        recordToDelete,
        patient.patientID,
        false,
      ),
    );
  };

  const { tags } = useTagsAllByTagType(TagTypeEnum.DocumentType);
  const [listOfTags, setListOfTags] = useState<ITagSelect[]>();

  useEffect(() => {
    if ((tags ?? []).length > 0) {
      const arr = tags!
        .filter((x) => x.externalID === '3')
        .map((tag) => {
          return {
            label: tag.description,
            value: tag.tagID,
            color: tag.color,
          };
        });
      setListOfTags(arr);
    }
  }, [tags]);

  useEffect(() => {
    dispatch(clearPHRPatientErrorAction());
    scrollToTop();
  }, [dispatch, showForm]);

  return (
    <>
      {showForm ? renderHeader() : renderHeader(onCreate)}
      {showForm ? (
        currentDocument ? (
          <DocumentsEditForm
            currentPhrDocument={currentDocument}
            toggleView={() => setShowForm(false)}
            patientID={patient.patientID}
            tags={listOfTags!}
          />
        ) : (
          <DocumentsAddForm
            patientID={patient.patientID}
            onEdit={onEdit}
            toggleView={() => setShowForm(false)}
          />
        )
      ) : (
        <DocumentsList
          currentUser={currentUser}
          onEdit={onEdit}
          onDelete={onDelete}
          phrDocuments={phrDocuments}
          tags={listOfTags!}
        />
      )}
      <Modal
        isOpen={showDeleteModal}
        title="Delete Document?"
        onDismiss={() => setShowDeleteModal(false)}
        onCancel={() => setShowDeleteModal(false)}
        onSubmit={confirmDelete}
        cancelText="Cancel"
        submitText="Yes"
      >
        <p>Are you sure you want to delete this document?</p>
      </Modal>
    </>
  );
};

export default PHRDocumentsComponent;
