import { AuthClient, PatientPrescriptionsClient } from '../../../../generated';
import { httpRequest } from '../../../../utils';

const patientPrescriptionsGet = async (patientPrescriptionID: string) => {
  const prescriptionsClient = new PatientPrescriptionsClient(new AuthClient());

  const patientPrescription = await httpRequest(() =>
    prescriptionsClient.patientPrescriptionsGet(patientPrescriptionID),
  );

  return patientPrescription;
};

export { patientPrescriptionsGet };
