import { useQuery } from 'react-query';
import { AuthClient, PatientFavoritesClient, PatientFavorite } from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

interface IProps {
  patientID?: string;
}

export const usePatientPreferredLabGet = ({ patientID }: IProps) => {
  const {
    isLoading: isLoadingPreferredLab,
    data: preferredLab,
    error: preferredLabError,
  } = useQuery<PatientFavorite, string>(
    [QueryKeys.PatientPreferredLab, patientID],
    async () =>
      httpRequest(() => new PatientFavoritesClient(new AuthClient()).preferredLabGet(patientID!)),
    { enabled: !!patientID },
  );

  return {
    isLoadingPreferredLab,
    preferredLab,
    preferredLabError,
  };
};
