import { useCallback, useState } from 'react';

interface IProps {
  totalCount: number;
  searchText?: string;
  recordsToTake?: number;
  initialPage?: number;
}

const useInfiniteScroll = ({ totalCount, recordsToTake = 10 }: IProps) => {
  const [recordsToSkip, setRecordsToSkip] = useState<number>(recordsToTake * 0);
  const totalPages = Math.ceil(totalCount / recordsToTake);
  const currentPage = totalPages - Math.ceil((totalCount - recordsToSkip) / recordsToTake);

  const goToPage = useCallback(
    (page: number) => {
      setRecordsToSkip(recordsToTake * (page + 1));
    },
    [recordsToTake],
  );

  return { recordsToTake, recordsToSkip, totalPages, currentPage, goToPage };
};

export default useInfiniteScroll;
