import jstz from 'jstimezonedetect';
import moment from 'moment';
import { TimeZone } from '../generated';

export const selectNearestTimeZone = async (timeZones: TimeZone[]) => {
  const tz = jstz.determine().name();
  let nearestTimeZone = timeZones.find((x) => x.timeZoneID === tz);
  if (!nearestTimeZone) {
    // Fallback
    nearestTimeZone = timeZones.find((x) => x.utcOffSetMinutes === moment().utcOffset());
  }
  return nearestTimeZone;
};
