// QueryKey Naming Convention: [Controller][FunctionNameFromClient]
export enum QueryKeys {
  AddressBooksCollection = 'AddressBooksCollection',
  AddressBooksGlobal = 'AddressBooksGlobal',
  AddressBooksPrivate = 'AddressBooksPrivate',
  AddressBooksSearch = 'AddressBooksSearch',
  SubscriptionsAvailableByCountry = 'SubscriptionsAvailableByCountry',
  SubscriptionsGet = 'SubscriptionsGet',
  SubscriptionsSubscribedByUserDetail = 'SubscriptionsSubscribedByUserDetail',
  ProviderBanksByProviderID = 'ProviderBanksByProviderID',
  ProviderBanksPut = 'ProviderBanksPut',
  ProviderBanksPost = 'ProviderBanksPost',
  CountriesAll = 'CountriesAll',
  StoresByProviderID = 'StoresByProviderID',
  StoresByStoreID = 'StoresByStoreID',
  TaskItemsSearch = 'TaskItemsSearch',
  TaskItemsDownload = 'TaskItemsDownload',
  TaskItemsGet = 'TaskItemsGet',
  StoreItemsGetByStoreID = 'StoreItemsGetByStoreID',
  PatientInsurancesByPatientID = 'PatientInsurancesByPatientID',
  ProviderStoreItemsByProviderID = 'ProviderStoreItemsByProviderID',
  ProviderSchedulesSearch = 'ProviderSchedulesSearch',
  PatientsRecentPatientDetails = 'PatientsRecentPatientDetails',
  PatientsProviderAvailabilityNotificationPatients = 'PatientsProviderAvailabilityNotificationPatients',
  ProvinceStatesByCountryID = 'ProvinceStatesByCountryID',
  CityTownsByProvinceStateID = 'CityTownsByProvinceStateID',
  TagsAll = 'TagsAll',
  TagsAllByTagType = 'TagsAllByTagType',
  VersionApiVersion = 'VersionApiVersion',
  TimeZonesAll = 'TimeZonesAll',
  LanguagesAll = 'LanguagesAll',
  WalletsMyWallets = 'WalletsMyWallets',
  WalletsMyWalletsAggregate = 'WalletsMyWalletsAggregate',
  UserDetailsUserDetailsRelationships = 'UserDetailsUserDetailsRelationships',
  UserDetailsAvailableUserDetailRelationships = 'UserDetailsAvailableUserDetailRelationships',
  ProviderAppointmentsGet = 'ProviderAppointmentsGet',
  ProviderAppointmentsSearch = 'ProviderAppointmentsSearch',
  WalletsWalletDetails = 'WalletsWalletDetails',
  WalletsMyWallet = 'WalletsMyWallet',
  StoresWalletStoreByCountryID = 'StoresWalletStoreByCountryID',
  SystemSettingsName = 'SystemSettingsName',
  FrequentlyAskedQuestionsByCountryID = 'FrequentlyAskedQuestionsByCountryID',
  FrequentlyAskedQuestionsSearch = 'FrequentlyAskedQuestionsSearch',
  PatientsInsuranceProviderRequired = 'PatientsInsuranceProviderRequired',
  InsuranceProvidersByCountryID = 'InsuranceProvidersByCountryID',
  ReferralCenterInviteDetailsByProviderID = 'ReferralCenterInviteDetailsByProviderID',
  PatientsGet = 'PatientsGet',
  PatientsSearch = 'PatientsSearch',
  ConsultsWaitingRoomPatients = 'ConsultsWaitingRoomPatients',
  PatientPrescriptionsByQRCode = 'PatientPrescriptionsByQRCode',
  StatementsCurrentStatements = 'StatementsCurrentStatements',
  StatementsSearch = 'StatementsSearch',
  StatementsByStatementID = 'StatementsByStatementID',
  StatementsByAllID = 'StatementsByAllID',
  PayoutsByID = 'PayoutsByID',
  PayoutCurrencies = 'PayoutCurrencies',
  PatientOrdersByQRCode = 'PatientOrdersByQRCode',
  PatientOrdersSearch = 'PatientOrdersSearch',
  PhrConsultsPreviousConsults = 'PhrConsultsPreviousConsults',
  PhrConsultsByPreceptor = 'PhrConsultsByPreceptor',
  OrdersAll = 'OrdersAll',
  PHRDocumentsDownload = 'PHRDocumentsDownload',
  OrderByID = 'OrderByID',
  ProviderTypeTags = 'ProviderTypeTags',
  ProviderSpecialtiesTags = 'ProviderSpecialtiesTags',
  LicenseTags = 'LicenseTags',
  FacilitySpecializationTags = 'FacilitySpecializationTags',
  FacilityRoleTags = 'FacilityRoleTags',
  FacilityNumberOfBedTags = 'FacilityNumberOfBedTags',
  ProviderOnboardingByUserDetailID = 'ProviderOnboardingByUserDetailID',
  LicenseCountryTags = 'LicenseCountryTags',
  Locums = 'Locums',
  PatientDependantsByPatientID = 'PatientDependantsByPatientID',
  PHRDocumentsByAssociatedID = 'PHRDocumentsByAssociatedID',
  AVailableWalkInProviders = 'AVailableWalkInProviders',
  ConsultationTypeTags = 'ConsultationTypeTags',
  ProviderCard = 'ProviderCard',
  ProviderLanguages = 'ProviderLanguages',
  RecurringPatients = 'RecurringPatients',
  ConsultSearch = 'ConsultSearch',
  ConsultBrowseCategory = 'ConsultBrowseCategory',
  PatientDasboard = 'PatientDasboard',
  UrgentCareStoreItems = 'UrgentCareStoreItems',
  ProviderWalkinAvailability = 'ProviderWalkinAvailability',
  PatientPreferredPharmacy = 'PatientPreferredPharmacy',
  FavoriteAddressBookInvitesGet = 'FavoriteAddressBookInvitesGet',
  MyFavoriteAddressBookInvitesGet = 'MyFavoriteAddressBookInvitesGet',
  PatientPreferredLab = 'PatientPreferredLab',
  StoreSchedulesSearch = 'StoreSchedulesSearch',
  StoreAppointmentsSearch = 'StoreAppointmentsSearch',
  StoreItemsByID = 'StoreItemsByID',
  StoreAppointmentsGet = 'StoreAppointmentsGet',
  SessionGet = 'SessionGet',
  SingleProviderStoreItem = 'SingleProviderStoreItem',
  SearchByPublicTag = 'SearchByPublicTag',
  ProviderAssignTestPatientsGet = 'ProviderAssignTestPatientsGet',
  SubscriptionsStoreItems = 'SubscriptionsStoreItems',
  Provider = 'Provider',
  StoreItemsSearch = 'StoreItemsSearch',
  InvoiceShoppingCartGet = 'InvoiceShoppingCartGet',
  UserDetailGet = 'UserDetailGet',
  PatientsGetByUserDetailID = 'PatientsGetByUserDetailID',
  AddressBookByStoreID = 'AddressBookByStoreID',
  ReferralQRCode = 'ReferralQRCode',
  ProvidersSymptomsTags = 'ProvidersSymptomsTags',
  AvailableConsultTypes = 'AvailableConsultTypes',
  TagsByID = 'TagsByID',
  EStoresByCountryID = 'EStoresByCountryID',
  EStoresOrdersByStoreID = 'EStoresOrdersByStoreID',
  ActiveWalletCard = 'ActiveWalletCard',
  WalletQRCode = 'WalletQRCode',
  UserDetailsUserDetailsSponsors = 'UserDetailsUserDetailsSponsors',
}
