import { useEffect, useState } from 'react';
import reactGA4 from 'react-ga4';
import ttiPolyfill from 'tti-polyfill';
import { AuthClient, SystemSettingsClient } from '../generated';
import { ICurrentUser } from '../store/currentUser/currentUserReducers';

interface IProps {
  currentUser: ICurrentUser | null;
}

export const timing = ({ category, variable, value }) => {
  if (category && variable && value) {
    reactGA4.event({
      category,
      action: variable,
      value,
    });
  }
};

export const pageview = (url) => {
  if (url) {
    reactGA4.send({
      hitType: 'pageview',
      page: url,
    });
  }
};

const useGoogleAnalytics = ({ currentUser }: IProps) => {
  const [isInitialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    const loadGoogleAnalytics = async () => {
      const systemSettingsClient = new SystemSettingsClient(new AuthClient());
      try {
        const gaID = await systemSettingsClient.name('GoogleAnalyticsID');
        if (gaID.value) {
          reactGA4.initialize(gaID.value);
          setInitialized(true);
          const callback = (list) => {
            list.getEntries().forEach((entry) => {
              timing({
                category: 'Load Performance',
                variable: 'Server Latency',
                value: entry.responseStart - entry.requestStart,
              });
              timing({
                category: 'Load Performance',
                variable: 'Download time',
                value: entry.responseEnd - entry.responseStart,
              });
              timing({
                category: 'Load Performance',
                variable: 'Total app load time',
                value: entry.responseEnd - entry.requestStart,
              });
            });
          };

          const observer = new PerformanceObserver(callback);
          observer.observe({ entryTypes: ['navigation'] });

          ttiPolyfill.getFirstConsistentlyInteractive().then((tti) => {
            timing({
              category: 'Load Performance',
              variable: 'Time to Interactive',
              value: tti,
            });
          });

          const appRenderPerformanceObserver = new PerformanceObserver((list) => {
            list.getEntries().forEach((entry) => {
              if (entry.name.includes('App')) {
                if (entry.name && entry.duration) {
                  timing({
                    category: 'App Render Performance',
                    variable: entry.name,
                    value: entry.duration,
                  });
                }
              }
            });
          });
          appRenderPerformanceObserver.observe({ entryTypes: ['mark', 'measure'] });
        }
      } catch (err) {
        console.log('Unable to Initialize Google Analytics', err);
      }
    };

    if (currentUser && !isInitialized) {
      loadGoogleAnalytics();
    }
  }, [currentUser, isInitialized]);
};

export default useGoogleAnalytics;
