import { Box, Flex, HStack, Link, StackProps, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { SecondaryButton } from '../../../../components/Buttons';
import { Avatar } from '../../../../components/common/Avatar';
import { ThumbnailTypeEnum } from '../../../../constants';
import useTranslationComponent from '../../../../hooks/useTranslationComponent';
import { usePartnerSettings } from '../../../../services/partners/usePartnerSettings';
import { OutpostTheme } from '../../../../themes/outpost';
import { getStoreGoogleMapUrl } from '../../../../utils/getStoreGoogleMapUrl';
import { getUserThumbnail } from '../../../../utils/getUserThumbnail';

import { SortStoreType } from '../../../heal/patient/BookAppointment/components/ProviderSummary/utils/sortStores';

type ProviderStoresProps = {
  providerStoreItems?: SortStoreType[];
  onViewAppointment?: (storeIndex: number) => void;
  hStackProps?: StackProps;
};

const ProviderStores: FC<ProviderStoresProps> = (props) => {
  const { t } = useTranslationComponent(['network']);
  const { partnerBookingAppointmentsClinic } = usePartnerSettings();

  return (
    <Flex flexWrap="wrap" width={{ base: '100%', md: '75%' }} mx="auto">
      {props.providerStoreItems?.map((storeItem, index) => (
        <HStack
          spacing="3"
          // p="3"
          // width={{ base: '100%', md: '75%' }}
          alignItems="flex-start"
          key={storeItem.store.storeID}
          {...props.hStackProps}
        >
          <Box position="relative">
            <Avatar
              size={OutpostTheme.Avatar.Size.Large}
              src={getUserThumbnail(storeItem.store.imageDetailID, ThumbnailTypeEnum.Clinic)}
            />
          </Box>
          <Box flex="1">
            <Box mb="2">
              <Text
                fontSize="12px"
                fontWeight="medium"
                color="rgb(65, 79, 101)"
                mb="1"
                textTransform="uppercase"
              >
                {t(partnerBookingAppointmentsClinic ?? 'CLINIC')}
              </Text>
              <Text fontSize="14px" fontWeight="bold" color="rgb(0, 3, 16)" mb="2">
                {storeItem.store.name}
              </Text>
              <Text fontSize="12px" color="rgb(0, 3, 16)">
                {storeItem.store.fullAddress}
              </Text>
              {getStoreGoogleMapUrl(
                storeItem.store.fullAddress,
                storeItem.store.longitude,
                storeItem.store.latitude,
              ) && (
                <Link
                  fontSize="14px"
                  color="rgb(0, 3, 16)"
                  textDecoration="none"
                  textAlign="center"
                  fontWeight="bold"
                  _hover={{
                    textDecoration: 'underline',
                  }}
                  _active={{
                    textDecoration: 'underline',
                  }}
                  _focus={{
                    textDecoration: 'underline',
                  }}
                  isExternal
                  href={getStoreGoogleMapUrl(
                    storeItem.store.fullAddress,
                    storeItem.store.longitude,
                    storeItem.store.latitude,
                  )}
                >
                  {t('See Map')}
                </Link>
              )}
            </Box>
            {props.onViewAppointment && (
              <SecondaryButton
                minW="100%"
                size="sm"
                onClick={() => (props.onViewAppointment ? props.onViewAppointment(index) : null)}
              >
                {t('View Appointments')}
              </SecondaryButton>
            )}
          </Box>
        </HStack>
      ))}
    </Flex>
  );
};

export { ProviderStores };
