import { CountryDefaultStoreDetails, StoreTypeEnum } from '../../../../generated';

type GetCountryDefaultPharmacyDetails = {
  countryID: string;
  storeTypeEnum: StoreTypeEnum;
};

const getCountryDefaultPharmacyDetails = (props: GetCountryDefaultPharmacyDetails) => {
  const countryDefaultStoreDetails = new CountryDefaultStoreDetails();
  countryDefaultStoreDetails.countryID = props.countryID;
  countryDefaultStoreDetails.storeTypeEnum = StoreTypeEnum.Pharmacy;

  return countryDefaultStoreDetails;
};

export { getCountryDefaultPharmacyDetails };
