import { Box, Center, Flex, IconButton, Text, useBreakpointValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { BinIcon, EditIcon } from '../../../../assets/icons';
import { DeleteButton, PrimaryButton } from '../../../../components/Buttons';
import { ModalBackButton } from '../../../../components/Buttons/ModalBackButton';
import { RolesEnum } from '../../../../generated';
import { AppState } from '../../../../store/root-reducers';
import { isInRole } from '../../../../utils/isInRole';

export const ConsultDetailsHeader = ({ onBack, currentConsult, onEdit, onDelete }) => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const showBtn = useBreakpointValue({ md: true, base: false });
  const provider = useSelector((state: AppState) => state.providerState.provider);

  return (
    <Flex justify="space-between" align="center" p={4}>
      <ModalBackButton left="unset" position="relative" onClick={() => onBack()} />
      <Text ml={4} variant="body" textTransform={'uppercase'}>
        Consult Notes
      </Text>

      {isInRole(currentUser, RolesEnum.Provider) ? (
        <Flex
          marginLeft="auto"
          marginRight={{ base: '1.5rem', md: '2.5rem' }}
          justify="flex-end"
          align="center"
          alignSelf="flex-start"
          position="sticky"
          bottom="-1px"
        >
          {(currentConsult.phrDocuments?.length ?? 0) === 0 &&
            !currentConsult.consultID &&
            currentConsult.providerID === provider?.providerID && (
              <>
                {showBtn ? (
                  <DeleteButton
                    fontWeight="normal"
                    marginLeft="1rem"
                    onClick={() => onDelete(currentConsult.phrConsultID)}
                  >
                    Delete
                  </DeleteButton>
                ) : (
                  <IconButton
                    aria-label="close"
                    p={0}
                    variant="ghost"
                    borderRadius="full"
                    onClick={() => onDelete(currentConsult.phrConsultID)}
                  >
                    <Center width="24px" height="24px" border="1px solid red" borderRadius="full">
                      <BinIcon stroke="red" />
                    </Center>
                  </IconButton>
                )}
              </>
            )}

          {(currentConsult.providerID === provider?.providerID ||
            currentConsult.attestationUserDetailID === provider?.userDetailID) && (
            <>
              {showBtn ? (
                <PrimaryButton
                  borderRadius="full"
                  bgColor="#020202"
                  _hover={{ bgColor: '#020202CC' }}
                  onClick={() => onEdit(currentConsult)}
                >
                  Edit
                </PrimaryButton>
              ) : (
                <IconButton
                  aria-label="close"
                  p={0}
                  variant="ghost"
                  borderRadius="full"
                  onClick={() => onEdit(currentConsult)}
                >
                  <Center alignItems="center" justifyContent="center">
                    <EditIcon width="20px" height="20px" />
                  </Center>
                </IconButton>
              )}
            </>
          )}
        </Flex>
      ) : (
        <Box />
      )}
    </Flex>
  );
};
