import React from 'react';

import { RadioControl } from '../../../../components/forms';

interface IAllergyRadioControl {
  value: string;
  name: string;
}

const AllergyRadioControl = ({ value, name }: IAllergyRadioControl) => {
  return (
    <RadioControl
      value={value}
      name={name}
      border="1px solid"
      borderColor="#C5CEE0"
      backgroundColor="#E4E9F2"
      _checked={{
        border: '1.5px solid',
        borderColor: '#789FFE',
        _before: {
          content: '""',
          display: 'inline-block',
          position: 'relative',
          width: 2.5,
          height: 2.5,
          backgroundColor: '#3E1191',
          borderRadius: '50%',
        },
      }}
      _focus={{
        outline: 'none',
      }}
    >
      {value}
    </RadioControl>
  );
};

export default AllergyRadioControl;
