import { AddressBookFolderEnum } from '../../../generated';

export const getAddressBookFolderName = (folder: AddressBookFolderEnum) => {
  switch (folder) {
    case AddressBookFolderEnum.Clinic:
      return 'Clinic';
    case AddressBookFolderEnum.Organization:
      return 'Hospital';
    case AddressBookFolderEnum.Lab:
      return 'Diagnostic Center';
    case AddressBookFolderEnum.Pharmacy:
      return 'Pharmacy';
    case AddressBookFolderEnum.Other:
      return 'Other';
    case AddressBookFolderEnum.Provider:
      return 'Provider';
    default:
      return folder;
  }
};
