import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { AlertIcon } from '../../../../../../../../assets/icons';
import { PurpleButton } from '../../../../../../../../components/Buttons';
import { FormCheckboxControl, FormTextArea } from '../../../../../../../../components/forms';
import { CenteredLoadingIndicator } from '../../../../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../../../../components/ServerValidationBox';
import { PatientOrder, PatientOrderQuestion } from '../../../../../../../../generated';
import { useQuestionair } from '../../../contexts/CreateOrderContext/useQuestionair/useQuestionair';
import { Label } from './common/Label';
import { OtherForm } from './questionairFormTypes';

export const OtherQuestionair = () => {
  const { patientOrder, onSubmitForm, isLoading, error } = useQuestionair();

  return (
    <Formik
      initialValues={
        {
          reasonForExam: patientOrder?.reasonForExam ?? '',
          pregnant: patientOrder?.patientOrderQuestion?.pregnant,
        } as OtherForm
      }
      validationSchema={object({
        reasonForExam: string().max(1024, 'Reason for Exam must be at most 1024 characters'),
      })}
      onSubmit={async (values: OtherForm) => {
        const patientOrderQuestion = new PatientOrderQuestion();
        patientOrderQuestion.init(patientOrder?.patientOrderQuestion);
        const po = new PatientOrder();
        po.init(patientOrder);
        po.patientOrderQuestion = patientOrderQuestion;
        po.reasonForExam = values.reasonForExam;
        po.patientOrderQuestion.pregnant = values.pregnant;

        await onSubmitForm(po);
      }}
    >
      {({ values }) => (
        <Form>
          {isLoading ? (
            <CenteredLoadingIndicator />
          ) : (
            <Stack spacing="5">
              <ServerValidationBox message={error} />

              <Text fontSize="12px" fontWeight="semibold">
                Screening Questions - Answer All
              </Text>

              <Box>
                <Label mb="5">
                  Does The Patient Have Any Of The Following (Check All That Apply)
                </Label>
                <Box>
                  <FormCheckboxControl name="pregnant">
                    <Text fontSize="sm">Currently Pregnant</Text>
                  </FormCheckboxControl>
                </Box>
                {values.pregnant && (
                  <Flex mt={8}>
                    <AlertIcon fontSize="24px" color="#EA1536" />
                    <Text ml={2} color="#EA1536" fontSize="sm">
                      If the Patient has any of these, Test may be contraindicated. Please proceed
                      with caution and verify safety with the Consulting Radiologist.
                    </Text>
                  </Flex>
                )}
              </Box>

              <FormTextArea
                maxLength={250}
                name="reasonForExam"
                label="Reason for Exam"
                formControlProps={{
                  mb: '0',
                }}
              />

              <Flex justifyContent="flex-start">
                <PurpleButton type="submit" width={{ base: '100%', sm: 'auto' }}>
                  {values.pregnant ? 'Ignore Warnings & Proceed' : 'Continue'}
                </PurpleButton>
              </Flex>
            </Stack>
          )}
        </Form>
      )}
    </Formik>
  );
};
