import { useInterval } from '@chakra-ui/react';
import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CampaignRoutes, HealRoutes, MessageCenterRoutes } from '../constants';
import { AuthClient, MarketingCampaignsClient, RolesEnum } from '../generated';
import { getActiveMarketingCampaigns } from '../store/activeMarketingCampaigns/activeMarketingCampaignsCreators';
import { ICurrentUser } from '../store/currentUser/currentUserReducers';
import { getPatient } from '../store/root-creator';
import { AppState } from '../store/root-reducers';
import { getShoppingCartItemCount } from '../store/shoppingCartItemCount/getShoppingCartItemCountAction';
import { getUnacknowledgedRewardCount } from '../store/unacknowledgedRewardCount/getUnacknowledgedRewardCountAction';
import { getUnreadMailMessageCount } from '../store/unreadMailMessageCount/getUnreadMailMessageCountAction';
import { httpRequest } from '../utils';
import { createRoute } from '../utils/createRoute';
import { isInRole } from '../utils/isInRole';

interface INotification {
  count: number | null;
  handleClick: () => void;
  show?: boolean;
}

export interface INotifications {
  reward: INotification;
  mail: INotification;
  cart: INotification;
}

interface IProps {
  currentUser: ICurrentUser | null;
}

const useNotification = ({ currentUser }: IProps): INotifications => {
  const dispatch = useDispatch();
  const patient = useSelector((state: AppState) => state.patientState.patient);
  const activeMarketingCampaigns = useSelector(
    (state: AppState) => state.activeMarketingCampaignsState.activeMarketingCampaigns,
  );
  const unreadMailMessageCount = useSelector(
    (state: AppState) => state.unreadMailMessageCountState.count,
  );
  const shoppingCartItemCount = useSelector(
    (state: AppState) => state.shoppingCartItemCountState.count,
  );
  const unacknowledgedRewardCount = useSelector(
    (state: AppState) => state.unacknowledgedRewardCountState.count,
  );

  // TODO: multiple campaigns support
  const [currentMarketingCampaignID, setCurrentMarketingCampaignID] = useState<string>('');

  // load active campaigns
  useEffect(() => {
    if (isInRole(currentUser, RolesEnum.Patient)) {
      // TODO: add provider cases when we're able to determine if the campaign is for a patient or provider.
      if (!patient) {
        if (currentUser) {
          dispatch(getPatient(currentUser.userDetailID));
        }
      } else {
        dispatch(getActiveMarketingCampaigns(patient.countryID));
      }
    }
  }, [patient, dispatch, currentUser]);

  useEffect(() => {
    if (activeMarketingCampaigns.length > 0) {
      setCurrentMarketingCampaignID(activeMarketingCampaigns[0]?.marketingCampaignID);
    }
  }, [activeMarketingCampaigns]);

  // load shopping cart item count
  useEffect(() => {
    if (shoppingCartItemCount === null) {
      dispatch(getShoppingCartItemCount());
    }
  }, [dispatch, shoppingCartItemCount, currentUser]);

  useInterval(() => dispatch(getShoppingCartItemCount()), 60000);

  // load Unacknowledged Reward Count
  useEffect(() => {
    if (currentMarketingCampaignID && unacknowledgedRewardCount === null) {
      dispatch(getUnacknowledgedRewardCount(currentMarketingCampaignID));
    }
  }, [dispatch, unacknowledgedRewardCount, currentMarketingCampaignID]);

  // load unread mail message count
  useEffect(() => {
    if (unreadMailMessageCount === null) {
      dispatch(getUnreadMailMessageCount());
    }
  }, [dispatch, unreadMailMessageCount]);

  const updateLastAcknowledgedCount = async (marketingCampaignID: string) => {
    const marketingCampaignsClient = new MarketingCampaignsClient(new AuthClient());
    try {
      await httpRequest(() => marketingCampaignsClient.lastAcknowledgedDate(marketingCampaignID));
    } catch (err) {
      // Ignore Error
    }
  };

  return {
    reward: {
      show: !!currentMarketingCampaignID,
      count: unacknowledgedRewardCount,
      handleClick: () => {
        // TODO: show a tooltip or default page for saying no active campaigns
        if (currentMarketingCampaignID) {
          updateLastAcknowledgedCount(currentMarketingCampaignID);
        }
        return dispatch(
          push(
            createRoute(CampaignRoutes.CampaignDetails, [
              ['marketingCampaignID', currentMarketingCampaignID],
            ]),
          ),
        );
      },
    },
    mail: {
      count: unreadMailMessageCount,
      handleClick: () => dispatch(push(MessageCenterRoutes.InboxMail)),
    },
    cart: {
      count: shoppingCartItemCount,
      handleClick: () => dispatch(push(HealRoutes.ShoppingCart)),
    },
  };
};

export default useNotification;
