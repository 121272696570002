import { RadioGroupProps, useRadioGroup, Wrap, WrapProps } from '@chakra-ui/react';
import { FastField, useFormikContext } from 'formik';
import React, { ReactNode } from 'react';
import { BaseProps } from '../baseProps';
import { FormControl } from '../FormControl/FormControl';
import { radioButtonContext } from './RadioButtonContext';

export type RadioGroupControlProps = BaseProps &
  RadioGroupProps & {
    children: ReactNode;
    wrapProps?: WrapProps;
  };

const { Provider } = radioButtonContext;

export const RadioGroupControl = (props: RadioGroupControlProps) => {
  const { children, name, onChange, label, value, formControlProps, wrapProps, ...rest } = props;
  const { spacing = 4, ...wrapPropsRest } = wrapProps || {};
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange,
    value,
  });

  const group = getRootProps();

  return (
    <Provider value={{ getRadioProps }}>
      <FormControl name={name} label={label} {...formControlProps}>
        <Wrap spacing={spacing} {...wrapPropsRest} {...group} {...rest}>
          {children}
        </Wrap>
      </FormControl>
    </Provider>
  );
};

export type FormRadioGroupControlProps = Overwrite<RadioGroupControlProps, { name: string }>;

export const FormRadioGroupControl = (props: FormRadioGroupControlProps) => {
  const { name, children, formControlProps, ...rest } = props;
  const { setFieldValue } = useFormikContext();

  return (
    <FastField name={name}>
      {({ field, meta }) => (
        <RadioGroupControl
          name={name}
          {...rest}
          formControlProps={{
            ...formControlProps,
            isInvalid: meta.error && meta.touched,
            ...meta,
          }}
          {...field}
          onChange={(value: string) => setFieldValue(name, value)}
        >
          {children}
        </RadioGroupControl>
      )}
    </FastField>
  );
};
