import { CometChat } from '@cometchat-pro/chat';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import jsonPackage from '../../../package.json';
import { COOKIE_NAME, SignInRoutes } from '../../constants';
import {
  AuthClient,
  IUserSignInDetails,
  RolesEnum,
  SecurityClient,
  UserSignInDetails,
  UserSignInResult,
} from '../../generated';
import { mixTrack } from '../../lib/mixpanel';
import { getUserType } from '../../lib/mixpanel/mixpanelUtils';
import { getPatient } from '../../store/root-creator';
import { httpRequest } from '../../utils';
import { expireCookie, setCookie } from '../../utils/cookieHelper';
import { decodeJwtToCurrentUser } from '../../utils/decodeJwtToCurrentUser';
import { isInRole } from '../../utils/isInRole';
import { disconnectSignalR } from '../actionMessage/sendActionMessageAction';
import { getActiveMarketingCampaignsActions } from '../activeMarketingCampaigns/activeMarketingCampaignsActions';
import { getAppFeatures } from '../appFeatures/appFeaturesCreators';
import { appVersionMissmatchAction } from '../appVersion/appVersionActions';
import { setActions } from '../currentChatUser/currentChatUserActions';
import { getProvider } from '../provider/providerCreators';
import { AppState, OHThunkResult } from '../root-reducers';
import {
  delegateActions,
  logoutActions,
  refreshTokenActions,
  signInActions,
} from './currentUserActions';
import { removeLocalStorageItems } from '../../utils/localStorageActions';
import { IAuthenticateUserSignIn } from '../../types';
// import { getBaseUrl } from '../../utils/getUrl';

export function signIn(
  signInDetails: IUserSignInDetails,
  onSuccessCallback?: () => void,
  authenticateSignIn?: IAuthenticateUserSignIn,
): OHThunkResult<Promise<UserSignInResult>> {
  return async (dispatch, getState: () => AppState) => {
    return new Promise<UserSignInResult>(async (resolve) => {
      if (authenticateSignIn?.t) {
        setCookie(COOKIE_NAME, authenticateSignIn.t!);
      }

      dispatch(signInActions.request());
      const securityClient = new SecurityClient(new AuthClient());

      try {
        const result: any =
          authenticateSignIn && authenticateSignIn.t && authenticateSignIn.u
            ? await securityClient.authenticateUser(
                authenticateSignIn.u,
                process.env.REACT_APP_UNBLOCK_URL,
              )
            : await securityClient.signIn(new UserSignInDetails(signInDetails));
        if (!authenticateSignIn && result.version !== jsonPackage.version) {
          dispatch(appVersionMissmatchAction());
        }

        const user = decodeJwtToCurrentUser(result.token!);
        if (user) {
          setCookie(COOKIE_NAME, result.token!);
          // @ts-ignore
          window._loq = window._log || [];
          // @ts-ignore
          window._loq.push([
            'custom',
            {
              name: user.fullName,
              email: user.emailAddress,
            },
          ]);

          mixTrack({
            eventName: 'login:login_result_success',
            uniqueId: user.userDetailID,
            userType: getUserType(user),
          });
          removeLocalStorageItems(['loginPurpose']);
          dispatch(signInActions.success(user));

          if (onSuccessCallback && typeof onSuccessCallback === 'function') {
            onSuccessCallback();
          } else {
            if (isInRole(user, RolesEnum.NotInitializedPatient)) {
              dispatch(push(SignInRoutes.SignUpTypeNotInitializedPatient));
            } else {
              let from = getState().router.location.state
                ? (getState().router.location.state as any).from ?? ''
                : '';

              // Check if we have a goto in the query
              if (!from) {
                const queryParams = queryString.parse(window.location.search);
                from = decodeURIComponent((queryParams?.goTo as string) || '');
                // Make sure nobody hijacks this goto, only allow to redirect within our app
                if (!from.startsWith('/')) {
                  from = '';
                }
              }
              if (from) {
                dispatch(push(from));
              } else {
                dispatch(push('/', { fromLogin: true }));
              }
            }
          }

          resolve(result);
        } else {
          mixTrack({
            eventName: 'login:login_result_failed',
            label: 'Unable to retrieve User',
          });
          throw new Error('Unable to retrieve User.');
        }
      } catch (error: any) {
        mixTrack({
          eventName: 'login:login_not_valid',
        });
        // This error needs to custom handled, because we don't want to handle 401,
        // like in other places of the app
        if (error && error.name === 'AbortError') {
          return; // Skip Abort Errors
        }
        try {
          JSON.parse(error.response);
        } catch (err) {
          throw new Error(error);
        }
        const parsed = JSON.parse(error.response);
        if (parsed.ValidationMessage) {
          // Recoverable Validation Error - should be displayed
          dispatch(signInActions.failure(parsed.ValidationMessage));
        } else {
          if (parsed.Error) {
            throw new Error(parsed.Error);
          } else if (parsed.Message) {
            throw new Error(parsed.Message);
          } else {
            // Some unhandled error
            throw new Error(error.response);
          }
        }
      }
    });
  };
}

export function logoutUser(): OHThunkResult<Promise<void>> {
  return async (dispatch, getState: () => AppState) => {
    return new Promise<void>(async (resolve) => {
      try {
        const id = getState().currentUserState.data?.userDetailID ?? '';
        CometChat.removeMessageListener(id);
        CometChat.logout();
      } catch (error) {
        console.log('comet chat error', error);
      }
      expireCookie(COOKIE_NAME);
      dispatch(disconnectSignalR());
      removeLocalStorageItems(['loginPurpose']);
      dispatch(logoutActions());
      dispatch(setActions.success(null));
      dispatch(getActiveMarketingCampaignsActions.success([]));

      dispatch(
        push(window.location.pathname?.includes('/heal/health-records') ? '/redirect' : '/signout'),
      );
      resolve();
    });
  };
}

export function refreshToken(): OHThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    return new Promise<void>(async () => {
      dispatch(refreshTokenActions.request());
      const securityClient = new SecurityClient(new AuthClient());
      try {
        const result = await httpRequest(() => securityClient.refreshToken());
        const user = decodeJwtToCurrentUser(result.token!);
        if (user) {
          setCookie(COOKIE_NAME, result.token!);
          dispatch(refreshTokenActions.success(user));
          if (user) {
            if (isInRole(user, RolesEnum.Provider) || isInRole(user, RolesEnum.Patient)) {
              dispatch(getAppFeatures());
            }
            if (isInRole(user, RolesEnum.Provider)) {
              dispatch(getProvider(user!.userDetailID));
            } else if (isInRole(user, RolesEnum.Patient)) {
              dispatch(getPatient(user!.userDetailID));
            }
          }
        } else {
          mixTrack({
            eventName: 'login:login_result_failed',
            label: 'Unable to refresh token',
          });
          throw new Error('Unable to retrieve User.');
        }
      } catch (err) {
        dispatch(refreshTokenActions.failure(err as any));
      }
    });
  };
}

export function actAsDelegate(
  userDetailID: string,
  callback?: () => void,
): OHThunkResult<Promise<void>> {
  return async (dispatch) => {
    return new Promise<void>(async (resolve) => {
      // const currentUserDetailID = getState().currentUserState.data?.userDetailID;
      dispatch(delegateActions.request());
      const securityClient = new SecurityClient(new AuthClient());
      try {
        const result = await securityClient.delegate(userDetailID);
        const user = decodeJwtToCurrentUser(result.token!);
        if (user) {
          // dispatch(
          //   push(
          //     createRoute(DelegateRoutes.DelegateRedirect, [['userDetailID', currentUserDetailID]]),
          //   ),
          // );
          mixTrack({
            eventName: 'login:provider_login_as_delegate_success',
            uniqueId: user.userDetailID,
            userType: getUserType(user),
          });
          dispatch(delegateActions.success(user));
          resolve();
          callback?.();
        } else {
          mixTrack({
            eventName: 'login:provider_login_as_delegate_failed',
            label: 'Unable to act as a delegate',
          });
          throw new Error('Unable to retrieve User.');
        }
      } catch (error) {
        dispatch(delegateActions.failure(error as any));
      }
    });
  };
}
