import { Box, Flex, FormHelperText } from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import { FormError, MobisPicker } from '../../../../components/forms';
import { CenteredLoadingIndicator } from '../../../../components/LoadingIndicator';
import Modal from '../../../../components/Modals/Modal';
import ServerValidationBox from '../../../../components/ServerValidationBox';
import useTranslationComponent from '../../../../hooks/useTranslationComponent';

interface IProps {
  title: string;
  isOpen: boolean;
  onDismiss?: () => void;
  onCancel?: () => void;
  cancelText?: string;
  onSubmit: (date: Date) => void;
  submitText?: string;
  isLoading?: boolean;
  error?: string;
}

export const BirthDateModal = (props: IProps) => {
  const { t } = useTranslationComponent();

  const { error, isLoading, onSubmit, ...rest } = props;
  const [birthDate, setBirthDate] = useState<Date>();
  const [showError, setShowError] = useState<boolean>(false);
  return (
    <Modal
      size="xl"
      onSubmit={() => (birthDate ? onSubmit(birthDate) : setShowError(true))}
      {...rest}
    >
      <ServerValidationBox message={error} />
      {isLoading ? (
        <CenteredLoadingIndicator />
      ) : (
        <Flex justifyContent="center" mt="6">
          <Box>
            <MobisPicker
              containerProps={{ width: { base: '100%', md: '50%' }, maxW: '300px' }}
              max={moment().toDate()}
              min={moment().add(-100, 'year').toDate()}
              controls={['calendar']}
              name="birthDate"
              label={t('Birth Date')}
              value={birthDate}
              onChange={(date) => date && setBirthDate(date as Date)}
              formHelperText={
                <FormHelperText>
                  {t('Your birth date is used to verify your identity.')}
                </FormHelperText>
              }
            />
            <FormError touched={showError} error={t('Birth Date is required')} />
            {/* to avoid DateTimePicker focus issue */}
            <div tabIndex={1}></div>
          </Box>
        </Flex>
      )}
    </Modal>
  );
};
