import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { CurrentChatUserType } from '../../constants';

export const setActions = createAsyncAction(
  ['SET_CURRENTCHATUSER_REQUEST', () => {}],
  ['SET_CURRENTCHATUSER_SUCCESS', (res: CurrentChatUserType | null) => res],
  ['SET_CURRENTCHATUSER_ERROR', (err: string) => err],
)();

export const clearAction = createAction('CLEAR_CURRENTCHATUSER')();

export const unreadUsersMessageAction = createAction(
  'SET_UNREAD_USER_CHAT',
  (res: number) => res,
)();
export const unreadGroupMessageAction = createAction(
  'SET_UNREAD_GROUP_CHAT',
  (res: number) => res,
)();

export const clearErrorAction = createAction('CLEAR_CURRENTCHATUSER_ERROR', () => {})();

export type CurrentChatUserActionTypes = ActionType<
  | typeof setActions
  | typeof clearAction
  | typeof clearErrorAction
  | typeof unreadUsersMessageAction
  | typeof unreadGroupMessageAction
>;
