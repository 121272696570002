import { Box, Flex, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { CalendarIcon2 } from '../../../assets/icons';
import { TimeFormatEnum } from '../../../constants';
import { TemperatureTypeEnum, TrackerTypeEnum } from '../../../generated';
import { verifyForPhrSection } from '../../../store/patient/phr/phrCreators';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { successToastr } from '../../../utils/toastr';

import { OutpostTheme } from '../../../themes/outpost';
import PhrItemView from '../components/PhrItemView';

interface ITrackerDetailsViewProps {
  item: any;
  name?: string;
  unitValue?: string;
  createdDate?: Date;
  createdBy?: string;
  updatedBy?: string | null;
  updatedDate?: Date | undefined;
  verifiedCallback?: () => void;
}

const returnName = (x: any, unitValue?: any) => {
  if (x.trackerTypeEnum === TrackerTypeEnum.BloodPressure) {
    return `${x.systolic} / ${x.diastolic} mmHg`;
  }
  if (x.trackerTypeEnum === TrackerTypeEnum.Temperature) {
    return `${x.temperature} Degrees ${TemperatureTypeEnum[unitValue]}`;
  }
  if (x.trackerTypeEnum === TrackerTypeEnum.Pulse) {
    return `${x.pulse} bpm`;
  }
  if (x.trackerTypeEnum === TrackerTypeEnum.BloodOxygen) {
    return `${x.bloodOxygen}%`;
  }
  if (x.trackerTypeEnum === TrackerTypeEnum.BloodGlucose) {
    return `${x.bloodGlucose} mol/L`;
  }
  if (x.trackerTypeEnum === TrackerTypeEnum.Respiratory) {
    return `${x.respiratory} breaths / min`;
  }
};

const TrackerDetailsView = ({
  item,
  name,
  createdDate,
  unitValue,
  verifiedCallback,
}: ITrackerDetailsViewProps) => {
  const dispatch = useDispatch();

  const onVerify = async () => {
    const id = item.phrTrackerDetailID;
    const patientID = item.patientID;
    dispatch(verifyForPhrSection(ReduxPHRCategoryEnum.PHRTrackers, id, patientID));
    successToastr({ description: 'Successfully verified health tracker.' });
    if (verifiedCallback) verifiedCallback();
  };

  return (
    <PhrItemView phrItem={item} onVerify={onVerify} name={returnName(item, unitValue) || name}>
      <Flex justify="space-between" align="center">
        <Box>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Created Date
          </Text>
          <Text
            fontSize={OutpostTheme.FontSizes.body}
            marginTop="0.5rem"
            display="flex"
            alignItems="center"
          >
            <CalendarIcon2 fontSize="18px" color="#78819D" />
            &nbsp;
            {moment(createdDate).format(TimeFormatEnum.LONG_DATE_TIME)}
          </Text>
        </Box>
      </Flex>

      <Box marginTop="1.5rem"></Box>
    </PhrItemView>
  );
};

export default TrackerDetailsView;
