import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { closeSideMenu, openSideMenu, SideMenuActionTypes } from './sideMenuActions';

const isOpen = createReducer<boolean, SideMenuActionTypes>(false)
  .handleAction([openSideMenu], () => true)
  .handleAction([closeSideMenu], () => false);

export interface ISideMenuState {
  isOpen: boolean;
}

export default combineReducers<ISideMenuState>({
  isOpen,
});
