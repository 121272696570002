import { Box, Center, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { SecondaryButton } from '../../../components/Buttons/SecondaryButton';

interface IProps {
  toggleModal: () => void;
  primaryText: string;
  secondaryText: string;
}

export const EmptyNotesCard = ({ toggleModal, primaryText, secondaryText }: IProps) => {
  return (
    <React.Fragment>
      <Center width="100%">
        <Box
          bgColor="#eee7f7"
          borderRadius="5px"
          height="259px"
          width="498px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Text color="#04101F" mb="16px">
            {primaryText}
          </Text>
          <Text color="#04101FC4" fontSize="sm" mb="16px">
            {secondaryText}
          </Text>
          <SecondaryButton
            leftIcon={<FontAwesomeIcon icon={['far', 'plus']} />}
            onClick={toggleModal}
          >
            Add Note
          </SecondaryButton>
        </Box>
      </Center>
    </React.Fragment>
  );
};
