import { Button, ButtonProps } from '@chakra-ui/react';

export type DangerButtonProps = ButtonProps;

export const DangerButton = (props: DangerButtonProps) => {
  const { children, ...rest } = props;
  return (
    <Button variant="danger" {...rest}>
      {children}
    </Button>
  );
};
