import { FormControl, FormErrorMessage, FormErrorMessageProps } from '@chakra-ui/react';
import React from 'react';
type IProps = FormErrorMessageProps & {
  error?: any;
  touched?: boolean;
  className?: string;
  testid?: string;
};

const FormError = ({ error, touched, className, testid, ...rest }: IProps) => {
  return (
    <FormControl isInvalid={touched && error} className={className}>
      <FormErrorMessage {...rest}>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default FormError;
