import { Button, HStack } from '@chakra-ui/react';
import { Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { date, object, string } from 'yup';

import { GLOBAL_MAX_TEXT_LENGTH } from '../../../constants';
import { IPHRSurgeryHistory, PHRSurgeryHistory } from '../../../generated';
import { createForPhrSection, updateForPhrSection } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../store/types';

import { Form, FormInputControl, FormMobisPicker, FormTextArea } from '../../../components/forms';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import ServerValidationBox from '../../../components/ServerValidationBox';
import PhrItemForm from '../components/PhrItemForm';

interface IProps {
  selectedSurgeryHistory: IPHRSurgeryHistory | null;
  patientID: string;
  toggleView: () => void;
  innerBtnRef: React.RefObject<HTMLButtonElement>;
}

interface IForm {
  name: string;
  notes: string;
  surgeryDate?: Date;
  implants: string;
  reason: string;
  location: string;
  surgeonName: string;
}

const SurgeryHistoryForm: React.FC<IProps> = ({
  selectedSurgeryHistory,
  patientID,
  toggleView,
  innerBtnRef,
}) => {
  const dispatch = useDispatch();
  const { error, isPhrLoading } = useSelector((state: AppState) => state.phrPatientState);

  const submit = async (values: IForm) => {
    const surgeryHistory = new PHRSurgeryHistory();
    surgeryHistory.init(selectedSurgeryHistory);
    surgeryHistory.patientID = patientID;
    surgeryHistory.name = values.name;
    surgeryHistory.notes = values.notes;
    surgeryHistory.implants = values.implants;
    surgeryHistory.reason = values.reason;
    surgeryHistory.location = values.location;
    surgeryHistory.surgeonName = values.surgeonName;
    surgeryHistory.surgeryDate = new Date(values.surgeryDate!);

    if (selectedSurgeryHistory) {
      await dispatch(
        updateForPhrSection(
          ReduxPHRCategoryEnum.PHRSurgeryHistories,
          surgeryHistory,
          surgeryHistory.patientID,
          false,
        ),
      );
    } else {
      await dispatch(
        createForPhrSection(
          ReduxPHRCategoryEnum.PHRSurgeryHistories,
          surgeryHistory,
          surgeryHistory.patientID,
          false,
        ),
      );
    }
    toggleView();
  };

  const initialValues: IForm = {
    name: selectedSurgeryHistory ? selectedSurgeryHistory.name! : '',
    notes: selectedSurgeryHistory ? selectedSurgeryHistory.notes! : '',
    surgeryDate: selectedSurgeryHistory ? selectedSurgeryHistory.surgeryDate! : undefined,
    implants: selectedSurgeryHistory ? selectedSurgeryHistory.implants! : '',
    reason: selectedSurgeryHistory ? selectedSurgeryHistory.reason! : '',
    location: selectedSurgeryHistory ? selectedSurgeryHistory.location! : '',
    surgeonName: selectedSurgeryHistory ? selectedSurgeryHistory.surgeonName! : '',
  };

  return (
    <PhrItemForm>
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          name: string()
            .required('Name is required')
            .max(100, 'Name must be at most 100 characters'),
          notes: string().max(GLOBAL_MAX_TEXT_LENGTH, 'Notes must be at most 10000 characters'),
          reason: string().max(GLOBAL_MAX_TEXT_LENGTH, 'Reason must be at most 10000 characters'),
          surgeryDate: date().required('Surgery date is required').typeError('Invalid date'),
          implants: string().max(100, 'Implants must be at most 100 characters'),
          location: string().max(100, 'Location must be at most 100 characters'),
          surgeonName: string().max(100, 'Surgeon Name must be at most 100 characters'),
        })}
        onSubmit={(values) => submit(values)}
      >
        {({ status }) => (
          <Form error={error}>
            <>
              <ServerValidationBox message={status} />
              {isPhrLoading ? (
                <CenteredLoadingIndicator />
              ) : (
                <>
                  <HStack
                    marginBottom="2rem"
                    spacing={{ base: 0, md: 4 }}
                    flexDir={{ base: 'column', md: 'row' }}
                  >
                    <FormInputControl
                      type="text"
                      name="name"
                      label="Name of Surgery"
                      placeholder="Enter name of surgery"
                      height="48px"
                      borderRadius="lg"
                      formControlProps={{
                        marginBottom: { base: '2rem', md: 0 },
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                    <FormMobisPicker
                      name="surgeryDate"
                      label="Surgery Date"
                      placeholder="DD / MM / YY"
                      max={moment().toDate()}
                      min={moment().add(-150, 'year').toDate()}
                      borderRadius="lg"
                      containerProps={{
                        height: '48px',
                        // inputElementProps: {
                        //   height: '48px',
                        // },
                      }}
                      formControlProps={{
                        marginBottom: 0,
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </HStack>

                  <HStack
                    marginBottom="2rem"
                    spacing={{ base: 0, md: 4 }}
                    flexDir={{ base: 'column', md: 'row' }}
                  >
                    <FormInputControl
                      type="text"
                      name="surgeonName"
                      label="Surgeon Name"
                      placeholder="Enter surgeon name"
                      height="48px"
                      borderRadius="lg"
                      formControlProps={{
                        marginBottom: { base: '2rem', md: 0 },
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                    <FormInputControl
                      type="text"
                      name="location"
                      label="Location"
                      placeholder="Enter location"
                      height="48px"
                      borderRadius="lg"
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </HStack>

                  <FormTextArea
                    name="notes"
                    label="Reason / Notes"
                    borderRadius="lg"
                    height="120px"
                    resize="none"
                    placeholder="Type a short note here"
                    formControlProps={{
                      marginTop: '1rem',
                      formLabelProps: {
                        textTransform: 'uppercase',
                        color: '#01143173',
                        fontSize: '12px',
                        fontWeight: 'light',
                      },
                    }}
                  />

                  <Button display="none" ref={innerBtnRef} type="submit" />
                </>
              )}
            </>
          </Form>
        )}
      </Formik>
    </PhrItemForm>
  );
};

export default SurgeryHistoryForm;
