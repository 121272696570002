import React from 'react';

import { Box, Flex, Stack, Tag, Text, Wrap, WrapItem } from '@chakra-ui/react';

import moment from 'moment';

import { TimeFormatEnum } from '../../../../../../constants';

import { ConsultTagTypeEnum } from '../../../../../../generated';

import useProviderConsultContext from '../../hooks/useProviderConsultContext';
import { OutpostTheme } from '../../../../../../themes/outpost';
import { usePartnerSettings } from '../../../../../../services/partners/usePartnerSettings';
import useTranslationComponent from '../../../../../../hooks/useTranslationComponent';

const ComplaintInConsult = (): JSX.Element => {
  const { t } = useTranslationComponent(['clinic']);
  const { patientCountry, consultDate, phrConsult } = useProviderConsultContext();

  const { partnerSymptomsText } = usePartnerSettings();

  const symptomsTags = phrConsult?.phrConsultTags?.filter(
    (x) => x.consultTagTypeEnum === ConsultTagTypeEnum.Complaint,
  );

  const hasSymptomsTags = symptomsTags?.length;

  return (
    <>
      <Flex justifyContent="space-between">
        <Box>
          <Text fontSize={OutpostTheme.FontSizes.body} fontWeight="500">
            {t('Date & Time')}
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body}>
            {moment(consultDate).format(TimeFormatEnum.SHORT_DATE_TIME)}
          </Text>
        </Box>
        <Box>
          <Text fontSize={OutpostTheme.FontSizes.body} fontWeight="500">
            {t('Patient Location')}
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body}>{patientCountry?.name}</Text>
        </Box>
      </Flex>
      <Stack spacing={4} my={4}>
        <Box>
          <Text fontSize={OutpostTheme.FontSizes.body} fontWeight="500">
            {t('Consult Reason')}
          </Text>
          <Text>{phrConsult?.complaint || '-'}</Text>
        </Box>

        <Box>
          <Text fontSize={OutpostTheme.FontSizes.body} fontWeight="500">
            {t(partnerSymptomsText ?? 'Symptoms')}
          </Text>
          {hasSymptomsTags && (
            <Wrap spacing={2}>
              <WrapItem>
                {symptomsTags.map((symptom) => (
                  <Tag colorScheme="blue" key={symptom.tagID} mr="2">
                    {t(symptom.tag?.name ?? '')}
                  </Tag>
                ))}
              </WrapItem>
            </Wrap>
          )}
        </Box>
      </Stack>
    </>
  );
};

export { ComplaintInConsult };
