import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IPatient, PHRTracker, TrackerTypeEnum } from '../../../../../generated';
import { IPHRPatientEnhanced } from '../../../../../store/patient/phr/phrReducers';
import {
  phrAnxietiesSelector,
  phrMoodsSelector,
  phrSleepsSelector,
  phrStressesSelector,
} from '../../../../../store/patient/phr/phrSelectors';
import { getAllForPhrSection } from '../../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../../store/types';
import { usePhrPatient } from './usePhrPatient';

export default function useTrackers(patient?: IPatient, trackers?: PHRTracker[]) {
  const { phrPatient, isLoading: isLoadingPhr } = usePhrPatient(patient);
  const [moodTrackers, setMoodTrackers] = useState<PHRTracker[] | null>(
    trackers
      ?.filter((x) => x.name !== TrackerTypeEnum.Exercise)
      .sort((a, b) => a.name.localeCompare(b.name)) || [],
  );
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setMoodTrackers(
      trackers
        ?.filter((x) => x.name !== TrackerTypeEnum.Exercise)
        .sort((a, b) => a.name.localeCompare(b.name)) || [],
    );
  }, [trackers]);

  useEffect(() => {
    if (moodTrackers && moodTrackers.length) {
      setLoading(false);
    }
  }, [moodTrackers]);

  return { isLoading: isLoading || isLoadingPhr, moodTrackers, phrPatient };
}

interface IProps {
  patient: IPHRPatientEnhanced | null | undefined;
}

export const useMentalTrackerValue = ({ patient }: IProps) => {
  const dispatch = useDispatch();
  const phrMoods = patient ? phrMoodsSelector(patient.phrMoods) : [];
  const phrAnxieties = patient ? phrAnxietiesSelector(patient.phrAnxieties) : [];
  const sleepRecords = patient ? phrSleepsSelector(patient.phrSleeps) : [];
  const phrStresses = patient ? phrStressesSelector(patient.phrStresses) : [];

  useEffect(() => {
    if (patient) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRAnxieties, patient.patientID));
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRMoods, patient.patientID));
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRSleeps, patient.patientID));
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRStresses, patient.patientID));
    }
  }, [dispatch, patient]);

  return {
    phrMoods,
    phrAnxieties,
    phrSleep: sleepRecords,
    phrStresses,
  };
};

enum TagCategoryEnum {
  NoTags = 0,
  Good = 0,
  Neutral = 1,
  Bad = 2,
}

enum MoodStatusLabelEnum {
  VeryUnpleasant = 'Very Unpleasant',
  Unpleasant = 'Unpleasant',
  Okay = 'Okay',
  Pleasant = 'Pleasant',
  VeryPleasant = 'Very Pleasant',
}

enum AnxietyStatusLabelEnum {
  Carefree = 'Carefree',
  SomewhatCarefree = 'Somewhat Carefree',
  NeitherAnxiousNorCarefree = 'Neither Anxious nor Carefree',
  SomewhatAnxious = 'Somewhat Anxious',
  VeryAnxious = 'Very Anxious',
}

enum StressStatusLabelEnum {
  NotAtAllStressed = 'Not at All Stressed',
  ALittleStressed = 'A Little Stressed',
  SomewhatStressed = 'Somewhat Stressed',
  VeryStressed = 'Very Stressed',
  ExtremelyStressed = 'Extremely Stressed',
}

enum SleepStatusLabelEnum {
  Terrible = 'Terrible',
  Poor = 'Poor',
  Fair = 'Fair',
  Good = 'Good',
  Excellent = 'Excellent',
}

const trackerHelper = {
  [TrackerTypeEnum.Mood]: {
    step: 1,
    min: 1,
    max: 5,
    getStatusText: function (value) {
      switch (value) {
        case 1:
          return MoodStatusLabelEnum.VeryUnpleasant;
        case 2:
          return MoodStatusLabelEnum.Unpleasant;
        case 3:
          return MoodStatusLabelEnum.Okay;
        case 4:
          return MoodStatusLabelEnum.Pleasant;
        case 5:
          return MoodStatusLabelEnum.VeryPleasant;
        default:
          return '';
      }
    },
    getTagCategoryIdx: function (value) {
      switch (value) {
        case 1:
        case 2:
          return TagCategoryEnum.Bad;
        case 3:
          return TagCategoryEnum.Neutral;
        case 4:
        case 5:
          return TagCategoryEnum.Good;
        default:
          return TagCategoryEnum.Neutral;
      }
    },
  },
  [TrackerTypeEnum.Anxiety]: {
    step: 1,
    min: 1,
    max: 5,
    getStatusText: function (value) {
      switch (value) {
        case 1:
          return AnxietyStatusLabelEnum.Carefree;
        case 2:
          return AnxietyStatusLabelEnum.SomewhatCarefree;
        case 3:
          return AnxietyStatusLabelEnum.NeitherAnxiousNorCarefree;
        case 4:
          return AnxietyStatusLabelEnum.SomewhatAnxious;
        case 5:
          return AnxietyStatusLabelEnum.VeryAnxious;
        default:
          return '';
      }
    },
    getTagCategoryIdx: function (value) {
      switch (value) {
        case 1:
        case 2:
          return TagCategoryEnum.Good;
        case 3:
          return TagCategoryEnum.Neutral;
        case 4:
        case 5:
          return TagCategoryEnum.Bad;
        default:
          return TagCategoryEnum.Neutral;
      }
    },
  },
  [TrackerTypeEnum.Stress]: {
    step: 1,
    min: 0,
    max: 10,
    getStatusText: function (value) {
      switch (value) {
        case 0:
        case 1:
        case 2:
          return StressStatusLabelEnum.NotAtAllStressed;
        case 3:
        case 4:
          return StressStatusLabelEnum.ALittleStressed;
        case 5:
        case 6:
          return StressStatusLabelEnum.SomewhatStressed;
        case 7:
        case 8:
          return StressStatusLabelEnum.VeryStressed;
        case 9:
        case 10:
          return StressStatusLabelEnum.ExtremelyStressed;
        default:
          return '';
      }
    },
    getTagCategoryIdx: function (value) {
      switch (value) {
        case 0:
        case 1:
        case 2:
        case 3:
          return TagCategoryEnum.Good;
        case 4:
        case 5:
        case 6:
        case 7:
          return TagCategoryEnum.Neutral;
        case 8:
        case 9:
        case 10:
          return TagCategoryEnum.Bad;
        default:
          return TagCategoryEnum.Neutral;
      }
    },
  },
  [TrackerTypeEnum.Sleep]: {
    step: 1,
    min: 1,
    max: 5,
    getStatusText: function (value) {
      switch (value) {
        case 1:
          return SleepStatusLabelEnum.Terrible;
        case 2:
          return SleepStatusLabelEnum.Poor;
        case 3:
          return SleepStatusLabelEnum.Fair;
        case 4:
          return SleepStatusLabelEnum.Good;
        case 5:
          return SleepStatusLabelEnum.Excellent;
        default:
          return '';
      }
    },
    getTagCategoryIdx: function () {
      return TagCategoryEnum.NoTags;
    },
  },
};

export const getTrackerStep = (type: TrackerTypeEnum) => trackerHelper[type].step;
export const getTrackerMin = (type: TrackerTypeEnum) => trackerHelper[type].min;
export const getTrackerMax = (type: TrackerTypeEnum) => trackerHelper[type].max;

export const getTrackerStatusByValue = (type: TrackerTypeEnum) => (value) =>
  trackerHelper[type].getStatusText(value);
export const getTrackerStatusOfMinValue = (type: TrackerTypeEnum) =>
  getTrackerStatusByValue(type)(trackerHelper[type].min);
export const getTrackerStatusOfMaxValue = (type: TrackerTypeEnum) =>
  getTrackerStatusByValue(type)(trackerHelper[type].max);
export const getMoodStatusTextByValue = getTrackerStatusByValue(TrackerTypeEnum.Mood);
export const getAnxietyStatusByValue = getTrackerStatusByValue(TrackerTypeEnum.Anxiety);
export const getStressStatusByValue = getTrackerStatusByValue(TrackerTypeEnum.Stress);
export const getSleepStatusByValue = getTrackerStatusByValue(TrackerTypeEnum.Sleep);

export const getTrackerTagCategoryIdx = (type: TrackerTypeEnum, value) =>
  trackerHelper[type].getTagCategoryIdx(value);
