import { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { RECORDS_TO_TAKE } from '../../../constants';
import {
  AddressBookFolderEnum,
  AddressBooksClient,
  AddressBookSearchDetails,
  AddressBookSortByEnum,
  AddressBookTypeEnum,
  AuthClient,
} from '../../../generated';
import useInfiniteScroll from '../../../hooks/useInfiniteScroll/useInfiniteScroll';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

interface IProps {
  countryID?: string;
  specialtyTagID?: string;
  currentUserDetailID?: string;
  searchText?: string | undefined;
  addressBookFolderEnum?: AddressBookFolderEnum | undefined;
  addressBookTypeEnum?: AddressBookTypeEnum | undefined;
  addressBookSortByEnum?: AddressBookSortByEnum;
  recordsToTake?: number;
  currentLocation?: {
    longitude: number;
    latitude: number;
  };
  addressBookFolderEnums?: AddressBookFolderEnum[];
}

export const useAddressBooksSearchInfinity = ({
  countryID,
  specialtyTagID,
  currentUserDetailID,
  searchText,
  addressBookFolderEnum,
  addressBookTypeEnum,
  addressBookSortByEnum = AddressBookSortByEnum.All,
  recordsToTake = RECORDS_TO_TAKE,
  currentLocation,
  addressBookFolderEnums,
}: IProps) => {
  const [totalCount, setTotalCount] = useState<number>(0);

  const { totalPages, goToPage, recordsToSkip, currentPage } = useInfiniteScroll({
    totalCount,
    searchText,
  });

  const fetchAddressBooks = async ({ recordsToSkip }) => {
    const searchDetails = new AddressBookSearchDetails();
    searchDetails.countryID = countryID;
    searchDetails.specialtyTagID = specialtyTagID;
    searchDetails.currentUserDetailID = currentUserDetailID;
    searchDetails.searchText = searchText;
    searchDetails.addressBookFolderEnum = addressBookFolderEnum;
    searchDetails.addressBookTypeEnum = addressBookTypeEnum;
    searchDetails.addressBookSortByEnum = addressBookSortByEnum;
    searchDetails.recordsToSkip = recordsToSkip;
    searchDetails.recordsToTake = recordsToTake;
    searchDetails.currentLatitude = currentLocation?.latitude;
    searchDetails.currentLongitude = currentLocation?.longitude;
    searchDetails.addressBookFolderEnums = addressBookFolderEnums;

    const result = await httpRequest(() =>
      new AddressBooksClient(new AuthClient()).search(searchDetails),
    );
    return result;
  };

  const items = useInfiniteQuery(
    [
      QueryKeys.AddressBooksSearch,
      {
        countryID,
        specialtyTagID,
        currentUserDetailID,
        searchText,
        addressBookFolderEnum,
        addressBookTypeEnum,
        addressBookSortByEnum,
        recordsToSkip,
        recordsToTake,
        currentLocation,
        addressBookFolderEnums,
      },
    ],
    async ({ pageParam }) => fetchAddressBooks({ recordsToSkip: pageParam }),
    {
      enabled: !!(countryID || currentUserDetailID),
      getNextPageParam: (lastPage) => {
        const { recordsToSkip = 0, recordsToTake = 0, totalCount = 0 } = lastPage || {};
        return recordsToSkip + recordsToTake < totalCount ? recordsToSkip + 10 : undefined;
      },
      getPreviousPageParam: (firstPage) => {
        const { recordsToSkip = 0, recordsToTake = 0, totalCount = 0 } = firstPage || {};
        return recordsToSkip + recordsToTake < totalCount ? recordsToSkip - 10 : false;
      },
    },
  );

  const {
    isLoading: isLoadingAddressBooks,
    data: addressBooksResult,
    error: addressBooksError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = items;

  useEffect(() => {
    const { pages } = addressBooksResult || {};
    if (pages && pages.length > 0) {
      setTotalCount(pages[0].totalCount ?? 0);
    }
  }, [addressBooksResult]);

  return {
    totalPages,
    goToPage,
    currentPage,
    recordsToSkip,
    totalCount,
    addressBooksResult,
    addressBooksError,
    isLoadingAddressBooks,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};
