import { ArrowBackIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, IconButton, Image, useBreakpointValue } from '@chakra-ui/react';

import { CometChat } from '@cometchat-pro/chat';
import { push } from 'connected-react-router';
import { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../../../assets/images/logo.svg';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import {
  getUnreadChatCount,
  loginCurrentChatUser,
} from '../../../../store/currentChatUser/currentChatUserCreators';
import { PartnerSettingsEnum } from '../../../../store/currentPartner/currentPartnerReducers';
import { AppState } from '../../../../store/root-reducers';
import usePageTitle from '../hooks/usePageTitle';
import useToggleSideMenu from '../hooks/useToggleSideMenu';
import { UserNavArea } from './UserNavArea';
// @ts-ignore
import sound from '../../../../assets/sounds/incomingmessage.wav';

const playMessageAlert = () => {
  try {
    const messageAudio = new Audio(sound);
    messageAudio.currentTime = 0;
    messageAudio
      .play()
      .then(() => {})
      .catch(() => {});
  } catch (error) {}
};

const initializeListeners = (listenerID: string, dispatch: Dispatch<any>) => {
  try {
    CometChat.addMessageListener(
      listenerID,
      new CometChat.MessageListener({
        onTextMessageReceived: () => {
          playMessageAlert();
          dispatch(getUnreadChatCount());
        },
        onMediaMessageReceived: () => {
          playMessageAlert();
          dispatch(getUnreadChatCount());
        },
        onCustomMessageReceived: () => {
          playMessageAlert();
          dispatch(getUnreadChatCount());
        },
      }),
    );
  } catch (err) {}
};

export const TopBar = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  // const provider = useSelector((state: AppState) => state.providerState.provider);
  // const patient = useSelector((state: AppState) => state.patientState.patient);
  const currentChatUser = useSelector((state: AppState) => state.currentChatUserState.data);

  const listenerID = currentUser?.userDetailID ?? '';

  useEffect(() => {
    const connectionListenerId = 'connect_' + new Date().getTime();
    if (currentChatUser) {
      dispatch(getUnreadChatCount());
      CometChat.addConnectionListener(
        connectionListenerId,
        new CometChat.ConnectionListener({
          onConnected: () => {
            initializeListeners(listenerID, dispatch);
          },
          inConnecting: () => {
            CometChat.removeMessageListener(listenerID);
          },
          onDisconnected: () => {
            CometChat.removeMessageListener(listenerID);
          },
        }),
      );
    } else {
      // console.log({ patient, provider });
      // if (provider) {
      loginCurrentChatUser(currentUser!, dispatch);
      // }
    }

    return () => {
      CometChat.removeConnectionListener(connectionListenerId);
    };
  }, [currentChatUser, currentUser, dispatch, listenerID]);

  const { toggleSideMenu } = useToggleSideMenu();
  const { title } = usePageTitle();

  const currentPartner = useSelector((state: AppState) => state.currentPartnerState.data);

  const img =
    currentPartner?.partnerSettings?.find(
      ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.APP_LOGO,
    )?.value || logo;

  const isOnNested = useSelector((state: AppState) => state.nestedViewState.isOnNested);
  const showButton = useBreakpointValue({ base: true, lg: false });

  return (
    <Box
      pt={{ base: 0, '2xl': '1rem' }}
      backgroundColor="rgb(248, 248, 248)"
      position="fixed"
      zIndex="1011"
      width={{
        base: '100%',
        lg: 'calc(100% - 0px)',
        '2xl': 'calc(1535px - 0px - 40px - 0rem)',
      }}
      top={{ base: '0' }}
    >
      <Flex
        height={{ base: '72px', '2xl': '62px' }}
        backgroundColor="white"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        px={{ base: 2, lg: 6 }}
        borderRadius={{ base: 0, '2xl': '0.5rem' }}
      >
        <Flex alignItems="center">
          <Box display={{ base: 'block', lg: 'block' }} mr={{ base: 2, lg: 2 }}>
            {isOnNested && currentUser?.isInMobileView ? (
              <IconButton
                onClick={() => dispatch(push(isOnNested))}
                aria-label="back"
                backgroundColor="white"
                icon={<ArrowBackIcon fontSize="24px" />}
              />
            ) : (
              <>
                {showButton && (
                  <IconButton
                    aria-label="menu"
                    backgroundColor="white"
                    onClick={toggleSideMenu}
                    icon={<HamburgerIcon fontSize="24px" />}
                  />
                )}
              </>
            )}
          </Box>

          <Breadcrumbs display={{ base: 'none', lg: 'none' }} />
          <Image src={img} />
        </Flex>

        <Heading display={{ base: 'none', lg: 'none' }} as="h1" fontSize="16px">
          {title}
        </Heading>

        <Box display={{ base: isOnNested ? 'none' : 'block', md: 'block' }}>
          <UserNavArea currentUser={currentUser!} />
        </Box>

        <IconButton
          display={{ base: isOnNested ? 'block' : 'none', md: 'none' }}
          visibility="hidden"
          aria-label="spacer"
          backgroundColor="white"
          icon={<ArrowBackIcon fontSize="24px" />}
        />
      </Flex>
    </Box>
  );
};
