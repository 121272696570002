import { useCallback, useState } from 'react';
import {
  AuthClient,
  FormStatusEnum,
  PHRForm,
  PHRFormQuestion,
  PHRFormsClient,
} from '../../../../../generated';
import { httpRequest } from '../../../../../utils';

interface IProps {
  formID: string;
  patientID: string;
  phrConsultID?: string;
}

const useSubmit = ({ formID, patientID, phrConsultID }: IProps) => {
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const submitForm = useCallback(
    async (phrFormQuestions: PHRFormQuestion[]) => {
      try {
        const phrForm = new PHRForm();
        phrForm.formID = formID;
        phrForm.phrFormQuestions = phrFormQuestions;
        phrForm.startDate = new Date();
        phrForm.patientID = patientID;
        phrForm.formStatusEnum = FormStatusEnum.Started;
        phrForm.phrConsultID = phrConsultID;
        setSubmitting(true);
        const client = new PHRFormsClient(new AuthClient());
        const phrFormID = await httpRequest(() => client.pHRFormsPost(phrForm));
        await httpRequest(() => client.scoreForm(patientID, phrFormID));
        const results = await httpRequest(() => client.pHRFormsGet(patientID, phrFormID));
        setSubmitting(false);
        return results;
      } catch (err) {
        setError(err as any);
        setSubmitting(false);
      }
    },
    [formID, patientID, phrConsultID],
  );

  return { isSubmitting, error, submitForm };
};

export default useSubmit;
