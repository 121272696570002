import { useEffect, useState } from 'react';
import { PatientOrder } from '../../../../generated';
import { usePatientOrdersSearch } from '../../../../services/patientOrders/queries/usePatientOrdersSearch';

interface IProps {
  patientID: string;
  phrConsultID: string;
}

export const useConsultPatientOrders = ({ patientID, phrConsultID }: IProps) => {
  const {
    isLoadingOrders,
    patientOrdersSearchResults,
    errorOrders,
    isFetchingOrders,
    refetchOrders,
  } = usePatientOrdersSearch({
    patientID,
  });
  const [consultPatientOrders, setConsultPatientOrders] = useState<PatientOrder[]>([]);

  useEffect(() => {
    const patientOrders = (patientOrdersSearchResults?.patientOrders?.items ?? [])?.filter(
      (x) => x.phrConsultID === phrConsultID,
    );
    setConsultPatientOrders(patientOrders);
  }, [patientOrdersSearchResults, phrConsultID]);

  return {
    isLoadingOrders: isLoadingOrders || isFetchingOrders,
    errorOrders,
    consultPatientOrders,
    refetchOrders,
  };
};
