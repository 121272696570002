const vowels = ['a', 'e', 'i', 'o', 'u'];

const getNounPrefix = (noun: string): string => {
  const firstLetter = noun.charAt(0);

  if (vowels.includes(firstLetter.toLocaleLowerCase())) {
    return 'an';
  } else {
    return 'a';
  }
};

export { getNounPrefix };
