import { IPatient, PatientPrescription, PrescriptionStatusEnum } from '../../../../generated';

type GetInitializedPrescriptionProps = {
  patientID: string;
  patient: IPatient;
  countryID: string;
  providerID: string;
  phrConsultID?: string;
};

const getInitializedPrescription = (props: GetInitializedPrescriptionProps) => {
  const patientPrescription = new PatientPrescription();
  patientPrescription.countryID = props.countryID;
  patientPrescription.addSignature = true;
  patientPrescription.patientID = props.patientID;
  patientPrescription.phrConsultID = props.phrConsultID;
  patientPrescription.providerID = props.providerID;
  patientPrescription.prescriptionStatusEnum = PrescriptionStatusEnum.Draft;
  patientPrescription.customerAddressLine1 = props.patient.addressLine1;
  patientPrescription.customerAddressLine2 = props.patient.addressLine2;
  patientPrescription.customerCity = props.patient.cityTown
    ? props.patient.cityTown.name
    : props.patient.city;
  patientPrescription.customerCountry = props.patient.country?.name;
  patientPrescription.customerEmailAddress = props.patient.emailAddress;
  patientPrescription.customerName = props.patient.fullName;
  patientPrescription.customerPhone =
    props.patient.cellPhoneNumber || props.patient.homePhoneNumber;
  patientPrescription.customerPostCode = props.patient.postCode;
  patientPrescription.customerProvince = props.patient.provinceState
    ? props.patient.provinceState.name
    : props.patient.province;

  return patientPrescription;
};

export { getInitializedPrescription };
