import { createContext, ReactNode, useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  AddressBook,
  AddressBookFolderEnum,
  AddressBookSortByEnum,
  AddressBookTag,
  AddressBookTypeEnum,
  ICountry,
  IPatient,
  RolesEnum,
  SendToEnum,
  Tag,
} from '../../../generated';
import { IAddressBooksManyProps } from '../../../services/addressBooks/mutations/useAddressBooksMany';
import { IAddressBooksSetPrimaryProps } from '../../../services/addressBooks/mutations/useAddressBooksSetPrimary';
import { IToggleFavoriteProps } from '../../../services/addressBooks/mutations/useAddressBooksToggleFavorite';
import { AppState } from '../../../store/root-reducers';
import { isInRole } from '../../../utils/isInRole';
import {
  AddressBookDeliveryModeEnum,
  AddressBookModeEnum,
  AddressBookViewModeEnum,
} from '../AddressBook';
import { CurrentLocationType, useAddressBooks } from '../hooks/useAddressBooks';

interface IAddressBooksContext {
  mode?: AddressBookModeEnum;
  viewMode?: AddressBookViewModeEnum;
  deliveryMode?: AddressBookDeliveryModeEnum;
  currentUserDetailID?: string;
  patient?: IPatient;
  documentName?: string;
  documentRef?: any;
  defaultFolder?: AddressBookFolderEnum;
  defaultFolders?: AddressBookFolderEnum[];
  callback?: (addresses: AddressBook[]) => void;
  validationMessage?: string;
  onValidate: (address: AddressBook) => boolean;
  //-----
  error: any;
  mutationError: string | null;
  isLoading: boolean;
  selectedAddresses: AddressBook[];
  setSelectedAddresses: (addresses: AddressBook[]) => void;
  onCreate: (addressBook: AddressBook) => void;
  onEdit: (addressBook: AddressBook) => void;
  onDelete: (addressBook: AddressBook) => void;
  onFavorite: ({ addressBook, userDetailID }: IToggleFavoriteProps) => void;
  onSelect: (addressBook: AddressBook) => void;
  onUnSelect: (addressBook?: AddressBook) => void;
  onCreateTags: (addressBookTags: AddressBookTag[]) => void;
  onEditTags: (addressBookTags: AddressBookTag[]) => void;
  onDeleteTags: (addressBookTags: AddressBookTag[]) => void;
  onSetPrimary: (props: IAddressBooksSetPrimaryProps) => void;
  onUpdateTags: (props: IAddressBooksManyProps) => void;
  //----------
  sendToEnum: SendToEnum;
  setSendToEnum: (val: SendToEnum) => void;
  //----------
  setSearchText: (text: string) => void;
  searchResult?: any;
  totalPages: number;
  goToPage: (page: number) => void;
  currentPage: number;
  totalCount: number;
  addressBookSortByEnum: string | undefined;
  setAddressBookTypeEnum: (val: AddressBookTypeEnum | undefined) => void;
  setAddressBookSortByEnum: (val: AddressBookSortByEnum) => void;
  country: ICountry;
  setCountry: (val: ICountry) => void;
  specialty?: Tag;
  setSpecialty: (val?: Tag) => void;
  // -----
  fetchNextPage: () => void;
  hasNextPage: boolean | any;
  isFetchingNextPage: boolean;
  heading?: string;
  onChangeLocation: (location?: CurrentLocationType) => void;
  selectedAddressBookFolders: AddressBookFolderEnum[];
  onToggleAddressBookFolder: (folder: AddressBookFolderEnum) => void;
  addressBookTypeEnum?: AddressBookTypeEnum;
  visibleFolders?: AddressBookFolderEnum[];
  visibleTypes?: AddressBookTypeEnum[];
  isProvider?: boolean;
}

export const AddressBooksContext = createContext<IAddressBooksContext>(null!);

interface IAddressBooksProviderProps {
  mode?: AddressBookModeEnum;
  viewMode?: AddressBookViewModeEnum;
  deliveryMode?: AddressBookDeliveryModeEnum;
  defaultCountry: ICountry;
  patient?: IPatient;
  documentName?: string;
  documentRef?: any;
  defaultFolder?: AddressBookFolderEnum;
  defaultFolders?: AddressBookFolderEnum[];
  callback?: (addresses: AddressBook[]) => void;
  validationMessage?: string;
  onValidate: (address: AddressBook) => boolean;
  children: ReactNode;
  heading?: string;
  visibleFolders?: AddressBookFolderEnum[];
  visibleTypes?: AddressBookTypeEnum[];
  preSelectedAddress?: AddressBook;
}

export function AddressBooksProvider({
  mode = AddressBookModeEnum.Delivery,
  viewMode = AddressBookViewModeEnum.Modal,
  deliveryMode = AddressBookDeliveryModeEnum.Single,
  defaultCountry,
  patient,
  documentName,
  documentRef,
  defaultFolder,
  callback,
  validationMessage = 'The Contact needs to be In Network or have a Fax Number',
  onValidate,
  children,
  heading,
  defaultFolders,
  visibleFolders,
  visibleTypes,
  preSelectedAddress,
}: IAddressBooksProviderProps) {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  const isProvider = isInRole(currentUser, RolesEnum.Provider);

  const {
    error,
    mutationError,
    isLoading,
    selectedAddresses,
    setSelectedAddresses,
    onCreate,
    onEdit,
    onDelete,
    onFavorite,
    onSelect,
    onUnSelect,
    onCreateTags,
    onEditTags,
    onDeleteTags,
    onSetPrimary,
    onUpdateTags,
    //-------
    sendToEnum,
    setSendToEnum,
    //----------
    setSearchText,
    searchResult,
    totalPages,
    goToPage,
    currentPage,
    totalCount,
    addressBookSortByEnum,
    setAddressBookTypeEnum,
    setAddressBookSortByEnum,
    country,
    setCountry,
    specialty,
    setSpecialty,
    // --------
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    onChangeLocation,
    selectedAddressBookFolders,
    onToggleAddressBookFolder,
    addressBookTypeEnum,
  } = useAddressBooks(
    mode,
    deliveryMode,
    defaultCountry,
    currentUser?.userDetailID ?? '',
    defaultFolder,
    defaultFolders,
    visibleFolders,
    preSelectedAddress,
  );

  return (
    <AddressBooksContext.Provider
      value={{
        mode,
        viewMode,
        deliveryMode,
        country,
        setCountry,
        specialty,
        setSpecialty,
        currentUserDetailID: currentUser?.userDetailID,
        patient,
        documentName,
        documentRef,
        defaultFolder,
        callback,
        validationMessage,
        onValidate,
        //-----
        error,
        mutationError,
        isLoading,
        selectedAddresses,
        setSelectedAddresses,
        onCreate,
        onEdit,
        onDelete,
        onFavorite,
        onSelect,
        onUnSelect,
        onCreateTags,
        onEditTags,
        onDeleteTags,
        onSetPrimary,
        onUpdateTags,
        //----------
        sendToEnum,
        setSendToEnum,
        //----------
        setSearchText,
        searchResult,
        totalPages,
        goToPage,
        currentPage,
        totalCount,
        addressBookSortByEnum,
        setAddressBookTypeEnum,
        setAddressBookSortByEnum,
        // -------
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        heading,
        onChangeLocation,
        selectedAddressBookFolders,
        onToggleAddressBookFolder,
        addressBookTypeEnum,
        visibleFolders,
        visibleTypes,
        isProvider,
      }}
    >
      {children}
    </AddressBooksContext.Provider>
  );
}

export default function useAddressBooksContext() {
  const context = useContext(AddressBooksContext);
  if (!context) {
    throw new Error('useAddressBooksContext must be used within a AddressBooksProvider');
  }
  return context;
}
