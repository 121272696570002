import { SecondaryButton } from '../../../Buttons';

interface IProps {
  onClick?: () => void;
  disabled?: boolean;
}

export const PrevButton = ({ onClick, disabled = false }: IProps) => {
  return (
    <SecondaryButton onClick={onClick} disabled={disabled} type="submit">
      Back
    </SecondaryButton>
  );
};
