import React, { createContext, ReactNode } from 'react';
import { EMPTY_GUID } from '../../../constants';
import { CityTown, ICountry, ProvinceState } from '../../../generated';
import { IAddressFormValues, ICityTownOption, IProvinceOption } from './AddressPicker';
import useCityTowns from './useCityTowns';
import useProvinceStates from './useProvinceStates';

export interface IAddressPickerContext {
  provinceStateOptions: ProvinceState[];
  provinceState: IProvinceOption | null;
  setProvinceState: React.Dispatch<React.SetStateAction<IProvinceOption | null>>;
  isLoadingProvinceStates: boolean;
  provinceStatesError: string | null;
  cityTownOptions: CityTown[];
  cityTown: ICityTownOption | null;
  setCityTown: React.Dispatch<React.SetStateAction<ICityTownOption | null>>;
  isLoadingCityTowns: boolean;
  cityTownsError: string | null;
  country?: ICountry;
}

export const AddressPickerContext = createContext<IAddressPickerContext>(null!);

interface IProps {
  country?: ICountry;
  initialValues: IAddressFormValues;
  children: ReactNode;
}

export const AddressPickerProvider = ({ country, initialValues, children }: IProps) => {
  const {
    provinceStateOptions,
    provinceState,
    setProvinceState,
    isLoadingProvinceStates,
    provinceStatesError,
  } = useProvinceStates({
    countryID: country?.countryID,
    initialProvinceState: initialValues.provinceState
      ? ({
          provinceStateID: initialValues.provinceState.provinceStateID,
          name: initialValues.provinceState.name,
        } as IProvinceOption)
      : initialValues.province
      ? { provinceStateID: EMPTY_GUID, name: initialValues.province, __isNew__: true }
      : null,
  });

  const { cityTownOptions, cityTown, setCityTown, isLoadingCityTowns, cityTownsError } =
    useCityTowns({
      provinceStateID: provinceState?.provinceStateID,
      initialCityTown: initialValues.cityTown
        ? ({
            cityTownID: initialValues.cityTown.cityTownID,
            name: initialValues.cityTown.name,
          } as ICityTownOption)
        : initialValues.city
        ? { cityTownID: EMPTY_GUID, name: initialValues.city, __isNew__: true }
        : null,
    });

  return (
    <AddressPickerContext.Provider
      value={{
        provinceStateOptions,
        provinceState,
        setProvinceState,
        isLoadingProvinceStates,
        provinceStatesError,
        cityTownOptions,
        cityTown,
        setCityTown,
        isLoadingCityTowns,
        cityTownsError,
        country,
      }}
    >
      {children}
    </AddressPickerContext.Provider>
  );
};
