import { useQuery } from 'react-query';
import { AuthClient, Order, OrdersClient } from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

export const useOrdersAll = () => {
  const {
    isLoading: isLoadingOrders,
    data: orders,
    error: ordersError,
  } = useQuery<Order[], string>(
    QueryKeys.OrdersAll,
    async () => httpRequest(() => new OrdersClient(new AuthClient()).all()),
    { staleTime: Infinity },
  );

  return {
    isLoadingOrders,
    orders,
    ordersError,
  };
};
