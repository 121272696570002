import mixpanel from 'mixpanel-browser';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AppFeatures, EMPTY_GUID } from '../../constants';
import { AppFeaturesClient, AuthClient, RolesEnum } from '../../generated';
import { ICurrentUser } from '../../store/currentUser/currentUserReducers';
import { AppState } from '../../store/root-reducers';
import { httpRequest } from '../../utils';
import getUrlParam from '../../utils/getUrlParam';
import { isInRole } from '../../utils/isInRole';
import { eventNames, TEventNames } from './eventNames';

type TUserTypes = 'Patient' | 'Provider' | 'Unknown';

export type MixTrackProps = {
  eventName: TEventNames;
  source?: string;
  uniqueId?: string;
  userType?: TUserTypes;
  label?: string | number;
};

const getUserType = (currentUser: ICurrentUser | null): TUserTypes => {
  if (!currentUser) return 'Unknown';

  if (isInRole(currentUser, RolesEnum.Patient)) {
    return 'Patient';
  } else if (isInRole(currentUser, RolesEnum.Provider)) {
    return 'Provider';
  }

  return 'Unknown';
};

const getUserAgent = () => {
  const mobileUserAgent = getUrlParam('ua');
  const decodedMobileUserAgent = mobileUserAgent ? decodeURIComponent(mobileUserAgent) : undefined;

  const webUserAgent = navigator.userAgent;

  return decodedMobileUserAgent || webUserAgent;
};

const getMixpanelToken = async () => {
  const appFeaturesClient = new AppFeaturesClient(new AuthClient());

  const response = await httpRequest(() => appFeaturesClient.all());

  const mixPanelFeature = response.find((feature) => feature.name === AppFeatures.Mixpanel);

  return mixPanelFeature?.description;
};

const mixInitialize = async (token?: string) => {
  if (!token) return;

  mixpanel.init(token, {
    debug: process.env.NODE_ENV === 'development',
  });
};

const mixTrack = (props: MixTrackProps) => {
  const userAgent = getUserAgent();

  const userDetailId = EMPTY_GUID;

  const {
    eventName,
    source = userAgent,
    uniqueId = userDetailId,
    userType = 'Unknown',
    label = '',
  } = props;

  mixpanel.track(eventNames[eventName], {
    source,
    uniqueId,
    label,
    userType,
  });
};

const useMixTrack = () => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  const mixTrack = useCallback(
    (props: MixTrackProps) => {
      const userAgent = getUserAgent();

      const userDetailId = currentUser?.userDetailID || EMPTY_GUID;

      const {
        eventName,
        source = userAgent,
        uniqueId = userDetailId,
        userType = getUserType(currentUser),
        label = '',
      } = props;

      mixpanel.track(eventNames[eventName], {
        source,
        uniqueId,
        label,
        userType,
      });
    },
    [currentUser],
  );

  return {
    mixTrack,
  };
};

export { mixInitialize, mixTrack, getMixpanelToken, useMixTrack, getUserType, getUserAgent };
