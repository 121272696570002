import styled from 'styled-components';
import { ButtonSizeEnum } from '../../constants';
import { SkeletonPulse } from '../Skeleton/SkeletonPulse';

const StyledSkeletonButton = styled.button`
  ${SkeletonPulse};
  width: auto;
  height: 40px;
  color: transparent;
`;

interface IProps {
  children?: any;
  size?: ButtonSizeEnum;
}

const SkeletonButton = ({ children }: IProps) => {
  return <StyledSkeletonButton>{children}</StyledSkeletonButton>;
};

export default SkeletonButton;
