import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { CurrentChatUserType } from '../../constants';
import {
  clearAction,
  clearErrorAction,
  CurrentChatUserActionTypes,
  setActions,
  unreadGroupMessageAction,
  unreadUsersMessageAction,
} from './currentChatUserActions';

const data = createReducer<CurrentChatUserType | null, CurrentChatUserActionTypes>(null)
  .handleAction([setActions.success], (_state, action) => action.payload)
  .handleAction([clearAction], () => null);

const error = createReducer<string, CurrentChatUserActionTypes>('')
  .handleAction([setActions.failure], (_state, action) => action.payload)
  .handleAction([clearErrorAction], () => '');

const isLoading = createReducer<boolean, CurrentChatUserActionTypes>(false)
  .handleAction([setActions.request], () => true)
  .handleAction([setActions.failure], () => false)
  .handleAction([setActions.success], () => false);

const unreadUsersMessageCount = createReducer<number, CurrentChatUserActionTypes>(0).handleAction(
  [unreadUsersMessageAction],
  (_state, action) => action.payload,
);

const unreadGroupMessageCount = createReducer<number, CurrentChatUserActionTypes>(0).handleAction(
  [unreadGroupMessageAction],
  (_state, action) => action.payload,
);

export interface ICurrentChatUserState {
  error: string;
  isLoading: boolean;
  data: CurrentChatUserType | null;
  unreadUsersMessageCount: number;
  unreadGroupMessageCount: number;
}

export default combineReducers<ICurrentChatUserState>({
  data,
  error,
  isLoading,
  unreadUsersMessageCount,
  unreadGroupMessageCount,
});
