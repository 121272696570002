import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import styled from 'styled-components';
import { Paragraph } from '../../../components/common/Paragraph';
import Modal from '../../../components/Modals/Modal';
import { ConsultRoutes, HealRoutes, SpacingRelated } from '../../../constants';
import { ConsultStatusEnum, ConsultTypeEnum, IConsult, RolesEnum } from '../../../generated';
import { useMixTrack } from '../../../lib/mixpanel/mixpanelUtils';
import { AppState } from '../../../store/root-reducers';
import { createRoute } from '../../../utils/createRoute';
import { isInRole } from '../../../utils/isInRole';

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${SpacingRelated.HalfRegular};
`;

interface IProps {
  activeConsult: IConsult | null;
}

const ActiveConsultModal = ({ activeConsult }: IProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const [showActiveConsultModal, setShowActiveConsultModal] = useState<boolean>(false);

  const { mixTrack } = useMixTrack();

  useEffect(() => {
    if (
      activeConsult &&
      activeConsult.consultTypeEnum === ConsultTypeEnum.Video &&
      (activeConsult.consultStatusEnum === ConsultStatusEnum.ProviderReady ||
        activeConsult.consultStatusEnum === ConsultStatusEnum.InProgress) &&
      !matchPath(location.pathname, {
        path: [ConsultRoutes.Consult, HealRoutes.ClinicQueue],
        exact: true,
      }) &&
      isInRole(currentUser, RolesEnum.Patient) &&
      activeConsult.patient?.userDetailID === currentUser?.userDetailID
    ) {
      setShowActiveConsultModal(true);
    }
  }, [activeConsult, currentUser, location.pathname]);

  const routeToActiveConsult = () => {
    mixTrack({
      eventName: 'video_consult:patient_go_to_video_consult_clicked',
    });
    if (activeConsult) {
      if (currentUser?.isInMobileView && currentUser.mobileSystem === 'ios') {
        // @ts-ignore
        webkit.messageHandlers.startPatientConsultCallbackHandler.postMessage(
          activeConsult.consultID,
        );
      } else {
        dispatch(
          push(createRoute(ConsultRoutes.Consult, [['consultID', activeConsult.consultID]])),
        );
      }
    }
  };

  return (
    <Modal
      isOpen={showActiveConsultModal}
      title="Please Join The Consult"
      onSubmit={routeToActiveConsult}
      submitText="Join Consult"
      onDismiss={() => setShowActiveConsultModal(false)}
    >
      <StyledParagraph>
        The {process.env.REACT_APP_PROVIDER_TITLE} has started the video consult. Please join the
        consult by clicking the button below.
      </StyledParagraph>
    </Modal>
  );
};

export default ActiveConsultModal;
