import { useMutation } from 'react-query';
import { AuthClient, InventoryClient, InventoryLoaderDetails } from '../../../generated';
import { httpRequest } from '../../../utils';

export const useInventorySubmitInventoryLoader = () => {
  const inventorySubmitInventoryLoader = useMutation<
    string,
    string,
    InventoryLoaderDetails,
    string
  >(async (inventoryLoaderDetails: InventoryLoaderDetails) =>
    httpRequest(() =>
      new InventoryClient(new AuthClient()).submitInventoryLoader(inventoryLoaderDetails),
    ),
  );

  return inventorySubmitInventoryLoader;
};
