import { ILanguage } from '../generated';

export const languageFromBrowser = (
  languages: ILanguage[],
  navigatorLanguage: string,
  navigatorLanguages: readonly string[],
) => {
  // 1. Get Users primary used language
  const userLanguage = languages.find((x) => x.languageID === navigatorLanguage);

  if (userLanguage) {
    return userLanguage;
  }

  // 2. Else get All users languages
  for (const language of navigatorLanguages) {
    const matchingLanguage = languages.find((x) => x.languageID === language);
    if (matchingLanguage) {
      return matchingLanguage;
    }
  }

  // 3. Else get en-US as default
  return languages.find((x) => x.languageID === 'en-US')
    ? languages.find((x) => x.languageID === 'en-US')
    : languages[0];
};
