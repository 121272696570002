import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { successToastr } from '../../../../utils/toastr';
import { displayValue } from '../../../../utils/displayValue';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import { PHRAllergy, PHRAllergyTag } from '../../../../generated';
import { verifyForPhrSection } from '../../../../store/patient/phr/phrCreators';

import PhrItemView from '../../components/PhrItemView';
import { OutpostTheme } from '../../../../themes/outpost';

interface IAllergyViewProps {
  phrAllergy: PHRAllergy;
}

const displayReactions = (tags: PHRAllergyTag[] | undefined, otherReaction: string | undefined) => {
  const reactions = tags?.length ? tags.map((x) => x.tag?.name).join(', ') : '';
  return displayValue(
    reactions ? reactions + (otherReaction && `, ${otherReaction}`) : otherReaction,
  );
};

const AllergyView = ({ phrAllergy }: IAllergyViewProps) => {
  const dispatch = useDispatch();

  const onVerify = async (id: string, patientID: string) => {
    dispatch(verifyForPhrSection(ReduxPHRCategoryEnum.PHRAllergies, id, patientID));
    successToastr({ description: 'Successfully verified allergy' });
  };

  return (
    <PhrItemView
      phrItem={{ ...phrAllergy, name: phrAllergy.otherName }}
      onVerify={() => onVerify(phrAllergy.phrAllergyID, phrAllergy.patientID)}
    >
      <HStack spacing={4} justify="space-between">
        <Box maxW="50%">
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Severity
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
            {phrAllergy.allergySeverityEnum}
          </Text>
        </Box>
        <Box maxW="50%">
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Reactions
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
            {displayReactions(phrAllergy.phrAllergyTags, phrAllergy.otherReaction)}
          </Text>
        </Box>
      </HStack>

      <Box marginTop="1.5rem">
        <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
          Notes
        </Text>
        <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
          {phrAllergy.notes ? phrAllergy.notes : 'No notes have been added yet'}
        </Text>
      </Box>
    </PhrItemView>
  );
};

export default AllergyView;
