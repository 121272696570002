import { useEffect, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { RolesEnum, UserDetailSetting, UserDetailSettingEnum } from '../../../generated';
import useTranslationComponent from '../../../hooks/useTranslationComponent';
import { AppState } from '../../../store/root-reducers';
import { getUserDetailSettings } from '../../../store/userDetailSettings/getUserDetailSettingsAction';
import { updateUserDetailSettings } from '../../../store/userDetailSettings/updateUserDetailSettingsAction';
import { isInRole } from '../../../utils/isInRole';
import TooltipComponent from '../Tooltip';
import ProviderStartDayModal from './ProviderStartDayModal';

const ProviderDashboardTour = () => {
  const { t } = useTranslationComponent(['tour']);

  const [stepIndex, setStepIndex] = useState<number>(0);
  const dispatch = useDispatch();
  const userDetailSettings = useSelector(
    (state: AppState) => state.userDetailSettingState.userDetailSettings,
  );
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const [run, setRun] = useState<boolean>(false);
  const [showIntroModal, setShowIntroModal] = useState<boolean>(false);

  const steps = [
    {
      target: 'body',
      content: t(`Welcome to Outpost!`, { Outpost: process.env.REACT_APP_COMPANY_NAME }),
      placement: 'center' as const,
    },
    {
      target: '#top-bar-profile',
      content: t(
        `First things first, head over to your Profile and make sure it is all up to date.`,
      ),
      placement: 'top' as const,
    },
    {
      target: '#schedule',
      content: t(`You can set up your schedule here.`),
      placement: 'top' as const,
    },
    {
      target: '#waiting-room',
      content: t(`To see your Patients, head over to the Waiting Room`),
      placement: 'top' as const,
    },
    {
      target: '#appointments',
      content: t(
        `Once patients are booking appointments with you, you will be able to see your day and weekly upcoming appointments here.`,
      ),
      placement: 'top' as const,
    },
    {
      target: '#my-patients',
      content: t(
        `If you ever have to check out a Patient outside of a Consultation, head over to My Patients`,
      ),
      placement: 'top' as const,
    },
    {
      target: '#carespaces',
      content: t(
        'If you ever want to know more about something we go through here, head over to the Health Resources where you will find video tutorials!',
      ),
      placement: 'top' as const,
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowIntroModal(true);
      const updatedUserDetailSettings = (userDetailSettings ?? []).map((x) => {
        const updated = new UserDetailSetting();
        updated.init(x);
        if (x.userDetailSettingEnum === UserDetailSettingEnum.ProviderDashboardTour) {
          updated.value = 'true';
        }
        return updated;
      });
      dispatch(updateUserDetailSettings(updatedUserDetailSettings));
    }
  };

  useEffect(() => {
    if (!userDetailSettings) {
      dispatch(getUserDetailSettings());
    }
  }, [dispatch, userDetailSettings]);

  useEffect(() => {
    if (userDetailSettings) {
      const hasTakenTour = userDetailSettings.find(
        (x) => x.userDetailSettingEnum === UserDetailSettingEnum.ProviderDashboardTour,
      );
      if (!hasTakenTour || hasTakenTour.value === 'false') {
        setRun(true);
      } else {
        setShowIntroModal(true);
      }
    }
  }, [userDetailSettings]);

  return (
    <>
      <Joyride
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        callback={handleJoyrideCallback}
        showSkipButton={true}
        run={run}
        locale={{
          back: t('Back'),
          close: t('Close'),
          last: t('Done'),
          next: t('Next'),
          skip: t('Skip'),
        }}
        stepIndex={stepIndex}
        steps={steps}
        continuous={true}
        tooltipComponent={TooltipComponent}
        styles={{
          options: {
            zIndex: 9999,
          },
        }}
      />
      {!isInRole(currentUser, RolesEnum.Receptionist) && (
        <ProviderStartDayModal isOpen={showIntroModal} onClose={() => setShowIntroModal(false)} />
      )}
    </>
  );
};

export { ProviderDashboardTour };
