import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { AuthClient, UserDetailRelationship, UserDetailsClient } from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

export const useUserDetailsUserDetailsRelationships = () => {
  const {
    isLoading: isLoadingUserDetailsRelationships,
    data: userDetailsRelationships,
    error: userDetailsRelationshipsError,
    refetch: refetchUserDetailsRelationships,
    isFetching: isFetchingUserDetailsRelationships,
  } = useQuery<UserDetailRelationship[], string>(
    QueryKeys.UserDetailsUserDetailsRelationships,
    async () =>
      httpRequest(() => new UserDetailsClient(new AuthClient()).userDetailRelationships()),
  );
  const {
    isLoading: isLoadingUserDetailsSponsors,
    data: userDetailsSponsors,
    error: userDetailsSponsorsError,
    refetch: refetchUserDetailsSponsors,
    isFetching: isFetchingUserDetailsSponsors,
  } = useQuery<UserDetailRelationship[], string>(
    QueryKeys.UserDetailsUserDetailsSponsors,
    async () => httpRequest(() => new UserDetailsClient(new AuthClient()).userDetailSponsors()),
  );

  const patientDependents = useMemo(
    () => userDetailsRelationships?.filter((relationship) => relationship.dependant),
    [userDetailsRelationships],
  );

  const patientSponsors = useMemo(
    () => userDetailsSponsors?.filter((relationship) => relationship.dependant),
    [userDetailsSponsors],
  );

  const walletDependents = useMemo(
    () => userDetailsRelationships?.filter((relationship) => relationship.wallet),
    [userDetailsRelationships],
  );

  return {
    isLoadingUserDetailsRelationships,
    userDetailsRelationships,
    userDetailsRelationshipsError,
    refetchUserDetailsRelationships,
    isFetchingUserDetailsRelationships,
    patientDependents,
    walletDependents,
    isLoadingUserDetailsSponsors,
    userDetailsSponsors,
    userDetailsSponsorsError,
    refetchUserDetailsSponsors,
    isFetchingUserDetailsSponsors,
    patientSponsors,
  };
};
