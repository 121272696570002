import { Flex, Text, HStack, ThemingProps, TextProps, FlexProps, Box } from '@chakra-ui/react';
import { Avatar } from '../../../../components/common/Avatar';
import { AddressBook } from '../../../../generated';
import { OutpostTheme } from '../../../../themes/outpost';
import { getUserThumbnail } from '../../../../utils/getUserThumbnail';

interface IProps {
  ActionButton?: React.ReactNode;
  selectedAddress: AddressBook;
  avatarProps?: ThemingProps<'Avatar'>;
  nameTextProps?: TextProps;
  containerProps?: FlexProps;
}

export const SelectedAddressCard = ({
  ActionButton,
  selectedAddress,
  avatarProps,
  nameTextProps,
  containerProps,
}: IProps) => {
  return (
    <Flex
      borderRadius="0.25rem"
      borderWidth="1px"
      borderColor="purple.400"
      py={4}
      px={2}
      minH="100px"
      alignItems="center"
      {...containerProps}
    >
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        direction={{ base: 'column', md: 'row' }}
        width="100%"
      >
        <HStack mb={{ base: 3, md: 0 }}>
          <Avatar
            size="lg"
            src={getUserThumbnail(selectedAddress.imageDetailID)}
            {...avatarProps}
          />
          <Box flex="1">
            <Text fontSize={OutpostTheme.FontSizes.body} {...nameTextProps}>
              {selectedAddress.name}
            </Text>
            <Text fontSize="12px">
              {[
                selectedAddress.addressLine1,
                selectedAddress.addressLine2,
                selectedAddress.city,
                selectedAddress.province,
                selectedAddress.postCode,
              ]
                .filter((x) => x)
                .join(', ')}
            </Text>
          </Box>
        </HStack>
        {ActionButton}
      </Flex>
    </Flex>
  );
};
