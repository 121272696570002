import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Flex, HStack, Stack, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { DangerButton } from '../../../../../components/Buttons/DangerButton';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';
import ToolTip from '../../../../../components/common/ToolTip';
import { TimeFormatEnum } from '../../../../../constants';
import { PHRMedication, RolesEnum } from '../../../../../generated';
import useTranslationComponent from '../../../../../hooks/useTranslationComponent';
import { AppState } from '../../../../../store/root-reducers';
import { OutpostTheme } from '../../../../../themes/outpost';
import { isInRole } from '../../../../../utils/isInRole';
import { MedicationInfo } from '../MedicationInfo/MedicationInfo';
import { Refill } from '../Refill/Refill';

interface IProps {
  phrMedications: PHRMedication[];
  onDiscontinue?: (phrMedication: PHRMedication[]) => void;
  inverse?: boolean;
  allowRefills: boolean;
  phrConsultID?: string;
  consultCountryID?: string;
  onRefill: (patientPrescriptionID: string) => void;
  allMedications: PHRMedication[];
}

const ConsultMedicationsList = ({
  phrMedications,
  onDiscontinue,
  allowRefills,
  phrConsultID,
  consultCountryID,
  onRefill,
  allMedications,
}: IProps) => {
  const { t } = useTranslationComponent(['clinic']);
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  if (!phrMedications.length) {
    return <Text>{t('No Medications added')}</Text>;
  }

  return (
    <Wrap spacing={4} overflow="hidden">
      {phrMedications.map((x) => {
        const isDiscontinued = !!x.discontinuedDate;
        const canRefill =
          allowRefills &&
          isInRole(currentUser, RolesEnum.Provider) &&
          x.patientPrescriptionID &&
          phrConsultID &&
          consultCountryID;

        return (
          <WrapItem width="100%">
            <Flex
              key={x.phrMedicationID}
              pt={2}
              pb={4}
              px={5}
              width="100%"
              backgroundColor="#FFFFFF"
              boxShadow="lg"
              borderRadius="0.25rem"
              border="1px solid #8F9BB31C"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box width="100%">
                {isDiscontinued && (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    ml={2}
                    height="40px"
                    sx={{ '> div': { position: 'absolute', top: '18px' } }}
                    position="relative"
                  >
                    <ToolTip tooltip={x.discontinuedReason}>
                      <DiscontinuedIcon icon={['fas', 'exclamation-circle']} size="lg" />
                    </ToolTip>
                  </Flex>
                )}
                <Flex justifyContent="space-between" alignItems="center">
                  <Text fontSize={OutpostTheme.FontSizes.body} fontWeight="500">
                    {[x.name, x.dosage].join(' ')}
                  </Text>
                  <MedicationInfo
                    phrConsultID={phrConsultID}
                    patientID={x.patientID}
                    consultCountryID={consultCountryID}
                    medication={x}
                    onRefill={onRefill}
                    allMedications={allMedications}
                  >
                    {({ openMedicationInfo }) => (
                      <Button
                        onClick={openMedicationInfo}
                        display="flex"
                        minWidth="auto"
                        variant="ghost"
                        fontSize="24"
                        p={0}
                        leftIcon={
                          <Center
                            border="2px solid #200E32"
                            width="24px"
                            height="24px"
                            borderRadius="full"
                          >
                            <ChevronRightIcon fontSize="24px" />
                          </Center>
                        }
                      />
                    )}
                  </MedicationInfo>
                </Flex>

                <Box>
                  <Flex mb={4} justifyContent="space-between">
                    <Stack spacing={0}>
                      <Text fontSize="12px" fontWeight="500">
                        {t('Duration')}
                      </Text>
                      <Text fontSize="12px">
                        {moment(x.startDate).format(TimeFormatEnum.SHORT_LONG_DATE)}{' '}
                        {x.endDate
                          ? `- ${moment(x.endDate).format(TimeFormatEnum.SHORT_LONG_DATE)}`
                          : null}
                      </Text>
                    </Stack>

                    <Stack spacing={0}>
                      <Text fontSize="12px" fontWeight="500">
                        {t('Refills')}
                      </Text>
                      <Text fontSize="12px">{x.refills}</Text>
                    </Stack>
                  </Flex>

                  <Stack spacing={0}>
                    <Text fontSize="12px" fontWeight="500">
                      {t('Added By')}
                    </Text>
                    <Text fontSize="12px">
                      {t('Prescribed by')} {x.createdByName}
                    </Text>
                  </Stack>
                </Box>
                {(canRefill || (onDiscontinue && !isDiscontinued)) && (
                  <HStack justifyContent="flex-start" spacing="4" mt={4}>
                    {canRefill && (
                      <Refill
                        patientID={x.patientID}
                        countryID={consultCountryID!}
                        medications={[x]}
                        phrConsultID={phrConsultID!}
                        onRefill={onRefill}
                      >
                        {({ openRefills }) => (
                          <PrimaryButton
                            borderRadius="full"
                            backgroundColor="blue.50"
                            width="40px"
                            height="40px"
                            minWidth="40px"
                            disabled={isDiscontinued || !x.medicationID}
                            title={
                              isDiscontinued || !x.medicationID ? t('Medication is continued.') : ''
                            }
                            onClick={openRefills}
                            color="blue.700"
                          >
                            <FontAwesomeIcon icon={['far', 'plus']} size="lg" />
                          </PrimaryButton>
                        )}
                      </Refill>
                    )}
                    {onDiscontinue && !isDiscontinued ? (
                      <DangerButton
                        onClick={() => onDiscontinue([x])}
                        borderRadius="full"
                        backgroundColor="red.50"
                        width="40px"
                        height="40px"
                      >
                        <FontAwesomeIcon icon={['far', 'times']} size="lg" />
                      </DangerButton>
                    ) : (
                      <ButtonSpacer>
                        <FontAwesomeIcon icon={['far', 'times']} size="lg" />
                      </ButtonSpacer>
                    )}
                  </HStack>
                )}
              </Box>
            </Flex>
          </WrapItem>
        );
      })}
    </Wrap>
  );
};

export default ConsultMedicationsList;

const ButtonSpacer = styled(DangerButton)`
  visibility: hidden;
`;

const DiscontinuedIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.Colors.Danger};
`;
