import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import { ButtonRow } from '../../../../components/Buttons/ButtonRow';
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton';
import { SecondaryButton } from '../../../../components/Buttons/SecondaryButton';
import { RadioControl, RadioGroupControl } from '../../../../components/forms';
import { CenteredLoadingIndicator } from '../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../components/ServerValidationBox';
import { ButtonSizeEnum } from '../../../../constants';
import {
  AuthClient,
  FileDetail,
  ImageDetailsClient,
  IProvider,
  Provider,
} from '../../../../generated';
import { updateProviderProfile } from '../../../../store/root-creator';
import { AppState } from '../../../../store/root-reducers';
import { httpRequest } from '../../../../utils';
import { getFileDetail } from '../../../../utils/fileHelpers';
import SignatureDropzoneComponent from './SignatureDropzoneComponent';

enum UploadType {
  FileUpload = 'FileUpload',
  SignaturePad = 'SignaturePad',
}

interface IProps {
  provider: IProvider;
  isEditing?: boolean;
  setIsEditing?: (editing: boolean) => void;
}

export const ProviderSignatureForm = ({
  provider,
  isEditing = false,
  setIsEditing = () => {},
}: IProps) => {
  const dispatch = useDispatch();
  const authClient = new AuthClient();
  const isProviderLoading = useSelector((state: AppState) => state.providerState.isLoading);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const imageDetailsClient = new ImageDetailsClient(authClient);
  const [signature, setSignature] = useState<File>();
  const [error, setError] = useState<string>('');
  const [uploadView, setUploadView] = useState<UploadType>(UploadType.SignaturePad);

  useEffect(() => {
    setSignature(undefined);
    setError('');
  }, [uploadView]);

  const uploadSignature = async () => {
    if (signature) {
      setIsUploading(true);
      const reader = new FileReader();
      reader.addEventListener('load', async (t: any) => {
        const fileDetail = getFileDetail(
          signature.type,
          signature.size,
          signature.name,
          t.currentTarget.result,
        );
        uploadSignatureAndUpdateProvider(fileDetail);
      });
      reader.readAsArrayBuffer(signature);
    }
  };

  const trimAndUploadCanvasSignature = async () => {
    //@ts-ignore
    if (!sigCanvas.isEmpty()) {
      setIsUploading(true);
      //@ts-ignore
      const selectedFileBase64 = sigCanvas.getTrimmedCanvas().toDataURL('image/png');
      const binaryString = atob(selectedFileBase64.split(',')[1]);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const fileDetail = new FileDetail();
      // @ts-ignore
      fileDetail.bytes = bytes.toByteArray();
      fileDetail.name = `${provider.userDetailID}-signature.png`;
      fileDetail.contentType = 'image/png';
      uploadSignatureAndUpdateProvider(fileDetail);
    } else {
      setError('You must draw a signature.');
    }
  };

  const uploadSignatureAndUpdateProvider = async (fileDetail: FileDetail) => {
    try {
      const imageDetailID = await httpRequest(() => imageDetailsClient.upload(fileDetail));
      const providerToUpdate = new Provider();
      providerToUpdate.init(provider);
      providerToUpdate.signatureImageDetailID = imageDetailID;
      await dispatch(updateProviderProfile(providerToUpdate));
      setIsUploading(false);
      setIsEditing(false);
    } catch (err) {
      setIsUploading(false);
      setError(err as any);
    }
  };

  let sigCanvas = useRef(null);

  return (
    <>
      {isUploading || isProviderLoading ? (
        <CenteredLoadingIndicator />
      ) : (
        <>
          {provider.signatureImageDetailID && !isEditing ? (
            <p>
              Thank you for uploading your signature. If you need to update your signature please
              create or upload a new one. For security purposes we do not display or allow you to
              download your existing signature.
            </p>
          ) : (
            <>
              <p>
                {provider.signatureImageDetailID
                  ? 'Note that uploading a new signature will override your existing one'
                  : 'Please create or upload a signature'}
              </p>
              <ServerValidationBox message={error} />
              <div className="row my-4">
                <div className="col-12 d-flex justify-content-center align-items-center">
                  <RadioGroupControl
                    value={uploadView}
                    onChange={(value: string) => setUploadView(value as UploadType)}
                    name="signatureType"
                  >
                    <RadioControl value={UploadType.SignaturePad} name="signatureType">
                      Signature Pad
                    </RadioControl>
                    <RadioControl value={UploadType.FileUpload} name="signatureType">
                      Signature Upload
                    </RadioControl>
                  </RadioGroupControl>
                </div>
              </div>
              {uploadView === UploadType.SignaturePad ? (
                <>
                  <SignatureCanvas
                    ref={(ref) => (sigCanvas = ref)}
                    canvasProps={{ className: 'sigCanvas' }}
                    onBegin={() => setError('')}
                  />
                  <ButtonRow>
                    <SecondaryButton
                      //   @ts-ignore
                      onClick={() => sigCanvas.clear()}
                      size={ButtonSizeEnum.medium}
                    >
                      Clear
                    </SecondaryButton>
                    <PrimaryButton
                      onClick={trimAndUploadCanvasSignature}
                      size={ButtonSizeEnum.medium}
                    >
                      Save
                    </PrimaryButton>
                  </ButtonRow>
                </>
              ) : (
                <>
                  <SignatureDropzoneComponent signature={signature} setSignature={setSignature} />
                  <ButtonRow>
                    <PrimaryButton
                      onClick={uploadSignature}
                      disabled={!signature}
                      size={ButtonSizeEnum.medium}
                      title={!signature ? 'No signature set.' : ''}
                    >
                      Save
                    </PrimaryButton>
                  </ButtonRow>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
