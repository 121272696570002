import React, { createContext, ReactNode } from 'react';
import useUnreadMessages from './useUnreadMessages/useUnreadMessages';

export interface IChatContext {
  setMessageCount: React.Dispatch<React.SetStateAction<number>>;
  setIncreaseUnreadCounter: React.Dispatch<React.SetStateAction<boolean>>;
  unreadMessageCount: number;
}

export const ChatContext = createContext<IChatContext>(null!);

interface ChatProviderProps {
  children: ReactNode;
}

export function ChatProvider({ children }: ChatProviderProps) {
  const { setMessageCount, setIncreaseUnreadCounter, unreadMessageCount } = useUnreadMessages();

  return (
    <ChatContext.Provider
      value={{
        setMessageCount,
        setIncreaseUnreadCounter,
        unreadMessageCount,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}
