import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import { FastField } from 'formik';
import React from 'react';

export type CheckboxControlProps = CheckboxProps & {
  name: string;
};

export const CheckboxControl = (props: CheckboxControlProps) => {
  return <Checkbox iconColor="#E4E9F2" {...props} />;
};

export default CheckboxControl;

export type FormCheckboxControlProps = CheckboxControlProps;

const isChecked = (formValue: any, fieldValue: any) => {
  let checked;
  if (formValue instanceof Array) {
    checked = formValue.includes(fieldValue) ?? false;
  } else {
    checked = formValue;
  }
  return checked;
};

export const FormCheckboxControl = (props: FormCheckboxControlProps) => {
  const { name, children, ...rest } = props;

  return (
    <FastField name={name}>
      {({ field, meta }) => (
        <CheckboxControl
          {...field}
          isInvalid={!!meta.error && meta.touched}
          isChecked={isChecked(field.value, props.value)}
          name={name}
          {...rest}
        >
          {children}
        </CheckboxControl>
      )}
    </FastField>
  );
};
