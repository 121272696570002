import { useQuery } from 'react-query';
import { QueryKeys } from '../../keys';
import { httpRequest } from '../../../utils';
import { AuthClient, ProvinceState, ProvinceStatesClient } from '../../../generated';

interface IProps {
  countryID?: string;
}

export const useProvinceStatesByCountryID = ({ countryID }: IProps) => {
  const {
    isLoading: isLoadingProvinceStates,
    data: provinceStatesResult,
    error: provinceStatesError,
  } = useQuery<ProvinceState[], string>(
    [QueryKeys.ProvinceStatesByCountryID, countryID],
    async () => {
      const result = await httpRequest(() =>
        new ProvinceStatesClient(new AuthClient()).byCountryID(countryID!),
      );
      return result;
    },
    {
      enabled: !!countryID,
    },
  );
  return { isLoadingProvinceStates, provinceStatesResult, provinceStatesError };
};
