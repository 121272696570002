import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { SystemMessage } from '../../generated';
import {
  getSystemMessagesActions,
  GetSystemMessagesActionTypes,
  newSystemMessageAction,
} from './getSystemMessagesAction';

export interface IDismissibleSystemMessage {
  systemMessage: SystemMessage;
  isDismissed: boolean;
}

export interface ISystemMessagesState {
  error: string;
  isLoading: boolean;
  systemMessages: IDismissibleSystemMessage[];
}

const systemMessages = createReducer<IDismissibleSystemMessage[], GetSystemMessagesActionTypes>([])
  .handleAction([getSystemMessagesActions.success], (_state, action) => action.payload)
  .handleAction([newSystemMessageAction], (state, action) => {
    const systemMessage: IDismissibleSystemMessage = {
      systemMessage: action.payload,
      isDismissed: false,
    };
    return [...state, systemMessage];
  });

const error = createReducer<string, GetSystemMessagesActionTypes>('')
  .handleAction([getSystemMessagesActions.failure], (_state, action) => action.payload)
  .handleAction([getSystemMessagesActions.success], () => '');

const isLoading = createReducer<boolean, GetSystemMessagesActionTypes>(false)
  .handleAction([getSystemMessagesActions.request], () => true)
  .handleAction([getSystemMessagesActions.failure, getSystemMessagesActions.success], () => false);

export default combineReducers<ISystemMessagesState>({
  systemMessages,
  error,
  isLoading,
});
