import { useEffect, useState } from 'react';

import { usePatientPreferredLabGet } from '../../../../services/patients/queries/usePatientPreferredLabGet';
import { AddressBook } from '../../../../generated';

type UseSelectLabOptionsProps = {
  onCloseModal?: () => void;
  patientID: string;
  selectedAddress?: AddressBook;
  onChangeAddress: (value: AddressBook) => void;
  isLoading?: boolean;
  preSelectedAddressBook?: AddressBook;
};

const useSelectLabOptions = (props: UseSelectLabOptionsProps) => {
  const { onChangeAddress } = props;

  const [selectMedicationFirst, setSelectMedicationFirst] = useState(false);

  const { isLoadingPreferredLab, preferredLab, preferredLabError } = usePatientPreferredLabGet({
    patientID: props.patientID,
  });

  const toggleSelectMedicationFirst = () => {
    setSelectMedicationFirst(!selectMedicationFirst);
  };

  const selectedLabID = props.selectedAddress?.associatedID;

  const selectedLabAddressBookID = props.selectedAddress?.addressBookID;

  const preferredLabAddress = preferredLab?.addressBook;

  const isShowingPreferredLab = preferredLabAddress?.associatedID === selectedLabID;

  const hasSelectedLab = !!props.selectedAddress;

  const isContinueDisabled = !hasSelectedLab && !selectMedicationFirst;

  const onContinue = () => {
    if (isContinueDisabled) return;
    props.onCloseModal?.();
  };

  useEffect(() => {
    // If preselected lab address is available, stick to it
    if (
      props.preSelectedAddressBook &&
      props.preSelectedAddressBook.associatedID !== selectedLabID
    ) {
      onChangeAddress(props.preSelectedAddressBook);
      return;
    }

    // We never want to set any other address if preselected lab address is available
    if (props.preSelectedAddressBook) {
      return;
    }

    // We need to select the preferred lab if it is available and no other lab has been selected
    if (!preferredLabAddress || selectedLabID) return;

    if (preferredLabAddress.associatedID === selectedLabID) return;

    if (selectedLabAddressBookID) return;

    onChangeAddress(preferredLabAddress);
  }, [
    preferredLabAddress,
    onChangeAddress,
    selectedLabID,
    selectedLabAddressBookID,
    props.preSelectedAddressBook,
  ]);

  return {
    selectMedicationFirst,
    toggleSelectMedicationFirst,
    onContinue,
    isContinueDisabled,
    isLoadingPreferredLab,
    preferredLab,
    preferredLabError,
    hasSelectedLab,
    isShowingPreferredLab,
  };
};

export { useSelectLabOptions };
