import { Box, Button, Flex, HStack, Link, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { CalendarIcon, EyeIcon, UserIcon, VerifiedShieldIcon } from '../../../assets/icons';
import LightGreenCircle from '../../../assets/images/light-green-circle.svg';
import { SecondaryButton } from '../../../components/Buttons';
import { ButtonSizeEnum, TimeFormatEnum } from '../../../constants';
import {
  ConsultTagTypeEnum,
  Country,
  PHRConsult,
  PHRDocument,
  PHRDocumentCategoryEnum,
  RolesEnum,
} from '../../../generated';
import { AppState } from '../../../store/root-reducers';
import { getTime } from '../../../utils';
import { displayValue } from '../../../utils/displayValue';
import { isInRole } from '../../../utils/isInRole';
import { ButtonRow } from '../components/StyledPhrItems';

const ListItem = ({ title, description }) => (
  <Flex w="100%" m={1}>
    <Box w="30%">
      <Text variant="body">{title}</Text>
    </Box>
    <Box>{description}</Box>
  </Flex>
);

const renderTags = (phrConsult: PHRConsult, consultTagTypeEnum: ConsultTagTypeEnum) => {
  //TODO: Change these to theme
  return phrConsult.phrConsultTags
    ?.filter((y) => y.consultTagTypeEnum === consultTagTypeEnum)
    .map((z) => (
      <Box
        backgroundColor="#F4F6FA"
        color="#1B3D71"
        key={z.phrConsultTagID}
        p={1}
        px={2}
        borderRadius="full"
        fontSize="12px"
      >
        {z.tag?.description}
      </Box>
    ));
};

interface IProps {
  title: string;
  isDownloading: boolean;
  date: string;
  phrConsult: PHRConsult;
  countries: Country[] | undefined;
  downloadChatTranscript: (phrConsult: PHRConsult) => Promise<void>;
  handleView: (document: PHRDocument) => void;
  download: (patientID: string, phrDocumentID: string) => Promise<void>;
  onEdit?: (phrConsult: PHRConsult) => void;
  onView: (phrConsult: PHRConsult) => void;
  onDelete?: (phrConsultID: string) => void;
}

export const ConsultItem = ({
  title,
  isDownloading,
  date,
  phrConsult,
  countries,
  downloadChatTranscript,
  handleView,
  download,
  onView,
}: IProps) => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  const { isOpen } = useDisclosure();

  return (
    <>
      <Flex
        width="100%"
        height="fit-content"
        backgroundColor="#ffffff"
        border="1.5px solid #616A8891"
        borderRadius="12px"
        paddingY="1.25rem"
        paddingX={{ base: '0.75rem', md: '1.5rem' }}
        backgroundImage={LightGreenCircle}
        backgroundRepeat="no-repeat"
        backgroundPosition="calc(100% + 100px) -110px"
        mb={4}
        onClick={() => onView(phrConsult)}
        cursor="pointer"
      >
        <Box flexGrow={1} height="100%">
          <Flex justify="space-between" align="flex-start">
            <Text
              fontSize="16px"
              fontWeight="medium"
              color="#020202"
              marginRight={{ base: '0.5rem', md: '1rem' }}
              cursor="pointer"
            >
              Reason: {title}
            </Text>

            <VerifiedShieldIcon height="22px" color={'#418A94'} width="22px" />
          </Flex>
          <Box mt={2} cursor="pointer" onClick={() => onView(phrConsult)}>
            <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: 2, md: 6 }}>
              {phrConsult.providerName && (
                <HStack>
                  <UserIcon />
                  <Text variant="body" fontSize={'14px'}>
                    {phrConsult.providerName}
                  </Text>
                </HStack>
              )}
              <HStack>
                <CalendarIcon />
                <Text variant="body" fontSize={'14px'}>
                  {date}
                </Text>
              </HStack>
            </Stack>
          </Box>

          <Box
            transition=".2s ease"
            h={isOpen ? 'auto' : '0px'}
            p={isOpen ? 4 : 0}
            overflow="hidden"
            background={phrConsult.isCancelled ? '#ff928f' : '#e2f3f1'}
            mt={4}
            borderRadius="18px"
          >
            <ListItem
              title="Patient Location"
              description={
                <Text variant="body">
                  {phrConsult.patientCountryID
                    ? (countries ?? []).find((y) => y.countryID === phrConsult.patientCountryID)
                        ?.name
                    : phrConsult.country?.name}
                </Text>
              }
            />

            <ListItem
              title={isInRole(currentUser, RolesEnum.Patient) ? 'Complaints' : 'Subjective'}
              description={
                <Text variant="body">
                  {phrConsult.subjective}
                  {renderTags(phrConsult, ConsultTagTypeEnum.Subjective)}
                </Text>
              }
            />
            <ListItem
              title={isInRole(currentUser, RolesEnum.Patient) ? 'What we found' : 'Objective'}
              description={
                <Text variant="body">
                  {phrConsult.objective}
                  {renderTags(phrConsult, ConsultTagTypeEnum.Objective)}
                </Text>
              }
            />
            <ListItem
              title={isInRole(currentUser, RolesEnum.Patient) ? 'Diagnosis' : 'Diagnosis Notes'}
              description={
                <Text variant="body">
                  {phrConsult.diagnosis}
                  {renderTags(phrConsult, ConsultTagTypeEnum.Diagnosis)}
                </Text>
              }
            />
            <ListItem
              title={isInRole(currentUser, RolesEnum.Patient) ? 'What you should do now' : 'Plan'}
              description={
                <Text variant="body">
                  {phrConsult.plan}
                  {renderTags(phrConsult, ConsultTagTypeEnum.Plan)}
                </Text>
              }
            />
            <ListItem
              title="Reason"
              description={
                <Text variant="body">
                  {phrConsult.complaint}
                  {renderTags(phrConsult, ConsultTagTypeEnum.Complaint)}
                </Text>
              }
            />
            <ListItem
              title="Consult Details"
              description={
                <Button
                  variant="link"
                  fontSize={'14px'}
                  onClick={() => downloadChatTranscript(phrConsult)}
                  isLoading={isDownloading}
                  rightIcon={<FontAwesomeIcon icon={['far', 'cloud-download']} />}
                  color="#000"
                >
                  Download
                </Button>
              }
            />
            {phrConsult.isCancelled && (
              <ListItem
                title="Cancel Reason"
                description={<Text variant="body">{phrConsult.consult?.cancelReason}</Text>}
              />
            )}
            <ListItem
              title="Consult Documents"
              description={
                <Text variant="body">
                  {(phrConsult.phrDocuments?.length ?? 0) === 0 ? (
                    <span>-</span>
                  ) : (
                    phrConsult.phrDocuments
                      ?.sort((a, b) => getTime(b.upsertDate) - getTime(a.upsertDate))
                      .filter(
                        (x) =>
                          (isInRole(currentUser, RolesEnum.Patient) &&
                            x.phrDocumentCategoryEnum !==
                              PHRDocumentCategoryEnum.PrescriptionNote) ||
                          isInRole(currentUser, RolesEnum.Provider),
                      )
                      .map((x) => (
                        <React.Fragment key={x.phrDocumentID}>
                          <Link onClick={() => handleView(x)}>
                            {x.fileDetail?.name} <EyeIcon />
                          </Link>
                          <br />
                          <Button
                            variant="link"
                            fontSize={'14px'}
                            onClick={() => download(x.patientID, x.phrDocumentID)}
                            rightIcon={<FontAwesomeIcon icon={['far', 'cloud-download']} />}
                          >
                            Download
                          </Button>
                          <br />
                        </React.Fragment>
                      ))
                  )}
                </Text>
              }
            />

            {isInRole(currentUser, RolesEnum.Provider) && phrConsult.attestationUserDetailID && (
              <>
                <ListItem
                  title={phrConsult.isAttested ? 'Attested By' : 'To Be Attested By'}
                  description={
                    <Text variant="body">
                      {displayValue(phrConsult.attestationProvider?.fullName)}
                    </Text>
                  }
                />
                <ListItem
                  title="Attestation Date"
                  description={
                    <Text variant="body">
                      {phrConsult.attestationDate
                        ? moment(phrConsult.attestationDate).format(TimeFormatEnum.LONG_DATE_TIME)
                        : '-'}
                    </Text>
                  }
                />
                <ListItem
                  title="Attestation"
                  description={<Text variant="body">{displayValue(phrConsult.attestation)}</Text>}
                />
              </>
            )}
            <ButtonRow>
              <SecondaryButton size={ButtonSizeEnum.medium} onClick={() => onView(phrConsult)}>
                View
              </SecondaryButton>
            </ButtonRow>
          </Box>
        </Box>
      </Flex>
    </>
  );
};
