import jwt_decode from 'jwt-decode';
import { RolesEnum } from '../generated';
import { ICurrentUser } from '../store/currentUser/currentUserReducers';

export function decodeJwtToCurrentUser(
  token: string,
  isInMobileView: boolean | undefined = false,
  sysParam: 'android' | 'ios' | undefined = undefined,
): ICurrentUser | null {
  const decodedToken = jwt_decode(token) as Record<string, any>;
  const fullName = [
    decodedToken['http://outpost.health/Title'],
    decodedToken['http://outpost.health/FirstName'],
    decodedToken['http://outpost.health/LastName'],
  ]
    .join(' ')
    .trim();

  let roles: RolesEnum[] = [];
  const role = decodedToken.role;
  if (role instanceof Array) {
    roles = role;
  } else {
    const decodedRole: RolesEnum = (RolesEnum as any)[role];
    roles.push(decodedRole);
  }

  const currentUser: ICurrentUser = {
    nameID: decodedToken.nameid,
    uniqueName: decodedToken.unique_name,
    tenantID: decodedToken['http://outpost.health/TenantID'],
    cultureInfo: decodedToken['http://outpost.health/CultureInfo'],
    userDetailID: decodedToken['http://outpost.health/UserDetailID'],
    firstName: decodedToken['http://outpost.health/FirstName'],
    lastName: decodedToken['http://outpost.health/LastName'],
    imageDetailID: decodedToken['http://outpost.health/ImageDetailID'],
    uniqueLifeTimeID: decodedToken['http://outpost.health/UniqueLifetimeID'],
    emailAddress: decodedToken['http://outpost.health/EmailAddress'],
    title: decodedToken['http://outpost.health/Title'],
    fullName: fullName,
    roles: roles,
    exp: decodedToken.exp,
    isInMobileView,
    mobileSystem: sysParam,
  };

  if (decodedToken['http://outpost.health/DelegateID']) {
    currentUser.delegateJWT = token;
  }

  return currentUser;
}
