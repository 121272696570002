import { Box } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import useTranslationComponent from '../../hooks/useTranslationComponent';

import { SelectComponent } from '../forms';

import { useLocationSearch } from './hooks';

type LocationSearchProps = {
  onSelectPlace: (place?: google.maps.places.PlaceResult) => void;
  handleSetPlaceDetails?: (placeResult: google.maps.places.PlaceResult | null) => void;
  isDisabled?: boolean;
  debounceTimeout?: number;
  selectedPlace?: google.maps.places.PlaceResult;
};

const LocationIcon = () => (
  <Box mr="2">
    <FontAwesomeIcon icon={['fal', 'map-marker-alt']} />
  </Box>
);

const LocationSearch: FC<LocationSearchProps> = (props) => {
  const { t } = useTranslationComponent();
  const controller = useLocationSearch({
    handleSetPlaceDetails: props.handleSetPlaceDetails,
    debounceTimeout: props.debounceTimeout,
    selectedPlace: props.selectedPlace,
  });

  return (
    <SelectComponent
      isDisabled={props.isDisabled}
      options={controller.placePredictions}
      name="placePredictions"
      valueKey="place_id"
      labelKey="description"
      placeholder={
        controller.isPlacePredictionsLoading ? t('Please wait...') : t('Type to Search Location')
      }
      value={props.selectedPlace}
      onChange={(value?: google.maps.places.PlaceResult) => {
        props.onSelectPlace(value);
      }}
      menuPosition="fixed"
      components={{
        DropdownIndicator: LocationIcon,
      }}
      noOptionsMessage={() => t('Start typing...')}
      isSearchable
      isLoading={controller.isPlacePredictionsLoading}
      onInputChange={(query) => {
        controller.getPlacePredictions({ input: query });
      }}
      customStyles={{ height: '48px' }}
    />
  );
};

export { LocationSearch };
