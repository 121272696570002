import { TooltipRenderProps } from 'react-joyride';
import styled from 'styled-components';
import { BorderRadius, ButtonSizeEnum, Device, SpacingRelated } from '../../constants';

const TooltipBody = styled.div`
  background-color: white;
  border-radius: 20px;
  padding: ${SpacingRelated.Regular};
  width: 290px;

  @media ${Device.mobileL} {
    width: 380px;
  }
`;

const TooltipTitle = styled.h2``;

const TooltipContent = styled.div`
  text-align: center;
  font-size: 16px;
`;

const TooltipFooter = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.Colors.Primary};
  margin-top: ${SpacingRelated.HalfRegular};
  padding-top: ${SpacingRelated.HalfRegular};
`;

interface IButtonProps {
  readonly inverse: boolean;
}

const Button = styled.button.attrs(() => ({
  size: ButtonSizeEnum.medium,
}))<IButtonProps>`
  margin-right: 8px;
  background-color: ${(props) => (props.inverse ? 'white' : props.theme.Colors.Primary)};
  border: 1px solid ${(props) => props.theme.Colors.Primary};
  color: ${(props) => (props.inverse ? props.theme.Colors.Primary : 'white')};
  padding: 10px 14px;
  border-radius: ${BorderRadius.Buttons};
`;

const FormattedMessage = styled.p``;

const SkipWrapper = styled.div``;

const ButtonWrapper = styled.div`
  ${Button}:last-of-type {
    margin-right: 0px;
  }
`;

interface IProps extends TooltipRenderProps {
  customContent?: any;
}

const TooltipComponent = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  skipProps,
  tooltipProps,
  isLastStep,
}: IProps) => {
  const { back, close, last, next, skip } = step.locale!;
  const { showSkipButton } = step;
  return (
    <TooltipBody {...tooltipProps}>
      {step.title && <TooltipTitle>{step.title}</TooltipTitle>}
      {/* @ts-ignore */}
      <TooltipContent>{step.content || step.customContent}</TooltipContent>
      <TooltipFooter>
        <SkipWrapper>
          {showSkipButton && !isLastStep && (
            <Button inverse {...skipProps}>
              <FormattedMessage id="skip">{skip}</FormattedMessage>
            </Button>
          )}
        </SkipWrapper>
        <ButtonWrapper>
          {index > 0 && (
            // @ts-ignore
            <Button {...backProps}>
              <FormattedMessage id="back">{back}</FormattedMessage>
            </Button>
          )}
          {continuous &&
            (!isLastStep ? (
              // @ts-ignore
              <Button {...primaryProps}>
                <FormattedMessage id="next">{next}</FormattedMessage>
              </Button>
            ) : (
              // @ts-ignore
              <Button {...primaryProps}>
                <FormattedMessage id="last">{last}</FormattedMessage>
              </Button>
            ))}
          {!continuous && (
            // @ts-ignore
            <Button {...closeProps}>
              <FormattedMessage id="close">{close}</FormattedMessage>
            </Button>
          )}
        </ButtonWrapper>
      </TooltipFooter>
    </TooltipBody>
  );
};

export default TooltipComponent;
