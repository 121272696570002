import styled from 'styled-components';
import { BorderRadius, ButtonSizeEnum, Device } from '../constants';
import { SecondaryButton } from './Buttons/SecondaryButton';
import { H4 } from './common/Header';

interface IWrapperProps {
  readonly inverse: boolean;
  readonly headerOutside: boolean;
  border?: string;
}

const Wrapper = styled.div<IWrapperProps>`
  position: relative;
  background-color: ${(props) => (props.inverse ? '#f3f3f3' : 'white')};
  border: ${(props) => (props.border ? props.border : '')};
  border-radius: ${BorderRadius.Regular};
  padding: 15px 1rem 15px;
  display: flex;
  flex-direction: column;

  @media ${Device.mobileL} {
    padding: 10px 25px 22px;
  }

  ${(props) =>
    props.headerOutside &&
    `
    margin-top: 30px;
  `}
`;

interface ITopLineProps {
  readonly headerOutside: boolean;
}

const TopLine = styled.div<ITopLineProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 18px;
  ${(props) =>
    props.headerOutside &&
    `
    position: absolute;
    top: -30px;

    @media ${Device.tablet} {
      top: -40px;
    }
  `}
`;

const CardHeader = styled(H4)`
  font-size: 17px;
  font-weight: 500;
  margin: 0;
  line-height: 25px;
  width: 100%;
`;

interface IProps {
  title?: any;
  actionText?: any;
  children?: any;
  onAction?: () => void;
  className?: string;
  onClick?: () => void;
  id?: string;
  inverse?: boolean;
  headerOutside?: boolean;
  border?: string;
}

const Card = ({
  title,
  actionText,
  children,
  onAction,
  className,
  onClick,
  id,
  inverse = false,
  headerOutside = false,
  border,
}: IProps) => {
  return (
    <Wrapper
      headerOutside={headerOutside}
      id={id}
      className={className}
      onClick={onClick}
      inverse={inverse}
      border={border}
    >
      {(title || actionText) && (
        <TopLine headerOutside={headerOutside}>
          <CardHeader>{title}</CardHeader>
          {actionText && (
            <SecondaryButton size={ButtonSizeEnum.medium} onClick={onAction}>
              {actionText}
            </SecondaryButton>
          )}
        </TopLine>
      )}
      {children}
    </Wrapper>
  );
};

export default Card;
