import { Box, chakra, Flex, Text, VStack } from '@chakra-ui/react';
import { ErrorMessage } from 'formik';
import moment from 'moment';
import ReactSelect from 'react-select';
import SimpleValue from 'react-select-simple-value';
import styled from 'styled-components';
import { SecondaryButton } from '../../../../../components/Buttons/SecondaryButton';
import {
  FormInputControl,
  FormMobisPicker,
  SelectComponent,
} from '../../../../../components/forms';
import { ButtonSizeEnum, SpacingRelated, TimeFormatEnum } from '../../../../../constants';
import {
  BloodGlucoseTypeEnum,
  HeightEnum,
  TemperatureTypeEnum,
  UserDetailSettingEnum,
  WeightEnum,
} from '../../../../../generated';
import { OutpostTheme } from '../../../../../themes/outpost';
import {
  convertBloodGlucose,
  convertHeight,
  convertHeight2,
  convertTemperature,
  convertWeight,
} from '../../../../../utils/conversionHelpers';
import { PatientVitalsCardContainer } from '../PatientVitalsCard/PatientVitalsCard';
import { VitalsTracker } from '../PatientVitalsCard/usePatientVitalsCard';

const defaultFormControlProps = {
  hideError: false,
  w: '120px',
  formLabelProps: { fontWeight: '600' },
};

export const renderPHRBloodPressures = () => (
  <Box
    padding={{ base: '1rem', md: '2rem 3rem' }}
    borderRadius="lg"
    width="100%"
    mb="2rem"
    mt="1rem"
    bgColor="#FFFFFF"
  >
    <Text fontSize="16px" fontWeight="semibold" color="#04101F" mb="1.25rem">
      Blood Pressure
    </Text>
    <Flex>
      <FormInputControl
        name="systolic"
        label="Systolic"
        type="text"
        height="48px"
        borderRadius="12px"
        bgColor="#F7F9FC"
        placeholder="Enter Value"
        formControlProps={{
          width: '140px',
          marginRight: '1rem',
          formLabelProps: {
            marginBottom: '1rem',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            fontSize: '12px',
          },
        }}
      />
      <FormInputControl
        name="diastolic"
        label="Diastolic"
        type="text"
        height="48px"
        borderRadius="12px"
        bgColor="#F7F9FC"
        placeholder="Enter Value"
        formControlProps={{
          width: '140px',
          formLabelProps: {
            marginBottom: '1rem',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            fontSize: '12px',
          },
        }}
      />
    </Flex>

    <Flex flexDir={{ base: 'column', md: 'row' }}>
      <FormMobisPicker
        name="bloodPressureDate"
        label="Date"
        controls={['calendar', 'time']}
        placeholder="DD/MM/YY"
        max={new Date()}
        min={moment().add(-150, 'year').toDate()}
        borderRadius="xl"
        containerProps={{
          height: '48px',
        }}
        formControlProps={{
          width: { base: '100%', md: '220px' },
          marginTop: { base: '1rem', md: 0 },
          marginRight: '1rem',
          formLabelProps: {
            marginBottom: '1rem',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            fontSize: '12px',
          },
        }}
      />
    </Flex>
  </Box>
);

export const renderPHRPulses = () => (
  <Box
    padding={{ base: '1rem', md: '2rem 3rem' }}
    borderRadius="lg"
    width="100%"
    mb="2rem"
    mt="1rem"
    bgColor="#FFFFFF"
  >
    <Text fontSize="16px" fontWeight="semibold" color="#04101F" mb="1.25rem">
      Heart Rate
    </Text>
    <Flex>
      <FormInputControl
        name="pulse"
        label="Pulse"
        type="text"
        height="48px"
        borderRadius="12px"
        bgColor="#F7F9FC"
        placeholder="Enter Value"
        formControlProps={{
          width: { base: '100%', md: '140px' },
          formLabelProps: {
            marginBottom: '1rem',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            fontSize: '12px',
          },
        }}
      />
    </Flex>

    <Flex flexDir={{ base: 'column', md: 'row' }}>
      <FormMobisPicker
        name="pulseDate"
        label="Date"
        controls={['calendar', 'time']}
        placeholder="DD/MM/YY"
        max={new Date()}
        min={moment().add(-150, 'year').toDate()}
        borderRadius="xl"
        containerProps={{
          height: '48px',
        }}
        formControlProps={{
          width: { base: '100%', md: '220px' },
          marginTop: { base: '1rem', md: 0 },
          marginRight: '1rem',
          formLabelProps: {
            marginBottom: '1rem',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            fontSize: '12px',
          },
        }}
      />
    </Flex>
  </Box>
);

export const renderPHRWeightHeights = (setFieldValue, values, tracker?: VitalsTracker) => (
  <PatientVitalsCardWrapper>
    <PatientVitalsCardContainer>
      <Flex justifyContent="space-between" w="100%" flexDirection={['column', 'column', 'row']}>
        <Flex alignItems="center">
          <FormInputControl
            name="weight"
            label="Weight"
            type="text"
            formControlProps={defaultFormControlProps}
          />
          <UnitWrapper minWidth="80px">
            <UnitSelect
              name="weightUnit"
              options={[
                { value: WeightEnum.Kg, label: WeightEnum.Kg },
                { value: WeightEnum.Lbs, label: WeightEnum.Lbs },
              ]}
              handleChange={(option) => {
                if (values.weight) {
                  if (option.value === WeightEnum.Kg) {
                    setFieldValue(
                      'weight',
                      convertWeight(true, values.weight, WeightEnum.Kg, WeightEnum.Lbs),
                    );
                  } else {
                    setFieldValue(
                      'weight',
                      convertWeight(true, values.weight, WeightEnum.Lbs, WeightEnum.Kg),
                    );
                  }
                }
                setFieldValue('weightUnit', option.value);
              }}
              value={values.weightUnit}
            />
          </UnitWrapper>
          {values.heightUnit === HeightEnum.In ? (
            <>
              <FormInputControl
                name="heightFt"
                label="Height"
                type="text"
                formControlProps={defaultFormControlProps}
              />
              <Text mx={4} mt={6} whiteSpace="nowrap">
                ft
              </Text>
              <FormInputControl
                name="heightIn"
                label=""
                type="text"
                formControlProps={defaultFormControlProps}
                mt={8}
              />
            </>
          ) : (
            <FormInputControl
              name="heightCm"
              label="Height"
              type="text"
              formControlProps={defaultFormControlProps}
            />
          )}
          <UnitWrapper minWidth="80px">
            <UnitSelect
              name="heightUnit"
              options={[
                { value: HeightEnum.Cm, label: HeightEnum.Cm.toLowerCase() },
                { value: HeightEnum.In, label: HeightEnum.In.toLowerCase() },
              ]}
              handleChange={(option) => {
                if (option.value === HeightEnum.Cm && (values.heightFt || values.heightIn)) {
                  const heightValues = convertHeight2(
                    true,
                    { feet: values.heightFt, inches: values.heightIn },
                    HeightEnum.Cm,
                    HeightEnum.In,
                  );
                  setFieldValue('heightCm', heightValues.centimeters);
                } else if (values.heightCm) {
                  const heightValues = convertHeight2(
                    true,
                    { centimeters: values.heightCm },
                    HeightEnum.In,
                    HeightEnum.Cm,
                  );
                  setFieldValue('heightFt', heightValues.feet);
                  setFieldValue('heightIn', heightValues.inches);
                }
                setFieldValue('heightUnit', option.value);
              }}
              value={values.heightUnit}
            />
          </UnitWrapper>
        </Flex>
        <Flex alignItems="center">
          <VStack spacing={1} align="stretch" mr={4}>
            {tracker && (
              <>
                <label>{'Last entered:'}</label>
                <div>{`${moment(tracker.upsertDate).format(TimeFormatEnum.LONG_DATE_TIME)}`}</div>
                <div>{`by: ${tracker.createdByName}`}</div>
              </>
            )}
          </VStack>
          <Flex justifyContent="flex-start">
            <ButtonClear
              handleClick={() => {
                setFieldValue('heightCm', '');
                setFieldValue('heightFt', '');
                setFieldValue('heightIn', '');
                setFieldValue('weight', '');
              }}
              small={tracker ? true : false}
            />
          </Flex>
        </Flex>
      </Flex>
    </PatientVitalsCardContainer>
    <ErrorMessageWrapper>
      <ErrorMessage name="heightCm" />
    </ErrorMessageWrapper>
    <ErrorMessageWrapper>
      <ErrorMessage name="weight" />
    </ErrorMessageWrapper>
  </PatientVitalsCardWrapper>
);

export const renderPHRTemperatures = (setFieldValue, values, userDetailSettings) => {
  const unitValue = userDetailSettings?.filter(
    (x) => x.userDetailSettingEnum === UserDetailSettingEnum.Temperature,
  )[0].value;

  const options = [
    {
      value: TemperatureTypeEnum.Celsius,
      label: TemperatureTypeEnum.Celsius,
    },
    {
      value: TemperatureTypeEnum.Fahrenheit,
      label: TemperatureTypeEnum.Fahrenheit,
    },
  ];

  return (
    <Box
      padding={{ base: '1rem', md: '2rem 3rem' }}
      borderRadius="lg"
      width="100%"
      mb="2rem"
      mt="2rem"
      bgColor="#FFFFFF"
    >
      <Text fontSize="16px" fontWeight="semibold" color="#04101F" mb="1.25rem">
        Temperature
      </Text>
      <Flex flexDir={{ base: 'column', md: 'row' }}>
        <FormInputControl
          name="temperature"
          label="Temperature"
          type="text"
          height="48px"
          borderRadius="12px"
          bgColor="#F7F9FC"
          placeholder="Enter Value"
          formControlProps={{
            width: { base: '100%', md: '140px' },
            marginRight: '1rem',
            formLabelProps: {
              marginBottom: '1rem',
              fontWeight: 'normal',
              textTransform: 'uppercase',
              fontSize: '12px',
            },
          }}
        />

        <SelectComponent
          label="Metric"
          name="temperatureUnit"
          isClearable={false}
          defaultValue={options.find((x) => x.value === unitValue)}
          options={options}
          components={{
            Placeholder: () => (
              <Text fontSize={OutpostTheme.FontSizes.body} color="#0516466E" paddingLeft="0.5rem">
                Select Metric
              </Text>
            ),
            IndicatorSeparator: null,
          }}
          onChange={(option) => {
            if (values.temperature) {
              if (option.value === TemperatureTypeEnum.Celsius) {
                setFieldValue(
                  'temperature',
                  convertTemperature(
                    true,
                    values.temperature,
                    TemperatureTypeEnum.Celsius,
                    TemperatureTypeEnum.Fahrenheit,
                  ),
                );
              } else {
                setFieldValue(
                  'temperature',
                  convertTemperature(
                    true,
                    values.temperature,
                    TemperatureTypeEnum.Fahrenheit,
                    TemperatureTypeEnum.Celsius,
                  ),
                );
              }
            }
            setFieldValue('temperatureUnit', option.value);
          }}
          styles={{
            control: (base) => ({
              ...base,
              height: '48px',
              borderRadius: '12px',
              backgroundColor: '#F7F9FC',
            }),
            menu: (base) => ({
              ...base,
              zIndex: 99,
            }),
          }}
          formControlProps={{
            width: { base: '100%', md: '200px' },
            marginRight: '1rem',
            formLabelProps: {
              marginBottom: '1rem',
              fontWeight: 'normal',
              textTransform: 'uppercase',
              fontSize: '12px',
            },
          }}
        />
      </Flex>

      <Flex flexDir={{ base: 'column', md: 'row' }}>
        <FormMobisPicker
          name="temperatureDate"
          label="Date"
          controls={['calendar', 'time']}
          placeholder="DD/MM/YY"
          max={new Date()}
          min={moment().add(-150, 'year').toDate()}
          borderRadius="xl"
          containerProps={{
            height: '48px',
            // inputElementProps: {
            //   height: '48px',
            // },
          }}
          formControlProps={{
            width: { base: '100%', md: '220px' },
            marginTop: { base: '1rem', md: 0 },
            marginRight: '1rem',
            formLabelProps: {
              marginBottom: '1rem',
              fontWeight: 'normal',
              textTransform: 'uppercase',
              fontSize: '12px',
            },
          }}
        />
      </Flex>
    </Box>
  );
};

export const renderPHRRespiratories = () => (
  <Box
    padding={{ base: '1rem', md: '2rem 3rem' }}
    borderRadius="lg"
    width="100%"
    mb="2rem"
    mt="2rem"
    bgColor="#FFFFFF"
  >
    <Text fontSize="16px" fontWeight="semibold" color="#04101F" mb="1.25rem">
      Respiratory Rate
    </Text>
    <Flex align="center" flexDir={{ base: 'column', md: 'row' }}>
      <FormInputControl
        name="respiratory"
        label="Enter Respiratory Rate"
        type="text"
        height="48px"
        borderRadius="12px"
        bgColor="#F7F9FC"
        placeholder="Enter Value"
        width={{ base: '100%', md: '140px' }}
        formControlProps={{
          width: '100%',
          maxW: { base: '100%', md: '50%' },
          marginRight: { base: 0, md: '1rem' },
          formLabelProps: {
            marginBottom: '1rem',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            fontSize: '12px',
          },
        }}
      />
      <Text fontSize={OutpostTheme.FontSizes.body} marginLeft={{ base: 'auto', md: 0 }}>
        breaths / min
      </Text>
    </Flex>

    <Flex flexDir={{ base: 'column', md: 'row' }}>
      <FormMobisPicker
        name="respiratoryDate"
        label="Date"
        controls={['calendar', 'time']}
        placeholder="DD/MM/YY"
        max={new Date()}
        min={moment().add(-150, 'year').toDate()}
        borderRadius="xl"
        containerProps={{
          height: '48px',
        }}
        formControlProps={{
          width: { base: '100%', md: '220px' },
          marginTop: { base: '1rem', md: 0 },
          marginRight: '1rem',
          formLabelProps: {
            marginBottom: '1rem',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            fontSize: '12px',
          },
        }}
      />
    </Flex>
  </Box>
);

export const renderPHRBloodOxygens = () => (
  <Box
    padding={{ base: '1rem', md: '2rem 3rem' }}
    borderRadius="lg"
    width="100%"
    mb="2rem"
    mt="2rem"
    bgColor="#FFFFFF"
  >
    <Text fontSize="16px" fontWeight="semibold" color="#04101F" mb="1.25rem">
      Oxygen Saturation
    </Text>
    <Flex>
      <FormInputControl
        name="bloodOxygen"
        label="Enter SPO2"
        type="text"
        height="48px"
        borderRadius="12px"
        bgColor="#F7F9FC"
        placeholder="Enter Value"
        formControlProps={{
          width: { base: '100%', md: '140px' },
          formLabelProps: {
            marginBottom: '1rem',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            fontSize: '12px',
          },
        }}
      />
    </Flex>

    <Flex flexDir={{ base: 'column', md: 'row' }}>
      <FormMobisPicker
        name="bloodOxygenDate"
        label="Date"
        controls={['calendar', 'time']}
        placeholder="DD/MM/YY"
        max={new Date()}
        min={moment().add(-150, 'year').toDate()}
        borderRadius="xl"
        containerProps={{
          height: '48px',
          // inputElementProps: {
          //   height: '48px',
          // },
        }}
        formControlProps={{
          width: { base: '100%', md: '220px' },
          marginTop: { base: '1rem', md: 0 },
          marginRight: '1rem',
          formLabelProps: {
            marginBottom: '1rem',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            fontSize: '12px',
          },
        }}
      />
    </Flex>
  </Box>
);

export const renderPHRBloodGlucoses = (setFieldValue, values, userDetailSettings) => {
  const unitValue = userDetailSettings?.filter(
    (x) => x.userDetailSettingEnum === UserDetailSettingEnum.BloodGlucose,
  )[0].value;

  const options = [
    {
      value: BloodGlucoseTypeEnum.MmolL,
      label: BloodGlucoseTypeEnum.MmolL,
    },
    {
      value: BloodGlucoseTypeEnum.MgdL,
      label: BloodGlucoseTypeEnum.MgdL,
    },
  ];

  return (
    <Box
      padding={{ base: '1rem', md: '2rem 3rem' }}
      borderRadius="lg"
      width="100%"
      mb="2rem"
      mt="2rem"
      bgColor="#FFFFFF"
    >
      <Text fontSize="16px" fontWeight="semibold" color="#04101F" mb="1.25rem">
        Blood Glucose
      </Text>
      <Flex flexDir={{ base: 'column', md: 'row' }}>
        <FormInputControl
          name="bloodGlucose"
          label="Enter Blood Glucose Level"
          type="text"
          height="48px"
          borderRadius="12px"
          bgColor="#F7F9FC"
          placeholder="Enter Value"
          width={{ base: '100%', md: '140px' }}
          formControlProps={{
            width: { base: '100%', md: '220px' },
            marginRight: '1rem',
            formLabelProps: {
              marginBottom: '1rem',
              fontWeight: 'normal',
              textTransform: 'uppercase',
              fontSize: '12px',
            },
          }}
        />

        <SelectComponent
          label="Metric"
          name="bloodGlucoseUnit"
          isClearable={false}
          options={options}
          components={{
            Placeholder: () => (
              <Text fontSize={OutpostTheme.FontSizes.body} color="#0516466E" paddingLeft="0.5rem">
                Select Metric
              </Text>
            ),
            IndicatorSeparator: null,
          }}
          defaultValue={options.find((x) => x.value === unitValue)}
          onChange={(option) => {
            if (values.bloodGlucose) {
              if (option.value === BloodGlucoseTypeEnum.MmolL) {
                setFieldValue(
                  'bloodGlucose',
                  convertBloodGlucose(
                    true,
                    values.bloodGlucose,
                    BloodGlucoseTypeEnum.MmolL,
                    BloodGlucoseTypeEnum.MgdL,
                  ),
                );
              } else {
                setFieldValue(
                  'bloodGlucose',
                  convertBloodGlucose(
                    true,
                    values.bloodGlucose,
                    BloodGlucoseTypeEnum.MgdL,
                    BloodGlucoseTypeEnum.MmolL,
                  ),
                );
              }
            }
            setFieldValue('bloodGlucoseUnit', option.value);
          }}
          styles={{
            control: (base) => ({
              ...base,
              height: '48px',
              borderRadius: '12px',
              backgroundColor: '#F7F9FC',
            }),
            menu: (base) => ({
              ...base,
              zIndex: 99,
            }),
          }}
          formControlProps={{
            width: { base: '100%', md: '200px' },
            marginRight: '1rem',
            formLabelProps: {
              marginBottom: '1rem',
              fontWeight: 'normal',
              textTransform: 'uppercase',
              fontSize: '12px',
            },
          }}
        />
      </Flex>

      <Flex flexDir={{ base: 'column', md: 'row' }}>
        <FormMobisPicker
          name="bloodGlucoseDate"
          label="Date"
          controls={['calendar', 'time']}
          placeholder="DD/MM/YY"
          max={new Date()}
          min={moment().add(-150, 'year').toDate()}
          borderRadius="xl"
          containerProps={{
            height: '48px',
          }}
          formControlProps={{
            width: { base: '100%', md: '220px' },
            marginTop: { base: '1rem', md: 0 },
            marginRight: '1rem',
            formLabelProps: {
              marginBottom: '1rem',
              fontWeight: 'normal',
              textTransform: 'uppercase',
              fontSize: '12px',
            },
          }}
        />
      </Flex>
    </Box>
  );
};

export const renderPHRHeadCircumferences = (setFieldValue, values, tracker?: VitalsTracker) => (
  <PatientVitalsCardWrapper>
    <PatientVitalsCardContainer>
      <Flex justifyContent="space-between" w="100%" flexDirection={['column', 'column', 'row']}>
        <Flex alignItems="center">
          <FormInputControl
            name="headCircumference"
            label="Head Circumference"
            type="text"
            formControlProps={defaultFormControlProps}
          />
          <UnitWrapper>
            <UnitSelect
              name="headCircumferenceUnit"
              options={[
                {
                  value: HeightEnum.Cm,
                  label: HeightEnum.Cm.toLowerCase(),
                },
                {
                  value: HeightEnum.In,
                  label: HeightEnum.In.toLowerCase(),
                },
              ]}
              handleChange={(option) => {
                if (values.headCircumference) {
                  if (option.value === HeightEnum.Cm) {
                    setFieldValue(
                      'headCircumference',
                      convertHeight(true, values.headCircumference, HeightEnum.Cm, HeightEnum.In),
                    );
                  } else {
                    setFieldValue(
                      'headCircumference',
                      convertHeight(true, values.headCircumference, HeightEnum.In, HeightEnum.Cm),
                    );
                  }
                }
                setFieldValue('headCircumferenceUnit', option.value);
              }}
              value={values.headCircumferenceUnit}
            />
          </UnitWrapper>
        </Flex>
        <Flex alignItems="center">
          <VStack spacing={1} align="stretch" mr={4}>
            {tracker && (
              <>
                <label>{'Last entered:'}</label>
                <div>{`${moment(tracker.upsertDate).format(TimeFormatEnum.LONG_DATE_TIME)}`}</div>
                <div>{`by: ${tracker.createdByName}`}</div>
              </>
            )}
          </VStack>
          <Flex justifyContent="flex-start">
            <ButtonClear
              handleClick={() => {
                setFieldValue('headCircumference', '');
              }}
              small={tracker ? true : false}
            />
          </Flex>
        </Flex>
      </Flex>
    </PatientVitalsCardContainer>
    <ErrorMessageWrapper>
      <ErrorMessage name="headCircumference" />
    </ErrorMessageWrapper>
  </PatientVitalsCardWrapper>
);

export const renderPHRLengths = (setFieldValue, values, tracker?: VitalsTracker) => (
  <PatientVitalsCardWrapper>
    <PatientVitalsCardContainer>
      <Flex justifyContent="space-between" w="100%" flexDirection={['column', 'column', 'row']}>
        <Flex alignItems="center">
          <FormInputControl
            name="length"
            label="Length"
            type="text"
            formControlProps={defaultFormControlProps}
          />
          <UnitWrapper>
            <UnitSelect
              name="lengthUnit"
              options={[
                {
                  value: HeightEnum.Cm,
                  label: HeightEnum.Cm.toLowerCase(),
                },
                {
                  value: HeightEnum.In,
                  label: HeightEnum.In.toLowerCase(),
                },
              ]}
              handleChange={(option) => {
                if (values.length) {
                  if (option.value === HeightEnum.Cm) {
                    setFieldValue(
                      'length',
                      convertHeight(true, values.length, HeightEnum.Cm, HeightEnum.In),
                    );
                  } else {
                    setFieldValue(
                      'length',
                      convertHeight(true, values.length, HeightEnum.In, HeightEnum.Cm),
                    );
                  }
                }
                setFieldValue('lengthUnit', option.value);
              }}
              value={values.lengthUnit}
            />
          </UnitWrapper>
        </Flex>
        <Flex alignItems="center">
          <VStack spacing={1} align="stretch" mr={4}>
            {tracker && (
              <>
                <label>{'Last entered:'}</label>
                <div>{`${moment(tracker.upsertDate).format(TimeFormatEnum.LONG_DATE_TIME)}`}</div>
                <div>{`by: ${tracker.createdByName}`}</div>
              </>
            )}
          </VStack>
          <Flex justifyContent="flex-start">
            <ButtonClear
              handleClick={() => {
                setFieldValue('length', '');
              }}
              small={tracker ? true : false}
            />
          </Flex>
        </Flex>
      </Flex>
    </PatientVitalsCardContainer>
    <ErrorMessageWrapper>
      <ErrorMessage name="length" />
    </ErrorMessageWrapper>
  </PatientVitalsCardWrapper>
);

const UnitSelect = ({ options, value, handleChange, ...props }) => (
  <SimpleValue options={options} value={value}>
    {(simpleProps) => (
      <ReactSelect
        {...props}
        onChange={(val: any) => {
          handleChange?.(val);
        }}
        {...simpleProps}
      />
    )}
  </SimpleValue>
);

interface IButtonClearProps {
  handleClick: () => void;
  small: boolean;
}
const ButtonClear = ({ handleClick, small }: IButtonClearProps) => (
  <SecondaryButton
    size={small ? ButtonSizeEnum.small : ButtonSizeEnum.medium}
    onClick={handleClick}
  >
    Clear
  </SecondaryButton>
);

const PatientVitalsCardWrapper = styled.div`
  margin-bottom: ${SpacingRelated.HalfRegular};
`;

const ErrorMessageWrapper = styled.div`
  margin-top: 6px;
  margin-left: 24px;
  font-size: 0.85rem;
  color: red;
`;

const UnitWrapper = chakra(Box, {
  baseStyle: {
    mx: 3,
    mt: 4,
    fontSize: 'sm',
    minWidth: '120px',
  },
});
