import React, { useState, useEffect } from 'react';
import { Box, Flex, Checkbox, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';

import { ButtonSizeEnum } from '../../../../constants';
import { IPHRPatientEnhanced } from '../../../../store/patient/phr/phrReducers';
import { updateForPhrSection } from '../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import { scrollToTop } from '../../../../utils/scrollToTop';

import { PrimaryButton } from '../../../../components/Buttons';
import { FormInputControl } from '../../../../components/forms';
import { OutpostTheme } from '../../../../themes/outpost';

interface IForm {
  reason: string;
}

interface IProps {
  patient: IPHRPatientEnhanced;
}

export const CovidVaccinationExemption = ({ patient }: IProps) => {
  const dispatch = useDispatch();
  const [covidVaccinated, setCovidVaccinated] = useState<boolean>(!!patient.covidVaccinated);

  useEffect(() => {
    scrollToTop();
  }, []);

  const submit = async (values: IForm) => {
    patient.covidVaccinated = covidVaccinated;
    patient.covidVaccinationReason = covidVaccinated ? '' : values.reason;
    await dispatch(
      updateForPhrSection(ReduxPHRCategoryEnum.PHRPatients, patient, patient.patientID, false),
    );
  };

  const initialValues: IForm = {
    reason: patient.covidVaccinationReason || '',
  };

  return (
    <Box margin="1.5rem">
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          reason: string()
            .required('A vaccination name is required')
            .max(100, 'Reason must be at most 100 characters'),
        })}
        onSubmit={submit}
      >
        {() => (
          <Form>
            <>
              <Checkbox
                marginTop="0.5rem"
                marginBottom="1rem"
                defaultChecked={covidVaccinated}
                checked={covidVaccinated}
                onChange={() => setCovidVaccinated((prev) => !prev)}
              >
                <Text fontSize={OutpostTheme.FontSizes.body} color="#011431F0">
                  Patient can not / should not / and has chosen not to be vaccinated
                </Text>
              </Checkbox>
              {covidVaccinated && (
                <FormInputControl
                  name="reason"
                  label="Reason"
                  height="48px"
                  borderRadius="lg"
                  formControlProps={{
                    formLabelProps: {
                      textTransform: 'uppercase',
                      color: '#01143173',
                      fontSize: '12px',
                      fontWeight: 'light',
                    },
                  }}
                />
              )}
              {covidVaccinated && (
                <Flex
                  justify="flex-end"
                  align="center"
                  position="sticky"
                  bottom="-1px"
                  paddingY="1rem"
                >
                  <PrimaryButton size={ButtonSizeEnum.medium} type="submit" borderRadius="full">
                    Save
                  </PrimaryButton>
                </Flex>
              )}
            </>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
