import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { AppFeature } from '../../generated';
import { AppFeaturesActionTypes, getAppFeaturesActions } from './appFeaturesActions';

const appFeatures = createReducer<AppFeature[], AppFeaturesActionTypes>([]).handleAction(
  [getAppFeaturesActions.success],
  (_state, action) => action.payload,
);

const isLoading = createReducer<boolean, AppFeaturesActionTypes>(false).handleAction(
  [getAppFeaturesActions.success, getAppFeaturesActions.failure],
  () => false,
);

const error = createReducer<string, AppFeaturesActionTypes>('').handleAction(
  [getAppFeaturesActions.failure],
  (_state, action) => action.payload,
);

export interface IAppFeaturesState {
  appFeatures: AppFeature[];
  error: string;
  isLoading: boolean;
}

export default combineReducers<IAppFeaturesState>({
  appFeatures,
  error,
  isLoading,
});
