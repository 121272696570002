import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthClient, Tag, TagsClient, TagTypeEnum } from '../../../../generated';
import { getPHRPatient } from '../../../../store/patient/phr/phrCreators';
import { IPHRPatientEnhanced } from '../../../../store/patient/phr/phrReducers';
import { getAllForPhrSection } from '../../../../store/root-creator';
import { AppState } from '../../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import { httpRequest } from '../../../../utils';

export const useAllergiesData = (phr: IPHRPatientEnhanced) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppState) => state.currentUserState.data!);
  const { phrPatient, isLoading, error } = useSelector((state: AppState) => state.phrPatientState);
  const phrAllergies = useSelector(
    (state: AppState) =>
      (state.phrPatientState.phrPatient && state.phrPatientState.phrPatient.phr.phrAllergies) ?? [],
  );
  const [allergyTags, setAllergyTags] = useState<Tag[]>([]);
  const [allergyReactionTags, setAllergyReactionTags] = useState<Tag[]>([]);
  const tagNameMapRef = useRef({});

  const arrayToMap = (tags) => {
    const reducer = (acc, cur) => {
      return Object.assign(acc, {
        [cur.tagID]: cur.name,
      });
    };
    return tags.reduce(reducer, {});
  };

  useEffect(() => {
    if (phr) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRAllergies, phr.patientID));
    }
  }, [dispatch, phr]);

  useEffect(() => {
    if (!phrPatient) {
      dispatch(getPHRPatient(phr.patientID));
    }
  }, [dispatch, phr.patientID, phrPatient]);

  useEffect(() => {
    const getTags = async (tagType: TagTypeEnum) => {
      const client = new TagsClient(new AuthClient());
      const tags = await httpRequest(() => client.allByTagType(tagType));
      switch (tagType) {
        case TagTypeEnum.Allergy:
          setAllergyTags(
            tags.sort((a, b) => a.name!.toLowerCase().localeCompare(b.name!.toLowerCase())),
          );
          break;
        case TagTypeEnum.AllergyReaction:
          setAllergyReactionTags(
            tags.sort((a, b) => a.name!.toLowerCase().localeCompare(b.name!.toLowerCase())),
          );
          tagNameMapRef.current = arrayToMap(tags);
          break;
        default:
          break;
      }
    };

    getTags(TagTypeEnum.Allergy);
    getTags(TagTypeEnum.AllergyReaction);
  }, []);

  return {
    isLoading,
    error,
    currentUser,
    phrPatient,
    phrAllergies,
    allergyTags,
    allergyReactionTags,
    tagNameMap: tagNameMapRef.current,
  };
};
