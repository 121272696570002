import { useSelector } from 'react-redux';
import { RolesEnum } from '../generated';
import { AppState } from '../store/root-reducers';
import { isInRole } from '../utils/isInRole';

interface IProps {
  roles: RolesEnum | RolesEnum[];
  children?: any;
}

const FeatureAccessComponent = ({ roles, children }: IProps) => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  return isInRole(currentUser, roles) ? children : null;
};

export default FeatureAccessComponent;
