import React from 'react';
import moment from 'moment';
import { Box, Flex, Text } from '@chakra-ui/react';

import { PHRNote } from '../../../generated';
import { TimeFormatEnum } from '../../../constants';

interface IProps {
  referralNote: PHRNote;
}

export const NotesCard = ({ referralNote }: IProps) => {
  const { noteTypeEnum, createdDate, notes } = referralNote;
  return (
    <Box px="2rem" py="3rem" bgColor="#0C346812" borderRadius="8px">
      <Flex justify="space-between" align="center" mb="2rem">
        <Flex borderRadius="5px" bgColor="#3E119138" justify="center" px="1rem" py="0.5rem">
          <Text color="#3E1191">{noteTypeEnum}</Text>
        </Flex>
        <Text>{moment(createdDate).format(TimeFormatEnum.SHORT_LONG_DATE)}</Text>
      </Flex>

      <Text>{notes}</Text>
    </Box>
  );
};
