import { DurationTypeEnum } from '../../../../../generated';
import { FrequencyEnum, RouteEnum } from './useAddNewMedication';

export const buildForm = (quantityPerDose: string | undefined, form: string | undefined) => {
  let text = '';
  if (quantityPerDose && !isNaN(+quantityPerDose)) {
    if (+quantityPerDose > 1) {
      text += `${quantityPerDose} ${form}s`;
    } else {
      text += `${quantityPerDose} ${form}`;
    }
  } else {
    text += [quantityPerDose, form].join(' ');
  }
  return text;
};

export const buildRoute = (route: string | undefined) => {
  let text = '';
  switch (route) {
    case RouteEnum.PO:
      text = 'orally';
      break;
    case RouteEnum.Top:
      text = 'topically';
      break;
    case RouteEnum.Otic:
      text = 'into the ear';
      break;
    case RouteEnum.Oph:
      text = 'into the eye';
      break;
    case RouteEnum.PR:
      text = 'rectally';
      break;
    case RouteEnum.PV:
      text = 'vaginally';
      break;
    case RouteEnum.SC:
      text = 'under the skin';
      break;
  }

  return text;
};

export const buildFrequency = (frequency: string | undefined) => {
  let text = '';
  switch (frequency) {
    case FrequencyEnum.QD:
      text = 'once a day';
      break;
    case FrequencyEnum.BID:
      text = 'twice a day';
      break;
    case FrequencyEnum.TID:
      text = 'three times a day';
      break;
    case FrequencyEnum.QID:
      text = 'four times a day';
      break;
    case FrequencyEnum.QHS:
      text = 'before bed';
      break;
    // case FrequencyEnum.FIVEX:
    //   text = 'five times a day';
    //   break;
    case FrequencyEnum.Q4H:
      text = 'every 4 hours';
      break;
    case FrequencyEnum.Q6H:
      text = 'every 6 hours';
      break;
    // case FrequencyEnum.QOD:
    //   text = 'every other day';
    //   break;
  }

  return text;
};

export const buildDuration = (
  durationCount: number | undefined,
  durationTypeEnum: DurationTypeEnum | undefined,
) => {
  if (typeof durationCount === 'undefined') {
    return '';
  } else if (durationCount > 1) {
    return `for ${durationCount} ${durationTypeEnum?.toLocaleLowerCase()}`;
  } else {
    // remove the s from the word
    return `for ${durationCount} ${durationTypeEnum?.slice(0, -1).toLocaleLowerCase()}`;
  }
};

export const buildRefills = (refills: number | undefined) => {
  if (refills === 1) {
    return `${refills} refill`;
  } else {
    return `${refills} refills`;
  }
};
