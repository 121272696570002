import React from 'react';
import styled from 'styled-components';
import { SkeletonPulse, SkeletonPulseInverse } from './SkeletonPulse';

interface ISkeletonLineProps {
  readonly width?: string;
  readonly height?: string;
  readonly inverse?: boolean;
}

const StyledSkeletonLine = styled.span<ISkeletonLineProps>`
  ${(props) => (props.inverse ? SkeletonPulseInverse : SkeletonPulse)};
  ${(props) => 'height: ' + props.height + ';'}
  width: ${(props) => (props.width ? `${props.width}` : '5.5rem')};
  border-radius: 5px;
  color: transparent !important;

  &:before {
    content: '\\200b';
  }
`;

interface IProps {
  height?: string;
  width?: string;
  className?: string;
  children?: any;
  inverse?: boolean;
}

const SkeletonLine = ({ height, width, className, children, inverse = false }: IProps) => (
  <StyledSkeletonLine className={className} width={width} height={height} inverse={inverse}>
    {children}
  </StyledSkeletonLine>
);

export default SkeletonLine;
