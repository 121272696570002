import { useMutation } from 'react-query';
import { AuthClient, Patient, PatientsClient } from '../../../generated';
import { httpRequest } from '../../../utils';

export const usePatientsPut = () => {
  const patientsPut = useMutation<string, string, Patient, string>(async (patient: Patient) =>
    httpRequest(() => new PatientsClient(new AuthClient()).patientsPut(patient)),
  );

  return patientsPut;
};
