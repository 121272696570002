import React from 'react';
import SkeletonLine from '../../../components/Skeleton/SkeletonLine';
import { Details, ItemName, Label, StyledCard, Value } from './StyledPhrItems';

const PhrCardSkeletonComponent = ({ count = 1 }) => {
  return (
    <>
      {[...Array(count)].map((x, index) => (
        <StyledCard key={index}>
          <ItemName>
            <SkeletonLine width="auto">Condition Name</SkeletonLine>
          </ItemName>
          <Details>
            <Label>
              <SkeletonLine width="auto">Created Date:</SkeletonLine>
            </Label>
            <Value>
              <SkeletonLine width="auto">November 02, 2000</SkeletonLine>
            </Value>
          </Details>
          <Details>
            <Label>
              <SkeletonLine width="auto">Created Date:</SkeletonLine>
            </Label>
            <Value>
              <SkeletonLine width="auto">November 02, 2000</SkeletonLine>
            </Value>
          </Details>
        </StyledCard>
      ))}
    </>
  );
};

export default PhrCardSkeletonComponent;
