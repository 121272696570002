import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  AuthClient,
  IPatient,
  IProvider,
  Patient,
  PatientsClient,
  Provider,
  ProvidersClient,
  RolesEnum,
} from '../../../../generated';
import { useMixTrack } from '../../../../lib/mixpanel/mixpanelUtils';
import { ICurrentUser } from '../../../../store/currentUser/currentUserReducers';
import { getPatient, getProvider, logoutUser } from '../../../../store/root-creator';
import { httpRequest } from '../../../../utils';
import { isInRole } from '../../../../utils/isInRole';

export enum PrerequisiteStepEnum {
  Init = 'Init',
  ProviderToC = 'ProviderToC',
  PatientToC = 'PatientToC',
  PatientBirthDate = 'PatientBirthDate',
  Fulfilled = 'Fulfilled',
}

interface IProps {
  currentUser: ICurrentUser | null;
  patient: IPatient | null;
  provider: IProvider | null;
}

export const usePrerequisites = ({ currentUser, patient, provider }: IProps) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [step, setStep] = useState<PrerequisiteStepEnum>(PrerequisiteStepEnum.Init);

  const { mixTrack } = useMixTrack();

  useEffect(() => {
    const getInitialStep = () => {
      let initialStep;
      if (isInRole(currentUser, RolesEnum.Patient) && patient) {
        if (!patient.hasAgreedToTC) {
          initialStep = PrerequisiteStepEnum.PatientToC;
        } else if (!patient.birthDate) {
          initialStep = PrerequisiteStepEnum.PatientBirthDate;
        } else {
          initialStep = PrerequisiteStepEnum.Fulfilled;
        }
      } else if (isInRole(currentUser, RolesEnum.Provider) && provider) {
        if (!provider.hasAgreedToTC) {
          initialStep = PrerequisiteStepEnum.ProviderToC;
        } else {
          initialStep = PrerequisiteStepEnum.Fulfilled;
        }
      }
      setStep(initialStep);
    };
    if (currentUser) {
      getInitialStep();
    }
  }, [currentUser, patient, provider]);

  const onCancel = () => dispatch(logoutUser());

  const agreeWithProviderToC = async () => {
    setLoading(true);
    try {
      const newProvider = new Provider();
      newProvider.init(provider);
      newProvider.hasAgreedToTC = true;
      await httpRequest(() => new ProvidersClient(new AuthClient()).providersPut(newProvider));
      mixTrack({
        eventName: 'dashboard:provider_accepted_terms_and_conditions',
      });
      dispatch(getProvider(provider!.userDetailID));
      setStep(PrerequisiteStepEnum.Fulfilled);
    } catch (err) {
      mixTrack({
        eventName: 'dashboard:provider_accepted_terms_and_conditions_failed',
      });
      setError(err as any);
    }
    setLoading(false);
  };

  const agreeWithPatientToC = async () => {
    setLoading(true);
    try {
      const newPatient = new Patient();
      newPatient.init(patient);
      newPatient.hasAgreedToTC = true;
      await httpRequest(() => new PatientsClient(new AuthClient()).patientsPut(newPatient));
      dispatch(getPatient(currentUser!.userDetailID));
      mixTrack({
        eventName: 'dashboard:patient_accepted_terms_and_conditions',
      });
      if (patient?.birthDate) {
        setStep(PrerequisiteStepEnum.Fulfilled);
      } else {
        setStep(PrerequisiteStepEnum.PatientBirthDate);
      }
    } catch (err) {
      mixTrack({
        eventName: 'dashboard:patient_accepted_terms_and_conditions_failed',
      });
      setError(err as any);
    }
    setLoading(false);
  };

  const setPatientBirthDate = async (date: Date) => {
    setLoading(true);
    try {
      const newPatient = new Patient();
      newPatient.init(patient);
      newPatient.birthDate = date;
      await httpRequest(() => new PatientsClient(new AuthClient()).patientsPut(newPatient));
      dispatch(getPatient(currentUser?.userDetailID));
      setStep(PrerequisiteStepEnum.Fulfilled);
    } catch (err) {
      setError(err as any);
    }
    setLoading(false);
  };

  return {
    isLoading,
    error,
    step,
    onCancel,
    agreeWithProviderToC,
    agreeWithPatientToC,
    setPatientBirthDate,
  };
};
