import { Dispatch, SetStateAction, useCallback } from 'react';
import { ModalStateEnum } from '..';
import {
  AddressBook,
  PatientOrder,
  PatientOrderFinalizeDetails,
  PatientOrderFinalizeResultDetail,
} from '../../../../../../../../generated';
import { usePatientOrdersFinalize } from '../../../../../../../../services/patientOrders/mutations/usePatientOrdersFinalize';

type UseConfirmOrderProps = {
  selectedAddress?: AddressBook;
  isAddressBookInit: boolean;
  patientOrder?: PatientOrder;
  setPatientOrder: Dispatch<SetStateAction<PatientOrder | undefined>>;
  setModalState: Dispatch<SetStateAction<ModalStateEnum>>;
  setPatientOrderFinalizeResultDetails: Dispatch<
    SetStateAction<PatientOrderFinalizeResultDetail[] | undefined>
  >;
};

export const useConfirmOrder = ({
  selectedAddress,
  isAddressBookInit,
  patientOrder,
  setPatientOrder,
  setModalState,
  setPatientOrderFinalizeResultDetails,
}: UseConfirmOrderProps) => {
  const patientOrdersFinalize = usePatientOrdersFinalize();

  const confirmPatientOrder = useCallback(async () => {
    if (patientOrder?.patientOrderID) {
      const request = new PatientOrderFinalizeDetails();
      request.patientID = patientOrder.patientID;
      request.patientOrderIDs = [patientOrder.patientOrderID];
      try {
        const results = await patientOrdersFinalize.mutateAsync(request);
        setPatientOrderFinalizeResultDetails(results?.patientOrderFinalizeResultDetails);
        setPatientOrder(undefined);
        setModalState(ModalStateEnum.Success);
      } catch (err) {
        // Eat err
      }
    }
  }, [
    patientOrder,
    patientOrdersFinalize,
    setModalState,
    setPatientOrder,
    setPatientOrderFinalizeResultDetails,
  ]);

  return {
    selectedAddress,
    isAddressBookInit,
    patientOrder,
    confirmPatientOrder,
    isLoading: patientOrdersFinalize.isLoading,
    error: patientOrdersFinalize.error,
  };
};

export type UseConfirmOrderReturnType = ReturnType<typeof useConfirmOrder>;
