import { createReducer } from 'typesafe-actions';
import {
  updatePageTitle,
  clearPageTitle,
  updateBreadcrumb,
  clearBreadcrumb,
} from './pageHeaderActions';
import { combineReducers } from 'redux';
import { PathParamKeysEnum } from '../../constants';

const title = createReducer<string | null>(null).handleAction(
  [updatePageTitle, clearPageTitle],
  (state, action) => {
    return action.payload;
  },
);

const breadcrumbs = createReducer<[PathParamKeysEnum, string][]>([])
  .handleAction([updateBreadcrumb], (state: [PathParamKeysEnum, string][], action) => {
    const index = state.findIndex((x) => x[0] === action.payload[0]);
    let newState = [...state];
    if (index > -1) {
      newState[index] = action.payload;
    } else {
      newState = [...newState, action.payload];
    }
    return newState;
  })
  .handleAction([clearBreadcrumb], (state, action) => {
    return [...state.filter((x) => x[0] !== action.payload)];
  });

export interface IPageHeaderState {
  title: string | null;
  breadcrumbs: [PathParamKeysEnum, string];
}

export default combineReducers<IPageHeaderState>({
  title,
  breadcrumbs,
});
