import {
  Alert,
  AlertIcon,
  Box,
  Center,
  Flex,
  ModalBody,
  ModalFooter,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { ReactElement } from 'react';

import { AddressBookModal } from '../../../../../components/AddressBook/AddressBookModal';
import { isInNetwork } from '../../../../../components/AddressBook/utils';
import { ButtonRow, PrimaryButton, PurpleButton } from '../../../../../components/Buttons';
import { CenteredLoadingIndicator } from '../../../../../components/LoadingIndicator';
import { BaseModal } from '../../../../../components/Modals';
import Modal from '../../../../../components/Modals/Modal';
import { UpdatePatientsAddressModal } from '../../../../../components/UpdatePatientsAddressModal/UpdatePatientsAddressModal';
import {
  AddressBook,
  AddressBookFolderEnum,
  Country,
  IPatient,
  PatientOrder,
} from '../../../../../generated';

import { BaseModalTitleCards } from '../../../consultMedications/components/PrescriptionOrderModal/BaseModalTitleCards';

import { AddOrderTests } from '../CreateOrder/components/AddOrderTests/AddOrderTests';
import { ConfirmOrder } from '../CreateOrder/components/ConfirmOrder/ConfirmOrder';
import { FinalizeOrder } from '../CreateOrder/components/FinalizeOrder/FinalizeOrder';
import { Questionair } from '../CreateOrder/components/Questionair/Questionair';
import { SelectOrderForm } from '../CreateOrder/components/SelectOrderType/SelectOrderForm';
import { SuccessScreen } from '../CreateOrder/components/SuccessScreen/SuccessScreen';
import {
  CreateOrderContext,
  CreateOrderProvider,
  ModalStateEnum,
} from '../CreateOrder/contexts/CreateOrderContext';
import { SelectLabOptionsModal } from '../SelectLabOptions';
import useTranslationComponent from '../../../../../hooks/useTranslationComponent';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  patient: IPatient;
  draftPatientOrder?: PatientOrder;
  setPatientOrder: React.Dispatch<React.SetStateAction<PatientOrder | undefined>>;
  refetchOrders: () => void;
  country: Country;
  onOpenNotificationModal?: (content: ReactElement | string[]) => void;
  refreshPhrPatient?: () => void;
  preSelectedAddressBook?: AddressBook;
  isClinicalOrder?: boolean;
}

export const OrderModal = ({
  isOpen,
  onClose,
  patient,
  draftPatientOrder,
  setPatientOrder,
  refetchOrders,
  country,
  onOpenNotificationModal,
  refreshPhrPatient,
  preSelectedAddressBook,
  isClinicalOrder,
}: IProps) => {
  const { t } = useTranslationComponent(['phr']);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const hasCity = patient.city || patient.cityTownID;
  const hasProvince = patient.province || patient.provinceStateID;

  const patientHasValidAddress = patient.addressLine1 && hasCity && hasProvince;

  const {
    isOpen: isAddressBookOpen,
    onClose: onCloseAddressBook,
    onOpen: onOpenAddressBook,
  } = useDisclosure();

  const {
    isOpen: isLabOptionsOpen,
    onClose: onCloseLabOptions,
    onOpen: onOpenLabOptions,
  } = useDisclosure();

  const {
    isOpen: isFinalizeOrderModalOpen,
    onClose: onCloseFinalizeOrderModal,
    onOpen: onOpenFinalizeOrderModal,
  } = useDisclosure();

  const {
    isOpen: isUpdatingPatientAddress,
    onClose: onClosePatientAddressUpdate,
    onOpen: onOpenPatientAddressUpdate,
  } = useDisclosure();

  return (
    <CreateOrderProvider
      draftPatientOrder={draftPatientOrder}
      setPatientOrder={setPatientOrder}
      country={country}
      patient={patient}
      onOpenNotificationModal={onOpenNotificationModal}
      isClinicalOrder={isClinicalOrder}
      preSelectedAddressBook={preSelectedAddressBook}
    >
      <CreateOrderContext.Consumer>
        {({ modalState, onBack, setModalState, controller }) => {
          const countryToUse = controller.selectedLabAddress?.country || country;

          const showSelectOrderTypeName =
            modalState !== ModalStateEnum.SelectOrder && controller.selectedOrder;

          const globalIsLoading = controller.isLoading;

          const showChangeAddress = modalState !== ModalStateEnum.Success && !isClinicalOrder;

          const title = t(!controller.ordersAdded ? 'Orders not added.' : '');

          return (
            <>
              <BaseModal
                isOpen={isOpen}
                onDismiss={
                  !isClinicalOrder
                    ? () => {
                        setModalState(ModalStateEnum.SelectOrder);
                        controller.clearAllOrders();
                        onClose();
                      }
                    : undefined
                }
                onBack={onBack}
                size="xl"
                modalHeaderProps={{
                  pl: { base: '4', md: '10' },
                  pr: { base: '4', md: '10' },
                }}
                title={
                  <Box>
                    <Box borderBottom="1px solid rgb(240, 240, 240)" pb="4">
                      <BaseModalTitleCards
                        isMobile={isMobile}
                        patient={patient}
                        selectedAddress={controller.selectedLabAddress}
                        onOpenAddressBook={onOpenAddressBook}
                        title={`${t('Diagnostics & Imaging Orders')} ${
                          showSelectOrderTypeName ? `- ${controller.selectedOrder?.name}` : ''
                        }`}
                        subtitle={t('Diagnostics Facility')}
                        showAddress={modalState !== ModalStateEnum.SelectOrder}
                        showChangeAddress={showChangeAddress}
                      />
                    </Box>
                  </Box>
                }
              >
                <VStack height="100%" alignItems="stretch" overflowY="auto">
                  <ModalBody flex="1" overflowY="auto">
                    <Box height="100%" overflowY="auto">
                      {globalIsLoading ? (
                        <CenteredLoadingIndicator />
                      ) : (
                        <>
                          {modalState === ModalStateEnum.SelectOrder && (
                            <SelectOrderForm onOpenLabOptionsModal={onOpenLabOptions} />
                          )}

                          {patientHasValidAddress ? (
                            <>
                              {modalState === ModalStateEnum.Questionair && <Questionair />}
                              {modalState === ModalStateEnum.Build && <AddOrderTests />}
                              {modalState === ModalStateEnum.Summary && (
                                <FinalizeOrder country={country} patient={patient} />
                              )}
                              {modalState === ModalStateEnum.Confirm && <ConfirmOrder />}
                              {modalState === ModalStateEnum.Success && (
                                <SuccessScreen
                                  onClose={() => {
                                    onClose();
                                    refetchOrders();
                                  }}
                                  isClinicalOrder={isClinicalOrder}
                                />
                              )}
                            </>
                          ) : modalState !== ModalStateEnum.SelectOrder ? (
                            <Alert status="warning" justifyContent="space-between">
                              <Flex
                                flexDirection={{ base: 'column', md: 'row' }}
                                alignItems="center"
                              >
                                <Flex>
                                  <AlertIcon />
                                  {t(
                                    'Patient does not have a valid address, please enter the patients address before proceeding.',
                                  )}
                                </Flex>
                                <Box mt={{ base: '2', md: '0' }} ml={{ base: '0', md: '2' }}>
                                  <PrimaryButton onClick={onOpenPatientAddressUpdate} minW="300px">
                                    {t("Update Patient's Address")}
                                  </PrimaryButton>
                                </Box>
                              </Flex>
                            </Alert>
                          ) : null}
                        </>
                      )}
                    </Box>
                  </ModalBody>
                  {modalState === ModalStateEnum.Build &&
                    controller.ordersAdded &&
                    !globalIsLoading && (
                      <ModalFooter pt="0" px={{ base: '4', md: '10' }}>
                        <ButtonRow>
                          <>
                            <PurpleButton
                              width={{ base: '100%', md: 'auto' }}
                              minWidth="200px"
                              onClick={onOpenFinalizeOrderModal}
                              disabled={!controller.ordersAdded}
                              title={title}
                            >
                              {t('Confirm Order')}
                            </PurpleButton>
                          </>
                        </ButtonRow>
                      </ModalFooter>
                    )}
                </VStack>
              </BaseModal>
              <SelectLabOptionsModal
                isModalOpen={isLabOptionsOpen}
                onCloseModal={onCloseLabOptions}
                patientID={patient.patientID}
                onOpenAddressBook={onOpenAddressBook}
                selectedAddress={controller.selectedLabAddress}
                isLoading={controller.isLoading}
                onChangeAddress={(address: AddressBook) => {
                  if (!isInNetwork(address) && !address.faxPhoneNumber) return;
                  controller.handleUpdateSelectedLabAddress(address);
                }}
                preSelectedAddressBook={preSelectedAddressBook}
              />
              {countryToUse && (
                <AddressBookModal
                  isModalOpen={isAddressBookOpen}
                  onDismissModal={onCloseAddressBook}
                  addressBookProps={{
                    country: countryToUse,
                    patient: patient,
                    defaultFolder: AddressBookFolderEnum.Lab,
                    callback: (addresses?: AddressBook[]) =>
                      addresses && controller.handleUpdateSelectedLabAddress(addresses[0]),
                    onValidate: (addressBook: AddressBook) =>
                      !!(isInNetwork(addressBook) || addressBook.faxPhoneNumber),
                    heading: t('Select Diagnostic Facility'),
                    onClose: onCloseAddressBook,
                    visibleFolders: [AddressBookFolderEnum.Lab],
                    preSelectedAddress: controller.selectedLabAddress,
                  }}
                />
              )}

              <Modal
                title={t('Finalize Order')}
                cancelText={t('Cancel')}
                onSubmit={() => {
                  controller.handleFinalizeOrder();

                  onCloseFinalizeOrderModal();
                }}
                submitText={t('Yes')}
                isOpen={isFinalizeOrderModalOpen}
                onCancel={onCloseFinalizeOrderModal}
                onDismiss={onCloseFinalizeOrderModal}
                modalBodyProps={{ display: 'flex', alignItems: 'center' }}
              >
                <Center height="100%" width="100%">
                  <Text textAlign="center">
                    {t('Are you sure you want to finalize this Order?')}
                  </Text>
                </Center>
              </Modal>

              <Modal
                title=""
                cancelText={t('Cancel')}
                onSubmit={() => {
                  if (!controller.patientOrderItemDataToRemove) return;

                  controller.handleRemovePatientOrder(
                    controller.patientOrderItemDataToRemove.orderTypeEnum,
                    controller.patientOrderItemDataToRemove.patientOrderItem,
                  );

                  controller.handleClearPatientOrderDataToRemove();
                }}
                submitText={t('Yes')}
                isOpen={!!controller.patientOrderItemDataToRemove}
                onCancel={controller.handleClearPatientOrderDataToRemove}
                onDismiss={controller.handleClearPatientOrderDataToRemove}
                modalBodyProps={{ display: 'flex', alignItems: 'center' }}
              >
                <Center height="100%" width="100%">
                  <Text textAlign="center">{t('Are you sure you want to remove this Order?')}</Text>
                </Center>
              </Modal>

              {isUpdatingPatientAddress && (
                <UpdatePatientsAddressModal
                  patient={patient}
                  isOpen={isUpdatingPatientAddress}
                  close={onClosePatientAddressUpdate}
                  order={controller.patientOnlyOrder}
                  updatingDelivery={false}
                  country={countryToUse}
                  refreshPhrPatient={refreshPhrPatient}
                  onUpdatePatientOrderAddress={controller.handleUpdatePatientPartialProperty}
                />
              )}
            </>
          );
        }}
      </CreateOrderContext.Consumer>
    </CreateOrderProvider>
  );
};
