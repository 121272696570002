import { MeasurementTypeEnum } from '../generated';

import { getFormattedDistance } from './getFormattedDistance';

const getDistanceToShow = (distance?: number, providerMeasurementType?: MeasurementTypeEnum) => {
  const formattedDistance = distance ? getFormattedDistance(distance) : undefined;

  const distanceInKm = formattedDistance ? `${formattedDistance.km} Km` : undefined;
  const distanceInMiles = formattedDistance ? `${formattedDistance.miles} Miles` : undefined;

  return providerMeasurementType === MeasurementTypeEnum.Imperial ? distanceInMiles : distanceInKm;
};

export { getDistanceToShow };
