import { Box, Flex, HStack, Text } from '@chakra-ui/react';

import { ChevronRightIcon, HomeIcon } from '../../../../../assets/icons';
import { SelectedAddressCard } from '../../../../../components/AddressBook/components/SelectedAddressCard/SelectedAddressCard';
import BirthDateComponent from '../../../../../components/BirthDateComponent';
import { AddressBook, IPatient } from '../../../../../generated';
import useTranslationComponent from '../../../../../hooks/useTranslationComponent';
import { OutpostTheme } from '../../../../../themes/outpost';

type BaseModalTitleCardsProps = {
  isMobile: boolean | undefined;
  patient: IPatient;
  selectedAddress?: AddressBook;
  onOpenAddressBook: () => void;
  handleDeleteAddress?: () => void;
  title?: string;
  subtitle?: string;
  showAddress?: boolean;
  showChangeAddress?: boolean;
};

const BaseModalTitleCards = ({
  patient,
  selectedAddress,
  onOpenAddressBook,
  title,
  subtitle,
  showAddress = true,
  showChangeAddress = true,
}: BaseModalTitleCardsProps): JSX.Element => {
  const { t } = useTranslationComponent(['phr']);

  return (
    <Box>
      <Flex alignItems="center" justifyContent="center" px="9">
        <Text mb="3" width="100%">
          {t(title ?? 'Create a New Prescription')}
        </Text>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent={!showAddress ? 'center' : 'space-between'}
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Box pr={{ md: '3' }} mb={{ base: '3', md: '0' }}>
          <Flex flexDirection="column">
            <Text fontWeight="600" textAlign="left" fontSize="14px">
              {patient.fullName}
            </Text>
            <Text color="#9D9D9D" fontWeight="400 !important" fontSize="12px" textAlign="left">
              {t(patient.genderEnum)} |{' '}
              <BirthDateComponent birthDate={patient.birthDateString} showBirthDate={true} />
            </Text>
          </Flex>
        </Box>
        {showAddress &&
          (!!selectedAddress ? (
            <Flex
              flex="1"
              maxW="max-content"
              pl={{ md: '3' }}
              fontWeight="normal"
              textAlign="left"
              alignItems="center"
              flexDirection={{ base: 'column', md: 'row' }}
              alignSelf={{ base: 'flex-start', md: 'center' }}
            >
              <Box
                pr={{ md: '2' }}
                mb={{ base: '2', md: '0' }}
                alignSelf={{ base: 'flex-start', md: 'center' }}
              >
                <Text fontSize="14px" fontWeight="semibold">
                  {t(`Selected ${subtitle ?? 'Pharmacy'}`)}
                </Text>
              </Box>
              <SelectedAddressCard
                selectedAddress={selectedAddress}
                avatarProps={{ size: 'sm' }}
                containerProps={{ minH: 'auto', py: '2', px: '3', maxW: '380px' }}
                nameTextProps={{
                  fontSize: '14px',
                  fontWeight: 'semibold',
                }}
                ActionButton={
                  <Flex
                    minW="4rem"
                    justifyContent="flex-end"
                    alignSelf={{ base: 'flex-end', md: 'flex-start' }}
                  >
                    {showChangeAddress && (
                      <Text
                        cursor="pointer"
                        fontSize="12px"
                        color="#6B57E5"
                        onClick={onOpenAddressBook}
                      >
                        {t('Change')}
                      </Text>
                    )}
                  </Flex>
                }
              />
            </Flex>
          ) : (
            <Box flex="1" maxW="max-content" pl="3" fontWeight="normal" textAlign="left">
              <Flex
                borderRadius="lg"
                border="2px solid rgb(211, 211, 211)"
                py="4"
                px="10"
                alignItems="center"
                role="button"
                transition="border-color 0.2s ease-in-out"
                _hover={{ borderColor: '#6B57E5' }}
                onClick={onOpenAddressBook}
              >
                <HStack>
                  <HomeIcon color="#6B57E5" />
                  <Text fontSize={OutpostTheme.FontSizes.body} color="#6B57E5">
                    {t(`Click to Select a ${subtitle ?? 'Pharmacy'}`)}
                  </Text>
                </HStack>
                <Box pl="3">
                  <ChevronRightIcon color="#6B57E5" fontSize="14px" />
                </Box>
              </Flex>
            </Box>
          ))}
      </Flex>
    </Box>
  );
};

export { BaseModalTitleCards };
