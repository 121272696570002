import React from 'react';
import Modal from '../../../../../components/Modals/Modal';
import { IProvider } from '../../../../../generated';
import { ProviderSignatureForm } from '../../../../myProfile/components/ProviderSignature/ProviderSignatureForm';

interface IProps {
  isOpen: boolean;
  onCancel: () => void;
  provider: IProvider;
}

export const SignatureModal = ({ isOpen, onCancel, provider }: IProps) => {
  return (
    <Modal title="Signature on file is needed" onDismiss={onCancel} isOpen={isOpen}>
      <ProviderSignatureForm provider={provider} />
    </Modal>
  );
};
