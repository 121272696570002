import { ActionType, createAsyncAction } from 'typesafe-actions';
import { MarketingCampaign } from '../../generated';

export const getActiveMarketingCampaignsActions = createAsyncAction(
  ['GET_ACTIVE_MARKETING_CAMPAIGNS_REQUEST', () => {}],
  ['GET_ACTIVE_MARKETING_CAMPAIGNS_SUCCESS', (res: MarketingCampaign[]) => res],
  ['GET_ACTIVE_MARKETING_CAMPAIGNS_ERROR', (err: string) => err],
)();

export type ActiveMarketingCampaignActionTypes = ActionType<
  typeof getActiveMarketingCampaignsActions
>;
