import { useQuery } from 'react-query';
import {
  AuthClient,
  ProviderSchedule,
  ProviderSchedulesClient,
  ProviderScheduleSearchDetails,
} from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

interface IProps {
  providerID?: string;
  startDate?: Date;
  endDate?: Date;
}

export const useProviderSchedulesSearch = ({ providerID, startDate, endDate }: IProps) => {
  const {
    isLoading: isLoadingProviderSchedules,
    data: providerSchedules,
    error: providerSchedulesError,
  } = useQuery<ProviderSchedule[], string>(
    [QueryKeys.ProviderSchedulesSearch, { providerID, startDate, endDate }],
    async () => {
      const providerScheduleSearchDetails = new ProviderScheduleSearchDetails();
      providerScheduleSearchDetails.providerID = providerID;
      providerScheduleSearchDetails.startDate = startDate;
      providerScheduleSearchDetails.endDate = endDate;
      return httpRequest(() =>
        new ProviderSchedulesClient(new AuthClient()).search(providerScheduleSearchDetails),
      );
    },
    { enabled: !!providerID && !!startDate && !!endDate },
  );

  return {
    isLoadingProviderSchedules,
    providerSchedules,
    providerSchedulesError,
  };
};
