import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Image,
  Link,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import appLogo from '../../../assets/images/outpost-logo-white-2.svg';
import { PartnerSettingsEnum } from '../../../store/currentPartner/currentPartnerReducers';
import { AppState } from '../../../store/root-reducers';
import { FooterLink } from './FooterLink';
import useTranslationComponent from '../../../hooks/useTranslationComponent';

const Footer = () => {
  const { t } = useTranslationComponent();
  const isSideMenuOpen = useSelector((state: AppState) => state.sideMenuState.isOpen);
  const currentPartner = useSelector((state: AppState) => state.currentPartnerState.data);
  const img =
    currentPartner?.partnerSettings?.find(
      ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.APP_LOGO,
    )?.value || appLogo;
  const showFooter = useBreakpointValue({ base: !isSideMenuOpen, lg: true });

  return showFooter ? (
    <Box backgroundColor="#000000">
      <HStack
        pr={{ base: 4, md: 12 }}
        pl={{ base: 4, md: 'calc(245px + 3rem)' }}
        py={{ base: 4, md: 6 }}
        justifyContent={{ base: 'center', sm: 'flex-start' }}
        w="100%"
        maxW="1535px"
        margin="0 auto"
        wrap={{ base: 'wrap', sm: 'nowrap' }}
      >
        <VStack mb={[8, 0]} mr={{ md: 12 }}>
          <Box width="204px" mr={{ base: '0', lg: '148px' }} mb="20px">
            <Link href={process.env.REACT_APP_ABOUT_URL}>
              <Image src={img} />
            </Link>
          </Box>
          <Box textAlign="start">
            <Text color="#FFFFFF">{t('Healthcare, Funds And Records At Your Fingertips')}</Text>
            <Text mt={4} color="#FFFFFF" fontWeight="bold">
              {t('GET IN TOUCH')}
            </Text>
            <Text mt={4} color="#FFFFFF">
              hello@outpost.health
            </Text>
          </Box>
        </VStack>

        <Flex display={{ base: 'block', xl: 'none' }} w="100%">
          <Accordion allowToggle>
            <AccordionItem>
              <AccordionButton p={4}>
                <Box flex="1" textAlign="left" fontWeight="700" color="#FFFFFF">
                  {t('OUR PLATFORM')}
                </Box>
                <AccordionIcon border={0} borderColor="white" />
              </AccordionButton>
              <AccordionPanel borderTopColor="gray.200" p={4}>
                <VStack spacing={3} align="flex-start" whiteSpace="nowrap">
                  <FooterLink href="https://www.outpost.health/provider" text={t('Providers')} />
                  <FooterLink href="https://www.outpost.health/patients" text={t('Patients')} />
                  <FooterLink
                    href="https://www.outpost.health/payments"
                    text={t('Health Wallet')}
                  />
                  <FooterLink
                    href="https://www.outpost.health/covid19"
                    text={t('Covid-19 Testing')}
                  />
                </VStack>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton p={4}>
                <Box flex="1" textAlign="left" fontWeight="700" color="#FFFFFF">
                  {t('OUR COMPANY')}
                </Box>
                <AccordionIcon border={0} borderColor="white" />
              </AccordionButton>
              <AccordionPanel borderTopColor="gray.200" p={4}>
                <VStack spacing={3} align="flex-start" whiteSpace="nowrap">
                  <FooterLink href="https://www.outpost.health/about" text={t('About')} />
                  <FooterLink
                    href="https://www.outpost.health/our-partners"
                    text={t('Our Partners')}
                  />
                  <FooterLink href="https://www.outpost.health/blog-360" text={t('Blog')} />
                  <FooterLink
                    href="https://www.outpost.health/privacy"
                    text={t('Privacy Policy')}
                  />
                  <FooterLink
                    href="https://www.outpost.health/terms"
                    text={t('Terms & Conditions')}
                  />
                </VStack>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton p={4}>
                <Box flex="1" textAlign="left" fontWeight="700" color="#FFFFFF">
                  {t('FOLLOW US')}
                </Box>
                <AccordionIcon border={0} borderColor="white" />
              </AccordionButton>
              <AccordionPanel borderTopColor="gray.200" p={4}>
                <VStack spacing={3} align="flex-start" whiteSpace="nowrap">
                  <FooterLink href="https://www.facebook.com/outposthealth" text="Facebook" />
                  <FooterLink href="https://twitter.com/Outposthealthng" text="Twitter" />
                  <FooterLink
                    href="https://www.linkedin.com/company/outpost-healthcare/"
                    text="Linkedin"
                  />
                  <FooterLink href="https://www.instagram.com/outposthq/" text="Instagram" />
                  <FooterLink
                    href="https://www.outpost.health/payments"
                    text={t('Health Wallet')}
                  />
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Flex>
        <Flex display={{ base: 'none', xl: 'block' }}>
          <HStack spacing={{ base: '60px', lg: '100px' }} mt={6} align="flex-start">
            <VStack spacing={3} align="flex-start" whiteSpace="nowrap">
              <Text fontWeight="700" mb={3} color="#FFFFFF">
                {t('OUR PLATFORM')}
              </Text>
              <FooterLink href="https://www.outpost.health/provider" text={t('Providers')} />
              <FooterLink href="https://www.outpost.health/patients" text={t('Patients')} />
              <FooterLink href="https://www.outpost.health/payments" text={t('Health Wallet')} />
              <FooterLink href="https://www.outpost.health/covid19" text={t('Covid-19 Testing')} />
            </VStack>
            <VStack spacing={3} align="flex-start" whiteSpace="nowrap">
              <Text fontWeight="700" mb={3} color="#FFFFFF">
                {t('OUR COMPANY')}
              </Text>
              <FooterLink href="https://www.outpost.health/about" text={t('About')} />
              <FooterLink href="https://www.outpost.health/our-partners" text={t('Our Partners')} />
              <FooterLink href="https://www.outpost.health/blog-360" text={t('Blog')} />
              <FooterLink href="https://www.outpost.health/privacy" text={t('Privacy Policy')} />
              <FooterLink href="https://www.outpost.health/terms" text={t('Terms & Conditions')} />
            </VStack>
            <VStack spacing={3} align="flex-start" whiteSpace="nowrap">
              <Text fontWeight="700" mb={3} color="#FFFFFF">
                {t('FOLLOW US')}
              </Text>
              <FooterLink href="https://www.facebook.com/outposthealth" text="Facebook" />
              <FooterLink href="https://twitter.com/Outposthealthng" text="Twitter" />
              <FooterLink
                href="https://www.linkedin.com/company/outpost-healthcare/"
                text="Linkedin"
              />
              <FooterLink href="https://www.instagram.com/outposthq/" text="Instagram" />
              <FooterLink href="/support" text={t('Help Center')} />
            </VStack>
          </HStack>
        </Flex>
      </HStack>

      <Flex justifyContent="center" w="100%" minH="100px" my={10}>
        <Box w="52%" borderTop="1px solid #333">
          <Text textAlign="center" my={10} fontSize={18} fontWeight="500" color="#FFFFFF">
            {t('Copyright')} &copy; {moment().get('year')} | {process.env.REACT_APP_COMPANY_NAME}
          </Text>
        </Box>
      </Flex>
    </Box>
  ) : null;
};

export default Footer;
