import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ModalStateEnum } from '..';
import { PhoneEventsEnum } from '../../../../../../../../constants';
import { useMixTrack } from '../../../../../../../../lib/mixpanel/mixpanelUtils';
import { AppState } from '../../../../../../../../store/root-reducers';
import useCreateOrderContext from '../../../hooks/useCreateOrderContext';

export const useSuccessScreen = () => {
  const {
    setPatientOrder,
    setModalState,
    patientOrderFinalizeResultDetails,
    setPatientOrderFinalizeResultDetails,
    controller,
  } = useCreateOrderContext();

  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  const isInMobileView = currentUser?.isInMobileView;
  const mobileSystem = currentUser?.mobileSystem;

  const { mixTrack } = useMixTrack();

  const onResetForm = () => {
    setPatientOrder(undefined);
    setPatientOrderFinalizeResultDetails(undefined);
    controller.clearAllOrders();
    setModalState(ModalStateEnum.SelectOrder);
  };

  const onCompleteClinicalOrderCallback = useCallback(() => {
    mixTrack({
      eventName: 'neo_clinical_order:complete_clicked',
    });

    if (isInMobileView) {
      if (mobileSystem === 'android') {
        // @ts-ignore
        AndroidResult.callback(PhoneEventsEnum.LAB_ORDER_CREATED_CALLBACK);
      } else {
        // @ts-ignore
        webkit.messageHandlers.labOrderCreatedCallback.postMessage(
          PhoneEventsEnum.LAB_ORDER_CREATED_CALLBACK,
        );
      }
    }
  }, [isInMobileView, mixTrack, mobileSystem]);

  return { onResetForm, patientOrderFinalizeResultDetails, onCompleteClinicalOrderCallback };
};
