import styled from 'styled-components';
import { FontFamilies } from '../constants';

interface ITagComponentProps {
  readonly backgroundColor: string;
  readonly textColor: string;
}

const TagComponent = styled.span<ITagComponentProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 30px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  font-family: ${FontFamilies.Primary};
  font-size: 12px;
  min-width: 28px !important;
  height: 17px;
`;

const Wrapper = styled.span`
  display: inline-block;
`;

interface IProps {
  children?: any;
  backgroundColor: string;
  textColor: string;
  className?: string;
  style?: Record<string, any>;
}

const Tag = ({ children, backgroundColor, textColor, className, style }: IProps) => {
  return (
    <Wrapper>
      <TagComponent
        className={className}
        backgroundColor={backgroundColor}
        textColor={textColor}
        style={style}
      >
        {children}
      </TagComponent>
    </Wrapper>
  );
};

export default Tag;
