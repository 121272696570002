import { createReducer } from 'typesafe-actions';
import { IUssdTransactionStatus } from '../../constants';
import { updateUssdTransaction } from './ussdTransactionActions';
import { combineReducers } from 'redux';

const ussdTransaction = createReducer<IUssdTransactionStatus | null>(null).handleAction(
  [updateUssdTransaction],
  (state, action) => {
    return action.payload;
  },
);

export interface IUssdTransactionState {
  ussdTransaction: IUssdTransactionStatus | null;
}

export default combineReducers<IUssdTransactionState>({
  ussdTransaction,
});
