import { useSelector } from 'react-redux';
import { RolesEnum } from '../generated';
import { AppState } from '../store/root-reducers';
import { isInRole } from '../utils/isInRole';

const useIsMarketingPartner = () => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  return isInRole(currentUser, RolesEnum.MarketingCampaignPartner);
};

export { useIsMarketingPartner };
