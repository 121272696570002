import { ITheme } from '../../styled';

export const OutpostTheme: ITheme = {
  Colors: {
    Primary: '#374659',
    DarkBlue: '#1B3D71',
    LightBlue: '#D5E1E8',
    White: '#ffffff',
    BackgroundColor: '#f5f6fb',
    Danger: '#ff485e',
    Warning: '#fbbf00',
    Green: '#46AA78',
    DarkGreen: '#003A45',
    Gray: '#8d92a4',
    LightGray: '#bdc2d4',
    Highlight: '#FF506E',
    CallToAction: '#FF506E',
    Profile: '#516474',
  },
  External: {
    Primary: '#333333',
    DarkBlue: '#1B3D71',
    LightBlue: '#D5E1E8',
    White: '#ffffff',
    BackgroundColor: '#f5f6fb',
    Danger: '#ff485e',
    Warning: '#fbbf00',
    Green: '#46AA78',
    DarkGreen: '#003A45',
    Gray: '#8d92a4',
    LightGray: '#bdc2d4',
    Highlight: '#EF8062',
    CallToAction: '#374659',
    Profile: '#516474',
  },
  FontColors: {
    Primary: '#333333',
    Secondary: '#666',
    Light: '#8c8c8c',
  },
  FontSizes: {
    small: '10px',
    body: '14px',
    heading: '20px',
  },
  Radius: {
    cards: '12px',
  },
  SystemMessageColors: {
    info: {
      light: '#dde9fb',
      dark: '#464c52',
    },
    warning: {
      light: '#fff3cd',
      dark: '#586404',
    },
    danger: {
      light: '#fee0dd',
      dark: '#82352c',
    },
  },
  NavColors: {
    Patient: {
      Dashboard: {
        solid: '#354B69',
        light: '#495D78',
        underline: '#ffffff',
        accent: '#ffffff',
      },
      Heal: {
        solid: '#354B69',
        light: '#495D78',
        underline: '#5FD7B6',
        accent: '#5FD7B6',
      },
      Monitor: {
        solid: '#73627C',
        light: '#817289',
        underline: '#EAC234',
        accent: '#EAC234',
      },
      Learn: {
        solid: '#003A45',
        light: '#265761',
        underline: '#F5938C',
        accent: '#F5938C',
      },
      Profile: {
        solid: '#516474',
        light: '#455664',
        underline: '#ffffff',
        accent: '#EE6A4C',
      },
      MessageCenter: {
        solid: '#354B69',
        light: '#495D78',
        underline: '#ffffff',
        accent: '#ffffff',
      },
      Wallet: {
        solid: '#354B69',
        light: '#495D78',
        underline: '#ffffff',
        accent: '#ffffff',
      },
    },
    Provider: {
      Dashboard: {
        solid: '#131725',
        light: '#242C47',
        underline: '#ffffff',
        accent: '#ffffff',
      },
      Heal: {
        solid: '#131725',
        light: '#242C47',
        underline: '#5FD7B6',
        accent: '#5FD7B6',
      },
      Monitor: {
        solid: '#73627C',
        light: '#817289',
        underline: '#EAC234',
        accent: '#EAC234',
      },
      Learn: {
        solid: '#003A45',
        light: '#265761',
        underline: '#F5938C',
        accent: '#F5938C',
      },
      Profile: {
        solid: '#516474',
        light: '#455664',
        underline: '#ffffff',
        accent: '#EE6A4C',
      },
      MessageCenter: {
        solid: '#9EABBE',
        light: '#A8B3C4',
        underline: '#ffffff',
        accent: '#ffffff',
      },
      Wallet: {
        solid: '#9EABBE',
        light: '#A8B3C4',
        underline: '#ffffff',
        accent: '#ffffff',
      },
    },
  },
  Avatar: {
    Size: {
      Peewee: 'sm',
      Small: 'sm',
      Medium: 'md',
      Large: 'lg',
      Extra: 'xl',
      Jumbo: '2xl',
    },
    Radius: {
      Round: '50%',
      RoundedSqaure: '25%',
      Square: '10%',
    },
  },
};
