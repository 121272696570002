import { Box, Grid, HStack, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import ImageViewer from 'react-simple-image-viewer';

import { CalendarIcon2 } from '../../../../assets/icons';
import { TimeFormatEnum } from '../../../../constants';
import { PHRDocument, PHRVaccination } from '../../../../generated';
import { verifyForPhrSection } from '../../../../store/patient/phr/phrCreators';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import { successToastr } from '../../../../utils/toastr';

import { SecondaryButton } from '../../../../components/Buttons';
import { OutpostTheme } from '../../../../themes/outpost';
import PhrItemView from '../../components/PhrItemView';
import { ViewPhrDocument } from '../../components/ViewPhrDocument';

interface IVaccinationViewProps {
  vaccination: PHRVaccination;
}

const CovidVaccinationView = ({ vaccination }: IVaccinationViewProps) => {
  const dispatch = useDispatch();
  const [currentDoc, setCurrentDoc] = useState<PHRDocument | null>(null);

  const onVerify = async (id: string, patientID: string) => {
    dispatch(verifyForPhrSection(ReduxPHRCategoryEnum.PHRVaccinations, id, patientID));
    successToastr({ description: 'Successfully verified vaccination' });
  };

  return (
    <>
      <PhrItemView
        phrItem={{ ...vaccination, name: '' }}
        onVerify={() => onVerify(vaccination.phrVaccinationID, vaccination.patientID)}
      >
        <Grid
          templateColumns="repeat(2, 1fr)"
          templateRows="repeat(2, 1fr)"
          gridColumnGap="1rem"
          gridRowGap="1.5rem"
        >
          <Box>
            <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
              Vaccine Manufacturer
            </Text>
            <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
              {vaccination.manufacturerName ?? '-'}
            </Text>
          </Box>
          <Box>
            <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
              Vaccination Date
            </Text>
            <Text
              fontSize={OutpostTheme.FontSizes.body}
              marginTop="0.5rem"
              display="flex"
              alignItems="center"
            >
              <CalendarIcon2 fontSize="18px" color="#78819D" />
              &nbsp;
              {moment(vaccination.vaccinationDate).format(TimeFormatEnum.SHORT_LONG_DATE)}
            </Text>
          </Box>

          <Box>
            <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
              Lot Number
            </Text>
            <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
              {vaccination.lotNumber ?? '-'}
            </Text>
          </Box>
          <Box>
            <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
              Vaccine Facility
            </Text>
            <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
              {vaccination.location ?? '-'}
            </Text>
          </Box>

          <Box>
            <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
              Attached Documents
            </Text>

            {vaccination.frontPHRDocumentID && (
              <HStack
                spacing={8}
                justify="space-between"
                fontSize={OutpostTheme.FontSizes.body}
                marginTop="0.5rem"
              >
                <Text>{vaccination.frontPHRDocument?.fileDetail?.name}</Text>
                <SecondaryButton
                  borderRadius="full"
                  height="1.5rem"
                  onClick={() => setCurrentDoc(vaccination.frontPHRDocument!)}
                >
                  View
                </SecondaryButton>
              </HStack>
            )}

            {vaccination.backPHRDocumentID && (
              <HStack
                spacing={8}
                justify="space-between"
                fontSize={OutpostTheme.FontSizes.body}
                marginTop="0.5rem"
              >
                <Text>{vaccination.backPHRDocument?.fileDetail?.name}</Text>
                <SecondaryButton
                  borderRadius="full"
                  height="1.5rem"
                  onClick={() => setCurrentDoc(vaccination.backPHRDocument!)}
                >
                  View
                </SecondaryButton>
              </HStack>
            )}
          </Box>
        </Grid>
        <Box marginTop="1.5rem">
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Notes
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
            {vaccination.notes || 'No notes have been added yet'}
          </Text>
        </Box>
      </PhrItemView>

      {currentDoc && currentDoc.fileDetail?.contentType === 'application/pdf' && (
        <ViewPhrDocument
          patientID={vaccination.patientID ?? ''}
          documentID={currentDoc.phrDocumentID ?? ''}
          onClose={() => setCurrentDoc(null)}
          fileName={currentDoc.fileDetail?.name ?? ''}
        />
      )}

      {currentDoc && currentDoc.fileDetail?.contentType !== 'application/pdf' && (
        <ImageViewer
          src={[
            `/api/v1/PHRs/PHRDocuments/${vaccination.patientID}/DocumentAsSrc/${currentDoc.phrDocumentID}`,
          ]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          onClose={() => {
            setCurrentDoc(null);
          }}
        />
      )}
    </>
  );
};

export default CovidVaccinationView;
