import { Center } from '@chakra-ui/react';
import styled, { keyframes } from 'styled-components';

const loaderSize = 6;
const checkHeight = loaderSize / 2;
const checkWidth = checkHeight / 2;

const Rotation = keyframes`
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: ${checkWidth}em;
    opacity: 1;
  }
  40% {
    width: ${checkHeight}em;
    width: ${checkWidth}em;
    opacity: 1;
  }
  100% {
    width: ${checkHeight}em;
    width: ${checkWidth}em;
    opacity: 1;
  }
`;

const CheckmarkWrapper = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: ${loaderSize}em;
  height: ${loaderSize}em;
  -webkit-animation: none;
  animation: none;
  transition: border 500ms ease-out;
  background-color: #079b35;
`;

const Checkmark = styled.div`
  &:after {
    animation-duration: 500ms;
    animation-timing-function: ease;
    animation-name: ${Rotation};
    transform: scaleX(-1) rotate(135deg);
  }

  &:after {
    opacity: 1;
    height: ${checkHeight}em;
    width: ${checkWidth}em;
    transform-origin: left top;
    border-right: 8px solid #ffffff;
    border-top: 8px solid #ffffff;
    content: '';
    left: ${checkWidth}em;
    top: ${checkHeight}em;
    position: absolute;
  }
`;

interface IProps {
  className?: string;
}

const CenteredAnimatedCheckmark = ({}: IProps) => {
  return (
    <Center>
      <CheckmarkWrapper>
        <Checkmark className="animated-checkmark draw"></Checkmark>
      </CheckmarkWrapper>
    </Center>
  );
};

export default CenteredAnimatedCheckmark;
