import { AddressBookTag, TagTypeEnum } from '../../../generated';

export const getSortedAddressBookTags = (addressBookTags?: AddressBookTag[]) => {
  return (
    addressBookTags
      ?.filter((x) => x?.tag?.tagTypeEnum === TagTypeEnum.Specialties)
      .sort((a, b) => (a.tag && b.tag ? a.tag?.name.localeCompare(b.tag?.name) : 0))
      .sort((a, b) => a.displaySortOrder - b.displaySortOrder)
      .sort((a) => (a.isPrimary ? -1 : 1)) || []
  );
};
