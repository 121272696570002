import { Box, Text } from '@chakra-ui/react';
import { Formik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { date, number, object } from 'yup';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';
import { EmojiPicker } from '../../../../../components/emojiPicker/EmojiPicker';
import useEmojiPicker from '../../../../../components/emojiPicker/useEmojiPicker';
import { Form, FormMobisPicker } from '../../../../../components/forms';
import { FormikButtonGroup } from '../../../../../components/forms/ButtonGroup';
import { CenteredLoadingIndicator } from '../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../components/ServerValidationBox';
import { ButtonSizeEnum } from '../../../../../constants';
import { PHRTrackerAnxietyDTO, TagTypeEnum } from '../../../../../generated';
import { useTagsAllByTagType } from '../../../../../services/tags/queries/useTagsAllByTagType';
import { createForPhrSection, updateForPhrSection } from '../../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../../store/types';
import { ButtonRow } from '../../../components/StyledPhrItems';

const DEFAULT_LEVEL = 3;

interface IProps {
  selectedData: PHRTrackerAnxietyDTO | null;
  patientID: string;
  onClose: () => void;
}

interface IForm {
  trackerDate: Date;
  anxietyValue?: number;
  tagIDs?: string[];
}

const AnxietyForm = ({ selectedData, patientID, onClose }: IProps) => {
  const dispatch = useDispatch();
  const { tagMap, tagsError } = useTagsAllByTagType(TagTypeEnum.AnxietyTrackerValues);
  const { isLoading, emojis, getTagList } = useEmojiPicker({
    tagTypeEnum: TagTypeEnum.AnxietyTracker,
  });
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const onSubmit = async (values: IForm) => {
    setSubmitting(true);
    if (selectedData) {
      selectedData.trackerDate = moment(values.trackerDate).toDate();
      selectedData.anxietyValue = values.anxietyValue;
      selectedData.tags = values.tagIDs?.map((x) => tagMap[x]);
      await dispatch(
        updateForPhrSection(ReduxPHRCategoryEnum.PHRAnxieties, selectedData, patientID, true),
      );
    } else {
      const anxiety = new PHRTrackerAnxietyDTO();
      anxiety.trackerDate = values.trackerDate;
      anxiety.anxietyValue = values.anxietyValue;
      anxiety.tags = values.tagIDs?.map((x) => tagMap[x]);
      await dispatch(
        createForPhrSection(ReduxPHRCategoryEnum.PHRAnxieties, anxiety, patientID, true),
      );
    }
    setSubmitting(false);
    onClose();
  };

  const initialValues: IForm = {
    trackerDate: selectedData ? moment(selectedData.trackerDate!).toDate() : moment().toDate(),
    anxietyValue: selectedData ? selectedData.anxietyValue : DEFAULT_LEVEL,
    tagIDs: selectedData ? selectedData.tags?.map((x) => x.tagID) : [],
  };

  return (
    <Box w="100%">
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          trackerDate: date().required('Date is required').typeError('Invalid date'),
          anxietyValue: number()
            .required('Anxiety Value is required')
            .positive('Anxiety Value must be a positive number')
            .typeError('Anxiety Value must be a positive number')
            .moreThan(0, 'Value must be greater than 0')
            .lessThan(100, 'Value must be less than 100'),
        })}
        onSubmit={onSubmit}
      >
        {({ status, values, setFieldValue }) => (
          <Form error={status}>
            {isSubmitting ? (
              <CenteredLoadingIndicator />
            ) : (
              <>
                <ServerValidationBox message={status || tagsError} />
                <Text textAlign="center" variant="heading" fontSize="20px" mb={6} fontWeight="bold">
                  How anxious do you feel today?
                </Text>
                <div className="row" style={{ display: 'none' }}>
                  <div className="col-12 col-sm-6">
                    <FormMobisPicker
                      name="trackerDate"
                      label="Tracked at"
                      controls={['calendar', 'time']}
                      max={moment().toDate()}
                      min={moment().add(-150, 'year').toDate()}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12">
                    <EmojiPicker
                      isLoading={isLoading}
                      emojis={emojis}
                      value={values.anxietyValue || DEFAULT_LEVEL}
                      onChange={(val) => setFieldValue('anxietyValue', val)}
                    />
                  </div>
                </div>

                <TagsWrapper>
                  {getTagList(values.anxietyValue)?.length > 0 && (
                    <Text
                      textAlign="center"
                      variant="heading"
                      fontSize="14px"
                      mb={8}
                      mt={10}
                      fontWeight="bold"
                    >
                      I also feel
                    </Text>
                  )}
                  <Tags>
                    {getTagList(values.anxietyValue)?.map((x) => (
                      <ButtonGroup
                        key={x.tagXrefID}
                        hideError={true}
                        label={x.toTag?.description}
                        value={x.toTag?.tagID}
                        name="tagIDs"
                        type="checkbox"
                      />
                    ))}
                  </Tags>
                </TagsWrapper>
                <StyledButtonRow>
                  <PrimaryButton size={ButtonSizeEnum.medium} type="submit">
                    I'm done
                  </PrimaryButton>
                </StyledButtonRow>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AnxietyForm;

const Tags = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 12px;
  margin-bottom: 24px;
`;

const ButtonGroup = styled(FormikButtonGroup)`
  width: max-content;
  margin: 4px 12px 4px 0;
  div {
    font-size: 0.85rem;
    font-weight: bold;
    min-height: 26px;
  }
`;

const TagsWrapper = styled.div`
  margin-top: 15px;
  /* margin-left: 2px; */
`;

const StyledButtonRow = styled(ButtonRow)`
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 0;
`;
