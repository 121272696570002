import { ArrowBackIcon } from '@chakra-ui/icons';
import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';

export type BackButtonProps = ButtonProps;

export const BackButton = (props: BackButtonProps) => {
  const { children, fontSize = '16px', ...rest } = props;

  return (
    <Button
      variant="ghost"
      {...rest}
      fontSize={fontSize}
      leftIcon={<ArrowBackIcon fontSize="24px" />}
    >
      {children}
    </Button>
  );
};

export default BackButton;
