import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TimeFormatEnum } from '../../../../constants';
import {
  HeightEnum,
  IUserDetailSetting,
  PHRTrackerHeadCircumferenceDTO,
  UserDetailSettingEnum,
} from '../../../../generated';
import { IPHRPatientEnhanced } from '../../../../store/patient/phr/phrReducers';
import { phrHeadCircumferencesSelector } from '../../../../store/patient/phr/phrSelectors';
import { getAllForPhrSection } from '../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import { enumToOptions } from '../../../../utils';
import { convertHeight } from '../../../../utils/conversionHelpers';
import { Details, ItemName, Value } from '../../components/StyledPhrItems';
import TrackerView from '../TrackerView';
import HeadCircumferenceChart from './HeadCircumferenceChart';
import HeadCircumferenceForm from './HeadCircumferenceForm';

interface IProps {
  patient: IPHRPatientEnhanced;
  userDetailSettings: IUserDetailSetting[];
  renderHeader: (addButton?: any) => any;
}

const HeadCircumferenceComponent = ({ patient, userDetailSettings, renderHeader }: IProps) => {
  const dispatch = useDispatch();
  const unitValue = userDetailSettings.filter(
    (x) => x.userDetailSettingEnum === UserDetailSettingEnum.Height,
  )[0].value;
  const [selectedUnitValue, setSelectedUnitValue] = useState<HeightEnum>(HeightEnum[unitValue]);
  const phrHeadCircumferences = phrHeadCircumferencesSelector(patient.phrHeadCircumferences);
  const [convertedHeadCircumferences, setConvertedHeadCircumferences] = useState<
    PHRTrackerHeadCircumferenceDTO[]
  >([]);

  useEffect(() => {
    if (patient) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRHeadCircumferences, patient.patientID));
    }
  }, [dispatch, patient]);

  useEffect(() => {
    const converted = JSON.parse(JSON.stringify(phrHeadCircumferences));
    setConvertedHeadCircumferences(
      converted.map((x) => {
        x.headCircumference = convertHeight(true, x.headCircumference, selectedUnitValue);
        return x;
      }),
    );
  }, [selectedUnitValue, phrHeadCircumferences]);

  const thinView = (headCircumference: PHRTrackerHeadCircumferenceDTO) => (
    <>
      <ItemName>{headCircumference.headCircumference}</ItemName>
      <Details>
        <Value>
          {' '}
          {moment(headCircumference.trackerDate).format(TimeFormatEnum.LONG_DATE_TIME)}
        </Value>
      </Details>
    </>
  );

  return (
    <TrackerView
      renderHeader={renderHeader}
      phrCategoryEnum={ReduxPHRCategoryEnum.PHRHeadCircumferences}
      patient={patient}
      trackerData={convertedHeadCircumferences}
      thinView={thinView}
      Chart={HeadCircumferenceChart}
      Form={HeadCircumferenceForm}
      createText="Head Circumference"
      unitTypeOptions={enumToOptions(HeightEnum)}
      unitValue={selectedUnitValue}
      setSelectedUnitValue={setSelectedUnitValue}
    />
  );
};

export default HeadCircumferenceComponent;
