import { useQuery } from 'react-query';
import { AuthClient, TimeZone, TimeZonesClient } from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

export const useTimeZonesAll = () => {
  const {
    isLoading: isLoadingTimeZones,
    data: timeZones,
    error: timeZonesError,
  } = useQuery<TimeZone[], string>(
    QueryKeys.TimeZonesAll,
    async () => httpRequest(() => new TimeZonesClient(new AuthClient()).all()),
    { staleTime: Infinity },
  );

  return {
    isLoadingTimeZones,
    timeZones,
    timeZonesError,
  };
};
