import { useField } from 'formik';
import styled from 'styled-components';
import { FontFamilies } from '../../constants';
import FormError from './FormError';

interface IProps {
  label: string;
  value: string;
  name?: string;
  onChange: (value: any) => void;
  checked?: boolean;
  onClick?: (e) => void;
  disabled?: boolean;
  type?: 'radio' | 'checkbox';
  labelTemplate?: any;
}

const ButtonGroup = ({
  label,
  value,
  onChange,
  checked,
  name,
  onClick,
  disabled,
  type = 'radio',
  labelTemplate,
}: IProps) => {
  return (
    <>
      <Label>
        <Input
          name={name}
          type={type}
          value={value}
          onChange={() => onChange(value)}
          checked={checked}
          onClick={onClick}
          disabled={disabled}
        />

        <Button>{labelTemplate ? labelTemplate(label) : label}</Button>
      </Label>
    </>
  );
};

export default ButtonGroup;

interface IFormikProps {
  label: any;
  name: string;
  value?: any;
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: any;
  onClick?: any;
  checked?: boolean;
  hideError?: boolean;
  className?: string;
  dataFor?: string;
  dataTip?: string;
  type?: string;
}

export const FormikButtonGroup = (props: IFormikProps) => {
  const [field, meta] = useField({ ...props, type: props.type ?? 'radio' });

  return (
    <div className={props.className}>
      <Label>
        <Input type={props.type ?? 'radio'} {...field} {...props} />
        <Button data-for={props.dataFor} data-tip={props.dataTip} disabled={props.disabled}>
          {props.label}
        </Button>
      </Label>
      {!props.hideError && <FormError touched={meta.touched} error={meta.error} />}
    </div>
  );
};

const Label = styled.label``;

const Input = styled.input`
  position: absolute;
  left: -999999px;
  top: auto;

  &:checked ~ div {
    background-color: #4c919a;
    color: white;
  }
`;

interface IButtonProps {
  disabled?: boolean;
}

const Button = styled.div<IButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 44px;
  min-height: 44px;
  border-radius: 18px;
  background-color: #e8ecf0;
  color: ${(props) => props.theme.FontColors.Primary};
  outline: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  font-family: ${FontFamilies.Primary};

  &:hover {
    ${(props) => (props.disabled ? 'cursor: not-allowed;opacity: 0.7;' : 'cursor: pointer;')}
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;
