import { AuthClient, PatientOrdersClient } from '../../../../generated';
import { httpRequest } from '../../../../utils';

const patientOrderGet = async (patientOrderID: string) => {
  const patientOrdersClient = new PatientOrdersClient(new AuthClient());

  const orderID = await httpRequest(() => patientOrdersClient.patientOrdersGet(patientOrderID));

  return orderID;
};

export { patientOrderGet };
