import { Box, Link, Text, VStack } from '@chakra-ui/react';
import { Formik } from 'formik';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { object, string } from 'yup';
import { PrimaryButton } from '../../../../../components/Buttons';
import { Form, FormPinInputControl } from '../../../../../components/forms';
import HelmetWrapper from '../../../../../components/HelmetWrapper';
import { CenteredLoadingIndicator } from '../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../components/ServerValidationBox';
import { API_KEY } from '../../../../../constants';
import {
  AuthClient,
  SecurityClient,
  SignUpEmailDetails,
  SignUpEmailValidate,
} from '../../../../../generated';
import useTranslationComponent from '../../../../../hooks/useTranslationComponent';
import { mixTrack } from '../../../../../lib/mixpanel';
import { httpRequest } from '../../../../../utils';
import { SignupFlowEnum } from '../enums';

type SecurityCodeProps = {
  controller: {
    setCurrentStage: Dispatch<SetStateAction<SignupFlowEnum>>;
    handleNext: (values: any) => void;
  };
  token?: string;
  email: string;
  smsNumber: string;
};
interface IForm {
  token: string;
}

const SecurityCode: FC<SecurityCodeProps> = (props) => {
  const { t } = useTranslationComponent(['signin', 'validation']);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const authClient = new AuthClient();
  const securityClient = new SecurityClient(authClient);

  const initialValues: IForm = {
    token: props.token ?? '',
  };

  const submitToken = async (values: IForm) => {
    try {
      setIsLoading(true);
      const signUpEmailValidate = new SignUpEmailValidate();
      signUpEmailValidate.email = props.email;
      signUpEmailValidate.token = values.token;
      const result = await httpRequest(() =>
        securityClient.validateEmailCode(signUpEmailValidate, API_KEY),
      );

      if (result.isValid) {
        mixTrack({
          eventName: 'sign_up:patient_security_code_entered_successfully',
        });
        props.controller.handleNext(signUpEmailValidate.token);
      } else {
        mixTrack({
          eventName: 'sign_up:patient_security_code_entered_failed',
          label: 'Invalid code, please try again',
        });
        setError('Invalid code, please try again.');
        setIsLoading(false);
      }
    } catch (err) {
      mixTrack({
        eventName: 'sign_up:patient_security_code_entered_failed',
      });
      setError(err as string);
      setIsLoading(false);
    }
  };

  const resendCode = async () => {
    try {
      setIsLoading(true);
      const signUpEmailDetails = new SignUpEmailDetails();
      signUpEmailDetails.email = props.email;
      signUpEmailDetails.smsNumber = props.smsNumber ? props.smsNumber : undefined;
      await httpRequest(() => securityClient.sendEmailCode(signUpEmailDetails, API_KEY));
    } catch (err) {
      setError(err as string);
    }
    setIsLoading(false);
  };

  return (
    <>
      <HelmetWrapper title={t('Security Code')} />
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          token: string()
            .required(t('Code is required', { ns: 'validation' }))
            .matches(/^\d{6}$/, t('Code must be a 6 digit number', { ns: 'validation' })),
        })}
        onSubmit={(values) => submitToken(values)}
      >
        {() => (
          <Form error={error} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            {isLoading ? (
              <CenteredLoadingIndicator />
            ) : (
              <Box display="flex" flexDirection="column" flexGrow={1} mt={2}>
                <ServerValidationBox message={error} />

                <VStack alignItems="flex-start">
                  <FormPinInputControl
                    justify="flex-start"
                    size="lg"
                    name="token"
                    label={t('enter security code')}
                    pinLength={6}
                  />

                  <Text fontSize={{ base: '12px', sm: '14px' }}>
                    <Link
                      color="purple.600"
                      fontWeight="600"
                      textDecoration="none"
                      textTransform="lowercase"
                      onClick={resendCode}
                    >
                      {t('Resend Code')}
                    </Link>
                  </Text>
                </VStack>

                <VStack mt="auto" pt={12} pb={1} gap={2}>
                  <PrimaryButton w="100%" tabIndex={3} size="lg" type="submit">
                    {t('continue')}
                  </PrimaryButton>
                </VStack>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export { SecurityCode };
