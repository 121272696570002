import React from 'react';
import { usePhrDocumentsDownload } from '../../../services/phrDocuments/queries/usePhrDocumentsDownload';
import { PdfViewModal } from '../../../components/PdfViewer/PdfViewModal';

interface IProps {
  patientID: string;
  documentID: string;
  fileName: string;
  onClose: () => void;
}

export const ViewPhrDocument = React.memo(
  ({ patientID, fileName, documentID, onClose }: IProps) => {
    const { isDownloadingFile, file, downloadError } = usePhrDocumentsDownload({
      patientID: patientID,
      documentID: documentID,
    });

    return (
      <PdfViewModal
        isOpen={true}
        isLoading={isDownloadingFile}
        loadingError={downloadError ?? ''}
        initialScale={1.5}
        pdf={file}
        fileName={fileName}
        onClose={onClose}
      />
    );
  },
);
