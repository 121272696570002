import { useContext } from 'react';
import { ProviderConsultContext } from '..';

export default function useProviderConsultContext() {
  const context = useContext(ProviderConsultContext);
  if (!context) {
    throw new Error('useProviderConsultContext must be used within a ProviderConsult Provider');
  }
  return context;
}
