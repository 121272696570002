import { createSelector } from 'reselect';
import {
  PatientCareTeam,
  PatientCareTeamTypeEnum,
  PatientInsurance,
  UserDetailRelationship,
} from '../../generated';
import { getTime } from '../../utils';

export const patientDependantsSelector = createSelector(
  (patientDependants?: UserDetailRelationship[] | null) => patientDependants ?? [],
  (phrDependants: UserDetailRelationship[]) =>
    phrDependants
      .filter((dependents) => dependents.dependant)
      .sort((a, b) => (a.name!.toLowerCase()! > b.name!.toLowerCase() ? 1 : -1)),
);

export const patientInsurancesSelector = createSelector(
  (patientInsurances?: PatientInsurance[] | null) => patientInsurances ?? [],
  (phrInsurances) => phrInsurances.sort((a, b) => getTime(b.upsertDate) - getTime(a.upsertDate)),
);

export const patientCareTeamsSelector = createSelector(
  (patientCareTeams: PatientCareTeam[] | undefined) => patientCareTeams ?? [],
  (phrCareTeams) =>
    phrCareTeams
      .filter((x) => x.patientCareTeamTypeEnum !== PatientCareTeamTypeEnum.Self)
      .sort((a, b) => getTime(b.upsertDate) - getTime(a.upsertDate)),
);
