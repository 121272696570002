import { PatientPrescription } from '../../../../generated';

type GetUpdatedPrescriptionProps = {
  prescription: PatientPrescription;
  rest?: Partial<PatientPrescription>;
};

const getUpdatedPrescription = (props: GetUpdatedPrescriptionProps) => {
  const patientPrescription = new PatientPrescription();
  patientPrescription.init({
    ...props.prescription,
    ...props.rest,
  });

  patientPrescription.country = undefined;
  patientPrescription.patient = undefined;
  patientPrescription.provider = undefined;
  patientPrescription.store = undefined;

  return patientPrescription;
};

export { getUpdatedPrescription };
