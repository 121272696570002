import { ActionType, createAsyncAction, createAction } from 'typesafe-actions';
import { ReduxPHRCategoryEnum } from '../../types';
import { IPHRPatientCombined } from './phrReducers';
import { IPHRSummaryDTO } from '../../../generated';

export const phrActions = Object.assign(
  {},
  ...Object.keys(ReduxPHRCategoryEnum).map((key) => {
    const getAllKey = `getAll${key}`;
    const getOneKey = `getOne${key}`;
    const createKey = `create${key}`;
    const deleteKey = `delete${key}`;
    const updateKey = `update${key}`;
    const verifyKey = `verify${key}`;

    return {
      [getAllKey]: createAsyncAction(
        [`GETALL_${key.toUpperCase()}_REQUEST`, () => {}],
        [`GETALL_${key.toUpperCase()}_SUCCESS`, (res: Array<any>) => ({ key, body: res })],
        [`GETALL_${key.toUpperCase()}_ERROR`, (err: string) => err],
      )(),
      [getOneKey]: createAsyncAction(
        [`GETONE_${key.toUpperCase()}_REQUEST`, () => {}],
        [`GETONE_${key.toUpperCase()}_SUCCESS`, (res: any) => ({ key, body: res })],
        [`GETONE_${key.toUpperCase()}_ERROR`, (err: string) => err],
      )(),
      [createKey]: createAsyncAction(
        [`CREATE_${key.toUpperCase()}_REQUEST`, () => {}],
        [`CREATE_${key.toUpperCase()}_SUCCESS`, () => {}],
        [`CREATE_${key.toUpperCase()}_ERROR`, (err: string) => err],
      )(),
      [deleteKey]: createAsyncAction(
        [`DELETE_${key.toUpperCase()}_REQUEST`, () => {}],
        [`DELETE_${key.toUpperCase()}_SUCCESS`, (res: any) => ({ key, body: res })],
        [`DELETE_${key.toUpperCase()}_ERROR`, (err: string) => err],
      )(),
      [updateKey]: createAsyncAction(
        [`UPDATE_${key.toUpperCase()}_REQUEST`, () => {}],
        [`UPDATE_${key.toUpperCase()}_SUCCESS`, () => {}],
        [`UPDATE_${key.toUpperCase()}_ERROR`, (err: string) => err],
      )(),
      [verifyKey]: createAsyncAction(
        [`VERIFY_${key.toUpperCase()}_REQUEST`, () => {}],
        [`VERIFY_${key.toUpperCase()}_SUCCESS`, () => {}],
        [`VERIFY_${key.toUpperCase()}_ERROR`, (err: string) => err],
      )(),
    };
  }),
);

export const getPHRSummaryActions = createAsyncAction(
  ['GET_PHRSUMMARY_REQUEST', () => {}],
  ['GET_PHRSUMMARY_SUCCESS', (res: IPHRSummaryDTO[]) => res],
  ['GET_PHRSUMMARY_ERROR', (err: string) => err],
)();

export const getPHRPatientActions = createAsyncAction(
  ['GET_PHRPATIENT_REQUEST', () => {}],
  ['GET_PHRPATIENT_SUCCESS', (res: IPHRPatientCombined) => res],
  ['GET_PHRPATIENT_ERROR', (err: string) => err],
)();

export const getPHRPatientTokenActions = createAsyncAction(
  ['GET_PHRPATIENTTOKEN_REQUEST', () => {}],
  ['GET_PHRPATIENTTOKEN_SUCCESS', (res: string) => res],
  ['GET_PHRPATIENTTOKEN_ERROR', (err: string) => err],
)();

export const clearPHRPatientTokenAction = createAction('CLEAR_PHRPATIENT_TOKEN', () => {})();

export const clearPHRPatientErrorAction = createAction('CLEAR_PHRPATIENT_ERROR', () => {})();

export type PhrActionTypes = ActionType<typeof phrActions | typeof clearPHRPatientErrorAction>;
