import { Box, RadioProps, useRadio } from '@chakra-ui/react';
import { useContext } from 'react';
import { radioButtonContext } from './RadioButtonContext';

type RadioButtonControlProps = RadioProps & {
  dataFor?: string;
  dataTip?: string;
  activeColor?: string;
};

const RadioButtonAlt = (props: RadioButtonControlProps) => {
  const { dataFor, activeColor, dataTip } = props;

  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: activeColor ? activeColor : '#3E1191',
          color: 'white',
          borderColor: activeColor ? activeColor : '#3E1191',
        }}
        _focus={{
          boxShadow: 'lg',
        }}
        px={5}
        py={1}
        data-for={dataFor}
        data-tip={dataTip}
        fontSize="12px"
      >
        {props.children}
      </Box>
    </Box>
  );
};

export const RadioButtonAltControl = (props: RadioButtonControlProps) => {
  const { dataFor, dataTip, value, ...rest } = props;
  const { getRadioProps } = useContext(radioButtonContext);

  const radio = getRadioProps({ value });
  return (
    <RadioButtonAlt dataTip={dataTip} dataFor={dataFor} {...radio} {...rest}>
      {value}
    </RadioButtonAlt>
  );
};
