import React from 'react';
import { Box, Flex, Text, HStack } from '@chakra-ui/react';
import styled from 'styled-components';
import moment from 'moment';
import Card from '../../../../../components/Card';
import { CenteredLoadingIndicator } from '../../../../../components/LoadingIndicator';
import { Device } from '../../../../../constants';
import { ICurrentUser } from '../../../../../store/currentUser/currentUserReducers';
import { ReduxPHRCategoryEnum } from '../../../../../store/types';
import { IUnitSettings } from '../../hooks/usePatientVitals';
import { usePatientVitalsCard, VitalsTracker } from './usePatientVitalsCard';

interface IProps {
  trackerID: ReduxPHRCategoryEnum;
  tracker: VitalsTracker;
  trackerDisplayName: string;
  showTracker: (trackerID: ReduxPHRCategoryEnum) => void;
  unitSettings?: IUnitSettings;
  currentUser: ICurrentUser | null;
  color: string;
}
export const PatientVitalsCard = ({
  trackerID,
  tracker,
  trackerDisplayName,
  showTracker,
  unitSettings,
  color,
}: IProps) => {
  const { isLoading, recordSet } = usePatientVitalsCard(trackerID, tracker, unitSettings);

  return (
    <Flex
      flexDir="column"
      justify="space-between"
      padding="1.25rem"
      background="#FFFFFF"
      borderRadius="lg"
      h="225px"
      onClick={() => showTracker(trackerID)}
      cursor="pointer"
    >
      {isLoading ? (
        <CenteredLoadingIndicator />
      ) : (
        <>
          <HStack spacing={4}>
            <Box w="28px" h="28px" borderRadius="lg" bgColor={color} />
            <Text fontSize="12px" textTransform="uppercase" color="#414F65">
              {trackerDisplayName}
            </Text>
          </HStack>

          <Text fontSize="24px" fontWeight="semibold">
            {recordSet[0].records[0].value} {recordSet[0].records[0].unit}
          </Text>

          <Text fontSize="12px" color="#414F65">
            Updated {moment(tracker?.upsertDate).fromNow()}
          </Text>
        </>
      )}
    </Flex>
  );
};

export const PatientVitalsCardContainer = ({ children }) => (
  <Card>
    <Container>{children}</Container>
  </Card>
);

interface IRecordSetProps {
  label: string;
  children: any;
}
export const RecordSet = ({ label, children }: IRecordSetProps) => (
  <RecordSetContainer>
    <Label>{label}</Label>
    <div>{children}</div>
  </RecordSetContainer>
);

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media ${Device.mobileL} {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
`;

const RecordSetContainer = styled.div`
  padding-right: 16px;
  padding-top: 8px;
  &:last-of-type {
    padding-right: 0;
    min-width: 0;
  }
  > div {
    display: flex;
  }
  @media ${Device.mobileL} {
    padding-right: 16px;
    min-width: 90px;
  }
`;
const Label = styled.label`
  color: ${(props) => props.theme.Colors.Profile};
  font-size: 1rem;
  font-weight: 700;
  margin-top: 8px;
`;
