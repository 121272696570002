import {
  Box,
  Button,
  Center,
  chakra,
  Circle,
  Divider,
  Flex,
  HStack,
  Stack,
  StackDivider,
  TabPanels,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  BackButton,
  LinkButton,
  PrimaryButton,
  SecondaryButton,
} from '../../../../../../components/Buttons';
import { Avatar } from '../../../../../../components/common/Avatar';
import { CenteredLoadingIndicator } from '../../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../../components/ServerValidationBox';
import { Tab } from '../../../../../../components/Tabs/Tab';
import { TabList } from '../../../../../../components/Tabs/TabList';
import { Tabs } from '../../../../../../components/Tabs/Tabs';
import {
  ConsultTabTypeEnum,
  Device,
  HealRoutes,
  PhoneEventsEnum,
} from '../../../../../../constants';
import {
  ConsultStatusEnum,
  ConsultTypeEnum,
  IConsult,
  RolesEnum,
} from '../../../../../../generated';
import { useViewHeight } from '../../../../../../hooks/useViewHeight';
import { setActiveConsult } from '../../../../../../store/activeConsult/activeConsultActions';
import { AppState } from '../../../../../../store/root-reducers';
import { OutpostTheme } from '../../../../../../themes/outpost';
import { getConsultDescription } from '../../../../../../utils';
import { getUserThumbnail } from '../../../../../../utils/getUserThumbnail';
import { OrderComponent } from '../../../../../phr/orders/Order';
import ReferralsComponent from '../../../../../phr/referrals/ReferralsComponent';
import ConsultChat from '../../../components/ConsultChat/ConsultChat';
import ConsultNotesComponent from '../../ConsultNotesComponent';
import ConsultPHRNotesComponent from '../../ConsultPhrNotesComponent';
import useProviderConsultContext from '../../hooks/useProviderConsultContext';
import MedicationsComponent from '../../MedicationsComponent';
import { SectionPanel } from './SectionPanel';

import { isInRole } from '../../../../../../utils/isInRole';
import { routeWithWindow } from '../../../../../../utils/routeWithWindow';
import useTranslationComponent from '../../../../../../hooks/useTranslationComponent';

interface IProps {
  consult?: IConsult;
  token?: string;
  unreadChatMessages?: number;
}

const ConsultActionArea = ({ consult, token, unreadChatMessages = 0 }: IProps) => {
  const { t } = useTranslationComponent(['clinic']);
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppState) => state.currentUserState.data!);
  const isOnMobile = useBreakpointValue({ base: true, md: false });
  const [showMobileSubMenu, setShowMobileSubMenu] = useState<boolean>(false);
  const {
    isInConsult,
    phrConsultIsLoading,
    loadingPhrConsultError,
    phrConsult,
    tags,
    patientID,
    currentTab,
    setCurrentTab,
    updateSOAPNotes,
    completeConsult,
    isUpdatingPhrConsult,
    reOpenConsult,
    reOpenConsultLoading,
  } = useProviderConsultContext();

  // const waitingRoomClicked = useRef<boolean>(false);
  // const completeConsultClicked = useRef<boolean>(false);

  const isNurse = isInRole(currentUser, RolesEnum.Nurse);

  const handleChange = (index: number) => {
    setCurrentTab(index);
  };

  // Resetting in case we come back into mobile view
  useEffect(() => {
    if (!isOnMobile) {
      setShowMobileSubMenu(true);
    }
  }, [isOnMobile]);

  const { getVH } = useViewHeight();

  const consultNotesHeight = `${Number(getVH(100, false)) - 170}px`;

  const consultNotCompleted = consult?.consultStatusEnum !== ConsultStatusEnum.PatientCompleted;

  const isInMobileWebView =
    currentUser?.isInMobileView &&
    (currentUser.mobileSystem === 'android' || currentUser.mobileSystem === 'ios');

  return (
    <Flex
      height="100%"
      position="relative"
      flexDirection="column"
      bgColor="#ffffff"
      borderLeft="1px solid #F4F4F4"
      overflowY="hidden"
    >
      {loadingPhrConsultError ? (
        <ServerValidationBox message={loadingPhrConsultError} />
      ) : phrConsultIsLoading || isUpdatingPhrConsult || !phrConsult ? (
        <Center minH="400px">
          <CenteredLoadingIndicator />
        </Center>
      ) : isOnMobile ? (
        <>
          <Box flexGrow={1} w="100%" overflowY="auto" h="600px" pt={5}>
            {!showMobileSubMenu && (
              <Box backgroundColor="white" position="absolute" top="0px" zIndex={5} width="100%">
                <BackButton onClick={() => setShowMobileSubMenu(true)}>{t('Back')}</BackButton>
              </Box>
            )}
            <Box display={showMobileSubMenu ? 'block' : 'none'}>
              <Text mb={5} textAlign="center" fontSize={OutpostTheme.FontSizes.body}>
                {t('Patient Information')}
              </Text>
              <HStack px={4} spacing={4}>
                <Avatar size="lg" src={getUserThumbnail(consult?.patientImageDetailID)} />
                <Box>
                  <Text fontWeight="500">{consult?.patientName}</Text>
                  <Text fontSize="12px" noOfLines={2} color="gray.700">
                    {t('Reason')}: {getConsultDescription(consult)}
                  </Text>
                </Box>
              </HStack>
              <Divider mt={7} borderColor="gray.50" mb={1} />

              <Stack divider={<StackDivider borderColor="gray.50" />}>
                <Box px={4}>
                  <Button
                    textAlign="left"
                    variant="unstyled"
                    py={3}
                    isFullWidth
                    onClick={() => {
                      setCurrentTab(ConsultTabTypeEnum.ConsultNotes);
                      setShowMobileSubMenu(false);
                    }}
                  >
                    {t('Consult Notes')}
                  </Button>
                </Box>
                {!isNurse && (
                  <>
                    <Box px={4}>
                      <Button
                        textAlign="left"
                        variant="unstyled"
                        py={3}
                        isFullWidth
                        onClick={() => {
                          setCurrentTab(ConsultTabTypeEnum.Prescriptions);
                          setShowMobileSubMenu(false);
                        }}
                      >
                        {t('Prescriptions')}
                      </Button>
                    </Box>
                    <Box px={4}>
                      <Button
                        textAlign="left"
                        variant="unstyled"
                        py={3}
                        isFullWidth
                        onClick={() => {
                          setCurrentTab(ConsultTabTypeEnum.Orders);
                          setShowMobileSubMenu(false);
                        }}
                      >
                        {t('Diagnostic & Imaging')}
                      </Button>
                    </Box>
                    <Box px={4}>
                      <Button
                        textAlign="left"
                        variant="unstyled"
                        py={3}
                        isFullWidth
                        onClick={() => {
                          setCurrentTab(ConsultTabTypeEnum.Referrals);
                          setShowMobileSubMenu(false);
                        }}
                      >
                        {t('Referrals')}
                      </Button>
                    </Box>
                    <Box px={4}>
                      <Button
                        textAlign="left"
                        variant="unstyled"
                        py={3}
                        isFullWidth
                        onClick={() => {
                          setCurrentTab(ConsultTabTypeEnum.Notes);
                          setShowMobileSubMenu(false);
                        }}
                      >
                        {t('More')}
                      </Button>
                    </Box>
                  </>
                )}
              </Stack>
            </Box>

            <Box
              display={
                !showMobileSubMenu && currentTab === ConsultTabTypeEnum.ConsultNotes
                  ? 'block'
                  : 'none'
              }
            >
              <ConsultNotesComponent consult={consult} isNurse={isNurse} />
              {isInConsult && (
                <HStack
                  backgroundColor="white"
                  width="100%"
                  // position="absolute"
                  bottom={0}
                  spacing={3}
                  px={4}
                >
                  {isInMobileWebView ? (
                    <>
                      <SecondaryButton
                        isFullWidth
                        minWidth="80px"
                        disabled={consult?.consultStatusEnum !== ConsultStatusEnum.PatientCompleted}
                        onClick={async () => {
                          if (await completeConsult(false)) {
                            dispatch(setActiveConsult(null));

                            if (currentUser?.isInMobileView) {
                              if (currentUser?.mobileSystem === 'android') {
                                // @ts-ignore
                                AndroidResult.callback(PhoneEventsEnum.CONSULT_END);
                              } else {
                                // @ts-ignore
                                webkit.messageHandlers.endConsultCallbackHandler.postMessage(
                                  consult?.consultID,
                                );
                              }
                            } else {
                              dispatch(push(HealRoutes.Clinic));
                            }
                          } else {
                            setCurrentTab(ConsultTabTypeEnum.ConsultNotes);
                          }
                        }}
                      >
                        {t('Complete')}
                      </SecondaryButton>
                      <SecondaryButton
                        minWidth="110px"
                        onClick={() => {
                          updateSOAPNotes(true);
                          if (currentUser?.isInMobileView) {
                            if (currentUser?.mobileSystem === 'android') {
                              // @ts-ignore
                              AndroidResult.callback(PhoneEventsEnum.RETURN_TO_WAITING_ROOM);
                              dispatch(push(HealRoutes.WaitingRoom));
                            } else {
                              // @ts-ignore
                              webkit.messageHandlers.returnToWaitingRoomCallback.postMessage(
                                consult?.consultID,
                              );
                            }
                          } else {
                            dispatch(push(HealRoutes.WaitingRoom));
                          }
                        }}
                      >
                        {t('Waiting Room')}
                      </SecondaryButton>
                    </>
                  ) : (
                    <>
                      <LinkButton
                        variant="outlined"
                        opacity={consultNotCompleted ? '0.5' : '1'}
                        cursor={consultNotCompleted ? 'not-allowed' : 'pointer'}
                        minW="80px"
                        px="3"
                        href={consultNotCompleted ? undefined : HealRoutes.Clinic}
                        onClick={
                          consultNotCompleted
                            ? undefined
                            : async () => {
                                if (await completeConsult(false)) {
                                  dispatch(setActiveConsult(null));
                                } else {
                                  setCurrentTab(ConsultTabTypeEnum.ConsultNotes);
                                }
                              }
                        }
                      >
                        {t('Complete')}
                      </LinkButton>
                      <LinkButton
                        href={HealRoutes.WaitingRoom}
                        variant="outlined"
                        minW="110px"
                        px="3"
                        onClick={() => {
                          updateSOAPNotes(true);
                        }}
                      >
                        {t('Waiting Room')}
                      </LinkButton>
                    </>
                  )}
                  <PrimaryButton onClick={() => updateSOAPNotes(false)} isFullWidth minWidth="80px">
                    {t('Save')}
                  </PrimaryButton>
                </HStack>
              )}
            </Box>

            {!isNurse && (
              <>
                <Box
                  pt={7}
                  display={
                    !showMobileSubMenu && currentTab === ConsultTabTypeEnum.Prescriptions
                      ? 'block'
                      : 'none'
                  }
                >
                  <MedicationsComponent
                    isInConsult={isInConsult}
                    patientID={patientID}
                    phrConsultID={phrConsult.phrConsultID}
                    country={phrConsult.country}
                    storeID={phrConsult.storeID}
                  />
                </Box>
                <Box
                  pt={7}
                  display={
                    !showMobileSubMenu && currentTab === ConsultTabTypeEnum.Orders
                      ? 'block'
                      : 'none'
                  }
                >
                  <OrderComponent consult={phrConsult} />
                </Box>
                <Box
                  pt={7}
                  display={
                    !showMobileSubMenu && currentTab === ConsultTabTypeEnum.Referrals
                      ? 'block'
                      : 'none'
                  }
                >
                  {currentTab === ConsultTabTypeEnum.Referrals && (
                    <ReferralsComponent consult={phrConsult!} />
                  )}
                </Box>
                <Box
                  pt={7}
                  display={
                    !showMobileSubMenu && currentTab === ConsultTabTypeEnum.Notes ? 'block' : 'none'
                  }
                >
                  <ConsultPHRNotesComponent
                    patientID={patientID}
                    phrConsultID={phrConsult.phrConsultID}
                    isInConsult={isInConsult}
                  />
                </Box>
              </>
            )}
          </Box>
        </>
      ) : (
        <Tabs
          display="flex"
          flexDirection="column"
          index={currentTab}
          onChange={handleChange}
          width="100%"
        >
          <TabList
            sx={{
              '> button:not(:last-of-type)': {
                mr: 1,
              },
            }}
            mx={{ base: 0, xl: isInConsult ? 6 : 0 }}
            my={2}
            border="1px solid #762EE714"
            backgroundColor="#f2f2f2"
            color="purple.400"
            p="3"
          >
            <TabButton minWidth="0" px="5">
              Consult Notes
            </TabButton>
            {isInConsult && (
              <TabButton
                _selected={{
                  backgroundColor: '#000000',
                  color: 'white',
                  '> div': { backgroundColor: 'white' },
                }}
                minWidth="0"
                px="5"
              >
                Chat{' '}
                {unreadChatMessages > 0 && <Circle ml={1} size={2} backgroundColor="purple.400" />}
              </TabButton>
            )}
            {!isNurse && (
              <>
                <TabButton minWidth="0" px="5">
                  Prescriptions
                </TabButton>
                <TabButton minWidth="0" px="5">
                  Diagnostics
                </TabButton>
                <TabButton minWidth="0" px="5">
                  Referrals
                </TabButton>
                <TabButton minWidth="0" px="5">
                  More
                </TabButton>
              </>
            )}
          </TabList>
          <TabPanels>
            <SectionPanel isInConsult={isInConsult} h={isInConsult ? consultNotesHeight : '500px'}>
              {!phrConsultIsLoading && phrConsult && tags ? (
                <ConsultNotesComponent consult={consult} isNurse={isNurse} />
              ) : (
                <CenteredLoadingIndicator />
              )}
            </SectionPanel>
            {isInConsult && consult && (
              <SectionPanel
                isInConsult={isInConsult}
                h={isInConsult ? consultNotesHeight : '500px'}
              >
                <Flex pb={4} px={4} height="100%" direction="column" backgroundColor="white">
                  <ConsultChat
                    token={token}
                    channelUniqueName={consult.sessionID!}
                    consultID={consult.consultID}
                    consultStatus={consult.consultStatusEnum}
                  />
                </Flex>
              </SectionPanel>
            )}

            {!isNurse && (
              <SectionPanel
                isInConsult={isInConsult}
                h={isInConsult ? consultNotesHeight : '500px'}
                overflowY="auto"
              >
                <Flex p={4} height="100%" direction="column" backgroundColor="white">
                  <MedicationsComponent
                    isInConsult={isInConsult}
                    patientID={patientID}
                    phrConsultID={phrConsult.phrConsultID}
                    country={phrConsult.country}
                    storeID={phrConsult.storeID}
                  />
                </Flex>
              </SectionPanel>
            )}
            {!isNurse && (
              <SectionPanel
                isInConsult={isInConsult}
                h={isInConsult ? consultNotesHeight : '500px'}
                overflowY="auto"
              >
                <Flex p={4} height="100%" direction="column" backgroundColor="white">
                  <OrderComponent consult={phrConsult} />
                </Flex>
              </SectionPanel>
            )}
            {!isNurse && (
              <SectionPanel
                isInConsult={isInConsult}
                h={isInConsult ? consultNotesHeight : '500px'}
              >
                <Flex p={4} height="100%" direction="column" backgroundColor="white">
                  {((isInConsult && currentTab === ConsultTabTypeEnum.Referrals) ||
                    (!isInConsult && currentTab === ConsultTabTypeEnum.Referrals - 1)) && (
                    // when in consult, the tab order changes since the chat tab will be added
                    <ReferralsComponent consult={phrConsult!} />
                  )}
                </Flex>
              </SectionPanel>
            )}
            {!isNurse && (
              <SectionPanel
                isInConsult={isInConsult}
                h={isInConsult ? consultNotesHeight : '500px'}
                overflowY="auto"
              >
                <Flex p={4} height="100%" direction="column" backgroundColor="white">
                  <ConsultPHRNotesComponent
                    patientID={patientID}
                    phrConsultID={phrConsult.phrConsultID}
                    isInConsult={isInConsult}
                  />
                </Flex>
              </SectionPanel>
            )}
          </TabPanels>
          {isInConsult && (
            <HStack
              justifyContent="flex-end"
              backgroundColor="white"
              width="100%"
              spacing={4}
              p={4}
            >
              {(consult?.consultStatusEnum === ConsultStatusEnum.PatientCompleted ||
                consult?.consultStatusEnum === ConsultStatusEnum.Completed) &&
                consult?.consultTypeEnum === ConsultTypeEnum.Video && (
                  <SecondaryButton isLoading={reOpenConsultLoading} onClick={() => reOpenConsult()}>
                    Re-open Consult
                  </SecondaryButton>
                )}
              <SecondaryButton
                disabled={consult?.consultStatusEnum !== ConsultStatusEnum.PatientCompleted}
                onClick={async () => {
                  if (await completeConsult(false)) {
                    await dispatch(setActiveConsult(null));
                    routeWithWindow(HealRoutes.Clinic);

                    // dispatch(push(HealRoutes.Clinic));
                  } else {
                    setCurrentTab(ConsultTabTypeEnum.ConsultNotes);
                  }
                }}
              >
                Complete Consult
              </SecondaryButton>
              <SecondaryButton
                onClick={async () => {
                  await updateSOAPNotes(false);
                  routeWithWindow(HealRoutes.WaitingRoom);
                  // dispatch(push(HealRoutes.WaitingRoom));
                }}
              >
                Waiting Room
              </SecondaryButton>
              {/* <LinkButton
                variant="outlined"
                opacity={consultNotCompleted ? '0.5' : '1'}
                cursor={consultNotCompleted ? 'not-allowed' : 'pointer'}
                href={consultNotCompleted ? undefined : HealRoutes.Clinic}
                onClick={
                  consultNotCompleted
                    ? undefined
                    : async (event) => {
                        if (!completeConsultClicked.current) {
                          event.preventDefault();

                          if (await completeConsult(false)) {
                            dispatch(setActiveConsult(null));
                          } else {
                            setCurrentTab(ConsultTabTypeEnum.ConsultNotes);
                          }

                          completeConsultClicked.current = true;

                          event.currentTarget.click();
                        }
                      }
                }
              >
                Complete Consult
              </LinkButton>
              <LinkButton
                href={HealRoutes.WaitingRoom}
                variant="outlined"
                onClick={(event) => {
                  if (!waitingRoomClicked.current) {
                    event.preventDefault();

                    updateSOAPNotes(true);

                    waitingRoomClicked.current = true;

                    event.currentTarget.click();
                  }
                }}
              >
                Waiting Room
              </LinkButton> */}
              <PrimaryButton onClick={() => updateSOAPNotes(false)}>Save Draft</PrimaryButton>
            </HStack>
          )}
        </Tabs>
      )}
    </Flex>
  );
};

export default ConsultActionArea;

// interface ISectionPanel {

// }
// const SectionPanel = () => chakra(TabPanel, {
//   baseStyle: {
//     padding: 1,
//     height: '100%',
//     borderTop: { md: '1px solid #EDEFF1', xl: 'none' },
//     borderLeft: { md: '1px solid #EDEFF1', xl: 'none' },
//     borderRight: { md: '1px solid #EDEFF1', xl: 'none' },
//     borderTopRadius: { md: '0.5rem', xl: '0' },
//     backgroundColor: { md: 'white', xl: 'transparent' },
//   },
// });

const TabButton = chakra(Tab, {
  baseStyle: {
    minWidth: '80px',
    height: '42px',
    border: 'none',
    borderRadius: '0.25rem',
    fontWeight: '400',
    _selected: { backgroundColor: '#000000', color: 'white' },
  },
});

// const SectionPanel = styled(TabPanel)<IActionAreaProps>`
//   max-height: ${(props) => (props.isInConsult ? 'none' : '700px')};
//   height: ${(props) => (props.isInConsult ? '100%' : '700px')};
//   padding: 0;
// `;

interface IActionAreaProps {
  readonly isInConsult: boolean;
}

export const Wrapper = styled.div<IActionAreaProps>`
  height: 100%;
  overflow: hidden;
  flex: 1;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  /* max-height: ${(props) => (props.isInConsult ? 'none' : '700px')};
  ${(props) => !props.isInConsult && 'height: 700px'}; */
  /* @media ${Device.laptopL} {
    max-height: none;
  } */

  ${(props) =>
    props.isInConsult &&
    `
    background-color: white;
    border-radius: 20px;
 `}
`;
