import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { PHRFamilyHistory } from '../../../generated';
import { verifyForPhrSection } from '../../../store/patient/phr/phrCreators';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { successToastr } from '../../../utils/toastr';

import PhrItemView from '../components/PhrItemView';
import { OutpostTheme } from '../../../themes/outpost';

interface IFamilyHistoryViewProps {
  familyHistory: PHRFamilyHistory;
}

const FamilyHistoryView = ({ familyHistory }: IFamilyHistoryViewProps) => {
  const dispatch = useDispatch();

  const onVerify = async (id: string, patientID: string) => {
    dispatch(verifyForPhrSection(ReduxPHRCategoryEnum.PHRFamilyHistories, id, patientID));
    successToastr({ description: 'Successfully verified family history' });
  };

  return (
    <PhrItemView
      phrItem={{ ...familyHistory, name: familyHistory.condition }}
      onVerify={() => onVerify(familyHistory.phrFamilyHistoryID, familyHistory.patientID)}
    >
      <Flex justify="space-between" flexDir={{ base: 'column', md: 'row' }}>
        <Box maxWidth={{ base: '100%', md: '45%' }}>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Relationship
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
            {familyHistory.relationshipTypeEnum}
          </Text>
        </Box>
        <Box maxWidth={{ base: '100%', md: '45%' }} marginTop={{ base: '1.5rem', md: 0 }}>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Family Side
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
            {familyHistory.fathersSide
              ? 'Father Side'
              : familyHistory.mothersSide
              ? 'Mother Side'
              : '-'}
          </Text>
        </Box>
      </Flex>
    </PhrItemView>
  );
};

export default FamilyHistoryView;
