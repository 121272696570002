import { Formik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { date, number, object } from 'yup';
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton';
import { SecondaryButton } from '../../../../components/Buttons/SecondaryButton';
import { Form, FormInputControl, FormMobisPicker } from '../../../../components/forms';
import { CenteredLoadingIndicator } from '../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../components/ServerValidationBox';
import { ButtonSizeEnum } from '../../../../constants';
import { PHRTrackerStepCounterDTO } from '../../../../generated';
import { createForPhrSection, updateForPhrSection } from '../../../../store/root-creator';
import { AppState } from '../../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import { ButtonRow } from '../../components/StyledPhrItems';

interface IProps {
  selectedData: PHRTrackerStepCounterDTO | null;
  patientID: string;
  toggleView: () => void;
}

interface IForm {
  trackerDate: moment.Moment;
  stepCount?: number;
}

const StepCounterForm = ({ selectedData, patientID, toggleView }: IProps) => {
  const dispatch = useDispatch();
  const { error, isPhrLoading } = useSelector((state: AppState) => state.phrPatientState);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const onSubmit = async (values: IForm) => {
    setSubmitting(true);
    const obj = Object.assign({}, values);
    if (selectedData) {
      selectedData.trackerDate = moment(values.trackerDate).toDate();
      selectedData.stepCount = values.stepCount;
      await dispatch(
        updateForPhrSection(ReduxPHRCategoryEnum.PHRStepCounters, selectedData, patientID, true),
      );
    } else {
      await dispatch(
        createForPhrSection(ReduxPHRCategoryEnum.PHRStepCounters, obj, patientID, true),
      );
    }
  };

  useEffect(() => {
    if (isSubmitting && !isPhrLoading) {
      if (error) {
        setSubmitting(false);
      } else {
        toggleView();
      }
    }
  }, [error, isPhrLoading, isSubmitting, toggleView]);

  const initialValues: IForm = {
    trackerDate: selectedData ? moment(selectedData.trackerDate!) : moment(),
    stepCount: selectedData ? selectedData.stepCount : undefined,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={object({
        trackerDate: date().required('Date is required').typeError('Invalid date'),
        stepCount: number()
          .required('Step Count is required')
          .positive('Step Count must be a postive number')
          .typeError('Step Count must be a postive number')
          .moreThan(0, 'Value must be greater than 0')
          .lessThan(9999999, 'Value must be less than 9999999'),
      })}
      onSubmit={onSubmit}
    >
      {({ status }) => (
        <Form error={error}>
          {isSubmitting ? (
            <CenteredLoadingIndicator />
          ) : (
            <>
              <ServerValidationBox message={status} />
              <div className="row">
                <div className="col-12 col-sm-6">
                  <FormMobisPicker
                    name="trackerDate"
                    label="Tracked at"
                    controls={['calendar', 'time']}
                    max={moment().toDate()}
                    min={moment().add(-150, 'year').toDate()}
                  />
                  {/* <FormDateTimePicker
                    name="trackerDate"
                    label="Tracked at"
                    maxDate={moment().toDate()}
                    minDate={moment().add(-150, 'year').toDate()}
                  /> */}
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <FormInputControl name="stepCount" label="Step Count" type="text" />
                </div>
              </div>
              <ButtonRow>
                <SecondaryButton size={ButtonSizeEnum.medium} onClick={toggleView}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size={ButtonSizeEnum.medium} type="submit">
                  Save
                </PrimaryButton>
              </ButtonRow>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default StepCounterForm;
