import {
  Box,
  Flex,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { FC } from 'react';

import { CalendarIcon2, BagIcon } from '../../../../../../../assets/icons';
import { Avatar } from '../../../../../../../components/common/Avatar';
import { NotificationModal } from '../../../../../../../components/Modals/NotificationModal';
import { Tag as TagComponent } from '../../../../../../../components/TagComponent';
import { ExpandableText } from '../../../../../../../components/Typography';
import { TimeFormatEnum } from '../../../../../../../constants';
import {
  ConsultProviderSearchProviderResult,
  ConsultTypeEnum,
  Tag,
  TagTypeEnum,
} from '../../../../../../../generated';
import useTranslationComponent from '../../../../../../../hooks/useTranslationComponent';
import { OutpostTheme } from '../../../../../../../themes/outpost';
import { getDistanceToShow } from '../../../../../../../utils/getDistanceToShow';
import { getStoreGoogleMapUrl } from '../../../../../../../utils/getStoreGoogleMapUrl';
import { getUserThumbnail } from '../../../../../../../utils/getUserThumbnail';

import { ContinueButton } from '../Buttons';
import { PatientFavoriteIcon } from '../PatientFavoriteIcon/PatientFavoriteIcon';
import { RatingCard } from './RatingCard';

const getHomeCareTags = (tags?: Tag[]) =>
  tags?.filter((tag) => tag.tagTypeEnum === TagTypeEnum.HomeCareService) || [];

const maximumVisibleOtherTags = 2;

const maximumVisibleHomeCareTags = 2;

type ProviderBookingCardProps = {
  onSelectProvider?: (provider: ConsultProviderSearchProviderResult) => void;
  provider: ConsultProviderSearchProviderResult;
  consultTypeEnum?: ConsultTypeEnum;
  careTypeTags?: Tag[];
  patientID?: string;
};

const ProviderBookingCard: FC<ProviderBookingCardProps> = (props) => {
  const { t } = useTranslationComponent(['network']);

  const {
    isOpen: isNotificationModalOpen,
    onClose: onCloseNotificationModal,
    onOpen: onOpenNotificationModal,
  } = useDisclosure();

  const {
    isOpen: isOtherTagsOpen,
    onClose: onCloseOtherTags,
    onOpen: onOpenOtherTags,
  } = useDisclosure();

  const primaryTag = props.provider.primaryProviderConditionTag?.name;

  const homeCareTags = getHomeCareTags(props.provider.providerTags);
  const hasHomeCareTags = homeCareTags.length !== 0;
  const hasManyHomeCareTags = homeCareTags.length > maximumVisibleHomeCareTags;
  const otherTagsNames = props.provider.notPrimaryProviderTags;
  const hasManyOtherTags = otherTagsNames && otherTagsNames.length > maximumVisibleOtherTags;
  const providerMeasurementType = props.provider.country?.measurementTypeEnum;
  const distanceToShow = getDistanceToShow(props.provider.distance, providerMeasurementType);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const getVisibleOtherTags = () => {
    if (hasManyOtherTags) {
      return otherTagsNames?.slice(0, maximumVisibleOtherTags);
    }
    return otherTagsNames;
  };

  const getVisibleHomeCareTags = () => {
    if (hasManyHomeCareTags) {
      return homeCareTags.slice(0, maximumVisibleHomeCareTags);
    }
    return homeCareTags;
  };

  const getAvailability = () => (
    <Flex justifyContent="space-between" mt="auto" direction={{ base: 'column', sm: 'row' }}>
      <Box pr={{ base: '0', sm: '3' }}>
        <Text
          mb="0"
          fontSize={{ base: OutpostTheme.FontSizes.small, sm: OutpostTheme.FontSizes.body }}
          fontWeight="medium"
          color="rgb(65, 79, 101)"
        >
          {t('Next Availability')}:
        </Text>
        {props.provider.nextAvailableAppointmentDate ? (
          <HStack spacing="1" alignItems="center">
            <CalendarIcon2
              color="purple.600"
              fontSize={{ base: OutpostTheme.FontSizes.small, sm: OutpostTheme.FontSizes.body }}
            />
            <Text
              fontSize={{ base: OutpostTheme.FontSizes.small, sm: OutpostTheme.FontSizes.body }}
            >
              {moment(props.provider.nextAvailableAppointmentDate).format(TimeFormatEnum.LONG_DATE)}
            </Text>
          </HStack>
        ) : (
          <Box>
            <Text
              fontSize={{ base: OutpostTheme.FontSizes.small, sm: OutpostTheme.FontSizes.body }}
              as="span"
            >
              {t('No available')}{' '}
            </Text>
            {props.consultTypeEnum && (
              <Text
                as="span"
                fontSize={{ base: OutpostTheme.FontSizes.small, sm: OutpostTheme.FontSizes.body }}
                fontWeight="medium"
              >
                {t(props.consultTypeEnum)} {t(props.careTypeTags?.map((x) => x.name).join(', '))}{' '}
              </Text>
            )}
            <Text
              fontSize={{ base: OutpostTheme.FontSizes.small, sm: OutpostTheme.FontSizes.body }}
              as="span"
            >
              {t('appointments')}
            </Text>
            <Text
              fontSize={{ base: OutpostTheme.FontSizes.small, sm: OutpostTheme.FontSizes.body }}
            >
              ** {t('Other appointment types may be available')}
            </Text>
          </Box>
        )}
      </Box>
    </Flex>
  );

  return (
    <Box bgColor="white" borderRadius="xl" px={[4, 8]} py="4" minHeight="188px">
      <Flex
        alignItems={{ base: 'stretch', md: 'center' }}
        direction={{ base: 'column', md: 'row' }}
        wrap="wrap"
        gap={[6, 8]}
      >
        <Stack
          direction="row"
          gap={[6, 8]}
          maxWidth={{ base: '100%', md: '325px', lg: '38%' }}
          minWidth={{ base: '100%', md: '500px' }}
        >
          <VStack position="relative" alignItems="center" width={{ base: '60px', md: '120px' }}>
            <Box position="relative" mb={[2, 0]}>
              <Avatar
                height={{ base: '60px', md: '120px' }}
                width={{ base: '60px', md: '120px' }}
                src={getUserThumbnail(props.provider.imageDetailID)}
              />
              {props.provider.rating
                ? props.provider.rating !== 0 && <RatingCard rating={props.provider.rating} />
                : null}
            </Box>
          </VStack>
          <Stack flex="1">
            <HStack wrap="wrap">
              <Box flex={{ lg: '1' }}>
                <Text
                  fontSize={{ base: OutpostTheme.FontSizes.body, md: '18px' }}
                  fontWeight="600"
                  color="purple.600"
                >
                  {props.provider.fullName}
                </Text>
                <Text
                  fontSize={{ base: OutpostTheme.FontSizes.small, sm: OutpostTheme.FontSizes.body }}
                  mb="1"
                  color="#000"
                >
                  {props.provider.providerTypeTag?.name}
                  {primaryTag ? `, ${t(primaryTag)}` : ''}
                </Text>
              </Box>
              {props.provider.providerID && props.patientID && (
                <PatientFavoriteIcon
                  isFavorite={props.provider.isFavorite}
                  patientID={props.patientID}
                  providerID={props.provider.providerID}
                />
              )}
            </HStack>

            {props.provider.yearsOfExperience && (
              <HStack spacing="1" alignItems="center">
                <BagIcon fontSize={OutpostTheme.FontSizes.body} />
                <Text
                  fontSize={{ base: OutpostTheme.FontSizes.small, sm: OutpostTheme.FontSizes.body }}
                >
                  {t('Experience')}: {props.provider.yearsOfExperience} {t('years')}
                </Text>
              </HStack>
            )}

            <a href={getStoreGoogleMapUrl(props.provider.fullAddress)} target="_blank">
              <HStack spacing="1" alignItems="flex-start" mt="2">
                <FontAwesomeIcon
                  icon={['fal', 'map-marker-alt']}
                  fontSize={OutpostTheme.FontSizes.body}
                />
                <Text
                  fontSize={{ base: OutpostTheme.FontSizes.small, sm: OutpostTheme.FontSizes.body }}
                >
                  {props.provider.fullAddress}
                </Text>
              </HStack>
            </a>

            {distanceToShow && (
              <Text
                align="left"
                fontSize={{ base: OutpostTheme.FontSizes.small, sm: OutpostTheme.FontSizes.body }}
              >
                {t('Distance')}: <Text as="strong">{distanceToShow}</Text>
              </Text>
            )}

            {/* {hasHomeCareTags && (
            <Box mt="1">
              <Text fontSize="12px" fontWeight="semibold" mb="1">
                Additional Services Offered:
              </Text>
              <Wrap>
                {getVisibleHomeCareTags().map((tag) => (
                  <WrapItem key={tag.tagID}>
                    <TagComponent
                      text={tag.description}
                      textProps={{
                        fontSize: OutpostTheme.FontSizes.small,
                      }}
                      containerProps={{
                        px: '1',
                        py: '0.5',
                        bgColor: 'rgb(255, 222, 0)',
                      }}
                    />
                  </WrapItem>
                ))}
                {hasManyHomeCareTags && (
                  <WrapItem>
                    <IconButton
                      aria-label="see more"
                      bgColor="transparent"
                      border="1px solid rgb(211, 211, 211)"
                      _hover={{ bgColor: 'rgb(219, 217, 217)' }}
                      p="0"
                      minW="40px"
                      minH="19px"
                      height="auto"
                      borderRadius="8"
                      onClick={(event) => {
                        event.stopPropagation();
                        onOpenNotificationModal();
                      }}
                    >
                      <EllipseIcon fontSize="12px" transform="rotate(90deg)" />
                    </IconButton>
                  </WrapItem>
                )}
              </Wrap>
            </Box>
          )} */}

            {!isMobile && getAvailability()}
          </Stack>
        </Stack>

        <Box flex={{ lg: '1' }}>
          {props.provider.professionalStatement && (
            <Box my={['0', '2']}>
              <ExpandableText
                dangerouslySetInnerHTML={{ __html: props.provider.professionalStatement }}
              />
            </Box>
          )}
        </Box>

        {isMobile && getAvailability()}

        <Box
          alignSelf={{ sm: 'center' }}
          mt={{ base: '6', sm: '0' }}
          minW={{ base: '100%', md: '120px' }}
          ml="auto"
        >
          <ContinueButton
            minW={{ base: '100%', sm: '185px' }}
            onClick={() => props.onSelectProvider?.(props.provider)}
          >
            {t('book now')}
          </ContinueButton>
        </Box>
      </Flex>

      {hasManyOtherTags && (
        <NotificationModal
          isModalOpen={isOtherTagsOpen}
          onDismissModal={onCloseOtherTags}
          onClose={onCloseOtherTags}
          modalTitle={t('All Services Offered')}
        >
          <Wrap px="2">
            {otherTagsNames?.map((tag) => (
              <WrapItem key={tag}>
                <TagComponent
                  text={tag}
                  textProps={{
                    fontSize: OutpostTheme.FontSizes.body,
                  }}
                />
              </WrapItem>
            ))}
          </Wrap>
        </NotificationModal>
      )}

      {hasManyHomeCareTags && (
        <NotificationModal
          isModalOpen={isNotificationModalOpen}
          onDismissModal={onCloseNotificationModal}
          onClose={onCloseNotificationModal}
          modalTitle={t('All Additional Services Offered')}
        >
          <Wrap px="2">
            {homeCareTags.map((tag) => (
              <WrapItem key={tag.tagID}>
                <TagComponent
                  text={tag.description}
                  textProps={{
                    fontSize: OutpostTheme.FontSizes.body,
                  }}
                  containerProps={{
                    px: '2',
                    py: '1',
                    bgColor: '#ffedb3',
                  }}
                />
              </WrapItem>
            ))}
          </Wrap>
        </NotificationModal>
      )}
    </Box>
  );
};

export { ProviderBookingCard };
