import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useUrlQuery = () => {
  const location = useLocation();
  const history = useHistory();

  const toggleSearchQuery = useCallback(
    (query: Record<string, string>) => {
      const URLSearchQuery = new URLSearchParams(location.search);

      const queryKeys = Object.keys(query);

      queryKeys.forEach((key) => {
        if (URLSearchQuery.has(key)) {
          if (URLSearchQuery.get(key) === query[key]) {
            URLSearchQuery.delete(key);
          } else {
            URLSearchQuery.set(key, query[key]);
          }
        } else {
          URLSearchQuery.append(key, query[key]);
        }
      });

      const search = URLSearchQuery.toString();

      const updatedLocation = { ...location, search };
      history.replace(updatedLocation);
    },
    [history, location],
  );

  const routeWithQuery = useCallback(
    (query: Record<string, string>, route: string) => {
      const URLSearchQuery = new URLSearchParams(location.search);

      const queryKeys = Object.keys(query);

      queryKeys.forEach((key) => {
        if (URLSearchQuery.has(key)) {
          URLSearchQuery.set(key, query[key]);
        } else {
          URLSearchQuery.append(key, query[key]);
        }
      });

      const search = URLSearchQuery.toString();

      const updatedLocation = { ...location, search };
      history.push({
        ...updatedLocation,
        pathname: route,
      });
    },
    [history, location],
  );

  return { toggleSearchQuery, routeWithQuery };
};

export { useUrlQuery };
