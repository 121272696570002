import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import {
  DashboardRoutes,
  DefaultRoutes,
  HealRoutes,
  LearnRoutes,
  MessageCenterRoutes,
  MonitorRoutes,
  ProfileRoutes,
  RouteConfigs,
  WalletRoutes,
} from '../../../../constants';
import { clearPageTitle, updatePageTitle } from '../../../../store/pageHeader/pageHeaderActions';
import { AppState } from '../../../../store/root-reducers';

const allRoutes = [
  ...Object.values(DashboardRoutes),
  ...Object.values(ProfileRoutes),
  ...Object.values(LearnRoutes),
  ...Object.values(HealRoutes),
  ...Object.values(MonitorRoutes),
  ...Object.values(MessageCenterRoutes),
  ...Object.values(WalletRoutes),
  ...Object.values(DefaultRoutes),
];

const PageTitle = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { title } = useSelector((state: AppState) => state.pageHeaderState);

  useEffect(() => {
    const currentRouteStructure =
      allRoutes.find((path) =>
        matchPath(location.pathname, {
          path,
          exact: true,
        }),
      ) || '';
    const route = RouteConfigs.find((x) => x.path === currentRouteStructure);
    if (route) {
      dispatch(updatePageTitle(route.title));
    }
    return () => {
      dispatch(clearPageTitle());
    };
  }, [location, dispatch]);

  return (
    <Helmet>
      <title>
        {title
          ? `${title} - ${process.env.REACT_APP_COMPANY_NAME}`
          : process.env.REACT_APP_COMPANY_NAME}
      </title>
    </Helmet>
  );
};

export default PageTitle;
