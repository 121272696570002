import { Alert, AlertDescription, AlertIcon, AlertTitle, CloseButton } from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppState } from '../store/root-reducers';
import { LocalStorageKeysEnum } from '../utils/enums';
import { removeLocalStorageItems } from '../utils/localStorageActions';
import { reloadWindow } from '../utils/reloadWindow';
import { SecondaryButton } from './Buttons/SecondaryButton';
import Modal from './Modals/Modal';

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

interface IProps {
  canSignOut?: boolean;
}

const onEndDelegation = () => {
  removeLocalStorageItems([LocalStorageKeysEnum.DelegateAsUserDetailID]);
  reloadWindow();
};

const UserDelegateBanner = ({ canSignOut = true }: IProps) => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return currentUser?.delegateJWT ? (
    <>
      <Alert status="info">
        <AlertIcon />
        <AlertTitle mr={2}>Delegating for {currentUser.fullName}</AlertTitle>
        <AlertDescription
          flexGrow={1}
          display="flex"
          pr={8}
          justifyContent="center"
          alignItems="center"
        >
          {canSignOut && (
            <ButtonWrapper>
              <SecondaryButton onClick={() => setIsOpen(true)}>End Session</SecondaryButton>
            </ButtonWrapper>
          )}
        </AlertDescription>
        <CloseButton position="absolute" right="8px" top="8px" />
      </Alert>
      <Modal
        title="Confirm Logout"
        isOpen={isOpen}
        onSubmit={onEndDelegation}
        onCancel={() => setIsOpen(false)}
        submitText="Confirm"
        cancelText="Cancel"
      >
        <p>
          Are you sure you want to end your delegation session? The page will reload and you will be
          returned to your own account.
        </p>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default UserDelegateBanner;
