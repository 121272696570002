import React from 'react';
import { Grid, Text } from '@chakra-ui/react';
import { ProviderCard } from './ProviderCard';
import { AuthClient, NoteTypeEnum, PHRDocumentsClient, PHRNote } from '../../../../generated';
import { httpRequest, prepareForDownload } from '../../../../utils';

export const SentReferralsList = ({ referrals }) => {
  const download = async (patientID: string, phrDocumentID: string) => {
    const phrDocumentsClient = new PHRDocumentsClient(new AuthClient());
    try {
      const x = await httpRequest(() => phrDocumentsClient.download(patientID, phrDocumentID));
      const newBlob = new Blob([x.data]);
      prepareForDownload(x.fileName!, newBlob);
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <Text mb={5}>Sent Referrals ({referrals?.length})</Text>
      <Grid
        templateColumns={{ base: '1fr', sm: 'repeat(2, 260px)' }}
        templateRows="auto"
        gap={4}
        overflow="scroll"
        height="300px"
      >
        {referrals
          ?.filter((note) => note.noteTypeEnum === NoteTypeEnum.Referral)
          .map((referralNote: PHRNote) => {
            return <ProviderCard referralNote={referralNote} downloadNote={download} />;
          })}
      </Grid>
    </React.Fragment>
  );
};

export default SentReferralsList;
