import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  styles: {
    global: {
      '*': {
        '::-webkit-scrollbar': { width: '8px', height: '8px' },
        '::-webkit-scrollbar-track': {
          borderRadius: '0.5rem',
          marginTop: '8px',
          marginBottom: '8px',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#ccc',
          borderRadius: '0.5rem',
        },
        '::-webkit-scrollbar-corner': {
          borderRadius: '0.5rem',
        },
      },
      body: {
        backgroundColor: '#f9f9f9 !important',
        fontFamily: "'Poppins', 'Open Sans', sans-serif",
      },
      '#root': {
        backgroundColor: '#f9f9f9 !important',
      },
    },
  },
  fonts: {
    body: "'Poppins', 'Open Sans', sans-serif",
    heading: "'Poppins', sans-serif",
    mono: "'Open Sans', sans-serif",
  },
  colors: {
    pallete: {
      red: '#eb5757',
      white: '#FFFFFF',
    },
    green: {
      500: '#069048',
    },
    purple: {
      50: '#fbf9fe',
      100: '#dbc4ff',
      200: '#B796F3',
      300: '#9A6DEE',
      400: '#762EE7',
      500: '#621BE4',
      600: '#3E1191',
      700: '#3B1089',
      800: '#270B5B',
      900: '#14052E',
    },
    gray: {
      50: '#f2f2f2',
      100: '#d9d9d9',
      200: '#bfbfbf',
      300: '#a7a5a6',
      400: '#999999',
      500: '#737373',
      600: '#595959',
      700: '#404040',
      800: '#262626',
      900: '#0d0d0d',
    },
    blue: {
      50: '#e8f1fd',
      100: '#e8f1fd',
      200: '#D0E2FB',
      300: '#A1C6F7',
      400: '#04064b',
      500: '#04064b',
      600: '#00021b',
      700: '#00021b',
      800: '#00021b',
      900: '#00021b',
    },
    borders: {
      default: '#f2f1f3',
    },
    sidebar: {
      menu: {
        background: '#3E1191',
        color: '#FFFFFF',
      },
      logout: {
        background: '#eb5757',
      },
    },
    tab: {
      background: '#3E1191',
      color: '#FFFFFF',
    },
  },
  components: {
    Link: {
      baseStyle: {
        color: '#04064b',
        textDecoration: 'underline',
      },
    },
    Tabs: {
      baseStyle: {
        tablist: {
          flexWrap: 'wrap',
          pt: 3,
          px: 6,
          border: 'none',
        },
        tab: {
          fontSize: '14px',
          fontWeight: '500',
          py: 2,
          px: 8,
          color: '#3E1191',
          borderTopRadius: '0.7rem',
          _selected: {
            borderColor: '#3E1191',
            backgroundColor: '#3E1191',
            color: '#FFFFFF',
          },
          _active: {
            borderColor: '#3E1191',
            backgroundColor: '#3E1191',
            color: '#FFFFFF',
          },
        },
      },
    },
    Accordion: {
      baseStyle: {
        container: {
          borderTopWidth: '0',
          _last: {
            borderBottomWidth: '0',
          },
        },
        button: {
          px: '0',
          py: '0',
        },
      },
    },
    Text: {
      variants: {
        subtitle: {
          fontSize: '16px',
          fontFamily: "'Poppins', sans-serif",
          fontWeight: '400',
        },
        heading: {
          fontSize: '20px',
          fontFamily: "'Poppins', sans-serif",
        },
        body: {
          fontSize: '14px',
        },
      },
    },
    Alert: {
      baseStyle: {
        container: {
          borderRadius: 10,
          fontSize: 13,
        },
      },
    },
    Avatar: {
      baseStyle: {
        container: {
          sx: {
            '> img': { objectFit: 'contain' },
          },
        },
      },
    },
    Heading: {
      sizes: {
        '2xl': {
          fontSize: '56px',
          lineSpacing: '-3%',
        },
        xl: {
          fontSize: '48px',
          lineSpacing: '-3%',
        },
        lg: {
          fontSize: '32px',
          lineSpacing: '-2%',
        },
        md: {
          fontSize: '24px',
        },
        sm: {
          lineHeight: '28px',
          fontWeight: '600',
          fontSize: '20px',
        },
      },
    },
    PinInput: {
      baseStyle: {
        letterSpacing: '0px !important',
        borderRadius: '4px',
        backgroundColor: '#F7F9FC',
        border: '1px solid #E4E9F2',
        borderColor: '#E4E9F2',
      },
    },
    Button: {
      baseStyle: {
        fontWeight: '400',
        borderRadius: '0.5rem',
        fontSize: '14px',
      },
      sizes: {
        lg: {
          fontSize: '14px',
          padding: '1rem 1.25rem',
        },
        md: {
          fontSize: '14px',
          padding: '0.75rem 1rem',
        },
        sm: {
          fontSize: '14px',
          padding: '0.5rem 0.75rem',
        },
        xs: {
          fontSize: '12px',
          padding: '0.25rem 0.5rem',
        },
      },
      variants: {
        danger: {
          color: 'pallete.red',
          backgroundColor: '#FCE8E8',
          _hover: {
            color: '#FFFFFF',
            backgroundColor: 'pallete.red',
          },
        },
        secondary: {
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: '#04064b',
          color: '#04064b',
          _hover: {
            backgroundColor: '#E8F1FD',
            borderColor: '#0F50A9',
            color: '#0F50A9',
          },
        },
        menu: {
          display: 'flex',
          justifyContent: 'flex-start',
          borderRadius: '0.25rem',
          backgroundColor: 'transparent',
          minHeight: '44px',
          padding: '10px 1.25rem',
          color: '#fff',
          width: '100%',
          fontSize: '12px',
          textTransform: 'uppercase',
          fontFamily: 'Inter',
          fontWeight: '500',
          _hover: {
            backgroundColor: '#3E445B',
          },
        },
      },
    },
    Textarea: {
      variants: {
        outline: {
          borderRadius: '4px',
          backgroundColor: '#F7F9FC',
          border: '1px solid #E4E9F2',
          borderColor: '#E4E9F2',
          padding: 4,
          fontSize: '14px',
          _placeholder: {
            color: '#0516466E',
            fontSize: '14px',
          },
          _disabled: {
            backgroundColor: '#f2f1f3',
            color: '#737373',
            opacity: 1,
            borderColor: '#f2f1f3',
          },
        },
      },
    },
    Input: {
      sizes: {
        lg: {
          field: {
            borderRadius: '4px',
            backgroundColor: '#F7F9FC',
            border: '1px solid #E4E9F2',
            borderColor: '#E4E9F2',
            fontSize: '16px',
            _placeholder: {
              color: '#0516466E',
              fontSize: '16px',
            },
            _disabled: {
              backgroundColor: '#f2f1f3',
              color: '#737373',
              opacity: 1,
              borderColor: '#f2f1f3',
            },
          },
        },
      },
      variants: {
        outline: {
          field: {
            borderRadius: '4px',
            backgroundColor: '#F7F9FC',
            border: '1px solid #E4E9F2',
            borderColor: '#E4E9F2',
            fontSize: '14px',
            _placeholder: {
              color: '#0516466E',
              fontSize: '14px',
            },
            _disabled: {
              backgroundColor: '#f2f1f3',
              color: '#737373',
              opacity: 1,
              borderColor: '#f2f1f3',
            },
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderRadius: '0.25rem',
        },
        label: {
          marginInlineStart: '0.75rem',
        },
      },
    },
    Radio: {
      variants: {
        button: {
          container: {
            _hover: {
              cursor: 'pointer',
            },
            padding: '0.5rem',
            borderRadius: '0.25rem',
            backgroundColor: '#f1f1f1',
            borderColor: '#666666',
          },
          control: {
            borderRadius: '0.25rem',
          },
        },
      },
    },
    Modal: {
      variants: {
        borderless: {
          dialog: {
            borderRadius: '1.5rem',
          },
          body: {
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
          },
          header: {
            width: '100%',
            height: '34px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '34px',
            paddingRight: '34px',
            paddingBottom: 0,
            fontSize: '18px',
            textAlign: 'center',
          },
        },
      },
      baseStyle: {
        dialog: {
          top: { base: 'unset', xs: '20px', sm: '20px' },
          marginTop: { base: 0, sm: '3.75rem' },
          marginBottom: { base: 0, sm: '3.75rem' },
          borderRadius: { base: 0, sm: '1rem' },
        },
        dialogContainer: {
          height: '-webkit-fill-available',
        },
        header: {
          paddingTop: 5,
          paddingLeft: '46px',
          paddingRight: '46px',
          paddingBottom: 0,
          fontSize: '18px',
          textAlign: 'center',
        },
        body: {
          padding: { base: '0.5rem 1rem', md: '1.25rem 2.5rem' },
        },
        closeButton: {
          top: '22px',
          right: '14px',
          color: '#200E32',
          borderRadius: '100%',
          height: '24px',
          width: '24px',
          borderColor: '#200E32',
          borderWidth: '2px',
          zIndex: 9999,
        },
      },
      sizes: {
        md: {
          dialog: {
            maxWidth: '36rem',
          },
        },
        lg: {
          dialog: {
            width: '840px',
            maxWidth: '840px',
          },
        },
        xl: {
          dialog: {
            width: '960px',
            maxWidth: '960px',
          },
        },
        '2xl': {
          dialog: {
            width: '1280px',
            maxWidth: '1280px',
          },
        },
      },
    },
    Tooltip: {
      baseStyle: {
        borderRadius: 'md',
      },
    },
  },
});
