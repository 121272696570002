import { AuthClient, ProviderStoreItemsClient } from '../generated';
import { httpRequest } from '../utils';

const getProviderStoreItem = async (providerID: string, storeItemID: string) => {
  const data = await httpRequest(() =>
    new ProviderStoreItemsClient(new AuthClient()).byProviderIDAndStoreItemID(
      providerID,
      storeItemID,
    ),
  );

  return data;
};

export { getProviderStoreItem };
