import { appUrl, communityUrl, idUrl } from './baseUrl';
import { COOKIE_NAME, NotFoundException, UnauthorizedException } from './constants';
import { IConsult, Patient, StoreTypeEnum } from './generated';
import { getCookie } from './utils/cookieHelper';

export const enumToOptions = (enumList) => {
  return Object.keys(enumList)
    .sort((a, b) => a.localeCompare(b))
    .reduce((acc: any, cur) => {
      acc.push({
        id: enumList[cur],
        description: enumList[cur],
      });
      return acc;
    }, []);
};

export const returnUpdatedList = (newObject: any, oldList: any[], key = 'id') => {
  const indexOfLocal = oldList.findIndex((x) => x[key] === newObject[key]);
  if (indexOfLocal !== -1) oldList[indexOfLocal] = newObject;
  return oldList;
};

export const getMerchantStoreDescriptions = (storeTypeEnum?: StoreTypeEnum) => ({
  storeName: storeTypeEnum
    ? storeTypeEnum === StoreTypeEnum.Pharmacy
      ? 'Pharmacy'
      : 'Diagnostic Facility'
    : '',
  itemName: storeTypeEnum
    ? storeTypeEnum === StoreTypeEnum.Pharmacy
      ? 'Prescription'
      : 'Diagnostic Test'
    : '',
});

export const getPatientProvinceStateName = (patient?: Patient): string => {
  return (patient?.provinceState ? patient?.provinceState.name : patient?.province) ?? '';
};

export function updateOrAdd<T>(arr: T[], idField: string, id: string, value: any): T[] {
  const arrAsMap = arr?.reduce(function (map, obj) {
    map[obj[idField]] = obj;
    return map;
  }, {});
  arrAsMap[id] = value;
  return Object.values(arrAsMap);
}

export function formatCurrency(amount: any, currency: any, cultureInfo?: any) {
  return new Intl.NumberFormat(cultureInfo, {
    ...(!!currency && currency !== 'NotSet' ? { style: 'currency', currency: currency } : {}),
  }).format(!isNaN(amount) ? amount : 0);
}

export function getMinorCurrencyUnit(amount, decimalPlace) {
  return Math.floor(amount * Math.pow(10, decimalPlace));
}

export function formatFullName(title = '', firstName = '', lastName = '') {
  return [title, lastName + ',', firstName].join(' ').trim();
}

export function uuid() {
  const chars = '0123456789abcdef'.split('');

  const uuid: Array<string> = [];
  const rnd = Math.random;
  let r: any;
  uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
  uuid[14] = '4'; // version 4

  for (let i = 0; i < 36; i++) {
    if (!uuid[i]) {
      r = 0 | (rnd() * 16);

      uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r & 0xf];
    }
  }

  return uuid.join('');
}

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    //@ts-ignore
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

function timeoutPromise(ms, promise) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      const error = new Error('We experienced a timeout, please try again.');
      error.name = 'TimeoutError';
      reject(error);
    }, ms);
    promise.then(
      (res) => {
        clearTimeout(timeoutId);
        resolve(res);
      },
      (err) => {
        clearTimeout(timeoutId);
        reject(err);
      },
    );
  });
}

export async function httpRequest<T>(
  request: () => Promise<T>,
  signInAction?: () => void,
): Promise<T> {
  return new Promise(async (resolve, reject) => {
    try {
      const result = (await timeoutPromise(300000, request())) as Promise<T>;
      resolve(result);
    } catch (error: any) {
      if (error?.name === 'AbortError') {
        return; // Skip Abort Errors
      }

      if (error?.name === 'TimeoutError') {
        reject(error.message);
      } else if (error.status === 409) {
        reject('Unable to edit this record at this time.');
      } else if (error.status === 404) {
        // Test if we can parse response
        try {
          JSON.parse(error.response);
        } catch (err) {
          window.location.href = '/404';
        }
        const parsed = JSON.parse(error.response);
        reject(new NotFoundException(parsed.Message));
      } else if (error.status === 401) {
        // console.log('req:::', { error, location });
        if (signInAction && typeof signInAction === 'function') {
          signInAction();
        } else if (window.location.pathname?.includes('/heal/health-records')) {
          window.location.href = '/redirect';
        } else if (window.location.pathname !== '/signin') {
          window.location.href = '/signin';
        }
      } else if (error.status === 403) {
        if (error.response) {
          const parsed = JSON.parse(error.response);
          reject(new UnauthorizedException(parsed?.ValidationMessage));
        } else {
          reject(new UnauthorizedException('No Access'));
        }
      } else {
        // Test if we can parse response
        // try {
        //   JSON.parse(error.response);
        // } catch (err) {
        //   throw new Error(error);
        // }
        // console.log(error);
        const parsed = JSON.parse(error.response);
        if (parsed.ValidationMessage) {
          // Recoverable Validation Error - should be displayed
          reject(parsed.ValidationMessage);
        } else {
          if (parsed.Error) {
            reject(parsed.Error);
          } else if (parsed.Message) {
            throw new Error(parsed.Message);
          } else {
            // Some unhandled error
            throw new Error(error.response);
          }
        }
      }
    }
  });
}

export function isEmptyObjectOrNull(value): boolean {
  return !(value && Object.keys(value).length > 0);
}

export function merge(obj1, obj2) {
  for (const key in obj2) {
    if (obj2[key] !== undefined && obj2[key] !== null) {
      obj1[key] = obj2[key];
    }
  }

  return obj1;
}

export const getTime = (date?: Date) => {
  return date != null ? new Date(date).getTime() : 0;
};

export const createMarkup = (htmlString: string) => {
  return { __html: htmlString };
};

export const getConsultDescription = (consult: IConsult | undefined) => {
  if (!consult) {
    return '';
  }

  let description = '';
  if (consult.consultTags && consult.consultTags.length > 0) {
    consult.consultTags.forEach((x, index) => {
      if (index === consult.consultTags!.length - 1) {
        description += `${x.tag!.description.trim()}`;
      } else {
        description += `${x.tag!.description.trim()}, `;
      }
    });
  }
  if (consult.description) {
    if (description.length > 0) {
      description += `, ${consult.description}`;
    } else {
      description += consult.description;
    }
  }
  return description;
};

export const isGeneratedEmailAddress = (userDetailID: string, emailAddress: string) => {
  const strippedEmail = emailAddress.substring(1).split('@')[0].toLowerCase();
  const strippedUserDetailID = userDetailID.replace(/-/g, '').toLowerCase();
  return strippedEmail === strippedUserDetailID;
};

export const checkForEmptyProperties = (obj) => {
  for (const key in obj) {
    if (obj[key] !== null && obj[key] !== '' && obj[key] !== undefined) return false;
  }
  return true;
};

export const prepareForDownload = (fileName: string, blob: Blob) => {
  const data = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = data;
  link.download = fileName;
  link.click();
  setTimeout(function () {
    window.URL.revokeObjectURL(data);
  }, 100);
};

export const generatePassword = (): string => {
  return 'Apple1234$';
  //let generatedPassword = Math.random().toString(36).substring(7);

  //return `A${generatedPassword}${Math.floor(Math.random() * 10)}!`;
};

export const goToCommunity = (userDetailId: string) => {
  const cookie = getCookie(COOKIE_NAME);
  if (!userDetailId && !cookie) return;

  window.location.href = communityUrl + `?t=${cookie}&u=${userDetailId}`;
};

export const goToApp = (userDetailId: string, from?: string) => {
  const cookie = getCookie(COOKIE_NAME);
  if (!userDetailId && !cookie) return;

  window.location.href =
    appUrl + `?t=${cookie}&u=${userDetailId}${from ? `&from=${encodeURIComponent(from)}` : ''}`;
};

export const createURLWithSearchParams = (
  baseURL: string,
  params: Record<string, string>,
): string => {
  const url = new URL(baseURL);
  const searchParams = new URLSearchParams();
  // console.log({ url, params });

  // Add parameters to URLSearchParams object
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      return;
    }
    if (key === 'from') {
      const fromSearchParam = new URLSearchParams(params[key]?.split('?')[1]);
      // console.log({ fromSearchParam });
      if (fromSearchParam.get('t')) fromSearchParam.delete('t');
      if (fromSearchParam.get('u')) fromSearchParam.delete('u');
      if (!fromSearchParam.get(key)) {
        searchParams.set(key, decodeURIComponent(params[key]?.toString()));
      } else {
        searchParams.set(key, decodeURIComponent(fromSearchParam.get(key) || '/'));
      }
    } else {
      searchParams.set(key, key === 'room' ? params[key] : decodeURIComponent(params[key]));
    }
  });

  // Assign URLSearchParams object to the URL
  url.search = searchParams.toString();

  return url.toString();
};

export const handleRouteToIdWithQuery = (from: string, path: string | null = 'login') => {
  const pathString = createURLWithSearchParams(
    `${process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : idUrl}/${
      typeof path === 'string' ? path : 'login'
    }`,
    {
      continue: encodeURIComponent('health-record'),
      from,
    },
  );
  return pathString;
};
