import { Box, Grid, Image, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { CalendarIcon2, DocumentIcon, LabInstrumentIcon, TimeIcon } from '../../../../assets/icons';
import { TimeFormatEnum } from '../../../../constants';
import { PHRTrackerCovidDTO, RolesEnum, TrackerPositiveNegativeEnum } from '../../../../generated';
import { AppState } from '../../../../store/root-reducers';
import { isInRole } from '../../../../utils/isInRole';

import { OutpostTheme } from '../../../../themes/outpost';
import { getTime } from '../../../../utils';
import PhrItem, {
  AddPHRItemButton,
  EmptyPHRItemListCard,
  PHRItemList,
} from '../../components/PhrItem';

interface IProps {
  phrCovidTests: PHRTrackerCovidDTO[];
  covidTestQRCode: string;
  onView: (phrVaccination: PHRTrackerCovidDTO) => void;
  onCreate: () => void;
}

const CovidTestsList = ({ phrCovidTests = [], covidTestQRCode, onView, onCreate }: IProps) => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data!);
  const isPatient = isInRole(currentUser, RolesEnum.Patient);

  return (
    <PHRItemList>
      {!isPatient && <AddPHRItemButton name="Test Result" onCreate={onCreate} />}

      {covidTestQRCode && (
        <Image src={covidTestQRCode} marginX="auto" marginTop="1rem" height="200px" width="200px" />
      )}

      {phrCovidTests.length === 0 ? (
        <EmptyPHRItemListCard>No Test Results added</EmptyPHRItemListCard>
      ) : (
        <>
          {phrCovidTests
            .sort((a, b) => getTime(b.trackerDate) - getTime(a.trackerDate))
            .map((item, index) => (
              <PhrItem
                key={index}
                name="Covid Test"
                isVerified={item.verified}
                onView={() => onView(item)}
                SecondaryInfo={<CovidTestSecondaryInfo item={item} />}
              />
            ))}
        </>
      )}
    </PHRItemList>
  );
};

interface ICovidTestSecondaryInfoProps {
  item: PHRTrackerCovidDTO;
}

export const CovidTestSecondaryInfo = ({ item }: ICovidTestSecondaryInfoProps) => {
  return (
    <Grid
      templateColumns="1fr 1fr"
      gridGap="1rem"
      marginTop="1.5rem"
      fontSize={OutpostTheme.FontSizes.body}
      fontWeight="300"
      color="#030303"
    >
      <Box>
        <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="medium">
          Manufacturer
        </Text>
        <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
          <LabInstrumentIcon fontSize="18px" color="#030303" />{' '}
          {item.covidTestBrandTypeTag?.name || 'Unknown Brand'}
        </Text>
      </Box>
      <Box>
        <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="medium">
          Result
        </Text>
        <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
          <DocumentIcon fontSize="18px" color="#030303" />{' '}
          {!item.testResult
            ? TrackerPositiveNegativeEnum.Invalid
            : item.testResult
            ? TrackerPositiveNegativeEnum.Positive
            : TrackerPositiveNegativeEnum.Negative}
        </Text>
      </Box>
      <Box>
        <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="medium">
          Date of Test
        </Text>
        <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
          <CalendarIcon2 fontSize="18px" color="#030303" />{' '}
          {moment(item.trackerDate).format(TimeFormatEnum.SHORT_LONG_DATE)}
        </Text>
      </Box>
      <Box>
        <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="medium">
          Time of Test
        </Text>
        <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
          <TimeIcon fontSize="18px" color="#030303" />{' '}
          {moment(item.trackerDate).format(TimeFormatEnum.TIME)}
        </Text>
      </Box>
    </Grid>
  );
};

export default CovidTestsList;
