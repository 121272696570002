import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AuthClient, ConsultsClient } from '../../../../../../generated';
import { refreshActiveConsultByConsultID } from '../../../../../../store/activeConsult/activeConsultCreators';
import { errorToastr, successToastr } from '../../../../../../utils/toastr';

interface IProps {
  consultID: string;
}

const useConsultReopen = ({ consultID }: IProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const consultClient = new ConsultsClient(new AuthClient());

  const refreshConsult = () => {
    dispatch(refreshActiveConsultByConsultID(consultID));
  };

  const reOpenConsult = async () => {
    setLoading(true);
    try {
      await consultClient.reopen(consultID);
      setLoading(false);
      refreshConsult();
      successToastr({ description: 'Consult re-opened successfully.' });
    } catch (error: any) {
      errorToastr({ description: 'Error re-opening consult.' });
      setLoading(false);
    }
  };

  return {
    reOpenConsult,
    reOpenConsultLoading: loading,
  };
};

export { useConsultReopen };
