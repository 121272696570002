import React from 'react';
import moment from 'moment';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { CalendarIcon2 } from '../../../assets/icons';
import { PHRSurgeryHistory } from '../../../generated';
import { TimeFormatEnum } from '../../../constants';
import { verifyForPhrSection } from '../../../store/patient/phr/phrCreators';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { successToastr } from '../../../utils/toastr';

import PhrItemView from '../components/PhrItemView';
import { OutpostTheme } from '../../../themes/outpost';

interface ISurgeryHistoryViewProps {
  phrSurgeryHistory: PHRSurgeryHistory;
}

const SurgeryHistoryView = ({ phrSurgeryHistory }: ISurgeryHistoryViewProps) => {
  const dispatch = useDispatch();

  const onVerify = async (id: string, patientID: string) => {
    dispatch(verifyForPhrSection(ReduxPHRCategoryEnum.PHRSurgeryHistories, id, patientID));
    successToastr({ description: 'Successfully verified surgery history' });
  };

  return (
    <PhrItemView
      phrItem={phrSurgeryHistory}
      onVerify={() => onVerify(phrSurgeryHistory.phrSurgeryHistoryID, phrSurgeryHistory.patientID)}
    >
      <Flex justify="space-between" align="center">
        <Box>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Surgery Date
          </Text>
          <Text
            fontSize={OutpostTheme.FontSizes.body}
            marginTop="0.5rem"
            display="flex"
            alignItems="center"
          >
            <CalendarIcon2 fontSize="18px" color="#78819D" />
            &nbsp;
            {moment(phrSurgeryHistory.surgeryDate).format(TimeFormatEnum.SHORT_LONG_DATE)}
          </Text>
        </Box>
        <Box>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Surgeon Name
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
            {phrSurgeryHistory.surgeonName}
          </Text>
        </Box>
      </Flex>

      <Flex justify="space-between" align="center" marginTop="1.5rem">
        <Box>
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Location
          </Text>
          <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
            {phrSurgeryHistory.location}
          </Text>
        </Box>
      </Flex>

      <Box marginTop="1.5rem">
        <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
          Notes
        </Text>
        <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
          {phrSurgeryHistory.notes ? phrSurgeryHistory.notes : 'No notes have been added yet'}
        </Text>
      </Box>
    </PhrItemView>
  );
};

export default SurgeryHistoryView;
