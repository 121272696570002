import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  AddressBook,
  IPatient,
  Medication,
  PatientPrescription,
  PHRMedication,
  SendToEnum,
  StoreTypeEnum,
} from '../../../../../generated';
import { AppState } from '../../../../../store/root-reducers';
import { getStrippedHtmlTags } from '../../../../../utils/getStrippedHtmlTags';
import { errorToastr } from '../../../../../utils/toastr';

import { PrescriptionsTypeEnum } from '../../enums';
import {
  addressBookGet,
  arrayHasData,
  countryDefaultStorePost,
  finalizePrescriptions,
  getCountryDefaultPharmacyDetails,
  getFreshPrhMedication,
  getInitializedPrescription,
  getSendToDetailsForAddress,
  getUpdatedPrescription,
  patientPrescriptionsGet,
  patientPrescriptionsPost,
  patientPrescriptionsPut,
} from '../../utils';

type UsePrescriptionOrderModalProps = {
  patientID: string;
  countryID: string;
  phrConsultID?: string;
  patient?: IPatient;
  onOpenNotificationModal?: (content: ReactElement | string[]) => void;
  onCloseModal?: () => void;
};

type MedicationDataToRemoveType = {
  phrMedication: PHRMedication;
  prescriptionType: PrescriptionsTypeEnum;
};

const usePrescriptionOrderModal = (props: UsePrescriptionOrderModalProps) => {
  const provider = useSelector((state: AppState) => state.providerState.provider);
  const [medicationDataToRemove, setMedicationDataToRemove] = useState<
    MedicationDataToRemoveType | undefined
  >();

  const providerID = provider?.providerID;

  const [expressPharmacyPrescription, setExpressPharmacyPrescription] = useState<
    PatientPrescription | undefined
  >();
  const [selectedPharmacyPrescription, setSelectedPharmacyPrescription] = useState<
    PatientPrescription | undefined
  >();
  const [patientOnlyPrescription, setPatientOnlyPrescription] = useState<
    PatientPrescription | undefined
  >();

  const [prescriptionToEditTypeEnum, setPrescriptionToEditTypeEnum] = useState<
    PrescriptionsTypeEnum | undefined
  >();

  const [sendCopyToPatient, setSendCopyToPatient] = useState(false);
  const [expressPharmacyAddress, setExpressPharmacyAddress] = useState<AddressBook | undefined>();
  const [selectedPharmacyAddress, setSelectedPharmacyAddress] = useState<AddressBook | undefined>();
  const [medicationList, setMedicationList] = useState<Medication[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const selectedPharmacyCountry = selectedPharmacyAddress?.country;

  const countryIDToUse = selectedPharmacyCountry?.countryID || props.countryID;

  const initializeGlobalPrescription = useCallback(async () => {
    if (!providerID || !props.patient) return;

    const defaultPatientPrescription = getInitializedPrescription({
      patient: props.patient,
      patientID: props.patientID,
      countryID: countryIDToUse,
      providerID: providerID,
      phrConsultID: props.phrConsultID,
    });

    const prescriptionID = await patientPrescriptionsPost(defaultPatientPrescription);

    const prescriptionData = await patientPrescriptionsGet(prescriptionID);

    return prescriptionData;
  }, [props.patient, countryIDToUse, props.patientID, props.phrConsultID, providerID]);

  const initializeExpressPharmacyPrescription = useCallback(async () => {
    const patientPrescription = await initializeGlobalPrescription();

    setExpressPharmacyPrescription(patientPrescription);
  }, [initializeGlobalPrescription]);

  const initializeSelectedPharmacyPrescription = useCallback(async () => {
    const patientPrescription = await initializeGlobalPrescription();

    setSelectedPharmacyPrescription(patientPrescription);
  }, [initializeGlobalPrescription]);

  const initializePatientOnlyPrescription = useCallback(async () => {
    const patientPrescription = await initializeGlobalPrescription();

    setPatientOnlyPrescription(patientPrescription);
  }, [initializeGlobalPrescription]);

  const updateExpressPharmacyPrescription = useCallback(async () => {
    if (!expressPharmacyPrescription || !expressPharmacyAddress) return;

    const patientPrescription = getUpdatedPrescription({
      prescription: expressPharmacyPrescription,
    });

    // Both are null and undefined respectively, we just want to check if they are roughly the same
    // eslint-disable-next-line eqeqeq
    if (expressPharmacyPrescription.sendToID == expressPharmacyAddress.associatedID) return;

    patientPrescription.sendToID = expressPharmacyAddress.associatedID;
    patientPrescription.sendToEnum = SendToEnum.Store;
    patientPrescription.storeID = expressPharmacyAddress.associatedID;

    const newPatientPrescriptionID = await patientPrescriptionsPut(patientPrescription);

    const newPatientPrescription = await patientPrescriptionsGet(newPatientPrescriptionID);

    setExpressPharmacyPrescription(newPatientPrescription);
  }, [expressPharmacyAddress, expressPharmacyPrescription]);

  const updateSelectedPharmacyPrescription = useCallback(async () => {
    if (!selectedPharmacyPrescription) return;

    const patientPrescription = getUpdatedPrescription({
      prescription: selectedPharmacyPrescription,
    });

    const sendToDetails = getSendToDetailsForAddress(selectedPharmacyAddress);

    // Both are null and undefined respectively, we just want to check if they are roughly the same
    // eslint-disable-next-line eqeqeq
    if (selectedPharmacyPrescription.sendToID == sendToDetails.sendToID) return;

    patientPrescription.sendToID = sendToDetails.sendToID;
    patientPrescription.sendToEnum = sendToDetails.sendToEnum;
    patientPrescription.storeID =
      sendToDetails.sendToEnum === SendToEnum.Store ? sendToDetails.sendToID : undefined;

    const newPatientPrescriptionID = await patientPrescriptionsPut(patientPrescription);

    const newPatientPrescription = await patientPrescriptionsGet(newPatientPrescriptionID);

    setSelectedPharmacyPrescription(newPatientPrescription);
  }, [selectedPharmacyAddress, selectedPharmacyPrescription]);

  const updatePatientOnlyPrescription = useCallback(async () => {
    if (!patientOnlyPrescription || !props.patient) return;

    const patientPrescription = getUpdatedPrescription({
      prescription: patientOnlyPrescription,
    });

    if (patientOnlyPrescription.sendToID === props.patient.userDetailID) return;

    patientPrescription.sendToID = props.patient.userDetailID;
    patientPrescription.sendToEnum = SendToEnum.UserDetail;
    patientPrescription.sendCopyToPatient = sendCopyToPatient;
    patientPrescription.sendSubject = `Prescription for ${props.patient.fullName}`;
    patientPrescription.sendMessage = `Prescription for ${props.patient.fullName}`;

    const newPatientPrescriptionID = await patientPrescriptionsPut(patientPrescription);

    const newPatientPrescription = await patientPrescriptionsGet(newPatientPrescriptionID);

    setPatientOnlyPrescription(newPatientPrescription);
  }, [props.patient, patientOnlyPrescription, sendCopyToPatient]);

  const getPrescriptionToUe = (medication: Medication | null) => {
    let prescriptionToUse = {
      type: PrescriptionsTypeEnum.PatientOnly,
      data: patientOnlyPrescription,
    };

    if (
      medication?.storeID === selectedPharmacyAddress?.associatedID ||
      !!selectedPharmacyAddress?.addressBookID
    ) {
      return (prescriptionToUse = {
        type: PrescriptionsTypeEnum.SelectedPharmacy,
        data: selectedPharmacyPrescription,
      });
    }

    if (medication?.storeID === expressPharmacyAddress?.associatedID) {
      return (prescriptionToUse = {
        type: PrescriptionsTypeEnum.ExpressPharmacy,
        data: expressPharmacyPrescription,
      });
    }

    return prescriptionToUse;
  };

  const setUsedPrescriptionToUpdate = (
    prescriptionType: PrescriptionsTypeEnum,
    prescription: PatientPrescription,
  ) => {
    switch (prescriptionType) {
      case PrescriptionsTypeEnum.SelectedPharmacy:
        setSelectedPharmacyPrescription(prescription);
        break;
      case PrescriptionsTypeEnum.ExpressPharmacy:
        setExpressPharmacyPrescription(prescription);
        break;
      case PrescriptionsTypeEnum.PatientOnly:
      default:
        setPatientOnlyPrescription(prescription);
    }
  };

  const getPrescriptionDataFromType = (prescriptionType: PrescriptionsTypeEnum) => {
    switch (prescriptionType) {
      case PrescriptionsTypeEnum.SelectedPharmacy:
        return {
          type: prescriptionType,
          data: selectedPharmacyPrescription,
        };
      case PrescriptionsTypeEnum.ExpressPharmacy:
        return {
          type: prescriptionType,
          data: expressPharmacyPrescription,
        };
      case PrescriptionsTypeEnum.PatientOnly:
      default:
        return {
          type: prescriptionType,
          data: patientOnlyPrescription,
        };
    }
  };

  const handleAddPhrMedication = async (
    medication: Medication | null,
    phrMedication: PHRMedication,
    prescriptionType?: PrescriptionsTypeEnum,
  ) => {
    try {
      setIsLoading(true);
      const prescriptionToUse = prescriptionType
        ? getPrescriptionDataFromType(prescriptionType)
        : getPrescriptionToUe(medication);

      if (!prescriptionToUse.data) return;

      const patientPrescription = getUpdatedPrescription({
        prescription: prescriptionToUse.data,
      });

      phrMedication.patientPrescriptionID = patientPrescription.patientPrescriptionID;

      // Add medication to existing phr medication list
      const phrMedications = [...(patientPrescription.phrMedications ?? []), phrMedication];

      patientPrescription.phrMedications = phrMedications;

      const newPatientPrescriptionID = await patientPrescriptionsPut(patientPrescription);

      const newPatientPrescription = await patientPrescriptionsGet(newPatientPrescriptionID);

      setUsedPrescriptionToUpdate(prescriptionToUse.type, newPatientPrescription);
    } catch (error) {
    } finally {
      if (prescriptionToEditTypeEnum) setPrescriptionToEditTypeEnum(undefined);

      setIsLoading(false);
    }
  };

  const handleRemovePhrMedication = async (
    prescriptionType: PrescriptionsTypeEnum,
    phrMedication: PHRMedication | null,
    all = false,
  ) => {
    try {
      setIsLoading(true);
      const prescriptionToUse = getPrescriptionDataFromType(prescriptionType);

      if (!prescriptionToUse.data) return;

      const patientPrescription = getUpdatedPrescription({
        prescription: prescriptionToUse.data,
      });

      // Set prescription to be removed isDeleted prop to true
      const phrMedications = patientPrescription.phrMedications?.map((phrMed) => {
        phrMed.isDeleted = all ? true : phrMed.phrMedicationID === phrMedication?.phrMedicationID;
        return phrMed;
      });

      patientPrescription.phrMedications = phrMedications;

      const newPatientPrescriptionID = await patientPrescriptionsPut(patientPrescription);

      const newPatientPrescription = await patientPrescriptionsGet(newPatientPrescriptionID);

      setUsedPrescriptionToUpdate(prescriptionToUse.type, newPatientPrescription);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendPhrMedicationToPatient = async (
    prescriptionType: PrescriptionsTypeEnum,
    phrMedication: PHRMedication,
  ) => {
    try {
      setIsLoading(true);

      if (!patientOnlyPrescription) return;

      const newPhrMedication = new PHRMedication();
      newPhrMedication.init(getFreshPrhMedication(phrMedication));

      // Remove phr medication from original prescription list
      await handleRemovePhrMedication(prescriptionType, phrMedication);

      // Add phr medication to patient only prescription
      await handleAddPhrMedication(null, newPhrMedication, PrescriptionsTypeEnum.PatientOnly);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnSendPhrMedicationToPatient = async (
    prescriptionType: PrescriptionsTypeEnum,
    phrMedication: PHRMedication,
  ) => {
    try {
      setIsLoading(true);

      if (!patientOnlyPrescription) return;

      // Add medication to original prescription list
      const medication = medicationList.find(
        (med) => med.medicationID === phrMedication.medicationID,
      );

      const newPhrMedication = new PHRMedication();
      newPhrMedication.init(getFreshPrhMedication(phrMedication));

      // Remove phr medication from patient only prescription list
      await handleRemovePhrMedication(prescriptionType, phrMedication);

      // Add phr medication to original prescription list
      await handleAddPhrMedication(medication || null, newPhrMedication);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleSetMedicationDataToRemove = (
    phrMedication: PHRMedication,
    prescriptionType: PrescriptionsTypeEnum,
  ) => {
    setMedicationDataToRemove({
      prescriptionType,
      phrMedication,
    });
  };

  const handleClearMedicationDataToRemove = () => setMedicationDataToRemove(undefined);

  const handleUpdateIsLoading = (loadingState: boolean) => setIsLoading(loadingState);

  const handleUpdateSendCopyToPatient = (sendCopyToPatientState: boolean) =>
    setSendCopyToPatient(sendCopyToPatientState);

  const handleUpdateExpressPharmacyAddress = (address: AddressBook) =>
    setExpressPharmacyAddress(address);

  const handleUpdateSelectedPharmacyAddress = (address: AddressBook) =>
    setSelectedPharmacyAddress(address);

  const handleRemoveSelectedPharmacyAddress = async () => {
    try {
      setIsLoading(true);
      await handleRemovePhrMedication(PrescriptionsTypeEnum.SelectedPharmacy, null, true);
      setSelectedPharmacyAddress(undefined);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateMedicationList = useCallback(
    (medicationList: Medication[]) => setMedicationList(medicationList),
    [],
  );

  const handleUpdatePrescriptionToEditTypeEnum = (prescriptionType?: PrescriptionsTypeEnum) =>
    setPrescriptionToEditTypeEnum(prescriptionType);

  const handleUpdatePatientPrescriptionAddress = async (
    partialAddress: Partial<PatientPrescription>,
    prescriptionType: PrescriptionsTypeEnum,
    prescription?: PatientPrescription,
  ) => {
    if (!prescription) return;

    const patientPrescription = getUpdatedPrescription({
      prescription,
      rest: partialAddress,
    });

    const newPatientPrescriptionID = await patientPrescriptionsPut(patientPrescription);

    const newPatientPrescription = await patientPrescriptionsGet(newPatientPrescriptionID);

    setUsedPrescriptionToUpdate(prescriptionType, newPatientPrescription);
  };

  const handleUpdatePatientAddress = async (partialAddress: Partial<PatientPrescription>) => {
    try {
      setIsLoading(true);

      await handleUpdatePatientPrescriptionAddress(
        partialAddress,
        PrescriptionsTypeEnum.SelectedPharmacy,
        selectedPharmacyPrescription,
      );
      await handleUpdatePatientPrescriptionAddress(
        partialAddress,
        PrescriptionsTypeEnum.ExpressPharmacy,
        expressPharmacyPrescription,
      );
      await handleUpdatePatientPrescriptionAddress(
        partialAddress,
        PrescriptionsTypeEnum.PatientOnly,
        patientOnlyPrescription,
      );
    } catch (error) {
      errorToastr({ description: getStrippedHtmlTags(error as string), isClosable: true });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFinalizePrescription = async () => {
    try {
      setIsLoading(true);

      const notificationMessages: string[] = [];

      const validPrescriptionsIDs: string[] = [];

      if (
        selectedPharmacyPrescription &&
        arrayHasData(selectedPharmacyPrescription?.phrMedications)
      ) {
        validPrescriptionsIDs.push(selectedPharmacyPrescription.patientPrescriptionID);
      }

      if (
        expressPharmacyPrescription &&
        arrayHasData(expressPharmacyPrescription?.phrMedications)
      ) {
        validPrescriptionsIDs.push(expressPharmacyPrescription.patientPrescriptionID);
      }

      if (patientOnlyPrescription && arrayHasData(patientOnlyPrescription?.phrMedications)) {
        validPrescriptionsIDs.push(patientOnlyPrescription.patientPrescriptionID);
      }

      const result = await finalizePrescriptions(props.patientID, validPrescriptionsIDs);

      result.patientPrescriptionFinalizeResultDetails?.forEach((prescriptionResult) => {
        if (prescriptionResult.message) {
          notificationMessages.push(prescriptionResult.message);
        }
      });

      props.onCloseModal?.();

      if (arrayHasData(notificationMessages)) {
        props.onOpenNotificationModal?.(notificationMessages);
      }
    } catch (error) {
      setError(error as string);
      errorToastr({ description: getStrippedHtmlTags(error as string), isClosable: true });
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch default store and store address book
  useEffect(() => {
    (async () => {
      try {
        const countryDefaultStoreDetails = getCountryDefaultPharmacyDetails({
          countryID: countryIDToUse,
          storeTypeEnum: StoreTypeEnum.Pharmacy,
        });

        const expressPharmacy = await countryDefaultStorePost(countryDefaultStoreDetails);

        const expressPharmacyAddressBook = await addressBookGet(expressPharmacy.storeID);

        setExpressPharmacyAddress(expressPharmacyAddressBook);
      } catch (error) {}
    })();
  }, [countryIDToUse]);

  // Initialize global prescriptions
  useEffect(() => {
    (async () => {
      try {
        await initializeExpressPharmacyPrescription();
        await initializeSelectedPharmacyPrescription();
        await initializePatientOnlyPrescription();
      } catch (error) {
        setError(error as any);
      }
    })();
  }, [
    initializeExpressPharmacyPrescription,
    initializeSelectedPharmacyPrescription,
    initializePatientOnlyPrescription,
  ]);

  // Update global prescriptions
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await updateExpressPharmacyPrescription();
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [updateExpressPharmacyPrescription]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await updateSelectedPharmacyPrescription();
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [updateSelectedPharmacyPrescription]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await updatePatientOnlyPrescription();
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [updatePatientOnlyPrescription]);

  return {
    expressPharmacyPrescription,
    selectedPharmacyPrescription,
    patientOnlyPrescription,
    isLoading,
    handleUpdateIsLoading,
    generalPrescriptionError: error,
    sendCopyToPatient,
    handleUpdateSendCopyToPatient,
    expressPharmacyAddress,
    handleUpdateExpressPharmacyAddress,
    selectedPharmacyAddress,
    handleUpdateSelectedPharmacyAddress,
    handleRemoveSelectedPharmacyAddress,
    handleAddPhrMedication,
    handleRemovePhrMedication,
    handleSendPhrMedicationToPatient,
    handleUnSendPhrMedicationToPatient,
    medicationDataToRemove,
    handleSetMedicationDataToRemove,
    handleClearMedicationDataToRemove,
    medicationList,
    handleUpdateMedicationList,
    handleUpdatePatientAddress,
    handleFinalizePrescription,
    selectedPharmacyCountry,
    prescriptionToEditTypeEnum,
    handleUpdatePrescriptionToEditTypeEnum,
  };
};

export { usePrescriptionOrderModal };
