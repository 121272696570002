import { useQuery } from 'react-query';
import { AuthClient, Language, LanguagesClient } from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

export const useLanguagesAll = () => {
  const {
    isLoading: isLoadingLanguages,
    data: languages,
    error: languagesError,
  } = useQuery<Language[], string>(
    QueryKeys.LanguagesAll,
    async () => httpRequest(() => new LanguagesClient(new AuthClient()).all()),
    { staleTime: Infinity },
  );

  return {
    isLoadingLanguages,
    languages,
    languagesError,
  };
};
