import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import {
  GetWaitingRoomActionTypes,
  patientReadyAction,
  togglePlayAudio,
} from './waitingRoomActions';

const consultReadyDates = createReducer<IConsultReady[], GetWaitingRoomActionTypes>(
  [],
).handleAction([patientReadyAction], (state, action) => {
  let found = false;
  state.map((x) => {
    if (x.consultID === action.payload) {
      found = true;
      x.readyDate = new Date();
    }
    return x;
  });

  if (!found) {
    return [...state, { consultID: action.payload, readyDate: new Date() }];
  } else {
    return [...state];
  }
});

const playAudio = createReducer<boolean, GetWaitingRoomActionTypes>(true).handleAction(
  [togglePlayAudio],
  (state) => !state,
);

export interface IConsultReady {
  consultID: string;
  readyDate: Date;
}
export interface IWaitingRoomState {
  consultReadyDates: IConsultReady[];
  playAudio: boolean;
}

export default combineReducers<IWaitingRoomState>({
  consultReadyDates,
  playAudio,
});
