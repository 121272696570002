const getJoinedString = (strings?: any | any[], separator?: string) => {
  if (!strings) {
    return '';
  }

  if (typeof strings === 'string') {
    return strings;
  }

  if (strings instanceof Array) {
    return strings.join(separator || '');
  }
};

export { getJoinedString };
