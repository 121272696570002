import { EMPTY_GUID } from '../../../../constants';
import { AddressBook, SendToEnum } from '../../../../generated';

const getSendToDetailsForAddress = (addressBook?: AddressBook) => {
  if (!addressBook) {
    return {
      sendToEnum: SendToEnum.DoNotSend,
      sendToID: undefined,
    };
  }

  if (addressBook.addressBookID === EMPTY_GUID) {
    // It's an internal Store
    return {
      sendToEnum: SendToEnum.Store,
      sendToID: addressBook.associatedID,
    };
  } else {
    // It's an external Address Book entry
    return {
      sendToEnum: SendToEnum.AddressBookFax,
      sendToID: addressBook.addressBookID,
    };
  }
};

export { getSendToDetailsForAddress };
