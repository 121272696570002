import { Flex, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { ModalBackButton } from '../../../../../components/Buttons/ModalBackButton';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';

interface IProps {
  title: string;
  onBack?: () => void;
  children?: any;
}
export const Title = ({ title, onBack, children }: IProps) => (
  <Container>
    <TitleRow>
      {onBack ? (
        <BackButton onClick={onBack}>
          <ChevronIcon icon={['far', 'chevron-left']} size="lg" />
          {title}
        </BackButton>
      ) : (
        <>{title}</>
      )}
      {children}
    </TitleRow>
  </Container>
);

const Container = styled.div`
  margin-bottom: 20px;
  padding-right: 16px;
`;
const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
`;
const BackButton = styled.button`
  border: none;
  background-color: transparent;
  font-weight: 700;
`;
const ChevronIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

export const renderHeader =
  (onBack?: () => void, title?: string) => (addButton?: any, optionalBack?: () => void) =>
    (
      <Flex w="100%" justify={'space-between'} align="center" mb={8}>
        <Flex
          align={'center'}
          justify={{ base: 'flex-start', md: 'space-between' }}
          w={{ base: 'auto', md: '55%' }}
        >
          {renderBackButton(false, optionalBack ? optionalBack : onBack)}
          <Text variant="heading" fontSize="16px">
            {title}
          </Text>
        </Flex>
        {addButton ? addButton() : renderBackButton(true, onBack)}
      </Flex>
    );

const renderBackButton = (hidden: boolean, onBack?: () => void) => (
  <>
    <ModalBackButton
      left="unset"
      position="relative"
      visibility={hidden ? 'hidden' : 'visible'}
      onClick={() => onBack && onBack()}
    />
  </>
);

export const addButton = (handleClick: () => void, label?: string) => (
  <PrimaryButton onClick={handleClick}>
    <FontAwesomeIcon icon={['far', 'plus']} />
    {label ?? ' Add'}
  </PrimaryButton>
);
