import React from 'react';
import { HStack, Text, LinkProps, Link } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

type LinkButtonProps = LinkProps & {
  hasForwardIcon?: boolean;
};

const LinkButtonVariant = {
  solid: {
    background: 'purple.600',
    color: 'white',
    _hover: {
      background: 'white',
      color: 'purple.600',
      borderColor: 'purple.600',
    },
  } as LinkProps,
  outlined: {
    background: 'white',
    color: 'purple.600',
    borderColor: 'purple.600',
    _hover: {
      background: 'purple.600',
      color: 'white',
    },
  } as LinkProps,
};

export const LinkButton = (props: LinkButtonProps) => {
  const {
    children,
    minWidth = '120px',
    borderRadius = '2rem',
    fontSize = '12px',
    py = '9px',
    px = '6',
    border = '1px solid white',
    variant = 'solid',
    hasForwardIcon = false,
    ...rest
  } = props;
  return (
    <Link
      minWidth={minWidth}
      borderRadius={borderRadius}
      fontSize={fontSize}
      textDecoration="none"
      border={border}
      py={py}
      px={px}
      {...LinkButtonVariant[variant]}
      {...rest}
    >
      <HStack justifyContent="space-between">
        <Text>{children}</Text>
        {hasForwardIcon && <ArrowForwardIcon />}
      </HStack>
    </Link>
  );
};
