import { FileDetail } from '../generated';

export const getFileDetail = (
  type: string,
  size: number,
  name: string,
  readBuffer: ArrayBuffer,
) => {
  const convertFilename = (filename) => {
    const nameTokens = filename.replace(/\s+/g, '').split('.');
    const fileExtension = nameTokens.length - 1;
    nameTokens[fileExtension] = nameTokens[fileExtension].toLowerCase();
    return nameTokens.join('.');
  };
  const byteArray: any = new Uint8Array(readBuffer).toByteArray();
  const convertedFilename = convertFilename(name);
  const fileDetail = new FileDetail();

  fileDetail.bytes = byteArray;
  fileDetail.name = convertedFilename;
  fileDetail.contentType = type;
  fileDetail.size = size;
  return fileDetail;
};
