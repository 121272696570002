import { Flex, ModalBody, ModalFooter } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import Rating from 'react-rating';
import styled from 'styled-components';
import { IFormQuestion, IPHRFormQuestion, PHRFormQuestion } from '../../../../../generated';
import { ButtonRow } from '../../../../Buttons';
import useFormBuilderContext from '../../../hooks/useFormBuilderContext/useFormBuilderContext';
import { NextButton } from '../../NavigationButtons/NextButton';
import { PrevButton } from '../../NavigationButtons/PrevButton';
import { QuestionHeader } from '../../QuestionHeader/QuestionHeader';

interface IProps {
  formQuestion: IFormQuestion;
  previousAnswer?: IPHRFormQuestion;
}

export const RatingQuestion = ({ formQuestion, previousAnswer }: IProps) => {
  const [rating, setRating] = useState<number>();
  const { onNext, goToPrevious, canGoBack, formQuestions, state, lastStep } =
    useFormBuilderContext();

  useEffect(() => {
    if (previousAnswer?.value) {
      setRating(parseInt(previousAnswer?.value));
    }
  }, [previousAnswer, setRating]);

  return (
    <>
      <ModalBody>
        <QuestionHeader
          title={formQuestion.name}
          description={formQuestion.description}
          currentStep={state.currentIndex + 1}
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
          totalQuestionCount={formQuestions?.length!}
        />
        <Flex w="100%" justifyContent="center" alignItems="center">
          {/* @ts-ignore */}
          <Rating
            initialRating={rating}
            emptySymbol={<Star icon={['fal', 'star']} />}
            fullSymbol={<Star icon={['fas', 'star']} />}
            onChange={(rating) => setRating(rating)}
          />
        </Flex>
      </ModalBody>
      <ModalFooter>
        <ButtonRow>
          {canGoBack && <PrevButton onClick={goToPrevious} />}
          <NextButton
            lastStep={lastStep}
            onClick={() => {
              const newFormQuestion = new PHRFormQuestion();
              if (previousAnswer) {
                newFormQuestion.init(previousAnswer);
              } else {
                newFormQuestion.formQuestionID = formQuestion.formQuestionID;
              }
              newFormQuestion.value = `${rating}`;
              onNext(newFormQuestion);
            }}
            disabled={formQuestion.required && !rating}
          />
        </ButtonRow>
      </ModalFooter>
    </>
  );
};

const Star = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.Colors.Primary};
  font-size: 2.4rem;
  margin: 0 8px;
`;
