import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { PrimaryButton, SecondaryButton } from '../../../components/Buttons';

interface IProps {
  children: React.ReactNode;
}

const PhrItemForm = ({ children }: IProps) => {
  return (
    <Box
      bgColor="#ffffff"
      borderRadius="lg"
      position="relative"
      overflowY="auto"
      marginX={{ base: '0.5rem', md: '1.5rem' }}
      padding={{ base: '1rem 0.5rem', md: '2.5rem 1.5rem' }}
      border={{ base: 'none', md: '1px solid #0C346891' }}
      height={{ base: 'calc(100vh - 8rem)', md: '550px' }}
    >
      {children}
    </Box>
  );
};

export default PhrItemForm;

export const PhrFormButtons = ({ innerBtnRef, toggleView }) => {
  return (
    <Flex
      marginLeft="auto"
      marginRight={{ base: '1.5rem', md: '2.5rem' }}
      justify="flex-end"
      align="center"
      alignSelf="flex-start"
      position="sticky"
      bottom="-1px"
    >
      <PrimaryButton borderRadius="full" onClick={() => innerBtnRef.current.click()}>
        Save
      </PrimaryButton>
      <SecondaryButton
        display={{ base: 'none', md: 'block' }}
        fontWeight="normal"
        marginLeft="1rem"
        onClick={toggleView}
      >
        Cancel
      </SecondaryButton>
    </Flex>
  );
};
