import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

const useGoogleTagManager = () => {
  const [isInitialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    const loadGoogleTagManager = async () => {
      try {
        TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID });
        setInitialized(true);
      } catch (err) {
        console.log('Unable to Initialize Google Tag Manager', err);
      }
    };

    if (!isInitialized) {
      loadGoogleTagManager();
    }
  }, [isInitialized]);
};

export default useGoogleTagManager;
