import React, { useState, useEffect } from 'react';
import { Flex, Text, Image, Box } from '@chakra-ui/react';
import { Tag } from '../../generated';
import { CenteredLoadingIndicator } from '../LoadingIndicator';

interface IEmojiPickerProps {
  onChange: (value: any) => void;
  value: number;
  isLoading?: boolean;
  emojis: Tag[];
}

export const EmojiPicker = ({ onChange, value = 1, emojis, isLoading }: IEmojiPickerProps) => {
  const [selected, setSelected] = useState<number>(value);

  useEffect(() => {
    if (value > 5) setSelected(5);
    if (value < 1) setSelected(1);
  }, [value]);

  return (
    <>
      {isLoading ? (
        <Box>
          <CenteredLoadingIndicator />
        </Box>
      ) : (
        <Box w="100%">
          <Flex my={4} w="100%" justify={{ base: 'center', md: 'space-between' }} wrap="wrap">
            {emojis
              .sort((a, b) => parseInt(a.externalID || '') - parseInt(b.externalID || ''))
              .map((item) => (
                <Flex
                  key={item.externalID}
                  direction="column"
                  borderRadius="18px"
                  border="2px solid"
                  borderColor={selected === parseInt(item.externalID || '') ? '#00A18F' : '#E4E9F2'}
                  cursor="pointer"
                  align="center"
                  p={4}
                  w={{ base: '45%', md: '19%' }}
                  transition=".2s ease-in"
                  _hover={{
                    borderColor: '#00A18F',
                  }}
                  onClick={() => {
                    setSelected(parseInt(item.externalID || ''));
                    onChange(item.externalID);
                  }}
                  m={{ base: 2, md: 0 }}
                >
                  <Box h="70%">
                    <Image src={item.imageURL} mb={4} />
                  </Box>
                  <Text textAlign="center" fontWeight="bold" variant="body">
                    {item.description}
                  </Text>
                </Flex>
              ))}
          </Flex>
        </Box>
      )}
    </>
  );
};

export default EmojiPicker;
