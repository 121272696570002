import { Stack, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { SpacingRelated } from '../../constants';
import { FileDetail } from '../../generated';
import { TertiaryButton } from '../Buttons';
import { CenteredLoadingIndicator } from '../LoadingIndicator';
import { DeleteFileModal } from './DeleteFileModal/DeleteFileModal';
import { FileListItem } from './FileListItem/FileListItem';
import useFileUploader, { IFileUpload } from './useFileUploader/useFileUploader';
import useLoadMimeTypes from './useLoadMimeTypes/useLoadMimeTypes';

interface IProps {
  files: IFileUpload[];
  setFiles: React.Dispatch<React.SetStateAction<IFileUpload[]>>;
  getRequestBody: (fileDetail: FileDetail) => any;
  deleteFile: (id: string) => void;
  uploadUrl: string;
  instructions?: string;
  dropzoneOptions?: DropzoneOptions;
}

export const FileUploader = ({
  files,
  setFiles,
  getRequestBody,
  deleteFile,
  uploadUrl,
  instructions = 'Add a picture or documents such as lab reports etc.',
  dropzoneOptions,
}: IProps) => {
  const [idToDelete, setIdToDelete] = useState<string>('');
  const { onFileAdded } = useFileUploader({
    files,
    setFiles,
    getRequestBody,
    uploadUrl,
    multiple: dropzoneOptions?.multiple,
  });
  const { allowedTypes, isLoading } = useLoadMimeTypes();
  const {
    onDrop = onFileAdded,
    minSize = 0,
    multiple = true,
    noClick = true,
    noKeyboard = true,
    accept = allowedTypes,
    ...restDropzoneOptions
  } = dropzoneOptions || {};
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    minSize,
    multiple,
    noClick,
    noKeyboard,
    accept,
    ...restDropzoneOptions,
  });

  const onDelete = (name: string) => {
    const file = files.find((x) => x.name === name);
    if (file?.id) {
      setIdToDelete(file.id);
    } else {
      setFiles(files.filter((x) => x.name !== name));
    }
  };

  return isLoading ? (
    <CenteredLoadingIndicator />
  ) : (
    <Wrapper>
      <Dropzone {...getRootProps()}>
        <Stack alignItems="center" spacing={2}>
          <UploadIcon icon={['far', 'cloud-upload']} onClick={open} size="2x" />
          <Text textAlign="center" fontWeight="bold">
            {instructions}
          </Text>
          <Text>
            Drag and Drop to Upload or <TertiaryButton onClick={open}>Browse Files</TertiaryButton>
          </Text>
        </Stack>
        <input {...getInputProps()} />
      </Dropzone>
      <FilesList>
        {files.map((x, index) => (
          <FileListItem onDelete={onDelete} key={index} file={x} />
        ))}
      </FilesList>
      <DeleteFileModal
        id={idToDelete}
        onClose={() => setIdToDelete('')}
        onDelete={(phrDocumentID: string) => {
          deleteFile(phrDocumentID);
          setIdToDelete('');
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 400px;
  max-width: calc(100vw - 30px);
`;

const FilesList = styled.div`
  display: flex;
  flex-direction: column;
`;

const Dropzone = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${SpacingRelated.Regular};
`;

const UploadIcon = styled(FontAwesomeIcon)`
  color: var(--chakra-colors-blue-500);

  &:hover {
    cursor: pointer;
  }
`;
