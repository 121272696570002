import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import { AddressBook } from '../../../../generated';
import useAddressBooksContext from '../../context/AddressBooksProvider';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  addressBook: AddressBook;
}

export const DeleteConfirm = ({ isOpen, onClose, addressBook }: IProps) => {
  const { onDelete } = useAddressBooksContext();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Heading size="sm">Are you sure you want to delete this contact?</Heading>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" colorScheme="blue" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              onDelete(addressBook);
              onClose();
            }}
          >
            Yes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
