import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { TimeFormatEnum } from '../constants';
import useTranslationComponent from '../hooks/useTranslationComponent';
import { getAge } from '../utils/getAge';

interface IProps {
  birthDate?: string | Date;
  showBirthDate?: boolean;
  showAge?: boolean;
  showBirthDayIcon?: boolean;
}

const BirthDateComponent = ({
  birthDate,
  showBirthDate = false,
  showAge = false,
  showBirthDayIcon = false,
}: IProps) => {
  const { t } = useTranslationComponent();
  const isBirthDay = (birthDate: string | Date): boolean =>
    moment().date() === moment(birthDate).date() && moment().month() === moment(birthDate).month();

  if (!birthDate) return <>-</>;
  if (!showBirthDate) return <>{showAge && `${getAge(birthDate, t)} y/o`}</>;
  return (
    <>
      {moment(birthDate).format(TimeFormatEnum.LONG_DATE)}
      {showAge && ` | ${getAge(birthDate, t)}`}{' '}
      {showBirthDayIcon && isBirthDay(birthDate) && (
        <FontAwesomeIcon icon={['far', 'birthday-cake']} />
      )}
    </>
  );
};

export default BirthDateComponent;
