import { ModalBody } from '@chakra-ui/react';
import { BaseModal } from '../../../../../../../components/Modals';
import PhrComponent from '../../../../../../phr/PhrComponent';

interface IProps {
  patientName?: string;
  patientID?: string;
  onClose?: () => void;
}

export const HealthRecords = ({ patientID, onClose }: IProps) => {
  return (
    <BaseModal isOpen={!!patientID} size="xl" modalContentProps={{ overflowY: 'hidden' }}>
      <ModalBody padding={0}>
        <PhrComponent patientID={patientID!} onDismiss={onClose} />
      </ModalBody>
    </BaseModal>
  );
};
