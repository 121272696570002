/* eslint-disable import/namespace */
import * as generated from '../../../generated';
import { httpRequest } from '../../../utils';
import { scrollToTop } from '../../../utils/scrollToTop';
import { AppState, OHThunkResult } from '../../root-reducers';
import { ReduxPHRCategoryEnum } from '../../types';
import {
  clearPHRPatientTokenAction,
  getPHRPatientActions,
  getPHRPatientTokenActions,
  getPHRSummaryActions,
  phrActions,
} from './phrActions';
import { IPHRPatientCombined } from './phrReducers';

export const isTrackerType = (phrType: ReduxPHRCategoryEnum) => {
  const phrTypeString = phrType.toLowerCase();
  return (
    ReduxPHRCategoryEnum.PHRBloodPressures.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRPulses.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRBloodGlucoses.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRBloodOxygens.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRRespiratories.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRTemperatures.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRStepCounters.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRHCVs.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRUricAcids.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRHemoglobins.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRCholesterols.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRFetalDopplers.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRTyphoids.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRDengues.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRMalarias.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRChinkungunias.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRTroponins.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRSyphilises.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRHIVs.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRRapidPregnancies.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRHeadCircumferences.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRWaistCircumferences.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRWeightHeights.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRLengths.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRMoods.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRAnxieties.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRStresses.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRSleeps.toLowerCase() === phrTypeString ||
    ReduxPHRCategoryEnum.PHRCovids.toLowerCase() === phrTypeString
  );
};

export function getPHRPatient(
  patientID: string,
  withSummary = true,
): OHThunkResult<Promise<IPHRPatientCombined>> {
  return async (dispatch) => {
    return new Promise<IPHRPatientCombined>(async (resolve) => {
      dispatch(getPHRPatientActions.request());

      try {
        const patientsClient = new generated.PatientsClient(new generated.AuthClient());
        const patient = await httpRequest(() => patientsClient.patientsGet(patientID));

        const phrPatientsClient = new generated.PHRPatientsClient(new generated.AuthClient());
        const phr = await httpRequest(() => phrPatientsClient.pHRPatientsGet(patientID));

        const result: IPHRPatientCombined = {
          patient,
          phr,
        };

        dispatch(getPHRPatientActions.success(result));
        dispatch(clearPHRPatientTokenAction());
        if (withSummary) {
          dispatch(getPHRSummaryDTO(true));
        }
        resolve(result);
      } catch (err) {
        dispatch(getPHRPatientActions.failure(err as any));
      }
    });
  };
}

export const getAllForPhrSection = <T>(
  phrType: ReduxPHRCategoryEnum,
  patientID: string,
): OHThunkResult<Promise<Array<T>>> => {
  return async (dispatch, getState: () => AppState) => {
    return new Promise<Array<T>>(async (resolve) => {
      if (getState().phrPatientState.phrPatient?.patient?.patientID !== patientID) {
        await dispatch(getPHRPatient(patientID));
      }

      const actions = phrActions[`getAll${phrType}`];

      dispatch(actions.request());
      let client;
      if (isTrackerType(phrType)) {
        client = new generated.PHRTrackersClient(
          new generated.AuthClient(getState().phrPatientState.token),
        );
      } else {
        client = new generated[`${phrType}Client`](
          new generated.AuthClient(getState().phrPatientState.token),
        );
      }
      try {
        let result: Array<T>;
        if (isTrackerType(phrType)) {
          const searchDetails = new generated.PHRTrackerSearchDetails();
          const startDate = new Date();
          startDate.setFullYear(startDate.getFullYear() - 1);
          searchDetails.startDate = startDate;
          searchDetails.endDate = new Date();
          searchDetails.patientID = patientID;
          result = await httpRequest(() => client[getClientName(phrType, 'Search')](searchDetails));
        } else {
          result = await httpRequest(() => client.byPatientID(patientID));
        }
        dispatch(actions.success(result));
        resolve(result);
      } catch (err) {
        dispatch(actions.failure(err as any));
      }
    });
  };
};

export const getOneForPhrSection = <T>(
  phrType: ReduxPHRCategoryEnum,
  primaryID: string,
  patientID: string,
  isTracker: boolean,
): OHThunkResult<Promise<T>> => {
  return async (dispatch, getState: () => AppState) => {
    return new Promise<T>(async (resolve) => {
      const actions = phrActions[`getOne${phrType}`];

      dispatch(actions.request());
      let client;
      if (isTracker) {
        client = new generated.PHRTrackersClient(
          new generated.AuthClient(getState().phrPatientState.token),
        );
      } else {
        client = new generated[`${phrType}Client`](
          new generated.AuthClient(getState().phrPatientState.token),
        );
      }

      try {
        let result: T;
        if (isTracker) {
          result = await httpRequest(() =>
            client[getClientName(phrType, 'ByPHRTrackerDetailID')](patientID, primaryID),
          );
        } else {
          result = await httpRequest(
            () =>
              (result = client[`${phrType.charAt(0).toLowerCase()}${phrType.substring(1)}Get`](
                patientID,
                primaryID,
              )),
          );
        }
        dispatch(actions.success(result));
        resolve(result);
      } catch (err) {
        dispatch(actions.failure(err as any));
      }
    });
  };
};

export const createForPhrSection = <T>(
  phrType: ReduxPHRCategoryEnum,
  entity: T,
  patientID: string,
  isTracker: boolean,
): OHThunkResult<Promise<T>> => {
  return async (dispatch, getState: () => AppState) => {
    return new Promise<T>(async (resolve) => {
      const actions = phrActions[`create${phrType}`];
      dispatch(actions.request());
      let client;
      if (isTracker) {
        client = new generated.PHRTrackersClient(
          new generated.AuthClient(getState().phrPatientState.token),
        );
      } else {
        client = new generated[`${phrType}Client`](
          new generated.AuthClient(getState().phrPatientState.token),
        );
      }

      try {
        let primaryID: string;
        if (isTracker) {
          primaryID = await httpRequest(() =>
            client[getClientName(phrType, 'Post')](patientID, entity),
          );
        } else {
          const clientFunctionName = `${phrType.charAt(0).toLowerCase()}${phrType.substring(
            1,
          )}Post`;
          // For Allergy Details we will have to pass along the patient ID
          if (clientFunctionName === 'pHRAllergyDetailsPost') {
            primaryID = await httpRequest(() => client[clientFunctionName](patientID, entity));
          } else {
            primaryID = await httpRequest(() => client[clientFunctionName](entity));
          }
        }
        const result: T = await (dispatch(
          getOneForPhrSection(phrType, primaryID, patientID, isTracker),
        ) as unknown as T);
        dispatch(actions.success(result));
        dispatch(getPHRSummaryDTO(false));
        scrollToTop();
        resolve(result);
      } catch (err) {
        dispatch(actions.failure(err as any));
      }
    });
  };
};

export const deleteForPhrSection = (
  phrType: ReduxPHRCategoryEnum,
  primaryID: string,
  patientID: string,
  isTracker: boolean,
): OHThunkResult<Promise<void>> => {
  return async (dispatch, getState: () => AppState) => {
    return new Promise<void>(async (resolve) => {
      const actions = phrActions[`delete${phrType}`];
      dispatch(actions.request());
      let client;
      if (isTracker) {
        client = new generated.PHRTrackersClient(
          new generated.AuthClient(getState().phrPatientState.token),
        );
      } else {
        client = new generated[`${phrType}Client`](
          new generated.AuthClient(getState().phrPatientState.token),
        );
      }

      try {
        if (isTracker) {
          await httpRequest(() => client[getClientName(phrType, 'Delete')](patientID, primaryID));
        } else {
          await httpRequest(() =>
            client[`${phrType.charAt(0).toLowerCase()}${phrType.substring(1)}Delete`](
              patientID,
              primaryID,
            ),
          );
        }

        dispatch(actions.success(primaryID));
        dispatch(getPHRSummaryDTO(false));
        resolve();
      } catch (err) {
        dispatch(actions.failure(err as any));
      }
    });
  };
};

export const updateForPhrSection = <T>(
  phrType: ReduxPHRCategoryEnum,
  updatedEntity: T,
  patientID: string,
  isTracker: boolean,
): OHThunkResult<Promise<T>> => {
  return async (dispatch, getState: () => AppState) => {
    return new Promise<T>(async (resolve) => {
      const actions = phrActions[`update${phrType}`];
      dispatch(actions.request());
      let client;
      if (isTracker) {
        client = new generated.PHRTrackersClient(
          new generated.AuthClient(getState().phrPatientState.token),
        );
      } else {
        client = new generated[`${phrType}Client`](
          new generated.AuthClient(getState().phrPatientState.token),
        );
      }

      try {
        let primaryID: string;

        if (isTracker) {
          primaryID = await httpRequest(() =>
            client[getClientName(phrType, 'Put')](patientID, updatedEntity),
          );
        } else {
          const clientFunctionName = `${phrType.charAt(0).toLowerCase()}${phrType.substring(1)}Put`;
          primaryID = await httpRequest(() => client[clientFunctionName](updatedEntity));
        }

        const result: T = await (dispatch(
          getOneForPhrSection(phrType, primaryID, patientID, isTracker),
        ) as unknown as T);
        dispatch(actions.success(result));
        dispatch(getPHRSummaryDTO(false));
        resolve(result);
      } catch (err) {
        dispatch(actions.failure(err as any));
      }
    });
  };
};

export const verifyForPhrSection = <T>(
  phrType: ReduxPHRCategoryEnum,
  primaryID: string,
  patientID: string,
): OHThunkResult<Promise<T>> => {
  return async (dispatch, getState: () => AppState) => {
    return new Promise<T>(async (resolve) => {
      const actions = phrActions[`verify${phrType}`];
      dispatch(actions.request());
      const client = new generated[`${phrType}Client`](
        new generated.AuthClient(getState().phrPatientState.token),
      );
      try {
        await httpRequest(() => client.verify(patientID, primaryID));
        const result: T = await (dispatch(
          getOneForPhrSection(phrType, primaryID, patientID, false),
        ) as unknown as T);
        dispatch(actions.success(result));
        resolve(result);
      } catch (err) {
        dispatch(actions.failure(err as any));
      }
    });
  };
};

export function getToken(
  patientDependant: generated.UserDetailRelationship,
): OHThunkResult<Promise<string>> {
  return async (dispatch) => {
    return new Promise<string>(async (resolve) => {
      dispatch(getPHRPatientTokenActions.request());
      const securityClient = new generated.SecurityClient(new generated.AuthClient());
      try {
        const result = await httpRequest(() => securityClient.signInAsDependant(patientDependant));
        dispatch(getPHRPatientTokenActions.success(result.token!));
        resolve(result.token!);
      } catch (err) {
        dispatch(getPHRPatientTokenActions.failure(err as any));
      }
    });
  };
}

export function getPHRSummaryDTO(
  shouldUpdateLoading: boolean,
): OHThunkResult<Promise<generated.PHRSummaryDTO[]>> {
  return async (dispatch, getState: () => AppState) => {
    return new Promise<generated.PHRSummaryDTO[]>(async (resolve) => {
      if (shouldUpdateLoading) {
        dispatch(getPHRSummaryActions.request());
      }
      const phrPatientsClient = new generated.PHRPatientsClient(new generated.AuthClient());
      if (getState().phrPatientState.phrPatient?.patient) {
        const patientID = getState().phrPatientState.phrPatient!.patient!.patientID;
        try {
          const result = await httpRequest(() => phrPatientsClient.pHRSummaryDTO(patientID!));
          dispatch(getPHRSummaryActions.success(result));
          resolve(result);
        } catch (err) {
          dispatch(getPHRSummaryActions.failure(err as any));
        }
      }
    });
  };
}

function getClientName(phrType: string, tail: string) {
  if (phrType.endsWith('lises') || phrType.endsWith('sses')) {
    return `pHRTracker${phrType.substring(3, phrType.length - 2)}${tail}`;
  } else if (phrType.endsWith('ies')) {
    return `pHRTracker${phrType.substring(3, phrType.length - 3)}y${tail}`;
  } else {
    return `pHRTracker${phrType.substring(3, phrType.length - 1)}${tail}`;
  }
}
