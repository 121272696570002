import React from 'react';
import { Helmet } from 'react-helmet';

interface IProps {
  title: string;
}

const HelmetWrapper: React.FC<IProps> = ({ title }) => {
  return (
    <Helmet>
      <title>{`${title} - ${process.env.REACT_APP_COMPANY_NAME}`}</title>
    </Helmet>
  );
};

export default HelmetWrapper;
