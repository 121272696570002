import moment from 'moment';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { ConsultStatusEnum, IConsult } from '../../generated';
import { CurrentUserActionTypes, logoutActions } from '../currentUser/currentUserActions';
import {
  ActiveConsultActionTypes,
  boughtConsultAction,
  canceledConsultAction,
  clearActiveConsult,
  getActiveConsultActions,
  getActiveConsultByConsultIDActions,
  moveConsultToPhone,
  refreshActiveConsultByConsultIDActions,
  reInitializeActiveConsultByConsultIDActions,
  setActiveConsult,
  updateActiveConsultStatusAction,
  updatedConsultStoreItemAction,
} from './activeConsultActions';

const consult = createReducer<
  IActiveConsult | null,
  ActiveConsultActionTypes | CurrentUserActionTypes
>(null)
  .handleAction(
    [
      setActiveConsult,
      getActiveConsultByConsultIDActions.success,
      reInitializeActiveConsultByConsultIDActions.success,
      refreshActiveConsultByConsultIDActions.success,
    ],
    (state, action) => action.payload,
  )
  .handleAction([getActiveConsultActions.success], (state, action) => {
    // We are currently getting the active consult by ID, which superceeds this request
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    if (state?.updatedDate! >= action?.payload?.updatedDate!) {
      return state;
    } else {
      return action.payload;
    }
  })
  .handleAction([boughtConsultAction], (state, action) => {
    // check if scheduled and within 30 min
    if (action.payload.consultStatusEnum === ConsultStatusEnum.Scheduled) {
      if (moment(action.payload.startDate) <= moment().add('m', 30)) {
        return action.payload;
      } else {
        return state;
      }
    } else {
      return action.payload;
    }
  })
  .handleAction([canceledConsultAction], (state, action) => {
    if (state != null && state.consultID === action.payload.consultID) {
      return null;
    } else {
      return state;
    }
  })
  .handleAction([clearActiveConsult], () => null)
  .handleAction([logoutActions], () => null)
  .handleAction([updateActiveConsultStatusAction], (state, action) => {
    if (state && action.payload.consultID === state.consultID) {
      return Object.assign({}, state, { consultStatusEnum: action.payload.consultStatusEnum });
    } else {
      return state;
    }
  })
  .handleAction([updatedConsultStoreItemAction], (state, action) => {
    if (state && action.payload.consultID === state.consultID) {
      const updatedConsult = Object.assign({}, state);
      if (updatedConsult.consultStoreItems) {
        updatedConsult.consultStoreItems = updatedConsult.consultStoreItems.map((x) => {
          if (x.consultStoreItemID === action.payload.consultStoreItemID) {
            return action.payload;
          } else {
            return x;
          }
        });
      }
      return updatedConsult;
    } else {
      return state;
    }
  })
  .handleAction([moveConsultToPhone], (state, action) => {
    if (state?.consultID === action.payload) {
      const newState = { ...state };
      newState.isMovedToPhone = true;
      return newState;
    } else {
      return state;
    }
  });

const error = createReducer<string, ActiveConsultActionTypes>('').handleAction(
  [
    getActiveConsultActions.failure,
    getActiveConsultByConsultIDActions.failure,
    reInitializeActiveConsultByConsultIDActions.failure,
    refreshActiveConsultByConsultIDActions.failure,
  ],
  (_state, action) => action.payload,
);

const isLoading = createReducer<boolean, ActiveConsultActionTypes>(false)
  .handleAction([getActiveConsultByConsultIDActions.request], () => true)
  .handleAction(
    [getActiveConsultByConsultIDActions.failure, getActiveConsultByConsultIDActions.success],
    () => false,
  );

const isRefreshing = createReducer<boolean, ActiveConsultActionTypes>(false)
  .handleAction([refreshActiveConsultByConsultIDActions.request], () => true)
  .handleAction(
    [
      refreshActiveConsultByConsultIDActions.failure,
      refreshActiveConsultByConsultIDActions.success,
    ],
    () => false,
  );

const isReinitializing = createReducer<boolean, ActiveConsultActionTypes>(false)
  .handleAction([reInitializeActiveConsultByConsultIDActions.request], () => true)
  .handleAction(
    [
      reInitializeActiveConsultByConsultIDActions.failure,
      reInitializeActiveConsultByConsultIDActions.success,
    ],
    () => false,
  );

export interface IActiveConsult extends IConsult {
  isMovedToPhone?: boolean;
}

export interface IActiveConsultState {
  error: string;
  isLoading: boolean;
  isRefreshing: boolean;
  isReinitializing: boolean;
  consult: IActiveConsult | null;
}

export default combineReducers<IActiveConsultState>({
  consult,
  error,
  isLoading,
  isRefreshing,
  isReinitializing,
});
