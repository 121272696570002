import { Text } from '@chakra-ui/react';
import { Formik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { date, number, object } from 'yup';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';
import { EmojiPicker } from '../../../../../components/emojiPicker/EmojiPicker';
import useEmojiPicker from '../../../../../components/emojiPicker/useEmojiPicker';
import { Form, FormMobisPicker } from '../../../../../components/forms';
import { CenteredLoadingIndicator } from '../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../components/ServerValidationBox';
import { ButtonSizeEnum } from '../../../../../constants';
import { PHRTrackerSleepDTO, TagTypeEnum } from '../../../../../generated';
import { createForPhrSection, updateForPhrSection } from '../../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../../store/types';
import { ButtonRow } from '../../../components/StyledPhrItems';

const DEFAULT_LEVEL = 3;

interface IProps {
  selectedData: PHRTrackerSleepDTO | null;
  patientID: string;
  onClose: () => void;
}

interface IForm {
  trackerDate: Date;
  sleepValue?: number;
  tagIDs?: string[];
}

const SleepForm = ({ selectedData, patientID, onClose }: IProps) => {
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const { isLoading, emojis } = useEmojiPicker({
    tagTypeEnum: TagTypeEnum.SleepTracker,
  });

  const onSubmit = async (values: IForm) => {
    setSubmitting(true);
    if (selectedData) {
      selectedData.trackerDate = moment(values.trackerDate).toDate();
      selectedData.sleepValue = values.sleepValue;
      await dispatch(
        updateForPhrSection(ReduxPHRCategoryEnum.PHRSleeps, selectedData, patientID, true),
      );
    } else {
      const sleep = new PHRTrackerSleepDTO();
      sleep.trackerDate = values.trackerDate;
      sleep.sleepValue = values.sleepValue;
      await dispatch(createForPhrSection(ReduxPHRCategoryEnum.PHRSleeps, sleep, patientID, true));
    }
    setSubmitting(false);
    onClose();
  };

  const initialValues: IForm = {
    trackerDate: selectedData ? moment(selectedData.trackerDate!).toDate() : moment().toDate(),
    sleepValue: selectedData ? selectedData.sleepValue : DEFAULT_LEVEL,
    tagIDs: selectedData ? selectedData.tags?.map((x) => x.tagID) : [],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={object({
        trackerDate: date().required('Date is required').typeError('Invalid date'),
        sleepValue: number()
          .required('Sleep Value is required')
          .positive('Sleep Value must be a postive number')
          .typeError('Sleep Value must be a postive number')
          .moreThan(0, 'Value must be greater than 0')
          .lessThan(100, 'Value must be less than 100'),
      })}
      onSubmit={onSubmit}
    >
      {({ status, values, setFieldValue }) => (
        <Form error={status}>
          {isSubmitting ? (
            <CenteredLoadingIndicator />
          ) : (
            <>
              <ServerValidationBox message={status} />
              <Text
                textAlign="center"
                variant="heading"
                fontSize="20px"
                mb={10}
                mt={4}
                fontWeight="bold"
              >
                How would you rate your quality of sleep in the past seven days?
              </Text>
              <div className="row" style={{ display: 'none' }}>
                <div className="col-12 col-sm-6">
                  <FormMobisPicker
                    name="trackerDate"
                    label="Tracked at"
                    controls={['calendar', 'time']}
                    max={moment().toDate()}
                    min={moment().add(-150, 'year').toDate()}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12">
                  <EmojiPicker
                    isLoading={isLoading}
                    emojis={emojis}
                    value={values.sleepValue || DEFAULT_LEVEL}
                    onChange={(val) => setFieldValue('sleepValue', val)}
                  />
                </div>
              </div>
              <StyledButtonRow>
                <PrimaryButton size={ButtonSizeEnum.medium} type="submit">
                  I'm done
                </PrimaryButton>
              </StyledButtonRow>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SleepForm;

const StyledButtonRow = styled(ButtonRow)`
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 0;
`;
