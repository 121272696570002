import { ActionType, createAsyncAction } from 'typesafe-actions';
import { DiscussionItem } from '../../generated';
import { OHThunkResult } from '../root-reducers';

export const replyDiscussionItemsActions = createAsyncAction(
  ['REPLY_DISCUSSION_ITEMS_REQUEST', () => {}],
  ['REPLY_DISCUSSION_ITEMS_SUCCESS', (res: DiscussionItem & { isProvider?: boolean }) => res],
  ['REPLY_DISCUSSION_ITEMS_ERROR', (err: string) => err],
)();

export function replyDiscussionItems(
  newDiscussionItem: DiscussionItem & { isProvider?: boolean },
): OHThunkResult<Promise<DiscussionItem>> {
  return async (dispatch) => {
    return new Promise<DiscussionItem>(async (resolve) => {
      dispatch(replyDiscussionItemsActions.request());
      try {
        dispatch(replyDiscussionItemsActions.success(newDiscussionItem));
        resolve(newDiscussionItem);
      } catch (err) {
        dispatch(replyDiscussionItemsActions.failure(err as any));
      }
    });
  };
}

export type ReplyDiscussionItemsActionTypes = ActionType<typeof replyDiscussionItemsActions>;
