import Modal from '../../../../components/Modals/Modal';
import { IPHRConsult } from '../../../../generated';
import { SendReferral } from './SendReferral/SendReferral';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  consult?: IPHRConsult;
  onReferralSent: () => void;
}

export const ReferralModal = ({ isOpen, onClose, consult, onReferralSent }: IProps) => {
  return (
    <Modal title="Send Referral" isOpen={isOpen} onDismiss={onClose} size="md">
      <SendReferral consult={consult} onSentReferral={onReferralSent} />
    </Modal>
  );
};
