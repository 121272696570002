import { OrderWithQuestionairEnum } from '../../contexts/CreateOrderContext';
import { useQuestionair } from '../../contexts/CreateOrderContext/useQuestionair/useQuestionair';
import { CTScanQuestionair } from './forms/CTScanQuestionair';
import { LabTestsQuestionair } from './forms/LabTestsQuestionair';
import { MammogramQuestionair } from './forms/MammogramQuestionair';
import { MriQuestionair } from './forms/MriQuestionair';
import { NuclearMedicineQuestionair } from './forms/NuclearMedicineQuestionair';
import { OtherQuestionair } from './forms/OtherQuestionair';
import { UltrasoundQuestionair } from './forms/UltrasoundQuestionair';
import { XrayQuestionair } from './forms/XrayQuestionair';

export const Questionair = () => {
  const { selectedOrderType } = useQuestionair();

  switch (selectedOrderType?.name) {
    case OrderWithQuestionairEnum.MRI:
      return <MriQuestionair />;
    case OrderWithQuestionairEnum.Mammogram:
      return <MammogramQuestionair />;
    case OrderWithQuestionairEnum.CTScan:
      return <CTScanQuestionair />;
    case OrderWithQuestionairEnum.LabTests:
      return <LabTestsQuestionair />;
    case OrderWithQuestionairEnum.NuclearMedicine:
      return <NuclearMedicineQuestionair />;
    case OrderWithQuestionairEnum.Other:
      return <OtherQuestionair />;
    case OrderWithQuestionairEnum.Ultrasound:
      return <UltrasoundQuestionair />;
    case OrderWithQuestionairEnum.Xray:
      return <XrayQuestionair />;
    default:
      return null;
  }
};
