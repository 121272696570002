import { useCallback } from 'react';

import { ConsultProviderSearchDetails, Tag } from '../../../../../../../../../generated';

import useBookConsultContext from '../../../../../hooks/useBookConsultContext/useBookConsultContext';

import type { InitialSearchOptionsType } from '../useProviderSearch';

import { InitialCategoryDataType } from './useConsultSearchInfinity';

interface IProps {
  searchOptions: InitialSearchOptionsType;
  categoryData: InitialCategoryDataType;
}

export const getTagsIDs = (tags?: Tag[]) => {
  return tags?.map((tag) => tag.tagID);
};

const useGetSearchDetails = ({ searchOptions, categoryData }: IProps) => {
  const { state } = useBookConsultContext();

  const searchCountryId =
    searchOptions.country?.countryID || state.country?.countryID || state.emergencyConsultCountryID;

  const getSearchDetails = useCallback(() => {
    const searchDetails = new ConsultProviderSearchDetails();
    searchDetails.countryID = searchCountryId;
    searchDetails.storeItemTypeEnum = state.storeItemTypeEnum;
    searchDetails.prescriptionRefill = !!state.prescriptionTag;
    searchDetails.spokenLanguageTagID = searchOptions.language?.languageID;
    searchDetails.genderEnum = searchOptions.gender?.id;
    searchDetails.provinceStateID = searchOptions.provinceState?.provinceStateID;
    searchDetails.cityTownID = searchOptions.cityTown?.cityTownID;
    searchDetails.consultProviderSearchBrowseEnum = categoryData.categoryToBrowse;
    searchDetails.searchText = categoryData.searchText;
    searchDetails.browseStoreID = categoryData.browseStoreID;
    searchDetails.browseOrganizationID = categoryData.browseOrganizationID;
    searchDetails.homeCareServiceTagIDs = getTagsIDs(searchOptions.homeCareServiceTags);
    searchDetails.currentLongitude = searchOptions.geolocation?.coords.longitude;
    searchDetails.currentLatitude = searchOptions.geolocation?.coords.latitude;
    searchDetails.symptomTagIDs = getTagsIDs(searchOptions.consultSymptoms);

    searchDetails.specialityTagIDs = searchOptions.specialtyTag
      ? [searchOptions.specialtyTag.tagID]
      : categoryData.specialityTagIDs;

    searchDetails.careTypeTagsIDs = state.careTypeTags
      ? state.careTypeTags.map((careType) => careType.tagID)
      : undefined;

    return searchDetails;
  }, [
    state.storeItemTypeEnum,
    state.careTypeTags,
    state.prescriptionTag,
    searchCountryId,
    searchOptions,
    categoryData,
  ]);

  return {
    getSearchDetails,
  };
};

export { useGetSearchDetails };
