import moment from 'moment';
import { TFunction } from 'i18next';

const getAgeInMonths = (birthDate: string | Date) =>
  moment().diff(new Date(birthDate), 'months', false);

const getAgeInYears = (birthDate: string | Date) => {
  const ageInMonths = getAgeInMonths(birthDate);

  if (ageInMonths < 0) return 0;

  return Math.floor(ageInMonths / 12);
};

const getAge = (birthDate: string | Date, t: TFunction) => {
  const ageInYears = getAgeInYears(birthDate);

  if (ageInYears === 0) {
    return getAgeInMonths(birthDate) + ' ' + t('Months');
  }

  return ageInYears + ' ' + t('Years');
};

export { getAge, getAgeInMonths, getAgeInYears };
