import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ISystemSettingValue } from './systemSettingsReducers';

export const getSystemSettingActions = createAsyncAction(
  ['GET_SYSTEM_SETTING_REQUEST', () => {}],
  ['GET_SYSTEM_SETTING_SUCCESS', (res: ISystemSettingValue[]) => res],
  ['GET_SYSTEM_SETTING_ERROR', (err: string) => err],
)();

export type SystemSettingsActionTypes = ActionType<typeof getSystemSettingActions>;
