import { Box, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import { EyeIcon } from '../../../../../assets/icons';
import { TimeFormatEnum } from '../../../../../constants';
import { IPatientOrder, OrderStatusEnum, PHRDocument } from '../../../../../generated';
import { OutpostTheme } from '../../../../../themes/outpost';
import { ViewPhrDocument } from '../../../components/ViewPhrDocument';

interface IProps {
  patientOrders: IPatientOrder[];
}

export const OrderList = ({ patientOrders }: IProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isImageOpen, setIsImageOpen] = useState<boolean>(false);
  const [downloadDoc, setDownloadDoc] = useState<PHRDocument>();

  const handleView = (document: PHRDocument) => {
    if (document.fileDetail?.contentType === 'application/pdf') {
      setDownloadDoc(document);
      setIsOpen(!isOpen);
    } else {
      setDownloadDoc(document);
      setIsImageOpen(!isImageOpen);
    }
  };

  return (
    <>
      <HStack spacing={2} wrap="nowrap" overflowX="auto">
        {patientOrders.map((x) => (
          <Box
            key={x.patientOrderID}
            w="275px"
            maxW="275px"
            minW="275px"
            borderColor="#E4E9F2"
            borderWidth="1px"
            borderRadius="0.25rem"
            boxShadow="lg"
            px={6}
            py={5}
          >
            <Flex mb={3} justifyContent="space-between" alignItems="center">
              <Text fontWeight="500">{x.order?.name}</Text>
              {x.orderStatusEnum === OrderStatusEnum.Completed && (
                <IconButton
                  variant="unstyled"
                  ml={2}
                  aria-label="view order"
                  icon={<EyeIcon fontSize="20px" />}
                  color={'#3E1191'}
                  onClick={() => {
                    handleView(x.phrDocument!);
                  }}
                />
              )}
            </Flex>

            <Text fontSize={OutpostTheme.FontSizes.body}>
              {moment(x.createdDate).format(TimeFormatEnum.SHORT_DATE_TIME)}
            </Text>
            <Text fontSize={OutpostTheme.FontSizes.body}>{x.provider?.fullName}</Text>
          </Box>
        ))}
      </HStack>

      {isOpen && downloadDoc && downloadDoc.fileDetail?.contentType === 'application/pdf' && (
        <ViewPhrDocument
          patientID={downloadDoc.patientID ?? ''}
          documentID={downloadDoc.phrDocumentID ?? ''}
          onClose={() => setIsOpen(!isOpen)}
          fileName={downloadDoc.fileDetail?.name ?? ''}
        />
      )}

      {isImageOpen && (
        <ImageViewer
          src={[
            `/api/v1/PHRs/PHRDocuments/${downloadDoc?.patientID}/DocumentAsSrc/${downloadDoc?.phrDocumentID}`,
          ]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          onClose={() => {
            setIsImageOpen(false);
            setDownloadDoc(undefined);
          }}
        />
      )}
    </>
  );
};
