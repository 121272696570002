import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import { FastField } from 'formik';
import React, { ReactNode } from 'react';
import { BaseProps } from '../baseProps';
import { FormControl } from '../FormControl';

export type SingleCheckboxProps = BaseProps &
  CheckboxProps & {
    children: ReactNode;
  };

export const SingleCheckbox = (props: SingleCheckboxProps) => {
  const { label, children, formControlProps, ...rest } = props;
  return (
    <FormControl label={label} {...formControlProps}>
      <Checkbox {...rest}>{children}</Checkbox>
    </FormControl>
  );
};

export type FormSingleCheckboxProps = SingleCheckboxProps;

export const FormSingleCheckbox = (props: FormSingleCheckboxProps) => {
  const { name, children, formControlProps, ...rest } = props;

  return (
    <FastField name={name}>
      {({ field, meta }) => (
        <SingleCheckbox
          {...rest}
          formControlProps={{
            isInvalid: meta.error && meta.touched,
            ...meta,
            ...formControlProps,
          }}
          // isInvalid={meta.error && meta.touched}
          isChecked={field.value}
          {...meta}
          {...field}
        >
          {children}
        </SingleCheckbox>
      )}
    </FastField>
  );
};
