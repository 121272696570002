import { ActionType, createAction } from 'typesafe-actions';
import { AuthClient, PatientsClient, ProvidersClient, RolesEnum } from '../../generated';
import { httpRequest } from '../../utils';
import { isInRole } from '../../utils/isInRole';
import { refreshToken } from '../currentUser/currentUserCreators';
import { ICurrentUser } from '../currentUser/currentUserReducers';
import { OHThunkResult } from '../root-reducers';

export const updateProfilePictureRequest = createAction(
  'UPDATE_PROFILE_PICTURE_REQUEST',
  () => {},
)();
export const updateProfilePictureProviderSuccess = createAction(
  'UPDATE_PROFILE_PICTURE_PROVIDER_SUCCESS',
  (res: string) => res,
)();
export const updateProfilePicturePatientSuccess = createAction(
  'UPDATE_PROFILE_PICTURE_PATIENT_SUCCESS',
  (res: string) => res,
)();
export const updateProfilePictureError = createAction(
  'UPDATE_PROFILE_PICTURE_ERROR',
  (err: string) => err,
)();

export function updateProfilePicture(
  imageDetailID: string,
  currentUser: ICurrentUser,
): OHThunkResult<Promise<void>> {
  return async (dispatch) => {
    return new Promise<void>(async (resolve) => {
      dispatch(updateProfilePictureRequest);
      const authClient = new AuthClient();
      try {
        if (isInRole(currentUser, RolesEnum.Patient)) {
          const patientsClient = new PatientsClient(authClient);
          const patient = await httpRequest(() =>
            patientsClient.byUserDetailID(currentUser.userDetailID),
          );
          patient.imageDetailID = imageDetailID;
          await httpRequest(() => patientsClient.patientsPut(patient));
          dispatch(updateProfilePicturePatientSuccess(imageDetailID));
        } else if (isInRole(currentUser, RolesEnum.Provider)) {
          const providersClient = new ProvidersClient(authClient);
          const provider = await httpRequest(() =>
            providersClient.byUserDetailID(currentUser.userDetailID),
          );
          provider.imageDetailID = imageDetailID;
          provider.discussionsImageDetailID = imageDetailID;
          await httpRequest(() => providersClient.providersPut(provider));
          dispatch(updateProfilePictureProviderSuccess(imageDetailID));
        }
        dispatch(refreshToken());
        resolve();
      } catch (err) {
        dispatch(updateProfilePictureError(err as any));
      }
    });
  };
}

export type UpdateProfilePictureActionTypes = ActionType<
  | typeof updateProfilePictureRequest
  | typeof updateProfilePictureProviderSuccess
  | typeof updateProfilePicturePatientSuccess
  | typeof updateProfilePictureError
>;

// export const getPhrDocumentsActions = createAsyncAction(
//   ['GET_PHR_DOCUMENTS_REQUEST', () => {}],
//   ['GET_PHR_DOCUMENTS_SUCCESS', (res: PHRDocument[]) => res],
//   ['GET_PHR_DOCUMENTS_ERROR', (err: string) => err],
// )();

// export function getPhrDocuments(): OHThunkResult<Promise<PHRDocument[]>> {
//   return async (dispatch) => {
//     return new Promise<PHRDocument[]>(async (resolve) => {
//       dispatch(getPhrDocumentsActions.request());

//       try {
//         let result = await httpRequest(() => );
//         dispatch(getPhrDocumentsActions.success(result));
//         resolve(result);
//       } catch (err) {
//         dispatch(getPhrDocumentsActions.failure(err as any));
//       }
//     })
//   }
// }

// export type GetPhrDocumentsActionTypes = ActionType<typeof getPhrDocumentsActions>

// const phrDocuments = createReducer<PHRDocument[], GetPhrDocumentsActionTypes>([])
//   .handleAction(
//     [getPhrDocumentsActions.success],
//     (state, action) => action.payload,
//   )

// const error = createReducer<string, GetPhrDocumentsActionTypes>('').handleAction(
//   [getPhrDocumentsActions.failure],
//   (state, action) => action.payload,
// ).handleAction(
//   [getPhrDocumentsActions.success],
//   (state, action) => ''
// );

// const isPhrLoading = createReducer<boolean, GetPhrDocumentsActionTypes>(false)
//   .handleAction([getPhrDocumentsActions.request], (state, action) => true)
//   .handleAction(
//     [
//       getPhrDocumentsActions.failure,
//       getPhrDocumentsActions.success,
//     ],
//     (state, action) => false,
//   );

//   const isLoading = createReducer<boolean, AnyAction>(false);

//   export type PhrDocumentsState = {
//     PhrDocuments: PHRDocument[];
//     error: string;
//     isLoading: boolean
//   }

//   export default combineReducers({
//     phrDocuments,
//     error,
//     isLoading,
//   }
