import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { VerifiedShieldIcon } from '../../../assets/icons';

import { TimeFormatEnum } from '../../../constants';
import { IPHRConsult } from '../../../generated';
import { OutpostTheme } from '../../../themes/outpost';

interface ConsultDetailsProps {
  phrItem: IPHRConsult;
  children: React.ReactNode;
  name?: string;
}

const ConsultDetails = ({ phrItem, children, name }: ConsultDetailsProps) => {
  return (
    <>
      <Box
        bgColor="#ffffff"
        borderRadius="lg"
        position="relative"
        overflowY="auto"
        marginX={{ base: '0.5rem', md: '1.5rem' }}
        paddingX={{ base: '0.5rem', md: '1.5rem' }}
        paddingBottom="1.5rem"
        border={{ base: 'none', md: '1px solid #0C346891' }}
        height={{ base: 'calc(100vh - 8rem)', md: '550px' }}
      >
        <Flex
          justify="space-between"
          align="center"
          position="sticky"
          top="-1px"
          bgColor="#ffffff"
          padding={{ base: '1rem', md: '1.5rem' }}
          marginX={{ base: '-0.5rem', md: '-1.5rem' }}
          backgroundRepeat="no-repeat"
          backgroundPosition={{ base: 'calc(100% + 90px) -135px', md: 'calc(100% + 90px) -120px' }}
        >
          <Text fontSize="1rem" color="#3E1191" fontWeight="semibold">
            {name}
          </Text>{' '}
          <VerifiedShieldIcon height="22px" color="#418A94" width="22px" />
        </Flex>

        {children}

        <Box marginTop="1.5rem">
          <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="light">
            Details
          </Text>
          <VStack
            marginTop="0.5rem"
            spacing={4}
            align="flex-start"
            fontSize={OutpostTheme.FontSizes.body}
          >
            {phrItem.createdDate && (
              <HStack>
                <FontAwesomeIcon size="xs" color="#F2A1AE" icon={['far', 'circle']} />
                <Text>
                  Created by {phrItem.createdByName} on{' '}
                  {moment(phrItem.createdDate).format(TimeFormatEnum.LONG_DATE)}
                </Text>
              </HStack>
            )}

            {phrItem.updatedDate && (
              <HStack>
                <FontAwesomeIcon size="xs" color="#2E8AE7" icon={['far', 'circle']} />
                <Text>
                  Updated by {phrItem.updatedByName} on{' '}
                  {moment(phrItem.updatedDate).format(TimeFormatEnum.LONG_DATE)}
                </Text>
              </HStack>
            )}

            {phrItem.verified && (
              <HStack>
                <FontAwesomeIcon size="xs" color="#2B7D88" icon={['far', 'circle']} />
                <Text>
                  Verified by {phrItem.verifiedByProviderName} on{' '}
                  {moment(phrItem.verifiedDate).format(TimeFormatEnum.LONG_DATE)}
                </Text>
              </HStack>
            )}
          </VStack>
        </Box>
      </Box>
    </>
  );
};

export default ConsultDetails;
