import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TimeFormatEnum } from '../../../../constants';
import {
  HeightEnum,
  IUserDetailSetting,
  PHRTrackerLengthDTO,
  UserDetailSettingEnum,
} from '../../../../generated';
import { IPHRPatientEnhanced } from '../../../../store/patient/phr/phrReducers';
import { phrLengthsSelector } from '../../../../store/patient/phr/phrSelectors';
import { getAllForPhrSection } from '../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import { enumToOptions } from '../../../../utils';
import { convertHeight } from '../../../../utils/conversionHelpers';
import { Details, ItemName, Value } from '../../components/StyledPhrItems';
import TrackerView from '../TrackerView';
import LengthChart from './LengthChart';
import LengthForm from './LengthForm';

interface IProps {
  patient: IPHRPatientEnhanced;
  userDetailSettings: IUserDetailSetting[];
  renderHeader: (addButton?: any) => any;
}

const LengthComponent = ({ patient, userDetailSettings, renderHeader }: IProps) => {
  const dispatch = useDispatch();
  const unitValue = userDetailSettings.filter(
    (x) => x.userDetailSettingEnum === UserDetailSettingEnum.Height,
  )[0].value;
  const [selectedUnitValue, setSelectedUnitValue] = useState<HeightEnum>(HeightEnum[unitValue]);
  const phrLengths = phrLengthsSelector(patient.phrLengths);
  const [convertedLength, setConvertedLength] = useState<PHRTrackerLengthDTO[]>([]);

  useEffect(() => {
    if (patient) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRLengths, patient.patientID));
    }
  }, [dispatch, patient]);

  useEffect(() => {
    const converted = JSON.parse(JSON.stringify(phrLengths));
    setConvertedLength(
      converted.map((x) => {
        x.length = convertHeight(true, x.length, selectedUnitValue);
        return x;
      }),
    );
  }, [selectedUnitValue, phrLengths]);

  const thinView = (length: PHRTrackerLengthDTO) => (
    <>
      <ItemName>{length.length}</ItemName>
      <Details>
        <Value> {moment(length.trackerDate).format(TimeFormatEnum.LONG_DATE_TIME)}</Value>
      </Details>
    </>
  );

  return (
    <TrackerView
      renderHeader={renderHeader}
      phrCategoryEnum={ReduxPHRCategoryEnum.PHRLengths}
      patient={patient}
      trackerData={convertedLength}
      thinView={thinView}
      Chart={LengthChart}
      Form={LengthForm}
      createText="Length"
      unitTypeOptions={enumToOptions(HeightEnum)}
      unitValue={selectedUnitValue}
      setSelectedUnitValue={setSelectedUnitValue}
    />
  );
};

export default LengthComponent;
