import {
  Box,
  HStack,
  IconButton,
  Text,
  useBreakpointValue,
  useDisclosure,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { FC } from 'react';
import { EllipseIcon } from '../../../../assets/icons';

import { Avatar } from '../../../../components/common/Avatar';
import { NotificationModal } from '../../../../components/Modals/NotificationModal';
import { Tag } from '../../../../components/TagComponent';
import useTranslationComponent from '../../../../hooks/useTranslationComponent';
import { OutpostTheme } from '../../../../themes/outpost';
import { getUserThumbnail } from '../../../../utils/getUserThumbnail';

import { RatingCard } from '../../../heal/patient/BookAppointment/components/common/Provider';

type ProviderBasicInfoProps = {
  providerName?: string;
  providerImageDetailId?: string;
  providerRating?: string | number;
  providerSpecialtyTag?: string;
  providerType?: string;
  fullProviderDetails?: boolean;
  otherTagsNames?: string[];
};

const maximumVisibleTags = 2;

const ProviderBasicInfo: FC<ProviderBasicInfoProps> = (props) => {
  const { t } = useTranslationComponent(['network']);
  const {
    isOpen: isOtherTagsOpen,
    onClose: onCloseOtherTags,
    onOpen: onOpenOtherTags,
  } = useDisclosure();

  const hasManyOtherTags = props.otherTagsNames && props.otherTagsNames.length > maximumVisibleTags;

  const isMobile = useBreakpointValue({ base: true, md: false });

  const getVisibleOtherTags = () => {
    if (hasManyOtherTags) {
      return props.otherTagsNames?.slice(0, maximumVisibleTags);
    }
    return props.otherTagsNames;
  };

  return (
    <HStack spacing={{ base: '3', md: 9 }} alignItems="center" py={{ base: 4, md: 6 }} width="100%">
      <Box position="relative">
        <Avatar
          size={isMobile ? OutpostTheme.Avatar.Size.Medium : OutpostTheme.Avatar.Size.Extra}
          src={getUserThumbnail(props.providerImageDetailId)}
        />
        {!!props.providerRating && <RatingCard rating={props.providerRating} />}
      </Box>
      <Box flex={1}>
        {!props.fullProviderDetails && (
          <Text fontSize="12px" fontWeight="medium" color="rgb(65, 79, 101)" my="1">
            {t('PROVIDER')}
          </Text>
        )}
        <Text fontSize={OutpostTheme.FontSizes.body} fontWeight="600" color="rgb(0, 3, 16)">
          {props.providerName}
        </Text>
        {props.fullProviderDetails && (
          <Box>
            <Text fontSize={OutpostTheme.FontSizes.body} color="purple.600" mb="1">
              {t(props.providerType)}
              {props.providerSpecialtyTag ? `, ${props.providerSpecialtyTag}` : ''}
            </Text>
            <Wrap spacing={1}>
              {getVisibleOtherTags()?.map((tag) => (
                <WrapItem key={tag}>
                  <Tag
                    text={tag}
                    textProps={{ fontSize: '12px' }}
                    containerProps={{ py: '0.5', px: '1', borderRadius: 'md' }}
                  />
                </WrapItem>
              ))}
              {hasManyOtherTags && (
                <WrapItem>
                  <IconButton
                    aria-label="see more"
                    bgColor="transparent"
                    border="1px solid rgb(211, 211, 211)"
                    _hover={{ bgColor: 'rgb(219, 217, 217)' }}
                    p="0"
                    minW="40px"
                    minH="23px"
                    height="auto"
                    borderRadius="8"
                    onClick={onOpenOtherTags}
                  >
                    <EllipseIcon transform="rotate(90deg)" />
                  </IconButton>
                </WrapItem>
              )}
            </Wrap>
          </Box>
        )}
      </Box>
      {hasManyOtherTags && (
        <NotificationModal
          isModalOpen={isOtherTagsOpen}
          onDismissModal={onCloseOtherTags}
          onClose={onCloseOtherTags}
          modalTitle={t('All Services Offered')}
        >
          <Wrap px="2">
            {props.otherTagsNames?.map((tag) => (
              <WrapItem key={tag}>
                <Tag
                  text={tag}
                  textProps={{
                    fontSize: '14px',
                  }}
                />
              </WrapItem>
            ))}
          </Wrap>
        </NotificationModal>
      )}
    </HStack>
  );
};

export { ProviderBasicInfo };
