import React from 'react';
import { Text, Box } from '@chakra-ui/react';

import { PHRFamilyHistory, RelationshipTypeEnum } from '../../../generated';

import PhrItem, { PHRItemList, EmptyPHRItemListCard } from '../components/PhrItem';
import { getTime } from '../../../utils';
import { OutpostTheme } from '../../../themes/outpost';

interface IProps {
  phrFamilyHistories: PHRFamilyHistory[];
  onView: (phrFamilyHistory: PHRFamilyHistory) => void;
}

const FamilyHistoriesList = ({ phrFamilyHistories, onView }: IProps) => {
  return (
    <>
      {phrFamilyHistories.length === 0 ? (
        <EmptyPHRItemListCard>No Family Histories added</EmptyPHRItemListCard>
      ) : (
        <PHRItemList>
          {phrFamilyHistories
            .sort((a, b) => getTime(b.upsertDate) - getTime(a.upsertDate))
            .map((item, index) => (
              <PhrItem
                key={index}
                name={item.condition}
                isVerified={item.verified}
                onView={() => onView(item)}
                SecondaryInfo={<SecondaryInfoComponent item={item} />}
              />
            ))}
        </PHRItemList>
      )}
    </>
  );
};

export default FamilyHistoriesList;

interface ISecondaryInfoComponentProps {
  item: PHRFamilyHistory;
}

const SecondaryInfoComponent = ({ item }: ISecondaryInfoComponentProps) => {
  return (
    <Box marginTop="1rem">
      <Text textTransform="uppercase" color="#020202" fontSize="12px" fontWeight="normal">
        Found In
      </Text>
      <Text fontSize={OutpostTheme.FontSizes.body}>
        {item.relationshipTypeEnum === RelationshipTypeEnum.Unknown
          ? 'Unknown'
          : item.relationshipTypeEnum}
      </Text>
    </Box>
  );
};
