import { Center } from '@chakra-ui/react';
import { FC } from 'react';

import { SearchIcon } from '../../../../../../../assets/icons';
import { SelectComponent } from '../../../../../../../components/forms/Selects';
import { Tag } from '../../../../../../../generated';
import useTranslationComponent from '../../../../../../../hooks/useTranslationComponent';
import { usePartnerSettings } from '../../../../../../../services/partners/usePartnerSettings';
import { getSelectGroup } from '../../../../../../../utils/getSelectInputGroups';

type SymptomsSearchProps = {
  selectedTags: Tag[];
  symptomTags?: Tag[];
  onSelectTags: (tags: Tag[]) => void;
  isLoadingCareTypeTags: boolean;
  isDisabled?: boolean;
};

export const SearchIndicator = () => (
  <Center px="3">
    <SearchIcon fontSize="18px" />
  </Center>
);

const SymptomsSearch: FC<SymptomsSearchProps> = (props) => {
  const { t } = useTranslationComponent(['clinic']);
  // let filterIndex = 0;

  const { partnerSymptomsText } = usePartnerSettings();

  return (
    <SelectComponent
      isDisabled={props.isDisabled}
      options={getSelectGroup({
        tags: props.symptomTags || [],
        allLabel: t(`All ${partnerSymptomsText ?? 'Symptoms'}`),
        commonLabel: t(`Common ${partnerSymptomsText ?? 'Symptoms'}`),
      })}
      name="tags"
      valueKey="tagID"
      labelKey="description"
      placeholder={t(
        props.isLoadingCareTypeTags
          ? 'Please wait...'
          : `Search for ${partnerSymptomsText ?? 'symptoms'}`,
      )}
      value={props.selectedTags || []}
      onChange={(value: Tag[]) => {
        if (value) props.onSelectTags(value);
      }}
      menuPosition="fixed"
      isClearable={false}
      components={{ DropdownIndicator: SearchIndicator }}
      noOptionsMessage={() => t('Start typing...')}
      // filterOption={({ label }, query) =>
      //   (label || '').toLowerCase().indexOf(query?.toLowerCase() || 'a') >= 0 && filterIndex++ < 10
      // }
      // onInputChange={() => {
      //   filterIndex = 0;
      // }}
      isMulti
      controlShouldRenderValue={false}
      isSearchable
      isLoading={props.isLoadingCareTypeTags}
    />
  );
};

export { SymptomsSearch };
