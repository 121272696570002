import { keyframes, css } from 'styled-components';

export const Pulse = keyframes`
    from {
        background-position: 0% 0%;
    }

    to {
        background-position: -135% 0%;
    }
`;

export const SkeletonPulse = css`
  display: inline-block;
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: ${Pulse} 1.2s ease-in-out infinite;
`;

export const SkeletonPulseInverse = css`
  display: inline-block;
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #c1c1c1 0%, #d6d6d6c9 50%, #c1c1c1 100%);
  background-size: 400% 400%;
  animation: ${Pulse} 1.2s ease-in-out infinite;
`;
