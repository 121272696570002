import { ConsultProviderSearchProviderResult, Session, Tag } from '../../../generated';
import { uuid } from '../../../utils';
import { sessionPost } from '../../../utils/sessionPost';

import { BookingDataType } from '../hooks/usePublicBookingFlow';

const handleSaveBookingSession = async (
  bookingData: BookingDataType,
  additionalData?: Partial<BookingDataType>,
) => {
  // strip care type tag
  const strippedCareTypeTag = new Tag();

  strippedCareTypeTag.init(bookingData.selectedCareType);

  strippedCareTypeTag.tagXrefs = undefined;

  // strip provider
  const strippedProvider = new ConsultProviderSearchProviderResult();

  strippedProvider.init(bookingData.provider || additionalData?.provider);

  strippedProvider.consultProviderSearchProviderAppointmentsResults = undefined;

  // strip symptom tags
  const strippedSymptomsTags: Tag[] = [];

  (bookingData.selectedSymptoms || additionalData?.selectedSymptoms)?.forEach((tag) => {
    const strippedTag = new Tag();

    strippedTag.init(tag);

    strippedTag.tagXrefs = undefined;
    strippedSymptomsTags.push(strippedTag);
  });

  try {
    const sessionData: BookingDataType = {
      ...bookingData,
      ...(additionalData ? additionalData : {}),
      selectedCareType: strippedCareTypeTag,
      provider: strippedProvider,
      selectedSymptoms: strippedSymptomsTags,
      previousData: undefined,
    };

    const sessionItem = new Session();

    sessionItem.sessionID = uuid();
    sessionItem.data = JSON.stringify(sessionData);

    await sessionPost(sessionItem);

    return sessionItem.sessionID;
  } catch (error) {
    throw error;
  }
};

export { handleSaveBookingSession };
