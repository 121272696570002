import { Accordion, ExpandedIndex, Stack, Tag } from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ChatIcon,
  CrossIcon,
  DashboardIcon,
  MessageIcon,
  MyNetworkIcon,
  StethoscopeIcon,
  UserIcon,
} from '../../../assets/icons';
import {
  DashboardRoutes,
  HealRoutes,
  LearnRoutes,
  LocumsRoutes,
  MessageCenterRoutes,
  MonitorRoutes,
  MyNetworkRoutes,
  SubMenuEnum,
} from '../../../constants';
import useNotification from '../../../hooks/useNotification';
import useTranslationComponent from '../../../hooks/useTranslationComponent';
import { AppState } from '../../../store/root-reducers';
import { MenuItem } from './components/MenuItem';
import { SideMenu } from './components/SideMenu';

export const ProviderSideMenu = () => {
  const { t } = useTranslationComponent();
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const usersCount = useSelector(
    (state: AppState) => state.currentChatUserState.unreadUsersMessageCount,
  );
  const groupCount = useSelector(
    (state: AppState) => state.currentChatUserState.unreadGroupMessageCount,
  );
  const totalCount = usersCount + groupCount;
  const [openIndex, setOpenIndex] = useState<ExpandedIndex | undefined>(0);
  const { mail } = useNotification({ currentUser });

  return (
    <SideMenu>
      <MenuItem routes={[DashboardRoutes.Dashboard]} Icon={DashboardIcon}>
        {t('Dashboard')}
      </MenuItem>
      <MenuItem
        Icon={MessageIcon}
        routes={[
          MessageCenterRoutes.InboxMail,
          MessageCenterRoutes.Mail,
          MessageCenterRoutes.SentMail,
          MessageCenterRoutes.ComposeMail,
          MessageCenterRoutes.DeletedMail,
          MessageCenterRoutes.AddressBook,
        ]}
      >
        {t('Messages')}
        {!!mail.count && (
          <Tag ml={4} size="sm" colorScheme="blue">
            {mail.count}
          </Tag>
        )}
      </MenuItem>
      <MenuItem
        Icon={CrossIcon}
        routes={[
          HealRoutes.Clinic,
          HealRoutes.Incomplete,
          HealRoutes.WaitingRoom,
          HealRoutes.ProviderTests,
          HealRoutes.ProviderConsultSessions,
          HealRoutes.ProviderConsultAttestations,
          HealRoutes.ProviderMyDay,
        ]}
      >
        {t('My Clinic')}
      </MenuItem>
      <Accordion index={openIndex} onChange={setOpenIndex} allowToggle>
        <Stack spacing={4}>
          <MenuItem
            routes={[
              `${DashboardRoutes.Submenu}?id=${SubMenuEnum.Admin}`,
              MonitorRoutes.ClinicalTrials,
              MonitorRoutes.ClinicalTrial,
              MonitorRoutes.ParticipateClinicalTrial,
              HealRoutes.Fax,
              HealRoutes.FaxIncoming,
              HealRoutes.FaxForwarded,
              HealRoutes.FaxDeleted,
              MonitorRoutes.Statements,
            ]}
            Icon={UserIcon}
          >
            {t('Admin')}
          </MenuItem>

          <MenuItem
            routes={[
              `${DashboardRoutes.Submenu}?id=${SubMenuEnum.Learning}`,
              LearnRoutes.Community,
              LearnRoutes.CommunitySearchResults,
              LearnRoutes.CommunityDiscussion,
              LearnRoutes.CommunityDiscussionCreate,
              LearnRoutes.CommunityDiscussionItem,
              LearnRoutes.CareSpaces,
            ]}
            Icon={ChatIcon}
          >
            {t('Learning')}
          </MenuItem>

          <MenuItem
            routes={[`${DashboardRoutes.Submenu}?id=${SubMenuEnum.Locums}`, LocumsRoutes.Home]}
            Icon={StethoscopeIcon}
          >
            {t('Locums')}
          </MenuItem>

          <MenuItem routes={[MyNetworkRoutes.HomePage]} Icon={MyNetworkIcon}>
            {t('My Network')}
            {totalCount > 0 && (
              <Tag ml={4} size="sm" colorScheme="blue">
                {totalCount}
              </Tag>
            )}
          </MenuItem>
        </Stack>
      </Accordion>
    </SideMenu>
  );
};
