import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PathParamKeysEnum } from '../../../constants';
import { TrackerCategoryEnum } from '../../../generated';
import { updateBreadcrumb } from '../../../store/pageHeader/pageHeaderActions';
import { IPHRPatientCombined } from '../../../store/patient/phr/phrReducers';
import PhysicalTrackers from './PhysicalTrackers';

interface IProps {
  phrPatient: IPHRPatientCombined;
  onBack?: () => void;
  renderDefaultHeader?: (
    onCreate?: any,
    onBack?: (() => void) | undefined,
    title?: string | undefined,
    showDismiss?: boolean,
  ) => JSX.Element;
}
const PhysicalTrackersDashboard = ({ phrPatient, onBack, renderDefaultHeader }: IProps) => {
  const patient = phrPatient.patient!;
  const phrTrackers = (phrPatient.phr && phrPatient.phr.phrTrackers) || [];
  const dispatch = useDispatch();

  useEffect(() => {
    if (patient) {
      dispatch(updateBreadcrumb([PathParamKeysEnum.PatientID, patient.fullName || '']));
    }
  }, [patient, dispatch]);

  const trackers = phrTrackers.filter(
    (y) => y.trackerCategoryEnum === TrackerCategoryEnum.Physical,
  );

  return (
    <>
      <PhysicalTrackers
        renderDefaultHeader={renderDefaultHeader}
        patient={patient}
        trackers={trackers}
        onBack={onBack}
      />
    </>
  );
};

export default PhysicalTrackersDashboard;
