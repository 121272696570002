export enum DurationTimeUnitEnum {
  Days = 'Days',
  Weeks = 'Weeks',
  Months = 'Months',
  Years = 'Years',
}

export enum ResponseTypeEnum {
  Pending = 'Pending',
  Success = 'Success',
  Error = 'Error',
}
