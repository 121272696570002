import { Box, Flex, Text } from '@chakra-ui/react';

export const NoTestsSelected = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      height="340px"
      borderRadius="0.25rem"
      backgroundColor="#F7F9FF"
      px="3"
    >
      <Box>
        <Text textAlign="center" mb={2} fontWeight="700">
          Added Tests
        </Text>
        <Text textAlign="center" fontSize="12px">
          You do not have any tests added to this order list yet
        </Text>
      </Box>
    </Flex>
  );
};
