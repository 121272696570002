import React, { createContext, ReactNode } from 'react';
import { FormQuestion, PHRFormQuestion } from '../../../../generated';
import { useFormQuestions } from '../../../../services/formQuestions/useFormQuestions';
import useManageState, { IFormBuilderState } from './useManageState/useManageState';
import useSubmit from './useSubmit/useSubmit';

export interface IFormBuilderContext {
  isLoadingQuestions: boolean;
  loadingQuestionsError: string | null;
  formQuestions: FormQuestion[] | undefined;
  onNext: (phrFormQuestion: PHRFormQuestion) => void;
  goToPrevious: () => void;
  state: IFormBuilderState;
  canGoBack: boolean;
  lastStep: boolean;
  isSubmitting: boolean;
  error: string;
}

export const FormBuilderContext = createContext<IFormBuilderContext>(null!);

interface FormBuilderProviderProps {
  formID: string;
  patientID: string;
  phrConsultID?: string;
  children: ReactNode;
}

export default function FormBuilderProvider({
  formID,
  patientID,
  phrConsultID,
  children,
}: FormBuilderProviderProps) {
  const {
    isLoadingQuestions,
    questionsError: loadingQuestionsError,
    formQuestions,
  } = useFormQuestions({ formID });

  const { isSubmitting, error, submitForm } = useSubmit({
    formID,
    patientID,
    phrConsultID,
  });

  const { onNext, goToPrevious, state, canGoBack, lastStep } = useManageState({
    questionCount: formQuestions?.length || 0,
    submitForm,
  });

  return (
    <FormBuilderContext.Provider
      value={{
        isLoadingQuestions,
        loadingQuestionsError,
        formQuestions,
        state,
        onNext,
        goToPrevious,
        canGoBack,
        lastStep,
        isSubmitting,
        error,
      }}
    >
      {children}
    </FormBuilderContext.Provider>
  );
}
