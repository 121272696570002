import { AuthClient, PatientsClient } from '../../../../generated';
import { httpRequest } from '../../../../utils';

const getPatientWithID = async (patientID: string) => {
  const patientsClient = new PatientsClient(new AuthClient());

  const patient = await httpRequest(() => patientsClient.patientsGet(patientID));

  return patient;
};

export { getPatientWithID };
