import React from 'react';
import { Box } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { ButtonRow, PrimaryButton, SecondaryButton } from '../../../../../components/Buttons';
import { FormInputControl, FormTextArea } from '../../../../../components/forms';
import { GLOBAL_MAX_TEXT_LENGTH } from '../../../../../constants';
import { DurationTypeEnum, PHRMedication } from '../../../../../generated';
import { IAddNewMedicationForm } from '../../hooks/useAddNewMedication/useAddNewMedication';
import useTranslationComponent from '../../../../../hooks/useTranslationComponent';

interface IProps {
  submit: (values: IAddNewMedicationForm) => Promise<void>;
  initialValues?: IAddNewMedicationForm;
  selectedMedication?: PHRMedication | null;
  onCancel: () => void;
  preFilledName?: string;
}

export const NonFormularyForm = ({
  submit,
  initialValues,
  onCancel,
  selectedMedication,
  preFilledName,
}: IProps) => {
  const { t } = useTranslationComponent(['phr', 'validation']);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: initialValues?.name || preFilledName || '',
        notes: initialValues?.notes || '',
        startDate: new Date(),
        frequency: '',
        otherFrequency: '',
        quantityPerDose: '',
        otherQuantityPerDose: '',
        form: '',
        otherForm: '',
        methodOfIntake: '',
        otherMethodOfIntake: '',
        quantity: '',
        endDate: undefined,
        refills: '',
        otherRefills: '',
        durationCount: '',
        otherDurationCount: '',
        durationType: DurationTypeEnum.Days,
        prn: false,
        prnReason: '',
      }}
      onSubmit={submit}
      validationSchema={object({
        name: string()
          .required(t('A name is required', { ns: 'validation' }))
          .max(100, t('Name must be at most 100 characters', { ns: 'validation' })),
        notes: string()
          .nullable()
          .max(
            GLOBAL_MAX_TEXT_LENGTH,
            t(`Notes must be at most 10000 characters`, {
              ns: 'validation',
              10000: GLOBAL_MAX_TEXT_LENGTH,
            }),
          ),
      })}
    >
      {() => (
        <Box width="100%">
          <Form>
            <Box maxW="460px">
              <FormInputControl
                label={t('Non-Formulary Medication')}
                type="text"
                name="name"
                placeholder={t('Enter name of Non-Formulary')}
                formControlProps={{
                  tooltip: t('Medication or Other Product that is not listed in our Database'),
                }}
              />

              <FormTextArea label={t('Additional Information')} name="notes" placeholder=" " />
            </Box>
            <Box maxW="460px">
              <ButtonRow>
                {selectedMedication && (
                  <SecondaryButton onClick={onCancel}>{t('Cancel')}</SecondaryButton>
                )}
                <PrimaryButton type="submit">
                  {t(selectedMedication ? 'Update' : 'Add')}
                </PrimaryButton>
              </ButtonRow>
            </Box>
          </Form>
        </Box>
      )}
    </Formik>
  );
};
