import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { AuthClient, Tag, TagsClient, TagTypeEnum } from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

export const useTagsAllByTagType = (type: TagTypeEnum) => {
  const [tagMap, setTagMap] = useState<any>({});
  const {
    isLoading: isLoadingTags,
    data: tags,
    error: tagsError,
  } = useQuery<Tag[], string>(
    QueryKeys.TagsAllByTagType + (type ?? ''),
    async () => httpRequest(() => new TagsClient(new AuthClient()).allByTagType(type)),
    { enabled: !!type },
  );

  useEffect(() => {
    if (tags) {
      setTagMap(arrayToMap(tags));
    }
  }, [tags]);

  return {
    isLoadingTags,
    tags,
    tagMap,
    tagsError,
  };
};

const arrayToMap = (tags: Tag[]) => {
  const reducer = (acc, cur) => {
    return Object.assign(acc, {
      [cur.tagID]: cur,
    });
  };
  return tags.reduce(reducer, {});
};
