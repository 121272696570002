import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PHRTrackerStressDTO } from '../../../../../generated';
import { IPHRPatientEnhanced } from '../../../../../store/patient/phr/phrReducers';
import { phrStressesSelector } from '../../../../../store/patient/phr/phrSelectors';
import { getAllForPhrSection } from '../../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../../store/types';
import PhrItem, { SecondaryInfoComponent } from '../../../components/PhrItem';
import { getStressStatusByValue } from '../hooks/useTrackers';
import MoodTrackerView from '../MoodTrackerView';
import StressChart from './StressChart';
import StressForm from './StressForm';

interface IProps {
  patient: IPHRPatientEnhanced;
  renderHeader: (addButton?: any, optionalBack?: any) => any;
  onClose: () => void;
}

const StressComponent = ({ patient, renderHeader, onClose }: IProps) => {
  const dispatch = useDispatch();
  const phrStresses = phrStressesSelector(patient.phrStresses);

  useEffect(() => {
    if (patient) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRStresses, patient.patientID));
    }
  }, [dispatch, patient]);

  const thinView = (stress: PHRTrackerStressDTO, onView?: (data: any) => void) => (
    <>
      <PhrItem
        name={getStressStatusByValue(stress.stressValue)}
        isVerified={stress.verified}
        onView={() => {
          if (onView) {
            onView(stress);
          }
        }}
        SecondaryInfo={
          <SecondaryInfoComponent
            startDate={stress.trackerDate}
            startDateTitle="Tracked on:"
            showTime
          />
        }
      />
    </>
  );

  return (
    <MoodTrackerView
      renderHeader={renderHeader}
      phrCategoryEnum={ReduxPHRCategoryEnum.PHRStresses}
      patient={patient}
      trackerData={phrStresses}
      thinView={thinView}
      Chart={StressChart}
      Form={StressForm}
      createText="Stress"
      onClose={onClose}
    />
  );
};

export default StressComponent;
