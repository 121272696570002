import { Box, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { Avatar } from '../../../../components/common/Avatar';
import { OutpostTheme } from '../../../../themes/outpost';
import { getUserThumbnail } from '../../../../utils/getUserThumbnail';

import { RatingCard } from '../../../heal/patient/BookAppointment/components/common/Provider';

type StoreBasicInfoProps = {
  storeName?: string;
  storeImageDetailId?: string;
  storeRating?: string | number;
  description?: string;
};

const StoreBasicInfo: FC<StoreBasicInfoProps> = (props) => {
  return (
    <HStack spacing="3">
      <Box position="relative">
        <Avatar
          size={OutpostTheme.Avatar.Size.Extra}
          src={getUserThumbnail(props.storeImageDetailId)}
        />
        {!!props.storeRating && <RatingCard rating={props.storeRating} />}
      </Box>
      <Box flex="1">
        <Text
          fontSize={OutpostTheme.FontSizes.body}
          fontWeight="semibold"
          color="rgb(0, 3, 16)"
          mb="1"
        >
          {props.storeName}
        </Text>
        {props.description && (
          <Text fontSize="12px" fontWeight="medium" color="rgb(65, 79, 101)">
            {props.description}
          </Text>
        )}
      </Box>
    </HStack>
  );
};

export { StoreBasicInfo };
