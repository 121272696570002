import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PHRTrackerAnxietyDTO } from '../../../../../generated';
import { IPHRPatientEnhanced } from '../../../../../store/patient/phr/phrReducers';
import { phrAnxietiesSelector } from '../../../../../store/patient/phr/phrSelectors';
import { getAllForPhrSection } from '../../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../../store/types';
import PhrItem, { SecondaryInfoComponent } from '../../../components/PhrItem';
import { getAnxietyStatusByValue } from '../hooks/useTrackers';
import MoodTrackerView from '../MoodTrackerView';
import AnxietyChart from './AnxietyChart';
import AnxietyForm from './AnxietyForm';

interface IProps {
  patient: IPHRPatientEnhanced;
  renderHeader: (addButton?: any, optionalBack?: any) => any;
  onClose: () => void;
}

const AnxietyComponent = ({ patient, renderHeader, onClose }: IProps) => {
  const dispatch = useDispatch();
  const phrAnxieties = phrAnxietiesSelector(patient.phrAnxieties);

  useEffect(() => {
    if (patient) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRAnxieties, patient.patientID));
    }
  }, [dispatch, patient]);

  // const thinView = (anxiety: PHRTrackerAnxietyDTO) => (
  //   <>
  //     <Header>
  //       <Name>{getAnxietyStatusByValue(anxiety.anxietyValue)}</Name>
  //       <Tags>
  //         {anxiety.tags
  //           ?.sort((a, b) => a.name.localeCompare(b.name))
  //           .map((x) => (
  //             <Tag key={x.tagID}>{x.name}</Tag>
  //           ))}
  //       </Tags>
  //     </Header>
  //     <DateTime>{moment(anxiety.trackerDate).format(TimeFormatEnum.LONG_DATE_TIME)}</DateTime>
  //   </>
  // );

  const thinView = (anxiety: PHRTrackerAnxietyDTO, onView?: (data: any) => void) => (
    <>
      <PhrItem
        name={getAnxietyStatusByValue(anxiety.anxietyValue)}
        isVerified={anxiety.verified}
        onView={() => {
          if (onView) {
            onView(anxiety);
          }
        }}
        SecondaryInfo={
          <SecondaryInfoComponent
            startDate={anxiety.trackerDate}
            startDateTitle="Tracked on:"
            showTime
          />
        }
      />
    </>
  );

  return (
    <MoodTrackerView
      renderHeader={renderHeader}
      phrCategoryEnum={ReduxPHRCategoryEnum.PHRAnxieties}
      patient={patient}
      trackerData={phrAnxieties}
      thinView={thinView}
      Chart={AnxietyChart}
      Form={AnxietyForm}
      createText="Anxiety"
      onClose={onClose}
    />
  );
};

export default AnxietyComponent;
