import { useEffect, useState } from 'react';
import { Tag, TagTypeEnum, TagXref } from '../../../../../../../generated';
import { getUnique } from '../../../../../../../utils/getUnique';

function XRefFlatToTags(allTags: Tag[], xRefTag: TagXref) {
  let tags: Tag[] = [];
  if (xRefTag.toTag) {
    if ((xRefTag.toTag.tagXrefs ?? []).length > 0) {
      tags = [
        ...tags,
        xRefTag.toTag,
        ...(xRefTag.toTag.tagXrefs ?? []).reduce(XRefFlatToTags, [] as Tag[]),
      ];
    } else {
      tags = [xRefTag.toTag];
    }
    allTags = [...allTags, ...tags];
  }
  return allTags;
}

const useSymptomTags = (careTypeTags?: Tag[], careTypeTagXrefs?: TagXref[]) => {
  const [level1SymptomTags, setLevel1SymptomTags] = useState<Tag[]>();
  const [symptomTags, setSymptomTags] = useState<Tag[]>();

  useEffect(() => {
    const tagXrefs = (careTypeTags ?? []).reduce(
      (acc, cur) => [...acc, ...(cur.tagXrefs ?? [])],
      [] as TagXref[],
    );

    const tagsToUse = careTypeTagXrefs || tagXrefs;

    const tags = tagsToUse.reduce(XRefFlatToTags, [] as Tag[]);
    const l1 = tags
      .filter((x) => x.tagTypeEnum === TagTypeEnum.SymptomLevel1)
      .sort((a, b) => a.description.localeCompare(b.description));
    setLevel1SymptomTags(getUnique(l1, 'tagID'));
    const lrest = tags
      .filter(
        (x) =>
          x.tagTypeEnum === TagTypeEnum.SymptomLevel2 ||
          x.tagTypeEnum === TagTypeEnum.SymptomLevel3,
      )
      .sort((a, b) => a.description.localeCompare(b.description));
    setSymptomTags(getUnique(lrest, 'tagID'));
  }, [careTypeTags, careTypeTagXrefs]);

  return { level1SymptomTags, symptomTags };
};

export default useSymptomTags;
