import { Box, Tag } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { ClinicIcon, HouseIcon, ListIcon, MessageIcon, MyNetworkIcon } from '../../../assets/icons';
import {
  DashboardRoutes,
  MerchantProvider,
  MessageCenterRoutes,
  MyNetworkRoutes,
} from '../../../constants';
import { ProviderSettingEnum } from '../../../generated';
import useNotification from '../../../hooks/useNotification';
import useTranslationComponent from '../../../hooks/useTranslationComponent';
import { AppState } from '../../../store/root-reducers';
import { MenuItem } from './components/MenuItem';
import { SideMenu } from './components/SideMenu';

export const MerchantSideMenu = () => {
  const { t } = useTranslationComponent();
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const usersCount = useSelector(
    (state: AppState) => state.currentChatUserState.unreadUsersMessageCount,
  );
  const groupCount = useSelector(
    (state: AppState) => state.currentChatUserState.unreadGroupMessageCount,
  );
  const totalCount = usersCount + groupCount;
  const { mail } = useNotification({ currentUser });

  const { provider } = useSelector((state: AppState) => state.providerState);

  const ownsAStore = provider?.providerSettings?.find((item) => {
    return item.providerSettingEnum === ProviderSettingEnum.StoreManager;
  });

  return (
    <SideMenu>
      <MenuItem routes={[DashboardRoutes.Dashboard]} Icon={HouseIcon}>
        {t('Dashboard')}
      </MenuItem>
      <MenuItem routes={[MerchantProvider.Tasks]} Icon={ListIcon}>
        {t('Manage Tasks')}
      </MenuItem>
      {ownsAStore && (
        <MenuItem routes={[MerchantProvider.StoreManager]} Icon={ClinicIcon}>
          {t('Store Management')}
        </MenuItem>
      )}
      {ownsAStore && (
        <MenuItem
          routes={[MerchantProvider.Statements]}
          Icon={(props) => (
            <Box fontSize="22px" {...props}>
              <FontAwesomeIcon icon={['fal', 'file-invoice']} />
            </Box>
          )}
        >
          {t('Statements')}
        </MenuItem>
      )}
      <MenuItem
        Icon={MessageIcon}
        routes={[
          MessageCenterRoutes.InboxMail,
          MessageCenterRoutes.Mail,
          MessageCenterRoutes.SentMail,
          MessageCenterRoutes.ComposeMail,
          MessageCenterRoutes.DeletedMail,
          MessageCenterRoutes.AddressBook,
        ]}
      >
        {t('Messages')}
        {!!mail.count && (
          <Tag ml={4} size="sm" colorScheme="blue">
            {mail.count}
          </Tag>
        )}
      </MenuItem>

      <MenuItem routes={[MyNetworkRoutes.HomePage]} Icon={MyNetworkIcon}>
        {t('My Network')}
        {totalCount > 0 && (
          <Tag ml={4} size="sm" colorScheme="blue">
            {totalCount}
          </Tag>
        )}
      </MenuItem>
      {/* <MenuItem Icon={CodeIcon}>Store Code</MenuItem> */}
      {/* <MenuItem Icon={User2Icon}>Customer Code</MenuItem> */}
      {/* <MenuItem Icon={DocumentIcon}>Requisition Order</MenuItem> */}
      {/* <MenuItem routes={[MerchantProvider.InvoiceBuilder]} Icon={PaperIcon}>
        Build Invoice
      </MenuItem> */}
    </SideMenu>
  );
};
