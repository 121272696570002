import { Box, Flex, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Rating from 'react-rating';
import styled from 'styled-components';
import {
  FormQuestion,
  FormQuestionTypeEnum,
  FormStatusEnum,
  PHRForm,
  PHRFormQuestion,
} from '../../../../generated';
import { IntakeForm } from '../../../../routes/heal/Consult/Provider/ConsultNotesComponent';
import { FormSingleCheckbox } from '../../../forms';
import { MultipleChoiceResultView } from '../QuestionTypes';

export enum TargetWindowEnum {
  Screen = 'screen',
  Print = 'print',
}

interface IProps {
  intakeForm: IntakeForm;
  phrForm: PHRForm;
  formQuestions: FormQuestion[];
  showAllOptions?: boolean;
  window?: TargetWindowEnum;
}

export const Result = ({
  phrForm,
  formQuestions,
  showAllOptions = false,
  window = TargetWindowEnum.Screen,
}: IProps) => {
  const renderQuestion = (phrQuestion: PHRFormQuestion, index: number, showAllOptions: boolean) => {
    const question = formQuestions?.find(
      (question) => question.formQuestionID === phrQuestion.formQuestionID,
    );
    return (
      <Box key={index} mb={4}>
        <Question>{`${index + 1}. ${question?.name}`}</Question>
        <Box ml={4} mt={2}>
          {question?.formQuestionTypeEnum === FormQuestionTypeEnum.MultipleChoice && (
            <MultipleChoiceResultView
              formQuestion={question}
              previousAnswer={phrQuestion}
              showAllOptions={showAllOptions}
            />
          )}
          {question?.formQuestionTypeEnum === FormQuestionTypeEnum.Rating &&
            !!Number(phrQuestion.value) && (
              /* @ts-ignore */
              <Rating
                initialRating={Number(phrQuestion.value)}
                emptySymbol={<Star icon={['fal', 'star']} />}
                fullSymbol={<Star icon={['fas', 'star']} />}
                readonly
              />
            )}
          {question?.formQuestionTypeEnum === FormQuestionTypeEnum.OpinionScale && (
            <Flex>
              <Text color="blue.400" mr={2}>{`${phrQuestion.value}`}</Text>
              <Text
                color="gray.400"
                size="sm"
              >{`(${question.scaleStart} - ${question.scaleStop})`}</Text>
            </Flex>
          )}
          {(question?.formQuestionTypeEnum === FormQuestionTypeEnum.ShortText ||
            question?.formQuestionTypeEnum === FormQuestionTypeEnum.LongText ||
            question?.formQuestionTypeEnum === FormQuestionTypeEnum.YesNo) && (
            <Text color="blue.400">{phrQuestion.value}</Text>
          )}
        </Box>
      </Box>
    );
  };

  const countAnsweredQuestions = (phrFormQuestions: PHRFormQuestion[]) =>
    phrFormQuestions.filter(
      (x) => x.phrFormQuestionChoices!.length > 0 || (!!x.value && x.value !== 'undefined'),
    ).length;

  return (
    <Box mb={6}>
      {window === TargetWindowEnum.Screen &&
        formQuestions.find(
          (x) => x.formQuestionTypeEnum === FormQuestionTypeEnum.MultipleChoice,
        ) && <FormSingleCheckbox name="showAllOptions">Show all options</FormSingleCheckbox>}
      {phrForm.phrFormQuestions?.map((x, index) => renderQuestion(x, index, showAllOptions))}
      <Score>
        SCORE:
        {phrForm.formStatusEnum === FormStatusEnum.Scored && (
          <Point>{phrForm.totalScore ?? ''}</Point>
        )}
      </Score>
      <ScoreNote>
        {phrForm.formStatusEnum === FormStatusEnum.Scored && <div>{phrForm.scoreNotes ?? ''}</div>}
        <div>{`${countAnsweredQuestions(phrForm.phrFormQuestions || [])} / ${
          phrForm.phrFormQuestions?.length
        } questions answered`}</div>
      </ScoreNote>
    </Box>
  );
};

//export default forwardRef(Result);

const Star = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.Colors.Primary};
  font-size: 1.1rem;
  margin: 0 2px;
`;

const Question = styled.div`
  font-weight: 500;
  font-size: 16px;
  @media print {
    font-size: 13px;
  }
`;

const Score = styled.div`
  display: flex;
  font-weight: 500;
`;

const Point = styled.span`
  margin-left: 8px;
`;

const ScoreNote = styled.div`
  margin: 4px 0 10px 16px;
  color: ${(props) => props.theme.Colors.Primary};
  > div {
    padding: 2px;
  }
`;
