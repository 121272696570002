import { Formik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { date, number, object } from 'yup';
import { ButtonRow } from '../../../../components/Buttons';
import { PrimaryButton } from '../../../../components/Buttons/PrimaryButton';
import { SecondaryButton } from '../../../../components/Buttons/SecondaryButton';
import { Form, FormInputControl, FormMobisPicker } from '../../../../components/forms';
import { CenteredLoadingIndicator } from '../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../components/ServerValidationBox';
import { ButtonSizeEnum, ITrackerWeightDTO } from '../../../../constants';
import { WeightHeightEnum } from '../../../../generated';
import { createForPhrSection, updateForPhrSection } from '../../../../store/root-creator';
import { AppState } from '../../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import { convertHeightWeight } from '../../../../utils/conversionHelpers';
import { UnitWrapper } from '../../components/StyledPhrItems';

interface IProps {
  selectedData: ITrackerWeightDTO | null;
  patientID: string;
  toggleView: () => void;
  unit: WeightHeightEnum;
  unitSelector: any;
}

interface IForm {
  trackerDate: moment.Moment;
  height?: number;
  weight?: number;
}

export const WeightAndHeightForm = ({
  selectedData,
  patientID,
  toggleView,
  unit,
  unitSelector,
}: IProps) => {
  const dispatch = useDispatch();
  const { error, isPhrLoading } = useSelector((state: AppState) => state.phrPatientState);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const onSubmit = async (values: IForm) => {
    setSubmitting(true);
    if (selectedData) {
      selectedData.trackerDate = moment(values.trackerDate).toDate();
      selectedData.height = values.height;
      selectedData.weight = values.weight;
      await dispatch(
        updateForPhrSection(ReduxPHRCategoryEnum.PHRWeightHeights, selectedData, patientID, true),
      );
    } else {
      await dispatch(
        createForPhrSection(ReduxPHRCategoryEnum.PHRWeightHeights, values, patientID, true),
      );
    }
  };

  const handleUnitChange = (setFieldValue, currentWeight, currentHeight, toUnit) => {
    const { height, weight } = convertHeightWeight(
      true,
      currentWeight,
      currentHeight,
      toUnit,
      unit,
    );
    setFieldValue('weight', weight);
    setFieldValue('height', height);
  };

  useEffect(() => {
    if (isSubmitting && !isPhrLoading) {
      if (error) {
        setSubmitting(false);
      } else {
        toggleView();
      }
    }
  }, [error, isPhrLoading, isSubmitting, toggleView]);

  const initialValues: IForm = {
    trackerDate: selectedData ? moment(selectedData.trackerDate!) : moment(),
    height: selectedData?.height,
    weight: selectedData?.weight,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={object({
        trackerDate: date().required('Date is required').typeError('Invalid date'),
        height: number()
          .required('A height value is required')
          .positive('The height value must be a postive number')
          .typeError('Height must be a postive number')
          .moreThan(0, 'Value must be greater than 0')
          .lessThan(300, 'Value must be less than 300'),
        weight: number()
          .required('Weight is required')
          .positive('Weight must be a postive number')
          .typeError('Weight must be a postive number')
          .moreThan(0, 'Value must be greater than 0')
          .lessThan(1000, 'Value must be less than 1000'),
      })}
      onSubmit={onSubmit}
    >
      {({ status, setFieldValue, values }) => (
        <Form error={error}>
          {isSubmitting ? (
            <CenteredLoadingIndicator />
          ) : (
            <>
              <ServerValidationBox message={status} />
              <UnitWrapper>
                {unitSelector((x) => {
                  handleUnitChange(setFieldValue, values.weight, values.height, x);
                })}
              </UnitWrapper>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <FormMobisPicker
                    name="trackerDate"
                    label="Tracked at"
                    controls={['calendar', 'time']}
                    max={moment().toDate()}
                    min={moment().add(-150, 'year').toDate()}
                  />
                  {/* <FormDateTimePicker
                    name="trackerDate"
                    label="Tracked at"
                    maxDate={moment().toDate()}
                    minDate={moment().add(-150, 'year').toDate()}
                  /> */}
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <FormInputControl name="weight" label="Weight" type="text" />
                </div>
                <div className="col-12 col-sm-6">
                  <FormInputControl name="height" label="Height" type="text" />
                </div>
              </div>
              <ButtonRow>
                <SecondaryButton size={ButtonSizeEnum.medium} onClick={toggleView}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size={ButtonSizeEnum.medium} type="submit">
                  Save
                </PrimaryButton>
              </ButtonRow>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};
