import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { FormError } from '../../../../components/forms';

const Dropzone = styled.div`
  border: 2px dashed rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 0.5rem 0.25rem;
`;

const NoFilesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const AvailableFileTypes = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    font-weight: 700;
    margin: 0 5px;
    display: inline;
  }
`;

const AcceptedFiles = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0;
  margin: 0;
`;

const AcceptedFile = styled.li`
  margin-bottom: 10px;
  width: 50%;
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.p`
  text-align: center;
`;

const LinkText = styled.span`
  color: ${(props) => props.theme.Colors.Primary};
  font-weight: 500;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

const FileWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  position: relative;
`;

const CheckmarkIcon = styled(FontAwesomeIcon)`
  position: absolute;
  bottom: -4px;
  right: -4px;
  border: 1px solid white;
  border-radius: 50%;
  background-color: #7ab986;
  color: white;
  padding: 2px;
  font-size: 0.8em;
`;

const FileName = styled.p`
  flex-grow: 1;
  padding-left: 8px;
`;

const RemoveIcon = styled(FontAwesomeIcon)`
  font-size: 1.25em;
  :hover {
    cursor: pointer;
  }
`;

const CloudIcon = styled(FontAwesomeIcon)`
  margin: 1rem 0 0.5rem;
`;

interface IProps {
  signature?: File;
  setSignature: (file?: File) => void;
}

const DropzoneComponent = ({ signature, setSignature }: IProps) => {
  const onDrop = (acceptedFile) => {
    // let newFiles = [...signature, ...acceptedFiles];
    // // Only set unique files
    // let uniqueNewFiles: File[] = [];
    // const map = new Map();
    // for (const file of newFiles) {
    //   map.set(file.path, true);
    //   uniqueNewFiles.push(file);
    // }

    setSignature(acceptedFile[0]);
  };

  const removeFile = () => {
    setSignature(undefined);
  };

  const { getRootProps, getInputProps, isDragReject, open } = useDropzone({
    onDrop,
    minSize: 0,
    multiple: false,
    noClick: true,
    noKeyboard: true,
    accept: 'image/png, image/jpeg',
  });

  return (
    <Dropzone {...getRootProps()}>
      <Text>
        Drag and Drop your file into this box or click <LinkText onClick={open}>HERE</LinkText> to
        upload
      </Text>
      <input {...getInputProps()} />
      {!signature ? (
        <NoFilesContainer>
          <CloudIcon icon={['far', 'cloud-upload']} size="3x" />
          <AvailableFileTypes>
            <li>.JPG</li>
            <li>.PNG</li>
          </AvailableFileTypes>
        </NoFilesContainer>
      ) : (
        <AcceptedFiles>
          <AcceptedFile>
            <FileWrapper>
              {signature.type === 'image/png' || signature.type === 'image/jpeg' ? (
                <FontAwesomeIcon icon={['far', 'image']} size="lg" />
              ) : (
                <FontAwesomeIcon icon={['far', 'file-alt']} size="lg" />
              )}
              <CheckmarkIcon icon={['far', 'check']} size="lg" />
            </FileWrapper>
            <FileName>{signature.name}</FileName>
            <RemoveIcon onClick={removeFile} icon={['far', 'trash-alt']} size="lg" />
          </AcceptedFile>
        </AcceptedFiles>
      )}

      {isDragReject && <FormError error="File type is not accepted." />}
    </Dropzone>
  );
};

export default DropzoneComponent;
