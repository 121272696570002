import {
  AuthClient,
  PatientOrderFinalizeDetails,
  PatientOrdersClient,
} from '../../../../generated';
import { httpRequest } from '../../../../utils';

const finalizeOrders = async (
  patientID: string,
  patientOrderIDs: string[],
  prepareForPayment?: boolean,
) => {
  const patientOrderClient = new PatientOrdersClient(new AuthClient());

  const finalizeDetails = new PatientOrderFinalizeDetails();
  finalizeDetails.patientID = patientID;
  finalizeDetails.patientOrderIDs = patientOrderIDs;
  finalizeDetails.prepareForPayment = prepareForPayment;

  const result = await httpRequest(() => patientOrderClient.finalize(finalizeDetails));

  return result;
};

export { finalizeOrders };
