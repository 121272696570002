import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import {
  AuthClient,
  IOrder,
  OrderItem,
  PatientOrder,
  PatientOrderItem,
  PatientOrdersClient,
} from '../../../../../../../../generated';
import { useOrderByID } from '../../../../../../../../services/orders/queries/useOrderByID';
import { usePatientOrdersPut } from '../../../../../../../../services/patientOrders/mutations/usePatientOrdersPut';
import { httpRequest } from '../../../../../../../../utils';

export interface IOrderItemOption {
  orderItemID?: string;
  description: string;
}

type UseAddOrderTestsProps = {
  selectedOrderType?: IOrder;
  patientOrder?: PatientOrder;
  setPatientOrder: Dispatch<SetStateAction<PatientOrder | undefined>>;
  goToNextStep: () => void;
  countryID: string;
  storeID?: string;
  addressBookID?: string;
  handleUpdateOrderItemList: (orderItem: OrderItem[]) => void;
};

export const useAddOrderTests = ({
  selectedOrderType,
  patientOrder,
  setPatientOrder,
  goToNextStep,
  countryID,
  storeID,
  addressBookID,
  handleUpdateOrderItemList,
}: UseAddOrderTestsProps) => {
  const [orderItemOptions, setOrderItemOptions] = useState<IOrderItemOption[]>([]);
  const [selectedOrderItem, setSelectedOrderItem] = useState<PatientOrderItem>();
  const { isLoadingOrder, order, orderError } = useOrderByID({
    orderID: selectedOrderType?.orderID,
    countryID,
    storeID,
    addressBookID,
  });
  const patientOrdersPut = usePatientOrdersPut();
  const [isUpdating, setUpdating] = useState<boolean>(false);
  const [isEditTest, setIsEditTest] = useState<boolean>(false);
  const [errorUpdating, setErrorUpdating] = useState<string>();

  const addOrderItem = useCallback(
    async (patientOrderItem: PatientOrderItem, additionalInformation: string) => {
      try {
        setUpdating(true);
        const updatedOrder = new PatientOrder();
        updatedOrder.init(patientOrder);
        const patientOrderItems = patientOrder?.patientOrderItems ?? [];
        patientOrderItem.patientOrderID = patientOrder?.patientOrderID ?? '';
        patientOrderItem.additionalInformation = additionalInformation;
        updatedOrder.patientOrderItems = [...patientOrderItems, patientOrderItem];
        await patientOrdersPut.mutateAsync(updatedOrder);
        const po = await httpRequest(() =>
          new PatientOrdersClient(new AuthClient()).patientOrdersGet(
            patientOrder?.patientOrderID ?? '',
          ),
        );
        setPatientOrder(po);
      } catch (err) {
        setErrorUpdating(err as any);
      }
      setUpdating(false);
    },
    [setPatientOrder, patientOrder, patientOrdersPut],
  );

  const removeOrderItem = useCallback(
    async (orderItemID: string) => {
      try {
        setUpdating(true);
        const updatedOrder = new PatientOrder();
        updatedOrder.init(patientOrder);
        const orderItems = patientOrder?.patientOrderItems ?? [];
        updatedOrder.patientOrderItems = orderItems.map((x) => {
          x.isDeleted = x.orderItemID === orderItemID;
          return x;
        });
        await patientOrdersPut.mutateAsync(updatedOrder);
        updatedOrder.patientOrderItems = orderItems.filter((x) => x.orderItemID !== orderItemID);
        const po = await httpRequest(() =>
          new PatientOrdersClient(new AuthClient()).patientOrdersGet(
            patientOrder?.patientOrderID ?? '',
          ),
        );
        setPatientOrder(po);
      } catch (err) {
        setErrorUpdating(err as any);
      }
      setUpdating(false);
    },
    [setPatientOrder, patientOrder, patientOrdersPut],
  );

  const onEdit = useCallback(
    async (patientOrderItem: PatientOrderItem) => {
      setIsEditTest(true);
      removeOrderItem(patientOrderItem.orderItemID!);
      const resetPoi = new PatientOrderItem();
      resetPoi.orderItemID = patientOrderItem.orderItemID;
      resetPoi.additionalInformation = patientOrderItem.additionalInformation;
      resetPoi.testDescription = patientOrderItem.testDescription;
      resetPoi.orderItem = patientOrderItem.orderItem;
      setSelectedOrderItem(resetPoi);
    },
    [removeOrderItem],
  );

  const getOrderItemByOrderItemID = useCallback(
    (orderItemID: string) => {
      return (order?.orderItems ?? []).find((x) => x.orderItemID === orderItemID);
    },
    [order],
  );

  useEffect(() => {
    const orderItemOptions = (order?.orderItems ?? []).map(
      (orderItem) =>
        ({
          orderItemID: orderItem.orderItemID,
          description: orderItem.item?.description,
        } as IOrderItemOption),
    );

    setOrderItemOptions(
      orderItemOptions.filter((x) => {
        return (
          (patientOrder?.patientOrderItems ?? []).filter((y) => y.orderItemID === x.orderItemID)
            .length === 0
        );
      }),
    );
  }, [order, patientOrder]);

  useEffect(() => {
    if (order?.orderItems) {
      handleUpdateOrderItemList(order?.orderItems);
    }
  }, [order, handleUpdateOrderItemList]);

  return {
    orderName: selectedOrderType?.name ?? '',
    orderItemOptions,
    addedOrderItems: patientOrder?.patientOrderItems ?? [],
    addOrderItem,
    removeOrderItem,
    selectedOrderItem,
    setSelectedOrderItem,
    getOrderItemByOrderItemID,
    isLoadingOrder,
    orderError,
    isUpdating,
    errorUpdating,
    goToNextStep,
    onEdit,
    setIsEditTest,
    isEditTest,
  };
};

export type UseAddOrderTestsReturnType = ReturnType<typeof useAddOrderTests>;
