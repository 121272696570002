import { Box } from '@chakra-ui/react';
import moment from 'moment';
import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { CHART_PLUGINS } from '../../../../constants';
import { PHRTrackerRespiratoryDTO } from '../../../../generated';
import { getTime } from '../../../../utils';
import TrackerChartBase from '../TrackerChartBase';

interface IProps {
  dataSet: PHRTrackerRespiratoryDTO[];
}

const RespiratoryChart: React.FC<IProps> = ({ dataSet }) => {
  const [daysRange, setDaysRange] = useState<number>(180);

  const data = () => {
    const sorted = [...dataSet].sort((a, b) => getTime(a.trackerDate) - getTime(b.trackerDate));

    return {
      datasets: [
        {
          label: 'Respiratory Rate',
          data: sorted.map((d) => ({ x: d.trackerDate, y: d.respiratory })),
          fill: false,
          borderColor: '#00A18F',
          borderWidth: 2,
        },
      ],
    };
  };

  const options = {
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          type: 'linear',
          ticks: {
            suggestedMin: 0,
            suggestedMax: 80,
          },
        },
      ],
      xAxes: [
        {
          type: 'time',
          position: 'bottom',
          distribution: 'linear',
          time: {
            unit: daysRange <= 7 ? 'day' : 'month',
          },
          ticks: {
            max: moment(),
            min: moment().subtract(daysRange, 'd'),
          },
        },
      ],
    },
    plugins: CHART_PLUGINS,
    maintainAspectRatio: false,
    layout: {
      padding: { top: 20, bottom: 20 },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <TrackerChartBase
      name="Respiratory Rate"
      color="#F0E9D4"
      daysRange={daysRange}
      setDaysRange={setDaysRange}
    >
      <Box
        position="relative"
        height={{ base: 'calc(100% - 5.5rem)', md: 'calc(100% - 4rem)' }}
        width="calc(100% - 20px)"
        margin="0 auto"
      >
        {/* @ts-ignore */}
        <Line data={data} options={options} />
      </Box>
    </TrackerChartBase>
  );
};

export default RespiratoryChart;
