import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { COOKIE_NAME } from '../constants';
import { getCookie, setCookie } from '../utils/cookieHelper';
import { decodeJwtToCurrentUser } from '../utils/decodeJwtToCurrentUser';
import { TCurrentAppState } from './currentApp/currentAppReducers';
import websocketMiddleware from './middlewares/websocketMiddleware';
import { rootReducer } from './root-reducers';

const composeEnhancer: typeof compose =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function defaultState() {
  // Check if we have JWT from mobile
  const urlParams = new URLSearchParams(window.location.search);
  const jwt = urlParams.get('jwt');
  const sysParam = (urlParams.get('sys') as 'android' | 'ios') ?? undefined;
  const webViewJwt: string | null = null;
  const jwtParam = webViewJwt || jwt;
  const webViewID = urlParams.get('webViewID');

  let cookie = getCookie(COOKIE_NAME);
  if (jwtParam) {
    setCookie(COOKIE_NAME, jwtParam);
    cookie = jwtParam;
  }

  const appStateData = {
    currentAppState: {
      data: {
        isInMobileView: !!jwtParam,
        isMobileSystem: !!webViewID,
        mobileSystem: sysParam,
      },
    } as TCurrentAppState,
  };

  // Check if we have an Outpost.Health cookie
  if (cookie.length > 0) {
    return {
      ...appStateData,
      router: undefined,
      currentUserState: {
        isLoading: false,
        error: '',
        data: decodeJwtToCurrentUser(cookie, !!jwtParam, sysParam),
      },
    };
  }
  return appStateData;
}

const userState = defaultState();

export const initializeStore = (history) =>
  createStore(
    rootReducer(history),
    userState,
    composeEnhancer(applyMiddleware(thunk, routerMiddleware(history), websocketMiddleware)),
  );

export const initializeTestStore = () => createStore(rootReducer(), {});
