import { ActionType, createAction } from 'typesafe-actions';
import { AuthClient, PatientsClient, ProvidersClient, RolesEnum } from '../../generated';
import { httpRequest } from '../../utils';
import { isInRole } from '../../utils/isInRole';
import { refreshToken } from '../currentUser/currentUserCreators';
import { ICurrentUser } from '../currentUser/currentUserReducers';
import { OHThunkResult } from '../root-reducers';

export const updateCommunityPictureRequest = createAction(
  'UPDATE_COMMUNITY_PICTURE_REQUEST',
  () => {},
)();
export const updateCommunityPictureProviderSuccess = createAction(
  'UPDATE_COMMUNITY_PICTURE_PROVIDER_SUCCESS',
  (res: string) => res,
)();
export const updateCommunityPicturePatientSuccess = createAction(
  'UPDATE_COMMUNITY_PICTURE_PATIENT_SUCCESS',
  (res: string) => res,
)();
export const updateCommunityPictureError = createAction(
  'UPDATE_COMMUNITY_PICTURE_ERROR',
  (err: string) => err,
)();

export function updateCommunityPicture(
  discussionsImageDetailID: string,
  currentUser: ICurrentUser,
): OHThunkResult<Promise<void>> {
  return async (dispatch) => {
    return new Promise<void>(async (resolve) => {
      dispatch(updateCommunityPictureRequest());
      const authClient = new AuthClient();
      try {
        if (isInRole(currentUser, RolesEnum.Patient)) {
          const patientsClient = new PatientsClient(authClient);
          const patient = await httpRequest(() =>
            patientsClient.byUserDetailID(currentUser.userDetailID),
          );
          patient.discussionsImageDetailID = discussionsImageDetailID;
          await httpRequest(() => patientsClient.patientsPut(patient));
          dispatch(updateCommunityPicturePatientSuccess(discussionsImageDetailID));
        } else if (isInRole(currentUser, RolesEnum.Provider)) {
          const providersClient = new ProvidersClient(authClient);
          const provider = await httpRequest(() =>
            providersClient.byUserDetailID(currentUser.userDetailID),
          );
          provider.discussionsImageDetailID = discussionsImageDetailID;
          await httpRequest(() => providersClient.providersPut(provider));
          dispatch(updateCommunityPictureProviderSuccess(discussionsImageDetailID));
        }
        dispatch(refreshToken());
        resolve();
      } catch (err) {
        dispatch(updateCommunityPictureError(err as any));
      }
    });
  };
}

export type UpdateCommunityPictureActionTypes = ActionType<
  | typeof updateCommunityPictureRequest
  | typeof updateCommunityPictureProviderSuccess
  | typeof updateCommunityPicturePatientSuccess
  | typeof updateCommunityPictureError
>;
