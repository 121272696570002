import { Flex, Stack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { PurpleButton } from '../../../../../../../../components/Buttons';
import { FormTextArea } from '../../../../../../../../components/forms';
import { CenteredLoadingIndicator } from '../../../../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../../../../components/ServerValidationBox';
import { PatientOrder, PatientOrderQuestion } from '../../../../../../../../generated';
import { useQuestionair } from '../../../contexts/CreateOrderContext/useQuestionair/useQuestionair';
import { UltrasoundForm } from './questionairFormTypes';

export const UltrasoundQuestionair = () => {
  const { patientOrder, onSubmitForm, isLoading, error } = useQuestionair();

  return (
    <Formik
      initialValues={
        {
          reasonForExam: patientOrder?.reasonForExam ?? '',
        } as UltrasoundForm
      }
      validationSchema={object({
        reasonForExam: string().max(1024, 'Reason for Exam must be at most 1024 characters'),
      })}
      onSubmit={async (values: UltrasoundForm) => {
        const patientOrderQuestion = new PatientOrderQuestion();
        patientOrderQuestion.init(patientOrder?.patientOrderQuestion);

        const po = new PatientOrder();
        po.init(patientOrder);
        po.patientOrderQuestion = patientOrderQuestion;
        po.reasonForExam = values.reasonForExam;

        await onSubmitForm(po);
      }}
    >
      {({}) => (
        <Form>
          {isLoading ? (
            <CenteredLoadingIndicator />
          ) : (
            <Stack spacing="5">
              <ServerValidationBox message={error} />
              <FormTextArea
                maxLength={250}
                name="reasonForExam"
                label="Reason for Exam"
                formControlProps={{
                  mb: '0',
                }}
              />

              <Flex justifyContent="flex-start">
                <PurpleButton type="submit" width={{ base: '100%', sm: 'auto' }}>
                  Continue
                </PurpleButton>
              </Flex>
            </Stack>
          )}
        </Form>
      )}
    </Formik>
  );
};
