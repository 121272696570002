import {
  AuthClient,
  PatientPrescriptionFinalizeDetails,
  PatientPrescriptionsClient,
} from '../../../../generated';
import { httpRequest } from '../../../../utils';

const finalizePrescriptions = async (patientID: string, patientPrescriptionIDs: string[]) => {
  const prescriptionsClient = new PatientPrescriptionsClient(new AuthClient());

  const finalizeDetails = new PatientPrescriptionFinalizeDetails();
  finalizeDetails.patientID = patientID;
  finalizeDetails.patientPrescriptionIDs = patientPrescriptionIDs;

  const result = await httpRequest(() => prescriptionsClient.finalize(finalizeDetails));

  return result;
};

export { finalizePrescriptions };
