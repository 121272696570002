import { createIcon } from '@chakra-ui/react';

export const OutpostLogoWatermark = createIcon({
  displayName: 'OutpostLogoWatermark',
  viewBox: '0 0 145.134 129.187',
  path: (
    <svg xmlns="http://www.w3.org/2000/svg">
      <path
        id="Path_20351"
        data-name="Path 20351"
        d="M108.588,125.576h0a8.263,8.263,0,0,1,1.3-10.8,56.971,56.971,0,0,0,18.221-34.9,54.938,54.938,0,0,0-12.6-43.527C96.42,14.017,62.578,9.851,40.083,27.086,18.075,43.948,10.157,75,22.064,97.777a36.224,36.224,0,0,0,3.053,4.895c4.274,5.727,9.332,8.815,15.448,9.44,6.894.7,15.064-1.906,22.781-7.111a90.448,90.448,0,0,1-9.554-15.419C50.232,81.871,49.1,74.7,50.346,67.664A34.864,34.864,0,0,1,61.485,48.331a36.718,36.718,0,0,1,19.89-9.519,25.165,25.165,0,0,1,12.08,1.379,24.347,24.347,0,0,1,9.386,6.4,27.577,27.577,0,0,1,6.269,11.73,29.866,29.866,0,0,1,.261,13.621c-1.694,7.943-5.629,15.655-12.021,23.569a119.235,119.235,0,0,1-10.79,11.494,67.958,67.958,0,0,0,6,5.373,8.293,8.293,0,0,1,1.994,10.765h0a8.263,8.263,0,0,1-12.2,2.236,86.181,86.181,0,0,1-8.372-7.623c-10.026,7.121-20.929,10.987-31.113,10.987a38.947,38.947,0,0,1-3.94-.2c-10.671-1.093-20.008-6.619-27.006-15.99a53.032,53.032,0,0,1-4.462-7.126c-7.8-14.862-9.539-32.58-4.929-49.89A77.8,77.8,0,0,1,30.042,13.973C44.436,2.956,62.682-1.8,81.4.617a74.637,74.637,0,0,1,25.42,8.061,72.169,72.169,0,0,1,21.259,16.93,70.42,70.42,0,0,1,14.409,27.085,74.078,74.078,0,0,1-21.51,74.36,8.278,8.278,0,0,1-12.39-1.477ZM84.67,55.126a10.627,10.627,0,0,0-1.29.084c-7.668.941-15.507,8.1-16.773,15.31-1.339,7.648,3.477,15.6,9.357,23.741,7.953-7.633,15.2-16.251,17.236-25.775a12.277,12.277,0,0,0-2.566-10.745,7.786,7.786,0,0,0-5.964-2.615Z"
        transform="translate(-0.004 -0.003)"
        fill="currentColor"
        opacity="0.15"
      />
    </svg>
  ),
});
