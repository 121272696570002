import { useMutation } from 'react-query';
import {
  AuthClient,
  PatientOrderFinalizeDetails,
  PatientOrderFinalizeResults,
  PatientOrdersClient,
} from '../../../generated';
import { httpRequest } from '../../../utils';

export const usePatientOrdersFinalize = () => {
  const patientOrdersFinalize = useMutation<
    PatientOrderFinalizeResults,
    string,
    PatientOrderFinalizeDetails,
    string
  >(async (finalizeDetails: PatientOrderFinalizeDetails) =>
    httpRequest(() => new PatientOrdersClient(new AuthClient()).finalize(finalizeDetails)),
  );

  return patientOrdersFinalize;
};
