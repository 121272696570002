import { Link, LinkProps, Text } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { OutpostTheme } from '../../../themes/outpost';

type IFooterLink = LinkProps & {
  href: string;
  text: string;
  children?: ReactNode;
};
export const FooterLink = (props: IFooterLink) => {
  const { href, text, children, ...rest } = props;
  return (
    <Link
      href={href}
      _hover={{ textDecoration: 'none' }}
      textDecoration="none"
      isExternal
      {...rest}
    >
      <Text fontSize={OutpostTheme.FontSizes.body} fontWeight="400" color="#FFFFFF">
        {text}
      </Text>
    </Link>
  );
};
