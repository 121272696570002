import { useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import { createGlobalStyle } from 'styled-components';

import '../../../assets/styles/phone-input.scss';
import { INITIAL_COUNTRY } from '../../../constants';
import { BaseProps } from '../baseProps';
import { FormControl } from '../FormControl/FormControl';

export type PhoneInputControlProps = BaseProps & Overwrite<PhoneInputProps, { country?: string }>;

const GlobalStyle = createGlobalStyle`
  .react-tel-input .form-control:focus {
    box-shadow: none;
    border-color: rgb(0, 3, 16) !important;
  }
`;

export const PhoneInputControl = (props: PhoneInputControlProps) => {
  const {
    label,
    name,
    inputProps,
    preferredCountries = process.env.REACT_APP_SUPPORTED_COUNTRY_CODES?.split(',') ?? [],
    country = INITIAL_COUNTRY,
    formControlProps,
    ...rest
  } = props;
  const { inputStyle, ...restInput } = rest;

  return (
    <FormControl label={label} name={name} {...formControlProps}>
      <GlobalStyle />
      <PhoneInput
        specialLabel=""
        placeholder=""
        inputProps={{
          name,
          ...inputProps,
        }}
        preferredCountries={preferredCountries}
        // countryCodeEditable={false}
        country={country.toLowerCase()}
        inputStyle={{
          backgroundColor: 'rgba(243, 243, 243, 0.50)',
          // borderColor: 'rgba(12, 52, 104, 0.57)',
          borderColor: 'rgb(0, 3, 16)',
          borderRadius: '8px',
          minHeight: '40px',
          ...inputStyle,
        }}
        containerStyle={{
          padding: '1px',
        }}
        {...restInput}
      />
    </FormControl>
  );
};

export type FormPhoneInputControlProps = PhoneInputControlProps & {
  setFormattedValue?: (formatted: string) => void;
};

export const FormPhoneInputControl = (props: FormPhoneInputControlProps) => {
  const { name, setFormattedValue, formControlProps, ...rest } = props;
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  // In case we have an initial value, we need to trigger a change event to get the setFormattedValue
  // https://stackoverflow.com/questions/23892547/what-is-the-best-way-to-trigger-onchange-event-in-react-js
  useEffect(() => {
    if (setFormattedValue && field.value) {
      const element = document.getElementsByName(name)[0];
      // @ts-ignore
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value',
      ).set;
      nativeInputValueSetter?.call(element, field.value);
      const event = new Event('input', { bubbles: true });
      // @ts-ignore
      element.dispatchEvent(event);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PhoneInputControl
      {...field}
      onChange={(val, data, event, formattedValue) => {
        if (setFormattedValue) {
          setFormattedValue(formattedValue);
        }
        setFieldValue(field.name, val);
      }}
      formControlProps={{ ...meta, ...formControlProps }}
      {...rest}
    />
  );
};
