import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { CurrentAppActionTypes, getCurrentAppActions, TCurrentApp } from './currentAppActions';

const data = createReducer<TCurrentApp | null, CurrentAppActionTypes>(null).handleAction(
  [getCurrentAppActions.success],
  (_state, action) => action.payload,
);

export type TCurrentAppState = {
  data: TCurrentApp | null;
};

export default combineReducers<TCurrentAppState>({
  data,
});
