import { useLocation } from 'react-router';
import { RouteConfigs } from '../../../../constants';

const usePageTitle = () => {
  const location = useLocation();

  let title = '';
  const route = RouteConfigs.find((x) => x.path === location.pathname);
  if (route) {
    title = route.title;
  }

  return { title };
};

export default usePageTitle;
