import { Box, Flex, Text } from '@chakra-ui/react';
import { getReactSelectStyle } from '../../../constants';
import { Country, CurrencyEnum, ICountry } from '../../../generated';
import { OutpostTheme } from '../../../themes/outpost';
import { FormSelectComponent, SelectComponent } from '../Selects';
import { FormSelectComponentProps, SelectComponentProps } from './Select';

export const generateSingleCurrencyOption = (country: ICountry) => {
  return {
    value: country.currencyEnum!,
    label: `${country.currencySymbol} (${country.currencyCode}) ${country.currencyName}`,
    country: { code: country.code.toLowerCase() },
    currency: {
      code: country.currencyCode,
      symbol: country.currencySymbol,
      name: country.currencyName,
    },
  };
};

const getCurrencyOptions = (countries) => countries.map((x) => generateSingleCurrencyOption(x));

export interface ICurrencyOption {
  value: CurrencyEnum;
  label: string;
  country: any;
  currency: any;
}

export type CurrencyPickerType = Omit<SelectComponentProps, 'options'> & {
  countries: ICountry[] | Country[];
  alt?: boolean;
  showOnlyLogo?: boolean;
};

export const CurrencyPicker = (props: CurrencyPickerType) => {
  const options = getCurrencyOptions(props.countries);
  const styles = {
    ...getReactSelectStyle(),
    control: (styles, { isFocused }) => ({
      ...styles,
      color: '#333',
      fontSize: '14px',
      minHeight: '40px',
      borderColor: isFocused ? 'none !important' : '#E4E9F2',
      borderRadius: '100px',
    }),
  };

  const altOption = props.alt ? { styles } : {};

  return (
    <SelectComponent
      {...props}
      components={{
        IndicatorSeparator: () => null,
      }}
      options={options}
      optionTemplate={optionComponent}
      singleValueTemplate={
        props.alt
          ? (data: any) => altSingleValueComponent(data, props.showOnlyLogo)
          : (data: any) => singleValueComponent(data, props.showOnlyLogo)
      }
      {...altOption}
    />
  );
};

export type FormCurrencyPickerType = Omit<FormSelectComponentProps, 'options'> & {
  countries: ICountry[] | Country[];
  alt?: boolean;
  showOnlyLogo?: boolean;
};

export const FormCurrencyPicker = (props: FormCurrencyPickerType) => {
  const options = getCurrencyOptions(props.countries);

  return (
    <FormSelectComponent
      {...props}
      options={options}
      components={{
        IndicatorSeparator: () => null,
      }}
      optionTemplate={optionComponent}
      singleValueTemplate={
        props.alt
          ? (data: any) => altSingleValueComponent(data, props.showOnlyLogo)
          : (data: any) => singleValueComponent(data, props.showOnlyLogo)
      }
    />
  );
};

const optionComponent = (single) => {
  return (
    <Flex justifyContent="flex-start" alignItems="center" className="f16">
      {/* hf3286 <span className={`flag flag-${single.country.code}`}></span> */}
      {/* <Text mr={1}>{single.currency.code}</Text> */}
      <Text
        fontSize="12px"
        color="gray.500"
      >{`${single.currency.symbol} ${single.currency.name}`}</Text>
    </Flex>
  );
};

const singleValueComponent = (single, showOnlyLogo = false) => {
  return (
    <Flex justifyContent="flex-start" alignItems="center">
      <Box className={`flag flag-${single.country.code.toLowerCase()}`} as="span" />
      {!showOnlyLogo && (
        <Text fontSize={OutpostTheme.FontSizes.body} mr={1}>
          {single.currency.code}
        </Text>
      )}
      {!showOnlyLogo && <Text color="gray.400">{`${single.currency.symbol}`}</Text>}
    </Flex>
  );
};

const altSingleValueComponent = (single, showOnlyLogo = false) => {
  return (
    <Flex justifyContent="flex-start" alignItems="center">
      <Box className={`flag flag-${single.country.code.toLowerCase()}`} as="span" />
      {!showOnlyLogo && (
        <Text fontSize={OutpostTheme.FontSizes.body} mr={1}>
          {single.currency.symbol}
        </Text>
      )}
      {!showOnlyLogo && <Text fontSize="12px" color="#000000">{`${single.currency.name}`}</Text>}
    </Flex>
  );
};
