import { Box, Text, HStack, Flex } from '@chakra-ui/react';

import { PatientOrderItem } from '../../../../../../../generated';
import { OutpostTheme } from '../../../../../../../themes/outpost';

interface IProps {
  onRemoveOrderItem?: () => void;
  patientOrderItem: PatientOrderItem;
  onEdit?: (patientOrderItem: PatientOrderItem) => void;
  Cost?: React.ReactElement;
}

export const ItemCard = ({ onRemoveOrderItem, patientOrderItem, onEdit, Cost }: IProps) => {
  return (
    <Box
      backgroundColor="#FFFFFF"
      boxShadow="lg"
      border="1px solid"
      borderColor="#f2f2f2"
      borderRadius="0.25rem"
      p={5}
      position="relative"
    >
      <Flex justify="space-between">
        <Text fontSize={OutpostTheme.FontSizes.body} pr="30px" flex={1}>
          {patientOrderItem.orderItem
            ? patientOrderItem.orderItem?.item?.name
            : patientOrderItem.testDescription}
        </Text>
        {/* {Cost && onRemoveOrderItem && (
          <Text
            onClick={() => onRemoveOrderItem(patientOrderItem.orderItemID!)}
            fontSize="12px"
            color="red.500"
            cursor="pointer"
            minW="4rem"
            textAlign="right"
          >
            Delete
          </Text>
        )} */}
      </Flex>
      <Text fontSize="12px" color="gray.500" mt={4} mb={3}>
        {patientOrderItem.additionalInformation}
      </Text>
      {!patientOrderItem.testDescription && Cost}
      <HStack spacing={4}>
        {onEdit && (
          <Text
            onClick={() => onEdit(patientOrderItem)}
            fontSize="12px"
            color="#3E1191"
            cursor="pointer"
          >
            Edit
          </Text>
        )}
        {onRemoveOrderItem && (
          <Text onClick={onRemoveOrderItem} fontSize="12px" color="red.500" cursor="pointer">
            Delete
          </Text>
        )}
      </HStack>
    </Box>
  );
};
