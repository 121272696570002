import { createSelector } from 'reselect';
import { IPatientOrder } from '../../../../generated';
import { getTime } from '../../../../utils';

const parameterSelector = (orders: IPatientOrder[] | null) => {
  return { orders };
};
export const sortedOrderSelector = createSelector(parameterSelector, ({ orders }) =>
  orders ? orders.sort((a, b) => getTime(b.createdDate) - getTime(a.createdDate)) : [],
);
