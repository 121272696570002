import { Button, ButtonProps } from '@chakra-ui/react';

export type DeleteButtonProps = ButtonProps;

export const DeleteButton = (props: DeleteButtonProps) => {
  const { children, ...rest } = props;
  return (
    <Button
      variant="danger"
      borderRadius="8px"
      fontSize="12px"
      transition="all 0.2s ease-out"
      {...rest}
    >
      {children}
    </Button>
  );
};
