import { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import {
  CityTown,
  GenderEnum,
  Language,
  ProvinceState,
  StoreItemTypeEnum,
  Tag,
  AuthClient,
  SearchClient,
  ConsultProviderSearchProviderResult,
  ConsultProviderSearchOrganizationResult,
  ConsultProviderSearchStoreResult,
  ConsultProviderSearchResults,
  ConsultProviderSearchDetails,
  ConsultProviderSearchBrowseEnum,
} from '../../../generated';
import { RECORDS_TO_TAKE } from '../../../constants';

import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../../services/keys';
import { getTagsIDs } from '../../../routes/heal/patient/BookAppointment/components/BookConsultProvider/ProviderSearchProvider/hooks/useConsultSearchInfinity/useGetSearchDetails';
import { leaveArrayOnlyWithKeys } from '../../../utils/leaveArrayOnlyWithKeys';

type CategoryKeyType =
  | 'providers'
  | 'specialities'
  | 'organizations'
  | 'clinics'
  | 'labs'
  | 'pharmacies';

export type UsePublicConsultSearchProps = {
  recordsToTake?: number;
  storeItemTypeEnum?: StoreItemTypeEnum;
  countryId?: string;
  careTypeTags?: Tag[];
  categoryToBrowseEnum?: ConsultProviderSearchBrowseEnum;
  startDate?: Date;
  endDate?: Date;
  currentLongitude?: number;
  currentLatitude?: number;
  browseStoreId?: string;
  browseOrganizationID?: string;
  homeCareServiceTags?: Tag[];
  searchText?: string;
  publicFacingPagesOnly?: boolean;
  categoryKey?: CategoryKeyType | 'all';
  language?: Language;
  gender?: GenderEnum;
  provinceState?: ProvinceState;
  cityTown?: CityTown;
  specialtyTag?: Tag;
  consultSymptoms?: Tag[];
};

type CategoryListType =
  | ConsultProviderSearchProviderResult
  | ConsultProviderSearchOrganizationResult
  | ConsultProviderSearchStoreResult;

type AllCategoryObjectType = {
  [key in CategoryKeyType]?: CategoryListType[];
};

const categories = ['providers', 'specialities', 'organizations', 'clinics', 'labs', 'pharmacies'];

const getObjectKeyWithHighestTotalCount = (obj: ConsultProviderSearchResults) => {
  const keys = Object.keys(obj);

  const filteredKeys = leaveArrayOnlyWithKeys(keys, categories);

  const highestCount = Math.max(...filteredKeys.map((key) => obj[key].totalCount));

  return filteredKeys.find((key) => obj[key].totalCount === highestCount);
};

const getPageData = (categoryKey: CategoryKeyType | 'all', page: ConsultProviderSearchResults) => {
  if (categoryKey === 'all') {
    const foundKey = getObjectKeyWithHighestTotalCount(page);

    if (foundKey) {
      return page[foundKey];
    }
  }

  return page[categoryKey];
};

const allCategoryListHasData = (allCategories: AllCategoryObjectType) => {
  const keys = Object.keys(allCategories);

  return keys.some((key) => allCategories[key].length > 0);
};

const removeDuplicateObjectsFromArray = (list: CategoryListType[]) => {
  const uniqueList = list.filter((item, index) => {
    return list.indexOf(item) === index;
  });

  return uniqueList;
};

const removeDuplicateObjectsInCategories = (allCategories: AllCategoryObjectType) => {
  const keys = Object.keys(allCategories);

  const tempCategories: AllCategoryObjectType = {};

  keys.forEach((key) => {
    const list = allCategories[key] as CategoryListType[];

    tempCategories[key] = removeDuplicateObjectsFromArray(list);
  });

  return tempCategories;
};

const usePublicConsultSearch = ({
  recordsToTake: recordsToTakeCount = RECORDS_TO_TAKE,
  categoryToBrowseEnum,
  countryId,
  storeItemTypeEnum,
  careTypeTags,
  categoryKey = 'providers',
  startDate,
  endDate,
  currentLongitude,
  currentLatitude,
  browseStoreId,
  browseOrganizationID,
  homeCareServiceTags,
  searchText,
  publicFacingPagesOnly,
  language,
  gender,
  provinceState,
  cityTown,
  specialtyTag,
  consultSymptoms,
}: UsePublicConsultSearchProps) => {
  const [categoryList, setCategoryList] = useState<CategoryListType[]>([]);
  const [allCategories, setAllCategoryList] = useState<AllCategoryObjectType>({});

  const fetchProviders = async ({ recordsToSkip }) => {
    const searchDetails = new ConsultProviderSearchDetails();
    searchDetails.countryID = countryId;
    searchDetails.storeItemTypeEnum = storeItemTypeEnum;
    searchDetails.consultProviderSearchBrowseEnum = categoryToBrowseEnum;
    searchDetails.careTypeTagsIDs = getTagsIDs(careTypeTags);
    searchDetails.appointmentStartDate = startDate;
    searchDetails.appointmentEndDate = endDate;
    searchDetails.currentLongitude = currentLongitude;
    searchDetails.currentLatitude = currentLatitude;
    searchDetails.browseStoreID = browseStoreId;
    searchDetails.browseOrganizationID = browseOrganizationID;
    searchDetails.homeCareServiceTagIDs = getTagsIDs(homeCareServiceTags);
    searchDetails.recordsToSkip = recordsToSkip;
    searchDetails.recordsToTake = recordsToTakeCount;
    searchDetails.searchText = searchText;
    searchDetails.publicFacingPagesOnly = publicFacingPagesOnly;
    searchDetails.spokenLanguageTagID = language?.languageID;
    searchDetails.genderEnum = gender;
    searchDetails.provinceStateID = provinceState?.provinceStateID;
    searchDetails.cityTownID = cityTown?.cityTownID;
    searchDetails.specialityTagIDs = specialtyTag ? [specialtyTag.tagID] : undefined;
    searchDetails.symptomTagIDs = getTagsIDs(consultSymptoms);

    const client = new SearchClient(new AuthClient());

    const result = await httpRequest(() => client.consultSearch(searchDetails));

    return result;
  };

  const {
    isLoading: isLoadingSearchResults,
    data: searchResults,
    error: searchResultsError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<ConsultProviderSearchResults, string>(
    [
      QueryKeys.ConsultBrowseCategory,
      {
        recordsToTakeCount,
        countryId,
        careTypeTags,
        storeItemTypeEnum,
        categoryToBrowseEnum,
        categoryKey,
        startDate,
        endDate,
        currentLongitude,
        currentLatitude,
        browseStoreId,
        browseOrganizationID,
        homeCareServiceTags,
        searchText,
        publicFacingPagesOnly,
        language,
        gender,
        provinceState,
        cityTown,
        specialtyTag,
        consultSymptoms,
      },
    ],
    async ({ pageParam }) => fetchProviders({ recordsToSkip: pageParam }),
    {
      enabled: !!(countryId && !publicFacingPagesOnly
        ? storeItemTypeEnum && categoryKey === 'providers'
          ? startDate && endDate
          : true
        : true),

      getNextPageParam: (lastPage) => {
        const {
          recordsToSkip = 0,
          recordsToTake = 0,
          totalCount = 0,
        } = getPageData(categoryKey, lastPage) || {};
        return recordsToSkip + recordsToTake < totalCount
          ? recordsToSkip + recordsToTakeCount
          : undefined;
      },

      getPreviousPageParam: (firstPage) => {
        const {
          recordsToSkip = 0,
          recordsToTake = 0,
          totalCount = 0,
        } = getPageData(categoryKey, firstPage) || {};
        return recordsToSkip + recordsToTake < totalCount
          ? recordsToSkip - recordsToTakeCount
          : undefined;
      },
    },
  );

  useEffect(() => {
    // Store category with categoryKey in an array
    const tempCategoryList: CategoryListType[] = [];

    // Store all categories in one object
    const tempAllCategoryList: AllCategoryObjectType = {};

    searchResults?.pages.forEach((page) => {
      const keys = Object.keys(page);

      const filteredKeys = leaveArrayOnlyWithKeys(keys, categories);

      filteredKeys.forEach((key) => {
        if (tempAllCategoryList[key]) {
          tempAllCategoryList[key].push(...page[key].items);
        } else {
          tempAllCategoryList[key] = page[key].items;
        }
      });

      return (categoryKey ? page?.[categoryKey] : undefined)?.items?.forEach(
        (item: CategoryListType) => {
          tempCategoryList.push(item);
        },
      );
    });

    setCategoryList(removeDuplicateObjectsFromArray(tempCategoryList));
    setAllCategoryList(removeDuplicateObjectsInCategories(tempAllCategoryList));
  }, [searchResults, categoryKey]);

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoadingSearchResults: isLoadingSearchResults || !categoryList,
    categoryError: searchResultsError,
    category: categoryList,
    categoryExist: categoryList?.length !== 0,
    searchResults,
    allCategories,
    allCategoriesExists: allCategoryListHasData(allCategories),
  };
};

export { usePublicConsultSearch };
