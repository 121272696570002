import { useCallback, useEffect, useState } from 'react';
import {
  AuthClient,
  DurationTypeEnum,
  Medication,
  MedicationsClient,
  PHRMedication,
} from '../../../../../generated';
import { httpRequest } from '../../../../../utils';
import {
  buildDuration,
  buildForm,
  buildFrequency,
  buildRefills,
  buildRoute,
} from './instructionUtils';

export enum FrequencyEnum {
  QD = 'QD',
  BID = 'BID',
  TID = 'TID',
  QID = 'QID',
  QHS = 'QHS',
  // FIVEX = '5X',
  Q4H = 'Q4H',
  Q6H = 'Q6H',
  // QOD = 'QOD',
}

export enum QuantityPerDoseEnum {
  HALF = '0.5',
  ONE = '1',
  TWO = '2',
  THREE = '3',
}

export enum FormEnum {
  Tabs = 'tablet',
  Caps = 'capsule',
  Drops = 'drop',
}

export enum RouteEnum {
  PO = 'PO',
  Top = 'Top',
  Otic = 'Otic',
  Oph = 'Oph',
  PR = 'PR',
  PV = 'PV',
  SC = 'SC',
}

export enum DurationCountEnum {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  FIVE = '5',
  SIX = '6',
  SEVEN = '7',
  TEN = '10',
  // ELEVEN = '11',
  TWELVE = '12',
}

export enum RefillsEnum {
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '6',
  TWELVE = '12',
}

export interface IAddNewMedicationForm {
  name: string;
  form?: string;
  otherForm?: string;
  quantity?: string;
  otherQuantity?: string;
  frequency?: FrequencyEnum | string;
  otherFrequency?: string;
  quantityPerDose?: string;
  otherQuantityPerDose?: string;
  methodOfIntake?: string;
  otherMethodOfIntake?: string;
  startDate: Date;
  endDate?: Date;
  notes?: string;
  refills?: string;
  otherRefills?: string;
  durationCount?: string;
  otherDurationCount?: string;
  durationType?: DurationTypeEnum;
  prn?: boolean;
  prnReason?: string;
  packQuantity?: string | number;
}

interface IProps {
  onSubmit: (baseMedication: Medication | null, medication: PHRMedication) => Promise<void>;
  selectedMedication: PHRMedication | null;
  countryID: string;
  storeID?: string;
  addressBookID?: string;
  onCancel: () => void;
  handleUpdateMedicationList: (medication: Medication[]) => void;
}

const useAddNewMedication = ({
  onSubmit,
  selectedMedication,
  countryID,
  storeID,
  addressBookID,
  onCancel,
  handleUpdateMedicationList,
}: IProps) => {
  const [medicationSearched, setMedicationSearched] = useState<Medication | null>(null);
  const [listOfMedications, setListOfMedications] = useState<Medication[]>([]);
  const [initialValues, setInitialValues] = useState<IAddNewMedicationForm>();
  const [initialized, setInitialized] = useState<boolean>(false);
  const [isLoadingMedications, setIsLoadingMedications] = useState<boolean>(false);
  const [medicationsError, setMedicationsError] = useState<string>('');

  const onSelectSearchedMedication = (medication: Medication) => {
    setMedicationSearched(medication);
  };

  const clear = useCallback(() => {
    setMedicationSearched(null);
    setInitialValues(undefined);
  }, []);

  useEffect(() => {
    const loadData = async (countryID: string) => {
      setIsLoadingMedications(true);
      setMedicationsError('');
      try {
        const medicationsClient = new MedicationsClient(new AuthClient());
        const result = await httpRequest(() =>
          medicationsClient.byCountryID(countryID, storeID, addressBookID),
        );
        setListOfMedications(result);
        handleUpdateMedicationList(result);
      } catch (err) {
        setMedicationsError(err as string);
      }
      setIsLoadingMedications(false);
    };
    loadData(countryID);
  }, [countryID, storeID, handleUpdateMedicationList, addressBookID]);

  // Find Selected Medication in list of Medication
  useEffect(() => {
    if (listOfMedications) {
      if (selectedMedication) {
        const medication =
          listOfMedications.find((x) => x.medicationID === selectedMedication.medicationID) || null;

        setMedicationSearched(medication);
      }
      setInitialized(true);
    }
  }, [listOfMedications, selectedMedication]);

  useEffect(() => {
    if (initialized && (medicationSearched || selectedMedication)) {
      const initialValues: IAddNewMedicationForm = {
        name: selectedMedication?.name ? selectedMedication.name : medicationSearched?.name || '',
        frequency: (Object.values(FrequencyEnum) as string[]).includes(
          selectedMedication?.frequency || '',
        )
          ? selectedMedication?.frequency
          : '',
        otherFrequency: !(Object.values(FrequencyEnum) as string[]).includes(
          selectedMedication?.frequency || '',
        )
          ? selectedMedication?.frequency || ''
          : '',
        quantityPerDose: (Object.values(QuantityPerDoseEnum) as string[]).includes(
          selectedMedication?.quantityPerDose || '',
        )
          ? selectedMedication?.quantityPerDose
          : '',
        otherQuantityPerDose: !(Object.values(QuantityPerDoseEnum) as string[]).includes(
          selectedMedication?.quantityPerDose || '',
        )
          ? selectedMedication?.quantityPerDose || ''
          : '',
        form: (Object.values(FormEnum) as string[]).includes(selectedMedication?.form || '')
          ? selectedMedication?.form
          : '',
        otherForm: !(Object.values(FormEnum) as string[]).includes(selectedMedication?.form || '')
          ? selectedMedication?.form || ''
          : '',
        methodOfIntake: (Object.values(RouteEnum) as string[]).includes(
          selectedMedication?.methodOfIntake || '',
        )
          ? selectedMedication?.methodOfIntake
          : '',
        otherMethodOfIntake: !(Object.values(RouteEnum) as string[]).includes(
          selectedMedication?.methodOfIntake || '',
        )
          ? selectedMedication?.methodOfIntake || ''
          : '',
        quantity: selectedMedication?.quantity ?? '',
        startDate: selectedMedication?.startDate ?? new Date(),
        endDate: selectedMedication?.endDate ?? undefined,
        notes: selectedMedication?.notes ?? '',
        refills: (Object.values(RefillsEnum) as string[]).includes(
          selectedMedication?.refills ? '' + selectedMedication.refills : '',
        )
          ? '' + selectedMedication?.refills
          : '',
        otherRefills: !(Object.values(RefillsEnum) as string[]).includes(
          selectedMedication?.refills ? '' + selectedMedication.refills : '',
        )
          ? selectedMedication?.refills
            ? '' + selectedMedication?.refills
            : ''
          : '',
        durationCount: (Object.values(DurationCountEnum) as string[]).includes(
          selectedMedication?.durationCount ? '' + selectedMedication.durationCount : '',
        )
          ? '' + selectedMedication?.durationCount
          : '',
        otherDurationCount: !(Object.values(DurationCountEnum) as string[]).includes(
          selectedMedication?.durationCount ? '' + selectedMedication.durationCount : '',
        )
          ? selectedMedication?.durationCount
            ? '' + selectedMedication?.durationCount
            : ''
          : '',
        durationType: selectedMedication?.durationTypeEnum ?? DurationTypeEnum.Days,
        prn: selectedMedication?.prn || false,
        prnReason: selectedMedication?.prnReason || '',
        packQuantity: selectedMedication?.packageQuantity ?? '',
      };

      setInitialValues(initialValues);
    }
  }, [initialized, selectedMedication, medicationSearched]);

  const submit = async (values: IAddNewMedicationForm) => {
    const medication = new PHRMedication();
    medication.init(selectedMedication);
    medication.name = values.name;
    medication.form = values.form || values.otherForm;
    medication.quantity = values.quantity || values.otherQuantity;
    medication.frequency = values.frequency || values.otherFrequency;
    medication.quantityPerDose = values.quantityPerDose || values.otherQuantityPerDose;
    medication.methodOfIntake = values.methodOfIntake || values.otherMethodOfIntake;
    medication.durationTypeEnum = values.durationType;
    medication.durationCount = values.durationCount
      ? parseInt(values.durationCount)
      : values.otherDurationCount
      ? parseInt(values.otherDurationCount)
      : 0;
    medication.startDate = values.startDate;
    medication.endDate = values.endDate;
    medication.notes = values.notes;
    medication.refills = values.refills
      ? parseInt(values.refills)
      : values.otherRefills
      ? parseInt(values.otherRefills)
      : 0;
    medication.prn = values.prn;
    medication.prnReason = values.prn ? values.prnReason : '';
    medication.instructions = `${[
      buildForm(medication.quantityPerDose, medication.form),
      buildRoute(medication.methodOfIntake),
      buildFrequency(medication.frequency),
      buildDuration(medication.durationCount, medication.durationTypeEnum),
    ].join(' ')}.`;
    medication.dispensingInstructions = `${buildRefills(medication.refills)}. ${buildForm(
      medication.quantity,
      medication.form,
    )} per prescription.`;
    medication.medicationID = medicationSearched?.medicationID;
    medication.packageQuantity = values.packQuantity ? Number(values.packQuantity) : undefined;

    onSubmit(medicationSearched, medication);
    clear();
    onCancel();
  };

  return {
    submit,
    initialValues,
    listOfMedications,
    medicationSearched,
    setMedicationSearched,
    clear,
    isLoadingMedications,
    medicationsError,
    onSelectSearchedMedication,
  };
};

export default useAddNewMedication;
