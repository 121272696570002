import { HStack, StackProps, Text, TextProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

type TagProps = {
  text: string;
  iconComponent?: ReactNode;
  textProps?: TextProps;
  containerProps?: StackProps;
};

const Tag: FC<TagProps> = (props) => {
  return (
    <HStack
      bgColor="rgb(255, 194, 0)"
      borderRadius="100px"
      py="1"
      px="2"
      width="max-content"
      {...props.containerProps}
    >
      {props.iconComponent}
      <Text fontSize="14px" color="rgb(0, 3, 16)" {...props.textProps}>
        {props.text}
      </Text>
    </HStack>
  );
};

export { Tag };
