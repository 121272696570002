import { ActionType, createAsyncAction } from 'typesafe-actions';
import { AuthClient, DiscussionItemsClient, DiscussionItem } from '../../generated';
import { httpRequest } from '../../utils';
import { OHThunkResult } from '../root-reducers';

export const getDiscussionItemsActions = createAsyncAction(
  ['GET_DISCUSSION_ITEMS_REQUEST', () => {}],
  ['GET_DISCUSSION_ITEMS_SUCCESS', (res: DiscussionItem) => res],
  ['GET_DISCUSSION_ITEMS_ERROR', (err: string) => err],
)();

export function getDiscussionItems(
  discussionItemID: string,
  handleFailedGet?: () => void,
): OHThunkResult<Promise<DiscussionItem>> {
  return async (dispatch) => {
    return new Promise<DiscussionItem>(async (resolve) => {
      dispatch(getDiscussionItemsActions.request());

      try {
        const discussionItemsClient = new DiscussionItemsClient(new AuthClient());
        const result = await httpRequest(() =>
          discussionItemsClient.discussionItemsGet(discussionItemID),
        );
        dispatch(getDiscussionItemsActions.success(result));
        resolve(result);
      } catch (err) {
        dispatch(getDiscussionItemsActions.failure(err as any));
        if (handleFailedGet) handleFailedGet();
      }
    });
  };
}

export type GetDiscussionItemsActionTypes = ActionType<typeof getDiscussionItemsActions>;
