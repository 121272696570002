import { Image, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import uploadFolder from '../assets/images/uploadFolder.svg';
import { SystemSettingsEnum } from '../constants';
import useTranslationComponent from '../hooks/useTranslationComponent';
import { AppState } from '../store/root-reducers';
import { getSystemSettingByName } from '../store/systemSettings/systemSettingsCreators';
import { FormError } from './forms';
import { CenteredLoadingIndicator } from './LoadingIndicator';

const Dropzone = styled.div`
  border: 2px dashed #d4d9e2;
  border-radius: 8px;
  padding: 1rem 0.5rem;
`;

const NoFilesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const AcceptedFiles = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const AcceptedFile = styled.li`
  margin-bottom: 10px;
  width: 50%;
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: space-between;
`;

const LinkText = styled.span`
  color: ${(props) => props.theme.Colors.Primary};
  font-weight: 500;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

const FileWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  position: relative;
`;

const CheckmarkIcon = styled(FontAwesomeIcon)`
  position: absolute;
  bottom: -4px;
  right: -4px;
  border: 1px solid white;
  border-radius: 50%;
  background-color: #7ab986;
  color: white;
  padding: 2px;
  font-size: 0.8em;
`;

const FileName = styled.p`
  flex-grow: 1;
  padding-left: 8px;
`;

const RemoveIcon = styled(FontAwesomeIcon)`
  font-size: 1.25em;
  :hover {
    cursor: pointer;
  }
`;

interface IProps {
  files: File[];
  setFiles: (files: File[]) => void;
  multiple?: boolean;
}

interface IWrapperProps {
  files: File[];
  setFiles: (files: File[]) => void;
  allowedTypes: string;
  multiple: boolean;
}

const DropzoneComponent = ({ files, setFiles, multiple = true }: IProps) => {
  const dispatch = useDispatch();
  const systemSettings = useSelector((state: AppState) => state.systemSettingsState.systemSettings);
  const [hasSettings, setHasSettings] = useState<boolean>(false);
  const [allowedTypes, setAllowedTypes] = useState<string>('');

  useEffect(() => {
    if (systemSettings.findIndex((x) => x.name === SystemSettingsEnum.AllowedMimeTypes) === -1) {
      dispatch(getSystemSettingByName(SystemSettingsEnum.AllowedMimeTypes));
    } else {
      const allowedMimeTypes = systemSettings.find(
        (x) => x.name === SystemSettingsEnum.AllowedMimeTypes,
      );
      setAllowedTypes(allowedMimeTypes?.value.split('|').join(', ') ?? '');
      setHasSettings(true);
    }
  }, [dispatch, systemSettings]);

  if (!hasSettings) {
    return <CenteredLoadingIndicator />;
  } else {
    return (
      <DropzoneWrapper
        multiple={multiple}
        files={files}
        setFiles={setFiles}
        allowedTypes={allowedTypes}
      />
    );
  }
};

const DropzoneWrapper = ({ files, setFiles, allowedTypes, multiple }: IWrapperProps) => {
  const { t } = useTranslationComponent();
  const onDrop = (acceptedFiles) => {
    const newFiles = [...files, ...acceptedFiles];
    // Only set unique files
    const uniqueNewFiles: File[] = [];
    const map = new Map();
    for (const file of newFiles) {
      map.set(file.path, true);
      uniqueNewFiles.push(file);
    }

    setFiles(uniqueNewFiles);
  };

  const { getRootProps, getInputProps, isDragReject, open } = useDropzone({
    onDrop,
    minSize: 0,
    multiple: multiple,
    noClick: true,
    noKeyboard: true,
    accept: allowedTypes,
  });

  const removeFile = (file) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const filesComponent = files.map((acceptedFile, index) => (
    <AcceptedFile key={index}>
      <FileWrapper>
        {acceptedFile.type === 'image/png' || acceptedFile.type === 'image/jpeg' ? (
          <FontAwesomeIcon icon={['far', 'image']} size="lg" />
        ) : (
          <FontAwesomeIcon icon={['far', 'file-alt']} size="lg" />
        )}
        <CheckmarkIcon icon={['far', 'check']} size="lg" />
      </FileWrapper>
      <FileName>{acceptedFile.name}</FileName>
      <RemoveIcon onClick={removeFile(acceptedFile)} icon={['far', 'trash-alt']} size="lg" />
    </AcceptedFile>
  ));

  return (
    <Dropzone {...getRootProps()}>
      <input {...getInputProps()} />
      {files.length === 0 ? (
        <NoFilesContainer>
          <Image src={uploadFolder} mb="5" />
          <Text fontSize="sm" textAlign="center" mt="0" mb="3">
            {t('Drag and Drop your file into this box or click')}{' '}
            <LinkText onClick={open}>{t('HERE')}</LinkText> {t('to upload')}
          </Text>
          <Text fontSize="xs" color="#6f737ca6">
            {t('Supports PDF, JPG, PNG and DOC')}
          </Text>
        </NoFilesContainer>
      ) : (
        <AcceptedFiles>{filesComponent}</AcceptedFiles>
      )}

      {isDragReject && <FormError error={t('File type is not accepted.')} />}
    </Dropzone>
  );
};

export default DropzoneComponent;
