import { useMutation } from 'react-query';
import { AuthClient, PatientOrdersClient } from '../../../generated';
import { httpRequest } from '../../../utils';

export const usePatientOrdersDelete = () => {
  const patientOrdersDelete = useMutation<void, string, string, string>(
    async (patientOrderID: string) =>
      httpRequest(() =>
        new PatientOrdersClient(new AuthClient()).patientOrdersDelete(patientOrderID),
      ),
  );

  return patientOrdersDelete;
};
