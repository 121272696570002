import { ActionType, createAsyncAction } from 'typesafe-actions';
import { AuthClient, UserDetailsClient, UserDetailSetting } from '../../generated';
import { httpRequest } from '../../utils';
import { serializeUserDetailSettings } from '../../utils/parseUserDetailSettings';
import { OHThunkResult } from '../root-reducers';

export const updateUserDetailSettingsActions = createAsyncAction(
  ['UPDATE_USER_DETAIL_SETTINGS_REQUEST', () => {}],
  ['UPDATE_USER_DETAIL_SETTINGS_SUCCESS', (res: UserDetailSetting[]) => res],
  ['UPDATE_USER_DETAIL_SETTINGS_ERROR', (err: string) => err],
)();

export function updateUserDetailSettings(
  newUserDetailSettings: UserDetailSetting[],
): OHThunkResult<Promise<Array<UserDetailSetting>>> {
  return async (dispatch) => {
    return new Promise<Array<UserDetailSetting>>(async (resolve) => {
      dispatch(updateUserDetailSettingsActions.request());
      newUserDetailSettings = newUserDetailSettings.map((x) => {
        x.value = serializeUserDetailSettings(x.userDetailSettingEnum, x.value);
        return x;
      });
      try {
        const userDetailsClient = new UserDetailsClient(new AuthClient());

        await httpRequest(() => userDetailsClient.userDetailSettingsPut(newUserDetailSettings));

        dispatch(updateUserDetailSettingsActions.success(newUserDetailSettings));
        resolve(newUserDetailSettings);
      } catch (err) {
        dispatch(updateUserDetailSettingsActions.failure(err as any));
      }
    });
  };
}

export type UpdateUserDetailSettingsActionTypes = ActionType<
  typeof updateUserDetailSettingsActions
>;
