// When using yes / no for radio button values, this will convert to boolean

export const radioToBoolean = (val: 'yes' | 'no' | undefined) =>
  val === 'yes' ? true : val === 'no' ? false : undefined;

export const boolenToRadio = (val?: boolean | null) => {
  if (typeof val === 'undefined' || val === null) {
    return undefined;
  } else if (val === true) {
    return 'yes';
  } else {
    return 'no';
  }
};
