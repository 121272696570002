import { Box, StackProps, Text, TextProps } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { OutpostTheme } from '../../themes/outpost';

export type ExpandableTextProps = TextProps & {
  containerProps?: StackProps;
  showMoreProps?: TextProps;
  initialNoOfLines?: number;
};

const ExpandableText: FC<ExpandableTextProps> = (props) => {
  const { showMoreProps, containerProps, initialNoOfLines = 2, ...rest } = props;

  const [showMore, setShowMore] = useState(false);

  const onToggleShowMore = () => {
    setShowMore((previous) => !previous);
  };

  return (
    <Box
      flex="1"
      spacing={{ base: '0', md: '2' }}
      // alignItems={{ base: 'flex-start', md: 'center' }}
      display="flex"
      flexDirection="column"
      role="button"
      onClick={(event) => {
        event.stopPropagation();
        onToggleShowMore();
      }}
      {...containerProps}
    >
      <Text
        fontSize={{ base: OutpostTheme.FontSizes.small, sm: OutpostTheme.FontSizes.body }}
        noOfLines={showMore ? undefined : initialNoOfLines}
        {...rest}
      />
      <Text
        fontSize={{ base: OutpostTheme.FontSizes.small, sm: OutpostTheme.FontSizes.body }}
        variant="ghost"
        size="sm"
        color="purple.600"
        fontWeight="600"
        {...showMoreProps}
      >
        {showMore ? 'view less-' : 'view more+'}
      </Text>
    </Box>
  );
};

export { ExpandableText };
