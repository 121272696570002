import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { IAddNewMedicationForm } from '../../hooks/useAddNewMedication/useAddNewMedication';

export const OtherHandler = () => {
  const { values, setFieldValue } = useFormikContext<IAddNewMedicationForm>();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  // Frequency
  useEffect(() => {
    if (values.frequency && values.otherFrequency) {
      setFieldValue('otherFrequency', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.frequency, setFieldValue]);

  useEffect(() => {
    if (values.otherFrequency && values.frequency) {
      setFieldValue('frequency', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.otherFrequency, setFieldValue]);

  // Quantity Per Dose
  useEffect(() => {
    if (values.quantityPerDose && values.otherQuantityPerDose) {
      setFieldValue('otherQuantityPerDose', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.quantityPerDose, setFieldValue]);

  useEffect(() => {
    if (values.otherQuantityPerDose && values.quantityPerDose) {
      setFieldValue('quantityPerDose', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.otherQuantityPerDose, setFieldValue]);

  // Form
  useEffect(() => {
    if (values.form && values.otherForm) {
      setFieldValue('otherForm', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.form, setFieldValue]);

  useEffect(() => {
    if (values.otherForm && values.form) {
      setFieldValue('form', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.otherForm, setFieldValue]);

  // Method of Intake
  useEffect(() => {
    if (values.methodOfIntake && values.otherMethodOfIntake) {
      setFieldValue('otherMethodOfIntake', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.methodOfIntake, setFieldValue]);

  useEffect(() => {
    if (values.otherMethodOfIntake && values.methodOfIntake) {
      setFieldValue('methodOfIntake', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.otherMethodOfIntake, setFieldValue]);

  // Refills
  useEffect(() => {
    if (values.refills && values.otherRefills) {
      setFieldValue('otherRefills', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.refills, setFieldValue]);

  useEffect(() => {
    if (values.otherRefills && values.refills) {
      setFieldValue('refills', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.otherRefills, setFieldValue]);

  // Duration Count
  useEffect(() => {
    if (values.durationCount && values.otherDurationCount) {
      setFieldValue('otherDurationCount', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.durationCount, setFieldValue]);

  useEffect(() => {
    if (values.otherDurationCount && values.durationCount) {
      setFieldValue('durationCount', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.otherDurationCount, setFieldValue]);

  return null;
};
