import { Box, Center, Flex, Stack, VStack, Text } from '@chakra-ui/react';
import { useMemo, FC } from 'react';
import GoogleMapReact from 'google-map-react';

import { HeaderSection } from '../../../../components/Modals';
import { LocationNotificationModal } from '../../../../components/LocationNotificationModal';
import { LocationSearch } from '../../../../components/LocationSearch/LocationSearch';
import { DeleteButton, SecondaryButton } from '../../../../components/Buttons';
import { CenteredLoadingIndicator } from '../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../components/ServerValidationBox';
import InfiniteScroll from '../../../../components/InfiniteScroll/InfiniteScroll';
import { sortStoresByDistance } from '../../../../utils/sortStoresByDistance';
import { getStringAbbreviation } from '../../../../utils/getStringAbbreviation';
import { getDistanceToShow } from '../../../../utils/getDistanceToShow';
import { ConsultProviderSearchStoreResult } from '../../../../generated';

import { UsePublicBookingFlowReturnType } from '../../hooks/usePublicBookingFlow';
import { useInPersonVisit } from '../../hooks/useInPersonVisit';

import { ClinicInfo } from './ClinicInfo';
import { usePartnerSettings } from '../../../../services/partners/usePartnerSettings';
import useTranslationComponent from '../../../../hooks/useTranslationComponent';

type InPersonVisitProps = {
  controller: UsePublicBookingFlowReturnType;
};

type MapMarkerProps = {
  lat: number;
  lng: number;
  name?: string;
};

const clinicHasLocation = (clinic: ConsultProviderSearchStoreResult) =>
  clinic.latitude && clinic.longitude;

const MapMarker: FC<MapMarkerProps> = (props) => (
  <Center
    width="min-content"
    p="0.5"
    borderRadius="100px"
    bgColor="white"
    border="2px solid rgb(255, 43, 79)"
    fontSize="10px"
    // Map does some magic here to make the marker appear on top of the map
    //@ts-ignore
    lat={props.lat}
    lng={props.lng}
  >
    {props.name}
  </Center>
);

const InPersonVisit: FC<InPersonVisitProps> = (props) => {
  const { t } = useTranslationComponent(['clinic']);
  const { partnerBookingSeeProviderTitle, partnerNetworkClinics } = usePartnerSettings();

  const controller = useInPersonVisit(props.controller);

  const sortedClinics = useMemo(
    () => sortStoresByDistance(controller.category),
    [controller.category],
  );

  return (
    <VStack height="100%" alignItems="stretch" spacing="0">
      <HeaderSection
        heading={t(partnerBookingSeeProviderTitle ?? 'How do you want to see your provider?')}
        containerProps={{
          mb: '0',
        }}
        onGoBack={props.controller.handleGoToPreviousBookingData}
      />
      <Flex flex="1" overflowY="auto" flexDirection={{ base: 'column', md: 'row' }}>
        <Box
          width={{ base: '100%', md: '40%' }}
          height={{ base: '60%', md: 'auto' }}
          overflowY="auto"
          paddingX={{ base: '0', md: '3' }}
          paddingY="3"
        >
          <VStack height="100%" alignItems="stretch" spacing="0" overflowY="auto">
            <Box>
              <Box mb="2">
                {controller.position ? (
                  <DeleteButton size="sm" onClick={controller.clearPosition}>
                    {t('Clear my location')}
                  </DeleteButton>
                ) : (
                  <SecondaryButton size="sm" onClick={controller.onGetCurrentLocation}>
                    {t('Use my location')}
                  </SecondaryButton>
                )}
              </Box>
              <LocationSearch onSelectPlace={controller.onSelectPlace} />
            </Box>
            <Box flex="1" overflowY="auto">
              <Stack height="100%" overflowY="auto">
                {controller.isLoadingSearchResults ? (
                  <Box flex="1" overflowY="auto" px="2" width="100%">
                    <CenteredLoadingIndicator />
                  </Box>
                ) : controller.categoryError ? (
                  <ServerValidationBox message={controller.categoryError} />
                ) : controller.categoryExist ? (
                  <Box flex="1" overflowY="auto">
                    <InfiniteScroll
                      hasMoreData={controller.hasNextPage || false}
                      isInitLoading={controller.isLoadingSearchResults}
                      isLoading={controller.isLoadingSearchResults || controller.isFetchingNextPage}
                      isFetchingNextPage={controller.isFetchingNextPage}
                      onBottomHit={controller.fetchNextPage}
                      loadOnMount={false}
                      height="100%"
                    >
                      <>
                        {sortedClinics.map((clinic) => (
                          <Box mb="4" key={clinic.storeID}>
                            <ClinicInfo
                              onSelectClinic={() => controller.handleNextStep(clinic)}
                              clinic={{
                                name: clinic.name || '',
                                fullAddress: clinic.fullAddress || '',
                                description: clinic.description || '',
                                distance: getDistanceToShow(
                                  clinic.distance,
                                  props.controller.bookingData.patientCountry?.measurementTypeEnum,
                                ),
                              }}
                              onGetPosition={
                                clinicHasLocation(clinic)
                                  ? () =>
                                      controller.onSetClinicPosition(
                                        clinic.latitude,
                                        clinic.longitude,
                                      )
                                  : undefined
                              }
                            />
                          </Box>
                        ))}
                      </>
                    </InfiniteScroll>
                  </Box>
                ) : (
                  <Box flex="1" overflowY="auto" px="2">
                    <Text textAlign="center" color="rgb(89, 101, 120)">
                      {t(`No ${partnerNetworkClinics ?? 'Clinics'} found`)}
                    </Text>
                  </Box>
                )}
              </Stack>
            </Box>
          </VStack>
        </Box>
        <Box flex="1" paddingX={{ base: '0', md: '3' }} paddingY="3">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
              libraries: ['places'],
            }}
            center={{
              lat: controller.latLng.lat,
              lng: controller.latLng.lng,
            }}
            defaultZoom={11}
          >
            {/* Your current location */}
            {controller.position && (
              <MapMarker
                lat={controller.position.coords.latitude}
                lng={controller.position.coords.longitude}
                name={t('You')}
              />
            )}

            {/* Your searched/selected location */}
            {controller.selectedPosition?.geometry?.location && (
              <MapMarker
                lat={controller.selectedPosition.geometry?.location?.lat()}
                lng={controller.selectedPosition.geometry?.location?.lng()}
                name={t('You')}
              />
            )}

            {/* Clinics marker */}
            {sortedClinics.map(
              (clinic) =>
                clinic.latitude &&
                clinic.longitude && (
                  <MapMarker
                    key={clinic.storeID}
                    lat={clinic.latitude}
                    lng={clinic.longitude}
                    name={getStringAbbreviation(clinic.name) || 'C'}
                  />
                ),
            )}
          </GoogleMapReact>
        </Box>
      </Flex>
      <LocationNotificationModal
        isNotificationModalOpen={controller.isNotificationModalOpen}
        positionError={controller.positionError}
        onDismissNotificationModal={controller.onDismissNotificationModal}
        onDismissNotificationModalError={controller.onDismissNotificationModalError}
        position={controller.position}
      />
    </VStack>
  );
};

export { InPersonVisit };
