import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AuthClient,
  ConsultsClient,
  SubscriptionConsultDetail,
} from '../../../../../../../generated';
import { AppState } from '../../../../../../../store/root-reducers';
import { httpRequest } from '../../../../../../../utils';

const useSubscriptions = () => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionConsultDetail[]>([]);

  const loadSubscriptionDetails = useCallback(async (userDetailID: string) => {
    try {
      setLoading(true);
      setError('');
      const result = await httpRequest(() =>
        new ConsultsClient(new AuthClient()).subscriptionConsultDetails(userDetailID),
      );
      setSubscriptionDetails(result);
    } catch (err) {
      setError(err as any);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (currentUser) {
      loadSubscriptionDetails(currentUser.userDetailID);
    }
  }, [currentUser, loadSubscriptionDetails]);

  return { isLoading, error, subscriptionDetails, loadSubscriptionDetails };
};

export default useSubscriptions;
