import { Box, Link, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { getStoreGoogleMapUrl } from '../../../../utils/getStoreGoogleMapUrl';

type StoreDetailedInfoProps = {
  address?: string;
  information?: string;
  informationMaxHeight?: string;
  longitude?: string | number;
  latitude?: string | number;
};

const StoreDetailedInfo: FC<StoreDetailedInfoProps> = (props) => (
  <Box>
    {props.address && (
      <Box mb="6">
        <Text fontWeight="bold" mb="3" fontSize="14px" px="2">
          ADDRESS
        </Text>
        <Box maxH="250px" overflowY="auto" px="2">
          <Text fontSize="14px" dangerouslySetInnerHTML={{ __html: props.address }} />
        </Box>
        {getStoreGoogleMapUrl(props.address, props.longitude, props.latitude) && (
          <Link
            fontSize="14px"
            color="rgb(0, 3, 16)"
            textDecoration="none"
            textAlign="center"
            px="2"
            fontWeight="bold"
            _hover={{
              textDecoration: 'underline',
            }}
            _active={{
              textDecoration: 'underline',
            }}
            _focus={{
              textDecoration: 'underline',
            }}
            isExternal
            href={getStoreGoogleMapUrl(props.address, props.longitude, props.latitude)}
          >
            See Map
          </Link>
        )}
      </Box>
    )}
    {props.information && (
      <Box mb="6">
        <Text fontWeight="bold" mb="3" fontSize="14px" px="2">
          INFORMATION
        </Text>
        <Box maxH={props.informationMaxHeight ?? '250px'} overflowY="auto" px="2">
          <Text fontSize="14px" dangerouslySetInnerHTML={{ __html: props.information }} />
        </Box>
      </Box>
    )}
  </Box>
);

export { StoreDetailedInfo };
