import { Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { use100vh } from 'react-div-100vh';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, Redirect, useLocation } from 'react-router-dom';
import { CenteredLoadingIndicator } from '../../../../components/LoadingIndicator';
import ToCModal from '../../../../components/ToCModal';
import { ProviderDashboardTour } from '../../../../components/tours/ProviderDashboard/ProviderDashboardTour';
import {
  COOKIE_NAME,
  DashboardRoutes,
  DelegateRoutes,
  HealRoutes,
  SignInRoutes,
} from '../../../../constants';
import { RolesEnum } from '../../../../generated';
import useTranslationComponent from '../../../../hooks/useTranslationComponent';
import i18next from '../../../../i18n';
import { AppState } from '../../../../store/root-reducers';
import { isInRole } from '../../../../utils/isInRole';
import { UnclaimedReward } from '../../../Dashboard/PatientDashboard/components/UnclaimedReward/UnclaimedReward';
import useGetAuthenticatedLayout from '../../hooks/useGetAuthenticatedLayout/useGetAuthenticatedLayout';
import { BirthDateModal } from './BirthDateModal';
import { PrerequisiteStepEnum, usePrerequisites } from './usePrerequisites';
import { IAuthenticateUserSignIn } from '../../../../types';
import { signIn } from '../../../../store/root-creator';
import { setCookie } from '../../../../utils/cookieHelper';

interface IProps {
  children?: ReactNode;
}

export interface LocationState {
  fromLogin?: boolean;
}

export const AuthenticatedPrerequisites = ({ children }: IProps) => {
  const { t } = useTranslationComponent();

  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const patient = useSelector((state: AppState) => state.patientState.patient);
  const provider = useSelector((state: AppState) => state.providerState.provider);
  const location = useLocation<LocationState>();
  const dispatch = useDispatch();
  const isOnDashboard = !!matchPath(location.pathname, {
    path: [DashboardRoutes.Dashboard],
    exact: true,
  });
  const isOnBookConsult = !!matchPath(location.pathname, {
    path: [HealRoutes.BookConsult],
  }); // birthdate will be set as part of the consult
  const {
    isLoading,
    error,
    step,
    onCancel,
    agreeWithProviderToC,
    agreeWithPatientToC,
    setPatientBirthDate,
  } = usePrerequisites({ currentUser, patient, provider });

  useGetAuthenticatedLayout({ currentUser, pathname: location.pathname });
  const height = use100vh();

  const isMobile = currentUser?.isInMobileView;

  if (currentUser == null) {
    try {
      const fromSearchParam = new URLSearchParams(location.search?.split('?')[1]);
      const authenticateSignIn: IAuthenticateUserSignIn = {};
      if (fromSearchParam.get('t')) {
        authenticateSignIn.t = fromSearchParam.get('t') as string;
        setCookie(COOKIE_NAME, authenticateSignIn.t!);
        fromSearchParam.delete('t');
      }
      if (fromSearchParam.get('u')) {
        authenticateSignIn.u = fromSearchParam.get('u') as string;
        fromSearchParam.delete('u');
      }
      location.search = fromSearchParam.toString();

      if (window.location.pathname?.includes('/heal/health-records')) {
        if (authenticateSignIn && authenticateSignIn.t && authenticateSignIn.u) {
          try {
            dispatch(signIn({}, () => '', authenticateSignIn));
          } catch (error) {}
          return <Redirect to={{ pathname: location.pathname }} />;
        }
        return <Redirect to={{ pathname: DelegateRoutes.Redirect }} />;
      }
    } catch (error) {}

    return <Redirect to={{ pathname: SignInRoutes.SignIn, state: { from: location } }} />;
  }

  return (
    <>
      {!i18next.isInitialized ||
      (!provider && !patient && !isInRole(currentUser, RolesEnum.NotApprovedProvider)) ? (
        <Flex justifyContent="center" alignItems="center" minHeight={height || '100vh'}>
          <CenteredLoadingIndicator />
        </Flex>
      ) : (
        <>
          {step === PrerequisiteStepEnum.ProviderToC && !isMobile && (
            <ToCModal
              name="ProviderSignUpTC"
              title={t('Terms and Conditions')}
              onCancel={onCancel}
              cancelText={t('Sign Out')}
              onSubmit={agreeWithProviderToC}
              submitText={t('Agree to Terms and Conditions')}
              isOpen={step === PrerequisiteStepEnum.ProviderToC}
              isLoading={isLoading}
              error={error}
            />
          )}
          {step === PrerequisiteStepEnum.PatientToC && !isMobile && (
            <ToCModal
              name="PatientSignUpTC"
              title={t('Terms and Conditions')}
              onCancel={onCancel}
              cancelText={t('Sign Out')}
              onSubmit={agreeWithPatientToC}
              submitText={t('Agree to Terms and Conditions')}
              isOpen={step === PrerequisiteStepEnum.PatientToC}
              isLoading={isLoading}
              error={error}
            />
          )}
          {step === PrerequisiteStepEnum.PatientBirthDate && !isOnBookConsult && !isMobile && (
            <BirthDateModal
              title={t('Please enter your Birth Date')}
              onCancel={onCancel}
              cancelText={t('Sign Out')}
              onSubmit={setPatientBirthDate}
              submitText={t('Save')}
              isOpen={step === PrerequisiteStepEnum.PatientBirthDate}
              isLoading={isLoading}
              error={error}
            />
          )}
          {step === PrerequisiteStepEnum.Fulfilled && isOnDashboard && (
            <>
              {isInRole(currentUser, RolesEnum.Patient) && <UnclaimedReward />}
              {isInRole(currentUser, RolesEnum.Provider) &&
                !!location.state?.fromLogin &&
                !isInRole(currentUser, RolesEnum.MerchantProvider) && <ProviderDashboardTour />}
            </>
          )}
          {children}
        </>
      )}
    </>
  );
};
