import { Box, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import Modal from '../../../components/Modals/Modal';
import ServerValidationBox from '../../../components/ServerValidationBox';
import { IPHRConsult, PHRConsult, RolesEnum } from '../../../generated';
import { clearPHRPatientErrorAction } from '../../../store/patient/phr/phrActions';
import { IPHRPatientCombined } from '../../../store/patient/phr/phrReducers';
import { phrConsultsSelector } from '../../../store/patient/phr/phrSelectors';
import { deleteForPhrSection, getAllForPhrSection } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { isInRole } from '../../../utils/isInRole';
import { scrollToTop } from '../../../utils/scrollToTop';
import PhrCardSkeletonComponent from '../components/PhrCardSkeleton';
import { ConsultDetailsHeader } from './components/ConsultDetailsHeader';
import ConsultsForm from './ConsultsForm';
import ConsultsList from './ConsultsList';
import { CreateConsultModal } from './CreateConsultModal';

interface IProps {
  patient: IPHRPatientCombined;
  renderHeader: (onCreate?: any, onBack?: () => void, title?: string, showDismiss?: boolean) => any;
  inverse?: boolean;
}

export enum ViewStateEnum {
  Home = 'home',
  Details = 'details',
  Edit = 'edit',
}

const PHRConsultsComponent = ({ patient, renderHeader, inverse }: IProps) => {
  const dispatch = useDispatch();

  const phrConsults = phrConsultsSelector(patient.phr.phrConsults);
  const provider = useSelector((state: AppState) => state.providerState.provider);
  const currentUser = useSelector((state: AppState) => state.currentUserState.data!);
  const { isPhrLoading, error: phrError } = useSelector((state: AppState) => state.phrPatientState);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [currentConsult, setCurrentConsult] = useState<IPHRConsult | null>(null);
  const [isLoading] = useState<boolean>(false);
  const [recordToDelete, setRecordToDelete] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [showCreateConsultModal, setShowCreateConsultModal] = useState<boolean>(false);

  const [activeView, setActiveView] = useState<ViewStateEnum>(ViewStateEnum.Home);

  useEffect(() => {
    if (patient && !showForm) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRConsults, patient.patient.patientID));
    }
    dispatch(clearPHRPatientErrorAction());
    scrollToTop();
    // avoid re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, showForm]);

  const onEdit = (phrConsult: PHRConsult) => {
    setError('');
    setCurrentConsult(phrConsult);
    setActiveView(ViewStateEnum.Edit);
  };

  const onView = (phrConsult: PHRConsult) => {
    setError('');
    setCurrentConsult(phrConsult);
    setActiveView(ViewStateEnum.Details);
  };

  const onDelete = async (phrConsultID: string) => {
    setError('');
    setShowDeleteModal(true);
    setRecordToDelete(phrConsultID);
  };

  const confirmDelete = async () => {
    setShowDeleteModal(false);
    await dispatch(
      deleteForPhrSection(
        ReduxPHRCategoryEnum.PHRConsults,
        recordToDelete,
        patient.patient.patientID,
        false,
      ),
    );
  };

  useEffect(() => {
    if (phrError.includes('edit')) {
      setError(
        'This Note cannot be deleted because there are attached documents, notes or prescriptions.',
      );
    }
  }, [phrError]);

  const onCreate = async (consult: PHRConsult) => {
    setCurrentConsult(consult);
    setShowCreateConsultModal(false);
    setShowForm(true);
  };

  return (
    <Box bg={activeView === ViewStateEnum.Edit ? '#FFFFFF' : '#f4f4f4'} h="100%">
      {activeView === ViewStateEnum.Details ? (
        <ConsultDetailsHeader
          currentConsult={currentConsult}
          onBack={() => setActiveView(ViewStateEnum.Home)}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ) : activeView === ViewStateEnum.Home ? (
        renderHeader(
          isInRole(currentUser, RolesEnum.Provider)
            ? () => setShowCreateConsultModal(true)
            : undefined,
        )
      ) : (
        renderHeader(undefined, () => setActiveView(ViewStateEnum.Details))
      )}

      {(activeView === ViewStateEnum.Home || activeView === ViewStateEnum.Details) && (
        <>
          {isPhrLoading ? (
            [...Array(3)].map((x, index) => <PhrCardSkeletonComponent key={index} />)
          ) : (
            <Box overflowY="auto" p={2} height={{ base: 'calc(100vh - 4rem)', md: '600px' }}>
              <ServerValidationBox message={error} />
              <ConsultsList
                activeView={activeView}
                currentConsult={currentConsult}
                phrConsults={phrConsults}
                inverse={inverse}
                onEdit={onEdit}
                onView={onView}
                onDelete={(phrConsultID: string) => onDelete(phrConsultID)}
              />
            </Box>
          )}
        </>
      )}

      {activeView === ViewStateEnum.Edit && (
        <>
          {isLoading || !currentConsult ? (
            <CenteredLoadingIndicator />
          ) : (
            <Flex p={2} h="100%" flexDirection="column" bg="white">
              <ConsultsForm currentConsult={currentConsult} toggleView={() => setShowForm(false)} />
            </Flex>
          )}
        </>
      )}

      <Modal
        isOpen={showDeleteModal}
        title="Delete Consult Note Record?"
        onDismiss={() => setShowDeleteModal(false)}
        onCancel={() => setShowDeleteModal(false)}
        onSubmit={confirmDelete}
        cancelText="Cancel"
        submitText="Yes"
      >
        <p>Are you sure you want to delete this record?</p>
      </Modal>
      {showCreateConsultModal && (
        <CreateConsultModal
          providerID={provider?.providerID}
          patientID={patient.patient.patientID}
          patientCountryID={patient.patient.countryID}
          onSuccess={onCreate}
          onCancel={() => setShowCreateConsultModal(false)}
        />
      )}
    </Box>
  );
};

export default PHRConsultsComponent;
