import { ModalBody, ModalFooter } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ButtonRow } from '../../../components/Buttons';
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton';
import { SecondaryButton } from '../../../components/Buttons/SecondaryButton';
import { CountrySelect, SelectComponent, StoreOption } from '../../../components/forms/Selects';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import { BaseModal } from '../../../components/Modals';
import ServerValidationBox from '../../../components/ServerValidationBox';
import {
  AuthClient,
  Country,
  PHRConsult,
  PHRConsultsClient,
  Store,
  StoresClient,
} from '../../../generated';
import { useCountriesAll } from '../../../services/countries/useCountriesAll';
import { httpRequest } from '../../../utils';

interface IProps {
  providerID?: string;
  patientID: string;
  patientCountryID: string;
  onSuccess: (consult: PHRConsult) => void;
  onCancel: () => void;
  quickMenuTitle?: string;
}

export const CreateConsultModal = ({
  providerID,
  patientID,
  patientCountryID,
  onSuccess,
  onCancel,
  quickMenuTitle,
}: IProps) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [patientCountry, setPatientCountry] = useState<Country>();
  const [providerStore, setProviderStore] = useState<Store>();
  const { isLoadingCountries, countries, countriesError } = useCountriesAll();
  const [loadingStoresError, setLoadingStoresError] = useState<string>('');
  const [isLoadingStores, setLoadingStores] = useState<boolean>(true);
  const [stores, setStores] = useState<Store[]>();

  useEffect(() => {
    const loadStores = async (providerID: string) => {
      setLoadingStores(true);
      try {
        const result = await httpRequest(() =>
          new StoresClient(new AuthClient()).byProviderID(providerID),
        );
        setStores(result);
        if (result.length === 0) {
          setError('You are currently not set up with any Clinics. Please contact support.');
        } else {
          const store = result.find((x) => x.countryID === patientCountryID);
          setProviderStore(store ? store : result[0]);
        }
      } catch (err) {
        setLoadingStoresError(err as string);
      }
      setLoadingStores(false);
    };
    if (providerID) {
      loadStores(providerID);
    }
  }, [patientCountryID, providerID]);

  useEffect(() => {
    if (countries && countries.length > 0) {
      setPatientCountry(countries.find((x) => x.countryID === patientCountryID));
    }
  }, [countries, dispatch, patientCountryID, setPatientCountry]);

  const onSubmit = async () => {
    if (!patientCountry) {
      setError('You need to select a Patient Country');
    } else if (!providerStore) {
      setError('You need to select a Store');
    } else {
      setLoading(true);
      const phrConsult = new PHRConsult();
      phrConsult.providerID = providerID;
      phrConsult.patientID = patientID;
      phrConsult.countryID = patientCountry.countryID;
      phrConsult.patientCountryID = patientCountry.countryID;
      phrConsult.storeID = providerStore.storeID;
      phrConsult.consultDate = new Date();
      phrConsult.isCancelled = false;
      const phrConsultsClient = new PHRConsultsClient(new AuthClient());
      try {
        const newPHRConsultID = await httpRequest(() =>
          phrConsultsClient.pHRConsultsPost(phrConsult),
        );
        const newConsult = await httpRequest(() =>
          phrConsultsClient.pHRConsultsGet(patientID, newPHRConsultID),
        );
        onSuccess(newConsult);
      } catch (err) {
        setError(err as string);
      }
      setLoading(false);
    }
  };

  return (
    <BaseModal
      title={quickMenuTitle ? quickMenuTitle : 'Create new Consult'}
      onDismiss={onCancel}
      isOpen={true}
    >
      {isLoadingCountries || isLoading || isLoadingStores ? (
        <ModalBody>
          <CenteredLoadingIndicator />
        </ModalBody>
      ) : countriesError || loadingStoresError ? (
        <ModalBody>
          <ServerValidationBox message={countriesError || loadingStoresError} />
        </ModalBody>
      ) : (
        <>
          <ModalBody>
            <ServerValidationBox message={error} />
            <SelectComponent
              name="storeID"
              optionTemplate={StoreOption}
              value={providerStore}
              options={stores ?? []}
              label="Confirm Clinic Location"
              valueKey="storeID"
              labelKey="name"
              onChange={setProviderStore}
              isClearable={false}
              menuPortalTarget={document.body}
            />

            <CountrySelect
              name="countryID"
              value={patientCountry}
              options={countries ?? []}
              label="Confirm Patient's Location"
              valueKey="countryID"
              labelKey="name"
              onChange={setPatientCountry}
              isClearable={false}
              menuPortalTarget={document.body}
            />
          </ModalBody>
          <ModalFooter>
            <ButtonRow>
              <SecondaryButton isDisabled={isLoading} onClick={onCancel}>
                Cancel
              </SecondaryButton>
              <PrimaryButton isLoading={isLoading} onClick={onSubmit}>
                Create
              </PrimaryButton>
            </ButtonRow>
          </ModalFooter>
        </>
      )}
    </BaseModal>
  );
};
