import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Input,
  Text,
  useRadioGroup,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { Field, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { object, string } from 'yup';
import { EMPTY_GUID } from '../../../../constants';
import {
  AddressBook,
  AddressBookFolderEnum,
  AddressBookTag,
  AddressBookTypeEnum,
  CityTown,
  ProvinceState,
  Tag,
  TagTypeEnum,
} from '../../../../generated';
import { useCityTownsByProvinceStateID } from '../../../../services/cityTowns/queries/useCityTownsByProvinceStateID';
import { useProvinceStatesByCountryID } from '../../../../services/provinceStates/queries/useProvinceStatesByCountryID';
import { useTagsAllByTagType } from '../../../../services/tags/queries/useTagsAllByTagType';
import { AppState } from '../../../../store/root-reducers';
import { ButtonRow } from '../../../Buttons/ButtonRow';
import { Form } from '../../../forms';
import { SelectComponent } from '../../../forms/Selects';
import { CenteredLoadingIndicator } from '../../../LoadingIndicator';
import { RadioCard } from '../../../RadioCard/RadioCard';
import ServerValidationBox from '../../../ServerValidationBox';
import { AddressBookViewModeEnum } from '../../AddressBook';
import useAddressBooksContext from '../../context/AddressBooksProvider';
import { AddressBookStateEnum } from '../../hooks/useNavigation';
import { getAddressBookFolderName } from '../../utils';

import { getJoinedString } from '../../../../utils/getJoinedString';
import { PrimaryButton, SecondaryButton } from '../../../Buttons';
import useTranslationComponent from '../../../../hooks/useTranslationComponent';

interface IForm {
  name: string;
  organizationName: string;
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  emailAddress: string;
  officePhoneNumber: string;
  cellPhoneNumber: string;
  homePhoneNumber: string;
  faxPhoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  cityTownID: string;
  province: string;
  provinceStateID: string;
  countryID: string;
  postCode: string;
  addressBookFolder: AddressBookFolderEnum;
  specialties: Tag[];
  primarySpecialtyTagID?: string;
}

interface IProps {
  addressBook?: AddressBook | null;
  state: AddressBookStateEnum;
  onCancel: () => void;
  onComplete: () => void;
}

export const ContactForm = ({ addressBook, state, onCancel, onComplete }: IProps) => {
  const { t } = useTranslationComponent(['common', 'validation']);
  const { error, isLoading, country, viewMode, onCreate, onEdit, onUpdateTags } =
    useAddressBooksContext();
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const [addressBookFolder, setAddressBookFolder] = useState<AddressBookFolderEnum | undefined>(
    addressBook?.addressBookFolderEnum || AddressBookFolderEnum.Provider,
  );
  const [city, setCity] = useState<string>('');
  const [cityTown, setCityTown] = useState<CityTown | null>(addressBook?.cityTown || null);
  const [province, setProvince] = useState<string>('');
  const [provinceState, setProvinceState] = useState<ProvinceState | null>(
    addressBook?.provinceState || null,
  );
  const { isLoadingProvinceStates, provinceStatesResult, provinceStatesError } =
    useProvinceStatesByCountryID({ countryID: country.countryID });
  const { isLoadingCityTowns, cityTownsResult, cityTownsError } = useCityTownsByProvinceStateID({
    provinceStateID: provinceState?.provinceStateID,
  });
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'addressBookFolder',
    defaultValue: AddressBookFolderEnum.Provider,
    onChange: (val) => setAddressBookFolder(val as AddressBookFolderEnum),
  });
  const group = getRootProps();
  const {
    isLoadingTags: specialtyTagsLoading,
    tags: specialtyTags,
    tagsError: specialtyTagsError,
  } = useTagsAllByTagType(TagTypeEnum.Specialties);
  const [primarySpecialty, setPrimarySpecialty] = useState<Tag>();
  const [specialties, setSpecialties] = useState<(Tag | undefined)[]>([]);

  const onSubmit = async (values: IForm) => {
    const book = new AddressBook();
    if (addressBook) book.init(addressBook);
    book.countryID = values.countryID;
    book.name = values.name;
    book.organizationName = values.organizationName;
    book.title = values.title;
    book.firstName = values.firstName;
    book.middleName = values.middleName;
    book.lastName = values.lastName;
    book.emailAddress = values.emailAddress;
    book.addressBookTypeEnum = AddressBookTypeEnum.Private;
    book.addressBookFolderEnum = values.addressBookFolder;
    book.officePhoneNumber = values.officePhoneNumber;
    book.cellPhoneNumber = values.cellPhoneNumber;
    book.homePhoneNumber = values.homePhoneNumber;
    book.faxPhoneNumber = values.faxPhoneNumber;
    book.addressLine1 = values.addressLine1;
    book.addressLine2 = values.addressLine2;
    if (values.cityTownID === EMPTY_GUID) {
      book.city = city;
      book.cityTownID = '';
    } else {
      book.city = '';
      book.cityTownID = values.cityTownID;
    }
    if (values.provinceStateID === EMPTY_GUID) {
      book.province = province;
      book.provinceStateID = '';
    } else {
      book.province = '';
      book.provinceStateID = values.provinceStateID;
    }
    book.postCode = values.postCode;
    book.userDetailID = currentUser?.userDetailID || '';
    book.enabled = true;

    let addressBookID;
    if (!addressBook) {
      addressBookID = onCreate ? await onCreate(book) : '';
    } else {
      if (Boolean(onEdit)) {
        await onEdit(book);
      }
      addressBookID = book.addressBookID;
    }

    const addressBookTags =
      (specialties &&
        specialties.map((x) => {
          const addressBookTag = new AddressBookTag();
          addressBookTag.tagID = x?.tagID ?? '';
          addressBookTag.isPrimary = x?.tagID === primarySpecialty?.tagID;
          addressBookTag.displaySortOrder = 1;
          return addressBookTag;
        })) ||
      [];
    await onUpdateTags({ addressBookID, addressBookTags });

    onComplete();
  };

  useEffect(() => {
    if (addressBook) {
      if (!addressBook.provinceState && addressBook.province) {
        const provinceState = new ProvinceState();
        provinceState.provinceStateID = EMPTY_GUID;
        provinceState.name = addressBook.province;
        setProvinceState(provinceState);
      }
      if (!addressBook.cityTown && addressBook.city) {
        const cityTown = new CityTown();
        cityTown.cityTownID = EMPTY_GUID;
        cityTown.name = addressBook.city;
        setCityTown(cityTown);
      }
    }
  }, [addressBook]);

  useEffect(() => {
    if (primarySpecialty) {
      const tag = specialtyTags?.find((x) => x.tagID === primarySpecialty.tagID);
      if (!specialties || specialties.length === 0) {
        setSpecialties([tag]);
      } else if (!specialties.find((x) => x?.tagID === primarySpecialty.tagID)) {
        specialties.push(tag);
        setSpecialties([...specialties]);
      }
    }
  }, [primarySpecialty, specialties, specialtyTags]);

  useEffect(() => {
    if (addressBook) {
      const tags = Array.from(addressBook?.addressBookTags || [], (x) => x.tag);
      const primaryTag = addressBook?.addressBookTags?.find((x) => x.isPrimary)?.tag;
      setSpecialties(tags);
      setPrimarySpecialty(primaryTag);
    }
  }, [addressBook]);

  return (
    <Formik
      initialValues={{
        name: addressBook?.name || '',
        organizationName: addressBook?.organizationName || '',
        title: addressBook?.title || '',
        firstName: addressBook?.firstName || '',
        middleName: addressBook?.middleName || '',
        lastName: addressBook?.lastName || '',
        emailAddress: addressBook?.emailAddress || '',
        officePhoneNumber: addressBook?.officePhoneNumber || '',
        cellPhoneNumber: addressBook?.cellPhoneNumber || '',
        homePhoneNumber: addressBook?.homePhoneNumber || '',
        faxPhoneNumber: addressBook?.faxPhoneNumber || '',
        addressLine1: addressBook?.addressLine1 || '',
        addressLine2: addressBook?.addressLine2 || '',
        city: addressBook?.city || '',
        cityTownID: addressBook?.cityTownID || '',
        province: addressBook?.province || '',
        provinceStateID: addressBook?.provinceStateID || '',
        countryID: country.countryID || addressBook?.countryID || '',
        postCode: addressBook?.postCode || '',
        addressBookFolder: addressBookFolder || AddressBookFolderEnum.Provider,
        specialties: addressBook?.addressBookTags?.map((x) => x.tag!) || [],
        primarySpecialtyTagID: addressBook?.addressBookTags?.find((x) => x.isPrimary)?.tag?.tagID,
      }}
      validationSchema={object({
        name: string().when('addressBookFolder', {
          is: (value) => value !== AddressBookFolderEnum.Provider,
          then: string().required(t('Name is required', { ns: 'validation' })),
          otherwise: string(),
        }),
        organizationName: string().when('addressBookFolder', {
          is: (value) => value === AddressBookFolderEnum.Organization,
          then: string().required(t('Hospital name is required', { ns: 'validation' })),
          otherwise: string(),
        }),
        firstName: string().when('addressBookFolder', {
          is: (value) => value === AddressBookFolderEnum.Provider,
          then: string().required(t('First name is required', { ns: 'validation' })),
          otherwise: string(),
        }),
        lastName: string().when('addressBookFolder', {
          is: (value) => value === AddressBookFolderEnum.Provider,
          then: string().required(t('Last name is required', { ns: 'validation' })),
          otherwise: string(),
        }),
        countryID: string().required(),
        addressLine1: string().max(
          250,
          t('Address Line 1 must be at most 250 characters', { ns: 'validation' }),
        ),
        addressLine2: string().max(
          250,
          t('Address Line 2 must be at most 250 characters', { ns: 'validation' }),
        ),
        city: string().max(200, t('City must be at most 250 characters', { ns: 'validation' })),
        province: string().max(
          250,
          t('Province must be at most 250 characters', { ns: 'validation' }),
        ),
        postCode: string().max(
          50,
          t('Postal Code / Zip must be at most 250 characters', { ns: 'validation' }),
        ),
        cellPhoneNumber: string().test(
          'required',
          t('Either Mobile Phone or Home Phone Number is required.', { ns: 'validation' }),
          function (value) {
            if (value && (value.match(/\d/g)?.join('').length ?? 0) < 5) {
              return this.createError({
                message: t(`Mobile Phone Number must be at least 5 digits.`, { ns: 'validation' }),
                path: 'cellPhoneNumber',
              });
            }
            return true;
          },
        ),
        homePhoneNumber: string().test(
          'required',
          t('Either Mobile Phone or Home Phone Number is required.', { ns: 'validation' }),
          function (value) {
            if (value && (value.match(/\d/g)?.join('').length ?? 0) < 5) {
              return this.createError({
                message: t(`Home Phone Number must be at least 5 digits.`, { ns: 'validation' }),
                path: 'homePhoneNumber',
              });
            }
            return true;
          },
        ),
        faxPhoneNumber: string().when('addressBookFolder', {
          is: (value) => value === AddressBookFolderEnum.Lab,
          then: string().required(t('Fax number is required', { ns: 'validation' })),
          otherwise: string(),
        }),
      })}
      onSubmit={onSubmit}
    >
      {({ errors, setFieldValue }) => {
        return (
          <StyledForm
            error={error || provinceStatesError || cityTownsError || specialtyTagsError || ''}
          >
            <VStack height="100%" alignItems="stretch">
              {isLoading || specialtyTagsLoading ? (
                <CenteredLoadingIndicator />
              ) : (
                <Box
                  w={viewMode === AddressBookViewModeEnum.Page ? '100%' : '100%'}
                  // minHeight={viewMode === AddressBookViewModeEnum.Page ? '528px' : '670px'}
                  flex="1"
                  overflowY="auto"
                  px="1"
                >
                  <Heading textAlign="center" size="md" fontSize="24px" mb={4}>
                    {t(state === AddressBookStateEnum.Create ? 'Add new contact' : 'Edit contact')}
                  </Heading>
                  <ServerValidationBox message={error} />
                  <Text>{t('Contact type')}</Text>
                  <Box mt={4} mb={4}>
                    <Wrap {...group} spacing={2}>
                      {Object.values(AddressBookFolderEnum)
                        .filter((x) => x !== AddressBookFolderEnum.Patient)
                        .map((value, index) => {
                          // @ts-ignore
                          const radio = getRadioProps({ value });
                          return (
                            <WrapItem key={index}>
                              <RadioCard
                                clickHandler={(val) => {
                                  setAddressBookFolder(val as AddressBookFolderEnum);
                                  setFieldValue('addressBookFolder', val);
                                }}
                                key={value}
                                {...radio}
                                isChecked={addressBookFolder === value}
                              >
                                {getAddressBookFolderName(value)}
                              </RadioCard>
                            </WrapItem>
                          );
                        })}
                    </Wrap>
                  </Box>

                  <Accordion allowToggle defaultIndex={0} mr={4}>
                    <Box
                      border="1px solid"
                      borderColor="gray.200"
                      borderRadius="lg"
                      w="100%"
                      mb={4}
                      mr={4}
                    >
                      <AccordionItem>
                        <AccordionButton>
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            w="100%"
                            px={4}
                            py={2}
                          >
                            <Flex alignItems="center">
                              <Text mr={4}>{t('Personal information')}</Text>
                              <Text fontSize="xs" color="red.400">
                                {[
                                  errors.title,
                                  errors.firstName,
                                  errors.middleName,
                                  errors.lastName,
                                  errors.name,
                                  errors.organizationName,
                                ]
                                  .filter((x) => !!x)
                                  .join(', ')}
                              </Text>
                            </Flex>
                            <AccordionIcon />
                          </Flex>
                        </AccordionButton>

                        <AccordionPanel borderTop="1px solid" borderTopColor="gray.200">
                          {addressBookFolder !== AddressBookFolderEnum.Provider ? (
                            <Box w="100%" px={8}>
                              <Grid
                                templateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
                                gap={2}
                                w="100%"
                                alignItems="center"
                              >
                                <GridItem colSpan={1}>
                                  <Field name="name">
                                    {({ field }) => (
                                      <Box mx={2}>
                                        <Text fontSize="sm" color="gray.400">
                                          {t('Name')}
                                        </Text>
                                        <Input {...field} />
                                      </Box>
                                    )}
                                  </Field>
                                </GridItem>
                                <GridItem colSpan={1}>
                                  {addressBookFolder === AddressBookFolderEnum.Organization && (
                                    <Field name="organizationName">
                                      {({ field }) => (
                                        <Box mx={2}>
                                          <Text fontSize="sm" color="gray.400">
                                            {t('Hospital name')}
                                          </Text>
                                          <Input {...field} />
                                        </Box>
                                      )}
                                    </Field>
                                  )}
                                </GridItem>
                              </Grid>
                            </Box>
                          ) : (
                            <Box w="100%" px={8}>
                              <VStack spacing={2}>
                                <Grid
                                  templateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
                                  gap={2}
                                  w="100%"
                                  alignItems="center"
                                >
                                  <GridItem colSpan={1}>
                                    <Field name="title">
                                      {({ field }) => (
                                        <Box mx={2}>
                                          <Text fontSize="sm" color="gray.400">
                                            {t('Title')}
                                          </Text>
                                          <Input {...field} />
                                        </Box>
                                      )}
                                    </Field>
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    <Field name="firstName">
                                      {({ field }) => (
                                        <Box mx={2}>
                                          <Text fontSize="sm" color="gray.400">
                                            {t('First name')}
                                          </Text>
                                          <Input {...field} />
                                        </Box>
                                      )}
                                    </Field>
                                  </GridItem>
                                </Grid>
                                <Grid
                                  templateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
                                  gap={2}
                                  w="100%"
                                  alignItems="center"
                                >
                                  <GridItem colSpan={1}>
                                    <Field name="middleName">
                                      {({ field }) => (
                                        <Box mx={2}>
                                          <Text fontSize="sm" color="gray.400">
                                            {t('Middle name')}
                                          </Text>
                                          <Input {...field} />
                                        </Box>
                                      )}
                                    </Field>
                                  </GridItem>
                                  <GridItem colSpan={1}>
                                    <Field name="lastName">
                                      {({ field }) => (
                                        <Box mx={2}>
                                          <Text fontSize="sm" color="gray.400">
                                            {t('Last name')}
                                          </Text>
                                          <Input {...field} />
                                        </Box>
                                      )}
                                    </Field>
                                  </GridItem>
                                </Grid>
                              </VStack>
                            </Box>
                          )}
                        </AccordionPanel>
                      </AccordionItem>
                    </Box>
                    <Box
                      border="1px solid"
                      borderColor="gray.200"
                      borderRadius="lg"
                      w="100%"
                      mb={4}
                      mr={4}
                    >
                      <AccordionItem>
                        <AccordionButton>
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            w="100%"
                            px={4}
                            py={2}
                          >
                            <Flex alignItems="center">
                              <Text mr={4}>{t('Contact information')}</Text>
                              <Text fontSize="xs" color="red.400">
                                {[
                                  errors.emailAddress,
                                  errors.faxPhoneNumber,
                                  errors.officePhoneNumber,
                                  errors.cellPhoneNumber,
                                  errors.homePhoneNumber,
                                ]
                                  .filter((x) => !!x)
                                  .join(', ')}
                              </Text>
                            </Flex>
                            <AccordionIcon />
                          </Flex>
                        </AccordionButton>

                        <AccordionPanel borderTop="1px solid" borderTopColor="gray.200">
                          <Box w="100%" px={8}>
                            <VStack spacing={2}>
                              <Grid
                                templateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
                                gap={2}
                                w="100%"
                                alignItems="center"
                              >
                                <GridItem colSpan={1}>
                                  <Field name="emailAddress">
                                    {({ field }) => (
                                      <Box mx={2}>
                                        <Text fontSize="sm" color="gray.400">
                                          {t('Email address')}
                                        </Text>
                                        <Input {...field} />
                                      </Box>
                                    )}
                                  </Field>
                                </GridItem>
                                <GridItem colSpan={1}>
                                  <Field name="faxPhoneNumber">
                                    {({ field }) => (
                                      <Box mx={2}>
                                        <Text fontSize="sm" color="gray.400">
                                          {t('Fax number')}
                                        </Text>
                                        <Input {...field} />
                                      </Box>
                                    )}
                                  </Field>
                                </GridItem>
                              </Grid>
                              <Grid
                                templateColumns={['1fr', '1fr', 'repeat(3, 1fr)']}
                                gap={2}
                                w="100%"
                                alignItems="center"
                              >
                                <GridItem colSpan={1}>
                                  <Field name="officePhoneNumber">
                                    {({ field }) => (
                                      <Box mx={2}>
                                        <Text fontSize="sm" color="gray.400">
                                          {t('Office phone')}
                                        </Text>
                                        <Input {...field} />
                                      </Box>
                                    )}
                                  </Field>
                                </GridItem>
                                <GridItem colSpan={1}>
                                  <Field name="cellPhoneNumber">
                                    {({ field }) => (
                                      <Box mx={2}>
                                        <Text fontSize="sm" color="gray.400">
                                          {t('Cell phone')}
                                        </Text>
                                        <Input {...field} />
                                      </Box>
                                    )}
                                  </Field>
                                </GridItem>
                                <GridItem colSpan={1}>
                                  <Field name="homePhoneNumber">
                                    {({ field }) => (
                                      <Box mx={2}>
                                        <Text fontSize="sm" color="gray.400">
                                          {t('Home phone')}
                                        </Text>
                                        <Input {...field} />
                                      </Box>
                                    )}
                                  </Field>
                                </GridItem>
                              </Grid>
                            </VStack>
                          </Box>
                        </AccordionPanel>
                      </AccordionItem>
                    </Box>
                    <Box
                      border="1px solid"
                      borderColor="gray.200"
                      borderRadius="lg"
                      w="100%"
                      mb={4}
                      mr={4}
                    >
                      <AccordionItem>
                        <AccordionButton>
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            w="100%"
                            px={4}
                            py={2}
                          >
                            <Flex alignItems="center">
                              <Text mr={4}>Address</Text>
                              <Text fontSize="xs" color="red.400">
                                {[
                                  errors.addressLine1,
                                  errors.addressLine2,
                                  errors.city,
                                  errors.province,
                                  errors.postCode,
                                ]
                                  .filter((x) => !!x)
                                  .join(', ')}
                              </Text>
                            </Flex>
                            <AccordionIcon />
                          </Flex>
                        </AccordionButton>

                        <AccordionPanel borderTop="1px solid" borderTopColor="gray.200">
                          <Box w="100%" px={8}>
                            <VStack spacing={2}>
                              <Grid
                                templateColumns={['1fr', '1fr', 'repeat(2, 1fr)']}
                                gap={2}
                                w="100%"
                                alignItems="center"
                              >
                                <GridItem colSpan={1}>
                                  <Field name="addressLine1">
                                    {({ field }) => (
                                      <Box mx={2}>
                                        <Text fontSize="sm" color="gray.400">
                                          {t('Address line 1')}
                                        </Text>
                                        <Input {...field} />
                                      </Box>
                                    )}
                                  </Field>
                                </GridItem>
                                <GridItem colSpan={1}>
                                  <Field name="addressLine2">
                                    {({ field }) => (
                                      <Box mx={2}>
                                        <Text fontSize="sm" color="gray.400">
                                          {t('Address line 2')}
                                        </Text>
                                        <Input {...field} />
                                      </Box>
                                    )}
                                  </Field>
                                </GridItem>
                              </Grid>
                              <Grid
                                templateColumns={['1fr', '1fr', 'repeat(3, 1fr)']}
                                gap={2}
                                w="100%"
                                alignItems="center"
                              >
                                <GridItem colSpan={1}>
                                  <Box mx={2}>
                                    <SelectComponent
                                      name="province"
                                      isSearchable={true}
                                      isCreatable={true}
                                      value={provinceState}
                                      label={t(country.provinceStateLabel || 'Province')}
                                      options={provinceStatesResult || []}
                                      valueKey="provinceStateID"
                                      labelKey="name"
                                      onChange={(option) => {
                                        if (option?.provinceStateID === EMPTY_GUID) {
                                          setProvince(option?.name);
                                        }
                                        setProvinceState(option);
                                        setCityTown(null);
                                        setFieldValue('provinceStateID', option?.provinceStateID);
                                        setFieldValue('cityTownID', '');
                                      }}
                                      //@ts-ignore
                                      getNewOptionData={(inputValue) => ({
                                        name: inputValue,
                                        provinceStateID: EMPTY_GUID,
                                      })}
                                      noOptionsMessage={() => t('No Province found')}
                                      isLoading={isLoadingProvinceStates}
                                      formControlProps={selectControlProps}
                                    />
                                  </Box>
                                </GridItem>
                                <GridItem colSpan={1}>
                                  <Box mx={2}>
                                    <SelectComponent
                                      name="city"
                                      isSearchable={true}
                                      isCreatable={true}
                                      value={cityTown}
                                      label="City"
                                      options={cityTownsResult || []}
                                      valueKey="cityTownID"
                                      labelKey="name"
                                      onChange={(option) => {
                                        if (option?.cityTownID === EMPTY_GUID) {
                                          setCity(option?.name);
                                        }
                                        setCityTown(option);
                                        setFieldValue('cityTownID', option?.cityTownID);
                                      }}
                                      //@ts-ignore
                                      getNewOptionData={(inputValue) => ({
                                        name: inputValue,
                                        cityTownID: EMPTY_GUID,
                                      })}
                                      noOptionsMessage={() => t('No City found')}
                                      isLoading={isLoadingCityTowns}
                                      formControlProps={selectControlProps}
                                    />
                                  </Box>
                                </GridItem>
                                <GridItem colSpan={1}>
                                  <Field name="postCode">
                                    {({ field }) => (
                                      <Box mx={2}>
                                        <Text fontSize="sm" color="gray.400">
                                          {t('Postal code')}
                                        </Text>
                                        <Input {...field} />
                                      </Box>
                                    )}
                                  </Field>
                                </GridItem>
                              </Grid>
                            </VStack>
                          </Box>
                        </AccordionPanel>
                      </AccordionItem>
                    </Box>
                    {addressBookFolder === AddressBookFolderEnum.Provider && (
                      <Box
                        border="1px solid"
                        borderColor="gray.200"
                        borderRadius="lg"
                        w="100%"
                        mb={4}
                        mr={4}
                      >
                        <AccordionItem>
                          <AccordionButton>
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                              w="100%"
                              px={4}
                              py={2}
                            >
                              <Flex alignItems="center">
                                <Text mr={4}>{t('Specialties')}</Text>
                                <Text fontSize="xs" color="red.400">
                                  {getJoinedString(errors.specialties, ',')}
                                </Text>
                              </Flex>
                              <AccordionIcon />
                            </Flex>
                          </AccordionButton>

                          <AccordionPanel borderTop="1px solid" borderTopColor="gray.200">
                            <Box w="100%" px={8}>
                              <Grid
                                templateColumns={['1fr', '1fr', 'repeat(3, 1fr)']}
                                gap={2}
                                w="100%"
                                alignItems="center"
                              >
                                <GridItem colSpan={1} px={2}>
                                  <SelectComponent
                                    label={t('Primary specialty')}
                                    value={primarySpecialty}
                                    name="primarySpecialtyTagID"
                                    options={specialtyTags || []}
                                    valueKey="tagID"
                                    labelKey="name"
                                    isSearchable={true}
                                    closeMenuOnSelect={false}
                                    isMulti={false}
                                    isClearable={false}
                                    onChange={setPrimarySpecialty}
                                  />
                                </GridItem>
                                <GridItem colSpan={2} px={2}>
                                  {specialtyTags && (
                                    <SelectComponent
                                      label={t('Specialties')}
                                      value={specialties}
                                      name="specialties"
                                      options={specialtyTags}
                                      valueKey="tagID"
                                      labelKey="name"
                                      isSearchable={true}
                                      closeMenuOnSelect={false}
                                      isMulti={true}
                                      onChange={setSpecialties}
                                    />
                                  )}
                                </GridItem>
                              </Grid>
                            </Box>
                          </AccordionPanel>
                        </AccordionItem>
                      </Box>
                    )}
                  </Accordion>
                </Box>
              )}
              <ButtonRow>
                <SecondaryButton variant="outline" colorScheme="purple" onClick={onCancel}>
                  {t('Cancel')}
                </SecondaryButton>
                <PrimaryButton type="submit">{t('Save')}</PrimaryButton>
              </ButtonRow>
            </VStack>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

const selectControlProps = {
  m: 0,
  formLabelProps: { m: 0, color: 'gray.400' },
};

const StyledForm = styled(Form)`
  height: 100%;
`;
