import { use100vh } from 'react-div-100vh';

const useViewHeight = () => {
  const height = use100vh();

  /**
   *
   * @param vh Vertical height percentage (0-100)
   * @param convertToPX Convert result to 'px'
   * @returns Calculated device vertical height or value in vh
   */
  const getVH = (vh: number, convertToPX = true) => {
    if (!height) return `${vh}vh`;
    const viewHeight = (vh / 100) * height;
    if (convertToPX) return `${viewHeight}px`;
    return viewHeight;
  };

  return {
    getVH,
  };
};

export { useViewHeight };
