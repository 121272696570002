import { Button, ButtonProps } from '@chakra-ui/react';
import { useContext } from 'react';
import { toggleSwitchContext } from './ToggleSwitchContext';

interface IProps {
  id: string;
  children: any;
  activeColor?: string;
  activeBgColor?: string;
  color?: string;
}
type TToggleButton = IProps & ButtonProps;

export const ToggleButton = ({
  id,
  children,
  activeColor,
  activeBgColor,
  color,
  ...rest
}: TToggleButton) => {
  const { handleClick, activeId, onClick } = useContext(toggleSwitchContext);

  const bgColor = id === activeId ? activeBgColor || 'white' : 'transparent';
  const defaultColor = id === activeId ? activeColor || '#333333' : color || '#333333';

  return (
    <Button
      bgColor={bgColor}
      color={defaultColor}
      borderColor="transparent"
      width="50%"
      borderRadius="10px"
      padding="0.25rem"
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          handleClick(id);
        }
      }}
      _active={{
        bgColor,
      }}
      _hover={{
        bgColor,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};
