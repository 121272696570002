import { useSelector } from 'react-redux';
import { useCountriesAll } from '../../../../../services/countries/useCountriesAll';
import { countriesProviderCanWritePrescriptions } from '../../../../../store/provider/providerSelectors';
import { AppState } from '../../../../../store/root-reducers';

const useProviderPrescriptionCountries = () => {
  const { isLoadingCountries: isLoadingEligbleCountries, countries } = useCountriesAll();
  const provider = useSelector((state: AppState) => state.providerState.provider);
  const elegibleCountries = countriesProviderCanWritePrescriptions({ provider, countries });

  return {
    elegibleCountries,
    isLoadingEligbleCountries,
  };
};

export default useProviderPrescriptionCountries;
