const openUriSchemeWithHyperlinkFallback = (uri: string, href: string) => {
  const iframe = document.createElement('iframe');
  iframe.style.visibility = 'hidden';
  iframe.src = uri;
  iframe.onload = function noApp() {
    window.location.href = href;
  };
  document.body.appendChild(iframe);
};

export { openUriSchemeWithHyperlinkFallback };
