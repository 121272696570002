import { useEffect, useState } from 'react';
import { PHRForm, PHRFormQuestion } from '../../../../../generated';

export interface IFormBuilderState {
  currentIndex: number;
  currentPHRQuestion: PHRFormQuestion | undefined;
  questions: PHRFormQuestion[];
  formResult: PHRForm | undefined;
  prevState: IFormBuilderState | undefined;
}
interface IProps {
  submitForm: (phrFormQuestions: PHRFormQuestion[]) => Promise<PHRForm | undefined>;
  questionCount: number;
}

const initialState = {
  currentIndex: 0,
  questions: [],
  currentPHRQuestion: undefined,
  formResult: undefined,
  prevState: undefined,
};

const useManageState = ({ submitForm, questionCount }: IProps) => {
  const [state, setState] = useState<IFormBuilderState>(initialState);

  const goToPrevious = async () => {
    setState((prev) => (prev.prevState ? { ...prev.prevState } : initialState));
  };

  const onNext = async (phrFormQuestion: PHRFormQuestion) => {
    const questions = [...state.questions];
    questions[state.currentIndex] = phrFormQuestion;
    let formResult: PHRForm | undefined = undefined;
    if (state.currentIndex + 1 === questionCount) {
      formResult = await submitForm(questions);
    }

    setState((prev) => {
      const newState = {
        questions,
        currentIndex: prev.currentIndex + 1,
        formResult,
        prevState: prev.prevState,
      } as IFormBuilderState;

      newState.prevState = { ...newState, currentIndex: prev.currentIndex };

      return newState;
    });
  };

  useEffect(() => {
    setState((prev) => ({ ...prev, currentPHRQuestion: state.questions[state.currentIndex] }));
  }, [state.currentIndex, state.questions]);

  const canGoBack = state.currentIndex !== 0;

  const lastStep = state.currentIndex === questionCount - 1;

  return { onNext, goToPrevious, state, canGoBack, lastStep };
};

export default useManageState;
