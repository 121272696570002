import { Box, chakra, Divider, Flex, HStack, Image, Text } from '@chakra-ui/react';
import { push } from 'connected-react-router';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';

import { CrossIcon, UserIcon } from '../../assets/icons';
import curledArrow from '../../assets/icons/curled-arrow.png';
import { TabButton } from '../../components/Buttons';
import { PrimaryButton } from '../../components/Buttons/PrimaryButton';
import {
  FormInputControl,
  FormMaskedInputControl,
  FormPasswordControl,
  FormPhoneInputControl,
} from '../../components/forms';
import Form from '../../components/forms/Form';
import HelmetWrapper from '../../components/HelmetWrapper';
import { CenteredLoadingIndicator } from '../../components/LoadingIndicator';
import ServerValidationBox from '../../components/ServerValidationBox';
import { SignInRoutes } from '../../constants';
import { UserSignInDetails } from '../../generated';
import { mixTrack } from '../../lib/mixpanel';
import { PartnerSettingsEnum } from '../../store/currentPartner/currentPartnerReducers';
import { clearErrorAction } from '../../store/currentUser/currentUserActions';
import { signIn } from '../../store/root-creator';
import { AppState } from '../../store/root-reducers';
import { OutpostTheme } from '../../themes/outpost';
import { getNounPrefix } from '../../utils/getNounPrefix';
import { useUnAuthenticatedLayout } from '../layouts/hooks/useUnAuthenticatedLayout/useUnAuthenticatedLayout';

import { MobileRedirect } from './components/MobileRedirect';
import useTranslationComponent from '../../hooks/useTranslationComponent';

interface IForm {
  email: string;
  phoneNumber: string;
  careCardNumber: string;
  password: string;
  pin: string;
}

enum LoginTypeEnum {
  Email = 'Email',
  Phone = 'Phone',
  CardNumber = 'CardNumber', // Neo Card
}

const initialValues: IForm = {
  email: '',
  phoneNumber: '',
  careCardNumber: '',
  password: '',
  pin: '',
};

const LoginView = () => {
  const { t } = useTranslationComponent(['signin', 'common', 'validation']);
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector((state: AppState) => state.currentUserState);
  const { location } = useSelector((state: AppState) => state.router);
  const [loginType, setLoginType] = useState(LoginTypeEnum.Email);

  const { showHeaderAndFooter, partnerLogo, outpostLogo } = useUnAuthenticatedLayout();

  const currentPartner = useSelector((state: AppState) => state.currentPartnerState.data);
  const currentPartnerLoading = useSelector(
    (state: AppState) => state.currentPartnerState.isLoading,
  );

  const logo = partnerLogo || outpostLogo;

  const patientRegistrationMessage = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.PROHIBIT_PATIENT_REGISTRATION,
  )?.value;

  const prohibitPatientRegistration = !!patientRegistrationMessage;

  const companyName = currentPartner?.name || process.env.REACT_APP_COMPANY_NAME;

  const providerRegistrationMessage = currentPartner?.partnerSettings?.find(
    ({ partnerSettingName }) =>
      partnerSettingName === PartnerSettingsEnum.PROHIBIT_PROVIDER_REGISTRATION,
  )?.value;

  const prohibitProviderRegistration = !!providerRegistrationMessage;

  const isEmailLogin = loginType === LoginTypeEnum.Email;
  const isPhoneLogin = loginType === LoginTypeEnum.Phone;
  const isCardNumberLogin = loginType === LoginTypeEnum.CardNumber;

  const login = (values: IForm) => {
    mixTrack({
      eventName: 'login:login_auth_clicked',
    });

    const uniqueLifeTimeId =
      loginType === LoginTypeEnum.Email
        ? values.email
        : loginType === LoginTypeEnum.Phone
        ? values.phoneNumber
        : loginType === LoginTypeEnum.CardNumber
        ? values.careCardNumber.replaceAll('-', '')
        : undefined;

    const password = loginType === LoginTypeEnum.CardNumber ? values.pin : values.password;

    const details = new UserSignInDetails();
    details.emailOrUniqueLifetimeID = uniqueLifeTimeId?.trim();
    details.passwordOrPIN = password.trim();
    dispatch(signIn(details));
  };

  useEffect(() => {
    dispatch(clearErrorAction());
    document.getElementById('passwordOrPinId')?.focus();
    document.getElementById('emailOrUniqueLifetimeId')?.focus();
  }, [dispatch]);

  return (
    <>
      <HelmetWrapper title="Login" />
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          ...(loginType === LoginTypeEnum.Email
            ? {
                email: string()
                  .trim()
                  .required(t('Email address is required', { ns: 'validation' }))
                  .email(t('Must be a valid email address', { ns: 'validation' }))
                  .max(
                    256,
                    t('Email address must be at most 256 characters', { ns: 'validation' }),
                  ),
              }
            : {}),

          ...(loginType === LoginTypeEnum.Phone
            ? {
                phoneNumber: string().test(
                  'required',
                  t('Phone Number is required.', { ns: 'validation' }),
                  function (value) {
                    if ((value?.match(/\d/g)?.join('').length ?? 0) < 5) {
                      return this.createError({
                        message: t('Phone Number must be at least 5 digits.', { ns: 'validation' }),
                        path: 'phoneNumber',
                      });
                    }
                    return true;
                  },
                ),
              }
            : {}),

          ...(loginType === LoginTypeEnum.CardNumber
            ? {
                careCardNumber: string()
                  .required(t('Neo Carecard Number is required', { ns: 'validation' }))
                  .matches(
                    /^[0-9-]{13}$/,
                    t('Neo Carecard Number Must be exactly 10 digits', { ns: 'validation' }),
                  ),
              }
            : {}),

          ...(loginType === LoginTypeEnum.CardNumber
            ? {
                pin: string()
                  .required(t('Pin is required', { ns: 'validation' }))
                  .max(256, t('Pin must be at most 256 characters', { ns: 'validation' })),
              }
            : {
                password: string()
                  .required(t('Password is required', { ns: 'validation' }))
                  .max(256, t('Password must be at most 256 characters', { ns: 'validation' })),
              }),
        })}
        onSubmit={(values) => login(values)}
      >
        {() => (
          <Form>
            {!currentPartnerLoading && (
              <Box mb="6">
                {(!showHeaderAndFooter || partnerLogo) && (
                  <Box mb={4}>
                    <Image src={logo} />
                  </Box>
                )}
                <Text variant="heading" mb="0" fontSize="24px" fontWeight="bold">
                  {t('Welcome To', { ns: 'signin' })} {companyName}
                </Text>
                <Text fontSize={OutpostTheme.FontSizes.body}>
                  {t('Please enter your details to proceed', { ns: 'signin' })}
                </Text>
              </Box>
            )}
            {isLoading ? (
              <CenteredLoadingIndicator />
            ) : (
              <>
                <ServerValidationBox message={error} />

                <HStack mb="4" spacing="4">
                  <TabButton
                    onClick={() => {
                      setLoginType(LoginTypeEnum.Email);
                    }}
                    fontSize={{ base: '12px', md: '14px' }}
                    isSelected={isEmailLogin}
                  >
                    {t('Email Address', { ns: 'common' })}
                  </TabButton>
                  <TabButton
                    onClick={() => {
                      setLoginType(LoginTypeEnum.Phone);
                    }}
                    fontSize={{ base: '12px', md: '14px' }}
                    isSelected={isPhoneLogin}
                  >
                    {t('Phone Number', { ns: 'common' })}
                  </TabButton>
                  <TabButton
                    onClick={() => {
                      setLoginType(LoginTypeEnum.CardNumber);
                    }}
                    fontSize={{ base: '12px', md: '14px' }}
                    isSelected={isCardNumberLogin}
                  >
                    {t('Neo Carecard', { ns: 'common' })}
                  </TabButton>
                </HStack>

                {loginType === LoginTypeEnum.Email ? (
                  <FormInputControl
                    size="lg"
                    tabIndex={1}
                    autoComplete="on"
                    name="email"
                    type="email"
                    label={t('Email Address', { ns: 'common' })}
                    id="emailId"
                    formControlProps={{
                      mb: '0',
                    }}
                  />
                ) : loginType === LoginTypeEnum.Phone ? (
                  <FormPhoneInputControl
                    name="phoneNumber"
                    label={t('Phone Number', { ns: 'common' })}
                    formControlProps={{
                      mb: '0',
                    }}
                    inputStyle={{
                      backgroundColor: 'transparent',
                      minHeight: '47px',
                    }}
                  />
                ) : loginType === LoginTypeEnum.CardNumber ? (
                  <FormMaskedInputControl
                    name="careCardNumber"
                    label={t('Neo Carecard Number', { ns: 'common' })}
                    id="careCardNumberId"
                    mask="999-999-999-9"
                    tabIndex={1}
                    minHeight="48px"
                    backgroundColor="transparent"
                  />
                ) : null}

                {!isCardNumberLogin && (
                  <ForgotPasswordWrapper>
                    <Text
                      fontSize="12px"
                      cursor="pointer"
                      onClick={() => {
                        mixTrack({
                          eventName: 'login:forgot_password_clicked',
                        });

                        dispatch(push(SignInRoutes.ResetPassword));
                      }}
                    >
                      {t(`Forgot ${isCardNumberLogin ? 'Pin' : 'Password'}`, { ns: 'common' })}?
                    </Text>
                  </ForgotPasswordWrapper>
                )}
                {loginType === LoginTypeEnum.CardNumber ? (
                  <FormPasswordControl
                    autoComplete="on"
                    size="lg"
                    name="pin"
                    label={t('Pin', { ns: 'common' })}
                    id="pinId"
                    tabIndex={2}
                    formControlProps={{
                      mb: '0',
                      maxWidth: '12rem',
                    }}
                  />
                ) : (
                  <FormPasswordControl
                    autoComplete="on"
                    size="lg"
                    name="password"
                    label={t('Password', { ns: 'common' })}
                    id="passwordId"
                    tabIndex={2}
                    formControlProps={{
                      mb: '0',
                    }}
                  />
                )}

                <Flex align="flex-end" mb="12">
                  <PrimaryButton
                    marginBottom="-20px"
                    w={['100%', '40%']}
                    tabIndex={3}
                    size="lg"
                    type="submit"
                  >
                    {t('Log in', { ns: 'common' })}
                  </PrimaryButton>
                  <Box height="80px">
                    <Image height="80px" src={curledArrow} transform="rotate(160deg)" />
                  </Box>
                </Flex>

                <SignUpRow>
                  <HStack>
                    <UserIcon />
                    {prohibitPatientRegistration ? (
                      <Text
                        fontSize={OutpostTheme.FontSizes.body}
                        dangerouslySetInnerHTML={{ __html: patientRegistrationMessage }}
                      />
                    ) : (
                      <Text fontSize={OutpostTheme.FontSizes.body}>
                        {t('Not')} {t(getNounPrefix(companyName), { ns: 'common' })}{' '}
                        {t(companyName)} {t('Member')}?
                        <Text
                          cursor="pointer"
                          as="span"
                          color="#00A18F"
                          onClick={() => {
                            mixTrack({
                              eventName: 'login:patient_sign_up_clicked',
                            });

                            const from = location.state
                              ? encodeURIComponent(
                                  `${(location.state as any).from.pathname}${
                                    (location.state as any).from.search
                                  }`,
                                ) ?? ''
                              : '';
                            dispatch(push(`${SignInRoutes.SignUpType}?goTo=${from}`));
                          }}
                        >
                          {' '}
                          {t('Create an account')}.{' '}
                        </Text>
                      </Text>
                    )}
                  </HStack>

                  <Divider style={{ background: '#999' }} my="3" />

                  <HStack>
                    <CrossIcon />
                    {prohibitProviderRegistration ? (
                      <Text
                        fontSize={OutpostTheme.FontSizes.body}
                        dangerouslySetInnerHTML={{ __html: providerRegistrationMessage }}
                      />
                    ) : (
                      <Text fontSize={OutpostTheme.FontSizes.body}>
                        {t('Are you a Provider or Healthcare Facility')}?
                        <Text
                          cursor="pointer"
                          as="a"
                          color="#00A18F"
                          // @ts-ignore
                          href={SignInRoutes.ProviderSignUp}
                          onClick={() => {
                            mixTrack({
                              eventName: 'login:provider_sign_up_clicked',
                            });
                          }}
                        >
                          {' '}
                          {t('Register Here')}.{' '}
                        </Text>
                      </Text>
                    )}
                  </HStack>
                </SignUpRow>
              </>
            )}
          </Form>
        )}
      </Formik>
      <MobileRedirect />
    </>
  );
};
export default LoginView;

const SignUpRow = chakra(Flex, {
  baseStyle: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    mb: '30px',
  },
});

const ForgotPasswordWrapper = chakra(Flex, {
  baseStyle: {
    position: 'relative',
    bottom: '-20px',
    justifyContent: 'flex-end',
    zIndex: 99,
  },
});
