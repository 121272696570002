import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RolesEnum } from '../generated';
import { getAppFeatures } from '../store/appFeatures/appFeaturesCreators';
import { AppState } from '../store/root-reducers';
import { isInRole } from '../utils/isInRole';

interface IProps {
  featureName: string;
}

const useAppFeature = ({ featureName }: IProps) => {
  const dispatch = useDispatch();
  const appFeatures = useSelector((state: AppState) => state.appFeaturesState.appFeatures);
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  useEffect(() => {
    if (
      currentUser &&
      appFeatures.length === 0 &&
      (isInRole(currentUser, RolesEnum.Patient) || isInRole(currentUser, RolesEnum.Provider))
    ) {
      dispatch(getAppFeatures());
    }
  }, [appFeatures.length, currentUser, dispatch]);

  return appFeatures.filter((x) => x.name === featureName && x.enabled).length !== 0;
};

export default useAppFeature;
