/* eslint-disable @typescript-eslint/default-param-last */
import {
  BloodGlucoseTypeEnum,
  HeightEnum,
  TemperatureTypeEnum,
  WeightEnum,
  WeightHeightEnum,
} from '../generated';
export const convertHeightWeight = (
  shouldRound: boolean,
  weight = 0,
  height = 0,
  toUnit: WeightHeightEnum,
  fromUnit: WeightHeightEnum = WeightHeightEnum.Metric,
) => {
  let convertedWeight = weight;
  let convertedHeight = height;
  if (fromUnit === WeightHeightEnum.Metric && toUnit === WeightHeightEnum.Imperial) {
    convertedWeight = convertWeight(shouldRound, weight, WeightEnum.Lbs);
    convertedHeight = convertHeight(shouldRound, height, HeightEnum.In);
  } else if (fromUnit === WeightHeightEnum.Imperial && toUnit === WeightHeightEnum.Metric) {
    convertedWeight = convertWeight(shouldRound, weight, WeightEnum.Kg, WeightEnum.Lbs);
    convertedHeight = convertHeight(shouldRound, height, HeightEnum.Cm, HeightEnum.In);
  }
  return { weight: convertedWeight, height: convertedHeight };
};

export const convertWeight = (
  shouldRound: boolean,
  value = 0,
  toUnit: WeightEnum,
  fromUnit: WeightEnum = WeightEnum.Kg,
) => {
  const KG_LB_CONSTANT = 2.20462;
  if (!value || value === 0) return value;

  let converted = value;
  if (fromUnit === WeightEnum.Lbs && toUnit === WeightEnum.Kg) {
    converted = value / KG_LB_CONSTANT;
  } else if (fromUnit === WeightEnum.Kg && toUnit === WeightEnum.Lbs) {
    converted = value * KG_LB_CONSTANT;
  }

  return shouldRound ? Math.round(converted * 100) / 100 : converted;
};

export const convertHeight = (
  shouldRound: boolean,
  value = 0,
  toUnit: HeightEnum,
  fromUnit: HeightEnum = HeightEnum.Cm,
) => {
  const CM_IN_CONSTANT = 2.54;
  if (value === 0) return value;

  let converted = value;
  if (fromUnit === HeightEnum.Cm && toUnit === HeightEnum.In) {
    converted = value / CM_IN_CONSTANT;
  } else if (fromUnit === HeightEnum.In && toUnit === HeightEnum.Cm) {
    converted = value * CM_IN_CONSTANT;
  }

  return shouldRound ? Math.round(converted * 100) / 100 : converted;
};

export interface IHeightValues {
  centimeters?: number | null;
  feet?: number | null;
  inches?: number | null;
}
export class HeightValues implements IHeightValues {
  centimeters: number | null;

  feet: number | null;

  inches: number | null;

  constructor(
    centimeters: number | null = null,
    feet: number | null = null,
    inches: number | null = null,
  ) {
    this.centimeters = centimeters;
    this.feet = feet;
    this.inches = inches;
  }
}
export const convertHeight2 = (
  shouldRound: boolean,
  values: IHeightValues,
  toUnit: HeightEnum,
  fromUnit: HeightEnum = HeightEnum.Cm,
): IHeightValues => {
  const IN_CM_CONSTANT = 2.54;
  const FT_CM_CONSTANT = 30.48;
  const FT_IN_CONSTANT = 12;
  const getFeet = (centimeters) => Math.floor(centimeters / FT_CM_CONSTANT);
  const getInches = (centimeters, feet) => {
    const inches = centimeters / IN_CM_CONSTANT - feet * FT_IN_CONSTANT;
    return shouldRound ? Math.round(inches * 100) / 100 : inches;
  };
  const getKilograms = (feet, inches) => {
    const centimeters = feet * FT_CM_CONSTANT + inches * IN_CM_CONSTANT;
    return shouldRound ? Math.round(centimeters * 100) / 100 : centimeters;
  };
  const convertedValues = new HeightValues();
  if (fromUnit === HeightEnum.Cm && toUnit === HeightEnum.In) {
    convertedValues.feet = getFeet(values.centimeters);
    convertedValues.inches = getInches(values.centimeters, convertedValues.feet);
  } else if (fromUnit === HeightEnum.In && toUnit === HeightEnum.Cm) {
    convertedValues.centimeters = getKilograms(values.feet, values.inches);
  } else if (values.centimeters) {
    convertedValues.centimeters = Math.round(values.centimeters * 100) / 100;
  }
  return convertedValues;
};

export const convertBloodGlucose = (
  shouldRound: boolean,
  value = 0,
  toUnit: BloodGlucoseTypeEnum,
  fromUnit: BloodGlucoseTypeEnum = BloodGlucoseTypeEnum.MmolL,
) => {
  const MMOL_TO_MG_CONSTANT = 18;
  if (value === 0) return value;

  let converted = value;
  if (fromUnit === BloodGlucoseTypeEnum.MmolL && toUnit === BloodGlucoseTypeEnum.MgdL) {
    converted = value * MMOL_TO_MG_CONSTANT;
  } else if (fromUnit === BloodGlucoseTypeEnum.MgdL && toUnit === BloodGlucoseTypeEnum.MmolL) {
    converted = value / MMOL_TO_MG_CONSTANT;
  }

  return shouldRound ? Math.round(converted * 100) / 100 : converted;
};

export const convertTemperature = (
  shouldRound: boolean,
  value = 0,
  toUnit: TemperatureTypeEnum,
  fromUnit: TemperatureTypeEnum = TemperatureTypeEnum.Celsius,
) => {
  const C_TO_F_CONSTANT = 1.8;
  const F_TO_C_CONSTANT = 5 / 9;

  let converted = value;
  if (fromUnit === TemperatureTypeEnum.Celsius && toUnit === TemperatureTypeEnum.Fahrenheit) {
    converted = value * C_TO_F_CONSTANT + 32;
  } else if (
    fromUnit === TemperatureTypeEnum.Fahrenheit &&
    toUnit === TemperatureTypeEnum.Celsius
  ) {
    converted = (value - 32) * F_TO_C_CONSTANT;
  }

  return shouldRound ? Math.round(converted * 10) / 10 : converted;
};

export const calculateBmi = (
  weight: number,
  height: number,
  unit: WeightHeightEnum = WeightHeightEnum.Metric,
  decimalPlace = 2,
) => {
  const coeff = Math.pow(10, decimalPlace);
  return (
    Math.round(
      (weight / Math.pow(height / (unit === WeightHeightEnum.Metric ? 100 : 1), 2)) *
        (unit === WeightHeightEnum.Metric ? 1 : 703) *
        coeff,
    ) / coeff
  );
};
