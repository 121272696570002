import React from 'react';
import { Paragraph } from '../../../../../../components/common/Paragraph';
import ProviderSearchInput from '../../../../../../components/common/ProviderSearch/ProviderSearchInput';
import { FormError } from '../../../../../../components/forms';
import { ProviderSettingEnum } from '../../../../../../generated';
import useProviderConsultContext from '../../hooks/useProviderConsultContext';

const Resident = (): JSX.Element => {
  const {
    patientCountry,
    phrConsult,
    validationMessages,
    attestationProvider,
    setAttestationProvider,
  } = useProviderConsultContext();

  return (
    <>
      {phrConsult?.attestationUserDetailID ? (
        <Paragraph>
          {/* Once a Preceptor has been selected you will not be able to select a different one. If
              you edit your consult it will be reassigned to the selected Preceptor to be
              re-attested.
              <br /> */}
          {phrConsult.isAttested
            ? `Attested By: ${phrConsult.attestationProvider?.fullName}`
            : `Assigned Preceptor: ${phrConsult.attestationProvider?.fullName}`}{' '}
        </Paragraph>
      ) : (
        <>
          <ProviderSearchInput
            inputTitle={`This consult is applicable for attestation. Please select a preceptor ${process.env.REACT_APP_PROVIDER_TITLE} to attest this consult.`}
            selectedProvider={attestationProvider}
            setSelectedProvider={setAttestationProvider}
            providerSettingEnum={ProviderSettingEnum.PreceptorProvider}
            providerSettingValue="true"
            defaultCountryID={phrConsult?.countryID ?? patientCountry?.countryID}
            modalTitle="Search for a Preceptor"
          />
          <FormError touched={true} error={validationMessages?.preceptor} />
        </>
      )}
    </>
  );
};

export { Resident };
