import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { SystemSettingsEnum } from '../../constants';
import { getSystemSettingActions, SystemSettingsActionTypes } from './systemSettingsAction';

export interface ISystemSettingValue {
  name: SystemSettingsEnum;
  value: string;
}

const systemSettings = createReducer<ISystemSettingValue[], SystemSettingsActionTypes>(
  [],
).handleAction([getSystemSettingActions.success], (_state, action) => action.payload);

const isLoading = createReducer<boolean, SystemSettingsActionTypes>(false).handleAction(
  [getSystemSettingActions.success, getSystemSettingActions.failure],
  () => false,
);

const error = createReducer<string, SystemSettingsActionTypes>('').handleAction(
  [getSystemSettingActions.failure],
  (_state, action) => action.payload,
);

export interface ISystemSettingsState {
  systemSettings: ISystemSettingValue[];
  error: string;
  isLoading: boolean;
}

export default combineReducers<ISystemSettingsState>({
  systemSettings,
  error,
  isLoading,
});
