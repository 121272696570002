import { Tag } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { HouseIcon, MessageIcon, MyNetworkIcon } from '../../../assets/icons';
import { DashboardRoutes, MessageCenterRoutes, MyNetworkRoutes } from '../../../constants';
import useNotification from '../../../hooks/useNotification';
import useTranslationComponent from '../../../hooks/useTranslationComponent';
import { AppState } from '../../../store/root-reducers';

import { MenuItem } from './components/MenuItem';
import { SideMenu } from './components/SideMenu';

export const HomeCareAdminSideMenu = () => {
  const { t } = useTranslationComponent(['network']);
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const usersCount = useSelector(
    (state: AppState) => state.currentChatUserState.unreadUsersMessageCount,
  );
  const groupCount = useSelector(
    (state: AppState) => state.currentChatUserState.unreadGroupMessageCount,
  );
  const totalCount = usersCount + groupCount;
  const { mail } = useNotification({ currentUser });

  return (
    <SideMenu>
      <MenuItem routes={[DashboardRoutes.Dashboard]} Icon={HouseIcon}>
        {t('Dashboard')}
      </MenuItem>
      <MenuItem
        Icon={MessageIcon}
        routes={[
          MessageCenterRoutes.InboxMail,
          MessageCenterRoutes.Mail,
          MessageCenterRoutes.SentMail,
          MessageCenterRoutes.ComposeMail,
          MessageCenterRoutes.DeletedMail,
          MessageCenterRoutes.AddressBook,
        ]}
      >
        {t('Messages')}
        {!!mail.count && (
          <Tag ml={4} size="sm" colorScheme="blue">
            {mail.count}
          </Tag>
        )}
      </MenuItem>

      <MenuItem routes={[MyNetworkRoutes.HomePage]} Icon={MyNetworkIcon}>
        {t('My Network')}
        {totalCount > 0 && (
          <Tag ml={4} size="sm" colorScheme="blue">
            {totalCount}
          </Tag>
        )}
      </MenuItem>
    </SideMenu>
  );
};
