import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { IPartner } from '../../generated';

export const setActions = createAsyncAction(
  ['SET_CURRENT_PARTNER_REQUEST', () => {}],
  ['SET_CURRENT_PARTNER_SUCCESS', (res: IPartner) => res],
  ['SET_CURRENT_PARTNER_ERROR', (err: string) => err],
)();

export const clearAction = createAction('CLEAR_CURRENTPARTNER')();

export const clearErrorAction = createAction('CLEAR_CURRENTPARTNER_ERROR', () => {})();

export type CurrentPartnerActionTypes = ActionType<
  typeof setActions | typeof clearAction | typeof clearErrorAction
>;
