import { FC } from 'react';

import useProviderConsultContext from '../../hooks/useProviderConsultContext';

import { SmallStyledTextArea } from '../Styled';

const NurseNotes: FC = () => {
  const { setNurseNotes, nurseNotes, validationMessages, debouncedUpdateSOAPNotes } =
    useProviderConsultContext();

  return (
    <SmallStyledTextArea
      name="nurseNotes"
      onChange={(e) => {
        setNurseNotes(e.target.value);
        debouncedUpdateSOAPNotes();
      }}
      value={nurseNotes || ''}
      label="Nurse Notes"
      formControlProps={{ error: validationMessages?.nurseNotes }}
    />
  );
};

export { NurseNotes };
