import useAppFeature from '../hooks/useAppFeature';

interface IProps {
  featureName: string;
  children?: any;
}

const AppFeatureComponent = ({ featureName, children }: IProps) => {
  const isActive = useAppFeature({ featureName });

  return isActive ? children : null;
};

export default AppFeatureComponent;
