import { IconButton } from '@chakra-ui/react';
import { MouseEventHandler } from 'react';
import { HeartFilledIcon2, HeartIcon } from '../../../../assets/icons';
import { AddressBook } from '../../../../generated';

interface IProps {
  addressBook: AddressBook;
  userDetailID?: string;
  onToggleFavorite?: MouseEventHandler<HTMLButtonElement>;
}

export const AddressBookFavoriteIcon = ({
  addressBook,
  userDetailID,
  onToggleFavorite,
}: IProps) => {
  return (
    <IconButton
      aria-label={addressBook.isFavorite ? 'Remove from favorite' : 'Add to favorite'}
      bgColor="transparent"
      _hover={userDetailID ? { bgColor: 'rgb(219, 217, 217)' } : undefined}
      padding="1"
      onClick={onToggleFavorite}
      cursor={userDetailID ? 'pointer' : 'not-allowed'}
      height="100%"
    >
      {addressBook.isFavorite ? (
        <HeartFilledIcon2 fontSize="20px" />
      ) : (
        <HeartIcon fontSize="20px" />
      )}
    </IconButton>
  );
};
