import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
  FormErrorMessage,
  Text,
  FormLabel,
  FormLabelProps,
} from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import NoticeIcon from '../../NoticeIcon';

export type FormControlProps = Omit<ChakraFormControlProps, 'label'> & {
  name?: string;
  label: any;
  testid?: string;
  touched?: boolean;
  valid?: string;
  error?: string;
  tooltip?: any;
  hideError?: boolean;
  children: ReactNode;
  formLabelProps?: FormLabelProps;
};

export const FormControl = (props: FormControlProps) => {
  const {
    testid,
    touched,
    valid,
    error,
    mb = 4,
    label,
    tooltip,
    children,
    hideError,
    name,
    // Pulling off some formik values to keep errors out of console
    // @ts-ignore
    initialError,
    // @ts-ignore
    initialValue,
    // @ts-ignore
    initialTouched,
    // @ts-ignore
    isChecked,
    formLabelProps,
    ...rest
  } = props;

  return (
    <ChakraFormControl mb={mb} isInvalid={touched && !!error} {...rest}>
      {label && (
        <FormLabel
          textTransform="lowercase"
          color="#000000"
          fontSize="14px"
          fontWeight="500"
          display="flex"
          fontFamily="Poppins"
          htmlFor={name}
          {...formLabelProps}
        >
          {label} <NoticeIcon tooltip={tooltip} />
        </FormLabel>
      )}
      {children}
      {hideError ? null : (
        <FormErrorMessage data-testid={`${testid}-error`}>{error}</FormErrorMessage>
      )}
      {valid && (
        <Text color="green.400" mt="0.5rem" fontSize="0.875rem" fontFamily="Poppins">
          {valid}
        </Text>
      )}
    </ChakraFormControl>
  );
};
