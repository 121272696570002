import { PHRMedication } from '../../../../generated';

const getFreshPrhMedication = (phrMedication: PHRMedication) => {
  const newPhrMedication: Partial<PHRMedication> = {
    name: phrMedication.name,
    form: phrMedication.form,
    quantity: phrMedication.quantity,
    frequency: phrMedication.frequency,
    quantityPerDose: phrMedication.quantityPerDose,
    methodOfIntake: phrMedication.methodOfIntake,
    durationTypeEnum: phrMedication.durationTypeEnum,
    durationCount: phrMedication.durationCount,
    startDate: phrMedication.startDate,
    endDate: phrMedication.endDate,
    notes: phrMedication.notes,
    refills: phrMedication.refills,
    prn: phrMedication.prn,
    prnReason: phrMedication.prnReason,
    instructions: phrMedication.instructions,
    dispensingInstructions: phrMedication.dispensingInstructions,
    medicationID: phrMedication.medicationID,
    packageQuantity: phrMedication.packageQuantity,
    totalAmount: phrMedication.totalAmount,
  };

  return newPhrMedication;
};

export { getFreshPrhMedication };
