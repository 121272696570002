import { AuthClient, PartnersClient, PartnerSearchDetails } from '../../generated';
import { httpRequest } from '../../utils';
import { OHThunkResult } from '../root-reducers';
import { setActions } from './currentPartnerActions';

export function getCurrentPartner(data: PartnerSearchDetails): OHThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    return new Promise<void>(async () => {
      dispatch(setActions.request());
      const partnerClient = new PartnersClient(new AuthClient());
      try {
        const result = await httpRequest(() => partnerClient.search(data));
        if (result) {
          dispatch(setActions.success(result));
        } else {
          throw new Error('Unable to retrieve User.');
        }
      } catch (err) {
        dispatch(setActions.failure(err as string));
      }
    });
  };
}
