import { Grid, GridItem, Text } from '@chakra-ui/react';
import { ErrorMessage, FormikErrors, FormikTouched } from 'formik';
import { Dispatch, SetStateAction, useEffect } from 'react';

import { EMPTY_GUID } from '../../../constants';
import { CityTown, ProvinceState } from '../../../generated';
import useTranslationComponent from '../../../hooks/useTranslationComponent';
import { IForm } from '../../UpdatePatientsAddressModal/UpdatePatientsAddressModal';

import { FormInputControl } from '../InputControl';
import { SelectComponent } from '../Selects';

import useAddressPickerContext from './useAddressPickerContext';

export interface IAddressFormValues {
  addressLine1: string | undefined;
  addressLine2: string | undefined;
  cityTown: CityTown | undefined;
  city: string | undefined;
  provinceState: ProvinceState | undefined;
  province: string | undefined;
  postCode: string | undefined;
}

export interface IProvinceOption {
  name: string;
  provinceStateID: string;
  __isNew__?: boolean;
}

export interface ICityTownOption {
  name: string;
  cityTownID: string;
  __isNew__?: boolean;
}

interface IProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setFieldError?: (field: string, message?: string) => void;
  touched?: FormikTouched<IForm & { cityTown?: string; provinceState?: string }>;
  errors?: FormikErrors<IForm & { cityTown?: string; provinceState?: string }>;
  values?: IForm;
  validateForm?: (values?: any) => Promise<FormikErrors<IForm>>;
  errorStateHelper?: {
    city: string;
    province: string;
  };
  setErrorStateHelper?: Dispatch<
    SetStateAction<{
      city: string;
      province: string;
    }>
  >;
}

export const AddressPicker = ({
  setFieldValue,
  setFieldError,
  touched,
  errors,
  values,
  validateForm,
  errorStateHelper,
  setErrorStateHelper,
}: IProps) => {
  const { t } = useTranslationComponent(['profile', 'validation']);
  const {
    provinceStateOptions,
    provinceState,
    setProvinceState,
    isLoadingProvinceStates,
    cityTownOptions,
    cityTown,
    setCityTown,
    isLoadingCityTowns,
    country,
  } = useAddressPickerContext();

  useEffect(() => {
    if (values && setFieldError && touched && errorStateHelper) {
      if (!provinceState && !values.province && (touched.province || touched.provinceState)) {
        setFieldError(errorStateHelper.province, t('State is required', { ns: 'validation' }));
      }
      if (!cityTown && !values.city && (touched.city || touched.cityTown)) {
        setFieldError(errorStateHelper.city, t('City is required', { ns: 'validation' }));
      }
    }
  }, [
    touched,
    values,
    provinceState,
    cityTown,
    errors,
    validateForm,
    setFieldError,
    errorStateHelper,
    t,
  ]);

  return (
    <>
      <Grid templateColumns={'repeat(6, 1fr)'} gap={{ md: 7 }} mt={{ md: '4' }} mb={{ md: '3' }}>
        <GridItem colSpan={{ base: 6, md: 3 }}>
          <FormInputControl name="addressLine1" type="text" label={t('Address Line 1')} />
        </GridItem>
        <GridItem colSpan={{ base: 6, md: 3 }}>
          <FormInputControl name="addressLine2" type="text" label={t('Address Line 2')} />
        </GridItem>
      </Grid>
      <Grid templateColumns={'repeat(6, 1fr)'} gap={{ md: 7 }}>
        <GridItem colSpan={{ base: 6, md: 3, lg: 2 }}>
          <SelectComponent
            name="provinceState"
            value={provinceState}
            isSearchable={true}
            isCreatable={true}
            label={t(country?.provinceStateLabel || 'Province')}
            options={provinceStateOptions}
            valueKey="provinceStateID"
            labelKey="name"
            onChange={(option: IProvinceOption) => {
              if (!option) {
                setFieldValue('provinceState', undefined);
                setFieldValue('provinceStateID', undefined);
                setFieldValue('province', undefined);
                setErrorStateHelper?.bind(null, (state) => ({
                  ...state,
                  province: 'provinceState',
                }));
              } else if (option.provinceStateID === EMPTY_GUID) {
                option.__isNew__ = true;
                setFieldValue('provinceState', undefined);
                setFieldValue('provinceStateID', undefined);
                setFieldValue('province', option.name);
                setErrorStateHelper?.bind(null, (state) => ({ ...state, province: 'province' }));
              } else {
                setFieldValue('provinceStateID', option.provinceStateID);
                setFieldValue('province', undefined);
                setFieldValue('provinceState', option);
                setErrorStateHelper?.bind(null, (state) => ({
                  ...state,
                  province: 'provinceState',
                }));
              }
              setProvinceState(option);
              setCityTown(null);
              setFieldValue('cityTownID', '');
              setFieldValue('city', undefined);
            }}
            getNewOptionData={(inputValue) => ({
              name: inputValue,
              provinceStateID: EMPTY_GUID,
            })}
            noOptionsMessage={() => t('No Province found')}
            isLoading={isLoadingProvinceStates}
            formControlProps={{ mb: '2' }}
          />
          {errors && errorStateHelper ? (
            !!errors[errorStateHelper.province] && (
              <Text color="#e64747" fontSize="14px">
                {errors[errorStateHelper.province]}
              </Text>
            )
          ) : (
            <ErrorMessage name="provinceState">
              {(error) => (
                <Text color="#e64747" fontSize="14px">
                  {error}
                </Text>
              )}
            </ErrorMessage>
          )}
        </GridItem>
        <GridItem colSpan={{ base: 6, md: 3, lg: 2 }}>
          <SelectComponent
            name="cityTown"
            isSearchable={true}
            isCreatable={true}
            value={cityTown}
            label={t('City')}
            options={cityTownOptions}
            valueKey="cityTownID"
            labelKey="name"
            onChange={(option: ICityTownOption) => {
              if (!option) {
                setFieldValue('cityTown', undefined);
                setFieldValue('cityTownID', undefined);
                setFieldValue('city', undefined);
                setErrorStateHelper?.bind(null, (state) => ({ ...state, city: 'cityTown' }));
              } else if (option.cityTownID === EMPTY_GUID) {
                option.__isNew__ = true;
                setFieldValue('cityTown', undefined);
                setFieldValue('cityTownID', undefined);
                setFieldValue('city', option.name);
                setErrorStateHelper?.bind(null, (state) => ({ ...state, city: 'city' }));
              } else {
                setFieldValue('cityTownID', option.cityTownID);
                setFieldValue('city', undefined);
                setFieldValue('cityTown', option);
                setErrorStateHelper?.bind(null, (state) => ({ ...state, city: 'cityTown' }));
              }
              setCityTown(option);
            }}
            getNewOptionData={(inputValue) => ({
              name: inputValue,
              cityTownID: EMPTY_GUID,
            })}
            noOptionsMessage={() => 'No City found'}
            isLoading={isLoadingCityTowns}
            formControlProps={{ mb: '2' }}
          />
          {errors && errorStateHelper ? (
            !!errors[errorStateHelper.city] && (
              <Text color="#e64747" fontSize="14px">
                {errors[errorStateHelper.city]}
              </Text>
            )
          ) : (
            <ErrorMessage name="cityTown">
              {(error) => (
                <Text color="#e64747" fontSize="14px">
                  {error}
                </Text>
              )}
            </ErrorMessage>
          )}
        </GridItem>
        <GridItem colSpan={{ base: 6, md: 3, lg: 2 }} mt={{ md: '-4', lg: '0' }}>
          <FormInputControl name="postCode" type="text" label={t('Postal Code')} />
        </GridItem>
      </Grid>
    </>
  );
};
