import React from 'react';
import { Box } from '@chakra-ui/react';

interface IProps {
  ThinView: any;
}

const TrackerDetailCardComponent: React.FC<IProps> = ({ ThinView }) => {
  return (
    <Box marginX={{ base: '1rem', md: '2.5rem' }} marginBottom="1rem">
      <ThinView />
    </Box>
  );
};

export default TrackerDetailCardComponent;
