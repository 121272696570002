import { CometChat } from '@cometchat-pro/chat';
import { ChatSettingsEnum, EMPTY_GUID } from '../../constants';
import { getBaseUrl } from '../../utils/getUrl';
import { getUserThumbnail } from '../../utils/getUserThumbnail';
import { ICurrentUser } from '../currentUser/currentUserReducers';
import { OHThunkResult } from '../root-reducers';
import {
  clearAction,
  setActions,
  unreadGroupMessageAction,
  unreadUsersMessageAction,
} from './currentChatUserActions';

export function loginCurrentChatUser(
  user: ICurrentUser,
  dispatch,
  authKey = ChatSettingsEnum.REACT_APP_COMETCHAT_AUTH_KEY,
) {
  dispatch(setActions.request());
  CometChat.login(user.userDetailID, authKey)
    .then(
      (chatUser) => {
        dispatch(setActions.success(chatUser));
        chatUser.setName(`${user?.firstName} ${user?.lastName}`);
        if (user?.imageDetailID && user?.imageDetailID !== EMPTY_GUID) {
          chatUser.setAvatar(getBaseUrl() + getUserThumbnail(user?.imageDetailID)!);
        }
        CometChat.updateCurrentUserDetails(chatUser).then(
          (updatedUser) => {
            dispatch(setActions.success(updatedUser));
          },
          () => {},
        );
      },
      (error) => {
        if (error.hasOwnProperty('code') && error.code === 'ERR_UID_NOT_FOUND') {
          const newUser = new CometChat.User(user?.userDetailID);
          newUser.setName(`${user?.firstName} ${user?.lastName}`);
          if (user?.imageDetailID && user?.imageDetailID !== EMPTY_GUID) {
            newUser.setAvatar(getBaseUrl() + getUserThumbnail(user?.imageDetailID)!);
          }
          CometChat.createUser(newUser, authKey)
            .then(() => {
              CometChat.login(user?.userDetailID, authKey)
                .then((newChatUser) => {
                  dispatch(setActions.success(newChatUser));
                })
                .catch(() => {});
            })
            .catch(() => {});
        }
      },
    )
    .catch(() => {});
}

export function getUnreadChatCount(): OHThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    return new Promise<void>(async () => {
      try {
        const res = await CometChat.getUnreadMessageCountForAllUsers();
        const sum1 =
          Object.keys(res).length > 0
            ? Object.keys(res)
                .map((x) => res[x])
                .reduce((t, a) => t + a)
            : 0;
        dispatch(unreadUsersMessageAction(sum1));

        const res2 = await CometChat.getUnreadMessageCountForAllGroups();
        const sum2 =
          Object.keys(res2).length > 0
            ? Object.keys(res2)
                .map((x) => res2[x])
                .reduce((t, a) => t + a)
            : 0;
        dispatch(unreadGroupMessageAction(sum2));
      } catch (err) {
        // console.log("Unread Chat Error: ", err)
      }
    });
  };
}

export function checkCurrentChatUserStatus(): OHThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    return new Promise<void>(async () => {
      dispatch(setActions.request());
      CometChat.getLoggedinUser()
        .then((user) => {
          dispatch(setActions.success(user));
        })
        .catch((error) => {
          dispatch(setActions.failure(error));
        });
    });
  };
}

export function logoutCurrentChatUser(): OHThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    return new Promise<void>(async () => {
      dispatch(setActions.request());
      CometChat.logout()
        .then(() => {
          dispatch(setActions.success(null));
          dispatch(clearAction());
        })
        .catch((error) => {
          dispatch(setActions.failure(error));
        });
    });
  };
}
