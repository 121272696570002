import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PatientDashboardTour from '../../../../../components/tours/PatientDashboard/PatientDashboardTour';
import { MarketingCampaignTypeEnum, RolesEnum } from '../../../../../generated';
import { AppState } from '../../../../../store/root-reducers';
import { isInRole } from '../../../../../utils/isInRole';
import { ClaimNote } from '../../../../Campaign/friendReferral/ClaimNote/ClaimNote';
import { useCheckUnacknowledgedRewards } from '../../hooks/useCheckUnacknowledgedRewards/useCheckUnacknowledgedRewards';
import { useLocation } from 'react-router-dom';
import { LocationState } from '../../../../layouts/components/AuthenticatedPrerequisites/AuthenticatedPrerequisites';

export const UnclaimedReward = () => {
  const { unacknowledgedReward, acknowledgeReward, isFirstTimeReward } =
    useCheckUnacknowledgedRewards(MarketingCampaignTypeEnum.FriendReferral);
  const [includeWelcomeMessage, setIncludeWelcomeMessage] = useState<boolean>(true);
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const location = useLocation<LocationState>();

  if (isFirstTimeReward && unacknowledgedReward?.marketingCampaignUserDetail) {
    return (
      <ClaimNote
        acknowledgeReward={() => {
          setIncludeWelcomeMessage(false);
          acknowledgeReward(unacknowledgedReward.marketingCampaignUserDetailRewardID);
        }}
        marketingCampaignUserDetail={unacknowledgedReward.marketingCampaignUserDetail}
      />
    );
  } else if (isInRole(currentUser, RolesEnum.Patient) && !!location.state?.fromLogin) {
    return <PatientDashboardTour includeWelcomeMessage={includeWelcomeMessage} />;
  }

  return null;
};
