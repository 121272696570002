import { useQuery } from 'react-query';

import { AuthClient, Session, SessionClient } from '../../../generated';
import { httpRequest } from '../../../utils';

import { QueryKeys } from '../../keys';

interface IProps {
  sessionID?: string;
}

export const useSessionGet = ({ sessionID }: IProps) => {
  const {
    isLoading: isLoadingSession,
    data: session,
    error: sessionError,
  } = useQuery<Session | undefined, string>(
    [QueryKeys.SessionGet, sessionID],
    async () => httpRequest(() => new SessionClient(new AuthClient()).sessionGet(sessionID!)),
    {
      enabled: !!sessionID,
    },
  );

  return {
    isLoadingSession,
    session,
    sessionError,
  };
};
