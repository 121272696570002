import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { AddressBooksClient, AuthClient } from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

export interface IAddressBooksSetPrimaryProps {
  addressBookID: string;
  addressBookTagID: string;
}

export const useAddressBooksSetPrimary = () => {
  const queryClient = useQueryClient();
  const [error, setError] = useState<string>('');
  const addressBooksSetPrimary = useMutation(
    async ({ addressBookID, addressBookTagID }: IAddressBooksSetPrimaryProps) =>
      httpRequest(() =>
        new AddressBooksClient(new AuthClient()).setPrimary(addressBookID, addressBookTagID),
      ),
    {
      onSuccess: () => {
        setError('');
        queryClient.invalidateQueries(QueryKeys.AddressBooksSearch);
        queryClient.invalidateQueries(QueryKeys.AddressBooksPrivate);
        queryClient.invalidateQueries(QueryKeys.AddressBooksCollection);
      },
      onError: (err: string) => setError(err as any),
    },
  );

  return { error, addressBooksSetPrimary };
};
