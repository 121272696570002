import { CloseIcon } from '@chakra-ui/icons';
import { Box, Image, Wrap, WrapItem } from '@chakra-ui/react';
import { useState } from 'react';
import ImgsViewer from 'react-images-viewer';

interface IProps {
  patientID: string;
  phrDocumentIDs: string[];
  onDeleteImage?: (phrDocumentID: string) => void;
}

export const JournalImages = ({ phrDocumentIDs, patientID, onDeleteImage }: IProps) => {
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <>
      <Wrap shouldWrapChildren>
        {phrDocumentIDs.map((y, index) => (
          <WrapItem>
            <Box position="relative" width="100px">
              <Image
                borderRadius="4px"
                onClick={() => {
                  setCurrentImage(index);
                  setOpen(true);
                }}
                _hover={{ cursor: 'pointer' }}
                boxSize="100px"
                src={`/api/v1/PHRs/PHRDocuments/${patientID}/DocumentAsSrc/${y}`}
              />
              {!!onDeleteImage && (
                <CloseIcon
                  _hover={{
                    cursor: 'pointer',
                  }}
                  color="red.400"
                  position="absolute"
                  top="-5px"
                  right="-5px"
                  backgroundColor="white"
                  borderRadius="full"
                  p={1}
                  height="20px"
                  width="20px"
                  borderColor="red.400"
                  borderStyle="solid"
                  borderWidth="1px"
                  onClick={() => onDeleteImage(y)}
                />
              )}
            </Box>
          </WrapItem>
        ))}
      </Wrap>

      <ImgsViewer
        backdropCloseable
        imgs={phrDocumentIDs.map((y) => ({
          src: `/api/v1/PHRs/PHRDocuments/${patientID}/DocumentAsSrc/${y}`,
        }))}
        currImg={currentImage}
        isOpen={isOpen}
        onClickPrev={() =>
          setCurrentImage((prev) => (prev - 1 <= 0 ? phrDocumentIDs.length - 1 : prev - 1))
        }
        onClickNext={() =>
          setCurrentImage((prev) => (prev + 1 >= phrDocumentIDs.length ? 0 : prev + 1))
        }
        onClose={() => {
          setOpen(false);
        }}
      />
    </>
  );
};
