import React, { useState, useEffect } from 'react';

import { ConsultTagTypeEnum, PHRConsultTag } from '../../../../../../generated';

import { AutoComplete } from '../../../../../../components/forms/Selects/';
import { ConsultTagType } from '../../../components/enums';
import useProviderConsultContext from '../../hooks/useProviderConsultContext';

import { SmallStyledTextArea } from '../Styled';

const Diagnosis = (): JSX.Element => {
  const {
    tags,
    phrConsult,
    setDiagnosis,
    diagnosis,
    validationMessages,
    setPhrConsultTags,
    debouncedUpdateSOAPNotes,
  } = useProviderConsultContext();

  const [diagnosisTags, setDiagnosisTags] = useState<any[]>(
    (phrConsult?.phrConsultTags ?? [])
      .filter((x) => x.consultTagTypeEnum === ConsultTagType.Diagnosis)
      .map(
        (x) =>
          ({
            phrConsultID: x.phrConsultID,
            consultTagTypeEnum: x.consultTagTypeEnum,
            tagID: x.tagID,
            description: x.tag?.description,
          } as any),
      ),
  );

  useEffect(() => {
    setPhrConsultTags([
      ...(diagnosisTags ?? []).map(
        (x) =>
          ({
            phrConsultID: phrConsult?.phrConsultID,
            consultTagTypeEnum: ConsultTagTypeEnum.Diagnosis,
            tagID: x.tagID,
          } as PHRConsultTag),
      ),
    ]);
    // phrConsult.phrConsultID is saying undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagnosisTags]);

  return (
    <>
      <AutoComplete
        label="Diagnosis"
        value={diagnosisTags}
        isMulti={true}
        options={tags || []}
        valueKey="tagID"
        labelKey="description"
        name="diagnosisTags"
        onChange={(value) => {
          setDiagnosisTags(value);
          debouncedUpdateSOAPNotes();
        }}
        noOptionsMessage={() => 'No Diagnosis found'}
      />

      <SmallStyledTextArea
        name="diagnosis"
        onChange={(e) => {
          setDiagnosis(e.target.value);
          debouncedUpdateSOAPNotes();
        }}
        value={diagnosis || ''}
        label="Diagnosis Notes"
        formControlProps={{ error: validationMessages?.diagnosis }}
      />
    </>
  );
};

export { Diagnosis };
