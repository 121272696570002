import { ArrowBackIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';

import { CometChat } from '@cometchat-pro/chat';
import { push } from 'connected-react-router';
import { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../../../assets/images/neo-logo-black.svg';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import {
  getUnreadChatCount,
  loginCurrentChatUser,
} from '../../../../store/currentChatUser/currentChatUserCreators';
import { PartnerSettingsEnum } from '../../../../store/currentPartner/currentPartnerReducers';
import { AppState } from '../../../../store/root-reducers';
import usePageTitle from '../hooks/usePageTitle';
// @ts-ignore
import sound from '../../../../assets/sounds/incomingmessage.wav';
import { Avatar } from '../../../../components/common/Avatar';
import { OutpostTheme } from '../../../../themes/outpost';
import { getUserThumbnail } from '../../../../utils/getUserThumbnail';
import { PowerIcon, UserIconAlt } from '../../../../assets/icons';
import { logoutUser } from '../../../../store/root-creator';
import { goToApp } from '../../../../utils';

const playMessageAlert = () => {
  try {
    const messageAudio = new Audio(sound);
    messageAudio.currentTime = 0;
    messageAudio
      .play()
      .then(() => {})
      .catch(() => {});
  } catch (error) {}
};

const initializeListeners = (listenerID: string, dispatch: Dispatch<any>) => {
  try {
    CometChat.addMessageListener(
      listenerID,
      new CometChat.MessageListener({
        onTextMessageReceived: () => {
          playMessageAlert();
          dispatch(getUnreadChatCount());
        },
        onMediaMessageReceived: () => {
          playMessageAlert();
          dispatch(getUnreadChatCount());
        },
        onCustomMessageReceived: () => {
          playMessageAlert();
          dispatch(getUnreadChatCount());
        },
      }),
    );
  } catch (err) {}
};

export const NeoTopBar = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  // const provider = useSelector((state: AppState) => state.providerState.provider);
  // const patient = useSelector((state: AppState) => state.patientState.patient);
  const currentChatUser = useSelector((state: AppState) => state.currentChatUserState.data);

  const listenerID = currentUser?.userDetailID ?? '';

  useEffect(() => {
    const connectionListenerId = 'connect_' + new Date().getTime();
    if (currentChatUser) {
      dispatch(getUnreadChatCount());
      CometChat.addConnectionListener(
        connectionListenerId,
        new CometChat.ConnectionListener({
          onConnected: () => {
            initializeListeners(listenerID, dispatch);
          },
          inConnecting: () => {
            CometChat.removeMessageListener(listenerID);
          },
          onDisconnected: () => {
            CometChat.removeMessageListener(listenerID);
          },
        }),
      );
    } else {
      // console.log({ patient, provider });
      // if (provider) {
      loginCurrentChatUser(currentUser!, dispatch);
      // }
    }

    return () => {
      CometChat.removeConnectionListener(connectionListenerId);
    };
  }, [currentChatUser, currentUser, dispatch, listenerID]);

  const { title } = usePageTitle();

  const currentPartner = useSelector((state: AppState) => state.currentPartnerState.data);

  const img =
    currentPartner?.partnerSettings?.find(
      ({ partnerSettingName }) => partnerSettingName === PartnerSettingsEnum.APP_LOGO,
    )?.value || logo;

  const isOnNested = useSelector((state: AppState) => state.nestedViewState.isOnNested);

  const attemptLogout = () => {
    dispatch(logoutUser());
  };

  return (
    <Box
      pt={{ base: 0, '2xl': '1rem' }}
      backgroundColor="rgb(248, 248, 248)"
      position="fixed"
      zIndex="1011"
      width={{
        base: '100%',
        lg: 'calc(100% - 0px)',
        '2xl': 'calc(1535px - 0px - 40px - 0rem)',
      }}
      top={{ base: '0' }}
    >
      <Flex
        height={{ base: '72px', '2xl': '62px' }}
        backgroundColor="white"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        px={{ base: 2, lg: 6 }}
        borderRadius={{ base: 0, '2xl': '0.5rem' }}
      >
        <Flex alignItems="center">
          <Box display={{ base: 'block', lg: 'block' }} mr={{ base: 2, lg: 2 }}>
            {isOnNested && currentUser?.isInMobileView ? (
              <IconButton
                onClick={() => dispatch(push(isOnNested))}
                aria-label="back"
                backgroundColor="white"
                icon={<ArrowBackIcon fontSize="24px" />}
              />
            ) : null}
          </Box>

          <Breadcrumbs display={{ base: 'none', lg: 'none' }} />
          <Image src={img} />
        </Flex>

        <Heading display={{ base: 'none', lg: 'none' }} as="h1" fontSize="16px">
          {title}
        </Heading>

        <Box
          display={{ base: isOnNested ? 'none' : 'flex', md: 'flex' }}
          alignItems="center"
          gap={3}
        >
          {currentUser && (
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    display="flex"
                    alignItems="center"
                    as={IconButton}
                    variant="unstyled"
                    rightIcon={
                      <Box display={{ base: 'block' }}>
                        <ChevronDownIcon
                          fontSize={24}
                          transition="transform 0.2s ease"
                          transform={isOpen ? 'rotate(-180deg)' : 'rotate(0)'}
                        />
                      </Box>
                    }
                  >
                    <Avatar
                      size={OutpostTheme.Avatar.Size.Medium}
                      src={getUserThumbnail(currentUser?.imageDetailID)}
                    />
                  </MenuButton>
                  <MenuList gap={3} border="none" boxShadow="lg" minW="200px" mt={4} py={4}>
                    <Text fontSize="16px" fontWeight="500" ml={4} mb={2}>
                      {currentUser?.fullName ?? ''}
                    </Text>
                    <Text fontSize="14px" fontWeight="500" ml={4} mb={2}>
                      {currentUser?.emailAddress ?? ''}
                    </Text>
                    <MenuItem
                      onClick={() => goToApp(currentUser.userDetailID)}
                      // icon={<UserIconAlt fontSize="14px" />}
                      fontSize="14px"
                    >
                      Go to dashboard
                    </MenuItem>
                    <MenuItem
                      onClick={() => goToApp(currentUser.userDetailID, '/carefund')}
                      // icon={<UserIconAlt fontSize="14px" />}
                      fontSize="14px"
                    >
                      Return to carefund
                    </MenuItem>
                    <MenuItem
                      onClick={attemptLogout}
                      icon={<PowerIcon h="14px" w="14px" />}
                      fontSize="14px"
                      backgroundColor={'transparent'}
                      color="pallete.red"
                      _hover={{
                        background: 'pallete.red',
                        color: 'pallete.white',
                      }}
                      aria-label="Logout"
                      title="Logout"
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          )}
        </Box>

        <IconButton
          display={{ base: isOnNested ? 'block' : 'none', md: 'none' }}
          visibility="hidden"
          aria-label="spacer"
          backgroundColor="white"
          icon={<ArrowBackIcon fontSize="24px" />}
        />
      </Flex>
    </Box>
  );
};
