import { useEffect, useState } from 'react';
import { FormError, TextArea } from '../../../../../components/forms';
import { SelectComponent } from '../../../../../components/forms/Selects';
import Modal from '../../../../../components/Modals/Modal';
import useTranslationComponent from '../../../../../hooks/useTranslationComponent';

interface IProps {
  isOpen: boolean;
  onDismiss: () => void;
  onSubmit: (reason: string) => void;
}

const OTHER_ID = 9999;

const reasons = [
  {
    id: 1,
    label: 'Response to Monitoring',
  },
  {
    id: 2,
    label: 'Allergy',
  },
  {
    id: 3,
    label: 'Intolerance',
  },
  {
    id: 4,
    label: 'Change in medication / dose',
  },
  {
    id: 5,
    label: 'Compliance issues',
  },
  {
    id: 6,
    label: 'Rx no longer required',
  },
  {
    id: 7,
    label: 'Drug interacts with another drug',
  },
  {
    id: 8,
    label: 'Drug product discontinued or recalled',
  },
  {
    id: 9,
    label: 'Duplicate Therapy',
  },
  {
    id: 10,
    label: 'Disease',
  },
  {
    id: OTHER_ID,
    label: 'Other',
  },
];

export const DiscontinueMedicationModal = ({ isOpen, onDismiss, onSubmit }: IProps) => {
  const { t } = useTranslationComponent(['phr']);

  const [reason, setReason] = useState<any>();
  const [otherReason, setOtherReason] = useState<string>('');
  const [reasonError, setReasonError] = useState<string>('');

  useEffect(() => {
    if (reason || otherReason.length > 0) {
      setReasonError('');
    }
  }, [reason, otherReason]);

  const onDiscontinue = () => {
    if (!reason || (reason.id === OTHER_ID && otherReason.length === 0)) {
      setReasonError(t('A Reason is required'));
    } else {
      onSubmit(reason.id === OTHER_ID ? otherReason : reason.label);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('Are you sure you want to discontinue the selected medication(s)?')}
      onDismiss={onDismiss}
      onCancel={onDismiss}
      onSubmit={onDiscontinue}
      cancelText={t('Cancel')}
      submitText={t('Yes')}
    >
      <SelectComponent
        name="reason"
        valueKey="id"
        label={t('Reason')}
        labelKey="label"
        value={reason}
        options={reasons}
        onChange={setReason}
      />
      {reason?.id === OTHER_ID && (
        <TextArea
          name="reason"
          label={t('Reason')}
          value={otherReason}
          onChange={(e) => setOtherReason(e.target.value)}
        />
      )}
      <FormError touched={!!reasonError} error={reasonError} />
    </Modal>
  );
};
