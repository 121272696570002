import {
  Country,
  GenerateDocumentStateEnum,
  IPatient,
  OrderStatusEnum,
  PatientOrder,
} from '../../../../generated';

type GetInitializedOrderProps = {
  patientID: string;
  patient: IPatient;
  country: Country;
  providerID?: string;
  orderID: string;
  phrConsultID?: string;
};

const getInitializedOrder = (props: GetInitializedOrderProps) => {
  const patientOrder = new PatientOrder();
  patientOrder.init(patientOrder);
  patientOrder.orderID = props.orderID;
  patientOrder.orderStatusEnum = OrderStatusEnum.Draft;
  patientOrder.generateDocumentStateEnum = GenerateDocumentStateEnum.NotSet;
  patientOrder.patientID = props.patientID;
  patientOrder.providerID = props.providerID!;
  patientOrder.phrConsultID = props.phrConsultID;
  patientOrder.customerAddressLine1 = props.patient.addressLine1;
  patientOrder.customerAddressLine2 = props.patient.addressLine2;
  patientOrder.customerCity = props.patient.city;
  patientOrder.customerCityTownID = props.patient.cityTownID;
  patientOrder.customerProvince = props.patient.province;
  patientOrder.customerProvinceStateID = props.patient.provinceStateID;
  patientOrder.customerCountry = props.country?.name;

  return patientOrder;
};

export { getInitializedOrder };
