import React from 'react';
import { AddressBooksProvider } from './context/AddressBooksProvider';
import { AddressBookView } from './components/AddressBookView/AddressBookView';
import {
  AddressBook,
  AddressBookFolderEnum,
  AddressBookTypeEnum,
  ICountry,
  IPatient,
} from '../../generated';

export enum AddressBookModeEnum {
  Browse = 'Browse',
  Delivery = 'Delivery',
  InAppMail = 'InAppMail',
}

export enum AddressBookViewModeEnum {
  Page = 'Page',
  Modal = 'Modal',
}

export enum AddressBookDeliveryModeEnum {
  None = 'None',
  Single = 'Single',
  Multiple = 'Multiple',
}

export interface AddressBookComponentProps {
  country: ICountry;
  mode?: AddressBookModeEnum;
  viewMode?: AddressBookViewModeEnum;
  deliveryMode?: AddressBookDeliveryModeEnum;
  callback?: (addresses?: AddressBook[]) => void;
  onValidate?: (address: AddressBook) => boolean;
  validationMessage?: string;
  documentName?: string;
  documentRef?: any;
  defaultFolder?: AddressBookFolderEnum;
  defaultFolders?: AddressBookFolderEnum[];
  patient?: IPatient;
  heading?: string;
  onClose?: () => void;
  visibleFolders?: AddressBookFolderEnum[];
  visibleTypes?: AddressBookTypeEnum[];
  preSelectedAddress?: AddressBook;
}

const AddressBookComponent = ({
  country,
  mode,
  viewMode,
  deliveryMode,
  callback,
  onValidate,
  patient,
  validationMessage,
  documentName,
  documentRef,
  defaultFolder,
  defaultFolders,
  heading,
  onClose,
  visibleFolders,
  visibleTypes,
  preSelectedAddress,
}: AddressBookComponentProps) => {
  return (
    <AddressBooksProvider
      mode={mode}
      viewMode={viewMode}
      deliveryMode={deliveryMode}
      defaultCountry={country}
      callback={async (addresses?: AddressBook[]) => callback && (await callback(addresses))}
      onValidate={(address: AddressBook) => (onValidate ? onValidate(address) : true)}
      patient={patient}
      validationMessage={validationMessage}
      documentName={documentName}
      documentRef={documentRef}
      defaultFolder={defaultFolder}
      defaultFolders={defaultFolders}
      heading={heading}
      visibleFolders={visibleFolders}
      visibleTypes={visibleTypes}
      preSelectedAddress={preSelectedAddress}
    >
      <AddressBookView onClose={onClose} />
    </AddressBooksProvider>
  );
};

export default AddressBookComponent;
