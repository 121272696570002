import { Radio, RadioProps, WrapItem } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { radioButtonContext } from './RadioButtonContext';

export type RadioButtonControlProps = RadioProps & {
  dataFor?: string;
  dataTip?: string;
};

export const RadioButtonControl = (props: RadioButtonControlProps) => {
  const { dataFor, dataTip, value, ...rest } = props;
  const { getRadioProps } = useContext(radioButtonContext);

  // if (getRadioProps) {
  const radio = getRadioProps({ value });
  return (
    <WrapItem>
      <Radio
        variant="button"
        data-for={props.dataFor}
        data-tip={props.dataTip}
        {...radio}
        {...rest}
      />
    </WrapItem>
  );
};
