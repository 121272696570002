import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import { PHRAllergy } from '../../../../../generated';
import { getTime } from '../../../../../utils';

import PhrItem, { PHRItemList, EmptyPHRItemListCard } from '../../../components/PhrItem';
import { OutpostTheme } from '../../../../../themes/outpost';

interface IProps {
  phrAllergies: PHRAllergy[];
  onView: (phrAllergy: PHRAllergy) => void;
}

const AllergyList = ({ phrAllergies, onView }: IProps) => {
  return (
    <>
      {phrAllergies.length === 0 ? (
        <EmptyPHRItemListCard>No Allergies added</EmptyPHRItemListCard>
      ) : (
        <PHRItemList mobileHeightOffset="18rem" height="400px">
          {phrAllergies
            .sort((a, b) => getTime(b.upsertDate) - getTime(a.upsertDate))
            .map((item, index) => (
              <PhrItem
                key={index}
                name={item.otherName}
                isVerified={item.verified}
                onView={() => onView(item)}
                SecondaryInfo={<SecondaryInfoComponent item={item} />}
              />
            ))}
        </PHRItemList>
      )}
    </>
  );
};

interface ISecondaryInfoComponentProps {
  item: PHRAllergy;
}

const SecondaryInfoComponent = ({ item }: ISecondaryInfoComponentProps) => {
  return (
    <Box marginTop="1rem">
      <Text textTransform="uppercase" color="#020202" fontSize="12px" fontWeight="normal">
        Severity
      </Text>
      <Text fontSize={OutpostTheme.FontSizes.body}>{item.allergySeverityEnum}</Text>
    </Box>
  );
};

export default AllergyList;
