import { HStack, useBreakpointValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { AwardIcon, MessageIcon, ShoppingCartIcon } from '../../../../assets/icons';
import { Avatar } from '../../../../components/common/Avatar';
import { ProfileRoutes } from '../../../../constants';
import { RolesEnum } from '../../../../generated';
import useNotification from '../../../../hooks/useNotification';
import { ICurrentUser } from '../../../../store/currentUser/currentUserReducers';
import { OutpostTheme } from '../../../../themes/outpost';
import { getUserThumbnail } from '../../../../utils/getUserThumbnail';
import { isInRole } from '../../../../utils/isInRole';
import { NotificationIcon } from './NotificationIcon';
import LanguageToggle from '../../../../components/LanguageToggle';

interface IProps {
  currentUser: ICurrentUser;
}

export const UserNavArea = ({ currentUser }: IProps) => {
  const { reward, mail, cart } = useNotification({ currentUser });

  const showLanguageToggle = useBreakpointValue({ base: false, lg: true });

  return (
    <HStack spacing={{ base: 2, md: 4 }}>
      {isInRole(currentUser, RolesEnum.Patient) && reward.show && (
        <NotificationIcon
          id="top-bar-reward"
          ariaLabel="reward"
          handleClick={reward.handleClick}
          count={reward.count}
          Icon={AwardIcon}
        />
      )}
      <NotificationIcon
        id="top-bar-messages"
        ariaLabel="messages"
        handleClick={mail.handleClick}
        count={mail.count}
        Icon={MessageIcon}
      />

      {isInRole(currentUser, RolesEnum.Patient) && (
        <NotificationIcon
          handleClick={cart.handleClick}
          count={cart.count}
          Icon={ShoppingCartIcon}
          id="top-bar-shopping-cart"
          ariaLabel="shopping cart"
        />
      )}

      {showLanguageToggle && <LanguageToggle />}

      <Link
        aria-label="profile"
        id="top-bar-profile"
        to={
          isInRole(currentUser, RolesEnum.Patient)
            ? ProfileRoutes.PatientProfile
            : ProfileRoutes.ProviderProfile
        }
      >
        <Avatar
          size={OutpostTheme.Avatar.Size.Medium}
          src={getUserThumbnail(currentUser.imageDetailID)}
        />
      </Link>
    </HStack>
  );
};
