export const BottomViews = {
  Patient: 1,
  Chat: 2,
  HealthRecords: 3,
};

export const ConsultStatus = {
  ProviderReady: 'ProviderReady',
  ConsultReady: 'ConsultReady',
  InProgress: 'InProgress',
  PatientCompleted: 'PatientCompleted',
  Completed: 'Completed',
  Cancelled: 'Cancelled',
};

export const ConsultTagType = {
  Subjective: 'Subjective',
  Objective: 'Objective',
  Diagnosis: 'Diagnosis',
  Plan: 'Plan',
};
