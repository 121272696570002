import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { IProvider } from '../../generated';
import { CurrentUserActionTypes, logoutActions } from '../currentUser/currentUserActions';
import {
  UpdateCommunityPictureActionTypes,
  updateCommunityPictureProviderSuccess,
} from '../pictureUpload/updateCommunityPictureAction';
import {
  UpdateProfilePictureActionTypes,
  updateProfilePictureProviderSuccess,
} from '../pictureUpload/updateProfilePictureAction';
import {
  getProviderActions,
  ProviderActionTypes,
  updateProviderProfileActions,
} from './providerActions';
const provider = createReducer<
  IProvider | null,
  | ProviderActionTypes
  | CurrentUserActionTypes
  | UpdateCommunityPictureActionTypes
  | UpdateProfilePictureActionTypes
>(null)
  .handleAction([getProviderActions.success], (_state, action) => action.payload)
  .handleAction([updateCommunityPictureProviderSuccess], (state, action) => {
    const newProvider = Object.assign({}, state, {
      discussionsImageDetailID: action.payload,
    });
    return newProvider;
  })
  .handleAction([updateProfilePictureProviderSuccess], (state, action) => {
    const newProvider = Object.assign({}, state, {
      imageDetailID: action.payload,
    });
    return newProvider;
  })
  .handleAction([logoutActions], () => null);

const error = createReducer<string, ProviderActionTypes>('').handleAction(
  [getProviderActions.failure, updateProviderProfileActions.failure],
  (_state, action) => action.payload,
);

const isLoading = createReducer<boolean, ProviderActionTypes>(false)
  .handleAction([getProviderActions.request, updateProviderProfileActions.request], () => true)
  .handleAction(
    [
      getProviderActions.failure,
      getProviderActions.success,
      updateProviderProfileActions.failure,
      updateProviderProfileActions.success,
    ],
    () => false,
  );

export interface IProviderState {
  error: string;
  isLoading: boolean;
  provider: IProvider | null;
}

export default combineReducers<IProviderState>({
  provider,
  error,
  isLoading,
});
