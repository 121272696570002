import { Flex, Text, Box } from '@chakra-ui/react';
import React from 'react';
import { SelectComponent, SelectComponentProps } from '../Selects';

const CountrySelect = (props: SelectComponentProps) => {
  const countryOptionComponent = (country) => {
    return (
      <Flex justifyContent="flex-start" alignItems="center" className="f16">
        <Box className={`flag flag-${country.code.toLowerCase()}`} as="span" />
        <Text>{country.name}</Text>
      </Flex>
    );
  };

  return <SelectComponent {...props} optionTemplate={countryOptionComponent} />;
};

export default CountrySelect;
