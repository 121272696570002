import { PatientOrder } from '../../../../generated';

type GetUpdatedPatientOrderProps = {
  patientOrder: PatientOrder;
  rest?: Partial<PatientOrder>;
};

const getUpdatedPatientOrder = (props: GetUpdatedPatientOrderProps) => {
  const patientOrder = new PatientOrder();
  patientOrder.init({
    ...props.patientOrder,
    ...props.rest,
  });

  patientOrder.patient = undefined;
  patientOrder.provider = undefined;
  patientOrder.store = undefined;
  patientOrder.customerCityTown = undefined;
  patientOrder.customerProvinceState = undefined;

  return patientOrder;
};

export { getUpdatedPatientOrder };
