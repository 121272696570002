const getStoreGoogleMapUrl = (
  fullAddress?: string,
  longitude?: number | string,
  latitude?: number | string,
) => {
  const encodedAddress = fullAddress ? encodeURIComponent(fullAddress) : undefined;

  return longitude
    ? `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
    : encodedAddress
    ? `https://maps.google.com/maps?q=${encodedAddress}&t=&z=13&ie=UTF8&iwloc=`
    : undefined;
};

export { getStoreGoogleMapUrl };
