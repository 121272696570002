import { useQuery } from 'react-query';
import { AuthClient, CountriesClient, Country } from '../../generated';
import { httpRequest } from '../../utils';
import { QueryKeys } from '../keys';

export const useCountriesAll = () => {
  const {
    isLoading: isLoadingCountries,
    data: countries,
    error: countriesError,
  } = useQuery<Country[], string>(
    QueryKeys.CountriesAll,
    async () => httpRequest(() => new CountriesClient(new AuthClient()).all()),
    { staleTime: Infinity },
  );

  return {
    isLoadingCountries,
    countries,
    countriesError,
  };
};
