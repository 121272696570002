import { Flex, IconButton } from '@chakra-ui/react';
import 'chartjs-plugin-annotation';
import 'chartjs-plugin-zoom';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import styled from 'styled-components';
import { Paragraph } from '../../components/common/Paragraph';
import ServerValidationBox from '../../components/ServerValidationBox';
import PatientMedicationTour from '../../components/tours/PatientMedicationTour';
import { EMPTY_GUID, HealRoutes } from '../../constants';
import {
  IPHRSummaryDTO,
  PatientAccessEnum,
  PHRSummaryStatusEnum,
  RolesEnum,
  UserDetailRelationship,
} from '../../generated';
import useTranslationComponent from '../../hooks/useTranslationComponent';
import { useUserDetailsUserDetailsRelationships } from '../../services/userDetails/queries/useUserDetailsUserDetailsRelationships';
import { patientDependantsSelector } from '../../store/patient/patientSelectors';
import { getPHRPatient, getPHRSummaryDTO, getToken } from '../../store/patient/phr/phrCreators';
import { AppState } from '../../store/root-reducers';
import { getUserDetailSettings } from '../../store/userDetailSettings/getUserDetailSettingsAction';
import getUrlParam from '../../utils/getUrlParam';
import { isInRole } from '../../utils/isInRole';
import { scrollToTop } from '../../utils/scrollToTop';
import PhrBodyComponent, { PhrBodySkeletonComponent } from './components/PhrBody';
import PhrNavComponent, { PhrNavSkeleton } from './components/PhrNav';
import { XCloseIcon } from '../../assets/icons';

const Wrapper = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
`;

export interface IDependantsOption {
  label: string;
  phrDependantID: string;
  patientID: string;
  healthRecords: boolean;
}

interface IProps {
  patientID: string;
  onDismiss?: () => void;
}

const sys = getUrlParam('sys');

const PhrComponent = React.memo(({ patientID, onDismiss }: IProps) => {
  const { t } = useTranslationComponent(['clinic']);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const isOnPatientMedicalRecord = !!matchPath(pathname, {
    path: [HealRoutes.HealthRecords],
    exact: true,
  });
  const [currentCategory, setCurrentCategory] = useState<IPHRSummaryDTO | null>(
    isInRole(currentUser, RolesEnum.Provider) && !sys
      ? ({
          phrCategory: PatientAccessEnum.PHRSummary,
          phrCategoryName: t('Patient Information'),
          summaryStatusEnum: PHRSummaryStatusEnum.Normal,
        } as IPHRSummaryDTO)
      : null,
  );
  const [subnavCurrentCategory, setSubnavCurrentCategory] = useState<string | null>(null);
  const [currentSubNavCategoryID, setCurrentSubNavCategoryID] = useState<string | null>(null);

  const {
    phrPatient,
    isLoading: isLoadingPhrPatient,
    phrSummary,
    isPhrSummaryLoading,
    error,
  } = useSelector((state: AppState) => state.phrPatientState);

  const { isLoading: isUserSettingsLoading, userDetailSettings } = useSelector(
    (state: AppState) => state.userDetailSettingState,
  );

  const [hasDeepLinked, setHasDeepLinked] = useState<boolean>(false);

  const [currentPHRDependant, setCurrentPHRDependant] = useState<IDependantsOption>();
  const [patientDependants, setPatientDependants] = useState<UserDetailRelationship[]>();
  const [phrOptionsList, setPHROptionsList] = useState<IDependantsOption[]>();
  const { patient } = useSelector((state: AppState) => state.patientState);

  const { patientDependents } = useUserDetailsUserDetailsRelationships();

  const back = () => {
    setCurrentCategory(null);
  };

  const changeCategory = (x: IPHRSummaryDTO | null) => {
    scrollToTop();
    setCurrentSubNavCategoryID(null);
    setSubnavCurrentCategory(null);
    setCurrentCategory(x);
  };

  useEffect(() => {
    if (phrSummary) {
      const values = queryString.parse(window.location.search);
      if (values.currentCategory && !hasDeepLinked) {
        const categoryToDeepLinkTo = phrSummary.filter(
          (x) => x.phrCategory === values.currentCategory,
        );
        if (categoryToDeepLinkTo && categoryToDeepLinkTo.length === 1) {
          setCurrentCategory(categoryToDeepLinkTo[0]);
        }
        setHasDeepLinked(true);
      }
    }
  }, [hasDeepLinked, phrSummary]);

  useEffect(() => {
    dispatch(getUserDetailSettings());
    if (!phrPatient || phrPatient.patient.patientID !== patientID) {
      dispatch(getPHRPatient(patientID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!phrSummary && phrPatient) {
      dispatch(getPHRSummaryDTO(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (patient) {
      if (patientDependents) {
        const options = patientDependantsSelector(patientDependents).map((x) => ({
          label: `${x.toUserDetail?.patient!.firstName} ${x.toUserDetail?.patient!.lastName}`,
          phrDependantID: x.userDetailRelationshipID,
          patientID: x.toUserDetail!.patient!.patientID,
          healthRecords: x.healthRecords,
        }));
        const you = {
          label: `${patient.firstName} ${patient.lastName} (${t('You')})`,
          phrDependantID: EMPTY_GUID,
          patientID: patient.patientID,
          healthRecords: true,
        };

        options.unshift(you);
        setPHROptionsList(options);
        setPatientDependants(patientDependents);

        let selectedPatient = options.find((x) => x.patientID === patientID);
        if (!selectedPatient) {
          selectedPatient = you;
        }
        setCurrentPHRDependant(selectedPatient);
      }
    }
  }, [dispatch, patient, patientDependents, patientID, t]);

  const signInForDependant = useCallback(
    async (phrDependantID: string) => {
      const phrDependant = new UserDetailRelationship();
      phrDependant.userDetailRelationshipID = phrDependantID;
      dispatch(getToken(phrDependant));
    },
    [dispatch],
  );

  useEffect(() => {
    if (currentPHRDependant) {
      if (currentPHRDependant.phrDependantID !== EMPTY_GUID) {
        // signInForDependant(currentPHRDependant.phrDependantID);
        if (patient && patientDependants) {
          patientDependants.forEach((x) => {
            if (x.userDetailRelationshipID === currentPHRDependant.phrDependantID) {
              dispatch(getPHRPatient(x.toUserDetail!.patient!.patientID));
            }
          });
        }
      } else {
        dispatch(getPHRPatient(patient!.patientID));
      }
    }
  }, [currentPHRDependant, dispatch, patient, patientDependants, signInForDependant]);

  const renderCloseButton = () => (
    <Flex direction="column" p={3}>
      <IconButton
        aria-label="close"
        p={0}
        variant="ghost"
        borderRadius="full"
        onClick={onDismiss}
        ml="auto"
      >
        <XCloseIcon width="24px" height="24px" />
      </IconButton>
    </Flex>
  );

  return isUserSettingsLoading || isLoadingPhrPatient || isPhrSummaryLoading ? (
    <Flex height="100%">
      <PhrNavSkeleton />
      <PhrBodySkeletonComponent />
    </Flex>
  ) : phrPatient ? (
    <>
      <Flex height="100%">
        <PhrNavComponent
          patient={phrPatient.patient}
          phrSummary={phrSummary!}
          currentCategory={currentCategory}
          setCurrentCategory={changeCategory}
          currentPHRDependant={currentPHRDependant}
          phrOptionsList={phrOptionsList}
          setCurrentPHRDependant={setCurrentPHRDependant}
          onDismiss={onDismiss}
        />
        <PhrBodyComponent
          phrPatient={phrPatient!}
          currentCategory={currentCategory}
          subnavCurrentCategory={subnavCurrentCategory}
          setSubnavCurrentCategory={setSubnavCurrentCategory}
          currentSubNavCategoryID={currentSubNavCategoryID}
          setCurrentSubNavCategoryID={setCurrentSubNavCategoryID}
          back={back}
          userDetailSettings={userDetailSettings!}
          onDismiss={onDismiss}
        />
      </Flex>
      {isOnPatientMedicalRecord && (
        <PatientMedicationTour
          selectedCategory={currentCategory}
          setHealthConditionsCategory={() =>
            setCurrentCategory(
              phrSummary?.find((x) => x.phrCategory === PatientAccessEnum.PHRHealthCondition) ||
                null,
            )
          }
          clearCategory={() => setCurrentCategory(null)}
        />
      )}
    </>
  ) : error ? (
    <>
      {renderCloseButton()}
      <ServerValidationBox message={error} />
    </>
  ) : (
    <>
      {renderCloseButton()}
      <NoPatientMessage>{t('Unable to load Patient')}</NoPatientMessage>
    </>
  );
});

export default PhrComponent;

export const PhrSkeletonComponent: React.FC = () => {
  return (
    <Wrapper>
      <PhrNavSkeleton />
      <PhrBodySkeletonComponent />
    </Wrapper>
  );
};

const NoPatientMessage = styled(Paragraph)`
  font-style: italic;
  text-align: center;
`;
