import { useQuery } from 'react-query';
import { AuthClient, PatientFavoritesClient, PatientFavorite } from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

interface IProps {
  patientID?: string;
}

export const usePatientPreferredPharmacyGet = ({ patientID }: IProps) => {
  const {
    isLoading: isLoadingPreferredPharmacy,
    data: preferredPharmacy,
    error: preferredPharmacyError,
  } = useQuery<PatientFavorite, string>(
    [QueryKeys.PatientPreferredPharmacy, patientID],
    async () =>
      httpRequest(() =>
        new PatientFavoritesClient(new AuthClient()).preferredPharmacyGet(patientID!),
      ),
    { enabled: !!patientID },
  );

  return {
    isLoadingPreferredPharmacy,
    preferredPharmacy,
    preferredPharmacyError,
  };
};
