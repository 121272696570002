import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  IconButton,
  SimpleGrid,
  Text,
  useDisclosure,
  Wrap,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { EditIcon } from '../../../../assets/icons';

import { AddressBook, AddressBookTag, AddressBookTypeEnum } from '../../../../generated';
import useTranslationComponent from '../../../../hooks/useTranslationComponent';

import { AddressBookModeEnum } from '../../AddressBook';
import useAddressBooksContext from '../../context/AddressBooksProvider';
import { getOtherAddressBookTags, isSameAddress } from '../../utils';

import { AddressBookCardContents } from '../AddressBookCard/AddressBookCardContents';
import { AddressBookFavoriteIcon } from '../AddressBookFavoriteIcon/AddressBookFavoriteIcon';
import { DeleteConfirm } from '../DeleteConfirm/DeleteConfirm';

interface IAddressBookCardProps {
  addressBook: AddressBook;
  onEdit?: () => void;
}

export const AddressBookCard = ({ addressBook, onEdit }: IAddressBookCardProps) => {
  const { t } = useTranslationComponent();
  const {
    isOpen: isDeleteConfirmOpen,
    onOpen: onDeleteConfirmOpen,
    onClose: onDeleteConfirmClose,
  } = useDisclosure();
  const {
    mode,
    country,
    currentUserDetailID,
    selectedAddresses,
    onSelect,
    onUnSelect,
    onFavorite,
    isProvider,
  } = useAddressBooksContext();
  const [selectedCard, setSelectedCard] = useState<boolean>(false);
  const [specialties, setSpecialties] = useState<AddressBookTag[]>([]);

  const onToggle = (addressBook: AddressBook) =>
    selectedCard ? onUnSelect(addressBook) : onSelect(addressBook);

  useEffect(() => {
    const isSelectedAddress = (addressBook: AddressBook) =>
      !!selectedAddresses?.find((x) => isSameAddress(x, addressBook));
    const displaySelectedCard = (addressBook: AddressBook) =>
      mode !== AddressBookModeEnum.Browse && isSelectedAddress(addressBook);
    if (addressBook) {
      setSelectedCard(displaySelectedCard(addressBook));
    }
  }, [addressBook, mode, selectedAddresses]);

  useEffect(() => {
    setSpecialties(getOtherAddressBookTags(addressBook?.addressBookTags));
  }, [addressBook]);

  return (
    <Box
      border={`${selectedCard ? '2px' : '1px'} solid transparent`}
      borderColor={selectedCard ? '#7d38e8' : 'rgb(211, 211, 211)'}
      _hover={{ backgroundColor: 'white' }}
      backgroundColor="white"
      borderRadius="lg"
    >
      <AccordionItem>
        <AccordionButton>
          <Flex alignItems="stretch" width="100%" pl="2" height="100%">
            <AddressBookCardContents
              addressBook={addressBook}
              onToggle={onToggle}
              isCardSelected={selectedCard}
            />
            {addressBook.addressBookTypeEnum === AddressBookTypeEnum.Private && (
              <Flex>
                <IconButton
                  aria-label={t('Edit Private contact')}
                  bgColor="transparent"
                  _hover={{ bgColor: 'rgb(219, 217, 217)' }}
                  padding="1"
                  onClick={(e) => {
                    e.preventDefault();
                    if (onEdit && addressBook.addressBookTypeEnum === AddressBookTypeEnum.Private) {
                      onSelect(addressBook);
                      onEdit();
                    }
                  }}
                  height="100%"
                >
                  <EditIcon />
                </IconButton>
              </Flex>
            )}
            {!isProvider && (
              <Flex>
                <AddressBookFavoriteIcon
                  addressBook={addressBook}
                  userDetailID={currentUserDetailID}
                  onToggleFavorite={(e) => {
                    e.preventDefault();
                    if (currentUserDetailID) {
                      onFavorite({ addressBook, userDetailID: currentUserDetailID });
                    }
                  }}
                />
              </Flex>
            )}
            <Flex px="2" alignItems="center">
              <Box
                border="1px solid rgb(0, 3, 16)"
                borderRadius="50%"
                width="5"
                height="5"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <AccordionIcon color="rgb(0, 3, 16)" fontSize="16px" />
              </Box>
            </Flex>
          </Flex>
        </AccordionButton>
        <AccordionPanel borderTop="1px solid rgb(211, 211, 211)" py="3" px="4">
          <SimpleGrid columns={4} spacing={4} minChildWidth="40" mb={2}>
            <Box>
              <Text fontSize="sm" color="gray.400">
                {t('Email address')}
              </Text>
              <Text fontSize="sm">{addressBook.emailAddress || '-'}</Text>
            </Box>
            <Box>
              <Text fontSize="sm" color="gray.400">
                {t('Office phone number')}
              </Text>
              <Text fontSize="sm">{addressBook.officePhoneNumber || '-'}</Text>
            </Box>
            <Box>
              <Text fontSize="sm" color="gray.400">
                {t('Cell phone number')}
              </Text>
              <Text fontSize="sm">{addressBook.cellPhoneNumber || '-'}</Text>
            </Box>
            <Box>
              <Text fontSize="sm" color="gray.400">
                {t('Home phone number')}
              </Text>
              <Text fontSize="sm">{addressBook.homePhoneNumber || '-'}</Text>
            </Box>
          </SimpleGrid>
          <Box>
            <Text fontSize="sm" color="gray.400">
              {t('Specialties')}
            </Text>
            <Wrap spacing={2} mb={4}>
              {specialties.map((x) => (
                <Text fontSize="sm" ml={2}>
                  {x.tag?.name},
                </Text>
              ))}
            </Wrap>
          </Box>
          {addressBook.addressBookTypeEnum === AddressBookTypeEnum.Private &&
            (country.code === 'CA' || country.code === 'US') && (
              <Flex justifyContent="flex-end" w="100%" px={4} pt={4}>
                <Box pr={8}>
                  <Button colorScheme="red" variant="ghost" onClick={onDeleteConfirmOpen}>
                    {t('Delete')}
                  </Button>
                  <DeleteConfirm
                    addressBook={addressBook}
                    isOpen={isDeleteConfirmOpen}
                    onClose={onDeleteConfirmClose}
                  />
                </Box>
                <Box>
                  <Button
                    colorScheme="blue"
                    variant="ghost"
                    onClick={() => {
                      if (onEdit) {
                        onSelect(addressBook);
                        onEdit();
                      }
                    }}
                  >
                    {t('Edit')}
                  </Button>
                </Box>
              </Flex>
            )}
        </AccordionPanel>
      </AccordionItem>
    </Box>
  );
};
