import React from 'react';
import styled from 'styled-components';

interface IProps {
  percentage?: number;
}

export const ProgressBar = ({ percentage }: IProps) => {
  return (
    <Wrapper>
      <Filler percentage={percentage || 0} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: 5px;
  width: 100%;
  border-radius: 50px;
  background-color: rgb(230, 230, 230);
`;

interface FillerProps {
  readonly percentage: number;
}

const Filler = styled.div<FillerProps>`
  background: #000;
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
  width: ${(props) => props.percentage}%;
`;
