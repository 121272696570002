import { TabList as ChakraTabList, chakra } from '@chakra-ui/react';

export const TabList = chakra(ChakraTabList, {
  baseStyle: {
    flexWrap: 'nowrap',
    overflowX: 'auto !important',
    pt: 3,
    px: 6,
    border: 'none',
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    '> button:not(:last-of-type)': {
      mr: 4,
    },
  },
});
