import { Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { Avatar } from '../../../../../../../components/common/Avatar';
import { AddressBook } from '../../../../../../../generated';
import { OutpostTheme } from '../../../../../../../themes/outpost';
import { getUserThumbnail } from '../../../../../../../utils/getUserThumbnail';

interface IProps {
  ActionButton?: React.ReactNode;
  selectedAddress: AddressBook;
}

export const SelectedLab = ({ ActionButton, selectedAddress }: IProps) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems={['flex-start', 'center']}
      direction={['column', 'row']}
    >
      <HStack mb={[3, 0]}>
        <Avatar size="lg" src={getUserThumbnail(selectedAddress.imageDetailID)} />
        <Stack mx={5} flexGrow={1} spacing={1}>
          <Text fontSize={OutpostTheme.FontSizes.body}>{selectedAddress.name}</Text>
          <Text fontSize="12px">
            {[
              selectedAddress.addressLine1,
              selectedAddress.addressLine2,
              selectedAddress.city,
              selectedAddress.province,
              selectedAddress.postCode,
            ]
              .filter((x) => x)
              .join(', ')}
          </Text>
        </Stack>
      </HStack>
      {ActionButton}
    </Flex>
  );
};
