import React from 'react';
import { Box, Text, HStack, Image } from '@chakra-ui/react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { PHRVaccination, RolesEnum } from '../../../../generated';
import { TimeFormatEnum } from '../../../../constants';
import { CalendarIcon2, BagIcon } from '../../../../assets/icons';
import { IPHRPatientEnhanced } from '../../../../store/patient/phr/phrReducers';
import { getTime } from '../../../../utils';
import { OutpostTheme } from '../../../../themes/outpost';
import { AppState } from '../../../../store/root-reducers';
import { isInRole } from '../../../../utils/isInRole';

import PhrItem, {
  AddPHRItemButton,
  PHRItemList,
  EmptyPHRItemListCard,
} from '../../components/PhrItem';

import { CovidVaccinationExemption } from './CovidVaccinationExemption';

interface IProps {
  phrVaccinations: PHRVaccination[];
  patient: IPHRPatientEnhanced;
  covidVaccineQRCode: string;
  onView: (phrVaccination: PHRVaccination) => void;
  onCreate: () => void;
}

const CovidVaccinationsList = ({
  phrVaccinations = [],
  patient,
  covidVaccineQRCode,
  onView,
  onCreate,
}: IProps) => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data!);

  const isPatient = isInRole(currentUser, RolesEnum.Patient);

  return (
    <PHRItemList>
      {!isPatient && <AddPHRItemButton name="Vaccination" onCreate={onCreate} />}

      {covidVaccineQRCode && (
        <Image
          src={covidVaccineQRCode}
          marginX="auto"
          marginTop="1rem"
          height="200px"
          width="200px"
        />
      )}

      <CovidVaccinationExemption patient={patient} />

      {phrVaccinations.length === 0 ? (
        <EmptyPHRItemListCard>No Vaccinations added</EmptyPHRItemListCard>
      ) : (
        <>
          {phrVaccinations
            .sort((a, b) => getTime(b.vaccinationDate) - getTime(a.vaccinationDate))
            .map((item, index) => (
              <PhrItem
                key={index}
                name={item.manufacturerName}
                isVerified={item.verified}
                onView={() => onView(item)}
                SecondaryInfo={<CovidVaccineSecondaryInfo item={item} />}
              />
            ))}
        </>
      )}
    </PHRItemList>
  );
};

interface ICovidVaccineSecondaryInfoProps {
  item: PHRVaccination;
}

export const CovidVaccineSecondaryInfo = ({ item }: ICovidVaccineSecondaryInfoProps) => {
  return (
    <HStack
      spacing={{ base: 8, md: 12 }}
      fontSize={OutpostTheme.FontSizes.body}
      fontWeight="300"
      color="#011431"
      align="flex-start"
      marginTop="0.5rem"
    >
      <Box>
        <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="medium">
          Date Vaccinated
        </Text>
        <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
          <CalendarIcon2 fontSize="18px" color="#616A88" />{' '}
          {moment(item.vaccinationDate).format(TimeFormatEnum.SHORT_LONG_DATE)}
        </Text>
      </Box>
      <Box>
        <Text textTransform="uppercase" color="#01143173" fontSize="12px" fontWeight="medium">
          Lot Number
        </Text>
        <Text fontSize={OutpostTheme.FontSizes.body} marginTop="0.5rem">
          <BagIcon fontSize="18px" color="#616A88" /> {item.lotNumber}
        </Text>
      </Box>
    </HStack>
  );
};

export default CovidVaccinationsList;
