import { Box, Button, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { FastField, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { date, object, string } from 'yup';

import { GLOBAL_MAX_TEXT_LENGTH } from '../../../../constants';
import {
  AuthClient,
  FileDetail,
  IPHRVaccination,
  PHRDocument,
  PHRDocumentCategoryEnum,
  PHRDocumentsClient,
  PHRDocumentUploadDetails,
  PHRVaccination,
  TagTypeEnum,
  VaccineTypeEnum,
} from '../../../../generated';
import { useTagsAllByTagType } from '../../../../services/tags/queries/useTagsAllByTagType';
import { createForPhrSection, updateForPhrSection } from '../../../../store/root-creator';
import { AppState } from '../../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import { getFileDetail } from '../../../../utils/fileHelpers';
import { scrollToTop } from '../../../../utils/scrollToTop';
import { errorToastr } from '../../../../utils/toastr';

import { PrimaryButton } from '../../../../components/Buttons';
import {
  FormInputControl,
  FormMobisPicker,
  FormSelectComponent,
  FormTextArea,
} from '../../../../components/forms';
import { CenteredLoadingIndicator } from '../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../components/ServerValidationBox';
import { OutpostTheme } from '../../../../themes/outpost';
import PhrItemForm from '../../components/PhrItemForm';

interface IForm {
  vaccinationDate: Date;
  vaccineManufacturerTagID: string;
  facility: string;
  lotNumber: string;
  frontDocumentName: string;
  backDocumentName: string;
  notes: string | undefined;
}

interface IProps {
  currentVaccination: IPHRVaccination | null;
  patientID: string;
  toggleView: () => void;
  innerBtnRef: React.RefObject<HTMLButtonElement>;
}

enum EFileCategory {
  Front = 'Front',
  Back = 'Back',
}

const CovidVaccinationForm = ({
  currentVaccination,
  patientID,
  toggleView,
  innerBtnRef,
}: IProps) => {
  const dispatch = useDispatch();
  const frontDocInputRef = useRef<HTMLInputElement>(null);
  const backDocInputRef = useRef<HTMLInputElement>(null);
  const { error, isPhrLoading } = useSelector((state: AppState) => state.phrPatientState);
  const [isUploading, setIsUploading] = useState(false);
  const { tags: vaccineManufacturerTags = [] } = useTagsAllByTagType(
    TagTypeEnum.VaccineManufacturer,
  );
  const [frontDocument, setFrontDocument] = useState<PHRDocument | null>();
  const [backDocument, setBackDocument] = useState<PHRDocument | null>();

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleFileUpload = async (file: File, cb: any, category: EFileCategory) => {
    const reader = new FileReader();
    let fileDetail: FileDetail;

    reader.onloadend = async function () {
      setIsUploading(true);
      try {
        fileDetail = getFileDetail(file.type, file.size, file.name, reader.result as ArrayBuffer);
        const documentUploadDetail = new PHRDocumentUploadDetails();
        documentUploadDetail.fileDetail = fileDetail;
        documentUploadDetail.patientID = patientID;
        documentUploadDetail.phrDocumentCategoryEnum = PHRDocumentCategoryEnum.Other;

        if (fileDetail) {
          const documentID = await new PHRDocumentsClient(new AuthClient()).uploadPHRDocument(
            documentUploadDetail,
          );
          fileDetail.fileDetailID = documentID;
          cb(fileDetail.name);

          const phrDocument = new PHRDocument();
          phrDocument.fileDetail = fileDetail;
          phrDocument.fileDetailID = fileDetail.fileDetailID!;
          phrDocument.patientID = patientID;

          if (category === EFileCategory.Front) {
            setFrontDocument(phrDocument);
          } else {
            setBackDocument(phrDocument);
          }
        }
      } catch (e) {
        errorToastr({ description: 'An error occured while uploading document' });
      } finally {
        setIsUploading(false);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const submit = async (values: IForm) => {
    const vaccination = new PHRVaccination();
    vaccination.init(values);

    vaccination.patientID = patientID;
    vaccination.vaccinationDate = values.vaccinationDate;
    vaccination.manufacturerTagID = values.vaccineManufacturerTagID;
    vaccination.manufacturerName = vaccineManufacturerTags.filter(
      (tag) => tag.tagID === values.vaccineManufacturerTagID,
    )[0]?.name;
    vaccination.location = values.facility;
    vaccination.lotNumber = values.lotNumber;
    vaccination.frontPHRDocument = frontDocument!;
    vaccination.frontPHRDocumentID = frontDocument?.fileDetailID;
    vaccination.backPHRDocument = backDocument!;
    vaccination.backPHRDocumentID = backDocument?.fileDetailID;
    vaccination.notes = values.notes;
    vaccination.vaccineTypeEnum = VaccineTypeEnum.Covid;

    if (currentVaccination) {
      vaccination.phrVaccinationID = currentVaccination?.phrVaccinationID;
      await dispatch(
        updateForPhrSection(
          ReduxPHRCategoryEnum.PHRVaccinations,
          vaccination,
          vaccination.patientID,
          false,
        ),
      );
    } else {
      await dispatch(
        createForPhrSection(
          ReduxPHRCategoryEnum.PHRVaccinations,
          vaccination,
          vaccination.patientID,
          false,
        ),
      );
    }
    toggleView();
  };

  const initialValues: IForm = {
    vaccinationDate: currentVaccination?.vaccinationDate || new Date(),
    vaccineManufacturerTagID: currentVaccination?.manufacturerTagID || '',
    facility: currentVaccination?.location || '',
    lotNumber: currentVaccination?.lotNumber || '',
    frontDocumentName: currentVaccination?.frontPHRDocument?.fileDetail?.name || '',
    backDocumentName: currentVaccination?.backPHRDocument?.fileDetail?.name || '',
    notes: currentVaccination ? currentVaccination.notes : '',
  };

  return (
    <PhrItemForm>
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          vaccinationDate: date()
            .typeError('Invalid date')
            .required('A vaccination date is required'),
          vaccineManufacturerTagID: string()
            .required('Vaccine Manufacturer is required')
            .max(100, 'Manufacturer name must be at most 100 characters'),
          facility: string()
            .required('Vaccination facility is required')
            .max(100, 'Name must be at most 100 characters'),
          lotNumber: string()
            .required('Lot number is required')
            .max(100, 'Name must be at most 100 characters'),
          frontDocumentName: string(),
          backDocumentName: string(),
          notes: string().max(GLOBAL_MAX_TEXT_LENGTH, 'Notes must be at most 10000 characters'),
        })}
        onSubmit={(values) => submit(values)}
      >
        {({ setFieldValue, errors, values, touched }) => (
          <Form>
            {isPhrLoading ? (
              <CenteredLoadingIndicator />
            ) : (
              <>
                <ServerValidationBox message={error} />
                <Grid gap={4} templateColumns={{ base: '1fr', md: '1fr 1fr' }} templateRows="auto">
                  <GridItem>
                    <FormSelectComponent
                      label="Vaccine Manufacturer"
                      name="vaccineManufacturerTagID"
                      valueKey="tagID"
                      labelKey="name"
                      isClearable={false}
                      options={vaccineManufacturerTags}
                      defaultInputValue={
                        vaccineManufacturerTags.filter(
                          (tag) => tag.tagID === currentVaccination?.manufacturerTagID,
                        )[0]?.name
                      }
                      components={{
                        Placeholder: () => (
                          <Text
                            fontSize={OutpostTheme.FontSizes.body}
                            color="#0516466E"
                            paddingLeft="0.5rem"
                          >
                            Select Manufacturer
                          </Text>
                        ),
                        IndicatorSeparator: null,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: '48px',
                          borderRadius: '8px',
                          backgroundColor: '#F7F9FC',
                        }),
                      }}
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <FormMobisPicker
                      name="vaccinationDate"
                      label="Vaccination Date"
                      placeholder="DD/MM/YY"
                      borderRadius="lg"
                      containerProps={{
                        height: '48px',
                      }}
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <FormInputControl
                      name="lotNumber"
                      label="Lot Number"
                      placeholder="Enter Lot Number"
                      height="48px"
                      borderRadius="lg"
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <FormInputControl
                      name="facility"
                      label="Facility Where Vaccine Was Given"
                      placeholder="Enter Healthcare Facility"
                      height="48px"
                      borderRadius="lg"
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <Box flexShrink={1}>
                      <Text
                        marginBottom="1rem"
                        textTransform="uppercase"
                        color="#01143173"
                        fontSize="12px"
                        fontWeight="light"
                      >
                        Upload Front of Document
                      </Text>

                      {values.frontDocumentName ? (
                        <Flex justify="space-between">
                          <Flex
                            bgColor="#3E11911C"
                            color="#3E1191"
                            borderRadius="lg"
                            fontSize={OutpostTheme.FontSizes.body}
                            height="48px"
                            flexGrow={1}
                            paddingX="1rem"
                            align="center"
                            justify="center"
                          >
                            <Text>{getDocumentDisplayName(values.frontDocumentName)}</Text>
                          </Flex>
                          <PrimaryButton
                            height="48px"
                            color="#FF3B46"
                            bgColor="#FFFFFF"
                            _hover={{
                              backgroundColor: '#FFFFFF',
                            }}
                            _active={{
                              backgroundColor: '#FFFFFF',
                            }}
                            onClick={() => setFieldValue('frontDocumentName', '')}
                          >
                            Delete
                          </PrimaryButton>
                        </Flex>
                      ) : (
                        <>
                          <FastField name="frontDocumentName">
                            {() => (
                              <input
                                ref={frontDocInputRef}
                                type="file"
                                id="frontDocumentName"
                                accept="image/png, image/jpeg, application/pdf"
                                style={{ display: 'none' }}
                                onChange={(event) => {
                                  handleFileUpload(
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                                    event?.currentTarget?.files?.[0]!,
                                    (documentID: string) =>
                                      setFieldValue('frontDocumentName', documentID),
                                    EFileCategory.Front,
                                  );
                                }}
                              />
                            )}
                          </FastField>

                          <PrimaryButton
                            bgColor="#762EE71C"
                            color="#707070"
                            _hover={{ bgColor: '#762EE71C' }}
                            _active={{ bgColor: '#762EE71C' }}
                            borderRadius="lg"
                            fontSize={OutpostTheme.FontSizes.body}
                            height="48px"
                            width="100%"
                            isLoading={isUploading}
                            onClick={() => {
                              frontDocInputRef?.current?.click();
                            }}
                          >
                            Tap To Upload Document
                          </PrimaryButton>
                          {errors.frontDocumentName && touched.frontDocumentName && (
                            <Text
                              fontSize={OutpostTheme.FontSizes.body}
                              color="red"
                              marginTop="0.5rem"
                            >
                              {errors.frontDocumentName}
                            </Text>
                          )}
                        </>
                      )}
                    </Box>
                  </GridItem>
                  <GridItem>
                    <Box flexShrink={1}>
                      <Text
                        marginBottom="1rem"
                        textTransform="uppercase"
                        color="#01143173"
                        fontSize="12px"
                        fontWeight="light"
                      >
                        Upload Back of Document
                      </Text>

                      {values.backDocumentName ? (
                        <Flex justify="space-between">
                          <Flex
                            bgColor="#3E11911C"
                            color="#3E1191"
                            borderRadius="lg"
                            fontSize={OutpostTheme.FontSizes.body}
                            height="48px"
                            flexGrow={1}
                            paddingX="1rem"
                            align="center"
                            justify="center"
                          >
                            <Text>{getDocumentDisplayName(values.backDocumentName)}</Text>
                          </Flex>
                          <PrimaryButton
                            height="48px"
                            color="#FF3B46"
                            bgColor="#FFFFFF"
                            _hover={{
                              backgroundColor: '#FFFFFF',
                            }}
                            _active={{
                              backgroundColor: '#FFFFFF',
                            }}
                            onClick={() => setFieldValue('backDocumentName', '')}
                          >
                            Delete
                          </PrimaryButton>
                        </Flex>
                      ) : (
                        <>
                          <FastField name="backDocumentName">
                            {() => (
                              <input
                                ref={backDocInputRef}
                                type="file"
                                id="backDocumentName"
                                accept="image/png, image/jpeg, application/pdf"
                                style={{ display: 'none' }}
                                onChange={(event) => {
                                  handleFileUpload(
                                    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                                    event?.currentTarget?.files?.[0]!,
                                    (documentID: string) =>
                                      setFieldValue('backDocumentName', documentID),
                                    EFileCategory.Back,
                                  );
                                }}
                              />
                            )}
                          </FastField>

                          <PrimaryButton
                            bgColor="#762EE71C"
                            color="#707070"
                            _hover={{ bgColor: '#762EE71C' }}
                            _active={{ bgColor: '#762EE71C' }}
                            borderRadius="lg"
                            fontSize={OutpostTheme.FontSizes.body}
                            height="48px"
                            width="100%"
                            isLoading={isUploading}
                            onClick={() => {
                              backDocInputRef?.current?.click();
                            }}
                          >
                            Tap To Upload Document
                          </PrimaryButton>
                          {errors.backDocumentName && touched.backDocumentName && (
                            <Text
                              fontSize={OutpostTheme.FontSizes.body}
                              color="red"
                              marginTop="0.5rem"
                            >
                              {errors.backDocumentName}
                            </Text>
                          )}
                        </>
                      )}
                    </Box>
                  </GridItem>
                  <GridItem colSpan={[1, 1, 2]}>
                    <FormTextArea
                      name="notes"
                      label="Notes"
                      borderRadius="lg"
                      height="120px"
                      resize="none"
                      placeholder="Type a short note here"
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </GridItem>
                </Grid>
                <Button display="none" ref={innerBtnRef} type="submit" />
              </>
            )}
          </Form>
        )}
      </Formik>
    </PhrItemForm>
  );
};

export default CovidVaccinationForm;

const getDocumentDisplayName = (documentName: string) => {
  if (!documentName) return '';

  return documentName.length > 15 ? documentName.slice(0, 15) + '...' : documentName;
};
