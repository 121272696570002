import { Box, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { ResultViewModal } from '../../../../../../components/FormBuilder/components/ResultView/ResultViewModal';
import { FormStatusEnum, Patient } from '../../../../../../generated';
import { OutpostTheme } from '../../../../../../themes/outpost';

import useProviderConsultContext from '../../hooks/useProviderConsultContext';

import { FormInfo, FormName, FormStatus, IntakeForms as IntakeFormContainer } from '../Styled';

interface IntakeForm {
  formID: string;
  formName?: string;
  formShortName?: string;
  formStatusEnum?: FormStatusEnum;
  verified?: boolean;
  patient?: Patient;
  updatedDate?: Date;
}

type IntakeFormProps = {
  intakeForms: IntakeForm[];
};

const IntakeForms: FC<IntakeFormProps> = (props): JSX.Element => {
  const { phrConsult, phrForms, loadPhrForms } = useProviderConsultContext();

  return (
    <Box my={4}>
      <Text fontSize={OutpostTheme.FontSizes.body} fontWeight="500">
        Forms
      </Text>
      <Text fontSize={OutpostTheme.FontSizes.body}>
        {props.intakeForms?.length === 0 && 'none'}
      </Text>
      {props.intakeForms?.length !== 0 && (
        <IntakeFormContainer>
          {props.intakeForms?.map((x) => (
            <FormInfo key={x.formID}>
              <ResultViewModal
                intakeForm={x}
                phrForm={phrForms.find((y) => y.formID === x.formID)}
                loadPhrForms={() => loadPhrForms(phrConsult!.phrConsultID)}
              >
                {({ openModal }) => (
                  <FormName
                    onClick={openModal}
                    readable={
                      x.formStatusEnum === FormStatusEnum.Complete ||
                      x.formStatusEnum === FormStatusEnum.Scored
                    }
                  >
                    {x.formShortName}
                  </FormName>
                )}
              </ResultViewModal>
              {x.formStatusEnum !== FormStatusEnum.Complete &&
              x.formStatusEnum !== FormStatusEnum.Scored ? (
                <FormStatus>Unanswered</FormStatus>
              ) : (
                <>
                  {x.verified && (
                    <>
                      <FormStatus>Verified</FormStatus>
                      {/* <FontAwesomeIcon color="#46AA78" icon={['fas', 'shield-alt']} /> */}
                      <FontAwesomeIcon color="#46AA78" icon={['fas', 'shield']} />
                    </>
                  )}
                </>
              )}
            </FormInfo>
          ))}
        </IntakeFormContainer>
      )}
    </Box>
  );
};

export { IntakeForms };
