import { AddressBook } from '../../../generated';

export const hasAddress = (addressBook: AddressBook) =>
  addressBook.addressLine1 ||
  addressBook.addressLine2 ||
  addressBook.cityTown?.name ||
  addressBook.city ||
  addressBook.provinceState?.name ||
  addressBook.province ||
  addressBook.postCode;
