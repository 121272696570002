import { PatientPrescription } from '../../../../generated';
import { getAdditionalItems } from './getAdditionalItems';
import { getSortedPhrMedications } from './getSortedPhrMedications';

const getPrescriptionData = (patientPrescription?: PatientPrescription) => {
  const { sortByName } = getSortedPhrMedications();

  const phrMedications = patientPrescription?.phrMedications ?? [];

  const invoice = patientPrescription?.invoice;

  const currencyEnum = invoice?.currencyEnum;
  const taxLabel = invoice?.taxLabel;
  const totalAmount = invoice?.totalAmount;

  const invoiceStoreItems = invoice?.invoiceStoreItems ?? [];

  const sortedMedicationsByName = sortByName(phrMedications);
  const additionalItems = getAdditionalItems(phrMedications, invoiceStoreItems);

  return {
    phrMedications,
    sortedMedicationsByName,
    additionalItems,
    currencyEnum,
    taxLabel,
    totalAmount,
  };
};

export { getPrescriptionData };
