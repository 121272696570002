import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { getAddressBookID } from '../../../components/AddressBook/utils';
import { AddressBook, AddressBooksClient, AuthClient } from '../../../generated';
import { httpRequest } from '../../../utils';
import { QueryKeys } from '../../keys';

export interface IToggleFavoriteProps {
  addressBook: AddressBook;
  userDetailID?: string;
}

export const useAddressBooksToggleFavorite = () => {
  const queryClient = useQueryClient();
  const [error, setError] = useState<string>('');
  const toggleFavorite = useMutation(
    async ({ addressBook, userDetailID }: IToggleFavoriteProps) => {
      if (!addressBook.isFavorite) {
        await httpRequest(() =>
          new AddressBooksClient(new AuthClient()).favoriteAddressBookPost(
            userDetailID ? userDetailID : addressBook.userDetailID,
            getAddressBookID(addressBook)!,
          ),
        );
      } else {
        await httpRequest(() =>
          new AddressBooksClient(new AuthClient()).favoriteAddressBookDelete(
            userDetailID ? userDetailID : addressBook.userDetailID,
            getAddressBookID(addressBook)!,
          ),
        );
      }
      return Promise.resolve(addressBook);
    },
    {
      onSuccess: async () => {
        setError('');
        queryClient.invalidateQueries(QueryKeys.AddressBooksSearch);
        queryClient.invalidateQueries(QueryKeys.AddressBooksGlobal);
        queryClient.invalidateQueries(QueryKeys.AddressBooksPrivate);
        queryClient.invalidateQueries(QueryKeys.AddressBooksCollection);
      },
      onError: (err: string) => {
        setError(err as any);
      },
    },
  );

  return { error, toggleFavorite };
};
