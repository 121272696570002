import { Button, Flex, ModalBody, ModalFooter, Text, Wrap, WrapItem } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { IFormQuestion, IPHRFormQuestion, PHRFormQuestion } from '../../../../../generated';
import { ButtonRow } from '../../../../Buttons';
import useFormBuilderContext from '../../../hooks/useFormBuilderContext/useFormBuilderContext';
import { NextButton } from '../../NavigationButtons/NextButton';
import { PrevButton } from '../../NavigationButtons/PrevButton';
import { QuestionHeader } from '../../QuestionHeader/QuestionHeader';

interface IProps {
  formQuestion: IFormQuestion;
  previousAnswer?: IPHRFormQuestion;
}

export const OpinionScaleQuestion = ({ formQuestion, previousAnswer }: IProps) => {
  const [value, setValue] = useState<number | string>();
  const [steps, setSteps] = useState<number[]>([]);
  const { onNext, goToPrevious, canGoBack, formQuestions, state, lastStep } =
    useFormBuilderContext();

  useEffect(() => {
    if (previousAnswer?.value) {
      setValue(previousAnswer?.value);
    }
  }, [previousAnswer, setValue]);

  useEffect(() => {
    const buildSteps = (formQuestion: IFormQuestion) => {
      const steps = new Array<number>();
      let i;
      for (
        i = formQuestion.scaleStart;
        i <= formQuestion.scaleStop!;
        i = i + (formQuestion.scaleIncrement || 1)
      ) {
        steps.push(i);
      }
      setSteps(steps);
    };
    if (formQuestion && formQuestion.scaleStop) {
      buildSteps(formQuestion);
    }
  }, [formQuestion]);

  return (
    <>
      <ModalBody>
        <QuestionHeader
          title={formQuestion.name}
          description={formQuestion.description}
          currentStep={state.currentIndex + 1}
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
          totalQuestionCount={formQuestions?.length!}
        />
        <Flex w="100%" justifyContent="center" alignItems="center" mb={2}>
          <Wrap spacing={getSpacing(steps.length)}>
            {steps.map((x) => (
              <WrapItem>
                <Button
                  w={BUTTON_SIDE_LENGTH}
                  h={BUTTON_SIDE_LENGTH}
                  onClick={() => setValue(x)}
                  isActive={value === x}
                >
                  <Text fontSize="lg" fontWeight="600">
                    {x}
                  </Text>
                </Button>
              </WrapItem>
            ))}
          </Wrap>
        </Flex>
        <Flex w="100%" justifyContent="center" alignItems="center">
          <Flex
            w={`${getWidthInPixel(steps.length)}px`}
            display={{ base: 'none', md: 'flex' }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Text>{formQuestion.scaleLeftLabel}</Text>
            <Text>{formQuestion.scaleRightLabel}</Text>
          </Flex>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <ButtonRow>
          {canGoBack && <PrevButton onClick={goToPrevious} />}
          <NextButton
            lastStep={lastStep}
            onClick={() => {
              const newFormQuestion = new PHRFormQuestion();
              if (previousAnswer) {
                newFormQuestion.init(previousAnswer);
              } else {
                newFormQuestion.formQuestionID = formQuestion.formQuestionID;
              }
              newFormQuestion.value = `${value}`;
              onNext(newFormQuestion);
            }}
            disabled={formQuestion.required && !value}
          />
        </ButtonRow>
      </ModalFooter>
    </>
  );
};

const BUTTON_GAP = 2;
const BUTTON_SIDE_LENGTH = 14;
const PIXELS_PER_UNIT = 4;

const getWidthInPixel = (buttonCount: number) =>
  PIXELS_PER_UNIT * BUTTON_SIDE_LENGTH * buttonCount +
  PIXELS_PER_UNIT * getSpacing(buttonCount) * (buttonCount - 1);

const getSpacing = (buttonCount: number) => (buttonCount > 8 ? BUTTON_GAP : BUTTON_GAP * 2);
