import {
  Alert,
  AlertIcon,
  Box,
  Center,
  Flex,
  HStack,
  IconButton,
  ModalBody,
  ModalFooter,
  Text,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';

import { EditIcon } from '../../../../../assets/icons';
import { AddressBookModal } from '../../../../../components/AddressBook/AddressBookModal';
import { isInNetwork } from '../../../../../components/AddressBook/utils';
import { ButtonRow } from '../../../../../components/Buttons';
import { PrimaryButton } from '../../../../../components/Buttons/PrimaryButton';
import { CenteredLoadingIndicator } from '../../../../../components/LoadingIndicator';
import { BaseModal } from '../../../../../components/Modals';
import Modal from '../../../../../components/Modals/Modal';
import ServerValidationBox from '../../../../../components/ServerValidationBox';
import { UpdatePatientsAddressModal } from '../../../../../components/UpdatePatientsAddressModal/UpdatePatientsAddressModal';
import {
  AddressBook,
  AddressBookFolderEnum,
  Country,
  IPatient,
  PatientPrescription,
} from '../../../../../generated';
import useTranslationComponent from '../../../../../hooks/useTranslationComponent';
import { getAllForPhrSection } from '../../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../../store/types';

import usePrescriptionOrder from '../../hooks/usePrescriptionOrder/usePrescriptionOrder';
import { usePrescriptionOrderModal } from '../../hooks/usePrescriptionOrderModal';
import { arrayHasData, getPrescriptionData } from '../../utils';

import { AddedMedications } from '../AddedMedications/AddedMedications';
import AddNewMedication from '../AddNewMedication/AddNewMedication';
import { SelectPharmacyOptionsModal } from '../SelectPharmacyOptions';

import { BaseModalTitleCards } from './BaseModalTitleCards';

interface IProps {
  isOpen: boolean;
  onDismiss: () => void;
  onQuickMenuClose?: () => void;
  prescription: PatientPrescription | null;
  setPrescription: (prescription: PatientPrescription | null) => void;
  phrConsultID?: string;
  patientID: string;
  country: Country;
  patient: IPatient;
  refreshPhrPatient?: () => void;
  onOpenNotificationModal?: (content: ReactElement | string[]) => void;
}

export const PrescriptionOrderModal = ({
  isOpen,
  onDismiss,
  prescription,
  setPrescription,
  phrConsultID,
  patientID,
  country,
  patient,
  onQuickMenuClose,
  refreshPhrPatient,
  onOpenNotificationModal,
}: IProps) => {
  const { t } = useTranslationComponent(['phr']);
  const dispatch = useDispatch();

  const onClose = () => {
    onDismiss();
    setSelectedMedication(null);
    onQuickMenuClose?.();
    dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRMedications, patientID));
  };

  const {
    expressPharmacyPrescription,
    selectedPharmacyPrescription,
    patientOnlyPrescription,
    isLoading,
    sendCopyToPatient,
    handleUpdateSendCopyToPatient,
    expressPharmacyAddress,
    selectedPharmacyAddress,
    handleUpdateSelectedPharmacyAddress,
    handleAddPhrMedication,
    handleRemovePhrMedication,
    handleSendPhrMedicationToPatient,
    handleUnSendPhrMedicationToPatient,
    medicationDataToRemove,
    handleClearMedicationDataToRemove,
    handleSetMedicationDataToRemove,
    handleUpdateMedicationList,
    handleUpdatePatientAddress,
    handleFinalizePrescription,
    generalPrescriptionError,
    selectedPharmacyCountry,
    prescriptionToEditTypeEnum,
    handleUpdatePrescriptionToEditTypeEnum,
  } = usePrescriptionOrderModal({
    patientID,
    phrConsultID,
    countryID: country.countryID,
    patient,
    onOpenNotificationModal,
    onCloseModal: onClose,
  });

  const countryToUse = selectedPharmacyCountry || country;

  const { selectedMedication, setSelectedMedication, refreshPrescription } = usePrescriptionOrder({
    prescription,
    setPrescription,
    phrConsultID,
    patientID,
    onClose,
    countryID: countryToUse.countryID,
    onQuickMenuClose,
    onOpenNotificationModal,
  });

  const [showPrescription, setShowPrescription] = useState<boolean>(true);

  const [isSubmit, setIsSubmit] = useState<number>(0);
  const [isCancel, setIsCancel] = useState<number>(0);

  const {
    isOpen: isUpdatingPatientAddress,
    onClose: onClosePatientAddressUpdate,
    onOpen: onOpenPatientAddressUpdate,
  } = useDisclosure();

  const {
    isOpen: isAddressBookOpen,
    onClose: onCloseAddressBook,
    onOpen: onOpenAddressBook,
  } = useDisclosure();

  const {
    isOpen: isFinalizePrescriptionOpen,
    onClose: onCloseFinalizePrescription,
    onOpen: onOpenFinalizePrescription,
  } = useDisclosure();

  const { isOpen: isPharmacyOptionsOpen, onClose: onClosePharmacyOptions } = useDisclosure({
    defaultIsOpen: true,
  });

  const isMobile = useBreakpointValue({ base: true, md: false });

  const patientHasValidAddress = patient.addressLine1 || patient.addressLine2;

  const expressPharmacyPrescriptionData = getPrescriptionData(expressPharmacyPrescription);
  const selectedPharmacyPrescriptionData = getPrescriptionData(selectedPharmacyPrescription);
  const patientPrescriptionData = getPrescriptionData(patientOnlyPrescription);

  const medicationsAdded =
    arrayHasData(expressPharmacyPrescriptionData.sortedMedicationsByName) ||
    arrayHasData(selectedPharmacyPrescriptionData.sortedMedicationsByName) ||
    arrayHasData(patientPrescriptionData.sortedMedicationsByName);

  const isDisableButton = !medicationsAdded;
  const title = !medicationsAdded ? t('No medications added.') : '';

  return isOpen ? (
    <BaseModal
      isOpen={isOpen}
      onDismiss={onClose}
      size="xl"
      title={
        <Box>
          <Box borderBottom="1px solid rgb(211, 211, 211)" pb="4">
            <BaseModalTitleCards
              isMobile={isMobile}
              patient={patient}
              selectedAddress={selectedPharmacyAddress}
              onOpenAddressBook={onOpenAddressBook}
            />
          </Box>
          {isMobile && (
            <Box mb="4">
              <HStack>
                <Box>
                  <Text as="span" fontSize="14px" fontWeight="normal">
                    {t('Selected Pharmacy')}:{' '}
                  </Text>
                  <Text as="strong" fontSize="14px">
                    {selectedPharmacyAddress?.name || t('None Selected')}
                  </Text>
                </Box>
                <Box>
                  <IconButton
                    aria-label="Go back"
                    bgColor="transparent"
                    _hover={{ bgColor: 'rgb(219, 217, 217)' }}
                    onClick={onOpenAddressBook}
                    p="2"
                    borderRadius="full"
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              </HStack>
            </Box>
          )}
        </Box>
      }
    >
      {generalPrescriptionError ? (
        <ServerValidationBox message={generalPrescriptionError} />
      ) : isLoading ? (
        <ModalBody height="100%" padding="0 2">
          <CenteredLoadingIndicator />
        </ModalBody>
      ) : (
        <VStack height="100%" alignItems="stretch" overflowY="auto">
          <ModalBody flex="1" overflowY="auto" padding="0 2">
            <Box height="100%" overflowY="auto">
              <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                justifyContent="space-between"
                height="100%"
                overflowY="auto"
              >
                {isUpdatingPatientAddress && (
                  <UpdatePatientsAddressModal
                    patient={patient}
                    isOpen={isUpdatingPatientAddress}
                    close={onClosePatientAddressUpdate}
                    prescription={patientOnlyPrescription}
                    onUpdate={refreshPrescription}
                    updatingDelivery={false}
                    country={countryToUse}
                    refreshPhrPatient={refreshPhrPatient}
                    onUpdatePatientPrescriptionAddress={handleUpdatePatientAddress}
                  />
                )}
                {patientHasValidAddress ? (
                  <Box
                    minW={{ base: '100%', md: '44%' }}
                    maxW="100%"
                    overflowY="auto"
                    borderRadius={4}
                    pr={{ md: '3' }}
                    flex={{ base: '0 1 auto', md: '1' }}
                  >
                    <AddNewMedication
                      selectedMedication={selectedMedication}
                      onCancel={() => {
                        setSelectedMedication(null);
                        handleUpdatePrescriptionToEditTypeEnum(undefined);
                      }}
                      addMedication={(medication, phrMedication) =>
                        handleAddPhrMedication(
                          medication,
                          phrMedication,
                          prescriptionToEditTypeEnum,
                        )
                      }
                      country={countryToUse}
                      patient={patient}
                      setShowPrescription={setShowPrescription}
                      isSubmit={isSubmit}
                      isCancel={isCancel}
                      selectedAddress={selectedPharmacyAddress}
                      handleUpdateMedicationList={handleUpdateMedicationList}
                      defaultAddress={expressPharmacyAddress}
                    />
                  </Box>
                ) : (
                  <Box
                    minW={{ base: '100%', md: '44%' }}
                    maxW="100%"
                    overflowY="auto"
                    borderRadius={4}
                    pr={{ md: '3' }}
                    flex={{ base: '0 1 auto', md: '1' }}
                  >
                    <Alert status="warning" justifyContent="space-between">
                      <Flex flexDirection={{ base: 'column', md: 'row' }} alignItems="center">
                        <Flex>
                          <AlertIcon />
                          {t(
                            'Patient does not have a valid address, please enter the patients address before proceeding.',
                          )}
                        </Flex>
                        <Box mt={{ base: '2', md: '0' }} ml={{ base: '0', md: '2' }}>
                          <PrimaryButton onClick={onOpenPatientAddressUpdate} minW="300px">
                            {t("Update Patient's Address")}
                          </PrimaryButton>
                        </Box>
                      </Flex>
                    </Alert>
                  </Box>
                )}

                {showPrescription && (
                  <VStack
                    width={{ base: '100%', md: '44%' }}
                    alignItems="center"
                    overflowY="auto"
                    flex="1"
                    mt={{ base: '4', md: '0' }}
                  >
                    <Text fontWeight="500" ml="3" mb="2" fontSize="14px" textTransform="uppercase">
                      {t('Prescription Summary')}
                    </Text>
                    <Box flex="1" minWidth="100%" height="100%" overflowY="auto" pb="4">
                      <AddedMedications
                        selectMedicationToEdit={setSelectedMedication}
                        onRemoveMedication={handleSetMedicationDataToRemove}
                        selectedAddress={selectedPharmacyAddress}
                        showCategorizedSummary
                        prescriptionToPatient={patientOnlyPrescription}
                        prescriptionToExpressPharmacy={expressPharmacyPrescription}
                        prescriptionToSelectedPharmacy={selectedPharmacyPrescription}
                        expressPharmacyAddress={expressPharmacyAddress}
                        sendToPatient={sendCopyToPatient}
                        handleSendPhrMedicationToPatient={handleSendPhrMedicationToPatient}
                        handleUnSendPhrMedicationToPatient={handleUnSendPhrMedicationToPatient}
                        handleUpdatePrescriptionToEditTypeEnum={
                          handleUpdatePrescriptionToEditTypeEnum
                        }
                      />
                    </Box>
                  </VStack>
                )}
              </Flex>
            </Box>
          </ModalBody>
          <ModalFooter pt="0">
            <ButtonRow>
              {showPrescription && medicationsAdded && (
                <>
                  <PrimaryButton
                    width={{ base: '100%', md: 'auto' }}
                    onClick={onOpenFinalizePrescription}
                    disabled={isDisableButton}
                    title={title}
                  >
                    {t('Confirm Order')}
                  </PrimaryButton>
                </>
              )}

              {!showPrescription &&
                ((!selectedMedication?.medicationID && !selectedMedication?.name) ||
                  (selectedMedication?.medicationID && selectedMedication?.name)) && (
                  <>
                    <VStack justifyContent="center">
                      <Text
                        mr={2}
                        onClick={() => {
                          setIsCancel((prev) => prev + 1);
                          setSelectedMedication(null);
                        }}
                        role="button"
                        color="red.400"
                      >
                        {t('Cancel')}
                      </Text>
                    </VStack>
                    <PrimaryButton
                      onClick={() => {
                        setIsSubmit((prev) => prev + 1);
                      }}
                    >
                      {t(selectedMedication ? 'Update' : 'Add Medication')}
                    </PrimaryButton>
                  </>
                )}
            </ButtonRow>
          </ModalFooter>
        </VStack>
      )}

      <Modal
        title=""
        cancelText={t('Cancel')}
        onSubmit={() => {
          if (!medicationDataToRemove) return;

          handleRemovePhrMedication(
            medicationDataToRemove.prescriptionType,
            medicationDataToRemove.phrMedication,
          );

          handleClearMedicationDataToRemove();
        }}
        submitText={t('Yes')}
        isOpen={!!medicationDataToRemove}
        onCancel={handleClearMedicationDataToRemove}
        onDismiss={handleClearMedicationDataToRemove}
        modalBodyProps={{ display: 'flex', alignItems: 'center' }}
      >
        <Center height="100%" width="100%">
          <Text textAlign="center">{t('Are you sure you want to remove this prescription?')}</Text>
        </Center>
      </Modal>

      <Modal
        title={t('Finalize Prescription')}
        cancelText={t('Cancel')}
        onSubmit={() => {
          handleFinalizePrescription();

          onCloseFinalizePrescription();
        }}
        submitText={t('Yes')}
        isOpen={isFinalizePrescriptionOpen}
        onCancel={onCloseFinalizePrescription}
        onDismiss={onCloseFinalizePrescription}
        modalBodyProps={{ display: 'flex', alignItems: 'center' }}
      >
        <Center height="100%" width="100%">
          <Text textAlign="center">
            {t('Are you sure you want to finalize this prescription?')}
          </Text>
        </Center>
      </Modal>

      <SelectPharmacyOptionsModal
        isModalOpen={isPharmacyOptionsOpen}
        onCloseModal={onClosePharmacyOptions}
        patientID={patientID}
        onChangeSendCopyToPatient={(sendCopyToPatient: boolean) =>
          handleUpdateSendCopyToPatient(sendCopyToPatient)
        }
        onOpenAddressBook={onOpenAddressBook}
        selectedAddress={selectedPharmacyAddress}
        isLoading={isLoading}
        onChangeAddress={(address: AddressBook) => {
          if (!isInNetwork(address) && !address.faxPhoneNumber) return;
          handleUpdateSelectedPharmacyAddress(address);
        }}
        sendCopyToPatient={sendCopyToPatient}
      />

      <AddressBookModal
        isModalOpen={isAddressBookOpen}
        onDismissModal={onCloseAddressBook}
        addressBookProps={{
          country: countryToUse,
          patient: patient,
          defaultFolder: AddressBookFolderEnum.Pharmacy,
          callback: (addresses?: AddressBook[]) =>
            addresses && handleUpdateSelectedPharmacyAddress(addresses[0]),
          onValidate: (addressBook: AddressBook) =>
            !!(isInNetwork(addressBook) || addressBook.faxPhoneNumber),
          heading: t('Select Pharmacy'),
          onClose: onCloseAddressBook,
          visibleFolders: [AddressBookFolderEnum.Pharmacy],
        }}
      />
    </BaseModal>
  ) : (
    <></>
  );
};
