import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { CurrentUserActionTypes, logoutActions } from '../currentUser/currentUserActions';
import {
  getShoppingCartItemCountActions,
  GetShoppingCartItemCountActionTypes,
} from './getShoppingCartItemCountAction';

export interface IShoppingCartItemCountState {
  error: string;
  isLoading: boolean;
  count: number | null;
}

const count = createReducer<
  number | null,
  GetShoppingCartItemCountActionTypes | CurrentUserActionTypes
>(null)
  .handleAction([getShoppingCartItemCountActions.success], (_state, action) => action.payload)
  .handleAction([logoutActions], () => null);

const error = createReducer<string, GetShoppingCartItemCountActionTypes>('')
  .handleAction([getShoppingCartItemCountActions.failure], (_state, action) => action.payload)
  .handleAction([getShoppingCartItemCountActions.success], () => '');

const isLoading = createReducer<boolean, GetShoppingCartItemCountActionTypes>(false)
  .handleAction([getShoppingCartItemCountActions.request], () => true)
  .handleAction(
    [getShoppingCartItemCountActions.failure, getShoppingCartItemCountActions.success],
    () => false,
  );

export default combineReducers<IShoppingCartItemCountState>({
  count,
  error,
  isLoading,
});
