import { Button, ButtonProps } from '@chakra-ui/react';
import React from 'react';

export type TertiaryButtonProps = ButtonProps;

export const TertiaryButton = (props: TertiaryButtonProps) => {
  const { children, colorScheme = 'blue', ...rest } = props;

  return (
    <Button colorScheme={colorScheme} variant="link" minWidth="unset" fontWeight="700" {...rest}>
      {children}
    </Button>
  );
};

export default TertiaryButton;
