import moment from 'moment';
import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import Card from '../../../../components/Card';
import { CHART_PLUGINS, ITrackerWeightDTO } from '../../../../constants';
import { WeightHeightEnum } from '../../../../generated';
import { OutpostTheme } from '../../../../themes/outpost';
import { getTime } from '../../../../utils';
import {
  BoundaryWrapper,
  ChartButtonRow,
  ChartContainer,
  PrimaryButtonSelect,
} from '../../components/StyledPhrItems';

interface IProps {
  dataSet: ITrackerWeightDTO[];
  unit: WeightHeightEnum;
}

export const WeightAndHeightChart = ({ dataSet, unit }: IProps) => {
  const [daysRange, setDaysRange] = useState<number>(14);
  const [showBmi, setShowBmi] = useState<boolean>(false);
  const heightLabel = `Height (${unit === WeightHeightEnum.Imperial ? 'in' : 'cm'})`;
  const weightLabel = `Weight (${unit === WeightHeightEnum.Imperial ? 'lb' : 'kg'})`;

  const data = () => {
    const sorted = [...dataSet].sort((a, b) => getTime(a.trackerDate) - getTime(b.trackerDate));

    if (showBmi) {
      return {
        datasets: [
          {
            label: 'BMI',
            data: sorted.map((d) => ({ x: d.trackerDate, y: d.bmi })),
            fill: false,
            borderColor: OutpostTheme.Colors.CallToAction,
            borderWidth: 2,
          },
        ],
      };
    } else {
      return {
        datasets: [
          {
            label: weightLabel,
            yAxisID: 'Weight',
            data: sorted.map((d) => ({ x: d.trackerDate, y: d.weight })),
            fill: false,
            borderColor: OutpostTheme.Colors.DarkGreen,
            borderWidth: 2,
          },
          {
            label: heightLabel,
            yAxisID: 'Height',
            data: sorted.map((d) => ({ x: d.trackerDate, y: d.height })),
            fill: false,
            borderColor: OutpostTheme.Colors.DarkBlue,
            borderWidth: 2,
          },
        ],
      };
    }
  };

  const yAxes = showBmi
    ? [
        {
          id: 'Weight',
          ticks: {
            display: false,
            position: 'right',
          },
        },
        {
          ticks: {
            suggestedMin: 18,
            stepSize: 1,
            suggestedMax: 35,
          },
          position: 'left',
        },
      ]
    : [
        {
          id: 'Height',
          scaleLabel: {
            display: true,
            labelString: heightLabel,
          },
          ticks: {
            callback: function (value) {
              if (unit === WeightHeightEnum.Imperial) {
                const feet = Math.floor(value / 12);
                const inches = Math.floor(value % 12);
                return `${feet}'${inches}"`;
              } else {
                if (Math.floor(value) === value) {
                  return value;
                }
              }
            },
            stepSize: unit === WeightHeightEnum.Imperial ? 12 : 20,
            suggestedMin: 0,
            suggestedMax: unit === WeightHeightEnum.Imperial ? 96 : 200,
          },
          position: 'left',
        },
        {
          id: 'Weight',
          scaleLabel: {
            display: true,
            labelString: weightLabel,
          },
          ticks: {
            suggestedMin: 0,
            stepSize: 5,
            suggestedMax: unit === WeightHeightEnum.Imperial ? 250 : 150,
          },
          position: 'right',
        },
      ];

  const options = {
    legend: {
      position: 'bottom',
    },
    scales: {
      yAxes,
      xAxes: [
        {
          type: 'time',
          position: 'bottom',
          distribution: 'linear',
          time: {
            unit: daysRange === 14 ? 'day' : 'month',
          },
          ticks: {
            max: moment(),
            min: moment().subtract(daysRange, 'd'),
          },
        },
      ],
    },
    maintainAspectRatio: false,
    plugins: CHART_PLUGINS,
  };

  return (
    <Card>
      <ChartButtonRow>
        <div>
          <PrimaryButtonSelect active={daysRange === 365} onClick={() => setDaysRange(365)}>
            Year
          </PrimaryButtonSelect>
          <PrimaryButtonSelect active={daysRange === 90} onClick={() => setDaysRange(90)}>
            3 Months
          </PrimaryButtonSelect>
          <PrimaryButtonSelect active={daysRange === 14} onClick={() => setDaysRange(14)}>
            14 Days
          </PrimaryButtonSelect>
        </div>
        <BoundaryWrapper>
          <PrimaryButtonSelect active={!showBmi} onClick={() => setShowBmi(false)}>
            Measurements
          </PrimaryButtonSelect>
          <PrimaryButtonSelect active={showBmi} onClick={() => setShowBmi(true)}>
            BMI
          </PrimaryButtonSelect>
        </BoundaryWrapper>
      </ChartButtonRow>
      <ChartContainer>
        {/* @ts-ignore */}
        <Line data={data} options={options} />
      </ChartContainer>
    </Card>
  );
};
