import React from 'react';
import { PrimaryButton } from '../../../Buttons';
import { Arrow } from './ButtonStyles';

interface IProps {
  onClick?: () => void;
  disabled?: boolean;
  lastStep?: boolean;
}

export const NextButton = ({ onClick, disabled = false, lastStep = false, ...rest }: IProps) => {
  return (
    <PrimaryButton onClick={onClick} disabled={disabled} type="submit" {...rest}>
      {lastStep ? 'Finish' : <Arrow icon={['far', 'long-arrow-right']} />}
    </PrimaryButton>
  );
};
