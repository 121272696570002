import { ActionType, createAction, createAsyncAction } from 'typesafe-actions';
import { AuthClient, SystemMessage, SystemMessagesClient } from '../../generated';
import { httpRequest } from '../../utils';
import { AppState, OHThunkResult } from '../root-reducers';
import { IDismissibleSystemMessage } from './systemMessagesReducer';

export const getSystemMessagesActions = createAsyncAction(
  ['GET_SYSTEM_MESSAGES_REQUEST', () => {}],
  ['GET_SYSTEM_MESSAGES_SUCCESS', (res: IDismissibleSystemMessage[]) => res],
  ['GET_SYSTEM_MESSAGES_ERROR', (err: string) => err],
)();

export const newSystemMessageAction = createAction(
  'NEW_SYSTEM_MESSAGE',
  (systemMessage: SystemMessage) => systemMessage,
)();

export function getSystemMessages(): OHThunkResult<Promise<IDismissibleSystemMessage[]>> {
  return async (dispatch, getState: () => AppState) => {
    return new Promise<IDismissibleSystemMessage[]>(async (resolve) => {
      dispatch(getSystemMessagesActions.request());
      const systemMessagesClient = new SystemMessagesClient(new AuthClient());
      try {
        const result = await httpRequest(() => systemMessagesClient.active());

        let dismissibleSystemMessages: IDismissibleSystemMessage[] = result.map((x) => ({
          systemMessage: x,
          isDismissed: false,
        }));

        const currentSystemMessages = getState().systemMessagesState.systemMessages;
        if (currentSystemMessages.length > 0) {
          dismissibleSystemMessages = dismissibleSystemMessages.map((x) => {
            currentSystemMessages.map((y) => {
              if (x.systemMessage.systemMessageID === y.systemMessage.systemMessageID) {
                x.isDismissed = y.isDismissed;
              }
              return y;
            });
            return x;
          });
        }

        dispatch(getSystemMessagesActions.success(dismissibleSystemMessages));
        resolve(dismissibleSystemMessages);
      } catch (err) {
        dispatch(getSystemMessagesActions.failure(err as any));
      }
    });
  };
}

export function dismissSystemMessage(systemMessageID: string): OHThunkResult<Promise<void>> {
  return async (dispatch, getState: () => AppState) => {
    return new Promise<void>(async (resolve) => {
      dispatch(getSystemMessagesActions.request());
      try {
        let systemMessages = getState().systemMessagesState.systemMessages;
        systemMessages = systemMessages.map((x) => {
          if (x.systemMessage.systemMessageID === systemMessageID) {
            x.isDismissed = true;
          }
          return x;
        });
        dispatch(getSystemMessagesActions.success(systemMessages));
        resolve();
      } catch (err) {
        dispatch(getSystemMessagesActions.failure(err as any));
      }
    });
  };
}

export type GetSystemMessagesActionTypes = ActionType<
  typeof getSystemMessagesActions | typeof newSystemMessageAction
>;
