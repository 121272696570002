import { Button, Grid, Text } from '@chakra-ui/react';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';

import { PHRFamilyHistory, RelationshipTypeEnum } from '../../../generated';
import { createForPhrSection, updateForPhrSection } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { enumToOptions } from '../../../utils';

import { Form, FormInputControl, FormSelectComponent } from '../../../components/forms';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import ServerValidationBox from '../../../components/ServerValidationBox';
import { OutpostTheme } from '../../../themes/outpost';
import PhrItemForm from '../components/PhrItemForm';

enum EFamilySide {
  Father = 'Father',
  Mother = 'Mother',
}

interface IProps {
  selectedFamilyHistory: PHRFamilyHistory | null;
  patientID: string;
  toggleView: () => void;
  innerBtnRef: React.RefObject<HTMLButtonElement>;
}

interface IForm {
  condition: string;
  notes?: string;
  familySide: string;
  relationshipTypeEnum: RelationshipTypeEnum;
}

const FamilyHistoryForm: React.FC<IProps> = ({
  selectedFamilyHistory,
  patientID,
  toggleView,
  innerBtnRef,
}) => {
  const dispatch = useDispatch();
  const { error, isPhrLoading } = useSelector((state: AppState) => state.phrPatientState);

  const submit = async (values: IForm) => {
    const familyHistory = new PHRFamilyHistory();
    familyHistory.init(selectedFamilyHistory);
    familyHistory.condition = values.condition;
    familyHistory.notes = values.notes ? values.notes : '';
    familyHistory.patientID = patientID;
    familyHistory.fathersSide = values.familySide === EFamilySide.Father;
    familyHistory.mothersSide = values.familySide === EFamilySide.Mother;
    familyHistory.relationshipTypeEnum = values.relationshipTypeEnum;

    if (selectedFamilyHistory) {
      await dispatch(
        updateForPhrSection(
          ReduxPHRCategoryEnum.PHRFamilyHistories,
          familyHistory,
          familyHistory.patientID,
          false,
        ),
      );
    } else {
      await dispatch(
        createForPhrSection(
          ReduxPHRCategoryEnum.PHRFamilyHistories,
          familyHistory,
          familyHistory.patientID,
          false,
        ),
      );
    }

    toggleView();
  };

  const initialValues: IForm = {
    condition: selectedFamilyHistory ? selectedFamilyHistory.condition : '',
    familySide: selectedFamilyHistory?.fathersSide
      ? EFamilySide.Father
      : selectedFamilyHistory?.mothersSide
      ? EFamilySide.Mother
      : '',
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    relationshipTypeEnum: selectedFamilyHistory?.relationshipTypeEnum!,
  };

  return (
    <PhrItemForm>
      <Formik
        initialValues={initialValues}
        validationSchema={object({
          condition: string()
            .required('Condition is required')
            .max(250, 'Condition must be at most 250 characters'),
          familySide: string().required('Family side must be selected'),
          relationshipTypeEnum: string().required('A Relatiionship must be selected'),
        })}
        onSubmit={(values) => submit(values)}
      >
        {({ status }) => (
          <Form error={error}>
            <>
              <ServerValidationBox message={status} />
              {isPhrLoading ? (
                <CenteredLoadingIndicator />
              ) : (
                <>
                  <Text
                    color="#3E1191"
                    fontSize={OutpostTheme.FontSizes.body}
                    textAlign="center"
                    mb="1.5rem"
                  >
                    This section collects information about health conditions that any members of
                    your family might have
                  </Text>
                  <Grid
                    gridTemplateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                    gridTemplateRows="auto"
                    gridGap={4}
                  >
                    <FormInputControl
                      type="text"
                      name="condition"
                      label="Condition"
                      placeholder="Enter name of Condition"
                      height="48px"
                      borderRadius="lg"
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />

                    <FormSelectComponent
                      name="relationshipTypeEnum"
                      options={enumToOptions(RelationshipTypeEnum)}
                      label="Who has this condition?"
                      valueKey="id"
                      labelKey="description"
                      isClearable={false}
                      components={{
                        Placeholder: () => (
                          <Text
                            fontSize={OutpostTheme.FontSizes.body}
                            color="#0516466E"
                            paddingLeft="0.5rem"
                          >
                            Select Relationship
                          </Text>
                        ),
                        IndicatorSeparator: null,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: '48px',
                          backgroundColor: '#F7F9FC',
                          borderRadius: '8px',
                        }),
                      }}
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />

                    <FormSelectComponent
                      name="familySide"
                      label="What side of the family is this relative from?"
                      options={[
                        { value: EFamilySide.Father, label: 'Father Side' },
                        { value: EFamilySide.Mother, label: 'Mother Side' },
                      ]}
                      isClearable={false}
                      components={{
                        Placeholder: () => (
                          <Text
                            fontSize={OutpostTheme.FontSizes.body}
                            color="#0516466E"
                            paddingLeft="0.5rem"
                          >
                            Select Family Side
                          </Text>
                        ),
                        IndicatorSeparator: null,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: '48px',
                          backgroundColor: '#F7F9FC',
                          borderRadius: '8px',
                        }),
                      }}
                      formControlProps={{
                        formLabelProps: {
                          textTransform: 'uppercase',
                          color: '#01143173',
                          fontSize: '12px',
                          fontWeight: 'light',
                        },
                      }}
                    />
                  </Grid>
                  <Button display="none" ref={innerBtnRef} type="submit" />
                </>
              )}
            </>
          </Form>
        )}
      </Formik>
    </PhrItemForm>
  );
};

export default FamilyHistoryForm;
