import { Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import { ProviderOnboardingDTO } from '../../../generated';
import { useProviderOnboardingByUserDetailID } from '../../../services/providerOnboarding/queries/useProviderOnboardingByUserDetailID';
import { ICurrentUser } from '../../../store/currentUser/currentUserReducers';
import { AppState } from '../../../store/root-reducers';

interface IOnboardingContext {
  currentUser: ICurrentUser | null;
  providerOnboardingDetails?: ProviderOnboardingDTO;
  setHasSubmitted: (hasSubmitted: boolean) => void;
  hasSubmitted: boolean;
}

export const OnboardingContext = React.createContext<IOnboardingContext>({
  currentUser: null,
  setHasSubmitted: () => {},
  hasSubmitted: false,
});

export const OnboardingContextProvider = ({ children }) => {
  const currentUser = useSelector((state: AppState) => state.currentUserState.data);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { providerOnboardingDetails } = useProviderOnboardingByUserDetailID(
    currentUser?.userDetailID ?? '',
    hasSubmitted,
  );

  return (
    <OnboardingContext.Provider
      value={{
        currentUser,
        providerOnboardingDetails,
        setHasSubmitted: () => setHasSubmitted(true),
        hasSubmitted,
      }}
    >
      <Box maxW="1535px" margin="0 auto">
        {!providerOnboardingDetails ? <CenteredLoadingIndicator /> : children}
      </Box>
    </OnboardingContext.Provider>
  );
};
