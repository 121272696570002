import { Box, HStack, Stack } from '@chakra-ui/react';
import { FC } from 'react';

import { FormControlProps, SelectComponent } from '../../../../components/forms';
import { Country } from '../../../../generated';
import useTranslationComponent from '../../../../hooks/useTranslationComponent';

type CountrySelectorProps = {
  countries: Country[];
  onSelectCountry: (country: Country) => void;
  selectedCountry?: Country;
  isLoadingCountries: boolean;
  formControlProps?: Omit<FormControlProps, 'label' | 'children'>;
  customStyles?: Omit<FormControlProps, 'children' | 'label'>;
};

const CountrySelector: FC<CountrySelectorProps> = (props) => {
  const { t } = useTranslationComponent();

  return (
    <Stack
      alignItems={{ base: 'stretch', md: 'center' }}
      direction={{ base: 'column', md: 'row' }}
      spacing="0"
    >
      <SelectComponent
        options={props.countries}
        name="country"
        getOptionLabel={(label: Country) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{ transform: 'scale(0.75)', borderRadius: '50%' }}
              className={`flag flag-${(label.code ?? 'US').toLocaleLowerCase()}`}
            />
            <span style={{ marginLeft: '5px' }}>{(label.name ?? '').toLocaleLowerCase()}</span>
          </div>
        )}
        isSearchable={false}
        getOptionValue={(label: Country) => label.countryID}
        placeholder={t(props.isLoadingCountries ? 'Please wait' : 'Select country')}
        menuPosition="fixed"
        isLoading={props.isLoadingCountries}
        isClearable={false}
        value={props.selectedCountry}
        onChange={props.onSelectCountry}
        formControlProps={{
          maxW: '240px',
          w: '216px',
          mb: '0',
          mt: '0',
          ...props.formControlProps,
        }}
        customStyles={{
          backgroundColor: '#fff',
          borderRadius: '12px',
          borderColor: 'transparent',
          ...props.customStyles,
        }}
      />
    </Stack>
  );
};

export { CountrySelector };
