import React, { useState, useEffect } from 'react';
import { chakra } from '@chakra-ui/react';
import { ToggleButton } from './ToggleButton';
import { ToggleArea } from './ToggleArea';
import { toggleSwitchContext } from './ToggleSwitchContext';
import styled from 'styled-components';
import { AppState } from '../../store/root-reducers';
import { useSelector } from 'react-redux';
import { PhoneEventsEnum } from '../../constants';

const { Provider } = toggleSwitchContext;

interface IProps {
  children: any;
  initial?: string;
  active?: string;
  onClick?: () => void;
}

const ToggleSwitch = ({ children, initial = '', active = '', onClick }: IProps) => {
  const [activeId, setActiveId] = useState<string>(initial);

  const currentUser = useSelector((state: AppState) => state.currentUserState.data);

  const handleTabClickCallback = (tab: string) => {
    if (currentUser?.isInMobileView) {
      if (currentUser.mobileSystem === 'android') {
        if (tab === 'current') {
          // @ts-ignore
          AndroidResult.callback(PhoneEventsEnum.CURRENT_CART_TAB_CALLBACK);
        } else if (tab === 'paylater') {
          // @ts-ignore
          AndroidResult.callback(PhoneEventsEnum.PAY_LATER_CART_TAB_CALLBACK);
        }
      } else {
        if (tab === 'current') {
          // @ts-ignore
          webkit.messageHandlers.currentCartTabCallbackHandler.postMessage(
            PhoneEventsEnum.CURRENT_CART_TAB_CALLBACK,
          );
        } else if (tab === 'paylater') {
          // @ts-ignore
          webkit.messageHandlers.payLaterCartTabCallbackHandler.postMessage(
            PhoneEventsEnum.PAY_LATER_CART_TAB_CALLBACK,
          );
        }
      }
    }
  };

  const handleClick = (activeId: string) => {
    handleTabClickCallback(activeId);
    setActiveId(activeId);
  };

  useEffect(() => {
    if (active) {
      setActiveId(active);
    }
  }, [active]);

  return <Provider value={{ activeId, handleClick, active, onClick }}>{children}</Provider>;
};

const ToggleBackground = styled.div`
  background-color: #eaebf1;
  padding: 5px;
  border-radius: 15px;
  min-width: 290px;
  max-width: 290px;
`;

const StyledToggleBackground = chakra(ToggleBackground);

ToggleSwitch.ButtonWrapper = StyledToggleBackground;
ToggleSwitch.Button = ToggleButton;
ToggleSwitch.Area = ToggleArea;

export default ToggleSwitch;
