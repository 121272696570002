import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PHRTrackerRespiratoryDTO } from '../../../../generated';
import { IPHRPatientEnhanced } from '../../../../store/patient/phr/phrReducers';
import { phrRespiratoriesSelector } from '../../../../store/patient/phr/phrSelectors';
import { getAllForPhrSection } from '../../../../store/root-creator';
import { ReduxPHRCategoryEnum } from '../../../../store/types';
import PhrItem, { SecondaryInfoComponent } from '../../components/PhrItem';
import { VitalsTracker } from '../../vitals/components/PatientVitalsCard/usePatientVitalsCard';
import TrackerView from '../TrackerView';
import RespiratoryChart from './RespiratoryChart';
import RespiratoryForm from './RespiratoryForm';

interface IProps {
  patient: IPHRPatientEnhanced;
  renderHeader: (addButton?: any, onBack?: () => void, title?: string) => any;
  defaultBack?: any;
  defaultTitle?: string;
  showSingleTrackerForm?: (id: ReduxPHRCategoryEnum, tracker: VitalsTracker) => void;
}

const RespiratoryComponent = ({
  patient,
  renderHeader,
  showSingleTrackerForm,
  defaultBack,
  defaultTitle,
}: IProps) => {
  const dispatch = useDispatch();
  const phrRespiratoryRates = phrRespiratoriesSelector(patient.phrRespiratories);

  useEffect(() => {
    if (patient) {
      dispatch(getAllForPhrSection(ReduxPHRCategoryEnum.PHRRespiratories, patient.patientID));
    }
  }, [dispatch, patient]);

  const thinView = (x: PHRTrackerRespiratoryDTO, onView?: (data: any) => void) => (
    <>
      <PhrItem
        name={`${x.respiratory} breaths / min`}
        isVerified={x.verified}
        onView={() => {
          if (onView) {
            onView(x);
          }
        }}
        SecondaryInfo={
          <SecondaryInfoComponent
            startDate={moment(x.trackerDate).toDate()}
            startDateTitle="Created on"
            showTime
          />
        }
      />
    </>
  );

  return (
    <TrackerView
      renderHeader={renderHeader}
      phrCategoryEnum={ReduxPHRCategoryEnum.PHRRespiratories}
      patient={patient}
      trackerData={phrRespiratoryRates}
      thinView={thinView}
      Chart={RespiratoryChart}
      Form={RespiratoryForm}
      createText="Respiratory"
      showSingleTrackerForm={showSingleTrackerForm}
      defaultBack={defaultBack}
      defaultTitle={defaultTitle}
    />
  );
};

export default RespiratoryComponent;
