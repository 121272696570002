export enum ReduxPHRCategoryEnum {
  PHRPatients = 'PHRPatients',
  PHRAllergies = 'PHRAllergies',
  PHRAllergyDetails = 'PHRAllergyDetails',
  PHRHealthConditions = 'PHRHealthConditions',
  PHRHealthJournals = 'PHRHealthJournals',
  PHRDependants = 'PHRDependants',
  PHRConsults = 'PHRConsults',
  PHRDocuments = 'PHRDocuments',
  PHRTrackers = 'PHRTrackers',
  PHRBloodPressures = 'PHRBloodPressures',
  PHRPulses = 'PHRPulses',
  PHRWeights = 'PHRWeights',
  PHRBloodGlucoses = 'PHRBloodGlucoses',
  PHRBloodOxygens = 'PHRBloodOxygens',
  PHRRespiratories = 'PHRRespiratories',
  PHRHeights = 'PHRHeights',
  PHRHCVs = 'PHRHCVs',
  PHRTemperatures = 'PHRTemperatures',
  PHRMedications = 'PHRMedications',
  PHRSurgeryHistories = 'PHRSurgeryHistories',
  PHRFamilyHistories = 'PHRFamilyHistories',
  PHRCareTeams = 'PHRCareTeams',
  PHRInsurances = 'PHRInsurances',
  PHRNotes = 'PHRNotes',
  PHRStepCounters = 'PHRStepCounters',
  PHRUricAcids = 'PHRUricAcids',
  PHRHemoglobins = 'PHRHemoglobins',
  PHRCholesterols = 'PHRCholesterols',
  PHRFetalDopplers = 'PHRFetalDopplers',
  PHRTyphoids = 'PHRTyphoids',
  PHRDengues = 'PHRDengues',
  PHRMalarias = 'PHRMalarias',
  PHRChinkungunias = 'PHRChinkungunias',
  PHRTroponins = 'PHRTroponins',
  PHRSyphilises = 'PHRSyphilises',
  PHRHIVs = 'PHRHIVs',
  PHRRapidPregnancies = 'PHRRapidPregnancies',
  PHRHeadCircumferences = 'PHRHeadCircumferences',
  PHRWaistCircumferences = 'PHRWaistCircumferences',
  PHRWeightHeights = 'PHRWeightHeights',
  PHRLengths = 'PHRLengths',
  PHRMoods = 'PHRMoods',
  PHRAnxieties = 'PHRAnxieties',
  PHRStresses = 'PHRStresses',
  PHRSleeps = 'PHRSleeps',
  PHRVaccinations = 'PHRVaccinations',
  PHRCovids = 'PHRCovids',
}
