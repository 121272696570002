import { Box, Flex, HStack } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TertiaryButton } from '../../../components/Buttons';
import { CenteredLoadingIndicator } from '../../../components/LoadingIndicator';
import ServerValidationBox from '../../../components/ServerValidationBox';
import { PHRMedication } from '../../../generated';
import { clearPHRPatientErrorAction } from '../../../store/patient/phr/phrActions';
import { IPHRPatientCombined } from '../../../store/patient/phr/phrReducers';
import {
  activeMedicationSelector,
  pastMedicationSelector,
} from '../../../store/patient/phr/phrSelectors';
import { deleteForPhrSection, getAllForPhrSection } from '../../../store/root-creator';
import { AppState } from '../../../store/root-reducers';
import { ReduxPHRCategoryEnum } from '../../../store/types';
import { scrollToTop } from '../../../utils/scrollToTop';
import { AddPHRItemButton } from '../components/PhrItem';
import PhrItemDeleteModal from '../components/PhrItemDeleteModal';
import { PhrFormButtons } from '../components/PhrItemForm';
import { PhrDetailsButtons } from '../components/PhrItemView';
import { MedicationList } from './components/MedicationList/MedicationList';
import { MedicationsForm } from './components/MedicationsForm/MedicationsForm';
import MedicationView from './components/MedicationView';

enum EMedicationsViewState {
  List = 'List',
  View = 'View',
  Edit = 'Edit',
}

enum EMedicationsTab {
  Active = 'Active',
  Past = 'Past',
}

interface IProps {
  phrPatient: IPHRPatientCombined;
  renderHeader: (onCreate?: any, onBack?: () => void, title?: string, showDismiss?: boolean) => any;
}

const MedicationsComponent = ({ phrPatient, renderHeader }: IProps) => {
  const dispatch = useDispatch();
  const innerBtnRef = useRef<HTMLButtonElement>(null);
  const [currentView, setCurrentView] = useState(EMedicationsViewState.List);
  const [currentTab, setCurrentTab] = useState(EMedicationsTab.Active);
  const [selectedMedication, setSelectedMedication] = useState<PHRMedication | null>(null);
  const [isInitialized, setInitialized] = useState<boolean>(false);
  const pastMedications = pastMedicationSelector(phrPatient?.phr?.phrMedications);
  const activeMedications = activeMedicationSelector(phrPatient?.phr?.phrMedications);
  const [recordToDelete, setRecordToDelete] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { isPhrLoading, error } = useSelector((state: AppState) => state.phrPatientState);

  useEffect(() => {
    if (phrPatient && !isInitialized) {
      setInitialized(true);
      dispatch(
        getAllForPhrSection(ReduxPHRCategoryEnum.PHRMedications, phrPatient.patient.patientID),
      );
    }
  }, [dispatch, isInitialized, phrPatient]);

  useEffect(() => {
    dispatch(clearPHRPatientErrorAction());
    scrollToTop();
  }, [currentView, dispatch]);

  const onCreate = () => {
    setCurrentView(EMedicationsViewState.Edit);
    setSelectedMedication(null);
  };

  const onView = (medication: PHRMedication) => {
    setSelectedMedication(medication);
    setCurrentView(EMedicationsViewState.View);
  };

  const onEdit = (medication: PHRMedication) => {
    setSelectedMedication(medication);
    setCurrentView(EMedicationsViewState.Edit);
  };

  const onDelete = (phrHealthConditionID: string) => {
    setShowDeleteModal(true);
    setRecordToDelete(phrHealthConditionID);
  };

  const confirmDelete = async () => {
    setCurrentView(EMedicationsViewState.List);
    setShowDeleteModal(false);
    await dispatch(
      deleteForPhrSection(
        ReduxPHRCategoryEnum.PHRMedications,
        recordToDelete,
        phrPatient.patient.patientID,
        false,
      ),
    );
  };

  return (
    <Box paddingY="2rem" backgroundColor="#f4f4f4" height="100%">
      {currentView === EMedicationsViewState.List ? (
        renderHeader()
      ) : (
        <Flex>
          {renderHeader(null, () => setCurrentView(EMedicationsViewState.List), '', false)}
          {currentView === EMedicationsViewState.View && (
            <PhrDetailsButtons
              isVerified={selectedMedication?.verified}
              phrItem={selectedMedication}
              phrItemID={selectedMedication?.phrMedicationID}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          )}
          {currentView === EMedicationsViewState.Edit && (
            <PhrFormButtons
              innerBtnRef={innerBtnRef}
              toggleView={() => setCurrentView(EMedicationsViewState.List)}
            />
          )}
        </Flex>
      )}

      {currentView === EMedicationsViewState.List && (
        <>
          <AddPHRItemButton name="Medication" onCreate={onCreate} />
          {isPhrLoading ? (
            <Box marginTop="1rem">
              <CenteredLoadingIndicator />
            </Box>
          ) : (
            <Box marginTop="1.5rem">
              <ServerValidationBox message={error} />
              <HStack
                spacing={12}
                justify="center"
                borderBottom={{ base: '1.5px solid #F3F3F3', md: 'none' }}
              >
                <MedicationTabButton
                  name="Active"
                  tab={EMedicationsTab.Active}
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
                <MedicationTabButton
                  name="Past"
                  tab={EMedicationsTab.Past}
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
              </HStack>
              {currentTab === EMedicationsTab.Active && (
                <MedicationList
                  emptyMessage="No Active Medications"
                  phrMedications={activeMedications}
                  onView={onView}
                />
              )}
              {currentTab === EMedicationsTab.Past && (
                <MedicationList
                  emptyMessage="No Past Medications"
                  phrMedications={pastMedications}
                  onView={onView}
                />
              )}
            </Box>
          )}
        </>
      )}

      {currentView === EMedicationsViewState.View && (
        <MedicationView medication={selectedMedication!} />
      )}

      {currentView === EMedicationsViewState.Edit && (
        <MedicationsForm
          selectedMedication={selectedMedication}
          toggleView={() => setCurrentView(EMedicationsViewState.List)}
          patientID={phrPatient.patient.patientID!}
          innerBtnRef={innerBtnRef}
        />
      )}

      <PhrItemDeleteModal
        isOpen={showDeleteModal}
        onDismiss={() => setShowDeleteModal(false)}
        onDelete={confirmDelete}
      />
    </Box>
  );
};

export default MedicationsComponent;

const MedicationTabButton = ({ name, tab, currentTab, setCurrentTab }) => {
  return (
    <TertiaryButton
      padding="0.5rem"
      width="148px"
      _hover={{
        textDecoration: 'none',
      }}
      borderRadius="none"
      fontWeight="500"
      color={currentTab === tab ? '#020202' : '#0C34685E'}
      borderBottom={currentTab === tab ? '2px solid #020202' : ''}
      onClick={() => setCurrentTab(tab)}
    >
      {name}
    </TertiaryButton>
  );
};
