import { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  AddressBook,
  AddressBookFolderEnum,
  AddressBookSortByEnum,
  AddressBookTypeEnum,
  ICountry,
  SendToEnum,
  Tag,
} from '../../../generated';
import { useAddressBooksDelete } from '../../../services/addressBooks/mutations/useAddressBooksDelete';
import { useAddressBooksMany } from '../../../services/addressBooks/mutations/useAddressBooksMany';
import { useAddressBooksPost } from '../../../services/addressBooks/mutations/useAddressBooksPost';
import { useAddressBooksPut } from '../../../services/addressBooks/mutations/useAddressBooksPut';
import { useAddressBooksSetPrimary } from '../../../services/addressBooks/mutations/useAddressBooksSetPrimary';
import { useAddressBooksToggleFavorite } from '../../../services/addressBooks/mutations/useAddressBooksToggleFavorite';
import { useAddressBookTagsDelete } from '../../../services/addressBooks/mutations/useAddressBookTagsDelete';
import { useAddressBookTagsPost } from '../../../services/addressBooks/mutations/useAddressBookTagsPost';
import { useAddressBookTagsPut } from '../../../services/addressBooks/mutations/useAddressBookTagsPut';
import { useAddressBooksSearchInfinity } from '../../../services/addressBooks/queries/useAddressBooksSearchInfinity';
import { AddressBookDeliveryModeEnum, AddressBookModeEnum } from '../AddressBook';
import { useAddressBookFolders } from '../hooks/useAddressBookFolders';
import { isSameAddress } from '../utils';

enum AddressBookCustomCategoryEnum {
  Private = 'Private',
  Favorite = 'Favorite',
}
export const AddressBookCategoryEnum = {
  ...AddressBookCustomCategoryEnum,
  ...AddressBookFolderEnum,
};
export const AddressBookCategories = Object.values(AddressBookCategoryEnum);
export interface ICategoryMap {
  label: string;
  counter: () => void;
  icon: any;
}

export type CurrentLocationType = {
  longitude: number;
  latitude: number;
};

export const useAddressBooks = (
  mode: AddressBookModeEnum,
  deliveryMode: AddressBookDeliveryModeEnum,
  defaultCountry: ICountry,
  currentUserDetailID: string,
  defaultFolder?: AddressBookFolderEnum,
  defaultFolders?: AddressBookFolderEnum[],
  visibleFolders?: AddressBookFolderEnum[],
  preSelectedAddress?: AddressBook,
) => {
  const [country, setCountry] = useState<ICountry>(defaultCountry);
  const [specialty, setSpecialty] = useState<Tag>();
  const [searchText, setSearchText] = useState<string>('');

  const [addressBookTypeEnum, setAddressBookTypeEnum] = useState<AddressBookTypeEnum | undefined>(
    AddressBookTypeEnum.Global,
  );
  const [addressBookSortByEnum, setAddressBookSortByEnum] = useState<
    AddressBookSortByEnum | undefined
  >(AddressBookSortByEnum.All);
  const [debounceSearchText] = useDebounce(searchText, 300);

  const [currentLocation, setCurrentLocation] = useState<CurrentLocationType>();

  const defaultFolderArr = defaultFolder ? [defaultFolder] : [];

  const { selectedAddressBookFolders, onToggleAddressBookFolder } = useAddressBookFolders({
    defaultSelectedFolders: defaultFolders || defaultFolderArr,
    visibleFolders,
  });

  const {
    totalPages,
    totalCount,
    goToPage,
    currentPage,
    addressBooksResult: searchResult,
    addressBooksError: error,
    isLoadingAddressBooks: isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useAddressBooksSearchInfinity({
    countryID: country.countryID,
    specialtyTagID: specialty?.tagID,
    currentUserDetailID,
    searchText: debounceSearchText,
    addressBookTypeEnum,
    addressBookSortByEnum,
    currentLocation,
    addressBookFolderEnums: selectedAddressBookFolders,
  });

  const { error: toggleFavoriteError, toggleFavorite } = useAddressBooksToggleFavorite();
  const { error: addressBooksPostError, addressBooksPost } = useAddressBooksPost();
  const { error: addressBooksPutError, addressBooksPut } = useAddressBooksPut();
  const { error: addressBooksDeleteError, addressBooksDelete } = useAddressBooksDelete();
  const { addressBookTagsPost } = useAddressBookTagsPost();
  const { addressBookTagsPut } = useAddressBookTagsPut();
  const { addressBookTagsDelete } = useAddressBookTagsDelete();
  const { addressBooksSetPrimary } = useAddressBooksSetPrimary();
  const { addressBooksMany } = useAddressBooksMany();

  const [selectedAddresses, setSelectedAddresses] = useState<AddressBook[]>([]);
  const [sendToEnum, setSendToEnum] = useState<SendToEnum>(SendToEnum.DoNotSend);
  const [mutationError, setMutationError] = useState<string>('');

  const onSelect = useCallback(
    (addressBook: AddressBook) => {
      let addresses: AddressBook[];
      if (deliveryMode === AddressBookDeliveryModeEnum.Multiple) {
        addresses = selectedAddresses ? [...selectedAddresses] : [];
        addresses.push(addressBook);
      } else {
        addresses = [addressBook];
      }
      setSelectedAddresses(addresses);
    },
    [deliveryMode, selectedAddresses],
  );
  const onUnSelect = (addressBook?: AddressBook) => {
    if (addressBook) {
      setSelectedAddresses(selectedAddresses?.filter((x) => !isSameAddress(x, addressBook)));
    } else {
      setSelectedAddresses([]);
    }
  };

  const onChangeLocation = useCallback((location?: CurrentLocationType) => {
    setCurrentLocation(location);
  }, []);

  useEffect(() => {
    const errors = new Array<string>();
    if (toggleFavoriteError) errors.push(toggleFavoriteError);
    if (addressBooksPostError) errors.push(addressBooksPostError);
    if (addressBooksPutError) errors.push(addressBooksPutError);
    if (addressBooksDeleteError) errors.push(addressBooksDeleteError);
    setMutationError(errors.join(', '));

    if (preSelectedAddress && selectedAddresses.length === 0) onSelect(preSelectedAddress);
  }, [
    toggleFavoriteError,
    addressBooksPostError,
    addressBooksPutError,
    addressBooksDeleteError,
    selectedAddresses.length,
    preSelectedAddress,
    onSelect,
  ]);

  return {
    error,
    mutationError,
    isLoading,
    selectedAddresses,
    setSelectedAddresses,
    // handlers ---
    onFavorite: toggleFavorite.mutateAsync,
    onCreate: addressBooksPost.mutateAsync,
    onEdit: addressBooksPut.mutateAsync,
    onDelete: addressBooksDelete.mutateAsync,
    onCreateTags: addressBookTagsPost.mutateAsync,
    onEditTags: addressBookTagsPut.mutateAsync,
    onDeleteTags: addressBookTagsDelete.mutateAsync,
    onSetPrimary: addressBooksSetPrimary.mutateAsync,
    onUpdateTags: addressBooksMany.mutateAsync,
    onSelect,
    onUnSelect,
    //-------------
    sendToEnum,
    setSendToEnum,
    // search -----
    setSearchText,
    searchResult,
    totalPages,
    goToPage,
    currentPage,
    totalCount,
    addressBookSortByEnum,
    setAddressBookTypeEnum,
    setAddressBookSortByEnum,
    country,
    setCountry,
    specialty,
    setSpecialty,
    // ---------
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    onChangeLocation,
    selectedAddressBookFolders,
    onToggleAddressBookFolder,
    addressBookTypeEnum,
  };
};
