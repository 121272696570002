import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { API_KEY } from '../constants';
import { AuthClient, TermsAndCondition, TermsAndConditionsClient } from '../generated';
import { httpRequest } from '../utils';
import { CenteredLoadingIndicator } from './LoadingIndicator';
import Modal from './Modals/Modal';
import ServerValidationBox from './ServerValidationBox';

interface IProps {
  name: string;
  title: string;
  isOpen: boolean;
  onDismiss?: () => void;
  onCancel?: () => void;
  cancelText?: string;
  onSubmit?: () => void;
  submitText?: string;
  isLoading?: boolean;
  error?: string;
}

const ToCModalComponent = (props: IProps) => {
  const { name, error, ...rest } = props;
  const [toc, setToc] = useState<TermsAndCondition>(new TermsAndCondition());
  const [isContentsLoading, setContentsLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState<string>('');

  useEffect(() => {
    const getToc = async () => {
      const authClient = new AuthClient();
      const termsAndConditionsClient = new TermsAndConditionsClient(authClient);
      setContentsLoading(true);
      try {
        const toc = await httpRequest(() => termsAndConditionsClient.byName(name, API_KEY));
        setToc(toc);
        setContentsLoading(false);
      } catch (err) {
        setContentsLoading(false);
        setLoadingError(err as string);
      }
    };
    getToc();
  }, [name]);

  return (
    <Modal {...rest} size="xl">
      <ServerValidationBox message={loadingError || error} />
      {isContentsLoading ? (
        <CenteredLoadingIndicator />
      ) : (
        <>
          <Box h="100%" overflowY="auto" pr={3}>
            <div dangerouslySetInnerHTML={{ __html: toc ? toc.value : '<div></div>' }} />
          </Box>
        </>
      )}
    </Modal>
  );
};

export default ToCModalComponent;
