import { Box, BoxProps, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react';
import { useField } from 'formik';
import { matchSorter } from 'match-sorter';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { components } from 'react-select';
import { SearchIcon } from '../../../assets/icons';
import useTranslationComponent from '../../../hooks/useTranslationComponent';
import { SelectComponent, SelectComponentProps } from './Select';

export type AutoCompleteProps = SelectComponentProps & {
  resultLimit?: number;
  staticOptions?: any[];
  filterKeys?: string[];
  onInputChangeCallback?: (value: string, setMenuIsOpen: (isOpen: boolean) => void) => void;
};

const IndicatorSeparator = ({ innerProps }) => {
  return <span style={{ display: 'none' }} {...innerProps} />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props} style={{ display: 'none' }}>
      <span></span>
    </components.DropdownIndicator>
  );
};

export const AutoComplete = (props: AutoCompleteProps) => {
  const {
    options,
    staticOptions,
    labelKey,
    resultLimit = 7,
    components: initialComponents,
    onInputChangeCallback,
    filterKeys,
    ...rest
  } = props;
  let i = 0;
  const [allOptions, setAllOptions] = useState<any>([...options]);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const components = { ...initialComponents, IndicatorSeparator, DropdownIndicator };

  return (
    <SelectComponent
      menuIsOpen={menuIsOpen}
      labelKey={labelKey}
      filterOption={() => i++ < resultLimit}
      onInputChange={(inputValue) => {
        let menuIsOpen = false;
        if (inputValue) {
          menuIsOpen = true;
        }
        setMenuIsOpen(menuIsOpen);
        i = 0;
        setAllOptions([
          ...(staticOptions || []),
          // @ts-ignore
          ...matchSorter(options, inputValue, { keys: [labelKey, ...(filterKeys ?? [])] }),
        ]);
        if (onInputChangeCallback) {
          onInputChangeCallback(inputValue, setMenuIsOpen);
        }
      }}
      options={allOptions}
      components={components}
      {...rest}
    />
  );
};

export type AutoCompleteAltProps = {
  options: any[];
  name: string;
  resultLimit?: number;
  staticOptions?: any[];
  label?: string;
  error?: string;
  filterKeys?: string[];
  labelKey: string;
  valueKey: string;
  selected?: any;
  onSelect?: (value: any) => void;
  isForm?: boolean;
  placeholder?: string;
  containerProps?: BoxProps;
  onInputChange?: (value: string) => void;
  onAddNewCallback?: (value: any) => void;
  mustSelectFromOptions?: boolean;
  saveNewValueOnAddNew?: boolean;
  addNewText?: string;
  noOptionsMessage?: string;
};

export const AutoCompleteAlt = (props: AutoCompleteAltProps) => {
  const { t } = useTranslationComponent();
  const {
    options,
    staticOptions,
    filterKeys,
    name,
    labelKey,
    valueKey,
    selected,
    onSelect,
    isForm,
    placeholder = t('Start typing...'),
    containerProps,
    onAddNewCallback,
    mustSelectFromOptions = true,
    saveNewValueOnAddNew = false,
    addNewText,
    noOptionsMessage,
    label,
    error,
  } = props;

  const [, meta, { setValue, setTouched }] = useField(name);

  const [selectedValue, setSelectedValue] = useState<any>();
  const [cursor, setCursor] = useState<number>(-1);

  const containerRef = useRef<any>(null);
  const resultRef = useRef<any>(null);

  const [componentError, setComponentError] = useState<string>(error || '');

  const [inputText, setInputText] = useState<string>('');
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const showMenu = () => setMenuOpen(true);
  const hideMenu = () => setMenuOpen(false);

  const handleOutsideClick = useCallback(
    (e, selectedValue) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setTouched(true);
        if (selectedValue && labelKey) {
          setInputText(selectedValue[labelKey]);
        }
        hideMenu();
      }
    },
    [labelKey, setTouched],
  );

  useEffect(() => {
    window.addEventListener('mousedown', (e) => handleOutsideClick(e, selectedValue));
    return () => {
      window.removeEventListener('mousedown', (e) => handleOutsideClick(e, selectedValue));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollIntoView = (position) => {
    resultRef.current?.parentNode?.scrollTo({
      top: position,
      behavior: 'smooth',
    });
  };

  const visibleOptions = useMemo(() => {
    if (!inputText) return options;
    setCursor(-1);
    scrollIntoView(0);
    return [
      ...(staticOptions || []),
      // @ts-ignore
      ...matchSorter(options, inputText, { keys: [labelKey, ...(filterKeys ?? [])] }),
    ];
  }, [options, inputText, filterKeys, labelKey, staticOptions]);

  const onSelectValue = (x) => {
    setComponentError('');
    setSelectedValue(x);
    setInputText(x[labelKey]);
    if (isForm) {
      setValue(x[valueKey]);
    }
    if (onSelect) {
      onSelect(x);
    }
    hideMenu();
  };

  useEffect(() => {
    if (cursor < 0 || cursor > visibleOptions.length || !resultRef) {
      return () => {};
    }
    const listItems: any[] = Array.from(resultRef.current.children);
    if (listItems[cursor]) {
      scrollIntoView(listItems[cursor].offsetTop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor]);

  const keyboardNavigation = (e) => {
    if (e.key === 'ArrowDown') {
      if (menuOpen) {
        setCursor((c) => (c < visibleOptions.length - 1 ? c + 1 : c));
      } else {
        showMenu();
      }
    }
    if (e.key === 'ArrowUp') {
      setCursor((c) => (c > 0 ? c - 1 : 0));
    }
    if (e.key === 'Escape') {
      setCursor(-1);
      hideMenu();
    }
    if (e.key === 'Enter' && cursor > 0) {
      setInputText(visibleOptions[cursor][labelKey]);
      onSelectValue(visibleOptions[cursor]);
      hideMenu();
    }
  };

  useEffect(() => {
    if (selected) {
      setInputText(selected[labelKey]);
      setSelectedValue(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleTextChange = (val: string) => {
    setInputText(val);
  };

  return (
    <Box {...containerProps} ref={containerRef}>
      {label && (
        <Text variant="body" mb={2}>
          {label}
        </Text>
      )}
      <Box>
        <InputGroup background="none">
          <Input
            background="#FFF"
            border={componentError ? '1px solid red !important' : '1px solid #000000 !important'}
            _focus={{
              background: '#FFF',
              border: componentError ? '1px solid red' : '1px solid #000000 !important',
            }}
            autoComplete="off"
            name={name}
            type="text"
            value={inputText}
            onChange={(e) => {
              handleTextChange(e.target.value);
            }}
            onClick={showMenu}
            onKeyDown={(e) => keyboardNavigation(e)}
            placeholder={placeholder}
          />
          <InputRightElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
        </InputGroup>
      </Box>

      {(componentError || meta?.error) && (
        <Box>
          <Text variant="body" color="red">
            {meta?.error || componentError}
          </Text>
        </Box>
      )}

      {menuOpen && (
        <Box
          mt={2}
          borderRadius="10px"
          boxShadow="lg"
          border="1px solid #E4E9F2"
          ref={resultRef}
          maxH="400px"
          overflowY="auto"
        >
          {!mustSelectFromOptions && (
            <Box
              cursor="pointer"
              p={2}
              pl={3}
              borderBottom="1px solid #E4E9F2"
              onClick={() => {
                setComponentError('');
                if (onAddNewCallback) {
                  onAddNewCallback(inputText);
                }
                if (saveNewValueOnAddNew) {
                  const obj = {};
                  obj[labelKey] = inputText;
                  obj[valueKey] = inputText;
                  setSelectedValue(obj);
                  if (isForm) {
                    setValue(inputText);
                  }
                  if (onSelect) {
                    onSelect(obj);
                  }
                }
                setMenuOpen(false);
              }}
            >
              <Text variant="body" fontWeight="bold">
                {addNewText || `+ ${t('Add New')}`}
              </Text>
            </Box>
          )}

          {visibleOptions.length > 0 ? (
            visibleOptions.map((x, i) => (
              <Box
                cursor="pointer"
                key={i}
                p={2}
                pl={3}
                borderBottom="1px solid #E4E9F2"
                onClick={() => onSelectValue(x)}
                background={i === cursor ? '#F2F2F2' : '#FFFFFF'}
                _hover={{
                  background: '#F2F2F2',
                }}
              >
                <Text variant="body">{x[labelKey]}</Text>
              </Box>
            ))
          ) : (
            <>
              <Box cursor="pointer" p={2} pl={3} borderBottom="1px solid #E4E9F2">
                <Text variant="body">{noOptionsMessage || t('No Record Found')}</Text>
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
