import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Provider, ProviderSettingEnum } from '../../../generated';
import InlineOption from '../InlineOption';
import { Paragraph } from '../Paragraph';
import ProviderSearchModal from './ProviderSearchModal';

const ReferralInfoWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid #757575;
  flex-direction: column;
  margin-bottom: 10px;

  > ${Paragraph} {
    padding: 2px 8px;
    margin: 8px 0 2px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 10px 0;

  > ${Paragraph} {
    padding: 2px 8px;
    margin: 8px 0 2px;
  }
`;

const AddressBookWrapper = styled.div`
  padding: 0 10px;
  border-left: 1px solid #cccccc;
`;

const ReferralLabel = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #1b3d71;
  padding-left: 8px;
  margin-bottom: 0;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  &:hover {
    color: ${(props) => props.theme.FontColors.Secondary};
    cursor: pointer;
  }
`;

interface IProps {
  inputTitle: string;
  selectedProvider: Provider | null;
  setSelectedProvider: (provder: Provider | null) => void;
  providerSettingEnum?: ProviderSettingEnum;
  providerSettingValue?: string;
  defaultCountryID?: string;
  modalTitle?: string;
}

const ProviderSearchInput = ({
  inputTitle,
  selectedProvider,
  setSelectedProvider,
  providerSettingEnum,
  providerSettingValue,
  defaultCountryID,
  modalTitle,
}: IProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      {selectedProvider === null ? (
        <ReferralInfoWrapper onClick={() => setIsModalOpen(true)}>
          <ReferralLabel>{inputTitle}</ReferralLabel>
          <TextWrapper>
            <Paragraph>No provider selected</Paragraph>
            <AddressBookWrapper>
              <StyledFontAwesomeIcon icon={['far', 'address-book']} size="lg" />
            </AddressBookWrapper>
          </TextWrapper>
        </ReferralInfoWrapper>
      ) : (
        <ReferralInfoWrapper onClick={() => setIsModalOpen(true)}>
          <ReferralLabel>{inputTitle}</ReferralLabel>
          <TextWrapper>
            <InlineOption
              text={
                selectedProvider?.fullName
                  ? `${selectedProvider?.fullName} - ${selectedProvider?.providerTypeTag?.name}`
                  : ''
              }
              onClear={(e) => {
                e.stopPropagation();
                setSelectedProvider(null);
              }}
            />
            <AddressBookWrapper>
              <StyledFontAwesomeIcon icon={['far', 'address-book']} size="lg" />
            </AddressBookWrapper>
          </TextWrapper>
        </ReferralInfoWrapper>
      )}
      {isModalOpen && (
        <ProviderSearchModal
          isOpen={isModalOpen}
          close={() => setIsModalOpen(false)}
          handleSelectedProvider={(provider: Provider) => {
            setSelectedProvider(provider);
            setIsModalOpen(false);
          }}
          providerSettingEnum={providerSettingEnum}
          providerSettingValue={providerSettingValue}
          defaultCountryID={defaultCountryID}
          modalTitle={modalTitle}
        />
      )}
    </>
  );
};

export default ProviderSearchInput;
