import { Box, Flex, Stack, Text, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { object, string } from 'yup';

import { AlertIcon } from '../../../../../../../../assets/icons';
import { PurpleButton } from '../../../../../../../../components/Buttons';
import { FormCheckboxControl, FormTextArea } from '../../../../../../../../components/forms';
import { CenteredLoadingIndicator } from '../../../../../../../../components/LoadingIndicator';
import ServerValidationBox from '../../../../../../../../components/ServerValidationBox';
import { PatientOrder, PatientOrderQuestion } from '../../../../../../../../generated';
import { boolenToRadio, radioToBoolean } from '../../../../../../../../utils/radioBooleanHelpers';

import { useQuestionair } from '../../../contexts/CreateOrderContext/useQuestionair/useQuestionair';

import { Label } from './common/Label';
import { BooleanRadioControl } from './common/RadioControl';
import { MriForm } from './questionairFormTypes';

export const MriQuestionair = () => {
  const { patientOrder, onSubmitForm, isLoading, error } = useQuestionair();

  return (
    <Formik
      initialValues={
        {
          reasonForExam: patientOrder?.reasonForExam ?? '',
          cardiacPacemaker: patientOrder?.patientOrderQuestion?.cardiacPacemaker,
          pregnant: patientOrder?.patientOrderQuestion?.pregnant,
          deepBrainImplant: patientOrder?.patientOrderQuestion?.deepBrainImplant,
          externalPacerWires: patientOrder?.patientOrderQuestion?.externalPacerWires,
          tissueExpander: patientOrder?.patientOrderQuestion?.tissueExpander,
          contrastAllergies: boolenToRadio(patientOrder?.patientOrderQuestion?.contrastAllergies),
          surgicallyImplantedDevices: boolenToRadio(
            patientOrder?.patientOrderQuestion?.surgicallyImplantedDevices,
          ),
          surgicallyImplantedDeviceDescription:
            patientOrder?.patientOrderQuestion?.surgicallyImplantedDeviceDescription ?? '',
          informedToPresentImplantCard: boolenToRadio(
            patientOrder?.patientOrderQuestion?.informedToPresentImplantCard,
          ),
          metallicForeignBodyFromEye: boolenToRadio(
            patientOrder?.patientOrderQuestion?.metallicForeignBodyFromEye,
          ),
          orbitXrayOrdered: boolenToRadio(patientOrder?.patientOrderQuestion?.orbitXrayOrdered),
          locationOfProcedure: patientOrder?.patientOrderQuestion?.locationOfProcedure ?? '',
          claustrophobic: boolenToRadio(patientOrder?.patientOrderQuestion?.claustrophobic),
        } as MriForm
      }
      validationSchema={object({
        reasonForExam: string().max(1024, 'Reason for Exam must be at most 1024 characters'),
        locationOfProcedure: string().max(
          1024,
          'Location of procedure must be at most 1024 characters',
        ),
        surgicallyImplantedDeviceDescription: string().max(
          1024,
          'Device description must be at most 1024 characters',
        ),
        contrastAllergies: string().required('This field is required.'),
        surgicallyImplantedDevices: string().required('This field is required.'),
        informedToPresentImplantCard: string().required('This field is required.'),
        metallicForeignBodyFromEye: string().required('This field is required.'),
        orbitXrayOrdered: string().required('This field is required.'),
        claustrophobic: string().required('This field is required.'),
      })}
      onSubmit={async (values: MriForm) => {
        const patientOrderQuestion = new PatientOrderQuestion();
        patientOrderQuestion.init(patientOrder?.patientOrderQuestion);

        const po = new PatientOrder();
        po.init(patientOrder);

        po.patientOrderQuestion = patientOrderQuestion;

        po.reasonForExam = values.reasonForExam;
        po.patientOrderQuestion.cardiacPacemaker = values.cardiacPacemaker;
        po.patientOrderQuestion.pregnant = values.pregnant;
        po.patientOrderQuestion.deepBrainImplant = values.deepBrainImplant;
        po.patientOrderQuestion.externalPacerWires = values.externalPacerWires;
        po.patientOrderQuestion.tissueExpander = values.tissueExpander;
        po.patientOrderQuestion.contrastAllergies = radioToBoolean(values.contrastAllergies);
        po.patientOrderQuestion.surgicallyImplantedDevices = radioToBoolean(
          values.surgicallyImplantedDevices,
        );
        po.patientOrderQuestion.surgicallyImplantedDeviceDescription =
          values.surgicallyImplantedDeviceDescription;
        po.patientOrderQuestion.informedToPresentImplantCard = radioToBoolean(
          values.informedToPresentImplantCard,
        );
        po.patientOrderQuestion.metallicForeignBodyFromEye = radioToBoolean(
          values.metallicForeignBodyFromEye,
        );
        po.patientOrderQuestion.orbitXrayOrdered = radioToBoolean(values.orbitXrayOrdered);
        po.patientOrderQuestion.locationOfProcedure = values.locationOfProcedure;
        po.patientOrderQuestion.claustrophobic = radioToBoolean(values.claustrophobic);

        await onSubmitForm(po);
      }}
    >
      {({ values }) => (
        <StyledForm>
          {isLoading ? (
            <CenteredLoadingIndicator />
          ) : (
            <VStack h="100%" alignItems="stretch">
              <Stack spacing="5" overflowY="auto" flex="1" px="2">
                <ServerValidationBox message={error} />

                <Text fontSize="12px" fontWeight="semibold">
                  Screening Questions - Answer All
                </Text>

                <Box pb={3}>
                  <Label mb={5}>
                    Does The Patient Have Any Of The Following? (Check All That Apply)
                  </Label>
                  <Wrap spacing="5" width="95%">
                    <WrapItem>
                      <FormCheckboxControl name="cardiacPacemaker">
                        <Text fontSize="sm">Cardiac Pacemaker</Text>
                      </FormCheckboxControl>
                    </WrapItem>
                    <WrapItem>
                      <FormCheckboxControl name="deepBrainImplant">
                        <Text fontSize="sm">Deep Brain Implant</Text>
                      </FormCheckboxControl>
                    </WrapItem>
                    <WrapItem>
                      <FormCheckboxControl name="externalPacerWires">
                        <Text fontSize="sm">External Pacer Wires</Text>
                      </FormCheckboxControl>
                    </WrapItem>
                    <WrapItem>
                      <FormCheckboxControl name="tissueExpander">
                        <Text fontSize="sm">Tissue Expander</Text>
                      </FormCheckboxControl>
                    </WrapItem>
                    <WrapItem>
                      <FormCheckboxControl name="pregnant">
                        <Text fontSize="sm">Currently Pregnant</Text>
                      </FormCheckboxControl>
                    </WrapItem>
                  </Wrap>
                  {(values.cardiacPacemaker ||
                    values.deepBrainImplant ||
                    values.externalPacerWires ||
                    values.tissueExpander ||
                    values.pregnant) && (
                    <Flex mt={7} maxW="60%">
                      <AlertIcon fontSize="24px" color="#EA1536" />
                      <Text ml={2} color="#EA1536" fontSize="sm">
                        If the Patient has any of these, an MRI may be contraindicated. Please
                        proceed with caution and verify safety with the Consulting Radiologist.
                      </Text>
                    </Flex>
                  )}
                </Box>

                <BooleanRadioControl label="Contrast Allergies" name="contrastAllergies" />

                <BooleanRadioControl
                  label="Does The Patient Have Aneurysm Clips, Cardiac Valves, Stents Or Any Other
                    Surgically Implanted Devices?"
                  name="surgicallyImplantedDevices"
                />

                <Box>
                  <FormTextArea
                    maxLength={250}
                    name="surgicallyImplantedDeviceDescription"
                    label="Provide Details including Make / Model if known:"
                    formControlProps={{
                      mb: '0',
                    }}
                  />
                </Box>

                <BooleanRadioControl
                  label="Patient Informed to present implant Card at MRI Appointment?"
                  name="informedToPresentImplantCard"
                />

                <BooleanRadioControl
                  label="Has the Patient ever had a metallic foreign body removed from their eye by a
                    medical professional?"
                  name="metallicForeignBodyFromEye"
                />

                <BooleanRadioControl label="Was Orbit X-Ray ordered?" name="orbitXrayOrdered" />

                <Box>
                  <FormTextArea
                    maxLength={250}
                    name="locationOfProcedure"
                    label="Location / Date of Procedure:"
                    formControlProps={{
                      mb: '0',
                    }}
                  />
                </Box>

                <BooleanRadioControl label="Is patient claustrophobic?" name="claustrophobic" />

                <Box>
                  <FormTextArea
                    maxLength={250}
                    name="reasonForExam"
                    label="Clinical History / Reason for Exam:"
                    formControlProps={{
                      mb: '0',
                    }}
                  />
                </Box>
              </Stack>
              <Flex justifyContent="flex-end" pt="4">
                <PurpleButton type="submit" width={{ base: '100%', sm: 'auto' }}>
                  {values.cardiacPacemaker ||
                  values.deepBrainImplant ||
                  values.externalPacerWires ||
                  values.tissueExpander ||
                  values.pregnant
                    ? 'Ignore Warnings & Proceed'
                    : 'Continue'}
                </PurpleButton>
              </Flex>
            </VStack>
          )}
        </StyledForm>
      )}
    </Formik>
  );
};

const StyledForm = styled(Form)`
  height: 100%;
`;
