import { useEffect, useState } from 'react';
import { EMPTY_GUID } from '../../../constants';
import { CityTown } from '../../../generated';
import { useCityTownsByProvinceStateID } from '../../../services/cityTowns/queries/useCityTownsByProvinceStateID';
import { ICityTownOption } from './AddressPicker';

interface IProps {
  provinceStateID?: string;
  initialCityTown: ICityTownOption | null;
}

const useCityTowns = ({ provinceStateID, initialCityTown }: IProps) => {
  const [cityTown, setCityTown] = useState<ICityTownOption | null>(initialCityTown);
  const [cityTownOptions, setCityTownOptions] = useState<CityTown[]>([]);
  const { isLoadingCityTowns, cityTownsResult, cityTownsError } = useCityTownsByProvinceStateID({
    provinceStateID,
  });

  useEffect(() => {
    let options = [...(cityTownsResult ?? [])];
    if (cityTown?.cityTownID === EMPTY_GUID) {
      // @ts-ignore
      options = [...options, { ...cityTown, __isNew__: true }];
    }
    setCityTownOptions(options);
  }, [cityTownsResult, cityTown]);

  return {
    cityTownOptions,
    cityTown,
    setCityTown,
    isLoadingCityTowns,
    cityTownsError,
  };
};

export default useCityTowns;
