import { useFormikContext } from 'formik';
import moment from 'moment';
import { useEffect } from 'react';
import { DurationTypeEnum } from '../../../../../generated';
import {
  FrequencyEnum,
  IAddNewMedicationForm,
} from '../../hooks/useAddNewMedication/useAddNewMedication';

export const DurationHandler = () => {
  const { values, setFieldValue } = useFormikContext<IAddNewMedicationForm>();

  useEffect(() => {
    if (
      ((values.durationCount && !isNaN(values.durationCount as any)) ||
        (values.otherDurationCount && !isNaN(values.otherDurationCount as any))) &&
      values.durationType &&
      values.frequency &&
      ((values.quantityPerDose && !isNaN(values.quantityPerDose as any)) ||
        (values.otherQuantityPerDose && !isNaN(values.otherQuantityPerDose as any)))
    ) {
      const multiplyFactor =
        values.durationType === DurationTypeEnum.Years
          ? 365
          : values.durationType === DurationTypeEnum.Months
          ? 31
          : values.durationType === DurationTypeEnum.Weeks
          ? 7
          : 1;

      let times = 1;
      switch (values.frequency) {
        // case FrequencyEnum.QOD:
        //   times = 0.5;
        //   break;
        case FrequencyEnum.QD:
        case FrequencyEnum.QHS:
          times = 1;
          break;
        case FrequencyEnum.BID:
          times = 2;
          break;
        case FrequencyEnum.TID:
          times = 3;
          break;
        case FrequencyEnum.QID:
        case FrequencyEnum.Q6H:
          times = 4;
          break;
        // case FrequencyEnum.FIVEX:
        //   times = 5;
        //   break;
        case FrequencyEnum.Q4H:
          times = 6;
          break;
      }

      const days =
        multiplyFactor *
        (values.durationCount
          ? +values.durationCount
          : values.otherDurationCount
          ? +values.otherDurationCount
          : 0);
      const quantity =
        days *
        times *
        (values.quantityPerDose
          ? +values.quantityPerDose
          : values.otherQuantityPerDose
          ? +values.otherQuantityPerDose
          : 1);
      setFieldValue('quantity', '' + quantity);

      const startDate = moment(values.startDate);
      const endDate = startDate.add(days, 'd');

      setFieldValue('endDate', endDate.toDate());
    }
  }, [
    setFieldValue,
    values.durationCount,
    values.otherDurationCount,
    values.durationType,
    values.frequency,
    values.startDate,
    values.quantityPerDose,
    values.otherQuantityPerDose,
  ]);

  return null;
};
